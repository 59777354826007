import { ChannelScalarDataAndMappings } from '../channel-scalar-data-and-mappings';
import { ProcessedExplorationMap } from './processed-exploration-map';
import { RemoveBadJobsFromStudy } from '../remove-bad-jobs-from-study';
import { GetExplorationInputsFromExplorationMap } from './get-exploration-inputs-from-exploration-map';
import { GetExplorationJobs } from './get-exploration-jobs';
import { GetExplorationJob } from './get-exploration-job';
import { GetExplorationJobsMetadataFromExplorationMap } from './get-exploration-jobs-metadata-from-exploration-map';
import { ExplorationMap } from '../exploration-map';
import { ExpandExplorationSubSweeps } from './expand-exploration-sub-sweeps';
import { GetExplorationInputsFromInputsMetadata } from './get-exploration-inputs-from-inputs-metadata';
import { SortExplorationMetadata } from './exploration-sorting/sort-exploration-metadata';


/**
 * This class processes an exploration map into a more easily usable structure.
 * See the documentation on `ProcessedExplorationMap` for more information.
 */
export class GetProcessedExplorationMap {

  public static create() {
    return new GetProcessedExplorationMap(
      new RemoveBadJobsFromStudy(),
      new GetExplorationInputsFromExplorationMap(new ExpandExplorationSubSweeps()),
      new GetExplorationJobsMetadataFromExplorationMap(),
      SortExplorationMetadata.create(),
      new GetExplorationInputsFromInputsMetadata(),
      new GetExplorationJobs(new GetExplorationJob()));
  }

  /**
   * Creates a new instance of GetProcessedExplorationMap.
   * @param removeBadJobsFromStudy Removes bad jobs from the study.
   * @param getExplorationInputsFromExplorationMap Gets the exploration inputs from the exploration map.
   * @param getExplorationJobsMetadataFromExplorationMap Gets the exploration jobs metadata from the exploration map.
   * @param sortExplorationMetadata Sorts the exploration metadata.
   * @param getExplorationInputsFromInputsMetadata Gets the exploration inputs from the inputs metadata.
   * @param getExplorationJobs Gets the exploration jobs.
   */
  constructor(
    private readonly removeBadJobsFromStudy: RemoveBadJobsFromStudy,
    private readonly getExplorationInputsFromExplorationMap: GetExplorationInputsFromExplorationMap,
    private readonly getExplorationJobsMetadataFromExplorationMap: GetExplorationJobsMetadataFromExplorationMap,
    private readonly sortExplorationMetadata: SortExplorationMetadata,
    private readonly getExplorationInputsFromInputsMetadata: GetExplorationInputsFromInputsMetadata,
    private readonly getExplorationJobs: GetExplorationJobs) {
  }

  /**
   * Processes an exploration map into a more easily usable structure.
   * @param map The exploration map.
   * @param scalarData The scalar data.
   * @returns The processed exploration map.
   */
  public execute(map: ExplorationMap, scalarData?: ChannelScalarDataAndMappings) {

    // Get the exploration inputs in a nicer form than the raw exploration map.
    let inputsMetadata = this.getExplorationInputsFromExplorationMap.execute(map);

    if (scalarData) {
      // If we have scalar data, use it to remove bad jobs from the study.
      this.removeBadJobsFromStudy.execute(scalarData, map);
    }

    // Gets the metadata about each exploration job.
    let jobsMetadata = this.getExplorationJobsMetadataFromExplorationMap.execute(map);

    // Sort the exploration metadata based on the sweep values.
    [inputsMetadata, jobsMetadata] = this.sortExplorationMetadata.execute(inputsMetadata, jobsMetadata);

    // Convert from the basic metadata structures to more fleshed out versions.
    const inputs = this.getExplorationInputsFromInputsMetadata.execute(inputsMetadata);

    // Convert from the job metadata to a more fleshed out job structure.
    const jobs = this.getExplorationJobs.execute(inputs, jobsMetadata);

    // Return the processed exploration map data.
    return new ProcessedExplorationMap(
      map.designName,
      inputs,
      jobs);
  }
}

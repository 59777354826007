import {ISaveOutputConfigHandler} from '../compare-config-dialog/compare-config-dialog.service';
import {StudyInput} from '../../../../worksheets/study-input';
import {StudyStagingArea} from '../../../study-staging-area/study-staging-area.service';
import {Injectable} from '@angular/core';

@Injectable()
export class SaveToStagingAreaHandlerFactory {
  constructor(
    private readonly studyStagingArea: StudyStagingArea) {
  }

  public create(): SaveToStagingAreaHandler {
    return new SaveToStagingAreaHandler(this.studyStagingArea);
  }
}

export class SaveToStagingAreaHandler implements ISaveOutputConfigHandler {

  constructor(
    private readonly studyStagingArea: StudyStagingArea){

  }

  public canExecute(studyInput: StudyInput): boolean {
    return true;
  }

  public execute(studyInput: StudyInput): Promise<void> {
    this.studyStagingArea.stageInput(studyInput);
    return Promise.resolve();
  }
}

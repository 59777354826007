<div id="edit-config-sticky">
  <div class="sticky" [ngStyle]="{ 'bottom': bottomString }">
    <p class="last-item">
      @if (showSaved) {
        <span id="sticky-success-message" class="status-text text-body-secondary">{{isStaged ? 'Staged' : 'Saved'}}</span>
      }
      @if (showDirty) {
        <span id="sticky-dirty-message" class="status-text text-body-secondary">{{isStaged ? 'Unstaged' : 'Unsaved'}} Changes</span>
      }
      <button id="sticky-edit-submit" type="submit" class="btn btn-smart btn-secondary" [disabled]="isSubmitDisabled">
        @if (isStaged) {
          <i class="fa fa-arrow-circle-up"></i>
        }
        @if (!isStaged) {
          <i class="fa fa-cloud"></i>
        }
        {{submitButtonText}}
      </button>
      <button type="button" class="btn btn-smart btn-secondary" (click)="scrollToTop()">
        <i class="fa fa-long-arrow-up"></i>Scroll to Top
      </button>
    </p>
  </div>
</div>

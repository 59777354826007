import { ConfigBuilderBase } from './config-builder-base';
import { NavigationStationConfig, NavigationStationConfigBuilder } from './navigation-station-config-builder';
import {
  LocalConfigSourceLoader,
  LocalConfigSourceLoaderOptions,
  UnitsMap
} from '../../viewers/channel-data-loaders/local-config-source-loader';
import { StudyJob } from '../../study-job';
import { UrlFileLoader } from '../../url-file-loader';
import { RequestedLayoutIds, SiteHooks } from '../../site-hooks';
import { SourceLoaderViewModel } from '../../viewers/channel-data-loaders/source-loader-set';
import { SharedState } from '../../viewers/shared-state';
import { DomainNewsCache } from '../../viewers/domain-news-cache';
import { LinePlotViewer, POINT_MULTI_PLOT_VIEWER_TYPE } from '../../viewers/line-plot-viewer/line-plot-viewer';
import { ChannelNameStyle } from '../../viewers/channel-data-loaders/channel-name-style';
import { GetJsonValues } from '../../get-json-values-at-path';
import { ScatterPlotViewer } from '../../viewers/scatter-plot-viewer/scatter-plot-viewer';
import { IChannelMetadata } from '../../viewers/channel-data-loaders/source-loader';
import { firstDistinct } from '../../first-distinct';
import { IRectangle } from '../../viewers/rectangle';

/**
 * The type of chart to create.
 */
export enum ConfigPreviewChartType {

  /**
   * A line chart.
   */
  line,

  /**
   * A scatter chart.
   */
  scatter,
}

export interface CreateChartOptions {
  readonly arrayIsValidValue?: boolean;
  readonly forBranchName?: string;
  readonly filter?: (config: any) => boolean;
  readonly map?: (config: any) => any;
  readonly unitsMap?: UnitsMap;
  readonly chartType?: ConfigPreviewChartType;
  readonly layoutName?: string;
  readonly onlyIfPrimaryDomainExists?: boolean;
}

export class ConfigData {
  constructor(
    public readonly name: string,
    public readonly data: any) {
  }
}

/**
 * A config builder for previewing configs. This contains useful methods for generating charts
 * from data within the configs, if that data exists.
 */
export abstract class ConfigPreviewConfigBuilder extends ConfigBuilderBase implements NavigationStationConfigBuilder {

  /**
   * Constructs a new config preview config builder.
   * @param configType The type of config.
   * @param fileLoader The file loader.
   * @param siteHooks The site hooks.
   * @param configs The configs for this builder session.
   * @param unitsMap The units map.
   */
  constructor(
    protected readonly configType: string,
    fileLoader: UrlFileLoader,
    siteHooks: SiteHooks,
    protected configs: ReadonlyArray<ConfigData>,
    protected readonly unitsMap?: UnitsMap) {
    super(fileLoader, siteHooks, [new StudyJob('dummy', 0)], []);
  }

  /**
   * Creates a chart for the provided path into a config, if there is anything to plot.
   * @param propertyPath The property path to the data.
   * @param name The name of the chart.
   * @param primaryDomainName The primary domain name.
   * @param config The config to use as the data source.
   * @param defaultGridSlot The default grid slot.
   * @param domainNewsCache The domain news cache.
   * @param options The options for the source loader.
   * @param chartOptions The chart options.
   */
  protected async createConfigAreaChart(
    propertyPath: string,
    name: string,
    primaryDomainName: string,
    config: NavigationStationConfig,
    defaultGridSlot: IRectangle,
    domainNewsCache: DomainNewsCache | undefined,
    options?: LocalConfigSourceLoaderOptions,
    chartOptions?: CreateChartOptions) {

    chartOptions = chartOptions || {};

    let channels: IChannelMetadata[] = [];
    let sharedState = new SharedState(domainNewsCache);

    // For each config...
    for (let configData of this.configs) {

      // Query the config using the property path. Note this may return multiple results if there are
      // arrays in the path, or if the path contains branches.
      let jsonResults = GetJsonValues.forPath(configData.data, propertyPath, !!chartOptions.arrayIsValidValue);

      // For each result...
      for (let jsonResult of jsonResults) {

        // Get the JSON value which is the result of the query.
        let value = jsonResult.value;

        // If a branch name is specified, only include the data if it matches the branch name.
        if (chartOptions.forBranchName && value['name'] !== chartOptions.forBranchName) {
          continue;
        }

        // If a filter is specified, only include the data if it passes the filter.
        if (chartOptions.filter && !chartOptions.filter(value)) {
          continue;
        }

        // If a map function is specified, map the data.
        if (chartOptions.map) {
          value = chartOptions.map(value);
        }

        // Come up with a suitable name for the data source, which includes the config name if there is more than one config.
        let sourceName = this.configs.length > 1 ? configData.name + ' - ' + jsonResult.path : jsonResult.path;

        // Create a source loader for the data.
        let sourceLoader = new SourceLoaderViewModel(
          LocalConfigSourceLoader.create(
            this.siteHooks,
            value,
            sourceName,
            primaryDomainName,
            {
              ...this.unitsMap || {},
              ...chartOptions.unitsMap || {}
            },
            options));

        // Get the available channels in the data.
        let allChannels = await sourceLoader.getRequestableChannels(ChannelNameStyle.FullyQualified, primaryDomainName);

        // Filter out the domain channel.
        let channelsWithoutDomain = allChannels.filter(v => v.name !== primaryDomainName);

        // If we must have the primary domain and it doesn't exist, skip this data.
        if (allChannels.length === channelsWithoutDomain.length && chartOptions.onlyIfPrimaryDomainExists) {
          continue;
        }

        // If we have channels, then add the source loader to the shared state and add the channels to the list.
        if (channelsWithoutDomain.length) {
          sharedState.sourceLoaderSet.add(sourceLoader);
          channels.push(...channelsWithoutDomain);
        }
      }
    }

    // Remove duplicate channels.
    channels = firstDistinct(channels, c => c.name);

    // If we have some channels...
    if (channels.length) {
      config.sharedStates.push(sharedState);

      // Plot the primary domain against the other channels, one channel per pane.
      let defaultConfig = {
        columns: [
          {
            channels: [primaryDomainName],
            relativeSize: 1
          }
        ],
        rows: channels.map(v => ({ channels: [v.name], relativeSize: 1 }))
      };

      let layoutSuffix = name.replace(/\s/g, '');

      // Resolve the layout, falling back to the default created above if the user hasn't saved a default layout.
      let layout = await this.resolveViewerLayout(
        POINT_MULTI_PLOT_VIEWER_TYPE,
        new RequestedLayoutIds(chartOptions.layoutName || 'Default-' + this.configType + layoutSuffix),
        defaultConfig);

      // Add the viewer to the navigation station config.
      config.views.push(
        {
          title: name,
          viewerType: POINT_MULTI_PLOT_VIEWER_TYPE,
          layout,
          viewer: !chartOptions.chartType
            ? LinePlotViewer.createLinePlotViewer(primaryDomainName, layout.resolvedLayout.getConfigCopy(), config.channelNameStyle, sharedState, this.siteHooks)
            : ScatterPlotViewer.create(primaryDomainName, layout.resolvedLayout.getConfigCopy(), config.channelNameStyle, sharedState, this.siteHooks),
          grid: this.getGridSlot(defaultGridSlot, layout.viewerMetadata)
        });
    }
  }
}

import { TransientMultiPlotChannel } from './types/transient-multi-plot-channel';
import { TransientMultiPlotSide } from './types/transient-multi-plot-side';
import { IMultiPlotSide } from './types/i-multi-plot-side';
import { isUndefined } from '../../is-defined';


/**
 * Creates default transient data structures for a side and each of the side's channels if
 * they don't already exist.
 */
export class CreateTransientDataForSide {

  /**
   * Creates default transient data structures for a side and each of the side's channels if
   * they don't already exist.
   * @param side The side to create transient data on.
   */
  public execute(side: IMultiPlotSide) {
    if (isUndefined(side.transient)) {
      side.transient = new TransientMultiPlotSide();
    }

    if (side.channels) {
      for (let channel of side.channels) {
        if (isUndefined(channel.transient)) {
          channel.transient = new TransientMultiPlotChannel();
        }
      }
    }
  }
}

import * as d3 from '../../d3-bundle';
import { TrackPath } from './track-path';

export const TRACK_HEIGHT_SCALE_FACTOR = 1;

/**
 * Track data extracted from a track config and converted into a standard format which is straightforward to render.
 */
export class TrackData {

  /**
   * Creates a new instance of TrackData.
   * @param innerEdge The inner edge of the track.
   * @param outerEdge The outer edge of the track.
   * @param centreLine The supplied centre line if it exists (not a calculated one).
   * @param carPath The path of the car.
   * @param carNormals The normals of the car.
   * @param sLap The sLap data.
   * @param sLapCentreLine The sLap centre line data.
   * @param isFigureOfEight Whether the track is a figure of eight.
   * @param startFinishOffset The offset of the start/finish line.
   */
  constructor(
    public readonly innerEdge: TrackPath,
    public readonly outerEdge: TrackPath,
    public readonly centreLine: TrackPath, //
    public readonly carPath: TrackPath,
    public readonly carNormals: TrackPath,
    public readonly sLap: ReadonlyArray<number>,
    public readonly sLapCentreLine: ReadonlyArray<number>,
    public readonly isFigureOfEight: boolean,
    public readonly startFinishOffset: number) {

    let minHeight = d3.minStrict(
      [innerEdge, outerEdge, carPath]
        .filter(v => v.trackCoordinates.length)
        .map(v => v.minHeight));

    let scale = TRACK_HEIGHT_SCALE_FACTOR;
    let offset = -minHeight;

    // Drop minimum height to zero and scale up to exaggerate height in the viewer.
    this.innerEdge.adjustWorldElevationCoordinates(scale, offset);
    this.outerEdge.adjustWorldElevationCoordinates(scale, offset);
    this.centreLine.adjustWorldElevationCoordinates(scale, offset);
    this.carPath.adjustWorldElevationCoordinates(scale, offset);
  }
}

import {Component, OnInit} from '@angular/core';
import { AuthenticationService } from '../identity/state/authentication.service';

@Component({
  templateUrl: './pool-status.page.html'
})
export class PoolStatusPage implements OnInit {

  public tenantId: string;

  constructor(
    private authenticationService: AuthenticationService
  ){
  }

  public ngOnInit() {
    const userData = this.authenticationService.userDataSnapshot;
    this.tenantId = userData.tenant;
  }
}

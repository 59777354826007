import { SimType } from './sim-type';
import { ExplorationMap } from './viewers/channel-data-loaders/exploration-map';

/**
 * The scalar data for a single simulation job.
 */
export interface ScalarDataItem {

  /**
   * Mapping from the full name of the scalar result to the value.
   */
  [fullName: string]: number;
}

/**
 * The metadata for a single scalar result.
 */
export interface ScalarMetadataItem {

  /**
   * The name of the scalar result.
   */
  name: string;

  /**
   * The simulation type of the scalar result.
   */
  simType: SimType;

  /**
   * The units of the scalar result.
   **/
  units: string;

  /**
   * The description of the scalar result.
   */
  description: string;
}

/**
 * The metadata for a scalar input.
 */
export interface ScalarInputsMetadataItem {

  /**
   * The name of the input.
   */
  inputName: string;

  /**
   * The units of the input.
   */
  units: string;

  /**
   * The description of the input.
   */
  description: string;

  /**
   * The full name of the input.
   */
  fullName: string;

  /**
   * The short name of the input.
   */
  shortName: string;

  /**
   * The modifier of the input.
   */
  modifier: string;
}

/**
 * The scalar results of a study.
 */
export interface StudyScalarResults {

  /**
   * The list of scalar data, one array element per simulation job.
   */
  data: ScalarDataItem[];

  /**
   * The list of scalar metadata, one array element per scalar result.
   */
  metadata: ScalarMetadataItem[];

  /**
   * The list of scalar inputs metadata, one array element per scalar result.
   */
  inputsMetadata: ScalarInputsMetadataItem[];
}

/**
 * A single scalar result.
 */
export interface SingleScalarResult {

  /**
   * The name of the scalar result.
   */
  name: string;

  /**
   * The value of the scalar result.
   */
  value: number;

  /**
   * The units of the scalar result.
   */
  units: string;

  /**
   * The description of the scalar result.
   */
  description: string;
}

/**
 * Metadata about a channel.
 */
export interface ChannelMetadata {
  /**
   * The name of the channel.
   */
  name: string;

  /**
   * The simulation type.
   */
  simType: string;

  /**
   * The description of the channel.
   */
  description: string;

  /**
   * The units of the channel.
   */
  units: string;

  /**
   * The X domain name for the channel.
   */
  xDomainName: string;

  /**
   * The binary format metadata for the channel.
   */
  binaryFormat?: ChannelBinaryFormat;

  /**
   * The loader metadata for the channel.
   */
  loaderMetadata?: any;
}

/**
 * Metadata about the binary format of channel data.
 */
export class ChannelBinaryFormat {
  /**
   * Creates a new instance of ChannelBinaryFormat.
   * @param pointsCount The number of points in the binary data.
   */
  constructor(
    public readonly pointsCount: number) {
  }
}

/**
 * Metadata for a study.
 */
export class StudyMetadata {
  /**
   * Creates a new instance of StudyMetadata.
   * @param studyId The study ID.
   * @param simVersion The simulation version.
   */
  constructor(
    public readonly studyId: string,
    public readonly simVersion: string) {
  }
}

/**
 * A generic interface for loading data into our visualizations.
 * This made more sense when the visualizations were in their own repository separate
 * from `canopy-web`.
 */
export interface UrlFileLoader {

  /**
   * Load the vector metadata for the given study, job and sim type.
   * @param studyId The study ID.
   * @param jobIndex The job index.
   * @param simType The simulation type.
   */
  loadVectorMetadata(studyId: string, jobIndex: number, simType: SimType): Promise<ChannelMetadata[]>;

  /**
   * Load the scalar results for the given study, job and sim type.
   * @param studyId The study ID.
   * @param jobIndex The job index.
   * @param simType The simulation type.
   */
  loadScalarResultsForSim(studyId: string, jobIndex: number, simType: SimType): Promise<SingleScalarResult[]>;

  /**
   * Load the scalar results for the given study.
   * @param studyId The study ID.
   */
  loadScalarResultsForStudy(studyId: string): Promise<StudyScalarResults>;

  /**
   * Load the channel data for the given study, job, sim type and channel.
   * @param studyId The study ID.
   * @param jobIndex The job index.
   * @param simType The simulation type.
   * @param channelName The channel name.
   * @param binaryFormat The binary format for the channel.
   */
  loadChannelData(studyId: string, jobIndex: number, simType: SimType, channelName: string, binaryFormat: ChannelBinaryFormat | undefined): Promise<ReadonlyArray<number>>;

  /**
   * Load the exploration map for the given study.
   * @param studyId The study ID.
   */
  loadExplorationMap(studyId: string): Promise<ExplorationMap>;

  /**
   * Load the chart layout for the given layout ID.
   * @param layoutId The layout ID.
   */
  loadChartLayout(layoutId: string): Promise<any>;

  /**
   * Load the track for the given study.
   * @param studyId The study ID.
   */
  loadTrackForStudy(studyId: string): Promise<any>;

  /**
   * Load the track for the given study and job.
   * @param studyId The study ID.
   * @param jobIndex The job index.
   */
  loadTrackForStudyJob(studyId: string, jobIndex: number): Promise<any>;

  /**
   * Load the CSV file with the given file name.
   * @param fileName The file name.
   */
  loadCsv(fileName: string): Promise<any>;

  /**
   * Load the car for the given study.
   * @param studyId The study ID.
   */
  loadCarForStudy(studyId: string): Promise<any>;

  /**
   * Load the car for the given study and job.
   * @param studyId The study ID.
   * @param jobIndex The job index.
   */
  loadCarForStudyJob(studyId: string, jobIndex: number): Promise<any>;

  /**
   * Load the study metadata for the given study.
   * @param studyId The study ID.
   */
  loadStudyMetadata(studyId: string): Promise<StudyMetadata>;
}

import {Injectable} from '@angular/core';
import {RowViewModel} from '../row-view-model';
import {ConfigReference} from '../../../generated/api-stubs';
import {ReplaceResult} from '../worksheet-item-component-base';
import {ConfirmReplaceAllReferences} from './confirm-replace-all-references.service';
import {ConfigViewModel} from '../config-view-model';

/**
 * Service for setting a config either for the currently selected cell or for all cells which
 * have the same reference as the current cell. If the user's preference (replace this reference or
 * replace all references) is not passed in, they will be prompted.
 */
@Injectable()
export class SetConfigOrAllReferences {

  /**
   * Creates an instance of SetConfigOrAllReferences.
   * @param confirmReplaceAllReferences The service for confirming the replacement of all references.
   */
  constructor(
    private readonly confirmReplaceAllReferences: ConfirmReplaceAllReferences) {
  }

  /**
   * Sets the config for the target cell or all cells with the same reference.
   * @param target The target view model.
   * @param rows The rows to set the config for.
   * @param reference The reference to set.
   * @param replaceResult The user's preference for replacing the config.
   */
  public async execute(target: ConfigViewModel, rows: ReadonlyArray<RowViewModel>, reference: ConfigReference, replaceResult: ReplaceResult | undefined){

    // We usually pass the replace result in, because we prompt at the beginning of the operation.
    // Prompting at the end would often result in needing to clean up any duplicated configs if the user chooses
    // to cancel, and in other cases we still prompt at the beginning for consistency.
    // If the replace result is not passed in, we prompt the user.
    if(replaceResult === undefined){
      replaceResult = await this.confirmReplaceAllReferences.execute(target, rows);
    }

    switch(replaceResult){
      case ReplaceResult.replaceThisReference:
        // Replace the target config type in all selected rows.
        for(let row of rows) {
          row.getOrCreateConfig(target.configType).setConfig(reference);
        }
        break;

      case ReplaceResult.replaceAllReferences:
        // This only occurs if the user has selected a single cell, so we don't need to iterate rows.
        await target.replaceAllConfigs(reference);
        break;
    }
  }
}

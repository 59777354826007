import {SimContext} from '../runner/sim-context';
import {ANY_TARGET, Rule} from '../runner/rule';
import {SimChannel} from '../runner/sim-channel';
import {Channel} from '../runner/channel';
import {RuleLogger} from '../runner/rule-logger';

export class ChannelNaNs extends Rule {
  public name = 'Channel NaNs';
  public configTypes: string[] = null;
  public simTypes = ANY_TARGET;

  public channels = [ 'gLat', 'gLong' ];

  public getSimChannels(context: SimContext): SimChannel[] {
    return this.channels.map(v => new SimChannel(v, context.simType));
  }

  public evaluateSim(context: SimContext, log: RuleLogger) {
    for(let channelName of this.channels){
      this.evaluateChannel(context.tryGetChannel(channelName, context.simType), log);
    }
  }

  private evaluateChannel(channel: Channel, log: RuleLogger){
    if(!channel){
      return;
    }

    if(channel.values.some(v => isNaN(v))){
      log.error(`Channel ${channel.fullName} contains NaNs.`);
    }
  }
}

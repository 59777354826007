import {PromptDialog} from '../../common/dialogs/prompt-dialog.service';
import {ViewJobDialog} from './view-job-dialog/view-job-dialog.service';
import {ConfirmationDialog} from '../../common/dialogs/confirmation-dialog.service';
import {Injectable} from '@angular/core';
import {StudyStub} from '../../../generated/api-stubs';
import {LoadingDialog} from '../../common/dialogs/loading-dialog.service';

@Injectable()
export class GoToJob {
  constructor(
    private readonly promptDialog: PromptDialog,
    private readonly viewJobDialog: ViewJobDialog,
    private readonly confirmationDialog: ConfirmationDialog,
    private readonly studyStub: StudyStub,
    private readonly loadingDialog: LoadingDialog){
  }

  public async execute(
    tenantId: string,
    studyId: string,
    jobCount: number){

    const jobIndex = await this.promptForJobIndex(jobCount);
    if(jobIndex !== -1){
      await this.viewJobDialog.show(tenantId, studyId, `${studyId}-${jobIndex}`);
    }
  }

  public async loadAndPromptForJobIndex(
    tenantId: string,
    studyId: string): Promise<number> {

    const studyResult = await this.loadingDialog.showUntilFinished(
      this.studyStub.getStudyMetadata(tenantId, studyId),
      'Loading...');
    return await this.promptForJobIndex(studyResult.study.data.jobCount, true);
  }

  public async promptForJobIndex(
    jobCount: number, alwaysPrompt: boolean = false): Promise<number> {

    let jobIndex = 0;
    let promptResult = '';
    if(jobCount > 1 || alwaysPrompt){
      promptResult = await this.promptDialog.show<string>('Enter a job index between 0 and ' + (jobCount - 1) + ':', 'Go To Job');
      if(!promptResult){
        return -1;
      }
      jobIndex = +promptResult;
    }

    if(jobIndex < 0 || jobIndex >= jobCount || isNaN(jobIndex)){
      await this.confirmationDialog.show('Invalid job index "' + promptResult + '".', 'Invalid Request', 'OK', 'OK');
      return -1;
    }

    return jobIndex;
  }
}


import {Component, OnInit} from '@angular/core';
import {ConfigType} from '../configs/config-types';
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {StudyStagingArea, StudyState, StudyTypeItem} from './study-staging-area.service';
import {StudyStub, StudyType, StudyTypeDefinition, StudyTypeState,} from '../../../generated/api-stubs';
import {FormSubmissionHandler} from '../../common/forms/form-submission-handler.service';
import {FormSubmissionButton} from '../../common/forms/form-submission-button';
import {CanopyValidators} from '../../common/forms/canopy-validators.service';
import {Timer} from '../../common/timer.service';
import {StudyTypeLookup, StudyTypeMap} from '../studies/study-type-lookup.service';
import {DisplayableError, InputValidationError} from '../../common/errors/errors';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {CompareStagedInputToSource} from '../compare-staged-input-to-source.service';
import {Subscription} from 'rxjs';
import {GetSimVersion} from '../../common/get-sim-version.service';
import {LoadingDialog} from '../../common/dialogs/loading-dialog.service';
import {debounceTime} from 'rxjs/operators';
import {CommittedStudy, SubmitStudy} from '../../worksheets/submit-study.service';
import {StudyInput} from '../../worksheets/study-input';
import {ActiveWorksheets} from '../../worksheets/active-worksheets.service';
import {WorksheetViewModel} from '../../worksheets/worksheet-view-model';
import {AddStudyToWorksheetAndExtractInputs} from '../../worksheets/add-study-to-worksheet-and-extract-inputs.service';
import {
  SaveToStagingAreaHandler,
  SaveToStagingAreaHandlerFactory
} from '../configs/comparing/save-output-config-handlers/save-to-staging-area-handler';
import {ConfigOrConfigLoader} from '../configs/comparing/config-or-config-loader';
import { AuthenticationService } from '../../identity/state/authentication.service';

export const NO_STUDY_TYPES_ERROR_MESSAGE = 'You do not have any study types.';
export const UNKNOWN_STUDY_TYPE_ERROR_MESSAGE = 'Unknown study type.';

export const STUDIES_NOT_RUN_DIRECTLY: Set<StudyType> = new Set<StudyType>([
  StudyType.pacejkaCanopyConverter
]);

export function getFilteredStudyTypes(studyTypesList: ReadonlyArray<StudyTypeDefinition>){
  return studyTypesList
    .filter(v => v.state === StudyTypeState.enabled && !STUDIES_NOT_RUN_DIRECTLY.has(v.studyType));
}

@Component({
  selector: 'cs-study-staging-area',
  templateUrl: './study-staging-area.component.html',
  styleUrls: ['./study-staging-area.component.scss']
})
export class StudyStagingAreaComponent implements OnInit {

  public readonly StudyTypeState = StudyTypeState;

  public studyTypesList: StudyTypeItem[];
  public studyTypesLookup: StudyTypeMap;

  public errorMessage: string;

  public showErrors: boolean;
  public submitButton = new FormSubmissionButton('Commit', 'Committing...');
  public form: UntypedFormGroup;
  public name: UntypedFormControl = new UntypedFormControl('', [Validators.required, ...CanopyValidators.configNameValidators]);
  public studyInputs: StudyInputItem[] = [];
  public studyType: UntypedFormControl = new UntypedFormControl('', [Validators.required]);

  public state: StudyState;
  public committedStudy: CommittedStudy;

  public isTestUser: boolean;

  public formChangedSubscription: Subscription;

  public saveToStagingAreaHandler: SaveToStagingAreaHandler;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly studyStub: StudyStub,
    private readonly getSimVersion: GetSimVersion,
    private readonly formSubmissionHandler: FormSubmissionHandler,
    private readonly studyStagingArea: StudyStagingArea,
    private readonly timer: Timer,
    private readonly studyTypeLookup: StudyTypeLookup,
    private readonly router: Router,
    private readonly compareStagedInputToSource: CompareStagedInputToSource,
    private readonly loadingDialog: LoadingDialog,
    private readonly submitStudy: SubmitStudy,
    private readonly activeWorksheets: ActiveWorksheets,
    private readonly addStudyToWorksheetAndExtractInputs: AddStudyToWorksheetAndExtractInputs,
    private readonly saveToStagingAreaHandlerFactory: SaveToStagingAreaHandlerFactory,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {

  }

  public ngOnInit() {
    this.loadComponent();
  }

  public async loadComponent(){
    this.reloadSimVersionAndFormAndInputs();
    this.subscribeToPermanentEvents();
  }

  public get isLoaded(): boolean{
    return !!this.studyTypesList;
  }

  public get worksheets(): ReadonlyArray<WorksheetViewModel> {
    return this.activeWorksheets.items;
  }

  public async addToWorksheet(worksheet: WorksheetViewModel){
    try {
      await this.addStudyToWorksheetAndExtractInputs.execute(
        worksheet,
        {
          tenantId: this.committedStudy.tenantId,
          targetId: this.committedStudy.studyId,
        },
        true);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async reloadSimVersionAndFormAndInputs() {
    try {
      this.saveToStagingAreaHandler = this.saveToStagingAreaHandlerFactory.create();
      await this.getSimVersion.execute();
      await this.reloadFormAndInputs();
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async reloadFormAndInputs(){
    try {
      this.unsubscribeFromTransientEvents();

      let formData = {
        name: this.name,
        studyType: this.studyType,
      };

      let configs = await this.studyTypeLookup.getConfigTypeList();
      const oldStudyInputs = this.studyInputs;
      const timestampMap: { [configType: string ]: string } = {};
      for(let item of oldStudyInputs){
        timestampMap[item.configType.singularKey] = item.updatedTimestamp;
      }

      this.studyInputs = [];
      for(let configType of configs){
        let control = new UntypedFormControl('', Validators.compose([]));
        (formData as any)[configType.singularKey] = control;
        const newItem = new StudyInputItem(configType, control);
        newItem.updatedTimestamp = timestampMap[configType.singularKey];
        this.studyInputs.push(newItem);
      }

      this.form = this.formBuilder.group(formData);

      this.subscribeToTransientEvents();

      await this.reloadInputs();
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public get enabledStudyTypes(): ReadonlyArray<StudyTypeItem> {
    return getFilteredStudyTypes(this.studyTypesList);
  }

  public subscribeToTransientEvents(){
    this.formChangedSubscription = this.form.valueChanges.pipe(debounceTime(500)).subscribe(() => this.saveStudyInformation());
  }

  public unsubscribeFromTransientEvents(){
    if(this.formChangedSubscription){
      this.formChangedSubscription.unsubscribe();
      this.formChangedSubscription = undefined;
    }
  }

  public subscribeToPermanentEvents(){
    this.studyStagingArea.changed.subscribe(() => this.reloadInputs());
    this.studyType.valueChanges.subscribe(() => this.onStudyTypeChanged());

    // This is now covered by the sim version changing.
    //this.studyTypeLookup.changed.subscribe(() => this.reloadSimVersionAndFormAndInputs());

    this.getSimVersion.changed.subscribe(() => this.reloadFormAndInputs());
  }

  public async onStudyTypeChanged(){
    this.studyStagingArea.setStudyType(this.studyType.value);
    await this.reloadInputs();
  }

  public async reloadInputs() {
    try {
      this.isTestUser = this.authenticationService.isTestUser;
      this.errorMessage = undefined;
      this.state = this.studyStagingArea.get();

      this.showErrors = false;

      this.studyTypesLookup = await this.studyTypeLookup.getStudyTypeMap(this.getSimVersion.currentSimVersion);
      this.studyTypesList = await this.studyTypeLookup.getStudyTypeList(this.getSimVersion.currentSimVersion);

      let studyType = this.studyTypesLookup[this.state.studyType];
      if(studyType && studyType.state === StudyTypeState.disabled){
        let defaultStudyType = this.studyTypesList.find(v => v.state === StudyTypeState.enabled);
        this.studyType.setValue(defaultStudyType.studyType);
        return;
      }

      if(studyType) {
        for(let input of this.studyInputs){
          let inputState = this.state.inputs.find(v => v.configType === input.configType.singularKey);
          input.state = inputState;
          this.updateIfChanged(input.control, inputState);
          this.updateStudyInputIfChanged(inputState, input);

          input.isEnabled = false;
          input.isRequired = false;

          let inputDefinition = studyType.inputs.find(v => input.configType.singularKey === v.configType);
          if(inputDefinition){
            input.isEnabled = true;
            input.isRequired = inputDefinition.isRequired;
          }
        }
      }

      this.updateIfChanged(this.studyType, this.state.studyType);
      this.updateIfChanged(this.name, this.state.studyName);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public canEditSource(studyInputItem: StudyInputItem){
    let studyInput = studyInputItem.state;
    const userData = this.authenticationService.userDataSnapshot;
    return studyInput && userData && studyInput.userId && studyInput.userId === userData.sub;
  }

  public canCompareToSource(studyInputItem: StudyInputItem){
    let studyInput = studyInputItem.state;
    return studyInput && studyInput.configId;
  }

  public canViewReferencedStudyJob(studyInputItem: StudyInputItem){
    let studyInput = studyInputItem.state;
    return studyInput && studyInput.data && studyInput.data.source && studyInput.data.source.studyId;
  }

  public editSource(studyInputItem: StudyInputItem){
    let studyInput = studyInputItem.state;
    const userData = this.authenticationService.userDataSnapshot;
    this.router.navigate(['/configs', studyInput.configType, userData.tenant, studyInput.configId, 'edit']);
  }

  public async viewReferencedStudyJob(studyInputItem: StudyInputItem){
    try{
      await this.loadingDialog.show(async cb => {
        cb.setStatus('Please wait...');
        let studyInput = studyInputItem.state;
        const userData = this.authenticationService.userDataSnapshot;
        let reference = studyInput.data.source;
        let tenantId = reference.tenantId || userData.tenant;
        let studyId = reference.studyId;
        let jobIndex = reference.jobIndex || 0;
        let studyMetadata = await this.studyStub.getStudyMetadata(tenantId, studyId);
        if(studyMetadata.study.data.jobCount === 1){
          this.router.navigate(['/studies', tenantId, studyId]);
        } else{
          this.router.navigate(['/studies', tenantId, studyId, 'jobs', jobIndex]);
        }
      });
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async compareToSource(studyInputItem: StudyInputItem){
    try{
      let studyInput = studyInputItem.state;
      await this.compareStagedInputToSource.execute(studyInput);
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public updateStudyInputIfChanged(studyInput: StudyInput, studyInputItem: StudyInputItem){
    if(!studyInput){
      studyInputItem.updatedTimestamp = undefined;
    } else if(studyInput.timestamp !== studyInputItem.updatedTimestamp){
      this.setUpdated(studyInputItem);

      studyInputItem.updatedTimestamp = studyInput.timestamp;
    }
  }

  public async setUpdated(studyInputItem: StudyInputItem){
    try {
      studyInputItem.isUpdated = true;
      await this.timer.delay(1000);
      studyInputItem.isUpdated = false;
    } catch(error){
      console.warn('Failed to set study input item as updated');
      console.warn(error);
    }
  }

  public clear() {
    try {
      this.errorMessage = undefined;

      let current = this.studyStagingArea.get();
      this.studyStagingArea.clear();
      if(current){
        this.studyStagingArea.setStudyInformation(current.studyType, '');
      }

      this.dismissCommittedStudy();
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public saveStudyInformation() {
    try {
      this.errorMessage = undefined;

      if(this.studyType.value !== this.studyStagingArea.state.studyType
        || this.name.value !== this.studyStagingArea.state.studyName) {
        this.studyStagingArea.setStudyInformation(this.studyType.value, this.name.value);
      }
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public dismissCommittedStudy(){
    this.committedStudy = undefined;
  }

  public incrementStudyName(name: string): string {
    let newName = name.replace(/ \d+$/, n => ' ' + <any>(1 + parseInt(n, 10)));
    if(newName === name){
      return name + ' 2';
    }

    return newName;
  }

  public removeInput(input: StudyInputItem){
    this.studyStagingArea.remove(input.configType.singularKey);
  }

  public async onSubmit() {
    this.showErrors = true;
    this.dismissCommittedStudy();
    await this.formSubmissionHandler.execute(this.submit, this.form, this.submitButton, this);
  }

  private async submit() {
    if(this.studyTypesList.length === 0){
      throw new InputValidationError(NO_STUDY_TYPES_ERROR_MESSAGE);
    }

    let studyTypeInformation = this.studyTypesLookup[this.studyType.value];
    if(!studyTypeInformation) {
      throw new DisplayableError(UNKNOWN_STUDY_TYPE_ERROR_MESSAGE, 'Unknown study type: ' + this.studyType.value);
    }

    const studyName = this.name.value;
    this.committedStudy = await this.submitStudy.execute(
      studyName,
      studyTypeInformation,
      (configType, targetSimVersion) => this.studyStagingArea.getInput(configType, targetSimVersion));

    this.name.setValue(this.incrementStudyName(studyName), {});
  }

  public getConfigOrConfigLoader(input: StudyInputItem){
    return () => new ConfigOrConfigLoader('staged', input.state, undefined);
  }

  private updateIfChanged(control: UntypedFormControl, value: any){
    if(control.value !== value){
      control.setValue(value, {});
    }
  }
}

class StudyInputItem {
  constructor(
    public configType: ConfigType,
    public control: UntypedFormControl){}

  public isEnabled: boolean;
  public isRequired: boolean;
  public isUpdated: boolean;
  public updatedTimestamp: string;
  public state: StudyInput;
}

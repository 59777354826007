
/**
 * Convert a sim version string to a number which can be compared.
 * This assumes that numeric versions are prefixed with '1.'.
 * @param simVersion The version string.
 * @returns The version number.
 */
export function simVersionToNumber(simVersion: string): number {
  if (simVersion && simVersion.startsWith('1.')) {
    return +simVersion.substring(2);
  }

  return 0;
}


<div class="container-fluid">
  <cs-error-message [message]="errorMessage"></cs-error-message>
</div>

<div class="worksheet-container" id="{{containerElementId}}">
  @if (worksheetViewModel) {
    <div class="container-fluid">
      <div class="card">
        <h1 class="card-header">
          <span class="worksheet-name">{{worksheetViewModel.name}}</span>
          <div class="card-header-float-right">
            <cs-support-session-launcher
              [tenantId]="tenantId"
              [documentId]="worksheetId"
              [documentType]="'config'" [documentSubType]="'worksheet'"
              [supportSession]="worksheetViewModel.supportSession"
              (supportSessionChanged)="worksheetViewModel.supportSession = $event">
            </cs-support-session-launcher>
          </div>
        </h1>
        <div class="card-body">
          <p [ngClass]="{ 'last-item': !hasProperties && !hasNotes }">
            @if (!isInstanceInDock) {
              <a class="btn btn-smart btn-secondary back-button"
                [routerLink]="isCurrentTenant ? ['/worksheets'] : ['/support-sessions']"
                >
                <i class="fa fa-caret-left"></i>Back
              </a>
            }
            @if (!isInstanceInDock) {
              <button class="btn btn-secondary btn-smart dock-worksheet-button"
                [disabled]="isWorksheetInDock"
                (click)="dockWorksheet()"><i class="fa fa-window-minimize"></i>Dock</button>
              }
              @if (isInstanceInDock) {
                <button class="btn btn-secondary btn-smart undock-worksheet-button"
                  (click)="undockWorksheet(true)"><i class="fa fa-window-maximize"></i>Undock</button>
                }
                @if (isInstanceInDock) {
                  <button class="btn btn-secondary btn-smart close-worksheet-button"
                    (click)="undockWorksheet(false)"><i class="fa fa-times"></i>Close</button>
                  }
                  @if (!isOwner) {
                    <button class="btn btn-secondary btn-smart synchronize-button"
                      (click)="reload()"><i class="fa fa-refresh"></i>Synchronize</button>
                    }
                    @if (isOwner) {
                      <button class="btn btn-secondary btn-smart edit-worksheet-metadata-button"
                        (click)="editMetadata()"><i class="fa fa-pencil"></i>Edit Metadata</button>
                      }
                      <button class="btn btn-secondary btn-smart edit-worksheet-labels-button"
                        (click)="editLabels()"><i class="fa fa-align-justify"></i>Edit Labels</button>
                        @if (isOwner) {
                          <button class="btn btn-secondary btn-smart undo-button"
                            (click)="undo()" [disabled]="!worksheetViewModel.history.canUndo">
                            <i class="fa fa-undo"></i>
                            Undo
                            <small class="undo-count">{{undoBufferSizeString}}</small>
                          </button>
                        }
                        @if (isOwner) {
                          <button class="btn btn-secondary btn-smart redo-button"
                            (click)="redo()" [disabled]="!worksheetViewModel.history.canRedo">
                            <i class="fa fa-repeat"></i>
                            Redo
                            <small class="undo-count">{{redoBufferSizeString}}</small>
                          </button>
                        }
                        <a href="https://support.canopysimulations.com/hc/en-gb/articles/5650092385181-Worksheets" class="btn btn-secondary btn-smart" target="_blank"><i class="fa fa-book"></i>Help</a>
                      </p>
                      @if (worksheetViewModel.notes) {
                        <pre [ngClass]="{ 'last-item': !hasProperties }" class="raw-json worksheet-notes">{{worksheetViewModel.notes}}</pre>
                      }
                      <cs-view-custom-properties [customProperties]="worksheetViewModel.properties"></cs-view-custom-properties>
                    </div>
                  </div>
                </div>
              }
              <div class="worksheet-table">
                @if (isLoading && !worksheetViewModel) {
                  <cs-loader class="worksheet-loading-spinner"></cs-loader>
                }
                @if (worksheetViewModel) {
                  <cs-worksheet-table [worksheet]="worksheetViewModel" [isWorksheetInDock]="isWorksheetInDock"></cs-worksheet-table>
                }
                @if (worksheetViewModel?.maximumRowsReached) {
                  <div class="alert alert-warning">Maximum rows reached.</div>
                }
                @if (isLoading && worksheetViewModel) {
                  <cs-loader class="worksheet-saving-spinner">Syncing Worksheet...</cs-loader>
                }
              </div>
            </div>

import { FilteredStudyExplorationAndScalarDataCache } from './filtered-study-exploration-and-scalar-data-cache';
import {
  LoadChannelDataFromExplorationMap,
  LoadChannelDataFromExplorationMapOptions, LoadedExplorationMapChannelData
} from './load-channel-data-from-exploration-map';
import {
  StudyExplorationAndScalarData
} from './load-study-exploration-and-scalar-data';

/**
 * A cache for the channel data loaded from an exploration.
 */
export class StudySourceLoaderCache {

  /**
   * The cached data. Stored as a promise so that multiple `get` requests can be made concurrently.
   */
  private cache?: Promise<LoadedData>;

  /**
   * The last data that was loaded, stored outside of a promise for synchronous access.
   */
  private lastData?: LoadedData;

  /**
   * Creates a new cache for the channel data loaded from an exploration.
   * @param explorationAndScalarDataCache The cache for the exploration and scalar data.
   * @param loadChannelDataFromExplorationMap The service for loading channel data from an exploration map.
   * @param options The options for loading the channel data.
   */
  constructor(
    private explorationAndScalarDataCache: FilteredStudyExplorationAndScalarDataCache,
    private loadChannelDataFromExplorationMap: LoadChannelDataFromExplorationMap,
    private options?: LoadChannelDataFromExplorationMapOptions) {

      // We subscribe to changes to the exploration and scalar data cache, which can occur
      // when the user removed jobs from the exploration through the UI.
      explorationAndScalarDataCache.changed.subscribe(() => this.cache = this.loadStudyData());
  }

  /**
   * Gets the loaded channel data. This will populate the cache if it is not already populated.
   * @returns The loaded channel data.
   */
  public get(): Promise<LoadedData> {
    if (!this.cache) {
      this.cache = this.loadStudyData();
    }

    return this.cache;
  }

  /**
   * Gets the last loaded channel data. This will NOT populate the cache if it is not already populated.
   * @returns The loaded channel data, or undefined.
   */
  public getSynchronous(): LoadedData | undefined {
    return this.lastData;
  }

  /**
   * Loads the study data.
   * @returns The loaded study data.
   */
  private async loadStudyData(): Promise<LoadedData> {
    let explorationData = this.explorationAndScalarDataCache.get();
    let inputDimensionData = await this.loadChannelDataFromExplorationMap.execute(this.options);

    let inputDimensionMap = inputDimensionData.reduce<BasicChannelDataMap>((p, c) => {
      p[c.fullName] = c;
      return p;
    }, {});

    this.lastData = {
      explorationData,
      inputDimensionList: inputDimensionData,
      inputDimensionMap
    };

    return this.lastData;
  }
}

/**
 * A map from channel name to channel data loaded from and exploration.
 */
export interface BasicChannelDataMap {
  [name: string]: LoadedExplorationMapChannelData;
}

/**
 * The data loaded from an exploration.
 */
export interface LoadedData {
  readonly explorationData: StudyExplorationAndScalarData;
  readonly inputDimensionList: ReadonlyArray<LoadedExplorationMapChannelData>;
  readonly inputDimensionMap: BasicChannelDataMap;
}

<div class="container-fluid" id="tenant-settings-channel-whitelists-component">
  <div class="card">
    <h1 class="card-header">Tenant Channel Whitelist</h1>
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          @if (!settingsResult && !errorMessage) {
            <cs-loader></cs-loader>
          }
          <cs-error-message [message]="errorMessage"></cs-error-message>
          @if (settingsResult) {
            <form (ngSubmit)="onSubmit()" [formGroup]="form">
              <div class="mb-3">
                <p>
                  A list of channels which are always whitelisted when any of your encrypted components are used,
                  including those you have distributed to other Canopy tenants.
                </p>
                <p>
                  Channels in this whitelist will be output from the simulation even if the encrypted components
                  would normally cause them to be hidden.
                </p>
                <p>
                  See the <a [href]="ENCRYPTION_DOCUMENTATION_URL" target="_blank">encryption documentation</a> for more information.
                </p>
                <p>
                  This list can be either comma or newline separated. Wildcards are allowed.
                </p>
                <textarea id="channels-input" class="form-control monospace-text-area" formControlName="channels"></textarea>
              </div>
              @if (!channels.pristine && channels.hasError('channelList')) {
                <p class="text-danger">
                  Invalid characters in channel list. Only alphanumeric characters, commas, whitespace, % and asterisks are allowed.
                </p>
              }
              @if (saveSuccessful) {
                <div id="success-message" class="alert alert-success">
                  Saved.
                </div>
              }
              <button id="create-submit" type="submit" class="btn btn-smart btn-primary" [disabled]="submitButton.isSubmitting || !isTenantAdministrator">{{submitButton.text}}</button>
              @if (!isTenantAdministrator) {
                <small>
                  Only tenant administrators can modify this list.
                </small>
              }
            </form>
          }
        </div>
      </div>
    </div>
  </div>
</div>

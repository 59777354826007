<div  class="row">
  <div class="col-12">
    <h2>Map Channel Names</h2>
    <cs-error-message [message]="errorMessage"></cs-error-message>

    @if (!errorMessage && (!mappings || isSaving || !mappingControls)) {
      <cs-loader></cs-loader>
    }

    @if (form) {
      <form (ngSubmit)="onSubmit()" [formGroup]="form">
        @if (mappings.length || mappingControls.length) {
          <p>
            Channel mapping preferences are saved for all users.
          </p>
        }
        @if (!errorMessage) {
          <p>
            @if (mappings.length || mappingControls.length) {
              <button id="channel-mappings-submit" type="submit" class="btn btn-smart btn-primary" [disabled]="submitButton.isSubmitting"
              >{{submitButton.text}}</button>
            }
            <button id="channel-mappings-skip" type="button" class="btn btn-smart btn-secondary" [disabled]="submitButton.isSubmitting" (click)="skip()">Skip</button>
          </p>
        }
        @if (!mappingControls.length) {
          <p>
            No mappings specified.
          </p>
        }
        @for (item of mappingControls; track item; let i = $index) {
          <div class="d-flex gap-1 mb-2">
            <input id="{{item.fromId}}-input" placeholder="From" type="text" class="form-control"
              [formControl]="item.from">
            <input id="{{item.toId}}-input" placeholder="To" type="text" class="form-control"
              [formControl]="item.to">
            <button type="button" class="btn btn-smart btn-secondary remove-channel-name-mapping-button" id="remove-channel-name-mapping-button-{{i}}" (click)="remove(i)">Remove</button>
            @if (item.from.hasError('minlength')) {
              <p class="text-danger">
                From is too short.
              </p>
            }
            @if (item.from.hasError('maxlength')) {
              <p class="text-danger">
                From is too long.
              </p>
            }
            @if (item.to.hasError('minlength')) {
              <p class="text-danger">
                To is too short.
              </p>
            }
            @if (item.to.hasError('maxlength')) {
              <p class="text-danger">
                To is too long.
              </p>
            }
          </div>
        }
        <p>
          @if (mappingControls) {
            <button type="button" class="btn btn-smart btn-secondary add-button" id="add-channel-name-mapping-button" (click)="add()">
              Add Channel Name Mapping
            </button>
          }
        </p>
      </form>
    }
  </div>
</div>

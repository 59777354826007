import * as d3 from '../../d3-bundle';
import {
  ChannelScalarDataAndMappings,
  ChannelScalarDataMap
} from './channel-scalar-data-and-mappings';
import {ExplorationMap} from './exploration-map';
import {SimType} from '../../sim-type';
import {OrderedUniqueIndices} from '../../ordered-unique-indices';
import {ChannelNameMapBuilder} from './channel-name-map';
import {ChannelNameStyle} from './channel-name-style';
import {getFullyQualifiedChannelName} from '../../get-fully-qualified-channel-name';
import {Utilities} from '../../utilities';
import {SingleScalarResult, UrlFileLoader} from '../../url-file-loader';

/**
 * A service that gets the scalar data from the individual jobs.
 * If, for some reason, the post-processor died and didn't produce the consolidated files,
 * we can still attempt to load the data the slow way by loading it from the individual jobs,
 * which is what this service does.
 */
export class GetScalarDataFromIndividualJobs {

  constructor(private urlFileLoader: UrlFileLoader){
  }

  /**
   * Gets the scalar data from the individual jobs.
   * @param studyId The study ID.
   * @param simTypes The sim types.
   * @param map The exploration map.
   * @returns The scalar data and mappings.
   */
  public async execute(studyId: string, simTypes: ReadonlyArray<SimType>, map: ExplorationMap): Promise<ChannelScalarDataAndMappings> {
    let jobCount = map.coordinates.length;
    let loadedScalarResults = await this.loadAllScalarResultsForStudy(studyId, jobCount, simTypes);
    let dataIndexToJobIndexMap = d3.range(loadedScalarResults.length);

    this.removeFailedPoints(loadedScalarResults, simTypes, map, dataIndexToJobIndexMap);

    jobCount = map.coordinates.length;

    // collect all channels together in one object
    let channelsByName: ChannelScalarDataMap = {};
    let defaultChannelMapBuilder = new ChannelNameMapBuilder(ChannelNameStyle.FullyQualified, simTypes);
    for (let jobIndex = 0; jobIndex < jobCount; jobIndex++) {
      for (let simTypeIndex = 0; simTypeIndex < simTypes.length; simTypeIndex++) {
        let loadedScalarResultsForJobAndSimType = loadedScalarResults[jobIndex * simTypes.length + simTypeIndex];
        if(!loadedScalarResultsForJobAndSimType){
          continue;
        }
        let simType = simTypes[simTypeIndex];
        for (let scalarResult of loadedScalarResultsForJobAndSimType){
          this.processScalarResult(scalarResult, simType, defaultChannelMapBuilder, channelsByName);
        }
      }
    }

    return new ChannelScalarDataAndMappings(
      channelsByName,
      defaultChannelMapBuilder.getMap(),
      {},
      dataIndexToJobIndexMap);
  }

  /**
   * For each job and sim type, loads the scalar results file.
   * @param studyId The study ID.
   * @param jobCount The number of jobs.
   * @param simTypes The sim types.
   * @returns The scalar results.
   */
  private async loadAllScalarResultsForStudy(studyId: string, jobCount: number, simTypes: ReadonlyArray<SimType>) {
    let loadedScalarResultTasks: Promise<SingleScalarResult[]>[] = [];
    for (let jobIndex = 0; jobIndex < jobCount; jobIndex++) {
      for (let simType of simTypes) {
        loadedScalarResultTasks.push(this.urlFileLoader.loadScalarResultsForSim(studyId, jobIndex, simType));
      }
    }
    return await Promise.all(loadedScalarResultTasks);
  }

  /**
   * Removes jobs where every sim type failed for a job.
   * @param loadedScalarResults The loaded scalar results.
   * @param simTypes The sim types.
   * @param map The exploration map.
   * @param dataIndexToJobIndexMap The data index to job index map.
   */
  private removeFailedPoints(loadedScalarResults: SingleScalarResult[][], simTypes: ReadonlyArray<SimType>, map: ExplorationMap, dataIndexToJobIndexMap: number[]) {
    let failedPoints = new OrderedUniqueIndices();
    for (let pointIndex = 0; pointIndex < map.coordinates.length; pointIndex++) {
      let allSimTypesFailed = true;
      for (let simTypeIndex = 0; simTypeIndex < simTypes.length; simTypeIndex++) {
        if (loadedScalarResults[simTypeIndex + simTypes.length * pointIndex]) {
          allSimTypesFailed = false;
        }
      }
      if(allSimTypesFailed){
        failedPoints.add(pointIndex);
      }
    }
    let failedPointValues = failedPoints.get();
    for (let i = failedPointValues.length - 1; i >= 0; i--) {
      loadedScalarResults.splice(simTypes.length * failedPointValues[i], simTypes.length);
    }
    this.stripFromMapAndIndexMap(failedPoints, map, dataIndexToJobIndexMap);
  }

  /**
   * Aggregates a scalar result by channel name. If the channelsByName map doesn't have a channel entry for this
   * scalar result then one is created, otherwise the value is added to the existing channel entry.
   * @param scalarResult The scalar result.
   * @param simType The simulation type.
   * @param defaultChannelMapBuilder The default channel map builder.
   * @param channelsByName The channels by name map.
   */
  private processScalarResult(
    scalarResult: SingleScalarResult,
    simType: SimType,
    defaultChannelMapBuilder: ChannelNameMapBuilder,
    channelsByName: ChannelScalarDataMap) {
    let genericName = scalarResult.name;
    let fullName = getFullyQualifiedChannelName(genericName, simType);
    defaultChannelMapBuilder.add(genericName, fullName, simType);

    let channelScalarData = channelsByName[fullName];
    if (!channelScalarData) {
      let values = [scalarResult.value];
      delete scalarResult.value;
      channelScalarData = {
        fullName,
        genericName,
        simType,
        values,
        units: scalarResult.units,
        description: scalarResult.description
      };

      channelsByName[fullName] = channelScalarData;
    } else {
      channelScalarData.values.push(scalarResult.value);
    }
  }

  /**
   * Strips the bad indices from the map and index map.
   * @param badIndicesBuilder The bad indices builder.
   * @param map The exploration map.
   * @param dataIndexToJobIndexMap The data index to job index map.
   */
  private stripFromMapAndIndexMap(badIndicesBuilder: OrderedUniqueIndices, map: ExplorationMap, dataIndexToJobIndexMap: number[]) {
    Utilities.strip(
      badIndicesBuilder,
      map.coordinates,
      map.jobNames,
      map.rCoordinates,
      map.indexMatrix,
      dataIndexToJobIndexMap);
  }
}

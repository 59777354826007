
import {UntypedFormGroup} from '@angular/forms';
export function setFormPristine(form: UntypedFormGroup, value: boolean = true) {
  if(value){
    form.markAsPristine();
  } else {
    form.markAsDirty();
  }

  /*
  // ORIGINAL HACK
  // Remove when this is closed: https://github.com/angular/angular/issues/4933
  (<any>form)._pristine = value;
  */
}

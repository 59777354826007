import * as d3 from '../../d3-bundle';
import { LinePlotViewerSettings } from './line-plot-viewer-settings';
import { DataRenderer, LineViewerGetInterpolatedChannelValueAtDomainValue } from './data-renderer';
import { NavigationStationViewer } from '../../navigation-station/navigation-station-viewer';
import { SiteHooks } from '../../site-hooks';
import { ChannelNameStyle } from '../channel-data-loaders/channel-name-style';
import { MultiPlotViewerBase } from '../multi-plot-viewer-base/multi-plot-viewer-base';
import { IMultiPlotLayout } from '../data-pipeline/types/i-multi-plot-layout';
import { MultiPlotViewerSettings } from '../multi-plot-viewer-base/multi-plot-viewer-settings';
import { DomainSnapBehaviour, MultiPlotDataRendererBase } from '../multi-plot-viewer-base/multi-plot-data-renderer-base';
import { SharedState } from '../shared-state';
import { ZoomType } from '../multi-plot-viewer-base/zoom-renderer';
import { DataPipeline } from '../data-pipeline/data-pipeline';

export const POINT_MULTI_PLOT_VIEWER_TYPE = 'pointMultiPlotViewer';

/**
 * A line plot viewer which extends the multi plot viewer.
 */
export class LinePlotViewer extends MultiPlotViewerBase implements NavigationStationViewer {

  /**
   * The type of curve to use when drawing the lines. Defaults to a linear interpolation line.
   */
  private readonly overrideLineCurve?: d3.CurveFactory;

  /**
   * Create a new instance of the line plot viewer.
   * @param primaryDomainName The primary domain name.
   * @param layout The chart layout.
   * @param channelNameStyle The channel name style.
   * @param dataPipeline The data pipeline.
   * @param sharedState The shared state.
   * @param siteHooks The site hooks.
   * @param interpolator The interpolator to use for the data renderer.
   */
  constructor(
    primaryDomainName: string,
    layout: IMultiPlotLayout,
    channelNameStyle: ChannelNameStyle,
    dataPipeline: DataPipeline,
    sharedState: SharedState,
    siteHooks: SiteHooks,
    private interpolator: LineViewerGetInterpolatedChannelValueAtDomainValue) {
    super(
      primaryDomainName,
      layout,
      channelNameStyle,
      dataPipeline,
      sharedState,
      siteHooks);

    // The layout is allowed to override the line curve type.
    let overrideLineCurveType = (<any>this.layout).overrideLineCurve;
    if (overrideLineCurveType) {
      this.overrideLineCurve = (<any>d3)[overrideLineCurveType];
    }
  }

  /**
   * Create a new instance of the line plot viewer.
   * @param primaryDomainName The primary domain name.
   * @param layout The chart layout.
   * @param channelNameStyle The channel name style.
   * @param sharedState The shared state.
   * @param siteHooks The site hooks.
   * @returns A new instance of the line plot viewer.
   */
  public static createLinePlotViewer(
    primaryDomainName: string,
    layout: IMultiPlotLayout,
    channelNameStyle: ChannelNameStyle,
    sharedState: SharedState,
    siteHooks: SiteHooks): LinePlotViewer {

    let interpolator = new LineViewerGetInterpolatedChannelValueAtDomainValue();
    return new LinePlotViewer(
      primaryDomainName,
      layout,
      channelNameStyle,
      DataPipeline.create(primaryDomainName, siteHooks, sharedState.sourceLoaderSet, channelNameStyle, interpolator),
      sharedState,
      siteHooks,
      interpolator);
  }

  /**
   * Get the CSS class for the line plot viewer.
   * @returns The CSS class for the line plot viewer.
   */
  protected getCssClass(): string {
    return 'line-plot-viewer';
  }

  /**
   * Create the settings for the line plot viewer.
   * @param sourceCount The number of sources.
   * @returns The settings for the line plot viewer.
   */
  createSettings(sourceCount: number): MultiPlotViewerSettings {
    return LinePlotViewerSettings.build(sourceCount);
  }

  /**
   * Create the data renderer for the line plot viewer.
   * @returns The data renderer for the line plot viewer.
   */
  createDataRenderer(): MultiPlotDataRendererBase {
    let dataRenderer = DataRenderer.create(this.interpolator);

    // Set the curve type and domain snap behaviour of the data renderer.
    if (!this.overrideLineCurve || this.overrideLineCurve === d3.curveLinear) {
      dataRenderer.domainSnapBehaviour(DomainSnapBehaviour.linearInterpolation);
    } else {
      dataRenderer.curve(this.overrideLineCurve);
      dataRenderer.domainSnapBehaviour(DomainSnapBehaviour.nearest);
    }

    return dataRenderer;
  }

  /**
   * Called when the viewer is built.
   */
  protected onBuilt() {
    // Call the super class implementation.
    super.onBuilt();

    // Set the zoom type to x.
    if (this.zoomRenderer) {
      this.zoomRenderer.zoomType(ZoomType.x);
    }
  }
}

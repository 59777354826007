import {Injectable} from '@angular/core';
import {ConfigStub, DocumentSubType} from '../../../generated/api-stubs';
import {
  ChartId, SavedChartResult, SimVersionChartId,
  UserChartId
} from './canopy-site-hooks.service';
import {ConfigLoaderDialog} from '../configs/config-loader-dialog/config-loader-dialog.service';
import {ConfigLoaderUtilities} from '../configs/config-loader-utilities.service';
import {ConfigTypeLookup} from '../configs/config-types';
import {CanopyError} from '../../common/errors/errors';
import {GetSimVersion} from '../../common/get-sim-version.service';
import {SaveAsDialog} from '../../common/dialogs/save-as-dialog.service';
import {StudyInput} from '../../worksheets/study-input';


@Injectable()
export class ChartRepositoryFactory {
  constructor(
    private readonly chartLoaderUtilities: ConfigLoaderUtilities,
    private readonly configStub: ConfigStub,
    private readonly chartLoaderDialog: ConfigLoaderDialog,
    private readonly getSimVersion: GetSimVersion,
    private readonly saveAsDialog: SaveAsDialog){
  }

  public create(tenantId: string){
    return new ChartRepository(
      tenantId,
      this.getSimVersion.currentSimVersion,
      this.chartLoaderUtilities,
      this.configStub,
      this.chartLoaderDialog,
      this.saveAsDialog);
  }
}

export class ChartRepository {

  constructor(
    private readonly tenantId: string,
    private readonly simVersion: string,
    private readonly configLoaderUtilities: ConfigLoaderUtilities,
    private readonly configStub: ConfigStub,
    private readonly configLoaderDialog: ConfigLoaderDialog,
    private readonly saveAsDialog: SaveAsDialog){
  }

  public async loadChart(chartType: DocumentSubType, chartId?: ChartId): Promise<StudyInput> {
    let simVersionChartId = <SimVersionChartId>chartId;
    let userChartId = <UserChartId>chartId;

    if(!chartId){
      let result = await this.configLoaderDialog.loadConfig(
        chartType,
        this.simVersion);

      if(!result){
        return undefined;
      }

      return result.config;
    } else if(typeof simVersionChartId === 'string') {
      let configType = ConfigTypeLookup.get(chartType);
      try {
        let result = await this.configLoaderUtilities.loadSimVersionConfig(
          configType,
          this.simVersion,
          simVersionChartId);

        return result;
      } catch(error){
        if(error.response && error.response.status === 404){
          return undefined;
        } else{
          throw error;
        }
      }
    } else if(userChartId.userId){
      let result = await this.configLoaderUtilities.loadUserConfig(
        this.tenantId,
        userChartId.userId,
        userChartId.configId,
        this.simVersion);

      return result;
    }

    throw new CanopyError('Unknown chart ID: ' + JSON.stringify(chartId));
  }

  public async saveChart(chartType: DocumentSubType, configId: string, name: string, chartConfig: any) {
    await this.configStub.putConfig(
      this.tenantId,
      configId,
      {
        name,
        configType: chartType,
        config: chartConfig,
        simVersion: this.simVersion
      });
  }

  public async saveChartAs(chartType: DocumentSubType, chartConfig: any): Promise<SavedChartResult> {
    let result = await this.saveAsDialog.showForContent(
      '',
      chartType,
      this.simVersion,
      undefined,
      chartConfig,
      undefined);

    if(!result){
      return undefined;
    }

    return {
      viewerId: {
        userId: result.userId,
        configId: result.configId,
      },
      name: result.config.name
    };
  }
}

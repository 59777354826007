import * as d3 from './d3-bundle';
import { isUndefined } from './is-defined';

// Functions in this file wrap their D3 equivalents but guarantee the numeric results are not undefined.

/**
 * Equivalent to d3.min but guarantees the result is not undefined.
 * @param array The array to find the minimum of.
 * @returns The minimum value in the array.
 */
export function minStrict(array: ReadonlyArray<number>): number {
  const result = d3.min(array);
  if (isUndefined(result)) {
    return NaN;
  }

  return result;
}

/**
 * Equivalent to d3.max but guarantees the result is not undefined.
 * @param array The array to find the maximum of.
 * @returns The maximum value in the array.
 */
export function maxStrict(array: ReadonlyArray<number>): number {
  const result = d3.max(array);
  if (isUndefined(result)) {
    return NaN;
  }

  return result;
}

/**
 * Equivalent to d3.mean but guarantees the result is not undefined.
 * @param array The array to find the mean of.
 * @returns The mean value in the array.
 */
export function meanStrict(array: ReadonlyArray<number>): number {
  const result = d3.mean(array);
  if (isUndefined(result)) {
    return NaN;
  }

  return result;
}

/**
 * Equivalent to d3.minIndex but guarantees the result is not undefined.
 * @param array The array to find index of the minimum value.
 * @returns The index of the minimum value in the array.
 */
export function minIndexStrict(array: ReadonlyArray<number>): number {
  const result = d3.minIndex(array);
  if (isUndefined(result)) {
    return NaN;
  }

  return result;
}

/**
 * Equivalent to d3.maxIndex but guarantees the result is not undefined.
 * @param array The array to find index of the maximum value.
 * @returns The index of the maximum value in the array.
 */
export function maxIndexStrict(array: ReadonlyArray<number>): number {
  const result = d3.maxIndex(array);
  if (isUndefined(result)) {
    return NaN;
  }

  return result;
}

/**
 * Equivalent to d3.extent but guarantees the result is not undefined.
 * @param array The array to find the extents of.
 * @returns The extents of the array.
 */
export function extentStrict(array: ReadonlyArray<number>): [number, number] {
  const result = d3.extent(array);
  if (!areValuesDefined(result)) {
    return [NaN, NaN];
  }

  return result;
}

/**
 * Returns true if both values in the array are defined.
 * @param array The array to check.
 * @returns True if both values in the array are defined, false otherwise.
 */
function areValuesDefined(array: [number, number] | [undefined, undefined]): array is [number, number] {
  return !isUndefined(array[0]) && !isUndefined(array[1]);
}

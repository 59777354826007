
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {CanopyJson} from '../../common/canopy-json.service';
import {ChartEditorChannelSource, ChartEditorDialogData} from './chart-editor-dialog.service';
import {Injectable} from '@angular/core';
import {ChannelNameStyle} from '../../visualizations/viewers/channel-data-loaders/channel-name-style';
import {
  SimType
} from '../../../generated/api-stubs';

@Injectable()
export class ChartEditorDialogSessionFactory {
  constructor(
    private json: CanopyJson,
    private getFriendlyErrorAndLog: GetFriendlyErrorAndLog
  ){}

  public create(data: ChartEditorDialogData): ChartEditorDialogSession{
    return new ChartEditorDialogSession(
      data,
      this.json,
      this.getFriendlyErrorAndLog);
  }
}

export const GENERIC_DESCRIPTION_SUFFIX = ' for any available simulation.';

@Injectable()
export class ChartEditorDialogSession {
  public errorMessage: string;
  public data: ChartSchema;
  public channelMetadata: Channel[];
  public showChannelSelection: boolean;
  public addChannelPaneIndex: number;
  public flattenView: boolean;

  constructor(
    private dialog: ChartEditorDialogData,
    private json: CanopyJson,
    private getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  public async load() {
    try {
      this.errorMessage = undefined;

      this.flattenView = this.dialog.flattenView;

      let allChannels: Channel[] = [];
      switch(this.dialog.channelSource){
        case ChartEditorChannelSource.ScalarResults:
          for(let simType of this.dialog.simTypes){
            let currentMetadata = <ScalarMetadataChannel[]>await this.dialog.fileLoader.loadScalarResultsForSim(
              this.dialog.studyId,
              this.dialog.jobIndex || 0,
              simType);
            if(currentMetadata){
              for(let metadata of currentMetadata){
                this.addChannelFromMetadata(simType, allChannels, metadata);
              }
            }
          }
          break;

        case ChartEditorChannelSource.VectorResults:
          for(let simType of this.dialog.simTypes){
            let currentMetadata = <VectorMetadataChannel[]>await this.dialog.fileLoader.loadVectorMetadata(
              this.dialog.studyId,
              this.dialog.jobIndex || 0,
              simType);
            if(currentMetadata) {
              let filteredMetadata = currentMetadata.filter(v => this.filterChannel(this.dialog.data, v));
              for(let metadata of filteredMetadata){
                this.addChannelFromMetadata(simType, allChannels, metadata);
              }
            }
          }
          break;
      }

      this.channelMetadata = allChannels;

      this.channelMetadata.sort((a: Channel, b: Channel) => {
        if(a.name !== b.name){
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        }

        if(!a.simType){
          return -1;
        }

        return this.dialog.simTypes.indexOf(a.simType) <= this.dialog.simTypes.indexOf(b.simType) ? -1 : 1;
      });
      //this.channelMetadata.sort(sortBy({name: 'name', primer: a => a.toLowerCase()}));

      let dataClone = this.json.clone(this.dialog.data);
      for(let pane of dataClone.panes){
        for(let i=0; i < pane.channels.length; ++i){
          let channel = pane.channels[i];
          let channelData = this.channelMetadata.find(v => v.name === channel);
          if(!channelData){
            channelData = {
              name: channel,
              genericName: channel,
              fullName: channel,
              units: '',
              description: '',
              simType: null
            };
          }
          pane.channels[i] = channelData;
        }
      }

      this.data = dataClone;
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  private addChannelFromMetadata(simType: SimType, allChannels: Channel[], metadata: {name: string; units: string; description: string}) {
    if(this.dialog.channelNameStyle === ChannelNameStyle.FullyQualified){
      let channelAnySimType: Channel = {
        name: metadata.name,
        genericName: metadata.name,
        fullName: metadata.name,
        units: metadata.units,
        description: metadata.name + GENERIC_DESCRIPTION_SUFFIX,
        simType: null
      };

      if(!allChannels.find(v => v.name === channelAnySimType.name)){
        allChannels.push(channelAnySimType);
      }
    }

    let fullName = metadata.name + ':' + simType;
    let channel = {
      name: this.dialog.channelNameStyle === ChannelNameStyle.FullyQualified ? fullName : metadata.name,
      genericName: metadata.name,
      fullName,
      units: metadata.units,
      description: metadata.description,
      simType
    };

    if(!allChannels.find(v => v.name === channel.name)){
      allChannels.push(channel);
    }
  }

  private filterChannel(chart: ChartSchema, channel: VectorMetadataChannel): boolean{
    return !channel.xDomainName || chart.xDomains.indexOf(channel.xDomainName) !== -1;
  }

  public buildResult(){
    let result = this.json.clone(this.data);
    for(let pane of result.panes){
      for(let i=0; i < pane.channels.length; ++i){
        pane.channels[i] = (<Channel>pane.channels[i]).name;
      }
    }

    result.panes = result.panes.filter(v => v.channels.length > 0);

    return result;
  }

  public moveChannelUp(pane: Pane, index: number) {
    if(index !== 0){
      this.swap(pane.channels, index, index - 1);
    }
  }

  public moveChannelDown(pane: Pane, index: number) {
    if(index !== pane.channels.length - 1){
      this.swap(pane.channels, index, index + 1);
    }
  }

  public movePaneUp(index: number) {
    if(index !== 0){
      this.swap(this.data.panes, index, index - 1);
    }
  }

  public movePaneDown(index: number) {
    if(index !== this.data.panes.length - 1){
      this.swap(this.data.panes, index, index + 1);
    }
  }

  public addPane(afterIndex: number){
    let referencePane = this.data.panes[afterIndex];
    let pane: Pane = {
      relativeSize: referencePane.relativeSize,
      channels: []
    };
    this.data.panes.splice(afterIndex + 1, 0, pane);
    return pane;
  }

  public removePane(index: number){
    this.data.panes.splice(index, 1);
    if(this.data.panes.length === 0){
      let pane: Pane = {
        relativeSize: 1,
        channels: []
      };
      this.data.panes.push(pane);
    }
  }

  public addChannel(paneIndex: number){
    this.addChannelPaneIndex = paneIndex;
    this.showChannelSelection = true;
  }

  public cancelAddChannel(){
    this.addChannelPaneIndex = undefined;
    this.showChannelSelection = false;
  }

  public selectChannel(channelIndex: number){
    let channel = this.channelMetadata[channelIndex];

    let pane;
    if(this.flattenView){
      pane = this.addPane(this.addChannelPaneIndex);
    } else{
      pane = this.data.panes[this.addChannelPaneIndex];
    }

    pane.channels.push(channel);
    this.cancelAddChannel();
  }

  public removeChannel(pane: Pane, index: number){
    pane.channels.splice(index, 1);
  }

  private swap(array: any[], x: number, y: number) {
    let b = array[x];
    array[x] = array[y];
    array[y] = b;
  }
}

interface VectorMetadataChannel {
  name: string;
  units: string;
  description: string;
  xDomainName: string;
}

interface ScalarMetadataChannel {
  name: string;
  units: string;
  description: string;
  value: number;
}

interface Channel {
  name: string;
  genericName: string;
  fullName: string;
  units: string;
  description: string;
  simType: SimType;
}

interface Pane {
  channels: (string | Channel)[];
  relativeSize: number;
}

interface ChartSchema {
  xDomains: string[];
  panes: Pane[];
}

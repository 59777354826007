import { ContourViewer } from './contour-viewer';
import { IEditChannelsType, NavigationStationViewer } from '../../navigation-station/navigation-station-viewer';
import { DataService } from './data/data-service';
import { SharedState } from '../shared-state';
import { IEditChannelsOptions, PaneChannelLayout, SiteHooks } from '../../site-hooks';
import * as d3 from '../../d3-bundle';
import { ChannelNameStyle } from '../channel-data-loaders/channel-name-style';
import { StudyJob } from '../../study-job';

export const LINE_CONTOUR_OVERLAY_VIEWER_TYPE = 'lineContourOverlayViewer';

/**
 * This viewer has not been converted to use our standard components and data pipeline.
 *
 * Traditionally Mark would prototype viewers and then they would be refactored and improved by
 * the platform team. In this case, that hasn't happened.
 */
export class LineContourOverlayViewer extends ContourViewer implements NavigationStationViewer {

  constructor(
    protected jobs: ReadonlyArray<StudyJob>,
    protected layout: any,
    protected dataService: DataService,
    protected sharedState: SharedState,
    siteHooks: SiteHooks,
    reverseColorScale: boolean = false,
  ) {
    super(siteHooks);

    this.reverseColorScale = reverseColorScale;
  }

  public dispose() {
  }

  public canImportData(): boolean {
    return false;
  }

  public canExportData(): boolean {
    return false;
  }

  public canStackDiagonals(): boolean {
    return false;
  }

  public importCsvData(name: string, fileContent: string) {
  }

  public exportCsvData() {
    return Promise.resolve();
  }

  protected async load() {
    let dataSubset = await this.dataService.extractVectorDataForJobs(ChannelNameStyle.Generic, this.layout, 'ComponentSweeps', this.jobs);
    if (!dataSubset.zData) {
      throw new Error('Z data not found.');
    }

    this.xData[0] = dataSubset.xData[0][0];
    this.xData[0].fullDomain = [d3.minStrict(this.xData[0].values), d3.maxStrict(this.xData[0].values)];

    this.yData = dataSubset.yData;
    this.yData[0].yMax = d3.maxStrict(this.yData[0].values);
    this.yData[0].yMin = d3.minStrict(this.yData[0].values);

    this.zData = dataSubset.zData;
    this.zData[0].zMax = d3.maxStrict(this.zData[0].values);
    this.zData[0].zMin = d3.minStrict(this.zData[0].values);

    console.log('zData min: ' + this.zData[0].zMin);
    console.log('zData max: ' + this.zData[0].zMax);

    if (isNaN(this.zData[0].zMin)) {
      console.log('Oh no');
    }

    this.zData[0].yDomain.yMax = d3.maxStrict(this.zData[0].yDomain.values);
    this.zData[0].yDomain.yMin = d3.minStrict(this.zData[0].yDomain.values);
  }

  public getLayout() {
    return this.layout;
  }

  public setLayout(): Promise<any> {
    return this.reBuild();
  }

  getEditChannelsTypes(): IEditChannelsType[] {
    return [];
  }

  getEditChannelsOptions(editType: IEditChannelsType): Promise<IEditChannelsOptions | undefined> {
    return Promise.resolve(undefined);
  }

  setEditChannelsResult(editType: IEditChannelsType, result: PaneChannelLayout): Promise<void> {
    return Promise.resolve();
  }

}

export class CheckAuthentication {
  static readonly type = '[Authentication] CheckAuthentication';
  constructor() {}
}

export class Login {
  static readonly type = '[Authentication] Login';
  constructor() {}
}

export class Logout {
  static readonly type = '[Authentication] Logout';
  constructor() {}
}

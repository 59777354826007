
/**
 * Check if the object is null or undefined.
 *
 * @param obj The object to check.
 * @returns `true` if the object is null or undefined, otherwise `false`.
 */
export function isNullOrUndefined(obj: any): obj is null | undefined {
  return typeof obj === 'undefined' || obj === null;
}

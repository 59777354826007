import {LazyLoaded} from './lazy-loaded';
import {
  GetStudyJobMetadataQueryResult
} from '../../../../generated/api-stubs';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {Injectable} from '@angular/core';

@Injectable()
export class LazyLoadedJobNameFactory {
  constructor(
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  public create(jobMetadataResult: LazyLoaded<GetStudyJobMetadataQueryResult>): LazyLoaded<string>{
    return new LazyLoaded<string>(
      async () => {
        await jobMetadataResult.load();
        return jobMetadataResult.value.studyJob.name;
      },
      this.getFriendlyErrorAndLog);
  }
}

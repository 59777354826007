import {Injectable} from '@angular/core';
import { BaseStorage, CANOPY_STORAGE_PREFIX } from './base-storage';

@Injectable()
export class LocalStorage extends BaseStorage {

  storage: Storage = localStorage;

  OLD_KEYS = [
    'currentUser',
    'studyStagingArea',
    'resultsStagingArea',
  ];

  REMOVED_KEYS: ReadonlyArray<string> = [
    'keyPermissions',
    'configPermissions',
  ];

  constructor(){
    super();
    // Upgrade old keys.
    for(let oldKey of this.OLD_KEYS){
      let value = localStorage.getItem(oldKey);
      if(value){
        localStorage.setItem(CANOPY_STORAGE_PREFIX + oldKey, value);
        localStorage.removeItem(oldKey);
      }
    }

    // Delete removed keys.
    for(let oldKey of this.REMOVED_KEYS){
      localStorage.removeItem(oldKey);
    }
  }
}

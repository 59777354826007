import { IChannelMetadata, ISourceLoader, SourceMetadata } from './source-loader';
import { Observable, Subject } from 'rxjs';
import { ChannelNameStyle } from './channel-name-style';
import { IViewerChannelData } from './viewer-channel-data';
import { ViewerChannelDataMap } from './viewer-channel-data-map';

/**
 * A set of source loaders.
 * When viewing multiple data sources overlaid on a single plot, this class tracks the set of source loaders.
 */
export class SourceLoaderSet {

  /**
   * The set of source loaders.
   */
  private _sources: SourceLoaderViewModel[] = [];

  /**
   * Raised when the set of source loaders changes.
   */
  private _changed: Subject<SourceLoaderSet> = new Subject();

  /**
   * Creates a new source loader set.
   * @param sources The source loaders.
   */
  constructor(sources?: SourceLoaderViewModel[]) {
    if (sources) {
      this._sources.push(...sources);
    }
  }

  /**
   * Gets the source loaders.
   * Returns a copy of the internal array.
   */
  public get sources(): SourceLoaderViewModel[] {
    return [...this._sources];
  }

  /**
   * Gets the changed event. Raised when the set of source loaders changes.
   */
  public get changed(): Observable<SourceLoaderSet> {
    return this._changed;
  }

  /**
   * Raises the changed event.
   */
  public raiseChangedEvent() {
    this._changed.next(this);
  }

  /**
   * Adds the specified source loaders.
   * @param sources The source loaders to add.
   */
  public add(...sources: SourceLoaderViewModel[]) {
    this._sources.push(...sources);
    this._changed.next(this);
  }

  /**
   * Removes the source loader at the specified index.
   * @param index The index of the source loader to remove.
   */
  public remove(index: number) {
    this._sources.splice(index, 1);
    this._changed.next(this);
  }

  /**
   * Moves a source loader from one index to another.
   * This is used when the user re-orders the data sources in the UI.
   * @param index The index of the source loader to move.
   * @param to The index to move the source loader to.
   */
  public move(index: number, to: number) {
    let item = this._sources[index];
    this._sources.splice(index, 1);
    this._sources.splice(to, 0, item);
    this._changed.next(this);
  }

  /**
   * Replaces the source loader at the specified index with the specified source loaders.
   * This is used when the user clicks the "split source" button, which, for example,
   * replaces a single source representing a multi-lap simulation with a source per lap
   * so the laps can be overlaid.
   * @param index The index of the source loader to replace.
   * @param sources The source loaders to replace with.
   */
  public replace(index: number, sources: ReadonlyArray<SourceLoaderViewModel>) {
    this._sources.splice(index, 1, ...sources);
    this._changed.next(this);
  }

  /**
   * Replaces the source loaders at the specified indices with the specified source loader.
   * This is used when the user clicks the "combine sources" button, which, for example,
   * replaces a source per lap with a single source representing the entire simulation.
   * @param indices The indices of the source loaders to replace.
   * @param source The source loader to replace with.
   */
  public replaceAll(indices: ReadonlyArray<number>, source: SourceLoaderViewModel) {
    for (let i = indices.length - 1; i >= 0; --i) {
      if (i === 0) {
        this._sources.splice(indices[i], 1, source);
      } else {
        this._sources.splice(indices[i], 1);
      }
    }

    this._changed.next(this);
  }
}

/**
 * A view model for a source loader.
 * This class is used to track whether a source loader is visible or not.
 */
export class SourceLoaderViewModel implements ISourceLoader {

  /**
   * Creates a new source loader view model.
   * @param _loader The source loader to wrap.
   * @param isVisible Whether the source loader is visible.
   */
  constructor(
    private _loader: ISourceLoader,
    public isVisible: boolean = true) {
  }

  /**
   * @inheritdoc
   */
  public getSourceMetadata(): Promise<SourceMetadata> {
    return this._loader.getSourceMetadata();
  }

  /**
   * @inheritdoc
   */
  public async getChannelData(requestedName: string, resultChannelNameStyle: ChannelNameStyle, xDomainName: string): Promise<IViewerChannelData> {
    let result = await this._loader.getChannelData(requestedName, resultChannelNameStyle, xDomainName);

    if (!result) {
      throw new Error('No channel data returned from getChannelData.');
    }

    return result;
  }

  /**
   * @inheritdoc
   */
  public getMultipleChannelData(requestedNames: ReadonlyArray<string>, resultChannelNameStyle: ChannelNameStyle, xDomainName: string): Promise<ViewerChannelDataMap> {
    return this._loader.getMultipleChannelData(requestedNames, resultChannelNameStyle, xDomainName);
  }

  /**
   * @inheritdoc
   */
  public getRequestableChannels(resultChannelNameStyle: ChannelNameStyle, primaryDomainName: string): Promise<IChannelMetadata[]> {
    return this._loader.getRequestableChannels(resultChannelNameStyle, primaryDomainName);
  }

  /**
   * @inheritdoc
   */
  public getConfig(type: string): Promise<any> {
    return this._loader.getConfig(type);
  }

  /**
   * @inheritdoc
   */
  public get id(): string {
    return this._loader.id;
  }

  /**
   * Gets the inner source loader.
   */
  public get inner(): ISourceLoader {
    return this._loader;
  }
}

import {Injectable} from '@angular/core';
import {CommandResult, ICommandContext} from './worksheet-command';
import {ConfigViewModel} from '../config-view-model';
import {ReplaceResult} from '../worksheet-item-component-base';
import {SetConfigOrAllReferences} from './set-config-or-all-references.service';
import {ConfirmReplaceAllReferences} from './confirm-replace-all-references.service';
import {ConfirmationDialog} from '../../common/dialogs/confirmation-dialog.service';
import {LoadingDialog} from '../../common/dialogs/loading-dialog.service';
import {ConfigLoaderDialog} from '../../simulations/configs/config-loader-dialog/config-loader-dialog.service';
import {WorksheetStub} from '../../../generated/api-stubs';
import { AuthenticationService } from '../../identity/state/authentication.service';

/**
 * A service which prompts the user for an existing config and sets it to the selected worksheet rows.
 */
@Injectable()
export class SetStandardConfig {

  /**
   * Creates an instance of SetStandardConfig.
   * @param setConfigOrAllReferences The service for setting a config or all references.
   * @param confirmReplaceAllReferences The service for confirming the replacement of all references.
   * @param confirmationDialog The confirmation dialog service.
   * @param loadingDialog The loading dialog service.
   * @param configLoaderDialog The config loader dialog service.
   * @param authenticationService The authentication service.
   * @param worksheetStub The worksheet stub service.
   */
  constructor(
    private readonly setConfigOrAllReferences: SetConfigOrAllReferences,
    private readonly confirmReplaceAllReferences: ConfirmReplaceAllReferences,
    private readonly confirmationDialog: ConfirmationDialog,
    private readonly loadingDialog: LoadingDialog,
    private readonly configLoaderDialog: ConfigLoaderDialog,
    private readonly authenticationService: AuthenticationService,
    private readonly worksheetStub: WorksheetStub) {
  }

  /**
   * Prompts the user for an existing config and sets it to the selected worksheet rows.
   * @param context The command context.
   * @returns The command result.
   */
  public async execute(context: ICommandContext<ConfigViewModel>): Promise<CommandResult> {

    const rows = context.selectedRows;
    const target = context.target;
    const userData = this.authenticationService.userDataSnapshot;

    if(userData.tenant !== context.worksheet.tenantId){
      throw new Error('You cannot load a config for a worksheet owned by another tenant.');
    }

    // If there are multiple rows with the same reference as the target cell, we prompt the user if
    // they want to replace all references or just the selected one. Whether it is appropriate to prompt
    // the user is determined by the `confirmReplaceAllReferences` service.
    const replaceResult = await this.confirmReplaceAllReferences.execute(context.target, rows);
    if(replaceResult === ReplaceResult.cancel){
      return CommandResult.NoUpdate;
    }

    // Prompt the user for a config.
    const tenantId = userData.tenant;
    const configResult = await this.configLoaderDialog.loadConfig(
      target.configType,
      undefined,
      undefined,
      undefined,
      true);

    if(!configResult) {
      return CommandResult.NoUpdate;
    }

    let configId = configResult.configId;

    if(configResult.userId){
      // The user selected a user config.
      const copyResponse = 'Copy';
      const moveResponse = 'Move';
      const responses = [moveResponse, copyResponse];

      let response: string;
      if(configResult.userId === userData.sub){
        // If the user owns the config, ask if they want to move or copy it.
        response = await this.confirmationDialog.show(
          'Would you like to copy this config into the worksheet, or move it? Moving it will remove it from the main config list.',
          'Copy or Move Config?',
          responses);
      } else{
        // For simplicity we are always copying configs which don't belong to the worksheet owner,
        // although moving them is supported on the back-end. We do this as moving them might confuse the
        // owner, who may not know why the config is no longer in the main config list.
        response = await this.confirmationDialog.show(
          'You do not own the selected config, so a copy of it will be made in the worksheet.',
          'Copy Config?',
          [copyResponse]);
      }

      if(!response){
        return CommandResult.NoUpdate;
      }

      if(response === copyResponse) {
        // Copy if required.
        const duplicationResult = await this.loadingDialog.showUntilFinished(
          this.worksheetStub.postDuplicateConfigs(
            tenantId,
            context.worksheet.worksheetId,
            {
              sourceTenantId: tenantId,
              sourceConfigIds: [ configId ]
            }),
          'Copying...');

        configId = duplicationResult.targetConfigIds[0];
      }
    } else {
      // We currently always copy default configs, because it's hard to think of a case where
      // referencing the original is beneficial. Referencing the original could have undesired
      // complications if we decide to change the default config or rename it in later sim versions.
      // However, referencing them directly is supported on the back-end if we decide to go that route.
      const duplicationResult = await this.loadingDialog.showUntilFinished(
        this.worksheetStub.postDuplicateConfigs(
          tenantId,
          context.worksheet.worksheetId,
          {
            sourceTenantId: tenantId,
            sourceDefaultConfigIds: [
              {
                configType: target.configType,
                name: configResult.configId,
              }
            ]
          }),
        'Copying...');

      configId = duplicationResult.targetDefaultConfigIds[0];
    }

    // Update the worksheet.
    await this.setConfigOrAllReferences.execute(
      context.target,
      rows,
      {
        tenant: {
          tenantId,
          targetId: configId,
        }
      },
      replaceResult);

    return CommandResult.UpdateOnly;
  }

}

@if (isVisible) {
  <div class="modal dialog-container" id="select-sim-version-dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title">Select Sim Version</h1>
          <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()">
          </button>
        </div>
        <div class="modal-body">
          @if (session) {
            <cs-select-sim-version
              [tenantId]="session.dialog.tenantId"
              [isTenantSimVersion]="session.dialog.isTenantSimVersion"
              [currentSimVersion]="session.dialog.currentSimVersion"
              [selectDefaultMessage]="session.dialog.selectDefaultMessage"
              [selectReleaseMessage]="session.dialog.selectReleaseMessage"
            (simVersionSelected)="session.onSimVersionSelected($event)"></cs-select-sim-version>
          }
        </div>
        <div class="modal-footer">
          <button id="dialog-dismiss-button" type="button" class="btn btn-secondary" (click)="dismiss()">Cancel</button>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div>
  }<!-- /.modal -->

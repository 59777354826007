import * as d3 from '../../d3-bundle';
import { HTMLDivSelection, SVGSelection } from '../../untyped-selection';
import { ISize } from '../size';
import { IMargin } from '../margin';
import { sumFirstN } from '../../sum-first-n';
import { ILegendSettings } from '../legend-settings';
import { GetChannelColorDelegate } from '../chart-settings';
import { SiteHooks } from '../../site-hooks';
import { SetErrorDelegate } from '../set-error-delegate';
import { Utilities } from '../../utilities';
import { getLineBreak } from '../../get-line-break';

/**
 * The interface for the chart settings we're interested in.
 */
interface IChartSettings {

  /**
   * The padding between the edge of the SVG and where we should draw.
   */
  readonly svgPadding: IMargin;

  /**
   * The margin around the data area of the chart, to allow for source labels, legend, etc.
   */
  readonly chartMargin: IMargin;

  /**
   * The size of the chart, calculated using the SVG size, SVG padding, and chart margin.
   */
  readonly chartSize: ISize;

  /**
   * The legend settings.
   */
  readonly legend: ILegendSettings;

  /**
   * Get the color for a channel.
   */
  readonly getChannelColor: GetChannelColorDelegate;
}

/**
 * The D3 style interface to the legend renderer.
 */
export class LegendRenderer {

  /**
   * The inner renderer.
   */
  private inner = new LegendRendererInner();

  /**
   * Render the legend.
   * @param selection The SVG selection to render the legend to.
   * @param parent The parent HTML div selection.
   * @returns This object.
   */
  public render(selection: SVGSelection, parent: HTMLDivSelection): this {
    this.inner.render(selection, parent);
    return this;
  }

  /**
   * Set the chart settings.
   * @param value The chart settings.
   * @returns This object.
   */
  public chartSettings(value: IChartSettings): this {
    this.inner.settings = value;
    return this;
  }

  /**
   * Set the data for the legend.
   * @param value The data for the legend.
   * @returns This object.
   */
  public data(value: ReadonlyArray<ILegendList>): this {
    this.inner.data = value;
    return this;
  }

  /**
   * Set the render channels.
   * @param value The render channels.
   * @returns This object.
   */
  public renderChannels(value: ReadonlyArray<LegendRenderChannel>): this {
    this.inner.renderChannels = value;
    return this;
  }

  /**
   * Set the orientation of the legend.
   * @param value The orientation of the legend.
   * @returns This object.
   */
  public orientation(value: RenderOrientation): this {
    this.inner.orientation = value;
    return this;
  }

  /**
   * Set the site hooks.
   * @param value The site hooks.
   * @returns This object.
   */
  public siteHooks(value: SiteHooks): this {
    this.inner.siteHooks = value;
    return this;
  }

  /**
   * Set the error handler.
   * @param value The error handler.
   * @returns This object.
   */
  public setError(value: SetErrorDelegate): this {
    this.inner.setError = value;
    return this;
  }

  /**
   * Listen for changes to the legend.
   * @param typenames The event type names.
   * @param callback The callback.
   * @returns This object.
   */
  public on(typenames: string, callback: (this: object, ...args: any[]) => void): this {
    this.inner.listeners.on(typenames, callback);
    return this;
  }
}

/**
 * The legend renderer implementation.
 */
export class LegendRendererInner {

  /**
   * The chart settings.
   */
  public settings?: IChartSettings;

  /**
   * The data for the legend.
   */
  public data?: ReadonlyArray<ILegendList>;

  /**
   * The render channels.
   */
  public renderChannels: ReadonlyArray<LegendRenderChannel> = [];

  /**
   * The orientation of the legend.
   */
  public orientation: RenderOrientation = RenderOrientation.vertical;

  /**
   * The site hooks.
   */
  public siteHooks?: SiteHooks;

  /**
   * The error handler.
   */
  public setError?: SetErrorDelegate;

  /**
   * The D3 style events.
   */
  public listeners = d3.dispatch('changed');

  /**
   * Render the legend.
   * @param selection The SVG selection to render the legend to.
   * @param parent The parent HTML div selection.
   */
  public render(selection: SVGSelection, parent: HTMLDivSelection) {

    // Ensure we have everything we need.
    if (!this.settings || !this.data) {
      return;
    }

    const settings = this.settings;
    const data = this.data;

    // Copy legend values to clipboard on Ctrl+C.
    parent
      .attr('tabindex', 0) // Required to allow the DIV to receive keydown events.
      .on('keydown.legend', (event: KeyboardEvent) => {
        if (event.ctrlKey && event.key === 'c') {
          this.copyLegendToClipboard();
        }
      });

    // Create a single legend container.
    let containerClassName = (this.orientation === RenderOrientation.horizontal ? 'horizontal' : 'vertical') + '-legend-area';
    let containerUpdate = selection.selectAll<SVGGElement, any>('.' + containerClassName).data([null]);
    let containerEnter = containerUpdate.enter().append('g').attr('class', containerClassName + ' legend-area');
    let container = containerEnter.merge(containerUpdate);

    // Render channel icon sizes.
    let renderChannelIconWidth = settings.legend.blobSize + settings.legend.blobSpacing;
    let allRenderChannelIconWidth = renderChannelIconWidth * this.renderChannels.length;

    // Position the container.
    if (this.orientation === RenderOrientation.vertical) {
      container.attr('transform',
        'translate('
        + (settings.svgPadding.left + settings.chartMargin.left + settings.chartSize.width)
        + ','
        + (settings.svgPadding.top + settings.chartMargin.top)
        + ')');
    } else {
      container.attr('transform',
        'translate('
        + (settings.svgPadding.left + settings.chartMargin.left)
        + ','
        + (settings.svgPadding.top + settings.chartMargin.top + settings.chartSize.height)
        + ')');
    }

    // Create the "Copy Legend Values" button.
    let copyLegendContainerUpdate = container.selectAll<SVGGElement, any>('.copy-legend-values-area')
      .data(this.data && this.data.length && this.data[0].channels && this.data[0].channels.length ? [this.data[0].channels[0]] : []);
    let copyLegendContainerEnter = copyLegendContainerUpdate.enter().append('g')
      .attr('class', 'copy-legend-values-area')
      .attr('transform', 'translate(0, -10)')
      .on('click', () => this.copyLegendToClipboard());
    let copyLegendContainer = copyLegendContainerUpdate.merge(copyLegendContainerEnter);
    copyLegendContainerEnter
      .append('text')
      .attr('class', 'copy-legend-values-icon')
      .text('\uf0ea');
    copyLegendContainer.select('.copy-legend-values-icon')
      .attr('x', d =>
        allRenderChannelIconWidth
        + settings.legend.valueWidth * d.legendValues.length
        + settings.legend.unitsWidth);
    copyLegendContainerEnter
      .append('text')
      .attr('class', 'copy-legend-values-text')
      .text('Copy');
    copyLegendContainer.select('.copy-legend-values-text')
      .attr('x', d =>
        allRenderChannelIconWidth
        + 2
        + settings.legend.blobSize
        + settings.legend.blobSpacing
        + settings.legend.valueWidth * d.legendValues.length
        + settings.legend.unitsWidth);

    // Create the container for the legend lists.
    let setClassName = 'legend-item-set';
    let setUpdate = container.selectAll<SVGGElement, any>('.' + setClassName).data(this.data as ILegendList[]);
    let setEnter = setUpdate.enter().append('g').attr('class', setClassName);
    setUpdate.exit().remove();
    let set = setEnter.merge(setUpdate);

    if (this.orientation === RenderOrientation.vertical) {
      set.attr('transform', (d, i) =>
        'translate(0,' + (sumFirstN(data.map(v => v.size), i) + (d.size >= 0 ? 0 : d.size)) + ')');
    } else {
      set.attr('transform', (d, i) =>
        'translate(' + sumFirstN(data.map(v => v.size), i) + ',0)');
    }

    // For each list create a container for each channel items.
    let itemClassName = 'legend-item';
    let itemUpdate = set.selectAll<SVGGElement, any>('.' + itemClassName).data((d: ILegendList) => d.channels.map(v => new LegendChannelWrapper(v, d)));
    let itemEnter = itemUpdate.enter().append('g').attr('class', itemClassName);
    itemUpdate.exit().remove();
    let item = itemEnter.merge(itemUpdate);

    item.attr('transform', (d, i) => 'translate(0,' + (i * (settings.legend.blobSize + settings.legend.blobSpacing)) + ')');

    // Create the render channel icons for each channel.
    itemEnter.append('g').attr('class', 'legend-render-channels');
    let renderChannelUpdate = item.select('.legend-render-channels').selectAll<SVGGElement, any>('.legend-render-channel')
      .data((d: LegendChannelWrapper) => this.renderChannels.map(v => new LegendRenderChannelWrapper(v, d.channel, d.parent)));
    renderChannelUpdate.exit().remove();
    let renderChannelEnter = renderChannelUpdate.enter().append('g')
      .attr('class', 'legend-render-channel')
      .attr('transform', (d, i) => `translate(${renderChannelIconWidth * (i)}, ${settings.legend.blobSize - settings.legend.blobSpacing / 2})`)
      .attr('width', settings.legend.blobSize)
      .attr('text-anchor', 'middle ')
      .style('cursor', 'pointer')
      .on('click', (_, d) => this.setRenderChannel(d));
    renderChannelEnter.append('text')
      .attr('class', 'icon-text render-channel-icon');
    renderChannelEnter.merge(renderChannelUpdate)
      .select('text')
      .text((d, i) => d.renderChannel.iconCodepoint)
      .classed('active-render-channel-icon', (d) => d.renderChannel.assignedChannelName() === d.channel.name)
      .attr('fill', (d, i) => settings.getChannelColor(d.channel.channelIndex, i));

    // Handle the click event to set the channel units for each channel.
    itemEnter.append('g').attr('class', 'legend-values')
      .on('click', (_, d: LegendChannelWrapper) => this.setChannelUnits(d.channel));

    // Render the channel values for each data source and channel.
    let valueUpdate = item.select('.legend-values').selectAll<SVGTextElement, LegendValue>('.legend-value')
      .data((d: LegendChannelWrapper) => d.channel.legendValues.map(v => new LegendValue(v, d)));
    valueUpdate.exit().remove();
    let valueEnter = valueUpdate.enter().append('text')
      .attr('class', 'legend-value')
      .attr('x', (d: LegendValue, i) => allRenderChannelIconWidth + settings.legend.valueWidth * (i + 1))
      .attr('y', settings.legend.blobSize - settings.legend.blobSpacing / 2)
      .attr('text-anchor', 'end')
      .style('cursor', 'pointer');
    valueEnter.merge(valueUpdate)
      .text((d: LegendValue) => LegendRendererInner.getLegendValueText(d.value))
      .attr('fill', (d: LegendValue, i) => settings.getChannelColor(d.parent.channel.channelIndex, i));

    // Render the units for each channel.
    itemEnter.append('text')
      .attr('class', 'legend-units')
      .attr('y', settings.legend.blobSize - settings.legend.blobSpacing / 2)
      .attr('text-anchor', 'middle')
      .style('cursor', 'pointer')
      .on('click', (_, d: LegendChannelWrapper) => this.setChannelUnits(d.channel));

    item.select('.legend-units')
      .text((d: LegendChannelWrapper) => d.channel.units)
      .attr('x', (d: LegendChannelWrapper) => allRenderChannelIconWidth + (settings.legend.valueWidth * d.channel.legendValues.length + 2) + settings.legend.unitsWidth / 2)
      .attr('fill', (d: LegendChannelWrapper) => settings.getChannelColor(d.channel.channelIndex, 0));

    // Render the square for toggling visibility for each channel.
    itemEnter.append('rect')
      .attr('class', 'legend-square')
      .attr('width', settings.legend.blobSize)
      .attr('height', settings.legend.blobSize)
      .style('cursor', 'pointer')
      .on('click', (_, d: LegendChannelWrapper) => {
        if (d.parent.disableToggleVisibility) {
          return;
        }

        this.lineToggleAndRescale(d.channel);
      });

    item.select('.legend-square')
      .style('fill', (d: LegendChannelWrapper) =>
        d.channel.isVisible && !d.parent.disableToggleVisibility ? settings.getChannelColor(d.channel.channelIndex, 0) : 'transparent')
      .style('stroke', (d: LegendChannelWrapper) => settings.getChannelColor(d.channel.channelIndex, 0))
      .attr('x', (d: LegendChannelWrapper) => allRenderChannelIconWidth + settings.legend.valueWidth * d.channel.legendValues.length + settings.legend.unitsWidth);

    // Render the channel names for each channel.
    itemEnter.append('text')
      .attr('class', 'legend-label')
      .attr('y', settings.legend.blobSize - settings.legend.blobSpacing / 2)
      .style('cursor', 'pointer')
      .on('click', (_, d: LegendChannelWrapper) => this.setChannelUnits(d.channel));

    item.select('.legend-label')
      .text((d: LegendChannelWrapper) => d.channel.name)
      .attr('fill', (d: LegendChannelWrapper) => settings.getChannelColor(d.channel.channelIndex, 0))
      .attr('x', (d: LegendChannelWrapper) =>
        allRenderChannelIconWidth +
        2
        + settings.legend.blobSize
        + settings.legend.blobSpacing
        + (settings.legend.valueWidth * d.channel.legendValues.length + settings.legend.unitsWidth))
      .text((d: LegendChannelWrapper) => settings.legend.trimLabel(d.channel.name));
  }

  /**
   * Handle the user requesting to edit the channel units.
   * @param d The channel to edit.
   */
  private async setChannelUnits(d: ILegendChannel) {
    try {
      if (this.siteHooks) {
        await this.siteHooks.editChannelUnits(d.genericName, d.units);
      }
    } catch (error) {
      this.handleError(error);
    }
  }

  /**
   * Handle the user toggling the visibility of a channel.
   * @param d The channel to toggle.
   */
  private lineToggleAndRescale(d: ILegendChannel) {
    d.isVisible = !d.isVisible;
    this.listeners.call('changed');
  }

  /**
   * Handle the user selecting a render channel.
   * @param d The render channel to select.
   */
  private setRenderChannel(d: LegendRenderChannelWrapper) {
    try {
      if (d.renderChannel.assignedChannelName() === d.channel.name) {
        d.renderChannel.action(undefined);
      } else {
        d.renderChannel.action(d.channel.name);
      }
      this.listeners.call('changed');
    } catch (error) {
      this.handleError(error);
    }
  }

  /**
   * Construct the text for the legend value boxes
   * @param d The value to format.
   * @returns The formatted value.
   */
  private static getLegendValueText(d: number): string {
    let magD = Math.abs(d);
    return isNaN(d) ? '-' : magD >= 1e4 ? d3.format('8d')(d) : magD < 1 ? d3.format('8.4f')(d) : d3.format('8.5g')(d);
  }

  /**
   * Copy the legend values to the clipboard.
   */
  private async copyLegendToClipboard() {
    try {
      if (!this.data) {
        return;
      }
      let tsvData: string[][] = [];
      for (let group of this.data) {
        for (let channel of group.channels) {
          let column: string[] = [];
          tsvData.push(column);
          column.push(channel.name);
          column.push(channel.units);
          for (let value of channel.legendValues) {
            column.push('' + value);
          }
        }
      }

      let lineBreak = getLineBreak();
      tsvData = Utilities.transpose(tsvData);
      let csvString = tsvData.map(v => v.join('\t')).join(lineBreak);
      await navigator.clipboard.writeText(csvString);
    } catch (error) {
      this.handleError(error);
    }
  }

  private handleError(error: Error) {
    let friendlyError = this.siteHooks ? this.siteHooks.getFriendlyErrorAndLog(error) : error.message;
    if (this.setError) {
      this.setError(friendlyError);
    } else {
      console.warn(friendlyError);
    }
  }
}

/**
 * Wraps the value for a channel and data source, and it's parent channel wrapper.
 */
class LegendValue {
  constructor(
    public value: number,
    public parent: LegendChannelWrapper) { }
}

/**
 * Wraps a channel and its parent list.
 */
class LegendChannelWrapper {
  constructor(
    public channel: ILegendChannel,
    public parent: ILegendList) {
  }
}

/**
 * Wraps a render channel and its parent channel.
 */
class LegendRenderChannelWrapper {
  constructor(
    public renderChannel: LegendRenderChannel,
    public channel: ILegendChannel,
    public parent: ILegendList) {
  }
}

/**
 * A grouped list of channels for the legend.
 */
export interface ILegendList {
  readonly size: number;
  readonly channels: ReadonlyArray<ILegendChannel>;
  readonly disableToggleVisibility?: boolean;
}

/**
 * A render channel is a channel which should be used to augment the rendering
 * of the data. For example, on the track viewer we have size and color render
 * channels which affect how the track ribbon is rendered.
 * The legend allows you to pick which channel should be used as each type of
 * render channel by displaying the icons next to each channel in the legend.
 */
export class LegendRenderChannel {

  /**
   * Creates a new LegendRenderChannel instance.
   * @param name The name of the render channel.
   * @param iconCodepoint The icon codepoint of the render channel.
   * @param assignedChannelName A function to get the assigned channel name.
   * @param action The action to take when the a new channel is selected.
   */
  constructor(
    public readonly name: string,
    public readonly iconCodepoint: string,
    public readonly assignedChannelName: () => string | undefined,
    public readonly action: (channelName: string | undefined) => void) {
  }
}

/**
 * A channel in the legend.
 */
export interface ILegendChannel {
  /**
   * The name of the channel.
   */
  name: string;

  /**
   * The generic name of the channel.
   */
  genericName: string;

  /**
   * The units of the channel.
   */
  units: string;

  /**
   * The legend values for the channel, one per data source.
   */
  legendValues: number[];

  /**
   * The channel index.
   */
  channelIndex: number;

  /**
   * The visibility of the channel.
   */
  isVisible: boolean;
}

/**
 * The orientation of the legend.
 */
export enum RenderOrientation {
  vertical,
  horizontal
}


import {Injectable} from '@angular/core';
import {CanopyFileLoader} from '../../visualizations/canopy-file-loader.service';
import {GetJobsSimTypeMetadata} from '../../../visualizations/viewers/channel-data-loaders/get-jobs-sim-type-metadata';

@Injectable()
export class GetJobsSimTypeMetadataFactory {
  constructor(){
  }

  public create(fileLoader: CanopyFileLoader){
    return new GetJobsSimTypeMetadata(fileLoader);
  }
}


@if (viewModel.populated) {
  @if (viewModel.populated.resolvedReference?.data) {
    <a class="link" [href]="url" (click)="onClick($event)">
      <ng-container [ngTemplateOutlet]="validity"></ng-container>
      <ng-container [ngTemplateOutlet]="referenceCount"></ng-container>
      <div class="cell-content" [ngClass]="{ 'invalid-input': studyInputNecessity === StudyInputNecessity.invalid, 'optional-input': studyInputNecessity === StudyInputNecessity.optional }">
        <span class="cell-name">
          {{viewModel.populated.resolvedReference.data.name | customLineBreaks}}@if (viewModel.populated.resolvedReference.data.isSupportSessionOpen) {
          <i class="fa fa-medkit support-session-open-icon" title="Support Session Open"></i>
        }
      </span>
      @if (isConfigReadOnly) {
        <i class="fa fa-user read-only-icon" title="You are not the owner."></i>
      }
      @if (viewModel.isTelemetry) {
        <div>
          <cs-study-jobs-summary
            [jobCount]="study.jobCount"
            [completedJobCount]="study.completedJobCount"
            [succeededJobCount]="study.succeededJobCount"
            [canNavigate]="false">
          </cs-study-jobs-summary>
        </div>
      }
      @if (hasJobIndex) {
        <div class="job-index"
          >
          Job Index {{viewModel.reference.tenant.jobIndex}}
        </div>
      }
      @if (isTestUser) {
        <span class="cell-reference-id">
          {{viewModel.reference.tenant?.targetId || viewModel.reference.default.name}}
        </span>
      }
      <cs-worksheet-labels [labels]="viewModel.populated.resolvedLabels"></cs-worksheet-labels>
    </div>
    <ng-container [ngTemplateOutlet]="dropDownMenu"></ng-container>
  </a>
} @else {
  @if (viewModel.populated.resolvedReference?.error) {
    <ng-container [ngTemplateOutlet]="validity"></ng-container>
    <ng-container [ngTemplateOutlet]="referenceCount"></ng-container>
    <div class="cell-content">
      {{viewModel.populated.resolvedReference.error}}
    </div>
    <ng-container [ngTemplateOutlet]="dropDownMenu"></ng-container>
  } @else {
    <div class="btn-group">
      <button type="button" class="btn btn-transparent"><cs-loader></cs-loader></button>
    </div>
  }
}
} @else {
  <ng-container [ngTemplateOutlet]="validity"></ng-container>
  <ng-container [ngTemplateOutlet]="dropDownMenu"></ng-container>
}




<ng-template #dropDownMenu>
</ng-template>

<ng-template #validity>
  @if (studyInputNecessity === StudyInputNecessity.required) {
    <i class="fa fa-circle required-input-icon validity-icon"
      [ngClass]="{ 'valid-icon': viewModel.hashValidity === HashValidity.valid, 'invalid-icon': viewModel.hashValidity === HashValidity.invalid}"
    title="Required Input"></i>
  }
  @if (studyInputNecessity === StudyInputNecessity.optional) {
    <i class="fa fa-circle-o optional-input-icon validity-icon"
      [ngClass]="{ 'valid-icon': viewModel.hashValidity === HashValidity.valid, 'invalid-icon': viewModel.hashValidity === HashValidity.invalid}"
    title="Optional Input"></i>
  }
</ng-template>

<ng-template #referenceCount>
  <span class="reference-count">{{referenceCountString}}</span>
</ng-template>

<cs-worksheet-item-error-message [errorMessage]="errorMessage"></cs-worksheet-item-error-message>
<cs-worksheet-context-menu-button></cs-worksheet-context-menu-button>

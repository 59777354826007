import { ResolvedColumn } from './resolved-column';
import { ConfigViewModel } from './config-view-model';
import { PopulatedStudyViewModel, StudyViewModel } from './study-view-model';
import { SimulationViewModel } from './simulation-view-model';
import {
  DocumentSubType,
  SimType, SimulationResolvedLabels,
  StudyReference,
  StudyType,
  WorksheetConfig,
  WorksheetRow,
  WorksheetStudy
} from '../../generated/api-stubs';
import { WorksheetUnderlyingData } from './worksheet-underlying-data';
import { ColumnViewModel } from './column-view-model';
import { OptionalColumnItemViewModel } from './worksheet-types';
import { ColumnType } from './column-type';
import { WorksheetViewModel } from './worksheet-view-model';
import { RowMetadataViewModel } from './row-metadata-view-model';
import { ClipboardContent } from './worksheet-clipboard.service';
import { ConfigPrototype, ConfigSet, UnpopulatedConfigPrototype } from './config-set';

/**
 * The view model for a row in the worksheet.
 */
export class RowViewModel {

  /**
   * The name of the row.
   */
  public name: string;

  /**
   * The combined list of view models for the columns and column items in the row.
   */
  public columns: ReadonlyArray<ResolvedColumn>;

  /**
   * The row metadata view model.
   */
  public readonly rowMetadata: RowMetadataViewModel;

  /**
   * The config view models on the row.
   */
  private readonly _configs: ConfigSet = new ConfigSet();

  /**
   * Gets the config view models on the row.
   */
  public get configs(): ReadonlyArray<ConfigViewModel> {
    return this._configs.list;
  }

  /**
   * The study view model on the row.
   */
  public readonly study: StudyViewModel;

  /**
   * The simulation view models on the row.
   */
  private _simulations: SimulationViewModel[];
  public get simulations(): ReadonlyArray<SimulationViewModel> {
    return this._simulations;
  }

  /**
   * Creates an instance of RowViewModel.
   * @param worksheet The worksheet view model containing this row.
   * @param data The worksheet outline data for the row, as returned by the API.
   * @param unpopulatedStudyType The unpopulated study type which the user has selected on the row.
   * @param underlyingData The underlying data for the worksheet (resolved labels, references, etc.).
   */
  constructor(
    public readonly worksheet: WorksheetViewModel,
    data: WorksheetRow,
    unpopulatedStudyType: StudyType,
    private underlyingData: WorksheetUnderlyingData) {

    this.name = data.name;
    this._configs.replace(data.configs.map(v => new ConfigPrototype(v, this, underlyingData)));

    if (data.study.reference) {
      this.study = StudyViewModel.fromStudy(this, data.study, underlyingData);
      this.updateSimulations(data.study.reference, underlyingData);
    } else {
      this.study = StudyViewModel.fromEmpty(this, unpopulatedStudyType, underlyingData);
    }

    this.rowMetadata = new RowMetadataViewModel(this);

    if (!this.simulations) {
      this._simulations = [];
    }
  }

  /**
   * Returns a value indicating whether the row is empty of configs and studies.
   */
  public get isEmpty(): boolean {
    return this._configs.list.every(v => !v.reference) && !this.study.reference;
  }

  /**
   * Gets the config view model of the specified type for the row.
   */
  public getConfig(configType: DocumentSubType): ConfigViewModel | undefined {
    return this._configs.get(configType);
  }

  /**
   * Gets (creating if necessary) the config view model of the specified type for the row.
   */
  public getOrCreateConfig(configType: DocumentSubType): ConfigViewModel | undefined {
    let configViewModel = this._configs.get(configType);
    if (configViewModel) {
      return configViewModel;
    }

    return this._configs.set(new UnpopulatedConfigPrototype(configType, this, this.underlyingData));
  }

  /**
   * Gets the populated studies for the row. This can include both the main study and
   * the telemetry study.
   */
  public getPopulatedStudies(): ReadonlyArray<PopulatedStudyViewModel> {
    const result: PopulatedStudyViewModel[] = [];
    const telemetryConfig = this.getConfig(DocumentSubType.telemetry);
    if (telemetryConfig && telemetryConfig.populated && telemetryConfig.populated.populatedStudy) {
      result.push(telemetryConfig.populated.populatedStudy);
    }
    if (this.study.populated) {
      result.push(this.study.populated);
    }
    return result;
  }

  /**
   * Gets the simulation view model for the specified simulation type, if it exists.
   */
  public getSimulation(simType: SimType): SimulationViewModel | undefined {
    return this._simulations.find(v => v.unpopulated.simType === simType);
  }

  /**
   * Updates all the config view models on the row with the specified config outline data.
   * @param value The name.
   */
  public setAllConfigsFromData(configs: ReadonlyArray<WorksheetConfig>) {
    if (!configs) {
      configs = [];
    }

    this._configs.replace(configs.map(v => new ConfigPrototype(v, this, this.underlyingData)));
  }

  /**
   * Updates the config view model of the specified type on the row with the specified config outline data.
   * @param config The config.
   */
  public setConfigFromData(config: WorksheetConfig) {
    if (!config) {
      return;
    }

    this._configs.set(new ConfigPrototype(config, this, this.underlyingData));
  }

  /**
   * Updates the study view model on the row with the specified study outline data.
   * @param study The study.
   */
  public setStudyFromData(study: WorksheetStudy) {
    if (!study) {
      return;
    }

    this.study.setStudy(study.reference);
  }

  /**
   * Gets whether the row is selected, which is the case if every config and the study are selected.
   */
  public get isSelected(): boolean {
    return this.study.isSelected && this.configs.every(v => v.isSelected);
  }

  /**
   * Gets whether anything on the row is selected.
   */
  public get anySelected(): boolean {
    return this.study.isSelected || this.configs.some(v => v.isSelected);
  }

  /**
   * Gets whether anything on the row is both selected and populated.
   */
  public get anySelectedAndPopulated(): boolean {
    return (this.study.isSelected && !!this.study.populated) || this.configs.some(v => v.isSelected && !!v.populated);
  }

  /**
   * Get's the worksheet row outline for the row. The outline can be used to send to the API to save
   * the study, or to populate the clipboard content. It is the underlying config representing the worksheet.
   * @returns The worksheet row outline.
   */
  public getOutline(): WorksheetRow {
    return {
      name: this.name,
      configs: this.configs.filter(config => !!config.populated).map(config => config.getOutline()),
      study: this.study.getOutline(),
    };
  }

  /**
   * Get's the worksheet row outline for the row, but only including populated items.
   * The outline can be used to send to the API to save the study, or to populate the clipboard content.
   * It is the underlying config representing the worksheet.
   * @returns The worksheet row outline.
   */
  public getRowPopulatedOutline(): WorksheetRow {
    return {
      name: this.name ? this.name : undefined,
      configs: this.configs.filter(config => !!config.populated).map(config => config.getOutline()),
      study: this.study.populated ? this.study.getOutline() : undefined,
    };
  }

  /**
   * Get's the worksheet row outline for the row, but only including selected items.
   * The outline can be used to send to the API to save the study, or to populate the clipboard content.
   * It is the underlying config representing the worksheet.
   * @returns The worksheet row outline.
   */
  public getRowSelectedOutline(): WorksheetRow {
    return {
      name: this.isSelected ? this.name : undefined,
      configs: this.configs.filter(config => !!config.populated && config.isSelected).map(config => config.getOutline()),
      study: this.study.populated && this.study.isSelected ? this.study.getOutline() : undefined,
    };
  }

  /**
   * Updates the row with the specified underlying data.
   * @param underlyingData The underlying data.
   */
  public update(underlyingData: WorksheetUnderlyingData) {
    this.underlyingData = underlyingData;

    for (let config of this._configs.list) {
      config.update(underlyingData);
    }

    this.study.update(underlyingData);

    this.updateSimulations(this.study.reference, underlyingData);
  }

  /**
   * Called when the study on the row has changed.
   */
  public onStudyChanged() {
    this.updateSimulations(this.study.reference, this.underlyingData);
  }

  /**
   * Generates any missing row item view models in the row based on the supplied column
   * view models.
   * @param columns The list of column view models.
   */
  public generateColumns(columns: ReadonlyArray<ColumnViewModel>): void {
    const simulationsMap = this.simulations.reduce((p, c) => {
      p[c.unpopulated.simType] = c;
      return p;
    }, {} as { [simType in SimType]: SimulationViewModel });

    this.columns = columns.map(c => {

      let value: OptionalColumnItemViewModel;
      switch (c.columnType) {
        case ColumnType.rowMetadata:
          value = this.rowMetadata;
          break;

        case ColumnType.config:
          value = this._configs.get(c.id as DocumentSubType);
          if (value === undefined) {
            let prototype = new UnpopulatedConfigPrototype(c.id as DocumentSubType, this, this.underlyingData);
            value = this._configs.set(prototype);
          }
          break;

        case ColumnType.study:
          value = this.study;
          break;

        case ColumnType.simulation:
          value = simulationsMap[c.id as SimType];
          if (value === undefined) {
            value = new SimulationViewModel(this, c.id as SimType, undefined, this.underlyingData);
            this._simulations.push(value);
          }
          break;
      }

      return new ResolvedColumn(c, value);
    });
  }

  /**
   * Copies the current row down to the next row in the worksheet the specified number of times.
   * New rows are created for the copies.
   * @param count The number of times to copy the row.
   */
  public copyDownRow(count: number) {
    this.worksheet.copyDownRow(this, count);
  }

  /**
   * Removes the row from the worksheet.
   */
  public removeRow() {
    if (this.worksheet.rows.length === 1) {
      this.worksheet.clearRow(this);
    } else {
      this.worksheet.removeRow(this);
    }
  }

  /**
   * Clears the row of all data.
   */
  public clearRow() {
    this.worksheet.clearRow(this);
  }

  /**
   * Pastes the specified clipboard content into the row.
   * @param content The clipboard content to paste.
   * @param forceDuplication Whether to force duplication of the pasted content.
   * @param removeExisting Whether to remove existing content on each row before pasting.
   */
  public async pasteRowsAsync(content: ClipboardContent, forceDuplication: boolean, removeExisting: boolean) {
    await this.worksheet.pasteRowsAsync(this, content, forceDuplication, removeExisting);
  }

  /**
   * Extracts the input configs from the study and places them on the row as configs.
   */
  public async extractStudyInputs() {
    await this.worksheet.extractStudyInputs(this);
  }

  /**
   * Updates the simulations on the row based on the specified study reference.
   * @param studyReference The study reference.
   * @param underlyingData The underlying data.
   */
  private updateSimulations(studyReference: StudyReference, underlyingData: WorksheetUnderlyingData) {
    if (!studyReference) {
      this._simulations = [];
      return;
    }

    const resolvedReference = underlyingData.studyResolvedReferences.get(studyReference);
    if (!resolvedReference || !resolvedReference.data) {
      this._simulations = [];
      return;
    }

    let labelsBySimType: SimTypeLabelsMap;

    const studyLabels = underlyingData.studyResolvedLabels.get(studyReference);
    if (studyLabels) {
      labelsBySimType = studyLabels.simulationLabels.reduce((p, c) => {
        p[c.simType] = c;
        return p;
      }, {} as SimTypeLabelsMap);
    } else {
      labelsBySimType = {};
    }

    this._simulations = resolvedReference.data.simTypes.map(v => new SimulationViewModel(
      this,
      v,
      labelsBySimType[v] || { simType: v, resolvedLabels: [] },
      underlyingData));
  }
}

/**
 * A map of simulation resolved labels by simulation type.
 */
type SimTypeLabelsMap = { [simType in SimType]?: SimulationResolvedLabels };

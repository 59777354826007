
// Extra properties are required to get around issues with Babel breaking instanceof.  Not required when using typescript.
// http://stackoverflow.com/questions/33870684/why-doesnt-instanceof-work-on-instances-of-error-subclasses-under-babel-node
// http://stackoverflow.com/questions/12915412/how-do-i-extend-a-host-object-e-g-error-in-typescript

export class CustomError extends Error {
  public stack: any;

  public isCancellationError: boolean = false;
  public isDisplayable: boolean = false;
  public isFromApi: boolean = false;
  public isConnectionError: boolean = false;
  public isInputValidationError: boolean = false;
  public isUnauthenticatedError: boolean = false;
  public isUnauthorizedError: boolean = false;
  public isPreconditionFailedError: boolean = false;
  public isNotFoundError: boolean = false;

  constructor(public readonly message: string) {
    super(message);
    this.stack = (<any>new Error()).stack;
  }
}

// Used for web application errors containing information
// that the user isn't concerned with.
export class CanopyError extends CustomError {
  constructor(message: string) {
    super(message);
  }
}

// Used when the web application fails to connect to another service.
export class ConnectionError extends CustomError {
  constructor(message: string, public readonly response?: any) {
    super(message);
    this.isConnectionError = true;
  }
}

// Used for when a promise is cancelled.
export class CancellationError extends CustomError {
  constructor(message: string) {
    super(message);
    this.isCancellationError = true;
  }
}

// Used to wrap any errors resulting from interactions with Azure services.
export class AzureError extends CustomError {
  constructor(message: string, public readonly response: any) {
    super(message);
  }
}

// An error which contains information that can be displayed to the user.
export class DisplayableError extends CustomError {
  constructor(message: string, public readonly detailedMessage?: string) {
    super(message);
    this.isDisplayable = true;
  }
}

// Derives from DisplayableError. Used if there is a problem validating user input.
// These are not logged back to the API.
export class InputValidationError extends DisplayableError {
  constructor(message: string) {
    super(message);
    this.isInputValidationError = true;
  }
}

// Derives from DisplayableError.  Used for errors resulting from interactions
// with the Canopy API.
// These are not logged back to the API.
export class ApiError extends DisplayableError {
  constructor(message: string, public readonly response?: any) {
    super(message);
    this.isFromApi = true;
  }
}

// Derives from ApiError. Used specifically for when the a request was denied
// because the user was unauthenticated.
export class UnauthenticatedError extends ApiError {
  constructor(message: string, response: any) {
    super(message, response);
    this.isUnauthenticatedError = true;
  }
}

// Derives from ApiError. Used specifically for when the a request was denied
// because the user was unauthorized for the request.
export class UnauthorizedError extends ApiError {
  constructor(message: string, response: any) {
    super(message, response);
    this.isUnauthorizedError = true;
  }
}

// Derives from ApiError. Used specifically for when the a request was denied
// because the user was unauthorized for the request.
export class NotFoundError extends ApiError {
  constructor(message: string, response: any) {
    super(message, response);
    this.isNotFoundError = true;
  }
}

// Derives from ApiError.
export class PreconditionFailedError extends ApiError {
  constructor(message: string, response?: any) {
    super(message, response);
    this.isPreconditionFailedError = true;
  }
}

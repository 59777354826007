import { ExtensionButtons } from './extension-buttons';
import { UpdateDelegate } from './update-delegate';
import { EditorNodeUtilities } from './editor-node-utilities';
import { ExtensionButtonCategories } from './extension-button-categories';


export abstract class AddButtonsHandler {
  abstract get category(): ExtensionButtonCategories;
  abstract execute(utils: EditorNodeUtilities, subTreeControls: any, globalUpdateDelegate: UpdateDelegate): ExtensionButtons;
}

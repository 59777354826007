<div id="pool-status-page">
  <div class="card">
    <h1 class="card-header">{{poolType ? poolType[0].toUpperCase() + poolType.substring(1) : ''}} Compute Pool Status</h1>

    <div class="card-body">

      @if (!isAssigned) {
        <p>
          No {{poolType}} pool assigned.
        </p>
      }

      <div class="loading-area">
        @if (counter > 0) {
          <p>
            {{counter}} seconds until refresh...
          </p>
        }
        @if (counter === 0) {
          <cs-loader></cs-loader>
        }
      </div>

      <cs-error-message [message]="errorMessage"></cs-error-message>

      @if (status) {
        <div>
          <div class="debug-information">
            <h2>Properties</h2>
            <table class="table last-item">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Value</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr><td>Pool State</td><td>{{status.poolState}}</td></tr>
                <tr><td>Allocation State</td><td>{{status.allocationState}}</td></tr>
                <tr><td>Current Nodes</td><td>{{status.currentDedicated}}</td></tr>
                <tr><td>Target Nodes</td><td>{{status.targetDedicated}}</td></tr>
                <tr><td>Current Low Priority</td><td>{{status.currentLowPriority}}</td></tr>
                <tr><td>Target Low Priority</td><td>{{status.targetLowPriority}}</td></tr>
                <tr><td>Maximum Tasks Per Node</td><td>{{status.maximumTasksPerNode}}</td></tr>
                <tr><td>Running Tasks</td><td>{{status.runningTasks}}</td></tr>
              </tbody>
            </table>
          </div>
          <div class="compute-nodes">
            <h2>Heat Map</h2>
            <p>
              Your pool currently has
              <strong>
                {{status.currentDedicated}} @if (containsHelperNodes) {
                <span>dedicated </span>
              }{{status.currentDedicated === 1 ? 'node' : 'nodes'}}</strong>@if (status.currentLowPriority) {
              <span> and <strong>{{status.currentLowPriority}} helper {{status.currentLowPriority === 1 ? 'node' : 'nodes'}}</strong></span>
              }.
              @if (status.maximumTasksPerNode > 1) {
                <span>Each node runs up to <strong>{{status.maximumTasksPerNode}} {{status.maximumTasksPerNode === 1 ? 'task' : 'tasks'}} in parallel</strong>.</span>
              }
            </p>
            @if (status.allocationState !== 'steady') {
              <div class="alert alert-info">
                Your pool is {{status.allocationState}}.
                The target number of @if (containsHelperNodes) {
                <span>dedicated </span>
                }nodes is <strong>{{status.targetDedicated}}</strong>.
                @if (status.targetLowPriority) {
                  <span>The target number of helper nodes is <strong>{{status.targetLowPriority}}</strong>.</span>
                }
              </div>
            }
            @for (node of status.computeNodes; track node) {
              <div class="compute-node">@for (task of node.tasks; track task) {
                <div class="compute-node-task {{task.class}}" title="{{task.tooltip}}"></div>
              }</div>
            }
            <div class="keys">
              <h3>Key: </h3>
              <div class="key">
                <div class="key-box idle"></div>Idle
              </div>
              <div class="key">
                <div class="key-box running"></div>Running
              </div>
              <div class="key">
                <div class="key-box transitioning"></div>Transitioning
              </div>
              <div class="key">
                <div class="key-box error"></div>Error
              </div>
              @if (containsHelperNodes) {
                <div class="key">
                  <div class="key-box idle low-priority"></div>Helper Node
                </div>
              }
            </div>
          </div>
        </div>
      }
    </div>
  </div>
</div>

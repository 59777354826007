
/**
 * Represents whether any of the hashes of a config (one per sim version) match
 * any of the study input hashes for that config type for the study on the same row.
 */
export enum HashValidity {

  /**
   * There is no data to compare.
   */
  none,

  /**
   * None of the config hashes match any of the study hashes.
   */
  invalid,

  /**
   * One of the config hashes matches one of the study hashes.
   */
  valid,
}

import { IViewerChannelData } from '../../channel-data-loaders/viewer-channel-data';

/**
 * Feature channels for a viewer. Feature channels are channels which are
 * used for viewer specific features, such as integration or splitting the data
 * into laps.
 */

export class FeatureChannels {

  /**
   * Creates a new FeatureChannels instance.
   * @param integrationChannel The channel over which integration calculations should be done.
   * @param splitChannel The channel over which data should be split into laps.
   */
  constructor(
    public readonly integrationChannel: IViewerChannelData | undefined,
    public readonly splitChannel: IViewerChannelData | undefined) {
  }
}

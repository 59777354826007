import { Injectable } from '@angular/core';
import { CanopyJson } from '../../common/canopy-json.service';
import { DownloadFile } from '../../common/download-file.service';
import { ConvertConfigToCsv } from './convert-config-to-csv.service';
import { CustomPropertyUtilities } from '../custom-properties/custom-property-utilities';
import { ConfigTypeLookup } from './config-types';
//import { StudyInput } from '../../worksheets/study-input';
import { ConfigOrConfigLoader } from './comparing/config-or-config-loader';
import { DownloadReEncryptedConfigDialog } from '../../encryption/download-re-encrypted-config-dialog/download-re-encrypted-config-dialog.service';
import { DownloadReEncryptedConfig } from '../../encryption/download-re-encrypted-config.service';
import { DownloadType } from '../studies/download-study.service';
import { ToastrService } from 'ngx-toastr';
import { LoadingDialog } from '../../common/dialogs/loading-dialog.service';
import { GetFriendlyErrorAndLog } from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import { CANOPY_TENANT_SHORT_NAME } from '../../common/constants';
import { AuthenticationService } from '../../identity/state/authentication.service';

@Injectable()
export class ExportConfigService {

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly loadingDialog: LoadingDialog,
    private readonly convertConfigToCsv: ConvertConfigToCsv,
    private readonly downloadFile: DownloadFile,
    private readonly json: CanopyJson,
    private readonly downloadReEncryptedConfig: DownloadReEncryptedConfig,
    private readonly downloadReEncryptedConfigDialog: DownloadReEncryptedConfigDialog,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog,
    private readonly toastrService: ToastrService
  ){}

  public downloadConfigTypes(getConfigOrConfigLoader: () => ConfigOrConfigLoader): DownloadType[]{
    return [
      {
        name: 'json',
        displayName: 'Export',
        description:'',
        execute: () => this.execute(async () => this.export(getConfigOrConfigLoader())),
      },
      {
        name: 'csv',
        displayName: 'Export CSV',
        description:'',
        execute: () => this.execute(async () => this.exportFilteredCsv(getConfigOrConfigLoader())),
      },
      {
        name: 'debug',
        displayName: 'Export Debug Config',
        description:'',
        execute: () => this.execute(async () => this.downloadReEncryptedConfig.execute(this.authenticationService.userDataSnapshot.tenant, CANOPY_TENANT_SHORT_NAME, await getConfigOrConfigLoader().getMaterialized())),
      },
      {
        name: 're-encrypted',
        displayName: 'Export Re-Encrypted Config',
        description:'',
        execute: async () => {
          this.downloadReEncryptedConfigDialog.show(getConfigOrConfigLoader());
        },
      },
    ];
  }

  public async execute(callback: () => Promise<void>) {
    try {
      await this.loadingDialog.show(async cb => {
        cb.setStatus('Preparing download...');
        await callback();
      });
    } catch (error) {
      let friendlyError = this.getFriendlyErrorAndLog.execute(error);
      this.toastrService.error(friendlyError, 'Download Error');
    }
  }

  public async export(configOrConfigLoader: ConfigOrConfigLoader){
    let studyInput = await configOrConfigLoader.getMaterialized();
    let configDataFormatted = this.json.stringify({
      simVersion: studyInput.simVersion,
      notes: studyInput.notes,
      customProperties: CustomPropertyUtilities.listToObject(studyInput.properties),
      config: studyInput.data,
    });

    this.downloadFile.text(
      (studyInput.name || ConfigTypeLookup.get(studyInput.configType).singularKey) + '.json',
      configDataFormatted,
      'application/json');
  }

  public async exportFilteredCsv(configOrConfigLoader: ConfigOrConfigLoader){
    let studyInput = await configOrConfigLoader.getMaterialized();
    let configDataFormatted = await this.convertConfigToCsv.execute(
      studyInput.data,
    ConfigTypeLookup.get(studyInput.configType).singularKey,
    studyInput.simVersion);

    this.downloadFile.text(
      (studyInput.name || ConfigTypeLookup.get(studyInput.configType).singularKey) + '.csv',
      configDataFormatted,
      'text/csv');
  }
}

import { SVGSelection } from '../../untyped-selection';

const TOGGLE_HEIGHT = 12;

export const OPTIONS_LEFT_PADDING = 15;
export const OPTIONS_BOTTOM_PADDING = 5;

/**
 * Defines a button that can be used to toggle state.
 * For example, the Zoom buttons at the bottom left of a line chart that toggle between zoom modes.
 */
export class ToggleButton<T> {

  /**
   * Create a new instance of a ToggleButton.
   * @param value The state value when this button is clicked.
   * @param name The name of the button.
   * @param clickAction The action which should be called when the button is clicked.
   * @param width The width of the button.
   * @param getSelectedClass An optional delegate which returns a class name to apply when the button is selected.
   */
  constructor(
    public value: T,
    public name: string,
    public clickAction: (d: ToggleButton<T>) => void,
    public width: number,
    public getSelectedClass: (d: ToggleButton<T>) => string | null = () => null) {
  }
}

export class ToggleOptionsRenderer {

  /**
   * Render a set of toggle options.
   * @param container The container to render the options in.
   * @param cssSuffix The suffix to use for the CSS class.
   * @param leftPadding The left padding.
   * @param topPadding The top padding.
   * @param title The title of the options.
   * @param titlePadding The padding to apply to the title.
   * @param toggles The toggle buttons to render.
   * @param currentValue The current value of the toggles.
   * @param additionalClickAction An optional additional action to call when a button is clicked.
   */
  public renderToggleOptions<T>(
    container: SVGSelection,
    cssSuffix: string,
    leftPadding: number,
    topPadding: number,
    title: string,
    titlePadding: number,
    toggles: ToggleButton<T>[],
    currentValue: T,
    additionalClickAction?: () => void) {

    let className = 'toggle-area-' + cssSuffix;
    let classSelector = '.' + className;

    // Create the group which will contain all the toggle buttons and the title.
    let toggleAreaUpdate = container.selectAll<SVGGElement, unknown>(classSelector).data([null]);
    let toggleAreaEnter = toggleAreaUpdate.enter().append<SVGGElement>('g')
      .attr('class', 'toggle-area toggle-area ' + className);

    let toggleArea = toggleAreaEnter.merge(toggleAreaUpdate);

    // Move it to the appropriate location on the chart.
    toggleArea
      .attr('transform',
        'translate('
        + (leftPadding)
        + ','
        + (topPadding - TOGGLE_HEIGHT)
        + ')');

    // Add the title.
    toggleAreaEnter.append('text')
      .attr('class', 'title')
      .attr('y', TOGGLE_HEIGHT / 2)
      .attr('dominant-baseline', 'central')
      .text(title);

    // Create the group to hold the buttons.
    toggleAreaEnter.append('g')
      .attr('class', 'toggles')
      .attr('transform', 'translate(' + titlePadding + ',0)');

    let optionsArea = container.selectAll(classSelector + ' .toggles');

    // Create a button for each supplied toggle button object.
    let toggleUpdate = optionsArea.selectAll<SVGGElement, ToggleButton<T>>('.toggle').data(toggles);

    // Position each button horizontally. We assume each button in the set is the same width.
    let toggleEnter = toggleUpdate.enter().append('g')
      .attr('class', 'toggle')
      .attr('transform', (d, i) => 'translate(' + (i * (d.width + 2)) + ',0)')
      .on('click', (_, d) => {
        d.clickAction(d);
        if (additionalClickAction) {
          additionalClickAction();
        }
      });

    // Draw the button outline.
    toggleEnter.append('rect')
      .attr('width', d => d.width)
      .attr('height', TOGGLE_HEIGHT);

    // Draw the button text.
    toggleEnter.append('text')
      .attr('dominant-baseline', 'middle')
      .attr('text-anchor', 'middle')
      .attr('x', d => d.width / 2)
      .attr('y', TOGGLE_HEIGHT / 2)
      .text((d: ToggleButton<T>) => d.name);

    // Draw the button fill, depending on the state.
    toggleEnter.merge(toggleUpdate)
      .select('rect').attr('class', (d) => currentValue === d.value ? (d.getSelectedClass(d) || 'toggle-selected') : '');
  }

}

import {
  StudyStub,
  GetStudyQueryResult, GetStudyJobMetadataQueryResult,
   PostStudyResult, NewStudyData
} from '../../../generated/api-stubs';
import {CanopyPusher, StudiesProgress} from '../../common/canopy-pusher.service';
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {Timer} from '../../common/timer.service';
import {JobViewModelFactory} from '../jobs/job-results/job-view-model';
import {CustomProperty} from '../custom-properties/custom-properties';
import {CarConfigValidationSessionBase, CheckStatus} from './car-config-validation-session-base';
import {Subscription} from 'rxjs';
import { AuthenticationService } from '../../identity/state/authentication.service';

export const WAITING_FOR_RESULT_MESSAGE = 'Waiting for result...';
export const VERIFY_CAR_STUDY_NAME = 'Verify Car';

export class CarConfigValidationSession extends CarConfigValidationSessionBase{
  public studyId: string;
  public studiesProgressSubscription: Subscription;
  public timerSubscription: Subscription;
  public isStudyCompleted: boolean = false;

  public studyMetadataResult: GetStudyQueryResult;
  public studyJobMetadataResult: GetStudyJobMetadataQueryResult;

  constructor(
    editorValue: any,
    simVersion: string,
    userId: string,
    configId: string,
    name: string,
    isEdited: boolean,
    customProperties: ReadonlyArray<CustomProperty>,
    private readonly timer: Timer,
    authenticationService: AuthenticationService,
    studyStub: StudyStub,
    canopyPusher: CanopyPusher,
    jobViewModelFactory: JobViewModelFactory,
    getFriendlyErrorAndLog: GetFriendlyErrorAndLog
  ){
    super(
      editorValue,
      simVersion,
      userId,
      configId,
      name,
      isEdited,
      customProperties,
      authenticationService,
      studyStub,
      canopyPusher,
      jobViewModelFactory,
      getFriendlyErrorAndLog);
  }

  public dispose(){
    this.unsubscribe();
  }

  public unsubscribe(){
    if(this.studiesProgressSubscription){
      this.studiesProgressSubscription.unsubscribe();
      this.studiesProgressSubscription = undefined;
    }

    if(this.timerSubscription){
      this.timerSubscription.unsubscribe();
      this.timerSubscription = undefined;
    }
  }

  public async executeStudy(newStudyData: NewStudyData): Promise<void>{
    this.studiesProgressSubscription = this.canopyPusher.studiesProgress.subscribe((data: StudiesProgress) => this.onStudiesProgress(data));
    let timer = this.timer.repeat(15000);
    this.timerSubscription = timer.subscribe(() => this.onTimerTick());

    let postStudyResult = <PostStudyResult>await this.studyStub.postStudy(
      this.userData?.tenant,
      newStudyData);

    this.studyId = postStudyResult.studyId;

    this.statusMessage = WAITING_FOR_RESULT_MESSAGE;
  }

  public async onStudiesProgress(data: StudiesProgress){
    try {
      if(this.isStudyCompleted){
        this.unsubscribe();
        return;
      }

      if(!this.studyId){
        return;
      }

      this.statusMessage = this.statusMessage + '.';

      let completedEventFound = !!data.items.find(item => item.studyId === this.studyId && item.completedJobCount > 0);
      if(completedEventFound){
        this.isStudyCompleted = true;
        this.unsubscribe();
        await this.loadResults();
      }
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async onTimerTick() {
    try {
      if(this.isStudyCompleted){
        this.unsubscribe();
        return;
      }

      if(!this.studyId){
        return;
      }

      this.statusMessage = this.statusMessage + '.';

      let studyMetadata = await this.studyStub.getStudyMetadata(
        this.userData?.tenant, this.studyId);

      let isCompleted = studyMetadata.study.data.completedJobCount > 0;
      if(isCompleted){
        this.isStudyCompleted = true;
        this.unsubscribe();
        await this.loadResults(studyMetadata);
      }
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async loadResults(studyMetadataResult?: GetStudyQueryResult){
    this.statusMessage = 'Loading result...';

    let jobId = this.studyId + '-0';
    let studyJobMetadataResultTask = this.studyStub.getStudyJobMetadata(
      this.userData?.tenant, this.studyId, jobId);

    if(studyMetadataResult){
      this.studyMetadataResult = studyMetadataResult;
    } else {
      this.studyMetadataResult = await this.studyStub.getStudyMetadata(
        this.userData?.tenant, this.studyId);
    }

    this.studyJobMetadataResult = await studyJobMetadataResultTask;

    this.jobViewModel = this.jobViewModelFactory.createFromData(
      this.studyJobMetadataResult,
      this.studyMetadataResult,
      'Current',
      VERIFY_CAR_STUDY_NAME);

    this.isStudySuccessful = !!this.studyMetadataResult.study.data.succeededJobCount;
    this.status = CheckStatus.checked;
    this.statusMessage = '';
  }
}

import {CanopyDocument} from '../../../generated/api-stubs';
import {VersionedDocumentData} from './versioned-document-data';
import { Injectable } from '@angular/core';

@Injectable()
export class StripNonVersionedDataFromDocument {
  public execute(document: CanopyDocument): VersionedDocumentData {
    return {
      name: document.name,
      data: document.data,
      properties: document.properties || {},
      notes: document.notes,
      deleteRequested: document.deleteRequested,
      parentWorksheetId: document.parentWorksheetId,
    };
  }
}


import {Injectable} from '@angular/core';
import {StudyStub} from '../../generated/api-stubs';
import {RetryingFileLoaderBase, AccessInformation} from '../simulations/visualizations/retrying-file-loader-base';
import {ChannelBinaryFormat} from '../visualizations/url-file-loader';
import { Timer } from './timer.service';

@Injectable()
export class BasicFileLoaderFactory {
  constructor(
    private readonly studyStub: StudyStub,
    private readonly timer: Timer){
  }

  public create(
    tenantId: string,
    studyId: string,
    accessInformation?: AccessInformation): BasicFileLoader {
    return new BasicFileLoader(
      tenantId,
      studyId,
      this.studyStub,
      this.timer,
      accessInformation);
  }
}

export class BasicFileLoader extends RetryingFileLoaderBase {

  constructor(tenantId: string,
              studyId: string,
              studyStub: StudyStub,
              timer: Timer,
              accessInformation: AccessInformation){
    super(tenantId, studyId, studyStub, timer, accessInformation);
  }

  public text(url: string, jobIndex?: number): Promise<any> {
    return this.loadText(url, jobIndex);
  }

  public json(url: string, jobIndex?: number): Promise<any> {
    return this.loadJson(url, jobIndex);
  }

  public csv(url: string, jobIndex?: number): Promise<any[]> {
    return this.loadCsv(url, jobIndex);
  }

  public loadNumericArray(url: string, binaryFormat: ChannelBinaryFormat | undefined, jobIndex?: number): Promise<ReadonlyArray<number>> {
    return this.loadNumericArray(url, binaryFormat, jobIndex);
  }
}

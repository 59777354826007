import {Rule} from '../runner/rule';
import {ConfigContext} from '../runner/config-context';
import {RuleLogger} from '../runner/rule-logger';

export class PreferTablesToRBFs extends Rule {
  public name = 'Prefer Tables To RBFs';
  public configTypes = 'car';
  public simTypes: string[] = null;

  public evaluateConfig(context: ConfigContext, log: RuleLogger) {
    let engineMapData = context.getValue('powertrain.engine.MEngineMapData');
    if(engineMapData && Array.isArray(engineMapData.value)) {
      log.warn('RBF engine map detected. Using "Table of MEngine values" is more robust.', engineMapData.path);
    }
  }
}

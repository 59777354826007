import {Injectable} from '@angular/core';
import {InterceptorBase} from './interceptor-base.service';
import {BasicRequestOptions} from '../canopy-http.service';
import {HttpHeaders} from '@angular/common/http';

export const ACCEPT_HEADER_KEY: string = 'Accept';
export const ACCEPT_HEADER_VALUE: string = 'application/json';

@Injectable()
export class AcceptHeaderInterceptor extends InterceptorBase {

  constructor() {
    super();
  }

  public request(requestOptions: BasicRequestOptions): Promise<BasicRequestOptions> {
    if (!requestOptions.headers) {
      requestOptions.headers = new HttpHeaders();
    }

    if(!requestOptions.headers.get(ACCEPT_HEADER_KEY)) {
      requestOptions.headers = requestOptions.headers.set(ACCEPT_HEADER_KEY, ACCEPT_HEADER_VALUE);
    }

    return Promise.resolve(requestOptions);
  }
}

import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {DocumentSubType, DocumentType, GetSupportSessionQueryResult} from '../../../generated/api-stubs';
import { FetchUserState } from '../../user-state/fetch-user-state.service';

@Component({
  selector: 'cs-support-session-hover-launcher',
  templateUrl: './support-session-hover-launcher.component.html',
  styleUrls: ['./support-session-hover-launcher.component.scss']
})
export class SupportSessionHoverLauncherComponent implements OnInit {

  @Input() public tenantId: string;
  @Input() public documentId: string;
  @Input() public documentType: DocumentType;
  @Input() public documentSubType: DocumentSubType;
  @Input() public supportSession: GetSupportSessionQueryResult;
  @Output() public supportSessionChanged: EventEmitter<GetSupportSessionQueryResult> = new EventEmitter<GetSupportSessionQueryResult>();

  public showLauncher: boolean = false;

  constructor(private fetchUserState: FetchUserState) {
  }

  public async ngOnInit() {
    const userState = await this.fetchUserState.get();
    this.showLauncher = !userState.isMicroCanopy;
  }

  public emit(value: GetSupportSessionQueryResult){
    this.supportSessionChanged.emit(value);
  }
}

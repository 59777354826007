import * as d3 from '../../d3-bundle';
import { SourceChannelMetadata, SourceLoaderBase, SourceMetadata } from './source-loader';
import { ChannelNameStyle } from './channel-name-style';
import { SourceViewerChannelData } from './viewer-channel-data';
import { FilteredStudyExplorationAndScalarDataCache } from './filtered-study-exploration-and-scalar-data-cache';
import { SiteHooks } from '../../site-hooks';
import {
  CONFIG_TYPE_CAR, CONFIG_TYPE_TRACK,
  GENERIC_CHANNEL_DESCRIPTION
} from '../../constants';
import { LoadedChannelData, ViewerChannelDataFactory, ILoadedChannelData } from './viewer-channel-data-factory';
import { LoadChannelDataFromExplorationMap } from './load-channel-data-from-exploration-map';
import { LoadedData, StudySourceLoaderCache } from './study-source-loader-cache';
import { SingleDimensionEvaluationDataCache } from './single-dimension-evaluation-data-cache';
import { UrlFileLoader } from '../../url-file-loader';

export const INDEX_CHANNEL_NAME = 'index';

export const CHANNEL_TYPE_METADATA_KEY = 'channel-type';
export const INPUT_DIMENSION_PARAMETER_INDEX_METADATA_KEY = 'input-dimension-parameter-index';
export const INPUT_DIMENSION_PARAMETER_VECTOR_INDEX_METADATA_KEY = 'input-dimension-parameter-vector-index';

/**
 * The channel type for a channel in a study.
 */
export enum ChannelType {

  /**
   * The channel type is unknown.
   */
  unknown,

  /**
   * The channel is an index.
   */
  index,

  /**
   * The channel is an exploration input.
   */
  input,

  /**
   * The channel is an exploration output.
   */
  output,
}

export class StudySourceLoader extends SourceLoaderBase {

  private indexChannelData?: ILoadedChannelData;

  constructor(
    private readonly siteHooks: SiteHooks,
    private readonly urlFileLoader: UrlFileLoader,
    public readonly studyId: string,
    private readonly channelDataFactory: ViewerChannelDataFactory,
    public readonly cache: StudySourceLoaderCache) {
    super();
  }

  /**
   * Creates a new study source loader.
   * @param siteHooks The site hooks.
   * @param urlFileLoader The URL file loader.
   * @param studyId The study ID.
   * @param explorationAndScalarDataCache The cache for exploration and scalar data.
   * @param singleDimensionEvaluationDataCache The cache for single dimension evaluation data.
   * @param inputDimensionIndex The optional input dimension index.
   * @returns The study source loader.
   */
  public static create(
    siteHooks: SiteHooks,
    urlFileLoader: UrlFileLoader,
    studyId: string,
    explorationAndScalarDataCache: FilteredStudyExplorationAndScalarDataCache,
    singleDimensionEvaluationDataCache?: SingleDimensionEvaluationDataCache,
    inputDimensionIndex?: number): StudySourceLoader {

    return new StudySourceLoader(
      siteHooks,
      urlFileLoader,
      studyId,
      new ViewerChannelDataFactory(siteHooks),
      new StudySourceLoaderCache(
        explorationAndScalarDataCache,
        new LoadChannelDataFromExplorationMap(siteHooks, explorationAndScalarDataCache, singleDimensionEvaluationDataCache),
        {
          dimensionIndex: inputDimensionIndex
        }));
  }

  /**
   * @inheritdoc
   */
  public async getSourceMetadata(): Promise<SourceMetadata> {
    let name = await this.siteHooks.getStudyName(this.studyId);
    return new SourceMetadata(name);
  }

  /**
   * Gets the underlying study data.
   * @returns The underlying study data, or undefined if the data is not loaded.
   */
  public get underlyingStudyData(): UnderlyingStudyData | undefined {
    let data = this.cache.getSynchronous();
    if (!data) {
      return undefined;
    }

    return new UnderlyingStudyData(this.studyId, data);
  }

  /**
   * @inheritdoc
   */
  public async getChannelData(requestedName: string, resultChannelNameStyle: ChannelNameStyle, xDomainName: string): Promise<SourceViewerChannelData<ExplorationData>> {
    let studyData = await this.cache.get();

    // First try and get an index channel.
    let loadedData = this.tryGetIndexChannel(requestedName, studyData.explorationData.explorationMap.jobs.length);
    let explorationMetadata = new ExplorationData(ChannelType.index);

    // If the channel is not an index channel, try and get an exploration output channel.
    if (!loadedData) {
      loadedData = studyData.explorationData.scalarData.getScalarData(requestedName);
      explorationMetadata = new ExplorationData(ChannelType.output);
    }

    // If the channel is not an output channel, try and get an exploration input channel.
    if (!loadedData) {
      let loadedExplorationData = studyData.inputDimensionMap[requestedName];
      if (loadedExplorationData) {
        loadedData = loadedExplorationData;
        explorationMetadata = new ExplorationData(
          ChannelType.input,
          loadedExplorationData.inputDimensionIndex,
          loadedExplorationData.inputDimensionParameterIndex);
      }
    }

    // If the channel is not an input channel, set the channel type to unknown.
    if (!loadedData) {
      explorationMetadata = new ExplorationData(ChannelType.unknown);
    }

    // Return the channel data in a standard format.
    return this.channelDataFactory.createSourceChannelData(explorationMetadata, requestedName, loadedData, resultChannelNameStyle);
  }

  /**
   * @inheritdoc
   */
  public getConfig(type: string): Promise<any> {
    switch (type) {
      case CONFIG_TYPE_TRACK:
        return this.urlFileLoader.loadTrackForStudy(this.studyId);

      case CONFIG_TYPE_CAR:
        return this.urlFileLoader.loadCarForStudy(this.studyId);
    }

    return super.getConfig(type);
  }

  /**
   * Attempts to get the index channel.
   * @param requestedName The requested channel name.
   * @param length The length of the index channel.
   * @returnsThe index channel data, or undefined if the channel is not the index channel.
   */
  private tryGetIndexChannel(requestedName: string, length: number): ILoadedChannelData | undefined {
    if (requestedName !== INDEX_CHANNEL_NAME) {
      return undefined;
    }

    if (!this.indexChannelData) {
      this.indexChannelData = new LoadedChannelData(
        INDEX_CHANNEL_NAME,
        INDEX_CHANNEL_NAME,
        'Job Index',
        '()',
        undefined,
        d3.range(length));
    }

    return this.indexChannelData;
  }

  /**
   * @inheritdoc
   */
  public async getRequestableChannels(resultChannelNameStyle: ChannelNameStyle, xDomainName: string): Promise<SourceChannelMetadata<ExplorationData>[]> {
    let studyData = await this.cache.get();

    let allScalarChannels = studyData.explorationData.scalarData.getAllChannels();
    let allInputDimensionChannels = studyData.inputDimensionList;

    switch (resultChannelNameStyle) {
      case ChannelNameStyle.FullyQualified:
        // We add both the generic and fully qualified names, but note in the description of the
        // generic channels that they could be from any simulation in the study job.
        return [
          ...allScalarChannels.map(
            v => new SourceChannelMetadata<ExplorationData>(
              new ExplorationData(ChannelType.output),
              v.genericName,
              GENERIC_CHANNEL_DESCRIPTION)),
          ...allScalarChannels.map(
            v => new SourceChannelMetadata<ExplorationData>(
              new ExplorationData(ChannelType.output),
              v.fullName,
              v.description)),
          ...allInputDimensionChannels.map(
            v => new SourceChannelMetadata<ExplorationData>(
              new ExplorationData(ChannelType.input, v.inputDimensionIndex, v.inputDimensionParameterIndex),
              v.fullName,
              v.description))
        ];

      case ChannelNameStyle.Generic:
        return [
          ...allScalarChannels.map(
            v => new SourceChannelMetadata<ExplorationData>(
              new ExplorationData(ChannelType.output),
              v.genericName,
              v.description)),
          ...allInputDimensionChannels.map(
            v => new SourceChannelMetadata<ExplorationData>(
              new ExplorationData(ChannelType.input, v.inputDimensionIndex, v.inputDimensionParameterIndex),
              v.genericName,
              v.description))
        ];
    }

    return [];
  }
}

/**
 * The underlying study data.
 */
export class UnderlyingStudyData {

  /**
   * Creates a new instance of UnderlyingStudyData.
   * @param studyId The study ID.
   * @param data The loaded study data.
   */
  constructor(
    public readonly studyId: string,
    public readonly data: LoadedData) {
  }
}

/**
 * Metadata about an exploration channel.
 */
export class ExplorationData {

  /**
   * Creates a new instance of ExplorationData.
   * @param channelType The channel type.
   * @param inputDimensionIndex The input dimension (sweep) index.
   * @param inputDimensionParameterIndex The input dimension parameter (sub-sweep) index.
   */
  constructor(
    public readonly channelType: ChannelType,
    public readonly inputDimensionIndex?: number,
    public readonly inputDimensionParameterIndex?: number) {
  }
}

import {CanopyFileLoader, CanopyFileLoaderFactory} from './canopy-file-loader.service';
import {NavigationStation, USE_FIRST_SUCCESSFUL_JOB_INDEX} from '../../visualizations/navigation-station/navigation-station';
import {StudyJob} from '../../visualizations/study-job';
import {CarConfigBuilder, Filter} from '../../visualizations/navigation-station/config-builders/car-config-builder';
import {MonteCarloConfigBuilder} from '../../visualizations/navigation-station/config-builders/monte-carlo-config-builder';
import {StarConfigBuilder} from '../../visualizations/navigation-station/config-builders/star-config-builder';
import {JobConfigBuilder} from '../../visualizations/navigation-station/config-builders/job-config-builder';
import {CanopySiteHooks, CanopySiteHooksFactory} from './canopy-site-hooks.service';
import {Injectable, NgZone} from '@angular/core';
import {
  StudyType,
  SimType
} from '../../../generated/api-stubs';
import {getJobIndexFromJobId} from '../../common/get-job-index-from-job-id';
import {AccessInformation} from './retrying-file-loader-base';
import {NavigationStationConfigBuilder} from '../../visualizations/navigation-station/config-builders/navigation-station-config-builder';
import {JobViewModel} from '../jobs/job-results/job-view-model';
import {POST_PROCESSOR_JOB_NAME} from '../jobs/view-job/view-job.component';
import {TrackPreviewConfigBuilder} from '../../visualizations/navigation-station/config-builders/track-preview-config-builder';
import {CarPreviewConfigBuilder} from '../../visualizations/navigation-station/config-builders/car-preview-config-builder';
import {TelemetryPreviewConfigBuilder} from '../../visualizations/navigation-station/config-builders/telemetry-preview-config-builder';
import {RacingLineJobConfigBuilder} from '../../visualizations/navigation-station/config-builders/racing-line-job-config-builder';
import {UnitsMap} from '../../visualizations/viewers/channel-data-loaders/local-config-source-loader';
import {GetSimVersion} from '../../common/get-sim-version.service';
import {OutOfZoneFileLoader, OutOfZoneNavigationStation, OutOfZoneSiteHooks} from './visualization-zones';

export class JobConfigData {
  constructor(
    public readonly name: string,
    public readonly data: any,
    public readonly job?: JobViewModel){
  }
}

@Injectable()
export class VisualizationFactory {
  constructor(
    private readonly canopyFileLoaderFactory: CanopyFileLoaderFactory,
    private readonly canopySiteHooksFactory: CanopySiteHooksFactory,
    private readonly getSimVersion: GetSimVersion,
    private readonly zone: NgZone){
  }

  public createCaches(): VisualizationCaches {
    let fileLoader = this.canopyFileLoaderFactory.create();
    let siteHooks = this.canopySiteHooksFactory.create(fileLoader);

    return new VisualizationCaches(siteHooks, fileLoader);
  }

  public async createCarViewer(elementId: string, jobs: JobViewModel[], filter: Filter, caches: VisualizationCaches): Promise<OutOfZoneNavigationStation> {
    let viewerData = await this.populateViewerData(jobs, caches.fileLoader, caches.siteHooks);
    return new OutOfZoneNavigationStation(this.zone, new NavigationStation(
      elementId,
      new CarConfigBuilder(
        new OutOfZoneFileLoader(this.zone, caches.fileLoader),
        new OutOfZoneSiteHooks(this.zone, caches.siteHooks),
        viewerData.studyJobs,
        [...viewerData.simTypes, 'ComponentSweeps'],
        filter,
        true)));
  }

  public async createSimTypesViewer(elementId: string, jobs: JobViewModel[], simTypes: SimType[], caches: VisualizationCaches): Promise<OutOfZoneNavigationStation> {
    let viewerData = await this.populateViewerData(jobs, caches.fileLoader, caches.siteHooks);
    return new OutOfZoneNavigationStation(this.zone, new NavigationStation(
      elementId,
      new JobConfigBuilder(
        new OutOfZoneFileLoader(this.zone, caches.fileLoader),
        new OutOfZoneSiteHooks(this.zone, caches.siteHooks),
        viewerData.studyJobs,
        simTypes)));
  }

  public async createRacingLineViewer(elementId: string, jobs: JobViewModel[], simTypes: SimType[], caches: VisualizationCaches, inputTrack: any): Promise<OutOfZoneNavigationStation> {
    let viewerData = await this.populateViewerData(jobs, caches.fileLoader, caches.siteHooks);
    return new OutOfZoneNavigationStation(this.zone, new NavigationStation(
      elementId,
      new RacingLineJobConfigBuilder(
        new OutOfZoneFileLoader(this.zone, caches.fileLoader),
        new OutOfZoneSiteHooks(this.zone, caches.siteHooks),
        viewerData.studyJobs,
        simTypes,
        inputTrack)));
  }

  public async createTrackPreview(elementId: string, configs: ReadonlyArray<JobConfigData>, unitsMap: UnitsMap, caches?: VisualizationCaches): Promise<OutOfZoneNavigationStation> {
    let fileLoader = caches ? caches.fileLoader : this.canopyFileLoaderFactory.create();
    let siteHooks = caches ? caches.siteHooks : this.canopySiteHooksFactory.create(fileLoader);

    let jobs = configs.map(v => v.job).filter(v => !!v);
    await this.populateViewerData(jobs, fileLoader, siteHooks);

    return new OutOfZoneNavigationStation(this.zone, new NavigationStation(
      elementId,
      new TrackPreviewConfigBuilder(
        new OutOfZoneFileLoader(this.zone, fileLoader),
        new OutOfZoneSiteHooks(this.zone, siteHooks),
        configs,
        unitsMap)));
  }

  public async createCarPreview(elementId: string, configs: ReadonlyArray<JobConfigData>, unitsMap: UnitsMap, caches?: VisualizationCaches): Promise<OutOfZoneNavigationStation> {
    let fileLoader = caches ? caches.fileLoader : this.canopyFileLoaderFactory.create();
    let siteHooks = caches ? caches.siteHooks : this.canopySiteHooksFactory.create(fileLoader);

    let jobs = configs.map(v => v.job).filter(v => !!v);
    await this.populateViewerData(jobs, fileLoader, siteHooks);

    return new OutOfZoneNavigationStation(this.zone, new NavigationStation(
      elementId,
      new CarPreviewConfigBuilder(
        new OutOfZoneFileLoader(this.zone, fileLoader),
        new OutOfZoneSiteHooks(this.zone, siteHooks),
        configs,
        unitsMap)));
  }

  public async createTelemetryPreview(elementId: string, configs: ReadonlyArray<JobConfigData>, caches?: VisualizationCaches): Promise<OutOfZoneNavigationStation> {
    let fileLoader = caches ? caches.fileLoader : this.canopyFileLoaderFactory.create();
    let siteHooks = caches ? caches.siteHooks : this.canopySiteHooksFactory.create(fileLoader);

    let jobs = configs.map(v => v.job).filter(v => !!v);
    await this.populateViewerData(jobs, fileLoader, siteHooks);

    return new OutOfZoneNavigationStation(this.zone, new NavigationStation(
      elementId,
      new TelemetryPreviewConfigBuilder(
        new OutOfZoneFileLoader(this.zone, fileLoader),
        new OutOfZoneSiteHooks(this.zone, siteHooks),
        configs,
        8)));
  }

  public populateCachesForStudyJobs(jobs: ReadonlyArray<JobViewModel>, caches: VisualizationCaches): Promise<{ simTypes: SimType[]; studyJobs: StudyJob[] }> {
    return this.populateViewerData(jobs, caches.fileLoader, caches.siteHooks);
  }

  private async populateViewerData(jobs: ReadonlyArray<JobViewModel>, fileLoader: CanopyFileLoader, siteHooks: CanopySiteHooks): Promise<{ simTypes: SimType[]; studyJobs: StudyJob[] }> {
    let addedStudyIds: string[] = [];
    let studyJobs: StudyJob[] = [];
    let simTypes: SimType[] = [];

    // Job metadata ultimately comes from blob storage, so we won't be hammering DocumentDB.
    let jobMetadataTasks = jobs.map(v => v.jobName.tryLoad());
    await Promise.all(jobMetadataTasks);

    for (let job of jobs) {
      await job.studyMetadataResult.tryLoad();
      await job.studyName.tryLoad();
      await job.jobName.tryLoad();
      await job.simTypes.tryLoad();

      if (job.studyMetadataResult.value
        && job.jobName.value
        && job.studyName.value
        && job.jobName.value !== POST_PROCESSOR_JOB_NAME) {
        let tenantId = job.source.tenantId;
        let studyId = job.source.studyId;
        let jobId = job.source.jobId;
        let simVersion = job.simVersion;
        if (addedStudyIds.indexOf(studyId) === -1) {
          if(job.inlineStudyResult){
            fileLoader.addInlineStudy(tenantId, studyId, job.inlineStudyResult);
          } else{
            fileLoader.addStudy(tenantId, studyId, job.studyMetadataResult.value.accessInformation, simVersion);
            fileLoader.addStudyJob(tenantId, studyId, getJobIndexFromJobId(jobId), job);
          }
          siteHooks.addStudy(tenantId, studyId, job.studyName.value);
          addedStudyIds.push(studyId);
        }

        siteHooks.addJobName(jobId, job.jobName.value);
        studyJobs.push(new StudyJob(studyId, getJobIndexFromJobId(jobId)));

        if(job.simTypes.value){
          for(let simType of job.simTypes.value){
            if(simTypes.indexOf(simType) === -1){
              simTypes.push(simType);
            }
          }
        }
      }
    }

    return {
      simTypes,
      studyJobs
    };
  }

  public createJobOverlayViewer(elementId: string, studyUrls: StudySource[]): OutOfZoneNavigationStation {
    let fileLoader = this.canopyFileLoaderFactory.create();
    let siteHooks = this.canopySiteHooksFactory.create(fileLoader);

    for(let study of studyUrls){
      fileLoader.addStudy(study.tenantId, study.studyId, study.accessInformation, this.getSimVersion.currentSimVersion);
      siteHooks.addStudy(study.tenantId, study.studyId, study.name);
      for(let job of study.jobs){
        siteHooks.addJobName(job.jobId, job.name);
      }
    }

    let studyJobs = this.getJobIndexes(studyUrls);
    let simTypes = this.getAllSimTypes(studyUrls);

    return new OutOfZoneNavigationStation(this.zone, new NavigationStation(
      elementId,
      new JobConfigBuilder(
        new OutOfZoneFileLoader(this.zone, fileLoader),
        new OutOfZoneSiteHooks(this.zone, siteHooks),
        studyJobs,
        simTypes)));
  }

  public createStarExplorationMapViewer(elementId: string, accessInformation: AccessInformation, tenantId: string, studyId: string, studyName: string, simTypes: SimType[]): OutOfZoneNavigationStation {
    let fileLoader = this.canopyFileLoaderFactory.create();
    let siteHooks = this.canopySiteHooksFactory.create(fileLoader);

    fileLoader.addStudy(tenantId, studyId, accessInformation, this.getSimVersion.currentSimVersion);
    siteHooks.addStudy(tenantId, studyId, studyName);

    return new OutOfZoneNavigationStation(this.zone, new NavigationStation(
      elementId,
      new StarConfigBuilder(
        new OutOfZoneFileLoader(this.zone, fileLoader),
        new OutOfZoneSiteHooks(this.zone, siteHooks),
        [{ studyId, jobIndex: USE_FIRST_SUCCESSFUL_JOB_INDEX }],
        [...simTypes, 'Debug'])));
  }

  public createParallelCoordinatesViewer(elementId: string, accessInformation: AccessInformation, tenantId: string, studyId: string, studyName: string, simTypes: SimType[]): OutOfZoneNavigationStation {
    let fileLoader = this.canopyFileLoaderFactory.create();
    let siteHooks = this.canopySiteHooksFactory.create(fileLoader);

    fileLoader.addStudy(tenantId, studyId, accessInformation, this.getSimVersion.currentSimVersion);
    siteHooks.addStudy(tenantId, studyId, studyName);

    return new OutOfZoneNavigationStation(this.zone, new NavigationStation(
      elementId,
      new MonteCarloConfigBuilder(
        new OutOfZoneFileLoader(this.zone, fileLoader),
        new OutOfZoneSiteHooks(this.zone, siteHooks),
        [{ studyId, jobIndex: USE_FIRST_SUCCESSFUL_JOB_INDEX }],
        [...simTypes, 'Debug'])));
  }

  public canCreateExplorationMapViewer(studyType: StudyType): boolean{
    return true; //this.canCreateQuasiStaticLapViewer(studyType);
  }

  private getJobIndexes(studyUrls: StudySource[]): StudyJob[]{
    let result: StudyJob[] = [];
    for(let study of studyUrls){
      for(let job of study.jobs){
        let jobIndex = getJobIndexFromJobId(job.jobId);
        result.push(new StudyJob(study.studyId, jobIndex));
      }
    }

    return result;
  }

  private getAllSimTypes(studyUrls: StudySource[]): SimType[]{
    let result: SimType[] = [];

    if(studyUrls.length === 0){
      return result;
    }

    for(let study of studyUrls){
      for(let simType of study.simTypes){
        if(result.indexOf(simType) === -1){
          result.push(simType);
        }
      }
    }

    return result;
  }

  /*
  private getCommonSimTypes(studyUrls: StudySource[]): SimType[]{
    if(studyUrls.length === 0){
      return [];
    }

    let result: SimType[] = studyUrls[0].simTypes;

    for(let study of studyUrls){
      for(let i = result.length - 1; i >= 0; --i){
        if(study.simTypes.indexOf(result[i]) === -1){
          result.splice(i, 1);
        }
      }
    }

    return result;
  }
  */
}

export class StudySource {
  constructor(
    public tenantId: string,
    public studyId: string,
    public name: string,
    public jobs: StudySourceJob[],
    public simTypes: SimType[],
    public accessInformation: AccessInformation
  ){}
}

export class StudySourceJob {
  constructor(
    public jobId: string,
    public name: string){
  }
}

export interface IDomainBuilderFactory{
  create(
    filter: string): NavigationStationConfigBuilder;
}

export class VisualizationCaches {
  constructor(
    public readonly siteHooks: CanopySiteHooks,
    public readonly fileLoader: CanopyFileLoader
  ){}
}

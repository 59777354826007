<div class="container-fluid" id="view-studies-page">
  <div class="list-card">
    <h1 class="list-card-header">Studies</h1>
    <div class="list-card-body">
      <div class="list-card-padded">
        <p>
          <a id="back-button" class="btn btn-smart btn-secondary" [routerLink]="['/']">
            <i class="fa fa-caret-left"></i>Back
          </a>
        </p>
      </div>
      <cs-view-studies
        (studySelected)="studySelected($event)"></cs-view-studies>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken } from '@ngxs/store';
import { UserChangedMessage } from '../../../../identity/state/identity.actions';
import { GetFriendlyErrorAndLog } from '../../services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import { ClearErrorMessage, SetErrorMessage } from './error-message.actions';

export interface ErrorMessageStateModel {
  errorMessage: string;
}

const STATE_TOKEN = new StateToken<ErrorMessageStateModel>('errorMessage');

@State({
  name: STATE_TOKEN
})
@Injectable()
export class ErrorMessageState {

  constructor(
    private _getFriendlyErrorAndLog: GetFriendlyErrorAndLog,
  ) {
  }

  @Action(SetErrorMessage)
  public setErrorMessage(ctx: StateContext<ErrorMessageStateModel>, action: SetErrorMessage): void {
    const { error } = action;
    const friendlyErrorMessage = this._getFriendlyErrorAndLog.execute(error);
    ctx.patchState({
      errorMessage: friendlyErrorMessage
    });
  }

  @Action([ ClearErrorMessage, UserChangedMessage ])
  public clearError(ctx: StateContext<ErrorMessageStateModel>): void {
    ctx.patchState({
      errorMessage: undefined
    });
  }
}

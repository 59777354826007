import {Injectable, EventEmitter} from '@angular/core';
import {GetUserSettingsQueryResult} from '../../generated/api-stubs';
import {ServiceOnInit} from '../common/service-on-init';
import {LogError} from '../common/log-error.service';
import {UserSettingsCache} from '../user-state/user-settings-cache.service';
import {Units} from '../visualizations/units';
import {CanopyError} from '../common/errors/errors';

@Injectable()
export class UnitsManager implements ServiceOnInit {
  public changed: EventEmitter<any> = new EventEmitter<any>();

  private unitsCache: {[name: string]: string};

  constructor(
    private logError: LogError,
    private userSettingsCache: UserSettingsCache){
  }

  public serviceOnInit() {
    this.userSettingsCache.changed.subscribe((promise: Promise<GetUserSettingsQueryResult>) => this.updateUnitsCache(promise));
  }

  private async updateUnitsCache(promise: Promise<GetUserSettingsQueryResult>){
    try {
      let settings = await promise;
      if(!settings){
        this.unitsCache = {};
      } else{
        this.unitsCache = settings.settings.channels.reduce<{[name: string]: string}>((c, v) => {
 c[v.name] = v.units; return c;
}, {});
      }

      this.changed.emit(undefined);
    } catch(error){
      this.logError.execute(error);
    }
  }

  public refresh(): Promise<any>{
    return this.updateUnitsCache(this.userSettingsCache.get());
  }

  public get isInitialized(): boolean {
    return !!this.unitsCache;
  }

  public async ensureInitialized(): Promise<any>{
    if(!this.unitsCache){
      await this.updateUnitsCache(this.userSettingsCache.get());
    }
  }

  public async getUnits(channelName: string, defaultUnits: string): Promise<string>{
    if(!this.unitsCache){
      await this.updateUnitsCache(this.userSettingsCache.get());
    }
    return this.unitsCache[this.removeArrayIndices(channelName)] || defaultUnits;
  }

  public getUnitsSynchronous(channelName: string, defaultUnits: string): string {
    if(!this.unitsCache){
      throw new CanopyError('Units cache was not populated.');
    }
    return this.unitsCache[this.removeArrayIndices(channelName)] || defaultUnits;
  }

  public getValueSynchronous(channelName: string, defaultUnits: string, channelValue: number): number{
    let currentUnits = this.getUnitsSynchronous(channelName, defaultUnits);
    if(!currentUnits || currentUnits === defaultUnits){
      return channelValue;
    }

    return Units.convertValueBetweenUnits(channelValue, defaultUnits, currentUnits);
  }

  public async getValue(channelName: string, defaultUnits: string, channelValue: number): Promise<number>{
    let currentUnits = await this.getUnits(channelName, defaultUnits);
    if(!currentUnits || currentUnits === defaultUnits){
      return channelValue;
    }

    return Units.convertValueBetweenUnits(channelValue, defaultUnits, currentUnits);
  }

  /**
   * This will ensure that channel names which contain array indices have the indices removed.
   * The initial check here should be fast, because in many cases like worksheets many units need
   * to be evaluated as fast as possible.
   * @param channelName The channel name to sanitize.
   * @returns The sanitized channel name.
   */
  private removeArrayIndices(channelName: string): string {
    if (channelName && channelName[channelName.length - 1] === ']') {
      let index = channelName.indexOf('[');
      if (index !== -1) {
        return channelName.substring(0, index);
      }
    }

    return channelName;
  }
}

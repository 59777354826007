import { FeatureChannelNames } from './types/feature-channel-names';
import { ChannelNameStyle } from '../channel-data-loaders/channel-name-style';
import { SourceLoaderViewModel } from '../channel-data-loaders/source-loader-set';
import { NLAP_DOMAIN_NAME } from '../../constants';
import { GetFirstChannelName } from './get-first-channel-name';


export class GetSourceFeatureChannelNames {

  constructor(
    private readonly primaryDomainName: string,
    private readonly channelNameStyle: ChannelNameStyle,
    private readonly getFirstChannelName: GetFirstChannelName) {
  }

  public async execute(source: SourceLoaderViewModel): Promise<FeatureChannelNames> {
    let allChannels = await source.getRequestableChannels(this.channelNameStyle, this.primaryDomainName);

    const possibleIntegrationChannelNames = ['tRun', 'tLap', 't'];
    let integrationChannelName = this.getFirstChannelName.execute(allChannels, possibleIntegrationChannelNames) || this.primaryDomainName;

    const possibleSplitChannelNames = [NLAP_DOMAIN_NAME];
    let splitChannelName = this.getFirstChannelName.execute(allChannels, possibleSplitChannelNames);

    return new FeatureChannelNames(integrationChannelName, splitChannelName);
  }
}

import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {WorksheetLabelsEditorDialogData, WorksheetLabelsEditorResult,} from './worksheet-labels-editor-dialog.service';
import {EventEmitter, Injectable} from '@angular/core';
import {
  DocumentSubType,
  LabelDefinition,
  LabelDefinitions,
  SimType,
  TenantSettingsStub
} from '../../../generated/api-stubs';
import {UserSettings} from '../../user-state/user-settings.service';
import {StudyTypeLookup} from '../../simulations/studies/study-type-lookup.service';
import {sortBy} from '../../common/sort-by';
import {LoadingDialog} from '../../common/dialogs/loading-dialog.service';
import {
  CombinedWorksheetLabelDefinition,
  CombinedWorksheetLabelDefinitions,
  CombinedWorksheetLabelDefinitionSet,
  LabelSetType
} from '../worksheet-labels-editor/worksheet-labels-editor.component';
import {CanopyJson} from '../../common/canopy-json.service';
import {ConfigTypeLookup} from '../../simulations/configs/config-types';
import { AuthenticationService } from '../../identity/state/authentication.service';

/**
 * Factory for creating worksheet labels editor dialog sessions.
 */
@Injectable()
export class WorksheetLabelsEditorDialogSessionFactory {

  /**
   * Creates an instance of WorksheetLabelsEditorDialogSessionFactory.
   * @param authenticationService The authentication service.
   * @param tenantSettingsStub The tenant settings stub.
   * @param userSettings The user settings service.
   * @param studyTypeLookup The study type lookup service.
   * @param loadingDialog The loading dialog service.
   * @param json The JSON service.
   * @param getFriendlyErrorAndLog The service for getting friendly errors and logging the errors.
   */
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly tenantSettingsStub: TenantSettingsStub,
    private readonly userSettings: UserSettings,
    private readonly studyTypeLookup: StudyTypeLookup,
    private readonly loadingDialog: LoadingDialog,
    private readonly json: CanopyJson,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog){}

  /**
   * Creates a new worksheet labels editor dialog session.
   * @param data The data for the dialog.
   * @returns The new dialog session.
   */
  public create(data: WorksheetLabelsEditorDialogData): WorksheetLabelsEditorDialogSession{
    return new WorksheetLabelsEditorDialogSession(
      data,
      this.authenticationService,
      this.tenantSettingsStub,
      this.userSettings,
      this.studyTypeLookup,
      this.loadingDialog,
      this.json,
      this.getFriendlyErrorAndLog);
  }
}

/**
 * A session for the worksheet labels editor dialog.
 */
export class WorksheetLabelsEditorDialogSession {

  /**
   * The error message to display.
   */
  public errorMessage: string;

  /**
   * The worksheet labels.
   */
  public worksheetLabels: LabelDefinitions;

  /**
   * The user labels.
   */
  public userLabels: LabelDefinitions;

  /**
   * The tenant labels.
   */
  public tenantLabels: LabelDefinitions;

  /**
   * The combined worksheet, user and tenant labels.
   */
  public combined: CombinedWorksheetLabelDefinitions;

  /**
   * Emits when the dialog is accepted. The result is the new worksheet labels.
   */
  public accepted: EventEmitter<WorksheetLabelsEditorResult> = new EventEmitter<WorksheetLabelsEditorResult>();

  /**
   * Creates an instance of WorksheetLabelsEditorDialogSession.
   * @param dialog The dialog data.
   * @param authenticationService The authentication service.
   * @param tenantSettingsStub The tenant settings stub.
   * @param userSettings The user settings service.
   * @param studyTypeLookup The study type lookup service.
   * @param loadingDialog The loading dialog service.
   * @param json The JSON service.
   * @param getFriendlyErrorAndLog The service for getting friendly errors and logging the errors.
   */
  constructor(
    public readonly dialog: WorksheetLabelsEditorDialogData,
    private readonly authenticationService: AuthenticationService,
    private readonly tenantSettingsStub: TenantSettingsStub,
    private readonly userSettings: UserSettings,
    private readonly studyTypeLookup: StudyTypeLookup,
    private readonly loadingDialog: LoadingDialog,
    private readonly json: CanopyJson,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  /**
   * Loads the dialog session.
   */
  public async load() {
    try {
      this.errorMessage = undefined;

      // Get the map of sim type to sim type definition.
      const simTypeMap = await this.studyTypeLookup.getSimTypeMap();

      // Gets the user data from the authentication service.
      const userData = this.authenticationService.userDataSnapshot;

      // Get the worksheet labels.
      this.worksheetLabels = this.dialog.worksheetLabels || this.getDefaultLabels();
      this.sortLabelDefinitions(this.worksheetLabels);

      // Get the user settings.
      const userSettings = await this.userSettings.get(userData?.tenant, userData?.sub);

      // Get the user labels.
      this.userLabels = userSettings.settings.labelDefinitions || this.getDefaultLabels();
      this.sortLabelDefinitions(this.userLabels);

      // Get the tenant settings.
      const tenantSettingsResult = await this.tenantSettingsStub.getTenantWorksheetLabelDefinitions(userData?.tenant);

      // Get the tenant labels.
      this.tenantLabels = tenantSettingsResult.labelDefinitions || this.getDefaultLabels();
      this.sortLabelDefinitions(this.tenantLabels);

      // Combine the worksheet, user and tenant labels into lists organized as either config or simulation labels.
      const combinedConfigLabelDefinitions: CombinedWorksheetLabelDefinitionSet[] = [];
      const combinedSimulationLabelDefinitions: CombinedWorksheetLabelDefinitionSet[] = [];
      for(let source of [this.worksheetLabels, this.userLabels, this.tenantLabels]){
        for(let configLabelDefinition of source.configLabelDefinitions){
          const configType = ConfigTypeLookup.get(configLabelDefinition.configType);
          this.addSourceLabelDefinitionsToSet(
            combinedConfigLabelDefinitions,
            configLabelDefinition.configType,
            configType ? configType.titleName : undefined,
            LabelSetType.config,
            configLabelDefinition.labels,
            source);
        }

        for(let simulationLabelDefinition of source.simulationLabelDefinitions){
          const simType = simTypeMap[simulationLabelDefinition.simType];
          this.addSourceLabelDefinitionsToSet(
            combinedSimulationLabelDefinitions,
            simulationLabelDefinition.simType,
            simType ? simType.name : undefined,
            LabelSetType.simulation,
            simulationLabelDefinition.labels,
            source);
        }
      }

      const configTypes = await this.studyTypeLookup.getConfigTypeList();
      for(let item of configTypes){
        if(!combinedConfigLabelDefinitions.some(v => v.key === item.singularKey)){
          combinedConfigLabelDefinitions.push(new CombinedWorksheetLabelDefinitionSet(
            item.singularKey,
            item.titleName,
            LabelSetType.config,
            [],
          ));
        }
      }

      const simTypes = await this.studyTypeLookup.getSimTypeList();
      for(let item of simTypes){
        if(!combinedSimulationLabelDefinitions.some(v => v.key === item.simType)){
          combinedSimulationLabelDefinitions.push(new CombinedWorksheetLabelDefinitionSet(
            item.simType,
            item.name,
            LabelSetType.simulation,
            [],
          ));
        }
      }

      // Move any categories with no labels to the bottom of the list, and otherwise sort by name.
      combinedConfigLabelDefinitions.sort(sortBy(
        {name: 'labels', primer: (v: LabelDefinition[]) => v.length === 0 ? 1 : 0 },
        'displayName'));
      combinedSimulationLabelDefinitions.sort(sortBy(
        {name: 'labels', primer: (v: LabelDefinition[]) => v.length === 0 ? 1 : 0 },
        'displayName'));

      this.combined = new CombinedWorksheetLabelDefinitions(
        combinedConfigLabelDefinitions,
        combinedSimulationLabelDefinitions);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  /**
   * Adds the source label definition to set to the appropriate label definition set.
   * @param target The target list to add the label definition to.
   * @param key The key of the label definition set (e.g. `car`).
   * @param displayName The display name of the label definition set (e.g. `Car`).
   * @param labelSetType The type of label set (e.g. `config`).
   * @param labels The labels to add.
   * @param source The source list (user, tenant, worksheet) the labels came from.
   */
  private addSourceLabelDefinitionsToSet(
    target: CombinedWorksheetLabelDefinitionSet[],
    key: string,
    displayName: string,
    labelSetType: LabelSetType,
    labels: ReadonlyArray<LabelDefinition>,
    source: LabelDefinitions) {

    let combined = target.find(v => v.key === key);
    if (!combined) {
      // If the label definition set doesn't already exist, create it.
      combined = new CombinedWorksheetLabelDefinitionSet(
        key,
        displayName || key,
        labelSetType,
        []);
      target.push(combined);
    }

    this.addSourceLabelDefinitionsToSetInner(labels, combined, source);
  }

  /**
   * Adds the source label definitions to the combined definition set.
   * @param labels The labels to add.
   * @param combined The combined label definition set.
   * @param source The source list (user, tenant, worksheet) the labels came from.
   */
  private addSourceLabelDefinitionsToSetInner(
    labels: ReadonlyArray<LabelDefinition>, combined: CombinedWorksheetLabelDefinitionSet, source: LabelDefinitions) {

    // For each label...
    for (let label of labels) {

      // If it has not already been added (from another source list, as it may be in multiple lists)...
      let combinedLabel = combined.labels.find(v => v.name === label.name && v.source === label.source);
      if (!combinedLabel) {
        // Add the label to the combined list.
        combinedLabel = new CombinedWorksheetLabelDefinition(label.source, label.name);
        combined.labels.push(combinedLabel);
      }

      // Mark the label as being in the source list.
      combinedLabel.inWorksheetLabels = combinedLabel.inWorksheetLabels || source === this.worksheetLabels;
      combinedLabel.inUserLabels = combinedLabel.inUserLabels || source === this.userLabels;
      combinedLabel.inTenantLabels = combinedLabel.inTenantLabels || source === this.tenantLabels;
    }
  }

  /**
   * Gets the default label definitions.
   * @returns The default label definitions.
   */
  private getDefaultLabels(): LabelDefinitions {
    return {
      configLabelDefinitions: [],
      simulationLabelDefinitions: [],
    };
  }

  /**
   * Saves the changes the user has made.
   */
  public async save(){
    try {
      // Get the user data from the authentication service.
      const userData = this.authenticationService.userDataSnapshot;

      // Create a list of worksheet labels, and determine if the list has changed.
      const newWorksheetLabels: LabelDefinitions = this.createTargetedLabelDefinitionsFromCombined('inWorksheetLabels');
      this.sortLabelDefinitions(newWorksheetLabels);
      const haveWorksheetLabelsChanged = this.haveChanged(newWorksheetLabels, this.worksheetLabels);

      // Create a list of user labels, and determine if the list has changed.
      const newUserLabels: LabelDefinitions = this.createTargetedLabelDefinitionsFromCombined('inUserLabels');
      this.sortLabelDefinitions(newUserLabels);
      const haveUserLabelsChanged = this.haveChanged(newUserLabels, this.userLabels);

      // Create a list of tenant labels, and determine if the list has changed.
      const newTenantLabels: LabelDefinitions = this.createTargetedLabelDefinitionsFromCombined('inTenantLabels');
      this.sortLabelDefinitions(newTenantLabels);
      const haveTenantLabelsChanged = this.haveChanged(newTenantLabels, this.tenantLabels);

      // If the user labels have changed, save the user settings.
      if(haveUserLabelsChanged){
        await this.loadingDialog.showUntilFinished(
          this.userSettings.update(
            userData?.tenant,
            userData?.sub,
            s => s.settings.labelDefinitions = newUserLabels),
          'Saving user settings...');
      }

      // If the tenant labels have changed, save the tenant settings.
      if(haveTenantLabelsChanged) {
        await this.loadingDialog.showUntilFinished(
          this.tenantSettingsStub.putTenantWorksheetLabelDefinitions(
            userData?.tenant,
            {labelDefinitions: newTenantLabels}),
          'Saving tenant settings...');
      }

      // Emit that the dialog was accepted, and include the new worksheet labels if they have changed.
      this.accepted.emit(new WorksheetLabelsEditorResult(
        haveWorksheetLabelsChanged || haveUserLabelsChanged || haveTenantLabelsChanged,
        haveWorksheetLabelsChanged ? newWorksheetLabels : undefined));
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  /**
   * Creates a list of label definitions for a specific source list (worksheet, user, tenant) from the current
   * combined list.
   * @param checkPropertyName The property name to check to determine if the label should be in the resulting list.
   * @returns The list of label definitions.
   */
  private createTargetedLabelDefinitionsFromCombined(checkPropertyName: keyof CombinedWorksheetLabelDefinition): LabelDefinitions {
    return {
      configLabelDefinitions: this.combined.configLabelDefinitions
        .map(v => ({
          configType: v.key as DocumentSubType,
          labels: v.labels
            .filter(l => l[checkPropertyName])
            .map(l => ({
              source: l.source,
              name: l.name,
            }))
        }))
        .filter(v => v.labels.length),
      simulationLabelDefinitions: this.combined.simulationLabelDefinitions
        .map(v => ({
          simType: v.key as SimType,
          labels: v.labels
            .filter(l => l[checkPropertyName])
            .map(l => ({
              source: l.source,
              name: l.name,
            }))
        }))
        .filter(v => v.labels.length),
    };
  }

  /**
   * Sorts the label definitions.
   * @param item The label definitions to sort.
   */
  private sortLabelDefinitions(item: LabelDefinitions){
    item.configLabelDefinitions.sort(sortBy('configType'));
    for(let set of item.configLabelDefinitions){
      if(set.labels){
        set.labels.sort(sortBy('source', 'name'));
      }
    }

    item.simulationLabelDefinitions.sort(sortBy('simType'));
    for(let set of item.configLabelDefinitions){
      if(set.labels){
        set.labels.sort(sortBy('source', 'name'));
      }
    }
  }

  /**
   * Determines if two lists of of label definitions are different from each other.
   * @param a The first set of label definitions.
   * @param b The second set of label definitions.
   * @returns True if the lists are different from each other.
   */
  private haveChanged(a: LabelDefinitions, b: LabelDefinitions): boolean {
    return !this.json.equals(a, b);
  }
}

<div id="car-config-validator">
  <div class="container-fluid">
    <p>
      <button type="button" (click)="runCheck(false)" id="verify-car-button" class="btn btn-smart btn-secondary">
        <i class="fa fa-check-square-o"></i>
        Verify @if (isCarDirty) {
        <span>Unsaved </span>
        }Car
      </button>
      @if (session) {
        <button type="button" id="clear-verify-car-button" (click)="removeSession()" class="btn btn-smart btn-secondary">
          <i class="fa fa-times"></i>
          Clear Results
        </button>
      }

      @if (canSaveSession) {
        <button type="button" id="pin-results-button" (click)="saveSession()" class="btn btn-smart btn-secondary">
          <i class="fa fa-map-pin"></i>
          Pin Results
        </button>
      }

      @if (savedSessionCount) {
        <span class="pinned-results">
          <div class="pinned-results-left">
            <i class="fa fa-map-pin"></i>
            <span id="pinned-results-count">{{savedSessionCount}}</span> Pinned
          </div><div id="clear-pinned-results-button" (click)="clearSavedSessions()" class="pinned-results-right">
          <i class="fa fa-times"></i>
        </div>
      </span>
    }
  </p>

  <cs-error-message [message]="errorMessage"></cs-error-message>
  @if (session) {
    <div>
      @if (session.status === checkStatusEnum.checking) {
        <p id="simulation-progress">
          <cs-loader id="progress-spinner"></cs-loader>
          <span id="progress-text">{{session.statusMessage}}</span>
        </p>
      }
      <cs-error-message [message]="session.errorMessage"></cs-error-message>
    </div>
  }
</div>

@if (session && session.status === checkStatusEnum.checked) {
  <div>
    <cs-car-config-validation-results
      [job]="session.jobViewModel"
    [previousJob]="previousSession?.jobViewModel"></cs-car-config-validation-results>
  </div>
}

</div>

import {Component} from '@angular/core';
import { CHART_SHORTCUT_KEYS_URL } from '../../common/constants';

@Component({
  selector: 'cs-chart-help',
  templateUrl: './chart-help.component.html',
  styleUrls: ['./chart-help.component.scss']
})
export class ChartHelpComponent {

  public CHART_SHORTCUT_KEYS_URL: string = CHART_SHORTCUT_KEYS_URL;

  public showHelp: boolean;

  public toggleHelp(){
    this.showHelp = !this.showHelp;
  }
}

/**
 * Represents an immutable position in 2D space.
 */
export interface IPosition {
  readonly x: number;
  readonly y: number;
}

/**
 * An immutable position in 2D space.
 */
export class Position {
  constructor(
    public readonly x: number,
    public readonly y: number) {
  }
}

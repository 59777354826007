import {Directive, ElementRef, OnDestroy} from '@angular/core';
import {ElementHeightCache} from './element-height-cache.service';
import {Timer} from './timer.service';
import {Observable, Subscription} from 'rxjs';

@Directive({
  selector: '[csElementHeightCacher]'
})
export class ElementHeightCacherDirective implements OnDestroy {

  private readonly repeatEvent: Observable<number>;

  private readonly subscription: Subscription = new Subscription();

  constructor(
    private readonly elementRef: ElementRef,
    private readonly cache: ElementHeightCache,
    private readonly timer: Timer) {

    const id = this.elementRef.nativeElement.id;

    if (id) {
      this.repeatEvent = this.timer.repeat(2500);
      this.subscription.add(this.repeatEvent.subscribe(() => {
        this.cache.set(id, this.elementRef.nativeElement.clientHeight);
      }));
    } else {
      console.warn('No ID found in element height cacher.');
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

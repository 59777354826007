import {IEditChannelsChannelMetadata, PaneChannelLayout, IPaneChannel} from '../../visualizations/site-hooks';
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {ChannelEditorDialogData} from './channel-editor-dialog.service';
import {ElementRef, Injectable} from '@angular/core';
import {sortBy} from '../../common/sort-by';
import {Timer} from '../../common/timer.service';
import {
  createRegularExpressionFromWildcardString,
  isWildcardString
} from '../../common/create-regular-expression-from-wildcard-string';

@Injectable()
export class ChannelEditorDialogSessionFactory {
  constructor(
    private readonly timer: Timer,
    private getFriendlyErrorAndLog: GetFriendlyErrorAndLog
  ){}

  public create(data: ChannelEditorDialogData): ChannelEditorDialogSession{
    return new ChannelEditorDialogSession(
      data,
      this.timer,
      this.getFriendlyErrorAndLog);
  }
}

export const GENERIC_DESCRIPTION_SUFFIX = ' for any available simulation.';

@Injectable()
export class ChannelEditorDialogSession {
  public errorMessage: string;
  public panes: Pane[];
  public channelMetadata: IEditChannelsChannelMetadata[];
  public filteredChannelMetadata: IEditChannelsChannelMetadata[];
  private _showChannelSelection: boolean;
  public addChannelPaneIndex: number;
  public flattenView: boolean;

  public filterElement: ElementRef;
  public get showChannelSelection(): boolean {
    return this._showChannelSelection;
  }

  public set showChannelSelection(value: boolean){
    this._showChannelSelection = value;
    if(value && this.filterElement){
      this.focusFilterInput();
    }
  }

  private async focusFilterInput(){
    await this.timer.yield();
    this.filterElement.nativeElement.focus();
    this.filterElement.nativeElement.select();
  }

  constructor(
    private readonly dialog: ChannelEditorDialogData,
    private readonly timer: Timer,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  public load() {
    try {
      this.errorMessage = undefined;

      this.flattenView = this.dialog.options.oneChannelPerPane;
      this.channelMetadata = [...this.dialog.options.channels];
      this.channelMetadata.sort(sortBy({name: 'name', primer: (a: string) => a.toLowerCase()}));
      this.filteredChannelMetadata = [...this.channelMetadata];

      let inputPanes = this.dialog.options.panes;
      let outputPanes: Pane[] = [];
      for(let inputPane of inputPanes){
        let outputPane: Pane = {
          channels: [],
          relativeSize: inputPane.relativeSize
        };

        for(let channel of inputPane.channels){
          let channelData = this.channelMetadata.find(v => v.name === channel.name);
          let description = '';
          if(channelData){
            description = channelData.description;
          }
          let paneChannel = new PaneChannel(
            channel.name,
            this.getValueOrDefault(channel.isVisible, true),
            this.getValueOrDefault(channel.isDelta, false),
            description);
          outputPane.channels.push(paneChannel);
        }

        outputPanes.push(outputPane);
      }

      if(this.flattenView && outputPanes.length === 0){
        outputPanes.push({
          channels: [],
          relativeSize: 1
        });
      }

      this.panes = outputPanes;
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public onFilterChanged(event: KeyboardEvent) {
    let filter = event.target as HTMLInputElement;
    let filterValue = filter.value;

    let filterEvaluator: (value: IEditChannelsChannelMetadata) => boolean;
    if(!filterValue){
      filterEvaluator = () => true;
    } else if(!isWildcardString(filterValue)){
      filterEvaluator = v => v.name.toLowerCase().indexOf(filter.value.toLowerCase()) !== -1;
    } else{
      let r = createRegularExpressionFromWildcardString(filterValue, true, true);
      filterEvaluator = v => r.test(v.name);
    }

    this.filteredChannelMetadata = this.channelMetadata.filter(v => filterEvaluator(v));
  }

  public buildResult(){
    let resultPanes: PaneChannelLayout = [];
    for(let pane of this.panes){
      resultPanes.push({
        channels: pane.channels.map(v => ({
            name: v.name,
            isVisible: v.isVisible,
            isDelta: v.isDelta
          })),
        relativeSize: pane.relativeSize
      });
    }

    resultPanes = resultPanes.filter(v => v.channels.length > 0);

    return resultPanes;
  }

  public moveChannelUp(pane: Pane, index: number) {
    if(index !== 0){
      this.swap(pane.channels, index, index - 1);
    }
  }

  public moveChannelDown(pane: Pane, index: number) {
    if(index !== pane.channels.length - 1){
      this.swap(pane.channels, index, index + 1);
    }
  }

  public movePaneUp(index: number) {
    if(index !== 0){
      this.swap(this.panes, index, index - 1);
    }
  }

  public movePaneDown(index: number) {
    if(index !== this.panes.length - 1){
      this.swap(this.panes, index, index + 1);
    }
  }

  public addPane(afterIndex: number){
    let referencePane = this.panes[afterIndex];
    let pane: Pane = {
      relativeSize: referencePane.relativeSize,
      channels: []
    };
    this.panes.splice(afterIndex + 1, 0, pane);
    return pane;
  }

  public removePane(index: number){
    this.panes.splice(index, 1);
    if(this.panes.length === 0){
      let pane: Pane = {
        relativeSize: 1,
        channels: []
      };
      this.panes.push(pane);
    }
  }

  public addChannel(paneIndex: number){
    this.addChannelPaneIndex = paneIndex;
    this.showChannelSelection = true;
  }

  public cancelAddChannel(){
    this.addChannelPaneIndex = undefined;
    this.showChannelSelection = false;
  }

  public selectChannel(channelIndex: number, keepOpen: boolean){
    let channel = this.filteredChannelMetadata[channelIndex];

    let pane: Pane;
    if(this.flattenView){
      pane = this.addPane(this.addChannelPaneIndex);
    } else{
      pane = this.panes[this.addChannelPaneIndex];
    }

    pane.channels.push(
      new PaneChannel(
        channel.name,
        true,
        false,
        channel.description));

    if(!keepOpen){
      this.cancelAddChannel();
    }
  }

  public removeChannel(pane: Pane, index: number){
    pane.channels.splice(index, 1);
  }

  private swap(array: any[], x: number, y: number) {
    let b = array[x];
    array[x] = array[y];
    array[y] = b;
  }

  private getValueOrDefault<T>(value: T, defaultValue: T): T{
    if(typeof value === 'undefined'){
      return defaultValue;
    }

    return value;
  }
}

export class PaneChannel implements IPaneChannel {
  constructor(
    public name: string,
    public isVisible: boolean,
    public isDelta: boolean,
    public description: string){
  }
}

interface Pane {
  channels: PaneChannel[];
  relativeSize: number;
}

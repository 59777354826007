import { NavigationStationConfig } from './navigation-station-config-builder';
import { SimType } from '../../sim-type';
import { StudyJob } from '../../study-job';
import { RequestedLayoutIds, SiteHooks } from '../../site-hooks';
import { UrlFileLoader } from '../../url-file-loader';
import { ExplorationConfigBuilderBase } from './exploration-config-builder-base';
import { ChannelNameStyle } from '../../viewers/channel-data-loaders/channel-name-style';
import { DimensionsSharedState } from '../../viewers/shared-state';
import { ChannelInterpolatorFactory } from '../../viewers/channel-data-loaders/interpolation/channel-interpolator-factory';
import { LINE_MULTI_PLOT_VIEWER_TYPE } from '../../viewers/line-plot-viewer/dimension-line-plot-viewer';

/**
 * A config builder for Star explorations.
 */
export class StarConfigBuilder extends ExplorationConfigBuilderBase {

  /**
   * Constructs a new Star config builder.
   * @param urlFileLoader The file loader.
   * @param siteHooks The site hooks.
   * @param studyJobs The study jobs for this builder session.
   * @param simTypes The sim types for this builder session.
   */
  constructor(
    urlFileLoader: UrlFileLoader,
    siteHooks: SiteHooks,
    studyJobs: StudyJob[],
    simTypes: SimType[]) {
    super(urlFileLoader, siteHooks, studyJobs, simTypes);
  }

  /**
   * @inheritdoc
   */
  public async build(): Promise<NavigationStationConfig> {

    await this.load();

    if (!this.explorationAndScalarDataCache) {
      throw new Error('Builder not initialized.');
    }

    let dimensionsSharedState = new DimensionsSharedState(this.rCoordinates, this.rDomainExtents);

    let config: NavigationStationConfig = {
      channelNameStyle: ChannelNameStyle.FullyQualified,
      sharedStates: [],
      views: []
    };

    let channelInterpolatorFactory = new ChannelInterpolatorFactory();
    let explorationAndScalarData = await this.explorationAndScalarDataCache.get();
    if (!explorationAndScalarData) {
      return config;
    }

    let channelInterpolator = channelInterpolatorFactory.create(this.explorationAndScalarDataCache);

    let resolvedLineMultiPlotViewerLayout = await this.resolveViewerLayout(LINE_MULTI_PLOT_VIEWER_TYPE, new RequestedLayoutIds('Default-Star'));

    // Create one viewer for each dimension in the star.
    for (let dimensionIndex = 0; dimensionIndex < this.numberOfDimensions; dimensionIndex++) {

      let defaultGridSlot = {
        x: 0,
        y: 0,
        width: 6,
        height: 10
      };

      await this.createDimensionViewer(
        dimensionsSharedState,
        dimensionIndex,
        channelInterpolator,
        config,
        resolvedLineMultiPlotViewerLayout,
        false,
        defaultGridSlot);
    }

    return config;
  }
}

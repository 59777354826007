import { Injectable } from '@angular/core';
import { CanopyJson } from '../common/canopy-json.service';
import { DownloadFile } from '../common/download-file.service';
import { CustomPropertyUtilities } from '../simulations/custom-properties/custom-property-utilities';
import { EncryptionStub } from '../../generated/api-stubs';
import { StudyInput } from '../worksheets/study-input';

@Injectable()
export class DownloadReEncryptedConfig {

  constructor(
    private readonly encryptionStub: EncryptionStub,
    private readonly json: CanopyJson,
    private readonly downloadFile: DownloadFile) {
  }

  public async execute(tenantId: string, decryptingTenantShortName: string, config: StudyInput) {
    const reEncryptionResult = await this.encryptionStub.reEncrypt(
      tenantId,
      {
        data: config.data,
        simVersion: config.simVersion,
        decryptingTenantShortName,
      }
    );

    const reEncryptedData = reEncryptionResult.data;

    let configDataFormatted = this.json.stringify({
      simVersion: config.simVersion,
      notes: config.notes,
      customProperties: CustomPropertyUtilities.listToObject(config.properties),
      config: reEncryptedData,
    });

    this.downloadFile.text(
      (config.name || config.configType) + '.json',
      configDataFormatted,
      'application/json');
  }
}

@if (isVisible) {
  <div class="modal dialog-container" id="prompt-dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{title}}</h4>
          <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()">
          </button>
        </div>
        <form (ngSubmit)="onSubmit()" [formGroup]="form">
          <div class="modal-body">
            <cs-error-message [message]="errorMessage"></cs-error-message>
            <div class="mb-3">
              <label class="form-label" for="prompt-dialog-value-input" class="title-label">{{message}}</label>
              <input csFocusOnShow id="prompt-dialog-value-input" type="text" class="form-control" formControlName="value">
              @if (!value.pristine && value.hasError('required')) {
                <p class="text-danger">
                  Value is required
                </p>
              }
            </div>
          </div>
          <div class="modal-footer">
            <button id="dialog-dismiss-button" type="button" class="btn btn-secondary" (click)="dismiss()"
            [disabled]="submitButton.isSubmitting">{{dismissMessage}}</button>
            <button id="dialog-accept-button" type="submit" class="btn btn-primary"
            [disabled]="submitButton.isSubmitting">{{submitButton.text}}</button>
          </div>
        </form>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div>
  }<!-- /.modal -->

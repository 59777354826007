import {Injectable} from '@angular/core';
import {ConfigStub, DocumentSubType, ListFilterData} from '../../../generated/api-stubs';
import {ConfigLoaderDialog, ConfigLoaderResult} from './config-loader-dialog/config-loader-dialog.service';
import {SaveAsDialog} from '../../common/dialogs/save-as-dialog.service';
import {CanopyJson} from '../../common/canopy-json.service';
import {Dayjs} from '../../common/dayjs.service';
import {ConfirmationDialog} from '../../common/dialogs/confirmation-dialog.service';
import {EncryptedNodeName} from './json-config-editor/editor-constants';
import { DownloadFile } from '../../common/download-file.service';
import { AuthenticationService } from '../../identity/state/authentication.service';

@Injectable()
export class ConfigSubTreeRepositoryFactory {
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly configStub: ConfigStub,
    private readonly saveAsDialog: SaveAsDialog,
    private readonly configLoaderDialog: ConfigLoaderDialog,
    private readonly json: CanopyJson,
    private readonly dayjs: Dayjs,
    private readonly downloadFile: DownloadFile,
    private readonly confirmationDialog: ConfirmationDialog){
  }

  public create(configType: DocumentSubType, simVersion: string){
    const userData = this.authenticationService.userDataSnapshot;
    return new ConfigSubTreeRepository(
      userData.tenant, userData.sub, configType, simVersion,
      this.configStub, this.saveAsDialog, this.configLoaderDialog, this.json, this.dayjs,
      this.downloadFile, this.confirmationDialog);
  }
}

export class ConfigSubTreeRepository {

  constructor(
    private readonly tenantId: string,
    private readonly userId: string,
    private readonly configType: DocumentSubType,
    private readonly simVersion: string,
    private readonly configStub: ConfigStub,
    private readonly saveAsDialog: SaveAsDialog,
    private readonly configLoaderDialog: ConfigLoaderDialog,
    private readonly json: CanopyJson,
    private readonly dayjs: Dayjs,
    private readonly downloadFile: DownloadFile,
    private readonly confirmationDialog: ConfirmationDialog){
  }

  public async loadConfigSubTree(subTreePath: string, initialFilter: ListFilterData): Promise<ConfigLoaderResult> {
    return await this.configLoaderDialog.loadConfig(
      this.configType,
      this.simVersion,
      subTreePath,
      initialFilter);
  }

  public async saveConfigSubTree(configId: string, subTreePath: string, name: string, config: any) {
    await this.configStub.putConfig(
      this.tenantId,
      configId,
      {
        name,
        configType: this.configType,
        config,
        simVersion: this.simVersion,
      },
      subTreePath);
  }

  public canSave(data: ConfigLoaderResult){
    return data && data.userId === this.userId;
  }

  public async saveConfigSubTreeAs(subTreePath: string, config: any): Promise<ConfigLoaderResult> {
    let result = await this.saveAsDialog.showForContent(
      '',
      this.configType,
      this.simVersion,
      subTreePath,
      config);

    return result;
  }

  public async import(subTreePath: string, loadedConfig: any): Promise<any> {

    if(!loadedConfig.path) {
      await this.confirmationDialog.show('The selected file cannot be imported as a component.');
      return undefined;
    }

    if(loadedConfig.path && loadedConfig.path !== subTreePath) {
      await this.confirmationDialog.show('The selected file should be imported at: ' + loadedConfig.path);
      return undefined;
    }

    delete loadedConfig.path;

    if (loadedConfig.simVersion === this.simVersion) {
      return loadedConfig.config;
    }

    let result = await this.configStub.upgradeConfig(
      this.tenantId,
      this.simVersion,
      {
        configType: this.configType,
        config: loadedConfig.config,
        simVersion: loadedConfig.simVersion
      },
      subTreePath);

    return result.config;
  }

  public async export(configName: string, subTreePath: string, name: string | undefined, config: any): Promise<void> {
    let configDataFormatted = this.json.stringify({
      simVersion: this.simVersion,
      path: subTreePath,
      config
    });

    this.downloadFile.text(
      (configName + '.' + (name || subTreePath)) + (config && config.name === EncryptedNodeName ? '-encrypted' : '') + '.json',
      configDataFormatted,
      'application/json');
  }

  public async encrypt(config: any, description: string, channelWhitelist: ReadonlyArray<string> | undefined): Promise<any> {
    const currentTime = this.dayjs.toDateTime(undefined);
    let encryptResult = await this.configStub.encryptWithMetadata(
      {
        data: config,
        description: description || 'Encrypted at ' + currentTime,
        channelWhitelist: channelWhitelist ? [...channelWhitelist] : undefined,
      });

    return encryptResult.data;
  }

  public async decrypt(config: any): Promise<any> {
    let decryptResult = await this.configStub.decryptWithMetadata(
      {
        data: config,
        decryptRecursively: false,
      });
    return decryptResult.data;
  }
}

import { Component, ElementRef, OnInit, ViewChild, AfterViewInit} from "@angular/core";
import { CanopyDocument, ConfigStub, ListFilter } from "../../../../generated/api-stubs";
import { ActivatedRoute } from "@angular/router";
import { CONFIG_TYPE_DATA_KEY, ConfigType } from "../config-types";
import { FilterUtilities } from "../../list-filtering/filter-utilities";
import { InferableRouteParams } from "../../../common/inferable-route-params.service";
import { ConfigSummary } from "../view-configs/view-configs.component";
import { JSONFormatter } from "../../../common/json-formatter/json-formatter";
import { Modal } from "bootstrap"
import { ToastrService } from "ngx-toastr";
import { GetFriendlyErrorAndLog } from "../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service";
import { ConfigChangedEvents } from "../config-changed-events";

@Component({
  templateUrl: './manage-config-components.page.html'
})
export class ManageConfigComponentsPage implements OnInit, AfterViewInit {
  public tenantId: string;
  public configType: ConfigType;
  public paths: string[];
  public path: string;
  public filter: ListFilter;
  public modal: Modal;

  public name: string = "";
  public configDocument: CanopyDocument;

  public isSaving: boolean;

  @ViewChild('config') configRef: ElementRef<HTMLDivElement>;
  @ViewChild('modal') modalRef: ElementRef<HTMLDivElement>;

  constructor(
    inferableRouteParams: InferableRouteParams,
    route: ActivatedRoute,
    filterUtilities: FilterUtilities,
    private readonly configStub: ConfigStub,
    private readonly configsChanged: ConfigChangedEvents,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog,
    private readonly toastrService: ToastrService){
    this.configType = route.snapshot.data[CONFIG_TYPE_DATA_KEY];
    this.tenantId =  inferableRouteParams.getTenantId(route);
    this.filter = filterUtilities.getInitialFilter();
  }

  async ngOnInit(){
    this.path = "";
    let subPathsResult = await this.configStub.getConfigSubPaths(this.tenantId, this.configType.singularKey);
    this.paths = subPathsResult.configSubPaths;
  }

  ngAfterViewInit(): void {
    this.modal = new Modal(this.modalRef.nativeElement);
  }

  public async onConfigSelected(configSummary: ConfigSummary) {
    try {
      let configResult = await this.configStub.getConfig(this.tenantId, configSummary.documentId, this.path);
      this.configDocument = configResult.config;
      let formatter = new JSONFormatter(this.configDocument.data.definition, 1, undefined, this.path.split('.').at(-1));
      this.configRef.nativeElement.innerHTML = "";
      this.configRef.nativeElement.appendChild(formatter.render());
      this.name = this.configDocument.name;
      this.modal.show();
    } catch(error) {
      let friendlyError = this.getFriendlyErrorAndLog.execute(error);
      this.toastrService.error(friendlyError, 'Error Getting Config');
    }
  }

  public async save(){
    this.isSaving = true;
    try {
      await this.configStub.putConfig(
        this.tenantId,
        this.configDocument.documentId,
        {
          name: this.name,
          configType: this.configDocument.subType,
          config: this.configDocument.data.definition,
          properties: this.configDocument.properties,
          simVersion: this.configDocument.simVersion,
          notes: this.configDocument.notes
        },
        this.configDocument.data.path);
    } catch(error) {
      let friendlyError = this.getFriendlyErrorAndLog.execute(error);
      this.toastrService.error(friendlyError, 'Saving Error');
    } finally {
      this.isSaving = false;
      this.configsChanged.configsChanged.emit();
    }
  };
}

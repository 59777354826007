import { ExplorationMap } from '../exploration-map';
import { ExpandExplorationSubSweeps } from './expand-exploration-sub-sweeps';
import { ExplorationInputsMetadata, ExplorationSubSweepMetadataWithArrayValues, ExplorationSweepMetadata } from './exploration-inputs-metadata';

/**
 * Gets the exploration inputs from the exploration map.
 */
export class GetExplorationInputsFromExplorationMap {

  /**
   * Creates a new instance of GetExplorationInputsFromExplorationMap.
   * @param expandExplorationSubSweeps Expands exploration sub-sweeps of arrays into parallel sub-sweeps of the array elements.
   */
  constructor(
    private readonly expandExplorationSubSweeps: ExpandExplorationSubSweeps) {
  }

  /**
   * Gets the exploration inputs from the exploration map. These are not in a convenient form
   * in the original exploration map, so we process them into a more usable structure.
   * @param explorationMap The exploration map.
   * @returns The exploration inputs.
   */
  public execute(explorationMap: ExplorationMap): ExplorationInputsMetadata {

    const basis = explorationMap.basis;
    const indexMatrix = explorationMap.indexMatrix;
    const coordinates = explorationMap.coordinates;

    if (!indexMatrix.length) {
      throw new Error('No indexMatrix items found in exploration map.');
    }

    if (!basis.length) {
      throw new Error('No basis items found in exploration map.');
    }

    if (basis.length !== indexMatrix[0].length) {
      throw new Error('Basis length did not match the length of the first index matrix row.');
    }

    // Create an array to store the sweeps.
    const sweeps: ExplorationSweepMetadata[] = Array(basis.length);

    // For each sweep index...
    for (let sweepIndex = 0; sweepIndex < basis.length; ++sweepIndex) {

      // Pull out the sub-sweep names.
      const subSweepNames = basis[sweepIndex];

      // Create an array to store the sub-sweeps.
      const subSweepsWithArrayValues: ExplorationSubSweepMetadataWithArrayValues[] = Array(subSweepNames.length);

      // For each sub-sweep index...
      for (let subSweepIndex = 0; subSweepIndex < subSweepNames.length; ++subSweepIndex) {

        // Pull out the sub-sweep name.
        const subSweepName = subSweepNames[subSweepIndex];

        // Create an array to store the sub-sweep values.
        const subSweepValues: (number | number[] | string)[] = [];

        // For each job index...
        for (let jobIndex = 0; jobIndex < indexMatrix.length; ++jobIndex) {

          // When the study is built a set of values are generated for each sub-sweep.
          // Unfortunately this list is not persisted in the exploration map. However, we can
          // reconstruct it from what is there.
          // First we get the index of the value for this job and sweep.
          const valueIndex = indexMatrix[jobIndex][sweepIndex];

          // If this sweep does not have a value index for this job (which can happen, for example,
          // if it is a part of a star dimension that is not the one being swept), then we skip it.
          if (valueIndex === 'NaN' || isNaN(valueIndex)) {
            continue;
          }

          // Otherwise, read the value for this sweep, sub-sweep and job.
          const coordinateValue = coordinates[jobIndex][sweepIndex][subSweepIndex];

          // Using the valueIndex we can store the value in the appropriate index of the
          // sub-sweep's values array.
          // In this way, by the time we have gone through every job, we will have
          // reconstructed the original list of values for each sub-sweep.
          subSweepValues[valueIndex] = coordinateValue;
        }

        // Create the sub-sweep metadata.
        let subSweep = new ExplorationSubSweepMetadataWithArrayValues(subSweepName, subSweepValues);

        // And add it to the the array of sub-sweeps.
        subSweepsWithArrayValues[subSweepIndex] = subSweep;
      }

      // Expand any sweeps of arrays into parallel sub-sweeps of the individual array indexes.
      const subSweeps = this.expandExplorationSubSweeps.execute(subSweepsWithArrayValues);

      // Add the sweep metadata containing the processed sub-sweeps.
      sweeps[sweepIndex] = new ExplorationSweepMetadata(subSweeps);
    }

    // Return the processed inputs metadata.
    return new ExplorationInputsMetadata(sweeps);
  }
}

import { Injectable } from '@angular/core';
import { ChartId } from '../simulations/visualizations/canopy-site-hooks.service';
import { DocumentSubType } from '../../generated/api-stubs';
import { ChartSize, LocalUserChartSettings, LocalUserSettingsManager } from './local-user-settings-manager';


@Injectable()
export class LocalUserChartSettingsManager {

  constructor(
    private readonly localUserSettingsManager: LocalUserSettingsManager) {
  }

  public getChartSettings(chartType: DocumentSubType, chartId: ChartId | undefined): LocalUserChartSettings | undefined {
    const state = this.localUserSettingsManager.get();
    const serializedChartId = JSON.stringify(chartId);

    for (let chartSettings of state.charts || []) {
      if (chartSettings.chartType === chartType && JSON.stringify(chartSettings.chartId) === serializedChartId) {
        return chartSettings;
      }
    }

    return undefined;
  }

  public setChartSettings(chartType: DocumentSubType, chartId: ChartId | undefined, size: ChartSize): void {
    const state = this.localUserSettingsManager.get();
    const serializedChartId = JSON.stringify(chartId);

    if (!state.charts) {
      state.charts = [];
    }

    let targetChartSettings: LocalUserChartSettings | undefined;

    let found = false;
    for (let i = 0; i < state.charts.length; ++i) {
      const chartSettings = state.charts[i];
      if (chartSettings.chartType === chartType && JSON.stringify(chartSettings.chartId) === serializedChartId) {
        found = true;
        state.charts[i] = new LocalUserChartSettings(
          chartSettings.chartType,
          chartSettings.chartId,
          size);
        break;
      }
    }

    if (!found) {
      targetChartSettings = new LocalUserChartSettings(chartType, chartId, size);
      state.charts.push(targetChartSettings);
    }

    this.localUserSettingsManager.save();
  }
}

import { XData } from './x-data';
import { YData } from './y-data';
import { ZData } from './z-data';
import { ChannelNameMap } from '../../channel-data-loaders/channel-name-map';

export class MultiPlotViewerData {
  constructor(
    public xData: XData[][],
    public yData: YData[] = [],
    public channelNameMap: ChannelNameMap,
    public rPaneSizes: number[] = []
  ) {
    // this.yData = [];
    // this.rPaneSizes = [];
  }

  public zData?: ZData[];

}

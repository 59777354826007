import {LazyLoaded} from './lazy-loaded';
import {GetStudyQueryResult} from '../../../../generated/api-stubs';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {Injectable} from '@angular/core';

@Injectable()
export class LazyLoadedStudyNameFactory {
  constructor(
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  public create(studyMetadataResult: LazyLoaded<GetStudyQueryResult>): LazyLoaded<string>{
    return new LazyLoaded<string>(
      async () => {
        await studyMetadataResult.load();
        return studyMetadataResult.value.study.name;
      },
      this.getFriendlyErrorAndLog);
  }
}

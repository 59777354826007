import { Injectable } from '@angular/core';
import { $each } from './polyfills';
import { AllButtonCategories } from '../json-editor-node-extensions/editor-node-extensions';
import { EditorNodeExtensionsFactory } from '../json-editor-node-extensions/editor-node-extensions-factory';
import { JsonEditorCustomization } from './json-editor-customization';
import { getCanopyJsonEditorOptions } from './get-canopy-json-editor-options';
import{ JSONEditor } from'@json-editor/json-editor'

@Injectable()
export class MultipleEditor extends JsonEditorCustomization {

  constructor(
    private readonly editorNodeExtensionsFactory: EditorNodeExtensionsFactory) {
    super();
  }

  public apply(): void {
    const service = this;

    JSONEditor.defaults.editors.multiple = class extends JSONEditor.defaults.editors.multiple{
      getDisplayText(arr: any[]) {
        let self = <any>this;
        let expanded: any[] = [];
        $each(arr, function(i, el) {
          if (el.$ref) {
            expanded.push(self.jsoneditor.expandRefs(el));
          } else {
            expanded.push(el);
          }
        });

        let disp = super.getDisplayText(expanded);

        // The base implementation will append ' N' to the display names
        // if it finds duplicates. However it considers the same element having
        // identical title and description as a duplicate, and ends up appending
        // ' 1' even if then only appears once in the list. We therefore work around
        // this by removing the ' 1'. If there are genuine duplicates then the others
        // will still end with ' 2', ' 3', etc.
        $each(disp, function(i, name: string) {
          if (name && name.endsWith(' 1')) {
            disp[i as any] = name.substr(0, name.length - 2);
          }
        });

        return disp;
      }

      isHiddenSwitcher(): boolean {
        let switcher = this.switcher as HTMLSelectElement;
        let itemCount = this.display_text.length;
        return itemCount === 1
          || (itemCount === 2 && (this.display_text[0] === 'Encrypted' || this.display_text[1] === 'Encrypted'))
          || switcher.value === 'Encrypted'
      }

      updateSwitcherVisibility() {
        let switcher = this.switcher as HTMLSelectElement;
        if (this.isHiddenSwitcher()) {
          switcher.classList.add('select-hidden');
        } else {
          switcher.classList.remove('select-hidden');
        }
      }

      build() {
        super.build();
        const canopyOptions = getCanopyJsonEditorOptions(this);
        if (this.container.children.length < 3) {
          return;
        }

        const header = document.createElement('div');
        const hoverArea = document.createElement('div');
        this.container.insertBefore(hoverArea, this.container.children[0]);
        hoverArea.appendChild(header);
        header.appendChild(this.container.children[1]);
        header.appendChild(this.container.children[1]);

        this.extensions = service.editorNodeExtensionsFactory.create(canopyOptions.simVersion, canopyOptions.configType, this, canopyOptions.configSubTreeRepository);
        let buttonsResult = this.extensions.addButtonsContainer(header, AllButtonCategories);

        if (buttonsResult) {
          hoverArea.className += ' hover-button-group-container';

          let oldSwitcher = this.switcher;
          //remove eventlisteners
          this.switcher = this.switcher.cloneNode(true);
          this.container.removeChild(oldSwitcher);
          header.insertBefore(this.switcher, header.lastChild);
          this.switcher.addEventListener('change', (e: any) => {
            e.preventDefault();
            e.stopPropagation();

            let value = e.currentTarget.value;

            if (value === 'Encrypted') {
              buttonsResult.buttons.encrypt.click();
            } else {
              this.switchEditor(this.display_text.indexOf(value));
              this.onChange(true);
            }

            this.updateSwitcherVisibility();
          });
        }
      }

      onChange(bubble: boolean) {
        super.onChange(bubble);
        this.updateSwitcherVisibility();
      }

      setValue(val: any, initial: any) {
        if(val === undefined || JSON.stringify(this.prevVal) === JSON.stringify(val)) return;
        this.prevVal = val;
        let prevType = this.type;
        if(val.name){
          for(let [i, type] of this.types.entries()){
            if(type.properties?.name?.enum){
              if(type.properties.name.enum[0] === val.name){
                this.type = i;
                this.switcher.value = this.display_text[i]

                const typeChanged = (this.type !== prevType)

                if (typeChanged) {
                  this.switchEditor(this.type)
                }

                this.editors[this.type].setValue(val, initial)

                this.refreshValue()
                this.onChange(typeChanged)
                return;
              }
            }
          }
        }

        super.setValue(val, initial);

        if (this.extensions) {
          this.extensions.update();
        }
      }

      showValidationErrors (errors: any) {
        /* oneOf and anyOf error paths need to remove the oneOf[i] part before passing to child editors */
        if (this.oneOf || this.anyOf) {
          const checkPart = this.oneOf ? 'oneOf' : 'anyOf'
          this.editors.forEach((editor: any, i: any) => {
            if (!editor) return
            const check = `${this.path}.${checkPart}[${i}]`
            const filterError = (newErrors: any, error: any) => {
              if (error.path.startsWith(check) || (error.path === check.substring(0, error.path.length)) && !this.isHiddenSwitcher()) {
                const newError = structuredClone(error);

                if (error.path.startsWith(check)) {
                  newError.path = this.path + newError.path.substr(check.length)
                }
                newErrors.push(newError)
              }
              return newErrors
            }
            editor.showValidationErrors(errors.reduce(filterError, []))
          })
        } else {
          this.editors.forEach((editor: any) => {
            if (!editor) return
            editor.showValidationErrors(errors)
          })
        }
      }
    }
  }
}

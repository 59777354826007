@if (isVisible) {
  <div class="modal dialog-container" id="config-loader-dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title">Load {{session?.configType?.titleName}}<small>{{dialog?.subTreePath}}</small></h1>
          <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()">
          </button>
        </div>
        <div class="modal-body">
          <cs-error-message [message]="session?.errorMessage"></cs-error-message>
          @if (session?.isLoading) {
            <cs-loader></cs-loader>
          }
          @if (!session?.isLoading && session?.configType) {
            <div>
              <div class="scroll-area">
                <h1>
                  User Configs
                </h1>
                <cs-view-configs class="view-configs-component"
                  [configType]="session.configType"
                  [canStage]="false"
                  [filter]="session.initialFilter"
                  [subTreePath]="dialog.subTreePath"
                  [canNavigate]="false"
                  (configSelected)="session.onConfigSelected($event)">
                </cs-view-configs>
                @if (!dialog.subTreePath) {
                  <cs-view-default-configs
                    [configType]="session.configType"
                    [canStage]="false"
                    (configSelected)="session.onDefaultConfigSelected($event)">
                  </cs-view-default-configs>
                }
              </div>
            </div>
          }
        </div>
        <div class="modal-footer">
          <cs-error-message [message]="session?.errorMessage"></cs-error-message>
          @if (dialog.allowExplicitNothing) {
            <button id="dialog-nothing-button" type="button" class="btn btn-secondary" (click)="acceptWithNothing()">Nothing</button>
          }
          <button id="dialog-dismiss-button" type="button" class="btn btn-secondary" (click)="dismiss()">Cancel</button>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div>
  }<!-- /.modal -->

import { Injectable } from '@angular/core';
import { getCanopyJsonEditorOptions } from './get-canopy-json-editor-options';
import { JsonEditorCustomization } from './json-editor-customization';
import{ JSONEditor } from'@json-editor/json-editor'

@Injectable()
export class SelectEditor extends JsonEditorCustomization {
  public apply(): void {
    JSONEditor.defaults.editors.select = class extends JSONEditor.defaults.editors.select {
      getAutocompleteMap() {
        const canopyOptions = getCanopyJsonEditorOptions(this);

        if (!canopyOptions.autoCompleteMaps) {
          return undefined;
        }

        return canopyOptions.autoCompleteMaps.find(v => this.path.endsWith(v.pathSuffix));
      }

      build() {
        let self = this;
        super.build();
        this.theme.addHelpLink(this.control, this.schema, this);

        let map = this.getAutocompleteMap();
        if (map) {
          if (map.changedAction) {
            this.input.addEventListener('change', function(e: Event) {
              map.changedAction(self.value, self.parent);
            });
          }
        }
      }

      setValue(value: any, initial: any, from_template: boolean) {
        super.setValue(value, initial, from_template);
        let map = this.getAutocompleteMap();
        if (map && map.changedAction) {
          map.changedAction(value, this.parent);
        }
      }
    }
  }
}

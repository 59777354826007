import { EventEmitter, Injectable } from '@angular/core';
import { DocumentSubType } from '../../../../generated/api-stubs';
import { GetStudyAutocompleteOptions, PARAMETER_PATH_SUFFIX } from './get-study-autocomplete-options.service';
import { SimVersionDocumentCache } from '../../sim-version-document-cache.service';
import { getParameterNameFromPath } from './get-parameter-name-from-path';
import {
  CHANNEL_NAME_SUFFIX, GetChannelNameAutocompleteOptions,
  USER_CHANNELS_SUFFIX
} from './get-channel-name-autocomplete-options.service';
import { GetUserMathsFormulaAutocompleteOptions } from './get-user-maths-formula-autocomplete-options.service';
import { SetAutocompleteSanitizer } from '../../../common/set-autocomplete-sanitizer';

export interface AutoCompleteMap {
  pathSuffix: string;
  popupOnSelect: boolean;
  userInformation: ReadonlyArray<string>;
  options: any;
  changedAction: (value: string, parentSchema: any) => void;
}

@Injectable()
export class GetAutocompleteMaps {

  constructor(
    private readonly getStudyAutocompleteOptions: GetStudyAutocompleteOptions,
    private readonly getChannelNameAutocompleteOptions: GetChannelNameAutocompleteOptions,
    private readonly getUserMathsFormulaAutocompleteOptions: GetUserMathsFormulaAutocompleteOptions,
    private readonly simVersionDocumentCache: SimVersionDocumentCache,
    private readonly setAutocompleteSanitizer: SetAutocompleteSanitizer) {
  }

  public async execute(simVersion: string, configType: DocumentSubType, editorUnitsChanged: EventEmitter<any>): Promise<AutoCompleteMap[]> {

    let studyAutocompleteInformation = await this.getStudyAutocompleteOptions.execute();
    let simVersionDocuments = await this.simVersionDocumentCache.get(simVersion);

    let result: AutoCompleteMap[] = [];
    result.push(
      {
        pathSuffix: '.' + PARAMETER_PATH_SUFFIX,
        popupOnSelect: true,
        userInformation: studyAutocompleteInformation.userInformation,
        changedAction: (value: string, parent: any) => this.updateChildUnits(value, parent, simVersionDocuments.units, editorUnitsChanged),
        options: studyAutocompleteInformation.options
      },
      {
        pathSuffix: '.valueType',
        popupOnSelect: false,
        userInformation: [],
        changedAction: (value: string, parent: any) => this.updateChildUnitsValueType(value, parent, simVersionDocuments.units, editorUnitsChanged),
        options: {}
      }
    );

    if (configType === DocumentSubType.userMaths) {
      let channelNameAutocompleteInformation = await this.getChannelNameAutocompleteOptions.execute(false);
      let formulaAutocompleteInformation = this.getUserMathsFormulaAutocompleteOptions.execute(studyAutocompleteInformation, channelNameAutocompleteInformation);
      result.push(
        {
          pathSuffix: '.' + CHANNEL_NAME_SUFFIX,
          popupOnSelect: true,
          userInformation: channelNameAutocompleteInformation.userInformation,
          changedAction: undefined,
          options: channelNameAutocompleteInformation.options
        },
        {
          pathSuffix: '.' + 'expression',
          popupOnSelect: true,
          userInformation: formulaAutocompleteInformation.userInformation,
          changedAction: undefined,
          options: formulaAutocompleteInformation.options
        },
      );
    } else if (configType === DocumentSubType.track) {
      let channelNameAutocompleteInformation = await this.getChannelNameAutocompleteOptions.execute(true);
      result.push(
        {
          pathSuffix: '.' + USER_CHANNELS_SUFFIX,
          popupOnSelect: true,
          userInformation: channelNameAutocompleteInformation.userInformation,
          changedAction: undefined,
          options: channelNameAutocompleteInformation.options
        }
      );
    }

    for(let map of result) {
      this.setAutocompleteSanitizer.execute(map.options);
    }

    return result;
  }

  private updateChildUnits(value: string, parent: any, schemaUnits: { [key: string]: string }, editorUnitsChanged: EventEmitter<any>) {

    let valueType: string;
    if (parent.editors) {
      if (parent.editors.valueType) {
        valueType = parent.editors.valueType.value;
      } else if (parent.editors.source && parent.editors.source.value && parent.editors.source.value.valueType) {
        valueType = parent.editors.source.value.valueType;
      }
    }

    if (valueType === 'multiplicative') {
      // For multiplicative the number entered is unitless.
      // It no longer represents the channel, so we should not
      // set the override data or we might end up using the user's
      // custom saved units for the channel.
      parent.overrideChildUnits = undefined;
    } else {
      let channelName = getParameterNameFromPath(value);

      let units = '()';
      let mode = OverrideChildUnitsMode.full;

      if (channelName) {
        units = schemaUnits[channelName] || units;

        if (valueType === 'additive') {
          mode = OverrideChildUnitsMode.scaleOnly;
        }
      }

      let overrideChildUnits: OverrideChildUnits = {
        units,
        channelName,
        mode,
      };

      parent.overrideChildUnits = overrideChildUnits;
    }

    editorUnitsChanged.emit();
  }

  private updateChildUnitsValueType(value: string, parent: any, schemaUnits: { [key: string]: string }, editorUnitsChanged: EventEmitter<any>) {
    // NOTE: The parent.value properties may not be up to date at this point so we need to look at parent.editors.
    let parameterPathLocation = parent;
    while (parameterPathLocation) {
      if (parameterPathLocation.editors && parameterPathLocation.editors && parameterPathLocation.editors[PARAMETER_PATH_SUFFIX]) {
        this.updateChildUnits(parameterPathLocation.editors[PARAMETER_PATH_SUFFIX].value, parameterPathLocation, schemaUnits, editorUnitsChanged);
        break;
      }

      parameterPathLocation = parameterPathLocation.parent;
    }
  }
}

export enum OverrideChildUnitsMode {
  full = 'full',
  scaleOnly = 'scaleOnly',
}

export interface OverrideChildUnits {
  units: string;
  channelName: string;
  mode: OverrideChildUnitsMode;
}

import {Component, Input} from '@angular/core';

@Component({
  selector: 'cs-error-message',
  templateUrl: './error-message.component.html'
})
export class ErrorMessageComponent {
  @Input() public message: string;
  @Input() public messages: ReadonlyArray<string>;
  @Input() public alertType: string = 'danger';
  @Input() public customClass: string = '';

  public get show(): boolean {
    return !!(this.message || (this.messages && this.messages.length));
  }

  public splitMessage(){
    return this.messages || (this.message ? this.message.split('\n') : []);
  }
}

import * as d3 from '../../d3-bundle';
import { ChannelBinaryFormat, UrlFileLoader } from '../../url-file-loader';
import { SimType } from '../../sim-type';
import { GetLegacyChannelMappings } from './get-legacy-channel-mappings';
import { ChannelDataTransforms } from './channel-data-transforms';

/**
 * Loads the channel data for a study job.
 */
export class LoadStudyJobChannelData {
  constructor(
    private readonly urlFileLoader: UrlFileLoader,
    private readonly getLegacyChannelMappings: GetLegacyChannelMappings,
    private readonly channelDataTransforms: ChannelDataTransforms) {
  }

  /**
   * Loads the channel data for a study job.
   * @param studyId The study ID.
   * @param jobIndex The job index.
   * @param simType The simulation type.
   * @param genericName The generic name of the channel.
   * @param binaryFormat The binary format of the channel.
   * @param metadata The metadata for the channel.
   * @returns The channel data.
   */
  public execute(
    studyId: string,
    jobIndex: number,
    simType: SimType,
    genericName: string,
    binaryFormat: ChannelBinaryFormat | undefined,
    metadata: any | undefined): Promise<ReadonlyArray<number> | undefined> {

    // If the metadata indicates that the channel is a scalar result, then load the scalar result as a constant
    // vector of the appropriate length.
    if (metadata && metadata.isScalarResult) {
      return this.executeForScalar(studyId, jobIndex, simType, binaryFormat, metadata);
    }

    // Otherwise, load the vector channel data.
    return this.executeForVector(studyId, jobIndex, simType, genericName, binaryFormat);
  }

  /**
   * Loads the vector channel data for a study job.
   * @param studyId The study ID.
   * @param jobIndex The job index.
   * @param simType The simulation type.
   * @param genericName The generic name of the channel.
   * @param binaryFormat The binary format of the channel.
   * @returns The channel data.
   */
  public async executeForVector(
    studyId: string,
    jobIndex: number,
    simType: SimType,
    genericName: string,
    binaryFormat: ChannelBinaryFormat | undefined): Promise<ReadonlyArray<number> | undefined> {

    // Load the channel data.
    let data = await this.urlFileLoader.loadChannelData(studyId, jobIndex, simType, genericName, binaryFormat);

    if (!data) {
      // If the channel data wasn't found, check if the channel data can be transformed from another channel.
      for (let transform of this.channelDataTransforms.transforms) {
        if (genericName === transform.targetDomain) {
          for (let source of transform.sourceDomains) {
            let sourceData = await this.urlFileLoader.loadChannelData(studyId, jobIndex, simType, source, binaryFormat);
            if (sourceData) {
              // Generate a monotonic channel we can use to map back to the source domain.
              data = d3.range(sourceData.length);
              break;
            }
          }
        }

        if (data) {
          break;
        }
      }
    }

    if (!data) {
      // The metadata for legacy jobs has already been reverse-mapped with dummy channels
      // (e.g. metadata for sRun has been created where only sLap existed).
      // Therefore here we need to forward-map back to the legacy channel data.
      let legacyChannelMappings = this.getLegacyChannelMappings.execute();

      while (!data) {
        let legacyMapping = legacyChannelMappings.find(v => v.currentName === genericName);
        if (!legacyMapping || genericName === legacyMapping.legacyName) {
          break;
        }

        genericName = legacyMapping.legacyName;
        data = await this.urlFileLoader.loadChannelData(studyId, jobIndex, simType, genericName, binaryFormat);
      }
    }

    return data;
  }

  /**
   * Loads the scalar channel data for a study job.
   * @param studyId The study ID.
   * @param jobIndex The job index.
   * @param simType The simulation type.
   * @param binaryFormat The binary format of the channel.
   * @param metadata The metadata for the channel.
   * @returns The channel data.
   */
  public async executeForScalar(
    studyId: string,
    jobIndex: number,
    simType: SimType,
    binaryFormat: ChannelBinaryFormat | undefined,
    metadata: any | undefined): Promise<ReadonlyArray<number> | undefined> {

    if (!binaryFormat || !metadata || !metadata.scalarName) {
      return undefined;
    }

    // Load the scalar results (the URL file loader is likely caching this).
    let scalarResults = await this.urlFileLoader.loadScalarResultsForSim(studyId, jobIndex, simType);
    if (!scalarResults) {
      return undefined;
    }

    // Get the value for this channel.
    let scalarName = metadata.scalarName;
    let channel = scalarResults.find(v => v.name === scalarName);
    if (!channel) {
      return undefined;
    }

    // Create a constant vector for the scalar data. This allows us to overlay a scalar result on a chart.
    return Array(binaryFormat.pointsCount).fill(channel.value);
  }
}

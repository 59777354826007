import { Injectable } from '@angular/core';
import { JsonEditorCustomization } from './json-editor-customization';
import{ JSONEditor } from'@json-editor/json-editor'

@Injectable()
export class FontAwesomeIconLibrary extends JsonEditorCustomization {
  public apply(): void {
    JSONEditor.defaults.iconlibs.fontawesome4 = class extends JSONEditor.AbstractIconLib {
      constructor(){
        super();
        this.mapping = {
          collapse: 'caret-down',
          expand: 'caret-right',
          delete: 'times',
          edit: 'pencil',
          edit_properties: 'pencil',
          add: 'plus',
          subtract: 'minus',
          cancel: 'ban',
          load: 'cloud-download',
          save: 'cloud-upload',
          saveas: 'cloud-upload plus',
          import: 'upload',
          importtir: 'upload',
          tirtocanopy: 'arrow-right',
          export: 'download',
          encrypt: 'lock',
          encryptwithmetadata: 'lock filter',
          decrypt: 'unlock',
          moveup: 'arrow-up',
          movedown: 'arrow-down'
        };

        this.icon_prefix = 'fa fa-';
      }

      getIcon(key: string) {
        let iconClass = this.mapping[key as keyof typeof this.mapping];

        if (!iconClass) {
          return null;
        }

        let iconClasses = iconClass.split(' ');

        if (iconClasses.length > 1) {
          let span = document.createElement('span');

          for (let iconClassItem of iconClasses) {
            let i = document.createElement('i');
            i.className = this.icon_prefix + iconClassItem;
            span.appendChild(i);
          }

          return span;
        }

        let i = document.createElement('i');
        i.className = this.icon_prefix + iconClass;
        return i;
      }
    }
  }
}

import * as d3 from '../d3-bundle';
import { createRandomString } from '../create-random-string';
import { RESIZE_DEBOUNCE_TIME, SLAP_DOMAIN_NAME, SLAPCENTRELINE_DOMAIN_NAME, SRUN_DOMAIN_NAME } from '../constants';
import { SourceLoaderSet, SourceLoaderViewModel } from './channel-data-loaders/source-loader-set';
import { Subject, Observable } from 'rxjs';

import { DomainNewsCache } from './domain-news-cache';
import { debounceTime } from 'rxjs/operators';
import { distinctUntilChanged } from 'rxjs/operators';

/**
 * The shared state for all visualizations.
 * Allows visualizations to communicate with each other, in addition to sharing common data
 * such as the source loaders.
 */
export class SharedState {

  /**
   * A random ID used to namespace events.
   */
  private randomId = createRandomString(16);

  /**
   * Raised when the window is resized.
   */
  private _windowResizeNews: Subject<number>;

  /**
   * A debounced version of the window resize event.
   */
  private _windowResizeNewsDebounced: Observable<number>;

  /**
   * Raised when a key is pressed.
   */
  private _keyPressNews: Subject<string | undefined>;

  /**
   * The set of source loaders.
   */
  private _sourceLoaderSet: SourceLoaderSet = new SourceLoaderSet();

  /**
   * This is set to true when the user is using mean/min/max/etc calculations.
   */
  public domainOverridden: boolean;

  /**
   * Creates a new shared state.
   * @param domainNewsCache The mapping of domain names to domain news.
   * @param dimensions The dimensions shared state.
   */
  constructor(
    private readonly domainNewsCache: DomainNewsCache = new DomainNewsCache(),
    public readonly dimensions?: DimensionsSharedState) {

    // Create subjects for when the window is resized, and a debounced version of the same event.
    this._windowResizeNews = new Subject<number>();
    this._windowResizeNewsDebounced = this._windowResizeNews.pipe(debounceTime(RESIZE_DEBOUNCE_TIME));
    d3.select(window).on('resize.' + this.randomId, () => this._windowResizeNews.next(undefined));

    // Create a subject for key press events.
    this._keyPressNews = new Subject<string | undefined>();
    d3.select('body')
      .on('keydown.' + this.randomId, (currentEvent: KeyboardEvent) => {
        let event = <KeyboardEvent>currentEvent;
        this._keyPressNews.next(event.key);
      })
      .on('keyup.' + this.randomId, () => {
        this._keyPressNews.next(undefined);
      });
  }

  /**
   * Gets the dimensions shared state.
   */
  public get definedDimensions(): DimensionsSharedState {
    if (!this.dimensions) {
      throw new Error('Dimensions shared state has not been defined.');
    }
    return this.dimensions;
  }

  /**
   * Creates a shared state from a set of source loaders.
   * @param sources The source loaders.
   * @returns The shared state.
   */
  public static fromSources(sources: SourceLoaderViewModel[]): SharedState {
    let result = new SharedState();
    result.sourceLoaderSet.add(...sources);
    return result;
  }

  /**
   * Gets the source loader set.
   */
  public get sourceLoaderSet(): SourceLoaderSet {
    return this._sourceLoaderSet;
  }

  /**
   * Gets the first source loader.
   * This is a helper method for visualizations which only care about the first source,
   * such as the parallel coordinates viewer.
   */
  public get isFirstSourceVisible(): boolean {
    return !!this.sourceLoaderSet.sources.length
      && this.sourceLoaderSet.sources[0].isVisible;
  }

  /**
   * Gets the domain news for the given domain name.
   * @param name The domain name.
   * @returns The domain news.
   */
  public getDomainNews(name: string): DomainNews {
    return this.domainNewsCache.getDomainNews(name);
  }

  /**
   * Gets the observable for window resize events.
   */
  public get windowResizeNews(): Observable<number> {
    return this._windowResizeNewsDebounced;
  }

  /**
   * Gets the observable for key press events.
   */
  public get keyPressNews(): Observable<string | undefined> {
    return this._keyPressNews;
  }

  /**
   * Gets the observable for the sLap domain.
   */
  public get sLapCursorNews(): Observable<DomainNewsEvent> {
    return this.getDomainNews(SRUN_DOMAIN_NAME).observable;
  }

  /**
   * Raises a news event for multiple sLap and sLapCentreLine cursors.
   * This is used by the track viewer when the cars are at different points on the track.
   * @param sLapCursors The sLap cursors.
   * @param sLapCentreLineCursors The sLapCentreLine cursors.
   */
  public sLapCursorSetAll(sLapCursors: ReadonlyArray<number>, sLapCentreLineCursors: ReadonlyArray<number>) {
    let event = new DomainNewsEvent(sLapCursors, new SecondaryDomainNewsEvent(SLAPCENTRELINE_DOMAIN_NAME, new DomainNewsEvent(sLapCentreLineCursors)));
    this.getDomainNews(SLAP_DOMAIN_NAME).raise(event);
    this.getDomainNews(SRUN_DOMAIN_NAME).raise(event);
  }

  /**
   * Disposes of the shared state.
   */
  public dispose() {
    d3.select(window).on('resize.' + this.randomId, null);

    d3.select('body')
      .on('keydown.' + this.randomId, null)
      .on('keyup.' + this.randomId, null);
  }

  /**
   * Raises a news event for window resize.
   */
  public raiseWindowResizedNews() {
    this._windowResizeNews.next(undefined);
  }
}

/**
 * The shared state for dimensions.
 */
export class DimensionsSharedState {

  /**
   * Raised when the RBF interpolation point in this dimension changes.
   */
  private _rCoordinatesNews: Subject<ReadonlyArray<number>>;

  /**
   * Raised when the domain extents in this dimension change.
   */
  private _rDomainExtentsNews: Subject<ReadonlyArray<Readonly<[number, number]>>>;

  /**
   * Creates a new dimensions shared state.
   * @param _rCoordinates The RBF interpolation point.
   * @param _rDomainExtents The domain extents.
   */
  constructor(
    private readonly _rCoordinates: number[],
    private readonly _rDomainExtents: [number, number][]) {
    this._rCoordinatesNews = new Subject<ReadonlyArray<number>>();
    this._rDomainExtentsNews = new Subject<ReadonlyArray<Readonly<[number, number]>>>();
  }

  /**
   * Gets the observable for RBF interpolation point changes.
   * The r-coordinates are the normalized values on the input dimensions where
   * we are currently interpolating.
   */
  public get rCoordinatesNews(): Observable<ReadonlyArray<number>> {
    return this._rCoordinatesNews;
  }

  /**
   * Gets the observable for domain extents changes.
   */
  public get rDomainExtentsNews(): Observable<ReadonlyArray<Readonly<[number, number]>>> {
    return this._rDomainExtentsNews;
  }

  /**
   * Gets the RBF interpolation point.
   */
  public get rCoordinates(): ReadonlyArray<number> {
    return this._rCoordinates;
  }

  /**
   * Gets the domain extents.
   */
  public get rDomainExtents(): ReadonlyArray<Readonly<[number, number]>> {
    return this._rDomainExtents;
  }

  /**
   * Update a single dimension of the RBF interpolation point and notify subscribers to rCoordinatesNews.
   * @param iDim The dimension index.
   * @param rCoord The RBF interpolation point value for the given dimension.
   */
  public rCoordinatesSet(iDim: number, rCoord: number) {
    // only broadcast this if it's news!
    if (this.rCoordinates[iDim] !== rCoord) {
      this._rCoordinates[iDim] = rCoord;
      this._rCoordinatesNews.next(this.rCoordinates);
    }
  }

  /**
   * Update a single dimension of rDomainExtents and notify subscribers to rDomainExtentsNews.
   * @param iDim The dimension index.
   * @param rExtent The domain extents for the given dimension.
   */
  public rDomainExtentsSet(iDim: number, rExtent: [number, number]) {
    // only broadcast this if it's news!
    if (this.rDomainExtents[iDim][0] !== rExtent[0] || this.rDomainExtents[iDim][1] !== rExtent[1]) {
      this._rDomainExtents[iDim] = rExtent;
      this._rDomainExtentsNews.next(this.rDomainExtents);
    }
  }

  /**
   * Raise events for the current values of rCoordinates and rDomainExtents.
   */
  public triggerAllNews(){
    this._rCoordinatesNews.next(this.rCoordinates);
    this._rDomainExtentsNews.next(this.rDomainExtents);
  }
}

/**
 * Contains events which are raised when a domain cursor changes.
 */
export class DomainNews {

  /**
   * The main event raised when the domain cursor changes.
   */
  private _subject: Subject<DomainNewsEvent>;

  /**
   * The distinct version of the main event.
   */
  private _distinct: Observable<DomainNewsEvent>;

  /**
   * Creates a new domain news.
   */
  constructor() {
    this._subject = new Subject<DomainNewsEvent>();
    this._distinct = this._subject.pipe(distinctUntilChanged((x: DomainNewsEvent, y: DomainNewsEvent) => {
      if (x.sourceValues.length !== y.sourceValues.length) {
        return false;
      }

      return d3.zip(x.sourceValues, y.sourceValues).every((v: number[]) => v[0] === v[1]);
    }));
  }

  /**
   * Raises an event with the latest domain cursor values.
   * @param value The event.
   */
  public raise(value: DomainNewsEvent) {
    this._subject.next(value);
  }

  /**
   * Gets the observable for domain cursor changes.
   */
  public get observable(): Observable<DomainNewsEvent> {
    return this._distinct;
  }
}

/**
 * A domain news event, containing the latest cursor values for each source
 * and an optional secondary domain event.
 */
export class DomainNewsEvent {

  /**
   * Creates a new domain news event.
   * @param sourceValues The values of the domain cursor for each source.
   * @param secondary An optional secondary domain event.
   */
  constructor(
    public readonly sourceValues: ReadonlyArray<number>,
    public readonly secondary?: SecondaryDomainNewsEvent) {
  }

  /**
   * Gets the value of the domain cursor for the given source index.
   * @param sourceIndex The source index.
   * @returns The value of the domain cursor for the given source index.
   */
  public getSourceValue(sourceIndex: number): number {
    if (this.sourceValues.length > sourceIndex) {
      return this.sourceValues[sourceIndex];
    }

    return 0;
  }
}

/**
 * A secondary domain news event, containing the latest cursor values for a secondary domain.
 */
export class SecondaryDomainNewsEvent {
  constructor(
    public readonly domainName: string,
    public readonly event: DomainNewsEvent) {
  }
}

import {Injectable} from '@angular/core';
import {DocumentSubType, WorksheetStub} from '../../generated/api-stubs';
import {LoadingDialog} from '../common/dialogs/loading-dialog.service';
import {CustomPropertyUtilities} from '../simulations/custom-properties/custom-property-utilities';
import {referenceEquals} from './worksheet-types';
import {GetSimVersion} from '../common/get-sim-version.service';
import {ConfirmationDialog} from '../common/dialogs/confirmation-dialog.service';
import { UserData } from '../identity/state/authentication.service';

export const CONTINUE_CONFIRMATION_DIALOG_RESPONSE = 'OK';

/**
 * A service for cloning a worksheet, or, at least, getting as close to cloning it
 * as we can given we don't support cloning studies.
 */
@Injectable()
export class CloneWorksheet {

  /**
   * Creates an instance of CloneWorksheet.
   * @param confirmationDialog The confirmation dialog service.
   * @param worksheetStub The worksheet stub.
   * @param getSimVersion The service for getting the current sim version.
   * @param loadingDialog The loading dialog service.
   */
  constructor(
    private readonly confirmationDialog: ConfirmationDialog,
    private readonly worksheetStub: WorksheetStub,
    private readonly getSimVersion: GetSimVersion,
    private readonly loadingDialog: LoadingDialog){
  }

  /**
   * Clones a worksheet.
   * Clone all configs.
   * Copy study names to row if row doesn't already have name.
   * Remove all studies.
   * @param userData The user authentication data.
   * @param name The name of the new worksheet.
   * @param sourceTenantId The tenant id of the source worksheet.
   * @param sourceWorksheetId The worksheet id of the source worksheet.
   * @returns The worksheet id of the new worksheet.
   */
  public async execute(
    userData: UserData,
    name: string,
    sourceTenantId: string,
    sourceWorksheetId: string): Promise<string> {

    // I'm not sure we need to remove telemetry, as it's just a reference to a study 🤔
    const confirmationResult = await this.confirmationDialog.show(
      'Configs and studies cannot be shared between worksheets. '
      + 'Cloning a worksheet will therefore duplicate all configs and remove all studies (including telemetry). '
      + 'If the row does not already have a name, the study name will be copied to the row name.',
      'Clone Worksheet',
      CONTINUE_CONFIRMATION_DIALOG_RESPONSE, 'Cancel');

    if(confirmationResult !== CONTINUE_CONFIRMATION_DIALOG_RESPONSE){
      return undefined;
    }

    // Load the source worksheet.
    const sourceWorksheetResult = await this.loadingDialog.showUntilFinished(
      this.worksheetStub.getWorksheet(
        sourceTenantId,
        sourceWorksheetId),
      'Loading worksheet...');

    // Get the properties and notes for the new worksheet.
    const properties = CustomPropertyUtilities.objectToList(sourceWorksheetResult.worksheet.properties);
    const notes = sourceWorksheetResult.worksheet.notes;

    // Create an empty new worksheet.
    const worksheetResult = await this.loadingDialog.showUntilFinished(
      this.worksheetStub.postWorksheet(
        userData.tenant,
        {
          name,
        }),
      'Creating worksheet...');

    const worksheetId = worksheetResult.worksheet.worksheetId;
    const worksheetOutline = sourceWorksheetResult.worksheet.outline;
    const rows = sourceWorksheetResult.worksheet.outline.rows || [];
    const sourceConfigIdSet: Set<string> = new Set<string>();

    // For each row...
    for(let row of rows){

      // Copy the study names to the row names, if the row doesn't already have a name.
      if(row.study.reference){
        if(!row.name){
          const resolvedStudy = sourceWorksheetResult.worksheet.resolvedReferences.studies.find(
            v => referenceEquals(row.study.reference, v.reference));
          if(resolvedStudy){
            row.name = resolvedStudy.data.name;
          }
        }
      }

      // Remove the study if it has one.
      row.study = { reference: undefined };

      // Remove telemetry (do we need to do this?).
      row.configs = row.configs.filter(v => v.configType !== DocumentSubType.telemetry);

      // Make a note of all the config IDs.
      for(let config of row.configs || []){
        if(config.reference && config.reference.tenant) {
          sourceConfigIdSet.add(config.reference.tenant.targetId);
        }
      }
    }

    // Duplicate the configs.
    const sourceConfigIds = Array.from(sourceConfigIdSet);
    const duplicationResult = await this.loadingDialog.showUntilFinished(
      this.worksheetStub.postDuplicateConfigs(
        userData.tenant,
        worksheetResult.worksheet.worksheetId,
        {
          sourceTenantId,
          sourceWorksheetId,
          sourceConfigIds,
        },
        this.getSimVersion.currentSimVersion),
      'Cloning configs...');

    // Map from the old config ID to the new config IDs.
    const map: {[input: string]: string} = {};
    for(let i=0; i < sourceConfigIds.length; ++i){
      map[sourceConfigIds[i]] = duplicationResult.targetConfigIds[i];
    }

    // Update each row to point to the new config IDs.
    for(let row of rows){
      for(let config of row.configs || []){
        if(config.reference && config.reference.tenant) {
          config.reference.tenant.tenantId = userData.tenant;
          config.reference.tenant.targetId = map[config.reference.tenant.targetId];
        }
      }
    }

    // Set the new rows.
    worksheetOutline.rows = rows;

    // Update the worksheet.
    await this.loadingDialog.showUntilFinished(
      this.worksheetStub.putWorksheet(
        userData.tenant,
        worksheetId,
        {
          name,
          properties,
          notes,
          outline: worksheetOutline,
        }),
      'Saving worksheet...');

    // Return the new worksheet ID.
    return worksheetResult.worksheet.worksheetId;
  }
}

import { DisplayableError } from '../../../common/errors/errors';
import { UnitsMap } from '../../../visualizations/viewers/channel-data-loaders/local-config-source-loader';
import { JsonEditorInstance } from './json-editor-instance';


export class UnvalidatedJsonEditorInstance extends JsonEditorInstance {

  constructor(
    unvalidatedData: any,
    simVersion: string,
    defaultUnits: UnitsMap) {
    super(simVersion, defaultUnits);

    this.setValue(unvalidatedData);
  }

  public get ready(): boolean{
    return this.jsonEditor.ready;
  }

  public get canValidate(): boolean {
    return false;
  }

  public get jsonEditor(): any {
    throw new Error('Not supported');
  }

  public unvalidatedContent: string;

  getValue(): any {
    try {
      return JSON.parse(this.unvalidatedContent);
    } catch (error) {
      throw new DisplayableError(error.message);
    }
  }

  setValue(value: any) {
    try {
      this.unvalidatedContent = JSON.stringify(value, undefined, 2);
    } catch (error) {
      throw new DisplayableError(error.message);
    }
  }

  validate(): any {
  }

  public getFirstInput(): HTMLElement | undefined {
    return undefined;
  }

  public getFirstVisibleInput(): HTMLElement | undefined {
    return undefined;
  }

  getFilteredValue(): any {
    throw new Error('Not supported');
  }

  setCustomView(parameters: string[]) {
    throw new Error('Not supported');
  }

  collapseAll() {
    throw new Error('Not supported');
  }

  destroy() {
  }
}

import {Injectable} from '@angular/core';
import {ExecuteStudyAndWaitFactory} from '../studies/execute-study-and-wait.service';
import {
  DocumentSubType,
  GetStudyJobMetadataQueryResult,
  GetStudyQueryResult,
  SimType,
  StudyType
} from '../../../generated/api-stubs';
import {CustomProperty} from '../custom-properties/custom-properties';
import {StudyUtilities} from '../studies/study-utilities.service';
import {CanopyJson} from '../../common/canopy-json.service';
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {FrontAndRearTyres} from './front-and-rear-tyres';
import { AuthenticationService } from '../../identity/state/authentication.service';

@Injectable()
export class ConvertPacejkaJsonToCanopy {

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly studyUtilities: StudyUtilities,
    private readonly json: CanopyJson,
    private readonly executeStudyAndWaitFactory: ExecuteStudyAndWaitFactory,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  public async execute(
    simVersion: string,
    pacejkaVersionName: string,
    front: any | undefined,
    rear: any | undefined,
    frontL: any | undefined,
    rearL: any | undefined): Promise<ConvertPacejkaJsonToCanopyResult> {
    try {
      const userData = this.authenticationService.userDataSnapshot;

      let study = {
        simTypes: [SimType.PacejkaCanopyConverter],
        simConfig: {
          pacejkaTyres: {
            name: pacejkaVersionName,
            front,
            rear,
            frontL,
            rearL,
          }
        },
        exploration: undefined as any
      };

      let sources = [{
        configType: DocumentSubType.pacejkaTyres,
        name: 'Tyres',
        isEdited: false
      }];

      let properties: CustomProperty[] = [];

      let studyData = {
        name: userData.sub,
        studyType: StudyType.pacejkaCanopyConverter,
        simVersion,
        sources,
        properties,
        study,
        isTransient: true
      };

      let executeStudyAndWait = this.executeStudyAndWaitFactory.create();
      await executeStudyAndWait.execute(studyData);

      let studyMetadataResult = executeStudyAndWait.studyMetadataResult;
      if(studyMetadataResult
        && studyMetadataResult.study.data.errorMessages
        && studyMetadataResult.study.data.errorMessages.length) {
        return new ConvertPacejkaJsonToCanopyResult(
          undefined,
          studyMetadataResult.study.data.errorMessages);
      }

      let studyJobMetadataResult = executeStudyAndWait.studyJobMetadataResult;
      if(studyJobMetadataResult
        && studyJobMetadataResult.studyJob.data.errorMessages
        && studyJobMetadataResult.studyJob.data.errorMessages.length) {
        return new ConvertPacejkaJsonToCanopyResult(
          undefined,
          studyJobMetadataResult.studyJob.data.errorMessages);
      }

      if(!executeStudyAndWait.isStudySuccessful) {
        return new ConvertPacejkaJsonToCanopyResult(
          undefined,
          ['Failed to execute study.']);
      }

      let frontCanopyTyresTask = this.downloadText(
        studyMetadataResult,
        studyJobMetadataResult,
        'frontCanopyTyres.json');

      let rearCanopyTyresTask = this.downloadText(
        studyMetadataResult,
        studyJobMetadataResult,
        'rearCanopyTyres.json');

      let frontLeftCanopyTyresTask = this.downloadText(
        studyMetadataResult,
        studyJobMetadataResult,
        'frontLeftCanopyTyres.json');

      let rearLeftCanopyTyresTask = this.downloadText(
        studyMetadataResult,
        studyJobMetadataResult,
        'rearLeftCanopyTyres.json');

      let frontCanopyTyresText = await frontCanopyTyresTask;
      let rearCanopyTyresText = await rearCanopyTyresTask;
      let frontLeftCanopyTyresText = await frontLeftCanopyTyresTask;
      let rearLeftCanopyTyresText = await rearLeftCanopyTyresTask;

      let frontCanopyTyres = this.json.parse(frontCanopyTyresText).config;
      let rearCanopyTyres = this.json.parse(rearCanopyTyresText).config;
      let frontLeftCanopyTyres = frontLeftCanopyTyresText ? this.json.parse(frontLeftCanopyTyresText).config : undefined;
      let rearLeftCanopyTyres = rearLeftCanopyTyresText ? this.json.parse(rearLeftCanopyTyresText).config : undefined;
      return new ConvertPacejkaJsonToCanopyResult(
        new FrontAndRearTyres(
          frontCanopyTyres,
          rearCanopyTyres,
          frontLeftCanopyTyres,
          rearLeftCanopyTyres,
          'Canopy'),
        []);
    } catch(error){
      return new ConvertPacejkaJsonToCanopyResult(
        undefined,
        [this.getFriendlyErrorAndLog.execute(error)]);
    }
  }

  private downloadText(
    studyMetadataResult: GetStudyQueryResult,
    studyJobMetadataResult: GetStudyJobMetadataQueryResult,
    fileName: string): Promise<string | undefined> {
    return this.studyUtilities.downloadText(
      studyJobMetadataResult.studyJob.tenantId,
      studyMetadataResult.study.documentId,
      studyJobMetadataResult.studyJob.documentId,
      studyJobMetadataResult.accessInformation.url,
      studyJobMetadataResult.accessInformation,
      fileName);
  }
}

export class ConvertPacejkaJsonToCanopyResult {
  constructor(
    public readonly tyres: FrontAndRearTyres | undefined,
    public readonly errorMessages: ReadonlyArray<string>){
  }
}

// TODO: Move these classes into new linting project.
// Reference linting project as NPM module.
// In build process, run typescript compilation with full strictness (no implicit any, strict null checks, etc).
// If compilation successful, compile just the rule set and push to blob.

// OR

// Create NPM module for linting engine.
// Reference from linting rules project and from canopy-web.
// Registry needs to be with rules, then declare var Registry here?


import {RulesOrchestrator} from './rules-orchestrator';
import {ExecutionResult} from './execution-result';
import {RuleContextBase} from './rule-context-base';
import {IContextDataLoader} from './context-data-loader';

export class LintingEngine {
  constructor(
    private readonly contextDataLoader: IContextDataLoader,
    private readonly orchestrator: RulesOrchestrator){
  }

  public async execute(context: RuleContextBase): Promise<ExecutionResult> {
    let channelsResult = this.orchestrator.getChannels(context);

    for(let channel of channelsResult.channels){
      let channelData = await this.contextDataLoader.loadChannel(channel.name, channel.simType);
      context.setChannel(channel.name, channel.simType, channelData);
    }

    return this.orchestrator.execute(context);
  }
}

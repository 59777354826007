import {Component, ElementRef, HostBinding, Input} from '@angular/core';
import {SimulationViewModel} from '../simulation-view-model';
import {WorksheetItemComponentBase} from '../worksheet-item-component-base';
import {CellElementToViewModelLookup} from '../cell-element-to-view-model-lookup';
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {SimulationContextMenuBuilder} from '../worksheet-commands/simulation-context-menu-builder';
import { AuthenticationService } from '../../identity/state/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { RowItemUrlService } from '../worksheet-commands/row-item-url.service';

/**
 * Component for displaying a simulation item in a worksheet.
 */
/* eslint-disable @angular-eslint/component-selector */
@Component({
  selector: '[cs-worksheet-simulation-item]',
  templateUrl: './worksheet-simulation-item.component.html',
  styleUrls: ['./worksheet-simulation-item.component.scss']
})
export class WorksheetSimulationItemComponent extends WorksheetItemComponentBase {

  /**
   * The view model for the simulation.
   */
  @Input() public readonly viewModel: SimulationViewModel;

  /**
   * Creates an instance of WorksheetSimulationItemComponent.
   * @param authenticationService The authentication service.
   * @param cellElementToViewModelLookup The cell element to view model lookup.
   * @param elementRef The element reference.
   * @param simulationContextMenuBuilder The simulation context menu builder.
   * @param getFriendlyErrorAndLog The service for getting friendly error messages and logging the error.
   * @param rowItemUrl The row item URL service.
   * @param route The activated route.
   */
  constructor(
    authenticationService: AuthenticationService,
    cellElementToViewModelLookup: CellElementToViewModelLookup,
    elementRef: ElementRef,
    simulationContextMenuBuilder: SimulationContextMenuBuilder,
    getFriendlyErrorAndLog: GetFriendlyErrorAndLog,
    rowItemUrl: RowItemUrlService,
    route: ActivatedRoute) {
    super(authenticationService, cellElementToViewModelLookup, elementRef, simulationContextMenuBuilder, getFriendlyErrorAndLog, rowItemUrl, route);
  }

  /**
   * Gets whether the simulation is part of the currently selected study.
   */
  @HostBinding('class.matches-selected') public get matchesSelected(): boolean {
    return this.viewModel.row.study.matchesSelectedConfig;
  }
}

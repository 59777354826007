/* eslint-disable quote-props */
import {
  IEditChannelsOptions, LoadViewerLayoutResult, PaneChannelLayout, SiteHooks, ResolvedLayoutId,
  RequestedLayoutIds,
  ViewerMetadata
} from './site-hooks';
import { UrlFileLoader } from './url-file-loader';
import { ChannelNameStyle } from './viewers/channel-data-loaders/channel-name-style';
import { CANOPY_SIMS_JSON_FOLDER, LocalUrlFileLoader } from './local-url-file-loader';
import { Subject } from 'rxjs';
import { Size } from './viewers/size';
// import { Size } from './viewers/size';

/**
 * An implementation of SiteHooks for automated tests.
 */
export class LocalSiteHooks implements SiteHooks {

  /** @inheritDoc */
  public unitsChangedEvent: Subject<any> = new Subject<any>();

  /**
   * Custom units for overriding units in tests.
   */
  private customUnits: { [channelName: string]: string } = {
    'vCar': 'kph',
    'vCarMax': 'kph',
    'sLap': 'km',
    'hRideR': 'mm',
    'hRideFSetup': 'km'
  };

  /**
   * Whether to use custom units.
   */
  private useCustomUnits: boolean = false;

  /**
   * Creates a new instance of LocalSiteHooks.
   * @param fileLoader The file loader to use.
   */
  constructor(
    private fileLoader: UrlFileLoader) {
  }

  /**
   * Creates a new instance of LocalSiteHooks.
   * @returns A new instance of LocalSiteHooks.
   */
  public static create(): LocalSiteHooks {
    return new LocalSiteHooks(new LocalUrlFileLoader());
  }

  /** @inheritDoc */
  public dispose() {
  }

  /** @inheritDoc */
  public async saveViewerLayoutAs(viewerType: string, config: any): Promise<{ viewerId: any; name: string }> {
    console.log('Save As: ' + viewerType);
    console.log(config);
    let r = Math.random().toString();
    return Promise.resolve({
      viewerId: r,
      name: r
    });
  }

  /** @inheritDoc */
  public async canSaveViewerLayout(viewerType: string, layoutId: any): Promise<boolean> {
    return Promise.resolve(!!layoutId);
  }

  /** @inheritDoc */
  public async saveViewerLayout(viewerType: string, layoutId: any, name: string, config: any): Promise<any> {
    console.log('Save: ' + viewerType + ', ' + name);
    console.log(layoutId);
    console.log(config);
    let promise = new Promise((resolve) => {
      setTimeout(() => resolve(true), 1000);
    });

    await promise;
    console.log('Saved');
    //throw new Error('bum');
  }

  /** @inheritDoc */
  public saveViewerMetadata(viewerType: string, layoutId: any, viewerMetadata: ViewerMetadata): Promise<any> {
    if (!viewerMetadata.size) {
      console.log(`Saving size of ${viewerType}/${layoutId} as undefined.`);
    } else {
      console.log(`Saving size of ${viewerType}/${layoutId} as ${viewerMetadata.size.width}x${viewerMetadata.size.height}.`);
    }
    return Promise.resolve();
  }

  /** @inheritDoc */
  public async loadViewerLayout(viewerType: string, layoutId?: any): Promise<LoadViewerLayoutResult> {
    layoutId = layoutId || 'Default';

    let viewerName = CANOPY_SIMS_JSON_FOLDER + '/' + viewerType + '/' + layoutId;
    let config = await this.fileLoader.loadChartLayout(viewerName);

    console.log('Returning for ' + layoutId);
    return Promise.resolve(new LoadViewerLayoutResult(
      layoutId,
      layoutId,
      config));
  }

  /** @inheritDoc */
  public async loadViewerMetadata(viewerType: string, layoutId: any): Promise<ViewerMetadata | undefined> {
    // return Promise.resolve(undefined);
    return Promise.resolve(new ViewerMetadata(
      new Size(10, 11)
    ));
  }

  /** @inheritDoc */
  public async editViewerDeprecated(viewerType: string, layoutConfig: any, studyId: string, jobIndex: number, simTypes: string[], channelNameStyle: ChannelNameStyle): Promise<any> {
    let result = JSON.parse(JSON.stringify(layoutConfig));
    if (viewerType === 'lineMultiPlotViewer' || viewerType === 'pointMultiPlotViewer') {
      if (result && result.panes && result.panes.length >= 2) {
        let p = result.panes[0];
        result.panes[0] = result.panes[1];
        result.panes[1] = p;
      }
    }

    return Promise.resolve(result);
  }

  /** @inheritDoc */
  public editViewerChannels(options: IEditChannelsOptions): Promise<PaneChannelLayout | undefined> {
    return Promise.resolve(undefined);
  }

  /** @inheritDoc */
  public getDefaultChannelUnits(channelName: string): Promise<string> {

    switch (channelName) {
      case 'mCar':
        return Promise.resolve('kg');
      case 'hRideFSetup':
        return Promise.resolve('m');
      case 'kAntiRollBar':
        return Promise.resolve('Nm/rad');
    }

    return Promise.resolve('()');
  }

  /** @inheritDoc */
  public getUserChannelUnits(channelName: string): Promise<string | undefined> {
    return Promise.resolve(this.getUserChannelUnitsSynchronous(channelName));
  }

  /** @inheritDoc */
  public ensureUnitsLoaded(): Promise<void> {
    return Promise.resolve();
  }

  /** @inheritDoc */
  public getUserChannelUnitsSynchronous(channelName: string): string | undefined {
    if (this.useCustomUnits) {
      return this.customUnits[channelName];
    }

    return undefined;
  }

  /** @inheritDoc */
  public editChannelUnits(channelName: string, currentUnits: string): Promise<void> {
    this.useCustomUnits = !this.useCustomUnits;
    this.unitsChangedEvent.next(undefined);
    return Promise.resolve();
  }

  /** @inheritDoc */
  public getViewerDefaultLayoutId(viewerType: string, layoutIds: RequestedLayoutIds): Promise<any> {
    return Promise.resolve();
  }

  /** @inheritDoc */
  public setViewerDefaultLayoutId(viewerType: string, layoutIds: RequestedLayoutIds, defaultLayoutId: ResolvedLayoutId): Promise<any> {
    return Promise.resolve();
  }

  /** @inheritDoc */
  public getStudyName(studyId: string): Promise<string> {
    return Promise.resolve(studyId);
  }

  /** @inheritDoc */
  public getStudyJobName(studyId: string, jobIndex: number): Promise<string> {
    return Promise.resolve(studyId + ' / Job ' + (1 + jobIndex));
  }

  /** @inheritDoc */
  public showStudyJob(studyId: string, jobIndex: number): Promise<void> {
    console.log(`Clicked: ${studyId}-${jobIndex}`);
    return Promise.resolve();
  }

  /** @inheritDoc */
  public getFriendlyErrorAndLog(error: any): string {

    if (error.isDisplayable) {
      return error.message;
    }

    console.error(error);
    return 'An error occurred.';
  }
}

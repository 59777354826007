import {RoleCheckType} from './authorize.service';
import {ADMINISTRATOR_ROLE, TENANT_ADMINISTRATOR_ROLE, TEST_USER_ROLE} from '../user-identity';

export class PageSecurity {

  public static unauthenticated = new PageSecurity(true, false, [], RoleCheckType.none);

  public static authenticated = new PageSecurity(false, true, [], RoleCheckType.none);

  public static administrator = new PageSecurity(false, true, [ADMINISTRATOR_ROLE], RoleCheckType.all);

  public static tenantAdministrator = new PageSecurity(false, true, [ADMINISTRATOR_ROLE, TENANT_ADMINISTRATOR_ROLE], RoleCheckType.atLeastOne);

  public static administratorOrTest = new PageSecurity(false, true, [ADMINISTRATOR_ROLE, TEST_USER_ROLE], RoleCheckType.atLeastOne);

  constructor(
    public readonly requireUnauthenticated: boolean,
    public readonly requireAuthenticated: boolean,
    public readonly requiredRoles: ReadonlyArray<string>,
    public readonly roleCheckType: RoleCheckType) {
  }
}

import { Vector3 } from 'three';
import { CoordinateTriplet } from './coordinate-triplet';

/**
 * Represents a coordinate in the car's coordinate system.
 */
export class CarCoordinate {

  /**
   * Creates a new instance of CarCoordinate.
   * @param x The x coordinate.
   * @param y The y coordinate.
   * @param z The z coordinate.
   */
  constructor(
    public readonly x: number,
    public readonly y: number,
    public readonly z: number) {
  }

  /**
   * Gets the coordinates in the world coordinate system.
   */
  public get worldVector(): Vector3 {
    return new Vector3(this.x, -this.z, this.y);
  }

  /**
   * Returns a new coordinate with the specified offset.
   * @param offset The offset.
   * @returns The new coordinate.
   */
  public withOffset(offset: CoordinateTriplet): CarCoordinate {
    return new CarCoordinate(this.x + offset[0], this.y + offset[1], this.z + offset[2]);
  }

  /**
   * Determines if the specified coordinate is equal to this coordinate.
   * @param other The other coordinate.
   * @returns True if the coordinates are equal; otherwise false.
   */
  public equals(other: CarCoordinate): boolean {
    if (!other) {
      return false;
    }

    return this.x === other.x && this.y === other.y && this.z === other.z;
  }

  /**
   * Determines if the specified coordinate is close to this coordinate.
   * @param other The other coordinate.
   * @returns True if the coordinates are close; otherwise false.
   */
  public isCloseTo(other: CarCoordinate): boolean {
    if (!other) {
      return false;
    }

    return this.isDimensionCloseTo(this.x, other.x)
      && this.isDimensionCloseTo(this.y, other.y)
      && this.isDimensionCloseTo(this.z, other.z);
  }

  /**
   * Determines if the specified dimension coordinates are close to each other.
   * @param a The first dimension coordinate.
   * @param b The second dimension coordinate.
   * @returns True if the dimension coordinates are close; otherwise false.
   */
  private isDimensionCloseTo(a: number, b: number) {
    return Math.abs(a - b) < 0.00001;
  }
}

/**
 * Creates instances of CarCoordinate.
 */
class CarCoordinateFactoryImplementation {

  /**
   * Creates a new instance of CarCoordinate.
   * @param coordinate The coordinate.
   * @param offset The offset.
   * @returns The new instance of CarCoordinate.
   */
  public fromArray(coordinate: CoordinateTriplet, offset: CoordinateTriplet = [0, 0, 0]) {
    return new CarCoordinate(coordinate[0] + offset[0], coordinate[1] + offset[1], coordinate[2] + offset[2]);
  }
}

/**
 * The factory for creating instances of CarCoordinate.
 */
export const CarCoordinateFactory = new CarCoordinateFactoryImplementation();

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CanopyNotifications {
  constructor() { }


  public get areNotificationsEnabled(): boolean {
    return window.Notification && Notification.permission === 'granted';
  }

  public notify(title: string, body: string, action?: () => void){
    const notification = new Notification(title, {
      icon: '/favicon-96x96.png',
      body,
    });

    if(action){
      notification.onclick = () => {
        action();
      };
    }
  }
}

import {ISaveOutputConfigHandler} from '../compare-config-dialog/compare-config-dialog.service';
import {InputCustomProperty, StudyInput} from '../../../../worksheets/study-input';
import { JsonEditorInstance } from '../../json-config-editor/json-editor-instance';
import {Injectable} from '@angular/core';

@Injectable()
export class SaveToEditorHandlerFactory {

  constructor() {
  }

  public create(
    editor: JsonEditorInstance,
    setProperties: (properties: ReadonlyArray<InputCustomProperty>) => void,
    setNotes: (notes: string) => void): SaveToEditorHandler {
    return new SaveToEditorHandler(
      editor,
      setProperties,
      setNotes);
  }
}

export class SaveToEditorHandler implements ISaveOutputConfigHandler {
  constructor(
    private readonly editor: JsonEditorInstance,
    private readonly setProperties: (properties: ReadonlyArray<InputCustomProperty>) => void,
    private readonly setNotes: (notes: string) => void) {
  }

  public canExecute(studyInput: StudyInput): boolean {
    return true;
  }

  public execute(studyInput: StudyInput): Promise<void> {
    this.editor.setValue(studyInput.data);
    this.setProperties(studyInput.properties);
    this.setNotes(studyInput.notes);
    return Promise.resolve();
  }
}

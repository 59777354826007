<div class="container-fluid" id="view-configs-page">

  @if (showTelemetrySimulations) {
    <div>
      <cs-view-telemetry-configs
        [tenantId]="tenantId"
      [userId]="userId"></cs-view-telemetry-configs>
    </div>
  }

  @if (!showConfigs) {
    <div>
      <button class="btn btn-secondary" (click)="toggleShowTelemetryConfigs()">Show Configs</button>
    </div>
  }
  @if (showConfigs) {
    <div>
      <div class="list-card">
        <h1 class="list-card-header">
          {{configType.titleName}} Configs
        </h1>
        <div class="list-card-body">
          <cs-error-message [message]="errorMessage"></cs-error-message>
          @if (!showTelemetrySimulations) {
            <div class="list-card-padded">
              <p>
                <a id="home-button" class="btn btn-smart btn-secondary" [routerLink]="['../../']">
                  <i class="fa fa-caret-left"></i>Back
                </a>
                <a id="new-config-button" class="btn btn-smart btn-secondary" [routerLink]="['/configs', configType.pluralKey, 'create']">
                  <i class="fa fa-file-o"></i>New {{configType.titleName}}
                </a>
                <a id="manage-components-button" class="btn btn-smart btn-secondary" [routerLink]="['/configs', configType.pluralKey, this.tenantId, 'manage-components']">
                  <i class="fa fa-gears"></i> Manage components
                </a>
              </p>
            </div>
          }
          <cs-view-configs [configType]="configType"
            [tenantId]="tenantId"
            [canStage]="true"
            [filter]="initialFilter"
            [navigationUrlTree]="navigationUrlTree"
            (filterChanged)="filterChanged($event)">
          </cs-view-configs>
        </div>
      </div>
      <cs-view-default-configs [configType]="configType"
        [canStage]="true"
        [navigationUrlTree]="defaultNavigationUrlTree">
      </cs-view-default-configs>
    </div>
  }
</div>

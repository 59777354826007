import { Component, OnInit } from '@angular/core';
import {SimVersionStub} from '../../../generated/api-stubs';
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {FormSubmissionButton} from '../../common/forms/form-submission-button';
import {FormSubmissionHandler} from '../../common/forms/form-submission-handler.service';
import {GetTenantSimVersion} from '../../common/get-tenant-sim-version.service';

@Component({
  selector: 'cs-set-global-sim-version',
  templateUrl: './set-global-sim-version.component.html',
  styleUrls: ['./set-global-sim-version.component.scss']
})
export class SetGlobalSimVersionComponent implements OnInit {

  public errorMessage: string;

  public form: UntypedFormGroup;
  public simVersion: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
  public submitButton = new FormSubmissionButton('Save', 'Saving...');
  public saveSuccessful = false;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly formSubmissionHandler: FormSubmissionHandler,
    private readonly simVersionStub: SimVersionStub,
    private readonly getTenantSimVersion: GetTenantSimVersion,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {

  }

  ngOnInit() {
    this.load();
  }

  public async load(): Promise<void> {
    try{
      this.errorMessage = undefined;
      let currentSimVersion = await this.simVersionStub.getSimVersion();

      this.simVersion.setValue(currentSimVersion, {});
      this.form = this.formBuilder.group({
        simVersion: this.simVersion,
      });
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async onSubmit() {
    this.saveSuccessful = false;
    let success = await this.formSubmissionHandler.execute(this.submit, this.form, this.submitButton, this);

    if (success) {
      this.saveSuccessful = true;
    }
  }

  public async submit(): Promise<void> {
    try{
      this.errorMessage = undefined;
      let simVersion: string = this.simVersion.value;
      simVersion = simVersion.trim();
      await this.simVersionStub.postSimVersion({
        simVersion,
      });
      // Update cached version if current tenant.
      await this.getTenantSimVersion.execute();
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }
}

import { ChartSettings } from '../chart-settings';
import { Margin } from '../margin';

export class ContourViewerSettings extends ChartSettings {
  public static build(lapCount: number) {
    let result = new ContourViewerSettings(lapCount);
    return result;
  }

  constructor(lapCount: number) {
    super(lapCount);

    this.chartMargin = new Margin(10, 10, 40, 40);
  }
}

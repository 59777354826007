import { EventEmitter } from '@angular/core';
import { UnitsMap } from '../../../visualizations/viewers/channel-data-loaders/local-config-source-loader';


export abstract class JsonEditorInstance {
  public changed: EventEmitter<any> = new EventEmitter<any>();
  protected customViewFilter: string[] = [];

  constructor(
    public readonly simVersion: string,
    public readonly defaultUnits: UnitsMap) {
  }

  public getCustomViewFilter(): string[] {
    return this.customViewFilter || [];
  }

  public abstract get ready(): boolean;
  public abstract get canValidate(): boolean;
  public abstract get jsonEditor(): any;

  public abstract setValue(value: any): void;
  public abstract getValue(): any;
  public abstract validate(): any;
  public abstract getFirstInput(): HTMLElement | undefined;
  public abstract getFirstVisibleInput(): HTMLElement | undefined;
  public abstract getFilteredValue(): any;
  public abstract setCustomView(parameters: string[]): void;
  public abstract collapseAll(): void;
  public abstract destroy(): void;
}

import * as d3 from '../../../d3-bundle';
import { ILegendChannel } from '../../components/legend-renderer';
import { IViewerChannelData, MonotonicStatus } from '../../channel-data-loaders/viewer-channel-data';
import { IPopulatedMultiPlotChannel } from './i-populated-multi-plot-channel';
import { arrayEquals } from '../../../../common/array-equals';

/**
 * A processed multi plot channel.
 */

export class ProcessedMultiPlotChannel implements ILegendChannel {

  /**
   * The current legend values of the channel (usually based on cursor position)
   */
  public legendValues: number[];

  /**
   * The legend values of the channel when the units are overridden.
   * This is automatically reset when the `legendValues` field changes.
   */
  private _legendValuesForUnitsOverride: number[];

  /**
   * The overridden units (occurs when, for example, gradient is being calculated interactively).
   * This is automatically reset when the `legendValues` field changes.
   */
  private _unitsOverride: string = null;

  /**
   * The monotonic status of the channel.
   */
  private _monotonicStatus: MonotonicStatus = MonotonicStatus.Unknown;

  /**
   * Creates a new processed multi plot channel.
   * @param unprocessed The unprocessed channel.
   * @param name The unique name of the channel (for some sources this may include additional
   *             disambiguating information, e.g. hRideF100:StraightSim vs hRideF100:ApexSim in a study).
   * @param genericName The generic name of the channel, which may not be unique (e.g. hRideF100 in a study).
   * @param channelIndex The index of the channel (used for coloring the channel).
   * @param sources The data sources for the channel.
   * @param units The units of the channel.
   * @param isUserSpecifiedUnits Whether the units were specified by the user.
   * @param renderOnlyForDomain If specified, the channel will only be rendered for this domain.
   */
  constructor(
    public readonly unprocessed: IPopulatedMultiPlotChannel,
    public readonly name: string,
    public readonly genericName: string,
    public readonly channelIndex: number,
    public readonly sources: ReadonlyArray<IViewerChannelData>,
    public readonly baseUnits: string,
    public readonly isUserSpecifiedUnits: boolean,
    public readonly renderOnlyForDomain: string | undefined = undefined) {

    // A channel has one legend value per data source (which is rendered as one column per
    // source in the legend).
    this.legendValues = this.sources.map(v => NaN);
    this._legendValuesForUnitsOverride = [...this.legendValues];
  }

  /**
   * The units of the channel. If the units are overridden, this will return the overridden units.
   */
  public get units(){
    // If the legend values have changed from what they were when the units were overridden, reset the units override.
    // This is used to automatically reset the units when the user has finished performing interactive calculations
    // on the chart such as gradient.
    if(this._legendValuesForUnitsOverride && !arrayEquals(this._legendValuesForUnitsOverride, this.legendValues)){
      this._unitsOverride = null;
      this._legendValuesForUnitsOverride = null;
    }
    return this._unitsOverride ?? this.baseUnits;
  }

  /**
   * Overrides the default units for the channel. This occurs when, for example, the gradient is being
   * calculated interactively.
   * The legend values for the new units should be set before setting the units override.
   * The current legend values are stored and if the legend values change, the units override is reset.
   */
  public set unitsOverride(value: string){
    this._legendValuesForUnitsOverride = [...this.legendValues];
    this._unitsOverride = value;
  }
  
  /**
   * True if any data sources have data.
   */
  public get hasData(): boolean {
    return this.sources.some(v => v.hasData);
  }

  /**
   * The minimum value of the channel across all data sources.
   */
  public get minimum(): number {
    return d3.minStrict(this.sources.filter(v => v.data).map(v => v.minimum));
  }

  /**
   * The maximum value of the channel across all data sources.
   */
  public get maximum(): number {
    return d3.maxStrict(this.sources.filter(v => v.data).map(v => v.maximum));
  }

  /**
   * The minimum value of the channel across all data sources that are visible.
   * @param sourcesVisibility The visibility of the sources.
   * @returns The minimum value.
   */
  public getVisibleMinimum(sourcesVisibility: ReadonlyArray<boolean>): number {
    return d3.minStrict(this.sources.filter((v, i) => v.data && sourcesVisibility[i]).map(v => v.minimum));
  }

  /**
   * The maximum value of the channel across all data sources that are visible.
   * @param sourcesVisibility The visibility of the sources.
   * @returns The maximum value.
   */
  public getVisibleMaximum(sourcesVisibility: ReadonlyArray<boolean>): number {
    return d3.maxStrict(this.sources.filter((v, i) => v.data && sourcesVisibility[i]).map(v => v.maximum));
  }

  /**
   * Gets whether the channel is visible.
   */
  public get isVisible(): boolean {
    return this.unprocessed.isVisible;
  }
  /**
   * Sets whether the channel is visible.
   */
  public set isVisible(value: boolean) {
    this.unprocessed.isVisible = value;
  }

  /**
   * Gets whether the channel axis should be reversed (used in parallel coordinates plots).
   */
  public get reverseAxis(): boolean {
    return this.unprocessed.reverseAxis;
  }
  /**
   * Sets whether the channel axis should be reversed (used in parallel coordinates plots).
   */
  public set reverseAxis(value: boolean) {
    this.unprocessed.reverseAxis = value;
  }

  /**
   * Gets the monotonic status of the channel, taking into account all the data sources.
   * If all data sources are increasing, the channel is increasing. If all data sources are
   * decreasing, the channel is decreasing. Otherwise, the channel is not monotonic.
   */
  public get monotonicStatus(): MonotonicStatus {
    if (this._monotonicStatus === MonotonicStatus.Unknown) {
      if (this.sources.filter(v => v.hasData).every(v => v.monotonicStatus === MonotonicStatus.Increasing)) {
        this._monotonicStatus = MonotonicStatus.Increasing;
      } else if (this.sources.filter(v => v.hasData).every(v => v.monotonicStatus === MonotonicStatus.Decreasing)) {
        this._monotonicStatus = MonotonicStatus.Decreasing;
      } else {
        this._monotonicStatus = MonotonicStatus.None;
      }
    }

    return this._monotonicStatus;
  }

  /**
   * Gets whether the channel is monotonic (either increasing or decreasing).
   */
  public get isMonotonic(): boolean {
    return this.monotonicStatus === MonotonicStatus.Decreasing || this._monotonicStatus === MonotonicStatus.Increasing;
  }
}

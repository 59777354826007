<!--pre class="raw-json">
{{json}}
</pre-->

@if (data && data.root && (data.root.conditions.length || data.root.groups.length)) {
  <div
    class="filter-query-renderer" [ngClass]="{ 'query-invalid': !data.isValid, 'query-not-applied': !isApplied }">
    <cs-filter-query-renderer-group [group]="data.root" [tenantUsers]="tenantUsers">
    </cs-filter-query-renderer-group>
  </div>
}

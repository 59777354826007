import {Component, Input} from '@angular/core';
import {GetTenantBillableStoredSimulationCountQueryResult, StudyStub} from '../../generated/api-stubs';
import {GetFriendlyErrorAndLog} from '../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import { AuthenticationService } from '../identity/state/authentication.service';

@Component({
  selector: 'cs-tenant-billable-simulation-count',
  templateUrl: './tenant-billable-simulation-count.component.html'
})
export class TenantBillableSimulationCountComponent {
  @Input() public tenantId: string;

  public errorMessage: string;

  public data: GetTenantBillableStoredSimulationCountQueryResult;
  public isLoading: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private studyStub: StudyStub,
    private getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  public async load() {
    try {
      this.isLoading = true;

      if(!this.tenantId){
        this.tenantId = this.authenticationService.userDataSnapshot?.tenant;
      }

      this.data = await this.studyStub.getTenantBillableStoredSimulationCount(this.tenantId);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
    this.isLoading = false;
  }
}


/**
 * Get the line break character based on the user's operating system.
 * @returns The line break character.
 */
export function getLineBreak(): string {
  if (navigator.userAgent.indexOf('Windows') !== -1) {
    return '\r\n';
  }
  return '\n';
}

import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

export function requiredValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const errors = Validators.required(control);
    return errors ? {
      required: {
        value: control.value,
        message: 'is required'
      }
    } : null;
  };
}

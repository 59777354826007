import { Injectable } from '@angular/core';
import { isEncrypted } from './is-encrypted';
import { JsonEditorCustomization } from './json-editor-customization';
import{ JSONEditor } from'@json-editor/json-editor'

@Injectable()
export class EncryptedResolver extends JsonEditorCustomization {
  public apply(): void {
    JSONEditor.defaults.resolvers.unshift(function(schema: any) {
      if (isEncrypted(schema)) {
        return 'encrypted';
      }

      return undefined;
    });
  }
}

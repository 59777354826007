@if (hasBeenActivated) {
  <div>
    <cs-job-selector [jobs]="jobs"
      [jobSelectorState]="jobSelectorState"
    (changed)="selectJob($event)"></cs-job-selector>
    <div class="container-fluid">
      <cs-error-message [message]="errorMessage"></cs-error-message>
      @if (job) {
        <div>
          @if (!job.scalarResults.isLoaded) {
            <cs-loader id="job-scalar-results-loader"></cs-loader>
          }
          @if (job.scalarResults.isLoaded) {
            <div>
              @if (!job.scalarResults.value || !job.scalarResults.value.length) {
                <div>
                  <div class="alert alert-info">
                    No scalar results found.
                  </div>
                </div>
              }
              @if (job.scalarResults.value && job.scalarResults.value.length) {
                <div>
                  @if (hasConstraintResults) {
                    <h1 class="scalar-result-type-header">
                      Constraint Scalar Results
                    </h1>
                    <ng-container [ngTemplateOutlet]="tables" [ngTemplateOutletContext]="{ sourceType: ScalarResultType.constraint }">
                    </ng-container>
                  }
                  <h1 class="scalar-result-type-header">
                    Scalar Results
                  </h1>
                  <ng-container [ngTemplateOutlet]="tables" [ngTemplateOutletContext]="{ sourceType: ScalarResultType.standard }">
                  </ng-container>
                </div>
              }
            </div>
          }
        </div>
      }
    </div>
  </div>
}

<ng-template #tables let-sourceType="sourceType">
  @for (scalarSet of job.scalarResults.value; track scalarSet) {
    @if (getResults(scalarSet, sourceType).length) {
      <div class="card">
        <h1 class="card-header">
          {{scalarSet.name}}
        </h1>
        <div class="card-body">
          <table class="table last-item" id="scalar-results-table-{{scalarSet.simType}}">
            <thead>
              <tr>
                <th>Name</th>
                <th>Value</th>
                <th>Units</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              @for (item of getResults(scalarSet, sourceType); track item; let i = $index) {
                <tr>
                  <td>{{item.name}}</td>
                  <td class="scalar-results-row-{{i}}-value"><cs-unit [channelName]="item.name" [units]="item.units" [value]="item.value"></cs-unit></td>
                  <td class="scalar-results-row-{{i}}-units"><cs-unit [channelName]="item.name" [units]="item.units" [value]="item.value" [showUnits]="true"></cs-unit></td>
                  <td>{{item.description}}</td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    }
  }
</ng-template>

import { EventEmitter } from '@angular/core';
import { LogError } from './log-error.service';
import { ServiceOnInit } from './service-on-init';
import { AuthenticationService, UserData } from '../identity/state/authentication.service';

export abstract class GetSimVersionBase implements ServiceOnInit {

  public changed: EventEmitter<string> = new EventEmitter<string>();

  private _isUpdating: boolean;
  private _currentSimVersion: string;
  protected userData: UserData = undefined;

  constructor(
    private readonly logError: LogError,
    private readonly authenticationService: AuthenticationService) {
  }

  public async serviceOnInit() {
    await this.updateCurrentUser();
  }

  public async updateCurrentUser() {
    try {
      let previousUserData = this.userData;
      this.userData = this.authenticationService.userDataSnapshot;
      if(this.userData?.sub !== previousUserData?.sub){
        this.updateCurrentSimVersion(undefined);
        await this.execute();
      }
    } catch (error) {
      this.logError.execute(error);
    }
  }

  public get currentSimVersion(): string {
    return this._currentSimVersion;
  }

  public get isUpdating(): boolean {
    return this._isUpdating;
  }

  public async execute(): Promise<string> {
    await this.authenticationService.checkAuthentication().toPromise();
    if(!this.authenticationService.isAuthenticatedSnapshot){
      return undefined;
    }
    this._isUpdating = true;
    try {
      let result = await this.loadSimVersion();

      if(this._currentSimVersion !== result){
        this.updateCurrentSimVersion(result);
      }

      return result;
    } finally {
      this._isUpdating = false;
    }
  }

  protected updateCurrentSimVersion(simVersion: string | undefined){
    this._currentSimVersion = simVersion;
    this.changed.emit(this._currentSimVersion);
  }

  protected abstract loadSimVersion(): Promise<string>;
}

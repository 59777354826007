import {Component, OnInit} from '@angular/core';
import {ChartEditorDialogData} from './chart-editor-dialog.service';
import {DialogManager, DialogBase} from '../../common/dialogs/dialog-manager.service';
import {ChartEditorDialogSession, ChartEditorDialogSessionFactory} from './chart-editor-dialog-session';

@Component({
  selector: 'cs-chart-editor-dialog',
  templateUrl: './chart-editor-dialog.component.html',
  styleUrls: ['./chart-editor-dialog.component.scss']
})
export class ChartEditorDialogComponent implements OnInit {
  public isVisible: boolean = false;

  public dialog: DialogBase<any>;
  public session: ChartEditorDialogSession;

  constructor(
    private sessionFactory: ChartEditorDialogSessionFactory,
    private dialogManager: DialogManager) {
  }

  public ngOnInit(): any {
    this.dialogManager.secondaryChanged.subscribe(() => this.dialogManagerChanged());
  }

  public accept() {
    if(this.dialog) {
      this.dialog.resolve(this.session.buildResult());
    }
  }

  public dismiss() {
    if(this.dialog){
      this.dialog.dismiss();
    }
  }

  public dialogManagerChanged() {
    this.dismiss();

    let currentDialog = this.dialogManager.getSecondary();
    if(currentDialog && currentDialog instanceof ChartEditorDialogData){
      this.isVisible = true;
      this.dialog = <ChartEditorDialogData>currentDialog;
      this.session = this.sessionFactory.create(<ChartEditorDialogData>currentDialog);
      this.session.load();
    } else {
      this.isVisible = false;
      this.session = undefined;
      this.dialog = undefined;
    }
  }
}


import { IMultiPlotSide } from './types/i-multi-plot-side';
import { GetValidatedChannel } from './get-validated-channel';


/**
 * Validates a side (a column or row) of a multi-plot layout.
 */
export class ValidateSide {
  constructor(
    private readonly getValidatedChannel: GetValidatedChannel) {
  }

  /**
   * Validates a side (a column or row) of a multi-plot layout.
   * Modifies the passed in side.
   *
   * @param side - The side to validate.
   */
  public execute(side: IMultiPlotSide) {

    // Ensure the side has a channels array and a relative size.
    side.channels = side.channels || [];
    side.relativeSize = side.relativeSize || 1;

    for (let i = 0; i < side.channels.length; ++i) {
      // Validate each channel.
      side.channels[i] = this.getValidatedChannel.execute(side.channels[i]);
    }
  }
}

import { BootstrapBase } from './bootstrap-base';
import { HTMLDivSelection } from '../untyped-selection';
import * as d3 from '../d3-bundle';
import { Disposable } from '../disposable';
import { NavigationStation } from '../navigation-station/navigation-station';
import { createRandomString } from '../create-random-string';
import { CANVAS_SNAPSHOT_BUTTON_ID, NAVIGATION_STATION_ID_ID } from './constants';
import { CanopyFileLoader } from '../../simulations/visualizations/canopy-file-loader.service';
import { CanopySiteHooks } from '../../simulations/visualizations/canopy-site-hooks.service';
import { UserData } from '../../identity/state/authentication.service';
import { DocumentType, DocumentSubType, PostStudyInlineResult } from '../../../generated/api-stubs';
import { ScalarMetadataItem } from '../url-file-loader';
import { MockFileLoader } from './mock-url-file-loader';

export abstract class BootstrapNavigationStationBase extends BootstrapBase {

  abstract get description(): string;

  abstract createNavigationStation(elementId: string, userData: UserData, fileLoader: CanopyFileLoader, siteHooks: CanopySiteHooks): NavigationStation;

  protected navigationStationBuilt(navigationStation: NavigationStation): Promise<void> {
    return Promise.resolve();
  }

  public async load(testArea: HTMLDivSelection, userData: UserData, fileLoader: CanopyFileLoader, siteHooks: CanopySiteHooks): Promise<Disposable> {
    let randomId = createRandomString(10);
    testArea
      .append('button')
      .attr('class', 'btn btn-primary')
      .attr('id', CANVAS_SNAPSHOT_BUTTON_ID)
      .on('click', () => {
        let self = this;
        d3.selectAll('canvas').each(function() {
          self.writeCanvas(<HTMLCanvasElement>d3.select(this).node());
        });
      })
      .text('Canvas Snapshot');
    let idArea = testArea.append('p');
    idArea.append('span').text('Navigation Station ID: ');
    idArea.append('span').attr('id', NAVIGATION_STATION_ID_ID).text(randomId);
    testArea.append('p').text(this.description);
    testArea.append('div').attr('id', randomId).attr('class', 'test-chart-container');
    let navigationStation = this.createNavigationStation(randomId, userData, fileLoader, siteHooks);
    await navigationStation.build();
    await this.navigationStationBuilt(navigationStation);
    return navigationStation;
  }

  public addGeneratedStudy(studyId: string, simType: string, userData: UserData, fileLoader: CanopyFileLoader) {

    let inlineStudyResult: PostStudyInlineResult = {
      errors: [],
      warnings: [],
      diagnosis: [],
      scalarResults: {},
      vectorResults: {},
      study: {
        documentId: undefined,
        tenantId: undefined,
        userId: undefined,
        name: 'a',
        type: DocumentType.study,
        subType: DocumentSubType.definition,
        simVersion: '',
        creationDate: undefined,
        modifiedDate: undefined,
        deleteRequested: false,
        data: {
          definition: {
            simConfig: {
              track: {
                name: 'Temp',
                hTrackAboveSeaLevel: 150,
                trackOutline: {
                  xTrackEdgeLeft: [
                    0,
                    -5,
                    -10,
                    -15,
                    -20,
                    -25,
                    -30,
                    -35,
                    -40,
                    -45,
                    -50,
                    -55,
                    -60,
                    -65,
                    -70,
                    -75,
                    -80,
                    -85,
                    -90,
                    -95,
                    -100,
                  ],
                  yTrackEdgeLeft: [
                    -5,
                    -5,
                    -5,
                    -5,
                    -5,
                    -5,
                    -5,
                    -5,
                    -5,
                    -5,
                    -5,
                    -5,
                    -5,
                    -5,
                    -5,
                    -5,
                    -5,
                    -5,
                    -5,
                    -5,
                    -5,
                  ],
                  xTrackEdgeRight: [
                    0,
                    -5,
                    -10,
                    -15,
                    -20,
                    -25,
                    -30,
                    -35,
                    -40,
                    -45,
                    -50,
                    -55,
                    -60,
                    -65,
                    -70,
                    -75,
                    -80,
                    -85,
                    -90,
                    -95,
                    -100,
                  ],
                  yTrackEdgeRight: [
                    5,
                    5,
                    5,
                    5,
                    5,
                    5,
                    5,
                    5,
                    5,
                    5,
                    5,
                    5,
                    5,
                    5,
                    5,
                    5,
                    5,
                    5,
                    5,
                    5,
                    5,
                  ]
                },
              }
            }
          },
          errorMessages: [],
          jobCount: 0,
          dispatchedJobCount: 0,
          completedJobCount: 0,
          succeededJobCount: 0,
          succeededComputeCredits: 0,
          succeededStorageCredits: 0,
          seed: 0,
          isTransient: false,
          executionTimeSeconds: 0,
          studyType: undefined,
          studyState: undefined,
          sources: []
        }
      }
    };

    inlineStudyResult.scalarResults[simType] = {
      sLapTotal: {
        value: 100,
        units: 'm',
        description: '',
        constraintParameterPath: undefined,
      },
    };

    const lapCount = 4;
    const pointsPerLap = 25;
    const pointCount = lapCount * pointsPerLap;

    function generateSineWave(pointCount: number, amplitude: number = 1, frequency: number = 1): number[] {
      let sineWave: number[] = [];
      let angularFrequency = 2 * Math.PI * frequency;

      for (let i = 0; i < pointCount; i++) {
        let angle = (angularFrequency * i) / pointCount;
        sineWave.push(amplitude * Math.sin(angle));
      }

      return sineWave;
    }

    function hashCode(str: string): number {
      let hash = 0;
      let i;
      let chr;
      if (str.length === 0) {
        return hash;
      }
      for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
      }
      return hash;
    }

    let hash = hashCode(studyId);

    inlineStudyResult.vectorResults[simType] = {
      NLap: {
        values: Array(pointCount).fill(0).map((v, i) => Math.floor(i / pointsPerLap)),
        units: '',
        description: '',
        xDomainName: 'sRun',
      },
      sRun: {
        values: Array(pointCount).fill(0).map((v, i) => i),
        units: 'm',
        description: '',
        xDomainName: 'sRun',
      },
      vCar: {
        values: generateSineWave(pointCount, hash % 20, lapCount + (hash % 2)),
        units: 'm/s',
        description: '',
        xDomainName: 'sRun',
      }
    };

    fileLoader.addInlineStudy(
      userData.tenant,
      studyId,
      inlineStudyResult);
  }

  protected configureMockExploration(mockFileLoader: MockFileLoader, explorationMap: string, scalarResultsCsv: string, scalarMetadataCsv: string) {
    mockFileLoader.overrideLoadExplorationMap = (studyId: string) => Promise.resolve(JSON.parse(explorationMap));

    mockFileLoader.overrideLoadScalarResultsForStudy = (studyId: string) => {
      let results = d3.csvParseRows(scalarResultsCsv);
      let metadata = d3.csvParse(scalarMetadataCsv) as unknown as ScalarMetadataItem[];

      let data = mockFileLoader.createScalarResultsUsingScalarMetadata(results, metadata);
      return Promise.resolve({
        data,
        metadata,
        inputsMetadata: []
      });
    };
  }
}

import {Injectable} from '@angular/core';

@Injectable()
export class TextFileReader {
  public async readSingleFileAndReset(event: any): Promise<TextFile | undefined> {
    let files = event.target.files;
    if(!files || files.length === 0) {
      return undefined;
    }
    let file = files[0];
    event.target.value = '';
    let content = await this.read(file);
    return new TextFile(file.name, content);
  }

  public read(file: File): Promise<string> {
    let reader = new AsyncTextFileReader(file);
    return reader.read();
  }
}

class AsyncTextFileReader {
  private fileReader: FileReader;
  private promise: Promise<any>;
  private resolve: (value: any) => void;

  constructor(private file: File){
    this.fileReader = new FileReader();
    this.promise = new Promise((resolve) => {
      this.resolve = resolve;
    });
  }

  public async read() {
    this.fileReader.onload = () => this.fileLoaded();
    this.fileReader.readAsText(this.file);
    return this.promise;
  }

  private fileLoaded() {
    let fileContents = this.fileReader.result;
    this.resolve(fileContents);
  }
}

export class TextFile {
  constructor(
    public readonly name: string,
    public readonly content: string) {
  }
}

export const CANOPY_STORAGE_PREFIX = 'cs_';

export abstract class BaseStorage {

  abstract readonly storage: Storage;

  public getItem(key: string): any {
    let value = this.storage.getItem(CANOPY_STORAGE_PREFIX + key);

    if (value) {
      try {
        return JSON.parse(value);
      } catch (error) {
        return undefined;
      }
    }

    return undefined;
  }

  public setItem(key: string, value: any) {
    this.storage.setItem(CANOPY_STORAGE_PREFIX + key, JSON.stringify(value));
  }

  public removeItem(key: string) {
    this.storage.removeItem(CANOPY_STORAGE_PREFIX + key);
  }

  public clear(){
    let count = this.storage.length;
    for (let i=count - 1; i >= 0; --i) {
      let key = this.storage.key(i);
      if(key.startsWith(CANOPY_STORAGE_PREFIX)){
        this.storage.removeItem(key);
      }
    }
  }
}

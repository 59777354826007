import { Injectable } from '@angular/core';
import { requiredValidator } from '../../../common/validators/required/required.validator';
import { FormComponentType } from '../../../common/services/form-builder.service';
import { minLengthValidator } from '../../../common/validators/min-length/min-length.validator';
import { FormConfig } from '../../../common/interfaces/form-config/form-config.interface';

@Injectable()
export class EditKeyPermissionsConfig implements FormConfig {
  public readonly fields = [
    {
      name: 'encryptingTenantShortName',
      label: 'Encrypting Tenant Short Name',
      component: FormComponentType.STRING,
      validators: [requiredValidator(), minLengthValidator(2)]
    },
    {
      name: 'minimumSimVersion',
      label: 'Minimum Sim Version',
      defaultValue: '1.0',
      component: FormComponentType.STRING
    },
    {
      name: 'description',
      label: 'Description',
      component: FormComponentType.STRING
    },
  ];
}


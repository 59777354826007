import {Injectable, EventEmitter} from '@angular/core';
import {LoadingDialogData} from './loading-dialog.service';

@Injectable()
export class LoadingDialogSessionFactory {

  constructor(){
  }

  public create(dialog: LoadingDialogData<any>){
    return new LoadingDialogSession(
      dialog);
  }
}

export class LoadingDialogSession{
  public loadingComplete: EventEmitter<any> = new EventEmitter<any>();

  public status: string;
  public isLoading: boolean;

  constructor(
    public dialog: LoadingDialogData<any>){
  }

  public async load(){
    try {
      this.isLoading = true;
      let result = await this.dialog.action(this);
      this.loadingComplete.emit(result);
    } catch (error) {
      this.loadingComplete.emit(new LoadingDialogError(error));
    }
    this.isLoading = false;
  }

  public setStatus(status: string): void{
    this.status = status;
  }
}

export class LoadingDialogError{
  constructor(public readonly error: any){

  }
}

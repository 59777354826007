import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { WorksheetViewModel } from '../worksheet-view-model';
import { CanopyPusher, StudiesProgress, StudyBuildProgress } from '../../common/canopy-pusher.service';
import { GetFriendlyErrorAndLog } from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import { Subscription } from 'rxjs';
import { ColumnViewModel } from '../column-view-model';
import { CellElementToViewModelLookup } from '../cell-element-to-view-model-lookup';
import { RowItemViewModel } from '../row-item-view-model';
import { SimulationViewModel } from '../simulation-view-model';
import { RowMetadataViewModel } from '../row-metadata-view-model';
import { ConfigViewModel } from '../config-view-model';
import { StudyViewModel } from '../study-view-model';
import { ContextMenu } from '../../context-menu/context-menu.service';
import { ButtonMenuItem, KeyboardAction, MenuDefinition } from '../../context-menu/context-menu-types';
import { Position } from '../../visualizations/viewers/position';
import { CommandResult, WorksheetContext } from '../worksheet-commands/worksheet-command';
import { ActivatedRoute } from '@angular/router';

// For resizing look at https://github.com/mattlewis92/angular-resizable-element/

/**
 * Component for displaying a worksheet table.
 */
@Component({
  selector: 'cs-worksheet-table',
  templateUrl: './worksheet-table.component.html',
  styleUrls: ['./worksheet-table.component.scss']
})
export class WorksheetTableComponent implements OnInit, OnDestroy {

  /**
   * The worksheet view model.
   */
  @Input() public worksheet: WorksheetViewModel;

  /**
   * Whether the worksheet is in the dock.
   */
  @Input() public isWorksheetInDock: boolean = false;

  /**
   * The keyboard actions enum.
   */
  public readonly KeyboardAction = KeyboardAction;

  /**
   * The navigate actions enum.
   */
  public readonly NavigateAction = NavigateAction;

  /**
   * The error message to display if an error occurs.
   */
  public errorMessage: string;

  /**
   * The subscription to the studies progress.
   */
  public studiesProgressSubscription: Subscription;

  /**
   * The subscription to the study build progress.
   */
  public studyBuildProgressSubscription: Subscription;

  /**
   * When selecting a range, this represents the start of the range.
   */
  private rangeSelectionStart: MouseTarget | undefined;

  /**
   * Whether to ignore the focus event.
   */
  private ignoreFocus: boolean = false;

  /**
   * Creates an instance of WorksheetTableComponent.
   * @param canopyPusher The canopy pusher service.
   * @param cellElementToViewModelLookup The cell element to view model lookup.
   * @param contextMenu The context menu service.
   * @param elementRef The element reference.
   * @param route The activated route.
   * @param getFriendlyErrorAndLog The service for getting a friendly error message and logging the error.
   */
  constructor(
    private readonly canopyPusher: CanopyPusher,
    private readonly cellElementToViewModelLookup: CellElementToViewModelLookup,
    private readonly contextMenu: ContextMenu,
    private readonly elementRef: ElementRef,
    private readonly route: ActivatedRoute,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  /**
   * Initializes the component.
   */
  ngOnInit() {
    this.studiesProgressSubscription = this.canopyPusher.studiesProgress.subscribe((data: StudiesProgress) => this.onStudiesProgress(data));
    this.studyBuildProgressSubscription = this.canopyPusher.studyBuildProgress.subscribe((data: StudyBuildProgress) => this.onStudyBuildProgress(data));
  }

  /**
   * Clean up after the component is destroyed.
   */
  public ngOnDestroy() {
    this.studiesProgressSubscription.unsubscribe();
    this.studyBuildProgressSubscription.unsubscribe();
  }

  /**
   * Handles the studies progress event.
   * @param data The studies progress data.
   */
  public async onStudiesProgress(data: StudiesProgress) {
    try {
      let shouldUpdate = false;

      // For each study progress item in the event...
      for (let item of data.items) {

        // For each row in the worksheet...
        for (let row of this.worksheet.rows) {

          // Get the studies on the row. They could be one in the study column, and one in the telemetry column.
          const studies = row.getPopulatedStudies();

          // For each study in the row...
          for (let populatedStudy of studies) {

            // If the study is resolved and the study document is available...
            if (populatedStudy.resolvedReference
                && populatedStudy.resolvedReference.data
                && populatedStudy.resolvedReference.data.studyDocument) {

              const targetStudyId = populatedStudy.reference.targetId;
              const study = populatedStudy.resolvedReference.data.studyDocument;
              const isStudyComplete = !!item.jobCount && item.jobCount === item.completedJobCount;

              if (targetStudyId === item.studyId) {
                // If the study ID matches the one in the event, update the progress data in the study document.
                study.executionTimeSeconds = item.executionTimeMs / 1000;
                study.jobCount = item.jobCount;
                study.completedJobCount = item.completedJobCount;
                study.succeededJobCount = item.succeededJobCount;
                study.succeededComputeCredits = item.succeededComputeCredits;
                study.succeededStorageCredits = item.succeededStorageCredits;

                // We should update the worksheet if the study has completed (so we can generate labels, etc.).
                shouldUpdate = shouldUpdate || isStudyComplete;
              } else if (item.resultsStudyIds && item.resultsStudyIds.indexOf(targetStudyId) !== -1) {
                // Studies like post-process user maths cause other study results to update, and they include
                // these target study IDs in the `resultsStudyIds` list of the event.
                // If the study ID in the worksheet matches one of these, and the study in the event has completed,
                // then we should update the worksheet to update any study labels, etc. on the results study.
                shouldUpdate = shouldUpdate || isStudyComplete;
              }
            }
          }
        }
      }

      if (shouldUpdate) {
        // We need to request an update to resolve labels.
        this.worksheet.requestUpdate(true, false);
      }
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  /**
   * Handles the study build progress event.
   * @param data The study build progress data.
   */
  public async onStudyBuildProgress(data: StudyBuildProgress) {
    try {
      // For each row of the worksheet...
      for (let row of this.worksheet.rows) {

        // Get the studies on the row. They could be one in the study column, and one in the telemetry column.
        const studies = row.getPopulatedStudies();

        // For each study in the row...
        for (let populatedStudy of studies) {

          // If the study ID matches the ID in the event, and if the study is resolved and the
          // study document is available...
          if (populatedStudy.reference.targetId === data.studyId
            && populatedStudy.resolvedReference
            && populatedStudy.resolvedReference.data
            && populatedStudy.resolvedReference.data.studyDocument) {

            const study = populatedStudy.resolvedReference.data.studyDocument;

            // If the study has not been updated with the latest progress data...
            if (study.dispatchedJobCount <= data.dispatchedJobCount) {

              // Update the study document.
              study.errorMessages = data.errorMessages;
              study.jobCount = data.jobCount;
              study.dispatchedJobCount = data.dispatchedJobCount;
            }
          }
        }
      }
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  /**
   * Gets the ID for tracking the column in angular.
   * @param index The index of the column.
   * @param item The column view model.
   * @returns The tracking ID.
   */
  public columnTrackById(index: number, item: ColumnViewModel) {
    return item.trackingId;
  }

  /**
   * Handles the context menu event.
   * @param event The mouse event.
   */
  public onTableContextMenu(event: MouseEvent) {
    if (event.altKey) {
      // If the user is holding down the alt key, just let the event filter through..
      return;
    }

    // Right-clicks can contribute to starting or ending a selection range.
    this.verifyRangeSelectionSet(event);

    // Find out what was right-clicked on.
    const target = this.getMouseTargetViewModel(event);
    if (!target) {
      // No view-model was found, so let the event pass through.
      return;
    }

    // The user right-clicked on something we can handle, so stop the browser context menu from showing.
    event.preventDefault();

    // Ensure the target is selected.
    if (!target.viewModel.isSelected) {
      this.performTableClick(event);
    }

    // Generate and show the context menu.
    const menuItemsDefinition = target.viewModel.generateContextMenu(
      new WorksheetContext(this.isWorksheetInDock, this.route));

    let menuPosition: Position;
    if (event.clientX || event.clientY) {
      menuPosition = new Position(event.clientX, event.clientY);
    } else {
      // This occurs when using a button to open the context menu.
      const el = (event.target || event.srcElement) as Element;
      const boundingRect = el.getBoundingClientRect();
      menuPosition = new Position(boundingRect.left, boundingRect.top);
    }

    this.contextMenu.content = new MenuDefinition(
      menuPosition,
      menuItemsDefinition.items,
      menuItemsDefinition.wrapper);
  }

  /**
   * Handles navigating the worksheet using the keyboard.
   * @param event The keyboard event.
   * @param action The navigation action.
   */
  public onTableNavigateKey(event: KeyboardEvent, action: NavigateAction) {

    // Keyboard navigation can contribute to range selection.
    this.verifyRangeSelectionSet(event);

    // Ensure we have a view model for the active element.
    let target = this.getElementViewModel(document.activeElement);
    if (!target) {
      return;
    }

    // Get the row and column.
    const row = target.viewModel.row;
    let columnIndex = row.columns.findIndex(v => v.value === target.viewModel);
    if (columnIndex === -1) {
      return;
    }

    // Prevent page scrolling.
    event.preventDefault();

    // Select the new element.
    if (action === NavigateAction.down || action === NavigateAction.up) {
      const rowIndex = this.worksheet.rows.indexOf(row);
      if (rowIndex === -1) {
        return;
      }

      if (action === NavigateAction.up && rowIndex === 0) {
        return;
      }
      if (action === NavigateAction.down && rowIndex === this.worksheet.rows.length - 1) {
        return;
      }

      const newRow = action === NavigateAction.up ? this.worksheet.rows[rowIndex - 1] : this.worksheet.rows[rowIndex + 1];
      this.clearSelection();
      this.findElementAndFocus(this.elementRef.nativeElement, newRow.columns[columnIndex].value);
    } else {
      const studyColumnIndex = row.columns.findIndex(v => v.value instanceof StudyViewModel);
      if (studyColumnIndex === -1) {
        return;
      }

      if (action === NavigateAction.left && columnIndex === 0) {
        return;
      }
      if (action === NavigateAction.right && columnIndex >= studyColumnIndex) {
        return;
      }

      if (columnIndex >= studyColumnIndex) {
        columnIndex = studyColumnIndex;
      }

      const newColumnIndex = action === NavigateAction.left ? columnIndex - 1 : columnIndex + 1;
      this.clearSelection();
      this.findElementAndFocus(this.elementRef.nativeElement, row.columns[newColumnIndex].value);
    }
  }

  /**
   * Handles a keypress related to the clipboard.
   * @param event The keyboard event.
   */
  public onClipboardKey(event: KeyboardEvent) {
    this.verifyRangeSelectionSet(event);
    if (!event.ctrlKey && !event.metaKey) {
      return;
    }

    if(event.altKey && event.key === 'v'){
      this.onTableKey(KeyboardAction.pasteDuplicate);
      return;
    }
    switch (event.key.toLocaleLowerCase()) {
      case 'x': this.onTableKey(KeyboardAction.cut); break;
      case 'c': this.onTableKey(KeyboardAction.copy); break;
      case 'v': this.onTableKey(KeyboardAction.paste); break;
    }
  }

  /**
   * Handles a keyboard undo or redo shortcut.
   * @param isUndo True if we should undo, false if we should redo.
   */
  public onUndoOrRedo(isUndo: boolean) {
    let target = this.getElementViewModel(document.activeElement);
    if (!target) {
      return;
    }

    let viewModel = target.viewModel;
    if (viewModel instanceof SimulationViewModel) {
      viewModel = viewModel.row.study;
    }

    // We run the undo/redo action through the same system as context menu commands,
    // to ensure that the worksheet is updated correctly.
    const contextMenu = viewModel.generateContextMenu(
      new WorksheetContext(this.isWorksheetInDock, this.route));

    contextMenu.wrapper.executeMenuItemAction({} as MouseEvent, async (e) => {
      if (isUndo) {
        await this.worksheet.undo();
      } else {
        await this.worksheet.redo();
      }
      return CommandResult.NoUpdate;
    });
  }

  /**
   * Handles the user double-clicking on a cell.
   * @param event
   */
  public onTableDoubleClick(event: MouseEvent) {
    this.verifyRangeSelectionSet(event);

    // This is the same as pressing enter on a cell.
    this.onTableKey(KeyboardAction.enter);
  }

  /**
   * When mouse or keyboard actions occur that can contribute to range selection,
   * this method is called to verify that the range selection is set.
   * @param event The event.
   */
  public verifyRangeSelectionSet(event: { shiftKey: boolean }) {
    if (event.shiftKey) {
      // If the shift key is pressed, we should ensure we have a range selection start set.
      if (!this.rangeSelectionStart) {
        this.onRangeSelectionStart();
      }
    } else {
      // If the shift key is not pressed, we should unset any range selection start.
      this.onRangeSelectionEnd();
    }
  }

  /**
   * Handles the start of a range selection.
   */
  public onRangeSelectionStart() {
    if (this.rangeSelectionStart) {
      return;
    }

    // Set the range selection start to the currently active element.
    this.rangeSelectionStart = this.getElementViewModel(document.activeElement);
  }

  /**
   * Handles the end of a range selection.
   */
  public onRangeSelectionEnd() {
    // Unset the range selection start.
    this.rangeSelectionStart = undefined;
  }

  /**
   * Handles the user clicking on the table.
   * @param event The event.
   */
  public onTableClicked(event: MouseEvent) {
    if (event.button) {
      return;
    }
    this.performTableClick(event);
  }

  /**
   * Actions a table click.
   * @param event The event.
   */
  private performTableClick(event: MouseEvent) {
    this.verifyRangeSelectionSet(event);

    const target = this.getMouseTargetViewModel(event);
    if (!target) {
      return;
    }

    // Prevent separate focus event firing.
    event.preventDefault();

    // Set focus to the clicked cell.
    this.setFocusWithoutRaisingEvent(target.element);

    // Ensure the view model is selected, or added to the current selection.
    const isControlKeyPressed = event.ctrlKey || event.metaKey;
    this.onViewModelActivated(target.viewModel, isControlKeyPressed, isControlKeyPressed);
  }

  /**
   * Handles the focus event.
   * @param event The focus event.
   */
  public onTableFocus(event: FocusEvent) {
    if (this.ignoreFocus) {

      // If we have set the ignoreFocus flag, just return.
      return;
    }

    const target = this.getElementViewModel(document.activeElement);
    if (!target) {
      return;
    }

    // Otherwise ensure the view model is selected.
    this.onViewModelActivated(target.viewModel, target.viewModel.isSelected, false);
  }

  /**
   * Handles a view model being "activated" (clicked on, focused, etc.).
   * @param viewModel The view model.
   * @param maintainSelection Whether to maintain the current selection (adding the view model to the selection if necessary).
   * @param toggleViewModelSelection Whether to toggle the selection depending on the current selection state, or always set the view model to selected.
   */
  private onViewModelActivated(viewModel: RowItemViewModel, maintainSelection: boolean, toggleViewModelSelection: boolean) {
    // If we're doing a range selection...
    if (this.rangeSelectionStart) {

      // Clear the current selection.
      this.clearSelection();

      // Get the start and end view models.
      let startViewModel = this.rangeSelectionStart.viewModel;
      let endViewModel = viewModel;

      // Get the start and end rows.
      let startRowIndex = this.worksheet.rows.indexOf(startViewModel.row);
      let endRowIndex = this.worksheet.rows.indexOf(endViewModel.row);

      // Ensure the start and end are in the correct order.
      if (startRowIndex > endRowIndex) {
        let tempIndex = startRowIndex;
        let tempViewModel = startViewModel;
        startRowIndex = endRowIndex;
        startViewModel = endViewModel;
        endRowIndex = tempIndex;
        endViewModel = tempViewModel;
      }

      // Get the start and end columns.
      let startColumnIndex = startViewModel.row.columns.findIndex(v => v.value === startViewModel);
      let endColumnIndex = endViewModel.row.columns.findIndex(v => v.value === endViewModel);

      // Ensure the start and end columns are in the correct order.
      if (startColumnIndex > endColumnIndex) {
        let tempIndex = startColumnIndex;
        startColumnIndex = endColumnIndex;
        endColumnIndex = tempIndex;
      }

      // Select all the view models in the range.
      for (let rowIndex = startRowIndex; rowIndex <= endRowIndex; ++rowIndex) {
        let currentRow = this.worksheet.rows[rowIndex];
        for (let columnIndex = startColumnIndex; columnIndex <= endColumnIndex; ++columnIndex) {
          let currentColumn = currentRow.columns[columnIndex];
          this.setViewModelSelected(currentColumn.value, false);
        }
      }
    } else {
      if (!maintainSelection) {
        // If we're not maintaining the selection, clear it.
        this.clearSelection();
      }

      // Set the view model to selected, or toggle it's selection as instructed.
      this.setViewModelSelected(viewModel, toggleViewModelSelection);
    }
  }

  /**
   * Sets the selection state of a row item view model.
   * @param viewModel The view model.
   * @param toggleSelection Whether to toggle the selection depending on the current selection state, or always set the view model to selected.
   */
  private setViewModelSelected(viewModel: RowItemViewModel, toggleSelection: boolean) {
    if (viewModel instanceof RowMetadataViewModel) {
      // If we're dealing with the first column (the row metadata), apply the selection to the whole row.
      for (let config of viewModel.row.configs) {
        config.isSelected = this.evaluateIsSelected(config.isSelected, toggleSelection);
      }
      viewModel.row.study.isSelected = this.evaluateIsSelected(viewModel.row.study.isSelected, toggleSelection);
    } else if (viewModel instanceof SimulationViewModel) {
      // If we're dealing with a simulation, apply the selection to the study.
      viewModel.row.study.isSelected = this.evaluateIsSelected(viewModel.row.study.isSelected, toggleSelection);
    } else if (viewModel instanceof ConfigViewModel || viewModel instanceof StudyViewModel) {
      // If we're dealing with a config or study, apply the selection to the item.
      viewModel.isSelected = this.evaluateIsSelected(viewModel.isSelected, toggleSelection);
    }
  }

  /**
   * Clears the selection state of all row items in the worksheet.
   */
  private clearSelection() {
    for (let row of this.worksheet.rows) {
      row.study.isSelected = false;
      for (let config of row.configs) {
        config.isSelected = false;
      }
    }
  }

  /**
   * Handles a keypress related to the worksheet.
   * @param action The keyboard action.
   */
  private onTableKey(action: KeyboardAction) {
    let target = this.getElementViewModel(document.activeElement);
    if (!target) {
      return;
    }

    let viewModel = target.viewModel;

    // If the view model is a simulation, apply it to the study.
    if (viewModel instanceof SimulationViewModel) {
      viewModel = viewModel.row.study;
    }

    // Run the equivalent context menu action.
    const contextMenu = viewModel.generateContextMenu(
      new WorksheetContext(this.isWorksheetInDock, this.route));

    const button = contextMenu.items
      .filter((v): v is ButtonMenuItem<CommandResult> => v instanceof ButtonMenuItem)
      .find(v => v.keyboardAction === action);
    if (!button) {
      return;
    }

    contextMenu.wrapper.executeMenuItemAction({} as MouseEvent, (e) => button.action(e));
  }

  /**
   * Sets focus to an element without raising the focus event.
   * @param element The element to focus.
   */
  private setFocusWithoutRaisingEvent(element: Element) {
    this.ignoreFocus = true;
    try {
      let htmlElement = element as HTMLElement;
      if (htmlElement.focus) {
        htmlElement.focus();
      }
    } finally {
      this.ignoreFocus = false;
    }
  }

  /**
   * Evaluate the new selection state.
   * @param previousIsSelectedState The previous selection state.
   * @param toggleSelection Whether to toggle the selection, or always set it to selected.
   * @returns The new selection state.
   */
  private evaluateIsSelected(previousIsSelectedState: boolean, toggleSelection: boolean) {
    return toggleSelection ? !previousIsSelectedState : true;
  }

  /**
   * Gets the view model element that was clicked on.
   * @param event The mouse event.
   * @returns The target element.
   */
  private getMouseTargetViewModelElement(event: MouseEvent): Element | undefined {
    return (event.target || event.srcElement) as Element;
  }

  /**
   * Gets the view model of the element that was clicked on.
   * @param event The mouse event.
   * @returns The view model of the element that was clicked on.
   */
  private getMouseTargetViewModel(event: MouseEvent): MouseTarget | undefined {
    let target = this.getMouseTargetViewModelElement(event);
    return this.getElementViewModel(target);
  }

  /**
   * Gets the view model for a DOM element.
   * @param target The target element.
   * @returns The view model if found, or undefined.
   */
  private getElementViewModel(target: Element): MouseTarget | undefined {
    let viewModel: RowItemViewModel | undefined;

    // Traverse up the DOM tree to find the view model.
    while (!viewModel && target && (!window.event || target !== window.event.currentTarget)) {
      viewModel = this.cellElementToViewModelLookup.get(target);
      if (viewModel) {
        return new MouseTarget(viewModel, target);
      }

      target = target.parentElement;
    }

    return undefined;
  }

  /**
   * Focuses the DOM element corresponding to the view model.
   * @param root The root element from which to start searching.
   * @param viewModel The view model.
   */
  private findElementAndFocus(root: Element, viewModel: RowItemViewModel) {
    const element = this.findElementForViewModel(root, viewModel);
    if (element) {
      element.focus();
    }
  }

  /**
   * Finds the element corresponding to the supplied view model.
   * @param root The root element from which to start searching.
   * @param viewModel The view model.
   * @returns The element if found, or undefined.
   */
  private findElementForViewModel(root: Element, viewModel: RowItemViewModel): HTMLTableDataCellElement {

    // Look up every table cell in our element to view model map until we find a matching view model.
    const cells = root.getElementsByTagName('td');
    for (let i = 0; i < cells.length; ++i) {
      const cell = cells.item(i);
      if (this.cellElementToViewModelLookup.get(cell) === viewModel) {
        return cell;
      }
    }

    return undefined;
  }
}

/**
 * Represents a mouse target.
 */
class MouseTarget {

  /**
   * Creates an instance of MouseTarget.
   * @param viewModel The view model of the target.
   * @param element The DOM element.
   */
  constructor(
    public readonly viewModel: RowItemViewModel,
    public readonly element: Element) {
  }
}

/**
 * The navigate actions enum.
 */
export enum NavigateAction {

  /**
   * Navigate up.
   */
  up,

  /**
   * Navigate down.
   */
  down,

  /**
   * Navigate left.
   */
  left,

  /**
   * Navigate right.
   */
  right,
}


import {Component, HostListener} from '@angular/core';
import {OnInit} from '@angular/core';
import {StudyStagingArea} from './simulations/study-staging-area/study-staging-area.service';
import {ApplicationInsightsRouteChangedHandler} from './common/application-insights-route-changed-handler.service';
import {CanopyPusher} from './common/canopy-pusher.service';
import {JsonEditor} from './simulations/configs/json-config-editor/json-editor.service';
import {ResultsStagingArea} from './simulations/results-staging-area/results-staging-area.service';
import {UnitsManager} from './units/units-manager.service';
import {UserSettingsCache} from './user-state/user-settings-cache.service';
import {LocalUserSettingsManager} from './common/local-user-settings-manager';
import {GetSimVersion} from './common/get-sim-version.service';
import {GetUserSimVersion} from './common/get-user-sim-version.service';
import {GetTenantSimVersion} from './common/get-tenant-sim-version.service';
import {StudyNotifier} from './notifications/study-notifier.service';
import {WorksheetDock} from './worksheets/worksheet-dock/worksheet-dock.service';
import {DialogManager} from './common/dialogs/dialog-manager.service';

@Component({
  selector: 'cs-app',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {


  constructor(
    public readonly dock: WorksheetDock,
    private readonly dialogManager: DialogManager,
    private readonly studyStagingArea: StudyStagingArea,
    private readonly resultsStagingArea: ResultsStagingArea,
    private readonly applicationInsightsRouteChangedHandler: ApplicationInsightsRouteChangedHandler,
    private readonly pusher: CanopyPusher,
    private readonly jsonEditor: JsonEditor,
    private readonly userSettingsCache: UserSettingsCache,
    private readonly unitsManager: UnitsManager,
    private readonly localUserSettingsManager: LocalUserSettingsManager,
    private readonly getUserSimVersion: GetUserSimVersion,
    private readonly getTenantSimVersion: GetTenantSimVersion,
    private readonly getSimVersion: GetSimVersion,
    private readonly getSimVersionOrOverrideService: GetUserSimVersion,
    private readonly studyNotifier: StudyNotifier) {
   }

  async ngOnInit() {
    // LocalUserSettingsManager should be loaded before GetUserSimVersion.
    this.localUserSettingsManager.serviceOnInit();

    this.getUserSimVersion.serviceOnInit();
    this.getTenantSimVersion.serviceOnInit();
    this.getSimVersion.serviceOnInit();
    this.studyStagingArea.serviceOnInit();
    this.resultsStagingArea.serviceOnInit();
    this.applicationInsightsRouteChangedHandler.serviceOnInit();
    this.pusher.serviceOnInit();
    this.jsonEditor.serviceOnInit();
    this.userSettingsCache.serviceOnInit();
    this.unitsManager.serviceOnInit();
    this.getSimVersionOrOverrideService.serviceOnInit();
    this.studyNotifier.serviceOnInit();
  }

  @HostListener('keydown.escape', [])
  public onEscape() {
    this.dialogManager.dismiss();
  }
}

import * as d3 from '../../d3-bundle';
import * as d3con from 'd3-contour';
import { SiteHooks } from '../../site-hooks';
import { SharedState } from '../shared-state';
import { SVGSelection } from '../../untyped-selection';
import { ContourViewerSettings } from './contour-viewer-settings';
import { Utilities } from '../../utilities';
import { XData } from './data/x-data';
import { YData } from './data/y-data';
import { ZData } from './data/z-data';
import { GREEN, BLUE } from '../../constants';
import { Subject, Subscription } from 'rxjs';

/**
 * This viewer has not been converted to use our standard components and data pipeline.
 *
 * Traditionally Mark would prototype viewers and then they would be refactored and improved by
 * the platform team. In this case, that hasn't happened.
 */
export abstract class ContourViewer {

  public errorEvent: Subject<string> = new Subject<string>();

  protected windowResizeSubscription!: Subscription;
  protected sourceLoaderSetChangedSubscription!: Subscription;
  protected parent!: d3.Selection<HTMLDivElement, any, HTMLElement, any>;
  protected elementId!: string;
  protected sharedState!: SharedState;
  protected svgHeight = 400;
  protected conHeight!: number;
  protected conWidth!: number;
  protected settings!: ContourViewerSettings;
  protected svg!: SVGSelection;

  protected xData: XData[] = [];
  protected yData: YData[] = [];
  protected zData: ZData[] = [];

  protected xs = d3.scaleLinear();
  protected ys = d3.scaleLinear();
  protected zsLin = d3.scaleLinear();

  protected xActiveIndex = 0;
  protected NLengthOfColourBar = 100;
  protected NWidthOfColourBar = 4;
  protected NLevels = 25;
  protected rMaxMaxTopLevel = 1e-6;
  protected reverseColorScale: boolean = false;

  constructor(protected siteHooks: SiteHooks) { }

  protected abstract load(): Promise<any>;

  public async reBuild(): Promise<any> {
    // remove ALL of existing chart
    this.select().selectAll('*').remove(); // !!
    // remove existing yData so we have to do this.load() again
    // this.sLap = [];
    // build a new one with the new info
    await this.build(this.elementId);
  }

  public async build(elementId: string) {

    this.elementId = elementId;

    this.settings = ContourViewerSettings.build(1);

    if (this.sharedState) {
      this.settings.sourceCount = this.sharedState.sourceLoaderSet.sources.length;
      if (!this.sourceLoaderSetChangedSubscription) {
        this.sourceLoaderSetChangedSubscription = this.sharedState.sourceLoaderSet.changed.subscribe(v => {
          this.settings.sourceCount = this.sharedState.sourceLoaderSet.sources.length;
        });
      }
    }

    this.settings.svgSize = Utilities.getRequiredSvgSize(this.elementId, this.settings.svgSize);

    this.parent = this.select()
      .append<HTMLDivElement>('div')
      .attr('class', 'contour-viewer-container');

    await this.load();

    let svg = this.parent
      .append<HTMLDivElement>('div')
      .append<SVGElement>('svg')
      .attr('class', 'contour-viewer')
      .attr('width', this.settings.svgSize.width)
      .attr('height', this.settings.svgSize.height)
      .attr('id', 'svg')
      .attr('transform', 'translate(' + this.settings.svgPadding.left + ',' + this.settings.svgPadding.top + ')');
    this.svg = svg;


    let height = +this.svg.attr('height') - this.settings.chartMargin.top - this.settings.chartMargin.bottom;
    let width = +this.svg.attr('width') - this.settings.chartMargin.left - this.settings.chartMargin.right - height * (this.NWidthOfColourBar / this.NLengthOfColourBar) - 1.5 * this.settings.legend.valueWidth;
    let rxScale = width / this.xData[0].values.length;
    let ryScale = height / this.zData[0].yDomain.values.length;
    // let rScale = d3.min([width / this.xData[0].values.length, height / this.zData[0].yDomain.values.length]);

    this.conWidth = width; //rScale * this.xData[0].values.length;
    this.conHeight = height; //rScale * this.zData[0].yDomain.values.length;


    // create scales
    let colourScale = d3.scaleLinear<string>()
      .domain([this.zData[0].zMin, this.zData[0].zMax])
      .range(this.reverseColorScale ? [GREEN, BLUE] : [BLUE, GREEN])
      .interpolate(d3.interpolateHcl);
    this.xs = d3.scaleLinear()
      .domain(this.xData[0].fullDomain)
      .range([0, this.conWidth]);
    this.ys = d3.scaleLinear()
      .domain([this.zData[0].yDomain.yMin, this.zData[0].yDomain.yMax])
      .range([this.conHeight, 0]);
    this.zsLin = d3.scaleLinear()
      .domain([this.zData[0].zMin, this.zData[0].zMax])
      .range([this.conHeight, 0]);
    // let zs = d3.scaleSequential(colourScale).domain([this.zData[0].zMax, this.zData[0].zMin]);
    // let xPointsToSize = d3.scaleLinear().domain([0, this.xData[0].values.length]).range([0, this.conWidth]);
    // let yPointsToSize = d3.scaleLinear().domain([0, this.zData[0].yDomain.values.length]).range([0, this.conHeight]);

    // Saturate if all values are within rMaxMaxTopLevel of maximum z-value
    if (this.zData[0].zMin > (1 - this.rMaxMaxTopLevel) * this.zData[0].zMax) {
      this.NLevels = 2;
      colourScale.range([GREEN, GREEN]);
    }

    // Build the chart area and contours etc.
    let chartArea = svg.append('g')
      .attr('class', 'chart-area')
      .attr('transform', 'translate(' + this.settings.chartMargin.left + ',' + this.settings.chartMargin.top + ')');
    let contours = d3con.contours()
      .size([this.xData[0].values.length, this.zData[0].yDomain.values.length])
      .thresholds(this.NLevels)
      // .thresholds(d3.range(this.zData[0].zMin, this.zData[0].zMax, (this.zData[0].zMax - this.zData[0].zMin) / this.NLevels))
      (this.zData[0].values);
    let paths = chartArea.append('g').attr('class', 'contour').selectAll('path')
      .data(contours);
    let proj = d3.geoProjection((x, y) => [rxScale * x, ryScale * y]).translate([0, 0]).fitSize([this.conWidth, this.conHeight], contours[0]);
    // let proj = d3.geoIdentity().fitSize([this.conWidth, this.conHeight], contours[0]);
    paths.enter().append('path')
      .attr('d', d3.geoPath(proj))
      // .attr('transform', 'translate(' + (-this.svg.attr('width')/2 - 3) + ',' + (-this.conHeight/2 + 3 + this.settings.chartMargin.top) + ')')
      .attr('fill', (d: any) => colourScale(d.value));

    // slap some axes on it
    let xAxes = this.svg.append('g')
      .attr('class', 'xAxes')
      .attr('transform', 'translate(' + this.settings.chartMargin.left + ',' + (this.settings.chartMargin.top + this.conHeight) + ')');

    xAxes.append<SVGGElement>('g')
      .attr('class', 'axis')
      .call(this.makeXAxis());

    xAxes.append('text')
      .attr('class', 'label')
      .attr('x', this.conWidth / 2)
      .attr('y', 0)
      .attr('dy', 30)
      .attr('text-anchor', 'middle')
      .text(this.xAxisLabel());

    let yAxes = this.svg.append('g')
      .attr('class', 'yAxes')
      .attr('transform', 'translate(' + this.settings.chartMargin.left + ',' + this.settings.chartMargin.top + ')');

    yAxes.append<SVGGElement>('g')
      .attr('class', 'axis')
      .call(this.makeYAxis());

    yAxes.append('text')
      .attr('class', 'label')
      .attr('x', -this.conHeight / 2)
      .attr('y', 0)
      .attr('dy', -30)
      .attr('text-anchor', 'middle')
      .attr('transform', 'rotate(-90)')
      .text(this.yAxisLabel());

    let colourbar = svg.append('g')
      .attr('class', 'colourbar')
      .attr('transform', 'translate(' + (this.settings.chartMargin.left + this.conWidth + 1.5 * this.settings.legend.valueWidth) + ',' + this.settings.chartMargin.top + ')');

    let linearSlew = d3.range(this.zData[0].zMax, this.zData[0].zMin, (this.zData[0].zMin - this.zData[0].zMax) / this.NLengthOfColourBar);
    let colourBarData = Array(this.NLengthOfColourBar * this.NWidthOfColourBar);
    for (let i = 0; i < this.NLengthOfColourBar; i++) {
      for (let j = 0; j < this.NWidthOfColourBar; j++) {
        colourBarData[i * this.NWidthOfColourBar + j] = linearSlew[i];
      }
    }
    console.log('Data length: ' + colourBarData.length);
    console.log('Bar width: ' + this.NWidthOfColourBar);
    console.log('Bar length: ' + this.NLengthOfColourBar);
    console.log('Levels: ' + this.NLevels);
    console.log('Z Scale Domain[0]: ' + this.zsLin.domain()[0]);
    console.log('Z Scale Domain[1]: ' + this.zsLin.domain()[1]);
    console.log('Z Scale Range[0]: ' + this.zsLin.range()[0]);
    console.log('Z Scale Range[1]: ' + this.zsLin.range()[1]);

    colourbar.selectAll('path')
      .data(d3con.contours()
        .size([this.NWidthOfColourBar, this.NLengthOfColourBar])
        .thresholds(this.NLevels)
        // .thresholds(d3.range(this.zData[0].zMin, this.zData[0].zMax, (this.zData[0].zMax - this.zData[0].zMin) / this.NLevels))
        (colourBarData))
      .enter().append('path')
      .attr('d', d3.geoPath(d3.geoIdentity().scale(this.conHeight / this.NLengthOfColourBar)))
      .attr('fill', (d: any) => colourScale(d.value));

    let zAxes = colourbar.append('g')
      .attr('class', 'zAxes');
    // .attr('transform', 'translate(' + (this.settings.chartMargin.left + this.conWidth + this.settings.legend.valueWidth) + ',' + this.settings.chartMargin.top + ')');

    zAxes.append<SVGGElement>('g')
      .attr('class', 'axis')
      .call(this.makeZAxis().ticks(this.NLevels));

    zAxes.append('text')
      .attr('class', 'label')
      .attr('x', -this.conHeight / 2)
      .attr('y', 0)
      .attr('dy', -this.settings.legend.valueWidth)
      .attr('text-anchor', 'middle')
      .attr('transform', 'rotate(-90)')
      .text(this.zAxisLabel());

    // Overlay given y-data
    for (let yChan of this.yData) {
      let xyLine = this.xData[this.xActiveIndex].values.map((x, i) => [x, yChan.values[i]]);
      chartArea.append<SVGPathElement>('path')
        .data([xyLine])
        .attr('d', d3.line<number[]>()
          .x((d) => this.xs(d[0]))
          .y((d) => this.ys(d[1]))
          .curve(d3.curveLinear))
        .style('stroke', 'black')
        .attr('fill', 'none');
    }

    // subscribe to news about window resize events
    if (this.sharedState) {
      if (!this.windowResizeSubscription) {
        this.windowResizeSubscription = this.sharedState.windowResizeNews.subscribe((code: number) => this.resizeChart());
      }
    } else {
      window.onresize = (event) => this.resizeChart();
    }

  }

  private resizeChart() {
    this.reBuild(); // much easier than re-sizing, and there is no zoom state to maintain so who cares!
  }

  // make axis with current x-scale
  protected makeXAxis() {
    let xAxis = d3.axisBottom(this.xs);

    if (this.xData.length === 1 && this.xData[0].valueLabels) {
      let xd = this.xData[0];
      xAxis.tickValues(d3.range(xd.valueLabels.length));
      xAxis.tickFormat((v, i) => xd.valueLabels[i]);
    }

    return xAxis;
  }

  protected makeXGrid() {
    return this.makeXAxis()
      .tickSize(-this.conHeight);
  }

  // make axis with current x-scale
  protected makeYAxis() {
    return d3.axisLeft(this.ys);
  }

  // make axis with current z-scale
  protected makeZAxis() {
    return d3.axisLeft(this.zsLin);
  }

  // make label for x-axis
  protected xAxisLabel() {
    return this.xData[this.xActiveIndex].name + ' (' + this.xData[this.xActiveIndex].units + ')';
  }

  // make label for y-axis
  protected yAxisLabel() {
    if (this.yData[0]) {
      return this.yData[0].name + ' (' + this.yData[0].units + ')';
    }
    return this.zData[0].yDomain.name + ' (' + this.zData[0].yDomain.units + ')';
  }

  // make label for z-axis
  protected zAxisLabel() {
    return this.zData[0].name + ' (' + this.zData[0].units + ')';
  }

  // select element within the current chart
  protected select(selection: string = '') {
    return d3.select('#' + this.elementId + ' ' + selection);
  }

  // select elements within the current chart
  protected selectAll(selection: string = '') {
    return d3.selectAll('#' + this.elementId + ' ' + selection);
  }
}

import { ISourceLoader } from '../channel-data-loaders/source-loader';
import { ChannelNameStyle } from '../channel-data-loaders/channel-name-style';
import { ViewerChannelDataMap } from '../channel-data-loaders/viewer-channel-data-map';


export class LoadSourceChannelDataMap {

  constructor(
    private readonly primaryDomainName: string,
    private readonly channelNameStyle: ChannelNameStyle) {
  }

  public async execute(loader: ISourceLoader, channelNames: ReadonlyArray<string>): Promise<ViewerChannelDataMap> {
    return await loader.getMultipleChannelData(channelNames, this.channelNameStyle, this.primaryDomainName);
    //let map: ViewerChannelDataMap = {};
    //for(let channelName of channelNames){
    //  let channelData = map[channelName];
    //  if(!channelData){
    //    channelData = await loader.getChannelData(channelName, this.channelNameStyle, this.primaryDomainName);
    //    map[channelName] = channelData;
    //  }
    //}
    //return map;
  }
}

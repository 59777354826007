@if (shouldDisplay) {
  <div class="context-menu-container"
    csClickOutside
    (clickOutside)="close()"
    #container>
    <cs-context-menu-renderer
      [items]="menu.content.items"
      [wrapper]="menu.content.wrapper"
      [horizontalExpandDirection]="horizontalExpandDirection"
      [verticalExpandDirection]="verticalExpandDirection"
      [screenSize]="screenSize"
      (closeRequested)="onCloseRequested()">
    </cs-context-menu-renderer>
  </div>
}


import { Injectable } from '@angular/core';
import { LoadingDialog } from '../../../common/dialogs/loading-dialog.service';
import { ConfigReference, ConfigStub } from '../../../../generated/api-stubs';
import { fromReadonly } from '../../../common/from-readonly';
import { StudyInput } from '../../study-input';
import { AuthenticationService } from '../../../identity/state/authentication.service';

/**
 * A service for importing a standard (non-telemetry) config from the staging area.
 */
@Injectable()
export class ImportStandardConfigFromStagingArea {

  /**
   * Creates an instance of ImportStandardConfigFromStagingArea.
   * @param authenticationService The authentication service.
   * @param loadingDialog The loading dialog service.
   * @param configStub The config stub service.
   */
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly loadingDialog: LoadingDialog,
    private readonly configStub: ConfigStub) {
  }

  /**
   * Imports the standard config from the staging area.
   * @param studyInput The study input.
   * @returns The config reference.
   */
  public async execute(studyInput: StudyInput): Promise<ConfigReference | undefined> {
    const userData = this.authenticationService.userDataSnapshot;

    // Save a copy of the config, so we can get the config reference.
    const configId = await this.loadingDialog.showUntilFinished(
      this.configStub.postConfig(
        userData.tenant,
        {
          name: studyInput.name,
          configType: studyInput.configType,
          config: studyInput.data,
          properties: fromReadonly(studyInput.properties),
          notes: studyInput.notes,
          simVersion: studyInput.simVersion
        }),
      `Saving "${studyInput.name}"...`);

    // Return the config reference.
    return {
      tenant: {
        tenantId: userData.tenant,
        targetId: configId,
      }
    };
  }
}

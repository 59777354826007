import {Component, OnInit} from '@angular/core';
import {ConfigLoaderDialogData, ConfigLoaderResult} from './config-loader-dialog.service';
import {DialogManager, DialogBase} from '../../../common/dialogs/dialog-manager.service';
import {ConfigLoaderDialogSession, ConfigLoaderDialogSessionFactory} from './config-loader-dialog-session';
import {Subscription} from 'rxjs';

@Component({
  selector: 'cs-config-loader-dialog',
  templateUrl: './config-loader-dialog.component.html',
  styleUrls: ['./config-loader-dialog.component.scss']
})
export class ConfigLoaderDialogComponent implements OnInit {
  public isVisible: boolean = false;

  public dialog: DialogBase<ConfigLoaderResult>;
  public session: ConfigLoaderDialogSession;
  public sessionSubscription: Subscription;

  constructor(
    private sessionFactory: ConfigLoaderDialogSessionFactory,
    private dialogManager: DialogManager) {
  }

  public ngOnInit(): any {
    this.dialogManager.secondaryChanged.subscribe(() => this.dialogManagerChanged());
  }

  public accept(result: ConfigLoaderResult) {
    if(this.dialog) {
      this.dialog.resolve(result);
    }
  }

  public acceptWithNothing() {
    if(this.dialog) {
      this.dialog.resolve({
        userId: undefined,
        configId: undefined,
        config: undefined
      });
    }
  }

  public dismiss() {
    if(this.dialog){
      this.dialog.dismiss();
    }
  }

  public dialogManagerChanged() {
    this.dismiss();

    let currentDialog = this.dialogManager.getSecondary();

    if(this.sessionSubscription){
      this.sessionSubscription.unsubscribe();
      this.sessionSubscription = undefined;
    }

    if(currentDialog && currentDialog instanceof ConfigLoaderDialogData){
      this.isVisible = true;
      this.dialog = <ConfigLoaderDialogData>currentDialog;
      this.session = this.sessionFactory.create(<ConfigLoaderDialogData>currentDialog);
      this.sessionSubscription = this.session.configSelected.subscribe((data: ConfigLoaderResult) => this.accept(data));
      this.session.load();
    } else {
      this.isVisible = false;
      this.session = undefined;
      this.dialog = undefined;
    }
  }
}


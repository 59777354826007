import { Component, OnInit } from '@angular/core';
import {DialogManager} from '../../../common/dialogs/dialog-manager.service';
import {ListVersionsDialogData, ListVersionsResult} from './list-versions-dialog.service';
import {ListVersionsDialogSessionFactory} from './list-versions-dialog-session';
import {DialogComponentBase} from '../../../common/dialogs/dialog-component-base';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';

@Component({
  selector: 'cs-list-versions-dialog',
  templateUrl: './list-versions-dialog.component.html',
  styleUrls: ['./list-versions-dialog.component.scss']
})
export class ListVersionsDialogComponent extends DialogComponentBase<ListVersionsDialogData, ListVersionsResult> implements OnInit {
  constructor(
    sessionFactory: ListVersionsDialogSessionFactory,
    dialogManager: DialogManager,
    getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
    super(sessionFactory, dialogManager, dialogManager.primaryChanged, getFriendlyErrorAndLog);
  }

  protected getCurrentDialog(): ListVersionsDialogData {
    let currentDialog = this.dialogManager.getPrimary();
    if (currentDialog && currentDialog instanceof ListVersionsDialogData) {
      return currentDialog;
    }
    return undefined;
  }
}



import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { EditKeyPermissionsConfig } from './edit-key-permission.config';
import { EditKeyPermissionModel } from './edit-key-permission.model';
import { InferableRouteParams } from '../../../common/inferable-route-params.service';
import { KeyPermissionsService } from '../services/key-permissions.service';

@Component({
  selector: 'cs-edit-key-permission',
  templateUrl: './edit-key-permission.component.html',
})
export class EditKeyPermissionComponent implements OnInit, OnDestroy {

  @Input() public tenantId: string;

  public model: EditKeyPermissionModel;

  /**
   * Dispatches the PutKeyPermission action to the store
   */
  public submit: () => Promise<void> = async () => {
    await this._keyPermissionsService.putKeyPermission(
      this.tenantId,
      this.model.encryptingTenantShortName.value,
      this.model.minimumSimVersion.value,
      this.model.description.value
    ).toPromise();
  };

  public get isEdit(): boolean {
    return !!(this.model.encryptingTenantShortName.value);
  }

  public get routeParams(): any[] {
    return ['/key-permissions', this.tenantId];
  }

  constructor(
    formBuilder: UntypedFormBuilder,
    inferableRouteParams: InferableRouteParams,
    route: ActivatedRoute,
    public formConfig: EditKeyPermissionsConfig,
    private _router: Router,
    private _keyPermissionsService: KeyPermissionsService) {
      this.tenantId = inferableRouteParams.getTenantId(route);
      this.model = new EditKeyPermissionModel(this.formConfig, formBuilder);
  }

  /**
   * Handles the OnInit lifecycle event
   */
  public ngOnInit(): void {
    const selectedPermission = this._keyPermissionsService.selectedPermissionSnapshot;
    if (selectedPermission) {
      this.model.patchValue(selectedPermission);
    }
  }

  /**
   * Handles the OnDestroy lifecycle event
   */
  public ngOnDestroy(): void {
    this._keyPermissionsService.clearKeyPermission();
  }

  /**
   * Navigates back to the list view
   */
  public async onSubmitted(success: boolean): Promise<void> {
    if (success) {
      this._navigateToListView();
    }
  }

  /**
   * Navigates back to the list view
   */
  private _navigateToListView(): void {
    this._router.navigate(this.routeParams);
  }
}


/**
 * Check if a value is a number.
 * @param n The value to check.
 * @returns True if the value is a number, false otherwise.
 */
export function isNumber(n: any) {
  // http://stackoverflow.com/q/9716468/37725
  return !isNaN(parseFloat(n)) && isFinite(n);
}

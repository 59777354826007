<cs-error-message [message]="errorMessage"></cs-error-message>
@if (!isLoaded && !errorMessage) {
  <cs-loader></cs-loader>
}

@if (isLoaded) {
  <div class="comparing">
    <p>
      Comparing
      <span class="deleted">{{leftName}}<span class="full-name">{{leftFullName}}</span></span>
      to
      <span class="added">{{rightName}}<span class="full-name">{{rightFullName}}</span></span>.
      @if (configType) {
        <span class="key">
          <span class="output-value-key output-value"></span> Output Value
        </span>
      }
      <span class="help-text">
        <i class="fa fa-info-circle"></i>Click on nodes to toggle children.
      </span>
    </p>
    @if (leftDeleteRequested !== rightDeleteRequested) {
      <p>
        <strong>Delete Requested:</strong>
        <span class="deleted">{{leftDeleteRequested}}</span>
        <span class="added">{{rightDeleteRequested}}</span>
      </p>
    }
    @if (leftParentWorksheetId !== rightParentWorksheetId) {
      <p>
        <strong>Worksheet ID:</strong>
        @if (leftParentWorksheetId) {
          <span class="deleted">{{leftParentWorksheetId}}</span>
        }
        @if (rightParentWorksheetId) {
          <span class="added">{{rightParentWorksheetId}}</span>
        }
      </p>
    }
    @if (delta) {
      <div class="object-delta-property">
        <span class="property-name" (click)="child.toggleChildren()">{{configType || 'root'}}:</span>
      </div>
      <cs-compare-config-structures-renderer
        #child
        class="renderer"
        [delta]="delta"
        [getUnits]="this"
      [outputChangeSet]="outputChangeSet"></cs-compare-config-structures-renderer>
    }
    @if (!delta) {
      <p class="last-item no-changes-detected">No config changes detected.</p>
    }
  </div>
}

import * as d3 from '../../d3-bundle';
import { getFullyQualifiedChannelName } from '../../get-fully-qualified-channel-name';
import { SimType } from '../../sim-type';
import { GetLegacyChannelMappings } from './get-legacy-channel-mappings';

export class ParseChannelDataCsv {

  constructor(
    private simType: SimType,
    private getLegacyChannelMappings: GetLegacyChannelMappings) {
  }

  public execute(fileContent: string): ParsedChannelDataCsvResult {
    // here we slightly dangerously assume that any unrecognized character
    // is the degree symbol, and replace it with 'deg'.
    fileContent = fileContent.replace(/\ufffd/g, 'deg');

    // first line is metadata, bin that.
    let firstEOL = fileContent.indexOf('\n');

    let parsedTelemetry: d3.CsvParseResult = d3.csvParse(fileContent.substring(firstEOL + 1));

    // first element is units, rest are data values.
    let parsedUnits = parsedTelemetry[0];
    let parsedValues = parsedTelemetry.slice(1, parsedTelemetry.length);

    let channelList: CsvChannelData[] = [];
    for (let channelName of parsedTelemetry.columns) {
      let trimmedChannelName = this.trim(channelName);

      let channel = {
        name: trimmedChannelName,
        simType: this.simType,
        units: this.trim(parsedUnits[channelName]),
        values: parsedValues.map((row) => parseFloat(this.trim(row[channelName])))
      };

      channelList.push(channel);
    }

    let channelsByName = this.getChannelMap(channelList);

    this.addLapTimeIfRequired(channelsByName, channelList);
    this.addRunChannelsIfRequired(channelsByName, channelList);

    return new ParsedChannelDataCsvResult(channelList, channelsByName);
  }

  private addLapTimeIfRequired(channelsByName: CsvChannelDataMap, channelList: CsvChannelData[]) {
    let secondsIntoExport = channelsByName['SecondsIntoExport'];
    if (secondsIntoExport) {
      secondsIntoExport.units = 's';
      let tLapChannel = {
        name: 'tLap',
        simType: this.simType,
        units: 's',
        values: [...secondsIntoExport.values]
      };

      channelList.push(tLapChannel);
      this.addChannelToMap(channelsByName, tLapChannel);
    }
  }

  private addRunChannelsIfRequired(channelsByName: CsvChannelDataMap, channelList: CsvChannelData[]) {
    let legacyChannelMappings = this.getLegacyChannelMappings.execute();
    for (let mapping of legacyChannelMappings) {
      this.addRunChannelIfRequired(channelsByName, channelList, mapping.currentName, mapping.legacyName);
    }
  }

  private addRunChannelIfRequired(channelsByName: CsvChannelDataMap, channelList: CsvChannelData[], desiredChannelName: string, sourceChannelName: string) {
    let desiredChannel = channelsByName[desiredChannelName];
    if (desiredChannel) {
      return;
    }

    let sourceChannel = channelsByName[sourceChannelName];
    if (!sourceChannel) {
      return;
    }

    desiredChannel = {
      name: desiredChannelName,
      simType: this.simType,
      units: sourceChannel.units,
      values: [...sourceChannel.values]
    };

    channelList.push(desiredChannel);
    this.addChannelToMap(channelsByName, desiredChannel);
  }

  private getChannelMap(list: ReadonlyArray<CsvChannelData>) {
    return list.reduce((previous: CsvChannelDataMap, current: CsvChannelData) => {
      this.addChannelToMap(previous, current);
      return previous;
    },
      {} as CsvChannelDataMap);
  }

  private addChannelToMap(channelMap: CsvChannelDataMap, newChannel: CsvChannelData) {
    channelMap[newChannel.name] = newChannel;
    channelMap[getFullyQualifiedChannelName(newChannel.name, this.simType)] = newChannel;
  }

  private trim(input: string | undefined) {
    input = (input || '').trim();

    if (input.length >= 2) {
      let lastIndex = input.length - 1;
      if ((input[0] === '\'' && input[lastIndex] === '\'')
        || (input[0] === '"' && input[lastIndex] === '"')) {
        input = input.substr(1, input.length - 2);
      }
    }

    return input;
  }
}

export class ParsedChannelDataCsvResult {
  constructor(
    public list: CsvChannelData[],
    public map: CsvChannelDataMap) {
  }
}

export interface CsvChannelDataMap {
  [channelName: string]: CsvChannelData;
}

export interface CsvChannelData {
  name: string;
  simType: string;
  units: string;
  values: number[];
}

import { Injectable } from '@angular/core';
import { JsonEditorCustomization } from './json-editor-customization';
import{ JSONEditor } from'@json-editor/json-editor'

@Injectable()
export class SplitIntegersEditor extends JsonEditorCustomization {
  public apply(): void {
    JSONEditor.defaults.editors.splitIntegers = class extends JSONEditor.defaults.editors.splitNumbers {
      getValue() {
        return super.getValue().map(function(v: number) {
 return Math.trunc(v);
});
      }
    }
  }
}

import * as d3 from '../../../d3-bundle';
import { IPosition } from '../../position';
import { QuadTreeCursorDataPoint } from './quad-tree-cursor-data-point';
import { ProcessedPlotSourceChannel } from './processed-plot-source-channel';

/**
 * A data source for a processed plot.
 */

export class ProcessedPlotSource {
  /**
   * Creates a new data source for a processed plot.
   * @param channels The plot channels for the source.
   */
  constructor(public readonly channels: ProcessedPlotSourceChannel[]) {
  }

  /**
   * Called when the plot is rescaled. Re-calculates X and Y coordinates for all channels
   * and resets the quad trees.
   * @param xScale The new X scale.
   * @param yScale The new Y scale.
   */
  public onRescaled(xScale: d3.ScaleLinear<number, number>, yScale: d3.ScaleLinear<number, number>) {
    this.channels.forEach(v => v.onRescaled(xScale, yScale));
  }

  /**
   * Gets the closest point to a given position in the source data, searching all channels in the plot.
   * @param internalPosition The internal position to find the closest point to.
   * @returns The closest point in the source data, or undefined if no point was found.
   */
  public getClosestPoint(internalPosition: IPosition): QuadTreeCursorDataPoint | undefined {
    let closestDistance = Infinity;
    let closestPoint: QuadTreeCursorDataPoint | undefined;
    for (let channel of this.channels) {
      let point = channel.getClosestPoint(internalPosition);
      if (point) {
        let x = internalPosition.x - point.x;
        let y = internalPosition.y - point.y;
        let distance = (x * x) + (y * y);
        if (distance < closestDistance) {
          closestDistance = distance;
          closestPoint = point;
        }
      }
    }

    return closestPoint;
  }
}

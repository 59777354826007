import { IViewerChannelData } from '../../channel-data-loaders/viewer-channel-data';
import { IPopulatedMultiPlotChannel } from './i-populated-multi-plot-channel';
import { ProcessedMultiPlotSide } from './processed-multi-plot-side';
import { SideType } from './side-type';
import { ProcessedMultiPlotChannel } from './processed-multi-plot-channel';

/**
 * A factory for creating processed data instances.
 */

export class ProcessedDataFactory {

  /**
   * Creates a new processed multi plot channel.
   * @param unprocessed The unprocessed channel.
   * @param name The unique name of the channel (for some sources this may include additional
   *             disambiguating information, e.g. hRideF100:StraightSim vs hRideF100:ApexSim in a study).
   * @param genericName The generic name of the channel, which may not be unique (e.g. hRideF100 in a study).
   * @param channelIndex The index of the channel (used for coloring the channel).
   * @param sources The data sources for the channel.
   * @param units The units of the channel.
   * @param isUserSpecifiedUnits Whether the units were specified by the user.
   * @param renderOnlyForDomain If specified, the channel will only be rendered for this domain.
   * @returns The processed channel.
   */
  public createChannel(
    unprocessed: IPopulatedMultiPlotChannel,
    name: string,
    genericName: string,
    channelIndex: number,
    sources: ReadonlyArray<IViewerChannelData>,
    units: string,
    isUserSpecifiedUnits: boolean,
    renderOnlyForDomain: string | undefined = undefined): ProcessedMultiPlotChannel {

    return new ProcessedMultiPlotChannel(unprocessed, name, genericName, channelIndex, sources, units, isUserSpecifiedUnits, renderOnlyForDomain);
  }

  /**
   * Creates a new processed multi plot side.
   * @param channels The channels for the side.
   * @param sideType The type of the side (row or column).
   * @returns The processed side.
   */
  public createSide(
    channels: ReadonlyArray<ProcessedMultiPlotChannel>,
    sideType: SideType): ProcessedMultiPlotSide {

    return new ProcessedMultiPlotSide(channels, sideType);
  }
}

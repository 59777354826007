import { WebGLRenderer } from 'three';

/**
 * A pool of WebGL renderers. We may have more than one renderer on the page at the same
 * time if we're displaying multiple visualizations (even if they are in hidden HTML pages).
 */
export class RendererPoolImplementation {

  /**
   * The pool of renderers.
   */
  private pool: WebGLRenderer[] = [];

  /**
   * Gets a renderer from the pool or creates a new one.
   * @returns The renderer.
   */
  public get(): WebGLRenderer {
    let result = this.pool.pop();
    return result || new WebGLRenderer({ alpha: true, antialias: true, logarithmicDepthBuffer: false });
  }

  /**
   * Releases a renderer back to the pool.
   * @param renderer The renderer to release.
   */
  public release(renderer: WebGLRenderer) {
    if (renderer) {
      this.pool.push(renderer);
    }
  }
}

/**
 * The singleton instance of the renderer pool.
 */
export const RendererPool = new RendererPoolImplementation();


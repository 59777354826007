import { Selector } from '@ngxs/store';
import { EncryptionConfigPermission } from '../../../../generated/api-stubs';
import { ConfigPermissionsState, ConfigPermissionsStateModel } from './config-permissions.state';

export class ConfigPermissionsSelectors {
  @Selector([ConfigPermissionsState])
  public static selectedPermission(state: ConfigPermissionsStateModel): EncryptionConfigPermission {
    return state.selectedPermission;
  }

  @Selector([ConfigPermissionsState])
  public static permissions(state: ConfigPermissionsStateModel): EncryptionConfigPermission[] {
    return state.permissions || [];
  }
}

@if (isAdministrator) {
  <button type="button"
    class="btn btn-smart btn-secondary linting-engine-dialog-launcher"
    [ngClass]="{'btn-icon': compactButton}"
    (click)="launch()"
    >
    <i class="fa fa-bug" aria-hidden="true"></i>
    @if (!compactButton) {
      <span>{{studyId ? 'Job ' : 'Config '}}Analysis</span>
    }
  </button>
}

@if (hasBeenActivated) {
  <div>
    @if (!jobsWithErrors && !errorMessage) {
      <cs-loader id="job-errors-loader"></cs-loader>
    }
    @if (jobsWithErrors) {
      <cs-job-selector
        [jobs]="jobsWithErrors"
        [showPostProcessor]="true"
        [jobSelectorState]="jobSelectorState"
      (changed)="selectJob($event)"></cs-job-selector>
    }
    <div class="container-fluid">
      @if (jobsWithErrors && jobsWithErrors.length === 0) {
        <div class="alert alert-info">
          <p>No errors found.</p>
        </div>
      }
      <cs-error-message [message]="errorMessage"></cs-error-message>
      @if (job) {
        <div>
          <h3>Job</h3>
          <div class="alert alert-danger job-failed-error">
            <p>Job failed.</p>
          </div>
          @if (job.jobMetadataResult.value?.studyJob.data.errorMessages?.length) {
            <div>
              <div class="alert alert-danger">
                @for (message of job.jobMetadataResult.value.studyJob.data.errorMessages; track message) {
                  <p>
                    {{message}}
                  </p>
                }
              </div>
            </div>
          }
          @if (job.studyMetadataResult.value?.study.data.errorMessages?.length) {
            <div>
              <h3>Study</h3>
              <div class="alert alert-danger">
                @for (message of job.studyMetadataResult.value.study.data.errorMessages; track message) {
                  <p>
                    {{message}}
                  </p>
                }
              </div>
            </div>
          }
          @if (job.diagnosis.value?.length) {
            <div>
              <h3>Diagnosis</h3>
              <div class="alert alert-warning">
                @for (message of job.diagnosis.value; track message) {
                  <p>
                    {{message}}
                  </p>
                }
              </div>
            </div>
          }
        </div>
      }
    </div>
  </div>
}

@if (isVisible) {
  <div class="modal dialog-container" id="download-re-encrypted-config-dialog">
    <div class="modal-dialog modal-dialog-wide" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title">
            Download Re-Encrypted Config
          </h1>
          <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()">
          </button>
        </div>
        <div class="modal-body">
          <cs-error-message [message]="errorMessage || session.errorMessage"></cs-error-message>
          @if (!session) {
            <cs-loader></cs-loader>
          }
          @if (session) {
            @if (session.isLoading) {
              <cs-loader class="loader-centered"></cs-loader>
            }
            @if (session.queryResult) {
              @if (!session.hasResults) {
                <div class="alert alert-warning">
                  <p class="last-item">No encryption keys are available for this config.</p>
                </div>
              }
              @if (session.hasResults) {
                <h3>
                  Select from the following encryption keys:
                </h3>
                @for (key of session.queryResult.keys; track key) {
                  <p>
                    <button (click)="session.downloadConfigAs(key)" class="btn btn-secondary">
                      {{key.tenantName}} <span class="tenant-short-name">{{key.tenantShortName}}</span>
                    </button>
                  </p>
                }
              }
              <hr/>
              <div class="alert alert-info">
                <h3>
                  For an encryption key to appear in this list, all of the following must be satisfied:
                </h3>
                <ul class="re-encryption-rules-list">
                  <li>
                    The config must contain at least one encrypted component.
                  </li>
                  <li>
                    The encryption key owning tenant must give your tenant permission to use their keys.
                  </li>
                  <li>
                    For each encrypted component in the config, the encrypted component owning tenant must give your
                    tenant permission to use the encryption keys belonging to the encryption key owning tenant.
                  </li>
                </ul>
              </div>
              <div class="alert alert-info last-item">
                <h3>What are re-encrypted configs?</h3>
                <cs-encryption-help-links></cs-encryption-help-links>
                <p>
                  A re-encrypted config is a config where the encrypted components have been re-encrypted using an
                  encryption key associated with a specific tenant and sim version.
                </p>
                <p>
                  This feature allows cars containing encrypted components to be used in Driver-in-the-Loop (DiL)
                  simulators without the contents of the encrypted components being exposed to the DiL operator.
                </p>
              </div>
            }
          }
        </div>
        <div class="modal-footer">
          <button id="dialog-dismiss-button" type="button" class="btn btn-secondary" (click)="dismiss()">Cancel</button>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div>
  }<!-- /.modal -->

import {EventEmitter, Injectable} from '@angular/core';
import {WorksheetViewModel} from '../worksheet-view-model';
import {Timer} from '../../common/timer.service';

const MIN_HEIGHT = 50;

/**
 * Service for the worksheet dock area.
 */
@Injectable()
export class WorksheetDock {

  /**
   * Event that is emitted when the user requests to undock the worksheet.
   */
  public readonly undockRequested: EventEmitter<void> = new EventEmitter();

  /**
   * The current height of the dock.
   */
  private _height: number = 300;

  /**
   * The current worksheet that is docked.
   */
  private _worksheet: DockedWorksheet | undefined;

  /**
   * The worksheet view model that is being transferred too or from the worksheet dock.
   */
  private transferringViewModel: WorksheetViewModel | undefined;

  /**
   * Creates an instance of WorksheetDock.
   * @param timer The timer service.
   */
  constructor(private readonly timer: Timer) {
  }

  /**
   * When the user identity changes, clear the dock.
   */
  public onIdentityChanged(){
    this.setWorksheet(undefined);
  }

  /**
   * Gets the height of the dock.
   */
  public get height(): number {
    return this._worksheet ? this._height : 0;
  }

  /**
   * Sets the height of the dock.
   */
  public set height(value: number) {
    if(value < MIN_HEIGHT){
      this._height = MIN_HEIGHT;
    } else {
      this._height = value;
    }
  }

  /**
   * Gets the worksheet that is docked.
   */
  public get worksheet(): DockedWorksheet | undefined {
    return this._worksheet;
  }

  /**
   * Sets the worksheet that is docked.
   */
  public async setWorksheet(value: DockedWorksheet | undefined) {
    if(this._worksheet && value){
      this._worksheet = undefined;
      await this.timer.yield();
    }

    this._worksheet = value;
  }

  /**
   * Sets the worksheet view model that is being transferred too or from the worksheet dock.
   */
  public setTransferringViewModel(viewModel: WorksheetViewModel) {
    this.transferringViewModel = viewModel;
  }

  /**
   * Pops the worksheet view model that is being transferred too or from the worksheet dock.
   */
  public popTransferringViewModel(): WorksheetViewModel {
    const result = this.transferringViewModel;
    this.transferringViewModel = undefined;
    return result;
  }

  /**
   * Called when the user requests to undock the worksheet.
   */
  public requestUndock() {
    this.undockRequested.emit();
  }
}

/**
 * Represents a docked worksheet.
 */
export class DockedWorksheet {

  /**
   * Creates an instance of DockedWorksheet.
   * @param tenantId The tenant id.
   * @param worksheetId The worksheet id.
   */
  constructor(
    public readonly tenantId: string,
    public readonly worksheetId: string){
  }
}

import {Injectable} from '@angular/core';
import {
  GetStudyJobMetadataQueryResult,
  GetStudyQueryResult,
  NewStudyData,
  PostStudyResult,
  StudyStub
} from '../../../generated/api-stubs';
import {CanopyPusher, StudiesProgress} from '../../common/canopy-pusher.service';
import {WAITING_FOR_RESULT_MESSAGE} from '../configs/car-config-validation-session';
import {Subscription} from 'rxjs';
import {Timer} from '../../common/timer.service';
import {defer, DeferredPromise} from '../../common/promise-defer';
import {StudyMetadataCache} from '../../notifications/study-metadata-cache.service';
import { AuthenticationService, UserData } from '../../identity/state/authentication.service';

@Injectable()
export class ExecuteStudyAndWaitFactory {

  constructor(
    private readonly canopyPusher: CanopyPusher,
    private readonly studyStub: StudyStub,
    private readonly studyMetadataCache: StudyMetadataCache,
    private readonly timer: Timer,
    private readonly authenticationService: AuthenticationService
  ){
  }

  public create(): ExecuteStudyAndWait {
    return new ExecuteStudyAndWait(
      this.canopyPusher,
      this.studyStub,
      this.studyMetadataCache,
      this.timer,
      this.authenticationService);
  }
}

export class ExecuteStudyAndWait {
  private studyId: string;
  private studiesProgressSubscription: Subscription;
  private timerSubscription: Subscription;
  private isStudyCompleted: boolean = false;

  private _studyMetadataResult: GetStudyQueryResult;
  private _studyJobMetadataResult: GetStudyJobMetadataQueryResult;

  private userData: UserData;

  private _statusMessage: string;
  private _isStudySuccessful: boolean;
  private deferredPromise: DeferredPromise;

  constructor(
    private readonly canopyPusher: CanopyPusher,
    private readonly studyStub: StudyStub,
    private readonly studyMetadataCache: StudyMetadataCache,
    private readonly timer: Timer,
    authenticationService: AuthenticationService){
    this.userData = authenticationService.userDataSnapshot;
  }

  public get studyMetadataResult(): GetStudyQueryResult {
    return this._studyMetadataResult;
  }

  public get studyJobMetadataResult(): GetStudyJobMetadataQueryResult {
    return this._studyJobMetadataResult;
  }

  public get statusMessage(): string {
    return this._statusMessage;
  }

  public get isStudySuccessful(): boolean {
    return this._isStudySuccessful;
  }

  public dispose(){
    this.unsubscribe();
  }

  public unsubscribe(){
    if(this.studiesProgressSubscription){
      this.studiesProgressSubscription.unsubscribe();
      this.studiesProgressSubscription = undefined;
    }

    if(this.timerSubscription){
      this.timerSubscription.unsubscribe();
      this.timerSubscription = undefined;
    }
  }

  public async execute(newStudyData: NewStudyData): Promise<void>{
    this.deferredPromise = defer();
    try {
      this._isStudySuccessful = false;
      this.studiesProgressSubscription = this.canopyPusher.studiesProgress.subscribe((data: StudiesProgress) => this.onStudiesProgress(data));
      let timer = this.timer.repeat(15000);
      this.timerSubscription = timer.subscribe(() => this.onTimerTick());

      let postStudyResult = <PostStudyResult>await this.studyStub.postStudy(
        this.userData.tenant,
        newStudyData);

      this.studyMetadataCache.set(this.userData.tenant, postStudyResult.studyId, newStudyData.name);
      this.studyId = postStudyResult.studyId;

      this._statusMessage = WAITING_FOR_RESULT_MESSAGE;
    } catch(error){
      this.unsubscribe();
      this.deferredPromise.reject(error);
    }

    return this.deferredPromise.promise;
  }

  public async onStudiesProgress(data: StudiesProgress){
    try {
      if(this.isStudyCompleted){
        this.unsubscribe();
        return;
      }

      if(!this.studyId){
        return;
      }

      this._statusMessage = this._statusMessage + '.';

      let completedEventFound = !!data.items.find(item => item.studyId === this.studyId && item.completedJobCount > 0);
      if(completedEventFound){
        this.isStudyCompleted = true;
        this.unsubscribe();
        await this.loadResults();
      }
    } catch (error) {
      this.unsubscribe();
      this.deferredPromise.reject(error);
    }
  }

  public async onTimerTick() {
    try {
      if(this.isStudyCompleted){
        this.unsubscribe();
        return;
      }

      if(!this.studyId){
        return;
      }

      this._statusMessage = this._statusMessage + '.';

      let studyMetadata = await this.studyStub.getStudyMetadata(
        this.userData.tenant, this.studyId);

      let isCompleted = studyMetadata.study.data.completedJobCount > 0;
      if(isCompleted){
        this.isStudyCompleted = true;
        this.unsubscribe();
        await this.loadResults(studyMetadata);
      }
    } catch (error) {
      this.unsubscribe();
      this.deferredPromise.reject(error);
    }
  }

  public async loadResults(studyMetadataResult?: GetStudyQueryResult){

    this._statusMessage = 'Loading result...';

    let jobId = this.studyId + '-0';
    let studyJobMetadataResultTask = this.studyStub.getStudyJobMetadata(
      this.userData.tenant, this.studyId, jobId);

    if(studyMetadataResult){
      this._studyMetadataResult = studyMetadataResult;
    } else {
      this._studyMetadataResult = await this.studyStub.getStudyMetadata(
        this.userData.tenant, this.studyId);
    }

    this._studyJobMetadataResult = await studyJobMetadataResultTask;
    this._isStudySuccessful = !!this._studyMetadataResult.study.data.succeededJobCount;
    this._statusMessage = '';

    this.deferredPromise.resolve();
  }
}

<div id="view-study-page">
  <div class="container-fluid">

    @if (!isLoaded) {
      <div class="card">
        <h1 class="card-header">
          View Study
        </h1>
        <div class="card-body">
          <p>
            <a id="back-button" class="btn btn-smart btn-secondary" [routerLink]="isWorksheetPage ? ['../../../'] : ['../../']">
              <i class="fa fa-caret-left"></i>Back
            </a>
          </p>
          <cs-error-message [message]="errorMessage"></cs-error-message>
          <cs-loader></cs-loader>
        </div>
      </div>
    }
  </div>

  @if (isLoaded) {
    <div>
      <div class="container-fluid">
        <div class="card">
          <h1 class="card-header" id="view-study-header">
            {{study.name}}
            <small class="text-body-secondary"
              [ngClass]="{ 'success-header': study.isCompleted && !study.failedJobCount, 'failure-header': study.isCompleted && study.failedJobCount  }">
              {{study.isCompleted ? 'Completed' : (study.isBuilding ? (study.dispatchedJobCount ? 'Building' : 'Validating') : 'In Progress')}}
            </small>
            <div class="card-header-float-right">
              @if (showWorksheets) {
                <button class="btn btn-secondary btn-icon" title="Create Worksheet. Hold Ctrl or Shift to force copying the study." (click)="createWorksheet($event)"><i class="fa fa-th"></i></button>
              }
              <cs-support-session-launcher
                [tenantId]="tenantId" [documentId]="studyId"
                [documentType]="'study'" [documentSubType]="'definition'"
                [supportSession]="supportSession"
              (supportSessionChanged)="onSupportSessionChanged($event)"></cs-support-session-launcher>
            </div>
          </h1>
          <div class="card-body">
            <p>
              <a id="back-button" class="btn btn-smart btn-secondary" [routerLink]="isCurrentTenant ? (isWorksheetPage ? ['../../../'] : ['../../']) : ['/support-sessions']">
                <i class="fa fa-caret-left"></i>Back
              </a>
              @if (!study.isBuilding) {
                <a id="view-jobs-button" class="btn btn-smart btn-secondary" [routerLink]="['./jobs']">
                  <i class="fa fa-cube"></i>View Jobs
                </a>
              }
              <button id="stage-to-results-staging-area-button" class="btn btn-smart btn-secondary" [disabled]="!study.isCompleted" (click)="stageToResultsStagingArea()">
                <i class="fa fa-area-chart"></i>Compare
              </button>
              @if (significantSimVersions.isAfterTelemetrySimulation) {
                <button id="stage-to-study-staging-area-button" class="btn btn-smart btn-secondary" [disabled]="!study.isCompleted" (click)="stageToStudyStagingArea()">
                  <i class="fa fa-arrow-circle-up"></i><cs-stage-as-telemetry-text [studyType]="study.studyType"></cs-stage-as-telemetry-text>
                </button>
              }
              @if (isOwner) {
                <a id="edit-button" class="btn btn-smart btn-secondary"
                  [routerLink]="['./edit']">
                  <i class="fa fa-edit"></i>Edit
                </a>
              }
              <cs-download-buttons
                [tenantId]="tenantId"
                [studyId]="studyId"
                [studyName]="study.name"
                [jobCount]="study.jobCount"
                [isCompleted]="study.isCompleted"
                [simTypes]="study.studyResult.simTypes">
              </cs-download-buttons>
            </p>
            <cs-error-message [message]="errorMessage"></cs-error-message>
            <pre id="study-json" class="raw-json debug-information">{{studyJson}}</pre>
            @if (study.errorMessages?.length) {
              <div class="alert alert-danger">
                @for (message of study.errorMessages; track message) {
                  <p>
                    {{message}}
                  </p>
                }
              </div>
            }
            @if (!study.isCompleted && !study.errorMessages?.length) {
              <div class="alert alert-info">
                @if (study.isBuilding && study.dispatchedJobCount) {
                  <p>
                    Building {{study.dispatchedJobCount}} of {{study.jobCount}} jobs...
                  </p>
                }
                @if (study.isBuilding && !study.dispatchedJobCount) {
                  <p>
                    @if (study.jobCount) {
                      <span>
                        Building...
                      </span>
                    }
                    @if (!study.jobCount) {
                      <span>
                        Waiting...
                      </span>
                    }
                  </p>
                }
                @if (!study.isBuilding) {
                  <p>
                    {{runningText}}
                  </p>
                  <cs-set-priority
                    [tenantId]="tenantId"
                    [studyId]="studyId"
                    [(priority)]="priority">
                  </cs-set-priority>
                }
              </div>
            }
            <p>
              Created {{study.creationDate}} by <strong>{{study.username}}</strong>.
            </p>
            @if (study.jobCount) {
              <p>
                This study has <strong>{{study.jobCount}} {{study.jobCount === 1 ? 'job' : 'jobs'}}</strong>,
                of which <strong>{{study.succeededJobCount}}</strong> {{study.succeededJobCount === 1 ? 'has' : 'have'}} succeeded
                and <strong>{{study.failedJobCount}}</strong> {{study.failedJobCount === 1 ? 'has' : 'have'}} failed.
              </p>
            }
            @if (study.succeededComputeCredits || study.succeededStorageCredits) {
              <p>
                This study has used <strong>{{study.succeededComputeCredits}} {{study.succeededComputeCredits === 1 ? 'compute credit' : 'compute credits'}}</strong>
                and <strong>{{study.succeededStorageCredits}} {{study.succeededStorageCredits === 1 ? 'storage credit' : 'storage credits'}}</strong>.
              </p>
            }
            <p class="last-item">
              {{study.studyTypeName}} version {{study.studyResult.study.simVersion}}.
            </p>
            <cs-view-custom-properties [customProperties]="study.properties"></cs-view-custom-properties>
          </div>
        </div>
        @if (study.notes) {
          <div class="card">
            <h1 class="card-header">
              Study Notes
            </h1>
            <div class="card-body study-notes-content">
              <pre class="raw-json last-item">{{study.notes}}</pre>
            </div>
          </div>
        }
        @if (study.jobCount > 1) {
          <cs-view-study-sources [studyResult]="study.studyResult"></cs-view-study-sources>
        }
      </div>
      @if (study.showExplorationMapViewer) {
        <div>
          @if (isLoadingChart) {
            <cs-loader class="loader-centered" id="study-chart-loader"></cs-loader>
          }
          <div id="dashboard">
          </div>
        </div>
      }
      @if (study.jobCount === 1) {
        <div>
          <cs-view-job [hideBackButton]="true" [tenantId]="tenantId" [studyId]="studyId" [jobId]="studyId + '-0'"
          [studyResultTask]="studyResultTask"></cs-view-job>
        </div>
      }
    </div>
  }
</div>


import {EditorAutocompleteInformation} from './editor-autocomplete-information';
import {DocumentSubType, StudyStub} from '../../../../generated/api-stubs';
import {Injectable} from '@angular/core';
import {StudyStagingArea} from '../../study-staging-area/study-staging-area.service';
import {getJobIdFromJobIndex} from '../../../common/get-job-id-from-job-index';
import {CanopyFileLoaderFactory} from '../../visualizations/canopy-file-loader.service';
import {distinct} from '../../../common/distinct';
import {GetSimVersion} from '../../../common/get-sim-version.service';
import { AuthenticationService } from '../../../identity/state/authentication.service';

export const CHANNEL_NAME_SUFFIX = 'channelName';
export const USER_CHANNELS_SUFFIX = 'userChannels';

@Injectable()
export class GetChannelNameAutocompleteOptions{

  private channelNames: string[] = [];
  private userInformation: string[] = [];

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly studyStagingArea: StudyStagingArea,
    private readonly canopyFileLoaderFactory: CanopyFileLoaderFactory,
    private readonly studyStub: StudyStub,
    private readonly getSimVersion: GetSimVersion){
  }

  public async initialize(){
    this.userInformation = [];
    this.channelNames = [];

    const stagedTelemetry = this.studyStagingArea.getExactInput(DocumentSubType.telemetry);
    if(!stagedTelemetry){
      this.userInformation.push('Stage a study job as telemetry to enable channel autocomplete.');
      return;
    }

    const channelNames: string[] = [];
    if (stagedTelemetry.data.channels && stagedTelemetry.data.channels.length){
      for(let channel of stagedTelemetry.data.channels){
        if(channel.name){
          channelNames.push(channel.name);
        }
      }
    }

    if(stagedTelemetry.data.source){
      try {
        let userData = this.authenticationService.userDataSnapshot;
        let reference = stagedTelemetry.data.source;
        let tenantId = reference.tenantId || userData.tenant;
        let studyId = reference.studyId;
        let jobIndex = reference.jobIndex || 0;
        let jobId = getJobIdFromJobIndex(studyId, jobIndex);
        let studyMetadata = await this.studyStub.getStudyMetadata(tenantId, studyId);
        let simVersion = this.getSimVersion.currentSimVersion;
        let job = await this.studyStub.getStudyJob(tenantId, studyId, jobId, simVersion);

        let fileLoader = this.canopyFileLoaderFactory.create();
        fileLoader.addStudy(tenantId, studyId, studyMetadata.accessInformation, simVersion);
        for(let simType of job.simTypes){
          let vectorMetadata = await fileLoader.loadVectorMetadata(studyId, jobIndex, simType);
          for(let item of vectorMetadata){
            channelNames.push(item.name);
          }
        }
      } catch(error){
        console.error('Failed to load channel name autocomplete from telemetry.');
        console.error(error);
      }
    }

    this.channelNames = distinct(channelNames);
  }

  public async execute(multiSelect: boolean): Promise<EditorAutocompleteInformation> {
    await this.initialize();
    return this.executeSynchronous(multiSelect);
  }

  public executeSynchronous(multiSelect: boolean): EditorAutocompleteInformation {

    let defaultSortFunction = function(a: string, b: string) {
      if (a === b) {
        return 0;
      }
      return a < b ? -1 : 1;
    };

    return new EditorAutocompleteInformation(
      this.channelNames,
      this.userInformation,
      {
        list: this.channelNames,
        minChars: 0,
        maxItems: 100,
        autoFirst: true,
        filter: (text: string, input: string) => {
          if(multiSelect){
            input = input.match(/[^,]*$/)[0].trim();
          }

          return text.indexOf(input) === 0 && (text as any).label !== input;
        },
        sort(a: string, b: string) {
          return defaultSortFunction(a.toLowerCase(), b.toLowerCase());
        },
        replace(text: string) {
          if(multiSelect){
            let before = this.input.value.match(/^.+,\s*|/)[0];
            this.input.value = before + text + ', ';
          } else{
            this.input.value = text;
          }
        }
      });
  }
}

<div class="container-fluid" id="view-key-permissions-page">
  <div>
    <div class="list-card">
      <h1 class="list-card-header">
        Key Permissions
      </h1>
      <div class="list-card-body">
        <div class="list-card-padded">
          <cs-form-error-message></cs-form-error-message>
          <p>
            <a id="tenant-button" class="btn btn-smart btn-secondary" [routerLink]="['/tenants', tenantId]">
              <i class="fa fa-caret-left"></i>Back
            </a>
            @if (showToolbar) {
              <a id="new-key-permission-button" class="btn btn-smart btn-secondary" [routerLink]="['create']">
                <i class="fa fa-file-o"></i>New Key Permission
              </a>
            }
          </p>
        </div>
        <div class="view-key-permissions-component">
          @if (isLoading) {
            <p>
              <cs-loader class="loader-centered"></cs-loader>
            </p>
          }
          @if (!isLoading) {
            <div>
              <div class="filtered-list">
                <table id="key-permissions-table" class="table key-permissions-table last-item">
                  <thead>
                    <tr>
                      <th>Encrypting Tenant Short Name</th>
                      <th>Minimum Sim Version</th>
                      <th>Description</th>
                      <th class="icon-cell"></th>
                    </tr>
                  </thead>
                  <tbody>
                    @for (keyPermission of keyPermissions; track trackById(index, keyPermission); let index = $index) {
                      <tr>
                        <td>
                          <span>{{keyPermission.encryptingTenantShortName}}</span>
                        </td>
                        <td>
                          <span>{{keyPermission.minimumSimVersion}}</span>
                        </td>
                        <td>
                          <span>{{keyPermission.description}}</span>
                        </td>
                        <td>
                          @if (showToolbar) {
                            <div class="btn-group" role="group">
                              <button id="edit-key-permissions-button" (click)="onEdit(keyPermission)" class="btn btn-secondary btn-icon btn-xsm">
                                <i class="fa fa-edit"></i>
                              </button>
                              <button id="delete-key-permissions-button" (click)="onDelete(keyPermission)" class="btn btn-secondary btn-icon btn-xsm">
                                @if (!isDeleting) {
                                  <i class="fa fa-trash-o"></i>
                                }
                                @if (isDeleting) {
                                  <i class="fa fa-spinner fa-spin"></i>
                                }
                              </button>
                            </div>
                          }
                        </td>
                      </tr>
                    }
                    @if (!keyPermissions.length) {
                      <tr>
                        <td rowspan="3">
                          There are no items to display.
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  </div>
</div>

import {ZERO_WIDTH_SPACE, ZERO_WIDTH_SPACE_REGEX} from './constants';

export function sanitizeClipboardText(event: ClipboardEvent){
  let text = (event.target as Node).textContent;
  if(text && text.indexOf(ZERO_WIDTH_SPACE) !== -1){
    text = text.replace(ZERO_WIDTH_SPACE_REGEX, '').trim();
    event.preventDefault();
    event.clipboardData.setData('Text', text);
    console.log('Sanitized clipboard');
  }
}

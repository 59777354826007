import * as d3 from '../../d3-bundle';
import { DesignName, ExplorationMap } from './exploration-map';
import { ChannelScalarDataAndMappings } from './channel-scalar-data-and-mappings';
import { OrderedUniqueIndices } from '../../ordered-unique-indices';
import { Utilities } from '../../utilities';

/**
 * Removes jobs with NaN values in all channels for any sim type, or jobs with lap times outside a sensible threshold.
 */
export class RemoveBadJobsFromStudy {

  /**
   * Removes jobs with NaN values in all channels for any sim type, or jobs with lap times outside a sensible threshold.
   * @param scalarData The scalar data.
   * @param map The exploration map.
   */
  public execute(scalarData: ChannelScalarDataAndMappings, map: ExplorationMap) {

    // Get the sim types found in the scalar results.
    let simTypes = scalarData.getSimTypes();

    // For each sim type, get the channel names.
    let simTypeChannelNames: { [simType: string]: ReadonlyArray<string> } = {};
    for (let simType of simTypes) {
      simTypeChannelNames[simType] = scalarData.getChannelNamesForSimType(simType);
    }

    // Remove jobs with NaN values in all channels for any sim type.
    let badIndices = new OrderedUniqueIndices();
    let jobCount = map.rCoordinates.length;
    for (let jobIndex = 0; jobIndex < jobCount; jobIndex++) {
      for (let simType of simTypes) {
        let allChannelsNaN = true;
        for (let channelName of simTypeChannelNames[simType]) {
          const channelScalarData = scalarData.getScalarData(channelName);
          if (channelScalarData) {
            let chanValues = channelScalarData.values;
            if (!isNaN(chanValues[jobIndex])) {
              allChannelsNaN = false;
            }
          }
        }

        if (allChannelsNaN) {
          badIndices.add(jobIndex);
          break;
        }
      }
    }

    // Remove jobs with lap times outside a sensible threshold. This stops laps with bad convergence from
    // polluting the visualization.
    let tLapTotalData = scalarData.getScalarData('tLapTotal');
    if (tLapTotalData && (map.designName === DesignName.monteCarlo || map.designName === DesignName.factorialMonteCarlo)) {
      let tLapValues = tLapTotalData.values.slice().sort((a, b) => a - b);
      let NLaps2pc = Math.floor(tLapValues.length / 50); // cut off the top and bottom 2% of laps when making stats for thresholds.
      let tLapStdDev = Utilities.standardDeviation(tLapValues.slice(NLaps2pc, -NLaps2pc));
      let tLapMean = d3.meanStrict(tLapValues.slice(NLaps2pc, -NLaps2pc));
      let tLapLowerThreshold = tLapMean - 2 * tLapStdDev;
      let tLapUpperThreshold = tLapMean + 2.5 * tLapStdDev;

      for (let jobIndex = 0; jobIndex < tLapValues.length; jobIndex++) {
        let tLapTotalValue = tLapTotalData.values[jobIndex];
        if (tLapTotalValue > tLapUpperThreshold) {
          badIndices.add(jobIndex);
        } else if (tLapTotalValue < tLapLowerThreshold) {
          badIndices.add(jobIndex);
        }
      }
    }

    // Remove the bad indices from the map and scalar data.
    this.stripFromMapAndChannels(badIndices, map, scalarData);
  }

  /**
   * Removes the bad indices from the map and scalar data.
   * @param badIndicesBuilder The list of bad indices.
   * @param map The exploration map.
   * @param channelScalarDataAndMappings The scalar data.
   */
  private stripFromMapAndChannels(badIndicesBuilder: OrderedUniqueIndices, map: ExplorationMap, channelScalarDataAndMappings: ChannelScalarDataAndMappings) {
    Utilities.strip(
      badIndicesBuilder,
      map.coordinates,
      map.jobNames,
      map.rCoordinates,
      map.indexMatrix,
      channelScalarDataAndMappings.lineIndexToJobIndexMap,
      ...channelScalarDataAndMappings.getChannelNames().map(v => channelScalarDataAndMappings.data[v].values));
  }
}

import {Component, OnInit, ViewChild} from '@angular/core';
import {
  CompareConfigDialogData,
  CompareConfigDialogDataDeprecated,
  CompareConfigDialogResult, ISaveOutputConfigHandler
} from './compare-config-dialog.service';
import {DialogBase, DialogManager} from '../../../../common/dialogs/dialog-manager.service';
import {
  CompareConfigsComponent,
} from '../compare-configs/compare-configs.component';
import {GetFriendlyErrorAndLog} from '../../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {StudyInput} from '../../../../worksheets/study-input';
import {ConfigOrConfigLoader} from '../config-or-config-loader';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'cs-compare-config-dialog',
  templateUrl: './compare-config-dialog.component.html',
  styleUrls: ['./compare-config-dialog.component.scss']
})
export class CompareConfigDialogComponent implements OnInit {

  public dialog: DialogBase<CompareConfigDialogResult>;

  public configs: ReadonlyArray<ConfigOrConfigLoader>;
  public saveOutputConfigHandler: ISaveOutputConfigHandler | undefined;

  @ViewChild('compareConfigsComponent') compareConfigsComponent: CompareConfigsComponent;

  constructor(
    private readonly dialogManager: DialogManager,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog,
    private readonly toastrService: ToastrService) {
  }

  public ngOnInit(): any {
    this.dialogManager.secondaryChanged.subscribe(() => this.dialogManagerChanged());
  }

  public get canSave(): boolean {
    if(this.compareConfigsComponent){
      return this.compareConfigsComponent.canSave;
    }

    return false;
  }

  public async save(){
    if(this.compareConfigsComponent){
      try {
        await this.compareConfigsComponent.save();
        this.dismiss();
      } catch(error){
          this.toastrService.error(this.getFriendlyErrorAndLog.execute(error));
      }
    }
  }

  public async saveAs(){
    if(this.compareConfigsComponent){
      try {
        await this.compareConfigsComponent.saveAs();
      } catch(error){
        this.toastrService.error(this.getFriendlyErrorAndLog.execute(error));
      }
    }
  }

  public async stage(){
    if(this.compareConfigsComponent){
      try {
        await this.compareConfigsComponent.stage();
        this.dismiss();
      } catch(error){
        this.toastrService.error(this.getFriendlyErrorAndLog.execute(error));
      }
    }
  }

  public dismiss() {
    if(this.dialog){
      this.dialog.dismiss();
    }
  }

  public dialogManagerChanged() {
    this.dismiss();

    let currentDialog = this.dialogManager.getSecondary();

    if(currentDialog && currentDialog instanceof CompareConfigDialogDataDeprecated){
      this.dialog = <CompareConfigDialogDataDeprecated>currentDialog;
      this.configs = [
        new ConfigOrConfigLoader(currentDialog.secondTitle, this.createBasicStudyInput(currentDialog.secondTitle, currentDialog.second), undefined),
        new ConfigOrConfigLoader(currentDialog.firstTitle, this.createBasicStudyInput(currentDialog.firstTitle, currentDialog.first), undefined),
      ];
    } else if(currentDialog && currentDialog instanceof CompareConfigDialogData){
      this.dialog = <CompareConfigDialogData>currentDialog;
      this.saveOutputConfigHandler = currentDialog.saveOutputConfigHandler;
      this.configs = currentDialog.configs;
    } else {
      this.dialog = undefined;
    }
  }

  private createBasicStudyInput(name: string, data: any): StudyInput {
    return new StudyInput(
      undefined,
      undefined,
      undefined,
      name,
      data,
      [],
      undefined,
      undefined,
      false,
      undefined);
  }
}

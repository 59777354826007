import {Component, Input, OnInit} from '@angular/core';
import {MembershipStub} from '../../generated/api-stubs';
import {GetFriendlyErrorAndLog} from '../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {getRoleIcon} from './get-role-icon';
import {DELETED_USER_ROLE, TENANT_ADMINISTRATOR_ROLE} from './user-identity';
import {ConfirmationDialog} from '../common/dialogs/confirmation-dialog.service';
import { AuthenticationService, UserData } from './state/authentication.service';

@Component({
  selector: 'cs-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrls: ['./user-roles.component.scss']
})
export class UserRolesComponent implements OnInit {
  @Input() public tenantId: string;
  @Input() public userId: string;

  public errorMessage: string;

  public userData: UserData;
  public roles: ReadonlyArray<string>;
  public canChangeRoles: boolean = false;

  public isUpdating: boolean;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly membershipStub: MembershipStub,
    private readonly confirmationDialog: ConfirmationDialog,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  public ngOnInit(): void {
    this.canChangeRoles = this.authenticationService.isAdministrator || this.authenticationService.isTenantAdministrator;
    this.userData = this.authenticationService.userDataSnapshot;
    this.load();
  }

  public async load(): Promise<void> {
    try{
      this.errorMessage = undefined;
      this.roles = undefined;
      let rolesResult = await this.membershipStub.getUserRoles(this.tenantId, this.userId);
      this.roles = rolesResult.roles;
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async setRole(role: string, isEnabled: boolean){
    try {
      this.errorMessage = undefined;
      this.isUpdating = true;

      await this.membershipStub.putUserRole(this.tenantId, this.userId, {
        role,
        isEnabled
      });

      await this.load();
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    } finally{
      this.isUpdating = false;
    }
  }

  public setAsTenantAdministrator(){
    this.setRole(TENANT_ADMINISTRATOR_ROLE, true);
  }

  public removeAsTenantAdministrator(){
    this.setRole(TENANT_ADMINISTRATOR_ROLE, false);
  }

  public async setAsDeletedUser(){
    try {
      let isConfirmed = await this.confirmationDialog.show(
      `Are you sure you want to delete this user and all their data? This action cannot be reversed.`,
      'Confirm Delete User and User Data',
      'Permanently Delete User and ALL User Data');

      if(isConfirmed){
        this.setRole(DELETED_USER_ROLE, true);
      }
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public removeAsDeletedUser(){
    this.setRole(DELETED_USER_ROLE, false);
  }

  public get isSelf(): boolean {
    return this.userData.sub === this.userId;
  }

  public get isTenantAdministrator(): boolean {
    return this.roles && this.roles.indexOf(TENANT_ADMINISTRATOR_ROLE) !== -1;
  }

  public get isDeletedUser(): boolean {
    return this.roles && this.roles.indexOf(DELETED_USER_ROLE) !== -1;
  }

  public getRoleIcon(role: string): string {
    return getRoleIcon(role);
  }
}

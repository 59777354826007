import { ProcessedDataFactory } from './types/processed-data-factory';
import { ChannelNameStyle } from '../channel-data-loaders/channel-name-style';
import { AutoScaleChannelGroup } from '../channel-data-loaders/auto-scale-channel-group';
import { NormalizeUnitsForChannelSources } from '../channel-data-loaders/normalize-units-for-channel-sources';
import { SiteHooks } from '../../site-hooks';
import { SourceLoaderSet } from '../channel-data-loaders/source-loader-set';
import { ApplyChannelModifiers } from '../channel-data-loaders/apply-channel-modifiers';
import { ViewerChannelDataFactory } from '../channel-data-loaders/viewer-channel-data-factory';
import { GetInterpolatedChannelValueAtDomainValue } from '../channel-data-loaders/get-interpolated-channel-value-at-domain-value';
import { GetSecondaryDomainChannelMapping } from '../channel-data-loaders/get-secondary-domain-channel-mappings';
import { ApplyModifiersToSourcesChannelData } from './apply-modifiers-to-sources-channel-data';
import { CloneAsSiChannelData } from './clone-as-si-channel-data';
import { CreateColumnsFromDiagonals } from './create-columns-from-diagonals';
import { CreatePlotsFromDiagonals } from './create-plots-from-diagonals';
import { CreateProcessedPlots } from './create-processed-plots';
import { CreateRowsFromDiagonals } from './create-rows-from-diagonals';
import { CreateTransientDataForLayout } from './create-transient-data-for-layout';
import { CreateTransientDataForSide } from './create-transient-data-for-side';
import { GetChannelIndexMap } from './get-channel-index-map';
import { GetChannelIndexMapForSides } from './get-channel-index-map-for-sides';
import { GetDistinctChannelNames } from './get-distinct-channel-names';
import { GetFeatureChannelNames } from './get-feature-channel-names';
import { GetFirstChannelName } from './get-first-channel-name';
import { GetProcessedChannel } from './get-processed-channel';
import { GetProcessedChannels } from './get-processed-channels';
import { GetProcessedPrimaryDomain } from './get-processed-primary-domain';
import { GetProcessedRenderChannel } from './get-processed-render-channel';
import { GetProcessedSide } from './get-processed-side';
import { GetSiFeatureChannel } from './get-si-feature-channel';
import { GetSideSizes } from './get-side-sizes';
import { GetSourceFeatureChannelNames } from './get-source-feature-channel-names';
import { GetSourceFeatureChannels } from './get-source-feature-channels';
import { GetUnprocessedLayout } from './get-unprocessed-layout';
import { GetValidatedChannel } from './get-validated-channel';
import { GetValidatedLayout } from './get-validated-layout';
import { GetXDomainNamesFromColumnSides } from './get-x-domain-names-from-column-sides';
import { LoadChannelData } from './load-channel-data';
import { LoadSourceChannelDataMap } from './load-source-channel-data-map';
import { MergeSides } from './merge-sides';
import { NormalizeSourcesChannelData } from './normalize-sources-channel-data';
import { ProcessLayout } from './process-layout';
import { ProcessLayoutRoot } from './process-layout-root';
import { ProcessLayoutSide } from './process-layout-side';
import { ProcessLayoutSides } from './process-layout-sides';
import { UpdatePlotSizes } from './update-plot-sizes';
import { UpdateProcessedPlotSizes } from './update-processed-plot-sizes';
import { UpdateSideSizes } from './update-side-sizes';
import { ValidateSide } from './validate-side';


/**
 * The data pipeline used by most of our visualizations to process the channel
 * data and layout.
 */
export class DataPipeline {

  /**
   * Creates an instance of the data pipeline. The passed in services are the
   * individual stages of the pipeline.
   *
   * @param getValidatedLayout - The service to get the validated layout.
   * @param loadChannelData - The service to load the channel data.
   * @param processLayout - The service to process the layout.
   * @param updatePlotSizes - The service to update the plot sizes.
   * @param getUnprocessedLayout - The service to get the unprocessed layout.
   */
  constructor(
    public readonly getValidatedLayout: GetValidatedLayout,
    public readonly loadChannelData: LoadChannelData,
    public readonly processLayout: ProcessLayout,
    public readonly updatePlotSizes: UpdatePlotSizes,
    public readonly getUnprocessedLayout: GetUnprocessedLayout) {
  }

  /**
   * Creates an instance of the data pipeline used by most of our visualizations
   * to process the channel data and layout.
   *
   * @param primaryDomainName - The name of the primary domain.
   * @param siteHooks - The site hooks service.
   * @param sourceLoaderSet - The source loader set.
   * @param channelNameStyle - The channel name style.
   * @param getInterpolatedChannelValueAtDomainValue - The function to get the interpolated channel value at a specific domain value.
   * @param autoScaleChannelsIndividually - Whether to auto scale the channels individually or as a group.
   * @returns The data pipeline.
   */
  public static create(
    primaryDomainName: string,
    siteHooks: SiteHooks,
    sourceLoaderSet: SourceLoaderSet,
    channelNameStyle: ChannelNameStyle,
    getInterpolatedChannelValueAtDomainValue: GetInterpolatedChannelValueAtDomainValue,
    autoScaleChannelsIndividually: boolean = false) {

    const getSecondaryDomainChannelMapping = new GetSecondaryDomainChannelMapping();
    const secondaryDomainName = getSecondaryDomainChannelMapping.executeForChannel(primaryDomainName);

    const applyChannelModifiers = ApplyChannelModifiers.create(new ViewerChannelDataFactory(siteHooks), getInterpolatedChannelValueAtDomainValue);

    const getValidatedChannel = new GetValidatedChannel();

    const getValidatedLayout = new GetValidatedLayout(
      primaryDomainName,
      new ValidateSide(
        getValidatedChannel),
      getValidatedChannel);

    const loadChannelData = new LoadChannelData(
      sourceLoaderSet,
      siteHooks,
      new GetFeatureChannelNames(
        sourceLoaderSet,
        new GetSourceFeatureChannelNames(
          primaryDomainName,
          channelNameStyle,
          new GetFirstChannelName())),
      new GetDistinctChannelNames(
        primaryDomainName,
        secondaryDomainName),
      new LoadSourceChannelDataMap(
        primaryDomainName,
        channelNameStyle),
      new GetSourceFeatureChannels(
        new GetSiFeatureChannel(
          new CloneAsSiChannelData())),
      new NormalizeSourcesChannelData(
        new NormalizeUnitsForChannelSources()),
      new ApplyModifiersToSourcesChannelData(
        primaryDomainName,
        applyChannelModifiers));

    const processedDataFactory = new ProcessedDataFactory();

    const getProcessedChannels = new GetProcessedChannels(
      applyChannelModifiers,
      processedDataFactory);

    const getProcessedChannel = new GetProcessedChannel(
      primaryDomainName,
      getProcessedChannels);

    const autoScaleChannelGroup = new AutoScaleChannelGroup();

    const getProcessedSide = new GetProcessedSide(
      autoScaleChannelsIndividually,
      processedDataFactory,
      autoScaleChannelGroup);

    const mergeSides = new MergeSides(
      getProcessedSide);

    const getXDomainNamesFromColumnSides = new GetXDomainNamesFromColumnSides(
      primaryDomainName,
      applyChannelModifiers);

    const processLayout = new ProcessLayout(
      new CreateTransientDataForLayout(
        new CreateTransientDataForSide()),
      new GetChannelIndexMap(
        new GetChannelIndexMapForSides(
          primaryDomainName,
          getXDomainNamesFromColumnSides,
          applyChannelModifiers)),
      new GetProcessedPrimaryDomain(
        primaryDomainName,
        getProcessedChannel,
        getValidatedChannel,
        autoScaleChannelGroup),
      new ProcessLayoutSides(
        new ProcessLayoutSide(
          getXDomainNamesFromColumnSides,
          getProcessedChannels,
          getProcessedChannel,
          getProcessedSide)),
      new ProcessLayoutRoot(
        secondaryDomainName,
        new CreateProcessedPlots(
          new CreatePlotsFromDiagonals(
            new CreateColumnsFromDiagonals(
              mergeSides),
            new CreateRowsFromDiagonals(
              mergeSides))),
        new GetProcessedRenderChannel(
          getProcessedChannel,
          autoScaleChannelGroup)));

    const updatePlotSizes = new UpdatePlotSizes(
      new GetSideSizes(),
      new UpdateSideSizes(),
      new UpdateProcessedPlotSizes());

    const getUnprocessedLayout = new GetUnprocessedLayout();

    return new DataPipeline(
      getValidatedLayout,
      loadChannelData,
      processLayout,
      updatePlotSizes,
      getUnprocessedLayout);
  }
}

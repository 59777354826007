import * as d3 from '../../d3-bundle';
import { IMultiPlotLayout } from '../data-pipeline/types/i-multi-plot-layout';
import { ProcessedPlot } from '../data-pipeline/types/processed-plot';
import { ProcessedMultiPlotChannel } from '../data-pipeline/types/processed-multi-plot-channel';
import { DisplayableError } from '../../displayable-error';
import { cssSanitize } from '../../css-sanitize';
import { IMinMax } from '../min-max';
import { ExplorationData, ChannelType } from '../channel-data-loaders/study-source-loader';
import { isUndefined } from '../../is-defined';

/**
 * A lookup from dimension name to dimension.
 */
export interface DimensionLookup {
  [name: string]: Dimension;
}

/**
 * A readonly lookup from dimension name to dimension.
 */
export interface ReadonlyDimensionLookup {
  readonly [name: string]: Dimension;
}

/**
 * A dimension in a parallel coordinates plot.
 */
export class Dimension {

  /**
   * The channel for this dimension.
   */
  public readonly channel: ProcessedMultiPlotChannel;

  /**
   * The scale for this dimension.
   */
  public readonly scale: d3.ScaleLinear<number, number>;

  /**
   * The calculated pixel position of the dimension.
   */
  private _calculatedPosition: number = 0;

  /**
   * The pixel offset of the dimension due to dragging, while it is being dragged.
   */
  public dragOffset: number = 0;

  /**
   * The CSS sanitized ID of the dimension.
   */
  public readonly id: string;

  /**
   * Create a new instance of the dimension.
   * @param allDimensions All dimensions.
   * @param plot The plot.
   * @param outputDimensionIndex The output dimension index.
   */
  constructor(
    public readonly allDimensions: ReadonlyArray<Dimension>,
    public readonly plot: ProcessedPlot,
    outputDimensionIndex: number) {

    // Note this is a property, and so may already have a value set as it looks in the plot transient data.
    if (isUndefined(this.outputDimensionIndex)) {
      this.outputDimensionIndex = outputDimensionIndex;
    }

    // Get the first visible channel from the plot. In a parallel coordinates plot we use the same
    // data pipeline as the multi-plot viewers, but we represent the chart as having one plot per column,
    // and one column per dimension.
    const channel = plot.column.processed.channels.find(v => v.isVisible);
    if (!channel) {
      throw new DisplayableError('No visible channels found for dimension.');
    }

    this.channel = channel;

    this.id = cssSanitize(this.channel.name);

    // Our scale is going to be the domain of the plot X axis with the range (pixel size) of the Y axis,
    // because we're flipping the X axis on it's side.
    // A layout for the parallel coordinates plot is a single row (with no channels) and one column per dimension (each with one channel).
    this.scale = d3.scaleLinear<number, number>();

    // If there is a zoom range set in the transient data, zoom to it.
    this.zoomToDomain(this.zoomDomain);

    this.updatePlotSizes();
  }

  /**
   * Update the positions and sizes of the axis for this dimension.
   */
  public updatePlotSizes() {
    let column = this.plot.column.processed;
    let row = this.plot.row.processed;

    // Our axis position is going to be half way along the plot, so that all the axes taken together are centered in the viewer.
    this._calculatedPosition = column.offset + (column.size / 2);

    // The column scale range will be zero and the plot X domain size.
    // We want to swap the X domain size for the Y domain size, as we're flipping the X axis on it's side.
    // We use `map` so that we keep the zero value and maximum value in the same order (it varies depending
    // on if the axis is reversed).
    // We then reverse it, because we're flipping the axis by 90 degrees which requires the range to be reversed
    // to keep the axis order what the user would expect.
    this.scale.range(
      column.scale.range()
        .map((v: number) => v === column.plotSize ? row.plotSize : v).reverse());
  }

  /**
   * True if the domain is currently zoomed to the filter, or not zoomed if there is no filter.
   */
  public get isZoomedToFilter(): boolean {
    const domain = this.scale.domain();
    if (this.filter) {
      // If there is a filter, return true if the domain is the same as the filter.
      return d3.min(domain) === this.filter.minimum
        && d3.max(domain) === this.filter.maximum;
    } else {
      // If there is no filter, return true if the domain is the same as the column domain.
      let columnDomain = this.plot.column.processed.scale.domain();
      return domain[0] === columnDomain[0] && domain[1] === columnDomain[1];
    }
  }

  /**
   * Zoom to the filter if there is one, or reset the zoom if there is no filter.
   */
  public zoomToFilter() {
    if (this.filter && !this.isZoomedToFilter) {
      let domain: [number, number] = [this.filter.minimum, this.filter.maximum];
      this.zoomDomain = domain;
      this.zoomToDomain(domain);
    } else {
      this.zoomDomain = undefined;
      this.resetZoom();
    }
  }

  /**
   * Zoom to the given domain, or reset the zoom if the domain is undefined.
   * @param domain The domain to zoom to, or undefined to reset the zoom.
   */
  private zoomToDomain(domain: [number, number] | undefined) {
    if (domain) {
      this.scale.domain(domain);
    } else {
      this.resetZoom();
    }
  }

  /**
   * Reset the zoom to the column domain.
   */
  private resetZoom() {
    this.scale.domain(this.dataDomain);
  }

  /**
   * The domain of the data for this dimension.
   */
  public get dataDomain(): [number, number] {
    return this.plot.column.processed.scale.domain() as [number, number];
  }

  /**
   * Gets the exploration data for this channel.
   */
  public get explorationData(): ExplorationData {
    return this.channel.sources[0].getSourceData<ExplorationData>();
  }

  /**
   * True if this is an input dimension, false if it is an output dimension.
   */
  public get isInputDimension(): boolean {
    return this.explorationData.channelType === ChannelType.input;
  }

  /**
   * The calculated pixel position of the dimension.
   */
  public get calculatedPosition(): number {
    return this._calculatedPosition;
  }

  /**
   * The actual pixel position of the dimension when it is being rendered.
   */
  public get renderPosition(): number {
    if (this.dragOffset) {
      // If this dimension is being dragged, return the calculated position plus the drag offset.
      return this._calculatedPosition + this.dragOffset;
    }

    // Check if another dimension is being dragged.
    let offsetDimension = this.allDimensions.find(v => !!v.dragOffset);
    if (offsetDimension) {
      // There is a dimension being offset by dragging. Check if we need to shuffle up or down.
      let offsetDimensionPosition = offsetDimension.renderPosition;
      if (offsetDimension.plot.plotIndex < this.plot.plotIndex && offsetDimensionPosition >= this.plot.absoluteRenderArea.x) {
        // It has been dragged passed our axis, so shuffle down.
        return this.allDimensions[this.plot.plotIndex - 1].calculatedPosition;
      } else if (offsetDimension.plot.plotIndex > this.plot.plotIndex && offsetDimensionPosition <= (this.plot.absoluteRenderArea.x + this.plot.absoluteRenderArea.width)) {
        // It has been dragged passed our axis, so shuffle up.
        return this.allDimensions[this.plot.plotIndex + 1].calculatedPosition;
      }
    }

    // Otherwise, return the calculated position.
    return this._calculatedPosition;
  }

  /**
   * Gets whether the axis is in the process of being reversed.
   * This is used to flag when the axis is being flipped, so that we can transition
   * the brush without rendering the filtered data as the brush moves.
   */
  public get isAxisReversing(): boolean {
    return this.channel.unprocessed.transient.flags['isAxisReversing'];
  }

  /**
   * Sets whether the axis is in the process of being reversed.
   */
  public set isAxisReversing(value: boolean) {
    this.channel.unprocessed.transient.flags['isAxisReversing'] = value;
  }

  /**
   * Gets whether the axis is in the process of being brushed.
   * This is used to track when we are brushing, so we don't recursively
   * move the brush during a render while we are brushing.
   */
  public get isBrushing(): boolean {
    return this.channel.unprocessed.transient.flags['isBrushing'];
  }

  /**
   * Sets whether the axis is in the process of being brushed.
   */
  public set isBrushing(value: boolean) {
    this.channel.unprocessed.transient.flags['isBrushing'] = value;
  }

  /**
   * Gets whether the axis is in the process of being zoomed.
   * This is used to flag when the axis is being zoomed, so that we can transition
   * the brush without rendering the filtered data as the brush moves.
   */
  public get isAxisZooming(): boolean {
    return this.channel.unprocessed.transient.flags['isAxisZooming'];
  }

  /**
   * Sets whether the axis is in the process of being zoomed.
   */
  public set isAxisZooming(value: boolean) {
    this.channel.unprocessed.transient.flags['isAxisZooming'] = value;
  }

  /**
   * Gets the current zoom domain for the axis.
   */
  public get zoomDomain(): [number, number] | undefined {
    return this.plot.column.transient.custom['zoomDomain'];
  }

  /**
   * Sets the current zoom domain for the axis.
   */
  public set zoomDomain(value: [number, number] | undefined) {
    this.plot.column.transient.custom['zoomDomain'] = value;
  }

  /**
   * Gets the output dimension index.
   */
  public get outputDimensionIndex(): number {
    return this.plot.column.transient.custom['outputDimensionIndex'];
  }

  /**
   * Sets the output dimension index.
   */
  public set outputDimensionIndex(value: number) {
    this.plot.column.transient.custom['outputDimensionIndex'] = value;
  }

  /**
   * Gets the current filter for this axis.
   */
  public get filter(): IMinMax | undefined {
    return this.channel.unprocessed.transient.filter;
  }

  /**
   * Sets the current filter for this axis.
   */
  public set filter(value: IMinMax | undefined) {
    this.channel.unprocessed.transient.filter = value;
  }
}

/**
 * A mouse event on a line in a parallel coordinates plot.
 */
export class LineMouseEvent {
  constructor(
    public type: string,
    public lineIndex: number,
    public sourceEvent?: any) {
  }
}

/**
 * A list of points across all dimensions.
 */
export type PointsInDimensions = ReadonlyArray<number>;

/**
 * The data for a parallel coordinates plot.
 */
export class ParallelCoordinatesData {

  /**
   * Create a new instance of the parallel coordinates data.
   * @param layout The layout of the plot.
   * @param dimensionList The list of dimensions.
   * @param dimensionsByName The dimensions by name.
   * @param lines The set of lines to draw.
   * @param colorScale The color scale.
   * @param colorDimensionIndex The color dimension.
   */
  constructor(
    public readonly layout: IMultiPlotLayout,
    public readonly dimensionList: ReadonlyArray<Dimension>,
    public readonly dimensionsByName: ReadonlyDimensionLookup,
    public readonly lines: ReadonlyArray<PointsInDimensions>,
    public readonly colorScale: d3.ScaleContinuousNumeric<string, string>,
    public readonly colorDimensionIndex: number) {
  }
}

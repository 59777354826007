export interface IGetUnits {
  getUnits(key: string): UnitsInformation;
}

export class UnitsInformation {
  constructor(
    public readonly targetUnits: string | undefined,
    public readonly sourceUnits: string | undefined) {
  }
}

/**
 * Represents an immutable rectangle.
 */
export interface IRectangle {
  readonly x: number;
  readonly y: number;
  readonly width: number;
  readonly height: number;
}

/**
 * An immutable rectangle.
 */
export class Rectangle implements IRectangle {
  constructor(
    public readonly x: number,
    public readonly y: number,
    public readonly width: number,
    public readonly height: number) {
  }
}

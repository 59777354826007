import { ProcessedMultiPlotChannel } from './processed-multi-plot-channel';
import { SourceData } from './source-data';
import { IPopulatedMultiPlotSide } from './i-populated-multi-plot-side';
import { ProcessedPlot } from './processed-plot';

/**
 * A processed layout for a multi plot viewer.
 */

export class ProcessedMultiPlotLayout {
  /**
   * Creates a new processed layout for a multi plot viewer.
   * @param primaryDomain The primary domain.
   * @param secondaryDomain The secondary domain (used for raising cursor events on an alternative domain).
   * @param colorChannel The color channel, for visualizations which color based on a channel value (e.g. track viewer).
   * @param sizeChannel The size channel, for visualizations which adjust size based on a channel value (e.g. track viewer).
   * @param plots The list of processed plots.
   * @param columns The list of processed columns.
   * @param rows The list of processed rows.
   * @param sourceData The list of data sources.
   */
  constructor(
    public readonly primaryDomain: ProcessedMultiPlotChannel,
    public readonly secondaryDomain: ProcessedMultiPlotChannel | undefined,
    public readonly colorChannel: ProcessedMultiPlotChannel | undefined,
    public readonly sizeChannel: ProcessedMultiPlotChannel | undefined,
    public readonly plots: ReadonlyArray<ProcessedPlot>,
    public readonly columns: IPopulatedMultiPlotSide[],
    public readonly rows: IPopulatedMultiPlotSide[],
    public readonly sourceData: ReadonlyArray<SourceData>) {
  }
}

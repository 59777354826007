import { getSafePath } from '../get-safe-path';
import { getNavigablePath } from '../get-navigable-path';
import { CreateConfigSubTreeFilter } from '../create-config-sub-tree-filter.service';
import { ExtensionButtons } from './extension-buttons';
import { AddButtonsHandler } from './add-buttons-handler';
import { EditorNodeUtilities } from './editor-node-utilities';
import { UpdateDelegate } from './update-delegate';
import { ExtensionButtonCategories } from './extension-button-categories';
import { Injectable } from '@angular/core';


@Injectable()
export class AddLoadButtons implements AddButtonsHandler {
  constructor(
    private readonly createConfigSubTreeFilter: CreateConfigSubTreeFilter) {
  }

  public get category(): ExtensionButtonCategories {
    return ExtensionButtonCategories.load;
  }

  public execute(utils: EditorNodeUtilities, subTreeControls: any, globalUpdateDelegate: UpdateDelegate): ExtensionButtons {
    let fullPath = utils.self.path;
    let safePath = getSafePath(fullPath);
    let navigablePath = getNavigablePath(fullPath);

    utils.subTreeData = undefined;

    let loadButton: HTMLButtonElement = utils.self.getButton('', 'load', 'Load Component');
    loadButton.className += ' btn-icon sub-tree-load-button';
    let saveButton: HTMLButtonElement = utils.self.getButton('', 'save', 'Save Component');
    saveButton.className += ' btn-icon sub-tree-save-button';
    let saveAsButton: HTMLButtonElement = utils.self.getButton('', 'saveas', 'Save Component As');
    saveAsButton.className += ' btn-icon sub-tree-save-as-button';

    let setSaveButtonEnablement = () => {
      saveButton.disabled = !utils.configSubTreeRepository.canSave(utils.subTreeData);
      //saveButton.innerText = 'Save';
    };
    // this.updateMethods.push(setSaveButtonEnablement);
    setSaveButtonEnablement();

    utils.addButton(subTreeControls, loadButton, async () => {
      let initialFilter = this.createConfigSubTreeFilter.execute(utils.configType, utils.self.jsoneditor.getValue(), safePath, navigablePath); // createInitialFilter();
      let result = await utils.configSubTreeRepository.loadConfigSubTree(safePath, initialFilter);

      if (result) {
        utils.subTreeData = result;
        utils.setValueOrParentValue(result.config.data);
      }
    }, globalUpdateDelegate);

    if (safePath) {
      utils.addButton(subTreeControls, saveButton, async () => {
        if (!utils.configSubTreeRepository.canSave(utils.subTreeData)) {
          return;
        }

        saveButton.disabled = true;
        saveButton.innerText = 'Saving...';

        let config = utils.self.getValue();

        await utils.configSubTreeRepository.saveConfigSubTree(
          utils.subTreeData.configId, safePath, utils.subTreeData.config.name, config);
      }, globalUpdateDelegate);

      utils.addButton(subTreeControls, saveAsButton, async () => {
        let config = utils.self.getValue();

        let result = await utils.configSubTreeRepository.saveConfigSubTreeAs(safePath, config);
        if (result) {
          utils.subTreeData = result;
        }
      }, globalUpdateDelegate);

      //this.addImportAndEncryptionButtons(subTreeControls, safePath, () => setSaveButtonEnablement());
    }

    return new ExtensionButtons(
      () => setSaveButtonEnablement(),
      {
        load: loadButton,
        save: saveButton,
        saveAs: saveAsButton,
      });
  }
}

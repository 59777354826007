import {
  DocumentCustomPropertyData,
  DocumentSubType,
  GetStudyJobQueryResult,
  GetStudyQueryResult
} from '../../generated/api-stubs';
import {CustomPropertyUtilities} from '../simulations/custom-properties/custom-property-utilities';
import {ConfigTypeLookup} from '../simulations/configs/config-types';

/**
 * Infer the input config types from a study result.
 * @param studyResult The study result.
 * @returns The input config types.
 */
export function inferInputConfigTypes(
  studyResult: GetStudyQueryResult) {

  let definition = studyResult.study.data.definition;
  let result: DocumentSubType[] = [];

  if(definition) {
    if (definition.simConfig) {
      for (let key of Object.keys(definition.simConfig)) {
        if (Object.keys(definition.simConfig[key]).length === 0) {
          continue;
        }
        result.push(key as DocumentSubType);
      }
    }

    if (definition.exploration) {
      result.push(DocumentSubType.exploration);
    }
  }

  return result;
}

/**
 * Get the source config for a given key (config type) from either the study or job result.
 * @param key The key.
 * @param studyResult The study result.
 * @param jobResult The optional job result.
 * @returns The source config.
 */
export function getSourceConfig(
  key: string,
  studyResult: GetStudyQueryResult,
  jobResult?: GetStudyJobQueryResult){
  let definition = jobResult ? jobResult.studyJobInput : studyResult.study.data.definition;
  return definition ? (definition[key] || definition.simConfig[key]) : undefined;
}

/**
 * Get the custom properties for the given key (config type) from either the study or job result.
 * @param key The key.
 * @param studyResult The study result.
 * @param jobResult The optional job result.
 * @returns The source config custom properties.
 */
export function getConfigPropertiesFromStudy(configType: string, studyResult: GetStudyQueryResult): DocumentCustomPropertyData[]{
  let propertyPrefix = configType + '.';
  return CustomPropertyUtilities.objectToList(studyResult.study.properties)
    .filter(v => v.name.startsWith(propertyPrefix))
    .map(v => ({ name: v.name.substr(propertyPrefix.length), value: v.value}));
}

/**
 * Get the notes for the given config type from a study result.
 * @param configType The config type.
 * @param studyResult The study result.
 * @returns The notes.
 */
export function getConfigNotesFromStudy(configType: string, studyResult: GetStudyQueryResult): string | undefined{
  let studyNotes = studyResult.study.notes;
  let configTypeDefinition = ConfigTypeLookup.get(configType);
  let configTitle = configTypeDefinition ? configTypeDefinition.titleName : configType;
  let regexString = `(?:^|\n)(?:${configType}|${configTitle}):\\s*\\n([\\s\\S]*?)(?:[\\w\\d ]+:\\n|$)`;
  let regex = new RegExp(regexString, 'i');
  let result = regex.exec(studyNotes);

  if(result && result.length === 2){
    return result[1].trim();
  }

  return undefined;
}

import {Component, Input, OnInit} from '@angular/core';
import {CanopyJson} from '../common/canopy-json.service';
import {FormSubmissionHandler} from '../common/forms/form-submission-handler.service';
import {
  PoolStub, TenantSettingsStub, GetAdminTenantSettingsQueryResult,
  StudyType
} from '../../generated/api-stubs';
import {FormSubmissionButton} from '../common/forms/form-submission-button';
import {GetFriendlyErrorAndLog} from '../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {UserIdentity} from '../identity/user-identity';
import {UntypedFormGroup, UntypedFormControl, UntypedFormBuilder} from '@angular/forms';
import {SimVersionDocumentCache} from '../simulations/sim-version-document-cache.service';
import {DisplayableError} from '../common/errors/errors';
import {GetTenantSimVersion} from '../common/get-tenant-sim-version.service';
import { AuthenticationService } from '../identity/state/authentication.service';

@Component({
  selector: 'cs-admin-tenant-settings',
  templateUrl: './admin-tenant-settings.component.html',
  styleUrls: ['./admin-tenant-settings.component.scss']
})
export class AdminTenantSettingsComponent implements OnInit {

  @Input() public tenantId: string;

  public form: UntypedFormGroup;
  public studyTypes: UntypedFormControl = new UntypedFormControl('', []);
  public tags: UntypedFormControl = new UntypedFormControl('', []);
  public internalTags: UntypedFormControl = new UntypedFormControl('', []);
  public simVersion: UntypedFormControl = new UntypedFormControl('', []);
  public poolId: UntypedFormControl = new UntypedFormControl('', []);
  public autoScaleFormula: UntypedFormControl = new UntypedFormControl('', []);
  public secondaryPoolId: UntypedFormControl = new UntypedFormControl('', []);
  public secondaryAutoScaleFormula: UntypedFormControl = new UntypedFormControl('', []);
  public heavyPoolId: UntypedFormControl = new UntypedFormControl('', []);
  public heavyAutoScaleFormula: UntypedFormControl = new UntypedFormControl('', []);

  public errorMessage: string;
  public submitButton = new FormSubmissionButton('Save', 'Saving...');
  public saveSuccessful = false;

  public isAdministrator: boolean;

  public currentIdentity: UserIdentity;
  public settingsResult: GetAdminTenantSettingsQueryResult;

  public testResult: string;
  public isTestingFormula: boolean;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly tenantSettingsStub: TenantSettingsStub,
    private readonly poolStub: PoolStub,
    private readonly json: CanopyJson,
    private readonly formSubmissionHandler: FormSubmissionHandler,
    private readonly simVersionDocumentCache: SimVersionDocumentCache,
    private readonly getTenantSimVersion: GetTenantSimVersion,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  public ngOnInit() {
    this.load();
  }

  public async load() {
    try {
      this.isAdministrator = this.authenticationService.isAdministrator;
      this.settingsResult = await this.tenantSettingsStub.getAdminTenantSettings(this.tenantId);

      this.studyTypes.setValue(this.getArrayString(this.settingsResult.settings.studyTypes), {});
      this.tags.setValue(this.getArrayString(this.settingsResult.settings.tags), {});
      this.internalTags.setValue(this.getArrayString(this.settingsResult.settings.internalTags), {});
      this.simVersion.setValue(this.settingsResult.settings.simVersion || '', {});

      let poolSettings = this.settingsResult.settings.poolSettings;
      if(poolSettings){
        this.poolId.setValue(poolSettings.poolId || '', {});
        this.autoScaleFormula.setValue(poolSettings.autoScaleFormula || '', {});
      }

      let secondaryPoolSettings = this.settingsResult.settings.secondaryPoolSettings;
      if(secondaryPoolSettings){
        this.secondaryPoolId.setValue(secondaryPoolSettings.poolId || '', {});
        this.secondaryAutoScaleFormula.setValue(secondaryPoolSettings.autoScaleFormula || '', {});
      }

      let heavyPoolSettings = this.settingsResult.settings.heavyPoolSettings;
      if(heavyPoolSettings){
        this.heavyPoolId.setValue(heavyPoolSettings.poolId || '', {});
        this.heavyAutoScaleFormula.setValue(heavyPoolSettings.autoScaleFormula || '', {});
      }

      this.form = this.formBuilder.group({
        studyTypes: this.studyTypes,
        tags: this.tags,
        internalTags: this.internalTags,
        simVersion: this.simVersion,
        poolId: this.poolId,
        autoScaleFormula: this.autoScaleFormula,
        secondaryPoolId: this.secondaryPoolId,
        secondaryAutoScaleFormula: this.secondaryAutoScaleFormula,
        heavyPoolId: this.heavyPoolId,
        heavyAutoScaleFormula: this.heavyAutoScaleFormula
      });
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public getArrayString(input: StudyType[] | string[]): string{
    if(!input) {
      return '';
    }

    return input.join(', ');
  }

  public getListFromString(inputString: string): string[]{
    if(!inputString) {
      return [];
    }

    return inputString.split(/\s*,\s*/);
  }

  public async reload(){
    this.form = undefined;
    await this.load();
  }

  public async onSubmit() {
    this.saveSuccessful = false;
    let success = await this.formSubmissionHandler.execute(this.submit, this.form, this.submitButton, this);

    if (success) {
      this.saveSuccessful = true;
    }
  }

  public async testFormula(){
    try {
      this.isTestingFormula = true;
      this.testResult = undefined;

      let tenantId = this.tenantId;
      let poolId = this.poolId.value;
      let poolFormula = this.autoScaleFormula.value;
      let secondaryPoolId = this.secondaryPoolId.value;
      let secondaryPoolFormula = this.secondaryAutoScaleFormula.value;
      let heavyPoolId = this.heavyPoolId.value;
      let heavyPoolFormula = this.heavyAutoScaleFormula.value;

      let result = poolId && poolFormula ? await this.poolStub.getTestAutoScaleFormula(tenantId, poolId, poolFormula) : null;
      let secondaryResult = secondaryPoolId && secondaryPoolFormula ? await this.poolStub.getTestAutoScaleFormula(tenantId, secondaryPoolId, secondaryPoolFormula) : null;
      let heavyResult = heavyPoolId && heavyPoolFormula ? await this.poolStub.getTestAutoScaleFormula(tenantId, heavyPoolId, heavyPoolFormula) : null;

      this.testResult = this.json.stringify([result, secondaryResult, heavyResult]);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
    this.isTestingFormula = false;
  }

  public async submit() {
    let poolId = this.poolId.value;
    let poolFormula = this.autoScaleFormula.value;
    let secondaryPoolId = this.secondaryPoolId.value;
    let secondaryPoolFormula = this.secondaryAutoScaleFormula.value;
    let heavyPoolId = this.heavyPoolId.value;
    let heavyPoolFormula = this.heavyAutoScaleFormula.value;

    this.verifyNotSettingSharedPoolFormula(poolId, poolFormula);
    this.verifyNotSettingSharedPoolFormula(secondaryPoolId, secondaryPoolFormula);
    this.verifyNotSettingSharedPoolFormula(heavyPoolId, heavyPoolFormula);

    let settings = {
      studyTypes: <StudyType[]>this.getListFromString(this.studyTypes.value) || undefined,
      tags: this.getListFromString(this.tags.value) || undefined,
      internalTags: this.getListFromString(this.internalTags.value) || undefined,
      simVersion: this.simVersion.value || undefined,
      poolSettings: poolId ? {
        poolId,
        autoScaleFormula: poolFormula
      } : undefined,
      secondaryPoolSettings: secondaryPoolId ? {
        poolId: secondaryPoolId,
        autoScaleFormula: secondaryPoolFormula
      } : undefined,
      heavyPoolSettings: heavyPoolId ? {
        poolId: heavyPoolId,
        autoScaleFormula: heavyPoolFormula
      } : undefined
    };

    this.simVersionDocumentCache.clear();

    try {
      let newETag = await this.tenantSettingsStub.putAdminTenantSettings(
        this.tenantId,
        {
          settings,
          eTag: this.settingsResult.eTag
        });

      this.settingsResult.eTag = newETag;
    } catch(error){
      if(error.isPreconditionFailedError){
        await this.reload();
      }

      throw error;
    }

    // Update cached version if current tenant.
    await this.getTenantSimVersion.executeForTenant(this.tenantId);
  }

  private verifyNotSettingSharedPoolFormula(poolId: string, poolFormula: string): void{
    if(poolId && poolId.indexOf('-shared-') !== -1){
      if(poolFormula){
        throw new DisplayableError('You cannot set the formula of a shared pool.');
      }
    }
  }
}

import { ExplorationInputs } from './exploration-inputs';
import { ExplorationSweep } from './exploration-sweep';
import { ExplorationSubSweep } from './exploration-sub-sweep';
import { ExplorationInputsMetadata } from './exploration-inputs-metadata';


/**
 * Gets the exploration inputs from the inputs metadata.
 */
export class GetExplorationInputsFromInputsMetadata {

  /**
   * Gets the exploration inputs from the inputs metadata.
   * While these new structures look similar to the old ones, they perform additional processing
   * and provide many convenience functions for the visualizations.
   * @param inputsMetadata The inputs metadata.
   * @returns The exploration inputs.
   */
  public execute(inputsMetadata: ExplorationInputsMetadata) {
    return new ExplorationInputs(
      inputsMetadata.sweeps.map(
        sweep => new ExplorationSweep(
          sweep.subSweeps.map(
            subSweep => new ExplorationSubSweep(
              subSweep.name,
              subSweep.values,
              subSweep.unexpandedName)))));
  }
}

import { ScatterPlotViewerSettings } from './scatter-plot-viewer-settings';
import { DataRenderer } from './data-renderer';
import { NavigationStationViewer } from '../../navigation-station/navigation-station-viewer';
import { SiteHooks } from '../../site-hooks';
import { ChannelNameStyle } from '../channel-data-loaders/channel-name-style';
import { MultiPlotViewerBase } from '../multi-plot-viewer-base/multi-plot-viewer-base';
import { IMultiPlotLayout } from '../data-pipeline/types/i-multi-plot-layout';
import { MultiPlotViewerSettings } from '../multi-plot-viewer-base/multi-plot-viewer-settings';
import { MultiPlotDataRendererBase } from '../multi-plot-viewer-base/multi-plot-data-renderer-base';
import { SharedState } from '../shared-state';
import { GetInterpolatedChannelValueAtDomainValue } from '../channel-data-loaders/get-interpolated-channel-value-at-domain-value';
import { DataPipeline } from '../data-pipeline/data-pipeline';

export const LINE_SCATTER_PLOT_VIEWER_TYPE = 'lineScatterPlotViewer';
export const POINT_SCATTER_PLOT_VIEWER_TYPE = 'pointScatterPlotViewer';

/**
 * A scatter plot viewer which extends the multi plot viewer.
 */
export class ScatterPlotViewer extends MultiPlotViewerBase implements NavigationStationViewer {

  /**
   * Create a new instance of the scatter plot viewer.
   * @param primaryDomainName The primary domain name.
   * @param layout The chart layout.
   * @param channelNameStyle The channel name style.
   * @param dataPipeline The data pipeline.
   * @param sharedState The shared state.
   * @param siteHooks The site hooks.
   */
  constructor(
    primaryDomainName: string,
    layout: IMultiPlotLayout,
    channelNameStyle: ChannelNameStyle,
    dataPipeline: DataPipeline,
    sharedState: SharedState,
    siteHooks: SiteHooks) {
    super(
      primaryDomainName,
      layout,
      channelNameStyle,
      dataPipeline,
      sharedState,
      siteHooks);
  }

  /**
   * Create a new instance of the scatter plot viewer.
   * @param primaryDomainName The primary domain name.
   * @param layout The chart layout.
   * @param channelNameStyle The channel name style.
   * @param sharedState The shared state.
   * @param siteHooks The site hooks.
   * @returns A new instance of the scatter plot viewer.
   */
  public static create(
    primaryDomainName: string,
    layout: IMultiPlotLayout,
    channelNameStyle: ChannelNameStyle,
    sharedState: SharedState,
    siteHooks: SiteHooks): ScatterPlotViewer {

    let interpolator = new GetInterpolatedChannelValueAtDomainValue();
    return new ScatterPlotViewer(
      primaryDomainName,
      layout,
      channelNameStyle,
      DataPipeline.create(primaryDomainName, siteHooks, sharedState.sourceLoaderSet, channelNameStyle, interpolator),
      sharedState,
      siteHooks);
  }

  /**
   * Get the CSS class for the scatter plot viewer.
   * @returns The CSS class for the scatter plot viewer.
   */
  protected getCssClass(): string {
    return 'scatter-plot-viewer';
  }

  /**
   * Create the settings for the scatter plot viewer.
   * @param sourceCount The number of sources.
   * @returns The settings for the scatter plot viewer.
   */
  createSettings(sourceCount: number): MultiPlotViewerSettings {
    return ScatterPlotViewerSettings.build(sourceCount);
  }

  /**
   * Create the data renderer for the scatter plot viewer.
   * @returns The data renderer for the scatter plot viewer.
   */
  createDataRenderer(): MultiPlotDataRendererBase {
    return DataRenderer.create();
  }
}

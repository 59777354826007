<div class="section">
  <p class="introduction">
    Requesting support is a two step process:
  </p>
</div>
<div class="section section-border">
  <h2>1. Grant us access to relevent data.</h2>
  @if (!session.data?.session) {
    <p>
      By opening a support session you are granting Canopy Simulations
      permission to access this study, config or worksheet and any data it contains.
      You may revoke this permission by closing the support session.
    </p>
  }
  @if (session.data?.session) {
    <ng-container class="session-status-container">
      @if (isSupportSessionOpen) {
        <div class="alert alert-warning">Support Session Open</div>
      }
      @if (!isSupportSessionOpen) {
        <div class="alert alert-success">Support Session Closed</div>
      }
    </ng-container>
  }

  <form (ngSubmit)="session.onSubmitAndOpen()" [formGroup]="session.form">

    @if (warningsRequireConfirmation) {
      <div class="mb-0">
        <input id="are-warnings-unrelated-input" formControlName="areWarningsUnrelated" type="checkbox">
        <label class="form-label" for="are-warnings-unrelated-input">Addressing the warnings below does not resolve my issue.</label>
      </div>
      @if (session.hasFormSubmissionBeenAttempted && session.areWarningsUnrelated.hasError('required')) {
        <p class="text-danger">
          Please confirm the warnings listed below are unrelated to your issue.
        </p>
      }
    }
    @if (!session.isLatestSimVersion) {
      <div class="mb-0">
        <input id="sim-version-check-input" formControlName="isSimVersionUnrelated" type="checkbox">
        <label class="form-label" for="sim-version-check-input">The latest sim version does not resolve my issue.</label>
      </div>
      @if (session.hasFormSubmissionBeenAttempted && session.isSimVersionUnrelated.hasError('required')) {
        <p class="text-danger">
          Please confirm the sim version is unrelated to your issue.
        </p>
      }
    }

    <p class="last-item">
      @if (!isSupportSessionOpen) {
        <button id="support-session-submit-button" type="submit" class="btn btn-secondary"
        >{{session.submitButton.text}}</button>
      }
      @if (isSupportSessionOpen) {
        <button id="support-session-close-button" type="button" class="btn btn-secondary"
        (click)="session.onSubmitAndClose()">{{session.closeSupportSessionButton.text}}</button>
      }
    </p>
  </form>
</div>
<div class="section-border" [ngClass]="{ 'section': warningsRequireConfirmation }">
  <h2>2. Create a ticket.</h2>
  @if (!isSupportSessionOpen) {
    <div class="alert alert-info">Please open a support session before creating a ticket.</div>
  }
  <a href="{{createSupportTicketUrl}}"
    id="create-new-support-session-ticket-link" class="btn btn-secondary"
  [class.disabled]="!isSupportSessionOpen">New Ticket</a>
  <a href="{{createSupportTicketEmailUrl}}"
    id="create-new-support-session-email-link"
    class="btn btn-secondary"
  [class.disabled]="!isSupportSessionOpen">New Ticket (Email)</a>
  <a href="{{searchSupportTicketsUrl}}" class="btn btn-secondary">Find Related Tickets</a>
  <a href="{{manageSupportTicketsUrl}}" class="btn btn-secondary">Manage Tickets</a>
  <a href="{{documentationUrl}}" class="btn btn-secondary">Documentation</a>
</div>

@if (warningsRequireConfirmation) {
  <h2>Warnings (from first job)</h2>
  <div class="alert alert-warning last-item">
    @for (warning of session.warnings; track warning) {
      <p>{{warning}}</p>
    }
  </div>
}

import {Component, ElementRef} from '@angular/core';

/**
 * Component for a button that opens a context menu, for users on devices that don't have a right-click.
 */
@Component({
  selector: 'cs-worksheet-context-menu-button',
  templateUrl: './worksheet-context-menu-button.component.html',
  styleUrls: ['./worksheet-context-menu-button.component.scss']
})
export class WorksheetContextMenuButtonComponent {

  /**
   * Creates an instance of WorksheetContextMenuButtonComponent.
   * @param elementRef The element reference.
   */
  constructor(private readonly elementRef: ElementRef) { }

  /**
   * Handles the button click event.
   * @param event The mouse event.
   */
  public onButtonClick(event: MouseEvent){
    event.preventDefault();
    this.elementRef.nativeElement.dispatchEvent(
      new CustomEvent(
        'contextmenu',
        event));
  }
}

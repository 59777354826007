import { MultiPlotViewerSettings } from '../multi-plot-viewer-base/multi-plot-viewer-settings';

/**
 * Settings for the scatter plot viewer.
 */
export class ScatterPlotViewerSettings extends MultiPlotViewerSettings {

  /**
   * Create a new instance of the scatter plot viewer settings.
   * @param sourceCount The number of data sources.
   * @returns A new instance of the scatter plot viewer settings.
   */
  public static build(sourceCount: number): ScatterPlotViewerSettings {
    return new ScatterPlotViewerSettings(sourceCount);
  }
}

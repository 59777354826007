<div class="container-fluid">
  <div class="card">
    <h1 class="card-header">Forgot your details?</h1>

    <div class="card-body">
      @if (!submitted) {
        <div class="row" id="form-panel">
          <div class="col-12 col-sm-6">
            <form (ngSubmit)="onSubmit()" [formGroup]="form">
              <div class="mb-3">
                <label class="form-label" for="tenant-input">Please enter your company:</label>
                <input id="tenant-input" type="text" class="form-control" formControlName="tenant">
              </div>
              <div class="mb-3">
                <label class="form-label" for="username-input">Then provide either your username</label>
                <input id="username-input" type="text" class="form-control" formControlName="username">
              </div>
              <div class="mb-3">
                <label class="form-label" for="email-input">Or your email</label>
                <input id="email-input" type="text" class="form-control" formControlName="email">
              </div>
              <cs-error-message [message]="errorMessage"></cs-error-message>
              <button id="reset-password-submit" type="submit" class="btn btn-smart btn-primary" [disabled]="submitButton.isSubmitting">{{submitButton.text}}</button>
            </form>
          </div>
        </div>
      }
      @if (submitted) {
        <div id="success-message">
          <p class="text-success">Please check your inbox.</p>
          <p>You should receive an email from us containing a link to reset your password.</p>
        </div>
      }
    </div>
  </div>
</div>

import { AmbientLight, DirectionalLight, Scene } from 'three';

/**
 * A simple lighting renderer which we use in all our scenes.
 */
export class SimpleLightingRenderer {

  /**
   * Initializes a new instance of the SimpleLightingRenderer class.
   * @param scene The scene to add the lighting.
   */
  constructor(
    private readonly scene: Scene) {
  }

  /**
   * Builds the lighting.
   */
  public build() {

    let ambientLight = new AmbientLight(0xd0d0d0, Math.PI);
    this.scene.add(ambientLight);

    let directionalLight = new DirectionalLight(0x777777, Math.PI);
    directionalLight.position.set(100, 100, -100).normalize();
    this.scene.add(directionalLight);

    let directionalLight2 = new DirectionalLight(0x555555, Math.PI);
    directionalLight2.position.set(-100, 100, 100).normalize();
    this.scene.add(directionalLight2);

    //let helper = new DirectionalLightHelper(directionalLight, 5);
    //this.scene.add(helper);
  }

  /**
   * Animates the lighting.
   */
  public animate() {
  }
}

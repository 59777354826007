import { Component } from '@angular/core';
import {WorksheetDock} from '../worksheet-dock/worksheet-dock.service';
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';

/**
 * If a user navigates to a worksheet which is currently docked, this component is
 * displayed which shows a button to allow the user to undock the worksheet.
 */
@Component({
  templateUrl: './request-undock-worksheet.page.html',
  styleUrls: ['./request-undock-worksheet.page.scss']
})
export class RequestUndockWorksheetPage {

  /**
   * The error message to display if an error occurs.
   */
  public errorMessage: string;

  /**
   * Creates an instance of RequestUndockWorksheetPage.
   * @param dock The worksheet dock service.
   * @param getFriendlyErrorAndLog The service to get a friendly error message and log the error.
   */
  constructor(
    private readonly dock: WorksheetDock,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  /**
   * Handles the requests to undock the worksheet.
   */
  public requestUndock() {
    try {
      this.dock.requestUndock();
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }
}

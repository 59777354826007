import {UrlFileLoader} from '../../visualizations/url-file-loader';
import {Injectable} from '@angular/core';
import {DialogManager, IDialog, DialogBase} from '../../common/dialogs/dialog-manager.service';
import {SimType} from '../../../generated/api-stubs';
import {ChannelNameStyle} from '../../visualizations/viewers/channel-data-loaders/channel-name-style';

@Injectable()
export class ChartEditorDialog {
  constructor(
    private dialogManager: DialogManager
  ){}

  public async editChart(
    flattenView: boolean,
    channelSource: ChartEditorChannelSource,
    data: any,
    studyId: string,
    jobIndex: number,
    simTypes: SimType[],
    channelNameStyle: ChannelNameStyle,
    fileLoader: UrlFileLoader): Promise<any> {

    let result = await this.dialogManager.showSecondaryDialog(
      new ChartEditorDialogData(
        flattenView,
        channelSource,
        data,
        studyId,
        jobIndex,
        simTypes,
        channelNameStyle,
        fileLoader));

    return result;
  }
}

export class ChartEditorDialogData extends DialogBase<any> implements IDialog {
  constructor(
    private _flattenView: boolean,
    private _channelSource: ChartEditorChannelSource,
    private _data: any,
    private _studyId: string,
    private _jobIndex: number,
    private _simTypes: SimType[],
    private _channelNameStyle: ChannelNameStyle,
    private _fileLoader: UrlFileLoader) {
    super();
  }

  public get flattenView(): boolean {
 return this._flattenView;
}
  public get channelSource(): ChartEditorChannelSource {
 return this._channelSource;
}
  public get data(): any {
 return this._data;
}
  public get studyId(): string {
 return this._studyId;
}
  public get jobIndex(): number {
 return this._jobIndex;
}
  public get simTypes(): SimType[] {
 return this._simTypes;
}
  public get channelNameStyle(): ChannelNameStyle {
 return this._channelNameStyle;
}
  public get fileLoader(): UrlFileLoader {
 return this._fileLoader;
}
}

export enum ChartEditorChannelSource {
  ScalarResults,
  VectorResults
}

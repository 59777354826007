<div class="canopy-nav">
  <div class="container-fluid">
    <nav class="navbar navbar-expand-sm navbar-light bg-faded">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-start" id="navbarSupportedContent">
        <div class="navbar-nav">
          <a id="nav-logo-link" class="navbar-brand" [routerLink]="['/']">
            <cs-logo></cs-logo>
          </a>
        </div>
        <ul class="navbar-nav navbar-right ms-auto">
          <cs-theme-switcher class="nav-item mt-auto mb-auto"></cs-theme-switcher>
          @if (!isAuthenticated) {
            <li class="nav-item">
              <a id="nav-sign-in-link" class="nav-link" href="javascript:void(0)" (click)="authenticationService.login().toPromise()">Sign In</a>
            </li>
          }
          @if (isAuthenticated) {
            @if (!currentUserState) {
              <li class="nav-item">
                <cs-loader></cs-loader>
              </li>
            }
            @if (isAdministrator()) {
              <li class="nav-item">
                <a id="nav-admin-link" class="nav-link" [routerLink]="['/admin']">Admin</a>
              </li>
            }
            <li class="nav-item" title="Documentation">
              <a id="nav-wiki-link" class="nav-link" href="https://support.canopysimulations.com/"><i class="fa fa-book"></i></a>
            </li>
            <li class="nav-item" title="Worksheets">
              <a id="nav-worksheets-link" class="nav-link" [routerLink]="['/worksheets']"><i class="fa fa-th"></i></a>
            </li>
            <li class="nav-item" title="Home">
              <a id="nav-home-link" class="nav-link" [routerLink]="['/']"><i class="fa fa-home"></i></a>
            </li>
            @if (currentUserState) {
              <li class="nav-item">
                <a id="nav-account-link" class="nav-link" [routerLink]="['/account-settings']"><i class="fa fa-user"></i>{{currentUserState?.username}}</a>
              </li>
            }
            @if (currentUserState) {
              <li class="nav-item">
                <a id="nav-tenant-link" class="nav-link" [routerLink]="['/tenants']"><i class="fa fa-users"></i>{{currentUserState?.tenant.shortName}}</a>
              </li>
            }
            <li class="nav-item" title="Sign Out">
              <a id="nav-sign-out-link" class="nav-link" href="javascript:void(0)" (click)="signOut.execute()"><i class="fa fa-sign-out"></i></a>
            </li>
          }
        </ul>
      </div>
    </nav>
  </div>
  <cs-error-message [message]="errorMessage"></cs-error-message>
</div>


/**
 * What to display if the user id could not be resolved.
 */
export const UNKNOWN_USER: string = 'Unknown User';

/**
 * What to display if the tenant id could not be resolved.
 */
export const UNKNOWN_STUDY_TYPE: string = 'Unknown Study';

/**
 * What to display if the sim type could not be resolved.
 */
export const UNKNOWN_SIM_TYPE: string = 'Unknown Simulation';

/**
 * The minimum number of rows in a worksheet.
 */
export const MINIMUM_ROWS: number = 3;

/**
 * The string representing scalar results for worksheet labels.
 */
export const SCALAR_RESULTS_LABEL_SOURCE = 'scalarResults';

/**
 * The string representing config for worksheet labels.
 */
export const CONFIG_LABEL_SOURCE = 'config';

// https://github.com/sindresorhus/strip-json-comments

enum commentType {
  none,
  singleComment,
  multiComment
}

export function removeJsonComments(str: string) {
  let currentChar;
  let nextChar;
  let insideString = false;
  let insideComment = commentType.none;
  let offset = 0;
  let ret = '';

  for (let i = 0; i < str.length; i++) {
    currentChar = str[i];
    nextChar = str[i + 1];

    if (insideComment === commentType.none && currentChar === '"') {
      let escaped = str[i - 1] === '\\' && str[i - 2] !== '\\';
      if (!escaped) {
        insideString = !insideString;
      }
    }

    if (insideString) {
      continue;
    }

    if (insideComment === commentType.none && currentChar + nextChar === '//') {
      ret += str.slice(offset, i);
      offset = i;
      insideComment = commentType.singleComment;
      i++;
    } else if (insideComment === commentType.singleComment && currentChar + nextChar === '\r\n') {
      i++;
      insideComment = commentType.none;
      offset = i;
      continue;
    } else if (insideComment === commentType.singleComment && currentChar === '\n') {
      insideComment = commentType.none;
      offset = i;
    } else if (!insideComment && currentChar + nextChar === '/*') {
      ret += str.slice(offset, i);
      offset = i;
      insideComment = commentType.multiComment;
      i++;
      continue;
    } else if (insideComment === commentType.multiComment && currentChar + nextChar === '*/') {
      i++;
      insideComment = commentType.none;
      offset = i + 1;
      continue;
    }
  }

  return ret + (insideComment !== commentType.none ? '' : str.substr(offset));
}

@if (isVisible) {
  <div class="modal dialog-container" id="edit-document-metadata-dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title">Edit Metadata</h1>
          <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()">
          </button>
        </div>
        <div class="modal-body">
          <cs-error-message [message]="session?.errorMessage"></cs-error-message>
          <cs-edit-document-metadata
            [tenantId]="session.dialog.tenantId"
            [target]="session.dialog.target"
            [initialDocumentMetadata]="session.dialog.initialDocumentMetadata"
          (metadataChanged)="session.onMetadataChanged($event)"></cs-edit-document-metadata>
        </div>
        <!--div class="modal-footer">
        <cs-error-message [message]="session?.errorMessage"></cs-error-message>
        <button id="dialog-dismiss-button" type="button" class="btn btn-secondary" (click)="dismiss()">Cancel</button>
        <button id="dialog-save-button" type="button" class="btn btn-primary" [disabled]="!session?.combined" (click)="session.save()">Save</button>
      </div-->
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div>
}<!-- /.modal -->

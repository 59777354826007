import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';


@Injectable()
export class SetAutocompleteSanitizer {

  // If a string only contains these characters, we know it does not require sanitizing.
  private readonly safeStringTest = new RegExp('^[\\w\\.\\[\\] -]*$');

  constructor(
    private readonly sanitizer: DomSanitizer) {
  }

  public execute(options: any) {
    let originalDataDelegate = options.data;

    options.data = (item: string | any, input: string) => {
      if (originalDataDelegate) {
        item = originalDataDelegate(item, input);
      }

      if (item.label || item.value) {
        return { label: this.sanitize(item.label), value: item.value };
      } else if (typeof item === 'string') {
        return { label: this.sanitize(item), value: item };
      } else if (Array.isArray(item) && item.length === 2) {
        return [ this.sanitize(item[0]), item[1] ];
      }

      console.log('Invalid autocomplete item: ' + item);
      return undefined;
    };
  }

  private sanitize(value: string) {
    // Do a quick test first.
    if (this.isSafeString(value)) {
      return value;
    }

    // Only run the sanitizer for potentially unsafe strings, because it is an expensive function.
    return this.sanitizer.sanitize(SecurityContext.HTML, value);
  }

  private isSafeString(value: string) {
    return this.safeStringTest.test(value);
  }
}

import { Injectable } from '@angular/core';
import { isArray } from './is-array';
import { isPrimitiveArray } from './is-primitive-array';
import { JsonEditorCustomization } from './json-editor-customization';
import{ JSONEditor } from'@json-editor/json-editor'

@Injectable()
export class ArrayValidator extends JsonEditorCustomization {
  public apply(): void {
    JSONEditor.defaults.custom_validators.push(function(schema: any, value: any, path: string) {
      let errors: any[] = [];

      let testValue = function(item: any) {
        if (item === undefined || item === null || Number.isNaN(item)) {
          errors.push({
            path,
            property: 'defined',
            message: 'All elements must have a value'
          });
        }
      };

      if (value && isArray(schema) && isPrimitiveArray(schema.items.type)) {
        if (!Array.isArray(value)) {
          errors.push({
            path,
            property: 'enumerable',
            message: 'Value was not an array'
          });
        } else {
          for (let item of value) {
            testValue(item);
          }
        }
      }
      return errors;
    });
  }
}

import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ErrorHandlingResolve } from '../../common/errors/error-handling-resolve';
import { InferableRouteParams } from '../../common/inferable-route-params.service';
import { ErrorMessageService } from '../../common/errors/services/error-message/error-message.service';
import { ConfigPermissionsService } from './services/config-permissions.service';


@Injectable()
export class ConfigPermissionResolve extends ErrorHandlingResolve<void> {
  constructor(
    private _router: Router,
    private _errorMessageService: ErrorMessageService,
    private _inferableRouteParams: InferableRouteParams,
    private _configPermissionService: ConfigPermissionsService,
  ) {
    super(_router, _errorMessageService);
  }

  async resolveInner(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<void> {
    const tenantId = this._inferableRouteParams.getTenantId(route);
    try {
      await this._configPermissionService.fetchConfigPermissions(tenantId).toPromise();

      let permissions = this._configPermissionService.permissionsSnapshot;

      let selectedPermission = permissions.find((value) => value.encryptingTenantShortName === route.params.encryptingTenantShortName
        && value.decryptingTenantShortName === route.params.decryptingTenantShortName);

      if (!selectedPermission) {
        throw new Error('The requested Config Permission could not be found.');
      }

      this._configPermissionService.setConfigPermission(
        selectedPermission.encryptingTenantShortName,
        selectedPermission.decryptingTenantShortName,
        selectedPermission.minimumSimVersion,
        selectedPermission.description
      );
    } catch (error) {
      this._errorMessageService.setErrorMessage(error);
      this._router.navigate(['/config-permissions', tenantId]);
    }
  }
}

import {DocumentSubType, GetConfigQueryResult} from '../../generated/api-stubs';
import {CustomProperty} from '../simulations/custom-properties/custom-properties';
import {CustomPropertyUtilities} from '../simulations/custom-properties/custom-property-utilities';

/**
 * Represents a config input to a study, and all of its metadata.
 */
export class StudyInput {

  /**
   * Creates an instance of StudyInput.
   * @param configType The config type.
   * @param userId The user ID.
   * @param configId The config ID.
   * @param name The name of the config.
   * @param data The config data.
   * @param properties The custom properties of the config.
   * @param notes The notes of the config.
   * @param simVersion The config sim version to which the data conforms.
   * @param isEdited Whether the config has been edited from the version given by the config id.
   * @param timestamp The timestamp of the config.
   */
  constructor(
    public readonly configType: DocumentSubType,
    public readonly userId: string,
    public readonly configId: string,
    public readonly name: string,
    public readonly data: any,
    public readonly properties: ReadonlyArray<InputCustomProperty>,
    public readonly notes: string,
    public readonly simVersion: string,
    public readonly isEdited: boolean,
    public readonly timestamp: string) {
    this.properties = [...(this.properties || [])];
  }

  /**
   * Creates a new StudyInput for testing purposes.
   * @param seed The seed for the study input to use for randomly generated data.
   * @returns A study input.
   */
  public static any(seed: number = 0): StudyInput {
    return new StudyInput(
      DocumentSubType.automatedTest,
      'user-id-' + seed,
      'config-id-' + seed,
      'name-' + seed,
      { seed },
      [
        new InputCustomProperty('property-name-' + seed, 'property-value-' + seed),
      ],
      'notes-' + seed,
      'sim-version-' + seed,
      false,
      undefined);
  }

  /**
   * Creates a new StudyInput from a mutable study input.
   * @param mutable The mutable study input.
   * @returns A study input.
   */
  public static fromMutable(mutable: IMutableStudyInput) {
    if(!mutable){
      return undefined;
    }

    return new StudyInput(
      mutable.configType,
      mutable.userId,
      mutable.configId,
      mutable.name,
      mutable.data,
      InputCustomProperty.fromMutables(mutable.properties),
      mutable.notes,
      mutable.simVersion,
      mutable.isEdited,
      mutable.timestamp);
  }

  /**
   * Creates a new StudyInput from an API result.
   * @param configResult The API result.
   * @returns A study input.
   */
  public static fromApiResult(configResult: GetConfigQueryResult) {
    if(!configResult){
      return undefined;
    }

    return new StudyInput(
      configResult.config.subType,
      configResult.config.userId,
      configResult.config.documentId,
      configResult.config.name,
      configResult.config.data,
      InputCustomProperty.fromMutables(CustomPropertyUtilities.objectToList(configResult.config.properties)),
      configResult.config.notes,
      configResult.convertedSimVersion,
      false,
      undefined);
  }

  /**
   * Converts a study input to a mutable study input.
   * @param value The study input.
   * @returns The mutable study input.
   */
  public static toMutable(value: StudyInput): IMutableStudyInput {
    if(!value){
      return undefined;
    }

    return {
      configType: value.configType,
      userId: value.userId,
      configId: value.configId,
      name: value.name,
      data: value.data,
      properties: InputCustomProperty.toMutables(value.properties),
      notes: value.notes,
      simVersion: value.simVersion,
      isEdited: value.isEdited,
      timestamp: value.timestamp};
  }

  // Returns a new StudyInput with the given data.
  public withData(data: any, isEdited?: boolean): StudyInput {
    let mutable = StudyInput.toMutable(this);
    mutable.data = data;
    if(isEdited !== undefined){
      mutable.isEdited = isEdited;
    }
    return StudyInput.fromMutable(mutable);
  }
}

/**
 * Represents a custom property of a config study input.
 */
export class InputCustomProperty {
  constructor(
    public readonly name: string,
    public readonly value: string) {
  }

  /**
   * Creates a InputCustomProperty from a mutable custom property.
   * @param mutable The mutable custom property.
   * @returns An InputCustomProperty.
   */
  public static fromMutable(mutable: CustomProperty) {
    if(!mutable){
      return undefined;
    }

    return new InputCustomProperty(
      mutable.name,
      mutable.value);
  }

  /**
   * Creates a mutable custom property from an InputCustomProperty.
   * @param value The InputCustomProperty.
   * @returns A mutable custom property.
   */
  public static toMutable(value: InputCustomProperty): CustomProperty {
    if(!value){
      return undefined;
    }

    return {
      name: value.name,
      value: value.value
    };
  }

  /**
   * Creates an array of InputCustomProperty from an array of mutable custom properties.
   * @param mutable The mutable custom properties.
   * @returns An array of InputCustomProperty.
   */
  public static fromMutables(mutable: ReadonlyArray<CustomProperty>): ReadonlyArray<InputCustomProperty> {
    return (mutable || []).map(InputCustomProperty.fromMutable);
  }

  /**
   * Creates an array of mutable custom properties from an array of InputCustomProperty.
   * @param value The InputCustomProperty.
   * @returns An array of mutable custom properties.
   */
  public static toMutables(value: ReadonlyArray<InputCustomProperty>): CustomProperty[] {
    return value.map(InputCustomProperty.toMutable);
  }
}

/**
 * Represents a mutable custom property of a config study input.
 */
export interface IMutableStudyInput {
  configType: DocumentSubType;
  userId: string;
  configId: string;
  name: string;
  data: any;
  properties: CustomProperty[];
  notes: string;
  simVersion: string;
  isEdited: boolean;
  timestamp: string;
}

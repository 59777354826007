import {Filter} from '../../../visualizations/navigation-station/config-builders/car-config-builder';
import {Injectable} from '@angular/core';

@Injectable()
export class GetVerifyCarVisualizationTabs {
  public execute(): CarVisualizationTab[]{
    return [
      {
        name: 'Powertrain',
        filter: Filter.powertrain,
        perJob: false,
      },
      {
        name: 'Tyres',
        filter: Filter.tyreSlip,
        perJob: false,
      },
      {
        name: 'Kinematics',
        filter: Filter.suspensionKinematics,
        perJob: false,
      }
    ];
  }
}

export interface CarVisualizationTab {
  name: string;
  filter: Filter;
  perJob: boolean;
}

<div class="container-fluid">
  <div class="card">
    <h1 class="card-header">
      Register
      @if (tenantName) {
        <small class="text-body-secondary">{{tenantName}}</small>
      }
    </h1>

    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-6">
          <form (ngSubmit)="onSubmit()" [formGroup]="form">
            <div class="mb-3">
              <label class="form-label" for="email-input">Email</label>
              <input id="email-input" type="text" class="form-control" formControlName="email">
              @if (!email.pristine && email.hasError('required')) {
                <p class="text-danger">
                  Email is required
                </p>
              }
              @if (email.hasError('minlength')) {
                <p class="text-danger">
                  Email is too short.
                </p>
              }
              @if (email.hasError('maxlength')) {
                <p class="text-danger">
                  Email is too long.
                </p>
              }
              @if (email.hasError('email')) {
                <p class="text-danger">
                  Email must be valid.
                </p>
              }
            </div>
            <div class="mb-3">
              <label class="form-label" for="username-input">Username</label>
              <input id="username-input" type="text" class="form-control" formControlName="username">
              @if (!username.pristine && username.hasError('required')) {
                <p class="text-danger">
                  Username is required
                </p>
              }
              @if (username.hasError('minlength')) {
                <p class="text-danger">
                  Username is too short.
                </p>
              }
              @if (username.hasError('maxlength')) {
                <p class="text-danger">
                  Username is too long.
                </p>
              }
            </div>
            <div class="mb-3">
              <label class="form-label" for="password-input">Password</label>
              <input id="password-input" type="password" class="form-control" formControlName="password">
              @if (!password.pristine && password.hasError('required')) {
                <p class="text-danger">
                  Password is required
                </p>
              }
              @if (password.hasError('minlength')) {
                <p class="text-danger">
                  Password is too short.
                </p>
              }
              @if (password.hasError('maxlength')) {
                <p class="text-danger">
                  Password is too long.
                </p>
              }
            </div>
            <cs-error-message [message]="errorMessage"></cs-error-message>
            <button id="register-submit" type="submit" class="btn btn-smart btn-primary" [disabled]="submitButton.isSubmitting">{{submitButton.text}}</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

export function createRegularExpressionFromWildcardString(input: string, ignoreCase: boolean, enforceStartOfString: boolean = false) {
  let pattern = input
      .replace(/\*/g, '.*')
      .replace(/\?/g, '.');

  if(enforceStartOfString){
    pattern = '^' + pattern;
  }
  return new RegExp(
    pattern,
    ignoreCase ? 'i' : '');
}

export function isWildcardString(input: string): boolean {
  return input.indexOf('*') !== -1
    || input.indexOf('?') !== -1;
}

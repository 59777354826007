import { Injectable, EventEmitter } from '@angular/core';
import { ChartRepository, ChartRepositoryFactory } from './chart-repository.service';
import { ChartEditorChannelSource, ChartEditorDialog } from './chart-editor-dialog.service';
import { CanopyFileLoader } from './canopy-file-loader.service';
import { SiteHooks, IEditChannelsOptions, PaneChannelLayout, LoadViewerLayoutResult, ViewerMetadata, RequestedLayoutIds } from '../../visualizations/site-hooks';
import { ChannelNameStyle } from '../../visualizations/viewers/channel-data-loaders/channel-name-style';
import {
  DocumentSubType,
  SimType
} from '../../../generated/api-stubs';
import { GetFriendlyErrorAndLog } from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import { Timer } from '../../common/timer.service';
import { UnitsManager } from '../../units/units-manager.service';
import { SetChannelUnitsDialog } from '../../units/set-channel-units-dialog.service';
import { Subscription } from 'rxjs';
import { GetPreferredChart } from './get-preferred-chart.service';
import { SetPreferredChart } from './set-preferred-chart.service';
import { ViewJobDialog } from '../jobs/view-job-dialog/view-job-dialog.service';
import { SimVersionDocumentCache } from '../sim-version-document-cache.service';
import { ChannelEditorDialog } from './channel-editor-dialog.service';
import { getJobIdFromJobIndex } from '../../common/get-job-id-from-job-index';
import { LineMouseEvent } from '../../visualizations/viewers/parallel-coordinates-viewer/parallel-coordinates-types';
import { ResultSource, ResultsStagingArea } from '../results-staging-area/results-staging-area.service';
import { StudyInput } from '../../worksheets/study-input';
import { LocalUserChartSettingsManager } from '../../common/local-user-chart-settings-manager';
import { AuthenticationService } from '../../identity/state/authentication.service';

@Injectable()
export class CanopySiteHooksFactory {
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly timer: Timer,
    private readonly chartRepositoryFactory: ChartRepositoryFactory,
    private readonly chartEditorDialog: ChartEditorDialog,
    private readonly channelEditorDialog: ChannelEditorDialog,
    private readonly unitsManager: UnitsManager,
    private readonly setChannelUnitsDialog: SetChannelUnitsDialog,
    private readonly getPreferredChart: GetPreferredChart,
    private readonly setPreferredChart: SetPreferredChart,
    private readonly viewJobDialog: ViewJobDialog,
    private readonly simVersionDocumentCache: SimVersionDocumentCache,
    private readonly resultsStagingArea: ResultsStagingArea,
    private readonly localUserChartSettingsManager: LocalUserChartSettingsManager,
    private readonly getFriendlyErrorAndLogService: GetFriendlyErrorAndLog) {
  }

  public create(
    fileLoader: CanopyFileLoader) {
    const userData = this.authenticationService.userDataSnapshot;

    return new CanopySiteHooks(
      userData.tenant,
      userData.sub,
      fileLoader,
      this.timer,
      this.chartRepositoryFactory,
      this.chartEditorDialog,
      this.channelEditorDialog,
      this.unitsManager,
      this.setChannelUnitsDialog,
      this.getPreferredChart,
      this.setPreferredChart,
      this.viewJobDialog,
      this.simVersionDocumentCache,
      this.resultsStagingArea,
      this.localUserChartSettingsManager,
      this.getFriendlyErrorAndLogService);
  }
}

interface StudyData {
  tenantId: string;
  studyId: string;
  name: string;
}

export class CanopySiteHooks implements SiteHooks {

  public unitsChangedEvent: EventEmitter<any> = new EventEmitter<any>();

  private chartRepository: ChartRepository;
  private simVersionChartCache: { [key: string]: Promise<StudyInput> } = {};
  private unitsChangedSubscription: Subscription;

  private studyCache: { [studyId: string]: StudyData } = {};
  private studyJobNameCache: { [jobId: string]: string } = {};

  constructor(
    private readonly tenantId: string,
    private readonly userId: string,
    private readonly fileLoader: CanopyFileLoader,
    private readonly timer: Timer,
    private readonly chartRepositoryFactory: ChartRepositoryFactory,
    private readonly chartEditorDialog: ChartEditorDialog,
    private readonly channelEditorDialog: ChannelEditorDialog,
    private readonly unitsManager: UnitsManager,
    private readonly setChannelUnitsDialog: SetChannelUnitsDialog,
    private readonly getPreferredChartService: GetPreferredChart,
    private readonly setPreferredChartService: SetPreferredChart,
    private readonly viewJobDialog: ViewJobDialog,
    private readonly simVersionDocumentCache: SimVersionDocumentCache,
    private readonly resultsStagingArea: ResultsStagingArea,
    private readonly localUserChartSettingsManager: LocalUserChartSettingsManager,
    private readonly getFriendlyErrorAndLogService: GetFriendlyErrorAndLog) {

    this.chartRepository = this.chartRepositoryFactory.create(tenantId);
    this.unitsChangedSubscription = this.unitsManager.changed.subscribe(() => this.unitsChangedEvent.emit(undefined));
  }

  public dispose() {
    this.unitsChangedSubscription.unsubscribe();
  }

  public addStudy(
    tenantId: string,
    studyId: string,
    name: string) {
    this.studyCache[studyId] = {
      tenantId,
      studyId,
      name
    };
  }

  public addJobName(jobId: string, name: string) {
    this.studyJobNameCache[jobId] = name;
  }

  public async saveViewerLayoutAs(viewerType: DocumentSubType, config: any): Promise<SavedChartResult> {

    return this.chartRepository.saveChartAs(viewerType, config);
  }

  public async canSaveViewerLayout(viewerType: string, layoutId: UserChartId): Promise<boolean> {
    return layoutId && layoutId.userId && this.userId === layoutId.userId;
  }

  public saveViewerLayout(viewerType: DocumentSubType, layoutId: UserChartId, name: string, config: any): Promise<any> {
    return this.chartRepository.saveChart(viewerType, layoutId.configId, name, config);
  }

  public async saveViewerMetadata(viewerType: DocumentSubType, layoutId: UserChartId, viewerMetadata: ViewerMetadata): Promise<any> {
    this.localUserChartSettingsManager.setChartSettings(viewerType, layoutId, viewerMetadata.size);
  }

  public async loadViewerLayout(viewerType: DocumentSubType, layoutId?: ChartId): Promise<LoadViewerLayoutResult> {

    const studyInput = await this.loadViewerLayoutConfig(viewerType, layoutId);
    return this.convertConfigLoaderResultToLoadedChartResult(studyInput);
  }

  public async loadViewerMetadata(viewerType: DocumentSubType, layoutId: UserChartId): Promise<ViewerMetadata | undefined> {
    const chartSettings = this.localUserChartSettingsManager.getChartSettings(viewerType, layoutId);
    return chartSettings ? new ViewerMetadata(chartSettings.size) : undefined;
  }

  private loadViewerLayoutConfig(viewerType: DocumentSubType, layoutId?: ChartId): Promise<StudyInput> {

    let simVersionChartId = <SimVersionChartId>layoutId;
    let task;
    if (simVersionChartId && typeof simVersionChartId === 'string') {
      let key = `${viewerType}-${layoutId}`;
      task = this.simVersionChartCache[key];
      if (!task) {
        task = this.chartRepository.loadChart(viewerType, layoutId);
        this.simVersionChartCache[key] = task;
      }

      return task;
    }

    return this.chartRepository.loadChart(viewerType, layoutId);
  }

  private convertConfigLoaderResultToLoadedChartResult(result: StudyInput): LoadViewerLayoutResult {
    if(!result){
      return undefined;
    }

    let viewerId: ChartId = result.userId
      ? {
        userId: result.userId,
        configId: result.configId
      }
      : result.name;

    return new LoadViewerLayoutResult(viewerId, result.name, result.data);
  }

  public async editViewerDeprecated(viewerType: string, layout: any, studyId: string, jobIndex: number, simTypes: SimType[], channelNameStyle: ChannelNameStyle): Promise<any> {
    let adjustedLayout = layout;

    let flattenView = false;
    let channelSource = ChartEditorChannelSource.ScalarResults;

    if (viewerType === DocumentSubType.parallelCoordinatesViewer) {
      flattenView = true;
    } else if (viewerType === DocumentSubType.pointMultiPlotViewer) {
      channelSource = ChartEditorChannelSource.VectorResults;
    }

    let result;
    switch (viewerType) {
      case DocumentSubType.lineMultiPlotViewer:
      case DocumentSubType.pointMultiPlotViewer:
      case DocumentSubType.parallelCoordinatesViewer:
        result = await this.chartEditorDialog.editChart(flattenView, channelSource, adjustedLayout, studyId, jobIndex, simTypes, channelNameStyle, this.fileLoader);
        break;
    }

    await this.timer.yield(); // Allow the dialog to close before rendering starts.
    return result;
  }

  public async editViewerChannels(options: IEditChannelsOptions): Promise<PaneChannelLayout> {
    let result = await this.channelEditorDialog.editChart(options);
    await this.timer.yield(); // Allow the dialog to close before rendering starts.
    return result;
  }

  public async getDefaultChannelUnits(channelName: string): Promise<string> {
    let simVersionDocuments = await this.simVersionDocumentCache.get(this.simVersionDocumentCache.lastRequestedVersion);

    let result = simVersionDocuments.units[channelName];

    return result || '()';
  }

  public getUserChannelUnits(channelName: string): Promise<string> {
    return this.unitsManager.getUnits(channelName, undefined);
  }

  public getUserChannelUnitsSynchronous(channelName: string): string {
    return this.unitsManager.getUnitsSynchronous(channelName, undefined);
  }

  public ensureUnitsLoaded() {
    return this.unitsManager.ensureInitialized();
  }

  public editChannelUnits(channelName: string, currentUnits: string): Promise<any> {
    return this.setChannelUnitsDialog.show(channelName, currentUnits);
  }

  public getViewerDefaultLayoutId(viewerType: DocumentSubType, layoutIds: RequestedLayoutIds): Promise<ChartId> {
    return this.getPreferredChartService.execute(viewerType, layoutIds);
  }

  public setViewerDefaultLayoutId(viewerType: DocumentSubType, layoutIds: RequestedLayoutIds, defaultLayoutId: ChartId): Promise<any> {
    return this.setPreferredChartService.execute(this.tenantId, this.userId, viewerType, layoutIds, defaultLayoutId);
  }

  public getStudyName(studyId: string): Promise<string> {
    let studyData = this.studyCache[studyId];
    let name = studyData ? studyData.name : 'Unnamed Study';
    return Promise.resolve(name);
  }

  public async getStudyJobName(studyId: string, jobIndex: number): Promise<string> {
    let studyName = await this.getStudyName(studyId);
    return studyName + ' / ' + (this.studyJobNameCache[getJobIdFromJobIndex(studyId, jobIndex)] || 'Unnamed');
  }

  public showStudyJob(studyId: string, jobIndex: number, currentEvent: LineMouseEvent): Promise<any> {
    let event = currentEvent as LineMouseEvent;
    let mouseEvent = event.sourceEvent;
    let studyData = this.studyCache[studyId];
    if(!!(mouseEvent.ctrlKey || mouseEvent.metaKey)){
      let jobId = this.getJobId(studyId, jobIndex);
      let jobName = this.studyJobNameCache[jobId] || `Job Index ${event.lineIndex}`;
      this.resultsStagingArea.addSource(
        new ResultSource(studyData.tenantId, studyId, studyData.name, jobId, jobName));
      return Promise.resolve();
    } else {
      return this.viewJobDialog.show(
        studyData.tenantId,
        studyId,
        this.getJobId(studyId, jobIndex));
    }
  }

  public getFriendlyErrorAndLog(error: any): string {
    return this.getFriendlyErrorAndLogService.execute(error);
  }

  private getJobId(studyId: string, jobIndex: number): string {
    return `${studyId}-${jobIndex}`;
  }
}

export interface UserChartId {
  userId: string;
  configId: string;
}

export type SimVersionChartId = string;

export type ChartId = UserChartId | SimVersionChartId;

export interface SavedChartResult {
  viewerId: ChartId;
  name: string;
}

export interface LoadedChartResult {
  viewerId: ChartId;
  name: string;
  config: any;
}

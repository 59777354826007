@if (isVisible) {
  <div class="modal dialog-container" id="import-telemetry-dialog">
    <div class="modal-dialog modal-dialog-wide" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title">
            Import Telemetry
          </h1>
          <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()">
          </button>
        </div>
        <div class="modal-body">
          <cs-error-message [message]="errorMessage || session.errorMessage"></cs-error-message>
          @if (!session) {
            <cs-loader></cs-loader>
          }
          @if (session) {
            @switch (session.stage) {
              @case (session.ImportTelemetryStage.selectFile) {
                <div  class="row">
                  <div class="col-12">
                    <p>
                      You can import from JSON, CSV or TSV files.
                    </p>
                    <p>
                      <span id="import-telemetry-file-button" class="btn btn-smart btn-secondary btn-file"><i class="fa fa-upload"></i>Select Telemetry File<input id="telemetry-file-load-button-input" type="file" (change)="session.processTelemetryFileEvent($event)" accept=".json,.csv,.tsv,.txt"></span>
                    </p>
                  </div>
                </div>
              }
              @case (session.ImportTelemetryStage.convertCsvToJson) {
                <cs-convert-telemetry-csv-to-json
                  [csvFile]="session.csvFile"
                  (telemetryConfigCreated)="session.onTelemetryConfigCreated($event)">
                </cs-convert-telemetry-csv-to-json>
              }
              @case (session.ImportTelemetryStage.validateTelemetry) {
                <cs-validate-telemetry-json
                  [telemetryConfig]="session.telemetryConfig"
                (telemetryConfigValidated)="session.onTelemetryConfigValidated($event)"></cs-validate-telemetry-json>
              }
              @case (session.ImportTelemetryStage.remapTelemetry) {
                <cs-remap-telemetry-json-stage
                  [telemetryConfig]="session.telemetryConfig"
                (telemetryConfigRemapped)="session.onTelemetryConfigRemapped($event)"></cs-remap-telemetry-json-stage>
              }
              @case (session.ImportTelemetryStage.refineTelemetry) {
                <cs-refine-telemetry-json
                  [telemetryConfig]="session.telemetryConfig"
                  [showDifferences]="session.dialog.showDifferences"
                (telemetryConfigProcessed)="session.onTelemetryConfigProcessed($event)"></cs-refine-telemetry-json>
              }
              @case (session.ImportTelemetryStage.submit) {
                <div  class="row">
                  <div class="col-12">
                    <p>Submitting...</p>
                    @if (!errorMessage && !session.errorMessage) {
                      <cs-loader></cs-loader>
                    }
                  </div>
                </div>
              }
            }
          }
        </div>
        <div class="modal-footer">
          <button id="dialog-dismiss-button" type="button" class="btn btn-secondary" (click)="dismiss()">Cancel</button>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div>
  }<!-- /.modal -->

import { Injectable } from '@angular/core';
import { RowMetadataViewModel } from '../../row-metadata-view-model';
import { ICommandContext } from '../worksheet-command';
import { Dayjs } from '../../../common/dayjs.service';
import { ConfigViewModel } from '../../config-view-model';


/**
 * A service for updating the names of the selected rows after importing configs from the staging area.
 */
@Injectable()
export class UpdateRowNames {

  /**
   * Creates an instance of UpdateRowNames.
   * @param dayjs The dayjs service.
   */
  constructor(
    private readonly dayjs: Dayjs) {
  }

  /**
   * Updates the names of the selected rows after importing configs from the staging area.
   * @param context The command context.
   */
  public execute(context: ICommandContext<RowMetadataViewModel | ConfigViewModel>) {
    if (context.isRowMetadata()) {
      const nowString = this.dayjs.toDateTimeUniversal(undefined);
      for (let row of context.selectedRows) {
        if (!row.name) {
          row.name = 'Staged ' + nowString;
        }
      }
    }
  }
}

import {Injectable} from '@angular/core';
import {GetFriendlyErrorAndLog} from '../errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {FormSubmissionButton} from './form-submission-button';
import {FormSubmissionComponent} from './form-submission-component.interface';
import {UntypedFormGroup} from '@angular/forms';

export const FORM_INVALID_ERROR_MESSAGE: string = 'Please complete the form.';

@Injectable()
export class FormSubmissionHandler {
  constructor(private getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  public async execute(
    action: () => Promise<any>,
    form: UntypedFormGroup,
    button: FormSubmissionButton,
    component: FormSubmissionComponent): Promise<boolean> {

    component.hasFormSubmissionBeenAttempted = true;

    if (form && !form.valid) {
      component.errorMessage = FORM_INVALID_ERROR_MESSAGE;
      return false;
    }

    let result = true;
    component.errorMessage = '';
    button.isSubmitting = true;
    try {
      await action.apply(component);
    } catch (error) {
      component.errorMessage = this.getFriendlyErrorAndLog.execute(error);
      result = false;
    }

    button.isSubmitting = false;
    return result;
  }
}


import {Injectable} from '@angular/core';
import {CommandContext, CommandResult, ICommandContext, WorksheetCommand} from './worksheet-command';
import {
  ButtonMenuItem, KeyboardAction,
  MenuItem,
} from '../../context-menu/context-menu-types';
import {RowMetadataViewModel} from '../row-metadata-view-model';
import {PromptDialog} from '../../common/dialogs/prompt-dialog.service';
import { AuthenticationService } from '../../identity/state/authentication.service';

/**
 * A command that renames a row in the worksheet.
 */
@Injectable()
export class RenameRowCommand extends WorksheetCommand {

  /**
   * Creates an instance of RenameRowCommand.
   * @param promptDialog The prompt dialog service.
   * @param authenticationService The authentication service.
   */
  constructor(
    private readonly promptDialog: PromptDialog,
    private readonly authenticationService: AuthenticationService) {
    super();
  }

  /**
   * Determines if the command can be executed.
   * @param context The command context.
   * @returns True if the command can be executed, otherwise false.
   */
  public canExecute(context: CommandContext): context is ICommandContext<RowMetadataViewModel> {
    const userData = this.authenticationService.userDataSnapshot;
    return context.isRowMetadata()
      && context.worksheet.canWrite(userData.sub)
      && context.isSingleRow;
  }

  /**
   * Executes the command.
   * @param context The command context.
   * @returns The command result.
   */
  public async execute(context: CommandContext): Promise<CommandResult> {
    if(!this.canExecute(context)) {
      return CommandResult.NoUpdate;
    }

    const name = await this.promptDialog.show<string>(
      'Enter a name:', 'Row Name', context.target.name);
    if(name === undefined) {
      return CommandResult.NoUpdate;
    }

    context.target.name = name;

    return CommandResult.UpdateOnly;
  }

  /**
   * Creates the menu items for the command.
   * @param context The command context.
   * @param result The menu items.
   */
  public createMenuItems(context: CommandContext, result: MenuItem<CommandResult>[]): void {
    if(!this.canExecute(context)) {
      return;
    }

    result.push(new ButtonMenuItem<CommandResult>(
      `Rename`,
      `set-button`,
      () => this.execute(context),
      'i-cursor',
      KeyboardAction.enter));
  }
}

import {MULTI_JOB_OVERLAY_HIDE_JOBS_NAME} from '../test-area-names';
import {NavigationStation} from '../../navigation-station/navigation-station';
import {JobConfigBuilder} from '../../navigation-station/config-builders/job-config-builder';
import {BootstrapNavigationStationBase} from '../bootstrap-navigation-station-base';
import { UserData } from '../../../identity/state/authentication.service';
import { CanopyFileLoader } from '../../../simulations/visualizations/canopy-file-loader.service';
import { CanopySiteHooks } from '../../../simulations/visualizations/canopy-site-hooks.service';

export class BootstrapMultiJobOverlayHideJobs extends BootstrapNavigationStationBase {
  public get name(){
    return MULTI_JOB_OVERLAY_HIDE_JOBS_NAME;
  }

  public get description(){
    return 'A job viewer overlaying multiple jobs.';
  }

  public createNavigationStation(elementId: string, userData: UserData, fileLoader: CanopyFileLoader, siteHooks: CanopySiteHooks): NavigationStation{

    this.addGeneratedStudy('lap-1', 'DynamicLap', userData, fileLoader);
    this.addGeneratedStudy('lap-2', 'DynamicLap', userData, fileLoader);

    return new NavigationStation(
      elementId,
      new JobConfigBuilder(
        fileLoader,
        siteHooks,
        [
          { studyId: 'lap-1', jobIndex: 38 },
          { studyId: 'lap-2', jobIndex: 65 }
        ],
        ['DynamicLap']));
  }
}

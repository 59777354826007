
/**
 * A readonly size interface.
 */
export interface ISize {

  /**
   * The width.
   */
  readonly width: number;

  /**
   * The height.
   */
  readonly height: number;
}

/**
 * A readonly size class.
 */
export class Size implements ISize {

  /**
   * Creates a new size.
   * @param width The width.
   * @param height The height.
   */
  constructor(
    public readonly width: number,
    public readonly height: number) {
  }
}

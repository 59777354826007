import {Directive, OnInit} from '@angular/core';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {Timer} from '../../../common/timer.service';

@Directive()

export abstract class ImportTelemetryStage implements OnInit {

  public errorMessage: string;

  protected constructor(
    protected readonly timer: Timer,
    protected readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  ngOnInit() {
    this.performRun();
  }


  private async performRun(): Promise<void> {
    try {
      // We yield so that if the stage completes immediately we don't trigger an
      // "Expression was changed after it was checked" warning from Angular about the stage enum.
      await this.timer.yield();

      await this.run();
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public abstract run(): Promise<void>;
}

import { CHANGE_EVENT, OrbitControls } from './orbit-controls';
import {
  IOrbitControlsInstructionsChartSettings,
  OrbitControlsInstructionsRenderer
} from './orbit-controls-instructions-renderer';
import { Mesh, MeshPhongMaterial, PerspectiveCamera, Plane, Ray, Scene, SphereGeometry, Vector3 } from 'three';
import { HTMLDivSelection, SVGSelection } from '../../untyped-selection';
import { CameraMode, CameraState } from './camera-state';
import {
  OPTIONS_BOTTOM_PADDING,
  OPTIONS_LEFT_PADDING,
  ToggleButton,
  ToggleOptionsRenderer
} from '../components/toggle-options-renderer';
import { DisplayableError } from '../../displayable-error';

/**
 * The renderer for the orbit control toggle buttons.
 */
export class OrbitControlsRenderer {

  /**
   * The renderer for the instructions.
   */
  protected instructionsRenderer?: OrbitControlsInstructionsRenderer;

  /**
   * The orbit controls instance.
   */
  protected controls?: OrbitControls;

  /**
   * The camera target sphere. This is the object we rotate around.
   */
  private cameraTargetSphere?: Mesh;

  /**
   * The ray from the camera to the target.
   */
  private cameraRay: Ray = new Ray();

  /**
   * The ground plane.
   */
  private groundPlane = new Plane(new Vector3(0, 1, 0), 0);

  /**
   * The toggle options renderer.
   */
  private toggleOptionsRenderer = new ToggleOptionsRenderer();

  /**
   * The set of toggles for the camera mode (orthographic, perspective).
   */
  private cameraModeToggles: ToggleButton<CameraMode>[] = [];

  /**
   * The set of toggles for the camera position (top, right, etc.).
   */
  private cameraPositionButtons: ToggleButton<number>[] = [];

  /**
   * Create a new instance of OrbitControlsRenderer.
   * @param settings The settings for the chart.
   * @param element The element to attach the controls to.
   * @param svg The SVG element to render to.
   * @param sceneParent The parent of the scene.
   * @param scene The scene.
   * @param cameraState The camera state.
   */
  constructor(
    private readonly settings: IOrbitControlsInstructionsChartSettings,
    private readonly element: HTMLElement,
    private readonly svg: SVGSelection,
    private readonly sceneParent: HTMLDivSelection,
    private readonly scene: Scene,
    private readonly cameraState: CameraState) {
  }

  /**
   * Build the renderer.
   * @param resetPosition The reset position.
   * @param resetTarget The reset target.
   */
  public build(resetPosition: Vector3, resetTarget: Vector3) {

    // Create the instructions renderer.
    this.instructionsRenderer = new OrbitControlsInstructionsRenderer(this.settings);

    // Set the camera state reset position.
    this.cameraState.setResetPostion(resetPosition, resetTarget);

    // Create the orbit controls instance and configure it.
    const controls = this.controls = new OrbitControls(this.cameraState.camera, this.element, undefined, this.scene);
    this.cameraState.modeChanged.subscribe(v => controls.object = this.cameraState.camera);
    this.controls.setResetTarget(resetTarget);
    this.controls.enabled = true;
    this.controls.maxDistance = 9000;
    this.controls.minDistance = 0;
    this.sceneParent.select('canvas').on('dblclick', () => {
      controls.reset();
    });
    this.controls.reset(); // Set the camera target to target0.
    this.controls.update();

    // Create the camera target sphere.
    this.cameraTargetSphere = this.createMarker();

    this.setMarkerSize();

    this.setTarget();

    // Create the camera mode and position buttons.
    this.cameraModeToggles = [
      new ToggleButton<CameraMode>(CameraMode.perspective, 'perspective', (t) => this.onButtonPress(() => this.cameraState.toPerspective()), 60),
      new ToggleButton<CameraMode>(CameraMode.orthographic, 'orthographic', (t) => this.onButtonPress(() => this.cameraState.toOrthographic()), 65),
    ];

    const positionButtonWidth = 40;
    this.cameraPositionButtons = [
      new ToggleButton<number>(NaN, 'top', (t) => this.onButtonPress(() => this.cameraState.toTopView()), positionButtonWidth),
      new ToggleButton<number>(NaN, 'bottom', (t) => this.onButtonPress(() => this.cameraState.toBottomView()), positionButtonWidth),
      new ToggleButton<number>(NaN, 'left', (t) => this.onButtonPress(() => this.cameraState.toLeftView()), positionButtonWidth),
      new ToggleButton<number>(NaN, 'right', (t) => this.onButtonPress(() => this.cameraState.toRightView()), positionButtonWidth),
      new ToggleButton<number>(NaN, 'front', (t) => this.onButtonPress(() => this.cameraState.toFrontView()), positionButtonWidth),
      new ToggleButton<number>(NaN, 'back', (t) => this.onButtonPress(() => this.cameraState.toBackView()), positionButtonWidth),
      new ToggleButton<number>(NaN, 'reset', (t) => this.onButtonPress(() => controls.reset()), positionButtonWidth),
    ];
  }

  /**
   * Handle a button press.
   * @param action The action to take.
   */
  public onButtonPress(action: () => void) {
    action();
    if (this.controls) {
      this.controls.dispatchEvent(CHANGE_EVENT);
    }
  }

  /**
   * Add an event listener.
   * @param type The type of event.
   * @param action The action to take.
   */
  public addEventListener(type: string, action: () => void) {
    if (!this.controls) {
      throw new DisplayableError('Controls were not created when listener was requested.');
    }

    this.controls.addEventListener(type, action);
  }

  /**
   * Render the 2D (SVG) elements.
   */
  public render2d() {
    if (!this.instructionsRenderer) {
      return;
    }

    this.instructionsRenderer.render(this.svg);
    this.renderOptions();
  }

  /**
   * Perform the animation step (moving the camera).
   */
  public animate() {
    if (!this.controls) {
      return;
    }

    this.cameraRay.set(this.cameraState.camera.position, (this.controls.target as Vector3).sub(this.cameraState.camera.position).normalize());
    this.cameraRay.intersectPlane(this.groundPlane, this.controls.target);
    this.setTarget();
    this.controls.update();
  }

  /**
   * Set the target of the camera.
   */
  private setTarget() {
    if (!this.controls || !this.cameraTargetSphere) {
      return;
    }

    this.cameraTargetSphere.position.copy(this.controls.target);

    if(this.cameraState.camera instanceof PerspectiveCamera){
      this.setMarkerSize();
    }

    this.cameraState.setTarget(this.controls.target);
  }

  /**
   * Create a marker.
   * @returns The marker.
   */
  private createMarker(): Mesh {
    let geometry = new SphereGeometry(1, 16, 16);
    let material = new MeshPhongMaterial({ color: 0x888888, flatShading: false, specular: 0x444444 });
    let marker = new Mesh(geometry, material);
    this.scene.add(marker);
    return marker;
  }

  /**
   * Set the size of the marker.
   */
  private setMarkerSize() {
    let targetDistance = new Vector3().subVectors(this.controls.object.position, this.controls.target).length();
    let newScale = targetDistance * 0.005;
    this.cameraTargetSphere.scale.setScalar(newScale);
  }

  /**
   * Render the toggle buttons.
   */
  private renderOptions() {
    this.toggleOptionsRenderer.renderToggleOptions<CameraMode>(
      this.svg,
      'camera-mode',
      OPTIONS_LEFT_PADDING,
      this.settings.svgSize.height - OPTIONS_BOTTOM_PADDING,
      'Mode',
      30,
      this.cameraModeToggles,
      this.cameraState.mode,
      () => this.renderOptions());

    this.toggleOptionsRenderer.renderToggleOptions<number>(
      this.svg,
      'camera-position',
      OPTIONS_LEFT_PADDING + 30 + this.cameraModeToggles.reduce((p, c) => c.width + p, 0) + OPTIONS_LEFT_PADDING,
      this.settings.svgSize.height - OPTIONS_BOTTOM_PADDING,
      'Position',
      40,
      this.cameraPositionButtons,
      NaN,
      () => this.renderOptions());
  }
}

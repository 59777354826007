import { IViewerChannelData } from '../channel-data-loaders/viewer-channel-data';
import { Units } from '../../units';


export class CloneAsSiChannelData {
  public execute(channel: IViewerChannelData): IViewerChannelData {
    return channel.clone(
      channel.data ? Units.convertValuesToSi(channel.data, channel.units) : undefined,
      Units.getSiUnit(channel.units),
      false);
  }
}

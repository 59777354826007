import { Component } from '@angular/core';
import {CanopyNotifications} from '../canopy-notifications.service';

@Component({
  selector: 'cs-notification-enabler',
  templateUrl: './notification-enabler.component.html',
  styleUrls: ['./notification-enabler.component.scss']
})
export class NotificationEnablerComponent {

  constructor(
    private readonly notifications: CanopyNotifications) { }

  public get shouldDisplayEnableNotificationsButton(): boolean {
    return window.Notification && !this.areNotificationsEnabled;
  }

  public get areNotificationsEnabled(): boolean {
    return this.notifications.areNotificationsEnabled;
  }

  public async enableNotifications() {
    if(!this.areNotificationsEnabled){
      await Notification.requestPermission();
    }
  }
}

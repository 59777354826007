
<div class="container-fluid">
  <cs-error-message [message]="errorMessage"></cs-error-message>
</div>

@if (jobs) {
  <cs-view-job-results [jobs]="jobs"></cs-view-job-results>
}

@if (!jobs) {
  <div class="container-fluid">
    <cs-loader id="validation-loader" class="loader-centered"></cs-loader>
  </div>
}

import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {BasicRequestOptions} from '../canopy-http.service';

@Injectable()
export class InterceptorBase {

  public request(requestOptions: BasicRequestOptions): Promise<BasicRequestOptions> {
    return Promise.resolve(requestOptions);
  }

  public response(response: HttpResponse<any>): Promise<HttpResponse<any>> {
    return Promise.resolve(response);
  }

  public responseError(response: HttpResponse<any>, retryCount: number): Promise<boolean> {
    return Promise.resolve(false);
  }
}

<div class="tenant-simulation-quota-view container-fluid">
  <cs-error-message [message]="errorMessage"></cs-error-message>
  @if (!data && !errorMessage) {
    <cs-loader class="loader-centered"></cs-loader>
  }
  @if (data && data.maxSimTypeCount) {
    <div class="row">
      <div class="col-12">
        <h1 class="main-title">Successful Simulations</h1>
      </div>
      @for (month of data.months; track month) {
        <div class="col-12 col-md-6">
          <div class="card">
            <h1 class="card-header">{{month.name}}</h1>
            @for (category of month.studyCategories; track category) {
              <div class="card-body">
              <!--h2>{{category.name}}</h2-->
              <table class="table last-item">
                <thead>
                  <tr>
                    <th>Compute Credits Used</th>
                    <th class="progress-column">
                      <div class="progress-container">
                        <div class="progress progress-hidden">&nbsp;</div>
                        <div class="progress-count">{{category.total.computeCredits | toFixed:3}}</div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <thead>
                  <tr>
                    <th>Simulations Run</th>
                    <th class="progress-column">
                      <div class="progress-container">
                        <div class="progress">
                          <div class="progress-bar" role="progressbar"
                            [ngStyle]="{ width: getBarWidth(category.total.count) }"
                            [attr.aria-valuenow]="category.total.count"
                            aria-valuemin="0"
                          [attr.aria-valuemax]="data.maxSimTypeCount"></div>
                        </div>
                        <div class="progress-count">{{category.total.count}}</div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  @for (studyType of category.studyTypes; track studyType) {
                    <tr>
                      <td>{{studyType.name}}</td>
                      <td class="progress-column">
                        <div class="progress-container">
                          <div class="progress">
                            <div class="progress-bar" role="progressbar"
                              [ngStyle]="{ width: getBarWidth(studyType.count) }"
                              [attr.aria-valuenow]="studyType.count"
                              aria-valuemin="0"
                            [attr.aria-valuemax]="data.maxSimTypeCount"></div>
                          </div>
                          <div class="progress-count">{{studyType.count}}</div>
                        </div>
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          }
        </div>
      </div>
    }
  </div>
}
</div>

@if (data) {
  <cs-tenant-billable-simulation-count [tenantId]="tenantId"></cs-tenant-billable-simulation-count>
}

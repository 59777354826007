
/**
 * The type of exploration that was run.
 */
export enum DesignName {
  monteCarlo = 'Monte Carlo',
  factorial = 'Factorial',
  star = 'Star',
  factorialMonteCarlo = 'Factorial/Monte Carlo',
}

/**
 * The sub-sweep values in the exploration, indexed by job then sweep then sub-sweep.
 */
export type DimensionExplorationMapCoordinates = (number | number[] | string)[][][];

/**
 * The exploration map, as output from the `canopy-api-algorithms` repository.
 */
export interface ExplorationMap {

  /**
   * The type of exploration which was run.
   */
  designName: DesignName;

  /**
   * The sub-sweep names, indexed by sweep then sub-sweep.
   */
  basis: string[][];

  /**
   * The sub-sweep values in the exploration, indexed by job then sweep then sub-sweep.
   */
  coordinates: DimensionExplorationMapCoordinates;

  /**
   * This is unused by the visualizations: see the ProcessedExplorationMap documentation for more information.
   * This is intended to contain the normalized values of the sub-sweeps in the exploration.
   */
  rCoordinates: (number | 'NaN')[][];

  /**
   * The names of the jobs in the exploration.
   */
  jobNames: string[];

  /**
   * When the study is built a set of values are generated for each sub-sweep.
   * The index matrix is the index into that list for the job and sweep.
   * Unfortunately this list is not persisted in the exploration map, but we can
   * reconstruct it using the index matrix and the `coordinates` property, which is
   * what we do in `GetExplorationInputsFromExplorationMap`.
   */
  indexMatrix: (number | 'NaN')[][];
}


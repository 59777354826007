export const CHANNEL_NOT_PRESENT_DESCRIPTION = 'Channel not present.';

export const RESIZE_DEBOUNCE_TIME = 100;
export const RELOAD_DEBOUNCE_TIME = 250;

export const IMPORT_SIM_TYPE = 'Import';
export const IMPORT_CHANNEL_DEFAULT_DESCRIPTION = 'Channel from import.';
export const GENERIC_CHANNEL_DESCRIPTION = 'Channel from any available simulation.';
export const INPUT_DIMENSION_CHANNEL_DESCRIPTION = 'Study input dimension.';
export const NO_CHANNEL_DESCRIPTION = '';

export const SLAP_DOMAIN_NAME = 'sLap';
export const SRUN_DOMAIN_NAME = 'sRun';
export const SLAPCENTRELINE_DOMAIN_NAME = 'sLapCentreLine';
export const TRUN_DOMAIN_NAME = 'tRun';
export const TLAP_DOMAIN_NAME = 'tLap';
export const T_DOMAIN_NAME = 't';
export const TIME_DOMAIN_NAME = 'time';
export const DISTANCE_DOMAIN_NAME = 'distance';
export const INDEX_DOMAIN_NAME = 'index';
export const NLAP_DOMAIN_NAME = 'NLap';

export const KNOWN_DISTANCE_DOMAINS: ReadonlyArray<string> = [
  SLAP_DOMAIN_NAME,
  SRUN_DOMAIN_NAME,
  DISTANCE_DOMAIN_NAME,
];

export const KNOWN_TIME_DOMAINS: ReadonlyArray<string> = [
  TRUN_DOMAIN_NAME,
  TLAP_DOMAIN_NAME,
  T_DOMAIN_NAME,
  TIME_DOMAIN_NAME,
];

export const NO_UNITS = '()';

export const GREEN = 'hsl(120, 100%, 50%)';
export const BLUE = 'hsl(240, 100%, 50%)';

export const SIM_TYPE_DYNAMIC_LAP = 'DynamicLap';
export const STUDY_TYPE_DYNAMIC_LAP = 'dynamicLap';

export const SIM_TYPE_DYNAMIC_MULTI_LAP = 'DynamicMultiLap';
export const STUDY_TYPE_DYNAMIC_MULTI_LAP = 'dynamicMultiLap';

export const SIM_TYPE_GENERATE_RACING_LINE = 'GenerateRacingLine';
export const STUDY_TYPE_GENERATE_RACING_LINE = 'generateRacingLine';

export const SIM_TYPE_QUASI_STATIC_LAP = 'QuasiStaticLap';
export const STUDY_TYPE_QUASI_STATIC_LAP = 'quasiStaticLap';

export const SIM_TYPE_QUASI_STATIC_LAP_WITH_GENERATE_RACING_LINE = 'QuasiStaticLapWithGenerateRacingLine';
export const STUDY_TYPE_QUASI_STATIC_LAP_WITH_GENERATE_RACING_LINE = 'quasiStaticLapWithGenerateRacingLine';

export const SIM_TYPE_OVERTAKING_LAP = 'OvertakingLap';
export const STUDY_TYPE_OVERTAKING_LAP = 'overtakingLap';

export const SIM_TYPE_TRACK_CONVERTER = 'TrackConverter';
export const STUDY_TYPE_TRACK_CONVERTER = 'trackConverter';

export const CONFIG_TYPE_TRACK = 'track';
export const CONFIG_TYPE_CAR = 'car';

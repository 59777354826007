import { FeatureChannelNames } from './types/feature-channel-names';
import { IMultiPlotLayout } from './types/i-multi-plot-layout';
import { firstDistinct } from '../../first-distinct';


export class GetDistinctChannelNames {
  constructor(
    private readonly primaryDomainName: string,
    private readonly secondaryDomainName: string | undefined) {
  }

  public execute(layout: IMultiPlotLayout, featureChannelNames: ReadonlyArray<FeatureChannelNames>): string[] {
    let allSides = [...layout.columns, ...layout.rows];
    let channelNames = allSides.map(s => s.channels.map(c => c.name)).reduce((p, c) => [...p, ...c], []);
    channelNames.push(this.primaryDomainName);

    if (this.secondaryDomainName) {
      channelNames.push(this.secondaryDomainName);
    }

    if (layout.colorChannel) {
      channelNames.push(layout.colorChannel.name);
    }

    if (layout.sizeChannel) {
      channelNames.push(layout.sizeChannel.name);
    }

    for (let item of featureChannelNames) {
      channelNames.push(...item.allChannelNames);
    }

    return firstDistinct(channelNames, v => v);
  }
}

import * as d3 from '../../d3-bundle';
import {getFullyQualifiedChannelName} from '../../get-fully-qualified-channel-name';
import {ChannelNameStyle} from './channel-name-style';
import {ChannelNameMapBuilder} from './channel-name-map';
import {
    ChannelScalarData, ChannelScalarDataAndMappings,
    ChannelScalarDataMap, InputsMetadataMap
} from './channel-scalar-data-and-mappings';
import {ExplorationMap} from './exploration-map';
import {SimType} from '../../sim-type';
import {UrlFileLoader} from '../../url-file-loader';

/**
 * A service that gets the scalar data from the consolidated files created by the post-processor job.
 */
export class GetScalarDataFromConsolidatedFiles {

  constructor(private urlFileLoader: UrlFileLoader){
  }

  /**
   * Gets the scalar data from the consolidated files created by the post-processor job.
   * @param studyId The study ID.
   * @param simTypes The simulation types.
   * @param map The exploration map.
   * @returns The scalar data and mappings.
   */
  public async execute(studyId: string, simTypes: ReadonlyArray<SimType>, map: ExplorationMap): Promise<ChannelScalarDataAndMappings> {

    // Load the results.
    let scalarResults = await this.urlFileLoader.loadScalarResultsForStudy(studyId);

    // Get the results into a nicer format for the visualizations.
    let channelsByName: ChannelScalarDataMap = {};
    let defaultChannelMapBuilder = new ChannelNameMapBuilder(ChannelNameStyle.FullyQualified, simTypes);
    for(let metadataItem of scalarResults.metadata) {

      let genericName = metadataItem.name;
      let fullName = getFullyQualifiedChannelName(genericName, metadataItem.simType);

      let channelScalarData: ChannelScalarData = {
        fullName,
        genericName,
        simType: metadataItem.simType,
        values: scalarResults.data.map((e) => e[fullName]),
        units: metadataItem.units,
        description: metadataItem.description
      };

      defaultChannelMapBuilder.add(genericName, fullName, metadataItem.simType);
      channelsByName[fullName] = channelScalarData;
    }

    // A line index refers to the lines in the viewer.
    // As jobs are excluded from the results (either by manual or automatic filtering),
    // lines are removed from the viewer, and so the line index of subsequent lines is decremented.
    // However their job index in the study stays the same.
    // This map allows us to keep track of the job indexes as jobs are filtered out.
    let lineIndexToJobIndexMap = d3.range(map.coordinates.length);

    let inputsMetadataMap = scalarResults.inputsMetadata.reduce((p, c) => {
      p[c.inputName] = c;
      return p;
    }, {} as InputsMetadataMap);

    return new ChannelScalarDataAndMappings(
      channelsByName,
      defaultChannelMapBuilder.getMap(),
      inputsMetadataMap,
      lineIndexToJobIndexMap);
  }
}

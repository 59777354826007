export function getFileNameWithoutExtension(filePath: string){
  let fileName = filePath;
  if(fileName.indexOf('/') !== -1){
    fileName = fileName.substr(fileName.lastIndexOf('/') + 1);
  }

  if(fileName.indexOf('.') !== -1){
    fileName = fileName.substr(0, fileName.lastIndexOf('.'));
  }

  return fileName;
}

import { ChannelBinaryFormat, ChannelMetadata, UrlFileLoader } from '../../../url-file-loader';
import { ExplorationMap } from '../../channel-data-loaders/exploration-map';
import { SimType } from '../../../sim-type';
import { Utilities } from '../../../utilities';
import { XData } from './x-data';
import { YData } from './y-data';
import { ZData } from './z-data';
import { MultiPlotViewerData } from './multi-plot-viewer-data';
import { ChannelData } from './channel-data';
import { Units } from '../../../units';
import { SiteHooks } from '../../../site-hooks';
import { getFullyQualifiedChannelName } from '../../../get-fully-qualified-channel-name';
import { ChannelScalarDataAndMappings } from '../../channel-data-loaders/channel-scalar-data-and-mappings';
import { ChannelNameMapBuilder } from '../../channel-data-loaders/channel-name-map';
import { ChannelNameStyle } from '../../channel-data-loaders/channel-name-style';
import { CHANNEL_NOT_PRESENT_DESCRIPTION } from '../../../constants';
import { ChannelInterpolatorFactory } from '../../channel-data-loaders/interpolation/channel-interpolator-factory';

export class DataService {

  public map?: ExplorationMap;

  protected scalarData?: ChannelScalarDataAndMappings;
  protected channelInterpolatorFactory: ChannelInterpolatorFactory;

  constructor(
    public simTypes: ReadonlyArray<SimType>,
    private urlFileLoader: UrlFileLoader,
    private siteHooks: SiteHooks
  ) {
    this.channelInterpolatorFactory = new ChannelInterpolatorFactory();
  }

  // extract vector data for a specific set of jobs
  public async extractVectorDataForJobs(channelNameStyle: ChannelNameStyle, layout: any, simType: SimType, jobs: ReadonlyArray<{ studyId: string; jobIndex: number }>): Promise<MultiPlotViewerData> {
    /////////////// Asynchronous fetching of data and building of chart //////////////
    let metadataCsv = await this.urlFileLoader.loadVectorMetadata(jobs[0].studyId, jobs[0].jobIndex, simType);     // FIXME - assumption that metadata is the same for both laps
    let channelsByName: { [name: string]: ChannelMetadata } = {};
    for (let channelMetadata of metadataCsv) {
      channelsByName[channelMetadata.name] = channelMetadata;
      channelsByName[getFullyQualifiedChannelName(channelMetadata.name, simType)] = channelMetadata;
    }

    // setup x-axis and x-gridlines
    let xDataFlat: XData[] = [];
    for (let xDomainName of layout.xDomains) {
      let userChannelUnits = await this.siteHooks.getUserChannelUnits(xDomainName);
      let channelByName = channelsByName[xDomainName];
      let channelUnits = userChannelUnits || channelByName.units;
      let channelDescription = channelByName.description;
      for (let lapIndex = 0; lapIndex < jobs.length; lapIndex++) {
        xDataFlat.push(new XData(
          xDomainName,
          channelByName.name,
          channelUnits,
          channelDescription,
          lapIndex,
          !!userChannelUnits,
          channelByName.binaryFormat,
          channelByName.units));
      }
    }

    let xData = Utilities.nest(xDataFlat, 'iLap');
    let yData: YData[] = [];
    let zData: ZData[] = [];
    let channelNameMapBuilder = new ChannelNameMapBuilder(channelNameStyle, this.simTypes);
    let rPaneSizes = [];

    // get data for a particular channel and return a channel object.
    let loadChannelTasks = xDataFlat.map(c => this.loadChannelData(xData, jobs, simType, c));
    await Promise.all(loadChannelTasks);

    // define layout of chart panes according to data in the loaded chart json
    for (let ip = 0; ip < layout.panes.length; ++ip) {
      let pane = layout.panes[ip];
      // loop through channels in this pane
      for (let ic = 0; ic < layout.panes[ip].channels.length; ++ic) {
        for (let iL = 0; iL < jobs.length; ++iL) {
          let requestedName = pane.channels[ic];

          let genericName = requestedName;
          let fullName = requestedName;
          let channelDescription = CHANNEL_NOT_PRESENT_DESCRIPTION;
          let defaultChannelUnits = '';
          let userChannelUnits;
          let binaryFormat: ChannelBinaryFormat | undefined;

          let channelByName = channelsByName[requestedName];
          if (channelByName) {
            genericName = channelByName.name;
            fullName = getFullyQualifiedChannelName(genericName, simType);
            channelDescription = channelByName.description;
            userChannelUnits = await this.siteHooks.getUserChannelUnits(genericName);
            defaultChannelUnits = channelByName.units;
            binaryFormat = channelByName.binaryFormat;
          }

          let channelUnits = userChannelUnits || defaultChannelUnits;

          yData.push(new YData(
            channelNameStyle === ChannelNameStyle.Generic ? genericName : fullName,
            genericName,
            fullName,
            requestedName,
            channelUnits,
            channelDescription,
            ic,
            ip,
            iL,
            'black',
            !!userChannelUnits,
            binaryFormat,
            defaultChannelUnits));

          channelNameMapBuilder.add(genericName, fullName, simType);
        }
      }
      // Check if there is a background defined for this pane
      if (layout.panes[ip].background) {
        for (let iL = 0; iL < jobs.length; ++iL) {
          let requestedName = layout.panes[ip].background.channel;
          let genericName = requestedName;
          let fullName = requestedName;
          let channelDescription = CHANNEL_NOT_PRESENT_DESCRIPTION;
          let defaultChannelUnits = '';
          let userChannelUnits;
          let binaryFormat: ChannelBinaryFormat | undefined;

          let channelByName = channelsByName[requestedName];
          if (channelByName) {
            genericName = channelByName.name;
            fullName = getFullyQualifiedChannelName(genericName, simType);
            channelDescription = channelByName.description;
            userChannelUnits = await this.siteHooks.getUserChannelUnits(genericName);
            defaultChannelUnits = channelByName.units;
            binaryFormat = channelByName.binaryFormat;
          }

          let channelUnits = userChannelUnits || defaultChannelUnits;

          zData.push(new ZData(
            channelNameStyle === ChannelNameStyle.Generic ? genericName : fullName,
            genericName,
            fullName,
            requestedName,
            channelUnits,
            channelDescription,
            ip,
            iL,
            !!userChannelUnits,
            binaryFormat,
            defaultChannelUnits));

          channelNameMapBuilder.add(genericName, fullName, simType);

          let requestedYName = layout.panes[ip].background.yDomain;
          let genericYName = requestedYName;
          let fullYName = requestedYName;
          let channelYDescription = CHANNEL_NOT_PRESENT_DESCRIPTION;
          let defaultYChannelUnits = '';
          let userYChannelUnits;
          let yBinaryFormat: ChannelBinaryFormat | undefined;

          let yChannelByName = channelsByName[requestedYName];
          if (yChannelByName) {
            genericYName = yChannelByName.name;
            fullYName = getFullyQualifiedChannelName(genericYName, simType);
            channelYDescription = yChannelByName.description;
            userYChannelUnits = await this.siteHooks.getUserChannelUnits(genericYName);
            defaultYChannelUnits = yChannelByName.units;
            yBinaryFormat = yChannelByName.binaryFormat;
          }

          let channelYUnits = userYChannelUnits || defaultYChannelUnits;

          zData[zData.length - 1].yDomain = new YData(
            channelNameStyle === ChannelNameStyle.Generic ? genericYName : fullYName,
            genericYName,
            fullYName,
            requestedYName,
            channelYUnits,
            channelYDescription,
            0,
            ip,
            iL,
            'black',
            !!userChannelUnits,
            yBinaryFormat,
            defaultYChannelUnits);

          channelNameMapBuilder.add(genericName, fullName, simType);
        }
      }
      // Now loop through deltaChannels in this pane, referenced to the first job
      if (layout.panes[ip].deltaChannels) {
        for (let ic = 0; ic < layout.panes[ip].deltaChannels.length; ++ic) {
          for (let iL = 0; iL < jobs.length; ++iL) {
            let requestedName = pane.deltaChannels[ic];

            let genericName = requestedName;
            let fullName = requestedName;
            let channelDescription = CHANNEL_NOT_PRESENT_DESCRIPTION;
            let defaultChannelUnits = '';
            let userChannelUnits;
            let binaryFormat: ChannelBinaryFormat | undefined;

            let channelByName = channelsByName[requestedName];
            if (channelByName) {
              genericName = 'Δ' + channelByName.name;
              fullName = getFullyQualifiedChannelName(genericName, simType);
              channelDescription = channelByName.description;
              userChannelUnits = await this.siteHooks.getUserChannelUnits(genericName);
              defaultChannelUnits = channelByName.units;
              binaryFormat = channelByName.binaryFormat;
            }

            let channelUnits = userChannelUnits || defaultChannelUnits;

            yData.push(new YData(
              channelNameStyle === ChannelNameStyle.Generic ? genericName : fullName,
              genericName,
              fullName,
              requestedName,
              channelUnits,
              channelDescription,
              ic + layout.panes[ip].channels.length,
              ip,
              iL,
              'black',
              !!userChannelUnits,
              binaryFormat,
              defaultChannelUnits,
              true
            ));

            channelNameMapBuilder.add(genericName, fullName, simType);
          }
        }
      }
      rPaneSizes.push(pane.relativeSize);
    }

    let dataSubset = new MultiPlotViewerData(
      xData, yData, channelNameMapBuilder.getMap(), rPaneSizes);

    // Add z stuff and load data for it.
    if (zData.length > 0) {
      dataSubset.zData = zData;
      // Load the yDomainData
      let loadPanelTasks = dataSubset.zData.map(c => this.loadChannelData(xData, jobs, simType, c.yDomain));
      await Promise.all(loadPanelTasks);
      let loadZDataTasks = dataSubset.zData.map(c => this.loadzChannelData(xData, c.yDomain, jobs, simType, c));
      await Promise.all(loadZDataTasks);
    }

    // Load data for channels from binary files
    let loadPanelTasks = dataSubset.yData.map(c => this.loadChannelData(xData, jobs, simType, c));
    await Promise.all(loadPanelTasks);

    // Replace delta channels with difference between them and the reference lap
    dataSubset.yData = dataSubset.yData.map(y => {
      if (y.deltaChannel && y.iLap > 0) {
        let yRef = dataSubset.yData.filter(c => c.iLap === 0 && c.name === y.name && c.deltaChannel === true);
        y.values = y.values.map((e, i) => e - yRef[0].values[i]);
        y.name = y.name;
      }
      return y;
    });
    // NaN the delta channels for the reference lap itself
    dataSubset.yData = dataSubset.yData.map(y => {
      if (y.deltaChannel && y.iLap === 0) {
        y.values = y.values.map(e => NaN);
        y.name = y.name;
      }
      return y;
    });

    return dataSubset;
  }

  // load data from particular channels
  private async loadChannelData(xData: XData[][], jobs: ReadonlyArray<{ studyId: string; jobIndex: number }>, simType: SimType, channel: ChannelData) {
    let searchName;
    if (channel.name.startsWith('Δ')) {
      searchName = channel.name.slice(1);
    } else {
      searchName = channel.name;
    }
    if (channel.description === CHANNEL_NOT_PRESENT_DESCRIPTION) {
      let values = [];
      for (let i = 0; i < xData[channel.iLap][0].values.length; i++) {
        values.push(NaN);
      }
      channel.values = values;
    } else {
      let job = jobs[channel.iLap];
      let data = await this.urlFileLoader.loadChannelData(job.studyId, job.jobIndex, simType, searchName, channel.binaryFormat);
      channel.values = data ? Units.convertValuesBetweenUnits([].slice.call(data), channel.binaryUnits, channel.units) : [];
    }
  }

  // load data from particular channels
  private async loadzChannelData(xData: XData[][], yDomain: YData, jobs: ReadonlyArray<{ studyId: string; jobIndex: number }>, simType: SimType, channel: ChannelData) {
    let searchName;
    if (channel.name.startsWith('Δ')) {
      searchName = channel.name.slice(1);
    } else {
      searchName = channel.name;
    }
    if (channel.description === CHANNEL_NOT_PRESENT_DESCRIPTION) {
      let values = [];
      for (let i = 0; i < xData[channel.iLap][0].values.length; i++) {
        for (let j = 0; j < yDomain.values.length; j++) {
          values.push(NaN);
        }
      }
      channel.values = values;
    } else {
      let job = jobs[channel.iLap];
      let data = await this.urlFileLoader.loadChannelData(job.studyId, job.jobIndex, simType, searchName, channel.binaryFormat);
      channel.values = data ? Units.convertValuesBetweenUnits([].slice.call(data), channel.binaryUnits, channel.units) : [];
    }
  }
}

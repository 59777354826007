<div id="custom-view-filter" class="custom-view-filter">
  <cs-error-message [message]="errorMessage"></cs-error-message>
  <div class="row">
    <div class="col-12">


      <form (ngSubmit)="applyFilter()" [formGroup]="form">
        <div class="d-flex gap-2 align-items-center last-item">

          <button class="btn btn-smart btn-secondary"
                  id="collapse-all-button"
                  type="button"
                  (click)="collapseAll()">
            Collapse
          </button>

          <span class="help-text">Use asterisk (*) for wildcard searches.</span>
          <span class="filter-title">{{managedConfig && managedConfig.name ? managedConfig.name : 'Filter'}}:</span>

          <cs-quick-config-manager class="quick-config-manager-component"
            [configType]="configType + 'CustomView'"
            [persistLocally]="true"
            [showName]="false"
            [updateSource]="configSource"
            (configChanged)="onConfigUpdatedExternally($event)"></cs-quick-config-manager>

          <button class="btn btn-smart btn-secondary"
                  id="filter-clear-button"
                  type="button"
                  (click)="clearFilter()">
            Clear
          </button>

          <tag-input formControlName="tags"
                     theme='minimal'
                     placeholder="+ Parameter Name"
                     secondaryPlaceholder="Enter a parameter name"
                     [modelAsStrings]="true"
                     [separatorKeyCodes]="[32, 188]"
                     [validators]="validators"
                     [clearOnBlur]="true"
                     [errorMessages]="errorMessages">
          </tag-input>
        </div>
      </form>

    </div>
  </div>
</div>

import {Injectable} from '@angular/core';
import {MenuDefinition, MenuItem, SeparatorMenuItem, SubMenuItem} from './context-menu-types';

@Injectable()
export class ContextMenu {
  private _content: MenuDefinition<any>;

  public get content(): MenuDefinition<any> {
    return this._content;
  }

  public set content(menu: MenuDefinition<any>) {
    if(menu){
      this._content = new MenuDefinition<any>(
        menu.position,
        this.filterMenuItems(menu.items),
        menu.wrapper);
    } else{
      this._content = undefined;
    }
  }

  private filterMenuItems<TResult>(items: ReadonlyArray<MenuItem<TResult>>): ReadonlyArray<MenuItem<TResult>> {
    const result: MenuItem<TResult>[] = [];

    if(!items){
      return result;
    }

    let isPreviousItemSeparator: boolean = true;
    for(let item of items){
      if(item instanceof SeparatorMenuItem){
        if(isPreviousItemSeparator){
          continue;
        }
        isPreviousItemSeparator = true;
      } else {
        isPreviousItemSeparator = false;
      }

      if(item instanceof SubMenuItem){
        result.push(new SubMenuItem<TResult>(
          item.name, item.cssClass, this.filterMenuItems(item.items), item.enableScroll));
      } else{
        result.push(item);
      }
    }

    while(result.length && result[result.length - 1] instanceof SeparatorMenuItem){
      result.pop();
    }

    return result;
  }
}

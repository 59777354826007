import {ConfigType} from './config-types';
import {CanopyJson} from '../../common/canopy-json.service';
import {ConfigStub, SimVersionStub} from '../../../generated/api-stubs';
import {Injectable} from '@angular/core';
import {StudyInput} from '../../worksheets/study-input';
import {
  configResultToStudyInput,
  defaultConfigToStudyInput,
  getDefaultConfigId
} from '../../worksheets/study-input-utilities';
import {GetSimVersion} from '../../common/get-sim-version.service';

@Injectable()
export class ConfigLoaderUtilities {
  constructor(
    private readonly simVersionStub: SimVersionStub,
    private readonly configStub: ConfigStub,
    private readonly getSimVersion: GetSimVersion,
    private readonly json: CanopyJson) {
  }

  public async loadUserConfig(tenantId: string, userId: string, configId: string, simVersion: string, subTreePath?: string): Promise<StudyInput>{
    let configResult = await this.configStub.getConfig(
      tenantId,
      configId,
      subTreePath,
      simVersion);

    return configResultToStudyInput(configResult, !!subTreePath);
  }

  public async loadSimVersionConfig(configType: ConfigType, simVersion: string, name: string): Promise<StudyInput>{

    simVersion = simVersion || this.getSimVersion.currentSimVersion;

    let documentResult = await this.simVersionStub.getDocument(
      simVersion,
      getDefaultConfigId(configType.singularKey, name));

    let configData = this.json.parse(documentResult.document.content);

    return defaultConfigToStudyInput(configType.singularKey, name, configData, simVersion);
  }
}

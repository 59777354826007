import {Injectable} from '@angular/core';
import {AuthenticationInterceptor} from './authentication-interceptor.service';
import {DeveloperNameInterceptor} from './developer-name-interceptor.service';
import {InterceptorBase} from './interceptor-base.service';
import {AcceptHeaderInterceptor} from './accept-header-interceptor.service';
import {BasicRequestOptions} from '../canopy-http.service';
import {HttpResponse} from '@angular/common/http';
import {RetryOnNetworkErrorInterceptor} from './retry-on-network-error-interceptor';

@Injectable()
export class Interceptors {
  private items: InterceptorBase[];

  constructor(
    authentication: AuthenticationInterceptor,
    developerName: DeveloperNameInterceptor,
    acceptHeaderInterceptor: AcceptHeaderInterceptor,
    retryOnNetworkErrorInterceptor: RetryOnNetworkErrorInterceptor) {
    this.items = [
      authentication,
      developerName,
      acceptHeaderInterceptor,
      retryOnNetworkErrorInterceptor
    ];
  }

  public async request(requestOptions: BasicRequestOptions): Promise<BasicRequestOptions> {
    if (!requestOptions) {
      requestOptions = {};
    }

    for (let item of this.items) {
      requestOptions = await item.request(requestOptions);
    }

    return requestOptions;
  }

  public async response(response: HttpResponse<any>): Promise<HttpResponse<any>> {
    for (let item of this.items) {
      response = await item.response(response);
    }

    return response;
  }

  public async responseError(response: HttpResponse<any>, retryCount: number): Promise<boolean> {
    let shouldRetry = false;
    for (let item of this.items) {
      shouldRetry = shouldRetry || await item.responseError(response, retryCount);
    }

    return shouldRetry;
  }
}

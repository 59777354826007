/* eslint-disable no-var */
/* eslint-disable prefer-rest-params */
/* eslint-disable prefer-spread */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable brace-style */
// @ts-nocheck
import {dispatch} from 'd3-dispatch';
import {dragDisable, dragEnable} from 'd3-drag';
import {interpolateZoom} from 'd3-interpolate';
import {select, pointer} from 'd3-selection';
import {interrupt} from 'd3-transition';
import constant from './constant';
import ZoomEvent from './event';
import {Transform, identity} from './transform';
import noevent, {nopropagation} from './noevent';

// Ignore right-click, since that should open the context menu.
// except for pinch-to-zoom, which is sent as a wheel+ctrlKey event
function defaultFilter(event) {
  return (!event.ctrlKey || event.type === 'wheel') && !event.button;
}

function defaultExtent() {
  let e = this;
  if (e instanceof SVGElement) {
    e = e.ownerSVGElement || e;
    if (e.hasAttribute('viewBox')) {
      e = e.viewBox.baseVal;
      return [[e.x, e.y], [e.x + e.width, e.y + e.height]];
    }
    return [[0, 0], [e.width.baseVal.value, e.height.baseVal.value]];
  }
  return [[0, 0], [e.clientWidth, e.clientHeight]];
}

function defaultTransform() {
  return this.__zoom || identity;
}

function defaultWheelDelta(event) {
  return -event.deltaY * (event.deltaMode === 1 ? 0.05 : event.deltaMode ? 1 : 0.002) * (event.ctrlKey ? 10 : 1);
}

function defaultTouchable() {
  return navigator.maxTouchPoints || ('ontouchstart' in this);
}

function defaultConstrain(transform, extent, translateExtent) {
  let dx0 = transform.invertX(extent[0][0]) - translateExtent[0][0];
  let dx1 = transform.invertX(extent[1][0]) - translateExtent[1][0];
  let dy0 = transform.invertY(extent[0][1]) - translateExtent[0][1];
  let dy1 = transform.invertY(extent[1][1]) - translateExtent[1][1];
  return transform.translate(
    dx1 > dx0 ? (dx0 + dx1) / 2 : Math.min(0, dx0) || Math.max(0, dx1),
    dy1 > dy0 ? (dy0 + dy1) / 2 : Math.min(0, dy0) || Math.max(0, dy1)
  );
}

export default function() {
  let filter = defaultFilter;
  let extent = defaultExtent;
  let constrain = defaultConstrain;
  let wheelDelta = defaultWheelDelta;
  let touchable = defaultTouchable;
  let kx0u = 0;
  let ky0u = 0;
  let rx = 1;
  let ry = 1;
  let scaleExtent = [[0, 0], [Infinity, Infinity]];
  let translateExtent = [[-Infinity, -Infinity], [Infinity, Infinity]];
  let duration = 250;
  let interpolate = interpolateZoom;
  let listeners = dispatch('start', 'zoom', 'end');
  let touchstarting;
  let touchfirst;
  let touchending;
  let touchDelay = 500;
  let wheelDelay = 150;
  let clickDistance2 = 0;
  let tapDistance = 10;

  function zoom(selection) {
    selection
        .property('__zoom', defaultTransform)
        .on('wheel.zoom', wheeled, {passive: false})
        .on('mousedown.zoom', mousedowned)
        .on('dblclick.zoom', dblclicked)
      .filter(touchable)
        .on('touchstart.zoom', touchstarted)
        .on('touchmove.zoom', touchmoved)
        .on('touchend.zoom touchcancel.zoom', touchended)
        .style('-webkit-tap-highlight-color', 'rgba(0,0,0,0)');
  }

  zoom.transform = function(collection, transform, point, event) {
    let selection = collection.selection ? collection.selection() : collection;
    selection.property('__zoom', defaultTransform);
    if (collection !== selection) {
      schedule(collection, transform, point, event);
    } else {
      selection.interrupt().each(function() {
        gesture(this, arguments)
          .event(event)
          .start()
          .zoom(null, typeof transform === 'function' ? transform.apply(this, arguments) : transform)
          .end();
      });
    }
  };

  zoom.scaleBy = function(selection, kx, ky, p, event) {
    zoom.scaleTo(selection, function() {
      let k0 = this.__zoom.kx;
      let k1 = typeof kx === 'function' ? kx.apply(this, arguments) : kx;
      return k0 * k1;
    }, function() {
      let k0 = this.__zoom.ky;
      let k1 = typeof ky === 'function' ? ky.apply(this, arguments) : ky;
      return k0 * k1;
    }, p, event);
  };

  zoom.scaleTo = function(selection, kx, ky, p, event) {
    zoom.transform(selection, function() {
      let e = extent.apply(this, arguments);
          let t0 = this.__zoom;
          let p0 = p == null ? centroid(e) : typeof p === 'function' ? p.apply(this, arguments) : p;
          let p1 = t0.invert(p0);
          let kx1 = typeof kx === 'function' ? kx.apply(this, arguments) : kx;
          let ky1 = typeof ky === 'function' ? ky.apply(this, arguments) : ky;
      return constrain(translate(scale(t0, kx1, ky1), p0, p1), e, translateExtent);
    }, p, event);
  };

  zoom.translateBy = function(selection, x, y, event) {
    zoom.transform(selection, function() {
      return constrain(this.__zoom.translate(
        typeof x === 'function' ? x.apply(this, arguments) : x,
        typeof y === 'function' ? y.apply(this, arguments) : y
      ), extent.apply(this, arguments), translateExtent);
    }, null, event);
  };

  zoom.translateTo = function(selection, x, y, p, event) {
    zoom.transform(selection, function() {
      let e = extent.apply(this, arguments);
          let t = this.__zoom;
          let p0 = p == null ? centroid(e) : typeof p === 'function' ? p.apply(this, arguments) : p;
      return constrain(identity.translate(p0[0], p0[1]).scale(t.kx, t.ky).translate(
        typeof x === 'function' ? -x.apply(this, arguments) : -x,
        typeof y === 'function' ? -y.apply(this, arguments) : -y
      ), e, translateExtent);
    }, p, event);
  };

  function scale(transform, kx, ky) {
    kx = Math.max(scaleExtent[0][0], Math.min(scaleExtent[1][0], kx));
    ky = Math.max(scaleExtent[0][1], Math.min(scaleExtent[1][1], ky));
    return (kx === transform.kx && ky === transform.ky) ? transform : new Transform(transform.x, transform.y, kx, ky);
  }

  function translate(transform, p0, p1) {
    let x = p0[0] - p1[0] * transform.kx;
    let y = p0[1] - p1[1] * transform.ky;
    return x === transform.x && y === transform.y ? transform : new Transform(x, y, transform.kx, transform.ky);
  }

  function centroid(extent) {
    return [(+extent[0][0] + +extent[1][0]) / 2, (+extent[0][1] + +extent[1][1]) / 2];
  }

  function schedule(transition, transform, point, event) {
    transition
        .on('start.zoom', function() { gesture(this, arguments).event(event).start(); })
        .on('interrupt.zoom end.zoom', function() { gesture(this, arguments).event(event).end(); })
        .tween('zoom', function() {
          let that = this;
          let args = arguments;
          let g = gesture(that, args).event(event);
          let e = extent.apply(that, args);
          let p = point == null ? centroid(e) : typeof point === 'function' ? point.apply(that, args) : point;
          let w = Math.max(e[1][0] - e[0][0], e[1][1] - e[0][1]);
          let a = that.__zoom;
          let b = typeof transform === 'function' ? transform.apply(that, args) : transform;
          //Todo
          let i = interpolate(a.invert(p).concat(w / a.k), b.invert(p).concat(w / b.k));
          return function(t) {
            if (t === 1) {t = b;} // Avoid rounding error on end.
            else { let l = i(t); let k = w / l[2]; t = new Transform(k, p[0] - l[0] * k, p[1] - l[1] * k); }
            g.zoom(null, t);
          };
        });
  }

  function gesture(that, args, clean) {
    return (!clean && that.__zooming) || new Gesture(that, args);
  }

  function Gesture(that, args) {
    this.that = that;
    this.args = args;
    this.active = 0;
    this.sourceEvent = null;
    this.extent = extent.apply(that, args);
    this.taps = 0;
  }

  Gesture.prototype = {
    event(event) {
      if (event) {this.sourceEvent = event;}
      return this;
    },
    start() {
      if (++this.active === 1) {
        this.that.__zooming = this;
        this.emit('start');
      }
      return this;
    },
    zoom(key, transform) {
      if (this.mouse && key !== 'mouse') {this.mouse[1] = transform.invert(this.mouse[0]);}
      if (this.touch0 && key !== 'touch') {this.touch0[1] = transform.invert(this.touch0[0]);}
      if (this.touch1 && key !== 'touch') {this.touch1[1] = transform.invert(this.touch1[0]);}
      this.that.__zoom = transform;
      this.emit('zoom');
      return this;
    },
    end() {
      if (--this.active === 0) {
        delete this.that.__zooming;
        this.emit('end');
      }
      return this;
    },
    emit(type) {
      let d = select(this.that).datum();
      listeners.call(
        type,
        this.that,
        new ZoomEvent(type, {
          sourceEvent: this.sourceEvent,
          target: zoom,
          type,
          transform: this.that.__zoom,
          dispatch: listeners
        }),
        d
      );
    }
  };

  function wheeled(event, ...args) {
    if (!filter.apply(this, arguments)) {return;}
    let g = gesture(this, args).event(event);
    let t = this.__zoom;
    let kx = Math.max(scaleExtent[0][0], Math.min(scaleExtent[1][0], t.kx * (1 + rx * (-1 + Math.pow(2, wheelDelta.apply(this, arguments))))));
    let ky = Math.max(scaleExtent[0][1], Math.min(scaleExtent[1][1], t.ky * (1 + ry * (-1 + Math.pow(2, wheelDelta.apply(this, arguments))))));
    let p = pointer(event);

    // If a scale factor has reached scale extend, sync its value with the other one
    if (t.kx === scaleExtent[0][0]) {
      kx = ky >= scaleExtent[0][0] ? kx : scaleExtent[0][0];
    }
    if (t.kx === scaleExtent[1][0]) {
      kx = ky <= scaleExtent[1][0] ? kx : scaleExtent[1][0];
    }
    if (t.ky === scaleExtent[0][1]) {
      ky = kx >= scaleExtent[0][1] ? ky : scaleExtent[0][1];
    }
    if (t.ky === scaleExtent[1][1]) {
      ky = kx <= scaleExtent[1][1] ? ky : scaleExtent[1][1];
    }

    // If the mouse is in the same location as before, reuse it.
    // If there were recent wheel events, reset the wheel idle timeout.
    if (g.wheel) {
      if (g.mouse[0][0] !== p[0] || g.mouse[0][1] !== p[1]) {
        g.mouse[1] = t.invert(g.mouse[0] = p);
      }
      clearTimeout(g.wheel);
    }

    // If this wheel event won’t trigger a transform change, ignore it.
    else if (t.kx === ky && t.ky === kx) {return;}

    // Otherwise, capture the mouse point and location at the start.
    else {
      g.mouse = [p, t.invert(p)];
      interrupt(this);
      g.start();
    }

    noevent(event);
    g.wheel = setTimeout(wheelidled, wheelDelay);
    g.zoom('mouse', constrain(translate(scale(t, kx, ky), g.mouse[0], g.mouse[1]), g.extent, translateExtent));

    function wheelidled() {
      g.wheel = null;
      g.end();
    }
  }

  function mousedowned(event, ...args) {
    if (touchending || !filter.apply(this, arguments)) {return;}
    let currentTarget = event.currentTarget;
    let g = gesture(this, args, true).event(event);
    let v = select(event.view).on('mousemove.zoom', mousemoved, true).on('mouseup.zoom', mouseupped, true);
    let p = pointer(event, currentTarget);
    let x0 = event.clientX;
    let y0 = event.clientY;

    dragDisable(event.view);
    nopropagation(event);
    g.mouse = [p, this.__zoom.invert(p)];
    interrupt(this);
    g.start();

    function mousemoved(event) {
      noevent(event);
      if (!g.moved) {
        let dx = event.clientX - x0; let dy = event.clientY - y0;
        g.moved = dx * dx + dy * dy > clickDistance2;
      }
      g.event(event)
       .zoom('mouse', constrain(translate(g.that.__zoom, g.mouse[0] = pointer(event, currentTarget), g.mouse[1]), g.extent, translateExtent));
    }

    function mouseupped(event) {
      v.on('mousemove.zoom mouseup.zoom', null);
      dragEnable(event.view, g.moved);
      noevent(event);
      g.event(event).end();
    }
  }

  function dblclicked(event, ...args) {
    if (!filter.apply(this, arguments)) {return;}
    let t0 = this.__zoom;
    let p0 = pointer(event.changedTouches ? event.changedTouches[0] : event, this);
    let p1 = t0.invert(p0);
    let kx1 = t0.kx * (1 + rx * (-1 + (event.shiftKey ? 0.5 : 2)));
    let ky1 = t0.ky * (1 + ry * (-1 + (event.shiftKey ? 0.5 : 2)));
    let t1 = constrain(translate(scale(t0, kx1, ky1), p0, p1), extent.apply(this, args), translateExtent);

    noevent(event);
    if (duration > 0) {select(this).transition().duration(duration).call(schedule, t1, p0, event);}
    else {select(this).call(zoom.transform, t1, p0, event);}
  }

  function touchstarted(event, ...args) {
    if (!filter.apply(this, arguments)) {return;}
    let touches = event.touches;
    let n = touches.length;
    let g = gesture(this, args, event.changedTouches.length === n).event(event);
    let started; let i; let t; let p;

    nopropagation(event);
    for (i = 0; i < n; ++i) {
      t = touches[i], p = pointer(t, this);
      p = [p, this.__zoom.invert(p), t.identifier];
      if (!g.touch0) {g.touch0 = p, started = true, g.taps = 1 + !!touchstarting;}
      else if (!g.touch1 && g.touch0[2] !== p[2]) {g.touch1 = p, g.taps = 0;}
    }

    if (touchstarting) {touchstarting = clearTimeout(touchstarting);}

    if (started) {
      if (g.taps < 2) {touchfirst = p[0], touchstarting = setTimeout(function() { touchstarting = null; }, touchDelay);}
      interrupt(this);
      g.start();
    }
  }

  function touchmoved(event, ...args) {
    if (!this.__zooming) {return;}
    let g = gesture(this, args).event(event);
        let touches = event.changedTouches;
        let n = touches.length; let i; let t; let p; let l;

    noevent(event);
    for (i = 0; i < n; ++i) {
      t = touches[i], p = pointer(t, this);
      if (g.touch0 && g.touch0[2] === t.identifier) {g.touch0[0] = p;}
      else if (g.touch1 && g.touch1[2] === t.identifier) {g.touch1[0] = p;}
    }
    t = g.that.__zoom;
    if (g.touch1) {
      var p0 = g.touch0[0]; var l0 = g.touch0[1];
      var p1 = g.touch1[0]; var l1 = g.touch1[1];
      var dp = (dp = p1[0] - p0[0]) * dp + (dp = p1[1] - p0[1]) * dp;
      var dl = (dl = l1[0] - l0[0]) * dl + (dl = l1[1] - l0[1]) * dl;
      t = scale(t, Math.sqrt(dp / dl));
      p = [(p0[0] + p1[0]) / 2, (p0[1] + p1[1]) / 2];
      l = [(l0[0] + l1[0]) / 2, (l0[1] + l1[1]) / 2];
    }
    else if (g.touch0) {p = g.touch0[0], l = g.touch0[1];}
    else {return;}

    g.zoom('touch', constrain(translate(t, p, l), g.extent, translateExtent));
  }

  function touchended(event, ...args) {
    if (!this.__zooming) {return;}
    let g = gesture(this, args).event(event);
        let touches = event.changedTouches;
        let n = touches.length; let i; let t;

    nopropagation(event);
    if (touchending) {clearTimeout(touchending);}
    touchending = setTimeout(function() { touchending = null; }, touchDelay);
    for (i = 0; i < n; ++i) {
      t = touches[i];
      if (g.touch0 && g.touch0[2] === t.identifier) {delete g.touch0;}
      else if (g.touch1 && g.touch1[2] === t.identifier) {delete g.touch1;}
    }
    if (g.touch1 && !g.touch0) {g.touch0 = g.touch1, delete g.touch1;}
    if (g.touch0) {g.touch0[1] = this.__zoom.invert(g.touch0[0]);}
    else {
      g.end();
      // If this was a dbltap, reroute to the (optional) dblclick.zoom handler.
      if (g.taps === 2) {
        t = pointer(t, this);
        if (Math.hypot(touchfirst[0] - t[0], touchfirst[1] - t[1]) < tapDistance) {
          let p = select(this).on('dblclick.zoom');
          if (p) {p.apply(this, arguments);}
        }
      }
    }
  }

  function constrainScaleExtent() {
    scaleExtent[0][0] = translateExtent[1][0] !== translateExtent[0][0] ? Math.max(kx0u, (extent()[1][0] - extent()[0][0]) / (translateExtent[1][0] - translateExtent[0][0])) : Infinity;
    scaleExtent[0][1] = translateExtent[1][1] !== translateExtent[0][1] ? Math.max(ky0u, (extent()[1][1] - extent()[0][1]) / (translateExtent[1][1] - translateExtent[0][1])) : Infinity;
  }

  zoom.wheelDelta = function(_) {
    return arguments.length ? (wheelDelta = typeof _ === 'function' ? _ : constant(+_), zoom) : wheelDelta;
  };

  zoom.filter = function(_) {
    return arguments.length ? (filter = typeof _ === 'function' ? _ : constant(!!_), zoom) : filter;
  };

  zoom.touchable = function(_) {
    return arguments.length ? (touchable = typeof _ === 'function' ? _ : constant(!!_), zoom) : touchable;
  };

  zoom.extent = function(_) {
    return arguments.length ? (extent = typeof _ === 'function' ? _ : constant([[+_[0][0], +_[0][1]], [+_[1][0], +_[1][1]]]), zoom) : extent;
  };

  zoom.scaleExtent = function(_) {
    if (arguments.length) {
      if (Array.isArray(_[0])) {
        kx0u = +_[0][0];
        scaleExtent[1][0] = +_[1][0];
        ky0u = +_[0][1];
        scaleExtent[1][1] = +_[1][1];
      } else {
        kx0u = ky0u = +_[0];
        scaleExtent[1][0] = scaleExtent[1][1] = +_[1];
      }
      constrainScaleExtent();
      return zoom;
    }
    return [[kx0u, ky0u], [scaleExtent[1][0], scaleExtent[1][1]]];
  };

  zoom.scaleRatio = function(_) {
    return arguments.length ? (rx = +_[0], ry = +_[1], zoom) : [rx, ry];
  };

  zoom.translateExtent = function(_) {
    return arguments.length ? (translateExtent[0][0] = +_[0][0], translateExtent[1][0] = +_[1][0], translateExtent[0][1] = +_[0][1], translateExtent[1][1] = +_[1][1], constrainScaleExtent(), zoom) : [[translateExtent[0][0], translateExtent[0][1]], [translateExtent[1][0], translateExtent[1][1]]];
  };

  zoom.constrain = function(_) {
    return arguments.length ? (constrain = _, zoom) : constrain;
  };

  zoom.duration = function(_) {
    return arguments.length ? (duration = +_, zoom) : duration;
  };

  zoom.interpolate = function(_) {
    return arguments.length ? (interpolate = _, zoom) : interpolate;
  };

  zoom.on = function() {
    let value = listeners.on.apply(listeners, arguments);
    return value === listeners ? zoom : value;
  };

  zoom.clickDistance = function(_) {
    return arguments.length ? (clickDistance2 = (_ = +_) * _, zoom) : Math.sqrt(clickDistance2);
  };

  zoom.tapDistance = function(_) {
    return arguments.length ? (tapDistance = +_, zoom) : tapDistance;
  };

  return zoom;
}

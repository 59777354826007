import {Component, Input, OnChanges} from '@angular/core';
import {
  Added,
  ArrayDelta,
  Deleted,
  Delta,
  DeltaType,
  Modified,
  Moved,
  ObjectDelta,
  TextUnidiff
} from '../compare-config-structures/convert-diff-to-view-model.service';
import {IGetUnits} from '../compare-config-structures/get-units';
import {ConfigChange} from '../../apply-changes-to-config.service';
import {cssSanitize} from '../../../../common/css-sanitize';

export class OutputChangeSet {
  private changes: { [path: string]: any } = {};

  public set(path: string, value: any){
    this.changes[path] = value;
  }

  public toggle(path: string, value: any, otherValue?: any){
    if(this.changes[path] === value){
      this.changes[path] = otherValue;
    } else{
      this.changes[path] = value;
    }
  }

  public contains(path: string, value: any){
    return this.changes[path] === value;
  }

  public getChanges(): ConfigChange[]{
    return Object.keys(this.changes).map(
      key => new ConfigChange(key.split('.'), this.changes[key]));
  }
}

@Component({
  selector: 'cs-compare-config-structures-renderer',
  templateUrl: './compare-config-structures-renderer.component.html',
  styleUrls: ['./compare-config-structures-renderer.component.scss']
})
export class CompareConfigStructuresRendererComponent implements OnChanges {

  public DeltaType = DeltaType;

  @Input() public delta: Delta;
  @Input() public parentName: string;
  @Input() public parentPath: string;
  @Input() public getUnits: IGetUnits;
  @Input() public outputChangeSet: OutputChangeSet;

  public isHidden: boolean = false;

  public get isComplexValue(): boolean {
    return this.delta.isComplexValue;
  }

  constructor() {
  }

  async ngOnChanges() {
    await this.load();
  }

  public async load() {
    if(this.delta.deltaType === DeltaType.added){
      this.outputChangeSet.set(this.parentPath, this.added.newValue);
    } else if(this.delta.deltaType === DeltaType.modified){
      this.outputChangeSet.set(this.parentPath, this.added.newValue);
    }
  }

  public get objectDelta(): ObjectDelta {
 return this.delta as ObjectDelta;
}
  public get arrayDelta(): ArrayDelta {
 return this.delta as ArrayDelta;
}
  public get added(): Added {
 return this.delta as Added;
}
  public get modified(): Modified {
 return this.delta as Modified;
}
  public get deleted(): Deleted {
 return this.delta as Deleted;
}
  public get moved(): Moved {
 return this.delta as Moved;
}
  public get unidiff(): TextUnidiff {
 return this.delta as TextUnidiff;
}

  public getNextPath(propertyName: string){
    return CompareUtilities.getNextPath(this.parentPath, propertyName);
  }

  public toggleChildren() {
    CompareUtilities.toggleChildren(this.outputChangeSet, this.delta, this.parentPath);
  }

  public cssSanitize(value: string){
    return cssSanitize(value);
  }
}

class CompareUtilities {

  public static getNextPath(parentPath: string, propertyName: string){
    if(parentPath){
      return parentPath + '.' + propertyName;
    }

    return propertyName;
  }

  public static toggleChildren(outputChangeSet: OutputChangeSet, delta: Delta, parentPath: string) {
    switch(delta.deltaType){
      case DeltaType.object:
        for(let property of (delta as ObjectDelta).properties){
          CompareUtilities.toggleChildren(
            outputChangeSet,
            property.delta,
            CompareUtilities.getNextPath(parentPath, property.name));
        }
        break;

      case DeltaType.array:
        for(let item of (delta as ArrayDelta).items){
          CompareUtilities.toggleChildren(
            outputChangeSet,
            item.delta,
            CompareUtilities.getNextPath(parentPath, '' + item.index));
        }
        break;

      case DeltaType.added:
        outputChangeSet.toggle(parentPath, (delta as Added).newValue);
        break;

      case DeltaType.modified:
        outputChangeSet.toggle(parentPath, (delta as Modified).oldValue, (delta as Modified).newValue);
        break;

      case DeltaType.deleted:
        outputChangeSet.toggle(parentPath, (delta as Deleted).oldValue);
        break;

      case DeltaType.moved:
        break;

      case DeltaType.unidiff:
        break;
    }
  }
}

import { IMultiPlotSide } from './types/i-multi-plot-side';
import { ApplyChannelModifiers } from '../channel-data-loaders/apply-channel-modifiers';
import { firstDistinct } from '../../first-distinct';


/**
 * Returns a list of distinct X domain channels for the supplied columns.
 */
export class GetXDomainNamesFromColumnSides {

  constructor(
    private readonly primaryDomainName: string,
    private readonly applyChannelModifiers: ApplyChannelModifiers) {
  }

  /**
   * Returns a list of distinct X domain channels for the supplied columns.
   * @param columnSides The list of column sides to search for channels.
   * @returns The distinct set of channel names.
   */
  public execute(columnSides: ReadonlyArray<IMultiPlotSide>): string[] {
    let result: string[] = [];
    for (let side of columnSides) {
      for (let channel of side.channels) {
        let modifiedChannels = this.applyChannelModifiers.executeForChannelName(channel.name, channel, [this.primaryDomainName]);
        result.push(...modifiedChannels.map(v => v.channelName));
      }
    }

    return firstDistinct(result, v => v);
  }
}

export class EditorAutocompleteInformation{
  constructor(
    public readonly autocompleteList: ReadonlyArray<AutocompleteOption>,
    public readonly userInformation: ReadonlyArray<string>,
    public readonly options: any){
  }
}


export interface NamedAutocompleteOption {
  label: string;
  value: string;
}

export type AutocompleteOption = (string | NamedAutocompleteOption);


/**
 * Returns a new object with the specified properties removed.
 * @param input The object to remove properties from.
 * @param propertyNames The names of the properties to remove.
 * @returns A new object with the specified properties removed.
 */
export function getObjectWithout(input: any, propertyNames: ReadonlyArray<string>): any {

  let result = { ...input };

  for (let name of propertyNames || []) {
    delete result[name];
  }

  return result;
}

import * as d3 from '../visualizations/d3-bundle';

// Taken from .NET Path.cs InvalidFileNameChars
const illegalCharacters = [
  '\"',
  '<',
  '>',
  '|',
  '\0',
  ...d3.range(1, 31).map(v => String.fromCharCode(v)),
  ':',
  '*',
  '?',
  '\\',
  '/'];

export function fileNameSanitize(input: string): string {

  if(!input){
    return 'file';
  }

  for(let c of illegalCharacters){
    input = input.replace(c, '_');
  }

  return input;
}

import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {ConfigType} from '../config-types';
import {SimVersionStub} from '../../../../generated/api-stubs';
import {StudyStagingArea} from '../../study-staging-area/study-staging-area.service';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {SimVersionDocumentCache} from '../../sim-version-document-cache.service';
import {sortBy} from '../../../common/sort-by';
import {cssSanitize} from '../../../common/css-sanitize';
import {CanopyJson} from '../../../common/canopy-json.service';
import {getFileNameWithoutExtension} from '../../../common/get-file-name-without-extension';
import {GetSimVersion} from '../../../common/get-sim-version.service';

@Component({
  selector: 'cs-view-default-configs',
  templateUrl: './view-default-configs.component.html',
  styleUrls: ['./view-default-configs.component.scss']
})
export class ViewDefaultConfigsComponent implements OnInit {
  @Input() public configType: ConfigType;
  @Input() public canStage: boolean;
  @Input() public navigationUrlTree: (defaultConfig: DefaultConfigSummary) => [];
  @Output() public configSelected: EventEmitter<DefaultConfigSummary> = new EventEmitter<DefaultConfigSummary>();

  public errorMessage: string;
  public configs: DefaultConfigSummary[];
  public simVersion: string;

  constructor(private readonly simVersionStub: SimVersionStub,
              private readonly documentCache: SimVersionDocumentCache,
              private readonly studyStagingArea: StudyStagingArea,
              private readonly json: CanopyJson,
              private readonly getSimVersion: GetSimVersion,
              private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  public ngOnInit() {
    this.load();
  }

  public async load() {
    try {
      let documentsResult = await this.documentCache.getFolder(
        this.configType.pluralKey,
        this.getSimVersion.currentSimVersion);
      this.simVersion = documentsResult.simVersion;

      this.configs = documentsResult.documents.map(
        document => new DefaultConfigSummary(
          document.name,
          getFileNameWithoutExtension(document.name),
          this.simVersion));

      this.configs.sort(sortBy('name'));
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public cssSanitize(input: string): string {
    return cssSanitize(input);
  }

  public async stage(config: DefaultConfigSummary) {
    try {
      config.isStaging = true;
      let configResult = await this.simVersionStub.getDocument(this.simVersion, config.filePath);
      let parsedContent = this.json.parse(configResult.document.content);
      this.studyStagingArea.stage(
        this.configType.singularKey,
        undefined,
        config.filePath,
        config.name,
        parsedContent,
        [],
        undefined,
        this.simVersion,
        false);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }

    config.isStaging = false;
  }

  public onConfigSelected(config: DefaultConfigSummary) {
    this.configSelected.emit(config);
  }
}

export class DefaultConfigSummary {
  constructor(
    public filePath: string,
    public name: string,
    public simVersion: string) {
  }

  public isStaging: boolean = false;
}

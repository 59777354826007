<div class="card">
  <h1 class="card-header">Multi Factor Authentication</h1>
  <div class="card-body">
    <cs-error-message [message]="errorMessage"></cs-error-message>

    <ng-container *ngTemplateOutlet="isLoading ? loading : loaded"></ng-container>

    <ng-template #loading>
      <cs-loader></cs-loader>
    </ng-template>

    <ng-template #disabled>
      <p>
        This enables multi factor authentication for the user. Changes will take effect on next sign in.
      </p>
      <p>
        This will disable OAuth password flow used by the Canopy CLI, the Canopy Python Client Library and other tools. We will improve support for these tools with MFA enabled in the future.
      </p>
    </ng-template>

    <ng-template #enabled>
      <div id="multi-factor-authentication-success-message" class="alert alert-success">
        Multi factor authentication is enabled.
      </div>
      <ng-container *ngTemplateOutlet="isSelf ? qrCode : undefined"></ng-container>
    </ng-template>

    <ng-template #qrCode>
      <p>
        Scan the QR Code below to add an entry in your authenticator app:
      </p>
      <img src="{{ qrCodeImageUrl }}" alt="{{ key }}" />
      <p>
        QR Code not working? Try entering the following key manually into your authenticator app: {{ key }}
      </p>
    </ng-template>

    <ng-template #loaded>
      <ng-container *ngTemplateOutlet="isTwoFactorEnabled ? enabled : disabled"></ng-container>
      <form (ngSubmit)="onSubmit()" [formGroup]="form">
        <button id="multi-factor-authentication-submit" type="submit" class="btn btn-smart btn-secondary" [disabled]="submitButton.isSubmitting">{{submitButton.text}}</button>
      </form>
    </ng-template>

  </div>
</div>

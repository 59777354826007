import {SimType, StudyStub} from '../../../generated/api-stubs';
import {LoadingDialog} from '../../common/dialogs/loading-dialog.service';
import {Injectable} from '@angular/core';
import {getJobIndexFromJobId} from '../../common/get-job-index-from-job-id';
import { getJobIdFromJobIndex } from '../../common/get-job-id-from-job-index';
import { ToastrService } from 'ngx-toastr';
import { GetFriendlyErrorAndLog } from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import { DownloadFile } from '../../common/download-file.service';

@Injectable()
export class DownloadStudy {
  constructor(
    private downloadFile: DownloadFile,
    private loadingDialog: LoadingDialog,
    private studyStub: StudyStub,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog,
    private readonly toastrService: ToastrService){
  }


  public downloadStudyTypes(tenantId: string, studyId: string, studyName: string, jobCount: number, simTypes: SimType[]): DownloadType[] {
    let ret: DownloadType[] = [
      {
        name: 'csv',
        displayName: 'Study (CSV)',
        description:'Approximately 10MB per job.',
        execute: () => this.execute(() => this.downloadStudy(tenantId, studyId, studyName, true, true, false)),
      },
      {
        name: 'binary',
        displayName: 'Study (Binary)',
        description:'Approximately 10MB per job.',
        execute: () => this.execute(() => this.downloadStudy(tenantId, studyId, studyName, true, false, false)),
      },
      {
        name: 'token',
        displayName: 'Study (Token)',
        description:'',
        execute: () => this.execute(() => Promise.resolve(this.downloadFile.url(this.getStudyTokenUri(tenantId, studyId, studyName), `${studyId}.canopy-download-token`))),
      },
    ];
    if(jobCount >= 2){
      ret.push(
        {
          name: 'scalar-csv',
          displayName: 'Scalar Results (CSV)',
          description:'',
          execute: () => this.execute(() => this.downloadStudy(tenantId, studyId, studyName, false, false, true)),
        },
        {
          name: 'scalar-zip',
          displayName: 'Scalar Results (Zip)',
          description:'',
          execute: () => this.execute(() => this.downloadStudy(tenantId, studyId, studyName, false, false, false)),
        }
      );
    }else if(simTypes){
      simTypes.forEach((st)=>{
        ret.push({
          name: `job-channels-${st}`,
          displayName: `Job Channels (${st})`,
          description: '',
          execute: () => this.execute(() => this.downloadJob(tenantId, studyId, getJobIdFromJobIndex(studyId, 0), studyName, undefined, true, st)),
        });
        ret.push({
          name: `job-scalar-results-${st}`,
          displayName: `Job Scalar Results (${st})`,
          description: '',
          execute: () => this.execute(() => this.downloadJobScalarResults(tenantId, studyId, getJobIdFromJobIndex(studyId, 0), st)),
        });
      });
    }
    return ret;
  };

  public async execute(callback: () => Promise<void>) {
    try {
      await this.loadingDialog.show(async cb => {
        cb.setStatus('Preparing download...');
        await callback();
      });
    } catch (error) {
      let friendlyError = this.getFriendlyErrorAndLog.execute(error);
      this.toastrService.error(friendlyError, 'Download Error');
    }
  }

  private getStudyTokenUri(
    tenantId: string,
    studyId: string,
    studyName: string): string {

    let data = {
      tenantId,
      studyId,
      studyName,
    };

    return `data:application/octet-stream,${encodeURIComponent(JSON.stringify(data))}`;
  }

  public getStudyJobTokenUri(
    tenantId: string,
    studyId: string,
    jobId: string,
    studyName: string,
    jobName: string): string {

    let data = {
      tenantId,
      studyId,
      studyName,
      job: {
        jobIndex: getJobIndexFromJobId(jobId),
        jobName,
      }
    };

    let dataString = JSON.stringify(data);

    return `data:application/octet-stream,${encodeURIComponent(dataString)}`;
  }

  private async downloadStudy(
    tenantId: string,
    studyId: string,
    studyName: string,
    full: boolean,
    channelsAsCsv: boolean,
    mergedScalarResultsOnly: boolean) {
    let result = await this.studyStub.getStudyDownloadUrl(
      tenantId, studyId);

    let downloadUrl = this.studyStub.getStudyDownload_url(
      tenantId, studyId,
      result.accessSignature, result.expiry, studyName, full, channelsAsCsv, mergedScalarResultsOnly);

    this.downloadFile.url(downloadUrl);
  }

  public downloadJob(
    tenantId: string,
    studyId: string,
    jobId: string,
    studyName: string,
    jobName: string,
    channelsAsCsv: boolean,
    simTypeChannels?: SimType) {
    return this.loadingDialog.show(async cb => {
      cb.setStatus('Preparing download...');
      return await this.downloadJobInner(tenantId, studyId, jobId, studyName, jobName, channelsAsCsv, simTypeChannels);
    });
  }

  private async downloadJobInner(
    tenantId: string,
    studyId: string,
    jobId: string,
    studyName: string,
    jobName: string,
    channelsAsCsv: boolean,
    simTypeChannels: SimType) {
    let result = await this.studyStub.getStudyDownloadUrl(
      tenantId, studyId);

    let downloadUrl = this.studyStub.getStudyJobDownload_url(
      tenantId, studyId, jobId,
      result.accessSignature, result.expiry, studyName + (jobName ? '-' + jobName : ''), channelsAsCsv, simTypeChannels);

    this.downloadFile.url(downloadUrl);
  }

  private async downloadJobScalarResults(tenantId: string, studyId: string, jobId: string, simType: SimType){
    let jobResult = await this.studyStub.getStudyJob(tenantId, studyId, jobId);
    let ai = jobResult.accessInformation;
    let filename = simType + '_ScalarResults.csv'
    // cross origin downloads aren't allowed unless the sas sets the response Content-Disposition header to 'attachment'
    // instead fetch file and create download of text locally
    return this.downloadFile.text(filename, await (await fetch(ai.url + filename + ai.accessSignature)).text());
  }
}

export interface DownloadType{
  name: string;
  displayName: string;
  description: string;
  execute: () => Promise<void>;
}

import {
  TrackCoordinateSystemUtilities
} from './viewers/channel-data-loaders/track-coordinate-system-utilities';
import {
  SIM_TYPE_GENERATE_RACING_LINE,
  STUDY_TYPE_GENERATE_RACING_LINE,
  STUDY_TYPE_QUASI_STATIC_LAP, STUDY_TYPE_QUASI_STATIC_LAP_WITH_GENERATE_RACING_LINE, STUDY_TYPE_TRACK_CONVERTER
} from './constants';
import { ChannelBinaryFormat, ChannelMetadata } from './url-file-loader';
import { simVersionToNumber } from './sim-version-to-number';
import { DisplayableError } from './displayable-error';

export const RACING_LINE_HAS_METADATA_SIM_VERSION = 2380;
export const RACING_LINE_CHANNEL_PREFIX = 'RacingLine_';

/**
 * Get the track data populated with the racing line data from the simulation output.
 */
export class PopulateTrackRacingLineFromSimulation {

  /**
   * Whether the racing line channels have metadata in the vector metadata file.
   * Early sim versions didn't have this.
   */
  private readonly racingLineHasMetadata: boolean;

  /**
   * Create a new instance of the PopulateTrackRacingLineFromSimulation class.
   * @param studyType The study type.
   * @param simVersion The simulation version.
   * @param vectorMetadata The vector metadata.
   * @param loadChannelData The function to load channel data.
   * @param loadJsonData The function to load JSON data.
   */
  constructor(
    private readonly studyType: string,
    private readonly simVersion: string,
    private readonly vectorMetadata: ChannelMetadata[],
    private readonly loadChannelData: (fileName: string, binaryFormat: ChannelBinaryFormat | undefined) => Promise<ReadonlyArray<number> | undefined>,
    private readonly loadJsonData: (fileName: string) => Promise<any | undefined>) {

    // Only later sim versions have metadata for the racing line channels in the vector metadata file.
    this.racingLineHasMetadata = simVersionToNumber(this.simVersion) >= RACING_LINE_HAS_METADATA_SIM_VERSION;
  }

  /**
   * Pick properties from an object.
   * @param o The object to pick properties from.
   * @param props The properties to pick.
   * @returns An object with the picked properties.
   */
  private pick(o: any, ...props: string[]) {
    return props.reduce((a, x) => {
      if (o.hasOwnProperty(x)) {
        a[x] = o[x];
      }

      return a;
    }, {} as any);
  }

  /**
   * Return track data with the racing line data from the simulation output.
   * @param trackData The existing track data.
   * @returns The track data with new the racing line data.
   */
  public async execute(trackData: any): Promise<any> {
    // Load the racing line data.
    let racingLineData = await this.getRacingLine();

    // Create a shallow copy of the existing track data.
    let result = {
      ...trackData
    };

    let removeStartFinishOffset = false;
    if (racingLineData.racingLine) {
      if (this.studyType === STUDY_TYPE_TRACK_CONVERTER) {
        // If we're running the track converter, there are certain properties we want to keep.
        result.racingLine = {
          ...this.pick((result.racingLine || {}), 'aTrackCamber', 'aTrackIncline', 'xDynamicLapFixedLineWidth', 'notes', 'customProperties'),
          ...racingLineData.racingLine
        };
      } else {
        // Otherwise we replace the racing line completely.
        result.racingLine = racingLineData.racingLine;
        removeStartFinishOffset = true;
      }
    }
    if (racingLineData.centreLine) {
      // Replace the centre line.
      result.centreLine = racingLineData.centreLine;
      removeStartFinishOffset = true;
    }

    if (removeStartFinishOffset) {
      // The simulation output has already applied the start/finish offset to the data,
      // so we remove the offset property so the offset isn't applied again next time the
      // resulting track is used.
      delete result.startFinishOffset;
    }

    return result;
  }

  /**
   * Get the racing line data from the simulation output.
   * @returns The racing line data.
   */
  public getRacingLine(): Promise<{ racingLine: any | undefined; centreLine: any | undefined }> {
    switch (this.studyType) {
      case STUDY_TYPE_TRACK_CONVERTER:
        return this.getRacingLineFromJson();

      default:
        return this.getRacingLineFromBinaryData();
    }
  }

  /**
   * Get the racing line data from the racing line JSON file.
   * @returns The racing line data.
   */
  public async getRacingLineFromJson(): Promise<{ racingLine: any | undefined; centreLine: any | undefined }> {
    let component = await this.loadJsonData('racingLine.json');

    if (!component) {
      throw new DisplayableError('Racing line output not found.');
    }

    let racingLine = component.config;

    return {
      racingLine,
      centreLine: undefined,
    };
  }

  /**
   * Get the racing line data from the racing line binary files.
   * @returns The racing line data.
   */
  public async getRacingLineFromBinaryData(): Promise<{ racingLine: any | undefined; centreLine: any | undefined }> {
    let metadata = this.getRacingLineMetadata(this.studyType);

    let sLap = await this.getRacingLineChannel('sRun', metadata);
    if (!sLap) {
      sLap = await this.getRacingLineChannel('sLap', metadata);
    }
    let cLap = await this.getRacingLineChannel('cLap', metadata);
    let aTrackCamber = await this.getRacingLineChannel('aTrackCamber', metadata);
    let zTrack = await this.getRacingLineChannel('zTrack', metadata);
    let aTrackIncline = await this.getRacingLineChannel('aTrackIncline', metadata);

    let result = {
      racingLine: this.simplifyObject({
        sLap,
        cLap,
        aTrackCamber,
        zTrack,
        aTrackIncline
      }),
      centreLine: undefined as any
    };

    let xCentreLine = await this.getRacingLineChannel('xCentreLine', metadata);
    let yCentreLine = await this.getRacingLineChannel('yCentreLine', metadata);
    //let zCentreLine = await this.getRacingLineChannel('zCentreLine', metadata);
    let sLapCentreLine = await this.getRacingLineChannel('sLapCentreLine', metadata);
    let aYawCentreLine = await this.getRacingLineChannel('aYawCentreLine', metadata);
    let xHalfWidth = await this.getRacingLineChannel('xHalfWidth', metadata);
    let rTrackCentreOffset = await this.getRacingLineChannel('rTrackCentreOffset', metadata);
    //let aCamber = await this.getRacingLineChannel('aCamber', metadata);

    result.centreLine = this.simplifyObject({
      xCentreLine,
      yCentreLine,
      //zCentreLine,
      sLapCentreLine,
      aYawCentreLine,
      xHalfWidth,
      rTrackCentreOffset,
      //aCamber,
    });

    return result;
  }

  /**
   * Load and interpret a racing line channel from a binary file.
   * @param name The name of the channel.
   * @param metadata The metadata for the racing line.
   * @returns The racing line channel.
   */
  public async getRacingLineChannel(name: string, metadata: RacingLineMetadata): Promise<ReadonlyArray<number> | undefined> {
    let channelName = metadata.channelPrefix + name;
    let channelVectorMetadata = this.vectorMetadata.find(v => v.name === channelName);

    // If the channel isn't in the metadata, then it doesn't exist.
    if (this.racingLineHasMetadata && !channelVectorMetadata) {
      return undefined;
    }

    let binaryFormat: ChannelBinaryFormat | undefined = channelVectorMetadata ? channelVectorMetadata.binaryFormat : undefined;

    // Load the binary file and interpret the bytes as a vector of numbers.
    let result = await this.loadChannelData(metadata.simType + '_' + channelName + '.bin', binaryFormat);

    if (result) {
      // Convert the channel into the correct coordinate system. The simulation coordinate system is different
      // from the coordinate system used in the visualizations and configs.
      result = TrackCoordinateSystemUtilities.channelAsIso(result, name, metadata.simType, this.simVersion);
    }

    return result;
  }

  /**
   * Get the metadata for the racing line channels.
   * @param studyType The study type we're loading channels from.
   * @returns The racing line metadata.
   */
  private getRacingLineMetadata(studyType: string): RacingLineMetadata {
    let simType: string;
    let channelPrefix: string = '';
    switch (studyType) {
      case STUDY_TYPE_GENERATE_RACING_LINE:
      case STUDY_TYPE_QUASI_STATIC_LAP:
      case STUDY_TYPE_QUASI_STATIC_LAP_WITH_GENERATE_RACING_LINE:
        simType = SIM_TYPE_GENERATE_RACING_LINE;
        break;

      default:
        simType = studyType[0].toUpperCase() + studyType.substr(1);
        channelPrefix = RACING_LINE_CHANNEL_PREFIX;
        break;
    }

    return new RacingLineMetadata(simType, channelPrefix);
  }

  /**
   * Simplify an object by removing any undefined properties.
   * If the resulting object is empty, then it is considered undefined.
   * @param input The object to simplify.
   * @returns The simplified object.
   */
  private simplifyObject(input: any) {
    if (!input) {
      return undefined;
    }

    // Shallow copy the object.
    let output = {
      ...input
    };

    // Remove any undefined properties.
    for (let key of Object.keys(output)) {
      if (!output[key]) {
        delete output[key];
      }
    }

    // If the object is empty, then it is considered undefined.
    if (Object.keys(output).length === 0) {
      return undefined;
    }

    return output;
  }
}

/**
 * Metadata for the racing line channels.
 */
class RacingLineMetadata {

  /**
   * Create a new instance of the RacingLineMetadata class.
   * @param simType The simulation type.
   * @param channelPrefix The channel prefix.
   */
  constructor(
    public readonly simType: string,
    public readonly channelPrefix: string
  ) { }
}

import { StudyJobSourceLoader } from '../../viewers/channel-data-loaders/study-job-source-loader';
import { SourceLoaderViewModel } from '../../viewers/channel-data-loaders/source-loader-set';
import { SharedState } from '../../viewers/shared-state';
import { NavigationStationConfigBuilder } from './navigation-station-config-builder';
import { SimType } from '../../sim-type';
import { StudyJob } from '../../study-job';
import { SiteHooks } from '../../site-hooks';
import { UrlFileLoader } from '../../url-file-loader';
import { LocalConfigSourceLoader, UnitsMap } from '../../viewers/channel-data-loaders/local-config-source-loader';
import { ConfigData } from './config-preview-config-builder-base';
import { TrackPreviewConfigBuilder } from './track-preview-config-builder';

/**
 * A config builder for viewing track racing lines output from simulations and comparing
 * them to the original racing lines.
 */
export class RacingLineJobConfigBuilder extends TrackPreviewConfigBuilder implements NavigationStationConfigBuilder {

  /**
   * Constructs a new Racing Line job config builder.
   * @param fileLoader The file loader.
   * @param siteHooks The site hooks.
   * @param studyJobs The study jobs for this builder session.
   * @param simTypes The sim types for this builder session.
   * @param baselineTrack The baseline track.
   * @param unitsMap The units map.
   */
  constructor(
    public readonly fileLoader: UrlFileLoader,
    public readonly siteHooks: SiteHooks,
    public readonly studyJobs: StudyJob[],
    public readonly simTypes: SimType[],
    private readonly baselineTrack: any,
    unitsMap?: UnitsMap) {
    super(fileLoader, siteHooks, [], unitsMap);

    this.trackPreviewName = 'Racing Line';
  }

  /**
   * Adds the source loaders to the shared state.
   * @param sharedState The shared state.
   */
  protected async populateSources(sharedState: SharedState): Promise<void> {
    let primarySimType = this.simTypes[0];
    let studyJobsSourceLoaders = this.studyJobs.map(v => StudyJobSourceLoader.create(this.siteHooks, this.fileLoader, v, primarySimType));

    let trackTasks = studyJobsSourceLoaders.map(v => ({
      name: v.id,
      task: v.getConfig('track')
    }));

    // Add a source loader for each study job.
    for (let studyJobSourceLoader of studyJobsSourceLoaders) {
      sharedState.sourceLoaderSet.add(new SourceLoaderViewModel(studyJobSourceLoader));
    }

    // If there is a baseline track, add that as well.
    if (this.baselineTrack) {
      sharedState.sourceLoaderSet.add(new SourceLoaderViewModel(LocalConfigSourceLoader.create(this.siteHooks, this.baselineTrack, 'Baseline')));
    }

    // Load the track config data for each study job.
    let tracks = await Promise.all(trackTasks.map(v => v.task));
    this.configs = [
      ...trackTasks.map((v, i) => new ConfigData(v.name, tracks[i])),
      new ConfigData('Baseline', this.baselineTrack),
    ];
  }
}

import {Injectable, EventEmitter} from '@angular/core';
import {UserSettings, UpdatedUserSettings} from '../user-state/user-settings.service';
import {GetUserSettingsQueryResult} from '../../generated/api-stubs';
import {ServiceOnInit} from '../common/service-on-init';
import {LogError} from '../common/log-error.service';
import { AuthenticationService, UserData } from '../identity/state/authentication.service';

@Injectable()
export class UserSettingsCache implements ServiceOnInit {

  public changed: EventEmitter<Promise<GetUserSettingsQueryResult>> = new EventEmitter<Promise<GetUserSettingsQueryResult>>();

  private cache: Promise<GetUserSettingsQueryResult>;
  private userData: UserData;

  constructor(
    private logError: LogError,
    private authenticationService: AuthenticationService,
    private userSettings: UserSettings){
  }

  public serviceOnInit() {
    this.userData = this.authenticationService.userDataSnapshot;
    this.userSettings.changed.subscribe((s: UpdatedUserSettings) => this.updateUserSettings(s));
  }

  public updateUserSettings(settings: UpdatedUserSettings){
    try {
      if(this.userData
        && this.userData.tenant === settings.tenantId
        && this.userData.sub === settings.userId){

        this.setCache(Promise.resolve(settings.result));
      }
    } catch (error) {
      this.logError.execute(error);
    }
  }

  public get(): Promise<GetUserSettingsQueryResult>{
    if(!this.cache) {
      let value = this.userSettings.get(this.userData.tenant, this.userData.sub);
      this.setCache(value);
    }

    return this.cache;
  }

  private setCache(value: Promise<GetUserSettingsQueryResult>){
    this.cache = value;
    this.changed.emit(value);
  }
}

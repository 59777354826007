export const SIM_VERSION_BEFORE_MOTOR_SPEED_CHANNEL_RENAME = 9034;

/**
 * Gets the engine and motor metadata, taking into account the current sim version.
 * @param channels The channels.
 * @param simVersion The sim version.
 */
export function getEngineAndMotorMetadata(channels: ReadonlyArray<{ name: string; xDomainName: string }>, simVersion: number): { engineChannelNames: string[]; motorChannelNames: string[]; motorNames: string[] } {
  let engineChannelNames = channels.filter(
    v => v.name.startsWith('MEngine') && v.xDomainName === 'nEngine').map(v => v.name);

  // The channel xDomainName is 'nMotor' for sim versions before SIM_VERSION_BEFORE_MOTOR_SPEED_CHANNEL_RENAME
  // and 'n' + motorName for sim versions after.
  let motorChannelNames = simVersion > SIM_VERSION_BEFORE_MOTOR_SPEED_CHANNEL_RENAME
    ? channels.filter(
      v => !v.name.startsWith('MEngine')
        && v.name.startsWith('M')
        && v.xDomainName.startsWith('n')
        && v.name.slice(1, v.name.length) === v.xDomainName.slice(1, v.xDomainName.length))
      .map(v => v.name)
    : channels.filter(v => v.name.startsWith('M') && v.xDomainName === 'nMotor').map(v => v.name);

  let motorNames = motorChannelNames.map(n => n.slice(1, n.length));

  return { engineChannelNames, motorChannelNames, motorNames };
}

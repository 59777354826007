/* eslint-disable quote-props */
export class Units {

  /**
   * A table of units and their SI equivalents and conversions.
   */
  private static toSiTable: { [unit: string]: { SiUnit: string; factor: number; offset: number } } = {
    // list SI ones first so they are always at the top of the suggested list
    '()': { SiUnit: '()', factor: 1, offset: 0 },
    'm': { SiUnit: 'm', factor: 1, offset: 0 },
    'kg': { SiUnit: 'kg', factor: 1, offset: 0 },
    'N': { SiUnit: 'N', factor: 1, offset: 0 },
    'm/s': { SiUnit: 'm/s', factor: 1, offset: 0 },
    'rad': { SiUnit: 'rad', factor: 1, offset: 0 },
    'Pa': { SiUnit: 'Pa', factor: 1, offset: 0 },
    'rad/s': { SiUnit: 'rad/s', factor: 1, offset: 0 },
    'Nm': { SiUnit: 'Nm', factor: 1, offset: 0 },
    'm/s2': { SiUnit: 'm/s2', factor: 1, offset: 0 },
    'W': { SiUnit: 'W', factor: 1, offset: 0 },
    'J': { SiUnit: 'J', factor: 1, offset: 0 },
    's': { SiUnit: 's', factor: 1, offset: 0 },
    'K': { SiUnit: 'K', factor: 1, offset: 0 },
    'N/rad': { SiUnit: 'N/rad', factor: 1, offset: 0 },
    'Nm/rad': { SiUnit: 'Nm/rad', factor: 1, offset: 0 },
    'N/m': { SiUnit: 'N/m', factor: 1, offset: 0 },
    'rad/s2': { SiUnit: 'rad/s2', factor: 1, offset: 0 },
    'kg/s': { SiUnit: 'kg/s', factor: 1, offset: 0 },
    'Ns/m': { SiUnit: 'Ns/m', factor: 1, offset: 0 },

    // now start listing all the horrible non-SI ones that people like
    '': { SiUnit: '()', factor: 1, offset: 0 },
    '%': { SiUnit: '()', factor: 0.01, offset: 0 },
    'pt': { SiUnit: '()', factor: 0.01, offset: 0 },
    'e-3': { SiUnit: '()', factor: 1e-3, offset: 0 },
    'e-6': { SiUnit: '()', factor: 1e-6, offset: 0 },

    'N/m2': { SiUnit: 'Pa', factor: 1, offset: 0 },
    'bar': { SiUnit: 'Pa', factor: 1e5, offset: 0 },
    'mbar': { SiUnit: 'Pa', factor: 1e5 * 0.001, offset: 0 },
    'psi': { SiUnit: 'Pa', factor: 6894.76, offset: 0 },
    'inHg': { SiUnit: 'Pa', factor: 3386.39, offset: 0 },

    'gm': { SiUnit: 'kg', factor: 0.001, offset: 0 },
    'lbm': { SiUnit: 'kg', factor: 0.45359237, offset: 0 },
    'st': { SiUnit: 'kg', factor: 6.35029318, offset: 0 },

    'lbf': { SiUnit: 'N', factor: 4.448222, offset: 0 },
    'kN': { SiUnit: 'N', factor: 1e3, offset: 0 },
    'MN': { SiUnit: 'N', factor: 1e6, offset: 0 },
    'GN': { SiUnit: 'N', factor: 1e9, offset: 0 },

    'ms': { SiUnit: 's', factor: 0.001, offset: 0 },
    'ks': { SiUnit: 's', factor: 1000, offset: 0 },

    'mm': { SiUnit: 'm', factor: 0.001, offset: 0 },
    'cm': { SiUnit: 'm', factor: 0.01, offset: 0 },
    'km': { SiUnit: 'm', factor: 1000, offset: 0 },
    'miles': { SiUnit: 'm', factor: 1609.344, offset: 0 },
    'in': { SiUnit: 'm', factor: 0.0254, offset: 0 },
    'ft': { SiUnit: 'm', factor: 0.3048, offset: 0 },
    'yd': { SiUnit: 'm', factor: 0.9144, offset: 0 },

    'kph': { SiUnit: 'm/s', factor: 1 / 3.6, offset: 0 },
    'mph': { SiUnit: 'm/s', factor: 0.44704, offset: 0 },
    'mm/s': { SiUnit: 'm/s', factor: 0.001, offset: 0 },
    'kn': { SiUnit: 'm/s', factor: 0.514444, offset: 0 },
    'in/s': { SiUnit: 'm/s', factor: 0.0254, offset: 0 },

    'g': { SiUnit: 'm/s2', factor: 9.80665, offset: 0 },

    'deg': { SiUnit: 'rad', factor: Math.PI / 180, offset: 0 },
    '°': { SiUnit: 'rad', factor: Math.PI / 180, offset: 0 },

    'rpm': { SiUnit: 'rad/s', factor: Math.PI / 30, offset: 0 },
    'deg/s': { SiUnit: 'rad/s', factor: Math.PI / 180, offset: 0 },
    '°/s': { SiUnit: 'rad/s', factor: Math.PI / 180, offset: 0 },

    'Nm/deg': { SiUnit: 'Nm/rad', factor: 180 / Math.PI, offset: 0 },

    'kW': { SiUnit: 'W', factor: 1e3, offset: 0 },
    'MW': { SiUnit: 'W', factor: 1e6, offset: 0 },
    'GW': { SiUnit: 'W', factor: 1e9, offset: 0 },
    'PS': { SiUnit: 'W', factor: 735.4987, offset: 0 },
    'hp': { SiUnit: 'W', factor: 745.6999, offset: 0 },

    'kJ': { SiUnit: 'J', factor: 1e3, offset: 0 },
    'MJ': { SiUnit: 'J', factor: 1e6, offset: 0 },
    'GJ': { SiUnit: 'J', factor: 1e9, offset: 0 },
    'kWh': { SiUnit: 'J', factor: 1e3 * 60 * 60, offset: 0 },

    'kPa': { SiUnit: 'Pa', factor: 1e3, offset: 0 },
    'MPa': { SiUnit: 'Pa', factor: 1e6, offset: 0 },
    'GPa': { SiUnit: 'Pa', factor: 1e9, offset: 0 },

    'kNm': { SiUnit: 'Nm', factor: 1e3, offset: 0 },
    'MNm': { SiUnit: 'Nm', factor: 1e6, offset: 0 },
    'GNm': { SiUnit: 'Nm', factor: 1e9, offset: 0 },

    'C': { SiUnit: 'K', factor: 1, offset: 273.15 },
    '°C': { SiUnit: 'K', factor: 1, offset: 273.15 },
    'F': { SiUnit: 'K', factor: 5 / 9, offset: 459.67 * 5 / 9 },

    'N/mm': { SiUnit: 'N/m', factor: 1000, offset: 0 },
    'lbf/in': { SiUnit: 'N/m', factor: 175.126835, offset: 0 },

    'lbfs/in': { SiUnit: 'Ns/m', factor: 175.126835, offset: 0 },
  };

  /**
   * Get a list of all known units.
   * @returns A list of all known units.
   */
  public static getKnownUnits(): string[] {
    return Object.keys(Units.toSiTable);
  }

  /**
   * Add a new conversion between two units.
   * @param newUnits The new units.
   * @param originalUnits The original units.
   * @param factorToOriginal The factor to convert to the original units.
   * @param offsetToOriginal The offset to convert to the original units.
   */
  public static addConversion(newUnits: string, originalUnits: string, factorToOriginal: number, offsetToOriginal: number) {
    if (Units.toSiTable[newUnits]) {
      return;
    }

    if (!Units.toSiTable[originalUnits]) {
      Units.toSiTable[originalUnits] = { SiUnit: originalUnits, factor: 1, offset: 0 };
    }

    let siUnit = Units.getSiUnit(originalUnits);
    let conversionToSi = Units.getConversionToSi(originalUnits);
    let factorToSi = conversionToSi.factor;
    let offsetToSi = conversionToSi.offset;

    Units.toSiTable[newUnits] = { SiUnit: siUnit, factor: factorToSi * factorToOriginal, offset: (offsetToSi + offsetToOriginal * factorToSi) };
  }

  /**
   * Convert a value in a given unit to SI.
   * @param value The value to convert.
   * @param sourceUnit The source unit.
   * @param applyFactorOnly Whether to apply the factor only, or the full conversion.
   * @returns The converted value.
   */
  public static convertValueToSi(value: number, sourceUnit: string, applyFactorOnly: boolean = false): number {
    let conversionToSi = Units.getConversionToSi(sourceUnit);
    let factor = conversionToSi.factor;
    let offset = applyFactorOnly ? 0 : conversionToSi.offset;
    return value * factor + offset;
  }

  /**
   * Convert a set of value in a given unit to SI.
   * @param values The values to convert.
   * @param sourceUnit The source unit.
   * @param returnOriginalIfNoConversion Whether to return the original array if no conversion is required, or a clone.
   * @returns The converted values.
   */
  public static convertValuesToSi(values: ReadonlyArray<number>, sourceUnit: string, returnOriginalIfNoConversion: boolean = false): ReadonlyArray<number> {
    let conversionToSi = Units.getConversionToSi(sourceUnit);
    let factor = conversionToSi.factor;
    let offset = conversionToSi.offset;

    if (factor === 1 && offset === 0) {
      if (returnOriginalIfNoConversion) {
        return values;
      }

      return [...values];
    }

    return values.map(value => value * factor + offset);
  }

  /**
   * Replace the values in the given array with their SI equivalents.
   * @param values The values to replace.
   * @param sourceUnit The source unit.
   */
  public static replaceValuesWithSi(values: number[], sourceUnit: string): void {
    let conversionToSi = Units.getConversionToSi(sourceUnit);
    let factor = conversionToSi.factor;
    let offset = conversionToSi.offset;

    if (factor === 1 && offset === 0) {
      return;
    }

    for (let i = 0; i < values.length; ++i) {
      values[i] = values[i] * factor + offset;
    }
  }

  /**
   * Convert a value in SI to a given unit.
   * @param value The value to convert.
   * @param targetUnit The target unit.
   * @param applyFactorOnly Whether to apply the factor only, or the full conversion.
   * @returns The converted value.
   */
  public static convertValueFromSi(value: number, targetUnit: string, applyFactorOnly: boolean = false): number {
    let conversionToSi = Units.getConversionToSi(targetUnit);
    let factor = conversionToSi.factor;
    let offset = applyFactorOnly ? 0 : conversionToSi.offset;
    return (value - offset) / factor;
  }

  /**
   * Convert a set of values in SI to a given unit.
   * @param values The values to convert.
   * @param targetUnit The target unit.
   * @param returnOriginalIfNoConversion Whether to return the original array if no conversion is required, or a clone.
   * @returns The converted values.
   */
  public static convertValuesFromSi(values: ReadonlyArray<number>, targetUnit: string, returnOriginalIfNoConversion: boolean = false): ReadonlyArray<number> {
    let conversionToSi = Units.getConversionToSi(targetUnit);
    let factor = conversionToSi.factor;
    let offset = conversionToSi.offset;

    if (factor === 1 && offset === 0) {
      if (returnOriginalIfNoConversion) {
        return values;
      }

      return [...values];
    }

    return values.map(value => (value - offset) / factor);
  }

  /**
   * Convert a value in one unit to a value in another unit
   * @param value The value to convert.
   * @param sourceUnit The source unit.
   * @param targetUnit The target unit.
   * @param applyFactorOnly Whether to apply the factor only, or the full conversion.
   * @returns The converted value.
   */
  public static convertValueBetweenUnits(value: number, sourceUnit: string, targetUnit: string, applyFactorOnly: boolean = false): number {
    return Units.convertValueFromSi(Units.convertValueToSi(value, sourceUnit, applyFactorOnly), targetUnit, applyFactorOnly);
  }

  /**
   * Convert values from one unit to another.
   * @param values The values to convert.
   * @param sourceUnit The source unit.
   * @param targetUnit The target unit.
   * @param returnOriginalIfNoConversion Whether to return the original array if no conversion is required, or a clone.
   * @returns The converted values.
   */
  public static convertValuesBetweenUnits(values: ReadonlyArray<number>, sourceUnit: string, targetUnit: string, returnOriginalIfNoConversion: boolean = false): ReadonlyArray<number> {
    let conversion = Units.getConversionBetweenUnits(sourceUnit, targetUnit);

    if (!conversion.isConversionRequired) {
      if (returnOriginalIfNoConversion) {
        return values;
      }

      return [...values];
    }

    let combinedOffset = conversion.offsetToSi - conversion.offsetFromSi;
    return values.map(v => (v * conversion.factorToSi + combinedOffset) / conversion.factorFromSi);
  }

  /**
   * Check if a conversion is required between two units.
   * @param sourceUnit The source unit.
   * @param targetUnit The target unit.
   * @returns True if a conversion is required, false otherwise.
   */
  public static isConversionRequired(sourceUnit: string, targetUnit: string) {
    return Units.getConversionBetweenUnits(sourceUnit, targetUnit).isConversionRequired;
  }

  /**
   * Return the SI version of the given unit.
   * @param unit The unit.
   * @returns The SI version of the unit.
   */
  public static getSiUnit(unit: string): string {
    if (Units.toSiTable[unit]) {
      return Units.toSiTable[unit].SiUnit;
    } else {
      return '';
    }
  }

  /**
   * Get the conversion to SI units for the given unit.
   * @param unit The unit.
   * @returns The conversion to SI units.
   */
  private static getConversionToSi(unit: string): { factor: number; offset: number } {
    return Units.toSiTable[unit] || { factor: 1, offset: 0 };
  }

  /**
   * Get the conversion between two units.
   * @param sourceUnit The source unit.
   * @param targetUnit The target unit.
   * @returns The conversion between the two units.
   */
  private static getConversionBetweenUnits(sourceUnit: string, targetUnit: string): ConversionBetweenUnits {
    let conversionFromSourceToSi = Units.getConversionToSi(sourceUnit);
    let factorToSi = conversionFromSourceToSi.factor;
    let offsetToSi = conversionFromSourceToSi.offset;

    let conversionFromTargetToSi = Units.getConversionToSi(targetUnit);
    let factorFromSi = conversionFromTargetToSi.factor;
    let offsetFromSi = conversionFromTargetToSi.offset;

    let conversionRequired = true;

    let combinedOffset = offsetToSi - offsetFromSi;
    if (combinedOffset === 0) {
      let combinedFactor = factorToSi / factorFromSi;

      if (combinedFactor === 1) {
        conversionRequired = false;
      }
    }

    return new ConversionBetweenUnits(factorToSi, offsetToSi, factorFromSi, offsetFromSi, conversionRequired);
  }

  /**
   * Return a list of all units which have the same SI unit as the given unit.
   * @param unit The unit.
   * @returns A list of alternative units.
   */
  public static getAlternativeUnits(unit: string): string[] {
    let alternatives = [];
    let SiUnit = Units.getSiUnit(unit);
    alternatives.push(SiUnit);
    for (let u in Units.toSiTable) {
      if (u !== SiUnit && Units.toSiTable[u].SiUnit === SiUnit) {
        alternatives.push(u);
      }
    }
    return alternatives;
  }
}

/**
 * A class to store the conversion between two units.
 */
class ConversionBetweenUnits {

  /**
   * Creates a new instance of ConversionBetweenUnits.
   * @param factorToSi The factor for conversion to SI units.
   * @param offsetToSi The offset for conversion to SI units.
   * @param factorFromSi The factor for conversion from SI units.
   * @param offsetFromSi  The offset for conversion from SI units.
   * @param isConversionRequired Whether a conversion is required.
   */
  constructor(
    public readonly factorToSi: number,
    public readonly offsetToSi: number,
    public readonly factorFromSi: number,
    public readonly offsetFromSi: number,
    public readonly isConversionRequired: boolean) {
  }
}

import {Component} from '@angular/core';
import {OnInit} from '@angular/core';
import {UserState} from '../user-state/user-state';
import {FetchUserState} from '../user-state/fetch-user-state.service';
import {GetFriendlyErrorAndLog} from '../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import { AuthenticationService, UserData } from '../identity/state/authentication.service';
import { SignOut } from '../identity/authentication/sign-out.service';

@Component({
  selector: 'cs-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  public userData: UserData;
  public currentUserState: UserState;
  public errorMessage: string;

  public get isAuthenticated(): boolean {
    return this.authenticationService.isAuthenticatedSnapshot;
  }

  constructor(
    public authenticationService: AuthenticationService,
    private fetchUserState: FetchUserState,
    private getFriendlyErrorAndLog: GetFriendlyErrorAndLog,
    public signOut: SignOut) {
  }

  public isAdministrator(): boolean {
    return this.authenticationService.isAuthenticatedSnapshot && this.authenticationService.isAdministrator;
  }

  public ngOnInit() {
    this.updateCurrentUser();
    this.fetchUserState.changed.subscribe(() => this.updateCurrentUser());
  }

  public async updateCurrentUser() {
    try {
      this.errorMessage = undefined;
      this.userData = this.authenticationService.userDataSnapshot;
      this.currentUserState = await this.fetchUserState.get();
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }
}

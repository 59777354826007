<div csSetBodyDialogStyle
     class="application-container"
     id="application-container"
     csElementHeightCacher
     [ngStyle]="{ 'height': 'calc(100% - ' + dock.height + 'px' }">

  <header>
    <cs-nav-bar></cs-nav-bar>
  </header>


  <router-outlet></router-outlet>

  <footer>
    <cs-footer></cs-footer>
  </footer>
</div>

import { Box3, GridHelper, Group, Scene, Color } from 'three';
import * as d3 from '../../d3-bundle';
import { modulo } from '../../modulo';

/**
 * Renders a floor grid in 3D.
 */
export class FloorRenderer {

  /**
   * The group containing the floor grid.
   */
  private floor: Group | undefined;

  /**
   * The size of the floor grid.
   */
  private floorSize: number = 1;

  /**
   * The bounding box of the car (or other config).
   */
  private readonly carBoundingBox = new Box3();

  /**
   * The group containing the config we want to draw a floor under.
   */
  private contentGroup: Group | undefined;

  /**
   * Creates a new instance of FloorRenderer.
   * @param scene The scene to render the floor in.
   * @param divisionsPerMeter The number of divisions per meter.
   * @param verticalOffset The vertical offset of the floor.
   */
  constructor(
    private readonly scene: Scene,
    private readonly divisionsPerMeter: number = 1,
    private readonly verticalOffset: number = 0) {
  }

  /**
   * Builds the floor grid.
   * @param contentGroup The group containing the content to draw the floor under.
   */
  public build(contentGroup: Group) {
    this.contentGroup = contentGroup;
    this.update();
  }

  /**
   * Updates the floor grid.
   */
  public update() {
    let size = 2;
    if (this.contentGroup && this.contentGroup.children.length) {

      // Get the bounding box of the content.
      this.carBoundingBox.setFromObject(this.contentGroup);

      // The largest absolute coordinate in the X or Z directions.
      let extent = Math.ceil(d3.maxStrict([
        this.carBoundingBox.max.x,
        this.carBoundingBox.max.z,
        this.carBoundingBox.min.x,
        this.carBoundingBox.min.z].map((v => Math.abs(v)))));

      let metresPerDivision = 1 / this.divisionsPerMeter;

      // Round up to the nearest division.
      if (metresPerDivision > 1) {
        extent += metresPerDivision - modulo(extent, metresPerDivision);
      }

      // The size of the floor grid is twice the extent.
      size = 2 * extent;
    }

    // If the floor grid already exists, remove it if the size has changed.
    if (this.floor) {
      if (size !== this.floorSize) {
        this.scene.remove(this.floor);
        this.floor = undefined;
      }
    }

    // If the floor grid doesn't exist, create it.
    if (!this.floor) {
      let style = getComputedStyle(document.documentElement);
      let divisions = size * this.divisionsPerMeter;
      let grid = new GridHelper(
        size,
        divisions,
        new Color(`${style.getPropertyValue('--grid-centreline-colour')}`),
        new Color(`${style.getPropertyValue('--grid-colour')}`));
      this.floor = new Group();
      this.floor.add(grid);
      this.floor.position.setY(this.verticalOffset);

      this.floorSize = size;
      this.scene.add(this.floor);
    }
  }
}

import { SourceData } from './types/source-data';
import { IMultiPlotSide } from './types/i-multi-plot-side';
import { IMultiPlotLayout } from './types/i-multi-plot-layout';
import { SideType } from './types/side-type';
import { ApplyChannelModifiers } from '../channel-data-loaders/apply-channel-modifiers';
import { DomainViewerChannelData } from '../channel-data-loaders/domain-viewer-channel-data';


/**
 * For any channels which have modifiers (e.g. delta channels), generate new channels with those
 * modifiers applied.
 */
export class ApplyModifiersToSourcesChannelData {

  constructor(
    private readonly primaryDomainName: string,
    private readonly applyChannelModifiers: ApplyChannelModifiers) {
  }

  /**
   * For any channels which have modifiers (e.g. delta channels), generate new channels with those
   * modifiers applied.
   *
   * @param layout - The layout containing all the channels and modifier flags.
   * @param sourcesData - The channel data for each source.
   */
  public execute(layout: IMultiPlotLayout, sourcesData: ReadonlyArray<SourceData>) {
    let primaryDomainData = sourcesData.map(v => v.channels.get(this.primaryDomainName));
    let columnDomains: DomainViewerChannelData[] = [new DomainViewerChannelData(this.primaryDomainName, primaryDomainData)];
    let rowDomains: DomainViewerChannelData[] = [];

    // If the side is a column, add it to the list of row domains.
    let addRowDomain = (sideType: SideType, channelName: string) => {
      if (sideType !== SideType.column) {
        return;
      }

      if (rowDomains.some(v => v.channelName === channelName)) {
        return;
      }

      let domainData = sourcesData.map(v => v.channels.get(channelName));
      rowDomains.push(new DomainViewerChannelData(channelName, domainData));
    };

    // Consolidate all the sides from the layout into a single list.
    // It is important that the columns are processed before the rows,
    // so that the row domains are populated with the columns' channels.
    let labeledSides: { sideType: SideType; side: IMultiPlotSide }[] = [
      ...layout.columns.map(v => ({ sideType: SideType.column, side: v })),
      ...layout.rows.map(v => ({ sideType: SideType.row, side: v }))
    ];

    for (let labeledSide of labeledSides) {
      for (let channel of labeledSide.side.channels) {
        let inputData = sourcesData.map(v => v.channels.get(channel.name));

        // This returns a set of data for each row domain. For example, if the
        // channel is a `vCar` delta channel, and the columns are `sRun` and `tRun`, then
        // it will have return data for the new channels `ΔvCar_sRun` and `ΔvCar_tRun`
        // which will contain the delta channels in each domain.
        let modifiedDataList = this.applyChannelModifiers.execute(
          channel.name,
          channel,
          inputData,
          labeledSide.sideType === SideType.column ? columnDomains : rowDomains);

        if (modifiedDataList.length) {
          // If we got back some new channels, add them to the `SourceData` instances.
          for (let modifiedData of modifiedDataList) {
            for (let i = 0; i < sourcesData.length; ++i) {
              const s = sourcesData[i];
              if (s && s.channels) {
                s.channels.set(modifiedData.channelName, modifiedData.sourcesData[i]);
              }
            }

            addRowDomain(labeledSide.sideType, modifiedData.channelName);
          }
        } else {
          addRowDomain(labeledSide.sideType, channel.name);
        }
      }
    }
  }
}

import {OnInit} from '@angular/core';
import {CanopyValidators} from '../common/forms/canopy-validators.service';
import {FormSubmissionButton} from '../common/forms/form-submission-button';
import {Component} from '@angular/core';
import {FetchUserState} from '../user-state/fetch-user-state.service';
import {TenancyStub} from '../../generated/api-stubs';
import {FormSubmissionHandler} from '../common/forms/form-submission-handler.service';
import {GetFriendlyErrorAndLog} from '../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {InferableRouteParams} from '../common/inferable-route-params.service';
import {UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import { AuthenticationService } from '../identity/state/authentication.service';

@Component({
  templateUrl: './tenant-account-settings.page.html'
})
export class TenantAccountSettingsPage implements OnInit {
  public form: UntypedFormGroup;
  public name: UntypedFormControl = new UntypedFormControl('', [Validators.required, ...CanopyValidators.tenantNameValidators]);
  public shortName: UntypedFormControl = new UntypedFormControl('', [Validators.required, ...CanopyValidators.tenantShortNameValidators]);
  public databaseId: UntypedFormControl = new UntypedFormControl('', [...CanopyValidators.databaseIdValidators]);
  public isTenantEnabled: UntypedFormControl = new UntypedFormControl(false);

  public errorMessage: string;
  public submitButton = new FormSubmissionButton('Save', 'Saving...');
  public saveSuccessful = false;

  public isAdministrator: boolean;
  private tenantId: string;

  constructor(
    private authenticationService: AuthenticationService,
    private formBuilder: UntypedFormBuilder,
    private fetchUserState: FetchUserState,
    private route: ActivatedRoute,
    private inferableRouteParams: InferableRouteParams,
    private tenancyStub: TenancyStub,
    private formSubmissionHandler: FormSubmissionHandler,
    private getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  public ngOnInit() {
    this.load();
  }

  public async load() {
    try {
      // NOTE: Only a FULL SITE ADMIN can change either the short name or enabled state.
      this.isAdministrator = this.authenticationService.isAdministrator;

      this.tenantId = this.inferableRouteParams.getTenantId(this.route);

      let userState = await this.fetchUserState.get();

      if(userState.tenant.tenantId === this.tenantId) {
        this.name.setValue(userState.tenant.name, {});
        this.shortName.setValue(userState.tenant.shortName, {});
        this.databaseId.setValue(userState.tenant.databaseId, {});
        this.isTenantEnabled.setValue(userState.tenant.isEnabled, {});
      } else {
        let tenant = await this.tenancyStub.getTenant(this.tenantId);
        this.name.setValue(tenant.name, {});
        this.shortName.setValue(tenant.shortName, {});
        this.databaseId.setValue(tenant.databaseId, {});
        this.isTenantEnabled.setValue(tenant.isEnabled, {});
      }

      this.form = this.formBuilder.group({
        name: this.name,
        shortName: this.shortName,
        databaseId: this.databaseId,
        isTenantEnabled: this.isTenantEnabled
      });
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async onSubmit() {
    this.saveSuccessful = false;
    let success = await this.formSubmissionHandler.execute(this.submit, this.form, this.submitButton, this);

    if (success) {
      this.saveSuccessful = true;
    }
  }

  private async submit() {
    await this.tenancyStub.putTenant(
      this.tenantId,
      {
        name: this.name.value,
        shortName: this.isAdministrator ? this.shortName.value : undefined,
        databaseId: this.isAdministrator ? this.databaseId.value : undefined,
        isEnabled: this.isAdministrator ? this.isTenantEnabled.value : undefined
      });

    await this.fetchUserState.update();
  }
}


/**
 * Returns the modulo of two numbers.
 * @param n The dividend.
 * @param m The divisor.
 * @returns The modulo.
 */
export function modulo(n: number, m: number): number {
  // https://stackoverflow.com/a/17323608/37725
  return ((n % m) + m) % m;
}

import { SimType } from './sim-type';

/**
 * Get the fully qualified channel name for a given channel name and simulation type.
 * @param channelName The channel name.
 * @param simType The simulation type.
 * @returns The fully qualified channel name.
 */
export function getFullyQualifiedChannelName(channelName: string, simType: SimType) {
  return simType ? channelName + ':' + simType : channelName;
}

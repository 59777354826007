import {Injectable} from '@angular/core';
import {StudyStagingArea} from '../../study-staging-area/study-staging-area.service';
import {DocumentSubType} from '../../../../generated/api-stubs';
import {StudyTypeLookup, StudyTypeMap} from '../../studies/study-type-lookup.service';
import {ConfigTypeLookup} from '../config-types';
import {CanopyError} from '../../../common/errors/errors';
import {AutocompleteOption, EditorAutocompleteInformation} from './editor-autocomplete-information';

export const PARAMETER_PATH_SUFFIX = 'parameterPath';

@Injectable()
export class GetStudyAutocompleteOptions{

  private studyTypeMap: StudyTypeMap;

  constructor(
    private readonly studyStagingArea: StudyStagingArea,
    private readonly studyTypeLookup: StudyTypeLookup){
  }

  public async initialize(){
    this.studyTypeMap = await this.studyTypeLookup.getStudyTypeMap();
  }

  public async execute(): Promise<EditorAutocompleteInformation> {
    await this.initialize();
    return this.executeSynchronous();
  }

  public executeSynchronous(
    excludeExploration: boolean = true,
    restrictToSelectedStudyType: boolean = true,
    desiredConfig?: DocumentSubType): EditorAutocompleteInformation {

    if(!this.studyTypeMap){
      throw new CanopyError('GetStudyAutocompleteOptions was not initialized.');
    }

    let currentInputs = this.studyStagingArea.state.inputs;

    let studyType = this.studyTypeMap[this.studyStagingArea.state.studyType];
    let restrictedConfigTypes = currentInputs.map(v => v.configType);
    if(restrictToSelectedStudyType && studyType){
      restrictedConfigTypes = studyType.inputs.map(studyInput => studyInput.configType);
    }
    if(desiredConfig){
      restrictedConfigTypes = restrictedConfigTypes.filter(v => v === desiredConfig);
    }

    let userInformation = [];
    let list: AutocompleteOption[] = [];
    for(let configType of restrictedConfigTypes){
      if(excludeExploration && configType === DocumentSubType.exploration){
        continue;
      }

      if(!currentInputs.find(v => v.configType === configType)){
        let configTypeData = ConfigTypeLookup.get(<string>configType);
        let configTypeName = configTypeData ? configTypeData.titleName : <string>configType;
        if(!desiredConfig){
          list.push(configType);
        }
        userInformation.push(`${configTypeName} should be staged to enable ${configTypeName} autocomplete.`);
      }
    }

    let dataLookup = {};
    for(let input of currentInputs){
      if(excludeExploration && input.configType === DocumentSubType.exploration){
        continue;
      }

      if(restrictedConfigTypes.indexOf(input.configType) === -1){
        continue;
      }

      if(!desiredConfig) {
        list.push(input.configType);
      }
      list.push(...this.getOptions(input.data, desiredConfig ? '' : input.configType, dataLookup));
    }

    let defaultSortFunction = function(a: string, b: string) {
      if (a === b) {
        return 0;
      }
      return a < b ? -1 : 1;
    };

    return new EditorAutocompleteInformation(
      list,
      userInformation,
      {
        list,
        minChars: 0,
        maxItems: 100,
        autoFirst: true,
        filter: (text: string, input: string) => {
          let lastPeriodIndex = text.lastIndexOf('.');
          if(lastPeriodIndex === -1){
            return text.indexOf(input) === 0 && (text as any).label !== input;
          }

          let textParent = text.substr(0, lastPeriodIndex - 1);
          return text.indexOf(input) === 0 && textParent.indexOf(input) === -1 && (text as any).label !== input;
        },
        sort(a: string, b: string) {
          let aIsArray = a.substr(a.length - 1) === ']';
          let bIsArray = b.substr(b.length - 1) === ']';
          if(aIsArray){
            if(!bIsArray){
              return 1;
            }
          } else{
            if(bIsArray){
              return -1;
            }
          }

          return defaultSortFunction(a.toLowerCase(), b.toLowerCase());
        }
      });
  }


  private getOptions(node: any, path: string, dataLookup: any): AutocompleteOption[] {
    const maximumArrayLength = 100;
    let result: AutocompleteOption[] = [];

    if(typeof node === 'object'){
      let isArray = Object.prototype.toString.call(node) === '[object Array]';
      if(isArray){
        if(node.length < maximumArrayLength && path){
          let index = 0;
          for(let item of node){
            let option = `${path}[${index}]`;
            result.push(option);
            if(item && item.name){
              let namedOption = `${path}[${item.name}]`;
              dataLookup[namedOption] = option;
              result.push({ label: namedOption, value: option });
            }
            result.push(...this.getOptions(item, option, dataLookup));
            ++index;
          }
        }
      } else{
        for(let key in node){
          if(!node.hasOwnProperty(key)){
            continue;
          }
          let option = (path ? path + '.' : '') + key;
          result.push(option);
          result.push(...this.getOptions(node[key], option, dataLookup));
        }
      }
    }

    return result;
  }
}

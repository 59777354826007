import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken } from '@ngxs/store';
import { ErrorMessageService } from '../../../common/errors/services/error-message/error-message.service';
import { UserChangedMessage } from '../../../identity/state/identity.actions';
import { EncryptionKeyPermission, EncryptionStub } from '../../../../generated/api-stubs';
import { ClearKeyPermission, DeleteKeyPermission, FetchKeyPermissions, PutKeyPermission, SetKeyPermission } from './key-permissions.actions';

export interface KeyPermissionsStateModel {
  permissions: EncryptionKeyPermission[];
  selectedPermission: EncryptionKeyPermission;
}

const STATE_TOKEN = new StateToken<KeyPermissionsStateModel>('keyPermissions');

@State({
  name: STATE_TOKEN
})
@Injectable()
export class KeyPermissionsState {
  constructor(
    private _errorMessageService: ErrorMessageService,
    private _encryptionStub: EncryptionStub) {}

  @Action(UserChangedMessage)
  public userChanged(ctx: StateContext<KeyPermissionsStateModel>): void {
    ctx.setState({
      permissions: [],
      selectedPermission: undefined,
    });
  }

  @Action(FetchKeyPermissions)
  public async fetchKeyPermission(ctx: StateContext<KeyPermissionsStateModel>, action: FetchKeyPermissions): Promise<void> {
    this._errorMessageService.clearErrorMessage();
    try {
      const { tenantId } = action;
      const keyPermissionsResult = await this._encryptionStub.getKeyPermissions(tenantId);
      ctx.patchState({
        permissions: keyPermissionsResult.permissions,
        selectedPermission: undefined,
      });
    } catch (err) {
      this._errorMessageService.setErrorMessage(err);
    }
  }

  @Action(SetKeyPermission)
  public setKeyPermission(ctx: StateContext<KeyPermissionsStateModel>, action: SetKeyPermission): void {
    const { encryptingTenantShortName, minimumSimVersion, description } = action;
    ctx.patchState({
      selectedPermission: {
        encryptingTenantShortName,
        minimumSimVersion,
        description
      }
    });
  }

  @Action(ClearKeyPermission)
  public clearKeyPermission(ctx: StateContext<KeyPermissionsStateModel>): void {
    ctx.patchState({
      selectedPermission: undefined
    });
  }

  @Action(PutKeyPermission)
  public async putKeyPermission(ctx: StateContext<KeyPermissionsStateModel>, action: PutKeyPermission): Promise<void> {
    this._errorMessageService.clearErrorMessage();
    try {
      const { tenantId, encryptingTenantShortName, minimumSimVersion, description } = action;
      await this._encryptionStub.putKeyPermission(
        tenantId,
        encryptingTenantShortName,
        {
          minimumSimVersion,
          description
        });
    } catch (err) {
      this._errorMessageService.setErrorMessage(err);
      throw err;
    }
  }

  @Action(DeleteKeyPermission)
  public async deleteKeyPermission(ctx: StateContext<KeyPermissionsStateModel>, action: DeleteKeyPermission): Promise<void> {
    this._errorMessageService.clearErrorMessage();
    try {
      const { tenantId, encryptingTenantShortName } = action;
      await this._encryptionStub.deleteKeyPermission(tenantId, encryptingTenantShortName);
    } catch (err) {
      this._errorMessageService.setErrorMessage(err);
    }
  }
}

import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ErrorHandlingResolve } from '../../common/errors/error-handling-resolve';
import { ErrorMessageService } from '../../common/errors/services/error-message/error-message.service';
import { InferableRouteParams } from '../../common/inferable-route-params.service';
import { ConfigPermissionsService } from './services/config-permissions.service';


@Injectable()
export class ConfigPermissionsResolve extends ErrorHandlingResolve<void> {

  constructor(
    router: Router,
    errorMessageService: ErrorMessageService,
    private _inferableRouteParams: InferableRouteParams,
    private _configPermissionService: ConfigPermissionsService) {
    super(router, errorMessageService);
  }

  protected async resolveInner(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<void> {
    const tenantId = this._inferableRouteParams.getTenantId(route);
    await this._configPermissionService.fetchConfigPermissions(tenantId).toPromise();
  }
}

import {DocumentSubType, StudyReference, StudyStub} from '../../generated/api-stubs';
import {Injectable} from '@angular/core';
import {GetSimVersion} from '../common/get-sim-version.service';
import {StudyInput} from './study-input';
import {studyResultToStudyInput} from './study-input-utilities';

/**
 * Loads a config from a study input.
 */
@Injectable()
export class LoadConfigFromStudyInput {

  /**
   * Creates an instance of LoadConfigFromStudyInput.
   * @param getSimVersion The service for getting the current sim version.
   * @param studyStub The study stub.
   */
  constructor(
    private readonly getSimVersion: GetSimVersion,
    private readonly studyStub: StudyStub){
  }

  /**
   * Loads a config from a study input.
   * @param configType The type of config to load.
   * @param studyReference The reference to the study.
   * @param simVersion The simulation version.
   * @returns The study input, or undefined if the config reference could not be loaded.
   */
  public async execute(configType: DocumentSubType, studyReference: StudyReference, simVersion?: string): Promise<StudyInput | undefined>{
    simVersion = simVersion || this.getSimVersion.currentSimVersion;

    const studyResult = await this.studyStub.getStudy(
      studyReference.tenantId,
      studyReference.targetId,
      simVersion);

    return studyResultToStudyInput(configType, studyResult);
  }
}

import { Injectable } from '@angular/core';
import { JsonEditorCustomization } from './json-editor-customization';
import{JSONEditor} from'@json-editor/json-editor'

@Injectable()
export class HiddenEnumEditor extends JsonEditorCustomization {
  public apply(): void {
    JSONEditor.defaults.editors.hiddenEnum = class extends JSONEditor.defaults.editors.string {
      build() {
        this.format = this.schema.format;
        this.input_type = 'hidden';
        this.input = this.theme.getFormInputField(this.input_type);

        this.control = this.theme.getFormControl(this.label, this.input, this.description);
        this.container.appendChild(this.control);
        this.refreshValue();
      }

      setValue(val: any, initial: any, from_template: boolean) {
        // Only set the value if we are setting the initial value.
        // Hidden enums can only have one value, so a branches value should never
        // be changed after it has been created. If it looks like it should change,
        // then the wrong branch must have been selected.
        // This allows us to safely use keep_oneof_values.
        if (!this.value) {
          super.setValue(val, initial, from_template);
        }
      }
    }
  }
}

import {DocumentSubType, GetStudyJobQueryResult, GetStudyQueryResult} from '../../../generated/api-stubs';
import {ConfigTypeLookup, sortByConfigType} from '../configs/config-types';
import {getSourceConfig, inferInputConfigTypes} from '../../worksheets/study-utilities';
import {Injectable} from '@angular/core';
import {SourceItem} from '../configs/view-study-sources/source-item';

@Injectable()
export class InferInputSources {
  public execute(
    studyResult: GetStudyQueryResult,
    jobResult?: GetStudyJobQueryResult): SourceItem[] {

    let metadataSources = (studyResult.study.data.sources || [])
      .filter(v => getSourceConfig(v.configType, studyResult, jobResult))
      .map(v => {
        let name = this.updateNameFromExplorationChanges(v.name, v.configType, jobResult);

        return new SourceItem(
          ConfigTypeLookup.get(v.configType).titleName,
          v.configType,
          v.userId,
          v.configId,
          name,
          v.isEdited);
      });

    let inferredSources = inferInputConfigTypes(studyResult).map(v => {
      let titleName = ConfigTypeLookup.get(v).titleName;
      let name = this.updateNameFromExplorationChanges(titleName, v, jobResult);
      return new SourceItem(
        titleName,
        v,
        undefined,
        undefined,
        name,
        false);
    });

    let mergedSources = [...metadataSources];
    for (let inferredSource of inferredSources) {
      if (mergedSources.every(v => v.typeKey !== inferredSource.typeKey)) {
        mergedSources.push(inferredSource);
      }
    }

    // Jobs don't have explorations.
    if (jobResult) {
      mergedSources = mergedSources.filter(v => v.typeKey !== DocumentSubType.exploration);
    }

    sortByConfigType(mergedSources, v => v.typeKey);

    return mergedSources;
  }

  private updateNameFromExplorationChanges(name: string, configType: DocumentSubType, jobResult?: GetStudyJobQueryResult): string {
    if(jobResult){
      let matchedChange = jobResult.studyJob.data.changes.find(change => change.path === configType);
      if(matchedChange){
        name = matchedChange.savedConfig.name;
      }
    }

    return name;
  }
}

import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {InferableRouteParams} from '../../../common/inferable-route-params.service';
import {JOB_ID_URL_PARAMETER_KEY, STUDY_ID_URL_PARAMETER_KEY} from '../../../common/constants';
import {getJobIdFromJobIndexOrJobId} from '../../../common/get-job-id-from-job-index';

@Component({
  templateUrl: './view-job.page.html'
})
export class ViewJobPage {
  public tenantId: string;
  public userId: string;
  public studyId: string;
  public jobId: string;

  constructor(
    route: ActivatedRoute,
    inferableRouteParams: InferableRouteParams){
    this.tenantId = inferableRouteParams.getTenantId(route);
    this.studyId = route.snapshot.params[STUDY_ID_URL_PARAMETER_KEY];
    this.jobId = getJobIdFromJobIndexOrJobId(
      this.studyId,
      route.snapshot.params[JOB_ID_URL_PARAMETER_KEY]);
  }
}

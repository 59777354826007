import {Component, OnInit} from '@angular/core';
import {InferableRouteParams} from '../../common/inferable-route-params.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Timer} from '../../common/timer.service';

export const WORKSHEET_ID_URL_PARAMETER_KEY: string = 'worksheetId';

/**
 * Renders the worksheet component and
 * displays any configs or studies the user opens within the worksheet.
 */
@Component({
  templateUrl: './edit-worksheet.page.html',
  styleUrls: ['./edit-worksheet.page.scss']
})
export class EditWorksheetPage implements OnInit {

  /**
   * The tenant ID of the worksheet.
   */
  public tenantId: string;

  /**
   * The worksheet ID.
   */
  public worksheetId: string;

  /**
   * This is used as a toggle to force the child route to reload.
   */
  public hideRoute: boolean = false;

  /**
   * Indicates if a child route exists, i.e. whether the user has a config
   * or study open in the worksheet.
   */
  public hasChildRoute: boolean = false;

  /**
   * The URL of the worksheet.
   */
  public worksheetUrl: ReadonlyArray<string>;

  /**
   * Creates an instance of EditWorksheetPage.
   * @param router The router.
   * @param route The route.
   * @param inferableRouteParams The service to get optional route parameters.
   * @param timer The timer.
   */
  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    inferableRouteParams: InferableRouteParams,
    private readonly timer: Timer) {
    this.tenantId = inferableRouteParams.getTenantId(route);
    this.worksheetId = route.snapshot.params[WORKSHEET_ID_URL_PARAMETER_KEY];

    this.worksheetUrl = this.route.snapshot.url.map(v => v.path);
    this.hasChildRoute = route.children.length > 0;
    this.router.events.subscribe(async event => {
      if (event instanceof NavigationEnd) {

        // Force the child route to reload.
        this.hideRoute = true;
        await this.timer.yield();
        this.hideRoute = false;

        this.hasChildRoute = route.children.length > 0;
      }
    });
  }

  /**
   * Initializes the component.
   */
  public async ngOnInit(){
    // Force the child route to reload (it is fine to do this even if there is no child route).
    this.hideRoute = true;
    await this.timer.yield();
    this.hideRoute = false;
  }

  /**
   * Dismisses the child route, so the worksheet is the route.
   */
  public dismissChildRoute(){
    this.router.navigate([...this.worksheetUrl]);
  }
}

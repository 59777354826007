import {Injectable} from '@angular/core';
import {StudyStagingArea} from './study-staging-area/study-staging-area.service';
import {SimVersionStub, ConfigStub} from '../../generated/api-stubs';
import {
  CompareConfigDialog,
} from './configs/comparing/compare-config-dialog/compare-config-dialog.service';
import {ConfirmationDialog} from '../common/dialogs/confirmation-dialog.service';
import {CanopyJson} from '../common/canopy-json.service';
import {LoadingDialog, DialogCallbacks} from '../common/dialogs/loading-dialog.service';
import {GetSimVersion} from '../common/get-sim-version.service';
import {StudyInput} from '../worksheets/study-input';
import {configResultToStudyInput, defaultConfigToStudyInput} from '../worksheets/study-input-utilities';
import {ConfigOrConfigLoader} from './configs/comparing/config-or-config-loader';
import { AuthenticationService } from '../identity/state/authentication.service';

@Injectable()
export class CompareStagedInputToSource{
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly configStub: ConfigStub,
    private readonly simVersionStub: SimVersionStub,
    private readonly studyStagingArea: StudyStagingArea,
    private readonly compareConfigDialog: CompareConfigDialog,
    private readonly confirmationDialog: ConfirmationDialog,
    private readonly loadingDialog: LoadingDialog,
    private readonly json: CanopyJson,
    private readonly getSimVersion: GetSimVersion/*,
    private readonly saveToStagingAreaHandlerFactory: SaveToStagingAreaHandlerFactory*/){
  }

  public async execute(studyInput: StudyInput): Promise<any> {
    let compareData = await this.loadingDialog.show<LoadedCompareData>(cb => this.loadDataToCompare(cb, studyInput));
    if(compareData){
      await this.compareConfigDialog.compare(
        studyInput.configType,
        [
          new ConfigOrConfigLoader('staged', compareData.staged, undefined),
          new ConfigOrConfigLoader('source config', compareData.source, undefined),
        ],
        undefined); //this.saveToStagingAreaHandlerFactory.create());
    } else{
      await this.confirmationDialog.show(
        'The source config no longer exists.',
        'Source Not Found',
        'OK',
        'OK');
    }
  }

  private async loadDataToCompare(callback: DialogCallbacks, studyInput: StudyInput): Promise<LoadedCompareData>{
    try{
      return await this.loadDataToCompareInner(callback, studyInput);
    } catch(error){
      if (error.isFromApi && error.response.status === 404) {
        return undefined;
      } else {
        throw error;
      }
    }
  }

  private async loadDataToCompareInner(callback: DialogCallbacks, studyInput: StudyInput): Promise<LoadedCompareData>{
    callback.setStatus('Loading source data...');
    const userData = this.authenticationService.userDataSnapshot;

    let existing = await this.getExistingConfig(userData.tenant, studyInput);
    if(!existing){
      return undefined;
    }

    callback.setStatus('Upgrading staged data...');
    let convertedInput = await this.studyStagingArea.getInput(studyInput.configType, existing.simVersion);

    return {
      staged: convertedInput,
      source: existing,
    };
  }

  private getExistingConfig(tenantId: string, studyInput: StudyInput){
    if(studyInput.userId){
      return this.getExistingUserConfig(tenantId, studyInput);
    } else{
      return this.getExistingDefaultConfig(tenantId, studyInput);
    }
  }

  private async getExistingUserConfig(tenantId: string, studyInput: StudyInput): Promise<StudyInput>{
    let getConfigResult = await this.configStub.getConfig(
      tenantId,
      studyInput.configId,
      undefined,
      this.getSimVersion.currentSimVersion);

    return configResultToStudyInput(getConfigResult);
  }

  private async getExistingDefaultConfig(tenantId: string, studyInput: StudyInput): Promise<StudyInput>{
    let documentResult = await this.simVersionStub.getDocument(studyInput.simVersion, studyInput.configId, tenantId);
    if(!documentResult){
      return undefined;
    }

    let data = this.json.parse(documentResult.document.content);
    return defaultConfigToStudyInput(studyInput.configType, studyInput.name, data, studyInput.simVersion);
  }
}

interface LoadedCompareData {
  staged: StudyInput;
  source: StudyInput;
}

import { LoadedVectorMetadataResult } from './load-vector-metadata-map';
import { LoadedChannelMetadata, ViewerChannelDataFactory } from './viewer-channel-data-factory';
import { IViewerChannelData } from './viewer-channel-data';
import { getFullyQualifiedChannelName } from '../../get-fully-qualified-channel-name';
import { ChannelNameStyle } from './channel-name-style';
import { SimType } from '../../sim-type';

/**
 * Get's the metadata for a study job channel in a standard format.
 */
export class GetStudyJobChannelMetadata {
  constructor(
    private readonly channelDataFactory: ViewerChannelDataFactory) {
  }

  /**
   * Gets the metadata for a study job channel in a standard format.
   * @param requestedName The name of the channel requested.
   * @param simType The sim type.
   * @param vectorMetadataResult The vector metadata result.
   * @param resultChannelNameStyle The channel name style to use for the result.
   * @param xDomainName The name of the x domain.
   * @returns The metadata for the channel.
   */
  public execute(
    requestedName: string,
    simType: SimType,
    vectorMetadataResult: LoadedVectorMetadataResult,
    resultChannelNameStyle: ChannelNameStyle,
    xDomainName: string): StudyJobChannelMetadataResult {

    let loadedMetadata = this.loadChannelMetadata(requestedName, simType, vectorMetadataResult, xDomainName);
    const resolvedMetadata = this.channelDataFactory.createChannelMetadata(requestedName, loadedMetadata, resultChannelNameStyle);

    return new StudyJobChannelMetadataResult(loadedMetadata, resolvedMetadata);
  }

  /**
   * Extracts the channel metadata from the vector metadata result map.
   * @param requestedName The name of the channel requested.
   * @param simType The sim type.
   * @param vectorMetadataResult The vector metadata result.
   * @param xDomainName The name of the x domain.
   * @returns The channel metadata.
   */
  private loadChannelMetadata(
    requestedName: string,
    simType: SimType,
    vectorMetadataResult: LoadedVectorMetadataResult,
    xDomainName: string): LoadedChannelMetadata | undefined {

    let channel = vectorMetadataResult.map[requestedName];
    if (!channel) {
      return undefined;
    }

    // If the X domain of the channel didn't match the requested xDomain.
    if (xDomainName !== channel.xDomainName) {

      // If the requested xDomain does exist in the list of sources X domains, then the requested channel doesn't exist.
      if (vectorMetadataResult.allDomainNames.indexOf(xDomainName) !== -1) {
        return undefined;
      }

      // If the found channel isn't part of the default domain for the source, then the requested channel doesn't exist.
      if (channel.xDomainName !== vectorMetadataResult.defaultDomainName) {
        return undefined;
      }

      // Otherwise the channel is not part of the requested domain,
      // and the source is not aware of the requested domain,
      // and the channel is part of the source's default domain,
      // so we return this channel as our best match.
      // This allows us to overlay, e.g. time based data with distance based data.
    }

    return new LoadedChannelMetadata(
      channel.name,
      getFullyQualifiedChannelName(channel.name, simType),
      channel.description,
      channel.units,
      channel.binaryFormat,
      channel.loaderMetadata);
  }
}

/**
 * The result of getting the metadata for a study job channel in a standard format.
 */
export class StudyJobChannelMetadataResult {
  constructor(
    public readonly loadedMetadata: LoadedChannelMetadata | undefined,
    public readonly viewerMetadata: IViewerChannelData) {
  }
}


import { DisplayableError } from '../../../displayable-error';

/**
 * A set of relative coordinates for a set of points in the hypercube.
 * Each dimension should be a single point with one dimension allowed to specify
 * multiple points to explore.
 */
export class InterpolationCoordinates {

  /**
   * Whether the interpolation coordinates contain a dimension with multiple coordinates.
   */
  public readonly hasExplorationDimension: boolean;

  /**
   * The index of the dimension that will be explored.
   */
  public readonly explorationDimensionIndex: number;

  /**
   * The number of points in the dimension being explored.
   */
  public readonly coordinateSetCount: number;

  /**
   * Create a new set of interpolation coordinates.
   * @param dimensions The dimensions of the interpolation coordinates.
   * @param explorationDimensionIndex The index of the dimension that will be explored. This is optional and will be determined automatically.
   */
  constructor(
    public readonly dimensions: ReadonlyArray<InterpolationCoordinatesDimension>,
    explorationDimensionIndex: number) {

    if (!dimensions) {
      throw new Error('Interpolation coordinates must specify dimensions list.');
    }

    this.hasExplorationDimension = false;
    this.explorationDimensionIndex = explorationDimensionIndex;
    this.coordinateSetCount = 1;

    for (let i = 0; i < dimensions.length; ++i) {
      let dimension = dimensions[i];
      if (!dimension || !dimension.coordinates || dimension.coordinates.length === 0) {
        throw new DisplayableError(`Interpolation dimension ${i} does not contain any coordinates. Most likely all points failed.`);
      }

      if (dimension.coordinates.length > 1) {
        if (this.hasExplorationDimension) {
          throw new Error('Interpolation coordinates can only contain one dimension with multiple coordinates specified.');
        }

        this.hasExplorationDimension = true;
        this.explorationDimensionIndex = i;
        this.coordinateSetCount = dimension.coordinates.length;
      }
    }
  }

  /**
   * Get's the coordinates for a specific index in the dimension being explored.
   * For example, if the interpolation coordinates looks like this:
   * [
   *  [2],
   *  [4],
   *  [1, 3, 5],
   *  [6],
   * ]
   * Then getCoordinatesSet(1) would return [2, 4, 3, 6].
   * @param index The index in the dimension being explored.
   * @returns The coordinates for the specified index.
   */
  public getCoordinatesSet(index: number): number[] {
    if (index >= this.coordinateSetCount) {
      throw new Error('Requested coordinate set index out of range.');
    }

    let result = this.dimensions.map(v => v.coordinates[0]);
    if (index > 0) {
      result[this.explorationDimensionIndex] = this.dimensions[this.explorationDimensionIndex].coordinates[index];
    }
    return result;
  }

  /**
   * Get all the coordinates sets for the dimension being explored.
   * @returns All the coordinates sets for the dimension being explored.
   */
  public getCoordinatesSets(): number[][] {
    let result: number[][] = [];
    for (let i = 0; i < this.coordinateSetCount; ++i) {
      result.push(this.getCoordinatesSet(i));
    }
    return result;
  }

}

/**
 * A single dimension of coordinates in the hypercube.
 */
export class InterpolationCoordinatesDimension {

  /**
   * Creates a new instance of InterpolationCoordinatesDimension.
   * @param coordinates The coordinates of the dimension.
   */
  constructor(
    public readonly coordinates: ReadonlyArray<number>) {
  }
}

/**
 * A factory for creating interpolation coordinates.
 */
class InterpolationCoordinatesFactoryImplementation {

  /**
   * Creates a new instance of InterpolationCoordinates.
   * @param setOfPoints The set of points to create the interpolation coordinates from.
   * @param explorationDimensionIndex The index of the dimension that will be explored. This is optional and will be determined automatically.
   * @returns A new instance of InterpolationCoordinates.
   */
  public fromArray(setOfPoints: ReadonlyArray<ReadonlyArray<number>>, explorationDimensionIndex: number = -1): InterpolationCoordinates {
    return new InterpolationCoordinates(setOfPoints.map(v => new InterpolationCoordinatesDimension(v)), explorationDimensionIndex);
  }
}

/**
 * A factory for creating interpolation coordinates.
 */
export const InterpolationCoordinatesFactory = new InterpolationCoordinatesFactoryImplementation();

import {GetStudyQueryResult, StudyStub} from '../../../../generated/api-stubs';
import {LazyLoaded} from './lazy-loaded';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {Injectable} from '@angular/core';

@Injectable()
export class LazyLoadedStudyFactory {
  constructor(
    private readonly studyStub: StudyStub,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  public create(tenantId: string, studyId: string, simVersion: string): LazyLoaded<GetStudyQueryResult>{
    return new LazyLoaded<GetStudyQueryResult>(
      () => this.studyStub.getStudy(
          tenantId,
          studyId,
          simVersion),
      this.getFriendlyErrorAndLog);
  }
}

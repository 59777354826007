import * as d3 from '../../d3-bundle';
import { DesignName } from './exploration-map';
import { Utilities } from '../../utilities';
import { ExplorationSubSweepDataType } from './explorations/exploration-sub-sweep';
import { ExplorationJobSweep } from './explorations/exploration-job-sweep';
import { ProcessedExplorationMap } from './explorations/processed-exploration-map';


/**
 * Gets the exploration data for a single dimension.
 */
export class GetSingleDimensionEvaluationData {

  /**
   * Extracts the exploration data for a single dimension from an exploration map.
   * @param map The exploration map.
   * @param dimensionIndex The dimension index.
   * @returns The exploration data for the single dimension.
   */
  public execute(map: ProcessedExplorationMap, dimensionIndex: number): DimensionEvaluationData {

    if (dimensionIndex >= map.inputs.sweeps.length) {
      throw new Error(`Dimension index ${dimensionIndex} requested but there are only ${map.inputs.sweeps.length} dimensions.`);
    }

    // Get the sub-sweeps for the dimension.
    let dimensionSubSweeps = map.inputs.sweeps[dimensionIndex].subSweeps;

    let jobsWithSweep: ExplorationJobSweep[] = [];

    // Pull out the data type of each sub-sweep input (e.g. numeric, string, bool.)
    let dataTypes: ExplorationSubSweepDataType[] = dimensionSubSweeps.map(v => v.dataType);

    switch (map.designName) {
      case DesignName.monteCarlo:
      case DesignName.factorialMonteCarlo:

        // For monte carlo and factorial monte-carlo designs we interpolate the data over a regular grid.
        let numberOfPointsToEvaluate = 21;
        const generatedNormalizedValues = Utilities.linspace([0, 1], numberOfPointsToEvaluate);
        const normalizedIndices = generatedNormalizedValues;
        const transposedNumericValues: ReadonlyArray<number>[] = [];
        const transposedNormalizedValues: ReadonlyArray<number>[] = [];
        for (let parameterIndex = 0; parameterIndex < dimensionSubSweeps.length; parameterIndex++) {

          // Create an array of the values for each job for this dimension and parameter.
          const rawValues = map.jobs.map(job => (job.sweeps[dimensionIndex].subSweepNumericValues[parameterIndex]));

          // Create a linear set of points over the parameter space.
          const parameterNumericValues = Utilities.linspace(
            [
              d3.minStrict(rawValues),
              d3.maxStrict(rawValues)
            ],
            numberOfPointsToEvaluate);

          // Add the linear spaced values and their normalized counterparts to the arrays.
          transposedNumericValues.push(parameterNumericValues);
          transposedNormalizedValues.push(normalizedIndices);
        }

        // Transpose, so that instead of having one job per column we have one job per row.
        const numericValues = Utilities.transpose(transposedNumericValues);
        const normalizedValues = Utilities.transpose(transposedNormalizedValues);

        // Then split the data by job, so that for each job we have the numeric values, the normalized
        // values, the job index, and the normalized indices.
        // Monte carlo only supports numeric values, which is why the first two parameters are the same.
        jobsWithSweep = generatedNormalizedValues.map((_, jobIndex) => new ExplorationJobSweep(
          numericValues[jobIndex],
          numericValues[jobIndex],
          normalizedValues[jobIndex],
          jobIndex,
          normalizedIndices[jobIndex]));
        break;

      case DesignName.factorial:
      case DesignName.star:
        // Each sweep is a dimension, so for each job pull out the sweep for the given dimension index.
        // We then only want unique sets of sub-sweep values.
        jobsWithSweep = Utilities.uniqueValue(map.jobs.map(job => job.sweeps[dimensionIndex]), v => v.subSweepValues);

        // Remove any jobs don't have data for this dimension. E.g. in a star each job only has data for one of the
        // dimensions.
        Utilities.strip(
          Utilities.findIndices(jobsWithSweep, j => isNaN(j.subSweepIndex)),
          jobsWithSweep);
        break;
    }

    return new DimensionEvaluationData(jobsWithSweep, dataTypes);
  }
}

/**
 * The exploration data for a single dimension.
 */
export class DimensionEvaluationData {

  /**
   * Creates a new instance of the DimensionEvaluationData class.
   * @param jobsWithSweep The list of jobs for this dimension.
   * @param dataTypes The data types for each sub-sweep input.
   */
  constructor(
    public readonly jobsWithSweep: ReadonlyArray<ExplorationJobSweep>,
    public readonly dataTypes: ReadonlyArray<ExplorationSubSweepDataType>) {
  }
}

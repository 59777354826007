import { STAR_CONTAINING_NAN_SCALAR_RESULTS_IN_ALL_LAPS_NAME } from '../test-area-names';
import { NavigationStation } from '../../navigation-station/navigation-station';
import { BootstrapNavigationStationBase } from '../bootstrap-navigation-station-base';
import { StarConfigBuilder } from '../../navigation-station/config-builders/star-config-builder';
import { UserData } from '../../../identity/state/authentication.service';
import { CanopyFileLoader } from '../../../simulations/visualizations/canopy-file-loader.service';
import { CanopySiteHooks } from '../../../simulations/visualizations/canopy-site-hooks.service';
import { MockFileLoader } from '../mock-url-file-loader';

export class BootstrapStarContainingNaNScalarResultsInAllLaps extends BootstrapNavigationStationBase {
  public get name() {
    return STAR_CONTAINING_NAN_SCALAR_RESULTS_IN_ALL_LAPS_NAME;
  }

  public get description() {
    return 'A star sweep where every lap contains a channel with NaN scalar results.';
  }

  public createNavigationStation(elementId: string, userData: UserData, fileLoader: CanopyFileLoader, siteHooks: CanopySiteHooks): NavigationStation {

    let mockFileLoader = new MockFileLoader(fileLoader);

    this.configureMockExploration(
      mockFileLoader,
      '{ "designName": "Star", "basis": [["car.chassis.carRunningMass.mCar+"], ["car.powertrain.electric.electricMotors[0].deployment.eMotor="]], "coordinates": [[[-100.0], ["NaN"]], [[-80.0], ["NaN"]], [[-60.0], ["NaN"]], [[-40.0], ["NaN"]], [[-20.0], ["NaN"]], [[0.0], ["NaN"]], [[20.0], ["NaN"]], [[40.0], ["NaN"]], [[60.0], ["NaN"]], [[80.0], ["NaN"]], [[100.0], ["NaN"]], [["NaN"], [0.8]], [["NaN"], [0.82]], [["NaN"], [0.84]], [["NaN"], [0.86]], [["NaN"], [0.88]], [["NaN"], [0.9]], [["NaN"], [0.92]], [["NaN"], [0.94]], [["NaN"], [0.96]], [["NaN"], [0.98]], [["NaN"], [1.0]]], "rCoordinates": [[0.0, "NaN"], [0.1, "NaN"], [0.2, "NaN"], [0.3, "NaN"], [0.4, "NaN"], [0.5, "NaN"], [0.6, "NaN"], [0.7, "NaN"], [0.8, "NaN"], [0.9, "NaN"], [1.0, "NaN"], ["NaN", 0.0], ["NaN", 0.1], ["NaN", 0.2], ["NaN", 0.3], ["NaN", 0.4], ["NaN", 0.5], ["NaN", 0.6], ["NaN", 0.7], ["NaN", 0.8], ["NaN", 0.9], ["NaN", 1.0]], "jobNames": ["Sweep 1 Point 1", "Sweep 1 Point 2", "Sweep 1 Point 3", "Sweep 1 Point 4", "Sweep 1 Point 5", "Sweep 1 Point 6", "Sweep 1 Point 7", "Sweep 1 Point 8", "Sweep 1 Point 9", "Sweep 1 Point 10", "Sweep 1 Point 11", "Sweep 2 Point 1", "Sweep 2 Point 2", "Sweep 2 Point 3", "Sweep 2 Point 4", "Sweep 2 Point 5", "Sweep 2 Point 6", "Sweep 2 Point 7", "Sweep 2 Point 8", "Sweep 2 Point 9", "Sweep 2 Point 10", "Sweep 2 Point 11"], "indexMatrix": [[0.0, "NaN"], [1.0, "NaN"], [2.0, "NaN"], [3.0, "NaN"], [4.0, "NaN"], [5.0, "NaN"], [6.0, "NaN"], [7.0, "NaN"], [8.0, "NaN"], [9.0, "NaN"], [10.0, "NaN"], ["NaN", 0.0], ["NaN", 1.0], ["NaN", 2.0], ["NaN", 3.0], ["NaN", 4.0], ["NaN", 5.0], ["NaN", 6.0], ["NaN", 7.0], ["NaN", 8.0], ["NaN", 9.0], ["NaN", 10.0]] }',
      `jobIdx,zCarMin,zCarMax,aPitchStart,aPitchEnd,vCarMaxElectric,hRideF0,hRideF100,hRideF200,hRideF300,hRideFvMax,hRideR0,hRideR100,hRideR200,hRideR300,hRideRvMax,rAeroBal100,rAeroBal200,rAeroBal300,rAeroBalvMax
0,-0.302458,-0.293614,-0.0114217,-0.0113266,54.5987,0.0300525,0.0309508,0.0282727,0.0242457,0.0283894,0.0700276,0.0688177,0.0658232,0.0628124,0.0659321,0.467658,0.49826,0.547087,0.496719
1,-0.302452,-0.293642,-0.0114194,-0.0113078,54.5524,0.0300552,0.0309506,0.0282905,0.0242969,0.0284117,0.0700222,0.0688133,0.0658246,0.0628154,0.0659385,0.467674,0.498194,0.546907,0.496586
2,-0.302447,-0.293669,-0.011417,-0.0112901,54.5058,0.0300579,0.0309476,0.028306,0.0243454,0.0284318,0.0700166,0.0688088,0.0658268,0.0628184,0.0659457,0.467698,0.498125,0.546734,0.496449
3,-0.302442,-0.293695,-0.0114146,-0.0112734,54.4589,0.0300606,0.0309421,0.0283195,0.0243911,0.0284498,0.0700108,0.0688045,0.0658296,0.0628214,0.0659537,0.467728,0.498056,0.54657,0.496311
4,-0.302436,-0.293719,-0.0114121,-0.0112577,54.4116,0.0300633,0.0309342,0.0283311,0.0244342,0.0284658,0.0700048,0.0688005,0.0658328,0.0628243,0.0659621,0.467764,0.497987,0.546414,0.496174
5,-0.302431,-0.293743,-0.0114096,-0.0112429,54.3641,0.030066,0.0309243,0.0283411,0.0244748,0.0284801,0.0699986,0.0687968,0.0658361,0.0628271,0.0659707,0.467805,0.497922,0.546265,0.496039
6,-0.302426,-0.293765,-0.011407,-0.011229,54.3164,0.0300686,0.0309125,0.0283495,0.0245131,0.0284928,0.0699923,0.0687936,0.0658393,0.0628298,0.0659793,0.46785,0.497862,0.546125,0.495909
7,-0.30242,-0.293786,-0.0114044,-0.0112159,54.2685,0.0300713,0.030899,0.0283566,0.0245491,0.0285041,0.0699858,0.068791,0.0658423,0.0628322,0.0659877,0.467896,0.497808,0.545994,0.495785
8,-0.302415,-0.293806,-0.0114018,-0.0112036,54.2206,0.0300739,0.0308842,0.0283625,0.0245831,0.0285141,0.0699793,0.0687891,0.0658448,0.0628344,0.0659956,0.467945,0.497763,0.545872,0.49567
9,-0.30241,-0.293826,-0.0113992,-0.011192,54.1725,0.0300765,0.0308683,0.0283673,0.0246151,0.028523,0.0699727,0.0687874,0.0658467,0.0628361,0.066003,0.467995,0.497728,0.545761,0.495565
10,-0.302405,-0.293844,-0.0113965,-0.0111811,54.1244,0.0300791,0.0308514,0.0283713,0.0246453,0.028531,0.0699661,0.0687858,0.0658477,0.0628373,0.0660094,0.468049,0.497706,0.545661,0.495473
11,-0.302431,-0.293743,-0.0114096,-0.0112429,54.3641,0.030066,0.0309243,0.0283411,0.0244748,0.0284801,0.0699986,0.0687968,0.0658361,0.0628271,0.0659707,0.467805,0.497922,0.546265,0.496039
12,-0.302431,-0.293743,-0.0114096,-0.0112429,54.3641,0.030066,0.0309243,0.0283411,0.0244748,0.0284801,0.0699986,0.0687968,0.0658361,0.0628271,0.0659707,0.467805,0.497922,0.546265,0.496039
13,-0.302431,-0.293743,-0.0114096,-0.0112429,54.3641,0.030066,0.0309243,0.0283411,0.0244748,0.0284801,0.0699986,0.0687968,0.0658361,0.0628271,0.0659707,0.467805,0.497922,0.546265,0.496039
14,-0.302431,-0.293743,-0.0114096,-0.0112429,54.3641,0.030066,0.0309243,0.0283411,0.0244748,0.0284801,0.0699986,0.0687968,0.0658361,0.0628271,0.0659707,0.467805,0.497922,0.546265,0.496039
15,-0.302431,-0.293743,-0.0114096,-0.0112429,54.3641,0.030066,0.0309243,0.0283411,0.0244748,0.0284801,0.0699986,0.0687968,0.0658361,0.0628271,0.0659707,0.467805,0.497922,0.546265,0.496039
16,-0.302431,-0.293743,-0.0114096,-0.0112429,54.3641,0.030066,0.0309243,0.0283411,0.0244748,0.0284801,0.0699986,0.0687968,0.0658361,0.0628271,0.0659707,0.467805,0.497922,0.546265,0.496039
17,-0.302431,-0.293743,-0.0114096,-0.0112429,54.3641,0.030066,0.0309243,0.0283411,0.0244748,0.0284801,0.0699986,0.0687968,0.0658361,0.0628271,0.0659707,0.467805,0.497922,0.546265,0.496039
18,-0.302431,-0.293743,-0.0114096,-0.0112429,54.3641,0.030066,0.0309243,0.0283411,0.0244748,0.0284801,0.0699986,0.0687968,0.0658361,0.0628271,0.0659707,0.467805,0.497922,0.546265,0.496039
19,-0.302431,-0.293743,-0.0114096,-0.0112429,54.3641,0.030066,0.0309243,0.0283411,0.0244748,0.0284801,0.0699986,0.0687968,0.0658361,0.0628271,0.0659707,0.467805,0.497922,0.546265,0.496039
20,-0.302431,-0.293743,-0.0114096,-0.0112429,54.3641,0.030066,0.0309243,0.0283411,0.0244748,0.0284801,0.0699986,0.0687968,0.0658361,0.0628271,0.0659707,0.467805,0.497922,0.546265,0.496039
21,-0.302431,-0.293743,-0.0114096,-0.0112429,54.3641,0.030066,0.0309243,0.0283411,0.0244748,0.0284801,0.0699986,0.0687968,0.0658361,0.0628271,0.0659707,0.467805,0.497922,0.546265,0.496039
`,
`"name","units","description","simType"
"zCarMin","m","","StraightSim"
"zCarMax","m","","StraightSim"
"aPitchStart","rad","","StraightSim"
"aPitchEnd","rad","","StraightSim"
"vCarMaxElectric","m/s","","StraightSim"
"hRideF0","m","","StraightSim"
"hRideF100","m","","StraightSim"
"hRideF200","m","","StraightSim"
"hRideF300","m","","StraightSim"
"hRideFvMax","m","","StraightSim"
"hRideR0","m","","StraightSim"
"hRideR100","m","","StraightSim"
"hRideR200","m","","StraightSim"
"hRideR300","m","","StraightSim"
"hRideRvMax","m","","StraightSim"
"rAeroBal100","()","","StraightSim"
"rAeroBal200","()","","StraightSim"
"rAeroBal300","()","","StraightSim"
"rAeroBalvMax","()","","StraightSim"
`);

    return new NavigationStation(
      elementId,
      new StarConfigBuilder(
        mockFileLoader,
        siteHooks,
        [{ studyId: 'star-containing-nan-scalar-results-in-all-laps', jobIndex: 0 }],
        ['DeploymentLap', 'ApexSim', 'StraightSim']));
  }
}


/**
 * Check if the input is defined.
 * @param input The input to check.
 * @returns True if the input is defined, false otherwise.
 */
export function isDefined<T>(input: T | undefined): input is T {
  return !isUndefined(input);
}

/**
 * Check if the input is undefined.
 * @param input The input to check.
 * @returns True if the input is undefined, false otherwise.
 */
export function isUndefined<T>(input: T | undefined): input is undefined {
  return typeof input === 'undefined';
}

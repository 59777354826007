import { Selector } from '@ngxs/store';
import { EncryptionKeyPermission } from '../../../../generated/api-stubs';
import { KeyPermissionsState, KeyPermissionsStateModel } from './key-permissions.state';

export class KeyPermissionsSelectors {
  @Selector([KeyPermissionsState])
  public static selectedPermission(state: KeyPermissionsStateModel): EncryptionKeyPermission {
    return state.selectedPermission;
  }

  @Selector([KeyPermissionsState])
  public static permissions(state: KeyPermissionsStateModel): EncryptionKeyPermission[] {
    return state.permissions || [];
  }
}

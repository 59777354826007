import { Selector } from '@ngxs/store';
import { AuthenticationState, AuthenticationStateModel } from './authentication.state';

export class AuthenticationSelectors {

  @Selector([AuthenticationState])
  public static idToken(state: AuthenticationStateModel): string {
    return state.idToken;
  }

  @Selector([AuthenticationState])
  public static isAuthenticated(state: AuthenticationStateModel): boolean {
    return state.isAuthenticated;
  }

  @Selector([AuthenticationState])
  public static authToken(state: AuthenticationStateModel): string {
    return state.authToken;
  }

  @Selector([AuthenticationState])
  public static userData(state: AuthenticationStateModel): any {
    let userData = state.userData || { roles: [] };

    // If userData.roles is a string, make it an array of strings
    if (typeof userData.roles === 'string') {
      userData = {
        ...userData,
        roles: [userData.roles]
      };
    }

    return userData;
  }
}

import {Injectable} from '@angular/core';
import {DialogManager, IDialog, DialogBase} from '../../common/dialogs/dialog-manager.service';
import {DocumentSubType, DocumentType, GetSupportSessionQueryResult} from '../../../generated/api-stubs';

@Injectable()
export class SupportSessionDialog {
  constructor(
    private dialogManager: DialogManager
  ){}

  public async show(
    tenantId: string,
    documentId: string,
    documentType: DocumentType,
    documentSubType: DocumentSubType,
    loadedSession: GetSupportSessionQueryResult): Promise<SupportSessionResult> {

    let result = await this.dialogManager.showTertiaryDialog(
      new SupportSessionDialogData(
        tenantId,
        documentId,
        documentType,
        documentSubType,
        loadedSession));

    return result;
  }
}

export class SupportSessionDialogData extends DialogBase<SupportSessionResult> implements IDialog {
  constructor(
    public readonly tenantId: string,
    public readonly documentId: string,
    public readonly documentType: DocumentType,
    public readonly documentSubType: DocumentSubType,
    public readonly loadedSession: GetSupportSessionQueryResult) {
    super();
  }
}

export type SupportSessionResult = GetSupportSessionQueryResult;

<div  class="row">
  <div class="col-12">
    @for (message of infoMessages; track message) {
      <p>{{message}}</p>
    }
    @for (message of warnMessages; track message) {
      <div class="alert alert-warning">{{message}}</div>
    }
    <cs-error-message [message]="errorMessage"></cs-error-message>
    @for (message of errorMessages; track message) {
      <cs-error-message [message]="message"></cs-error-message>
    }
    @if (!documentsResult && !errorMessage) {
      <cs-loader></cs-loader>
    }
  </div>
</div>
@if (documentsResult) {
  <div  class="row">
    <div class="col-12">
      @if (!errorMessage && !errorMessages.length) {
        <div>
          <p>
            <button class="btn btn-primary btn-smart" (click)="submit()">
              Submit Telemetry
            </button>
          </p>
          <hr>
          </div>
        }
        <h2>Summary</h2>
        <table class="table">
          <tbody>
            <tr>
              <td>Channels Accepted</td>
              <td><strong>{{telemetryConfig.config.channels.length}}</strong></td>
            </tr>
            @if (timeChannel) {
              <tr>
                <td>Time Domain</td>
                <td><strong>{{timeChannelOriginalName}}</strong></td>
              </tr>
            }
            @if (distanceChannel) {
              <tr>
                <td>Distance Domain</td>
                <td><strong>{{distanceChannelOriginalName}}</strong></td>
              </tr>
            }
            @if (inputDataLength) {
              <tr>
                <td>Input Channel Length</td>
                <td><strong>{{inputDataLength}}</strong></td>
              </tr>
            }
            @if (outputDataLength && outputDataLength !== inputDataLength) {
              <tr>
                <td>Output Channel Length</td>
                <td><strong>{{outputDataLength}}</strong></td>
              </tr>
            }
            @if (addedChannels.length) {
              <tr>
                <td>Channels Generated</td>
                <td><strong>{{addedChannels.length}}</strong></td>
              </tr>
            }
            <tr>
              <td>Channels Renamed</td>
              <td><strong>{{renamedChannels.length}}</strong></td>
            </tr>
            @if (removedChannels.length) {
              <tr>
                <td>Channels Removed</td>
                <td><strong>{{removedChannels.length}}</strong></td>
              </tr>
            }
            @if (channelsUnitConverted) {
              <tr>
                <td>Channel Units Converted</td>
                <td><strong>{{channelsUnitConverted}}</strong></td>
              </tr>
            }
          </tbody>
        </table>
        @if (!errorMessage && telemetryConfig.config.channels.length) {
          <div>
            <hr>
              <h2>Preview</h2>
              <cs-error-message [message]="configPreviewErrorMessage"></cs-error-message>
              <div #configPreviewContainer id="import-telemetry-preview-container">
              </div>
            </div>
          }
          @if (addedChannels.length) {
            <div>
              <hr>
                <h2>Generated Channels</h2>
                <table class="table">
                  <thead>
                    <tr>
                      <th>Channel</th>
                      <th>Reason</th>
                    </tr>
                  </thead>
                  <tbody>
                    @for (channel of addedChannels; track channel) {
                      <tr>
                        <td>{{channel.name}}</td>
                        <td>{{channel.reason}}</td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            }
            @if (renamedChannels.length) {
              <div>
                <hr>
                  <h2>Renamed Channels</h2>
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Old Name</th>
                        <th>New Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      @for (channel of renamedChannels; track channel) {
                        <tr>
                          <td>{{channel.from}}</td>
                          <td>{{channel.to}}</td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              }
              @if (removedChannels.length) {
                <div>
                  <hr>
                    <h2>Removed Channels</h2>
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Channel</th>
                          <th>Reason</th>
                        </tr>
                      </thead>
                      <tbody>
                        @for (channel of removedChannels; track channel) {
                          <tr>
                            <td>{{channel.name}}</td>
                            <td>{{channel.reason}}</td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                }
                @if (unitConversions.length) {
                  <div>
                    <hr>
                      <h2>Unit Conversions</h2>
                      <table class="table">
                        <thead>
                          <tr>
                            <th>From</th>
                            <th>To</th>
                            <th>Channels</th>
                          </tr>
                        </thead>
                        <tbody>
                          @for (item of unitConversions; track item) {
                            <tr>
                              <td>{{item.from}}</td>
                              <td>{{item.to}}</td>
                              <td>{{item.channels.join(', ')}}</td>
                            </tr>
                          }
                        </tbody>
                      </table>
                    </div>
                  }
                  @if (outputTelemetry) {
                    <div>
                      <hr>
                        <h2>Modifications During Import</h2>
                        <cs-compare-config-structures
                          [configs]="[compareInputTelemetry, compareOutputTelemetry]">
                        </cs-compare-config-structures>
                      </div>
                    }
                  </div>
                </div>
              }

import * as d3 from '../../d3-bundle';
import { SVGSelection } from '../../untyped-selection';
import { IMargin } from '../margin';
import { ISize } from '../size';
import { Position } from '../position';
import { IPopulatedMultiPlotSide } from '../data-pipeline/types/i-populated-multi-plot-side';
import { IPopulatedMultiPlotLayout } from '../data-pipeline/types/i-populated-multi-plot-layout';

const HANDLE_LENGTH = 30;
const MINIMUM_PANE_SIZE = 20;

/**
 * Settings for the chart.
 */
interface IChartSettings {
  readonly svgPadding: IMargin;
  readonly chartMargin: IMargin;
  readonly chartSize: ISize;
  readonly spaceBetweenPlots: number;
}

/**
 * The type of resize handles (column or row resize handles).
 */
export enum PaneResizeHandlesType {

  /**
   * Column resize handles.
   */
  columns,

  /**
   * Row resize handles.
   */
  rows
}

/**
 * D3 style interface to the pane resize handles renderer.
 */
export class PaneResizeHandlesRenderer {

  /**
   * The inner renderer.
   */
  private inner = new PaneResizeHandlesRendererInner();

  /**
   * Renders the pane resize handles.
   * @param selection The selection to render the pane resize handles to.
   * @returns This object.
   */
  public render(selection: SVGSelection): this {
    this.inner.render(selection);
    return this;
  }

  /**
   * Sets the layout.
   * @param value The layout.
   * @returns This object.
   */
  public layout(value: IPopulatedMultiPlotLayout): this {
    this.inner.layout = value;
    return this;
  }

  /**
   * Sets the settings.
   * @param value The settings
   * @returns This object.
   */
  public chartSettings(value: IChartSettings): this {
    this.inner.settings = value;
    return this;
  }

  /**
   * Sets the type of pane resize handles.
   * @param value The type of pane resize handles.
   * @returns This object.
   */
  public paneResizeHandlesType(value: PaneResizeHandlesType): this {
    this.inner.paneResizeHandlesType = value;
    return this;
  }

  /**
   * Listen for changes to the component.
   * @param typenames The event type names.
   * @param callback The callback.
   * @returns This object.
   */
  public on(typenames: string, callback: (this: object, ...args: any[]) => void): this {
    this.inner.listeners.on(typenames, callback);
    return this;
  }
}

/**
 * The inner pane resize handles renderer.
 */
export class PaneResizeHandlesRendererInner {

  /**
   * The layout.
   */
  public layout?: IPopulatedMultiPlotLayout;

  /**
   * The chart settings.
   */
  public settings?: IChartSettings;

  /**
   * The type of pane resize handles.
   */
  public paneResizeHandlesType: PaneResizeHandlesType = PaneResizeHandlesType.columns;

  /**
   * The D3 style event listeners.
   */
  public listeners = d3.dispatch('changed');

  /**
   * Renders the pane resize handles.
   * @param selection The selection to render the pane resize handles to.
   */
  public render(selection: SVGSelection) {
    if (!this.layout || !this.settings) {
      return;
    }

    const settings = this.settings;

    let self = this;

    // Let up some variables based on whether we're resizing rows or columns.
    let containerClassName = '-pane-resize-handles-renderer';
    let paneResizeHandleClassName = '-pane-resize-handle';
    let side: IPopulatedMultiPlotSide[];
    if (this.paneResizeHandlesType === PaneResizeHandlesType.rows) {
      containerClassName = 'row' + containerClassName;
      paneResizeHandleClassName = 'row' + paneResizeHandleClassName;
      side = this.layout.processed.rows;
    } else {
      containerClassName = 'column' + containerClassName;
      paneResizeHandleClassName = 'column' + paneResizeHandleClassName;
      side = this.layout.processed.columns;
    }

    // Create the container.
    let containerUpdate = selection.selectAll<SVGGElement, any>('.' + containerClassName).data([null]);
    let containerEnter = containerUpdate.enter().append('g').attr('class', containerClassName + ' multi-plot-pane-resize-handles-renderer');
    let container = containerEnter.merge(containerUpdate);

    // We call either a row or column a "side".
    // Create a list of the pairs of sides which should have resize handles in between them.
    let panePairs: SidePair[] = [];
    for (let i = 1; i < side.length; ++i) {
      panePairs.push([side[i - 1], side[i]]);
    }

    // Create a group for each pair of sides.
    let gUpdate = container.selectAll<SVGGElement, SidePair>('.' + paneResizeHandleClassName).data(panePairs);
    gUpdate.exit().remove();
    let gEnter = gUpdate.enter().append('g').attr('class', paneResizeHandleClassName + ' pane-resize-handle-parent');
    let g = gEnter.merge(gUpdate);

    let chartPosition = new Position(
      settings.svgPadding.left + settings.chartMargin.left,
      settings.svgPadding.top + settings.chartMargin.top);

    if (this.paneResizeHandlesType === PaneResizeHandlesType.columns) {
      // Create a rectangle for each pair of columns.
      g.attr('transform', (d: SidePair, i: number) =>
        'translate('
        + (chartPosition.x + d[1].processed.offset)
        + ','
        + (chartPosition.y)
        + ')');

      gEnter.append('rect').attr('class', 'handle')
        .attr('width', settings.spaceBetweenPlots)
        .attr('height', HANDLE_LENGTH + settings.chartSize.height);
    } else {
      // Create a rectangle for each pair of rows.
      g.attr('transform', (d: SidePair, i: number) =>
        'translate('
        + (chartPosition.x - HANDLE_LENGTH)
        + ','
        + (chartPosition.y + d[1].processed.offset - settings.spaceBetweenPlots)
        + ')');

      gEnter.append('rect').attr('class', 'handle')
        .attr('width', HANDLE_LENGTH + settings.chartSize.width)
        .attr('height', settings.spaceBetweenPlots);
    }

    // Handle the start of a mouse drag on the resize handles.
    gEnter
      .on('mousedown', function(currentEvent: any, d: SidePair) {
        let mouseEvent = currentEvent;
        mouseEvent.preventDefault();
        let dragData = new DragData(
          mouseEvent,
          [
            new InitialSize(d[0].relativeSize, d[0].processed.plotSize),
            new InitialSize(d[1].relativeSize, d[1].processed.plotSize)
          ]);
        self.paneResizeStart(selection, d, dragData);
      });
  }

  /**
   * Called when the mouse is pressed on a resize handle, to start resizing the panes.
   * @param trackingSelection The selection to track the mouse events on.
   * @param pair The pair of sides to resize.
   * @param dragData The drag data.
   */
  protected paneResizeStart(trackingSelection: SVGSelection, pair: SidePair, dragData: DragData) {
    // Track the appropriate mouse events.
    trackingSelection
      .on('mousemove.pane-resize-handle', e => this.paneResizeDuring(trackingSelection, pair, dragData, e))
      .on('mouseup.pane-resize-handle', () => this.paneResizeEnd(trackingSelection, pair, dragData))
      .on('mouseleave.pane-resize-handle', () => {
        //if(currentEvent.currentTarget === trackingSelection) {
        this.paneResizeEnd(trackingSelection, pair, dragData);
        //}
      });
  }

  /**
   * Called during a mouse drag on a resize handle, to resize the panes.
   * @param trackingSelection The selection to track the mouse events on.
   * @param pair The pair of sides to resize.
   * @param dragData The drag data.
   * @param currentEvent The current mouse event.
   */
  protected paneResizeDuring(trackingSelection: SVGSelection, pair: SidePair, dragData: DragData, currentEvent: MouseEvent) {
    let mouseEvent = currentEvent;
    //mouseEvent.preventDefault();

    // How much the mouse has moved since the start of the drag.
    let change = this.paneResizeHandlesType === PaneResizeHandlesType.columns
      ? mouseEvent.pageX - dragData.mouseEvent.pageX
      : mouseEvent.pageY - dragData.mouseEvent.pageY;

    // Get the initial sizes of the two rows/columns.
    let initialFirst = dragData.pair[0];
    let initialSecond = dragData.pair[1];
    let relativeSizeTotal = initialFirst.relativeSize + initialSecond.relativeSize;
    let absoluteSizeTotal = initialFirst.absoluteSize + initialSecond.absoluteSize;

    if (absoluteSizeTotal <= (2 * MINIMUM_PANE_SIZE)) {
      // The panes are too small already, just split them equally.
      pair[0].relativeSize = relativeSizeTotal / 2;
      pair[1].relativeSize = relativeSizeTotal / 2;
    } else {
      // Calculate the new relative sizes of the two rows/columns.
      let firstPaneNewAbsoluteSize = initialFirst.absoluteSize + change;

      if (firstPaneNewAbsoluteSize < MINIMUM_PANE_SIZE) {
        firstPaneNewAbsoluteSize = MINIMUM_PANE_SIZE;
      }

      if (firstPaneNewAbsoluteSize > (absoluteSizeTotal - MINIMUM_PANE_SIZE)) {
        firstPaneNewAbsoluteSize = absoluteSizeTotal - MINIMUM_PANE_SIZE;
      }

      let firstPaneChangeRatio = firstPaneNewAbsoluteSize / initialFirst.absoluteSize;
      let firstPaneNewRelativeSize = initialFirst.relativeSize * firstPaneChangeRatio;
      let secondPaneNewRelativeSize = relativeSizeTotal - firstPaneNewRelativeSize;

      pair[0].relativeSize = firstPaneNewRelativeSize;
      pair[1].relativeSize = secondPaneNewRelativeSize;
    }

    // We've updated the relative sizes of the two rows/columns, so raise the changed event
    // to update the chart.
    this.listeners.call('changed');
  }

  /**
   * Called when the mouse is released on a resize handle, to end resizing the panes.
   * @param trackingSelection The selection to track the mouse events on.
   * @param pair The pair of sides to resize.
   * @param dragData The drag data.
   */
  protected paneResizeEnd(trackingSelection: SVGSelection, pair: SidePair, dragData: DragData) {
    // Stop tracking the mouse events.
    trackingSelection
      .on('mousemove.pane-resize-handle', null)
      .on('mouseup.pane-resize-handle', null)
      .on('mouseleave.pane-resize-handle', null);
  }
}

/**
 * Represents the initial size of a row or column.
 */
class InitialSize {
  constructor(
    public relativeSize: number,
    public absoluteSize: number) {
  }
}

/**
 * Represents the data for a drag operation.
 */
class DragData {
  constructor(
    public mouseEvent: MouseEvent,
    public pair: [InitialSize, InitialSize]) {
  }
}

/**
 * Represents a pair of sides (a side is a row or column).
 */
type SidePair = [IPopulatedMultiPlotSide, IPopulatedMultiPlotSide];

import {DocumentSubType} from '../../../../generated/api-stubs';

export class SourceItem {
  constructor(
    public type: string,
    public typeKey: DocumentSubType,
    public userId: string,
    public configId: string,
    public name: string,
    public isEdited: boolean
  ) {
  }
}

import { ExplorationInputs } from './exploration-inputs';
import { ExplorationJob } from './exploration-job';
import { GetExplorationJob } from './get-exploration-job';
import { ExplorationJobsMetadata } from './get-exploration-jobs-metadata-from-exploration-map';

/**
 * Gets the exploration jobs from the exploration inputs and jobs metadata.
 */
export class GetExplorationJobs {

  /**
   * Creates a new instance of GetExplorationJobs.
   * @param getExplorationJob Gets the exploration job from the exploration inputs and job metadata.
   */
  constructor(
    private readonly getExplorationJob: GetExplorationJob) {
  }

  public static create(): GetExplorationJobs {
    return new GetExplorationJobs(new GetExplorationJob());
  }

  /**
   * Creates the exploration job structures from the exploration inputs and jobs metadata.
   * @param inputs The exploration inputs.
   * @param jobsMetadata The jobs metadata.
   * @returns The exploration jobs.
   */
  public execute(inputs: ExplorationInputs, jobsMetadata: ExplorationJobsMetadata): ReadonlyArray<ExplorationJob> {

    const jobs: ExplorationJob[] = Array(jobsMetadata.jobs.length);

    // For each job...
    for (let jobIndex = 0; jobIndex < jobsMetadata.jobs.length; ++jobIndex) {

      // ... create the exploration job structure from the job metadata and exploration inputs.
      jobs[jobIndex] = this.getExplorationJob.execute(inputs, jobsMetadata.jobs[jobIndex]);
    }

    return jobs;
  }
}

import {OnInit, Component, Input, EventEmitter} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {CanopyValidators} from '../../../common/forms/canopy-validators.service';
import {UntypedFormBuilder} from '@angular/forms';
import {CanopyAutocomplete} from '../../../common/canopy-autocomplete.service';
import {distinct} from '../../../common/distinct';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {DocumentSubType} from '../../../../generated/api-stubs';
import {IConfigSource, ManagedConfig} from '../quick-config-manager/quick-config-manager.component';
import {arrayEquals} from '../../../common/array-equals';
import { JsonEditorInstance } from '../json-config-editor/json-editor-instance';

@Component({
  selector: 'cs-custom-view-selector',
  templateUrl: './custom-view-filter.component.html',
  styleUrls: ['./custom-view-filter.component.scss']
})
export class CustomViewFilterComponent implements OnInit, IConfigSource {
  @Input() public configType: DocumentSubType;
  @Input() public editorInstance: JsonEditorInstance;

  public configUpdated: EventEmitter<ManagedConfig<ManagedConfigData>> = new EventEmitter();
  public managedConfig: ManagedConfig<ManagedConfigData>;
  private isFirstExternalConfig: boolean = true;

  public form: UntypedFormGroup;
  public tags: UntypedFormControl = new UntypedFormControl([], []);

  public errorMessage: string;

  public validators = CanopyValidators.customPropertyNameWithWildcardsValidators;
  public errorMessages =  {
    customPropertyName: 'The parameter name contains illegal characters.',
    maxLength: 'The parameter name is too long.'
  };

  private lastAutocompleteItems: string[] = [];

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly canopyAutocomplete: CanopyAutocomplete,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog
  ){
    this.form = this.formBuilder.group({
      tags: this.tags
    });
  }

  public ngOnInit(){
    try{
      this.canopyAutocomplete.create(
        'ng2-tag-input__text-input', this.tags, () => this.generateAutocompleteItems(), (a, b) => a.localeCompare(b), true);

      this.tags.valueChanges.subscribe(v => this.onConfigUpdatedManually(v));
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public applyFilter(){
    let visibleInput = this.editorInstance.getFirstVisibleInput();
    const tags = this.tags.value;
    this.editorInstance.setCustomView(tags);
    this.scrollToInput(visibleInput);
  }

  public scrollToInput(requestedInput: HTMLElement) {
    if(requestedInput){
      if(!this.isElementIncludedInCurrentFilter(requestedInput)){
        requestedInput = this.editorInstance.getFirstInput();
      }

      if(requestedInput){
        requestedInput.scrollIntoView({
          block: 'center'
        });
      }
    }

  }

  public clearFilter(){
    this.managedConfig = new ManagedConfig(
      undefined,
      undefined,
      undefined,
      {
        tags: []
      },
      false);
    this.configUpdated.emit(this.managedConfig);
    this.tags.setValue([]);

    this.applyFilter();
  }

  public collapseAll(){
    this.editorInstance.collapseAll();
  }

  public onConfigUpdatedExternally(item: ManagedConfig<ManagedConfigData>){
    if(item){
      this.managedConfig = item;
      this.tags.setValue(this.getManagedConfigTags(item));
    } else{
      this.managedConfig = undefined;
      this.tags.setValue([]);
    }

    if(this.isFirstExternalConfig === false || this.tags.value.length > 0){
      this.applyFilter();
    }
    this.isFirstExternalConfig = false;
  }

  private onConfigUpdatedManually(v: string[]){
    if(!this.managedConfig){
      if(v.length){
        this.managedConfig = new ManagedConfig<ManagedConfigData>(
          undefined,
          undefined,
          undefined,
          {
            tags: v
          },
          false);
        this.configUpdated.emit(this.managedConfig);

        this.applyFilter();
      }
    } else if(!arrayEquals(v, this.getManagedConfigTags(this.managedConfig))){
      this.managedConfig = ManagedConfig.copy<ManagedConfigData>(this.managedConfig, c => {
        c.isEdited = true;
        c.config = {
          tags: v
        };
      });

      this.configUpdated.emit(this.managedConfig);

      this.applyFilter();
    }
  }

  public get currentFilter() {
    return {
      tags: this.tags.value
    };
  }

  public get configSource(): IConfigSource{
    return this;
  }

  private isElementIncludedInCurrentFilter(element: HTMLElement){
    const rect = element.getBoundingClientRect();
    return !!rect.height;
  }

  private getManagedConfigTags(item: ManagedConfig<ManagedConfigData>): string[] {
    if(!item){
      return [];
    }

    let config = item.getConfigCopy();
    if(!config || !config.tags){
      return [];
    }

    return config.tags;
  }

  private generateAutocompleteItems() {
    let result = this.lastAutocompleteItems;
    try{
      let config = this.editorInstance.getValue();

      result = this.generateAutocompleteItemsFromConfig(config);
    } catch(error){
      // Just use the last items.
    }

    this.lastAutocompleteItems = result;
    return result;
  }

  private generateAutocompleteItemsFromConfig(config: any): string[] {
    return distinct(this.generateAutocompleteItemsFromConfigInner(config, undefined));
  }

  private generateAutocompleteItemsFromConfigInner(node: any, parentName: string): string[] {
    const maximumArrayLength = 100;
    let result = [];

    if(typeof node === 'object'){
      let isArray = Array.isArray(node);
      if(isArray){
        if(node.length < maximumArrayLength){
          for(let item of node){
            result.push(...this.generateAutocompleteItemsFromConfigInner(item, parentName));
          }
        }
      } else{
        if(node.name){
          result.push(node.name);
        }

        for(let key in node){
          if(!node.hasOwnProperty(key)){
            continue;
          }
          result.push(...this.generateAutocompleteItemsFromConfigInner(node[key], key));
          result.push(key);
        }
      }
    } else if(parentName){
      result.push(parentName);
    }

    return result;
  }
}

export interface ManagedConfigData {
  tags: string[];
}

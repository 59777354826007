<div class="container-fluid">
  <div class="card">
    <h1 class="card-header">Set Global Sim Version</h1>
    <div class="card-body">
      <cs-error-message [message]="errorMessage"></cs-error-message>

      @if (!form) {
        <cs-loader></cs-loader>
      }
      @if (form) {
        <div class="row">
          <div class="col-12 col-sm-6">
            <form (ngSubmit)="onSubmit()" [formGroup]="form">
              <div class="mb-3">
                <label class="form-label" for="sim-version-input">Sim Version</label>
                <input id="sim-version-input" type="text" class="form-control" formControlName="simVersion">
                @if (!simVersion.pristine && simVersion.hasError('required')) {
                  <p class="text-danger">
                    Name is required
                  </p>
                }
              </div>
              @if (saveSuccessful) {
                <div id="success-message" class="alert alert-success">
                  Saved.
                </div>
              }
              <button id="global-sim-version-submit" type="submit" class="btn btn-smart btn-primary" [disabled]="submitButton.isSubmitting">{{submitButton.text}}</button>
            </form>
          </div>
        </div>
      }
    </div>
  </div>


</div>

import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AuthenticationSelectors } from './authentication.selectors';
import { CheckAuthentication, Login, Logout } from './authentication.actions';

export interface UserData {
  amr: string;
  auth_time: number;
  idp: string;
  name: string;
  roles: string[];
  sub: string;
  tenant: string;
}

const ADMINISTRATOR_ROLE: string = 'administrator';
const TENANT_ADMINISTRATOR_ROLE: string = 'tenant-administrator';
const TEST_USER_ROLE: string = 'test-user';
// const DELETED_USER_ROLE: string = 'deleted-user';

@Injectable()
export class AuthenticationService {

  public get idToken(): Observable<string> {
    return this._store.select(AuthenticationSelectors.idToken);
  }

  public get idTokenSnapshot(): string {
    return this._store.selectSnapshot(AuthenticationSelectors.idToken);
  }

  public get isAuthenticated(): Observable<boolean> {
    return this._store.select(AuthenticationSelectors.isAuthenticated);
  }

  public get isAuthenticatedSnapshot(): boolean {
    return this._store.selectSnapshot(AuthenticationSelectors.isAuthenticated);
  }

  public get accessToken(): Observable<string> {
    return this._store.select(AuthenticationSelectors.authToken);
  }

  public get accessTokenSnapshot(): string {
    return this._store.selectSnapshot(AuthenticationSelectors.authToken);
  }

  public get userData(): Observable<UserData> {
    return this._store.select(AuthenticationSelectors.userData);
  }

  public get userDataSnapshot(): UserData {
    return this._store.selectSnapshot(AuthenticationSelectors.userData);
  }

  public get roles(): string[] {
    return this.userDataSnapshot?.roles;
  }

  public get isAdministrator(): boolean {
    return this.roles?.includes(ADMINISTRATOR_ROLE);
  }

  public get isTenantAdministrator(): boolean {
    return this.roles?.includes(TENANT_ADMINISTRATOR_ROLE);
  }

  public get isTestUser(): boolean {
    return this.roles?.includes(TEST_USER_ROLE);
  }

  public get showWorksheets() {
    return true; // this.isAdministrator || this.isTestUser || WORKSHEET_TENANTS.indexOf(this.userIdentity.tenantId) !== -1;
  }

  constructor(private _store: Store) {
  }

  public async serviceOnInit(): Promise<void> {
    await this.checkAuthentication().toPromise();
  }

  public checkAuthentication(): Observable<any> {
    return this._store.dispatch(new CheckAuthentication());
  }

  public login(): Observable<any> {
    return this._store.dispatch(new Login());
  }

  public logout(): Observable<any> {
    return this._store.dispatch(new Logout());
  }
}

<div class="container-fluid">
  <div class="card">
    <h1 class="card-header">Select User Sim Version</h1>
    <div class="card-body">
      <cs-select-sim-version [tenantId]="tenantId"
                             [isTenantSimVersion]="false"
                             [currentSimVersion]="currentSimVersion"
                             (simVersionSelected)="onSimVersionSelected($event)"></cs-select-sim-version>
    </div>
  </div>
</div>

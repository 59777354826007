import { isNullOrUndefined } from './is-null-or-undefined';

type Equal<T> = (a: T, b: T) => boolean;

/**
 * Compare two arrays for equality.
 * @param a The first array.
 * @param b The second array.
 * @param eq The equality function to use to compare elements.
 * @returns True if the arrays are equal, false otherwise.
 */
export function arraysAreEqual<T>(a: ReadonlyArray<T> | undefined | null, b: ReadonlyArray<T> | undefined | null, eq: Equal<T>) {
  return (a === b)
    || (isNullOrUndefined(a) && isNullOrUndefined(b)) /* we are saying here that a null array and an undefined array are equal for our purposes */
    || (!isNullOrUndefined(a) && !isNullOrUndefined(b) && a.length === b.length && a.every((x, idx) => eq(x, b[idx])));
}

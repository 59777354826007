import {LazyLoaded} from './lazy-loaded';
import {GetStudyJobMetadataQueryResult} from '../../../../generated/api-stubs';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {StudyUtilities} from '../../studies/study-utilities.service';
import {Injectable} from '@angular/core';

@Injectable()
export class LazyLoadedStudyJobDiagnosisFactory {
  constructor(
    private readonly studyUtilities: StudyUtilities,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  public create(jobMetadataResult: LazyLoaded<GetStudyJobMetadataQueryResult>, tenantId: string, studyId: string, jobId: string): LazyLoaded<string[]>{
    return new LazyLoaded<string[]>(
      async () => {
        await jobMetadataResult.load();

        let warnings = await this.studyUtilities.downloadDiagnosis(
          tenantId,
          studyId,
          jobId,
          jobMetadataResult.value.accessInformation.url,
          jobMetadataResult.value.accessInformation);

        return  warnings === undefined ? undefined : warnings.split('\n');
      },
      this.getFriendlyErrorAndLog);
  }
}

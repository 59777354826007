import { Injectable } from '@angular/core';
import { ConfigReference } from '../../../../generated/api-stubs';
import { StudyInput } from '../../study-input';


/**
 * A service for importing a telemetry config from the staging area.
 */
@Injectable()
export class ImportTelemetryConfigFromStagingArea {

  /**
   * Imports the telemetry config from the staging area.
   * @param studyInput The study input.
   * @returns The config reference.
   */
  public execute(studyInput: StudyInput): ConfigReference | undefined {

    if (!studyInput || !studyInput.data || !studyInput.data.source) {
      return undefined;
    }

    const source = studyInput.data.source;

    // The telemetry config is just a reference to a study, so we can simply create a
    // reference to the same study for the worksheet.
    return {
      tenant: {
        tenantId: source.tenantId,
        targetId: source.studyId,
        jobIndex: source.jobIndex,
      }
    };
  }
}

import { LegendRenderChannel } from '../../components/legend-renderer';
import { IMultiPlotLayout } from './i-multi-plot-layout';
import { IMultiPlotChannel } from './i-multi-plot-channel';

/**
 * A legend channel for one of the layout's special channels for rendering size or color of data
 * based on channel values (e.g. the track viewer coloring and sizing the racing line based on channels).
 * Here the `K` value must be a key of `IMultiPlotLayout`, which currently includes `sizeChannel` and `colorChannel`.
 */

export class MultiPlotViewerLegendRenderChannel<K extends keyof IMultiPlotLayout> extends LegendRenderChannel {

  /**
   * Creates a new MultiPlotViewerLegendRenderChannel instance.
   * @param name The name of the channel.
   * @param iconCodepoint The icon codepoint of the channel.
   * @param renderChannelPropertyName The channel property in the `IMultiPlotLayout` to which this corresponds.
   * @param getLayout A function to get the layout.
   */
  constructor(name: string, iconCodepoint: string, renderChannelPropertyName: K, getLayout: () => IMultiPlotLayout) {
    super(
      name,
      iconCodepoint,
      () => getLayout()[renderChannelPropertyName] ? (getLayout()[renderChannelPropertyName] as IMultiPlotChannel).name : undefined,
      (v) => getLayout()[renderChannelPropertyName] = (v ? { name: v } as any : undefined));
  }
}

import { CarCoordinate } from '../3d/car-coordinate';
import { RawSuspensionCoordinatesMap } from './raw-car-types';

/**
 * The coordinates of a suspension member.
 */
export type MemberCoordinates = ReadonlyArray<CarCoordinate>;

/**
 * A mutable map of suspension coordinates.
 */
export interface MutableSuspensionCoordinatesMap {
  [name: string]: CarCoordinate;
}

/**
 * A readonly map of suspension coordinates.
 */
export interface SuspensionCoordinatesMap {
  readonly [name: string]: CarCoordinate;
}

/**
 * An enumeration of suspension areas, and their human readable names.
 */
export enum SuspensionAreaName {
  frontExternal = 'Front External',
  frontExternalL = 'Front External Left',
  rearExternal = 'Rear External',
  rearExternalL = 'Rear External Left',
  frontInternal = 'Front Internal',
  frontInternalL = 'Front Internal Left',
  rearInternal = 'Rear Internal',
  rearInternalL = 'Rear Internal Left',
  chassis = 'Chassis',
  chassisL = 'Chassis Left',
}

/**
 * The list of suspension areas.
 */
export const SuspensionAreas: ReadonlyArray<SuspensionAreaName> = [
  SuspensionAreaName.frontExternal,
  SuspensionAreaName.frontExternalL,
  SuspensionAreaName.rearExternal,
  SuspensionAreaName.rearExternalL,
  SuspensionAreaName.frontInternal,
  SuspensionAreaName.frontInternalL,
  SuspensionAreaName.rearInternal,
  SuspensionAreaName.rearInternalL,
  SuspensionAreaName.chassis,
  SuspensionAreaName.chassisL,
];

/**
 * The type of suspension member. A line is a line drawn between two points, and a circle is a circle drawn around a point.
 */
export enum SuspensionMemberType {
  line,
  circle,
}

/**
 * A suspension member, consisting of a name, coordinates and type.
 */
export class SuspensionMember {

  /**
   * Creates a SuspensionMember instance.
   * @param name The name of the suspension member.
   * @param coordinates The coordinates of the suspension member.
   * @param type The type of suspension member.
   */
  constructor(
    public readonly name: string,
    public readonly coordinates: MemberCoordinates | undefined,
    public readonly type: SuspensionMemberType = SuspensionMemberType.line) {
  }
}

/**
 * The data for a suspension area.
 */
export class SuspensionAreaData {

  /**
   * Creates a SuspensionAreaData instance.
   * @param name The name of the suspension area.
   * @param memberNames The names of the suspension members.
   * @param map The map of names to positions in car coordinate space.
   * @param list The suspension members.
   * @param raw The raw suspension coordinates map.
   */
  constructor(
    public readonly name: SuspensionAreaName,
    public readonly memberNames: ReadonlyArray<string>,
    public readonly map: SuspensionCoordinatesMap,
    public readonly list: ReadonlyArray<SuspensionMember>,
    public readonly raw: RawSuspensionCoordinatesMap,
  ) {
  }
}

/**
 * The collection of suspension areas and their data for a car.
 */
export class SuspensionData {

  /**
   * Creates a SuspensionData instance.
   * @param frontExternal The front external suspension area.
   * @param frontExternalL The front external left suspension area.
   * @param rearExternal The rear external suspension area.
   * @param rearExternalL The rear external left suspension area.
   * @param frontInternal The front internal suspension area.
   * @param frontInternalL The front internal left suspension area.
   * @param rearInternal The rear internal suspension area.
   * @param rearInternalL The rear internal left suspension area.
   * @param chassis The chassis area.
   * @param chassisL The chassis left area.
   */
  constructor(
    public readonly frontExternal: SuspensionAreaData,
    public readonly frontExternalL: SuspensionAreaData,
    public readonly rearExternal: SuspensionAreaData,
    public readonly rearExternalL: SuspensionAreaData,
    public readonly frontInternal: SuspensionAreaData,
    public readonly frontInternalL: SuspensionAreaData,
    public readonly rearInternal: SuspensionAreaData,
    public readonly rearInternalL: SuspensionAreaData,
    public readonly chassis: SuspensionAreaData,
    public readonly chassisL: SuspensionAreaData,
  ) {
    this.verifySuspensionData(SuspensionAreaName.frontExternal, frontExternal);
    this.verifySuspensionData(SuspensionAreaName.frontExternalL, frontExternalL);
    this.verifySuspensionData(SuspensionAreaName.rearExternal, rearExternal);
    this.verifySuspensionData(SuspensionAreaName.rearExternalL, rearExternalL);
    this.verifySuspensionData(SuspensionAreaName.frontInternal, frontInternal);
    this.verifySuspensionData(SuspensionAreaName.frontInternalL, frontInternalL);
    this.verifySuspensionData(SuspensionAreaName.rearInternal, rearInternal);
    this.verifySuspensionData(SuspensionAreaName.rearInternalL, rearInternalL);
    this.verifySuspensionData(SuspensionAreaName.chassis, chassis);
    this.verifySuspensionData(SuspensionAreaName.chassisL, chassisL);
  }

  /**
   * Gets the suspension area data for the specified name.
   * @param name The name of the suspension area.
   * @returns The suspension area data.
   */
  public getArea(name: SuspensionAreaName) {
    switch (name) {
      case SuspensionAreaName.frontExternal: return this.frontExternal;
      case SuspensionAreaName.frontExternalL: return this.frontExternalL;
      case SuspensionAreaName.rearExternal: return this.rearExternal;
      case SuspensionAreaName.rearExternalL: return this.rearExternalL;
      case SuspensionAreaName.frontInternal: return this.frontInternal;
      case SuspensionAreaName.frontInternalL: return this.frontInternalL;
      case SuspensionAreaName.rearInternal: return this.rearInternal;
      case SuspensionAreaName.rearInternalL: return this.rearInternalL;
      case SuspensionAreaName.chassis: return this.chassis;
      case SuspensionAreaName.chassisL: return this.chassisL;
    }
  }

  /**
   * Does some basic verification of the suspension data. Currently only checks the name is correct.
   * @param expected The expected suspension area name.
   * @param data The suspension area data.
   */
  private verifySuspensionData(expected: SuspensionAreaName, data: SuspensionAreaData) {
    if (data.name !== expected) {
      throw new Error(`Incorrect suspension area. Expected ${expected} but got ${data.name}.`);
    }
  }
}

import { ChartSettings } from '../chart-settings';
import { Margin } from '../margin';

/**
 * Settings for the parallel coordinates viewer.
 */
export class ParallelCoordinatesViewerSettings extends ChartSettings {

  /**
   * Create a new instance of the parallel coordinates viewer settings.
   * @param sourceCount The number of data sources.
   * @returns A new instance of the parallel coordinates viewer settings.
   */
  public static build(sourceCount: number) {
    return new ParallelCoordinatesViewerSettings(sourceCount);
  }

  /**
   * Create a new instance of the parallel coordinates viewer settings.
   * @param sourceCount The number of data sources.
   */
  constructor(sourceCount: number) {
    super(sourceCount);

    // Create the required padding and margins for the parallel coordinates viewer.
    this.svgPadding = new Margin(43, 10, 10, 10);
    this.chartMargin = new Margin(
      this.chartMargin.top,
      this.chartMargin.right,
      16, // This creates room for the flip icons.
      this.chartMargin.left);

    // NOTE: The chart margin is currently only used indirectly (as it changes the chart height).
  }

  /**
   * Get the space between plots.
   */
  public get spaceBetweenPlots(): number {
    return 0;
  }
}

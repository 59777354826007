import {HomePage} from './home/home.page';
import {RegisterPage} from './identity/register.page';
import {ForgotPasswordPage} from './identity/forgot-password.page';
import {ResetPasswordPage} from './identity/reset-password.page';
import {AccountSettingsPage} from './identity/account-settings.page';
import {TenantInformationPage} from './tenancy/tenant-information.page';
import {TenantAccountSettingsPage} from './tenancy/tenant-account-settings.page';
import {ViewConfigsPage} from './simulations/configs/view-configs-page/view-configs.page';
import {ConfigTypeLookup, CONFIG_TYPE_DATA_KEY} from './simulations/configs/config-types';
import {AdminPage} from './admin/admin.page';
import {CreateTenantPage} from './tenancy/create-tenant.page';
import {NotAuthorizedPage} from './not-authorized.page';
import {CreateConfigPage} from './simulations/configs/create-config-page/create-config.page';
import {EditConfigPage} from './simulations/configs/edit-config-page/edit-config.page';
import {ViewConfigPage} from './simulations/configs/view-config-page/view-config.page';
import {ViewStudiesPage} from './simulations/studies/view-studies-page/view-studies.page';
import {ViewStudyPage} from './simulations/studies/view-study-page/view-study.page';
import {ViewJobsPage} from './simulations/jobs/view-jobs-page/view-jobs.page';
import {ViewJobPage} from './simulations/jobs/view-job-page/view-job.page';
import {ViewSimVersionDocumentsPage} from './admin/view-sim-version-documents.page';
import {PoolStatusPage} from './pool/pool-status.page';
import {EditStudyPage} from './simulations/studies/edit-study-page/edit-study.page';
import { RouterModule, Router, ActivatedRouteSnapshot, RouterStateSnapshot, Routes, ResolveFn } from '@angular/router';
import {ModuleWithProviders, inject} from '@angular/core';
import {CanDeactivateGuard} from './common/can-deactivate';
import {
  UnauthenticatedAuthGuard} from './identity/authorization/unauthenticated-auth-guard';
import {ViewSupportSessionsPage} from './admin/view-support-sessions.page';
import {Observable} from 'rxjs';
import {ViewJobResultsPage} from './simulations/jobs/job-results/view-job-results-page/view-job-results.page';
import {AuthenticatedAuthGuard} from './identity/authorization/authenticated-auth-guard';
import {AdministratorAuthGuard} from './identity/authorization/administrator-auth-guard';
import {TenantAdministratorAuthGuard} from './identity/authorization/tenant-administrator-auth-guard';
import {AdministratorOrTestAuthGuard} from './identity/authorization/administrator-or-test-auth-guard';
import {ViewWorksheetsPage} from './worksheets/view-worksheets/view-worksheets.page';
import {EditWorksheetPage} from './worksheets/edit-worksheet/edit-worksheet.page';
import {RequestUndockWorksheetPage} from './worksheets/request-undock-worksheet/request-undock-worksheet.page';
import {CAN_REUSE_DATA} from './custom-reuse-strategy';
import {TenantDownloadsPageComponent} from './tenancy/tenant-downloads-page/tenant-downloads-page.component';
import {ZendeskComponent} from './sso/zendesk/zendesk.component';
import {TenantSettingsChannelWhitelistsPageComponent} from './tenancy/tenant-settings-channel-whitelists-page/tenant-settings-channel-whitelists-page.component';
import { ZendeskMessagePage } from './sso/zendesk-message/zendesk-message.page';
import { ConfirmEmailPage } from './confirm-email/confirm-email.page';
import { EditConfigPermissionComponent } from './encryption/config-permissions/edit-config-permissions/edit-config-permission.component';
import { ViewConfigPermissionComponent } from './encryption/config-permissions/view-config-permissions/view-config-permission.component';
import { EditKeyPermissionComponent } from './encryption/key-permissions/edit-key-permissions/edit-key-permission.component';
import { ViewKeyPermissionComponent } from './encryption/key-permissions/view-key-permissions/view-key-permission.component';
import { GenericErrorPage } from './common/errors/generic-error-page/generic-error.page';
import { ConfigPermissionResolve } from './encryption/config-permissions/config-permission-resolve';
import { ConfigPermissionsResolve } from './encryption/config-permissions/config-permissions-resolve';
import { KeyPermissionResolve } from './encryption/key-permissions/key-permission-resolve';
import { KeyPermissionsResolve } from './encryption/key-permissions/key-permissions-resolve';
import { SignInRedirectPage } from './sign-in-redirect.page';
import { PersistentComponentsComponent } from './persistent-components/persistent-components';
import { VisualizationTestsPageComponent } from './visualizations/tests/visualization-tests-page/visualization-tests-page.component';
import { NotFoundPage } from './not-found.page';
import { ManageConfigComponentsPage } from './simulations/configs/manage-config-components-page/manage-config-components.page';

export const ConfigTypeResolve: ResolveFn<Observable<any>|Promise<any>|any> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  let configType = route.params['configType'];
  let result = ConfigTypeLookup.get(configType);
  if(!result) {
    inject(Router).navigate(['/']);
    return false;
  }
  return Promise.resolve(result);
}

const configPermissionsRoutes: Routes = [
  {
    path: `visualization-tests`,
    component: VisualizationTestsPageComponent,
    canActivate: [AuthenticatedAuthGuard]
  },

  {
    path: `config-permissions/:tenantId`,
    component: ViewConfigPermissionComponent,
    canActivate: [AuthenticatedAuthGuard],
    resolve: { configPermissions: async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
      await inject(ConfigPermissionsResolve).resolve(route, state) }
  },
  {
    path: `config-permissions/:tenantId/create`,
    component: EditConfigPermissionComponent,
    canActivate: [AuthenticatedAuthGuard]
  },
  {
    path: `config-permissions/:tenantId/edit/:encryptingTenantShortName/:decryptingTenantShortName`,
    component: EditConfigPermissionComponent,
    canActivate: [AuthenticatedAuthGuard],
    resolve: { configPermission: async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
      await inject(ConfigPermissionResolve).resolve(route, state) }
  }
];

const keyPermissionsRoutes: Routes = [
  {
    path: `key-permissions/:tenantId`,
    component: ViewKeyPermissionComponent,
    canActivate: [AuthenticatedAuthGuard],
    resolve: { configPermissions: async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
      await inject(KeyPermissionsResolve).resolve(route, state) }
  },
  {
    path: `key-permissions/:tenantId/create`,
    component: EditKeyPermissionComponent,
    canActivate: [AuthenticatedAuthGuard]
  },
  {
    path: `key-permissions/:tenantId/edit/:encryptingTenantShortName`,
    component: EditKeyPermissionComponent,
    canActivate: [AuthenticatedAuthGuard],
    resolve: { keyPermission: async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
      await inject(KeyPermissionResolve).resolve(route, state) }
  }
];

let studyAndConfigRoutes = [

  {
    path: 'studies',
    component: ViewStudiesPage,
    canActivate: [AuthenticatedAuthGuard]
  },
  {
    path: 'studies/:tenantId',
    component: ViewStudiesPage,
    canActivate: [AuthenticatedAuthGuard]
  },
  {
    path: 'studies/:tenantId/:studyId',
    component: ViewStudyPage,
    canActivate: [AuthenticatedAuthGuard]
  },
  {
    path: 'studies/:tenantId/:studyId/edit',
    component: EditStudyPage,
    canActivate: [AuthenticatedAuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'studies/:tenantId/:studyId/jobs',
    component: ViewJobsPage,
    canActivate: [AuthenticatedAuthGuard]
  },
  {
    path: 'studies/:tenantId/:studyId/jobs/:jobId',
    component: ViewJobPage,
    canActivate: [AuthenticatedAuthGuard]
  },
  {
    path: 'studies/:tenantId/:userId/:studyId',
    redirectTo: 'studies/:tenantId/:studyId'
  },
  {
    path: 'studies/:tenantId/:userId/:studyId/edit',
    redirectTo: 'studies/:tenantId/:studyId/edit'
  },
  {
    path: 'studies/:tenantId/:userId/:studyId/jobs',
    redirectTo: 'studies/:tenantId/:studyId/jobs'
  },
  {
    path: 'studies/:tenantId/:userId/:studyId/jobs/:jobId',
    redirectTo: 'studies/:tenantId/:studyId/jobs/:jobId'
  },

  {
    path: `configs/:${CONFIG_TYPE_DATA_KEY}`,
    component: ViewConfigsPage,
    resolve: {configType: ConfigTypeResolve},
    canActivate: [AuthenticatedAuthGuard]
  },
  {
    path: `configs/:${CONFIG_TYPE_DATA_KEY}/create`,
    component: CreateConfigPage,
    resolve: {configType: ConfigTypeResolve},
    canActivate: [AuthenticatedAuthGuard]
  },
  {
    path: `configs/:${CONFIG_TYPE_DATA_KEY}/:tenantId`,
    component: ViewConfigsPage,
    resolve: {configType: ConfigTypeResolve},
    canActivate: [AuthenticatedAuthGuard]
  },
  {
    path: `configs/:${CONFIG_TYPE_DATA_KEY}/:tenantId/:configId/edit`,
    component: EditConfigPage,
    resolve: {configType: ConfigTypeResolve},
    canActivate: [AuthenticatedAuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: `configs/:${CONFIG_TYPE_DATA_KEY}/:tenantId/:configId`,
    component: ViewConfigPage,
    resolve: {configType: ConfigTypeResolve},
    canActivate: [AuthenticatedAuthGuard]
  },
  {
    path: `configs/:${CONFIG_TYPE_DATA_KEY}/:tenantId/:userId/:configId/edit`,
    redirectTo: `configs/:${CONFIG_TYPE_DATA_KEY}/:tenantId/:configId/edit`
  },
  {
    path: `configs/:${CONFIG_TYPE_DATA_KEY}/:tenantId/:userId/:configId`,
    redirectTo: `configs/:${CONFIG_TYPE_DATA_KEY}/:tenantId/:configId`
  },
  {
    path: `default-configs/:simVersion/:${CONFIG_TYPE_DATA_KEY}/:configName/edit`,
    component: EditConfigPage,
    data: {isDefault: true},
    resolve: {configType: ConfigTypeResolve},
    canActivate: [AuthenticatedAuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: `default-configs/:simVersion/:${CONFIG_TYPE_DATA_KEY}/:configName`,
    component: ViewConfigPage,
    data: {isDefault: true},
    resolve: {configType: ConfigTypeResolve},
    canActivate: [AuthenticatedAuthGuard]
  },
];

let routes: Routes = [
    {
      path: '',
      component: PersistentComponentsComponent,
      children: [
      {
        path: '',
        component: HomePage,
        canActivate: [AuthenticatedAuthGuard]
      },
      {
        path: 'register',
        component: RegisterPage,
        canActivate: [TenantAdministratorAuthGuard]
      },
      {
        path: 'register/:tenantId',
        component: RegisterPage,
        canActivate: [TenantAdministratorAuthGuard]
      },
      {
        path: 'sign-in/forgot',
        component: ForgotPasswordPage,
        canActivate: [UnauthenticatedAuthGuard]
      },
      {
        path: 'sign-in/reset',
        component: ResetPasswordPage
      },
      {
        path: 'account-settings',
        component: AccountSettingsPage,
        canActivate: [AuthenticatedAuthGuard]
      },
      {
        path: 'account-settings/:tenantId/:userId',
        component: AccountSettingsPage,
        canActivate: [AuthenticatedAuthGuard]
      },
      {
        path: 'tenants',
        component: TenantInformationPage,
        canActivate: [AuthenticatedAuthGuard]
      },
      {
        path: 'tenants/:tenantId',
        component: TenantInformationPage,
        canActivate: [AuthenticatedAuthGuard]
      },
      {
        path: 'tenant-account-settings',
        component: TenantAccountSettingsPage,
        canActivate: [TenantAdministratorAuthGuard]
      },
      {
        path: 'tenant-account-settings/:tenantId',
        component: TenantAccountSettingsPage,
        canActivate: [TenantAdministratorAuthGuard]
      },

      {
        path: `configs/:${CONFIG_TYPE_DATA_KEY}/:tenantId/manage-components`,
        component: ManageConfigComponentsPage,
        resolve: {configType: ConfigTypeResolve},
        canActivate: [AuthenticatedAuthGuard]
      },

      ...studyAndConfigRoutes,

      {
        path: 'pool',
        component: PoolStatusPage,
        canActivate: [AuthenticatedAuthGuard]
      },

      {
        path: 'view/overlay',
        component: ViewJobResultsPage,
        canActivate: [AuthenticatedAuthGuard]
      },

      {
        path: 'admin',
        component: AdminPage,
        canActivate: [AdministratorAuthGuard]
      },
      {
        path: 'support-sessions',
        component: ViewSupportSessionsPage,
        canActivate: [AdministratorAuthGuard]
      },
      {
        path: 'admin/documents',
        component: ViewSimVersionDocumentsPage,
        canActivate: [AdministratorOrTestAuthGuard]
      },
      {
        path: 'create-tenant',
        component: CreateTenantPage,
        canActivate: [AdministratorAuthGuard]
      },
      {
        path: 'zendesk-message',
        component: ZendeskMessagePage
      },
      {
        path: 'confirm-email',
        component: ConfirmEmailPage,
        canActivate: [AuthenticatedAuthGuard]
      },

      {
        path: `worksheets`,
        component: ViewWorksheetsPage,
        canActivate: [AuthenticatedAuthGuard]
      },
      {
        path: `worksheets/docked`,
        component: RequestUndockWorksheetPage,
        canActivate: [AuthenticatedAuthGuard]
      },
      {
        path: `worksheets/:tenantId`,
        component: ViewWorksheetsPage,
        canActivate: [AuthenticatedAuthGuard]
      },
      {
        path: `worksheets/:tenantId/:worksheetId`,
        component: EditWorksheetPage,
        canActivate: [AuthenticatedAuthGuard],
        canDeactivate: [CanDeactivateGuard],
        data: CAN_REUSE_DATA,
        children: [
          ...studyAndConfigRoutes,
        ]
      },
      {
        path: `staged/:${CONFIG_TYPE_DATA_KEY}/edit`,
        component: EditConfigPage,
        data: {isStaged: true},
        resolve: {configType: ConfigTypeResolve},
        canActivate: [AuthenticatedAuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: `staged/:${CONFIG_TYPE_DATA_KEY}`,
        component: ViewConfigPage,
        data: {isStaged: true},
        resolve: {configType: ConfigTypeResolve},
        canActivate: [AuthenticatedAuthGuard]
      },
      {
        path: `downloads/:tenantId`,
        component: TenantDownloadsPageComponent,
        canActivate: [AuthenticatedAuthGuard]
      },
      {
        path: `encryption-channel-whitelists/:tenantId`,
        component: TenantSettingsChannelWhitelistsPageComponent,
        canActivate: [AuthenticatedAuthGuard]
      },

      ...configPermissionsRoutes,
      ...keyPermissionsRoutes,
    ]
  },
  {
    path: 'zendesk',
    component: ZendeskComponent,
  },
  {
    path: 'sign-in-redirect',
    component: SignInRedirectPage,
  },
  {
    path: 'sign-in',
    component: SignInRedirectPage,
  },
  {
    path: 'not-authorized',
    component: NotAuthorizedPage
  },
  {
    path: 'error',
    component: GenericErrorPage
  },
  {
    path: '**',
    component: NotFoundPage
  }
];

export const appRoutingProviders: any[] = [

];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes, { enableTracing: false });

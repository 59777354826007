import { TrackCoordinate } from '../3d/track-coordinate';
import { Vector3 } from 'three';
import { CreateZeroVector3 } from './extract-track-data';

/**
 * A set of coordinates representing a path in 3D space.
 */
export class TrackPath {

  /**
   * The vertices of the path in world coordinates linearly arranged in an array.
   */
  public readonly vertices: Float32Array;

  /**
   * The minimum height of the path.
   */
  public get minHeight(): number {
    return this._minHeight;
  }

  /**
   * The maximum height of the path.
   */
  public get maxHeight(): number {
    return this._maxHeight;
  }

  /**
   * The elevation offset of the path.
   */
  public get elevationOffset(): number {
    return this._elevationOffset;
  }

  /**
   * The elevation scale of the path.
   */
  public get elevationScale(): number {
    return this._elevationScale;
  }

  private _minHeight: number = 0;
  private _maxHeight: number = 0;
  private _elevationOffset: number = 0;
  private _elevationScale: number = 1;

  /**
   * Creates a new instance of TrackPath.
   * @param trackCoordinates The track coordinates in track coordinate space.
   */
  constructor(
    public readonly trackCoordinates: ReadonlyArray<TrackCoordinate>) {

    // Convert the track coordinates to vertices in world space.
    this.vertices = this.trackCoordinates
      .reduce((p: Float32Array, c: TrackCoordinate, i: number) => {
        let targetIndex = i * 3;
        p[targetIndex] = c.worldX;
        p[targetIndex + 1] = c.worldY;
        p[targetIndex + 2] = c.worldZ;
        return p;
      },
        new Float32Array(this.trackCoordinates.length * 3));

    // Calculate the min and max height of the path.
    if (trackCoordinates.length) {
      this._minHeight = this._maxHeight = trackCoordinates[0].worldY;
      for (let coordinate of trackCoordinates) {
        let worldY = coordinate.worldY;
        if (worldY > this._maxHeight) {
          this._maxHeight = worldY;
        }
        if (worldY < this._minHeight) {
          this._minHeight = worldY;
        }
      }
    }
  }

  /**
   * Clones a range of track coordinates.
   * @param startIndex The start index of the range.
   * @param count The number of coordinates to clone.
   * @returns The cloned range of track coordinates.
   */
  public cloneRange(startIndex: number, count: number) {
    let trackCoordinates = new Array<TrackCoordinate>(count);
    let sourceIndex = startIndex;
    for (let i = 0; i < count && sourceIndex < this.trackCoordinates.length; ++i, ++sourceIndex) {
      trackCoordinates[i] = this.trackCoordinates[sourceIndex];
    }

    let result = new TrackPath(trackCoordinates);
    result.adjustWorldElevationCoordinates(this._elevationScale, this._elevationOffset);
    return result;
  }

  /**
   * Gets the world coordinate at the specified index.
   * @param index The index of the coordinate.
   * @returns The world coordinate.
   */
  public getWorldCoordinate(index: number): Vector3 {
    let coordinate = this.trackCoordinates[index];
    if (!coordinate) {
      return CreateZeroVector3();
    }

    let worldCoordinate = coordinate.worldVector;
    worldCoordinate.setY((worldCoordinate.y + this._elevationOffset) * this._elevationScale);
    return worldCoordinate;
  }

  /**
   * Adjust the world elevation scale and offset (generally used to
   * drop minimum height to zero and scale up to exaggerate height in the viewer).
   * @param scale The scale to apply.
   * @param offset The offset to apply.
   */
  public adjustWorldElevationCoordinates(scale: number, offset: number) {

    // Reset before applying the new values.
    this.resetWorldElevationCoordinates();

    this._elevationScale = scale;
    this._elevationOffset = offset;

    this._minHeight = (this._minHeight + offset) * scale;
    this._maxHeight = (this._maxHeight + offset) * scale;

    // Adjust the vertices.
    for (let i = 1; i < this.vertices.length; i += 3) {
      this.vertices[i] = (this.vertices[i] + offset) * scale;
    }
  }

  /**
   * Reset the world elevation coordinates to zero offset and a scale factor of 1.
   */
  public resetWorldElevationCoordinates() {
    if (this._elevationScale === 1 && this._elevationOffset === 0) {
      return;
    }

    this._minHeight = this._minHeight / this._elevationScale - this._elevationOffset;
    this._maxHeight = this._maxHeight / this._elevationScale - this._elevationOffset;
    for (let i = 1; i < this.vertices.length; i += 3) {
      this.vertices[i] = this.vertices[i] / this._elevationScale - this._elevationOffset;
    }

    this._elevationScale = 1;
    this._elevationOffset = 0;
  }
}

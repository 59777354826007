import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {SupportSessionDialogData} from './support-session-dialog.service';
import {Injectable} from '@angular/core';
import {DocumentType, GetSupportSessionQueryResult, SimVersionStub, StudyStub, SupportSessionStub} from '../../../generated/api-stubs';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {FormSubmissionButton} from '../../common/forms/form-submission-button';
import {CreateUserInformationMaps, UserInformationMap} from '../create-user-information-maps.service';
import {FormSubmissionHandler} from '../../common/forms/form-submission-handler.service';
import {DocumentUpdatedEventService} from '../../worksheets/document-updated-event.service';
import {StudyUtilities} from '../studies/study-utilities.service';
import {getJobIdFromJobIndex} from '../../common/get-job-id-from-job-index';
import { CURRENT_VERSION } from '../../common/constants';
import { ParseReleaseNotes, RELEASE_NOTES_FILE_NAME } from '../../common/parse-release-notes.service';
import { simVersionToNumber } from '../../visualizations/sim-version-to-number';

@Injectable()
export class SupportSessionDialogSessionFactory {
  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly supportSessionStub: SupportSessionStub,
    private readonly studyStub: StudyStub,
    private readonly studyUtilities: StudyUtilities,
    private readonly formSubmissionHandler: FormSubmissionHandler,
    private readonly createUserInformationMaps: CreateUserInformationMaps,
    private readonly documentUpdatedEventService: DocumentUpdatedEventService,
    private readonly simVersionStub: SimVersionStub,
    private readonly parseReleaseNotes: ParseReleaseNotes,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog
  ){}

  public create(dialog: SupportSessionDialogData): SupportSessionDialogSession{
    return new SupportSessionDialogSession(
      dialog,
      this.formBuilder,
      this.supportSessionStub,
      this.studyStub,
      this.studyUtilities,
      this.formSubmissionHandler,
      this.createUserInformationMaps,
      this.documentUpdatedEventService,
      this.simVersionStub,
      this.parseReleaseNotes,
      this.getFriendlyErrorAndLog);
  }
}

@Injectable()
export class SupportSessionDialogSession {
  public errorMessage: string;
  public data: GetSupportSessionQueryResult;

  private openSupportSessionButton = new FormSubmissionButton('Open Support Session', 'Opening...');
  private updateSupportSessionButton = new FormSubmissionButton('Update Support Session', 'Updating...');
  private reopenSupportSessionButton = new FormSubmissionButton('Reopen Support Session', 'Opening...');

  public closeSupportSessionButton = new FormSubmissionButton('Close Support Session', 'Closing...');

  public form: UntypedFormGroup;
  public message: UntypedFormControl = new UntypedFormControl('', []);
  public areWarningsUnrelated: UntypedFormControl = new UntypedFormControl(true, [Validators.requiredTrue]);
  public isSimVersionUnrelated: UntypedFormControl = new UntypedFormControl(true, [Validators.requiredTrue]);
  public submitButton: FormSubmissionButton;

  public map: UserInformationMap;

  public tenantId: string;
  public documentId: string;
  public showZendesk: boolean;

  public warnings: ReadonlyArray<string>;
  public isLatestSimVersion: boolean = true;
  public hasFormSubmissionBeenAttempted: boolean = false;

  constructor(
    private readonly dialog: SupportSessionDialogData,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly supportSessionStub: SupportSessionStub,
    private readonly studyStub: StudyStub,
    private readonly studyUtilities: StudyUtilities,
    private readonly formSubmissionHandler: FormSubmissionHandler,
    private readonly createUserInformationMaps: CreateUserInformationMaps,
    private readonly documentUpdatedEventService: DocumentUpdatedEventService,
    private readonly simVersionStub: SimVersionStub,
    private readonly parseReleaseNotes: ParseReleaseNotes,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog){

    this.tenantId = dialog.tenantId;
    this.documentId = dialog.documentId;

    this.showZendesk = true;
      // this.tenantId === '5dc93a6f25e4473e0d0808d9bfb8ba2b'
      // || this.tenantId === '9df89e7c276d41bd9ebf7afc492127b4'
      // || this.tenantId === 'a4ed02d5506c4237a58d520e151f74be'
      // || this.tenantId === 'e200c94d995dec11a3ee0003ff2f291d';
  }

  public async load() {
    try {

      this.form = this.formBuilder.group({
        message: this.message,
        areWarningsUnrelated: this.areWarningsUnrelated,
        isSimVersionUnrelated: this.isSimVersionUnrelated
      });

      this.errorMessage = undefined;
      if(this.dialog.loadedSession){
        this.data = this.dialog.loadedSession;
      } else {
        this.data = await this.supportSessionStub.getSupportSession(
          this.dialog.tenantId,
          this.dialog.documentId);
      }

      this.dialog.dismissData = this.data;

      this.processSupportSession();
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public get isLoaded(): boolean {
    return !!this.data && !!this.submitButton;
  }

  public updateAndOpen() {
    return this.updateAndSetStatus(false);
  }
  public updateAndClose() {
    return this.updateAndSetStatus(true);
  }
  public updateAndKeepStatus() {
    return this.updateAndSetStatus(undefined);
  }
  private async updateAndSetStatus(isClosed?: boolean){
    try {
      this.errorMessage = undefined;
      await this.supportSessionStub.putSupportSession(
        this.tenantId,
        this.documentId,
        {
          message: this.message.value,
          isClosed
        });

      this.documentUpdatedEventService.emit(this.dialog.tenantId, this.dialog.documentId);

      this.message.setValue('', {});
      this.form.markAsPristine();

      await this.refreshSupportSession();
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  private async refreshSupportSession(){
    this.data = undefined;

    this.data = await this.supportSessionStub.getSupportSession(
      this.dialog.tenantId,
      this.dialog.documentId);

    this.dialog.dismissData = this.data;

    this.processSupportSession();
  }

  private async processSupportSession(){
    this.map = this.createUserInformationMaps.execute(this.data.userInformation);

    if(!this.data.session){
      await this.loadNewSupportSessionData();

      this.submitButton = this.openSupportSessionButton;
    } else{
      if(this.data.session.isOpen){
        this.submitButton = this.updateSupportSessionButton;
      } else{
        this.submitButton = this.reopenSupportSessionButton;
      }
    }
  }

  private async loadNewSupportSessionData() {

    if(this.dialog.documentType !== DocumentType.study) {
      return;
    }

    // Load study metadata.
    let studyMetadata = await this.studyStub.getStudyMetadata(this.tenantId, this.documentId);

    // Load job warnings for first job.
    let warningsText = await this.studyUtilities.downloadWarnings(
      this.tenantId,
      this.documentId,
      getJobIdFromJobIndex(this.documentId, 0),
      studyMetadata.accessInformation.jobs[0].url + '0/',
      studyMetadata.accessInformation);

    this.warnings = warningsText ? warningsText.split('\n') : [];

    let releaseNotes = await this.simVersionStub.getWikiDocument(CURRENT_VERSION, RELEASE_NOTES_FILE_NAME, this.tenantId);

    let latestSimVersion = Math.max(...this.parseReleaseNotes
    .execute(releaseNotes.document.content)
    .map(rn => simVersionToNumber(rn.simVersion)));

    let studySimVersion = simVersionToNumber(studyMetadata.study.simVersion);

    if(studySimVersion != latestSimVersion){
      this.isLatestSimVersion = false;
      this.isSimVersionUnrelated.setValue(false, {})
    }

    if(this.warnings.length) {
      this.areWarningsUnrelated.setValue(false, {});
    }
  }

  public async onSubmitAndOpen() {
    if(this.data && this.data.session && this.data.session.isOpen){
      await this.formSubmissionHandler.execute(this.updateAndKeepStatus, this.form, this.submitButton, this);
    } else{
      await this.formSubmissionHandler.execute(this.updateAndOpen, this.form, this.submitButton, this);
    }
  }

  public async onSubmitAndClose() {
    await this.formSubmissionHandler.execute(this.updateAndClose, null, this.closeSupportSessionButton, this);
  }
}


import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {InferableRouteParams} from '../../common/inferable-route-params.service';
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';

@Component({
  selector: 'cs-tenant-settings-channel-whitelists-page',
  templateUrl: './tenant-settings-channel-whitelists-page.component.html',
  styleUrls: ['./tenant-settings-channel-whitelists-page.component.scss']
})
export class TenantSettingsChannelWhitelistsPageComponent implements OnInit {
  public errorMessage: string;

  public tenantId: string;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly inferableRouteParams: InferableRouteParams,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) { }

  ngOnInit() {
    this.load();
  }

  public load(){
    try {
      this.tenantId = this.inferableRouteParams.getTenantId(this.route);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }
}


import {Injectable} from '@angular/core';
import {
  ConditionConfigMetadataName,
  ConditionMetadataName, ConditionOperator, ConditionSource, DocumentSubType, getConditionOperatorValues,
  GetSimVersionDocumentsQueryResult,
  GetTenantUsersQueryResult
} from '../../../generated/api-stubs';
import {StudyTypeLookup} from '../studies/study-type-lookup.service';
import {
  ConditionSourceViewModel, ConditionSourceItemViewModel, ConditionSourceItemSetViewModel, ConditionSourceItemType,
  ConditionValueViewModel
} from './filter-query-builder-types';
import {StudyStagingArea, StudyTypeItem} from '../study-staging-area/study-staging-area.service';
import {GroupResults} from './list-filter.component';
import {Subscription} from 'rxjs';
import {SimVersionDocumentCache} from '../sim-version-document-cache.service';
import {CanopyValidators} from '../../common/forms/canopy-validators.service';
import {Validators} from '@angular/forms';
import {GetStudyAutocompleteOptions} from '../configs/json-config-editor/get-study-autocomplete-options.service';
import {EditorAutocompleteInformation} from '../configs/json-config-editor/editor-autocomplete-information';
import {getParameterNameFromPath} from '../configs/json-config-editor/get-parameter-name-from-path';

@Injectable()
export class ListFilterQueryBuilderDataSourceFactory {
  constructor(
    private readonly studyTypeLookup: StudyTypeLookup,
    private readonly getStudyAutocompleteOptions: GetStudyAutocompleteOptions,
    private readonly studyStagingArea: StudyStagingArea,
    private readonly simVersionDocumentCache: SimVersionDocumentCache){
  }

  public create(
    filterConfigType: DocumentSubType,
    isPersistentFilter: boolean,
    tenantUsers: GetTenantUsersQueryResult): ListFilterQueryBuilderDataSource{

    return new ListFilterQueryBuilderDataSource(
      filterConfigType,
      isPersistentFilter,
      tenantUsers,
      this.studyTypeLookup,
      this.getStudyAutocompleteOptions,
      this.studyStagingArea,
      this.simVersionDocumentCache);
  }
}

const StrictEqualityOperators: ConditionOperator[] = [
  ConditionOperator.equals,
  ConditionOperator.notEquals
];

const EqualityOperators: ConditionOperator[] = [
  ConditionOperator.equals,
  ConditionOperator.notEquals,
  ConditionOperator.lessThan,
  ConditionOperator.greaterThan,
  ConditionOperator.lessThanOrEquals,
  ConditionOperator.greaterThanOrEquals
];

const AllConditionOperators: ConditionOperator[] = getConditionOperatorValues();

const CustomPropertyNameValidators = [Validators.required, ...CanopyValidators.customPropertyNameValidators];
const ConfigPathValidators = [Validators.required, ...CanopyValidators.configPathValidators];

export class ListFilterQueryBuilderDataSource {

  private simVersionDocuments: GetSimVersionDocumentsQueryResult;
  private studyTypesList: StudyTypeItem[];
  private studyAutocompleteInformation: EditorAutocompleteInformation;

  private studyStagingAreaChangedSubscription: Subscription;

  private filterAutocompleteConfigType: DocumentSubType;

  private _hasConditionSourceItemsChanged: boolean = true;

  constructor(
    private readonly filterConfigType: DocumentSubType,
    private readonly isPersistentFilter: boolean,
    private readonly tenantUsers: GetTenantUsersQueryResult,
    private readonly studyTypeLookup: StudyTypeLookup,
    private readonly getStudyAutocompleteOptions: GetStudyAutocompleteOptions,
    private readonly studyStagingArea: StudyStagingArea,
    private readonly simVersionDocumentCache: SimVersionDocumentCache
  ){
    if(this.filterConfigType !== DocumentSubType.studyFilter){
      this.filterAutocompleteConfigType = <DocumentSubType>this.filterConfigType.substr(0, this.filterConfigType.length - 'Filter'.length);
    }
  }

  public async initialize(){
    await this.getStudyAutocompleteOptions.initialize();
    this.studyTypesList = await this.studyTypeLookup.getStudyTypeList();

    this.simVersionDocuments = await this.simVersionDocumentCache.get(this.simVersionDocumentCache.lastRequestedVersion);

    this.studyStagingAreaChangedSubscription = this.studyStagingArea.changed.subscribe(() => this.onStudyStagingAreaChanged());
    this.onStudyStagingAreaChanged();
  }

  public dispose(){
    if(this.studyStagingAreaChangedSubscription){
      this.studyStagingAreaChangedSubscription.unsubscribe();
    }
  }

  public onStudyStagingAreaChanged(){
    this.studyAutocompleteInformation = this.getStudyAutocompleteOptions.executeSynchronous(
      false,
      false,
      this.filterAutocompleteConfigType);
    this._hasConditionSourceItemsChanged = true;
  }

  public get hasConditionSourceItemsChanged(): boolean {
    return this._hasConditionSourceItemsChanged;
  }

  public getConditionSourceViewModel(source: ConditionSource): ConditionSourceViewModel {
    switch(source){
      case ConditionSource.metadata:
        return new ConditionSourceViewModel(ConditionSource.metadata, 'Metadata');

      case ConditionSource.customProperty:
        return new ConditionSourceViewModel(ConditionSource.customProperty, 'Custom Property', CustomPropertyNameValidators);

      case ConditionSource.config:
        return new ConditionSourceViewModel(ConditionSource.config, 'Config', ConfigPathValidators);

      case ConditionSource.configMetadata:
        return new ConditionSourceViewModel(ConditionSource.configMetadata, 'Config Metadata');
    }

    return new ConditionSourceViewModel(source, source);
  }

  public getConditionSources(filterConfigType: DocumentSubType): ReadonlyArray<ConditionSourceViewModel> {
    switch(filterConfigType){
      case DocumentSubType.jobFilter:
        return [
          this.getConditionSourceViewModel(ConditionSource.metadata),
        ];

      case DocumentSubType.studyFilter:
        return [
          this.getConditionSourceViewModel(ConditionSource.customProperty),
          this.getConditionSourceViewModel(ConditionSource.metadata),
          this.getConditionSourceViewModel(ConditionSource.config),
          this.getConditionSourceViewModel(ConditionSource.configMetadata)
        ];

      default:
        if(this.isPersistentFilter){
          return [
            this.getConditionSourceViewModel(ConditionSource.customProperty),
            this.getConditionSourceViewModel(ConditionSource.metadata),
            this.getConditionSourceViewModel(ConditionSource.config),
          ];
        }

        return [
          this.getConditionSourceViewModel(ConditionSource.customProperty),
          this.getConditionSourceViewModel(ConditionSource.metadata),
        ];
    }
  }

  public getConditionSourceItemViewModel(source: ConditionSource, name: string, groupResults: GroupResults){

    groupResults = groupResults || [];

    switch(source){
      case ConditionSource.metadata:

        let metadataName: ConditionMetadataName = name as ConditionMetadataName;

        switch(metadataName){
          case ConditionMetadataName.state:
            return new ConditionSourceItemViewModel(metadataName, 'State', null, ConditionSourceItemType.string, StrictEqualityOperators,
              [
                new ConditionValueViewModel('successful', 'Successful'),
                new ConditionValueViewModel('failed', 'Failed')
              ], true);

          case ConditionMetadataName.userId:
            return new ConditionSourceItemViewModel(metadataName, 'User ID', null, ConditionSourceItemType.string, StrictEqualityOperators,
              this.tenantUsers.users.map(v => new ConditionValueViewModel(v.userId, v.username)),
              true);
          case ConditionMetadataName.name:
            return new ConditionSourceItemViewModel(metadataName, 'Name', null, ConditionSourceItemType.string, StrictEqualityOperators);
          case ConditionMetadataName.parentWorksheetId:
            return new ConditionSourceItemViewModel(metadataName, 'Parent Worksheet ID', null, ConditionSourceItemType.string, StrictEqualityOperators);
          case ConditionMetadataName.simVersion:
            return new ConditionSourceItemViewModel(metadataName, 'Sim Version', null, ConditionSourceItemType.number, StrictEqualityOperators);
          case ConditionMetadataName.creationDate:
            return new ConditionSourceItemViewModel(metadataName, 'Creation Date', null, ConditionSourceItemType.dateTime, EqualityOperators);
          case ConditionMetadataName.modifiedDate:
            return new ConditionSourceItemViewModel(metadataName, 'Modified Date', null, ConditionSourceItemType.dateTime, EqualityOperators);
          case ConditionMetadataName.supportSession:
            return new ConditionSourceItemViewModel(metadataName, 'Support Session', null, ConditionSourceItemType.string, StrictEqualityOperators,
              [
                new ConditionValueViewModel('open', 'Open'),
                new ConditionValueViewModel('closed', 'Closed')
              ],
              true);

          case ConditionMetadataName.studyType:
            return new ConditionSourceItemViewModel(metadataName, 'Study Type', null, ConditionSourceItemType.string, StrictEqualityOperators,
              this.studyTypesList.map(v => new ConditionValueViewModel(v.studyType, v.name)),
              true);
          case ConditionMetadataName.executionTimeSeconds:
            return new ConditionSourceItemViewModel(metadataName, 'Execution Time', 's', ConditionSourceItemType.number, EqualityOperators);
          case ConditionMetadataName.jobCount:
            return new ConditionSourceItemViewModel(metadataName, 'Job Count', null, ConditionSourceItemType.number, EqualityOperators);
          case ConditionMetadataName.succeededJobCount:
            return new ConditionSourceItemViewModel(metadataName, 'Succeeded Job Count', null, ConditionSourceItemType.number, EqualityOperators);
          case ConditionMetadataName.failedJobCount:
            return new ConditionSourceItemViewModel(metadataName, 'Failed Job Count', null, ConditionSourceItemType.number, EqualityOperators);
          case ConditionMetadataName.succeededComputeCredits:
            return new ConditionSourceItemViewModel(metadataName, 'Succeeded Compute Credits', null, ConditionSourceItemType.number, EqualityOperators);
          case ConditionMetadataName.succeededStorageCredits:
            return new ConditionSourceItemViewModel(metadataName, 'Succeeded Storage Credits', null, ConditionSourceItemType.number, EqualityOperators);
        }

        break;

      case ConditionSource.customProperty:

        let group = groupResults.find(v => v.name === name) || { name, groups: [] };
        return new ConditionSourceItemViewModel(
          group.name,
          group.name,
          null,
          ConditionSourceItemType.string,
          AllConditionOperators,
          group.groups.map(v => new ConditionValueViewModel(v.key)),
          false);

      case ConditionSource.config:
        return new ConditionSourceItemViewModel(
          name,
          name,
          this.simVersionDocuments.units[getParameterNameFromPath(name)],
          ConditionSourceItemType.string,
          AllConditionOperators);

      case ConditionSource.configMetadata:

        let configMetadataName: ConditionConfigMetadataName = name as ConditionConfigMetadataName;

        switch(configMetadataName) {
          case ConditionConfigMetadataName.name:
            return new ConditionSourceItemViewModel(configMetadataName, 'Name', null, ConditionSourceItemType.string, EqualityOperators);
        }
      }

    this._hasConditionSourceItemsChanged = false;

    return new ConditionSourceItemViewModel(name, name, null, ConditionSourceItemType.string);
  }

  public getConditionSourceItems(source: string, groupResults: GroupResults): ConditionSourceItemSetViewModel {
    let items: ConditionSourceItemViewModel[] = [];
    let isFiniteSet = true;
    let autocompleteOptions: any;

    groupResults = groupResults || [];

    switch(source){
      case ConditionSource.metadata:

        if(this.filterConfigType === DocumentSubType.jobFilter){
          items.push(
            this.getConditionSourceItemViewModel(source, ConditionMetadataName.state, groupResults));
        } else{
          items.push(
            this.getConditionSourceItemViewModel(source, ConditionMetadataName.name, groupResults),
            this.getConditionSourceItemViewModel(source, ConditionMetadataName.userId, groupResults),
            this.getConditionSourceItemViewModel(source, ConditionMetadataName.simVersion, groupResults),
            this.getConditionSourceItemViewModel(source, ConditionMetadataName.creationDate, groupResults),
            this.getConditionSourceItemViewModel(source, ConditionMetadataName.modifiedDate, groupResults),
            this.getConditionSourceItemViewModel(source, ConditionMetadataName.supportSession, groupResults),
            this.getConditionSourceItemViewModel(source, ConditionMetadataName.parentWorksheetId, groupResults));
        }

        switch(this.filterConfigType){
          case DocumentSubType.studyFilter:
            items.push(
              this.getConditionSourceItemViewModel(source, ConditionMetadataName.studyType, groupResults),
              this.getConditionSourceItemViewModel(source, ConditionMetadataName.executionTimeSeconds, groupResults),
              this.getConditionSourceItemViewModel(source, ConditionMetadataName.jobCount, groupResults),
              this.getConditionSourceItemViewModel(source, ConditionMetadataName.succeededJobCount, groupResults),
              this.getConditionSourceItemViewModel(source, ConditionMetadataName.failedJobCount, groupResults),
              this.getConditionSourceItemViewModel(source, ConditionMetadataName.succeededComputeCredits, groupResults),
              this.getConditionSourceItemViewModel(source, ConditionMetadataName.succeededStorageCredits, groupResults));
            break;
        }

        break;

      case ConditionSource.customProperty:
        isFiniteSet = false;
        for(let group of groupResults){
          items.push(this.getConditionSourceItemViewModel(source, group.name, groupResults));
        }
        break;

      case ConditionSource.config:
        isFiniteSet = false;
        if(this.studyAutocompleteInformation){
          autocompleteOptions = this.studyAutocompleteInformation.options;
          for(let autocompleteItem of this.studyAutocompleteInformation.autocompleteList){
            let name: string = (typeof autocompleteItem === 'object') ? autocompleteItem.value : autocompleteItem;
            items.push(this.getConditionSourceItemViewModel(source, name, groupResults));
          }
        }

        break;

      case ConditionSource.configMetadata:
        items.push(
          this.getConditionSourceItemViewModel(source, ConditionConfigMetadataName.name, groupResults));

        break;
    }

    return new ConditionSourceItemSetViewModel(
      isFiniteSet,
      items,
      autocompleteOptions);
  }
}

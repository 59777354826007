import {
  NavigationStationConfig
} from './navigation-station-config-builder';
import { StudyJob } from '../../study-job';
import { SimType } from '../../sim-type';
import { RequestedLayoutIds, SiteHooks } from '../../site-hooks';
import { UrlFileLoader } from '../../url-file-loader';
import { ExplorationConfigBuilderBase } from './exploration-config-builder-base';
import { DimensionsSharedState, SharedState } from '../../viewers/shared-state';
import { PARALLEL_COORDINATES_VIEWER_TYPE } from '../../viewers/parallel-coordinates-viewer/parallel-coordinates-viewer';
import { ChannelNameStyle } from '../../viewers/channel-data-loaders/channel-name-style';
import { DEFAULT_LAYOUT_ID } from './config-builder-base';
import { LINE_SCATTER_PLOT_VIEWER_TYPE, ScatterPlotViewer } from '../../viewers/scatter-plot-viewer/scatter-plot-viewer';
import { StudySourceLoader } from '../../viewers/channel-data-loaders/study-source-loader';
import { SourceLoaderViewModel } from '../../viewers/channel-data-loaders/source-loader-set';
import { ChannelInterpolatorFactory } from '../../viewers/channel-data-loaders/interpolation/channel-interpolator-factory';
import { LINE_MULTI_PLOT_VIEWER_TYPE } from '../../viewers/line-plot-viewer/dimension-line-plot-viewer';
import { ParallelCoordinatesViewer } from '../../viewers/parallel-coordinates-viewer/parallel-coordinates-viewer';

/**
 * A config builder for Monte Carlo explorations. This is also used for Factorial explorations.
 */
export class MonteCarloConfigBuilder extends ExplorationConfigBuilderBase {

  /**
   * Constructs a new Monte Carlo config builder.
   * @param urlFileLoader The file loader.
   * @param siteHooks The site hooks.
   * @param studyJobs The study jobs for this builder session.
   * @param simTypes The sim types for this builder session.
   */
  constructor(
    urlFileLoader: UrlFileLoader,
    siteHooks: SiteHooks,
    studyJobs: StudyJob[],
    simTypes: SimType[]) {
    super(urlFileLoader, siteHooks, studyJobs, simTypes);
  }

  /**
   * @inheritdoc
   */
  public async build(): Promise<NavigationStationConfig> {

    await this.load();

    if (!this.explorationAndScalarDataCache) {
      throw new Error('Builder not initialized.');
    }

    const explorationAndScalarDataCache = this.explorationAndScalarDataCache;

    let defaultChartName = this.simTypes && this.simTypes.length ? 'Default-' + this.simTypes[0] : 'Default';

    let dimensionsSharedState = new DimensionsSharedState(this.rCoordinates, this.rDomainExtents);

    let studySharedState = new SharedState(undefined, dimensionsSharedState);

    let studyLoaders = this.studyJobs.map(v =>
      new SourceLoaderViewModel(StudySourceLoader.create(this.siteHooks, this.fileLoader, v.studyId, explorationAndScalarDataCache)));
    studySharedState.sourceLoaderSet.add(...studyLoaders);

    let config: NavigationStationConfig = {
      channelNameStyle: ChannelNameStyle.FullyQualified,
      sharedStates: [studySharedState],
      views: []
    };

    let channelInterpolatorFactory = new ChannelInterpolatorFactory();
    let explorationAndScalarData = await this.explorationAndScalarDataCache.get();
    if (!explorationAndScalarData) {
      return config;
    }

    let channelInterpolator = channelInterpolatorFactory.create(explorationAndScalarDataCache);

    let resolvedLineMultiPlotViewerLayout = await this.resolveViewerLayout(LINE_MULTI_PLOT_VIEWER_TYPE, new RequestedLayoutIds(DEFAULT_LAYOUT_ID));
    let scatterPlotViewerLayout = await this.resolveViewerLayout(LINE_SCATTER_PLOT_VIEWER_TYPE, new RequestedLayoutIds(defaultChartName));
    let parallelCoordinatesViewerLayout = await this.resolveViewerLayout(PARALLEL_COORDINATES_VIEWER_TYPE, new RequestedLayoutIds(defaultChartName));

    // Create one plot for each dimension.
    for (let dimensionIndex = 0; dimensionIndex < this.numberOfDimensions; dimensionIndex++) {
      let grid = {
        width: 6,
        height: 6
      };

      await this.createDimensionViewer(
        dimensionsSharedState,
        dimensionIndex,
        channelInterpolator,
        config,
        resolvedLineMultiPlotViewerLayout,
        true,
        grid);
    }

    // Insert a scatter plot.
    config.views.unshift({
      title: 'Scatter Plots',
      viewerType: LINE_SCATTER_PLOT_VIEWER_TYPE,
      layout: scatterPlotViewerLayout,
      viewer: ScatterPlotViewer.create(
        'index',
        scatterPlotViewerLayout.resolvedLayout.getConfigCopy(),
        config.channelNameStyle,
        studySharedState,
        this.siteHooks),
      grid: this.getGridSlot({
        width: 6,
        height: 6
      }, scatterPlotViewerLayout.viewerMetadata)
    });

    // Insert the parallel coordinates plot.
    config.views.unshift({
      title: 'All Dimensions',
      viewerType: PARALLEL_COORDINATES_VIEWER_TYPE,
      layout: parallelCoordinatesViewerLayout,
      viewer: ParallelCoordinatesViewer.create(
        parallelCoordinatesViewerLayout.resolvedLayout.getConfigCopy(),
        config.channelNameStyle,
        studySharedState,
        this.siteHooks,
        channelInterpolator,
        explorationAndScalarDataCache),
      grid: this.getGridSlot({
        width: 12,
        height: 7
      }, parallelCoordinatesViewerLayout.viewerMetadata)
    });

    return config;
  }
}

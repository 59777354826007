import { ConfigSubTreeRepository } from '../../config-sub-tree-repository.service';
import { DocumentSubType } from '../../../../../generated/api-stubs';
import { Timer } from '../../../../common/timer.service';
import { Inject, Injectable } from '@angular/core';
import { GetFriendlyErrorAndLog } from '../../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import { ConfirmationDialog } from '../../../../common/dialogs/confirmation-dialog.service';
import { EditorNodeExtensions } from './editor-node-extensions';
import { AddButtonsHandler } from './add-buttons-handler';
import { EditorNodeUtilities } from './editor-node-utilities';


@Injectable()
export class EditorNodeExtensionsFactory {
  constructor(
    @Inject(AddButtonsHandler) private readonly extensions: AddButtonsHandler[],
    private readonly timer: Timer,
    private readonly confirmationDialog: ConfirmationDialog,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  public create(
    simVersion: string,
    configType: DocumentSubType,
    self: any,
    configSubTreeRepository: ConfigSubTreeRepository) {

    return new EditorNodeExtensions(
      this.extensions,
      self,
      new EditorNodeUtilities(
        simVersion,
        configType,
        self,
        configSubTreeRepository,
        this.timer,
        this.confirmationDialog,
        this.getFriendlyErrorAndLog
      ));
  }
}

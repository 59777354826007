import {DiffHashMap, DiffResult} from '../../../../common/canopy-json-diff-patch.service';
import {Injectable} from '@angular/core';
import {DisplayableError} from '../../../../common/errors/errors';

@Injectable()
export class ConvertDiffToViewModel {

  public execute(diffResult: DiffResult) {
    return this.inner(diffResult.data, diffResult.hashMap, diffResult.left, diffResult.right);
  }

  // https://github.com/benjamine/jsondiffpatch/blob/master/docs/deltas.md
  // https://benjamine.github.io/jsondiffpatch/demo/index.html
  private inner(diff: any, hashMap: DiffHashMap, left: any, right: any): Delta {
    if(typeof diff !== 'object') {
      throw new DisplayableError('Unexpected diff result format.');
    }

    if(Array.isArray(diff)){
      if(diff.length === 1){
        return new Added(diff[0]);
      } else if(diff.length === 2){
        return new Modified(diff[0], diff[1]);
      } else if(diff.length === 3 && diff[1] === 0 && diff[2] === 0){
        return new Deleted(diff[0]);
      } else if(diff.length === 3 && diff[1] === 0 && diff[2] === 2){
        return new TextUnidiff(diff[0]);
      } else if(diff.length === 3 && diff[2] === 3){
        return new Moved(diff[0], diff[1]);
      } else {
        throw new DisplayableError('Unexpected diff result array format.');
      }
    } else{
      if(diff._t === 'a'){
        let indices: IndexDelta[] = [];

        for(let key in diff){
          if(!diff.hasOwnProperty(key)){
            continue;
          }

          if(key === '_t'){
            continue;
          }

          let index: number;
          if(key.startsWith('_')){
            index = +key.substr(1);
          } else {
            index = +key;
          }

          indices.push(
            new IndexDelta(index, this.inner(diff[key], hashMap, left[index], right[index])));
        }

        // Merge added and removed on same index into modified.
        for(let i=indices.length-1; i>=0; --i){
          let item = indices[i];
          for(let j=i-1; j>=0; --j){
            let other = indices[j];
            if(other.index === item.index){
              if(item.delta instanceof Added && other.delta instanceof Deleted){
                indices.splice(i, 1);
                indices[j] = new IndexDelta(item.index, new Modified(other.delta.oldValue, item.delta.newValue));
              } else if(item.delta instanceof Deleted && other.delta instanceof Added){
                indices.splice(i, 1);
                indices[j] = new IndexDelta(item.index, new Modified(item.delta.oldValue, other.delta.newValue));
              }
            }
          }
        }

        return new ArrayDelta(left, right, indices);
      } else{
        let properties: PropertyDelta[] = [];

        for(let key in diff){
          if(!diff.hasOwnProperty(key)){
            continue;
          }

          properties.push(
            new PropertyDelta(key, this.inner(diff[key], hashMap, left[key], right[key])));
        }

        return new ObjectDelta(left, right, properties);
      }
    }
  }
}


export class Added implements Delta {
  public readonly deltaType = DeltaType.added;
  public readonly isComplexValue: boolean;

  constructor(
    public readonly newValue: any) {
    this.isComplexValue = typeof newValue === 'object';
  }
}

export class Modified implements Delta {
  public readonly deltaType = DeltaType.modified;
  public readonly isComplexValue: boolean;

  constructor(
    public readonly oldValue: any,
    public readonly newValue: any) {
    this.isComplexValue = typeof oldValue === 'object' || typeof newValue === 'object';
  }
}

export class Deleted implements Delta {
  public readonly deltaType = DeltaType.deleted;
  public readonly isComplexValue: boolean;

  constructor(
    public readonly oldValue: any) {
    this.isComplexValue = typeof oldValue === 'object';
  }
}

export class Moved implements Delta {
  public readonly deltaType = DeltaType.moved;
  public readonly isComplexValue: boolean = false;

  constructor(
    public readonly movedValue: any,
    public readonly destinationindex: number) {
  }
}

export class TextUnidiff implements Delta {
  public readonly deltaType = DeltaType.unidiff;
  public readonly isComplexValue: boolean = true;

  constructor(
    public readonly value: string) {
  }
}

export class PropertyDelta {
  constructor(
    public readonly name: string,
    public readonly delta: Delta) {
  }
}

export class IndexDelta {
  constructor(
    public readonly index: number,
    public readonly delta: Delta) {
  }
}

export class ObjectDelta implements Delta {
  public readonly deltaType = DeltaType.object;
  public readonly isComplexValue: boolean = true;

  constructor(
    public readonly left: any,
    public readonly right: any,
    public readonly properties: ReadonlyArray<PropertyDelta>) {
  }
}

export class ArrayDelta implements Delta {
  public readonly deltaType = DeltaType.array;
  public readonly isComplexValue: boolean = true;

  constructor(
    public readonly left: any,
    public readonly right: any,
    public readonly items: ReadonlyArray<IndexDelta>) {
  }
}

export enum DeltaType {
  object = 'object',
  array = 'array',
  added = 'added',
  modified = 'modified',
  deleted = 'deleted',
  moved = 'moved',
  unidiff = 'unidiff',
}

export interface Delta {
  readonly deltaType: DeltaType;
  readonly isComplexValue: boolean;
}

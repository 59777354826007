import { Utilities } from '../../utilities';
import { IMultiPlotLayout } from './types/i-multi-plot-layout';
import { GetValidatedChannel } from './get-validated-channel';
import { NO_PRIMARY_DOMAIN_SPECIFIED_ERROR_MESSAGE, CanStackDiagonals } from './can-stack-diagonals';
import { ValidateSide } from './validate-side';


/**
 * Validates a multi-plot layout.
 */
export class GetValidatedLayout {

  constructor(
    private readonly primaryDomainName: string,
    private readonly validateSide: ValidateSide,
    private readonly getValidatedChannel: GetValidatedChannel) {
  }

  /**
   * Take a layout and returns a validated and sanitized version of the layout.
   * Does not modify the passed in layout.
   *
   * @param layout - The layout to validate.
   * @returns A new validated and sanitized layout.
   */
  public async execute(layout: IMultiPlotLayout): Promise<IMultiPlotLayout> {

    // Clone the input layout so we don't modify it.
    layout = Utilities.deepClone(layout);

    if (!this.primaryDomainName) {
      throw new Error(NO_PRIMARY_DOMAIN_SPECIFIED_ERROR_MESSAGE);
    }

    // If there are no rows, create a default row.
    if (!layout.rows || !layout.rows.length) {
      layout.rows = [{
        channels: [],
        relativeSize: 1
      }];
    }

    // If there are no columns, duplicate the rows so we plot channels against each other.
    if (!layout.columns || !layout.columns.length) {
      layout.columns = Utilities.deepClone(layout.rows);

      // Reverse to ensure the matching channels are plotted from bottom left to top right.
      layout.columns.reverse();
    }

    Utilities.removeNullOrUndefinedItems(layout.columns);
    Utilities.removeNullOrUndefinedItems(layout.rows);

    for (let column of layout.columns) {

      // Validate the column.
      this.validateSide.execute(column);

      if (column.channels.length) {
        // For each column, ensure only one channel is visible.
        let visibleChannels = column.channels.filter(v => v.isVisible);
        if (visibleChannels.length === 0) {
          column.channels[0].isVisible = true;
        } else if (visibleChannels.length > 1) {
          for (let i = 1; i < visibleChannels.length; ++i) {
            visibleChannels[i].isVisible = false;
          }
        }
      }
    }

    for (let row of layout.rows) {
      // Validate the row.
      this.validateSide.execute(row);
    }

    let canStackDiagonals = CanStackDiagonals.execute(layout.rows.length, layout.columns.length);

    // If we can't stack diagonals, remove the flags indicating we should do so.
    if (!canStackDiagonals) {
      delete layout.stackDiagonalsHorizontally;
      delete layout.stackDiagonalsVertically;
    }

    // Validate the color channel.
    if (layout.colorChannel) {
      layout.colorChannel = this.getValidatedChannel.execute(layout.colorChannel);
    }

    // Validate the size channel.
    if (layout.sizeChannel) {
      layout.sizeChannel = this.getValidatedChannel.execute(layout.sizeChannel);
    }

    return layout;
  }
}

import { Injectable, EventEmitter, Output, Directive } from '@angular/core';

export const DIALOG_ALREADY_SHOWN_ERROR = 'Dialog is already being shown.';

@Directive()
@Injectable()
export class DialogManager {
  @Output() primaryChanged: EventEmitter<IDialog> = new EventEmitter<IDialog>();
  @Output() secondaryChanged: EventEmitter<IDialog> = new EventEmitter<IDialog>();
  @Output() tertiaryChanged: EventEmitter<IDialog> = new EventEmitter<IDialog>();

  private primary: IDialog;
  private secondary: IDialog;
  private tertiary: IDialog;

  public showPrimaryDialog(dialogData: IDialog) {
    this.resetPrimary();
    this.primary = dialogData;
    let promise = this.primary.promise;
    this.attachToPrimary();
    this.primaryChanged.emit(this.primary);
    return promise;
  }

  public showSecondaryDialog(dialogData: IDialog) {
    this.resetSecondary();
    this.secondary = dialogData;
    let promise = this.secondary.promise;
    this.attachToSecondary();
    this.secondaryChanged.emit(this.secondary);
    return promise;
  }

  public showTertiaryDialog(dialogData: IDialog) {
    this.resetTertiary();
    this.tertiary = dialogData;
    let promise = this.tertiary.promise;
    this.attachToTertiary();
    this.tertiaryChanged.emit(this.tertiary);
    return promise;
  }

  public getPrimary() {
    return this.primary;
  }

  public getSecondary() {
    return this.secondary;
  }

  public getTertiary() {
    return this.tertiary;
  }

  public dismiss() {
    for(let dialog of [this.tertiary, this.secondary, this.primary]){
      if(dialog){
        dialog.dismiss();
        return;
      }
    }
  }

  private async attachToPrimary(){
    try {
      await this.primary.promise;
      this.resetPrimary();
    } catch(e){
      this.resetPrimary();
    }
  }

  private async attachToSecondary(){
    try {
      await this.secondary.promise;
      this.resetSecondary();
    } catch(e){
      this.resetSecondary();
    }
  }

  private async attachToTertiary(){
    try {
      await this.tertiary.promise;
      this.resetTertiary();
    } catch(e){
      this.resetTertiary();
    }
  }

  private resetPrimary() {
    if(this.primary){
      this.primary = undefined;
      this.primaryChanged.emit(this.primary);
    }

    this.resetSecondary();
  }

  private resetSecondary() {
    if(this.secondary){
      this.secondary = undefined;
      this.secondaryChanged.emit(this.secondary);
    }

    this.resetTertiary();
  }

  private resetTertiary() {
    if(this.tertiary){
      this.tertiary = undefined;
      this.tertiaryChanged.emit(this.tertiary);
    }
  }
}

export interface IDialog {
  promise: Promise<any>;
  dismiss(): void;
}

export abstract class DialogBase<T> implements IDialog {
  private _promise: Promise<any>;
  private _resolve: (value: any) => void;
  private _reject: (value: any) => void;

  public dismissData: T | undefined = undefined;

  constructor() {
    this._promise = new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }

  public get promise(): Promise<T> {
 return this._promise;
}

  public resolve(result: T) {
    this._resolve(result);
  }

  public reject(error: Error) {
    this._reject(error);
  }

  public dismiss(): void {
    this.resolve(this.dismissData);
  }
}


import {Injectable} from '@angular/core';

import {UserIdentity} from '../user-identity';
import {CanopyError} from '../../common/errors/errors';
import {HttpResponse} from '@angular/common/http';

export const NO_USER_ID_ERROR = 'The user ID was not returned';
export const NO_TENANT_ID_ERROR = 'The tenant ID was not returned';
export const NO_ACCESS_TOKEN_ERROR = 'The access token was not returned';
export const NO_REFRESH_TOKEN_ERROR = 'The refresh token was not returned';

@Injectable()
export class ExtractAuthenticationDataFromResponse {

  public execute(response: HttpResponse<any>): UserIdentity {
    let data = response.body;

    let roles = [];
    let rolesString = data.roles;
    if (rolesString) {
      roles = data.roles.split(',');
    }

    let userId = data.user_id;
    if (!userId) {
      throw new CanopyError(NO_USER_ID_ERROR);
    }

    let tenantId = data.tenant_id;
    if (!tenantId) {
      throw new CanopyError(NO_TENANT_ID_ERROR);
    }

    let accessToken = data.access_token;
    if (!accessToken) {
      throw new CanopyError(NO_ACCESS_TOKEN_ERROR);
    }

    let refreshToken = data.refresh_token;
    if (!refreshToken) {
      throw new CanopyError(NO_REFRESH_TOKEN_ERROR);
    }

    return new UserIdentity(true, accessToken, refreshToken, userId, tenantId, roles);
  }
}

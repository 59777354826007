import {Injectable} from '@angular/core';
import {ConfirmationDialog} from './confirmation-dialog.service';
import {PromptDialog} from './prompt-dialog.service';
import {LoadingDialog} from './loading-dialog.service';
import {
  ConfigStub, DocumentSubType, DocumentNamesResultType,
  GetConfigNamesQueryResult, WorksheetStub
} from '../../../generated/api-stubs';
import {distinct} from '../distinct';
import {ConfigLoaderResult} from '../../simulations/configs/config-loader-dialog/config-loader-dialog.service';
import {CloneWorksheet} from '../../worksheets/clone-worksheet.service';
import {CustomPropertyUtilities} from '../../simulations/custom-properties/custom-property-utilities';
import {StudyInput} from '../../worksheets/study-input';
import {fromReadonly} from '../from-readonly';
import { AuthenticationService, UserData } from '../../identity/state/authentication.service';

@Injectable()
export class SaveAsDialog {
  constructor(
    private readonly loadingDialog: LoadingDialog,
    private readonly promptDialog: PromptDialog,
    private readonly confirmationDialog: ConfirmationDialog,
    private readonly configStub: ConfigStub,
    private readonly worksheetStub: WorksheetStub,
    private readonly cloneWorksheet: CloneWorksheet,
    private readonly authenticationService: AuthenticationService){
  }

  public showForReference(
    suggestedName: string,
    configType: DocumentSubType,
    simVersion: string,
    subTreePath: string,
    tenantId: string,
    configId: string): Promise<ConfigLoaderResult> {

    return this.showInner(
      suggestedName,
      configType,
      simVersion,
      subTreePath,
      (userData, name) => this.saveReferenceAsInner(userData, configType, simVersion, subTreePath, name, tenantId, configId));
  }

  public showForContent(
    suggestedName: string,
    configType: DocumentSubType,
    simVersion: string,
    subTreePath: string,
    config: any,
    properties?: ReadonlyArray<ConfigProperty>,
    notes?: string): Promise<ConfigLoaderResult> {

    return this.showInner(
      suggestedName,
      configType,
      simVersion,
      subTreePath,
      (userData, name) => this.saveContentAsInner(userData, configType, simVersion, subTreePath, name, config, properties, notes));
  }

  public async showInner(
    suggestedName: string,
    configType: DocumentSubType,
    simVersion: string,
    subTreePath: string,
    delegate: (userData: UserData, name: string) => Promise<ConfigLoaderResult>): Promise<ConfigLoaderResult>{

    const userData = this.authenticationService.userDataSnapshot;

    let configNames = await this.getConfigNamesInner(userData, configType, simVersion, subTreePath);

    let name = await this.promptDialog.show<string>(
      'Name',
      'Save As...',
      suggestedName || '',
      'Save',
      'Cancel',
      undefined,
      configNames.list);

    if(!name){
      return undefined;
    }

    let result = await delegate(userData, name);

    if(!result){
      return undefined;
    }

    let existingConfigs = configNames.result.names.filter(
      v => v.name === name && v.userId === userData.sub);

    if(existingConfigs.length){
      let configsPlurality = `config${existingConfigs.length === 1 ? '' : 's'}`;
      let shouldDelete = await this.confirmationDialog.show(
        `Would you like to delete ${existingConfigs.length} existing ${configsPlurality} with the same name?`,
        'Delete existing configs?',
        `Permanently delete ${existingConfigs.length} ${configsPlurality}`,
        'No');

      if(shouldDelete){
        await this.deleteConfigInner(userData, existingConfigs.map(v => v.configId), subTreePath);
      }
    }

    return result;
  }

  private async getConfigNamesInner(userData: UserData, configType: DocumentSubType, simVersion: string, subTreePath: string): Promise<ConfigNamesResult> {

    let configNames = await this.loadingDialog.showUntilFinished(
      this.configStub.getConfigNames(
        userData.tenant, configType, DocumentNamesResultType.nameAndIdAndOwner, subTreePath, simVersion),
      'Loading existing names...');

    return {
      result: configNames,
      list: distinct(configNames.names.map(v => v.name).concat(configNames.simVersionNames.map(v => v.name)))
    };
  }

  private async saveReferenceAsInner(
    userData: UserData,
    configType: DocumentSubType,
    simVersion: string,
    subTreePath: string,
    name: string,
    sourceTenantId: string,
    sourceConfigId: string): Promise<ConfigLoaderResult> {

    let configId: string;
    let config: StudyInput;
    if(configType === DocumentSubType.worksheet) {
      configId = await this.cloneWorksheet.execute(
        userData,
        name,
        sourceTenantId,
        sourceConfigId);

      if(!configId){
        return undefined;
      }
    } else{
      const configResult = await this.loadingDialog.showUntilFinished(
        this.configStub.getConfig(
          sourceTenantId,
          sourceConfigId,
          subTreePath,
          simVersion,
          undefined),
        'Loading document...');

      const properties = CustomPropertyUtilities.objectToList(configResult.config.properties);
      config = configResult.config.data;
      const notes = configResult.config.notes;

      return await this.saveContentAsInner(userData, configType, simVersion, subTreePath, name, config, properties, notes);
    }

    return {
      configId,
      userId: userData.sub,
      config
    };
  }

  private async saveContentAsInner(
    userData: UserData,
    configType: DocumentSubType,
    simVersion: string,
    subTreePath: string,
    name: string,
    config: any,
    properties: ReadonlyArray<ConfigProperty>,
    notes: string): Promise<ConfigLoaderResult>{

    let configId: string;
    if(configType === DocumentSubType.worksheet) {
      const worksheetResult = await this.loadingDialog.showUntilFinished(
        this.worksheetStub.postWorksheet(
          userData.tenant,
          {
            name,
            outline: config,
            properties: fromReadonly(properties),
            notes,
          }
        ),
        'Saving worksheet...');

      configId = worksheetResult.worksheet.worksheetId;
    } else{
      configId = await this.loadingDialog.showUntilFinished(
        this.configStub.postConfig(
          userData.tenant,
          {
            name,
            configType,
            config,
            properties: fromReadonly(properties),
            notes,
            simVersion
          },
          subTreePath),
        'Saving config...');
    }

    return {
      configId,
      userId: userData.sub,
      config: new StudyInput(
        configType,
        userData.sub,
        configId,
        name,
        config,
        properties,
        notes,
        simVersion,
        false,
        undefined)
    };
  }

  private async deleteConfigInner(userData: UserData, configIds: string[], subTreePath: string){
    let i = 0;
    for(let configId of configIds){
      i++;
      await this.loadingDialog.showUntilFinished(
        this.configStub.deleteConfig(
          userData.tenant,
          configId,
          subTreePath),
        `Deleting config ${i}...`);
    }
  }
}

interface ConfigNamesResult {
  result: GetConfigNamesQueryResult;
  list: string[];
}

interface ConfigProperty {
  name: string;
  value: string;
}

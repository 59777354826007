import {Injectable} from '@angular/core';

@Injectable()
export class ElementHeightCache {
  private readonly cache: {[name: string]: number} = {};

  public get(name: string): number {
    return this.cache[name];
  }

  public set(name: string, height: number) {
    this.cache[name] = height;
  }
}

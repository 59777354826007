import { Injectable } from '@angular/core';
import { asNumber } from '../../common/try-parse-as-number';

@Injectable()
export class ConvertPacejkaTirToJson {
  public execute(content: string): TirData {

    let linesWithComments = content.split('\n');

    let lines = linesWithComments
      .map(line => line.replace(/\$.*/, '').trim())
      .filter(v => v.length > 0);

    let root: TirData = {};

    for (let i = 0; i < lines.length; ++i) {
      let line = lines[i];
      if (line === '') {
        continue;
      }

      let commentHeader = this.getAsCommentHeader(line);
      if (commentHeader) {
        i = this.findSectionFinalLine(lines, i + 1);
        continue;
      }

      let header = this.getAsDataHeader(line);
      if (header) {
        let sectionStartIndex = i + 1;
        let endIndex = this.findSectionFinalLine(lines, sectionStartIndex);
        let sectionLines = lines.slice(sectionStartIndex, endIndex + 1);
        root[header] = this.readSectionData(sectionLines);
        i = endIndex;
      }
    }

    return root;
  }

  private readSectionData(lines: ReadonlyArray<string>): TirTable | TirParameters {
    if (lines.length === 0) {
      return {};
    }

    let firstLine = lines[0];
    let tableHeader = this.getAsTableHeader(firstLine);
    if (tableHeader) {
      return this.readSectionTable(lines.slice(1));
    }

    return this.readSectionParameters(lines);
  }

  private readSectionTable(lines: ReadonlyArray<string>): TirTable {
    let output: TirTable = [];
    for (let i = 0; i < lines.length; ++i) {
      let line = lines[i].replace(/ +/g, ' ');
      let values = line.split(' ').map(v => asNumber(v));
      output.push(values);
    }
    return output;
  }

  private readSectionParameters(lines: ReadonlyArray<string>): TirParameters {
    let result: TirParameters = {};
    for (let i = 0; i < lines.length; ++i) {
      let line = lines[i];
      let parts = line.split('=').map(v => v.trim());
      if (parts.length !== 2) {
        continue;
      }
      let key = parts[0];
      let value = parts[1];
      let valueAsString = this.getAsQuotedString(value);
      if (valueAsString !== undefined) {
        result[key] = valueAsString;
      } else {
        result[key] = asNumber(value);
      }
    }
    return result;
  }

  private findSectionFinalLine(lines: ReadonlyArray<string>, startIndex: number): number {
    for (let i = startIndex; i < lines.length; ++i) {
      let line = lines[i];
      if (this.getAsDataHeader(line) || this.getAsCommentHeader(line)) {
        return i - 1;
      }
    }

    return lines.length - 1;
  }

  private getAsQuotedString(line: string): string | undefined {
    return this.getAsEnclosed(line, '\'', '\'');
  }

  private getAsDataHeader(line: string): string | undefined {
    let header = this.getAsEnclosed(line, '[', ']');
    if (header) {
      header = header.toUpperCase();
    }

    return header;
  }

  private getAsCommentHeader(line: string): string | undefined {
    return this.getAsEnclosed(line, '(', ')');
  }

  private getAsTableHeader(line: string): string | undefined {
    return this.getAsEnclosed(line, '{', '}');
  }

  private getAsEnclosed(line: string, start: string, end: string): string | undefined {
    if (line.length >= start.length + end.length
      && line.startsWith(start)
      && line.endsWith(end)) {
      return line.substr(start.length, line.length - start.length - end.length);
    }

    return undefined;
  }
}

export interface TirParameters {
  [parameterName: string]: string | number;
}

export type TirTable = number[][];

export interface TirData {
  [sectionName: string]: TirTable | TirParameters | string;
}

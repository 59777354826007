import { IPopulatedMultiPlotLayout } from './types/i-populated-multi-plot-layout';
import { GetSideSizes } from './get-side-sizes';
import { UpdateProcessedPlotSizes } from './update-processed-plot-sizes';
import { UpdateScalesChartSettings } from './update-scales-chart-settings';
import { UpdateSideSizes } from './update-side-sizes';


/**
 * Updates the sizes of the rows and columns and processed plots in a layout.
 */
export class UpdatePlotSizes {
  constructor(
    private readonly getSideSizes: GetSideSizes,
    private readonly updateSideSizes: UpdateSideSizes,
    private readonly updateProcessedPlotSizes: UpdateProcessedPlotSizes) {
  }

  /**
   * Updates the sizes of the rows and columns and processed plots in a layout.
   *
   * @param layout The layout.
   * @param settings The chart settings.
   */
  public execute(layout: IPopulatedMultiPlotLayout, settings: UpdateScalesChartSettings) {
    // Order here is important. We must process columns first due to delta channels.

    // Get and update the column sizes.
    let columnSizes = this.getSideSizes.getColumnSizes(layout, settings.chartSize);
    this.updateSideSizes.execute(layout.columns, settings, columnSizes);

    // Get and update the row sizes.
    let rowSizes = this.getSideSizes.getRowSizes(layout, settings.chartSize);
    this.updateSideSizes.execute(layout.rows, settings, rowSizes);

    // If the processed columns are different to the original columns (which can happen if
    // we are stacking diagonals), update the processed column sizes.
    if (layout.columns !== layout.processed.columns) {
      let processedColumnSizes = this.getSideSizes.getProcessedColumnSizes(layout, settings.chartSize);
      this.updateSideSizes.execute(layout.processed.columns, settings, processedColumnSizes);
    }

    // If the processed rows are different to the original rows (which can happen if
    // we are stacking diagonals), update the processed row sizes.
    if (layout.rows !== layout.processed.rows) {
      let processedRowSizes = this.getSideSizes.getProcessedRowSizes(layout, settings.chartSize);
      this.updateSideSizes.execute(layout.processed.rows, settings, processedRowSizes);
    }

    // After updating the row and column sizes, we can update the processed plot sizes.
    this.updateProcessedPlotSizes.execute(layout, settings);
  }
}

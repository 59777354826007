<div class="container-fluid" id="view-worksheets-page">
  <div>
    <div class="list-card">
      <h1 class="list-card-header">
        Worksheets
      </h1>
      <div class="list-card-body">
        <cs-error-message [message]="errorMessage"></cs-error-message>

        <div class="list-card-padded">
          <p>
            <a id="home-button" class="btn btn-smart btn-secondary" [routerLink]="['/']">
              <i class="fa fa-caret-left"></i>Back
            </a>
            <a id="new-worksheet-button" class="btn btn-smart btn-secondary" (click)="createWorksheet()">
              <i class="fa fa-file-o"></i>New Worksheet
            </a>
          </p>
        </div>

        <cs-view-configs [configType]="configType"
                         [tenantId]="tenantId"
                         [canStage]="false"
                         [filter]="initialFilter"
                         [navigationUrlTree]="navigationUrlTree"
                         (filterChanged)="filterChanged($event)">
        </cs-view-configs>
      </div>
    </div>
  </div>
</div>

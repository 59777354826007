import {
  StudyStub, GetStudyTypesQueryResult, StudyType, StudyTypeDefinition,
  SimType, SimTypeDefinition
} from '../../../generated/api-stubs';
import { Injectable, EventEmitter, Output, Directive } from '@angular/core';
import {ConfigType, AllInputConfigTypes} from '../configs/config-types';
import {simVersionToNumber} from '../../visualizations/sim-version-to-number';
import { AuthenticationService } from '../../identity/state/authentication.service';

@Injectable()
export class StudyTypeLookupFactory {
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly studyStub: StudyStub){
  }

  public create(tenantId: string){
    let lookup = new StudyTypeLookup(this.authenticationService, this.studyStub);
    lookup.setTenantId(tenantId);
    return lookup;
  }
}

@Directive()
@Injectable()
export class StudyTypeLookup {
  @Output() changed: EventEmitter<boolean> = new EventEmitter<boolean>();

  private studyTypesResultTask: Promise<GetStudyTypesQueryResult>;
  private tenantId: string;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly studyStub: StudyStub){
  }

  public setTenantId(tenantId: string){
    this.tenantId = tenantId;
  }

  public async getStudyType(studyType: StudyType, simVersion?: string): Promise<StudyTypeDefinition>{
    let studyTypesResult = await this.loadIfRequiredAndFilter(simVersion);
    return studyTypesResult.studyTypes.find(v => v.studyType === studyType);
  }

  public async getStudyTypeList(simVersion?: string): Promise<StudyTypeDefinition[]>{
    let studyTypesResult = await this.loadIfRequiredAndFilter(simVersion);
    return studyTypesResult.studyTypes;
  }

  public async getStudyTypeMap(simVersion?: string): Promise<StudyTypeMap>{
    let studyTypesResult = await this.loadIfRequiredAndFilter(simVersion);
    return studyTypesResult.studyTypes.reduce((map, value) => {
      map[value.studyType] = value;
      return map;
    }, <StudyTypeMap>{});
  }

  public async getSimType(simType: SimType): Promise<SimTypeDefinition>{
    let studyTypesResult = await this.loadIfRequired();
    return studyTypesResult.simTypes.find(v => v.simType === simType);
  }

  public async getSimTypeList(): Promise<SimTypeDefinition[]>{
    let studyTypesResult = await this.loadIfRequired();
    return studyTypesResult.simTypes;
  }

  public async getSimTypeMap(): Promise<SimTypeMap>{
    let studyTypesResult = await this.loadIfRequired();
    return studyTypesResult.simTypes.reduce((map, value) => {
      map[value.simType] = value;
      return map;
    }, <SimTypeMap>{});
  }

  public async getConfigTypeList(): Promise<ConfigType[]>{
    let studyTypesResult = await this.loadIfRequired();
    return AllInputConfigTypes
      .map(config => ({
        config,
        definition: studyTypesResult.configTypes.find(allowed => allowed.configType === config.singularKey)}))
      .filter(
        v => v.definition)
      .map(v => v.config.copy(v.definition.state));
  }

  private loadIfRequired(){
    if(!this.studyTypesResultTask){
      let currentTenantId = this.tenantId || this.authenticationService.userDataSnapshot?.tenant;
      this.studyTypesResultTask = this.studyStub.getStudyTypes(currentTenantId);
    }

    return this.studyTypesResultTask;
  }

  private async loadIfRequiredAndFilter(simVersion?: string){
    let result = await this.loadIfRequired();
    if(simVersion){
      let numericSimVersion = simVersionToNumber(simVersion);

      // Clone result so we don't affect the cached version.
      result = {
        ...result,
        studyTypes: [...result.studyTypes],
      };

      for(let i=0; i < result.studyTypes.length; ++i){
        let studyType = result.studyTypes[i];
        if(studyType.previousDefinitions){
          for(let previousStudyType of studyType.previousDefinitions){
            let previousNumericSimVersion = simVersionToNumber(previousStudyType.simVersion);
            if(previousNumericSimVersion >= numericSimVersion){
              result.studyTypes[i] = previousStudyType.definition;
              break;
            }
          }
        }
      }
    }

    return result;
  }
}

export interface StudyTypeMap {
  [studyType: string]: StudyTypeDefinition;
}

export interface SimTypeMap {
  [simType: string]: SimTypeDefinition;
}

import {
  ConfigReference,
  DocumentCustomPropertyData,
  DocumentSubType, GetSupportSessionQueryResult,
  GetWorksheetQueryResult,
  LabelDefinitions,
  SimType,
  StudyReference,
  StudyType,
  TenantInformation,
  UserInformation,
  WorksheetOutline,
  WorksheetRow,
} from '../../generated/api-stubs';
import {ColumnType} from './column-type';
import {ColumnViewModel} from './column-view-model';
import {WorksheetUnderlyingData, WorksheetUnderlyingDataFactory} from './worksheet-underlying-data';
import {RowViewModel} from './row-view-model';
import {MINIMUM_ROWS} from './worksheet-constants';
import {CanopyJson} from '../common/canopy-json.service';
import {Injectable} from '@angular/core';
import {isDefined} from '../visualizations/is-defined';
import {ClipboardContent} from './worksheet-clipboard.service';
import {DuplicateClipboardContentIfRequired} from './duplicate-clipboard-content-if-required';
import {LoadingDialog} from '../common/dialogs/loading-dialog.service';
import {CustomPropertyUtilities} from '../simulations/custom-properties/custom-property-utilities';
import {IReference, referenceEquals} from './worksheet-types';
import {CreateWorksheetRowFromStudy, CreateWorksheetRowFromStudyOptions} from './create-worksheet-row-from-study';
import {RowItemViewModel} from './row-item-view-model';
import {UndoHistoryTracker} from './undo-history-tracker.service';

// https://codeburst.io/display-a-table-using-components-with-angular-4-f13f0971666d
// https://medium.com/@rohit22173/creating-re-sizable-columns-in-angular2-d22fbcbe39c9

/**
 * The maximum number of rows we allow the user to create in a worksheet. This is an arbitrary limit to prevent
 * performance issues in the UI. It can be increased if necessary.
 */
export const MAXIMUM_ROW_COUNT = 250;

/**
 * The number of items to keep in the undo history. This is fairly arbitrary and can be increase.
 * Each undo item is a full copy of the worksheet outline that is currently kept in memory.
 */
export const WORKSHEET_HISTORY_SIZE = 11;

/**
 * The parent of a worksheet view model. This is used to communicate changes to the parent component.
 */
export interface IWorksheetParent {
  /**
   * Updates the worksheet by sending the latest config to the API and rendering the result.
   * @param generateColumns Indicates if columns should be regenerated.
   * @param updateHistory Indicates if the history (undo stack) should have the current outline added to it.
   */
  update(generateColumns: boolean, updateHistory: boolean): void;

  /**
   * Waits for the current update, if any, to complete.
   * @returns A promise.
   */
  waitForUpdate(): Promise<void>;

  /**
   * Undoes the last action.
   */
  undo(): Promise<void>;

  /**
   * Redoes the last undone action.
   */
  redo(): Promise<void>;
}

/**
 * The factory to create a view model for a worksheet.
 */
@Injectable()
export class WorksheetViewModelFactory {

  /**
   * Creates a new worksheet view model factory.
   * @param json The JSON service.
   * @param duplicateClipboardContentIfRequired Duplicates clipboard content if required.
   * @param loadingDialog The loading dialog service.
   * @param createWorksheetRowFromStudy The service to create a worksheet row from a study.
   * @param worksheetUnderlyingDataFactory The factory to create the underlying data for a worksheet.
   */
  constructor(
    private readonly json: CanopyJson,
    private readonly duplicateClipboardContentIfRequired: DuplicateClipboardContentIfRequired,
    private readonly loadingDialog: LoadingDialog,
    private readonly createWorksheetRowFromStudy: CreateWorksheetRowFromStudy,
    private readonly worksheetUnderlyingDataFactory: WorksheetUnderlyingDataFactory){
  }

  /**
   * Creates a new worksheet view model.
   * @param parent The parent of the worksheet view model.
   * @param underlyingData The underlying data for the worksheet.
   * @param defaultStudyType The default study type.
   */
  public create(
    parent: IWorksheetParent,
    underlyingData: WorksheetUnderlyingData,
    defaultStudyType: StudyType): WorksheetViewModel {

    return new WorksheetViewModel(
      this.json,
      this.duplicateClipboardContentIfRequired,
      this.loadingDialog,
      this.createWorksheetRowFromStudy,
      parent,
      underlyingData,
      defaultStudyType);
  }

  /**
   * Creates a new worksheet view model from a worksheet result.
   * @param parent The parent of the worksheet view model.
   * @param worksheetResult The worksheet result.
   */
  public async createFromWorksheetResult(
    parent: IWorksheetParent,
    worksheetResult: GetWorksheetQueryResult): Promise<WorksheetViewModel> {

    const underlyingData = await this.worksheetUnderlyingDataFactory.create(worksheetResult);
    const defaultStudyType = underlyingData.studyTypesList[0];
    const result = this.create(parent, underlyingData, defaultStudyType.studyType);
    await result.generateColumns();
    return result;
  }
}

/**
 * The view model for a worksheet.
 */
export class WorksheetViewModel {

  /**
   * The tenant information for the tenant that owns the worksheet.
   */
  public readonly tenant: TenantInformation;

  /**
   * The user information for the user that owns the worksheet.
   */
  public readonly user: UserInformation;

  /**
   * The ID of the worksheet.
   */
  public readonly worksheetId: string;

  /**
   * The name of the worksheet.
   */
  public name: string;

  /**
   * The custom properties of the worksheet.
   */
  public properties: DocumentCustomPropertyData[] | undefined;

  /**
   * The worksheet notes.
   */
  public notes: string | undefined;

  /**
   * The support session information, if it exists.
   */
  public supportSession?: GetSupportSessionQueryResult;

  /**
   * The undo history tracker for the worksheet.
   */
  public readonly history: UndoHistoryTracker<ReadonlyArray<WorksheetRow>> = new UndoHistoryTracker<ReadonlyArray<WorksheetRow>>(WORKSHEET_HISTORY_SIZE);

  /**
   * The mutable array of row view models for the worksheet.
   */
  private readonly _rows: RowViewModel[];

  /**
   * Gets the read-only array of row view models for the worksheet.
   */
  public get rows(): ReadonlyArray<RowViewModel> {
    return this._rows;
  }

  /**
   * The read-only array of columns for the worksheet.
   */
  public columns: ReadonlyArray<ColumnViewModel>;

  /**
   * The worksheet (as opposed to the user or tenant) label definitions.
   */
  public worksheetLabelDefinitions: LabelDefinitions;

  /**
   * The reference last used for highlighting all cells that match the reference.
   */
  private previousMatchingReference: IReference | undefined;

  /**
   * Creates a new worksheet view model.
   * @param json The JSON service.
   * @param duplicateClipboardContentIfRequired Duplicates clipboard content if required.
   * @param loadingDialog The loading dialog service.
   * @param createWorksheetRowFromStudy The service to create a worksheet row from a study.
   * @param parent The parent of the worksheet view model.
   * @param underlyingData The underlying data for the worksheet.
   * @param defaultStudyType The default study type.
   */
  constructor(
    private readonly json: CanopyJson,
    private readonly duplicateClipboardContentIfRequired: DuplicateClipboardContentIfRequired,
    private readonly loadingDialog: LoadingDialog,
    private readonly createWorksheetRowFromStudy: CreateWorksheetRowFromStudy,
    private parent: IWorksheetParent,
    private underlyingData: WorksheetUnderlyingData,
    private defaultStudyType: StudyType) {

    const worksheetResult = this.underlyingData.worksheetResult;
    this.tenant = this.underlyingData.tenants[worksheetResult.worksheet.tenantId];
    this.user = this.underlyingData.users[worksheetResult.worksheet.userId];
    this.worksheetId = worksheetResult.worksheet.worksheetId;
    this.name = worksheetResult.worksheet.name;
    this.properties = CustomPropertyUtilities.objectToList(worksheetResult.worksheet.properties);
    this.notes = worksheetResult.worksheet.notes;
    this.worksheetLabelDefinitions = worksheetResult.worksheet.outline.labelDefinitions;

    this.supportSession = {
      session: worksheetResult.worksheet.supportSession,
      userInformation: worksheetResult.userInformation
    };

    // Create view models for each row.
    const rows = worksheetResult.worksheet.outline.rows.map(
      v => {
        const result = new RowViewModel(this, v, this.defaultStudyType, this.underlyingData);
        this.defaultStudyType = result.study.unpopulated.studyType || this.defaultStudyType;
        return result;
      });

    // Add more rows if there are fewer than the minimum.
    if(rows.length === 0 || rows.every(v => !!(v.study.reference === undefined && v.configs.length === 0))) {
      while(rows.length < MINIMUM_ROWS){
        rows.push(this.createEmptyRow());
      }
    }

    this._rows = rows;
  }

  /**
   * Gets the worksheet tenant ID.
   */
  public get tenantId(): string {
    return this.tenant.tenantId;
  }

  /**
   * Gets the worksheet user ID.
   */
  public get userId(): string {
    return this.user.userId;
  }

  /**
   * Gets whether the worksheet has reached the maximum number of rows.
   */
  public get maximumRowsReached(): boolean {
    return this._rows.length >= MAXIMUM_ROW_COUNT;
  }

  /**
   * Gets the worksheet underlying data (resolved reference, resolved labels, etc.).
   */
  public getCurrentUnderlyingData(): WorksheetUnderlyingData {
    return this.underlyingData;
  }

  /**
   * Gets whether the worksheet is read-only for the given user.
   * @param userId The user ID to check.
   * @returns True if the worksheet is read-only for the user, false otherwise.
   */
  public isReadOnly(userId: string): boolean{
    return this.userId !== userId;
  }

  /**
   * Gets whether the worksheet is writeable for the given user.
   * @param userId The user ID to check.
   * @returns True if the worksheet is writeable for the user, false otherwise.
   */
  public canWrite(userId: string): boolean{
    return !this.isReadOnly(userId);
  }

  /**
   * Waits for the current update, if any, to complete.
   * @returns A promise.
   */
  public waitForUpdate(){
    return this.parent.waitForUpdate();
  }

  /**
   * Updates the parent of the worksheet view model.
   * @param parent The new parent.
   */
  public updateParent(parent: IWorksheetParent) {
    this.parent = parent;
  }

  /**
   * Updates the worksheet view model from the given underlying data.
   * @param underlyingData The updated underlying data.
   */
  public update(underlyingData: WorksheetUnderlyingData){
    this.underlyingData = underlyingData;
    const worksheetResult = this.underlyingData.worksheetResult;
    this.supportSession = {
      session: worksheetResult.worksheet.supportSession,
      userInformation: worksheetResult.userInformation
    };
    this.worksheetLabelDefinitions = worksheetResult.worksheet.outline.labelDefinitions;

    for(let row of this._rows){
      row.update(underlyingData);
    }
  }

  /**
   * Updates the worksheet by sending the latest config to the API and rendering the result.
   * @param generateColumns Indicates if columns should be regenerated.
   * @param updateHistory Indicates if the history (undo stack) should have the current outline added to it.
   */
  public requestUpdate(generateColumns: boolean = false, updateHistory: boolean = true) {
    this.parent.update(generateColumns, updateHistory);
  }

  /**
   * Undoes the last action.
   */
  public undo(): Promise<void> {
    return this.parent.undo();
  }

  /**
   * Redoes the last undone action.
   */
  public redo(): Promise<void> {
    return this.parent.redo();
  }

  /**
   * Finds the superset of columns required to render all rows and ensures we have column view models
   * and row item view models for each one.
   */
  public generateColumns() {
    const allConfigTypes = new Set<DocumentSubType>();
    const allSimTypes = new Set<SimType>();

    for (const row of this.rows) {
      for (const config of row.configs.filter(v => !!v.populated)) {
        allConfigTypes.add(config.unpopulated.configType);
      }
      for (const configType of row.study.inputConfigTypes) {
        allConfigTypes.add(configType);
      }
      for (const simulation of row.simulations) {
        allSimTypes.add(simulation.unpopulated.simType);
      }
    }

    const sortedConfigTypes = Array.from(allConfigTypes).sort();
    const sortedSimulationTypes = Array.from(allSimTypes).sort();

    this.columns = [
      new ColumnViewModel(ColumnType.rowMetadata, undefined, 'Row'),
      ...sortedConfigTypes.map(v => new ColumnViewModel(
        ColumnType.config, v, this.underlyingData.getConfigTypeName((v)))),
      new ColumnViewModel(ColumnType.study, undefined, 'Study'),
      ...sortedSimulationTypes.map(v => new ColumnViewModel(
        ColumnType.simulation, v, this.underlyingData.getSimTypeName(v)))
    ];

    for (const row of this.rows) {
      row.generateColumns(this.columns);
    }
  }

  /**
   * Get's the worksheet outline. The outline can be used to send to the API to save
   * the study. It is the underlying config representing the worksheet.
   * @returns The worksheet outline.
   */
  public getOutline(): WorksheetOutline {
    // NOTE: If this is ever used for history / undo, it needs to be
    // either immutable or a deep copy.
    return {
      labelDefinitions: this.worksheetLabelDefinitions,
      rows: this.rows.map(row => row.getOutline()),
    };
  }

  /**
   * Highlights all items in the worksheet which match the given reference.
   * @param reference The reference to match.
   */
  public setItemsMatching(reference: IReference | undefined) {
    let setMatchesSelectedConfig = (reference: IReference, value: boolean) => {
      if(reference){
        const referenceMetadata = this.underlyingData.referencesMetadata.get(reference);
        referenceMetadata.configs.forEach(v => {
          v.matchesSelectedConfig = value;
        });
        referenceMetadata.studies.forEach(v => {
          v.matchesSelectedConfig = value;
        });
      }
    };

    setMatchesSelectedConfig(this.previousMatchingReference, false);
    this.previousMatchingReference = reference;
    setMatchesSelectedConfig(this.previousMatchingReference, true);
  }

  /**
   * Clears the highlighting of all items in the worksheet.
   */
  public clearItemsMatching() {
    this.setItemsMatching(undefined);
  }

  /**
   * Counts the number of times a study reference is used in the worksheet.
   * @param reference The study reference to count.
   * @returns The count of the study reference.
   */
  public countStudyReferences(reference: StudyReference){
    if(!reference){
      return 0;
    }

    let result = 0;
    for(let row of this._rows){
      if(referenceEquals(reference, row.study.reference)){
        ++result;
      }

      const telemetryConfig = row.getConfig(DocumentSubType.telemetry);
      if(telemetryConfig && referenceEquals(reference, telemetryConfig.reference)){
        ++result;
      }
    }

    return result;
  }

  /**
   * Replaces all configs currently referencing the source reference and replaces them so they reference the target reference.
   * @param sourceReference The source reference to replace.
   * @param targetReference The target reference to replace with.
   */
  public setAllConfigs(sourceReference: ConfigReference, targetReference: ConfigReference) {
    if(!sourceReference){
      return;
    }

    let sourceReferenceMetadata = this.underlyingData.referencesMetadata.get(sourceReference);
    for(let config of Array.from(sourceReferenceMetadata.configs)){
      config.setConfig(targetReference);
    }
  }

  /**
   * Replaces all studies currently referencing the source reference and replaces them so they reference the target reference.
   * @param sourceReference The source reference to replace.
   * @param targetReference The target reference to replace with.
   */
  public setAllStudies(sourceReference: StudyReference, targetReference: StudyReference) {
    if(!sourceReference){
      return;
    }

    let sourceReferenceMetadata = this.underlyingData.referencesMetadata.get(sourceReference);
    for(let study of Array.from(sourceReferenceMetadata.studies)){
      study.setStudy(targetReference);
    }
  }

  /**
   * Inserts the specified number of rows before the given row.
   * @param row The row to insert before.
   * @param count The number of rows to insert.
   */
  public insertRows(row: RowViewModel, count: number) {
    let rowIndex = this._rows.indexOf(row);
    if(rowIndex === -1) {
      rowIndex = this._rows.length;
    }

    this.insertRowsAt(
      rowIndex,
      count,
      () => this.createEmptyRow(row.study.studyType));
  }

  /**
   * Appends the specified number of rows after the given row.
   * @param row The row to append after.
   * @param count The number of rows to append.
   */
  public appendRows(row: RowViewModel, count: number) {
    let rowIndex = this._rows.indexOf(row);
    if(rowIndex === -1) {
      rowIndex = this._rows.length - 1;
    }

    this.insertRowsAt(
      rowIndex + 1,
      count,
      () => this.createEmptyRow(row.study.studyType));
  }

  /**
   * Copies the given row and appends the specified number of copies after it.
   * @param row The row to copy.
   * @param count The number of copies to append.
   */
  public copyDownRow(row: RowViewModel, count: number) {
    let rowIndex = this._rows.indexOf(row);
    if(rowIndex === -1) {
      rowIndex = this._rows.length - 1;
    }

    this.insertRowsAt(
      rowIndex + 1,
      count,
      () => new RowViewModel(
        this,
        this.json.clone(row.getOutline()),
        row.study.studyType,
        this.underlyingData));
  }

  /**
   * Removes the given row.
   * @param row The row to remove.
   */
  public removeRow(row: RowViewModel) {
    let rowIndex = this._rows.indexOf(row);
    if(rowIndex === -1) {
      return;
    }

    this._rows.splice(rowIndex, 1);
  }

  /**
   * Clears the given row of configs and studies.
   * @param row The row to clear.
   */
  public clearRow(row: RowViewModel) {
    this.pasteRowsSync(
      row,
      new ClipboardContent(this.tenantId, this.worksheetId, [this.createEmptyRowData()]),
      true);
  }

  /**
   * Extracts the inputs from the given row's study and pastes them into the row as configs.
   * @param row The row to extract inputs for.
   */
  public async extractStudyInputs(row: RowViewModel) {
    if (!row.study.reference) {
      return;
    }

    const rowContent = await this.createWorksheetRowFromStudy.execute(
      row.study.reference.tenantId,
      row.study.reference.targetId,
      this.worksheetId,
      CreateWorksheetRowFromStudyOptions.default());

    this.pasteRowsSync(row, rowContent.clipboardContent, false);
  }

  /**
   * Gets the row outlines for the currently selected rows.
   * @returns The row outlines for the currently selected rows.
   */
  public getSelectedOutline(): WorksheetRow[] {
    let result: WorksheetRow[] = [];
    let pending: WorksheetRow[] = [];
    for(let row of this.rows){
      if(row.anySelectedAndPopulated){
        result.push(...pending);
        pending.length = 0;
        result.push(row.getRowSelectedOutline());
      } else if(result.length){
        pending.push(row.getRowSelectedOutline());
      }
    }
    return result;
  }

  /**
   * Gets the row item view models for the currently selected cells.
   * @returns The row item view models for the currently selected cells.
   */
  public getSelectedViewModels(): RowItemViewModel[] {
    let result: RowItemViewModel[] = [];
    for(let row of this.rows){
      if(row.isSelected){
        result.push(row.rowMetadata);
      } else{
        result.push(...row.configs.filter(v => v.isSelected));
        if(row.study.isSelected){
          result.push(row.study);
        }
      }
    }
    return result;
  }

  /**
   * Gets the row view models for the currently selected rows.
   * @returns The row view models for the currently selected rows.
   */
  public getSelectedRows(): RowViewModel[] {
    let result: RowViewModel[] = [];
    for(let row of this.rows){
      if(row.anySelected){
        result.push(row);
      }
    }
    return result;
  }

  /**
   * Clears the current selection.
   */
  public clearSelected() {
    for(let row of this.rows){
      if(row.isSelected){
        this.clearRow(row);
      } else {
        if(row.study.populated && row.study.isSelected){
          row.study.setStudy(undefined);
        }

        for(let config of row.configs){
          if(config.populated && config.isSelected){
            config.setConfig(undefined);
          }
        }
      }
    }
  }

  /**
   * Pastes the given clipboard content into the worksheet at the given target row.
   * @param targetRow The target row to use as a reference when pasting (if there are multiple rows in the clipboard, this will be the first row).
   * @param content The clipboard content to paste.
   * @param removeExisting Indicates if all existing data should be removed on each row before pasting the new content.
   */
  public pasteRowsSync(targetRow: RowViewModel, content: ClipboardContent, removeExisting: boolean) {
    if(!this.verifyClipboardContentExists(content)) {
      return;
    }

    if(content.sourceWorksheetId !== this.worksheetId) {
      throw new Error('Synchronous paste rows cannot be used for content not from this worksheet.');
    }

    this.pasteRowsInner(targetRow, content, removeExisting);
  }

  /**
   * Applies the given rows to the worksheet.
   * @param rows The rows to apply.
   */
  public applyRows(rows: ReadonlyArray<WorksheetRow>) {
    if(!rows || rows.length === 0 || this._rows.length === 0) {
      return;
    }

    this.applyRowsInner(
      this._rows[0],
      new ClipboardContent(
        this.tenantId,
        this.worksheetId,
        rows),
      true);

    this._rows.length = rows.length;
  }

  /**
   * Inserts the specified number of rows at the given index, using the supplied factory to create each
   * row view model.
   * @param index The index to insert the rows at.
   * @param count The number of rows to insert.
   * @param rowFactory The factory to create each row view model.
   */
  public insertRowsAt(index: number, count: number, rowFactory: () => RowViewModel): boolean {
    if(this._rows.length === MAXIMUM_ROW_COUNT){
      return false;
    }

    let result = true;
    if((this._rows.length + count) > MAXIMUM_ROW_COUNT){
      count = MAXIMUM_ROW_COUNT - this._rows.length;
      result = false;
    }

    this._rows.splice(
      index,
      0,
      ...Array.from({length: count}, rowFactory));

    return result;
  }

  /**
   * Creates an empty row view model.
   * @param studyType The study type to use for the row.
   * @returns The empty row view model.
   */
  public createEmptyRow(studyType?: StudyType): RowViewModel {
    return new RowViewModel(
      this,
      this.createEmptyRowData(),
      studyType === undefined ? this.defaultStudyType : studyType,
      this.underlyingData);
  }

  /**
   * Pastes the given clipboard content into the worksheet at the given target row, duplicating any clipboard content when required.
   * @param targetRow The target row to use as a reference when pasting (if there are multiple rows in the clipboard, this will be the first row).
   * @param content The clipboard content to paste.
   * @param forceDuplication Indicates if the content should always be duplicated before pasting.
   * @param removeExisting Indicates if all existing data should be removed on each row before pasting the new content.
   */
  public async pasteRowsAsync(targetRow: RowViewModel, content: ClipboardContent, forceDuplication: boolean, removeExisting: boolean): Promise<void> {
    if(!this.verifyClipboardContentExists(content)) {
      return;
    }

    const duplicationTask = this.duplicateClipboardContentIfRequired.execute(this.tenantId, this.worksheetId, content, forceDuplication);
    content = await this.loadingDialog.showUntilFinished(duplicationTask, 'Copying...');

    this.pasteRowsInner(targetRow, content, removeExisting);
  }

  /**
   * Pastes the given clipboard content into the worksheet at the given target row.
   * @param targetRow The target row to use as a reference when pasting (if there are multiple rows in the clipboard, this will be the first row).
   * @param content The clipboard content to paste.
   * @param removeExisting Indicates if all existing data should be removed on each row before pasting the new content.
   */
  private pasteRowsInner(targetRow: RowViewModel, content: ClipboardContent, removeExisting: boolean) {
    if(!this.verifyClipboardContentExists(content)) {
      return;
    }

    this.applyRowsInner(targetRow, content, removeExisting);
  }

  /**
   * Applies the given rows to the worksheet.
   * @param targetRow The target row to use as a reference when pasting (if there are multiple rows in the clipboard, this will be the first row).
   * @param content The clipboard content to paste.
   * @param removeExisting Indicates if all existing data should be removed on each row before pasting the new content.
   */
  private applyRowsInner(targetRow: RowViewModel, content: ClipboardContent, removeExisting: boolean) {
    const targetRowIndex = this._rows.indexOf(targetRow);
    if(targetRowIndex === -1) {
      return;
    }

    for(let inputRowIndex = 0; inputRowIndex < content.rows.length; ++inputRowIndex){
      const outputRowIndex = targetRowIndex + inputRowIndex;

      if(outputRowIndex === this._rows.length) {
        const insertResult = this.insertRowsAt(
          outputRowIndex,
          1,
          () => this.createEmptyRow(targetRow.study.studyType));

        if(!insertResult){
          // Maximum rows have been reached, so stop pasting.
          break;
        }
      }

      const rowData = content.rows[inputRowIndex];
      const row = this._rows[outputRowIndex];
      if(isDefined(rowData.name)){
        row.name = rowData.name;
      }

      if(removeExisting) {
        row.setAllConfigsFromData(rowData.configs);
      } else if(rowData.configs) {
        for (let config of rowData.configs){
          row.setConfigFromData(config);
        }
      }

      if(rowData.study) {
        row.setStudyFromData(rowData.study);
      } else if(removeExisting){
        row.study.setStudy(undefined);
      }
    }
  }

  /**
   * Verifies that the clipboard content exists and that there is at least one row.
   * @param content The clipboard content to verify.
   * @returns True if the clipboard content exists and there is at least one row, false otherwise.
   */
  private verifyClipboardContentExists(content: ClipboardContent): boolean {
    return !!(content && content.rows && content.rows.length);
  }

  /**
   * Creates an empty row data object.
   * @returns The empty row data object.
   */
  private createEmptyRowData(): WorksheetRow {
    return {
      name: '',
      configs: [],
      study: { reference: undefined },
    };
  }
}


import {RuleLogLevel} from './rule-log-level';

export class RuleResult {
  constructor(
    public readonly level: RuleLogLevel,
    public readonly message: string,
    public readonly location: string,
    public readonly priority: number = 0) {
  }
}

import {IReference} from './worksheet-types';
import {referenceToId} from './reference-to-id';
import {ConfigViewModel} from './config-view-model';
import {StudyViewModel} from './study-view-model';

/**
 * Metadata about the references in a worksheet (reference counts, etc).
 */
export class WorksheetReferencesMetadata {

  /**
   * The map of reference id to metadata.
   */
  private readonly map: { [referenceId: string]: WorksheetReferenceMetadata } = {};

  /**
   * Increments the reference count for the given config view model.
   * @param config The config view model.
   */
  public incrementConfig(config: ConfigViewModel) {
    let metadata = this.getReferenceMetadata(config.reference);
    metadata.incrementConfig(config);
  }

  /**
   * Increments the reference count for the given study view model.
   * @param study The study view model.
   */
  public incrementStudy(study: StudyViewModel) {
    let metadata = this.getReferenceMetadata(study.reference);
    metadata.incrementStudy(study);
  }

  /**
   * Decrements the reference count for the given config view model.
   * @param config The config view model.
   */
  public decrementConfig(config: ConfigViewModel) {
    let metadata = this.getReferenceMetadata(config.reference);
    metadata.decrementConfig(config);
  }

  /**
   * Decrements the reference count for the given study view model.
   * @param study The study view model.
   */
  public decrementStudy(study: StudyViewModel) {
    let metadata = this.getReferenceMetadata(study.reference);
    metadata.decrementStudy(study);
  }

  /**
   * Gets the metadata for the given reference, creating it if it does not exist.
   * @param reference The reference.
   * @returns The metadata.
   */
  private getReferenceMetadata(reference: IReference) {
    const id = referenceToId(reference);
    let metadata = this.map[id];
    if (!metadata) {
      metadata = new WorksheetReferenceMetadata();
      this.map[id] = metadata;
    }
    return metadata;
  }

  /**
   * Gets the readonly metadata for the given reference.
   * @param reference The reference.
   * @returns The metadata.
   */
  public get(reference: IReference): IReadonlyWorksheetReferenceMetadata {
    return this.map[referenceToId(reference)] || WorksheetReferenceMetadata.empty;
  }
}

/**
 * A readonly interface to the metadata for a reference.
 */
export interface IReadonlyWorksheetReferenceMetadata {
  readonly count: number;
  readonly configCount: number;
  readonly studyCount: number;
  readonly configs: ReadonlySet<ConfigViewModel>;
  readonly studies: ReadonlySet<StudyViewModel>;
}

/**
 * Metadata about a single reference in a worksheet.
 */
export class WorksheetReferenceMetadata implements IReadonlyWorksheetReferenceMetadata {

  /**
   * The empty reference metadata.
   */
  public static readonly empty: WorksheetReferenceMetadata = new WorksheetReferenceMetadata();

  /**
   * The set of config view models that reference this reference.
   */
  private _configs: Set<ConfigViewModel> = new Set<ConfigViewModel>();

  /**
   * The set of study view models that reference this reference.
   */
  private _studies: Set<StudyViewModel> = new Set<StudyViewModel>();

  /**
   * The total reference count. For a study that is also in the telemetry column,
   * both reference counts may have non-zero values.
   */
  public get count(): number {
    return this._configs.size + this._studies.size;
  }

  /**
   * The reference count for configs, or the config component of a reference count
   * for a study that is also in the telemetry column.
   */
  public get configCount(): number {
    return this._configs.size;
  }

  /**
   * The reference count for studies, or the study component of a reference count
   * for a study that is also in the telemetry column.
   */
  public get studyCount(): number {
    return this._studies.size;
  }

  /**
   * Gets the set of config view models that reference this reference.
   */
  public get configs(): ReadonlySet<ConfigViewModel> {
    return this._configs;
  }

  /**
   * Gets the set of study view models that reference this reference.
   */
  public get studies(): ReadonlySet<StudyViewModel> {
    return this._studies;
  }

  /**
   * Increments the reference count for this reference.
   * @param config The config view model which now references this reference.
   */
  public incrementConfig(config: ConfigViewModel) {
    if(this === WorksheetReferenceMetadata.empty){
      throw new Error('Cannot increment the empty reference.');
    }

    this._configs.add(config);
  }

  /**
   * Increments the reference count for this reference.
   * @param study The study view model which now references this reference.
   */
  public incrementStudy(study: StudyViewModel) {
    if(this === WorksheetReferenceMetadata.empty){
      throw new Error('Cannot increment the empty reference.');
    }

    this._studies.add(study);
  }

  /**
   * Decrements the reference count for this reference.
   * @param config The config view model which no longer references this reference.
   */
  public decrementConfig(config: ConfigViewModel) {
    if(this === WorksheetReferenceMetadata.empty){
      throw new Error('Cannot increment the empty reference.');
    }

    this._configs.delete(config);
  }

  /**
   * Decrements the reference count for this reference.
   * @param study The study view model which no longer references this reference.
   */
  public decrementStudy(study: StudyViewModel) {
    if(this === WorksheetReferenceMetadata.empty){
      throw new Error('Cannot increment the empty reference.');
    }

    this._studies.delete(study);
  }
}

import { AddButtonsHandler } from './add-buttons-handler';
import { AddEncryptionButtons } from './add-encryption-buttons';
import { AddImportButtons } from './add-import-buttons';
import { AddLoadButtons } from './add-load-buttons';
import { AddPacejkaCanopyConverterButtons } from './add-pacejka-canopy-converter-buttons';
import { AddPacejkaImportButtons } from './add-pacejka-import-buttons';

export function getJsonEditorNodeExtensionsProviders(): any[] {
  return [
    { provide: AddButtonsHandler, useClass: AddEncryptionButtons, multi: true },
    { provide: AddButtonsHandler, useClass: AddImportButtons, multi: true },
    { provide: AddButtonsHandler, useClass: AddLoadButtons, multi: true },
    { provide: AddButtonsHandler, useClass: AddPacejkaCanopyConverterButtons, multi: true },
    { provide: AddButtonsHandler, useClass: AddPacejkaImportButtons, multi: true },
  ];
}

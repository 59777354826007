import { ComponentFactoryResolver, ComponentRef, Injectable, Type, ViewContainerRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { InputTextComponent } from '../components/input-text/input-text.component';

export enum FormComponentType {
  STRING = 'string',
}

export interface FormComponent {
  name: string;
  label: string;
  formControl: UntypedFormControl;
}

@Injectable()
export class FormBuilderService {

  constructor(private _componentFactoryResolver: ComponentFactoryResolver) {
  }

  /**
   * Gets the corresponding component for the specified form component type
   * @param type
   * @returns
   */
  public getFormComponent(type: FormComponentType): Type<FormComponent> {
    switch (type) {
      case FormComponentType.STRING:
      default:
        return InputTextComponent;
    }
  }

  /**
   * Create the specified component within the specified view container
   * @param component
   * @param containerRef
   * @returns
   */
  public createFormComponent(component: Type<FormComponent>, containerRef: ViewContainerRef): ComponentRef<FormComponent> {
    const componentFactory = this._componentFactoryResolver.resolveComponentFactory(component);
    const componentRef = containerRef.createComponent<FormComponent>(componentFactory);
    return componentRef;
  }

}


/**
 * The style of channel name to use when displaying channel names.
 */
export enum ChannelNameStyle {

  /**
   * Use the generic channel name. This may be ambiguous if there are multiple sim types.
   */
  Generic,

  /**
   * Use the fully qualified channel name. This is unambiguous, but may be verbose.
   */
  FullyQualified
}

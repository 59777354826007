import {
  NavigationStationConfig,
} from './navigation-station-config-builder';
import { SimType } from '../../sim-type';
import { StudyJob } from '../../study-job';
import { SimTypeInformation } from '../../viewers/channel-data-loaders/get-job-sim-type-metadata';
import { ConfigBuilderBase } from './config-builder-base';
import { UrlFileLoader } from '../../url-file-loader';
import { RequestedLayoutIds, SiteHooks } from '../../site-hooks';
import { ChannelNameStyle } from '../../viewers/channel-data-loaders/channel-name-style';
import { LinePlotViewer, POINT_MULTI_PLOT_VIEWER_TYPE } from '../../viewers/line-plot-viewer/line-plot-viewer';
import { SharedState } from '../../viewers/shared-state';
import { SourceLoaderViewModel } from '../../viewers/channel-data-loaders/source-loader-set';
import { StudyJobSourceLoader } from '../../viewers/channel-data-loaders/study-job-source-loader';
import { POINT_SCATTER_PLOT_VIEWER_TYPE, ScatterPlotViewer } from '../../viewers/scatter-plot-viewer/scatter-plot-viewer';
import { IRectangle } from '../../viewers/rectangle';
import {
  CONFIG_TYPE_TRACK,
  SLAP_DOMAIN_NAME,
  SRUN_DOMAIN_NAME,
  T_DOMAIN_NAME,
  TRUN_DOMAIN_NAME
} from '../../constants';
import { TRACK_VIEWER_TYPE, TrackViewer3d } from '../../viewers/track-viewer/track-viewer-3d';
import {
  GetJobsSimTypeMetadata,
  StudyJobSimTypeMetadata
} from '../../viewers/channel-data-loaders/get-jobs-sim-type-metadata';
import {
  isTimeOrDistanceSimTypeForJobs,
  isTimeOrDistanceSimType
} from '../../viewers/channel-data-loaders/is-time-or-distance-sim-type';
import { loadConfigsForSources } from '../../viewers/3d/load-configs-for-sources';
import { RACING_LINE_CHANNEL_PREFIX } from '../../populate-track-racing-line-from-simulation';


const defaultDomainsForBackwardsCompatibility: { [name: string]: string } = {
  StraightSim: 'vCar',
  ApexSim: 'vCar',
  QuasiStaticLap: 'sLap',
  DeploymentLap: 'sLap',
};

/**
 * A builder for for a one or more overlaid jobs.
 */
export class JobConfigBuilder extends ConfigBuilderBase {
  protected getJobsSimTypeMetadata: GetJobsSimTypeMetadata;

  /**
   * Constructs a new job config builder.
   * @param urlFileLoader The file loader.
   * @param siteHooks The site hooks.
   * @param studyJobs The study jobs for this builder session.
   * @param simTypes The sim types for this builder session.
   */
  constructor(
    urlFileLoader: UrlFileLoader,
    siteHooks: SiteHooks,
    studyJobs: ReadonlyArray<StudyJob>,
    simTypes: ReadonlyArray<SimType>) {
    super(urlFileLoader, siteHooks, studyJobs, simTypes);

    this.getJobsSimTypeMetadata = new GetJobsSimTypeMetadata(urlFileLoader);
  }

  /**
   * @inheritdoc
   */
  public async build(): Promise<NavigationStationConfig> {

    // Create an empty navigation station config.
    let config: NavigationStationConfig = {
      channelNameStyle: ChannelNameStyle.Generic,
      sharedStates: [],
      views: []
    };

    // Fetch the sim type metadata for the study jobs.
    let studyJobSimTypes: ReadonlyArray<StudyJobSimTypeMetadata> = await this.getJobsSimTypeMetadata.execute(this.studyJobs, this.simTypes);

    if (studyJobSimTypes.length === 1) {
      // If there is only one job, build the single job config charts.
      await this.buildSingleJobTimeOrDistanceSims(config, studyJobSimTypes[0].studyJob, studyJobSimTypes[0].simTypes);
    } else {
      // If there are multiple jobs, build the multiple job config charts.
      await this.buildMultipleJobTimeOrDistanceSims(config, studyJobSimTypes);
    }

    // Build any non-time or distance sims charts.
    await this.buildNonTimeOrDistanceSims(config, studyJobSimTypes);

    return config;
  }

  /**
   * Creates the viewers when overlaying multiple jobs.
   * @param config The navigation station config.
   * @param studyJobSimTypes The study jobs.
   */
  public async buildMultipleJobTimeOrDistanceSims(config: NavigationStationConfig, studyJobSimTypes: ReadonlyArray<StudyJobSimTypeMetadata>): Promise<void> {
    let defaultGridSlot = {
      x: 0,
      y: 0,
      width: 12,
      height: 8
    };

    // Find the first time or distance sim type in the passed jobs.
    // We use this as to construct the chart for overlaying all the jobs.
    let primarySimType: SimType | undefined;
    for (let job of studyJobSimTypes) {
      let found = this.getFirstTimeOrDistanceSim(job);
      if (found) {
        primarySimType = found.name;
        break;
      }
    }

    if (!primarySimType) {
      return;
    }

    let sharedState = new SharedState();
    config.sharedStates.push(sharedState);
    let loaders: SourceLoaderViewModel[] = [];

    // For each job...
    for (let job of studyJobSimTypes) {
      let firstTimeOrDistanceSim = this.getFirstTimeOrDistanceSim(job);
      if (firstTimeOrDistanceSim) {
        // If it has a time or distance sim type, add a source loader for it.
        let simType = firstTimeOrDistanceSim.name;
        loaders.push(new SourceLoaderViewModel(
          StudyJobSourceLoader.create(this.siteHooks, this.fileLoader, job.studyJob, simType),
          true));
      }
    }
    sharedState.sourceLoaderSet.add(...loaders);

    // Get all the X domains for the jobs we're going to overlay.
    let xDomainNames = this.findAllXDomainNames(studyJobSimTypes, primarySimType);

    // Create the viewers for the overlaid jobs.
    return await this.createTimeOrDistanceSimViewers(xDomainNames, primarySimType, config, sharedState, defaultGridSlot);
  }

  /**
   * Creates the viewers for a single job.
   * @param config The navigation station config.
   * @param studyJob The study job.
   * @param simTypes The sim types.
   */
  public async buildSingleJobTimeOrDistanceSims(config: NavigationStationConfig, studyJob: StudyJob, simTypes: ReadonlyArray<SimTypeInformation>): Promise<void> {

    let defaultGridSlot = {
      x: 0,
      y: 0,
      width: 12,
      height: 8
    };

    // Find all the time or distance simulations.
    let timeOrDistanceSims = simTypes.filter(v => isTimeOrDistanceSimType(v));
    if (!timeOrDistanceSims.length) {
      return;
    }

    // We assume the first one is the primary sim type.
    let primarySimType = timeOrDistanceSims.shift();

    let sharedState = new SharedState();
    config.sharedStates.push(sharedState);
    // For each time or distance sim type, add a loader.
    for (let simType of [primarySimType, ...timeOrDistanceSims]) {
      let loaders = this.studyJobs.map(v =>
        new SourceLoaderViewModel(
          StudyJobSourceLoader.create(this.siteHooks, this.fileLoader, v, simType.name),
          simType === primarySimType));
      sharedState.sourceLoaderSet.add(...loaders);
    }

    // Create the viewers.
    return await this.createTimeOrDistanceSimViewers(primarySimType.xDomainNames, primarySimType.name, config, sharedState, defaultGridSlot);
  }

  /**
   * Gets the first time or distance sim type in the given job.
   * @param job The job.
   * @returns The first time or distance sim type, or undefined if none found.
   */
  private getFirstTimeOrDistanceSim(job: StudyJobSimTypeMetadata) {
    return job.simTypes.find(v => isTimeOrDistanceSimType(v));
  }

  /**
   * Creates the time or distance sim viewers for the given X domains.
   * @param xDomainNames The X domain names.
   * @param primarySimType The primary sim type.
   * @param config The navigation station config we're updating.
   * @param sharedState The shared state.
   * @param defaultGridSlot The default grid slot.
   */
  private async createTimeOrDistanceSimViewers(xDomainNames: string[], primarySimType: SimType, config: NavigationStationConfig, sharedState: SharedState, defaultGridSlot: IRectangle): Promise<void> {
    // For each X domain...
    for (let xDomainName of xDomainNames) {
      if (xDomainName.startsWith(RACING_LINE_CHANNEL_PREFIX)) {
        continue;
      }

      let layoutIds = this.getChartLayoutIds(primarySimType, xDomainName);
      {
        // Create the top line plot viewer.
        let layout = await this.resolveViewerLayout(POINT_MULTI_PLOT_VIEWER_TYPE, layoutIds);
        if (layout.hasConfig) {
          config.views.push({
            title: primarySimType,
            viewerType: POINT_MULTI_PLOT_VIEWER_TYPE,
            layout,
            viewer: LinePlotViewer.createLinePlotViewer(
              xDomainName,
              layout.resolvedLayout.getConfigCopy(),
              config.channelNameStyle,
              sharedState,
              this.siteHooks),
            grid: this.getGridSlot(defaultGridSlot, layout.viewerMetadata)
          });

          // Create the track viewer if necessary.
          if (await this.requiresTrack(sharedState)) {
            let trackLayout = await this.resolveViewerLayout(TRACK_VIEWER_TYPE, layoutIds);

            config.views.push({
              title: 'Track',
              viewerType: TRACK_VIEWER_TYPE,
              layout: trackLayout,
              viewer: TrackViewer3d.create(this.siteHooks, sharedState, xDomainName, trackLayout.resolvedLayout.getConfigCopy()),
              grid: this.getGridSlot(defaultGridSlot, trackLayout.viewerMetadata)
            });
          }
        }
      }

      // Create the bottom scatter plot viewer.
      {
        let layout = await this.resolveViewerLayout(POINT_SCATTER_PLOT_VIEWER_TYPE, layoutIds);
        if (layout.hasConfig) {
          config.views.push({
            title: primarySimType,
            viewerType: POINT_SCATTER_PLOT_VIEWER_TYPE,
            layout,
            viewer: ScatterPlotViewer.create(
              xDomainName,
              layout.resolvedLayout.getConfigCopy(),
              config.channelNameStyle,
              sharedState,
              this.siteHooks),
            grid: this.getGridSlot(defaultGridSlot, layout.viewerMetadata)
          });
        }
      }
    }
  }

  /**
   * Determines if the shared state requires a track viewer.
   * @param sharedState The shared state.
   * @returns True if the shared state requires a track viewer, false otherwise.
   */
  private async requiresTrack(sharedState: SharedState): Promise<boolean> {
    // Load the track configs.
    let trackConfigs = await loadConfigsForSources(sharedState, CONFIG_TYPE_TRACK, false);

    // Determine if any of the track configs are populated.
    let populatedTrackConfigs = trackConfigs.filter(v => v && v.data && Object.keys(v.data).length > 0);

    // Return true if any of the track configs are populated.
    return populatedTrackConfigs.length > 0;
  }

  public async buildNonTimeOrDistanceSims(config: NavigationStationConfig, studyJobSimTypes: ReadonlyArray<StudyJobSimTypeMetadata>): Promise<void> {

    let defaultGridSlot = {
      x: 0,
      y: 0,
      width: this.studyJobs.length > 5 ? 12 : 6,
      height: 8
    };

    // Find all the sim types that are not time or distance sims.
    let nonTimeOrDistanceSims = this.simTypes.filter(simType => !isTimeOrDistanceSimTypeForJobs(simType, studyJobSimTypes));

    // For each sim type...
    for (let simType of nonTimeOrDistanceSims) {
      let sharedState = new SharedState();
      config.sharedStates.push(sharedState);

      // Add a loader for each job.
      let loaders = this.studyJobs.map(v =>
        new SourceLoaderViewModel(
          StudyJobSourceLoader.create(this.siteHooks, this.fileLoader, v, simType),
          true));
      sharedState.sourceLoaderSet.add(...loaders);

      let xDomainNames = this.findAllXDomainNames(studyJobSimTypes, simType);
      // For each X domain...
      for (let xDomainName of xDomainNames) {
        if (xDomainName.startsWith(RACING_LINE_CHANNEL_PREFIX)) {
          continue;
        }

        let layoutIds = this.getChartLayoutIds(simType, xDomainName);

        // Add a line viewer.
        {
          let layout = await this.resolveViewerLayout(POINT_MULTI_PLOT_VIEWER_TYPE, layoutIds);
          if (layout.hasConfig) {
            config.views.push({
              title: simType,
              viewerType: POINT_MULTI_PLOT_VIEWER_TYPE,
              layout,
              viewer: LinePlotViewer.createLinePlotViewer(
                xDomainName,
                layout.resolvedLayout.getConfigCopy(),
                config.channelNameStyle,
                sharedState,
                this.siteHooks),
              grid: this.getGridSlot(defaultGridSlot, layout.viewerMetadata)
            });
          }
        }

        // Add a scatter plot viewer.
        {
          let layout = await this.resolveViewerLayout(POINT_SCATTER_PLOT_VIEWER_TYPE, layoutIds);
          if (layout.hasConfig) {
            config.views.push({
              title: simType,
              viewerType: POINT_SCATTER_PLOT_VIEWER_TYPE,
              layout,
              viewer: ScatterPlotViewer.create(
                xDomainName,
                layout.resolvedLayout.getConfigCopy(),
                config.channelNameStyle,
                sharedState,
                this.siteHooks),
              grid: this.getGridSlot(defaultGridSlot, layout.viewerMetadata)
            });
          }
        }
      }
    }
  }

  /**
   * Finds all X domain names for the given study jobs and sim type.
   * @param studyJobSimTypes The study jobs.
   * @param simType The sim type we're interested in.
   * @returns The list of distinct X domain names.
   */
  private findAllXDomainNames(studyJobSimTypes: ReadonlyArray<StudyJobSimTypeMetadata>, simType: SimType) {
    let xDomainNames: string[] = [];
    for (let job of studyJobSimTypes) {
      let simTypeInformation = job.simTypes.find(v => v.name === simType);
      if (simTypeInformation) {
        for (let xDomainName of simTypeInformation.xDomainNames) {
          if (xDomainNames.indexOf(xDomainName) === -1) {
            xDomainNames.push(xDomainName);
          }
        }
      }
    }

    if (!xDomainNames.length) {
      let defaultXDomainName = defaultDomainsForBackwardsCompatibility[simType];
      if (defaultXDomainName) {
        xDomainNames.push(defaultXDomainName);
      }
    }

    return xDomainNames;
  }

  /**
   * Get the set of chart layout IDs, taking into account aliases and legacy sims.
   * @param simType The sim type.
   * @param xDomainName The X domain name.
   * @returns The set of chart layout IDs.
   */
  private getChartLayoutIds(simType: SimType, xDomainName: string): RequestedLayoutIds {

    // See also GetLegacyChannelMappings for where dummy channels are generated.

    if (xDomainName === SLAP_DOMAIN_NAME || xDomainName === SRUN_DOMAIN_NAME) {
      // Legacy lap sims use sLap as the primary domain.
      // We now generate sRun for those, and force them to use the
      // new sRun chart definitions.
      return new RequestedLayoutIds(
        this.getChartLayoutId(simType, SRUN_DOMAIN_NAME),
        [this.getChartLayoutId(simType, SLAP_DOMAIN_NAME)]);
    }

    if (xDomainName === T_DOMAIN_NAME || xDomainName === TRUN_DOMAIN_NAME) {
      return new RequestedLayoutIds(
        this.getChartLayoutId(simType, TRUN_DOMAIN_NAME),
        [this.getChartLayoutId(simType, T_DOMAIN_NAME)]);
    }

    return new RequestedLayoutIds(this.getChartLayoutId(simType, xDomainName), []);
  }

  /**
   * Gets the chart layout ID.
   * @param simType The sim type.
   * @param xDomainName The X domain name.
   * @returns The chart layout ID.
   */
  private getChartLayoutId(simType: string, xDomainName: string): string {
    return 'Default-' + simType + '-' + xDomainName;
  }
}

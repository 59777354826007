import { BootstrapBase } from '../bootstrap-base';
import { HTMLDivSelection, SVGSelection } from '../../untyped-selection';
import * as d3 from '../../d3-bundle';
import { MULTI_PLOT_AXIS_RENDERER_NAME } from '../test-area-names';
import { Margin } from '../../viewers/margin';
import { Disposable } from '../../disposable';
import { AxisRenderer, AxisType } from '../../viewers/multi-plot-viewer-base/axis-renderer';
import { ViewerChannelData } from '../../viewers/channel-data-loaders/viewer-channel-data';
import {
  SourceLoaderBase,
  SourceMetadata
} from '../../viewers/channel-data-loaders/source-loader';
import { ChannelNameStyle } from '../../viewers/channel-data-loaders/channel-name-style';
import { Utilities } from '../../utilities';
import { IEditChannelsChannelMetadata } from '../../site-hooks';
import { ScatterPlotViewerSettings } from '../../viewers/scatter-plot-viewer/scatter-plot-viewer-settings';
import { SourceLoaderSet, SourceLoaderViewModel } from '../../viewers/channel-data-loaders/source-loader-set';
import { LocalSiteHooks } from '../../local-site-hooks';
import { GetInterpolatedChannelValueAtDomainValue } from '../../viewers/channel-data-loaders/get-interpolated-channel-value-at-domain-value';
import { IPopulatedMultiPlotLayout } from '../../viewers/data-pipeline/types/i-populated-multi-plot-layout';
import { IMultiPlotLayout } from '../../viewers/data-pipeline/types/i-multi-plot-layout';
import { DataPipeline } from '../../viewers/data-pipeline/data-pipeline';

export class BootstrapMultiPlotAxisRenderer extends BootstrapBase {

  private createSampleLayout = (): IMultiPlotLayout => ({
    rows: [
      {
        channels: [{ name: 'aRockerFL', isVisible: true }],
        relativeSize: 0.5
      },
      {
        channels: [{ name: 'hRideF', isVisible: true }, { name: 'hRideR', isVisible: false }],
        relativeSize: 1
      },
      {
        channels: [{ name: 'sLap', isVisible: true }],
        relativeSize: 0.5
      }
    ],
    columns: [
      {
        channels: [{ name: 'aRockerFL', isVisible: true }],
        relativeSize: 0.5
      },
      {
        channels: [{ name: 'vCar', isVisible: true }],
        relativeSize: 1
      },
      {
        channels: [{ name: 'aSteerWheel', isVisible: true }],
        relativeSize: 0.5
      }
    ]
  });

  public get name() {
    return MULTI_PLOT_AXIS_RENDERER_NAME;
  }

  public get loadImmediately() {
    return false;
  }

  public async load(testArea: HTMLDivSelection): Promise<Disposable | undefined> {
    await this.renderAxisViewer(testArea, AxisType.rows);
    await this.renderAxisViewer(testArea, AxisType.columns);
    await this.renderAxisViewerAndAddAxis(testArea);
    await this.renderAxisViewerAndRemoveAxis(testArea);
    await this.renderAxisViewerAndChangeAxis(testArea);
    return Promise.resolve(undefined);
  }

  private async renderAxisViewer(testArea: HTMLDivSelection, axisType: AxisType) {

    await this.createTestItem(testArea, 'Axis ' + (axisType === AxisType.rows ? 'Rows' : 'Columns'), 'multi-plot-axis-tests', async (svg, svgParent) => {
      let { layout, channelData, chartSettings } = await this.createAxisData(svg);

      let axis = new AxisRenderer(chartSettings);
      axis.axisType(axisType)
        .layout(layout)
        .channelData(channelData);

      axis.render(svg);
    });
  }

  private async renderAxisViewerAndAddAxis(testArea: HTMLDivSelection) {

    let axisType = AxisType.rows;
    await this.createTestItem(testArea, 'Axis Add Row', 'multi-plot-axis-tests', async (svg, svgParent) => {
      let { layout, channelData, chartSettings, utilities } = await this.createAxisData(svg);

      let axis = new AxisRenderer(chartSettings);
      axis.axisType(axisType)
        .layout(layout)
        .channelData(channelData);

      axis.render(svg);

      layout.rows.push(Utilities.deepClone(layout.rows[0]));
      utilities.processLayout.execute(layout, channelData);
      utilities.updatePlotSizes.execute(layout, chartSettings);

      axis.render(svg);
    });
  }

  private async renderAxisViewerAndRemoveAxis(testArea: HTMLDivSelection) {

    let axisType = AxisType.rows;
    await this.createTestItem(testArea, 'Axis Remove Row', 'multi-plot-axis-tests', async (svg, svgParent) => {
      let { layout, channelData, chartSettings, utilities } = await this.createAxisData(svg);

      let axis = new AxisRenderer(chartSettings);
      axis.axisType(axisType)
        .layout(layout)
        .channelData(channelData);
      axis.render(svg);

      layout.rows.pop();
      utilities.processLayout.execute(layout, channelData);
      utilities.updatePlotSizes.execute(layout, chartSettings);

      axis.render(svg);
    });
  }

  private async renderAxisViewerAndChangeAxis(testArea: HTMLDivSelection) {

    let axisType = AxisType.columns;
    await this.createTestItem(testArea, 'Axis Change Column', 'multi-plot-axis-tests', async (svg, svgParent) => {
      let { layout, channelData, chartSettings, utilities } = await this.createAxisData(svg);

      let axis = new AxisRenderer(chartSettings);
      axis.axisType(axisType)
        .layout(layout)
        .channelData(channelData);

      axis.render(svg);

      layout.columns[0].channels[0].name = 'aRockerFR';
      layout.columns[0].relativeSize = 3;
      channelData = await utilities.loadChannelData.execute(layout);
      utilities.processLayout.execute(layout, channelData);
      utilities.updatePlotSizes.execute(layout, chartSettings);

      axis.render(svg);
    });
  }
  private async createAxisData(svg: SVGSelection) {
    let sourceCount = 2;
    let sources = new SourceLoaderSet(d3.range(sourceCount).map(v => new SourceLoaderViewModel(new RandomDataLoader(v, 100))));
    let layout = this.createSampleLayout();
    let utilities = DataPipeline.create('x', LocalSiteHooks.create(), sources, ChannelNameStyle.Generic, new GetInterpolatedChannelValueAtDomainValue());
    let channelData = await utilities.loadChannelData.execute(layout);
    utilities.processLayout.execute(layout, channelData);
    const populatedLayout = layout as IPopulatedMultiPlotLayout;

    let svgSize = { width: 400, height: 300 };
    let svgPadding = new Margin(5, 5, 5, 5);
    let chartMargin = new Margin(10, 10, 40, 40);

    let chartSettings = ScatterPlotViewerSettings.build(sourceCount);
    chartSettings.svgSize = svgSize;
    chartSettings.svgPadding = svgPadding;
    chartSettings.chartMargin = chartMargin;

    utilities.updatePlotSizes.execute(populatedLayout, chartSettings);

    this.RenderChartOutlines(svg, svgSize, svgPadding, chartMargin, chartSettings.chartSize);
    return { layout: populatedLayout, channelData, chartSettings, utilities };
  }
}

class RandomDataLoader extends SourceLoaderBase {
  constructor(private sourceIndex: number, private pointsPerChannel: number) {
    super();
  }

  getSourceMetadata(): Promise<SourceMetadata> {
    return Promise.resolve(new SourceMetadata('Source ' + (this.sourceIndex + 1)));
  }

  getChannelData(channelName: string): Promise<ViewerChannelData> {
    let maxValue = 80 + Math.random() * 40;

    let units: { [name: string]: string } = {
      aRockerFL: 'rad',
      aRockerFR: 'blah',
      vCar: 'm/s',
      aSteerWheel: 'deg',
    };

    return Promise.resolve(ViewerChannelData.createBasic(channelName, units[channelName] || '()', d3.range(this.pointsPerChannel).map(() => Math.random() * maxValue)));
  }

  getRequestableChannels(resultChannelNameStyle: ChannelNameStyle, xDomainName: string): Promise<IEditChannelsChannelMetadata[]> {
    return Promise.resolve([]);
  }
}

import {CreateWorksheetRowFromStudy, CreateWorksheetRowFromStudyOptions} from './create-worksheet-row-from-study';
import {DocumentCustomPropertyData, WorksheetStub} from '../../generated/api-stubs';
import {Injectable} from '@angular/core';
import {IWorksheetParent, WorksheetViewModelFactory} from './worksheet-view-model';
import {LoadingDialog} from '../common/dialogs/loading-dialog.service';
import { AuthenticationService } from '../identity/state/authentication.service';

export const SUPPORT_SESSION_TYPE_CUSTOM_PROPERTY_KEY = 'support-session-type';
export const SUPPORT_SESSION_ID_CUSTOM_PROPERTY_KEY = 'support-session-id';
export const SUPPORT_SESSION_TENANT_ID_CUSTOM_PROPERTY_KEY = 'support-session-tenant-id';

/**
 * Creates a worksheet from a study.
 * The inputs of the study will be extracted into config columns.
 * The study will be re-run if it doesn't belong to this tenant, otherwise it will be placed
 * on the same row as its config inputs.
 *
 * We implement IWorksheetParent so this class can be used as a parent for the worksheet view model.
 */
@Injectable()
export class CreateWorksheetFromStudy implements IWorksheetParent {

  /**
   * Creates a new instance of CreateWorksheetFromStudy.
   * @param authenticationService The authentication service.
   * @param worksheetStub The worksheet stub.
   * @param createWorksheetRowFromStudy Creates a worksheet row from a study.
   * @param worksheetViewModelFactory The worksheet view model factory.
   * @param loadingDialog The loading dialog service.
   */
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly worksheetStub: WorksheetStub,
    private readonly createWorksheetRowFromStudy: CreateWorksheetRowFromStudy,
    private readonly worksheetViewModelFactory: WorksheetViewModelFactory,
    private readonly loadingDialog: LoadingDialog){
  }

  /**
   * Creates a worksheet from a study.
   * The inputs of the study will be extracted into config columns.
   * The study will be re-run if it doesn't belong to this tenant, otherwise it will be placed
   * on the same row as its config inputs.
   * @param tenantId The tenant ID.
   * @param studyId The study ID.
   * @param name The name of the worksheet.
   * @param fromSupportSession Whether the study is from a support session.
   * @param options The options for creating the worksheet.
   * @returns The tenant ID and worksheet ID.
   */
  public async execute(tenantId: string, studyId: string, name: string, fromSupportSession: boolean, options: CreateWorksheetRowFromStudyOptions): Promise<WorksheetResult>{
    const userData = this.authenticationService.userDataSnapshot;

    const properties: DocumentCustomPropertyData[] = [];

    // If this study is from a support session, create some custom properties so we can find the
    // worksheet later if a user clicks the "create worksheet from study" button again.
    if(fromSupportSession){
      properties.push({
        name: SUPPORT_SESSION_TYPE_CUSTOM_PROPERTY_KEY,
        value: 'study',
      });
      properties.push({
        name: SUPPORT_SESSION_ID_CUSTOM_PROPERTY_KEY,
        value: studyId,
      });
      properties.push({
        name: SUPPORT_SESSION_TENANT_ID_CUSTOM_PROPERTY_KEY,
        value: tenantId,
      });
    }

    // Create a new worksheet.
    const worksheetResult = await this.loadingDialog.showUntilFinished(
      this.worksheetStub.postWorksheet(
        userData.tenant,
        {
          name,
          properties
        }),
      'Creating worksheet...');
    const worksheetId = worksheetResult.worksheet.worksheetId;
    const viewModel = await this.worksheetViewModelFactory.createFromWorksheetResult(
      this,
      worksheetResult);

    // Create the row from the study.
    const worksheetContent = await this.createWorksheetRowFromStudy.execute(tenantId, studyId, worksheetId, options);

    // Add the row to the worksheet.
    await viewModel.pasteRowsAsync(
      viewModel.rows[0],
      worksheetContent.clipboardContent,
      false,
      false);

    // Save the worksheet with the row added.
    await this.loadingDialog.showUntilFinished(
      this.worksheetStub.putWorksheet(
        userData.tenant,
        worksheetId,
        {
          name,
          properties,
          outline: viewModel.getOutline(),
        }),
      'Saving data to worksheet...');

    return new WorksheetResult(userData.tenant, worksheetId);
  }

  /**
   * @inheritdoc
   * We implement IWorksheetParent so this class can be used as a parent for the worksheet view model.
   */
  public update(generateColumns: boolean): void {
  }

  /**
   * @inheritdoc
   * We implement IWorksheetParent so this class can be used as a parent for the worksheet view model.
   */
  public waitForUpdate(): Promise<void> {
    return Promise.resolve();
  }

  /**
   * @inheritdoc
   * We implement IWorksheetParent so this class can be used as a parent for the worksheet view model.
   */
  redo(): Promise<void> {
    return Promise.resolve();
  }

  /**
   * @inheritdoc
   * We implement IWorksheetParent so this class can be used as a parent for the worksheet view model.
   */
  undo(): Promise<void> {
    return Promise.resolve();
  }
}

/**
 * The result of creating a worksheet.
 */
export class WorksheetResult {

  /**
   * Creates a new instance of WorksheetResult.
   * @param tenantId The tenant ID.
   * @param worksheetId The worksheet ID.
   */
  constructor(
    public readonly tenantId: string,
    public readonly worksheetId: string) {
  }
}

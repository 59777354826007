import {Channel} from './channel';

export abstract class RuleContextBase {
  private readonly channels: { [fullName: string]: ReadonlyArray<number> } = {};

  public constructor(
    public readonly type: string,
    public readonly data: { [key: string]: any }) {
  }

  public is(type: string) {
    return this.type.toLowerCase() === type.toLowerCase();
  }

  public setChannel(name: string, simType: string, data: ReadonlyArray<number>) {
    this.channels[this.getFullName(name, simType)] = data;
  }

  public getChannel(name: string, simType: string): Channel {
    let fullName = this.getFullName(name, simType);
    let result = this.channels[fullName];
    if (!result) {
      throw new Error('Channel not found: ' + fullName);
    }
    return new Channel(name, simType,[...result]);
  }

  public tryGetChannel(name: string, simType: string): Channel | undefined {
    let result = this.channels[this.getFullName(name, simType)];
    if(!result){
      return undefined;
    }

    return new Channel(name, simType,[...result]);
  }

  private getFullName(name: string, simType: string): string {
    return name + ':' + simType;
  }
}

import * as d3xy from './xyzoom';
import * as d3 from '../../d3-bundle';
import { SVGSelection } from '../../untyped-selection';
import { IMargin } from '../margin';
import { ISize } from '../size';
import { IPosition, Position } from '../position';
import { IPopulatedMultiPlotLayout } from '../data-pipeline/types/i-populated-multi-plot-layout';
import { ProcessedPlot } from '../data-pipeline/types/processed-plot';
import { ProcessedMultiPlotChannel } from '../data-pipeline/types/processed-multi-plot-channel';
import { SharedState } from '../shared-state';
import { PlotClippingRenderer } from './plot-clipping-renderer';
import { DomainSnapBehaviour } from './multi-plot-data-renderer-base';
import { GetChannelCalculationResult } from './get-channel-calculation-result';
import { Subscription } from 'rxjs';
import { ColumnLegendList } from './multi-plot-viewer-base';
import { ToggleButton, ToggleOptionsRenderer, OPTIONS_LEFT_PADDING, OPTIONS_BOTTOM_PADDING } from '../components/toggle-options-renderer';
import { ILegendChannel } from '../components/legend-renderer';
import { ZoomBrushSelection } from './zoom-brush-selection';

/**
 * The required chart settings for this component.
 */
interface IChartSettings {
  readonly uniqueId: string;
  readonly svgSize: ISize;
  readonly svgPadding: IMargin;
  readonly chartMargin: IMargin;
  readonly chartSize: ISize;
  readonly spaceBetweenPlots: number;
}

/**
 * Whether we're zooming the X domain, the Y domain, or both.
 */
export enum ZoomType {

  /**
   * Zooming the X domain.
   */
  x,

  /**
   * Zooming the Y domain.
   */
  y,

  /**
   * Zooming both the X and Y domains.
   */
  xy
}

/**
 * The type of calculation to perform on the channels.
 */
export enum CalculationType {
  none,
  mean,
  min,
  max,
  delta,
  range,
  gradient,
  integral
}

/**
 * The D3 style interface to the zoom renderer.
 */
export class ZoomRenderer {

  /**
   * The inner renderer.
   */
  private inner = new ZoomRendererInner();

  /**
   * Renders the zoom handlers.
   * @param selection The selection to render to.
   * @returns This object.
   */
  public render(selection: SVGSelection): this {
    this.inner.render(selection);
    return this;
  }

  /**
   * Called just before `render` is called on all other components.
   * @param selection The selection to render to.
   * @returns This object.
   */
  public preRender(selection: SVGSelection): this {
    this.inner.preRender(selection);
    return this;
  }

  /**
   * Sets the layout to render.
   * @param value The layout.
   * @returns This object.
   */
  public layout(value: IPopulatedMultiPlotLayout): this {
    this.inner.layout = value;
    return this;
  }

  /**
   * Sets the chart settings.
   * @param value The chart settings.
   * @returns This object.
   */
  public chartSettings(value: IChartSettings): this {
    this.inner.settings = value;
    return this;
  }

  /**
   * Sets the zoom type.
   * @param value The zoom type.
   * @returns This object.
   */
  public zoomType(value: ZoomType): this {
    this.inner.zoomType = value;
    return this;
  }

  /**
   * Sets the shared state.
   * @param value The shared state.
   * @returns This object.
   */
  public sharedState(value: SharedState): this {
    this.inner.sharedState = value;
    return this;
  }

  /**
   * Sets the x legend list.
   * @param value The x legend list.
   * @returns This object.
   */
  public xLegendList(value: ColumnLegendList): this {
    this.inner.xLegendList = value;
    return this;
  }

  /**
   * Sets the domain snap behaviour type.
   * @param value The domain snap behaviour type.
   * @returns This object.
   */
  public domainSnapBehaviour(value: DomainSnapBehaviour): this {
    this.inner.domainSnapBehaviour = value;
    return this;
  }

  /**
   * Sets whether we're rendering calculations.
   * @param value Whether we're rendering calculations.
   * @returns This object.
   */
  public get isRenderingCalculations(): boolean {
    return this.inner.isRenderingCalculations;
  }

  /**
   * Listen for changes to the component.
   * @param typenames The event type names.
   * @param callback The callback.
   * @returns This object.
   */
  public on(typenames: string, callback: (this: object, ...args: any[]) => void): this {
    this.inner.listeners.on(typenames, callback);
    return this;
  }

  /**
   * Disposes of the component.
   * @returns This object.
   */
  public dispose(): this {
    this.inner.dispose();
    return this;
  }
}

// Because methods are called after a new set of processed plots are created, but before
// they are bound to the UI, we need to look up the latest plot from the layout.
function getCurrentPlot(layout: IPopulatedMultiPlotLayout, plot: ProcessedPlot): ProcessedPlot {
  return layout.processed.plots[plot.plotIndex];
}

/**
 * The location we'll store the d3xy.zoom object on the DOM element.
 */
const ZOOM_PROPERTY = '__cs_zoom';

/**
 * The inner renderer for the zoom renderer.
 */
export class ZoomRendererInner {

  /**
   * The chart layout to render.
   */
  public layout?: IPopulatedMultiPlotLayout;

  /**
   * The chart settings.
   */
  public settings?: IChartSettings;

  /**
   * The type of zoom currently selected.
   */
  public zoomType: ZoomType = ZoomType.xy;

  /**
   * The shared state.
   */
  public sharedState?: SharedState;

  /**
   * The x legend list.
   */
  public xLegendList?: ColumnLegendList;

  /**
   * The domain snap behaviour.
   */
  public domainSnapBehaviour = DomainSnapBehaviour.nearest;

  /**
   * The calculation type being performed.
   */
  public _calculationType: CalculationType = CalculationType.none;

  /**
   * Gets the calculation type being performed.
   */
  public get calculationType(): CalculationType {
    return this._calculationType;
  }

  /**
   * Sets the calculation type being performed.
   */
  public set calculationType(value: CalculationType){
    this._calculationType = value;
    if(this.sharedState){
      this.sharedState.domainOverridden = this.isRenderingCalculations;
    }
  }

  /**
   * The D3 style event listeners.
   */
  public listeners = d3.dispatch('zoom', 'legendChanged');

  /**
   * The set of subscriptions to dispose of.
   */
  private subscriptions?: Subscription;

  /**
   * Whether zooming using the scroll wheel is currently enabled.
   */
  private enableZoomByScroll: boolean = false;

  /**
   * The zoom progress.
   */
  public _zoomProgress: ZoomProgress | undefined;

  /**
   * Gets the zoom progress.
   */
  public get zoomProgress(): ZoomProgress | undefined {
    return this._zoomProgress;
  }

  /**
   * Sets the zoom progress.
   */
  public set zoomProgress(value: ZoomProgress | undefined){
    this._zoomProgress = value;
    if(this.sharedState){
      this.sharedState.domainOverridden = this.isRenderingCalculations;
    }
  }

  /**
   * Whether we're currently zooming.
   */
  private isZooming: boolean = false;

  /**
   * The renderer for the charts toggleable options.
   */
  private toggleOptionsRenderer = new ToggleOptionsRenderer();

  /**
   * The zoom toggle buttons.
   */
  private zoomToggles: ToggleButton<ZoomType>[];

  /**
   * The calculation type toggle buttons.
   */
  private calculationToggles: ToggleButton<CalculationType>[];

  /**
   * The service to get the channel calculation result.
   */
  private getChannelCalculationResult: GetChannelCalculationResult = GetChannelCalculationResult.create();

  /**
   * Creates a new zoom renderer.
   */
  constructor() {
    // Define the zoom buttons.
    this.zoomToggles = [
      new ToggleButton<ZoomType>(ZoomType.x, 'x', (t) => this.zoomType = t.value, 15),
      new ToggleButton<ZoomType>(ZoomType.y, 'y', (t) => this.zoomType = t.value, 15),
      new ToggleButton<ZoomType>(ZoomType.xy, 'xy', (t) => this.zoomType = t.value, 15),
    ];

    // The delegate to handle setting the current calculation type.
    let setCalculationTypeDelegate = (t: ToggleButton<CalculationType>) => {
      this.calculationType = t.value;

      if (!this.isRenderingCalculations) {
        this.performChannelCalculations();
        this.listeners.call('legendChanged');
      }
    };

    // The delegate to return the class to apply if the toggle button is selected.
    let getSelectedClassDelegate = (t: ToggleButton<CalculationType>) => this.isRenderingCalculations ? 'calculating-toggle-selected' : null;

    // Define the calculation type toggle buttons.
    this.calculationToggles = [
      new ToggleButton<CalculationType>(CalculationType.none, 'none', (t) => {
        this.calculationType = t.value;
        this.resetLegendValues();
        this.listeners.call('legendChanged');
      }, 30),
      new ToggleButton<CalculationType>(CalculationType.mean, 'mean', setCalculationTypeDelegate, 30, getSelectedClassDelegate),
      new ToggleButton<CalculationType>(CalculationType.min, 'min', setCalculationTypeDelegate, 30, getSelectedClassDelegate),
      new ToggleButton<CalculationType>(CalculationType.max, 'max', setCalculationTypeDelegate, 30, getSelectedClassDelegate),
      new ToggleButton<CalculationType>(CalculationType.delta, 'delta', setCalculationTypeDelegate, 30, getSelectedClassDelegate),
      new ToggleButton<CalculationType>(CalculationType.range, 'range', setCalculationTypeDelegate, 30, getSelectedClassDelegate),
      new ToggleButton<CalculationType>(CalculationType.gradient, 'grad', setCalculationTypeDelegate, 30, getSelectedClassDelegate),
      new ToggleButton<CalculationType>(CalculationType.integral, 'integ', setCalculationTypeDelegate, 30, getSelectedClassDelegate),
    ];
  }

  /**
   * Gets whether calculations are currently being rendered.
   */
  public get isRenderingCalculations(): boolean {
    return !!(this.zoomProgress && this.calculationType !== CalculationType.none);
  }

  /**
   * Disposes of the component.
   */
  public dispose() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  /**
   * Called before render is called on all other components.
   * @param selection The selection to render to.
   */
  public preRender(selection: SVGSelection) {
    if (!this.layout || !this.settings || !this.sharedState) {
      return;
    }

    this.zoomProgress = undefined;

    // Keep track of the scales we've adjusted.
    let adjustedScales = new Set<d3.ScaleLinear<number, number>>();

    // For each plot...
    let zoomHandlerUpdate = selection.selectAll<SVGElement, ProcessedPlot>('.zoom-handler');
    zoomHandlerUpdate.data(this.layout.processed.plots as ProcessedPlot[])
      .each(function(plot: ProcessedPlot) {

        // Get the current zoom transform.
        let transform = d3xy.xyzoomTransform(this);

        // And apply it to the plot scales.
        if (transform) {
          let columnScale = plot.column.processed.scale;
          if (!adjustedScales.has(columnScale)) {
            columnScale.domain(transform.rescaleX(columnScale).domain());
            adjustedScales.add(columnScale);
          }

          let rowScale = plot.row.processed.scale;
          if (!adjustedScales.has(rowScale)) {
            rowScale.domain(transform.rescaleY(rowScale).domain());
            adjustedScales.add(rowScale);
          }
        }
      });
  }

  /**
   * Render the zoom component.
   * @param selection The selection to render to.
   */
  public render(selection: SVGSelection) {
    if (!this.layout || !this.settings || !this.sharedState) {
      return;
    }

    let containerClassName = 'zoom-renderer';

    // Create the container for the component.
    let containerUpdate = selection.selectAll<SVGGElement, null>('.' + containerClassName).data([null]);
    let containerEnter = containerUpdate.enter().append<SVGGElement>('g').attr('class', containerClassName + ' multi-plot-zoom-renderer');
    let container = containerEnter.merge(containerUpdate);

    // If we haven't already subscribed to zoom related key presses, subscribe.
    if (!this.subscriptions) {
      this.subscriptions = this.sharedState.keyPressNews.subscribe((key) => {

        // Not all key presses will require re-rendering.
        let requiresRender: boolean = false;

        if (key === 'x') {
          // The X key sets zooming to the X axis only.
          // Holding the key down enables zooming with the scroll wheel.
          this.zoomType = ZoomType.x;
          this.enableZoomByScroll = true;
          requiresRender = true;
        } else if (key === 'y') {
          // The Y key sets zooming to the Y axis only.
          // Holding the key down enables zooming with the scroll wheel.
          this.zoomType = ZoomType.y;
          this.enableZoomByScroll = true;
          requiresRender = true;
        } else if (key === 'z') {
          // The Z key sets zooming to both axes.
          // Holding the key down enables zooming with the scroll wheel.
          this.zoomType = ZoomType.xy;
          this.enableZoomByScroll = true;
          requiresRender = true;
        } else {
          // Any other key (including releasing the above keys) disables zooming with the scroll wheel.
          this.enableZoomByScroll = false;
        }

        // The M or N keys cycle through the calculation types.
        if (key === 'm' || key === 'n') {
          let currentToggle = this.calculationToggles.find(v => v.value === this.calculationType);
          let nextIndex = 0;
          if (currentToggle) {
            let currentIndex = this.calculationToggles.indexOf(currentToggle);
            if(key === 'm'){
              nextIndex = currentIndex + 1;
              if (nextIndex === this.calculationToggles.length) {
                nextIndex = 0;
              }
            }
            if(key ==='n'){
              nextIndex = currentIndex - 1;
              if (nextIndex === -1) {
                nextIndex = this.calculationToggles.length - 1;
              }
            }
          }
          this.calculationToggles[nextIndex].clickAction(this.calculationToggles[nextIndex]);
          requiresRender = true;
        }

        // The escape key cancels any zoom operation in progress.
        if (key === 'Escape') {
          if (this.zoomProgress) {
            this.zoomProgress = undefined;
            requiresRender = true;
          }
        }

        // If we need to re-render, do so.
        if (requiresRender) {
          this.renderZoomOptions(selection, container);
          this.renderZoomBrushes(selection, container);
        }
      });
    }

    // Render the zoom handlers, options and brushes.
    this.renderZoomHandlers(selection, container);
    this.renderZoomOptions(selection, container);
    this.renderZoomBrushes(selection, container);
  }

  /**
   * Render the zoom handlers, which respond to mouse events on each plot.
   * @param selection The chart selection.
   * @param container The zoom handler container selection.
   */
  private renderZoomHandlers(selection: SVGSelection, container: d3.Selection<SVGGElement, null, SVGElement, any>) {
    if (!this.layout) {
      return;
    }

    let self = this;

    // Create a zoom handler for each plot.
    let zoomHandlerUpdate = container.selectAll<SVGRectElement, ProcessedPlot>('.zoom-handler').data(this.layout.processed.plots as ProcessedPlot[]);
    zoomHandlerUpdate.exit().remove();
    let zoomHandlerEnter = zoomHandlerUpdate.enter()
      .append<SVGRectElement>('rect')
      .attr('class', 'zoom-handler')
      .attr('fill', 'transparent');

    let zoomHandler = zoomHandlerUpdate.merge(zoomHandlerEnter);

    // Double clicking on the zoom handler resets the zoom.
    zoomHandlerEnter
      .on('dblclick', () => {
        self=this;
        zoomHandler.each(function(d: ProcessedPlot) {
          let zoom: any = (this as any)[ZOOM_PROPERTY];
          d3.select(this).call(zoom.transform, d3xy.xyzoomIdentity);
        });
      });

    // Put the zoom handlers over each plot.
    zoomHandler
      .attr('transform',
        (d: ProcessedPlot) =>
          'translate('
          + (d.absoluteRenderArea.x)
          + ','
          + (d.absoluteRenderArea.y)
          + ')')
      .attr('width', (d: ProcessedPlot) => d.absoluteRenderArea.width)
      .attr('height', (d: ProcessedPlot) => d.absoluteRenderArea.height);

    // Get the function to determine whether to filter zoom events.
    let zoomFilter = this.getZoomFilter();

    // Get the zoom scale ratio for each axis.
    let scaleRatio = this.getScaleRatio();

    self=this;

    // For each new zoom handler...
    zoomHandlerEnter.each(function(d: ProcessedPlot) {
      // Create an instance of d3xy.xyzoom and attach it to the DOM element.
      let zoom: typeof d3xy.xyzoom = (this as any)[ZOOM_PROPERTY] = (d3xy.xyzoom() as any)
        .extent([[0, 0], [d.column.processed.plotSize, d.row.processed.plotSize]])
        .scaleExtent([1, 1000], [1, 1000])
        .filter(zoomFilter)
        .on('zoom', (currentEvent: any) => self.zoomHandler(selection, d, currentEvent));

      d3.select<SVGRectElement, ProcessedPlot>(this)
        .call(zoom)
        .on('wheel', (currentEvent) => {
          // If zooming with the scroll wheel is enabled, stop the scroll event propagating to the page.
          // https://github.com/d3/d3-zoom#zoom_scaleExtent
          if (self.enableZoomByScroll) {
            currentEvent.preventDefault();
          }
        })
        .on('contextmenu.zoom-brush', (currentEvent: any, d: ProcessedPlot) => {
          // On right-click we want to initiate a zoom operation.
          let mouseEvent = <MouseEvent>currentEvent;

           // Prevent browser menu.
          mouseEvent.preventDefault();

          if (self.zoomProgress) {
            // If we're in the middle of a zoom operation, cancel it.
            self.zoomProgress = undefined;
          } else {
            // Otherwise, start a new zoom operation.
            let mousePosition = new Position(mouseEvent.offsetX, mouseEvent.offsetY);
            let start = new Position(mousePosition.x - d.absoluteRenderArea.x, mousePosition.y - d.absoluteRenderArea.y);
            self.zoomProgress = new ZoomProgress(d, start);
          }
          self.renderZoomBrushes(selection, container);
          self.renderZoomOptions(selection, container);
        })
        .on('click.zoom-brush', () => {
          // If we're in the middle of a zoom operation and we left-click, complete the zoom operation.
          if (self.zoomProgress) {
            self.zoomToZoomProgress(selection);
          }
        })
        .on('mousemove.zoom-brush', (currentEvent: any, d: ProcessedPlot) => {
          // If we're in the middle of a zoom operation and we move the mouse, update the zoom progress.
          if (self.zoomProgress) {
            let mouseEvent = <MouseEvent>currentEvent;
            let mousePosition = new Position(mouseEvent.offsetX, mouseEvent.offsetY);
            let zoomPlot = self.zoomProgress.plot;

            let x: number;
            if (zoomPlot.column === d.column) {
              x = mousePosition.x - d.absoluteRenderArea.x;
            } else {
              x = d.column.processed.offset > zoomPlot.column.processed.offset
                ? zoomPlot.absoluteRenderArea.width
                : 0;
            }

            let y: number;
            if (zoomPlot.row === d.row) {
              y = mousePosition.y - d.absoluteRenderArea.y;
            } else {
              y = d.row.processed.offset > zoomPlot.row.processed.offset
                ? zoomPlot.absoluteRenderArea.height
                : 0;
            }

            self.zoomProgress.end = new Position(x, y);

            self.renderZoomBrushes(selection, container);
          }
        });
    });

    // For each zoom handler update or enter...
    zoomHandler.each(function(d: ProcessedPlot) {
      // Fetch the d3xy.xyzoom instance.
      let zoom: any = (this as any)[ZOOM_PROPERTY];

      // And update it with the latest data.
      zoom
        .extent([[0, 0], [d.column.processed.plotSize, d.row.processed.plotSize]])
        .translateExtent([[0, 0], [d.column.processed.plotSize, d.row.processed.plotSize]])
        .scaleRatio(scaleRatio);
    });
  }

  /**
   * Render the zoom brushes, which appear during zoom operations to show the area being zoomed.
   * @param selection The chart selection.
   * @param container The zoom brush container selection.
   */
  private renderZoomBrushes(selection: SVGSelection, container: d3.Selection<SVGGElement, null, SVGElement, any>) {
    if (!this.layout || !this.settings) {
      return;
    }

    const settings = this.settings;
    const self = this;

    // This will hold the data we need to render zoom brushes in the appropriate plots.
    let zoomBrushData: ZoomBrushPlot[] = [];

    // If we're in the middle of a zoom operation...
    if (this.zoomProgress) {
      const zoomProgress = this.zoomProgress;

      // Add the data for the plot the operation started in.
      zoomBrushData.push(new ZoomBrushPlot(zoomProgress.plot, true));

      // If the zoom operation involves the X axis, add the data for all other plots in the same column.
      if (this.zoomType === ZoomType.x || this.zoomType === ZoomType.xy) {
        // We include these plots in calculations (the second parameter to ZoomBrushPlot) because we're zooming
        // the X axis and we can calculate the mean, min, max, etc. for all the plots in the same column to display
        // on the legend.
        zoomBrushData.push(...this.layout.processed.plots.filter(
          v => v !== zoomProgress.plot && v.column === zoomProgress.plot.column)
          .map(v => new ZoomBrushPlot(v, true)));
      }

      // If the zoom operation involves the Y axis, add the data for all other plots in the same row.
      if (this.zoomType === ZoomType.y || this.zoomType === ZoomType.xy) {
        // We exclude these plots from calculations (the second parameter to ZoomBrushPlot) because
        // it only makes sense to have one plot in the row involved in row channel calculations.
        zoomBrushData.push(...this.layout.processed.plots.filter(
          v => v !== zoomProgress.plot && v.row === zoomProgress.plot.row)
          .map(v => new ZoomBrushPlot(v, false)));
      }
    }

    // If we're rendering calculations then reset all the legend values to NaN.
    // Any calculations will be set in `performZoomBrushCalculations` or `performChannelCalculations`.
    if (this.isRenderingCalculations) {
      this.resetLegendValues();
    }

    // Create a zoom brush for each plot.
    let zoomBrushUpdate = container.selectAll<SVGRectElement, ZoomBrushPlot>('.zoom-brush').data(zoomBrushData);
    zoomBrushUpdate.exit().remove();
    let zoomBrushEnter = zoomBrushUpdate.enter()
      .append('rect')
      .attr('class', 'zoom-brush');
    let zoomBrush = zoomBrushUpdate.merge(zoomBrushEnter);

    // For each zoom brush...
    zoomBrush.each(function(d: ZoomBrushPlot) {
      let brush = d3.select(this);

      // Get the area we should brush.
      let brushArea = self.getZoomProgressBrushArea(d.plot);

      if (brushArea) {
        // Draw the brush, ensuring we clip it to the plot area.
        brush
          .attr('clip-path', `url(#${PlotClippingRenderer.getPlotClipPathId(settings.uniqueId, d.plot.plotIndex)})`)
          .attr('x', brushArea.x + d.plot.absoluteRenderArea.x)
          .attr('y', brushArea.y + d.plot.absoluteRenderArea.y)
          .attr('width', brushArea.width)
          .attr('height', brushArea.height)
          .classed('calculating-zoom-brush', () => d.includeInCalculations && self.isRenderingCalculations);

        if (self.isRenderingCalculations) {
          self.performZoomBrushCalculations(d, brushArea);
        }
      } else {
        // Hide the brush if we don't have a brush area.
        brush.attr('opacity', 0);
      }
    });

    // If we're rendering calculations, tell the legend it needs to re-render.
    if (this.isRenderingCalculations) {
      this.listeners.call('legendChanged');
    }
  }

  /**
   * Reset all legend values to NaN.
   */
  private resetLegendValues() {
    if (!this.layout || !this.xLegendList) {
      return;
    }

    for (let xLegendItem of this.xLegendList.channels) {
      for (let i = 0; i < xLegendItem.legendValues.length; ++i) {
        xLegendItem.legendValues[i] = NaN;
      }
    }
    for (let row of this.layout.processed.rows) {
      for (let channel of row.processed.channels) {
        for (let i = 0; i < channel.legendValues.length; ++i) {
          channel.legendValues[i] = NaN;
        }
      }
    }
  }

  /**
   * Gets the current brush area for a plot.
   * @param d The plot to get the brush area for.
   * @returns The brush area.
   */
  private getZoomProgressBrushArea(d: ProcessedPlot): ZoomBrushSelection | undefined {
    let brushArea: ZoomBrushSelection | undefined;
    if (this.zoomProgress) {
      if (this.zoomType === ZoomType.x
        || (this.zoomType === ZoomType.xy && d !== this.zoomProgress.plot && d.column === this.zoomProgress.plot.column)) {
        // If we're zooming the X axis, or if we're zooming both axes and we're on a plot in the same column,
        // then we brush the entire height of the plot.
        brushArea = new ZoomBrushSelection(
          this.zoomProgress.start.x,
          -1,
          this.zoomProgress.end.x - this.zoomProgress.start.x,
          d.absoluteRenderArea.height + 2);
      }
      if (this.zoomType === ZoomType.y
        || (this.zoomType === ZoomType.xy && d !== this.zoomProgress.plot && d.row === this.zoomProgress.plot.row)) {
        // If we're zooming the Y axis, or if we're zooming both axes and we're on a plot in the same row,
        // then we brush the entire width of the plot.
        brushArea = new ZoomBrushSelection(
          -1,
          this.zoomProgress.start.y,
          d.absoluteRenderArea.width + 2,
          this.zoomProgress.end.y - this.zoomProgress.start.y);
      }
      if (this.zoomType === ZoomType.xy && d === this.zoomProgress.plot) {
        // If we're zooming both axes and we're on the plot that started the zoom operation,
        // then we brush the area that is being zoomed.
        brushArea = new ZoomBrushSelection(
          this.zoomProgress.start.x,
          this.zoomProgress.start.y,
          this.zoomProgress.end.x - this.zoomProgress.start.x,
          this.zoomProgress.end.y - this.zoomProgress.start.y);
      }
    }
    return brushArea;
  }

  /**
   * Perform the current calculation type for each channel and update the legend values.
   */
  private performChannelCalculations() {
    if (!this.layout) {
      return;
    }

    // Set the X channel to either the first visible channel in the first column, or undefined.
    let xChannel: ProcessedMultiPlotChannel | undefined;
    let visibleXDomains = this.layout.processed.columns[0].processed.channels.filter(v => v.isVisible);
    if (visibleXDomains.length) {
      xChannel = visibleXDomains[0];
    }

    // If we don't have an X domain we can't perform channel calculations.
    if (!xChannel) {
      return;
    }

    // For each row...
    for (let row of this.layout.processed.rows) {
      // And each channel in the row...
      for (let channel of row.processed.channels) {
        // And each data source for the channel...
        for (let sourceIndex = 0; sourceIndex < channel.sources.length; ++sourceIndex) {

          // Get the integration channel.
          let integrationChannel = this.layout.processed.sourceData[sourceIndex].featureChannels.integrationChannel;
          if(integrationChannel.name === 'index'
            && (this.calculationType === CalculationType.integral || this.calculationType === CalculationType.gradient)){
            // If the integration channel is index, it doesn't make sense to perform integrals or gradients.
            return;
          }

          // Get the X and Y data for this data source and channel.
          let xSourceChannel = xChannel.sources[sourceIndex];
          let ySourceChannel = channel.sources[sourceIndex];

          // If there is one column, use the extents of the column as the X extent.
          // If there are multiple columns (which can be zoomed independently), just use
          // infinite extents.
          let xExtent = this.layout.columns.length == 1 ?
            d3.extent(this.layout.columns[0].processed.scale.domain()) :
            [-Infinity, Infinity];
          let yExtent = d3.extent(row.processed.scale.domain());

          // Perform the calculation.
          let yResult = this.getChannelCalculationResult.execute(
            this.calculationType,
            this.domainSnapBehaviour,
            xSourceChannel,
            ySourceChannel,
            integrationChannel,
            [xExtent[0], xExtent[1]],
            [yExtent[0], yExtent[1]]);

          // Set the legend value to the calculation result.
          channel.legendValues[sourceIndex] = yResult;

          // Update the legend units based on the calculation type if necessary.
          switch (this.calculationType){
            case CalculationType.gradient:
              channel.unitsOverride = `(${channel.baseUnits})/${integrationChannel.units}`;
              break;
            case CalculationType.integral:
              channel.unitsOverride = `(${channel.baseUnits}).${integrationChannel.units}`;
              break;
          }
        }
      }
    };

    // For each column...
    for (let column of this.layout.processed.columns) {
      // And each channel in the column...
      for (let channel of column.processed.channels) {
        // And each data source for the channel...
        for (let sourceIndex = 0; sourceIndex < channel.sources.length; ++sourceIndex) {
          // Get the the extents of the column channel.
          // TODO: If we performed the calculation for the row channel using a smaller range than the full column channel data,
          // then we should use that range here.
          let result = NaN;
          let data = channel.sources[sourceIndex].data;
          if (data) {
            let xExtent = d3.extentStrict(data);
            result = xExtent[1] - xExtent[0];
          }
          // Set the column channel range as the legend value.
          this.getVisibleXLegendChannel(channel).legendValues[sourceIndex] = result;
        }
      }
    }
  }

  /**
   * Perform calculations for the the given plot and brush area.
   * @param d The plot to perform calculations for.
   * @param brushArea The brush area.
   */
  private performZoomBrushCalculations(d: ZoomBrushPlot, brushArea: ZoomBrushSelection) {
    if (!this.layout) {
      return;
    }

    // If the plot isn't included in the calculation, or there is no brush area, just return.
    if (!d.includeInCalculations || brushArea.width === 0 || brushArea.height === 0) {
      return;
    }

    // Set the X channel to either the first visible channel in the first column, or undefined.
    let xChannel: ProcessedMultiPlotChannel | undefined;
    let visibleXDomains = d.plot.column.processed.channels.filter(v => v.isVisible);
    if (visibleXDomains.length) {
      xChannel = visibleXDomains[0];
    }

    // If we don't have an X domain we can't perform channel calculations.
    if (!xChannel) {
      return;
    }

    // Get the X axis scale and default the bounds to infinite.
    let xScale = d.plot.column.processed.scale;
    let xDomainBounds: [number, number] = [-Infinity, Infinity];

    // If we're zooming the X axis update the X bounds to the brushed range.
    if (this.zoomType === ZoomType.x || this.zoomType === ZoomType.xy) {
      xDomainBounds = d3.extentStrict([
        xScale.invert(brushArea.x),
        xScale.invert(brushArea.x + brushArea.width)
      ]);
    }

    // For each data source...
    for (let sourceIndex = 0; sourceIndex < xChannel.sources.length; ++sourceIndex) {

      // Get the integration channel.
      let integrationChannel = this.layout.processed.sourceData[sourceIndex].featureChannels.integrationChannel;
      if(integrationChannel.name === 'index'
      && (this.calculationType === CalculationType.integral || this.calculationType === CalculationType.gradient)){
        // If the integration channel is index, it doesn't make sense to perform integrals or gradients.
        return;
      }

      // Get the X channel data for this data source.
      let xSourceChannel = xChannel.sources[sourceIndex];
      let xData = xSourceChannel.data;

      if (!xData) {
        continue;
      }

      // For each Y channel in the plot...
      for (let yChannel of d.plot.row.processed.channels) {

        // Get the Y channel data for this data source.
        let ySourceChannel = yChannel.sources[sourceIndex];

        if (!ySourceChannel.data) {
          continue;
        }

        // Get the X axis scale and default the bounds to infinite.
        let yScale = d.plot.row.processed.scale;
        let yDomainBounds: [number, number] = [-Infinity, Infinity];

        // If we're zooming the Y axis update the Y bounds to the brushed range.
        if (this.zoomType === ZoomType.y || (this.zoomType === ZoomType.xy && this.zoomProgress && d.plot === this.zoomProgress.plot)) {
          yDomainBounds = d3.extentStrict([
            yScale.invert(brushArea.y),
            yScale.invert(brushArea.y + brushArea.height)
          ]);
        }

        // Perform the calculation.
        let yResult = this.getChannelCalculationResult.execute(
          this.calculationType,
          this.domainSnapBehaviour,
          xSourceChannel,
          ySourceChannel,
          integrationChannel,
          xDomainBounds,
          yDomainBounds,
          brushArea.isSelectionReversedX);

        // Set the legend value to the calculation result.
        yChannel.legendValues[sourceIndex] = yResult;

        // Update the legend units based on the calculation type if necessary.
        switch (this.calculationType){
          case CalculationType.gradient:
            yChannel.unitsOverride = `(${yChannel.baseUnits})/${integrationChannel.units}`;
            break;
          case CalculationType.integral:
            yChannel.unitsOverride = `(${yChannel.baseUnits}).${integrationChannel.units}`;
            break;
        }
      }

      // Next we will calculate xRanges for current and additional data.
      // Get all the indices of the X channel within the X domain bounds.
      let xDomainIndices = xData.map((v, i) => v >= xDomainBounds[0] && v <= xDomainBounds[1]);
      let xRange: number;
      if(xSourceChannel.isMonotonic && this.domainSnapBehaviour === DomainSnapBehaviour.linearInterpolation){
        // If the data is monotonic we can quickly calculate the actual range from the data.
        let xMin = xDomainBounds[0] !== -Infinity ? xDomainBounds[0] : xData.at(0);
        let xMax = xDomainBounds[1] !== Infinity ? xDomainBounds[1] : xData.at(-1);
        xRange = xMax - xMin;
      }else{
        // Otherwise calculate the extent of the data within the X domain bounds.
        let xValues = xData.filter((v, i) => xDomainIndices[i]);
        let xExtent = d3.extentStrict(xValues);
        xRange = xExtent[1] - xExtent[0];
      }

      // If we are zooming and this plot is the one being interacted with, and the X channel is monotonic...
      if (this.zoomProgress && d.plot === this.zoomProgress.plot && xSourceChannel.isMonotonic) {
        let startIndex = xDomainIndices.indexOf(true);
        let endIndex = xDomainIndices.lastIndexOf(true);
        if (startIndex === -1 || endIndex === -1) {
          // If there are no data points within the range, we can't update the additional X channels with a range.
          // TODO: This should exit the if statement, not the outer loop. Current behavior only causes an issue
          // on charts with one row when zooming between data points.
          continue;
        }

        // For each additional X channel...
        for (let xAdditionalChannel of d.plot.column.processed.channels) {

          // If it's the visible channel (which we've already handled), or isn't monotonic, skip it.
          if (xAdditionalChannel === xChannel || !xAdditionalChannel.isMonotonic) {
            continue;
          }

          // Get the data for the current data source.
          let xAdditionalData = xAdditionalChannel.sources[sourceIndex].data;
          if (!xAdditionalData) {
            continue;
          }

          // Calculate the brushed range of the additional X channel.
          let xAdditionalRange: number;
          if(this.domainSnapBehaviour === DomainSnapBehaviour.linearInterpolation && startIndex > 0 && endIndex < xAdditionalData.length - 1){
            // If we're using linear interpolation, get the interpolated range.
            let xMin = d3.interpolate(xAdditionalData[startIndex-1], xAdditionalData[startIndex])((xDomainBounds[0] - xData[startIndex - 1])/(xData[startIndex] - xData[startIndex - 1]));
            let xMax = d3.interpolate(xAdditionalData[endIndex], xAdditionalData[endIndex+1])((xDomainBounds[1] - xData[endIndex])/(xData[endIndex + 1] - xData[endIndex]));
            xAdditionalRange = xMax - xMin;
          }else{
            // Otherwise just get the extent of the data using the start and end index.
            let xAdditionalExtent = d3.extentStrict([xAdditionalData[startIndex], xAdditionalData[endIndex]]);
            xAdditionalRange = xAdditionalExtent[1] - xAdditionalExtent[0];
          }

          // And update the legend with the additional X channel range.
          this.getVisibleXLegendChannel(xAdditionalChannel).legendValues[sourceIndex] = xAdditionalRange;
        }
      }

      // Update the legend with the visible X channel range.
      this.getVisibleXLegendChannel(xChannel).legendValues[sourceIndex] = xRange;
    }
  }

  /**
   * If the supplied channel is in the X legend list already, this returns the channel from the list. Otherwise
   * this will add the channel to the list and return it.
   * @param channel The channel to find.
   * @returns The channel, which is now guaranteed to be in the X legend list.
   */
  private getVisibleXLegendChannel(channel: ProcessedMultiPlotChannel): ILegendChannel {
    if (!this.xLegendList) {
      throw new Error('X legend list was not found.');
    }

    let xLegendItems = this.xLegendList.channels.filter(v => v.name === channel.name);
    if (!xLegendItems.length) {
      this.xLegendList.addChannel(channel);
      return channel;
    }

    return xLegendItems[0];
  }

  /**
   * Gets the X and Y scale ratios for the current zoom type.
   * @returns The scale ratios.
   */
  private getScaleRatio() {
    switch (this.zoomType) {
      case ZoomType.x:
        return [1, 0];
      case ZoomType.y:
        return [0, 1];
      default:
        return [1, 1];
    }
  }

  /**
   * Zooms the chart to the current in-progress zoom operation.
   * @param selection The chart selector.
   */
  private zoomToZoomProgress(selection: SVGSelection) {
    if (this.isZooming || !this.layout) {
      // If we're not zooming, there is nothing to do.
      return;
    }
    try {
      this.isZooming = true;

      if (!this.zoomProgress) {
        return;
      }

      let existingTransform;
      let self = this;
      const zoomProgress = this.zoomProgress;

      // For each plot...
      selection.selectAll<SVGElement, ProcessedPlot>('.zoom-handler')
        .each(function(plot: ProcessedPlot) {
          if (plot !== zoomProgress.plot) {
            // Ensure we are in the plot being zoomed.
            return;
          }

          // Get the in-progress zoom's brush area.
          let brushArea = self.getZoomProgressBrushArea(plot);
          if (!brushArea || brushArea.width === 0 || brushArea.height === 0) {
            return;
          }

          // Get the current zoom transform.
          existingTransform = d3xy.xyzoomTransform(this) || d3xy.xyzoomIdentity;

          // Calculate the new transform based on the brush area and the existing transform.
          if (self.zoomType === ZoomType.x || self.zoomType === ZoomType.xy) {
            let scalingFactor = plot.absoluteRenderArea.width / brushArea.width;
            existingTransform = d3xy.xyzoomIdentity
              .translate((existingTransform.x - brushArea.x) * scalingFactor, existingTransform.y)
              .scale(existingTransform.kx * scalingFactor, existingTransform.ky);
          }

          if (self.zoomType === ZoomType.y || self.zoomType === ZoomType.xy) {
            let scalingFactor = plot.absoluteRenderArea.height / brushArea.height;
            existingTransform = d3xy.xyzoomIdentity
              .translate(existingTransform.x, (existingTransform.y - brushArea.y) * scalingFactor)
              .scale(existingTransform.kx, existingTransform.ky * scalingFactor);
          }

          // Apply the new transform to the plot being zoomed.
          let zoom: any = (this as any)[ZOOM_PROPERTY];
          d3.select(this).call(zoom.transform, existingTransform);
        });

      if (existingTransform) {
        // Apply the new transform to neighboring plots.
        this.applyPlotTransformToNeighbours(selection, zoomProgress.plot, existingTransform);

        // Raise an event to say we have zoomed.
        this.listeners.call('zoom');
      }
    } finally {
      // Whatever happens, after this function is complete, we're no longer zooming.
      this.isZooming = false;
    }
  }

  /**
   * Called when the d3xy.xyzoom instance's zoom event is raised.
   * @param selection The selection.
   * @param plot The plot being zoomed.
   * @param currentEvent The event.
   */
  private zoomHandler(selection: SVGSelection, plot: ProcessedPlot, currentEvent: any) {
    if (this.isZooming || !this.layout) {
      // If we're already zooming, we don't need to handle the event.
      return;
    }
    try {
      // Set isZooming to true, to ensure we don't handle any additional zoom events while
      // updating the transforms.
      this.isZooming = true;

      // Get the current plot.
      plot = getCurrentPlot(this.layout, plot);

      // Get the current transform.
      let transform = currentEvent.transform;
      if (transform.k === Infinity) {
        return;
      }

      // Apply the transform to the neighboring plots.
      this.applyPlotTransformToNeighbours(selection, plot, transform);

      // Raise the event to say we have zoomed.
      this.listeners.call('zoom');
    } finally {
      // Always reset the isZooming flag.
      this.isZooming = false;
    }
  }

  /**
   * When a plot is zoomed, any neighboring plots in the same row or column need to be updated.
   * @param selection The chart selection.
   * @param plot The plot which was zoomed.
   * @param transform The transform for the plot which was zoomed.
   */
  private applyPlotTransformToNeighbours(selection: SVGSelection, plot: ProcessedPlot, transform: any) {

    let handlers = selection.selectAll<SVGElement, ProcessedPlot>('.zoom-handler');

    // For each plot...
    handlers
      .each(function(otherPlot: ProcessedPlot) {
        if (!otherPlot || !plot || otherPlot === plot) {
          // If we're the same plot which was zoomed, there is nothing to do.
          return;
        }

        if (otherPlot.column === plot.column) {
          // If we're in the same column, apply the transform to the X axis.
          let existingTransform = d3xy.xyzoomTransform(this);
          let newTransform = d3xy.xyzoomIdentity.translate(transform.x, existingTransform.y).scale(transform.kx, existingTransform.ky);
          let zoom: any = (this as any)[ZOOM_PROPERTY];
          d3.select(this).call(zoom.transform, newTransform);
        }

        if (otherPlot.row === plot.row) {
          // If we're in the same row, apply the transform to the Y axis.
          let existingTransform = d3xy.xyzoomTransform(this);
          let newTransform = d3xy.xyzoomIdentity.translate(existingTransform.x, transform.y).scale(existingTransform.kx, transform.ky);
          let zoom: any = (this as any)[ZOOM_PROPERTY];
          d3.select(this).call(zoom.transform, newTransform);
        }
      });
  }

  /**
   * Creates a function which which filters events for the d3xy.xyzoom instance.
   * @returns A function which filters events for the d3xy.xyzoom instance.
   */
  private getZoomFilter() {
    let self = this;
    return function(currentEvent: any) {

      // Ignore clicks other than left click.
      if (currentEvent.button) {
        return false;
      }

      // Ignore double click.
      if (currentEvent.type === 'dblclick') {
        return false;
      }

      // Ignore mousedown events if we're in the middle of a zoom operation.
      if (self.zoomProgress && currentEvent.type === 'mousedown') {
        return false;
      }

      // Ignore wheel events if enableZoomByScroll is false.
      if (!self.enableZoomByScroll && currentEvent.type === 'wheel') {
        return false;
      }

      // Otherwise, handle the event.
      return true;
    };
  }

  /**
   * Renders the zoom type and calculation type buttons.
   * @param selection The chart selection.
   * @param container The zoom renderer container selection.
   */
  private renderZoomOptions(selection: SVGSelection, container: d3.Selection<SVGGElement, null, SVGElement, any>) {
    if (!this.settings) {
      return;
    }

    this.toggleOptionsRenderer.renderToggleOptions<ZoomType>(
      selection,
      'zoom',
      OPTIONS_LEFT_PADDING,
      this.settings.svgSize.height - OPTIONS_BOTTOM_PADDING,
      'Zoom',
      30,
      this.zoomToggles,
      this.zoomType,
      () => this.renderZoomOptionsAndBrushes(selection, container));

    this.toggleOptionsRenderer.renderToggleOptions<CalculationType>(
      selection,
      'calculation',
      OPTIONS_LEFT_PADDING + 100,
      this.settings.svgSize.height - OPTIONS_BOTTOM_PADDING,
      'Mode',
      30,
      this.calculationToggles,
      this.calculationType,
      () => this.renderZoomOptionsAndBrushes(selection, container));
  }

  /**
   * Render the zoom toggle buttons and any current zoom brushes.
   * @param selection The chart selection.
   * @param container The zoom renderer container selection.
   */
  private renderZoomOptionsAndBrushes(selection: SVGSelection, container: d3.Selection<SVGGElement, null, SVGElement, any>) {
    this.renderZoomOptions(selection, container);
    this.renderZoomBrushes(selection, container);
  }
}


/**
 * An in-progress zoom operation.
 */
class ZoomProgress {

  /**
   * The end position of the zoom operation.
   */
  public end: IPosition;

  /**
   * Creates a new zoom progress instance.
   * @param plot The plot being zoomed.
   * @param start The start of the zoom operation.
   */
  constructor(
    public plot: ProcessedPlot,
    public start: IPosition) {
    this.end = start;
  }
}

/**
 * A plot with a zoom brush active on it.
 */
class ZoomBrushPlot {
  /**
   * Creates a new zoom brush plot instance.
   * @param plot The plot.
   * @param includeInCalculations Whether the plot should be included in calculations.
   */
  constructor(
    public plot: ProcessedPlot,
    public includeInCalculations: boolean) {
  }
}

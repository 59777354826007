import {DisplayableError, InputValidationError} from '../common/errors/errors';
import {CustomProperty} from '../simulations/custom-properties/custom-properties';
import {ConfigTypeLookup, ExplorationConfigType} from '../simulations/configs/config-types';
import {DocumentSubType, PostStudyResult, StudyStub, StudyTypeDefinition} from '../../generated/api-stubs';
import {GetSimVersion} from '../common/get-sim-version.service';
import {StudySubmittedEvents} from '../simulations/studies/study-submitted-events.service';
import {StudyMetadataCache} from '../notifications/study-metadata-cache.service';
import {Injectable} from '@angular/core';
import {StudyInput} from './study-input';
import { AuthenticationService } from '../identity/state/authentication.service';


/**
 * Submits a study based on the given information.
 */
@Injectable()
export class SubmitStudy {

  /**
   * Creates an instance of SubmitStudy.
   * @param authenticationService The authentication service.
   * @param getSimVersion The service for getting the sim version.
   * @param studyStub The study stub.
   * @param studySubmittedEvents The study submitted events service.
   * @param studyMetadataCache The study metadata cache.
   */
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly getSimVersion: GetSimVersion,
    private readonly studyStub: StudyStub,
    private readonly studySubmittedEvents: StudySubmittedEvents,
    private readonly studyMetadataCache: StudyMetadataCache) {
  }

  /**
   * Submits a study based on the given information.
   * @param studyName The name of the study.
   * @param studyTypeInformation The study type information.
   * @param getInput The function for getting the input for a config type.
   * @returns The committed study.
   */
  public async execute(
    studyName: string,
    studyTypeInformation: StudyTypeDefinition,
    getInput: (configType: DocumentSubType, targetSimVersion: string) => Promise<StudyInput>): Promise<CommittedStudy> {

    const userData = this.authenticationService.userDataSnapshot;

    // This will also refresh study inputs if the sim version changes.
    let simVersion = await this.getSimVersion.execute();

    // Start creating the new study data.
    let study = {
      simTypes: studyTypeInformation.simTypes,
      simConfig: {
      },
      exploration: undefined as any
    };

    let sources = [];
    let properties: CustomProperty[] = [];
    let notes: string = '';

    // For each input...
    for(let input of studyTypeInformation.inputs) {
      const configType = ConfigTypeLookup.get(input.configType);
      if(!configType){
        throw new DisplayableError('Unknown config type: ' + input.configType);
      }

      // Load the input config.
      let inputState = await getInput(input.configType, simVersion);
      if(!inputState){
        // Error if the input is required but nothing was returned.
        if(input.isRequired){
          throw new InputValidationError(configType.titleName + ' is required.');
        }
      } else{
        // Put it in the right place in the new study data.
        if(input.configType === ExplorationConfigType.singularKey){
          study.exploration = inputState.data;
        } else{
          (study.simConfig as any)[input.configType] = inputState.data;
        }

        // Add the source information.
        sources.push({
          configType: inputState.configType,
          userId: inputState.userId,
          configId: inputState.configId,
          name: inputState.name,
          isEdited: inputState.isEdited
        });

        // Add any custom properties from the config to the study.
        this.addProperties(properties, inputState.configType, inputState);

        // Add any notes from the config to the study.
        if(inputState.notes){
          if(notes){
            notes += '\n\n';
          }
          notes += configType.titleName + ':\n' + inputState.notes.trim();
        }
      }
    }

    // Submit the study.
    let studyResult = <PostStudyResult>await this.studyStub.postStudy(
      userData.tenant,
      {
        name: studyName,
        studyType: studyTypeInformation.studyType,
        simVersion,
        sources,
        properties,
        notes,
        study,
        isTransient: false
      });

    // Create the committed study metadata.
    const committedStudy = new CommittedStudy(
      studyName,
      userData.tenant,
      userData.sub,
      studyResult.studyId);

    // Emit that we have submitted a study for anyone interested.
    this.studySubmittedEvents.studySubmitted.emit(studyResult);

    // Update the study metadata cache.
    this.studyMetadataCache.set(userData.tenant, studyResult.studyId, studyName);

    // Return the committed study.
    return committedStudy;
  }

  /**
   * Add custom properties from the given config type to the study's custom properties list.
   * @param properties The list of custom properties to add to.
   * @param type The type of the config.
   * @param input The input to get the properties from.
   */
  private addProperties(properties: CustomProperty[], type: string, input: StudyInput) {
    for(let p of input.properties){
      properties.push({
        name: type + '.' + p.name,
        value: p.value
      });
    }
  }
}

/**
 * Represents a committed study.
 */
export class CommittedStudy {

  /**
   * Creates an instance of CommittedStudy.
   * @param name The name of the study.
   * @param tenantId The tenant ID.
   * @param userId The user ID.
   * @param studyId The study ID.
   */
  constructor(
    public name: string,
    public tenantId: string,
    public userId: string,
    public studyId: string
  ){}
}

// Draft-06 compatibility

interface KeyedObject {
  [key: string]: KeyedObject | string | number | boolean;
}

const $schema = '$schema';
const draft04Url = 'http://json-schema.org/draft-04/schema';
const exclusiveMinimum = 'exclusiveMinimum';
const exclusiveMaximum = 'exclusiveMaximum';
const minimum = 'minimum';
const maximum = 'maximum';
const format = 'format';
const tabs = 'tabs';
const tabstop = 'tabs-top';
const uniqueItems = 'uniqueItems';

const schemaTransformer = (node: KeyedObject) => {
  if (Object.getOwnPropertyNames(node).includes($schema)) {
    node[$schema] = draft04Url;
  }
};

const exclusiveMinimumTransformer = (node: KeyedObject) => {
  if (Object.getOwnPropertyNames(node).includes(exclusiveMinimum)) {
    node[minimum] = node[exclusiveMinimum];
    node[exclusiveMinimum] = true;
  }
};

const exclusiveMaximumTransformer = (node: KeyedObject) => {
  if (Object.getOwnPropertyNames(node).includes(exclusiveMaximum)) {
    node[maximum] = node[exclusiveMaximum];
    node[exclusiveMaximum] = true;
  }
};

const tabstopTransformer = (node: KeyedObject) => {
  if (Object.getOwnPropertyNames(node).includes(format) && node[format] === tabs) {
    node[format] = tabstop;
  }
};

const uniqueItemsTransformer = (node: KeyedObject) => {
  if (Object.getOwnPropertyNames(node).includes(uniqueItems) && node[uniqueItems] === false) {
    let deleted = delete node[uniqueItems];
    if(!deleted){
      throw new Error("not deleted");
    }
  }
};

const transformers: Array<(node: KeyedObject) => void> = [
  schemaTransformer,
  exclusiveMaximumTransformer,
  exclusiveMinimumTransformer,
  tabstopTransformer,
  uniqueItemsTransformer
];

export const backwardsCompatibilityAndBugFixes = (node: { [key: string]: any }, draft: number = 4): void => {
  if (!node) {
    return;
  }

  // todo: could be unnecessary...
  let currentDraft = draft;
  if (Object(node).hasOwnProperty($schema)) {
    currentDraft = getSchemaVersion(node[$schema]);
  }

  if (currentDraft === 6) {
    for (let tranformer of transformers) {
      tranformer(node);
    }
  }

  Object.keys(node).forEach(key => {
    if (typeof node[key] === 'object') {
      backwardsCompatibilityAndBugFixes(node[key], currentDraft);
    }
  });
};

const getSchemaVersion = (schema: string) => schema.includes('/draft-06/schema') ? 6 : 4;

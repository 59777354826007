import { Injectable } from '@angular/core';
import { CommandContext, CommandResult, ICommandContext, WorksheetCommand } from './worksheet-command';
import { MenuItem, ButtonMenuItem, SubMenuItem } from '../../context-menu/context-menu-types';
import { StudyViewModel } from '../study-view-model';
import { DownloadStudy } from '../../simulations/studies/download-study.service';

/**
 * A command that downloads the results of the selected study.
 */
@Injectable()
export class DownloadResultsCommand extends WorksheetCommand {

  /**
   * Creates an instance of DownloadResultsCommand.
   * @param downloadStudy The service for downloading a study.
   */
  constructor(private readonly downloadStudy: DownloadStudy) {
    super();
  }

  /**
   * Determines if the command can be executed.
   * @param context The command context.
   * @returns True if the command can be executed, otherwise false.
   */
  public canExecute(context: CommandContext): context is ICommandContext<StudyViewModel> {
    return context.isStudy()
      && context.isSingleRow
      && context.target.isComplete;
  }

  /**
   * Creates the menu items for the command.
   * @param context The command context.
   * @param result The menu items.
   */
  public createMenuItems(context: CommandContext, result: MenuItem<CommandResult>[]): void {
    if(!this.canExecute(context)) {
      return;
    }

    let downloadTypes: MenuItem<CommandResult>[] = [];
    let study = context.target.row.study;
    let studyData = study.resolvedReference.data;

    // Create a menu item for each download type (CSV, binary, etc.).
    for (let downloadType of this.downloadStudy.downloadStudyTypes(study.reference.tenantId, study.reference.targetId, studyData.name, studyData.studyDocument.jobCount, studyData.simTypes)){
      downloadTypes.push(new ButtonMenuItem<CommandResult>(
        downloadType.displayName,
        '',
        async () => {
          await downloadType.execute();
          return CommandResult.NoUpdate;
        },
        'download'));
    }

    // Put the download types in a sub-menu.
    result.push(new SubMenuItem<CommandResult>(
      'Download Results',
      '',
      downloadTypes,
      true));
  }
}

import { TransientMultiPlotLayout } from './types/transient-multi-plot-layout';
import { IMultiPlotLayout } from './types/i-multi-plot-layout';
import { isUndefined } from '../../is-defined';
import { CreateTransientDataForSide } from './create-transient-data-for-side';


/**
 * Creates default transient data structures for the layout and each row and column if
 * they don't already exist.
 */
export class CreateTransientDataForLayout {
  constructor(
    private readonly createTransientDataForSide: CreateTransientDataForSide) {
  }

  /**
   * Creates default transient data structures for the layout and each row and column if
   * they don't already exist.
   * @param layout The layout to create transient data on.
   */
  public execute(layout: IMultiPlotLayout) {
    if (isUndefined(layout.transient)) {
      layout.transient = new TransientMultiPlotLayout();
    }

    if (layout.rows) {
      for (let side of layout.rows) {
        this.createTransientDataForSide.execute(side);
      }
    }

    if (layout.columns) {
      for (let side of layout.columns) {
        this.createTransientDataForSide.execute(side);
      }
    }
  }
}

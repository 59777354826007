// Taken from http://stackoverflow.com/questions/6913512/how-to-sort-an-array-of-objects-by-multiple-fields
// Example: homes.sort(sort_by('city', {name:'price', primer: parseInt, reverse: true}));

// utility functions
let default_cmp = function(a: any, b: any) {
  if (a === b) {
    return 0;
  }
  return a < b ? -1 : 1;
};

let getCmpFunc = function(primer: (item: any) => any, reverse: boolean) {
  let dfc = default_cmp; // closer in scope
    let cmp = default_cmp;
  if (primer) {
    cmp = function(a, b) {
      return dfc(primer(a), primer(b));
    };
  }
  if (reverse) {
    return function(a: any, b: any) {
      return -1 * cmp(a, b);
    };
  }
  return cmp;
};

// actual implementation
interface SortByDefinition {
  name: string;
  primer?: (v: any) => string | number;
  reverse?: boolean;
}

type SortByItem = string | SortByDefinition;

export function sortBy(...inputs: SortByItem[]) {
  let fields: { name: string; cmp: (a: any, b: any) => number}[] = [];
    let n_fields = inputs.length;
    let field; let name; let cmp;

  // preprocess sorting options
  for (let i = 0; i < n_fields; i++) {
    field = inputs[i];
    if (typeof field === 'string') {
      name = field;
      cmp = default_cmp;
    } else {
      name = field.name;
      cmp = getCmpFunc(field.primer, field.reverse);
    }
    fields.push({
      name,
      cmp
    });
  }

  // final comparison function
  return function(A: any, B: any) {
    let name; let result;
    for (let i = 0; i < n_fields; i++) {
      result = 0;
      field = fields[i];
      name = field.name;

      result = field.cmp(A[name], B[name]);
      if (result !== 0) {
        break;
      }
    }
    return result;
  };
}

import { UrlFileLoaderBase } from '../../visualizations/url-file-loader-base';
import { StudyStub } from '../../../generated/api-stubs';
import { getJobIdFromJobIndex } from '../../common/get-job-id-from-job-index';
import { HttpErrorResponse } from '@angular/common/http';
import { ChannelBinaryFormat } from '../../visualizations/url-file-loader';
import { Timer } from '../../common/timer.service';

const MAX_ATTEMPTS: number = 5;

// NOTE: Currently this does a retry irrespective of the error (note 404 is handled in the base class).
// It could be improved by only retrying on 403 or transient errors.
export abstract class RetryingFileLoaderBase extends UrlFileLoaderBase {

  constructor(
    protected readonly tenantId: string,
    protected readonly studyId: string,
    protected readonly studyStub: StudyStub,
    private readonly timer: Timer,
    private accessInformation: AccessInformation
  ) {
    super();
  }

  protected async loadText(url: string, jobIndex?: number): Promise<string> {
    return this.loadWithRetries(() => super.loadText(url + this.getAccessSignature(jobIndex)));
  }

  protected async loadJson(url: string, jobIndex?: number): Promise<any> {
    return this.loadWithRetries(() => super.loadJson(url + this.getAccessSignature(jobIndex)));
  }

  public async loadCsv(url: string, jobIndex?: number): Promise<any[]> {
    return this.loadWithRetries(() => super.loadCsv(url + this.getAccessSignature(jobIndex)));
  }

  public async loadCsvRows(url: string, jobIndex?: number): Promise<string[][]> {
    return this.loadWithRetries(() => super.loadCsvRows(url + this.getAccessSignature(jobIndex)));
  }

  protected async loadNumericArray(url: string, binaryFormat: ChannelBinaryFormat | undefined, jobIndex?: number): Promise<ReadonlyArray<number>> {
    return this.loadWithRetries(() => super.loadNumericArray(url + this.getAccessSignature(jobIndex), binaryFormat));
  }

  protected hasJobIndex(jobIndex: number) {
    return typeof jobIndex !== 'undefined';
  }

  private async loadWithRetries<T>(delegate: () => Promise<T>) {
    let attempt = 1;
    while (attempt < MAX_ATTEMPTS) {
      try {
        return await delegate();
      } catch (error) {
        await this.getNewAccessSignatures(error);
        await this.timer.backoff(attempt, 100);
      }
    }

    return await delegate();
  }

  private getAccessSignature(jobIndex: number) {
    if (this.hasJobIndex(jobIndex) && this.accessInformation.jobs) {
      // We need job access signature and study access information has been passed in.
      return this.accessInformation.jobs[jobIndex % this.accessInformation.jobs.length].accessSignature;
    }

    // Either we need a study access signature, or we need a job access signature
    // and the job access information has been passed in.
    return this.accessInformation.accessSignature;
  }

  private async getNewAccessSignatures(error: HttpErrorResponse, jobIndex?: number): Promise<any> {
    if (error.status === 403) {
      if (!this.hasJobIndex(jobIndex) || this.accessInformation.jobs) {
        let studyResult = await this.studyStub.getStudyMetadata(this.tenantId, this.studyId);
        this.accessInformation = studyResult.accessInformation;
      } else {
        let jobResult = await this.studyStub.getStudyJobMetadata(this.tenantId, this.studyId, getJobIdFromJobIndex(this.studyId, jobIndex));
        this.accessInformation = jobResult.accessInformation;
      }
    }
  }
}

export interface AccessInformation {
  url: string;
  accessSignature: string;
  jobs?: {
    url: string;
    accessSignature: string;
  }[];
}

import { Component } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, Event } from '@angular/router';

@Component({
  selector: 'cs-route-resolving-indicator',
  templateUrl: './route-resolving-indicator.component.html',
  styleUrls: ['./route-resolving-indicator.component.scss']
})
export class RouteResolvingIndicatorComponent {

  public isLoading: boolean = false;

  constructor(
    private readonly router: Router,
  ) {
    this.router.events.subscribe(async event => {
      this.navigationInterceptor(event);
    });
   }

   navigationInterceptor(event: Event): void {
    if (event instanceof NavigationStart) {
      this.isLoading = true;
    }

    if (event instanceof NavigationEnd
      || event instanceof NavigationCancel
      || event instanceof NavigationError) {
      this.isLoading = false;
    }
  }
}

import { IChannelMetadata, SourceLoaderBase, SourceMetadata } from './source-loader';
import { ChannelNameStyle } from './channel-name-style';
import { IViewerChannelData } from './viewer-channel-data';
import { INDEX_CHANNEL_NAME, StudySourceLoader } from './study-source-loader';
import { FilteredStudyExplorationAndScalarDataCache } from './filtered-study-exploration-and-scalar-data-cache';
import { SiteHooks } from '../../site-hooks';
import { SharedState } from '../shared-state';
import * as d3 from '../../d3-bundle';
import { SingleDimensionEvaluationDataCache } from './single-dimension-evaluation-data-cache';
import { ViewerChannelDataMap, ViewerChannelDataMapContent } from './viewer-channel-data-map';
import { InterpolationCoordinatesFactory } from './interpolation/interpolation-coordinates';
import { ChannelInterpolator } from './interpolation/channel-interpolator';
import { MinMax } from '../min-max';
import { Units } from '../../units';
import { UrlFileLoader } from '../../url-file-loader';
import { ViewerChannelDataFactory } from './viewer-channel-data-factory';
import { DimensionEvaluationData, GetSingleDimensionEvaluationData } from './get-single-dimension-evaluation-data';

/**
 * A source loader for a single dimension of an exploration.
 */
export class SingleDimensionStudySourceLoader extends SourceLoaderBase {

  constructor(
    private readonly dimensionIndex: number,
    private readonly sharedState: SharedState,
    private readonly studySourceLoader: StudySourceLoader,
    private readonly explorationAndScalarDataCache: FilteredStudyExplorationAndScalarDataCache,
    private readonly dimensionDataCache: SingleDimensionEvaluationDataCache,
    private readonly channelInterpolator: ChannelInterpolator,
    private readonly channelDataFactory: ViewerChannelDataFactory) {
    super();
  }

  /**
   * Creates a new single dimension study source loader.
   * @param dimensionIndex The dimension index.
   * @param sharedState The shared state.
   * @param siteHooks The site hooks.
   * @param urlFileLoader The URL file loader.
   * @param studyId The study ID.
   * @param explorationAndScalarDataCache The cache for exploration and scalar data.
   * @param channelInterpolator The channel interpolator.
   */
  public static create(
    dimensionIndex: number,
    sharedState: SharedState,
    siteHooks: SiteHooks,
    urlFileLoader: UrlFileLoader,
    studyId: string,
    explorationAndScalarDataCache: FilteredStudyExplorationAndScalarDataCache,
    channelInterpolator: ChannelInterpolator): SingleDimensionStudySourceLoader {

    let dimensionDataCache = new SingleDimensionEvaluationDataCache(explorationAndScalarDataCache, new GetSingleDimensionEvaluationData());

    return new SingleDimensionStudySourceLoader(
      dimensionIndex,
      sharedState,
      StudySourceLoader.create(siteHooks, urlFileLoader, studyId, explorationAndScalarDataCache, dimensionDataCache, dimensionIndex),
      explorationAndScalarDataCache,
      dimensionDataCache,
      channelInterpolator,
      new ViewerChannelDataFactory(siteHooks));
  }

  /**
   * @inheritdoc
   */
  public getSourceMetadata(): Promise<SourceMetadata> {
    return this.studySourceLoader.getSourceMetadata();
  }

  /**
   * @inheritdoc
   */
  public async getChannelData(requestedName: string, resultChannelNameStyle: ChannelNameStyle, primaryDomainName: string): Promise<IViewerChannelData> {
    let result = await this.getMultipleChannelData([requestedName], resultChannelNameStyle, primaryDomainName);
    return result.get(requestedName);
  }

  /**
   * @inheritdoc
   */
  public async getMultipleChannelData(requestedNames: ReadonlyArray<string>, resultChannelNameStyle: ChannelNameStyle, primaryDomainName: string): Promise<ViewerChannelDataMap> {
    let explorationAndScalarData = await this.explorationAndScalarDataCache.get();
    let dimensionData = await this.dimensionDataCache.get(this.dimensionIndex);
    let studyCachedData = await this.studySourceLoader.cache.get();

    // If any of the required data is missing, return empty data.
    if (!dimensionData || !studyCachedData || !explorationAndScalarData) {
      return new ViewerChannelDataMap(requestedNames.reduce<ViewerChannelDataMapContent>((p, c) => {
        p[c] = this.channelDataFactory.createChannelData(c, undefined, resultChannelNameStyle);
        return p;
      }, {}));
    }

    let channels = await this.studySourceLoader.getMultipleChannelData(requestedNames, resultChannelNameStyle, primaryDomainName);
    let result = channels.getContent();

    let scalarChannelNames = requestedNames.filter(v => v !== INDEX_CHANNEL_NAME && !studyCachedData.inputDimensionMap[v]);
    let fullScalarChannelNames = scalarChannelNames.map(v => channels.get(v).fullName);

    // Get the interpolated data.
    let interpolatedChannels = this.getInterpolatedChannelData(this.channelInterpolator, dimensionData, fullScalarChannelNames);

    for (let scalarChannelName of scalarChannelNames) {
      let channel = channels.get(scalarChannelName);
      if (!channel) {
        continue;
      }

      // Get interpolated data in correct units.
      let dimensionValues: ReadonlyArray<number> = interpolatedChannels[channel.fullName];
      let originalChannel = explorationAndScalarData.scalarData.getScalarData(channel.fullName);
      if (originalChannel) {
        dimensionValues = Units.convertValuesBetweenUnits(dimensionValues, originalChannel.units, channel.units, true);
      }

      let extents = d3.extentStrict(dimensionValues);

      // Interpolated data can have greater extents than the source data, meaning it can be cropped from
      // the chart.  Here we take the extents of the source and initial interpolated data, to at least ensure
      // we don't start with cropped data.
      // Note this means the different dimension charts won't have _exactly_ the same extents,
      // which they would if we went solely off the source data.
      result[scalarChannelName] = channel.clone(
        dimensionValues,
        channel.units,
        channel.isUserSpecifiedUnits,
        channel.dataLabels,
        {
          ...channel.options,
          overrideExtents: new MinMax(
            d3.minStrict([extents[0], channel.minimum]), //channel.minimum,
            d3.maxStrict([extents[1], channel.maximum])) //channel.maximum)
        });
    }

    let indexChannel = channels.get(INDEX_CHANNEL_NAME);
    if (indexChannel) {
      result[INDEX_CHANNEL_NAME] = indexChannel.clone(d3.range(dimensionData.jobsWithSweep.length));
    }

    return new ViewerChannelDataMap(result);
  }

  /**
   * @inheritdoc
   */
  public getRequestableChannels(resultChannelNameStyle: ChannelNameStyle, xDomainName: string): Promise<IChannelMetadata[]> {
    return this.studySourceLoader.getRequestableChannels(resultChannelNameStyle, xDomainName);
  }

  /**
   * Gets the list of input dimensions for the exploration.
   * @returns The list of input dimensions.
   */
  public async getInputDomainNames(): Promise<string[]> {
    let studyCachedData = await this.studySourceLoader.cache.get();
    return studyCachedData.inputDimensionList.map(v => v.fullName);
  }

  /**
   * Gets the interpolated data for the given dimension and channels.
   * @param channelInterpolator The channel interpolator.
   * @param dimensionData The dimension data.
   * @param scalarChannelNames The scalar channel names.
   * @returns The interpolated data.
   */
  private getInterpolatedChannelData(
    channelInterpolator: ChannelInterpolator, dimensionData: DimensionEvaluationData, scalarChannelNames: string[]) {
    if (!this.sharedState.dimensions) {
      throw new Error('No dimensions supplied.');
    }

    let rCurrentPoint = this.sharedState.dimensions.rCoordinates;
    let rEvaluationValues = dimensionData.jobsWithSweep.map(sweep => sweep.normalizedSubSweepIndex);
    let setOfPoints = rCurrentPoint.slice().map(x => [x]);
    setOfPoints[this.dimensionIndex] = [...rEvaluationValues];
    let interpolationCoordinates = InterpolationCoordinatesFactory.fromArray(setOfPoints, this.dimensionIndex);
    return channelInterpolator.execute(scalarChannelNames, interpolationCoordinates);
  }
}

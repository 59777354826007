<div id="view-job-results-page">
  <div class="container-fluid">
    <p>
      <button (click)="goBack()" class="btn btn-secondary"><i class="fa fa-caret-left"></i>Back</button>
    </p>
    <cs-error-message [message]="errorMessage"></cs-error-message>
    @if (!jobs && !errorMessage) {
      <cs-loader id="chart-loader"></cs-loader>
    }

    @if (jobs && jobs.length === 0) {
      <p class="last-item">
        No jobs found to display.
      </p>
    }
    <pre id="job-dashboard-id" class="raw-json debug-information">{{dashboardId}}</pre>
  </div>

  @if (jobs) {
    <cs-view-job-results [jobs]="jobs" [navigationStationPrefix]="dashboardId"></cs-view-job-results>
  }
</div>

import {Injectable} from '@angular/core';
import {DialogManager, IDialog, DialogBase} from '../../../common/dialogs/dialog-manager.service';
import {ListFilterData} from '../../../../generated/api-stubs';
import {StudyInput} from '../../../worksheets/study-input';

@Injectable()
export class ConfigLoaderDialog {
  constructor(
    private dialogManager: DialogManager
  ){}

  public async loadConfig(
    configType: string,
    simVersion: string,
    subTreePath?: string,
    initialFilter?: ListFilterData,
    returnReferenceOnly: boolean = false,
    allowExplicitNothing: boolean = false): Promise<ConfigLoaderResult> {

    let result = await this.dialogManager.showSecondaryDialog(
      new ConfigLoaderDialogData(
        configType,
        simVersion,
        subTreePath,
        initialFilter,
        returnReferenceOnly,
        allowExplicitNothing));

    return result;
  }
}

export class ConfigLoaderDialogData extends DialogBase<ConfigLoaderResult> implements IDialog {
  constructor(
    public readonly configType: string,
    public readonly simVersion: string,
    public readonly subTreePath: string,
    public readonly initialFilter: ListFilterData,
    public readonly returnReferenceOnly: boolean,
    public readonly allowExplicitNothing: boolean) {
    super();
  }
}

export interface ConfigLoaderResult {
  userId: string;
  configId: string;
  config: StudyInput;
}

import { ExplorationSubSweepDataType } from './exploration-sub-sweep';
import { ExplorationMapValues } from './exploration-map-values';


/**
 * Gets the data type of the sub-sweep.
 */
export class GetExplorationSubSweepDataType {

  /**
   * Gets the data type of the sub-sweep.
   * @param values The values of the sub-sweep.
   * @returns The data type of the sub-sweep.
   */
  public static execute(values: ExplorationMapValues): ExplorationSubSweepDataType {
    if (values.length === 0) {
      return ExplorationSubSweepDataType.Numeric;
    }

    const firstValue = values[0];

    if (typeof firstValue === 'number') {
      return ExplorationSubSweepDataType.Numeric;
    }

    if (typeof firstValue === 'boolean') {
      return ExplorationSubSweepDataType.Boolean;
    }

    return ExplorationSubSweepDataType.Other;
  }
}

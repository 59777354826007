import { Injectable } from '@angular/core';
import { IDialogSessionFactory, IDialogActions } from '../../common/dialogs/dialog-component-base';
import { GetFriendlyErrorAndLog } from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import { EncryptionStub, QueryEncryptedDataQueryResult, QueryEncryptedDataTenantResult } from '../../../generated/api-stubs';
import { DownloadReEncryptedConfigDialogData, DownloadReEncryptedConfigResult } from './download-re-encrypted-config-dialog.service';
import { DownloadReEncryptedConfig } from '../download-re-encrypted-config.service';
import { AuthenticationService } from '../../identity/state/authentication.service';


@Injectable()
export class DownloadReEncryptedConfigDialogSessionFactory implements IDialogSessionFactory<DownloadReEncryptedConfigDialogData, DownloadReEncryptedConfigResult>{

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly encryptionStub: EncryptionStub,
    private readonly downloadReEncryptedConfig: DownloadReEncryptedConfig,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  public create(dialogActions: IDialogActions<DownloadReEncryptedConfigResult>, dialog: DownloadReEncryptedConfigDialogData) {
    return new DownloadReEncryptedConfigDialogSession(
      dialogActions,
      dialog,
      this.authenticationService,
      this.encryptionStub,
      this.downloadReEncryptedConfig,
      this.getFriendlyErrorAndLog);
  }
}

export class DownloadReEncryptedConfigDialogSession {

  constructor(
    private readonly dialogActions: IDialogActions<DownloadReEncryptedConfigResult>,
    public readonly dialog: DownloadReEncryptedConfigDialogData,
    private readonly authenticationService: AuthenticationService,
    private readonly encryptionStub: EncryptionStub,
    private readonly downloadReEncryptedConfig: DownloadReEncryptedConfig,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  public get isLoading() {
    return !this.errorMessage && !this.queryResult;
  }

  public get hasResults() {
    return this.queryResult && this.queryResult.keys.length > 0;
  }

  public errorMessage: string;

  public queryResult: QueryEncryptedDataQueryResult;

  public async load() {
    try {
      const userData = this.authenticationService.userDataSnapshot;

      const config = await this.dialog.config.getMaterialized();

      this.queryResult = await this.encryptionStub.query(
        userData.tenant,
        {
          data: config.data,
          simVersion: config.simVersion,
        });
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async downloadConfigAs(key: QueryEncryptedDataTenantResult) {
    try {
      this.errorMessage = undefined;

      const userData = this.authenticationService.userDataSnapshot;
      const config = await this.dialog.config.getMaterialized();
      await this.downloadReEncryptedConfig.execute(userData.tenant, key.tenantShortName, config);

      this.dialogActions.dismiss();
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }
}

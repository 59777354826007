import {Injectable} from '@angular/core';
import {JobViewModel} from '../jobs/job-results/job-view-model';

@Injectable()
export class CarConfigValidationStagingArea {

  private studyJobs: CachedCarConfigValidation[] = [];

  public add(
    jobViewModel: JobViewModel,
    editorValue: any){
    this.studyJobs.unshift(new CachedCarConfigValidation(jobViewModel, editorValue));
  }

  public get(){
    return [...this.studyJobs];
  }

  public clear(){
    this.studyJobs = [];
  }

  public count(){
    return this.studyJobs.length;
  }
}

export class CachedCarConfigValidation {

  constructor(
    public readonly jobViewModel: JobViewModel,
    public readonly editorValue: any){
  }
}

import {Injectable} from '@angular/core';
import {LogError} from './log-error.service';
import {ServiceOnInit} from './service-on-init';
import {GetSimVersionBase} from './get-sim-version-base';
import {LocalUserSettingsManager} from './local-user-settings-manager';
import { AuthenticationService } from '../identity/state/authentication.service';

@Injectable()
export class GetUserSimVersion extends GetSimVersionBase implements ServiceOnInit {

  constructor(
    logError: LogError,
    authenticationService: AuthenticationService,
    private readonly localUserSettingsManager: LocalUserSettingsManager) {
    super(logError, authenticationService);
  }

  protected loadSimVersion(): Promise<string> {
    return Promise.resolve(this.localUserSettingsManager.state.userSimVersion);
  }
}

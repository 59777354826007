import * as d3 from '../../d3-bundle';
import { SourceData } from './types/source-data';
import { IPopulatedMultiPlotLayout } from './types/i-populated-multi-plot-layout';
import { ProcessedPlotResult } from './types/processed-plot-result';
import { ProcessedPlot } from './types/processed-plot';
import { ProcessedPlotSource } from './types/processed-plot-source';
import { ProcessedPlotSourceChannel } from './types/processed-plot-source-channel';
import { DisplayableError } from '../../displayable-error';
import { CreatePlotsFromDiagonals } from './create-plots-from-diagonals';


/**
 * Create the set of processed plots which should be rendered, taking into account
 * options around stacking diagonal plots.
 */
export class CreateProcessedPlots {

  constructor(
    private readonly createPlotsFromDiagonals: CreatePlotsFromDiagonals) {
  }

  /**
   * Create the set of processed plots which should be rendered, taking into account
   * options around stacking diagonal plots.
   *
   * Processed plots contain all the information necessary to render a single plot
   * within the chart, including column and row information, and utility methods such
   * as for finding the closest data point to a given coordinate in the plot.
   * @param layout The populated layout.
   * @param sourceData The set of data sources.
   * @returns The set of processed plots.
   */
  public execute(
    layout: IPopulatedMultiPlotLayout,
    sourceData: ReadonlyArray<SourceData>): ProcessedPlotResult {

    let plots: ProcessedPlot[] = [];

    // If the number of rows and columns are multiples of each other, we can plot diagonals.
    let canPlotDiagonals
      = (layout.rows.length % layout.columns.length) === 0
      || (layout.columns.length % layout.rows.length) === 0;

    // If we can plot diagonals but the number of rows and columns are not the same, then
    // we repeat the diagonal plots in the direction of the longer side. Each set of plots
    // within a single repetition is a diagonal plot set.
    //
    // For example the following 6 column by 3 row chart has 2 diagonal plot sets, each containing
    // three plots:
    //
    //     │  ▉  ▉
    //     │ ▉  ▉
    //     │▉  ▉
    //     └──────
    //
    // For more information about diagonal plots, see `CreatePlotsFromDiagonals`.
    let plotsInDiagonalPlotSet = 0;
    let diagonalPlotSets: ProcessedPlot[][] = [];
    if (canPlotDiagonals) {
      // We know the rows and columns are multiples of each other, so the smallest side length is the number of plots
      // in each plot set.
      plotsInDiagonalPlotSet = Math.min(layout.rows.length, layout.columns.length);

      // And the number of plot sets is the largest side length divided by the number of plots in each plot set.
      let plotSetCount = Math.max(layout.rows.length, layout.columns.length) / plotsInDiagonalPlotSet;

      // Initialize the collection of all diagonal plot sets with an empty array for each plot set.
      diagonalPlotSets = d3.range(plotSetCount).map(_ => []);
    }

    let columnIndex = -1;

    // For each column...
    for (let column of layout.columns) {
      ++columnIndex;

      // Skip hidden columns.
      if (column.transient.isHidden) {
        continue;
      }

      let rowIndex = -1;

      // For each row...
      for (let row of layout.rows) {
        ++rowIndex;

        // Skip hidden rows.
        if (row.transient.isHidden) {
          continue;
        }

        // Get the index of the next plot.
        let plotIndex = plots.length;

        // Create the array that will hold the processed sources for this plot.
        let sources: ProcessedPlotSource[] = [];

        // For each source...
        for (let sourceIndex = 0; sourceIndex < sourceData.length; ++sourceIndex) {

          let channels: ProcessedPlotSourceChannel[] = [];

          // Get the source.
          let source = sourceData[sourceIndex];

          // Skip hidden sources.
          if (!source || !source.isVisible) {
            continue;
          }

          // For each processed X channel...
          for (let xChannel of column.processed.channels) {

            // Skip hidden channels.
            if (!xChannel.isVisible) {
              continue;
            }

            // Get the data source for the X channel.
            let xDataSource = xChannel.sources[sourceIndex];

            // Skip channels with no X data.
            if (!xDataSource || !xDataSource.data || !xDataSource.data.length) {
              continue;
            }

            // For each processed Y channel...
            for (let yChannel of row.processed.channels) {

              // Skip hidden channels.
              if (!yChannel.isVisible) {
                continue;
              }

              // Get the data source for the Y channel.
              let yDataSource = yChannel.sources[sourceIndex];

              // Skip channels with no Y data.
              if (!yDataSource || !yDataSource.data || !yDataSource.data.length) {
                continue;
              }

              // For channels with `renderOnlyForDomain` set, we've already filtered out row channels which definitely
              // shouldn't be rendered because their domain isn't shown in any column (see `ProcessLayoutSide`).
              // However we still need to filter out channels in the individual plots whose domain isn't shown for that
              // particular column.
              if (yChannel.renderOnlyForDomain) {
                let domainChannel = column.processed.channels.find(v => v.name === yChannel.renderOnlyForDomain);
                if (!domainChannel || !domainChannel.isVisible) {
                  // Skip if the domain isn't in the column, or if it isn't visible.
                  continue;
                }
              }

              // Make sure the X data and Y data have the same number of points.
              // We throw here, rather than skipping the channel, because it indicates a problem with the source data
              // that the user should know about.
              if (xDataSource.data.length !== yDataSource.data.length) {
                throw new DisplayableError(`Data length of ${xChannel.name} (${xDataSource.data.length}) did not match length of ${yChannel.name} (${yDataSource.data.length}).`);
              }

              // Add the processed channel to the list of channels for the current plot.
              channels.push(
                new ProcessedPlotSourceChannel(
                  plotIndex,
                  sourceIndex,
                  xChannel,
                  yChannel));
            }
          }

          // Add the data source to the list of sources for the current plot.
          sources.push(new ProcessedPlotSource(channels));
        }

        let processedPlot = new ProcessedPlot(
          plotIndex,
          column,
          row,
          sources);

        // Add the processed plot to the list of plots.
        plots.push(processedPlot);

        // If we can plot diagonals and this plot is on the diagonal...
        if (canPlotDiagonals && (columnIndex % plotsInDiagonalPlotSet) === (rowIndex % plotsInDiagonalPlotSet)) {
          // Get the set index this plot belongs to...
          let setIndex = Math.floor(Math.max(columnIndex, rowIndex) / plotsInDiagonalPlotSet);

          // ...and add it to the diagonal plot set.
          diagonalPlotSets[setIndex].push(processedPlot);
        }
      }
    }

    // If we can plot diagonals, and the user has chosen one of the diagonal stacking options then
    // we return the subset of plots that should be displayed (this could involve merging plots).
    if (canPlotDiagonals && (layout.stackDiagonalsHorizontally || layout.stackDiagonalsVertically)) {
      return this.createPlotsFromDiagonals.execute(layout, sourceData, diagonalPlotSets);
    }

    // If we're not returning diagonals, just return all the plots.
    return new ProcessedPlotResult(plots, layout.columns, layout.rows);
  }
}

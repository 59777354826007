@if (shouldDisplayEnableNotificationsButton) {
  <span>
    <a href="javascript:void(0)" (click)="enableNotifications()">Enable Notifications</a>
  </span>
}
@if (areNotificationsEnabled) {
  <span>
    Notifications Enabled
  </span>
}

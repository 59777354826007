import {RuleContextBase} from './rule-context-base';

export class ConfigContext extends RuleContextBase {
  public get configType(): string {
    return this.type;
  }

  public forEachValue(path: string, callback: NodeCallback): void {
    let values = GetJsonValues.forPath(this.data, path, true);
    for (const value of values) {
      callback(value);
    }
  }

  public getValues(path: string): ConfigNode[] {
    return GetJsonValues.forPath(this.data, path, true);
  }

  public getValue(path: string): ConfigNode {
    let result = GetJsonValues.forPath(this.data, path, true);
    if(result.length === 0){
      return undefined;
    }

    if(result.length > 1){
      throw new Error('Multiple results found for path: ' + path);
    }

    return result[0];
  }

  public recurseNodes(callback: NodeCallback) {
    TraverseJsonNodes.execute(this.data, callback);
  }

  public recurseNodesAtPath(path: string, callback: NodeCallback) {
    let values = GetJsonValues.forPath(this.data, path, true);
    for (const value of values) {
      TraverseJsonNodes.execute(value, callback);
    }
  }
}

type NodeCallback = (value: ConfigNode) => void;

export class TraverseJsonNodes {
  public static execute(root: any, callback: NodeCallback): void {
    this.inner(root, '', callback);
  }

  private static inner(root: any, path: string, callback: NodeCallback): void {
    if(!root){
      return;
    }

    const addPath = (path: string, pathItem: string) => path + (path.length ? '.' : '') + pathItem;

    callback(new ConfigNode(path, root));

    if (Array.isArray(root)) {
      for (let child of root) {
        this.inner(child, path, callback);
      }
    } else if (typeof root === 'object') {
      for(let key of Object.keys(root)){
        let nextToken = root[key];
        this.inner(nextToken, addPath(path, key), callback);
      }
    }
  }
}

export class GetJsonValues {
  public static forPath(root: any, path: string, arrayIsValid: boolean): ConfigNode[] {
    let result: ConfigNode[] = [];
    let pathElements = path.split('.');
    GetJsonValues.inner(root, '', pathElements, result, arrayIsValid);
    return result;
  }

  private static inner(root: any, path: string, pathElements: ReadonlyArray<string>, result: ConfigNode[], arrayIsValid: boolean) {

    if(!root){
      return;
    }

    const addPath = (path: string, pathItem: string) => path + (path.length ? '.' : '') + pathItem;

    if (Array.isArray(root)) {
      if (pathElements.length === 0 && arrayIsValid) {
        result.push(new ConfigNode(path, root));
      } else {
        let index = 0;
        for (let child of root) {
          this.inner(child, addPath(path, '' + index), pathElements, result, arrayIsValid);
          ++index;
        }
      }
    } else if (typeof root === 'object') {
      if (pathElements.length === 0) {
        result.push(new ConfigNode(path, root));
      } else {
        let pathItems = pathElements[0].split('|');
        for(let pathItem of pathItems){
          let nextPathElements = pathElements.slice(1);
          let nextToken = root[pathItem];
          this.inner(nextToken, addPath(path, pathItem), nextPathElements, result, arrayIsValid);
        }
      }
    } else if (pathElements.length === 0) {
      result.push(new ConfigNode(path, root));
    }
  }
}

export class ConfigNode {
  constructor(
    public readonly path: string,
    public readonly value: any){
  }
}

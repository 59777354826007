import {Injectable} from '@angular/core';
import {AbstractControl, ValidatorFn, Validators} from '@angular/forms';
import {isNumber} from '../is-number';

@Injectable()
export class CanopyValidators {

  public static channelList(control: AbstractControl): { channelList: boolean } {
    if (!control || !control.value) {
      return undefined; // We do not cover the "required" case.
    }

    // This RegEx should match exploration schema.
    let value = control.value;
    if (!/^[A-Za-z0-9\-_\s,%\*]*$/.test(value)) {
      return { channelList: true };
    }

    return undefined;
  }

  public static email(control: AbstractControl): { email: boolean } {
    if (!control || !control.value) {
      return undefined; // We do not cover the "required" case.
    }

    let value = control.value;
    if (value.length < 3 || value.length > 256) {
      return CanopyValidators.getEmailResult(true);
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      return CanopyValidators.getEmailResult(true);
    }

    return undefined;
  }

  public static customPropertyName(control: AbstractControl): { customPropertyName: boolean } {
    return CanopyValidators.customPropertyNameInner(control, false);
  }

  public static customPropertyNameWithWildcards(control: AbstractControl): { customPropertyName: boolean } {
    return CanopyValidators.customPropertyNameInner(control, true);
  }

  public static customPropertyNameInner(control: AbstractControl, allowWildcard: boolean = false): { customPropertyName: boolean } {
    if (!control || !control.value) {
      return undefined; // We do not cover the "required" case.
    }

    let value = control.value;

    if(allowWildcard){
      if (!/^[a-zA-Z0-9-\*\.]+$/.test(value)) {
        return { customPropertyName: true };
      }
    } else{
      if (!/^[a-zA-Z0-9-\.]+$/.test(value)) {
        return { customPropertyName: true };
      }
    }

    return undefined;
  }

  public static configPath(control: AbstractControl): { configPath: boolean } {
    if (!control || !control.value) {
      return undefined; // We do not cover the "required" case.
    }

    // This RegEx should match exploration schema.
    let value = control.value;
    if (!/^(([\w_]+))(\.[\w_]+(\[\d+\])*)*$/.test(value)) {
      return { configPath: true };
    }

    return undefined;
  }

  public static dateTime(control: AbstractControl): { dateTime: boolean } {
    if (!control || !control.value) {
      return undefined; // We do not cover the "required" case.
    }

    // This RegEx should match exploration schema.
    let value = control.value;
    if (!/^(?:[1-9]\d{3}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1\d|2[0-8])|(?:0[13-9]|1[0-2])-(?:29|30)|(?:0[13578]|1[02])-31)|(?:[1-9]\d(?:0[48]|[2468][048]|[13579][26])|(?:[2468][048]|[13579][26])00)-02-29)T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:Z|[+-][01]\d:[0-5]\d)$/.test(value)) {
      return { dateTime: true };
    }

    return undefined;
  }

  public static numeric(control: AbstractControl): { numeric: boolean } {
    if (!control || !control.value) {
      return undefined; // We do not cover the "required" case.
    }

    // This RegEx should match exploration schema.
    let value = control.value;
    if (!isNumber(value)) {
      return { numeric: true };
    }

    return undefined;
  }

  public static usernameValidators: ValidatorFn[] = [
    Validators.minLength(2),
    Validators.maxLength(200)
  ];

  public static tenantShortNameValidators: ValidatorFn[] = [
    Validators.minLength(2),
    Validators.maxLength(200)
  ];

  public static databaseIdValidators: ValidatorFn[] = [
    Validators.maxLength(128)
  ];

  public static regionIdValidators: ValidatorFn[] = [
    Validators.maxLength(32)
  ];

  public static emailValidators: ValidatorFn[] = [
    (c: AbstractControl) => CanopyValidators.email(c),
    Validators.maxLength(200)
  ];

  public static passwordValidators: ValidatorFn[] = [
    Validators.minLength(6),
    Validators.maxLength(100)
  ];

  public static tenantNameValidators: ValidatorFn[] = [
    Validators.maxLength(50)
  ];

  public static configNameValidators: ValidatorFn[] = [
    Validators.maxLength(100)
  ];

  public static configNotesValidators: ValidatorFn[] = [
    Validators.maxLength(2000)
  ];

  public static channelNameValidators: ValidatorFn[] = [
    Validators.maxLength(100)
  ];

  public static configContentValidators: ValidatorFn[] = [
    Validators.minLength(2),
    Validators.maxLength(200000)
  ];

  public static customPropertyNameValidators: ValidatorFn[] = [
    Validators.maxLength(50),
    (c: AbstractControl) => CanopyValidators.customPropertyName(c)
  ];

  public static customPropertyNameWithWildcardsValidators: ValidatorFn[] = [
    Validators.maxLength(50),
    (c: AbstractControl) => CanopyValidators.customPropertyNameWithWildcards(c)
  ];

  public static customPropertyValueValidators: ValidatorFn[] = [
    Validators.maxLength(200)
  ];

  public static configPathValidators: ValidatorFn[] = [
    (c: AbstractControl) => CanopyValidators.configPath(c),
  ];

  private static getEmailResult(value: boolean): { email: boolean } {
    return {
      email: value
    };
  }
}

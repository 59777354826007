<div class="container-fluid" id="generic-error-page">
  <div>
    <div class="list-card">
      <div class="list-card-body">
        <div class="list-card-padded">
          <p class="generic-error-message">
            An unexpected error occurred :(
          </p>

          <cs-form-error-message></cs-form-error-message>
        </div>
      </div>
    </div>
  </div>
</div>


import {ConfigTypeState, DocumentSubType} from '../../../generated/api-stubs';

function toWords(input: string){
  let result = input.replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, ' $1');
  result = result.charAt(0).toUpperCase() + result.slice(1);
  return result;
}

export class ConfigType {
  constructor(public readonly singularKey: DocumentSubType,
              public readonly pluralKey: string,
              public readonly name: string,
              public readonly titleName: string,
              public readonly icon?: string,
              public readonly state: ConfigTypeState = ConfigTypeState.disabled) {
  }

  public copy(state: ConfigTypeState){
    return new ConfigType(
      this.singularKey,
      this.pluralKey,
      this.name,
      this.titleName,
      this.icon,
      state);
  }
}

export const CarConfigType = new ConfigType(
  DocumentSubType.car,
  'cars',
  'car',
  'Car',
  'fa-car');

export const TrackConfigType = new ConfigType(
  DocumentSubType.track,
  'tracks',
  'track',
  'Track',
  'fa-flag-checkered');

export const WeatherConfigType = new ConfigType(
  DocumentSubType.weather,
  'weather',
  'weather',
  'Weather',
  'fa-sun-o');

export const ExplorationConfigType = new ConfigType(
  DocumentSubType.exploration,
  'explorations',
  'exploration',
  'Exploration',
  'fa-sliders');

export const Virtual4PostOptionsConfigType = new ConfigType(
  DocumentSubType.virtual4PostOptions,
  'virtual4PostOptions',
  'virtual 4 post rig options',
  'Virtual 4 Post Rig Options',
  'fa-gears');

export const BankedLimitSimOptionsConfigType = new ConfigType(
  DocumentSubType.bankedLimitSimOptions,
  'bankedLimitSimOptions',
  'banked limit sim options',
  'Banked Limit Sim Options',
  'fa-gears');

export const LimitSimOptionsConfigType = new ConfigType(
  DocumentSubType.limitSimOptions,
  'limitSimOptions',
  'limit sim options',
  'Limit Sim Options',
  'fa-gears');

export const SubLimitSimOptionsConfigType = new ConfigType(
  DocumentSubType.subLimitSimOptions,
  'subLimitSimOptions',
  'sub limit sim options',
  'Sub Limit Sim Options',
  'fa-gears');

export const CorneringSimOptionsConfigType = new ConfigType(
  DocumentSubType.corneringSimOptions,
  'corneringSimOptions',
  'cornering sim options',
  'Cornering Sim Options',
  'fa-gears');

export const ThermalReplayOptionsConfigType = new ConfigType(
  DocumentSubType.thermalReplayOptions,
  'thermalReplayOptions',
  'thermal replay options',
  'Thermal Replay Options',
  'fa-gears');

export const ChannelInferenceOptionsConfigType = new ConfigType(
  DocumentSubType.channelInferenceOptions,
  'channelInferenceOptions',
  'channel inference options',
  'Channel Inference Options',
  'fa-gears');

export const DragSimOptionsConfigType = new ConfigType(
  DocumentSubType.dragSimOptions,
  'dragSimOptions',
  'drag sim options',
  'Drag Sim Options',
  'fa-gears');

export const MockSimOptionsConfigType = new ConfigType(
  DocumentSubType.mockSimOptions,
  'mockSimOptions',
  'mock sim options',
  'Mock Sim Options',
  'fa-gears');

export const PacejkaTyresConfigType = new ConfigType(
  DocumentSubType.pacejkaTyres,
  'pacejkaTyres',
  'pacejka tyres',
  'Pacejka Tyres',
  'fa-gears');

export const OvertakingConfigType = new ConfigType(
  DocumentSubType.overtaking,
  'overtaking',
  'overtaking',
  'Overtaking',
  'fa-random');

export const DriveCycleConfigType = new ConfigType(
  DocumentSubType.driveCycle,
  'driveCycles',
  'drive cycle',
  'Drive Cycle',
  'fa-tachometer');

export const UserMathsConfigType = new ConfigType(
  DocumentSubType.userMaths,
  'userMaths',
  'user maths',
  'User Maths',
  'fa-code-fork  fa-rotate-90');

export const ConstraintsConfigType = new ConfigType(
  DocumentSubType.constraints,
  'constraints',
  'constraints',
  'Constraints',
  'fa-code-fork fa-rotate-270');

export const TelemetryConfigType = new ConfigType(
  DocumentSubType.telemetry,
  'telemetry',
  'telemetry',
  'Telemetry',
  'fa-wifi');

export const FilesConfigType = new ConfigType(
  DocumentSubType.files,
  'files',
  'files',
  'Files',
  'fa-files-o');

export const AircraftConfigType = new ConfigType(
  DocumentSubType.aircraft,
  'aircraft',
  'aircraft',
  'Aircraft',
  'fa-plane');

export const IliadBoatConfigType = new ConfigType(
  DocumentSubType.iliadBoat,
  'iliadBoats',
  'iliad boat',
  'Iliad Boat',
  'fa-ship');

export const IliadCollocationOptionsConfigType = new ConfigType(
  DocumentSubType.iliadCollocationOptions,
  'iliadCollocationOptions',
  'iliad collocation options',
  'Iliad Collocation Options',
  'fa-gears');

export const IliadVppOptionsConfigType = new ConfigType(
  DocumentSubType.iliadVppOptions,
  'iliadVppOptions',
  'iliad VPP options',
  'Iliad VPP Options',
  'fa-gears');

export const WorksheetConfigType = new ConfigType(
  DocumentSubType.worksheet,
  'worksheets',
  'worksheet',
  'Worksheet',
  'fa-th');

// This is a bit cheeky, but it is useful in certain places..
export const StudyDocumentType = new ConfigType(
  <DocumentSubType>'study',
  'studies',
  'study',
  'Study');

// This determines the config display order.
export const AllInputConfigTypes = [
  CarConfigType,
  AircraftConfigType,
  IliadBoatConfigType,
  WeatherConfigType,
  TrackConfigType,
  DriveCycleConfigType,
  TelemetryConfigType,
  FilesConfigType,
  Virtual4PostOptionsConfigType,
  ChannelInferenceOptionsConfigType,
  DragSimOptionsConfigType,
  MockSimOptionsConfigType,
  LimitSimOptionsConfigType,
  SubLimitSimOptionsConfigType,
  CorneringSimOptionsConfigType,
  BankedLimitSimOptionsConfigType,
  ThermalReplayOptionsConfigType,
  PacejkaTyresConfigType,
  IliadCollocationOptionsConfigType,
  IliadVppOptionsConfigType,
  OvertakingConfigType,
  UserMathsConfigType,
  ConstraintsConfigType,
  ExplorationConfigType, // Keep this last.
];

export const AllConfigTypes: ConfigType[] = [
  ...AllInputConfigTypes,
];

export const AllDocumentTypes: ConfigType[] = [
  ...AllConfigTypes,
  StudyDocumentType,
  WorksheetConfigType
];

const ConfigTypeSortRank = AllConfigTypes.reduce(
  (p, c, i) => {
    p[c.singularKey as string] = i + 1;
    return p;
  },
  {} as { [key: string]: number });

export function sortByConfigType<T>(items: T[], getConfigType: (item: T) => DocumentSubType): void {
  if(!items){
    return;
  }

  items.sort((a, b) => {
    let aIndex = ConfigTypeSortRank[getConfigType(a)] || 10000;
    let bIndex = ConfigTypeSortRank[getConfigType(b)] || 10000;
    if(aIndex < bIndex){
      return -1;
    } else if(bIndex < aIndex){
      return 1;
    }
    return 0;
  });
}

export const CONFIG_TYPE_DATA_KEY = 'configType';
export const IS_STAGED_DATA_KEY = 'isStaged';
export const IS_DEFAULT_DATA_KEY = 'isDefault';

export const STANDARD_CONFIG_TYPE_SUFFIXES = [
  'CustomView',
  'Filter',
  'Viewer'
];

// Exported and not a lambda for Angular AoT.
export function getLowerSingularKey(current: any, value: ConfigType): any {
  current[value.singularKey.toLowerCase()] = value;
  return current;
}

// Exported and not a lambda for Angular AoT.
export function getLowerPluralKey(current: any, value: ConfigType): any {
  current[value.pluralKey.toLowerCase()] = value;
  return current;
}

export class ConfigTypeLookup {
  private static singularLookup = AllDocumentTypes.reduce(getLowerSingularKey, {});
  private static pluralLookup = AllDocumentTypes.reduce(getLowerPluralKey, {});

  public static get(configType: string | undefined): ConfigType | undefined{
    if(!configType){
      return undefined;
    }

    if(STANDARD_CONFIG_TYPE_SUFFIXES.some(v => configType.endsWith(v))){
      let asWords = toWords(configType);
      return new ConfigType(
        <DocumentSubType>configType,
        configType, // Keep this singular for viewers
        asWords.toLowerCase(),
        asWords);
    }

    return ConfigTypeLookup.singularLookup[configType.toLowerCase()]
        || ConfigTypeLookup.pluralLookup[configType.toLowerCase()];
  }
}


export const NO_PRIMARY_DOMAIN_SPECIFIED_ERROR_MESSAGE = 'No primary domain specified.';

/**
 * Determines if the diagonals of a grid can be stacked.
 */
export class CanStackDiagonals {

  /**
   * Determines if the diagonals of a grid can be stacked.
   * @param rowCount The number of rows in the grid.
   * @param columnCount The number of columns in the grid.
   * @returns True if the diagonals can be stacked; otherwise false/
   */
  public static execute(rowCount: number, columnCount: number): boolean {
    if (rowCount !== 1 && columnCount !== 1) {
      if (rowCount === columnCount) {
        return true;
      } else if ((rowCount % columnCount) === 0) {
        return true;
      } else if ((columnCount % rowCount) === 0) {
        return true;
      }
    }

    return false;
  }
}

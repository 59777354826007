import * as d3 from '../../../d3-bundle';
import { ILegendList } from '../../components/legend-renderer';
import { definedValues } from '../../../defined-values';
import { ProcessedMultiPlotChannel } from './processed-multi-plot-channel';
import { SideType } from './side-type';

/**
 * A processed multi plot side. We call something that is either a row or a column a side.
 */

export class ProcessedMultiPlotSide implements ILegendList {
  private _plotSize: number = 0;
  private _size: number = 0;
  private _offset: number = 0;
  private readonly _scale: d3.ScaleLinear<number, number>;

  constructor(
    public readonly channels: ReadonlyArray<ProcessedMultiPlotChannel>,
    public readonly sideType: SideType) {
    this._scale = d3.scaleLinear<number, number>();
  }

  /**
   * The D3 linear scale for the side.
   */
  public get scale(): d3.ScaleLinear<number, number> {
    return this._scale;
  }

  /**
   * The size of the plot in the dimension of the side.
   * This excludes the space between plots.
   */
  public get plotSize(): number {
    return this._plotSize;
  }

  /**
   * The total size of the side.
   */
  public get size(): number {
    return this._size;
  }

  /**
   * The offset of the side.
   */
  public get offset(): number {
    return this._offset;
  }

  /**
   * The minimum value of all the channels in the side.
   */
  public get minimum(): number {
    return d3.minStrict(definedValues(this.channels.map(v => v.minimum)));
  }

  /**
   * The maximum value of all the channels in the side.
   */
  public get maximum(): number {
    return d3.maxStrict(definedValues(this.channels.map(v => v.maximum)));
  }

  /**
   * The minimum value of all the channels in the side that are visible.
   * @param sourcesVisibility The visibility of the sources.
   * @returns The minimum value.
   */
  public getVisibleMinimum(sourcesVisibility: ReadonlyArray<boolean>): number {
    return d3.minStrict(definedValues(this.channels.filter(v => v.isVisible).map(v => v.getVisibleMinimum(sourcesVisibility))));
  }

  /**
   * The maximum value of all the channels in the side that are visible.
   * @param sourcesVisibility The visibility of the sources.
   * @returns The maximum value.
   */
  public getVisibleMaximum(sourcesVisibility: ReadonlyArray<boolean>): number {
    return d3.maxStrict(definedValues(this.channels.filter(v => v.isVisible).map(v => v.getVisibleMaximum(sourcesVisibility))));
  }

  /**
   * Returns true if every visible channel has its axis reversed.
   */
  public get reverseAxis(): boolean {
    let visibleChannels = this.channels.filter(v => v.isVisible);
    return visibleChannels.length > 0 && visibleChannels.every(v => v.reverseAxis);
  }

  /**
   * Updates the size values of the side, and in turn the scales.
   * @param offset The offset of the side.
   * @param size The total size of the side.
   * @param spaceBetweenPlots The space between plots.
   */
  public updateSize(offset: number, size: number, spaceBetweenPlots: number) {
    this._offset = offset;
    this._size = size;
    this._plotSize = size - spaceBetweenPlots;
    if (this.sideType === SideType.row) {
      if (this.reverseAxis) {
        this.scale.range([0, this._plotSize]);
      } else {
        this.scale.range([this._plotSize, 0]);
      }
    } else {
      if (this.reverseAxis) {
        this.scale.range([this._plotSize, 0]);
      } else {
        this.scale.range([0, this._plotSize]);
      }
    }
  }
}

import * as d3 from '../../d3-bundle';
import { isDefaultUnits } from '../../is-default-units';
import { IViewerChannelData } from './viewer-channel-data';
import { Units } from '../../units';

/**
 * Scales a single channel or a group of channels together (e.g. converting `m` to `mm` or `km` to improve readability).
 */
export class AutoScaleChannelGroup {
  /**
   * Scales a single channel (e.g. converting `m` to `mm` or `km` to improve readability).
   * The channel data will be cloned before scaling is applied, and the original channel
   * will be updated with the new data.
   * @param channel The channel to scale.
   */
  public executeForChannel(channel: IChannelDataForSources) {
    this.execute({ channels: [channel] });
  }

  /**
   * Scales a set of channels together (e.g. converting `m` to `mm` or `km` to improve readability).
   * All channels in the group will be scaled by the same factor.
   * The channel data will be cloned before scaling is applied, and the original channels
   * will be updated with the new data.
   * @param side The group of channels to scale.
   */
  public execute(side: IChannelGroup) {

    // If the user has explicitly set units then we won't override them.
    let anyUserSpecifiedUnits = side.channels.map(v => v.isUserSpecifiedUnits).some(v => v);
    if (anyUserSpecifiedUnits) {
      return;
    }

    let dataMinimum = d3.minStrict(side.channels.map(c => d3.minStrict(c.sources.map(s => s.minimum))));
    let dataMaximum = d3.maxStrict(side.channels.map(c => d3.maxStrict(c.sources.map(s => s.maximum))));

    let format: ChannelFormat = {
      endOfRange: d3.maxStrict([Math.abs(dataMinimum), Math.abs(dataMaximum)]),
      exponent: 0,
      divisor: 1
    };

    if (format.endOfRange !== 0) {
      format.exponent = 3 * Math.floor(Math.log10(format.endOfRange) / 3);
      format.divisor = Math.pow(10, format.exponent);
    }

    for (let channel of side.channels) {
      let initialUnits = channel.baseUnits;
      if (isDefaultUnits(initialUnits)) {
        initialUnits = '()';
        // don't apply prefix to non-dimensional 'units'
        let channelMaximum = d3.maxStrict(channel.sources.map(s => s.maximum));
        if (format.exponent !== 0 && !isNaN(channelMaximum)) {
          channel.baseUnits = 'e' + format.exponent;
        } else {
          channel.baseUnits = '';
        }
      } else {
        // use d3 to find appropriate prefix for these values
        channel.baseUnits = d3.format('.0s')(format.divisor).slice(1, 2) + initialUnits;
      }

      // Ensure we are able to convert these new units to SI and back.
      Units.addConversion(channel.baseUnits, initialUnits, format.divisor, 0);

      channel.sources = channel.sources.map(
        v => !v || v.units === channel.baseUnits
          ? v
          : v.clone(v.data ? v.data.map(d => d / format.divisor) : undefined, channel.baseUnits));
    }
  }
}

/**
 * A channel to be scaled.
 */
interface IChannelDataForSources {

  /**
   * The base units for the channel.
   */
  baseUnits: string;

  /**
   * The sources for the channel.
   */
  sources: ReadonlyArray<IViewerChannelData>;

  /**
   * True if the user has explicitly set the units.
   */
  readonly isUserSpecifiedUnits: boolean;
}

/**
 * A group of channels to be scaled together.
 */
export interface IChannelGroup {

  /**
   * The channels to be scaled.
   */
  readonly channels: ReadonlyArray<IChannelDataForSources>;
}

/**
 * The format for a channel.
 */
interface ChannelFormat {

  /**
   * The end of the range for the channel.
   */
  endOfRange: number;

  /**
   * The exponent for the channel.
   */
  exponent: number;

  /**
   * The divisor for the channel.
   */
  divisor: number;
}

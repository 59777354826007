import {LazyLoaded} from './lazy-loaded';
import {
  SimType,
  GetStudyJobMetadataQueryResult, GetStudyJobQueryResult, GetStudyQueryResult,
  PostStudyInlineResult
} from '../../../../generated/api-stubs';
import {ScalarResultSet} from '../../studies/study-utilities.service';
import {Injectable} from '@angular/core';
import {LazyLoadedStudyJobFactory} from './lazy-loaded-study-job-factory';
import {LazyLoadedStudyJobWarningsFactory} from './lazy-loaded-study-job-warnings-factory';
import {LazyLoadedStudyJobScalarResultSetsFactory} from './lazy-loaded-study-job-scalar-result-sets-factory';
import {JobSource} from './job-source';
import {LazyLoadedJobNameFactory} from './lazy-loaded-study-job-name-factory';
import {LazyLoadedStudyNameFactory} from './lazy-loaded-study-name-factory';
import {LazyLoadedStudyMetadataFactory} from './lazy-loaded-study-metadata-factory';
import {LazyLoadedStudyJobMetadataFactory} from './lazy-loaded-study-job-metadata-factory';
import {LazyLoadedSimTypesFactory} from './lazy-loaded-sim-types.factory';
import {LazyLoadedStudyFactory} from './lazy-loaded-study-factory';
import {LazyStudyLoaders} from './lazy-study-loaders';
import {LazyLoadedStudyJobInfoFactory} from './lazy-loaded-study-job-info-factory';
import {LazyLoadedStudyJobDiagnosisFactory} from './lazy-loaded-study-job-diagnosis-factory';

@Injectable()
export class JobViewModelFactory {
  constructor(
    private readonly lazyLoadedStudyFactory: LazyLoadedStudyFactory,
    private readonly lazyLoadedStudyMetadataFactory: LazyLoadedStudyMetadataFactory,
    private readonly lazyLoadedStudyJobFactory: LazyLoadedStudyJobFactory,
    private readonly lazyLoadedStudyJobMetadataFactory: LazyLoadedStudyJobMetadataFactory,
    private readonly lazyLoadedStudyJobInfoFactory: LazyLoadedStudyJobInfoFactory,
    private readonly lazyLoadedStudyJobWarningsFactory: LazyLoadedStudyJobWarningsFactory,
    private readonly lazyLoadedStudyJobDiagnosisFactory: LazyLoadedStudyJobDiagnosisFactory,
    private readonly lazyLoadedStudyJobScalarResultSetsFactory: LazyLoadedStudyJobScalarResultSetsFactory,
    private readonly lazyLoadedStudyNameFactory: LazyLoadedStudyNameFactory,
    private readonly lazyLoadedJobNameFactory: LazyLoadedJobNameFactory,
    private readonly lazyLoadedSimTypesFactory: LazyLoadedSimTypesFactory){
  }

  public create(source: JobSource, simVersion: string, lazyStudyLoaders?: LazyStudyLoaders): JobViewModel{
    if(!lazyStudyLoaders){
      let study = this.lazyLoadedStudyFactory.create(source.tenantId, source.studyId, simVersion);
      let studyMetadata = this.lazyLoadedStudyMetadataFactory.create(study, source.tenantId, source.studyId);
      lazyStudyLoaders = new LazyStudyLoaders(study, studyMetadata);
    }

    return JobViewModel.create(
      simVersion,
      source,
      lazyStudyLoaders.study,
      lazyStudyLoaders.studyMetadata,
      this.lazyLoadedStudyJobFactory,
      this.lazyLoadedStudyJobMetadataFactory,
      this.lazyLoadedStudyJobInfoFactory,
      this.lazyLoadedStudyJobWarningsFactory,
      this.lazyLoadedStudyJobDiagnosisFactory,
      this.lazyLoadedStudyJobScalarResultSetsFactory,
      this.lazyLoadedStudyNameFactory,
      this.lazyLoadedJobNameFactory,
      this.lazyLoadedSimTypesFactory);
  }

  public createFromData(
    jobResult: GetStudyJobQueryResult | GetStudyJobMetadataQueryResult,
    studyResult: GetStudyQueryResult,
    jobName?: string,
    studyName?: string,
    inlineStudyResult?: PostStudyInlineResult): JobViewModel{

    const simVersion = studyResult.convertedSimVersion;

    let lazyStudyResult = this.lazyLoadedStudyFactory.create(
      studyResult.study.tenantId,
      studyResult.study.documentId,
      simVersion);

    let lazyStudyMetadataResult = this.lazyLoadedStudyMetadataFactory.create(
      lazyStudyResult,
      studyResult.study.tenantId,
      studyResult.study.documentId);

    let result = JobViewModel.create(
      simVersion,
      new JobSource(
        jobResult.studyJob.tenantId,
        studyResult.study.documentId,
        jobResult.studyJob.documentId,
        studyResult.study.name,
        jobResult.studyJob.name),
      lazyStudyResult,
      lazyStudyMetadataResult,
      this.lazyLoadedStudyJobFactory,
      this.lazyLoadedStudyJobMetadataFactory,
      this.lazyLoadedStudyJobInfoFactory,
      this.lazyLoadedStudyJobWarningsFactory,
      this.lazyLoadedStudyJobDiagnosisFactory,
      this.lazyLoadedStudyJobScalarResultSetsFactory,
      this.lazyLoadedStudyNameFactory,
      this.lazyLoadedJobNameFactory,
      this.lazyLoadedSimTypesFactory,
      inlineStudyResult);

    let fullJob = jobResult as GetStudyJobQueryResult;
    if(fullJob.studyJobInput){
      result.jobResult.value = fullJob;
    }

    if(studyResult.study.data && studyResult.study.data.definition){
      result.studyResult.value = studyResult;
    }

    result.jobMetadataResult.value = jobResult;
    result.studyMetadataResult.value = studyResult;

    if(jobName){
      result.jobName.value = jobName;
    }

    if(studyName){
      result.studyName.value = studyName;
    }

    return result;
  }
}

export class JobViewModel {

  public static create(
    simVersion: string,
    source: JobSource,
    studyResult: LazyLoaded<GetStudyQueryResult>,
    studyMetadataResult: LazyLoaded<GetStudyQueryResult>,
    lazyLoadedStudyJobFactory: LazyLoadedStudyJobFactory,
    lazyLoadedStudyJobMetadataFactory: LazyLoadedStudyJobMetadataFactory,
    lazyLoadedStudyJobInfoFactory: LazyLoadedStudyJobInfoFactory,
    lazyLoadedStudyJobWarningsFactory: LazyLoadedStudyJobWarningsFactory,
    lazyLoadedStudyJobDiagnosisFactory: LazyLoadedStudyJobDiagnosisFactory,
    lazyLoadedStudyJobScalarResultSetsFactory: LazyLoadedStudyJobScalarResultSetsFactory,
    lazyLoadedStudyNameFactory: LazyLoadedStudyNameFactory,
    lazyLoadedJobNameFactory: LazyLoadedJobNameFactory,
    lazyLoadedSimTypesFactory: LazyLoadedSimTypesFactory,
    inlineStudyResult?: PostStudyInlineResult): JobViewModel {

    let jobResult = lazyLoadedStudyJobFactory.create(
      source.tenantId,
      source.studyId,
      source.jobId,
      simVersion);

    let jobMetadataResult = lazyLoadedStudyJobMetadataFactory.create(
      jobResult,
      source.tenantId,
      source.studyId,
      source.jobId);

    let info = lazyLoadedStudyJobInfoFactory.create(
      jobMetadataResult,
      source.tenantId,
      source.studyId,
      source.jobId);

    let warnings = lazyLoadedStudyJobWarningsFactory.create(
      jobMetadataResult,
      source.tenantId,
      source.studyId,
      source.jobId);

    let diagnosis = lazyLoadedStudyJobDiagnosisFactory.create(
      jobMetadataResult,
      source.tenantId,
      source.studyId,
      source.jobId);

    let scalarResults = lazyLoadedStudyJobScalarResultSetsFactory.create(
      jobResult,
      source.tenantId,
      source.studyId,
      source.jobId);

    let jobName = lazyLoadedJobNameFactory.create(
      jobMetadataResult);

    let studyName = lazyLoadedStudyNameFactory.create(
      studyMetadataResult);

    let simTypes = lazyLoadedSimTypesFactory.create(
      jobResult,
      studyMetadataResult);

    let job = new JobViewModel(
      simVersion,
      source,
      studyResult,
      studyMetadataResult,
      jobResult,
      jobMetadataResult,
      scalarResults,
      info,
      warnings,
      diagnosis,
      studyName,
      jobName,
      simTypes,
      inlineStudyResult);

    return job;
  }

  constructor(
    public readonly simVersion: string,
    public readonly source: JobSource,
    public readonly studyResult: LazyLoaded<GetStudyQueryResult>,
    public readonly studyMetadataResult: LazyLoaded<GetStudyQueryResult>,
    public readonly jobResult: LazyLoaded<GetStudyJobQueryResult>,
    public readonly jobMetadataResult: LazyLoaded<GetStudyJobMetadataQueryResult>,
    public readonly scalarResults: LazyLoaded<ScalarResultSet[]>,
    public readonly info: LazyLoaded<string[]>,
    public readonly warnings: LazyLoaded<string[]>,
    public readonly diagnosis: LazyLoaded<string[]>,
    public readonly studyName: LazyLoaded<string>,
    public readonly jobName: LazyLoaded<string>,
    public readonly simTypes: LazyLoaded<SimType[]>,
    public readonly inlineStudyResult: PostStudyInlineResult){

    if(!studyName.isLoaded && this.source.studyName){
      this.studyName.value = this.source.studyName;
    }

    if(!jobName.isLoaded && this.source.jobName){
      this.jobName.value = this.source.jobName;
    }
  }

  public clone(jobName?: string, studyName?: string): JobViewModel{
    let result = new JobViewModel(
      this.simVersion,
      this.source,
      this.studyResult.clone(),
      this.studyMetadataResult.clone(),
      this.jobResult.clone(),
      this.jobMetadataResult.clone(),
      this.scalarResults.clone(),
      this.info.clone(),
      this.warnings.clone(),
      this.diagnosis.clone(),
      this.studyName.clone(),
      this.jobName.clone(),
      this.simTypes.clone(),
      this.inlineStudyResult);

    if(studyName){
      result.studyName.value = studyName;
    }

    if(jobName){
      result.jobName.value = jobName;
    }

    return result;
  }

  public async getStudyJobName(): Promise<string> {
    await this.studyName.load();
    await this.jobName.load();
    return this.studyName.value + ' / ' + this.jobName.value;
  }
}

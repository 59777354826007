
@if (hasConfigPreview) {
  <div #configPreviewContainer id="config-preview-container">
  </div>
}

@if (isLoaded) {
  <div>
    @if (isCar) {
      <cs-car-config-validator
        [tryGetEditorValue]="tryGetEditorValue"
        [simVersion]="editorInstance.simVersion"
        [isCarDirty]="form?.dirty"
        [userId]="userId"
        [configId]="configId"
        [name]="name.value"
        [isEdited]="isEdited"
        [customProperties]="customProperties"
      ></cs-car-config-validator>
    }
  </div>
}

<div id="edit-config-component">
  <div class="container-fluid">
    <div class="card">
      <h1 class="card-header">
        Edit {{configType.titleName}}
        @if (isStaged) {
          <small id="staged-sub-title" class="text-body-secondary">Staged Snapshot</small>
        }
        @if (saveSuccessful) {
          <small id="success-message" class="text-body-secondary">{{isStaged ? 'Staged' : 'Saved'}}</small>
        }
        @if (form?.dirty) {
          <small id="dirty-message" class="text-body-secondary">{{isStaged ? 'Unstaged' : 'Unsaved'}} Changes</small>
        }
        <div class="card-header-float-right">
          @if (isLoaded) {
            <cs-linting-engine-dialog-launcher
              [tenantId]="tenantId"
              [userId]="userId"
              [getConfigData]="getEditorValueCallback"
            [configType]="configType.singularKey"></cs-linting-engine-dialog-launcher>
          }
          @if (supportSession) {
            <cs-support-session-launcher
              [tenantId]="tenantId" [documentId]="configId"
              [documentType]="'config'" [documentSubType]="configType.singularKey"
              [supportSession]="supportSession"
            (supportSessionChanged)="onSupportSessionChanged($event)"></cs-support-session-launcher>
          }
        </div>
      </h1>

      <div class="card-body">
        @if (configType.singularKey === 'telemetry') {
          <cs-telemetry-help></cs-telemetry-help>
        }

        <form (ngSubmit)="onSubmit()" [formGroup]="form" autocomplete="off">

          <cs-edit-config-sticky
            [showSaved]="saveSuccessful" [showDirty]="form?.dirty"
            [isSubmitDisabled]="!isLoaded || isSubmitting || (!isStaged && !isOwner)"
            [isStaged]="isStaged" [submitButtonText]="isStaged ? submitStageButton.text : submitSaveButton.text"
            [additionalScrollOffset]="configType.singularKey === 'car' ? 50 : 0">
          </cs-edit-config-sticky>

          <p>
            <a id="back-button" class="btn btn-smart btn-secondary"
              [routerLink]="isCurrentTenant ? (isWorksheetPage ? ['../../../../../'] : ['/configs', configType.pluralKey]) : ['/support-sessions']">
              <i class="fa fa-caret-left"></i>Back
            </a>
            @if (showSubmitTelemetry) {
              <button id="create-run-telemetry" type="button" class="btn btn-smart btn-primary" [disabled]="isSubmitting" (click)="onSubmitTelemetry()">
                {{submitTelemetryButton.text}}
              </button>
            }
            @if (!isStaged) {
              <span class="button-menu-hover-target">
                <button type="button" id="save-hover-button" class="btn btn-smart btn-secondary" [disabled]="!isLoaded || isSubmitting">
                  <i class="fa fa-cloud"></i>{{submitSaveButton.text}}
                </button>
                <span class="button-menu-hovering-area">
                  <button type="submit" id="save-button" class="btn btn-smart btn-secondary" [disabled]="!isLoaded || isSubmitting || !isOwner">
                    <i class="fa fa-cloud"></i>{{submitSaveButton.text}}
                  </button>
                  <button type="button" id="save-as-button" class="btn btn-smart btn-secondary" (click)="onSaveAs()" [disabled]="!isLoaded || isSubmitting">
                    <i class="fa fa-file-o"></i>{{submitSaveAsButton.text}}
                  </button>
                  @if (isLoaded && !isDefault) {
                    <cs-list-versions-dialog-launcher class="add-margin"
                      [tenantId]="tenantId"
                      [userId]="userId"
                      [configId]="configId"
                      [configType]="configType.singularKey"
                      [getDocument]="getConfigDocumentCallback"
                      [setDocument]="setConfigDocumentCallback"
                      [saveOutputConfigHandler]="saveOutputConfigHandler"
                    ></cs-list-versions-dialog-launcher>
                  }
                </span>
              </span>
            }
            @if (isStaged) {
              <span class="button-menu-hover-target">
                <button type="button" id="save-hover-button" class="btn btn-smart btn-secondary" [disabled]="!isLoaded || isSubmitting">
                  <i class="fa fa-arrow-circle-up"></i>{{submitStageButton.text}}
                </button>
                <span class="button-menu-hovering-area">
                  <button type="submit" id="save-button" class="btn btn-smart btn-secondary" [disabled]="!isLoaded || isSubmitting">
                    <i class="fa fa-arrow-circle-up"></i>{{submitStageButton.text}}
                  </button>
                  <button type="button" id="save-to-source-button" class="btn btn-smart btn-secondary" (click)="onSave()" [disabled]="!isLoaded || isSubmitting || !isOwner">
                    <i class="fa fa-cloud"></i>{{submitSaveButton.text}}
                  </button>
                  <button type="button" id="save-as-button" class="btn btn-smart btn-secondary" (click)="onSaveAs()" [disabled]="!isLoaded || isSubmitting">
                    <i class="fa fa-file-o"></i>{{submitSaveAsButton.text}}
                  </button>
                </span>
              </span>
            }
            @if (!isStaged) {
              <button type="button" id="stage-button" class="btn btn-smart btn-secondary" (click)="onStage()" [disabled]="!isLoaded || isSubmitting">
                <i class="fa fa-arrow-circle-up"></i>{{submitStageButton.text}}
              </button>
            }
            <span class="button-menu-hover-target">
              <button type="button" id="hover-compare-button" class="btn btn-smart btn-secondary" [disabled]="!isLoaded">
                <i class="fa fa-files-o"></i>Compare
              </button>
              <span class="button-menu-hovering-area">
                @if (!isStaged) {
                  <button type="button" id="compare-to-saved-button" class="btn btn-smart btn-secondary" (click)="compareToSaved()" [disabled]="!isLoaded || isStaged">
                    <i class="fa fa-files-o"></i>Compare to Saved
                  </button>
                }
                <button type="button" id="compare-to-staged-button" class="btn btn-smart btn-secondary" (click)="compareToStaged()" [disabled]="!isLoaded || !form">
                  <i class="fa fa-files-o"></i>Compare to Staged
                </button>
                <cs-compare-to-something-button class="add-margin"
                  [configType]="configType.singularKey"
                  [simVersion]="simVersion"
                  [getConfigOrConfigLoader]="getConfigOrConfigLoaderCallback"
                  [saveOutputConfigHandler]="saveOutputConfigHandler"
                ></cs-compare-to-something-button>
              </span>
            </span>

            @if (isLoaded) {
              <span class="button-menu-hover-target">
                <button id="import-local-file-hover-button" type="button" class="btn btn-smart btn-secondary" [disabled]="!isLoaded">
                  <i class="fa fa-upload"></i>Import
                </button>
                <span class="button-menu-hovering-area">
                  <!-- IMPORTANT: Must contain no surrounding whitespace so we can add '(...)' around button text using :before/:after. No whitespace means the parenthesis hug the content. -->
                  @if (isLoaded) {
                    <span id="import-local-file-button" class="btn btn-smart btn-secondary btn-file"><i class="fa fa-upload"></i>Import Config<input id="file-load-button-input" type="file" (change)="processFileImportEvent($event)" accept=".json"></span>
                  }
                </span>
              </span>
            }

            @if (isLoaded) {
              <cs-export-config-buttons
                [isAdministrator]="isAdministrator"
                [getConfigOrConfigLoader]="getConfigOrConfigLoaderCallback">
              </cs-export-config-buttons>
            }

            @if (isLoaded && hasConfigPreview) {
              <button id="update-track-preview-button" type="button" class="btn btn-smart btn-primary" (click)="updateConfigPreview()" [disabled]="!isLoaded">
                Preview
              </button>
            }

          </p>

          <cs-error-message [message]="errorMessage || configPreviewErrorMessage"></cs-error-message>

          @if (!isLoaded && !errorMessage) {
            <cs-loader></cs-loader>
          }

          @if (isLoaded) {
            <div>
              <cs-compare-config-to-original
                [databaseResult]="documentResult"
                [stagedResult]="stagedConfig"
                [originalStagedResult]="originalStagedConfig"
                [editorContent]="initialEditorResult"
                [saveOutputConfigHandler]="saveOutputConfigHandler">
              </cs-compare-config-to-original>
              <pre id="config-json" class="raw-json debug-information">{{configDataFormatted}}</pre>
              <div class="row">
                <div class="col-12 col-sm-6">
                  <div class="mb-3">
                    <label for="name-input" class="title-label form-label">Name</label>
                    <input id="name-input" type="text" class="form-control" formControlName="name">
                    @if (!name.pristine && name.hasError('required')) {
                      <p class="text-danger">
                        Name is required
                      </p>
                    }
                    @if (name.hasError('minlength')) {
                      <p class="text-danger">
                        Name is too short.
                      </p>
                    }
                    @if (name.hasError('maxlength')) {
                      <p class="text-danger">
                        Name is too long.
                      </p>
                    }
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="mb-3">
                    <label for="notes-input" class="title-label form-label">Notes</label>
                    <textarea autosize [minRows]="1" id="notes-input" class="form-control notes-input" formControlName="notes"></textarea>
                    @if (notes.hasError('maxlength')) {
                      <p class="text-danger">
                        Notes are too long.
                      </p>
                    }
                  </div>
                </div>
              </div>
              <div class="row">
                <div class=col-12>
                  <label class="title-label form-label">Custom Properties</label>
                  <cs-view-edit-custom-properties [tenantId]="tenantId" [target]="configType.singularKey"
                    [form]="form"
                    [reset]="resetPropertyEditor"
                    [initialProperties]="customProperties"
                  [resultProperties]="customProperties"></cs-view-edit-custom-properties>
                </div>
              </div>
              @if (!editorInstance.canValidate) {
                <div class="row">
                  <div class="col-12">
                    <div class="mb-3">
                      <label for="unvalidated-input" class="title-label form-label">JSON</label>
                      <textarea id="unvalidated-input" class="form-control unvalidated-input" formControlName="unvalidatedInput"></textarea>
                    </div>
                  </div>
                </div>
              }
            </div>
          }

          @if (isLoaded && editorInstance.canValidate) {
            <cs-custom-view-selector
              class="custom-view-selector"
              [configType]="configType.singularKey"
            [editorInstance]="editorInstance"></cs-custom-view-selector>
          }

          <div id="json-editor" class="json-editor"></div>

        </form>
      </div>
    </div>
  </div>
</div>

import { SimType } from '../../sim-type';
import { simVersionToNumber } from '../../sim-version-to-number';
import { SIM_TYPE_GENERATE_RACING_LINE } from '../../constants';

export const GENERATE_RACING_LINE_COORDINATE_SYSTEM_CHANGE_SIM_VERSION = 1157;

/**
 * Utilities for track coordinate systems.
 */
export class TrackCoordinateSystemUtilities {

  /**
   * Determines if the sim type requires negation for the ISO coordinate system.
   * This behavior changed at a specific sim version, so this function takes the sim version into account.
   * @param simType The sim type.
   * @param simVersion The sim version.
   * @returns True if the sim type requires negation for the ISO coordinate system.
   */
  public static simTypeRequiresNegationForIso(
    simType: SimType | undefined | null,
    simVersion: string | undefined | null): boolean {

    if (simType
      && simVersion
      && simType === SIM_TYPE_GENERATE_RACING_LINE) {

      return simVersionToNumber(simVersion) >= GENERATE_RACING_LINE_COORDINATE_SYSTEM_CHANGE_SIM_VERSION;
    }

    return true;
  }

  /**
   * Determines if the channel requires negation for the ISO coordinate system.
   * This behavior changed at a specific sim version, so this function takes the sim version into account.
   * @param channelName The channel name.
   * @param simType The sim type.
   * @param simVersion The sim version.
   * @returns True if the channel requires negation for the ISO coordinate system.
   */
  public static channelRequiresNegationForIso(
    channelName: string,
    simType: SimType | undefined | null,
    simVersion: string | undefined | null): boolean {

    if (!TrackCoordinateSystemUtilities.simTypeRequiresNegationForIso(
      simType, simVersion)) {
      return false;
    }

    for (let prefix of ['RacingLine_']) {
      if (channelName.startsWith(prefix)) {
        channelName = channelName.substring(prefix.length);
        break;
      }
    }

    switch (channelName) {
      case 'cLap':
      case 'aTrackIncline':
      case 'aWind':
      case 'aYawCentreLine':
      case 'yCentreLine':
      case 'yTrackEdgeLeft':
      case 'yTrackEdgeRight':
      case 'zTrackEdgeLeft':
      case 'zTrackEdgeRight':
      case 'zTrack':
      case 'rTrackCentreOffset':
        return true;
    }

    return false;
  }

  /**
   * Converts a channel to the ISO coordinate system.
   * @param data The channel data.
   * @param channelName The channel name.
   * @param simType The sim type.
   * @param simVersion The sim version.
   * @returns The channel data in the ISO coordinate system.
   */
  public static channelAsIso(
    data: ReadonlyArray<number>,
    channelName: string,
    simType: SimType | undefined | null,
    simVersion: string | undefined | null): ReadonlyArray<number> {

    if (TrackCoordinateSystemUtilities.channelRequiresNegationForIso(
      channelName, simType, simVersion)) {

      return data.map(v => -v);
    }

    return data;
  }
}

import { IChannelMetadata, SourceLoaderBase, SourceMetadata } from './source-loader';
import { IViewerChannelData } from './viewer-channel-data';
import { EditChannelsChannelMetadata, SiteHooks } from '../../site-hooks';
import { UrlFileLoader } from '../../url-file-loader';
import { StudyJob } from '../../study-job';
import { SimType } from '../../sim-type';
import { getFullyQualifiedChannelName } from '../../get-fully-qualified-channel-name';
import {
  CONFIG_TYPE_CAR, CONFIG_TYPE_TRACK,
  GENERIC_CHANNEL_DESCRIPTION,
  NO_UNITS
} from '../../constants';
import { ChannelNameStyle } from './channel-name-style';
import { ViewerChannelDataFactory } from './viewer-channel-data-factory';
import { LoadVectorMetadataMap } from './load-vector-metadata-map';
import { StudyJobSourceLoaderCache } from './study-job-source-loader-cache';
import { GetLegacyChannelMappings } from './get-legacy-channel-mappings';
import { LoadStudyJobChannelData } from './load-study-job-channel-data';
import { GetStudyJobChannelMetadata } from './get-study-job-channel-metadata';
import { ChannelDataTransforms } from './channel-data-transforms';

/**
 * A source loader for a study job.
 */
export class StudyJobSourceLoader extends SourceLoaderBase {

  constructor(
    private readonly siteHooks: SiteHooks,
    private readonly urlFileLoader: UrlFileLoader,
    public readonly studyJob: StudyJob,
    private readonly simType: SimType,
    private readonly getStudyJobChannelMetadata: GetStudyJobChannelMetadata,
    private readonly channelDataFactory: ViewerChannelDataFactory,
    private readonly cache: StudyJobSourceLoaderCache,
    private readonly loadStudyJobChannelData: LoadStudyJobChannelData) {
    super();
  }

  /**
   * Creates a new study job source loader.
   * @param siteHooks The site hooks.
   * @param urlFileLoader The URL file loader.
   * @param studyJob The study job.
   * @param simType The simulation type.
   * @param channelDataTransforms The channel data transforms.
   */
  public static create(
    siteHooks: SiteHooks,
    urlFileLoader: UrlFileLoader,
    studyJob: StudyJob,
    simType: SimType,
    channelDataTransforms: ChannelDataTransforms = ChannelDataTransforms.none): StudyJobSourceLoader {

    const channelDataFactory = new ViewerChannelDataFactory(siteHooks);
    const getLegacyChannelMappings = new GetLegacyChannelMappings();
    return new StudyJobSourceLoader(
      siteHooks,
      urlFileLoader,
      studyJob,
      simType,
      new GetStudyJobChannelMetadata(channelDataFactory),
      channelDataFactory,
      new StudyJobSourceLoaderCache(
        studyJob,
        simType,
        LoadVectorMetadataMap.create(urlFileLoader, channelDataTransforms),
      ),
      new LoadStudyJobChannelData(
        urlFileLoader,
        getLegacyChannelMappings,
        channelDataTransforms));
  }

  /**
   * @inheritdoc
   */
  public async getSourceMetadata(): Promise<SourceMetadata> {
    let name = await this.siteHooks.getStudyJobName(this.studyJob.studyId, this.studyJob.jobIndex);
    return new SourceMetadata(name + ' / ' + this.simType);
  }

  /**
   * @inheritdoc
   */
  public async getChannelData(requestedName: string, resultChannelNameStyle: ChannelNameStyle, xDomainName: string): Promise<IViewerChannelData> {
    let vectorMetadataResult = await this.cache.get();

    let channelMetadata = this.getStudyJobChannelMetadata.execute(
      requestedName, this.simType, vectorMetadataResult, resultChannelNameStyle, xDomainName);

    let dataUnits: string = NO_UNITS;
    let data: ReadonlyArray<number> | undefined;
    if (channelMetadata.loadedMetadata) {
      dataUnits = channelMetadata.loadedMetadata.units;
      data = await this.loadStudyJobChannelData.execute(
        this.studyJob.studyId,
        this.studyJob.jobIndex,
        this.simType,
        channelMetadata.loadedMetadata.genericName,
        channelMetadata.loadedMetadata.binaryFormat,
        channelMetadata.loadedMetadata.loaderMetadata);
    }

    return this.channelDataFactory.createChannelDataFromMetadata(channelMetadata.viewerMetadata, data, dataUnits);
  }

  /**
   * @inheritdoc
   */
  public async getRequestableChannels(resultChannelNameStyle: ChannelNameStyle, xDomainName: string): Promise<IChannelMetadata[]> {
    let channelMetadataResult = await this.cache.get();

    let channelList = channelMetadataResult.list.filter(v => xDomainName === v.xDomainName);

    switch (resultChannelNameStyle) {
      case ChannelNameStyle.FullyQualified:
        // We add both the generic and fully qualified names, but note in the description of the
        // generic channels that they could be from any simulation in the study job.
        return [
          ...channelList.map(v => new EditChannelsChannelMetadata(v.name, GENERIC_CHANNEL_DESCRIPTION)),
          ...channelList.map(v => new EditChannelsChannelMetadata(getFullyQualifiedChannelName(v.name, this.simType), v.description))
        ];

      case ChannelNameStyle.Generic:
        return channelList.map(v => new EditChannelsChannelMetadata(v.name, v.description));
    }

    return [];
  }

  /**
   * @inheritdoc
   */
  public getConfig(type: string): Promise<any> {
    switch (type) {
      case CONFIG_TYPE_TRACK:
        return this.urlFileLoader.loadTrackForStudyJob(this.studyJob.studyId, this.studyJob.jobIndex);

      case CONFIG_TYPE_CAR:
        return this.urlFileLoader.loadCarForStudyJob(this.studyJob.studyId, this.studyJob.jobIndex);
    }

    return super.getConfig(type);
  }
}

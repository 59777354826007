import { Utilities } from '../../utilities';
import { IPopulatedMultiPlotLayout } from './types/i-populated-multi-plot-layout';
import { IMultiPlotLayout } from './types/i-multi-plot-layout';


/**
 * Returns a new layout with all transient and processed properties removed.
 */
export class GetUnprocessedLayout {

  /**
   * Returns a new layout with all transient and processed properties removed.
   * @param layout - The reference layout.
   * @returns A new layout with all transient and processed properties removed.
   */
  public execute(layout: IPopulatedMultiPlotLayout | IMultiPlotLayout): IMultiPlotLayout {
    return Utilities.deepClone(layout, (key, value) => {
      if (key === 'processed' || key === 'transient') {
        return undefined;
      }

      return value;
    });
  }
}

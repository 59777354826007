
<div class="menu-container"
  [ngClass]="{ 'scrollable-menu-container': enableScroll }"
  [ngStyle]="{ 'max-height': maximumScrollableHeight }">
  @for (item of items; track item) {
    @switch (item.type) {
      @case (MenuItemType.separator) {
        <hr/>
      }
      @case (MenuItemType.button) {
        <button (click)="executeAction($event, item)" class="{{asButton(item).cssClass}} button-menu-item"><i class="fa fa-fw fa-{{asButton(item).iconClass}}"></i>{{asButton(item).name}}</button>
      }
      @case (MenuItemType.subMenu) {
        <div class="sub-menu-container">
          <button class="{{asSubMenu(item).cssClass}}"><i class="fa fa-fw"></i>{{asSubMenu(item).name}}<i class="fa fa-caret-right"></i></button>
          <cs-context-menu-renderer class="sub-menu"
            [ngStyle]="{ 'top': subMenuTopString, 'left': subMenuLeftString, 'right': subMenuRightString, 'bottom': subMenuBottomString }"
            [items]="asSubMenu(item).items"
            [wrapper]="wrapper"
            [horizontalExpandDirection]="horizontalExpandDirection"
            [verticalExpandDirection]="verticalExpandDirection"
            [screenSize]="screenSize"
            [enableScroll]="asSubMenu(item).enableScroll"
            (closeRequested)="requestClose()">
          </cs-context-menu-renderer>
        </div>
      }
    }
  }
</div>

import * as d3 from '../../d3-bundle';
import { SVGSelection } from '../../untyped-selection';
import { ISize } from '../size';
import { IMargin } from '../margin';
import { IPosition, Position } from '../position';
import { ICanvasData } from '../canvas-utilities';
import { GetChannelColorDelegate } from '../chart-settings';
import { DomainNewsEvent, SecondaryDomainNewsEvent, SharedState } from '../shared-state';
import { SourceData } from '../data-pipeline/types/source-data';
import { IPopulatedMultiPlotLayout } from '../data-pipeline/types/i-populated-multi-plot-layout';
import { ProcessedPlot } from '../data-pipeline/types/processed-plot';
import { ProcessedPlotSourceChannel } from '../data-pipeline/types/processed-plot-source-channel';
import { QuadTreeCursorDataPoint } from '../data-pipeline/types/quad-tree-cursor-data-point';
import { ProcessedMultiPlotChannel } from '../data-pipeline/types/processed-multi-plot-channel';
import { MonotonicStatus } from '../channel-data-loaders/viewer-channel-data';
import { PlotClippingRenderer } from './plot-clipping-renderer';
import { Units } from '../../units';
import { GetInterpolatedChannelValueAtDomainValue } from '../channel-data-loaders/get-interpolated-channel-value-at-domain-value';
import { ColumnLegendList } from './multi-plot-viewer-base';
import { ProcessedDomainEvent } from './domain-event-handler';
import { CanvasDataRendererBase, DefaultRenderInformation } from '../components/canvas-data-renderer-base';
import { definedValues } from '../../defined-values';

/**
 * The interface for the chart settings we're interested in.
 */
interface IChartSettings {

  /**
   * The unique identifier for the chart.
   */
  readonly uniqueId: string;

  /**
   * The padding between the edge of the SVG and where we should draw.
   */
  readonly svgPadding: IMargin;

  /**
   * The margin around the data area of the chart, to allow for source labels, legend, etc.
   */
  readonly chartMargin: IMargin;

  /**
   * The size of the chart, calculated using the SVG size, SVG padding, and chart margin.
   */
  readonly chartSize: ISize;

  /**
   * The space between plots.
   */
  readonly spaceBetweenPlots: number;

  /**
   * Get the color for a channel.
   */
  readonly getChannelColor: GetChannelColorDelegate;
}

/**
 * The D3 style interface to the base class for rendering data for a multi-plot viewer to a canvas.
 */
export abstract class MultiPlotDataRendererBase {

  /**
   * The constructor for the base class.
   * @param inner The inner class to use for rendering the data.
   */
  constructor(private inner: MultiPlotDataRendererInnerBase) {
  }

  /**
   * Render the data to the given selection.
   * @param selection The selection to render to.
   * @returns This object.
   */
  public render(selection: SVGSelection): this {
    this.inner.render(selection);
    return this;
  }

  /**
   * Called when a domain event has been processed.
   * @param processedEvent The processed event.
   * @returns This object.
   */
  public domainEventProcessed(processedEvent: ProcessedDomainEvent): this {
    this.inner.domainEventProcessed(processedEvent);
    return this;
  }

  /**
   * Set the primary domain name.
   * @param value The primary domain name.
   * @returns This object.
   */
  public primaryDomainName(value: string): this {
    this.inner.primaryDomainName = value;
    return this;
  }

  /**
   * Set the layout.
   * @param value The layout.
   * @returns This object.
   */
  public layout(value: IPopulatedMultiPlotLayout): this {
    this.inner.layout = value;
    return this;
  }

  /**
   * Set the source data.
   * @param value The source data.
   * @returns This object.
   */
  public sourceData(value: ReadonlyArray<SourceData>): this {
    this.inner.sourceData = value;
    return this;
  }

  /**
   * Set the chart settings.
   * @param value The chart settings.
   * @returns This object.
   */
  public chartSettings(value: IChartSettings): this {
    this.inner.setSettings(value);
    return this;
  }

  /**
   * Set the canvas data.
   * @param value The canvas data.
   * @returns This object.
   */
  public canvasData(value: ICanvasData): this {
    this.inner.setCanvasData(value);
    return this;
  }

  /**
   * Set the x legend list.
   * @param value The x legend list.
   * @returns This object.
   */
  public xLegendList(value: ColumnLegendList): this {
    this.inner.xLegendList = value;
    return this;
  }

  /**
   * Set the shared state.
   * @param value The shared state.
   * @returns This object.
   */
  public sharedState(value: SharedState): this {
    this.inner.sharedState = value;
    return this;
  }

  /**
   * Set the domain snap behaviour.
   * @param value The domain snap behaviour.
   * @returns This object.
   */
  public domainSnapBehaviour(value: DomainSnapBehaviour): this {
    this.inner.domainSnapBehaviour = value;
    return this;
  }

  /**
   * Get the domain snap behaviour.
   * @returns The domain snap behaviour.
   */
  public getDomainSnapBehaviour(): DomainSnapBehaviour {
    return this.inner.domainSnapBehaviour;
  }

  /**
   * Listen for changes to the legend.
   * @param typenames The event type names.
   * @param callback The callback.
   * @returns This object.
   */
  public on(typenames: string, callback: (this: object, ...args: any[]) => void): this {
    this.inner.listeners.on(typenames, callback);
    return this;
  }

  /**
   * Dispose of the object.
   * @returns This object.
   */
  public dispose(): this {
    this.inner.dispose();
    return this;
  }
}

/**
 * The base class for rendering data for a multi-plot viewer to a canvas.
 */
export abstract class MultiPlotDataRendererInnerBase extends CanvasDataRendererBase<IChartSettings, DefaultRenderInformation> {

  /**
   * The chart layout.
   */
  public layout?: IPopulatedMultiPlotLayout;

  /**
   * The set of data sources.
   */
  public sourceData?: ReadonlyArray<SourceData>;

  /**
   * The primary domain name.
   */
  public primaryDomainName?: string;

  /**
   * The x legend list.
   */
  public xLegendList?: ColumnLegendList;

  /**
   * The shared state.
   */
  public sharedState?: SharedState;

  /**
   * The 3D style events.
   */
  public listeners = d3.dispatch('legendChanged');

  /**
   * The domain snap behaviour.
   */
  public domainSnapBehaviour = DomainSnapBehaviour.nearest;

  /**
   * The constructor for the base class.
   * @param canvasCssPrefix The CSS prefix for the canvas.
   * @param getInterpolatedChannelValueAtDomainValue The function to get the interpolated channel value at a domain value.
   */
  constructor(
    canvasCssPrefix: string,
    protected getInterpolatedChannelValueAtDomainValue: GetInterpolatedChannelValueAtDomainValue) {
    super('multi-plot-data-renderer', [canvasCssPrefix + '-viewer-canvas']);
  }

  /**
   * Set the chart settings.
   * @param value The chart settings.
   */
  public setSettings(value: IChartSettings) {
    this.settings = value;
  }

  /**
   * Set the canvas data.
   * @param value The canvas data.
   */
  public setCanvasData(value: ICanvasData) {
    this.canvasData = value;
  }

  /**
   * Render the data to the given selection.
   * @param selection The selection to render to.
   */
  public render(selection: SVGSelection) {
    if (!this.layout || !this.sourceData || !this.primaryDomainName) {
      return;
    }

    super.performRender(new DefaultRenderInformation());
  }

  /**
   * Dispose of the object.
   */
  public dispose() {
  }

  /**
   * Called to render to the given canvas contexts.
   * @param targetContexts The target contexts.
   * @param renderInformation The render information.
   */
  protected renderContext(targetContexts: ReadonlyArray<CanvasRenderingContext2D>, renderInformation: DefaultRenderInformation) {

    // Ensure we have everything we need.
    if (!this.settings || !this.canvasData || !this.layout || !this.layout.processed) {
      return;
    }

    let style = getComputedStyle(document.documentElement);

    // For the multi-plot viewer we just render to the first canvas (the parallel coordinates viewer uses two canvases).
    let context: CanvasRenderingContext2D = renderInformation.canvases.first.canvasContext;

    // Get the current pixel ratio.
    let pixelRatio = this.canvasData.settings.pixelRatio;

    // For each plot we need to render...
    for (let plot of this.layout.processed.plots) {

      // Reset the canvas transform.
      this.canvasUtilities.resetTransform(context, pixelRatio);

      let column = plot.column;
      let row = plot.row;

      let plotX = plot.absoluteRenderArea.x;
      let plotY = plot.absoluteRenderArea.y;

      let plotWidth = column.processed.plotSize;
      let plotHeight = row.processed.plotSize;

      // Move to the plot position.
      context.translate(plotX, plotY);

      // Set the stroke style.
      context.strokeStyle = `${style.getPropertyValue('--bs-border-color')}`;

      // Draw the plot border.
      context.strokeRect(0.5, 0.5, plotWidth, plotHeight);

      // Save the drawing state (in case it is manipulated by derived classes).
      context.save();
      try {

        // Start a new path.
        context.beginPath();

        // Add the plot border to the path.
        context.rect(0, 0, plotWidth, plotHeight);

        // Set the clipping region to the plot area.
        context.clip();

        // Draw lines where the axes are zero.
        this.drawZeroLines(context, plot);

        // Draw any plot features.
        this.drawPlotFeatures(context, plot);

        // For each data source...
        for (let source of plot.sources) {

          // For each channel in the source...
          for (let channel of source.channels) {

            // Draw the channel data.
            this.drawChannelData(context, plot, channel);
          }
        }
      } finally {
        // Restore the drawing state.
        context.restore();
      }
    }
  }

  /**
   * Draw lines on the plot where the axes are zero.
   * @param context The canvas rendering context.
   * @param plot The plot we're drawing.
   */
  private drawZeroLines(context: CanvasRenderingContext2D, plot: ProcessedPlot) {
    if (!this.sourceData) {
      return;
    }

    // Get the visible X and Y extents of all the visible sources.
    let sourcesVisibility = this.sourceData.map(v => v.isVisible);
    let xMinimum = plot.column.processed.getVisibleMinimum(sourcesVisibility);
    let xMaximum = plot.column.processed.getVisibleMaximum(sourcesVisibility);
    let yMinimum = plot.row.processed.getVisibleMinimum(sourcesVisibility);
    let yMaximum = plot.row.processed.getVisibleMaximum(sourcesVisibility);

    // If we have any zero crossings in the current view...
    if ((xMinimum < 0 && xMaximum > 0) || (yMinimum < 0 && yMaximum > 0)) {

      // Save the drawing context...
      context.save();
      try {
        // We draw the zero lines with a faint dashed line.
        context.lineWidth = 1;
        context.strokeStyle = '#bbb';
        context.setLineDash([15, 5]);
        context.beginPath();

        let columnScale = plot.column.processed.scale;
        let rowScale = plot.row.processed.scale;

        // Add the horizontal line if the Y axis crosses zero.
        if (yMinimum < 0 && yMaximum > 0) {
          let rowValue = rowScale(0) + 0.5;
          context.moveTo(
            columnScale(xMinimum),
            rowValue);
          context.lineTo(
            columnScale(xMaximum),
            rowValue);
        }

        // Add the vertical line if the X axis crosses zero.
        if (xMinimum < 0 && xMaximum > 0) {
          let columnValue = columnScale(0) - 0.5;
          context.moveTo(
            columnValue,
            rowScale(yMinimum));
          context.lineTo(
            columnValue,
            rowScale(yMaximum));
        }

        // Stroke the path.
        context.stroke();
      } finally {
        context.restore();
      }
    }
  }

  /**
   * Draw the channel data.
   * @param context The canvas rendering context.
   * @param plot The plot we're drawing.
   * @param channel The channel we're drawing.
   */
  protected abstract drawChannelData(context: CanvasRenderingContext2D, plot: ProcessedPlot, channel: ProcessedPlotSourceChannel): void;

  /**
   * Draw the plot features.
   * @param context The canvas rendering context.
   * @param plot The plot we're drawing.
   */
  protected abstract drawPlotFeatures(context: CanvasRenderingContext2D, plot: ProcessedPlot): void;

  /**
   * Attach the mouse move handler to the SVG element.
   * This is called by the base class.
   * @param svg The SVG element.
   */
  protected attachCanvasMouseMoveHandler(svg: SVGSelection): void {

    let isMouseDown: boolean = false;
    svg
      .on('mousedown.data-renderer-base', () => {
        isMouseDown = true;
      })
      .on('mouseup.data-renderer-base', () => {
        isMouseDown = false;
      })
      .on('mousemove.data-renderer-base', (mouseEvent: MouseEvent) => {
        let position = new Position(mouseEvent.offsetX, mouseEvent.offsetY);

        if (isMouseDown) {
          return;
        }

        this.handleMouseMoveEvent(position);

        this.listeners.call('legendChanged');
      })
      .on('mouseleave.data-renderer-base', () => {
        isMouseDown = false;

        this.handleMouseLeaveEvent();

        this.listeners.call('legendChanged');
      });
  }

  /**
   * Handle a domain event having been processed.
   * @param processedEvent The processed event.
   */
  public domainEventProcessed(processedEvent: ProcessedDomainEvent) {
    this.resetInteractionData();
    this.renderMultiPlotCursorDataPoints(processedEvent.plotCursorDataPoints);
  }

  /**
   * Render the circles for the data points nearest the cursor.
   * @param items The items to render.
   */
  protected renderMultiPlotCursorDataPoints(items: ReadonlyArray<PlotCursorDataPoint>) {
    if (!this.container || !this.sourceData) {
      return;
    }

    const settings = this.definedSettings;
    const sourceData = this.sourceData;

    let pointUpdate = this.container.selectAll<SVGCircleElement, PlotCursorDataPoint>('.cursor-point').data(items as PlotCursorDataPoint[], (v: PlotCursorDataPoint) => v.id);
    pointUpdate.exit().remove();
    let pointEnter = pointUpdate.enter().append<SVGCircleElement>('circle')
      .attr('class', 'cursor-point')
      .attr('r', '2.5')
      .attr('stroke', 'black')
      .lower();

    let applyPositionAndColor = (selection: d3.Selection<SVGCircleElement, PlotCursorDataPoint, SVGGElement, null>, transitionDuration: number) => {
      ((transitionDuration ? selection.transition().duration(transitionDuration).ease(d3.easeLinear) : selection)
        .attr('clip-path', (d: PlotCursorDataPoint, i) => `url(#${PlotClippingRenderer.getPlotClipPathId(settings.uniqueId, d.plot.plotIndex)})`) as d3.Transition<SVGCircleElement, PlotCursorDataPoint, SVGGElement, null>)
        .attr('cx', (d: PlotCursorDataPoint) => d.point.x + d.plot.absoluteRenderArea.x)
        .attr('cy', (d: PlotCursorDataPoint) => d.point.y + d.plot.absoluteRenderArea.y)
        .attr('display', (d: PlotCursorDataPoint) => sourceData[d.point.sourceIndex].isVisible && d.point.channel.isVisible ? 'inherit' : 'none')
        .attr('fill', (d: PlotCursorDataPoint) => settings.getChannelColor(d.point.channel.channelIndex, d.point.sourceIndex));
    };

    applyPositionAndColor(pointEnter, 0);
    applyPositionAndColor(pointUpdate, 0 /*TRANSITION_DURATION*/);
  }

  /**
   * Draw the cursor line which runs perpendicular to the monotonic axis where the cursor is positioned.
   * @param position The position of the cursor.
   */
  protected drawCursorLine(position: IPosition | undefined) {
    if (!this.container) {
      return;
    }

    const settings = this.definedSettings;
    let lineUpdate = this.container.selectAll<SVGLineElement, IPosition>('.cursor-line').data(position ? [position] : []);
    lineUpdate.exit().remove();
    let lineEnter = lineUpdate.enter().append('line')
      .attr('class', 'cursor-line')
      .attr('stroke-width', 1);
    lineUpdate.merge(lineEnter)
      .attr('x1', (d: IPosition) => d.x + 0.5)
      .attr('y1', settings.svgPadding.top + settings.chartMargin.top)
      .attr('x2', (d: IPosition) => d.x + 0.5)
      .attr('y2', settings.svgPadding.top + settings.chartMargin.top + settings.chartSize.height - settings.spaceBetweenPlots);
  }

  /**
   * Draw lines from the cursor position to the nearest data points.
   * @param positionOrUndefined The position of the cursor.
   * @param plotOrUndefined The plot the cursor is in.
   * @param points The data points to draw lines to.
   */
  protected drawClosestPointLines(positionOrUndefined: IPosition | undefined, plotOrUndefined: ProcessedPlot | undefined, points: (QuadTreeCursorDataPoint | undefined)[]) {
    if (!this.container) {
      return;
    }

    const settings = this.definedSettings;

    if (points.length) {
      if (!positionOrUndefined) {
        throw new Error('Plot must be defined when points are provided.');
      }
      if (!plotOrUndefined) {
        throw new Error('Position must be defined when points are provided.');
      }
    }

    const plot = plotOrUndefined;
    const position = positionOrUndefined;

    let linesUpdate = this.container.selectAll<SVGGElement, any>('.closest-point-lines').data([null]);
    let linesEnter = linesUpdate.enter().append('g').attr('class', 'closest-point-lines');

    let lines = linesEnter.merge(linesUpdate);

    let lineUpdate = lines.selectAll<SVGLineElement, QuadTreeCursorDataPoint>('.closest-point-line')
      .data(
        definedValues(points),
        (v: QuadTreeCursorDataPoint) => `point-line-${v.plotIndex}-${v.sourceIndex}`);

    lineUpdate.exit().remove();

    let lineEnter = lineUpdate.enter().append<SVGLineElement>('line')
      .attr('class', 'closest-point-line')
      .attr('stroke', 'black')
      .attr('stroke-width', 1)
      .attr('x2', (d: QuadTreeCursorDataPoint) => d.x + plot.absoluteRenderArea.x)
      .attr('y2', (d: QuadTreeCursorDataPoint) => d.y + plot.absoluteRenderArea.y);

    lineUpdate.merge(lineEnter)
      .attr('clip-path', () => `url(#${PlotClippingRenderer.getPlotClipPathId(settings.uniqueId, plot.plotIndex)})`)
      .attr('x1', () => position.x)
      .attr('y1', () => position.y);

    lineUpdate
      //.transition().duration(TRANSITION_DURATION).ease(d3.easeLinear)
      .attr('x2', (d: QuadTreeCursorDataPoint) => d.x + plot.absoluteRenderArea.x)
      .attr('y2', (d: QuadTreeCursorDataPoint) => d.y + plot.absoluteRenderArea.y);
  }

  /**
   * Render the cursor data points for a single plot.
   * @param plotOrUndefined The plot the cursor is in.
   * @param points The data points to render.
   */
  protected renderCursorDataPoints(plotOrUndefined: ProcessedPlot | undefined, points: CursorDataPoint[]) {
    if (points.length && !plotOrUndefined) {
      throw new Error('Plot must be defined when points are provided.');
    }

    const plot = plotOrUndefined;
    this.renderMultiPlotCursorDataPoints(points.map((v, i) => new PlotCursorDataPoint('point-' + i, plot, v)));
  }

  /**
   * Handle the mouse leave event.
   */
  protected handleMouseLeaveEvent() {
  }

  /**
   * Resets all the interaction data which is rendered as the cursor moves.
   */
  protected resetInteractionData() {
    this.renderCursorDataPoints(undefined, []);
    this.drawCursorLine(undefined);
    this.drawClosestPointLines(undefined, undefined, []);
  }

  /**
   * Handle the mouse move event.
   * @param position The position of the cursor.
   */
  protected handleMouseMoveEvent(position: IPosition) {
    if (!this.layout || !this.svg) {
      return;
    }

    // Determine if the cursor is inside a plot.
    let plot = this.layout.processed.plots.find(v => v.isInPlot(position));
    if (!plot) {
      // If not then reset the cursor and return.
      this.svg.style('cursor', 'inherit');
      return;
    }

    // If we are in the plot, set the cursor to a crosshair.
    this.svg.style('cursor', 'crosshair');

    let activeDomainChannel = plot.column.processed.channels.find(v => v.isVisible && v.hasData);
    if (activeDomainChannel &&
      // If monotonically active domain, render vertical line and interpolate.
      (activeDomainChannel.monotonicStatus === MonotonicStatus.Increasing
        || activeDomainChannel.monotonicStatus === MonotonicStatus.Decreasing)) {
      this.handleMouseMoveEventMonotonic(position, plot, activeDomainChannel);
      this.drawClosestPointLines(undefined, undefined, []);
      this.drawCursorLine(position);
    } else {
      // Otherwise, draw lines to the closest data points.
      let closestPoints = this.handleMouseMoveEventNonMonotonic(position, plot);
      this.drawCursorLine(undefined);
      this.drawClosestPointLines(position, plot, closestPoints);
    }
  }

  /**
   * Handle the mouse move event for a monotonic active domain.
   * @param position The cursor position.
   * @param plot The plot containing the cursor.
   * @param activeDomainChannel The active domain channel.
   */
  protected handleMouseMoveEventMonotonic(position: IPosition, plot: ProcessedPlot, activeDomainChannel: ProcessedMultiPlotChannel) {
    if (!this.layout || !this.sharedState) {
      return;
    }

    let activeMonotonicStatus = activeDomainChannel.monotonicStatus;
    let xChannels: ProcessedMultiPlotChannel[] = [...plot.column.processed.channels];
    if (xChannels.every(v => v.name !== this.primaryDomainName)) {
      // Always raise the event on the primary domain, even if it isn't an active x channel.
      xChannels.push(this.layout.processed.primaryDomain);
    }

    let secondaryDomain = this.layout.processed.secondaryDomain;

    // Convert the cursor position to a domain value.
    let plotPosition = new Position(position.x - plot.absoluteRenderArea.x, position.y - plot.absoluteRenderArea.y);
    let activeDomainValue = plot.column.processed.scale.invert(plotPosition.x);

    // For each x channel...
    for (let channel of xChannels) {

      // Create the domain news event for the channel.
      let domainNews = this.sharedState.getDomainNews(channel.name);
      let domainNewsEvent = this.getDomainNewsEventForChannel(channel, activeDomainValue, activeDomainChannel, activeMonotonicStatus);

      // If we're raising an event for the primary domain, include the secondary domain news event as well.
      if (secondaryDomain && channel.name === this.primaryDomainName) {
        let secondaryDomainNewsEvent = this.getDomainNewsEventForChannel(secondaryDomain, activeDomainValue, activeDomainChannel, activeMonotonicStatus);
        domainNewsEvent = new DomainNewsEvent(
          domainNewsEvent.sourceValues,
          new SecondaryDomainNewsEvent(secondaryDomain.name, secondaryDomainNewsEvent));
      }

      // Raise the event.
      domainNews.raise(domainNewsEvent);
    }
  }

  /**
   * Get the domain news event for a channel.
   * @param channel The channel.
   * @param activeDomainValue The active domain value.
   * @param activeDomainChannel The active domain channel.
   * @param activeMonotonicStatus The active monotonic status.
   * @returns The domain news event.
   */
  private getDomainNewsEventForChannel(
    channel: ProcessedMultiPlotChannel,
    activeDomainValue: number,
    activeDomainChannel: ProcessedMultiPlotChannel,
    activeMonotonicStatus: MonotonicStatus): DomainNewsEvent {

    // If the x channel is the visible one, we can create the event using the active domain value
    // as only one x domain is active at a time.
    if (channel.isVisible) {
      return new DomainNewsEvent(channel.sources.map(
        v => Units.convertValueToSi(activeDomainValue, channel.units)));
    }

    // Otherwise we need to go through each source and find the value for the non-active x channel
    // at the current active x channel value.
    let requiredDomainValues: number[] = [];
    for (let sourceIndex = 0; sourceIndex < channel.sources.length; ++sourceIndex) {
      let requiredDomainData = channel.sources[sourceIndex].data;
      let activeDomainData = activeDomainChannel.sources[sourceIndex].data;
      if (requiredDomainData && activeDomainData) {
        let requiredDomainValue = this.getInterpolatedChannelValueAtDomainValue.execute(requiredDomainData, activeDomainValue, activeDomainData, activeMonotonicStatus);
        requiredDomainValue = Units.convertValueToSi(requiredDomainValue, channel.units);
        requiredDomainValues.push(requiredDomainValue);
      } else {
        requiredDomainValues.push(NaN);
      }
    }
    return new DomainNewsEvent(requiredDomainValues);
  }

  /**
   * Handle the mouse move event for non-monotonic active domain.
   * @param position The cursor position.
   * @param plot The plot containing the cursor.
   */
  protected handleMouseMoveEventNonMonotonic(position: IPosition, plot: ProcessedPlot): (QuadTreeCursorDataPoint | undefined)[] {

    // Find the closest points to the cursor.
    let closestPoints = plot.getClosestPointsForSources(position);

    if (this.layout && this.sharedState && this.sourceData) {
      let primaryDomain = this.layout.processed.primaryDomain;
      let domainNews = this.sharedState.getDomainNews(primaryDomain.name);

      // Raise the event, where the event values are defined using the map below.
      domainNews.raise(
        new DomainNewsEvent(
          // For each source...
          this.sourceData.map((source, sourceIndex) => {
            // Find the closest point for this source.
            let p = closestPoints.find(v => !!v && v.sourceIndex === sourceIndex);
            if (p) {
              let channel = primaryDomain.sources[p.sourceIndex];
              if (channel.data) {
                // Get the SI value for the closest point for this source.
                return Units.convertValueToSi(channel.data[p.index], channel.units);
              }
            }

            return NaN;
          })));
    }

    return closestPoints;
  }
}

/**
 * A cursor data point, the plot containing the data point, and other metadata.
 */
export class PlotCursorDataPoint {

  /**
   * Creates a new cursor data point for a plot.
   * @param id The unique identifier for the cursor data point.
   * @param plot The plot containing the cursor data point.
   * @param point The cursor data point.
   */
  constructor(
    public id: string,
    public plot: ProcessedPlot,
    public point: CursorDataPoint) {
  }
}

/**
 * A cursor data point.
 */
export class CursorDataPoint {

  /**
   * Creates a new cursor data point.
   * @param x The x coordinate of the data point.
   * @param y The y coordinate of the data point.
   * @param channel The channel of the data point.
   * @param sourceIndex The index of the data source containing the point.
   */
  constructor(
    public x: number,
    public y: number,
    public channel: ProcessedMultiPlotChannel,
    public sourceIndex: number) {
  }
}

/**
 * A domain position, which may be between two data points.
 */
export class DomainPosition {

  /**
   * Creates a new domain position.
   * @param nearestIndex The index of the nearest data point.
   * @param previousIndex The index of the previous data point.
   * @param ratio The ratio of our position between the previous and next data points.
   */
  constructor(
    public readonly nearestIndex: number,
    public readonly previousIndex: number,
    public readonly ratio: number) {
  }
}

/**
 * The domain snap behaviour.
 */
export enum DomainSnapBehaviour {

  /**
   * Snap to the nearest data point.
   */
  nearest,

  /**
   * Linearly interpolate between the previous and next data points.
   */
  linearInterpolation
}

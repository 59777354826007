import * as d3 from './d3-bundle';
import { getFullyQualifiedChannelName } from './get-fully-qualified-channel-name';
import { ChannelBinaryFormat, ScalarDataItem, SingleScalarResult } from './url-file-loader';

/**
 * Base class for loading visualization related files from a URL.
 */
export abstract class UrlFileLoaderBase {

  /**
   * This function derives the channel names from the metadata,
   * rather than using the header row of the results file.
   * This is because the scalar results file didn't contain any
   * information about what sim type the channel came from, which caused
   * issues once different sims output the scalar channels with identical names.
   * The metadata contains the information we need to create fully qualified
   * channel names. We can continue using this function even once we
   * fix the header row to be fully qualified for backwards compatibility.
   * @param scalarResultRows The rows of the scalar results file.
   * @param scalarMetadata The metadata for the scalar results.
   * @returns A list of scalar data items.
   */
  public createScalarResultsUsingScalarMetadata(
    scalarResultRows: string[][],
    scalarMetadata: any[]): ScalarDataItem[] {

    if (!scalarResultRows || !scalarMetadata) {
      return [];
    }

    let result: ScalarDataItem[] = [];
    // Skip first row (headers)
    for (let rowIndex = 1; rowIndex < scalarResultRows.length; ++rowIndex) {
      let row = scalarResultRows[rowIndex];
      let resultRow: ScalarDataItem = {};
      // Skip first column (row index)
      for (let columnIndex = 1; columnIndex < row.length; ++columnIndex) {
        let metadataItem = scalarMetadata[columnIndex - 1];
        let channelKey = getFullyQualifiedChannelName(metadataItem.name, metadataItem.simType);
        resultRow[channelKey] = parseFloat(row[columnIndex]);
      }

      result.push(resultRow);
    }

    return result;
  }

  /**
   * Converts the parsed string scalar results into floating point numbers and returns
   * the results as an array of SingleScalarResult objects.
   * @param scalarResults The parsed string scalar results.
   * @returns The scalar results as an array of SingleScalarResult objects.
   */
  protected processScalarResultsForSim(scalarResults: any[]): SingleScalarResult[] {
    if (scalarResults) {
      for (let result of scalarResults) {
        result.value = result.value ? parseFloat(result.value) : 0;
      }
    }
    return scalarResults;
  }

  /**
   * Loads a JSON file from a URL.
   * @param url The URL of the JSON file.
   * @returns A promise that resolves to the JSON object.
   */
  protected loadJson(url: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      d3.json(url, (error: any, json: any) => {
        if (error) {
          if (error.target && error.target.status === 404) {
            resolve(undefined);
          } else {
            reject(error.target || error);
          }
        } else {
          resolve(json);
        }
      });
    });
  }

  /**
   * Loads a CSV file from a URL and returns the result where each row is
   * represented by an object.
   * @param url The URL of the CSV file.
   * @returns A promise that resolves to the CSV data.
   */
  public loadCsv(url: string): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      d3.csv(url, (error: any, csv: any[]) => {
        if (error) {
          if (error.target && error.target.status === 404) {
            resolve(undefined);
          } else {
            reject(error.target || error);
          }
        } else {
          resolve(csv);
        }
      });
    });
  }

  /**
   * Loads a CSV file from a URL and returns the result where each row is
   * represented by an array of strings.
   * @param url The URL of the CSV file.
   * @returns A promise that resolves to the CSV data.
   */
  public loadCsvRows(url: string): Promise<string[][]> {
    return new Promise<any[]>((resolve, reject) => {
      d3.text(url, (error: any, csv: string) => {
        if (error) {
          if (error.target && error.target.status === 404) {
            resolve(undefined);
          } else {
            reject(error.target || error);
          }
        } else {
          resolve(d3.csvParseRows(csv));
        }
      });
    });
  }

  /**
   * Loads a text file from a URL.
   * @param url The URL of the text file.
   * @returns A promise that resolves to the text.
   */
  protected loadText(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.getFileAsync(url, 'text', (file: XMLHttpRequest) => {
        if (file.status === 200) {
          resolve(file.responseText);
        } else if (file.status === 404) {
          resolve(undefined);
        } else {
          reject(file);
        }
      });
    });
  }

  /**
   * Loads a binary file from a URL and returns the result as an array of numbers.
   * @param url The URL of the binary file.
   * @param binaryFormat The binary format for the file.
   * @returns A promise that resolves to the binary data.
   */
  protected loadNumericArray(url: string, binaryFormat: ChannelBinaryFormat | undefined): Promise<ReadonlyArray<number>> {
    return new Promise((resolve, reject) => {
      this.getFileAsync(url, 'arraybuffer', (file: XMLHttpRequest) => {
        if (file.status === 200) {
          let arrayBuffer = file.response as ArrayBuffer;

          let result: ReadonlyArray<number> | undefined;
          if (binaryFormat) {
            if ((binaryFormat.pointsCount * 8) === arrayBuffer.byteLength) {
              result = [].slice.call(new Float64Array(arrayBuffer));
            } else if ((binaryFormat.pointsCount * 4) === arrayBuffer.byteLength) {
              result = [].slice.call(new Float32Array(arrayBuffer));
            } else {
              throw new Error(`Could not read expected number of points ${binaryFormat.pointsCount} from buffer of buffer length ${binaryFormat.pointsCount} for ${url}.`);
            }
          }

          if (!result) {
            result = [].slice.call(new Float64Array(arrayBuffer));
            console.warn(`Binary format metadata not found for ${url}`);
          }

          resolve(result);
        } else if (file.status === 404) {
          resolve(undefined);
        } else {
          reject(file);
        }
      });
    });
  }

  /**
   * Performs a GET request for a URL and calls a callback with the response.
   * @param url The URL to fetch.
   * @param callback The callback to call with the response of the GET request.
   */
  private getFileAsync(url: string, responseType: XMLHttpRequestResponseType, callback: (file: XMLHttpRequest) => void) {
    let file = new XMLHttpRequest();
    file.open('GET', url, true);
    file.responseType = responseType;
    file.send();
    file.onreadystatechange = function() {
      if (file.readyState === 4) {
        callback(file);
      }
    };
  }
}

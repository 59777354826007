@if (isVisible) {
  <div class="modal dialog-container" id="confirmation-dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{title}}</h4>
          <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()">
          </button>
        </div>
        <div class="modal-body">
          <p>{{message}}</p>
          @for (error of errors; track error) {
            <div class="alert alert-danger">
              {{error}}
            </div>
          }
          @for (warning of warnings; track warning) {
            <div class="alert alert-warning">
              {{warning}}
            </div>
          }
        </div>
        <div class="modal-footer">
          @if (showDismissButton) {
            <button id="dialog-dismiss-button" type="button" class="btn btn-secondary" (click)="dismiss()">{{dismissMessage}}</button>
          }
          @for (acceptMessage of acceptMessages; track acceptMessage; let i = $index) {
            <button
              id="dialog-accept-button{{acceptMessages.length > 1 ? '-' + cssSanitize(acceptMessage) : ''}}" type="button"
            class="btn btn-primary dialog-accept-button-index-{{i}}" (click)="accept(acceptMessage)">{{acceptMessage}}</button>
          }
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div>
  }<!-- /.modal -->

import {GetStudyQueryResult, StudyStub} from '../../../../generated/api-stubs';
import {LazyLoaded} from './lazy-loaded';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {Injectable} from '@angular/core';

@Injectable()
export class LazyLoadedStudyMetadataFactory {
  constructor(
    private readonly studyStub: StudyStub,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  public create(study: LazyLoaded<GetStudyQueryResult>, tenantId: string, studyId: string): LazyLoaded<GetStudyQueryResult>{
    return new LazyLoaded<GetStudyQueryResult>(
      async () => {

        if(study.isLoading){
          await study.load();
        }

        if(study.isLoaded){
          return study.value;
        }

        return await this.studyStub.getStudyMetadata(
          tenantId,
          studyId);
      },
      this.getFriendlyErrorAndLog);
  }
}

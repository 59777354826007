import { IMultiPlotSide } from './types/i-multi-plot-side';
import { ApplyChannelModifiers } from '../channel-data-loaders/apply-channel-modifiers';
import { ChannelIndexMap } from './channel-index-map';
import { GetXDomainNamesFromColumnSides } from './get-x-domain-names-from-column-sides';


/**
 * Assigns an index to each channel in the `sides` list, populating the supplied map of channel name to index.
 */
export class GetChannelIndexMapForSides {
  constructor(
    private readonly primaryDomainName: string,
    private readonly getXDomainNamesFromColumnSides: GetXDomainNamesFromColumnSides,
    private readonly applyChannelModifiers: ApplyChannelModifiers) {
  }

  /**
   * Assigns an index to each channel in the `sides` list, populating the supplied map of channel name to index.
   * @param channelIndexMap The channel index map to populate.
   * @param sides The set of sides containing the channels we want to assign indexes to.
   * @param columnSides The column sides (containing the X domain channels).
   * @returns The next channel index that would be used if there were more channels. Note this could be less than
   *          the number of entries in the ChannelIndexMap because multiple modified channels can be assigned the
   *          same index.
   */
  public execute(channelIndexMap: ChannelIndexMap, sides: ReadonlyArray<IMultiPlotSide>, columnSides: ReadonlyArray<IMultiPlotSide> | undefined): number {
    // The X domains are required for processing channels with modifiers, for example delta channels get expanded to
    // one channel per X domain.
    const xDomainNames = columnSides ? this.getXDomainNamesFromColumnSides.execute(columnSides) : [this.primaryDomainName];
    let channelIndex = 0;
    for (let side of sides) {
      for (let channel of side.channels) {
        let modifiedChannels = this.applyChannelModifiers.executeForChannelName(channel.name, channel, xDomainNames);
        if (modifiedChannels.length && !channelIndexMap.hasOwnProperty(modifiedChannels[0].channelName)) {
          for (let modifiedChannel of modifiedChannels) {
            let channelName = modifiedChannel.channelName;

            // Each modified channel for a channel is assigned the same index, so it gets colored same when
            // the user changes X domains.
            channelIndexMap[channelName] = channelIndex;
          }

          // Increment the index cor each channel (not each modified channel).
          channelIndex++;
        }
      }
    }

    // Return the next channel index, which may be less than the number of channels in the ChannelIndexMap.
    return channelIndex;
  }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { EditConfigPermissionsConfig } from './edit-config-permission.config';
import { EditConfigPermissionModel } from './edit-config-permission.model';
import { InferableRouteParams } from '../../../common/inferable-route-params.service';
import { ConfigPermissionsService } from '../services/config-permissions.service';

@Component({
  selector: 'cs-edit-config-permission',
  templateUrl: './edit-config-permission.component.html',
})
export class EditConfigPermissionComponent implements OnInit, OnDestroy {

  @Input() public tenantId: string;

  public model: EditConfigPermissionModel;

  /**
   * Dispatches the PutConfigPermission action to the store
   */
  public submit: () => Promise<void> = async () => {
    await this._configPermissionsService.putConfigPermission(
      this.tenantId,
      this.model.encryptingTenantShortName.value,
      this.model.decryptingTenantShortName.value,
      this.model.minimumSimVersion.value,
      this.model.description.value
    ).toPromise();
  };

  public get isEdit(): boolean {
    return !!(this.model.encryptingTenantShortName.value && this.model.decryptingTenantShortName.value);
  }

  public get routeParams(): any[] {
    return ['/config-permissions', this.tenantId];
  }

  constructor(
    formBuilder: UntypedFormBuilder,
    inferableRouteParams: InferableRouteParams,
    route: ActivatedRoute,
    public formConfig: EditConfigPermissionsConfig,
    private _router: Router,
    private _configPermissionsService: ConfigPermissionsService) {
      this.tenantId = inferableRouteParams.getTenantId(route);
      this.model = new EditConfigPermissionModel(this.formConfig, formBuilder);
  }

  /**
   * Handles the OnInit lifecycle event
   */
  public ngOnInit(): void {
    const selectedPermission = this._configPermissionsService.selectedPermissionSnapshot;
    if (selectedPermission) {
      this.model.patchValue(selectedPermission);
    }
  }

  /**
   * Handles the OnDestroy lifecycle event
   */
  public ngOnDestroy(): void {
    this._configPermissionsService.clearConfigPermission();
  }

  /**
   * Navigates back to the list view
   */
  public async onSubmitted(success: boolean): Promise<void> {
    if (success) {
      this._navigateToListView();
    }
  }

  /**
   * Navigates back to the list view
   */
  private _navigateToListView(): void {
    this._router.navigate(this.routeParams);
  }

}

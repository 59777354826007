import {CustomProperty} from './custom-properties';
import {sortBy} from '../../common/sort-by';

export class CustomPropertyUtilities {
  public static objectToList(input: any){
    let list = [];

    if(input){
      for(let name in input){
        if(!input.hasOwnProperty(name)) {
          continue;
        }

        let value = input[name];
        list.push({
          name,
          value,
          hasPeriod: name.indexOf('.') !== -1
        });
      }

      list.sort(sortBy('hasPeriod', 'name'));
    }

    let output: CustomProperty[] = list.map(v => ({ name: v.name, value: v.value }));

    return output;
  }

  public static listToObject(input: ReadonlyArray<CustomProperty>): any{
    let output: { [name: string]: string } = {};
    for(let item of input || []){
      output[item.name] = item.value;
    }

    return output;
  }
}

/*
 * Usage:
 *   value | toDateTime
 * Example:
 *   {{ <date> |  toDateTime }}
 *   formats to: 2.12
 */
import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';

@Pipe({name: 'toDateTime'})
export class ToDateTimePipe implements PipeTransform {

  private readonly datePipe = new DatePipe('en-US');

  transform(value: any): any {
    return this.datePipe.transform(value, 'd MMMM y, HH:mm:ss z');
  }
}

@if (isLoaded) {
  <div>
    @if (configType.singularKey === 'car') {
      <cs-car-config-validator
        [tryGetEditorValue]="tryGetEditorValue"
        [simVersion]="simVersion"
        [userId]="userId"
        [configId]="configId"
        [name]="configName"
        [isEdited]="isEdited"
        [customProperties]="customProperties"
      ></cs-car-config-validator>
    }
  </div>
}
<div id="view-config-page">
  <div class="container-fluid">
    <div class="card">
      <h1 class="card-header">
        View {{configType.titleName}}
        @if (isStaged) {
          <small id="staged-sub-title" class="text-body-secondary">Staged</small>
        }
        @if (isDefault) {
          <small id="default-sub-title" class="text-body-secondary">Default Config</small>
        }
        <div class="card-header-float-right">
          @if (supportSession) {
            <cs-support-session-launcher
              [tenantId]="tenantId" [documentId]="configId"
              [documentType]="'config'" [documentSubType]="configType.singularKey"
              [supportSession]="supportSession"
            (supportSessionChanged)="onSupportSessionChanged($event)"></cs-support-session-launcher>
          }
        </div>
      </h1>

      <div class="card-body">
        <p>
          <a id="back-button" class="btn btn-smart btn-secondary" [routerLink]="isCurrentTenant ? ['/configs', configType.pluralKey] : ['/support-sessions']">
            <i class="fa fa-caret-left"></i>Back
          </a>
          <a id="edit-button" class="btn btn-smart btn-secondary"
            [routerLink]="isStaged ? ['/staged', configType.singularKey, 'edit'] : ['/configs', configType.pluralKey, tenantId, configId, 'edit']">
            <i class="fa fa-edit"></i>Edit
          </a>
          @if (!isStaged) {
            <button id="stage-button" class="btn btn-smart btn-secondary" (click)="stage()" [disabled]="!isLoaded">
              <i class="fa fa-arrow-circle-up"></i>Stage Snapshot
            </button>
          }
          <button id="compare-to-staged-button" class="btn btn-smart btn-secondary" (click)="compareToStaged()" [disabled]="!isLoaded || isStaged">
            <i class="fa fa-files-o"></i>Compare to Staged
          </button>
          @if (isLoaded) {
            <a id="download-button" class="btn btn-smart btn-secondary"
              [href]="bypassSecurityTrustUrl('data:application/json;charset=utf-8,' + configDataEncoded)"
              target="_blank"
              download="{{fileNameSanitize(configName)}}.json">
              <i class="fa fa-arrow-circle-down"></i>Download
            </a>
          }
        </p>

        <cs-error-message [message]="errorMessage"></cs-error-message>

        @if (!isLoaded && !errorMessage) {
          <cs-loader></cs-loader>
        }

        @if (isLoaded) {
          <div>
            <h2 id="config-name">{{configName}}</h2>
            <pre id="config-json" class="raw-json debug-information">{{configDataFormatted}}</pre>
            <div id="config-data" #config></div>
            <cs-view-custom-properties [customProperties]="customProperties"></cs-view-custom-properties>
          </div>
        }
      </div>
    </div>

    @if (configNotes) {
      <div class="card">
        <h1 class="card-header">
          {{configType.titleName}} Notes
        </h1>
        <div class="card-body config-notes-content">
          <pre class="raw-json last-item">{{configNotes}}</pre>
        </div>
      </div>
    }
  </div>

  <div id="view-config-json-editor" style="display: none"></div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { ConfigOrConfigLoader } from '../comparing/config-or-config-loader';
import { ExportConfigService } from '../export-config.service';
import { DownloadType } from '../../studies/download-study.service';

@Component({
  selector: 'cs-export-config-buttons',
  templateUrl: './export-config-buttons.component.html'
})
export class ExportConfigButtonsComponent implements OnInit {
  @Input() public isAdministrator: boolean;
  @Input() public getConfigOrConfigLoader: () => ConfigOrConfigLoader;

  configDownloadTypes: DownloadType[];

  constructor(
    private readonly exportConfig: ExportConfigService
  ){}

  ngOnInit(){
    this.configDownloadTypes = this.exportConfig.downloadConfigTypes(this.getConfigOrConfigLoader);
  }
}

@if (isVisible) {
  <div class="modal dialog-container" id="loading-dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          @if (session?.isLoading) {
            <div>
              <p>{{session.status || 'Loading...'}}</p>
              <cs-loader></cs-loader>
            </div>
          }
          <cs-error-message [message]="errorMessage"></cs-error-message>
        </div>
        @if (!session?.isLoading) {
          <div class="modal-footer">
            <button id="dialog-dismiss-button" type="button" class="btn btn-secondary" (click)="dismiss()">Close</button>
          </div>
        }
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div>
  }<!-- /.modal -->

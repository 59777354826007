import {
  ListFilterData, ListFilterGroup, OrderByProperty,
} from '../../../generated/api-stubs';
import {Injectable} from '@angular/core';

@Injectable()
export class FilterUtilities {
  constructor(){
  }

  public getInitialFilter(defaultOrderByProperty: OrderByProperty = OrderByProperty.modifiedDate, orderByDescending: boolean = true, query?: ListFilterGroup) {
    let filter: ListFilterData = {};
    filter.orderByProperty = defaultOrderByProperty;
    filter.orderByDescending = orderByDescending;
    filter.query = query;
    return filter;
  }
}

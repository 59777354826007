export class FetchKeyPermissions {
  static readonly type = '[KeyPermissions] FetchKeyPermissions';
  constructor(public tenantId: string) {}
}

export class SetKeyPermission {
  static readonly type = '[KeyPermissions] SetKeyPermission';
  constructor(
    public encryptingTenantShortName: string,
    public minimumSimVersion: string,
    public description: string) {}
}

export class ClearKeyPermission {
  static readonly type = '[KeyPermissions] ClearKeyPermission';
  constructor() {}
}

export class PutKeyPermission {
  static readonly type = '[KeyPermission] PutKeyPermission';
  constructor(
    public tenantId: string,
    public encryptingTenantShortName: string,
    public minimumSimVersion: string,
    public description: string) {}
}

export class DeleteKeyPermission {
  static readonly type = '[KeyPermissions] DeleteKeyPermission';
  constructor(
    public tenantId: string,
    public encryptingTenantShortName: string) {}
}

import {SimVersionStub} from '../../generated/api-stubs';
import {Injectable} from '@angular/core';
import {LogError} from './log-error.service';
import {ServiceOnInit} from './service-on-init';
import {GetSimVersionBase} from './get-sim-version-base';
import { AuthenticationService } from '../identity/state/authentication.service';

@Injectable()
export class GetTenantSimVersion extends GetSimVersionBase implements ServiceOnInit {

  constructor(
    logError: LogError,
    authenticationService: AuthenticationService,
    private readonly simVersionStub: SimVersionStub) {
    super(logError, authenticationService);
  }

  public async executeForTenant(tenantId: string): Promise<string> {
    if(!tenantId){
      return undefined;
    }

    if(tenantId === this.userData.tenant){
      return await this.execute();
    }

    return await this.simVersionStub.getSimVersion(tenantId);
  }

  protected loadSimVersion(): Promise<string> {
    return this.simVersionStub.getSimVersion(this.userData.tenant);
  }
}

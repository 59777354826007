import { Injectable } from '@angular/core';
import { RowMetadataViewModel } from '../../row-metadata-view-model';
import { CommandContext, CommandResult, ICommandContext, WorksheetCommand } from '../worksheet-command';
import { ConfirmReplaceAllReferences } from '../confirm-replace-all-references.service';
import { ReplaceResult } from '../../worksheet-item-component-base';
import { ButtonMenuItem, MenuItem } from '../../../context-menu/context-menu-types';
import { StudyStagingArea } from '../../../simulations/study-staging-area/study-staging-area.service';
import { ImportConfigFromStagingArea } from './import-config-from-staging-area';
import { GetConfigsToImport } from './get-configs-to-import';
import { UpdateRowNames } from './update-row-names';
import { ConfigViewModel } from '../../config-view-model';
import { AuthenticationService } from '../../../identity/state/authentication.service';

/**
 * A command that imports staged configs onto the selected worksheet rows.
 */
@Injectable()
export class ImportStagedCommand extends WorksheetCommand {

  /**
   * Creates an instance of ImportStagedCommand.
   * @param authenticationService The authentication service.
   * @param confirmReplaceAllReferences The service for confirming the replacement of all references.
   * @param studyStagingArea The study staging area.
   * @param getConfigsToImport The service for getting the configs to import.
   * @param importConfigFromStagingArea The service for importing a config from the staging area.
   * @param updateRowNames The service for updating row names.
   */
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly confirmReplaceAllReferences: ConfirmReplaceAllReferences,
    private readonly studyStagingArea: StudyStagingArea,
    private readonly getConfigsToImport: GetConfigsToImport,
    private readonly importConfigFromStagingArea: ImportConfigFromStagingArea,
    private readonly updateRowNames: UpdateRowNames) {
    super();
  }

  /**
  * Determines if the command can be executed.
  * @param context The command context.
  * @returns True if the command can be executed, otherwise false.
  */
 public canExecute(context: CommandContext): context is ICommandContext<RowMetadataViewModel | ConfigViewModel> {
    const userData = this.authenticationService.userDataSnapshot;
    if (!context.worksheet.canWrite(userData.sub)) {
      return false;
    }

    if (context.isRowMetadata()) {
      return true;
    }

    if (context.isConfig() && !!this.studyStagingArea.state.getInput(context.target.configType)) {
      return true;
    }

    return false;
  }

  /**
   * Executes the command.
   * @param context The command context.
   * @returns The command result.
   */
  public async execute(context: CommandContext): Promise<CommandResult> {
    if (!this.canExecute(context)) {
      return CommandResult.NoUpdate;
    }

    const replaceResult = await this.confirmReplaceAllReferences.execute(context.target, context.selectedRows);
    if (replaceResult === ReplaceResult.cancel) {
      return CommandResult.NoUpdate;
    }

    const state = this.studyStagingArea.state;

    let configsToImport = await this.getConfigsToImport.execute(context, state.studyType);
    for (const configToImport of configsToImport) {
      await this.importConfigFromStagingArea.execute(state, configToImport, context, replaceResult);
    }

    this.updateRowNames.execute(context);

    return CommandResult.UpdateAndGenerateColumns;
  }

  /**
   * Creates the menu items for the command.
   * @param context The command context.
   * @param result The menu items.
   */
  public createMenuItems(context: CommandContext, result: MenuItem<CommandResult>[]): void {
    if (!this.canExecute(context)) {
      return;
    }

    result.push(new ButtonMenuItem<CommandResult>(
      `Import Staged`,
      `import-staged-button`,
      () => this.execute(context),
      'arrow-circle-down'));
  }
}

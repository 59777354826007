<div class="view-custom-properties">
  @if (customProperties && customProperties.length && showDivider) {
    <hr>
    }
    @for (cp of customProperties; track cp) {
      <div class="custom-property">
        <div class="name-box">{{cp.name}}</div><div class="value-box">{{cp.value}}</div>
      </div>
    }
  </div>

import {OnInit, OnDestroy} from '@angular/core';
import {Component} from '@angular/core';
import {
  StudyStub,
  GetStudyJobsQueryResult, ListFilterData, OrderByProperty, StudyJobState, DocumentCustomPropertyGroup,
  StudyType, JobDocument
} from '../../../../generated/api-stubs';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {InferableRouteParams} from '../../../common/inferable-route-params.service';
import {GetUsernameMap, UsernameMap} from '../../get-username-map.service';
import {Dayjs} from '../../../common/dayjs.service';
import {cssSanitize} from '../../../common/css-sanitize';
import {STUDY_ID_URL_PARAMETER_KEY} from '../../../common/constants';
import {ResultsStagingArea} from '../../results-staging-area/results-staging-area.service';
import {ResultSource} from '../../results-staging-area/results-staging-area.service';
import {CanopyJson, FlattenedJsonItem} from '../../../common/canopy-json.service';
import {CanopyPusher, StudiesProgress} from '../../../common/canopy-pusher.service';
import {FilterUtilities} from '../../list-filtering/filter-utilities';
import {POST_PROCESSOR_JOB_NAME} from '../view-job/view-job.component';
import {getExecutionTimeString} from '../../../common/get-execution-time-string';
import {ActivatedRoute} from '@angular/router';
import {ViewJobDialog} from '../view-job-dialog/view-job-dialog.service';
import {GoToJob} from '../go-to-job.service';
import {getJobIndexFromJobId} from '../../../common/get-job-index-from-job-id';
import {StageStudy} from '../../studies/stage-study.service';
import {SignificantSimVersions} from '../../../common/significant-sim-versions.service';
import {Subscription} from 'rxjs';
import { AuthenticationService, UserData } from '../../../identity/state/authentication.service';

export const REFRESH_FOR_FAILURE_REASON = 'Refresh list for failure reason.';

@Component({
  templateUrl: './view-jobs.page.html',
  styleUrls: ['./view-jobs.page.scss']
})
export class ViewJobsPage implements OnInit, OnDestroy {
  public errorMessage: string;
  public tenantId: string;
  public studyId: string;

  public studyName: string;
  public studyType: StudyType;
  public jobCount: number;
  public incompleteJobCount: number;
  public jobs: JobSummary[];
  public usernameMap: UsernameMap;
  public lastResult: GetStudyJobsQueryResult;
  public isReloading: boolean;

  public userData: UserData;
  public filter: ListFilterData;

  public studiesProgressSubscription: Subscription;
  public loadTask: Promise<any>;

  constructor(
    route: ActivatedRoute,
    inferableRouteParams: InferableRouteParams,
    filterUtilities: FilterUtilities,
    private readonly authenticationService: AuthenticationService,
    private readonly dayjs: Dayjs,
    private readonly json: CanopyJson,
    private readonly studyStub: StudyStub,
    private readonly getUsernameMap: GetUsernameMap,
    private readonly resultsStagingArea: ResultsStagingArea,
    private readonly canopyPusher: CanopyPusher,
    private readonly viewJobDialog: ViewJobDialog,
    private readonly goToJobService: GoToJob,
    private readonly stageStudy: StageStudy,
    public readonly significantSimVersions: SignificantSimVersions,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
    this.tenantId = inferableRouteParams.getTenantId(route);
    this.studyId = route.snapshot.params[STUDY_ID_URL_PARAMETER_KEY];
    this.userData = this.authenticationService.userDataSnapshot;

    this.filter = filterUtilities.getInitialFilter(OrderByProperty.index, false);
  }

  public ngOnInit() {
    this.studiesProgressSubscription = this.canopyPusher.studiesProgress.subscribe((data: StudiesProgress) => this.onStudiesProgress(data));
    this.loadTask = this.load();
  }

  public ngOnDestroy() {
    if (this.studiesProgressSubscription) {
      this.studiesProgressSubscription.unsubscribe();
    }
  }

  public async showJob(job: JobSummary){
    try{
      await this.viewJobDialog.show(job.tenantId, job.studyId, job.jobId);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public get getLoadGroupResultsDelegate(): () => Promise<DocumentCustomPropertyGroup[]>{
    return () => this.loadGroupResults();
  }

  public async loadGroupResults(): Promise<DocumentCustomPropertyGroup[]>{
    if(this.loadTask){
      try{
        await this.loadTask;
      } catch(e){}
    }

    if(this.lastResult){
      return this.lastResult.groupResults;
    }

    return [];
  }

  public async reloadFilter(){
    try {
      if(this.loadTask){
        try{
          await this.loadTask;
        } catch(e){}
      }

      this.isReloading = true;
      this.errorMessage = undefined;

      let getStudyJobsTask = this.loadTask = this.studyStub.getStudyJobs(this.tenantId, this.studyId, this.filter);
      let jobsResult = await getStudyJobsTask;
      this.jobs = [];
      this.addQueryResults(jobsResult);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
    this.isReloading = false;
  }

  public async load() {
    try {
      let usernameMapTask = this.getUsernameMap.execute(this.tenantId);
      let studyResultTask = this.studyStub.getStudyMetadata(this.tenantId, this.studyId);
      this.usernameMap = await usernameMapTask;
      let studyResult = await studyResultTask;

      this.studyName = studyResult.study.name;
      this.studyType = studyResult.study.data.studyType;
      this.jobCount = studyResult.study.data.dispatchedJobCount;
      this.incompleteJobCount = studyResult.study.data.dispatchedJobCount - studyResult.study.data.completedJobCount;

      this.jobs = [];
      this.isReloading = true;
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async loadNext() {
    try {
      if(this.loadTask){
        await this.loadTask;
      }

      if(!this.lastResult.queryResults.hasMoreResults){
        return;
      }

      this.isReloading = true;
      this.filter.continuationToken = this.lastResult.queryResults.continuationToken;
      let getStudyJobsTask = this.loadTask = this.studyStub.getStudyJobs(this.tenantId, this.studyId, this.filter);
      let jobsResult = await getStudyJobsTask;
      this.addQueryResults(jobsResult);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
    this.isReloading = false;
  }

  public addQueryResults(jobsResult: GetStudyJobsQueryResult) {
    this.lastResult = jobsResult;

    this.jobs.push(...jobsResult.queryResults.documents.map(s => new JobSummary(
      s.tenantId,
      s.userId,
      s.data.studyId,
      s.documentId,
      s.data.index,
      s.name,
      this.usernameMap[s.userId] || s.userId,
      this.dayjs.fromNow(s.creationDate),
      s.creationDate,
      this.dayjs.fromNow(s.modifiedDate),
      s.data.state === StudyJobState.successful,
      s.data.state === StudyJobState.failed,
      getExecutionTimeString(s.data.executionTimeSeconds),
      s.data.errorMessages,
      this.getChanges(s.data),
      s.userId === this.userData.sub,
      s.name === POST_PROCESSOR_JOB_NAME,
      s.data.computeCredits,
      s.data.storageCredits
    )));
  }

  private getChanges(data: JobDocument){
    if(data.definition){
      return this.json.flatten(data.definition);
    } else if(data.changes){
      return data.changes.map(v => ({ key: v.path, value: v.savedConfig ? v.savedConfig.name : v.value }));
    }

    return [];
  }

  public filterChanged(newFilter: ListFilterData){
    this.filter = newFilter;
    //this.location.replaceState('/studies/' + this.tenantId + '/' + this.userId + '/' + this.studyId + '/' + 'jobs' + '?filter=' + this.rison.encodeObject(newFilter));
    this.reloadFilter();
  }

  public async onStudiesProgress(data: StudiesProgress){
    try {
      if(this.loadTask){
        await this.loadTask;
      }

      if(!this.jobs){
        return;
      }

      for(let item of data.items) {

        if(item.studyId !== this.studyId){
          continue;
        }

        this.incompleteJobCount = this.jobCount - item.completedJobCount;

        for(let successfulJob of item.successfulJobs) {
          let job = this.jobs.find(v => v.index === successfulJob.i);
          if(job){
            job.isSuccessful = true;
            job.executionTime = getExecutionTimeString(successfulJob.t / 1000);
          }
        }

        for(let failedJob of item.failedJobs) {
          let job = this.jobs.find(v => v.index === failedJob.i);
          if(job) {
            job.isFailed = true;
            job.executionTime = getExecutionTimeString(failedJob.t / 1000);
            job.errorMessages = [REFRESH_FOR_FAILURE_REASON];
          }
        }
      }
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public cssSanitize(input: string): string {
    return cssSanitize(input);
  }

  public stageToResultsStagingArea(job: JobSummary){
    try {
      this.resultsStagingArea.addSource(
        new ResultSource(this.tenantId, this.studyId, this.studyName, job.jobId, job.name));
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async stageToStudyStagingArea(job: JobSummary) {
    try {
      await this.stageStudy.toStudyStagingArea(
        this.tenantId, this.studyId, this.studyName, this.jobCount, getJobIndexFromJobId(job.jobId));
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async goToJob(){
    try {
      await this.goToJobService.execute(this.tenantId, this.studyId, this.jobCount);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public trackById(index: number, item: JobSummary) {
    return item.index;
  }
}

export class JobSummary {
  constructor(
    public tenantId: string,
    public userId: string,
    public studyId: string,
    public jobId: string,
    public index: number,
    public name: string,
    public username: string,
    public creationDate: string,
    public creationDateRaw: string,
    public modifiedDate: string,
    public isSuccessful: boolean,
    public isFailed: boolean,
    public executionTime: string,
    public errorMessages: string[],
    public flattenedDefinition: FlattenedJsonItem[],
    public isOwner: boolean,
    public isPostProcessor: boolean,
    public computeCredits: number,
    public storageCredits: number
  ){}
}

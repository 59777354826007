import {EventEmitter, Injectable, NgZone} from '@angular/core';
import {CanopyPusher, StudiesProgress} from '../common/canopy-pusher.service';
import {ServiceOnInit} from '../common/service-on-init';
import {StudyMetadataCache} from './study-metadata-cache.service';
import {CanopyNotifications} from './canopy-notifications.service';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StudyNotifier implements ServiceOnInit {
  private studyCompletedEvent: EventEmitter<StudyEvent> = new EventEmitter<StudyEvent>();

  constructor(
    private readonly notifications: CanopyNotifications,
    private readonly studyMetadataCache: StudyMetadataCache,
    private readonly router: Router,
    private readonly zone: NgZone,
    private readonly pusher: CanopyPusher) {
  }

  serviceOnInit(): void {
    this.pusher.studiesProgress.subscribe((p: StudiesProgress) => this.onStudiesProgress(p));
  }

  public get studyCompleted(): Observable<StudyEvent> {
    return this.studyCompletedEvent;
  }

  public emit(tenantId: string, studyId: string) {
    this.studyCompletedEvent.emit(new StudyEvent(tenantId, studyId));
  }

  public onStudiesProgress(progress: StudiesProgress){
    this.processStudies(progress);
  }

  public async processStudies(progress: StudiesProgress){
    try {
      for(let item of progress.items) {
        const studyMetadata = await this.studyMetadataCache.get(item.studyId);
        if(studyMetadata){
          studyMetadata.progress = item;
          if(item.completedJobCount > 0 && item.jobCount === item.completedJobCount){
            try{
              this.emit(studyMetadata.tenantId, studyMetadata.studyId);
            } catch(emitError){
              console.error(emitError);
            }

            this.notifications.notify(
              'Completed: ' + studyMetadata.name,
              `The study completed with ${item.succeededJobCount} successful job(s) and ${item.completedJobCount - item.succeededJobCount} failure(s).`,
              () => this.zone.run(() => this.router.navigate(['/studies', studyMetadata.tenantId, studyMetadata.studyId]))
              );
            this.studyMetadataCache.remove(item.studyId);
          }
        }
      }
    } catch(error){
      console.error(error);
    }
  }
}

export class StudyEvent {
  constructor(
    public readonly tenantId: string,
    public readonly studyId: string){
  }
}

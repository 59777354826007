import * as d3 from '../../d3-bundle';
import { MonotonicStatus } from '../channel-data-loaders/viewer-channel-data';
import { CalculationType } from './zoom-renderer';
import { DomainSnapBehaviour } from './multi-plot-data-renderer-base';
import { GetInterpolatedChannelValueAtDomainValue } from '../channel-data-loaders/get-interpolated-channel-value-at-domain-value';

/**
 * Interface for a channel one which we can do calculations.
 */
export interface ICalculationChannel {

  /**
   * The data of the channel.
   */
  readonly data?: ReadonlyArray<number>;

  /**
   * Whether the channel is monotonic.
   */
  readonly isMonotonic: boolean;

  /**
   * The monotonic status of the channel (increasing / decreasing).
   */
  readonly monotonicStatus: MonotonicStatus;
}

/**
 * Interface for a channel one which we can do calculations, where the data is definitely present.
 */
export interface ICalculationChannelWithData extends ICalculationChannel {

  /**
   * The data of the channel.
   */
  readonly data: ReadonlyArray<number>;

  /**
   * Whether the channel is monotonic.
   */
  readonly isMonotonic: boolean;

  /**
   * The monotonic status of the channel (increasing / decreasing).
   */
  readonly monotonicStatus: MonotonicStatus;
}

/**
 * Class to get channel calculation results (min/max/mean/etc.).
 */
export class GetChannelCalculationResult {

  /**
   * Constructor for GetChannelCalculationResult.
   * @param getInterpolatedChannelValueAtDomainValue Gets the interpolated channel value at the specified domain value.
   */
  constructor(private readonly getInterpolatedChannelValueAtDomainValue: GetInterpolatedChannelValueAtDomainValue) {
  }

  /**
   * Create a new GetChannelCalculationResult.
   */
  public static create(): GetChannelCalculationResult {
    return new GetChannelCalculationResult(new GetInterpolatedChannelValueAtDomainValue());
  }

  /**
   * Execute a calculation on the specified channels.
   * @param calculationType The calculation type.
   * @param domainSnapBehaviour The domain snap behaviour.
   * @param xChannel The x channel.
   * @param yChannel The y channel.
   * @param integrationChannel The integration channel.
   * @param xDomainBounds The x domain bounds on which to perform the calculation.
   * @param yDomainBounds The y domain bounds on which to perform the calculation.
   * @param isSelectionReversedX Whether the selection is reversed in the x domain.
   * @returns The result of the calculation.
   */
  public execute(
    calculationType: CalculationType,
    domainSnapBehaviour: DomainSnapBehaviour,
    xChannel: ICalculationChannel | undefined,
    yChannel: ICalculationChannel | undefined,
    integrationChannel: ICalculationChannel | undefined,
    xDomainBounds: [number, number],
    yDomainBounds: [number, number],
    isSelectionReversedX: boolean = false): number {

    // If any of the channels are missing, return NaN.
    if (!xChannel || !yChannel || !xChannel.data || !yChannel.data) {
      return NaN;
    }

    const xChannelWithData = xChannel as ICalculationChannelWithData;
    const yChannelWithData = yChannel as ICalculationChannelWithData;

    // Perform the calculation based on the calculation type.
    switch (calculationType) {
      case CalculationType.min:
      case CalculationType.max:
      case CalculationType.delta:
      case CalculationType.range:
        return this.executeBasicCalculation(calculationType, xChannelWithData, yChannelWithData, xDomainBounds, yDomainBounds, isSelectionReversedX);

      case CalculationType.mean:
        return this.getMean(calculationType, domainSnapBehaviour, xChannelWithData, yChannelWithData, integrationChannel, xDomainBounds, yDomainBounds);

      case CalculationType.gradient:
      case CalculationType.integral:
        return this.getIntegralOrGradient(calculationType, domainSnapBehaviour, xChannelWithData, yChannelWithData, integrationChannel, xDomainBounds, yDomainBounds);
      default:
        throw new Error('Unexpected calculation type: ' + calculationType);
    }
  }

  /**
   * Get the integral or gradient of the specified channel.
   * @param calculationType The calculation type.
   * @param domainSnapBehaviour The domain snap behaviour.
   * @param xChannel The x channel.
   * @param yChannel The y channel.
   * @param integrationChannel The integration channel.
   * @param xDomainBounds The x domain bounds on which to perform the calculation.
   * @param yDomainBounds The y domain bounds on which to perform the calculation.
   * @returns The result of the calculation.
   */
  public getIntegralOrGradient(
    calculationType: CalculationType,
    domainSnapBehaviour: DomainSnapBehaviour,
    xChannel: ICalculationChannelWithData,
    yChannel: ICalculationChannelWithData,
    integrationChannel: ICalculationChannel | undefined,
    xDomainBounds: [number, number],
    yDomainBounds: [number, number]): number {

      if (!xChannel || !yChannel || !xChannel.data || !yChannel.data) {
        return NaN;
      }

      if (!integrationChannel || !integrationChannel.data) {
        return NaN;
      }

      const integrationChannelWithData = integrationChannel as ICalculationChannelWithData;

      if (xChannel.isMonotonic
        && yDomainBounds[0] === -Infinity && yDomainBounds[1] === Infinity
        && domainSnapBehaviour === DomainSnapBehaviour.linearInterpolation) {

        let integrationDomainBounds = [
          this.getInterpolatedChannelValueAtDomainValue.execute(integrationChannel.data, xDomainBounds[0], xChannel.data, xChannel.monotonicStatus),
          this.getInterpolatedChannelValueAtDomainValue.execute(integrationChannel.data, xDomainBounds[1], xChannel.data, xChannel.monotonicStatus),
        ];

        let minimum = d3.minStrict(integrationDomainBounds);
        let maximum = d3.maxStrict(integrationDomainBounds);
        let range = maximum - minimum;

        if (range === 0) {
          return NaN;
        }
        switch (calculationType) {
          case CalculationType.integral:
            return this.getIntegralWithContinuousTrapezoidRule(integrationChannelWithData, yChannel, minimum, maximum);
          case CalculationType.gradient:
            return this.executeBasicCalculation(CalculationType.gradient, integrationChannelWithData, yChannel, [integrationDomainBounds[0], integrationDomainBounds[1]], yDomainBounds);
        }
      }

      return NaN;
  }

  /**
   * Get the mean of the specified channel.
   * @param calculationType The calculation type.
   * @param domainSnapBehaviour The domain snap behaviour.
   * @param xChannel The x channel.
   * @param yChannel The y channel.
   * @param integrationChannel The integration channel.
   * @param xDomainBounds The x domain bounds on which to perform the calculation.
   * @param yDomainBounds The y domain bounds on which to perform the calculation.
   * @returns The result of the calculation.
   */
  public getMean(
    calculationType: CalculationType,
    domainSnapBehaviour: DomainSnapBehaviour,
    xChannel: ICalculationChannelWithData,
    yChannel: ICalculationChannelWithData,
    integrationChannel: ICalculationChannel | undefined,
    xDomainBounds: [number, number],
    yDomainBounds: [number, number]): number {

    if (!xChannel || !yChannel || !xChannel.data || !yChannel.data) {
      return NaN;
    }

    if (!integrationChannel || !integrationChannel.data) {
      return this.executeBasicCalculation(calculationType, xChannel, yChannel, xDomainBounds, yDomainBounds);
    }

    const integrationChannelWithData = integrationChannel as ICalculationChannelWithData;

    if (xChannel.isMonotonic
      && yDomainBounds[0] === -Infinity && yDomainBounds[1] === Infinity
      && domainSnapBehaviour === DomainSnapBehaviour.linearInterpolation) {

      // Calculate using exact bounds and trapezoid rule.
      return this.getMeanWithContinuousTrapezoidRule(xChannel, yChannel, integrationChannelWithData, xDomainBounds);
    }

    // Calculate using discreet data and midpoint rule.
    return this.getMeanWithDiscreetMidpointRule(xChannel, yChannel, integrationChannelWithData, xDomainBounds, yDomainBounds);
  }

  /**
   * Get the mean of the specified channel using the trapezoid rule.
   * @param xChannel The x channel.
   * @param yChannel The y channel.
   * @param integrationChannel The integration channel.
   * @param xDomainBounds The x domain bounds on which to perform the calculation.
   * @returns The mean of the specified channel.
   */
  public getMeanWithContinuousTrapezoidRule(
    xChannel: ICalculationChannelWithData,
    yChannel: ICalculationChannelWithData,
    integrationChannel: ICalculationChannelWithData,
    xDomainBounds: [number, number]): number {

    if (!xChannel.isMonotonic) {
      throw new Error('X Domain should be monotonic when evaluating mean with trapezoid rule.');
    }

    if (!integrationChannel || !integrationChannel.data) {
      throw new Error('Integration channel data must be supplied when evaluating mean with trapezoid rule.');
    }

    let integrationDomainBounds = [
      this.getInterpolatedChannelValueAtDomainValue.execute(integrationChannel.data, xDomainBounds[0], xChannel.data, xChannel.monotonicStatus),
      this.getInterpolatedChannelValueAtDomainValue.execute(integrationChannel.data, xDomainBounds[1], xChannel.data, xChannel.monotonicStatus),
    ];

    let minimum = d3.minStrict(integrationDomainBounds);
    let maximum = d3.maxStrict(integrationDomainBounds);
    let range = maximum - minimum;

    if (range === 0) {
      return NaN;
    }

    let integral = this.getIntegralWithContinuousTrapezoidRule(integrationChannel, yChannel, minimum, maximum);

    return integral / range;
  }

  /**
   * Get the integral of the specified channel with the trapezoid rule.
   * @param integrationChannel The integration channel.
   * @param yChannel The y channel.
   * @param minimum The minimum value of the integration channel bounds.
   * @param maximum The maximum value of the integration channel bounds.
   * @returns The integral of the specified channel.
   */
  public getIntegralWithContinuousTrapezoidRule(
    integrationChannel: ICalculationChannelWithData,
    yChannel: ICalculationChannelWithData,
    minimum: number,
    maximum: number) {

    if (!integrationChannel || !integrationChannel.data) {
      throw new Error('Integration channel data must be supplied when evaluating integral with trapezoid rule.');
    }

    if (!integrationChannel.isMonotonic) {
      throw new Error('Integration channel should be monotonic when evaluating integral with trapezoid rule.');
    }

    // Ensure the integration domain is monotonically increasing.
    let reverseData = integrationChannel.monotonicStatus === MonotonicStatus.Decreasing;

    let integrationChannelData: ReadonlyArray<number>;
    let yData: ReadonlyArray<number>;
    if (reverseData) {
      integrationChannelData = [...integrationChannel.data].reverse();
      yData = [...yChannel.data].reverse();
    } else {
      integrationChannelData = integrationChannel.data;
      yData = yChannel.data;
    }

    if (maximum < integrationChannelData[0] || minimum > integrationChannelData[integrationChannelData.length - 1]) {
      return 0;
    }

    let yValueAtMinimum = this.getInterpolatedChannelValueAtDomainValue.execute(yData, minimum, integrationChannelData, MonotonicStatus.Increasing);
    let yValueAtMaximum = this.getInterpolatedChannelValueAtDomainValue.execute(yData, maximum, integrationChannelData, MonotonicStatus.Increasing);

    let startIndexInclusive = this.getStartIndexInclusive(minimum, integrationChannelData);
    let endIndexExclusive = this.getEndIndexExclusive(maximum, integrationChannelData);

    if (startIndexInclusive === endIndexExclusive) {
      // We are between two points.
      return (yValueAtMinimum + yValueAtMaximum) * (maximum - minimum) / 2;
    }

    let integral = (yData[startIndexInclusive] + yValueAtMinimum) * (integrationChannelData[startIndexInclusive] - minimum) / 2;

    for (let i = startIndexInclusive; i < endIndexExclusive - 1; ++i) {
      integral += (yData[i] + yData[i + 1]) * (integrationChannelData[i + 1] - integrationChannelData[i]) / 2;
    }

    integral += (yData[endIndexExclusive - 1] + yValueAtMaximum) * (maximum - integrationChannelData[endIndexExclusive - 1]) / 2;

    return integral;
  }

  /**
   * Get the index equal to or greater than the specified value.
   * @param minimum The minimum value.
   * @param integrationChannelData The integration channel data.
   * @returns The index equal to or greater than the specified value.
   */
  public getStartIndexInclusive(minimum: number, integrationChannelData: ReadonlyArray<number>) {
    let startIndexInclusive: number;
    if (minimum < integrationChannelData[0]) {
      startIndexInclusive = 0;
    } else if (minimum > integrationChannelData[integrationChannelData.length - 1]) {
      return integrationChannelData.length;
    } else {
      startIndexInclusive = integrationChannelData.findIndex(v => v >= minimum);
    }
    return startIndexInclusive;
  }

  /**
   * Get the index less than the specified value.
   * @param maximum The maximum value.
   * @param integrationChannelData The integration channel data.
   * @returns The index less than the specified value.
   */
  public getEndIndexExclusive(maximum: number, integrationChannelData: ReadonlyArray<number>) {
    let endIndexExclusive: number;
    if (maximum >= integrationChannelData[integrationChannelData.length - 1]) {
      endIndexExclusive = integrationChannelData.length;
    } else if (maximum < integrationChannelData[0]) {
      return 0;
    } else {
      endIndexExclusive = integrationChannelData.findIndex(v => v > maximum);
    }
    return endIndexExclusive;
  }

  /**
   * Get the mean of the specified channel with the midpoint rule.
   * @param xChannel The x channel.
   * @param yChannel The y channel.
   * @param integrationChannel The integration channel.
   * @param xDomainBounds The x domain bounds on which to perform the calculation.
   * @param yDomainBounds The y domain bounds on which to perform the calculation.
   * @returns The mean of the specified channel.
   */
  public getMeanWithDiscreetMidpointRule(
    xChannel: ICalculationChannelWithData,
    yChannel: ICalculationChannelWithData,
    integrationChannel: ICalculationChannelWithData,
    xDomainBounds: [number, number],
    yDomainBounds: [number, number]): number {

    if (!integrationChannel || !integrationChannel.data) {
      throw new Error('Integration channel data must be supplied when evaluating integral with midpoint rule.');
    }

    let range = 0;
    let integral = 0;

    xDomainBounds = d3.extentStrict(xDomainBounds);
    yDomainBounds = d3.extentStrict(yDomainBounds);
    let xDomainIndices = xChannel.data.map((v, i) => v >= xDomainBounds[0] && v <= xDomainBounds[1] ? 1 : 0);
    let yDomainIndices = yChannel.data.map((v, i) => v >= yDomainBounds[0] && v <= yDomainBounds[1] ? 1 : 0);
    let dataIndices = xDomainIndices.map((x, i) => x && yDomainIndices[i] ? 1 : 0);

    let yData = yChannel.data;
    let integrationChannelData = integrationChannel.data;

    let lastIndex = dataIndices.length - 1;
    for (let i = 0; i <= lastIndex; ++i) {
      if (dataIndices[i]) {

        let integrationValue = integrationChannelData[i];
        let yValue = yData[i];

        if (i > 0) {
          let integrationRange = Math.abs((integrationValue - integrationChannelData[i - 1]) / 2);
          range += integrationRange;
          integral += yValue * integrationRange;
        }

        if (i < lastIndex) {
          let integrationRange = Math.abs((integrationValue - integrationChannelData[i + 1]) / 2);
          range += integrationRange;
          integral += yValue * integrationRange;
        }
      }
    }

    if (range === 0) {
      return NaN;
    }

    return integral / range;
  }

  /**
   * Execute a basic calculation on the specified channels.
   * @param calculationType The calculation type.
   * @param xChannel The x channel.
   * @param yChannel The y channel.
   * @param xDomainBounds The x domain bounds on which to perform the calculation.
   * @param yDomainBounds The y domain bounds on which to perform the calculation.
   * @param isSelectionReversedX Whether the selection is reversed in the x domain.
   * @returns The result of the calculation.
   */
  public executeBasicCalculation(
    calculationType: CalculationType,
    xChannel: ICalculationChannelWithData,
    yChannel: ICalculationChannelWithData,
    xDomainBounds: [number, number],
    yDomainBounds: [number, number],
    isSelectionReversedX: boolean = false): number {

    xDomainBounds = d3.extentStrict(xDomainBounds);
    yDomainBounds = d3.extentStrict(yDomainBounds);
    let xDomainIndices = xChannel.data.map((v, i) => v >= xDomainBounds[0] && v <= xDomainBounds[1] ? 1 : 0);
    let yDomainIndices = yChannel.data.map((v, i) => v >= yDomainBounds[0] && v <= yDomainBounds[1] ? 1 : 0);
    let dataIndices = xDomainIndices.map((x, i) => x && yDomainIndices[i] ? 1 : 0);

    let xFilteredData = xChannel.data.filter((v, i) => dataIndices[i]);
    let filteredData: number[] = yChannel.data.filter((v, i) => dataIndices[i]);

    if (filteredData.length === 0) {
      return NaN;
    }

    switch (calculationType) {
      case CalculationType.min:
        return d3.minStrict(filteredData);

      case CalculationType.max:
        return d3.maxStrict(filteredData);

      case CalculationType.mean:
        return d3.meanStrict(filteredData);

      case CalculationType.delta:
        if(yDomainBounds[0] === -Infinity && yDomainBounds[1] === Infinity){
          if(xChannel.isMonotonic){
            let delta = filteredData.at(-1) - filteredData.at(0);
            return isSelectionReversedX ? -delta : delta;
          }else{
            let startIndex = d3.minIndexStrict(xFilteredData);
            let endIndex = d3.maxIndexStrict(xFilteredData);
            return filteredData[endIndex] - filteredData[startIndex];
          }
        }
        return NaN;

      case CalculationType.range:
        return d3.maxStrict(filteredData) - d3.minStrict(filteredData);

      case CalculationType.gradient:
        let startIndex = d3.minIndexStrict(xFilteredData);
        let endIndex = d3.maxIndexStrict(xFilteredData);
        return (filteredData.at(endIndex) - filteredData.at(startIndex))/(xFilteredData.at(endIndex) - xFilteredData.at(startIndex));
    }

    throw new Error('Unexpected calculation type when evaluating min or max: ' + calculationType);
  }
}

@let isCompleted = completedJobCount === jobCount;
@let isSuccessful = succeededJobCount === jobCount;
@let failedJobCount = completedJobCount - succeededJobCount;
@let title = completedJobCount + ' jobs completed out of ' + jobCount +', of which ' + failedJobCount + ' jobs failed';
@if (canNavigate) {
  <a [routerLink]="['/studies', tenantId, studyId, 'jobs']">
    <ng-container [ngTemplateOutlet]="inner"></ng-container>
  </a>
} @else {
  <ng-container [ngTemplateOutlet]="inner"></ng-container>
}

<ng-template #inner>
  <span id="study-progress-text" title="{{title}}">
    {{completedJobCount}}@if (!isCompleted) {/{{jobCount}} }
    @if (failedJobCount > 0) {
      <span class="failure-job-count">
        <i class="fa fa-fw fa-times"></i>{{failedJobCount}}
      </span>
    }
  </span>
</ng-template>

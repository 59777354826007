import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SaveAsDialog} from '../../../common/dialogs/save-as-dialog.service';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {DocumentSubType} from '../../../../generated/api-stubs';
import {GetSimVersion} from '../../../common/get-sim-version.service';
import {ConfirmationDialog} from '../../../common/dialogs/confirmation-dialog.service';
export const SAVE_CONFIG_COPY_ERROR_TITLE = 'Clone Error';

@Component({
  selector: 'cs-clone-config-launcher',
  templateUrl: './clone-config-launcher.component.html',
  styleUrls: ['./clone-config-launcher.component.scss']
})
export class CloneConfigLauncherComponent {
  @Input() public compactButton: boolean = false;
  @Input() public configId: string;
  @Input() public configTenantId: string;
  @Input() public configType: DocumentSubType;
  @Input() public suggestedName: string;
  @Output() public copySaved: EventEmitter<CopySavedEvent> = new EventEmitter<CopySavedEvent>();

  constructor(
    private readonly prompt: ConfirmationDialog,
    private readonly getSimVersion: GetSimVersion,
    private readonly saveAsDialog: SaveAsDialog,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  public async execute() {
    try {
      let result = await this.saveAsDialog.showForReference(
        this.suggestedName,
        this.configType,
        this.getSimVersion.currentSimVersion,
        undefined,
        this.configTenantId,
        this.configId);

      if(result){
        this.copySaved.emit(new CopySavedEvent(result.configId));
      }
    } catch(error){
      const message = this.getFriendlyErrorAndLog.execute(error);
      await this.prompt.show(message, SAVE_CONFIG_COPY_ERROR_TITLE);
    }
  }
}

export class CopySavedEvent {
  constructor(public readonly documentId: string){
  }
}

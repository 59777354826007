import { OnDestroy, Directive } from '@angular/core';
import {Timer} from '../../common/timer.service';
import {
  JobConfigData,
  VisualizationFactory
} from '../visualizations/visualization-factory.service';
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import { JsonEditorInstance } from './json-config-editor/json-editor-instance';
import {ConfigPreviewComponentBase} from './config-preview-component-base';

@Directive()
export class ConfigEditorPageBase extends ConfigPreviewComponentBase implements OnDestroy {
  public errorMessage: string;

  public editorInstance: JsonEditorInstance;

  constructor(
    visualizationFactory: VisualizationFactory,
    timer: Timer,
    getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
    super(visualizationFactory, timer, getFriendlyErrorAndLog);
  }

  public ngOnDestroy(): any {
    if(this.editorInstance){
      this.editorInstance.destroy();
    }

    super.ngOnDestroy();
  }

  public async updateConfigPreview(){
    try {
      this.errorMessage = undefined;
      if(!this.editorInstance) {
        return;
      }

      let current = this.editorInstance.getValue();
      await this.loadConfigPreview(current);
    } catch(error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public loadConfigPreview(initialContent: any): Promise<void> {
    if(!this.editorInstance) {
      return Promise.resolve();
    }

    if(this.isTrack){
      return this.loadConfigPreviews( [new JobConfigData('Track', initialContent)], this.editorInstance.defaultUnits);
    } else if(this.isCar){
      return this.loadConfigPreviews([new JobConfigData('Car', initialContent)], this.editorInstance.defaultUnits);
    } else{
      return this.loadConfigPreviews([new JobConfigData('Telemetry', initialContent)], undefined);
    }
  }

  public async loadConfigPreviewIfRequired(config: any){
    if (this.hasConfigPreview){
      await this.loadConfigPreview(config);
    }
  }
}


import {CanopyDocument, ConfigStub, DocumentSubType, FilteredDocumentsResultType} from '../../generated/api-stubs';
import {SUPPORT_SESSION_ID_CUSTOM_PROPERTY_KEY} from './create-worksheet-from-study';
import {Injectable} from '@angular/core';
import { AuthenticationService } from '../identity/state/authentication.service';


/**
 * Gets the worksheet for a support session if it exists, or returns undefined.
 */
@Injectable()
export class GetWorksheetForSupportSession {

  /**
   * Creates an instance of GetWorksheetForSupportSession.
   * @param authenticationService The authentication service.
   * @param configStub The config stub.
   */
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly configStub: ConfigStub){
  }

  /**
   * Gets the worksheet for a support session if it exists, or returns undefined.
   * @param supportSessionId The support session ID.
   * @returns The worksheet for the support session, or undefined if it does not exist.
   */
  public async execute(supportSessionId: string): Promise<CanopyDocument | undefined> {
    const userData = this.authenticationService.userDataSnapshot;
    const configsResult = await this.configStub.getConfigs(
      userData.tenant,
      DocumentSubType.worksheet,
      {
        itemsPerPage: 1,
        filterCustomProperties: [
          {
            name: SUPPORT_SESSION_ID_CUSTOM_PROPERTY_KEY,
            value: supportSessionId,
          }
        ]
      },
      undefined,
      FilteredDocumentsResultType.queryOnly);

    if(configsResult.queryResults.documents.length){
      return configsResult.queryResults.documents[0];
    }

    return undefined;
  }
}

import { StudyJob } from '../../study-job';
import { GetJobSimTypeMetadata, SimTypeInformation } from './get-job-sim-type-metadata';
import { SimType } from '../../sim-type';
import { LoadVectorMetadataMap } from './load-vector-metadata-map';
import { UrlFileLoader } from '../../url-file-loader';
import { ChannelDataTransforms } from './channel-data-transforms';

export interface StudyJobSimTypeMetadata {
  studyJob: StudyJob;
  simTypes: SimTypeInformation[];
}

/**
 * A service that gets the sim type metadata for a list of study jobs and sim types.
 */
export class GetJobsSimTypeMetadata {

  private readonly loadVectorMetadataMap: LoadVectorMetadataMap;
  private readonly getJobSimTypeMetadata: GetJobSimTypeMetadata;

  /**
   * Creates a new instance of GetJobsSimTypeMetadata.
   * @param fileLoader The file loader.
   */
  constructor(
    private readonly fileLoader: UrlFileLoader) {

    this.getJobSimTypeMetadata = new GetJobSimTypeMetadata();
    this.loadVectorMetadataMap = LoadVectorMetadataMap.create(this.fileLoader, ChannelDataTransforms.none);
  }

  /**
   * Gets the sim type metadata for the specified study jobs and sim types.
   * @param studyJobs The study jobs.
   * @param simTypes The sim types.
   * @returns The sim type metadata.
   */
  public async execute(studyJobs: ReadonlyArray<StudyJob>, simTypes: ReadonlyArray<SimType>): Promise<StudyJobSimTypeMetadata[]> {
    let studyJobSimTypes: StudyJobSimTypeMetadata[] = [];
    for (let studyJob of studyJobs) {
      let simTypeMetadata = await this.getJobSimTypeMetadata.execute(studyJob.studyId, studyJob.jobIndex, simTypes, this.loadVectorMetadataMap);
      studyJobSimTypes.push({
        studyJob,
        simTypes: simTypeMetadata
      });
    }

    return studyJobSimTypes;
  }
}

/* eslint-disable quote-props */
import { CarCoordinateFactory } from '../3d/car-coordinate';
import { CoordinateTriplet } from '../3d/coordinate-triplet';
import {
  MutableSuspensionCoordinatesMap,
  SuspensionAreaData,
  SuspensionAreaName,
  SuspensionData,
  SuspensionMember,
  SuspensionMemberType
} from './car-types';
import { RawCarData, RawSuspensionCoordinatesMap, RawWheelData } from './raw-car-types';

/**
 * Takes the car config and extracts the suspension data in a way that can be rendered.
 */
export class ExtractSuspensionDataFromCar {

  /**
   * Takes the car config and extracts the suspension data in a way that can be rendered.
   * @param car The car data.
   * @returns The suspension data.
   */
  public execute(car: any): SuspensionData {
    const carData = new RawCarData(car);
    const frontSuspension = carData.frontSuspension;
    const rearSuspension = carData.rearSuspension;

    const frontExternal = this.getExternalMembers(SuspensionAreaName.frontExternal, frontSuspension.externalPickUpPoints, frontSuspension.wheel, frontSuspension.suspensionOffset);
    const frontExternalL = this.getExternalMembers(SuspensionAreaName.frontExternalL, frontSuspension.externalPickUpPointsL, frontSuspension.wheel, frontSuspension.suspensionOffset);

    const rearExternal = this.getExternalMembers(SuspensionAreaName.rearExternal, rearSuspension.externalPickUpPoints, rearSuspension.wheel, rearSuspension.suspensionOffset);
    const rearExternalL = this.getExternalMembers(SuspensionAreaName.rearExternalL, rearSuspension.externalPickUpPointsL, rearSuspension.wheel, rearSuspension.suspensionOffset);

    const frontInternal = this.getInternalMembers(SuspensionAreaName.frontInternal, frontSuspension.internalPickUpPoints, frontSuspension.suspensionOffset);
    const frontInternalL = this.getInternalMembers(SuspensionAreaName.frontInternalL, frontSuspension.internalPickUpPointsL, frontSuspension.suspensionOffset);

    const rearInternal = this.getInternalMembers(SuspensionAreaName.rearInternal, rearSuspension.internalPickUpPoints, rearSuspension.suspensionOffset);
    const rearInternalL = this.getInternalMembers(SuspensionAreaName.rearInternalL, rearSuspension.internalPickUpPointsL, rearSuspension.suspensionOffset);

    const chassis = this.getChassisMembers(SuspensionAreaName.chassis, carData.chassis);
    const chassisL = this.getChassisMembers(SuspensionAreaName.chassisL, carData.chassis, 'L');

    return new SuspensionData(
      frontExternal,
      frontExternalL,
      rearExternal,
      rearExternalL,
      frontInternal,
      frontInternalL,
      rearInternal,
      rearInternalL,
      chassis,
      chassisL);
  }

  /**
   * Not technically suspension, but get the data points to render for the chassis.
   * @param name The name of the suspension area.
   * @param chassis The chassis data.
   * @param suffix The suffix to append to each member name (e.g. 'L').
   * @returns The suspension area data.
   */
  private getChassisMembers(name: SuspensionAreaName, chassis: RawSuspensionCoordinatesMap, suffix: string = '') {
    const nameToComponentMap: { [name: string]: ReadonlyArray<string> } = {
      'Ride Height': ['rRideF', 'rRideR'],
      'Undertray': ['rUndertrayFront', 'rUndertrayMid', 'rUndertrayRear'],
    };
    const memberNames: string[] = Object.keys(nameToComponentMap);
    const memberList: SuspensionMember[] = [];
    for (let name of memberNames) {
      if (suffix && nameToComponentMap[name].every(v => !chassis[v + suffix])) {
        memberList.push(new SuspensionMember(name, undefined));
      } else if (nameToComponentMap[name].some(v => !chassis[v + suffix] && !chassis[v])) {
        memberList.push(new SuspensionMember(name, undefined));
      } else {
        memberList.push(new SuspensionMember(name, nameToComponentMap[name].map(v =>
          CarCoordinateFactory.fromArray(chassis[v + suffix] || chassis[v]))));
      }
    }

    const memberMap = this.getMemberMap(chassis);

    return new SuspensionAreaData(name, memberNames, memberMap, memberList, chassis);
  }

  /**
   * Get the external members names for the suspension area.
   * @param pickUpPoints The pick up points data.
   * @returns The external member names.
   */
  private getExternalMemberNames(pickUpPoints: any): ReadonlyArray<string> {
    if (pickUpPoints.name === 'Abstract set of mechanical advantages') {
      return [];
    }

    if (!pickUpPoints['rPRO']) {
      return ['FUWB', 'RUWB', 'FLWB', 'RLWB', 'TR'];
    }

    return ['FUWB', 'RUWB', 'FLWB', 'RLWB', 'PR', 'TR'];
  }

  /**
   * Get the external members for the suspension area.
   * @param name The name of the suspension area.
   * @param pickUpPoints The pick up points data.
   * @param wheel The wheel data.
   * @param rSuspensionDatumOffset The suspension datum offset.
   * @returns The suspension area data.
   */
  private getExternalMembers(
    name: SuspensionAreaName,
    pickUpPoints: RawSuspensionCoordinatesMap,
    wheel: RawWheelData,
    rSuspensionDatumOffset: CoordinateTriplet): SuspensionAreaData {

    const memberNames = [
      ...this.getExternalMemberNames(pickUpPoints),
      'DamperStrut',
    ];

    let memberList: SuspensionMember[] = [];
    for (let name of memberNames) {
      if ((pickUpPoints['r' + name + 'I'] && pickUpPoints['r' + name + 'O']) || (pickUpPoints['r' + name + 'Inboard'] && pickUpPoints['r' + name + 'Outboard'])) {
        memberList.push(new SuspensionMember(name, [
          CarCoordinateFactory.fromArray(pickUpPoints['r' + name + 'I'] || pickUpPoints['r' + name + 'Inboard'], rSuspensionDatumOffset),
          CarCoordinateFactory.fromArray(pickUpPoints['r' + name + 'O'] || pickUpPoints['r' + name + 'Outboard'], rSuspensionDatumOffset)]));
      } else {
        memberList.push(new SuspensionMember(name, undefined));
      }
    }

    const memberMap = this.getMemberMap(pickUpPoints, rSuspensionDatumOffset);

    if (!memberMap.rRockerC) {
      if (memberMap.rRLWBI) {
        memberMap.rRockerC = memberMap.rRLWBI;
      }
      if (memberMap.rFLWBI) {
        memberMap.rRockerAxis = memberMap.rFLWBI;
      }
      // NOTE: Don't modify the underlying car, as this has not been cloned from the platform.
      //pickUpPoints.rRockerC = pickUpPoints.rRLWBI;
      //pickUpPoints.rRockerAxis = pickUpPoints.rFLWBI;
    }

    if (memberMap.rRockerC) {
      const rRockerC = memberMap.rRockerC;
      memberList.push(new SuspensionMember('Rocker Axis', [rRockerC]));
    }

    if (memberMap.rWheelC || memberMap.rAxleC) {
      const rAxleC = memberMap.rWheelC || memberMap.rAxleC;
      memberList.push(new SuspensionMember('Hub Centre', [rAxleC]));

      if (wheel.rWheelDesign) {
        memberList.push(new SuspensionMember('Wheel Design Radius', [
          rAxleC,
          CarCoordinateFactory.fromArray([rAxleC.x, rAxleC.y, rAxleC.z - wheel.rWheelDesign])],
          SuspensionMemberType.circle));
      }

      if (wheel.rWheelSetup) {
        memberList.push(new SuspensionMember('Wheel Setup Radius', [
          rAxleC,
          CarCoordinateFactory.fromArray([rAxleC.x, rAxleC.y, rAxleC.z - wheel.rWheelSetup])],
          SuspensionMemberType.circle));
      }
    }

    if (memberMap.rUserTCP) {
      const rUserTCP = memberMap.rUserTCP;
      memberList.push(new SuspensionMember('User Tyre Contact Patch', [rUserTCP]));
    }

    this.getArbMembers(memberList, pickUpPoints, rSuspensionDatumOffset);

    memberList = this.removeMembersAtOrigin(memberList, rSuspensionDatumOffset);

    return new SuspensionAreaData(name, memberNames, memberMap, memberList, pickUpPoints);
  }

  /**
   * Get the internal members for the suspension area.
   * @param name The name of the suspension area.
   * @param pickUpPoints The pick up points data.
   * @param rSuspensionDatumOffset The suspension datum offset.
   * @returns The suspension area data.
   */
  private getInternalMembers(name: SuspensionAreaName, pickUpPoints: RawSuspensionCoordinatesMap, rSuspensionDatumOffset: CoordinateTriplet): SuspensionAreaData {
    const internalMemberTypes: ReadonlyArray<string> = ['Spring', 'BumpStop', 'Damper', 'Inerter'];
    const cornerInternalMemberNames = internalMemberTypes.map(v => 'Corner' + v);
    const triInternalMemberNames = internalMemberTypes.map(v => 'Tri' + v);
    const internalMemberNames: string[] = [
      ...cornerInternalMemberNames,
      ...triInternalMemberNames,
    ];

    let memberList: SuspensionMember[] = [];
    for (let name of cornerInternalMemberNames) {
      if (!pickUpPoints['r' + name + 'Chassis'] || !pickUpPoints['r' + name]) {
        memberList.push(new SuspensionMember(name, undefined));
      } else {
        memberList.push(new SuspensionMember(name, [
          CarCoordinateFactory.fromArray(pickUpPoints['r' + name + 'Chassis'], rSuspensionDatumOffset),
          CarCoordinateFactory.fromArray(pickUpPoints['r' + name], rSuspensionDatumOffset)]));
      }
    }
    for (let name of triInternalMemberNames) {
      const rockerPt = pickUpPoints['r' + name];
      if (!rockerPt) {
        if (name === 'TriSpring' && pickUpPoints['rTriSpringTBar'] && pickUpPoints['rTriSpringAnchor']) {
          memberList.push(new SuspensionMember(name, [
            CarCoordinateFactory.fromArray(pickUpPoints['rTriSpringTBar'], rSuspensionDatumOffset),
            CarCoordinateFactory.fromArray(pickUpPoints['rTriSpringAnchor'], rSuspensionDatumOffset)]));
        } else {
          memberList.push(new SuspensionMember(name, undefined));
        }
      } else {
        memberList.push(new SuspensionMember(name, [
          CarCoordinateFactory.fromArray([rockerPt[0], 0, rockerPt[2]], rSuspensionDatumOffset),
          CarCoordinateFactory.fromArray(rockerPt, rSuspensionDatumOffset)]));
      }
    }

    if (pickUpPoints['rTriSpringTBar'] && pickUpPoints['rTriSpringAnchor']) {

    }

    // U-type ARB w/ T-Type Tri
    this.addSuspensionMemberIfAllPointsExist(memberList, pickUpPoints, rSuspensionDatumOffset,
      'TriUnitLink',
      ['rTriUnitRockerPickup', 'rTriUnitPickupInboard']);

    this.addSuspensionMemberIfAllPointsExist(memberList, pickUpPoints, rSuspensionDatumOffset,
      'TriUnitTBar',
      ['rTriUnitPickupInboard', 'rTriUnitTBar']);

    this.addSuspensionMemberIfAllPointsExist(memberList, pickUpPoints, rSuspensionDatumOffset,
      'TriUnitAnchor',
      ['rTriUnitAnchor', 'rTriUnitTBar']);

    this.addSuspensionMemberIfAllPointsExist(memberList, pickUpPoints, rSuspensionDatumOffset,
      'TriUnit',
      ['rTriUnitAxis', 'rTriUnitTBar']);

    // Tri-spring on secondary rocker
    this.addSuspensionMemberIfAllPointsExist(memberList, pickUpPoints, rSuspensionDatumOffset,
      'SecondaryRockerCentre',
      ['rSecondaryRockerCentre']);

    const triUnitOnSecondaryRocker = pickUpPoints['rTriUnitOnSecondaryRocker'];
    if (triUnitOnSecondaryRocker) {
      memberList.push(new SuspensionMember('TriUnitOnSecondaryRocker', [
        CarCoordinateFactory.fromArray(triUnitOnSecondaryRocker, rSuspensionDatumOffset),
        CarCoordinateFactory.fromArray([triUnitOnSecondaryRocker[0], 0, triUnitOnSecondaryRocker[2]], rSuspensionDatumOffset)]));
    }

    this.addSuspensionMemberIfAllPointsExist(memberList, pickUpPoints, rSuspensionDatumOffset,
      'DropLinkSecondaryRocker',
      ['rSecondaryDropLinkOnPrimaryDroplink', 'rSecondaryDropLinkOnSecondaryRocker']);

    // Anti-roll bar on secondary rocker
    this.addSuspensionMemberIfAllPointsExist(memberList, pickUpPoints, rSuspensionDatumOffset,
      'DropLinkSecondaryRocker',
      ['rPrimaryDropLinkOnPrimaryRocker', 'rPrimaryDropLinkOnSecondaryRocker']);

    this.addSuspensionMemberIfAllPointsExist(memberList, pickUpPoints, rSuspensionDatumOffset,
      'ARBDropLinkSecondaryRocker',
      ['rSecondaryDropLinkOnSecondaryRocker', 'rARBPickupInboard']);

    // Z-type ARB
    // https://github.com/CanopySimulations/canopy-vis/issues/75
    this.addSuspensionMemberIfAllPointsExist(memberList, pickUpPoints, rSuspensionDatumOffset,
      'DropLink',
      ['rARBRockerPickupL', 'rARBPickupInboardL']);

    this.addSuspensionMemberIfAllPointsExist(memberList, pickUpPoints, rSuspensionDatumOffset,
      'DropLink',
      ['rARBRockerPickupR', 'rARBPickupInboardR']);

    this.addSuspensionMemberIfAllPointsExist(memberList, pickUpPoints, rSuspensionDatumOffset,
      'ARB',
      ['rARBPickupInboardR', 'rARBPickupInboardL']);

    this.getArbMembers(memberList, pickUpPoints, rSuspensionDatumOffset);
    this.getRollMembers(memberList, pickUpPoints, rSuspensionDatumOffset);

    memberList = this.removeMembersAtOrigin(memberList, rSuspensionDatumOffset);
    const memberMap = this.getMemberMap(pickUpPoints, rSuspensionDatumOffset);
    return new SuspensionAreaData(name, internalMemberNames, memberMap, memberList, pickUpPoints);
  }

  /**
   * Add a suspension member if all points exist.
   * @param memberList The list of suspension members, which should be added to if all points exist.
   * @param pickUpPoints The pick up points data.
   * @param rSuspensionDatumOffset The suspension datum offset.
   * @param memberName The name of the suspension member.
   * @param pointNames The names of the points, all of which should exist for a member to be added.
   */
  private addSuspensionMemberIfAllPointsExist(memberList: SuspensionMember[], pickUpPoints: RawSuspensionCoordinatesMap, rSuspensionDatumOffset: CoordinateTriplet, memberName: string, pointNames: ReadonlyArray<string>) {
    const resolvedMembers = pointNames.map(v => pickUpPoints[v]);

    if (resolvedMembers.every(v => !!v)) {
      memberList.push(new SuspensionMember(memberName, resolvedMembers.map(v => CarCoordinateFactory.fromArray(v, rSuspensionDatumOffset))));
    }
  }

  /**
   * Get the roll members for the suspension area.
   * @param memberList The list of suspension members, which will be appended to.
   * @param pickUpPoints The pick up points data.
   * @param rSuspensionDatumOffset The suspension datum offset.
   */
  private getRollMembers(memberList: SuspensionMember[], pickUpPoints: RawSuspensionCoordinatesMap, rSuspensionDatumOffset: CoordinateTriplet) {
    const rollComponentTypes: ReadonlyArray<string> = ['Spring', 'BumpStop', 'Damper'];

    for (let name of rollComponentTypes) {
      const cornerPointNameL = 'rRoll' + name + 'L';
      const cornerPointNameR = 'rRoll' + name + 'R';

      this.addSuspensionMemberIfAllPointsExist(memberList, pickUpPoints, rSuspensionDatumOffset,
        'Roll ' + name,
        [cornerPointNameR, cornerPointNameL]);
    }
  }

  /**
   * Get the anti-roll bar members for the suspension area.
   * @param memberList The list of suspension members, which will be appended to.
   * @param pickUpPoints The pick up points data.
   * @param rSuspensionDatumOffset The suspension datum offset.
   */
  private getArbMembers(memberList: SuspensionMember[], pickUpPoints: RawSuspensionCoordinatesMap, rSuspensionDatumOffset: CoordinateTriplet) {
    const arbPickup = pickUpPoints['rARBRockerPickup'] || pickUpPoints['rARBSuspensionPickup'];
    const arbPickupInboard = pickUpPoints['rARBPickupInboard'];

    const arbAxis = pickUpPoints['rARBAxis'];
    const arbAnchor = pickUpPoints['rARBAnchor'];

    if (arbAxis) {
      if (arbAnchor) {
        memberList.push(new SuspensionMember('ARB Lever Arm', [
          CarCoordinateFactory.fromArray([arbPickupInboard[0], 0, arbPickupInboard[2]], rSuspensionDatumOffset),
          CarCoordinateFactory.fromArray(arbPickupInboard, rSuspensionDatumOffset)
        ]));
        memberList.push(new SuspensionMember('ARB Torsion Element', [
          CarCoordinateFactory.fromArray([arbPickupInboard[0], 0, arbPickupInboard[2]], rSuspensionDatumOffset),
          CarCoordinateFactory.fromArray(arbAnchor, rSuspensionDatumOffset)
        ]));
      } else {

        memberList.push(new SuspensionMember('ARB Lever Arm', [
          CarCoordinateFactory.fromArray([arbAxis[0], arbPickupInboard[1], arbAxis[2]], rSuspensionDatumOffset),
          CarCoordinateFactory.fromArray(arbPickupInboard, rSuspensionDatumOffset)
        ]));
        memberList.push(new SuspensionMember('ARB Torsion Element', [
          CarCoordinateFactory.fromArray([arbAxis[0], arbPickupInboard[1], arbAxis[2]], rSuspensionDatumOffset),
          CarCoordinateFactory.fromArray([arbAxis[0], 0, arbAxis[2]], rSuspensionDatumOffset)
        ]));
      }
    }

    if (arbPickup && arbPickupInboard) {
      memberList.push(new SuspensionMember('ARB Pickups', [
        CarCoordinateFactory.fromArray(arbPickup, rSuspensionDatumOffset),
        CarCoordinateFactory.fromArray(arbPickupInboard, rSuspensionDatumOffset)
      ]));
    }
  }

  /**
   * Remove members with both ends at the origin. These are members where the user hasn't specified a position, so it has defaulted to 0,0,0.
   * @param memberList The list of suspension members.
   * @param rSuspensionDatumOffset The suspension datum offset.
   * @returns The list of suspension members with members with both ends at the origin removed.
   */
  private removeMembersAtOrigin(memberList: SuspensionMember[], rSuspensionDatumOffset: CoordinateTriplet): SuspensionMember[] {
    // Remove members with both ends at the origin.
    return memberList.map(
      v => v.coordinates && v.coordinates.every(c => c.x === rSuspensionDatumOffset[0] && c.y === rSuspensionDatumOffset[1] && c.z === rSuspensionDatumOffset[2])
        ? new SuspensionMember(v.name, undefined)
        : v);
  }

  /**
   * Get the member map for the suspension area.
   * @param pickUpPoints The pick up points data.
   * @param rSuspensionDatumOffset The suspension datum offset.
   * @returns The member map.
   */
  private getMemberMap(pickUpPoints: RawSuspensionCoordinatesMap, rSuspensionDatumOffset?: CoordinateTriplet) {
    let memberMap: MutableSuspensionCoordinatesMap = {};
    for (let key in pickUpPoints) {
      if (pickUpPoints.hasOwnProperty(key)) {
        let value = pickUpPoints[key];
        if (Array.isArray(value)) {
          memberMap[key] = CarCoordinateFactory.fromArray(value, rSuspensionDatumOffset);
        }
      }
    }
    return memberMap;
  }
}

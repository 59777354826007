import {Rule} from './rule';

type RuleClass = new (...args: any[]) => Rule;

export class Registry {
  private _rules: RuleClass[] = [];

  public addRule(rule: RuleClass) {
    if (!Rule.isPrototypeOf(rule)) {
      throw new Error(`You can only register classes derived from Rule (${rule.name})`);
    }

    this._rules.push(rule);
  }

  public get rules(): ReadonlyArray<RuleClass> {
    return this._rules;
  }
}

import * as d3 from '../../d3-bundle';
import {
  MultiPlotDataRendererBase,
  MultiPlotDataRendererInnerBase
} from '../multi-plot-viewer-base/multi-plot-data-renderer-base';
import { ProcessedPlot } from '../data-pipeline/types/processed-plot';
import { ProcessedPlotSourceChannel } from '../data-pipeline/types/processed-plot-source-channel';
import { GetInterpolatedChannelValueAtDomainValue } from '../channel-data-loaders/get-interpolated-channel-value-at-domain-value';
import { SourceSplitInspector } from '../channel-data-inspectors/source-split-inspector';

/**
 * D3 style interface to the data renderer for the line plot viewer.
 * Draws lines (connecting the data points) to a canvas.
 */
export class DataRenderer extends MultiPlotDataRendererBase {

  /**
   * Create a new instance of the data renderer for the given interpolator.
   * @param interpolator The interpolator to use for the data renderer.
   * @returns A new instance of the data renderer.
   */
  public static create(interpolator: LineViewerGetInterpolatedChannelValueAtDomainValue) {
    return new DataRenderer(
      new DataRendererInner('line-plot', interpolator, new SourceSplitInspector()),
      interpolator);
  }

  /**
   * Create a new instance of the data renderer for the line plot viewer.
   * @param linePlotInner The inner data renderer for the line plot viewer.
   * @param lineViewerGetInterpolatedChannelValueAtDomainValue The interpolator to use for the data renderer.
   */
  private constructor(
    private linePlotInner: DataRendererInner,
    private lineViewerGetInterpolatedChannelValueAtDomainValue: LineViewerGetInterpolatedChannelValueAtDomainValue) {
    super(linePlotInner);
  }

  /**
   * The type of curve to use when drawing the lines. Defaults to a linear interpolation line.
   * We also often use step-before interpolation for the line plot viewer.
   * @param value The type of curve to use when drawing the lines.
   * @returns This data renderer.
   */
  public curve(value: d3.CurveFactory): this {
    this.linePlotInner.curve = value;
    this.lineViewerGetInterpolatedChannelValueAtDomainValue.curve = value;
    return this;
  }
}

/**
 * The inner data renderer for the line plot viewer.
 * Draws lines (connecting the data points) to a canvas.
 */
export class DataRendererInner extends MultiPlotDataRendererInnerBase {

  /**
   * Create a new instance of the inner data renderer for the line plot viewer.
   * @param cssClassPrefix The prefix to use for the CSS class of the data renderer.
   * @param getInterpolatedChannelValueAtDomainValue The interpolator to use for the data renderer.
   * @param sourceSplitInspector The source split inspector to use for the data renderer.
   */
  constructor(
    cssClassPrefix: string,
    getInterpolatedChannelValueAtDomainValue: GetInterpolatedChannelValueAtDomainValue,
    private readonly sourceSplitInspector: SourceSplitInspector) {
    super(cssClassPrefix, getInterpolatedChannelValueAtDomainValue);
  }

  /**
   * The type of curve to use when drawing the lines. Defaults to a linear interpolation line.
   */
  public curve: d3.CurveFactory = d3.curveLinear;

  /**
   * Draw the data for the given channel to the canvas.
   * @param context The canvas rendering context.
   * @param plot The plot to draw.
   * @param channel The channel to draw.
   */
  protected drawChannelData(context: CanvasRenderingContext2D, plot: ProcessedPlot, channel: ProcessedPlotSourceChannel) {
    const settings = this.definedSettings;
    let sourceIndex = channel.sourceIndex;
    let yChannel = channel.yChannel;
    let xCoordinates = channel.xCoordinates;
    let yCoordinates = channel.yCoordinates;

    // Draw a line between all the data points.
    let line = d3.line<number>()
      .curve(this.curve)
      .defined((d: number) => !isNaN(d))
      .x((d: number, i: number) => xCoordinates[i])
      .y((d: number) => d);

    context.lineWidth = 1;
    context.strokeStyle = settings.getChannelColor ? settings.getChannelColor(yChannel.channelIndex, sourceIndex) : 'green';

    line.context(context);
    context.beginPath();
    line(yCoordinates);
    context.stroke();
  }

  /**
   * Draw any features for the plot, such as split lines.
   * @param context The canvas rendering context.
   * @param plot The plot to draw.
   */
  protected drawPlotFeatures(context: CanvasRenderingContext2D, plot: ProcessedPlot) {
    if (!this.sourceData) {
      return;
    }

    const settings = this.definedSettings;

    // Get the first visible X channel with data.
    let activeDomainChannel = plot.column.processed.channels.find(v => v.isVisible && v.hasData);

    // If the channel isn't monotonic, we have nothing to do.
    if (!activeDomainChannel || !activeDomainChannel.isMonotonic) {
      return;
    }

    // Get the Y extents of the visible channels.
    let sourcesVisibility = this.sourceData.map(v => v.isVisible);
    let yMinimum = plot.row.processed.getVisibleMinimum(sourcesVisibility);
    let yMaximum = plot.row.processed.getVisibleMaximum(sourcesVisibility);

    // For each source...
    for (let sourceIndex = 0; sourceIndex < this.sourceData.length; ++sourceIndex) {
      let source = this.sourceData[sourceIndex];
      if (!source.isVisible) {
        // If the source isn't visible, skip it.
        continue;
      }

      let splitChannel = source.featureChannels.splitChannel;
      if (!this.sourceSplitInspector.hasSplitIndices(splitChannel)) {
        // If the source doesn't have split indices, skip it.
        continue;
      }

      let columnScale = plot.column.processed.scale;
      let rowScale = plot.row.processed.scale;

      context.save();
      try {
        // Draw a vertical dashed line at each split index position.
        context.lineWidth = 0.5;
        context.strokeStyle = settings.getChannelColor ? settings.getChannelColor(0, sourceIndex) : 'black';
        context.setLineDash([5, 5]);
        context.beginPath();

        let domainData = activeDomainChannel.sources[sourceIndex].data;
        if (domainData) {
          for (let index of splitChannel.dataChangeIndices) {
            let columnValue = columnScale(domainData[index]) - 0.5;
            context.moveTo(
              columnValue,
              rowScale(yMinimum));
            context.lineTo(
              columnValue,
              rowScale(yMaximum));
          }
        }

        context.stroke();
      } finally {
        context.restore();
      }
    }
  }
}

/**
 * An interpolator for the line plot viewer.
 * Interpolates the channel value at a domain value, taking into account the current curve setting on the data renderer.
 */
export class LineViewerGetInterpolatedChannelValueAtDomainValue extends GetInterpolatedChannelValueAtDomainValue {
  public curve?: d3.CurveFactory;

  protected executeAtIndex(channelData: ReadonlyArray<number>, domainValue: number, domainData: ReadonlyArray<number>, index: number) {
    if (this.curve === d3.curveStepBefore) {
      // Handle step-before interpolation.
      if (index === -1) {
        return channelData[channelData.length - 1];
      }
      return channelData[index];
    } else {
      // Otherwise use linear interpolation.
      return super.executeAtIndex(channelData, domainValue, domainData, index);
    }
  }
}

import { Injectable, SecurityContext } from '@angular/core';
import { Utilities } from '../visualizations/utilities';
import { fileNameSanitize } from './file-name-sanitize';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class DownloadFile {
  constructor(private sanitizer: DomSanitizer){}

  public text(filename: string, text: string, contentType?: string) {
    Utilities.download(
      fileNameSanitize(filename),
      text,
      contentType);
  }

  public url(url: string, fileName?: string) {
    Object.assign(document.createElement('a'),
    {
      href: this.sanitizer.sanitize(SecurityContext.URL, url),
      download: fileName,
    }).click();
  }
}

import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SetErrorMessage, ClearErrorMessage } from '../../state/error-message/error-message.actions';
import { ErrorMessageSelectors } from '../../state/error-message/error-message.selectors';

@Injectable()
export class ErrorMessageService {

  public get message(): Observable<string> {
    return this._store.select(ErrorMessageSelectors.message);
  }

  public get messageSnapshot(): string {
    return this._store.selectSnapshot(ErrorMessageSelectors.message);
  }

  constructor(private _store: Store) {
  }

  public setErrorMessage(error: Error): void {
    this._store.dispatch(new SetErrorMessage(error));
  }

  public clearErrorMessage(): void {
    this._store.dispatch(new ClearErrorMessage());
  }
}

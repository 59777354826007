import {IHasReference, IReference} from './worksheet-types';
import {referenceToId} from './reference-to-id';

/**
 * A generic cache of resolved references, keyed by a string reference ID.
 */
export class ResolvedByReference<TResolved extends IHasReference> {

  /**
   * Creates an instance of ResolvedByReference.
   * @param includeJobIndex Whether to include the job index in the in the reference ID.
   */
  constructor(
    public readonly includeJobIndex: boolean = false) {
  }

  /**
   * The cache of resolved references.
   */
  private cache: { [referenceId: string]: TResolved } = {};

  /**
   * Adds a resolved reference to the cache.
   * @param resolvedReference The resolved reference to add.
   */
  public add(resolvedReference: TResolved) {
    this.cache[referenceToId(resolvedReference.reference, this.includeJobIndex)] = resolvedReference;
  }

  /**
   * Gets a resolved reference from the cache.
   * @param reference The reference to get.
   * @returns The resolved reference, or undefined if not found.
   */
  public get(reference: IReference): TResolved | undefined {
    return this.cache[referenceToId(reference, this.includeJobIndex)];
  }
}

import {Injectable} from '@angular/core';
import {ViewJobDialogData} from './view-job-dialog.service';

@Injectable()
export class ViewJobDialogSessionFactory {
  constructor(){
  }

  public create(dialog: ViewJobDialogData){
    return new ViewJobDialogSession(
      dialog);
  }
}

export class ViewJobDialogSession{

  constructor(
    public dialog: ViewJobDialogData){
  }

  public async load(){
  }
}

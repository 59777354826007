
export function getSafePath(fullPath: string) {
  let safePath = fullPath.replace(/\.[0-9]+\./g,'.')
    .replace(/\.[0-9]+$/g,'')
    .replace(/^root\.?/, '');

  if(!safePath){
    safePath = undefined;
  }

  return safePath;
}

import {GetSimVersion} from './get-sim-version.service';
import {Injectable} from '@angular/core';
import {simVersionToNumber} from '../visualizations/sim-version-to-number';

@Injectable()
export class SignificantSimVersions {

  constructor(private readonly getSimVersion: GetSimVersion){
  }

  public get isAfterTelemetrySimulation(): boolean {
    return this.isAfterSimVersion(1727);
  }

  public isAfterSimVersion(simVersionInclusive: number): boolean {
    const simVersion = this.getSimVersion.currentSimVersion;
    if(!simVersion){
      return false;
    }

    const numericSimVersion = simVersionToNumber(simVersion);
    return numericSimVersion === 0 || numericSimVersion >= simVersionInclusive;
  }
}

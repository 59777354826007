import { SVGSelection } from '../../untyped-selection';
import { IPopulatedMultiPlotLayout } from '../data-pipeline/types/i-populated-multi-plot-layout';
import { ProcessedPlot } from '../data-pipeline/types/processed-plot';
import { IMargin } from '../margin';

/**
 * The required chart settings for this component.
 */
interface IChartSettings {
  readonly uniqueId: string;
  readonly spaceBetweenPlots: number;
  readonly chartMargin: IMargin;
}

/**
 * A renderer for clipping paths for each plot.
 */
export class PlotClippingRenderer {

  /**
   * The inner renderer.
   */
  private inner = new PlotClippingRendererInner();

  /**
   * Renders the clipping paths.
   * @param selection The selection to render to.
   * @returns This object.
   */
  public render(selection: SVGSelection): this {
    this.inner.render(selection);
    return this;
  }

  /**
   * Sets the chart layout.
   * @param value The chart layout.
   * @returns This object.
   */
  public layout(value: IPopulatedMultiPlotLayout): this {
    this.inner.layout = value;
    return this;
  }

  /**
   * Sets the chart settings.
   * @param value The chart settings.
   * @returns This object.
   */
  public chartSettings(value: IChartSettings): this {
    this.inner.settings = value;
    return this;
  }

  /**
   * Gets the plot clip path ID. This is called when the plot clip path is needed by
   * another component.
   * @param uniqueId The unique ID.
   * @param plotIndex The plot index.
   * @returns The plot clip path ID.
   */
  public static getPlotClipPathId(uniqueId: string, plotIndex: number) {
    return uniqueId + '-plot-clip-' + plotIndex;
  }

  /**
   * Gets the plot X axis clip path ID. This is called when the plot X axis clip path is needed by
   * another component.
   * @param uniqueId The unique ID.
   * @param plotIndex The plot index.
   * @returns The plot X axis clip path ID.
   */
  public static getPlotXAxisClipPathId(uniqueId: string, plotIndex: number) {
    return uniqueId + '-plot-clip-x-' + plotIndex;
  }

  /**
   * Gets the plot Y axis clip path ID. This is called when the plot Y axis clip path is needed by
   * another component.
   * @param uniqueId The unique ID.
   * @param plotIndex The plot index.
   * @returns The plot Y axis clip path ID.
   */
  public static getPlotYAxisClipPathId(uniqueId: string, plotIndex: number) {
    return uniqueId + '-plot-clip-y-' + plotIndex;
  }
}

/**
 * The inner renderer for the clipping paths.
 */
export class PlotClippingRendererInner {

  /**
   * The chart layout.
   */
  public layout?: IPopulatedMultiPlotLayout;

  /**
   * The chart settings.
   */
  public settings?: IChartSettings;

  /**
   * Renders the clipping paths.
   * @param selection The selection to render to.
   */
  public render(selection: SVGSelection) {
    if (!this.layout || !this.settings) {
      return;
    }

    const settings = this.settings;

    let containerClassName = 'multi-plot-clipping-renderer';

    let containerUpdate = selection.selectAll<SVGGElement, any>('.' + containerClassName).data([null]);
    let containerEnter = containerUpdate.enter().append('g')
      .attr('class', containerClassName);
    let container = containerEnter.merge(containerUpdate);

    // Create a group for each plot.
    let gUpdate = container.selectAll<SVGGElement, ProcessedPlot>('.plot-clip-container').data(this.layout.processed.plots as ProcessedPlot[]);
    gUpdate.exit().remove();
    let gEnter = gUpdate.enter().append('g').attr('class', 'plot-clip-container');
    let g = gEnter.merge(gUpdate);

    // Create the clip path container for drawing area of the rectangular plot.
    let plotClipPathUpdate = g.select<SVGClipPathElement>('.plot-clip');
    let plotClipPathEnter = gEnter.append('clipPath')
      .attr('class', 'plot-clip')
      .attr('id', (d: ProcessedPlot, i: number) => PlotClippingRenderer.getPlotClipPathId(settings.uniqueId, i));
    let plotClipPath = plotClipPathEnter.merge(plotClipPathUpdate);

    // Create a clip path for the X axis area under the plot.
    let plotXClipPathUpdate = g.select<SVGClipPathElement>('.plot-clip-x');
    let plotXClipPathEnter = gEnter.append('clipPath')
      .attr('class', 'plot-clip-x')
      .attr('id', (d: ProcessedPlot, i: number) => PlotClippingRenderer.getPlotXAxisClipPathId(settings.uniqueId, i));
    let plotXClipPath = plotXClipPathEnter.merge(plotXClipPathUpdate);

    // Create a clip path for the Y axis area to the left of the plot.
    let plotYClipPathUpdate = g.select<SVGClipPathElement>('.plot-clip-y');
    let plotYClipPathEnter = gEnter.append('clipPath')
      .attr('class', 'plot-clip-y')
      .attr('id', (d: ProcessedPlot, i: number) => PlotClippingRenderer.getPlotYAxisClipPathId(settings.uniqueId, i));
    let plotYClipPath = plotYClipPathEnter.merge(plotYClipPathUpdate);

    plotClipPathEnter.append('rect');
    plotXClipPathEnter.append('rect');
    plotYClipPathEnter.append('rect');

    // Create a rectangle for the plot area.
    plotClipPath.select('rect')
      .attr('x', (d: ProcessedPlot) => d.absoluteRenderArea.x)
      .attr('y', (d: ProcessedPlot) => d.absoluteRenderArea.y)
      .attr('width', (d: ProcessedPlot) => d.absoluteRenderArea.width)
      .attr('height', (d: ProcessedPlot) => d.absoluteRenderArea.height);

    // Create a rectangle for the X axis area under the plot.
    plotXClipPath.select('rect')
      .attr('x', (d: ProcessedPlot) => d.absoluteRenderArea.x - settings.spaceBetweenPlots)
      .attr('y', (d: ProcessedPlot) => d.absoluteRenderArea.y + d.absoluteRenderArea.height)
      .attr('width', (d: ProcessedPlot) => d.absoluteRenderArea.width + 2 * settings.spaceBetweenPlots)
      .attr('height', (d: ProcessedPlot) => settings.spaceBetweenPlots + settings.chartMargin.bottom);

    // Create a rectangle for the Y axis area to the left of the plot.
    plotYClipPath.select('rect')
      .attr('x', (d: ProcessedPlot) => d.absoluteRenderArea.x - settings.spaceBetweenPlots - settings.chartMargin.left)
      .attr('y', (d: ProcessedPlot) => d.absoluteRenderArea.y - settings.spaceBetweenPlots)
      .attr('width', (d: ProcessedPlot) => settings.spaceBetweenPlots + settings.chartMargin.left)
      .attr('height', (d: ProcessedPlot) => d.absoluteRenderArea.height + 2 * settings.spaceBetweenPlots);
  }
}

import {Injectable} from '@angular/core';
import {IWorksheetContextMenuBuilder} from '../worksheet-context-menu-builder';
import {MenuItem} from '../../context-menu/context-menu-types';
import {RowItemViewModel} from '../row-item-view-model';
import {CommandContext, CommandResult, WorksheetContext} from './worksheet-command';

/**
 * A context menu builder for simulation row items.
 */
@Injectable()
export class SimulationContextMenuBuilder implements IWorksheetContextMenuBuilder {

  /**
   * Creates an instance of SimulationContextMenuBuilder.
   */
  constructor(){
  }

  /**
   * Generates the context menu items for the target row item.
   * @param target The target row item.
   * @param worksheetContext The worksheet context.
   * @returns The context menu items.
   */
  generateContextMenu(target: RowItemViewModel, worksheetContext: WorksheetContext): ReadonlyArray<MenuItem<CommandResult>> {
    // We don't currently have any simulation commands.
    let result: MenuItem<CommandResult>[] = [];

    const context = new CommandContext(target, worksheetContext);
    if(!context.isSimulation()){
      return result;
    }

    return result;
  }
}

import { ExplorationJobSweep } from './exploration-job-sweep';


/**
 * Represents a single job of an exploration.
 */
export class ExplorationJob {

  /**
   * Creates an instance of an exploration job.
   * @param name The name of the job.
   * @param sweeps The sweeps for the job.
   */
  constructor(
    public readonly name: string,
    public readonly sweeps: ReadonlyArray<ExplorationJobSweep>) {
  }
}


import { SourceData } from './types/source-data';
import { ProcessedMultiPlotSide } from './types/processed-multi-plot-side';
import { SideType } from './types/side-type';
import { ProcessedMultiPlotChannel } from './types/processed-multi-plot-channel';
import { ProcessedDataFactory } from './types/processed-data-factory';
import { AutoScaleChannelGroup } from '../channel-data-loaders/auto-scale-channel-group';


/**
 * Gets a processed side (column or row) given a set of processed channels, source data, and the side type.
 */
export class GetProcessedSide {

  constructor(
    private readonly autoScaleChannelsIndividually: boolean,
    private readonly processedDataFactory: ProcessedDataFactory,
    private readonly autoScaleChannelGroup: AutoScaleChannelGroup) {
  }

  /**
   * Gets a processed side (column or row) given a set of processed channels, source data, and the side type.
   * @param channels The set of processed channels for the side.
   * @param sourceData The set of data sources.
   * @param sideType The type of side (column or row).
   * @returns The processed side.
   */
  public execute(channels: ReadonlyArray<ProcessedMultiPlotChannel>, sourceData: ReadonlyArray<SourceData>, sideType: SideType): ProcessedMultiPlotSide {
    // Create a processed side instance.
    let processedSide = this.processedDataFactory.createSide(channels, sideType);

    if (sideType === SideType.column) {
      // You can't hide all x domain channels, so ensure at least one is visible.
      if (channels.length && channels.every(v => !v.isVisible)) {
        channels[0].isVisible = true;
      }
    }

    if (sideType === SideType.row && !this.autoScaleChannelsIndividually) {
      // If we a row, and we're not auto-scaling channels individually, auto-scale the entire side.
      this.autoScaleChannelGroup.execute(processedSide);
    } else {
      // If we're here we are either a column (X domain) channel, or we've been told to auto-scale channels individually.
      // We display X domain channels individually, so we auto-scale them individually too.
      processedSide.channels.forEach(c => this.autoScaleChannelGroup.executeForChannel(c));
    }

    // Get the vector of booleans indicating the visibility of each data source (the user can hide data sources).
    let sourcesVisibility = sourceData.map(v => v.isVisible);

    // Get the maximum and minimum values of all channel across all visible data sources.
    let visibleMinimum = processedSide.getVisibleMinimum(sourcesVisibility) || 0;
    let visibleMaximum = processedSide.getVisibleMaximum(sourcesVisibility) || 0;

    // If the minimum and maximum values are the same then add some padding so we can have a sensible
    // looking scale on the axis.
    if (visibleMinimum === visibleMaximum) {
      visibleMinimum -= 1;
      visibleMaximum += 1;
    }

    // Set the D3 scale domain to the minimum and maximum values.
    processedSide.scale.domain([visibleMinimum, visibleMaximum]);

    return processedSide;
  }
}

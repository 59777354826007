import {Injectable} from '@angular/core';
import {DialogManager, IDialog, DialogBase} from '../../common/dialogs/dialog-manager.service';
import {ListFilterGroup} from '../../../generated/api-stubs';

/**
 * The study loader dialog service.
 */
@Injectable()
export class StudyLoaderDialog {

  /**
   * Creates an instance of StudyLoaderDialog.
   * @param dialogManager The dialog manager.
   */
  constructor(
    private dialogManager: DialogManager
  ){}

  /**
   * Displays the dialog to load a study and returns the result of the dialog.
   * @param tenantId The tenant ID.
   * @param userId The user ID.
   * @param initialQuery The initial query.
   */
  public async loadStudy(
    tenantId: string,
    userId: string,
    initialQuery?: ListFilterGroup): Promise<StudyLoaderResult> {

    let result = await this.dialogManager.showSecondaryDialog(
      new StudyLoaderDialogData(
        tenantId,
        userId,
        initialQuery));

    return result;
  }
}

/**
 * The data for the study loader dialog.
 */
export class StudyLoaderDialogData extends DialogBase<StudyLoaderResult> implements IDialog {
  constructor(
    public readonly tenantId: string,
    public readonly userId: string,
    public readonly initialQuery: ListFilterGroup) {
    super();
  }
}

/**
 * The result of the study loader dialog.
 */
export interface StudyLoaderResult {
  userId: string;
  studyId: string;
  name: string;
}

import { Injectable } from '@angular/core';

export const RELEASE_NOTES_FILE_NAME = 'Release Notes.md';

@Injectable({
  providedIn: 'root'
})
export class ParseReleaseNotes {

  public execute(content: string): ReleaseNote[]{
    let regex = /(^|\s)##\s+([0-9\.]+)\s+-\s+(\d+\s+\w+\s+\d+)\s/g;

    let headings: ReleaseNoteHeading[] = [];
    for(let match of content.matchAll(regex)) {
      headings.push(new ReleaseNoteHeading(
        match[2],
        match[3],
        match.index,
        match[0].length));
    }

    let notes: ReleaseNote[] = [];
    for(let i=0; i < headings.length; ++i){
      let heading = headings[i];

      let itemContent = content.substring(
        heading.startIndex + heading.length,
        (i === headings.length - 1 ? content.length + 1 : headings[i + 1].startIndex));

      if(i === headings.length - 1){
        itemContent = itemContent.replace(/### (\[Release Notes Archive\]).*/g, '');
      }

      notes.push(new ReleaseNote(
        heading.simVersion,
        heading.date,
        itemContent));
    }

    return notes;
  }
}

class ReleaseNoteHeading {
  constructor(
    public simVersion: string,
    public date: string,
    public startIndex: number,
    public length: number){
  }
}

export class ReleaseNote {
  constructor(
    public simVersion: string,
    public date: string,
    public content: string){
  }
}

<div id="view-sim-version-documents-page">
  <div class="container-fluid">
    <cs-error-message [customClass]="'sticky-top'" [message]="errorMessage"></cs-error-message>

    @if (tenants) {
      <div>
        <h2>Compare Target</h2>
        <p>
          @if (tenants) {
            <select id="compare-tenant-select" (change)="selectedTenantId = $event.target.value">
              @for (t of tenants; track t) {
                <option [value]="t.tenantId">{{t.name}}</option>
              }
            </select>
          }
        </p>
      </div>
    }

    @if (!documents) {
      <cs-loader></cs-loader>
    }

    @for (document of documents; track document) {
      <div class="card">
        <h1 class="card-header">
          {{document.name}}
          @if (isAdministrator) {
            <button id="compare-{{document.id}}" class="btn btn-smart btn-secondary"
              (click)="compareDocument(document)"
              [disabled]="document.comparing">
              @if (!document.comparing) {
                <span>Compare</span>
              }
              @if (document.comparing) {
                <span>Comparing...</span>
              }
            </button>
          }
        </h1>
        <div class="card-body">
          @if (document.content) {
            <textarea readonly id="{{document.id}}" class="raw-json">{{document.content}}</textarea>
          }
          @if (!document.content) {
            <button id="load-{{document.id}}" class="btn btn-smart btn-secondary"
              (click)="loadDocument(document)"
              [disabled]="document.loading">
              @if (!document.loading) {
                <span>Load</span>
              }
              @if (document.loading) {
                <span>Loading...</span>
              }
            </button>
          }
        </div>
      </div>
    }
  </div>
</div>

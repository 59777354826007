import {Component, Input} from '@angular/core';
import {TelemetryConfigType} from '../config-types';
import {
  ConditionMetadataName, ConditionOperator, ConditionSource, GroupOperator,
  ListFilterGroup, StudyType
} from '../../../../generated/api-stubs';
import {StudySummary} from '../../studies/view-studies/view-studies.component';
import {Router} from '@angular/router';

@Component({
  selector: 'cs-view-telemetry-configs',
  templateUrl: './view-telemetry-configs.component.html',
  styleUrls: ['./view-telemetry-configs.component.scss']
})
export class ViewTelemetryConfigsComponent {
  @Input() public tenantId: string;
  @Input() public userId: string;

  public configType = TelemetryConfigType;

  public initialQuery: ListFilterGroup = {
    operator: GroupOperator.and,
    conditions: [
      {
        source: ConditionSource.metadata,
        name: ConditionMetadataName.studyType,
        operator: ConditionOperator.equals,
        value: StudyType.telemetry
      }
    ]
  };

  constructor(private readonly router: Router){
  }

  public studySelected(study: StudySummary) {
    this.router.navigate(['/studies', study.tenantId, study.studyId]);
  }

}

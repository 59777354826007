import { SourceData } from './types/source-data';
import { IPopulatedMultiPlotSide } from './types/i-populated-multi-plot-side';
import { IPopulatedMultiPlotLayout } from './types/i-populated-multi-plot-layout';
import { ProcessedPlot } from './types/processed-plot';
import { SideType } from './types/side-type';
import { MergeSides } from './merge-sides';
import { PlotSetDirection } from './plot-set-direction';


/**
 * Create the set of rows which should be rendered for the given set of
 * diagonal plot sets, taking into account options around stacking diagonal plots.
 */
export class CreateRowsFromDiagonals {
  constructor(
    private readonly mergeSides: MergeSides) {
  }

  /**
   * For a given set of diagonal plot sets and diagonal stacking options, return the final
   * set of rows to be rendered.
   * @param diagonalPlotSets The collection of diagonal plot sets.
   * @param plotSetDirection The plot set direction.
   * @param layout The populated layout.
   * @param sourceData The set of data sources.
   * @returns The set of columns to be rendered.
   */
  public execute(
    diagonalPlotSets: readonly ProcessedPlot[][],
    plotSetDirection: PlotSetDirection,
    layout: IPopulatedMultiPlotLayout,
    sourceData: ReadonlyArray<SourceData>): IPopulatedMultiPlotSide[] {

    // If the plot set direction is horizontal (we have more columns than rows) then
    // we only need the first plot set (because the rows are the same for each
    // plot set).
    //
    // For example:
    //
    //         ┌─┐ Plot set 2
    //      ┌─┐    Plot set 1
    //     │  ▉  ▉
    //     │ ▉  ▉
    //     │▉  ▉
    //     └──────
    if (plotSetDirection === PlotSetDirection.horizontal) {
      diagonalPlotSets = [diagonalPlotSets[0]];
    }

    // If we're stacking the diagonals horizontally then we need to merge the rows
    // of each of the relevant plot sets.
    //
    // The above example ultimately becomes (note again that we only need to merge the
    // rows of the first plot set because the rows are the same for each plot set):
    //
    //         ┌─┐ Plot set 2
    //      ┌─┐    Plot set 1
    //     │▉▉▉▉▉▉
    //     └──────
    //
    // A chart with a vertical plot set direction like the following:
    //
    //     │ ▉   ┐
    //     │▉    ┘ Plot set 1
    //     │ ▉   ┐
    //     │▉    ┘ Plot set 2
    //     └──
    //
    // Would become:
    //
    //     │▉▉  |  Plot set 1
    //     │▉▉   | Plot set 2
    //     └──
    if (layout.stackDiagonalsHorizontally) {
      return diagonalPlotSets.map(
        diagonalPlots => this.mergeSides.execute(diagonalPlots.map(v => v.row), sourceData, SideType.row));
    }

    // Return the final set of rows.
    return diagonalPlotSets.reduce((p, c) => {
      p.push(...c);
      return p;
    }, []).map(v => v.row);
  }
}

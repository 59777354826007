import {Injectable} from '@angular/core';
import {ConfigResolvedReference, DocumentSubType} from '../../generated/api-stubs';
import {WorksheetViewModel} from './worksheet-view-model';

/**
 * Deduplicates the row at the specified index in the worksheet, by searching
 * through the worksheet for identical configs and replacing the reference on the
 * target row with the reference on the row containing the identical config.
 */
@Injectable()
export class DeduplicateWorksheetRow {

  /**
   * Deduplicates the row at the specified index in the worksheet, by searching
   * through the worksheet for identical configs and replacing the reference on the
   * target row with the reference on the row containing the identical config.
   * @param worksheet The worksheet we're performing the deduplication on.
   * @param rowIndex The index of the row we're deduplicating.
   */
  public execute(worksheet: WorksheetViewModel, rowIndex: number){

    // If the row index is out of bounds, return.
    if(rowIndex < 0 || rowIndex >= worksheet.rows.length) {
      return;
    }

    // Get the target row and add all the resolved configs to a set.
    const targetRow = worksheet.rows[rowIndex];
    let remainingConfigs = new Set<DocumentSubType>();
    for(let config of targetRow.configs){
      // Ignore telemetry - we don't need to deduplicate it as it is just a reference.
      if(config.isResolved && config.configType !== DocumentSubType.telemetry){
        remainingConfigs.add(config.configType);
      }
    }

    // Iterate backwards through the rows in the worksheet...
    for (let i = worksheet.rows.length - 1; i >= 0; --i) {

      // Skip the row we're deduplicating.
      if (i === rowIndex) {
        continue;
      }

      let row = worksheet.rows[i];

      // Create a copy of the remaining configs set.
      let remainingConfigList = Array.from(remainingConfigs);

      // For each remaining config type in the original row...
      for(let configType of remainingConfigList){
        let sourceConfig = row.getConfig(configType);
        if(!sourceConfig || !sourceConfig.isResolved){
          // If the config on the current row is not resolved, skip it.
          continue;
        }

        // Get the config of the same type in the current row.
        let targetConfig = targetRow.getConfig(configType);

        let sourceResolvedReference = sourceConfig.populated.resolvedReference as ConfigResolvedReference;
        let targetResolvedReference = targetConfig.populated.resolvedReference as ConfigResolvedReference;

        // If the names match, the user ids match, and at least one hash matches, then we can deduplicate.
        if(sourceResolvedReference.data.name === targetResolvedReference.data.name
          && sourceResolvedReference.data.userId === targetResolvedReference.data.userId
          && sourceResolvedReference.data.hashes.some(s => targetResolvedReference.data.hashes.some(t => s.hash === t.hash))){

          // Remove the config type from the set of remaining config types.
          remainingConfigs.delete(configType);

          // Set the config on the row we're deduplicating to the same reference as the config on the current row.
          targetConfig.setConfig(sourceConfig.reference);
        }
      }
    }
  }
}

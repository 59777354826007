import {Injectable} from '@angular/core';
import {ApplicationInsights} from './application-insights.service';
import {ServiceOnInit} from './service-on-init';
import {Router, NavigationEnd} from '@angular/router';
import {Event} from '@angular/router';

@Injectable()
export class ApplicationInsightsRouteChangedHandler implements ServiceOnInit {
  constructor(
    private applicationInsights: ApplicationInsights,
    private router: Router) {
  }

  public serviceOnInit() {
    this.router.events.subscribe((event: Event) => this.handleRouteChange(event as NavigationEnd));
  }

  public handleRouteChange(event: NavigationEnd) {
    if(event instanceof NavigationEnd){
      let url = (<NavigationEnd>event).url;
      this.applicationInsights.trackPageView(url, url);
    }
  }
}

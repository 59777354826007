import {isNumber} from './is-number';

const hoursMinutesSecondsRegex = /^([0-9]+):([0-9]+):([0-9]+\.[0-9]+)$/;
export function tryParseAsNumber(value: string | number): number | undefined{
  if(typeof value === 'number'){
    return value;
  }

  value = value.toLowerCase();
  if (value === '') {
    return NaN;
  }
  if (value === 'nan') {
    return NaN;
  }
  if (value === 'inf' || value === 'infinity') {
    return NaN;
  }
  if (value === '-inf' || value === '-infinity') {
    return NaN;
  }

  let hmsMatch = hoursMinutesSecondsRegex.exec(value);
  if (hmsMatch) {
    return parseFloat(hmsMatch[1]) * 3600
      + parseFloat(hmsMatch[2]) * 60
      + parseFloat(hmsMatch[3]);
  }

  if (!isNumber(value)) {
    return undefined;
  }

  return parseFloat(value);
}

export function asNumber(value: string | number): number {
  const result = tryParseAsNumber(value);
  if(result  === undefined){
    return NaN;
  }

  return result;
}

import { Observable, Subject } from 'rxjs';
import { ProcessedExplorationMap } from './explorations/processed-exploration-map';
import { StudyExplorationAndScalarData } from './load-study-exploration-and-scalar-data';
import { StudyExplorationAndScalarDataCache } from './study-exploration-and-scalar-data-cache';
import { ChannelScalarDataAndMappings } from './channel-scalar-data-and-mappings';
import { OrderedUniqueIndices } from '../../ordered-unique-indices';
import { Utilities } from '../../utilities';

/**
 * Wraps a StudyExplorationAndScalarDataCache and filters out bad job indices.
 */
export class FilteredStudyExplorationAndScalarDataCache {

  /**
   * The changed event. Raised when new bad job indices are added.
   */
  private _changed: Subject<void> = new Subject();

  /**
   * The bad job indices.
   */
  private badJobIndices: number[] =[];

  /**
   * Creates a new instance of FilteredStudyExplorationAndScalarDataCache.
   * @param studyExplorationAndScalarDataCache The study exploration and scalar data cache.
   */
  constructor(private readonly studyExplorationAndScalarDataCache: StudyExplorationAndScalarDataCache) {}

  /**
   * Gets the changed event. Raised when new bad job indices are added.
   */
  public get changed(): Observable<void> {
    return this._changed;
  }

  /**
   * Initializes the cache.
   */
  public async initialize(): Promise<void>{
    await this.studyExplorationAndScalarDataCache.initialize();
  }

  /**
   * Gets the study exploration and scalar data with any bad jobs filtered out.
   * @returns The study exploration and scalar data with any bad jobs filtered out.
   */
  public get(): StudyExplorationAndScalarData | undefined {
    // Fetch the unfiltered data from the cache.
    let originalData = this.studyExplorationAndScalarDataCache.get();
    if(this.badJobIndices.length > 0){
      // Convert the list of bad job indices to bad line indices.
      let badLineIndices = OrderedUniqueIndices.from(this.badJobIndices.map(i => this.jobIndexToLineIndex(i, originalData.scalarData.lineIndexToJobIndexMap)));

      // Make a new ChannelScalarDataAndMappings, with a clone fo the lineIndexToJobIndexMap.
      let newScalarData = new ChannelScalarDataAndMappings(
        structuredClone(originalData.scalarData.data),
        originalData.scalarData.map,
        originalData.scalarData.inputsMetadataMap,
        [...originalData.scalarData.lineIndexToJobIndexMap]);

      // Make a copy of the jobs list.
      let newJobs = [...originalData.explorationMap.jobs];

      // Strip out the bad items from the jobs list, the lineIndexToJobIndexMap, and every scalar data array.
      Utilities.strip(
        badLineIndices,
        newJobs,
        newScalarData.lineIndexToJobIndexMap,
        ...newScalarData.getChannelNames().map(v => newScalarData.data[v].values));

      // Create a new ProcessedExplorationMap with the filtered data.
      let newExplorationMap = new ProcessedExplorationMap(
        originalData.explorationMap.designName,
        originalData.explorationMap.inputs,
        newJobs);

      // And return the filtered data.
      return new StudyExplorationAndScalarData(newScalarData, newExplorationMap);
    }else{
      // No filtering required, so just return the original data.
      return originalData;
    }
  }

  /**
   * Adds a bad job index. This isn't the most efficient implementation, but it's good enough for now.
   * @param jobIndex The job index.
   */
  public addToBadIndices(lineIndex: number){

    // Get the original data from the cache.
    let originalData = this.studyExplorationAndScalarDataCache.get();

    // Make a copy of the map from line index to job index.
    let lineIndexToJobIndexMap = [...originalData.scalarData.lineIndexToJobIndexMap];

    // Use the map to convert the current bad job indices to bad line indices.
    let badLineIndices = OrderedUniqueIndices.from(this.badJobIndices.map(i => this.jobIndexToLineIndex(i, lineIndexToJobIndexMap)));

    // Remove the bad line indices from the map.
    Utilities.strip(badLineIndices, lineIndexToJobIndexMap);

    // Use updated the map to convert the new bad line index to a bad job index.
    let jobIndex = lineIndexToJobIndexMap[lineIndex];

    // Push the new bad job index.
    this.badJobIndices.push(jobIndex);

    // Raise the changed event.
    this._changed.next();
  }

  /**
   * Converts a job index to a line index.
   * @param jobIndex The job index.
   */
  private jobIndexToLineIndex(jobIndex: number, lineIndexToJobIndexMap: readonly number[]): number {
    return lineIndexToJobIndexMap.indexOf(jobIndex);
  }
}

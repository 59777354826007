import { ChannelNameStyle } from './viewers/channel-data-loaders/channel-name-style';
import { Utilities } from './utilities';
import { Subject } from 'rxjs';
import { IChannelMetadata } from './viewers/channel-data-loaders/source-loader';
import { ISize } from './viewers/size';
import { LineMouseEvent } from './viewers/parallel-coordinates-viewer/parallel-coordinates-types';

/**
 * SiteHooks is a collection of methods that are provided by the site to the visualizations.
 * The site is responsible for implementing these methods.
 * This is less necessary now the visualizations are part of `canopy-web`. Previously they
 * were in a separate repository.
 */
export interface SiteHooks {
  /**
   * An event that is triggered when the units for a channel / parameter have been changed.
   */
  unitsChangedEvent: Subject<any>;

  /**
   * Called when the SiteHooks instance is no longer needed.
   */
  dispose(): void;

  /**
   * Save the layout of a viewer as a new layout.
   * @param viewerType The type of viewer.
   * @param config The config JSON to save.
   * @returns A promise that resolves to the viewer ID and name of the saved layout.
   */
  saveViewerLayoutAs(viewerType: string, config: any): Promise<{ viewerId: any; name: string }>;

  /**
   * Check if the viewer layout can be saved.
   * @param viewerType The type of viewer.
   * @param layoutId The layout ID.
   * @returns A promise that resolves to true if the viewer layout can be saved.
   */
  canSaveViewerLayout(viewerType: string, layoutId: ResolvedLayoutId): Promise<boolean>;

  /**
   * Save the layout of a viewer.
   * @param viewerType The type of viewer.
   * @param layoutId The layout ID.
   * @param name The name of the layout.
   * @param config The config JSON to save.
   * @returns A promise that resolves when the layout has been saved.
   */
  saveViewerLayout(viewerType: string, layoutId: ResolvedLayoutId, name: string, config: any): Promise<void>;

  /**
   * Save the metadata of a viewer.
   * @param viewerType The type of viewer.
   * @param layoutId The layout ID.
   * @param viewerMetadata The metadata to save.
   * @returns A promise that resolves when the metadata has been saved.
   */
  saveViewerMetadata(viewerType: string, layoutId: ResolvedLayoutId, viewerMetadata: ViewerMetadata): Promise<void>;

  /**
   * Load the layout of a viewer.
   * @param viewerType The type of viewer.
   * @param layoutId The layout ID.
   * @returns A promise that resolves to the layout ID, name and config of the loaded layout.
   */
  loadViewerLayout(viewerType: string, layoutId?: ResolvedLayoutId): Promise<LoadViewerLayoutResult>;

  /**
   * Load the metadata of a viewer.
   * @param viewerType The type of viewer.
   * @param layoutId The layout ID.
   * @returns A promise that resolves to the metadata of the loaded viewer.
   */
  loadViewerMetadata(viewerType: string, layoutId: ResolvedLayoutId): Promise<ViewerMetadata | undefined>;

  /**
   * Edit a viewer. Deprecated.
   * @param viewerType The type of viewer.
   * @param layoutConfig The layout config.
   * @param studyId The study ID.
   * @param jobIndex The job index.
   * @param simTypes The simulation types.
   * @param channelNameStyle The channel name style.
   * @returns A promise that resolves to the edited layout.
   */
  editViewerDeprecated(viewerType: string, layoutConfig: any, studyId: string, jobIndex: number, simTypes: ReadonlyArray<string>, channelNameStyle: ChannelNameStyle): Promise<any>;

  /**
   * Edit the channels of a viewer.
   * @param viewerType The type of viewer.
   * @param layoutConfig The layout config.
   * @param studyId The study ID.
   * @param jobIndex The job index.
   * @param simTypes The simulation types.
   * @param channelNameStyle The channel name style.
   * @returns A promise that resolves to the edited set of panes.
   */
  editViewerChannels(options: IEditChannelsOptions): Promise<PaneChannelLayout | undefined>;

  /**
   * Ensures units have been loaded.
   * @returns A promise that resolves when the units have been loaded.
   */
  ensureUnitsLoaded(): Promise<void>;

  /**
   * Get the default units for a channel.
   * @param channelName The channel name.
   * @returns A promise that resolves to the default units.
   */
  getDefaultChannelUnits(channelName: string): Promise<string>;

  /**
   * Get the user specified units for a channel.
   * @param channelName The channel name.
   * @returns A promise that resolves to the user specified units.
   */
  getUserChannelUnits(channelName: string): Promise<string | undefined>;

  /**
   * Get the user specified units for a channel synchronously.
   * @param channelName The channel name.
   * @returns The user specified units.
   */
  getUserChannelUnitsSynchronous(channelName: string): string | undefined;

  /**
   * Set the user specified units for a channel.
   * @param channelName The channel name.
   * @param units The units.
   * @returns A promise that resolves when the units have been set.
   */
  editChannelUnits(channelName: string, currentUnits: string): Promise<void>;

  /**
   * Get the user's default layout ID that should be used for this viewer.
   * @param viewerType The type of viewer.
   * @param layoutIds The possible set of layout IDs to which the user may have assigned a default layout.
   * @returns A promise that resolves to the default layout ID. Note the exact structure of the result is unimportant
   * as it is simply passed back to other methods in this interface such as `loadViewerLayout`.
   */
  getViewerDefaultLayoutId(viewerType: string, layoutIds: RequestedLayoutIds): Promise<any>;

  /**
   * Set the user's default layout ID that should be used for this viewer.
   * @param viewerType The type of viewer.
   * @param layoutIds The possible set of layout IDs to which the user may have assigned a default layout.
   * @param defaultLayoutId The default layout ID.
   * @returns A promise that resolves when the default layout ID has been set.
   */
  setViewerDefaultLayoutId(viewerType: string, layoutIds: RequestedLayoutIds, defaultLayoutId: ResolvedLayoutId): Promise<any>;

  /**
   * Get a friendly error message for the given error.
   * @param error The error.
   * @returns The friendly error message.
   */
  getFriendlyErrorAndLog(error: any): string;

  /**
   * Get the study name.
   * @param studyId The study ID.
   * @returns A promise that resolves to the study name.
   */
  getStudyName(studyId: string): Promise<string>;

  /**
   * Get the study job name.
   * @param studyId The study ID.
   * @param jobIndex The job index.
   * @returns A promise that resolves to the job name.
   */
  getStudyJobName(studyId: string, jobIndex: number): Promise<string>;

  /**
   * Show the study job page over the viewer.
   * @param studyId The study ID.
   * @param jobIndex The job index.
   * @param event The event containing additional metadata.
   * @returns A promise that resolves when the study job has been shown.
   */
  showStudyJob(studyId: string, jobIndex: number, event: LineMouseEvent): Promise<void>;
}

/**
 * A class representing a set of layout IDs. The primary layout ID is the currently used one.
 * The fallback layout IDs are usually IDs that were previously the primary layout ID but were
 * superseded, for example when sLap was superseded by sRun. We still pass in the old layout
 * IDs because the user may have overrides set for them.
 */
export class RequestedLayoutIds {
  /**
   * Create a new RequestedLayoutIds instance.
   * @param primary The primary layout ID.
   * @param fallback The fallback layout IDs.
   */
  constructor(
    public readonly primary: string,
    public readonly fallback: ReadonlyArray<string> = []) {
  }
}

/**
 * A class representing the result of loading a viewer layout.
 */
export class LoadViewerLayoutResult {

  /**
   * Create a new LoadViewerLayoutResult instance.
   * @param layoutId The layout ID.
   * @param name The name of the layout.
   * @param config The config of the layout.
   */
  constructor(
    public readonly layoutId: ResolvedLayoutId,
    public readonly name: string,
    private readonly _config: any) {
  }

  /**
   * Get a copy of the config.
   * @returns A copy of the config.
   */
  public getConfigCopy(): any {
    return Utilities.deepClone(this._config);
  }

  /**
   * Gets whether the instance has a config.
   */
  public get hasConfig(): boolean {
    return !!this._config;
  }
}

/**
 * A class representing the metadata of a viewer.
 */
export class ViewerMetadata {

  /**
   * Create a new ViewerMetadata instance.
   * @param size The size of the viewer.
   */
  constructor(
    public readonly size: ISize) {
  }
}

/**
 * A resolved layout ID. This can either be an internal layout ID, which is a string,
 * or one supplied by the implementor of the ISiteHooks interface.
 * A supplied layout ID can be any object, as it is simply stored and passed back into other
 * ISiteHooks methods.
 */
export type ResolvedLayoutId = string | any;

/**
 * A type representing the channel metadata passed to the `editViewerChannels` method.
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IEditChannelsChannelMetadata extends IChannelMetadata {
}

/**
 * The channel metadata passed to the `editViewerChannels` method.
 */
export class EditChannelsChannelMetadata implements IEditChannelsChannelMetadata {

  /**
   * Create a new EditChannelsChannelMetadata instance.
   * @param name The name of the channel.
   * @param description The description of the channel.
   */
  constructor(
    public readonly name: string,
    public readonly description: string) {
  }

  /**
   * Get the source data.
   * @returns The source data.
   */
  public getSourceData<TSourceData>(): TSourceData {
    return {} as TSourceData;
  }
}

/**
 * A channel in a viewer pane.
 */
export interface IPaneChannel {
  name: string;
  isVisible?: boolean;
  isDelta?: boolean;
}

/**
 * A pane in a viewer.
 */
export interface IPaneChannelData {
  channels: IPaneChannel[];
  relativeSize?: number;
}

/**
 * A list of panes in a viewer.
 */
export type PaneChannelLayout = IPaneChannelData[];

/**
 * The options passed to the `editViewerChannels` method.
 */
export interface IEditChannelsOptions {
  channels: IEditChannelsChannelMetadata[];
  panes: PaneChannelLayout;
  oneChannelPerPane?: boolean;
}

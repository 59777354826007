import {GetStudyJobMetadataQueryResult, GetStudyJobQueryResult, StudyStub} from '../../../../generated/api-stubs';
import {LazyLoaded} from './lazy-loaded';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {Injectable} from '@angular/core';

@Injectable()
export class LazyLoadedStudyJobMetadataFactory {
  constructor(
    private readonly studyStub: StudyStub,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  public create(studyJob: LazyLoaded<GetStudyJobQueryResult>, tenantId: string, studyId: string, jobId: string): LazyLoaded<GetStudyJobMetadataQueryResult>{
    return new LazyLoaded<GetStudyJobMetadataQueryResult>(
      async () => {
        if(studyJob.isLoading){
          await studyJob.load();
        }

        if(studyJob.isLoaded){
          return studyJob.value;
        }

        return await this.studyStub.getStudyJobMetadata(
          tenantId,
          studyId,
          jobId);
      },
      this.getFriendlyErrorAndLog);
  }
}

<div class="container-fluid">
  <div class="card">
    <h1 class="card-header">Account Settings</h1>

    <div class="card-body">
      <cs-error-message [message]="errorMessage"></cs-error-message>
      @if (!form) {
        <cs-loader></cs-loader>
      }
      @if (form) {
        <div class="row">
          <div class="col-12 col-sm-6">
            <form (ngSubmit)="onSubmit()" [formGroup]="form">
              <div class="mb-3">
                <label class="form-label" for="email-input">Email</label>
                <input id="email-input" type="text" class="form-control" formControlName="email">
                @if (!email.pristine && email.hasError('required')) {
                  <p class="text-danger">
                    Email is required.
                  </p>
                }
                @if (email.hasError('minlength')) {
                  <p class="text-danger">
                    Email is too short.
                  </p>
                }
                @if (email.hasError('maxlength')) {
                  <p class="text-danger">
                    Email is too long.
                  </p>
                }
                @if (email.hasError('email')) {
                  <p class="text-danger">
                    Email must be valid.
                  </p>
                }
              </div>
              <div class="mb-3">
                <label class="form-label" for="username-input">Username</label>
                <input id="username-input" type="text" class="form-control" formControlName="username">
                @if (!username.pristine && username.hasError('required')) {
                  <p class="text-danger">
                    Username is required.
                  </p>
                }
                @if (username.hasError('minlength')) {
                  <p class="text-danger">
                    Username is too short.
                  </p>
                }
                @if (username.hasError('maxlength')) {
                  <p class="text-danger">
                    Username is too long.
                  </p>
                }
              </div>
              @if (!changePassword) {
                <p>
                  <button type="button" id="change-password-button" (click)="setChangePassword(true)" class="btn btn-smart btn-secondary">Change Password</button>
                </p>
              }
              @if (changePassword) {
                <div class="mb-3">
                  <label class="form-label" for="password-input">New Password</label>
                  <input id="password-input" type="password" class="form-control" formControlName="password">
                  @if (password.hasError('minlength')) {
                    <p class="text-danger">
                      Password is too short.
                    </p>
                  }
                  @if (password.hasError('maxlength')) {
                    <p class="text-danger">
                      Password is too long.
                    </p>
                  }
                </div>
              }
              @if (!isSelf) {
                <div class="mb-3">
                  <input id="is-enabled-input" formControlName="isUserEnabled" type="checkbox">
                  <label class="form-label" for="is-enabled-input">Enable Account</label>
                </div>
              }
              @if (isSelf) {
                <div class="mb-3">
                  <label class="form-label" for="current-password-input">Current Password</label>
                  <input id="current-password-input" type="password" class="form-control" formControlName="currentPassword">
                  @if (!currentPassword.pristine && currentPassword.hasError('required')) {
                    <p class="text-danger">
                      Your current password is required.
                    </p>
                  }
                </div>
              }
              @if (saveSuccessful) {
                <div id="success-message" class="alert alert-success">
                  Saved.
                </div>
              }
              <button id="account-settings-submit" type="submit" class="btn btn-smart btn-primary" [disabled]="submitButton.isSubmitting">{{submitButton.text}}</button>
            </form>
          </div>
        </div>
      }
    </div>
  </div>

  <cs-user-roles [tenantId]="tenantId" [userId]="userId"></cs-user-roles>
  <cs-multi-factor-authentication [tenantId]="tenantId" [userId]="userId"></cs-multi-factor-authentication>
  @if (form) {
    <cs-sign-out-everywhere [tenantId]="tenantId" [userId]="userId"></cs-sign-out-everywhere>
  }
</div>

import { ParallelCoordinatesDataRenderer } from './parallel-coordinates-data-renderer';
import { LineMouseEvent, ParallelCoordinatesData } from './parallel-coordinates-types';
import { DomainNewsEvent, SharedState } from '../shared-state';
import { INDEX_DOMAIN_NAME } from '../../constants';
import { SVGSelection } from '../../untyped-selection';
import { StudySourceLoader, UnderlyingStudyData } from '../channel-data-loaders/study-source-loader';
import { SiteHooks } from '../../site-hooks';
import { IErrorHandler } from './error-handler';
import { ParallelCoordinatesViewerSettings } from './parallel-coordinates-viewer-settings';

export const AVERAGE_PIXELS_PER_CHARACTER = 5.2;

/**
 * Class to handle events such as mouseover and click on the parallel coordinates data.
 */
export class ParallelCoordinatesDataEventHandler {

  /**
   * The parallel coordinates data.
   */
  private data?: ParallelCoordinatesData;

  /**
   * The tooltips to display.
   */
  private tooltips: ToolTip[] = [];

  /**
   * Create a new instance of the parallel coordinates data event handler.
   * @param errorHandler The error handler.
   * @param svg The SVG selection.
   * @param sharedState The shared state.
   * @param siteHooks The site hooks.
   * @param settings The parallel coordinates viewer settings.
   * @param dataRenderer The parallel coordinates data renderer.
   */
  constructor(
    private readonly errorHandler: IErrorHandler,
    private readonly svg: SVGSelection,
    private readonly sharedState: SharedState,
    private readonly siteHooks: SiteHooks,
    private readonly settings: ParallelCoordinatesViewerSettings,
    private readonly dataRenderer: ParallelCoordinatesDataRenderer) {

    // Hook into mouseover, mouseout and click events. Note these events are raised by
    // our own code in the data renderer.
    this.dataRenderer
      .on('mouseover', (currentEvent: any) => this.handleMouseOver(currentEvent))
      .on('mouseout', () => this.handleMouseOut())
      .on('click', (currentEvent: any) => this.handleClick(currentEvent));
  }

  // Set the data for the parallel coordinates data event handler.
  public setData(data: ParallelCoordinatesData) {
    this.data = data;
  }

  /**
   * Called when a mouseover event occurs on the parallel coordinates data.
   * @param lineEvent The event event.
   */
  private handleMouseOver(lineEvent: LineMouseEvent) {
    // If the user is brushing the axis, don't show the tooltip.
    if (!this.data || this.data.dimensionList.some(v => v.isBrushing)) {
      return;
    }

    // Get the underlying study data, which contains the exploration map.
    let studyData = this.getStudyData();
    if (!studyData) {
      return;
    }

    if (this.sharedState) {
      // Raise an event on the index domain indicating which line we're hovering over.
      this.sharedState.getDomainNews(INDEX_DOMAIN_NAME).raise(
        new DomainNewsEvent(
          this.sharedState.sourceLoaderSet.sources.map(v => lineEvent.lineIndex)));
    }

    // Get the mouse position.
    let event = <MouseEvent>lineEvent.sourceEvent;
    let xSvg = event.offsetX;
    let ySvg = event.offsetY;

    // Get the job name.
    let dataLabel = studyData.data.explorationData.explorationMap.jobs[lineEvent.lineIndex].name;

    if (dataLabel) {
      // Push a new tooltip at the mouse location.
      this.tooltips.push(new ToolTip(xSvg, ySvg, dataLabel));
    }

    this.render();
  }

  /**
   * Render the tooltips and the click helper text.
   */
  private render() {
    // Create a tooltip container for each item in the tooltips array.
    let tooltipContainerUpdate = this.svg.selectAll<SVGGElement, ToolTip>('.job-tooltip').data(this.tooltips);
    tooltipContainerUpdate.exit().remove();
    let tooltipContainerEnter = tooltipContainerUpdate.enter().append('g')
      .attr('id', 'job-tooltip')
      .attr('class', 'job-tooltip');
    let tooltipContainer = tooltipContainerUpdate.merge(tooltipContainerEnter);

    // Position the tooltip containers.
    tooltipContainer
      .attr('transform', d => 'translate(' + (d.x + 10) + ',' + (d.y - 20) + ')');

    // Draw the tooltip box.
    tooltipContainerEnter.append('rect')
      .attr('class', 'tooltip-box')
      .attr('height', 15)
      .attr('rx', 5)
      .attr('ry', 5);
    tooltipContainer.select('.tooltip-box')
      .attr('width', (d) => d.label.length * AVERAGE_PIXELS_PER_CHARACTER);

    // Set the tooltip text.
    tooltipContainerEnter.append('text')
      .attr('x', 2)
      .attr('y', 11)
      .attr('text-anchor', 'start');
    tooltipContainer.select('text')
      .text(d => d.label);

    // Create a container for the click helper text that appears when hovering over the data.
    let helperUpdate = this.svg.selectAll<SVGGElement, unknown>('.mouse-click-helper').data([null]);
    let helperEnter = helperUpdate.enter().append<SVGGElement>('g')
      .attr('class', 'mouse-click-helper');
    let helper = helperUpdate.merge(helperEnter);
    helperEnter
      .attr('transform', `translate(${this.settings.svgSize.width - 4}, 11)`)
      .append('text')
      .attr('text-anchor', 'end')
      .style('opacity', 0)
      .text('Click to view job, Control+Click to add to Compare Results area or Alt+Click to remove data from interpolation.');

    // Transition the help text opacity depending on whether the user is hovering over any data lines.
    helper.select('.mouse-click-helper text')
      .transition().duration(this.tooltips.length ? 0 : 200)
      .style('opacity', this.tooltips.length);
  }

  /**
   * Called when the mouse is no longer hovering on the parallel coordinates data lines.
   */
  private handleMouseOut() {
    this.tooltips.length = 0;
    this.render();
  }

  /**
   * Handles the user clicking on a data line.
   * @param currentEvent The event event.
   */
  private handleClick(currentEvent: any) {
    let lineEvent = <LineMouseEvent>currentEvent;

    // Display the clicked job to the user.
    this.showStudyJob(lineEvent.lineIndex, currentEvent);
  }

  /**
   * Get the underlying study data, which contains the exploration map.
   * @returns The underlying study data.
   */
  private getStudyData(): UnderlyingStudyData | undefined {
    if (this.sharedState.sourceLoaderSet.sources.length === 0) {
      return undefined;
    }

    let sourceLoader = this.sharedState.sourceLoaderSet.sources[0].inner;
    if (!(sourceLoader instanceof StudySourceLoader)) {
      return undefined;
    }

    return sourceLoader.underlyingStudyData;
  }

  /**
   * Show the given study job to the user.
   * @param lineIndex The index of the line to show.
   * @param currentEvent The current event.
   * @returns A promise that resolves when the job has been closed.
   */
  private async showStudyJob(lineIndex: number, currentEvent?: LineMouseEvent) {
    try {
      let studyData = this.getStudyData();
      if (!studyData) {
        return;
      }

      let jobIndex = studyData.data.explorationData.scalarData.lineIndexToJobIndexMap[lineIndex];
      await this.siteHooks.showStudyJob(studyData.studyId, jobIndex, currentEvent);
    } catch (error) {
      this.errorHandler.setError(this.siteHooks.getFriendlyErrorAndLog(error));
    }
  }
}

/**
 * A tooltip to display on the parallel coordinates data.
 */
class ToolTip {

  /**
   * Create a new instance of the tooltip.
   * @param x The x coordinate to display the tooltip.
   * @param y The y coordinate to display the tooltip.
   * @param label The label for the tooltip.
   */
  constructor(
    public readonly x: number,
    public readonly y: number,
    public readonly label: string) {
  }
}

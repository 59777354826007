/**
 * Represents an immutable margin.
 */
export interface IMargin {
  readonly top: number;
  readonly right: number;
  readonly bottom: number;
  readonly left: number;
}

/**
 * An immutable margin.
 */
export class Margin implements IMargin {
  constructor(
    public readonly top: number,
    public readonly right: number,
    public readonly bottom: number,
    public readonly left: number) {
  }
}

import {isDefined} from '../../visualizations/is-defined';
import {DocumentSubType} from '../../../generated/api-stubs';
import {SimVersionDocumentCache} from '../sim-version-document-cache.service';
import {Injectable} from '@angular/core';
import {isNumber} from '../../common/is-number';

export const ConfigCsvHeader = 'Path,Value,Units\n';

@Injectable()
export class ConvertConfigToCsv {

  constructor(
    private simVersionDocumentCache: SimVersionDocumentCache
  ){
  }

  public async execute(config: any, configType: DocumentSubType, simVersion: string): Promise<string> {
    let lines = await this.executeCsvLines(config, configType, simVersion);
    return ConfigCsvHeader
      .concat(...lines.map(v => `"${v.path}",${isNumber(v.value) ? v.value : `"${v.value}"`},"${v.units}"\n`));
  }

  public async executeCsvLines(config: any, configType: DocumentSubType, simVersion: string): Promise<ConfigCsvLine[]> {

    let simVersionDocuments = await this.simVersionDocumentCache.get(simVersion);
    let units = simVersionDocuments.units;

    let inner = (value: any, valueKey: string, valuePath: string, lines: ConfigCsvLine[]): void => {

      if(isDefined(value)){
        if(typeof value === 'object'){
          let isArray = Array.isArray(value);
          if(isArray){
            let index = 0;
            for(let child of value){
              inner(child, valueKey, `${valuePath}[${index}]`, lines);
              ++index;
            }
          } else{
            for(let key in value){
              if(!value.hasOwnProperty(key)){
                continue;
              }
              let child = value[key];
              inner(child, key, `${valuePath}.${key}`, lines);
            }
          }
        } else{
          lines.push(new ConfigCsvLine(valuePath, value, units[valueKey] || ''));
        }
      }
    };

    let lines: ConfigCsvLine[] = [];
    inner(config, configType, configType, lines);

    return lines;
  }
}

export class ConfigCsvLine {
  constructor(
    public path: string,
    public value: string | number,
    public units: string
  ){}
}

import * as d3 from '../../../d3-bundle';


/**
 * Normalizes the numeric values in the sub sweep.
 */
export class GetExplorationSubSweepNormalizedValues {

  /**
   * Normalizes the numeric values in the sub sweep.
   * @param numericValues The numeric values.
   * @returns The normalized values.
   */
  public static execute(numericValues: ReadonlyArray<number>): ReadonlyArray<number> {

    // Create the array to store the results.
    let normalizedValues = Array(numericValues.length);

    if (numericValues.length === 0) {
      return normalizedValues;
    }

    const range = d3.extentStrict(numericValues);
    const span = range[1] - range[0];

    if (isNaN(span)) {
      throw new Error('Sub sweep numeric values contained invalid data');
    }

    // If there is no range, then all values are the same. Just return 0.5
    // as the normalized value for all values, which makes as much sense as anything.
    if (span === 0) {
      return numericValues.map(v => 0.5);
    }

    // Calculate the normalized values.
    for (let i = 0; i < numericValues.length; ++i) {
      let value = numericValues[i];
      normalizedValues[i] = (value - range[0]) / span;
    }

    return normalizedValues;
  }
}

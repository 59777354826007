

/**
 * The results of interpolating a channel using InterpolationCoordinates, which explores a single dimension.
 */
export interface ChannelInterpolatorResults {
  [channelName: string]: number[];
}

/**
 * The results of interpolating a channel using InterpolationCoordinates, which explores a single dimension.
 */
export interface ReadonlyChannelInterpolatorResults {
  readonly [channelName: string]: ReadonlyArray<number>;
}

/**
 * Create interpolated results for a set of channels with all values set to an empty array.
 * @param channelNames The names of the channels to create empty results for.
 * @returns The mapping of channel name to an empty array for the requested channels.
 */
export function emptyChannelInterpolatorResults(channelNames: ReadonlyArray<string>): ChannelInterpolatorResults {
  return channelNames.reduce<ChannelInterpolatorResults>((p, c) => {
    p[c] = [];
    return p;
  },
  {});
}

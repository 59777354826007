@if (isVisible) {
  <div class="modal dialog-container dialog-inner-scroll dialog-full-width" id="worksheet-labels-editor-dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title">Edit Labels</h1>
          <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()">
          </button>
        </div>
        <div class="modal-body">
          <cs-error-message [message]="session?.errorMessage"></cs-error-message>
          @if (!session.combined) {
            <cs-loader></cs-loader>
          }
          @if (session?.combined) {
            <div>
              <div class="scroll-area">
                <cs-worksheet-labels-editor
                  [canEditWorksheetLabels]="session.dialog.canEditWorksheetLabels"
                [labels]="session.combined"></cs-worksheet-labels-editor>
              </div>
            </div>
          }
        </div>
        <div class="modal-footer">
          <cs-error-message [message]="session?.errorMessage"></cs-error-message>
          <button id="dialog-dismiss-button" type="button" class="btn btn-secondary" (click)="dismiss()">Cancel</button>
          <button id="dialog-save-button" type="button" class="btn btn-primary" [disabled]="!session?.combined" (click)="session.save()">Save</button>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div>
  }<!-- /.modal -->

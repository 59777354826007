import { IPosition, Position } from '../../position';
import { IRectangle, Rectangle } from '../../rectangle';
import { QuadTreeCursorDataPoint } from './quad-tree-cursor-data-point';
import { ProcessedPlotSource } from './processed-plot-source';
import { IPopulatedMultiPlotSide } from './i-populated-multi-plot-side';

/**
 * A processed plot in a multi plot viewer.
 */

export class ProcessedPlot {
  private _absoluteRenderArea: IRectangle = new Rectangle(0, 0, 0, 0);

  // These sets only include channels which should ultimately be rendered in the plot,
  // and so don't include channels which aren't visible or delta channels which are hidden for this domain.
  private readonly _includedRowChannels: Set<string>;
  private readonly _includedColumnChannels: Set<string>;
  private readonly _includedChannelPairs: Set<string>;

  /**
   * Creates a new processed plot.
   * @param plotIndex The plot index within the multi-plot viewer.
   * @param column The column of the plot.
   * @param row The row of the plot.
   * @param sources The data sources for the plot.
   */
  constructor(
    public readonly plotIndex: number,
    public readonly column: IPopulatedMultiPlotSide,
    public readonly row: IPopulatedMultiPlotSide,
    public readonly sources: ProcessedPlotSource[]) {

    // This creates a fast lookup of the row and column channels, and the channel pairs.
    this._includedRowChannels = new Set<string>();
    this._includedColumnChannels = new Set<string>();
    this._includedChannelPairs = new Set<string>();
    for (let source of sources) {
      for (let channel of source.channels) {
        this._includedRowChannels.add(channel.yChannel.name);
        this._includedColumnChannels.add(channel.xChannel.name);
        this._includedChannelPairs.add(this.getChannelPairName(channel.xChannel.name, channel.yChannel.name));
      }
    }
  }

  /**
   * Returns true if the channel pair exists in the plot, false otherwise.
   * @param columnChannelName The column channel name.
   * @param rowChannelName The row channel name.
   * @returns True if the channel pair exists in the plot, false otherwise.
   */
  public hasChannelPair(columnChannelName: string, rowChannelName: string): boolean {
    return this._includedChannelPairs.has(this.getChannelPairName(columnChannelName, rowChannelName));
  }

  /**
   * Returns true if the row channel exists in the plot, false otherwise.
   * @param channelName The row channel name.
   * @returns True if the row channel exists in the plot, false otherwise.
   */
  public hasRowChannel(channelName: string): boolean {
    return this._includedRowChannels.has(channelName);
  }

  /**
   * Returns true if the column channel exists in the plot, false otherwise.
   * @param channelName The column channel name.
   * @returns True if the column channel exists in the plot, false otherwise.
   */
  public hasColumnChannel(channelName: string): boolean {
    return this._includedColumnChannels.has(channelName);
  }

  /**
   * Returns the render area within the viewer for this plot.
   */
  public get absoluteRenderArea(): IRectangle {
    return this._absoluteRenderArea;
  }

  /**
   * Returns true if the given coordinate is within the plot render area, false otherwise.
   * @param position The position to check.
   * @returns True if the given coordinate is within the plot render area, false otherwise.
   */
  public isInPlot(position: IPosition): boolean {
    let r = this._absoluteRenderArea;
    return position.x >= r.x
      && position.y >= r.y
      && position.x < r.x + r.width
      && position.y < r.y + r.height;
  }

  /**
   * Called when the plot is rescaled. Re-calculates X and Y coordinates for all channels
   * in all data sources and resets the quad trees.
   * @param xScale The new X scale.
   * @param yScale The new Y scale.
   */
  public onRescaled() {
    this.sources.forEach(v => v.onRescaled(this.column.processed.scale, this.row.processed.scale));
  }

  /**
   * Updates the render area within the viewer for this plot.
   * @param absoluteRenderArea The new render area.
   */
  public updateRenderArea(absoluteRenderArea: IRectangle) {
    this._absoluteRenderArea = absoluteRenderArea;

    this.onRescaled();
  }

  /**
   * Gets the closest point to a given position for each data source.
   * @param position The position relative to the viewer to find the closest point to.
   * @returns The closest point for each data source.
   */
  public getClosestPointsForSources(position: IPosition): (QuadTreeCursorDataPoint | undefined)[] {
    let internalPosition = new Position(
      position.x - this._absoluteRenderArea.x,
      position.y - this._absoluteRenderArea.y);

    let closestPoints: (QuadTreeCursorDataPoint | undefined)[] = [];
    for (let source of this.sources) {
      closestPoints.push(source.getClosestPoint(internalPosition));
    }
    return closestPoints;
  }

  /**
   * Gets the channel pair name, used for looking up the presence of a pair of channels.
   * @param columnChannel The column channel.
   * @param rowChannel The row channel.
   * @returns The channel pair name.
   */
  private getChannelPairName(columnChannel: string, rowChannel: string): string {
    return `${columnChannel}+${rowChannel}`;
  }
}

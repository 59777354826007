import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {CanopyValidators} from '../../common/forms/canopy-validators.service';
import {FormSubmissionButton} from '../../common/forms/form-submission-button';
import {
  GetTenantChannelWhitelistsQueryResult,
  TenantSettingsStub
} from '../../../generated/api-stubs';
import {FormSubmissionHandler} from '../../common/forms/form-submission-handler.service';
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {ENCRYPTION_DOCUMENTATION_URL} from '../../common/constants';
import { AuthenticationService } from '../../identity/state/authentication.service';

@Component({
  selector: 'cs-tenant-settings-channel-whitelists',
  templateUrl: './tenant-settings-channel-whitelists.component.html',
  styleUrls: ['./tenant-settings-channel-whitelists.component.scss']
})
export class TenantSettingsChannelWhitelistsComponent implements OnInit {

  public ENCRYPTION_DOCUMENTATION_URL: string = ENCRYPTION_DOCUMENTATION_URL;

  @Input() public tenantId: string;
  public isTenantAdministrator: boolean;

  public form: UntypedFormGroup;
  public channels: UntypedFormControl = new UntypedFormControl('', [CanopyValidators.channelList]);

  public saveSuccessful = false;
  public errorMessage: string;
  public submitButton = new FormSubmissionButton('Save', 'Saving...');

  public settingsResult: GetTenantChannelWhitelistsQueryResult;

  constructor(
    formBuilder: UntypedFormBuilder,
    private readonly authenticationService: AuthenticationService,
    private readonly tenantSettingsStub: TenantSettingsStub,
    private readonly formSubmissionHandler: FormSubmissionHandler,
    private getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
    this.form = formBuilder.group({
      channels: this.channels
    });
  }

  ngOnInit() {
    this.load();
  }

  public async load() {
    try {
      this.isTenantAdministrator = this.authenticationService.isTenantAdministrator;
      this.settingsResult = await this.tenantSettingsStub.getTenantChannelWhitelists(this.tenantId);
      let listAsString = this.settingsResult.settings.additiveChannelWhitelist.join('\n');
      this.channels.setValue(listAsString, {});
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async onSubmit() {
    this.saveSuccessful = false;
    let success = await this.formSubmissionHandler.execute(this.submit, this.form, this.submitButton, this);

    if (success) {
      this.saveSuccessful = true;
    }
  }

  public createSettings(){
    let listAsString: string = this.channels.value;
    let items = listAsString
      .split(/[\s,]+/)
      .map(v => v.trim())
      .filter(v => !!v);

    return {
      additiveChannelWhitelist: items,
    };
  }

  public async submit() {
    let settings = this.createSettings();

    await this.tenantSettingsStub.putTenantChannelWhitelists(
      this.tenantId,
      settings);
  }
}

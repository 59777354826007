import {DetachedRouteHandle, RouteReuseStrategy, ActivatedRouteSnapshot} from '@angular/router';
import { Injectable } from '@angular/core';
import { PersistentComponentsComponent } from './persistent-components/persistent-components';

export const CAN_REUSE_DATA_KEY = 'canReuse';

export const CAN_REUSE_DATA = {
  canReuse: true
};

function getComponentName(route: ActivatedRouteSnapshot){
  if (typeof route.component === 'function') {
    return route.component.name;
  } else if (typeof route.component === 'string') {
    return route.component;
  }

  return 'null';
}

function routeToUrl(route: ActivatedRouteSnapshot): string {
  let componentName = `[${getComponentName(route)}]`;
  let url = '';
  if (route.url && route.url.length) {
    url = route.url.join('/');
  }

  return '(' + componentName + url + ')';
}

function calculateKey(route: ActivatedRouteSnapshot, withChildren: boolean = true) {
  let next = route;
  let url = route.pathFromRoot.map(it => routeToUrl(it)).join('/') + '*';

  if(withChildren){
    while (next.firstChild) {
      next = next.firstChild;
      url += '/' + routeToUrl(next);
    }
  }
  return url;
}

function getWorksheetFromKey(key: string): string {
  let regex = /\W(worksheets\/\w+\/\w+)($|\W)/;
  let match: RegExpExecArray = regex.exec(key);
  if (match) {
    return match[1];
  }

  return undefined;
}

const EditWorksheetPage = 'EditWorksheetPage';

// https://github.com/angular/angular/issues/6634
@Injectable()
export class CustomReuseStrategy implements RouteReuseStrategy {
  private enableLogging: boolean = false;

  private log(...items: any[]){
    if(this.enableLogging){
      console.log(items.join('\n'));
    }
  }

  private lastWorksheet: CachedRoute;
  private persistentComponents: DetachedRouteHandle;

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    let result = false;
    if(getComponentName(route) === EditWorksheetPage){
      result = false;
    }

    if(route?.routeConfig?.component === PersistentComponentsComponent){
      result = true;
    }

    this.log('CustomReuseStrategy:shouldDetach', calculateKey(route), result);
    return result;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    let result = false;
    if(getComponentName(route) === EditWorksheetPage){
      let keyWithoutChildren = calculateKey(route, false);
      this.lastWorksheet = new CachedRoute(keyWithoutChildren, handle);
      result = true;
    }

    if(route?.routeConfig?.component === PersistentComponentsComponent){
      this.persistentComponents = handle;
      result = true;
    }

    this.log('CustomReuseStrategy:store', calculateKey(route), route, handle, result);
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    let result = false;
    if(this.isCachedWorksheet(route)){
      result = true;
    }

    if(this.persistentComponents && route?.routeConfig?.component === PersistentComponentsComponent){
      result = true;
    }

    this.log('CustomReuseStrategy:shouldAttach', calculateKey(route), route, result);
    return result;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    let result: DetachedRouteHandle;
    if(this.isCachedWorksheet(route)){
      result = this.lastWorksheet.handle;
    }

    if(this.persistentComponents && route?.routeConfig?.component === PersistentComponentsComponent){
      result =  this.persistentComponents;
    }

    this.log('CustomReuseStrategy:retrieve', calculateKey(route), route, !!result);
    return result;
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    const futureKey = calculateKey(future);
    const currentKey = calculateKey(curr);

    const futureWorksheet = getWorksheetFromKey(futureKey);
    const currentWorksheet = getWorksheetFromKey(currentKey);

    let result: boolean;
    if(futureWorksheet && currentWorksheet && futureWorksheet === currentWorksheet){
      // If we are navigating between child outlets of a worksheet then we we-use the route
      // to avoid reloading the worksheet each time. The worksheet page is set up to
      // automatically refresh the child outlet to avoid re-using the child component.
      result = true;
    } else{
      result = future.routeConfig === curr.routeConfig && !!curr.data[CAN_REUSE_DATA_KEY];
    }

    this.log('CustomReuseStrategy:shouldReuseRoute', futureKey, currentKey, result);

    return result;
  }

  private isCachedWorksheet(route: ActivatedRouteSnapshot){
    let keyWithoutChildren = calculateKey(route, false);
    return getComponentName(route) === EditWorksheetPage
      && this.lastWorksheet
      && this.lastWorksheet.key === keyWithoutChildren;
  }
}

class CachedRoute {
  constructor(
    public readonly key: string,
    public readonly handle: DetachedRouteHandle){}
}

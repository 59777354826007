import { CanopyTheme } from './canopy-theme';
import { FontAwesomeIconLibrary } from './font-awesome-icon-library';
import { BooleanResolver } from './boolean-resolver';
import { TableEditor } from './table-editor';
import { ArrayEditor } from './array-editor';
import { CheckboxEditor } from './checkbox-editor';
import { SelectEditor } from './select-editor';
import { SplitBooleans2DEditor } from './split-booleans-2D-editor';
import { SplitIntegers2DEditor } from './split-integers-2D-editor';
import { SplitNumbers2DEditor } from './split-numbers-2D-editor';
import { JsonEditorCustomization } from './json-editor-customization';
import { HiddenEnumResolver } from './hidden-enum-resolver';
import { SplitItemsResolver } from './split-items-resolver';
import { SplitItems2DResolver } from './split-items-2D-resolver';
import { SavedConfigResolver } from './saved-config-resolver';
import { EncryptedResolver } from './encrypted-resolver';
import { ArrayValidator } from './array-validator';
import { EncryptedEditor } from './encrypted-editor';
import { MultipleEditor } from './multiple-editor';
import { ObjectEditor } from './object-editor';
import { StringEditor } from './string-editor';
import { HiddenEnumEditor } from './hidden-enum-editor';
import { NumberEditor } from './number-editor';
import { IntegerEditor } from './integer-editor';
import { SavedConfigEditor } from './saved-config-editor';
import { SplitStringsEditor } from './split-strings-editor';
import { SplitNumbersEditor } from './split-numbers-editor';
import { SplitIntegersEditor } from './split-integers-editor';
import { SplitBooleansEditor } from './split-booleans-editor';
import { SplitStrings2DEditor } from './split-strings-2D-editor';


export function getJsonEditorCustomisationProviders(): any[] {
  return [
    { provide: JsonEditorCustomization, useClass: HiddenEnumResolver, multi: true },
    { provide: JsonEditorCustomization, useClass: SplitItemsResolver, multi: true },
    { provide: JsonEditorCustomization, useClass: SplitItems2DResolver, multi: true },
    { provide: JsonEditorCustomization, useClass: SavedConfigResolver, multi: true },
    { provide: JsonEditorCustomization, useClass: EncryptedResolver, multi: true },
    { provide: JsonEditorCustomization, useClass: ArrayValidator, multi: true },
    { provide: JsonEditorCustomization, useClass: EncryptedEditor, multi: true },
    { provide: JsonEditorCustomization, useClass: MultipleEditor, multi: true },
    { provide: JsonEditorCustomization, useClass: ObjectEditor, multi: true },
    { provide: JsonEditorCustomization, useClass: StringEditor, multi: true },
    { provide: JsonEditorCustomization, useClass: HiddenEnumEditor, multi: true },
    { provide: JsonEditorCustomization, useClass: NumberEditor, multi: true },
    { provide: JsonEditorCustomization, useClass: IntegerEditor, multi: true },
    { provide: JsonEditorCustomization, useClass: SavedConfigEditor, multi: true },
    { provide: JsonEditorCustomization, useClass: SplitStringsEditor, multi: true },
    { provide: JsonEditorCustomization, useClass: SplitNumbersEditor, multi: true },
    { provide: JsonEditorCustomization, useClass: SplitIntegersEditor, multi: true },
    { provide: JsonEditorCustomization, useClass: SplitBooleansEditor, multi: true },
    { provide: JsonEditorCustomization, useClass: SplitStrings2DEditor, multi: true },
    { provide: JsonEditorCustomization, useClass: SplitNumbers2DEditor, multi: true },
    { provide: JsonEditorCustomization, useClass: SplitIntegers2DEditor, multi: true },
    { provide: JsonEditorCustomization, useClass: SplitBooleans2DEditor, multi: true },
    { provide: JsonEditorCustomization, useClass: SelectEditor, multi: true },
    { provide: JsonEditorCustomization, useClass: CheckboxEditor, multi: true },
    { provide: JsonEditorCustomization, useClass: ArrayEditor, multi: true },
    { provide: JsonEditorCustomization, useClass: TableEditor, multi: true },
    { provide: JsonEditorCustomization, useClass: BooleanResolver, multi: true },
    { provide: JsonEditorCustomization, useClass: FontAwesomeIconLibrary, multi: true },
    { provide: JsonEditorCustomization, useClass: CanopyTheme, multi: true },
  ];
}

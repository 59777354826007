import { SourceData } from './types/source-data';
import { IMultiPlotLayout } from './types/i-multi-plot-layout';
import { SideType } from './types/side-type';
import { CreateTransientDataForLayout } from './create-transient-data-for-layout';
import { GetChannelIndexMap } from './get-channel-index-map';
import { GetProcessedPrimaryDomain } from './get-processed-primary-domain';
import { ProcessLayoutRoot } from './process-layout-root';
import { ProcessLayoutSides } from './process-layout-sides';


/**
 * Process the layout. This generates additional information useful for rendering the chart.
 */
export class ProcessLayout {
  constructor(
    private readonly createTransientDataForLayout: CreateTransientDataForLayout,
    private readonly getChannelIndexMap: GetChannelIndexMap,
    private readonly getProcessedPrimaryDomain: GetProcessedPrimaryDomain,
    private readonly processLayoutSides: ProcessLayoutSides,
    private readonly processLayoutRoot: ProcessLayoutRoot) {
  }

  /**
   * Process the layout. This generates additional information useful for rendering the chart.
   *
   * @param layout The layout to process.
   * @param sourceData The set of data sources.
   */
  public execute(layout: IMultiPlotLayout, sourceData: ReadonlyArray<SourceData>) {
    // Ensure the layout and each row, column and channel has transient data structures set.
    this.createTransientDataForLayout.execute(layout);

    // Create a map from channel name to channel index. This is used for assigning colors to channels.
    let channelIndexMap = this.getChannelIndexMap.execute(layout);

    // Get the processed primary domain (validated, with data, and auto-scaled if necessary).
    let processedPrimaryDomain = this.getProcessedPrimaryDomain.execute(sourceData, channelIndexMap);

    // Process the layout columns, rows and the overall layout (the root).
    // The processed root contains information about the individual plots we need to render.
    // It is important to process the columns first.
    this.processLayoutSides.execute(layout.columns, sourceData, channelIndexMap, SideType.column, layout.columns);
    this.processLayoutSides.execute(layout.rows, sourceData, channelIndexMap, SideType.row, layout.columns);
    this.processLayoutRoot.execute(layout, sourceData, channelIndexMap, processedPrimaryDomain);
  }
}

<cs-edit-worksheet
  [tenantId]="tenantId"
  [worksheetId]="worksheetId">
</cs-edit-worksheet>

@if (hasChildRoute) {
  <div class="modal dialog-container dialog-inner-scroll dialog-full-width dialog-no-footer worksheet-child-route-dialog" id="worksheet-child-route-dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="btn btn-primary close-button" aria-label="Close" id="dialog-dismiss-button" (click)="dismissChildRoute()">
            <i class="fa fa-caret-left"></i>Return to Worksheet
          </button>
        </div>
        <div class="modal-body">
          @if (!hideRoute) {
            <div class="scroll-area">
              <router-outlet></router-outlet>
            </div>
          }
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div><!-- /.modal -->
}

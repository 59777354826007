import {IReference, referenceAsDefault, referenceAsTenant} from './worksheet-types';

/**
 * Converts a reference to a string ID, to be used as a key in a map.
 * @param reference The reference.
 * @param includeJobIndex Whether to include the job index in the ID.
 * @returns The string ID.
 */
export function referenceToId(reference: IReference, includeJobIndex: boolean = false): string {
  const defaultReference = referenceAsDefault(reference);
  if (defaultReference) {
    return 'default,' + defaultReference.name;
  }

  const tenantReference = referenceAsTenant(reference);
  if(tenantReference){
    if(includeJobIndex){
      return tenantReference.tenantId + ',' + tenantReference.targetId + ',' + (tenantReference.jobIndex || 0);
    }

    return tenantReference.tenantId + ',' + tenantReference.targetId;
  }

  throw new Error('Unexpected reference format: ' + JSON.stringify(reference));
}


@if (isTelemetryJob && telemetrySource) {
  <button type="button"
    class="btn btn-smart btn-primary edit-and-run-telemetry-button"
    [ngClass]="{'btn-icon': compactButton}"
    (click)="execute()">
    @if (isRedirecting) {
      <cs-loader></cs-loader>
    }
    @if (!isRedirecting) {
      <i class="fa fa-repeat" aria-hidden="true"></i>
    }
    @if (!compactButton) {
      <span>Edit Telemetry</span>
    }
  </button>
}
<cs-error-message [message]="errorMessage"></cs-error-message>

<!-- Note: Strange formatting here is to prevent white space between elements -->
@if (condition) {
  <span class="query-condition">
    @if (!operator.requiresValue) {
      <span><span class="query-operator">{{operator.name}}</span>(</span
      >
      }{{condition.source}}.{{condition.name}}@if (!operator.requiresValue) {
      <span>)</span
        >
        }@if (operator.requiresValue) {
        <span>&nbsp;<span class="query-operator">{{operator.name}}</span
          >&nbsp;<span class="query-value">{{displayValue}}</span></span>
        }
      </span>
    }

export const CLIENT_ID = 'canopy.web.1';
export const UNEXPECTED_ERROR_TEXT = 'An error has occurred.';
export const PRECONDITION_FAILED_ERROR_TEXT = 'Another user modified this data. Please try again.';
export const CONNECTION_ERROR_TEXT = 'Unable to communicate with the server. Make sure you are connected to the internet and try again.';
export const DEVELOPER_NAME_HEADER = 'Developer-Name';

export const STUDY_ID_URL_PARAMETER_KEY: string = 'studyId';
export const JOB_ID_URL_PARAMETER_KEY: string = 'jobId';

export const SINGLE_JOB_NAME = 'Single';

export const ZERO_WIDTH_SPACE = '​';
export const ZERO_WIDTH_SPACE_REGEX = /​/g;

export const CURRENT_VERSION = 'current';

export const CANOPY_TENANT_SHORT_NAME = 'canopy';

// Common URLs
export const ENCRYPTION_DOCUMENTATION_URL = 'https://support.canopysimulations.com/hc/en-gb/articles/5650629140637-Config-Encryption';
export const TELEMETRY_DOCUMENTATION_URL = 'https://support.canopysimulations.com/hc/en-gb/articles/5650611762589-Telemetry';
export const LIST_FILTERING_DOCUMENTATION_URL = 'https://support.canopysimulations.com/hc/en-gb/articles/5650626042653-List-Filtering';
export const CHART_SHORTCUT_KEYS_URL = 'https://support.canopysimulations.com/hc/en-gb/articles/5650466169885-Chart-Controls-and-Shortcut-Keys';

export const HIGH_PRIORITY_VALUE = 1000;
export const LOW_PRIORITY_VALUE = -1000;
export const DEFAULT_PRIORITY_VALUE = 0;

import {LazyLoadedStudyMetadataFactory} from './lazy-loaded-study-metadata-factory';
import {JobSource} from './job-source';
import {JobViewModelFactory} from './job-view-model';
import {Injectable} from '@angular/core';
import {JobViewModel} from './job-view-model';
import {LazyLoadedStudyFactory} from './lazy-loaded-study-factory';
import {LazyStudyLoaders} from './lazy-study-loaders';
import {GetSimVersion} from '../../../common/get-sim-version.service';

@Injectable()
export class JobViewModelSetFactory {
  constructor(
    private readonly jobViewModelFactory: JobViewModelFactory,
    private readonly lazyLoadedStudyFactory: LazyLoadedStudyFactory,
    private readonly lazyLoadedStudyMetadataFactory: LazyLoadedStudyMetadataFactory,
    private readonly getSimVersion: GetSimVersion){
  }

  public create(sources: ReadonlyArray<JobSource>): JobViewModelSet{
    let simVersion = this.getSimVersion.currentSimVersion;
    let studyMetadataMap = this.createStudyMetadataMap(sources);
    let jobs = sources.map(v => this.jobViewModelFactory.create(v, simVersion, studyMetadataMap[v.studyId]));
    return new JobViewModelSet(studyMetadataMap, jobs);
  }

  private createStudyMetadataMap(sources: ReadonlyArray<JobSource>): StudyMetadataMap {
    let simVersion = this.getSimVersion.currentSimVersion;
    let distinctStudies: JobSource[] = [];
    for (let jobSource of sources) {
      if (!distinctStudies.find(v => v.studyId === jobSource.studyId)) {
        distinctStudies.push(jobSource);
      }
    }

    return distinctStudies.reduce((p: WritableStudyMetadataMap, c) => {
      let study = this.lazyLoadedStudyFactory.create(
        c.tenantId,
        c.studyId,
        simVersion);
      let studyMetadata = this.lazyLoadedStudyMetadataFactory.create(
        study,
        c.tenantId,
        c.studyId);

      p[c.studyId] = new LazyStudyLoaders(study, studyMetadata);

      return p;
    }, {});
  }
}

export interface WritableStudyMetadataMap {
  [studyId: string]: LazyStudyLoaders;
}
export type StudyMetadataMap = Readonly<WritableStudyMetadataMap>;

export class JobViewModelSet {
  constructor(
    public readonly studyMetadataMap: StudyMetadataMap,
    public readonly jobs: ReadonlyArray<JobViewModel>){
  }
}

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ImportTelemetryStage} from '../import-telemetry-dialog/import-telemetry-stage';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {Timer} from '../../../common/timer.service';
import {ChannelImportMapping, TenantSettingsStub} from '../../../../generated/api-stubs';
import {RenamedChannel, ValidatedTelemetryFile} from '../telemetry-config';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {CanopyValidators} from '../../../common/forms/canopy-validators.service';
import {sortBy} from '../../../common/sort-by';
import {CanopyAutocomplete} from '../../../common/canopy-autocomplete.service';
import {DisplayableError} from '../../../common/errors/errors';
import {setFormPristine} from '../../../common/forms/set-form-pristine-hack';
import {FormSubmissionButton} from '../../../common/forms/form-submission-button';
import {FormSubmissionHandler} from '../../../common/forms/form-submission-handler.service';
import {CanopyJson} from '../../../common/canopy-json.service';
import { AuthenticationService, UserData } from '../../../identity/state/authentication.service';

@Component({
  selector: 'cs-remap-telemetry-json-stage',
  templateUrl: './remap-telemetry-json-stage.component.html',
  styleUrls: ['./remap-telemetry-json-stage.component.scss']
})
export class RemapTelemetryJsonStageComponent extends ImportTelemetryStage {
  @Input() public telemetryConfig: ValidatedTelemetryFile;
  @Output() public telemetryConfigRemapped: EventEmitter<ValidatedTelemetryFile> = new EventEmitter<ValidatedTelemetryFile>();

  public userData: UserData;
  public mappings: ChannelImportMapping[];
  public isSaving: boolean;

  public form: UntypedFormGroup;
  public submitButton = new FormSubmissionButton('Apply', 'Applying...');
  public itemId: number = 0;
  public mappingControls: ChannelMappingControl[];

  constructor(
    private readonly json: CanopyJson,
    private readonly tenantSettingsStub: TenantSettingsStub,
    private readonly authenticationService: AuthenticationService,
    private readonly canopyAutocomplete: CanopyAutocomplete,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly formSubmissionHandler: FormSubmissionHandler,
    timer: Timer,
    getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
    super(timer, getFriendlyErrorAndLog);
  }

  public async run(): Promise<void> {
    this.userData = this.authenticationService.userDataSnapshot;
    let mappingsResult = await this.tenantSettingsStub.getTenantChannelImportMappings(this.userData.tenant);
    this.mappings = mappingsResult.channelImportMappings || [];

    this.form = this.formBuilder.group({});

    this.mappingControls = [];
    for(let mapping of this.mappings){
      let ids = this.getNextIds();

      this.mappingControls.push({
        initialFrom: mapping.from,
        from: new UntypedFormControl(mapping.from, CanopyValidators.channelNameValidators),
        to: new UntypedFormControl(mapping.to, CanopyValidators.channelNameValidators),
        fromId: ids.from,
        toId: ids.to,
        fromAutocomplete: undefined,
      });
    }

    this.mappingControls.sort(sortBy('initialFrom'));

    for(let item of this.mappingControls){
      this.form.addControl(item.fromId, item.from);
      this.form.addControl(item.toId, item.to);
    }

    await this.createAutocompleteOnInputs();
  }

  public async add(){
    try {
      let ids = this.getNextIds();
      let item: ChannelMappingControl = {
        initialFrom: '',
        from: new UntypedFormControl('', CanopyValidators.channelNameValidators),
        to: new UntypedFormControl('', CanopyValidators.channelNameValidators),
        fromId: ids.from,
        toId: ids.to,
        fromAutocomplete: undefined,
      };

      this.mappingControls.push(item);
      this.form.addControl(item.fromId, item.from);
      this.form.addControl(item.toId, item.to);

      await this.createAutocompleteOnInputs();
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public remove(index: number){
    try {
      let item = this.mappingControls[index];
      this.mappingControls.splice(index, 1);
      this.form.removeControl(item.fromId);
      this.form.removeControl(item.toId);
      setFormPristine(this.form, false);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async createAutocompleteOnInputs(){
    await this.timer.yield();
    let autoCompleteChannels = this.telemetryConfig.config.channels.map(v => v.name);
    for(let item of this.mappingControls){
      if(!item.fromAutocomplete){
        item.fromAutocomplete = this.canopyAutocomplete.create(item.fromId + '-input', item.from, autoCompleteChannels);
      }
    }
  }

  public updateMappings(): boolean {
    if(!this.form.valid) {
      throw new DisplayableError('Please correct invalid mappings before proceeding.');
    }

    let sortMappings = () => {
      this.mappings.sort(sortBy('from', 'to'));
    };

    sortMappings();
    let initialJson = this.json.stringify(this.mappings);

    this.mappings.splice(0);
    for(let item of this.mappingControls){
      if(item.from.value && item.to.value){
        this.mappings.push({ from: item.from.value.trim(), to: item.to.value.trim() });
      }
    }

    sortMappings();
    let finalJson = this.json.stringify(this.mappings);

    return initialJson !== finalJson;
  }

  public async saveMappings(): Promise<void> {
    try {
      this.isSaving = true;
      await this.tenantSettingsStub.putTenantChannelImportMappings(this.userData.tenant, {
        channelImportMappings: this.mappings || [],
      });
    } finally {
      this.isSaving = false;
    }
  }

  public applyMappings() {
    for(let channel of this.telemetryConfig.config.channels){
      for(let mapping of this.mappings){
        if(channel.name === mapping.from){
          this.telemetryConfig.channelNameMappings.push(new RenamedChannel(mapping.from, mapping.to));
          channel.name = mapping.to;
        }
      }
    }
  }

  public emitConfig() {
    this.telemetryConfigRemapped.emit(this.telemetryConfig);
  }

  public async saveAndContinue() {
    try {
      if(this.updateMappings()) {
        await this.saveMappings();
      }
      this.applyMappings();
      this.emitConfig();
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public skip() {
    try {
      this.emitConfig();
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async onSubmit() {
    await this.formSubmissionHandler.execute(this.submit, this.form, this.submitButton, this);
  }

  public async submit() {
    await this.saveAndContinue();
  }

  public getNextIds(): { from: string; to: string } {
    let id = this.getNextId();
    return {
      from: id + '-from',
      to: id + '-to'
    };
  }
  private getNextId(){
    return 'mapping-' + this.itemId++;
  }

}

interface ChannelMappingControl {
  initialFrom: string;
  from: UntypedFormControl;
  to: UntypedFormControl;
  fromId: string;
  toId: string;
  fromAutocomplete: any;
}

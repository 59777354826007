import { isNumber } from '../../is-number';
import { MonotonicStatus } from './viewer-channel-data';

/**
 * The result of calculating the monotonic status of a set of data.
 */
export class MonotonicStatusResult {

  /**
   * Creates an instance of the MonotonicStatusResult.
   * @param minimum The minimum value.
   * @param maximum The maximum value.
   * @param monotonicStatus The monotonic status.
   */
  constructor(
    public readonly minimum: number,
    public readonly maximum: number,
    public readonly monotonicStatus: MonotonicStatus) {
  }

  /**
   * Spreads the result into an array.
   * @returns The spread result.
   */
  public spread(): [number, number, MonotonicStatus] {
    return [this.minimum, this.maximum, this.monotonicStatus];
  }
}

/**
 * Gets the monotonic status of a set of data.
 */
export class GetMonotonicStatus {

  /**
   * Executes the monotonic status calculation.
   * We're generally using this from data classes, so we don't want to have to inject it, hence it is static.
   * @param data The data to calculate the monotonic status of.
   * @returns The monotonic status result.
   */
  public static execute(data: ReadonlyArray<number> | undefined): MonotonicStatusResult {
    let minimum: number = NaN;
    let maximum: number = NaN;

    let monotonicStatus = MonotonicStatus.Unknown;

    if (data && data.length) {
      let firstNumericIndex = data.findIndex(v => isNumber(v));
      if (firstNumericIndex !== -1) {
        let lastValue = data[firstNumericIndex];
        minimum = lastValue;
        maximum = lastValue;
        for (let i = firstNumericIndex + 1; i < data.length; ++i) {
          let currentValue = data[i];

          if (currentValue < minimum) {
            minimum = currentValue;
          }

          if (currentValue > maximum) {
            maximum = currentValue;
          }

          if (currentValue > lastValue) {
            switch (monotonicStatus) {
              case MonotonicStatus.Unknown:
                monotonicStatus = MonotonicStatus.Increasing;
                break;

              case MonotonicStatus.Decreasing:
                monotonicStatus = MonotonicStatus.None;
                break;
            }
          } else if (currentValue < lastValue) {
            switch (monotonicStatus) {
              case MonotonicStatus.Unknown:
                monotonicStatus = MonotonicStatus.Decreasing;
                break;

              case MonotonicStatus.Increasing:
                monotonicStatus = MonotonicStatus.None;
                break;
            }
          }

          lastValue = currentValue;
        }
      }
    }

    return new MonotonicStatusResult(minimum, maximum, monotonicStatus);
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {Timer} from './timer.service';
import {Subscription} from 'rxjs';
import {GetHttpError} from './errors/services/get-http-error/get-http-error.service';
import {HttpClient} from '@angular/common/http';

const REFRESH_INTERVAL = 6*60000;

@Component({
  selector: 'cs-update-checker',
  templateUrl: './update-checker.component.html',
  styleUrls: [ './update-checker.component.scss' ]
})
export class UpdateCheckerComponent implements OnInit, OnDestroy {

  public subscription: Subscription;
  public errorMessage: string;

  public lastVersion: string;

  public showUpdateMessage: boolean = false;

  constructor(
    private timer: Timer,
    private http: HttpClient,
    private getHttpError: GetHttpError){
  }

  public ngOnInit() {
   let timer = this.timer.repeat(REFRESH_INTERVAL);
    this.subscription = timer.subscribe(() => this.onTimerTick());
    this.load();
  }

  public ngOnDestroy(){
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public onTimerTick(){
    this.load();
  }

  public async load() {
    try {
      this.errorMessage = undefined;
      let newVersion = await this.getCurrentVersion();

      if(!this.lastVersion){
        this.lastVersion = newVersion;
      } else if(this.lastVersion !== newVersion){
        this.showUpdateMessage = true;
      }
    } catch (error) {
      // We're not that interested in failures here.
      //this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async getCurrentVersion(){
    try {
      return await this.http.get('/version.txt', { responseType: 'text' }).toPromise();
    } catch (response) {
      throw this.getHttpError.execute(response);
    }
  }
}

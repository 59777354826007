import {Injectable} from '@angular/core';
import {SetChannelUnitsDialogData} from './set-channel-units-dialog.service';
import {UserSettings} from '../user-state/user-settings.service';
import {Units} from '../visualizations/units';
import { AuthenticationService } from '../identity/state/authentication.service';

@Injectable()
export class SetChannelUnitsDialogSessionFactory {
  constructor(
    private authenticationService: AuthenticationService,
    private userSettings: UserSettings){
  }

  public create(dialog: SetChannelUnitsDialogData){
    return new SetChannelUnitsDialogSession(
      dialog,
      this.authenticationService,
      this.userSettings);
  }
}

export class SetChannelUnitsDialogSession{
  public allUnits: string[];
  public isSaving: boolean;

  constructor(
    private dialog: SetChannelUnitsDialogData,
    private authenticationService: AuthenticationService,
    private userSettings: UserSettings){

  }

  public async load(){
    this.allUnits = Units.getAlternativeUnits(this.dialog.currentUnits).filter(v => !!v);
    if(!this.allUnits.length){
      this.allUnits.push(this.dialog.currentUnits);
    }
  }

  public async saveUnits(selectedUnits: string){
    this.isSaving = true;
    try {
      const userData = this.authenticationService.userDataSnapshot;
      await this.userSettings.update(userData.tenant, userData.sub, currentSettings => {
        let channel = currentSettings.settings.channels.find(v => v.name === this.dialog.channelName);

        if(selectedUnits){
          if(channel){
            channel.units = selectedUnits;
          } else{
            currentSettings.settings.channels.push({
              name: this.dialog.channelName,
              units: selectedUnits
            });
          }
        } else{
          if(channel){
            let index = currentSettings.settings.channels.indexOf(channel);
            currentSettings.settings.channels.splice(index, 1);
          }
        }
      });
    } finally {
      this.isSaving = false;
    }
  }
}

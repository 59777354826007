import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
  ConditionOperatorsMap,
  ConditionSourceViewModel, ConditionSourceItemSetViewModel, FilterConditionViewModel, GroupViewModel,
  IFilterQueryBuilderDataAndFilterSource,
  IFilterQueryBuilderManager, ResolvedFilterCondition, ResolvedGroup, ConditionSourceItemViewModel
} from './filter-query-builder-types';
import {Timer} from '../../common/timer.service';
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {
  ConditionSource, GetTenantUsersQueryResult, ListFilterCondition, ListFilterData,
  ListFilterGroup
} from '../../../generated/api-stubs';
import {Subscription} from 'rxjs';

@Component({
  selector: 'cs-filter-query-builder',
  templateUrl: './filter-query-builder.component.html'
})
export class FilterQueryBuilderComponent implements OnInit, OnDestroy, IFilterQueryBuilderManager{
  @Input() public dataSource: IFilterQueryBuilderDataAndFilterSource;
  @Input() public tenantUsers: GetTenantUsersQueryResult;
  @Output() public queryChanged: EventEmitter<QueryChangedEvent> = new EventEmitter<QueryChangedEvent>();

  public errorMessage: string;

  public filterChangedSubscription: Subscription;

  public groupViewModel: GroupViewModel;

  constructor(
    private readonly timer: Timer,
    private getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  public ngOnInit(): void {
    this.filterChangedSubscription = this.dataSource.filterChanged.subscribe(
      (filter: ListFilterData) => this.loadFilter(filter.query));
    this.loadFilter(this.dataSource.currentQuery);
  }

  public ngOnDestroy(): void {
    if(this.filterChangedSubscription){
      this.filterChangedSubscription.unsubscribe();
    }
  }

  public loadFilter(currentQuery: ListFilterGroup){
    try{
      if(currentQuery){
        this.groupViewModel = this.getGroupViewModel(currentQuery);
      } else{
        this.groupViewModel = new GroupViewModel();
      }
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public getConditionSources(): ReadonlyArray<ConditionSourceViewModel> {
    return this.dataSource.getConditionSources();
  }

  public getConditionSourceItems(conditionSourceId: string): ConditionSourceItemSetViewModel {
    return this.dataSource.getConditionSourceItems(conditionSourceId);
  }

  public get hasConditionSourceItemsChanged(): boolean {
    return this.dataSource.hasConditionSourceItemsChanged;
  }

  public getConditionSourceViewModel(source: ConditionSource): ConditionSourceViewModel{
    return this.dataSource.getConditionSourceViewModel(source);
  }

  public getConditionSourceItemViewModel(source: string, name: string): ConditionSourceItemViewModel{
    return this.dataSource.getConditionSourceItemViewModel(source, name);
  }

  public notifyChanged() {
    this.notifyChangedInBackground();
  }

  public async notifyChangedInBackground() {
    try{
      await this.timer.yield();
      if(this.groupViewModel.populate()){
        let resolvedRoot = this.getResolvedGroup(this.groupViewModel);
        this.queryChanged.emit(new QueryChangedEvent(true, resolvedRoot));
      } else{
        this.queryChanged.emit(new QueryChangedEvent(false, null));
      }
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public getGroupViewModel(group: ListFilterGroup): GroupViewModel{
    if(!group){
      return undefined;
    }

    return new GroupViewModel(
      group.operator,
      (group.conditions || []).map(v => this.getConditionViewModel(v)).filter(v => !!v),
      (group.groups || []).map(v => this.getGroupViewModel(v)).filter(v => !!v));
  }

  public getConditionViewModel(condition: ListFilterCondition): FilterConditionViewModel{
    if(!condition){
      return undefined;
    }

    return new FilterConditionViewModel(
      this.dataSource.getConditionSourceViewModel(condition.source as ConditionSource),
      this.dataSource.getConditionSourceItemViewModel(condition.source as ConditionSource, condition.name),
      ConditionOperatorsMap[condition.operator],
      condition.value);
  }

  public getResolvedGroup(group: GroupViewModel): ResolvedGroup{
    if(!group){
      return undefined;
    }

    return new ResolvedGroup(
      group.operator,
      (group.conditions || []).map(v => this.getResolvedCondition(v)).filter(v => !!v),
      (group.groups || []).map(v => this.getResolvedGroup(v)).filter(v => !!v));
  }

  public getResolvedCondition(condition: FilterConditionViewModel): ResolvedFilterCondition{
    if(!condition){
      return undefined;
    }

    return new ResolvedFilterCondition(
      condition.source.id,
      condition.name.name,
      condition.operator.id,
      condition.value);
  }

  public get manager(): IFilterQueryBuilderManager {
    return this;
  }
}

export class ResolvedFilterQuery {
  constructor(
    public readonly isValid: boolean,
    public readonly root: ResolvedGroup | null){
  }
}

export class QueryChangedEvent {
  constructor(
    public readonly isValid: boolean,
    public readonly root: ResolvedGroup | null){
  }
}


<div class="card">
  <h1 class="card-header">Sign Out Everywhere</h1>

  <div class="card-body">
    <cs-error-message [message]="errorMessage"></cs-error-message>
    <p>
      This forces all devices using the account to be signed out, and can take up to 30 minutes to take effect.
    </p>
    @if (isSuccessful) {
      <div id="sign-out-everywhere-success-message" class="alert alert-success">
        Successfully signed out everywhere.
      </div>
    }
    <form (ngSubmit)="onSubmit()" [formGroup]="form">
      <button id="sign-out-everywhere-submit" type="submit" class="btn btn-smart btn-secondary" [disabled]="submitButton.isSubmitting">{{submitButton.text}}</button>
    </form>
  </div>
</div>

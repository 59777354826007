import { IViewerChannelData } from './viewer-channel-data';
import { ViewerChannelDataFactory } from './viewer-channel-data-factory';
import { GetInterpolatedChannelValueAtDomainValue } from './get-interpolated-channel-value-at-domain-value';
import { ApplyDeltaChannelModifier } from './apply-delta-channel-modifier';
import { DomainViewerChannelData } from './domain-viewer-channel-data';

/**
 * A class to apply channel modifiers to the channel data. Channel modifiers are things like "delta"
 * for creating delta channels.
 */
export class ApplyChannelModifiers {

  constructor(
    private applyDeltaChannelModifier: ApplyDeltaChannelModifier) {
  }

  /**
   * Creates an instance of ApplyChannelModifiers.
   * @param viewerChannelDataFactory The viewer channel data factory.
   * @param getInterpolatedChannelValueAtDomainValue The function to get the interpolated channel value at a domain value.
   * @returns A new instance of ApplyChannelModifiers.
   */
  public static create(
    viewerChannelDataFactory: ViewerChannelDataFactory,
    getInterpolatedChannelValueAtDomainValue: GetInterpolatedChannelValueAtDomainValue): ApplyChannelModifiers {
    return new ApplyChannelModifiers(
      new ApplyDeltaChannelModifier(viewerChannelDataFactory, getInterpolatedChannelValueAtDomainValue));
  }

  /**
   * Applies channel modifiers to the channel name only (not the channel data).
   * @param channelName The source channel name.
   * @param modifiers The set of modifiers.
   * @param xDomainNames The list of distinct X domains.
   * @returns The list of modified channel names and metadata.
   */
  public executeForChannelName(
    channelName: string,
    modifiers: IChannelModifiers,
    xDomainNames: ReadonlyArray<string>): ChannelNameWithModifiersResult[] {
    if (modifiers.isDelta) {
      return this.applyDeltaChannelModifier.executeForChannelName(channelName, xDomainNames);
    }

    return [new ChannelNameWithModifiersResult(channelName, undefined)];
  }

  /**
   * Applies channel modifiers to the channel name and data.
   * @param channelName The source channel name.
   * @param modifiers The set of modifiers.
   * @param sourcesData The sources data for the channel.
   * @param xDomains The list of distinct X domains.
   * @returns The list of modified channel names with modified data and metadata.
   */
  public execute(
    channelName: string,
    modifiers: IChannelModifiers,
    sourcesData: ReadonlyArray<IViewerChannelData>,
    xDomains: ReadonlyArray<DomainViewerChannelData> | undefined): ChannelWithModifiersResult[] {

    if (!sourcesData.length) {
      return [];
    }

    if (modifiers.isDelta) {
      if (xDomains) {
        return this.applyDeltaChannelModifier.execute(channelName, sourcesData, xDomains);
      }
    }

    return [];
  }
}

/**
 * An instance of a modified channel.
 */
export class ChannelWithModifiersResult {
  /**
   * Creates an instance of a modified channel.
   * @param channelName The modified channel name.
   * @param renderOnlyForDomain If the channel should only be rendered when a particular domain is selected this
   *        will be set to the name of the domain.
   * @param sourcesData The modified sources data for the channel.
   */
  constructor(
    public readonly channelName: string,
    public readonly renderOnlyForDomain: string | undefined,
    public readonly sourcesData: ReadonlyArray<IViewerChannelData>) { }
}

/**
 * An instance of a modified channel name.
 */
export class ChannelNameWithModifiersResult {
  /**
   * Creates an instance of a modified channel name.
   * @param channelName The modified channel name.
   * @param renderOnlyForDomain If the channel should only be rendered when a particular domain is selected this
   *        will be set to the name of the domain.
   */
  constructor(
    public readonly channelName: string,
    public readonly renderOnlyForDomain: string | undefined) {
  }
}

/**
 * The set of supported channel modifiers.
 */
export interface IChannelModifiers {

  /**
   * True if the channel is a delta channel.
   * A delta channel is the difference between the Nth data source and the first data source.
   * A delta channel is split into multiple modified channels, one per X domain, because the deltas
   * are different in each domain.
   */
  isDelta?: boolean;
}


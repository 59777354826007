@if (canSurvey) {
  <div class="alert alert-info d-flex justify-content-center" #scriptContainer>
    <a href="#" role="button" (click)="showSurvey()">Let us know how we're doing!</a>
    <button class="btn-close" aria-label="Close" (click)="setDismissed()"></button>
  </div>
}

<div #modal class="modal modal-lg" tabindex="-1" data-test-id="view-component-modal">
  <div class="modal-dialog">
    <div class="modal-content" style="height: calc(100vh - 2 * var(--bs-modal-margin))">
      <div class="modal-header">
        <h1 class="modal-title">Please tell us how we're doing</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div id="smcx-sdk"></div>
      </div>
    </div>
  </div>
</div>

import {Injectable} from '@angular/core';
import { AuthenticationService } from '../state/authentication.service';
import { ConfirmationDialog } from '../../common/dialogs/confirmation-dialog.service';
import { StudyStagingArea } from '../../simulations/study-staging-area/study-staging-area.service';

@Injectable()
export class SignOut {

  constructor(
    private authenticationService: AuthenticationService,
    private confirmationDialog: ConfirmationDialog,
    private stagingArea: StudyStagingArea) {
  }

  public async execute(): Promise<void> {
    let shouldContinue = await this.verifyStagingArea();

    if(!shouldContinue) {
      return;
    }

    await this.authenticationService.logout().toPromise();
  }

  private async verifyStagingArea(): Promise<boolean>{
    if(this.stagingArea.get().inputs.find(v => v.isEdited)){
      let shouldContinue = await this.confirmationDialog.show(
        'You have edited configs in your staging area. Any changes will be lost if you sign out.',
        'Clear Staging Area',
        'Clear Staging Area and Sign Out',
        'Cancel');

      return !!shouldContinue;
    }

    return true;
  }
}

import { SourceData } from './types/source-data';
import { IPopulatedMultiPlotSide } from './types/i-populated-multi-plot-side';
import { IPopulatedMultiPlotLayout } from './types/i-populated-multi-plot-layout';
import { ProcessedPlot } from './types/processed-plot';
import { SideType } from './types/side-type';
import { MergeSides } from './merge-sides';
import { PlotSetDirection } from './plot-set-direction';


/**
 * Create the set of columns which should be rendered for the given set of
 * diagonal plot sets, taking into account options around stacking diagonal plots.
 */
export class CreateColumnsFromDiagonals {
  constructor(
    private readonly mergeSides: MergeSides) {
  }

  /**
   * For a given set of diagonal plot sets and diagonal stacking options, return the final
   * set of columns to be rendered.
   * @param diagonalPlotSets The collection of diagonal plot sets.
   * @param plotSetDirection The plot set direction.
   * @param layout The populated layout.
   * @param sourceData The set of data sources.
   * @returns The set of columns to be rendered.
   */
  public execute(
    diagonalPlotSets: readonly ProcessedPlot[][],
    plotSetDirection: PlotSetDirection,
    layout: IPopulatedMultiPlotLayout,
    sourceData: ReadonlyArray<SourceData>): IPopulatedMultiPlotSide[] {

    // If the plot set direction is vertical (we have more rows than columns) then
    // we only need the first plot set (because the columns are the same for each
    // plot set).
    //
    // For example:
    //     │ ▉   ┐
    //     │▉    ┘ Plot set 1
    //     │ ▉   ┐
    //     │▉    ┘ Plot set 2
    //     └──
     if (plotSetDirection === PlotSetDirection.vertical) {
      diagonalPlotSets = [diagonalPlotSets[0]];
    }

    // If we're stacking the diagonals vertically then we need to merge the columns
    // of each of the relevant plot sets.
    //
    // The above example ultimately becomes (note again that we only need to merge the
    // columns of the first plot set because the columns are the same for each plot set):
    //
    //     │▉    ┐
    //     │▉    ┘ Plot set 1
    //     │▉    ┐
    //     │▉    ┘ Plot set 2
    //     └─
    //
    // A chart with a horizontal plot set direction like the following:
    //
    //         ┌─┐ Plot set 2
    //      ┌─┐    Plot set 1
    //     │  ▉  ▉
    //     │ ▉  ▉
    //     │▉  ▉
    //     └──────
    //
    // Would become:
    //
    //       ─ Plot set 2
    //      ─  Plot set 1
    //     │▉▉
    //     │▉▉
    //     │▉▉
    //     └──
    if (layout.stackDiagonalsVertically) {
      return diagonalPlotSets.map(
        diagonalPlots => this.mergeSides.execute(diagonalPlots.map(v => v.column), sourceData, SideType.column));
    }

    // Return the final set of columns.
    return diagonalPlotSets.reduce((p, c) => {
      p.push(...c);
      return p;
    }, []).map(v => v.column);
  }
}

import {Injectable} from '@angular/core';
import {Authorize} from './authorize.service';
import {AuthorizationResult} from './authorize.service';
import {PageSecurity} from './page-security';
import { AuthenticationService } from '../state/authentication.service';

@Injectable()
export class AuthorizePage {
  constructor(private authenticationService: AuthenticationService, private authorize: Authorize) {
  }

  public execute(pageSecurity: PageSecurity): AuthorizationResult {
    let authorized = AuthorizationResult.authorized;
    if (pageSecurity) {
      if (pageSecurity.requireUnauthenticated) {
        if (this.authenticationService.isAuthenticatedSnapshot) {
          authorized = AuthorizationResult.notAuthorized;
        }
      } else {
        authorized = this.authorize.execute(
            pageSecurity.requireAuthenticated,
            pageSecurity.requiredRoles,
            pageSecurity.roleCheckType);
      }
    }

    return authorized;
  }
}

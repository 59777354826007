import {JobViewModel} from './job-view-model';
import {EventEmitter, Injectable} from '@angular/core';

@Injectable()
export class JobSelectorState {
  private _selectedJob: JobViewModel;

  public changed: EventEmitter<JobViewModel> = new EventEmitter<JobViewModel>();

  public get selectedJob(): JobViewModel {
    return this._selectedJob;
  }
  public set selectedJob(job: JobViewModel) {
    if(this._selectedJob !== job){
      this._selectedJob = job;
      this.changed.emit(job);
    }
  }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {JobViewModel} from '../job-view-model';
import {GetFriendlyErrorAndLog} from '../../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {POST_PROCESSOR_JOB_NAME} from '../../view-job/view-job.component';
import {JobSelectorState} from '../job-selector-state';

@Component({
  selector: 'cs-job-selector',
  templateUrl: './job-selector.component.html',
  styleUrls: ['./job-selector.component.scss']
})
export class JobSelectorComponent implements OnInit {
  @Input() public jobs: ReadonlyArray<JobViewModel>;
  @Input() public showPostProcessor: boolean = false;
  @Input() public jobSelectorState: JobSelectorState;

  @Output() public changed: EventEmitter<JobViewModel> = new EventEmitter<JobViewModel>();

  public errorMessage: string;
  public isLoading: boolean;

  public filteredJobs: ReadonlyArray<JobViewModel>;
  public selectedJob: JobViewModel = null;
  public showStudyName: boolean = true;

  constructor(
    private getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  public ngOnInit(): void {
    this.load();
  }

  public async load(){
    try{
      this.isLoading = true;
      let filteredJobs: JobViewModel[] = [];

      // Job metadata ultimately comes from blob storage, so we won't be hammering DocumentDB.
      let jobMetadataTasks = this.jobs.map(v => v.jobName.tryLoad());
      await Promise.all(jobMetadataTasks);

      for(let job of this.jobs){
        await job.jobName.tryLoad();
        await job.studyName.tryLoad();

        if(this.showPostProcessor || job.jobName.value !== POST_PROCESSOR_JOB_NAME){
          filteredJobs.push(job);
        }
      }

      this.filteredJobs = filteredJobs;

      if(this.filteredJobs.length > 0){
        let firstStudyName = this.filteredJobs[0].studyName.value;
        this.showStudyName = this.filteredJobs.some(v => v.studyName.value !== firstStudyName);

        let job = this.filteredJobs[0];
        if(this.jobSelectorState
          && this.jobSelectorState.selectedJob
          && this.filteredJobs.indexOf(this.jobSelectorState.selectedJob) !== -1) {
          job = this.jobSelectorState.selectedJob;
        }

        this.selectJob(job);
      }

      if(this.jobSelectorState){
        this.jobSelectorState.changed.subscribe((v: JobViewModel) => this.selectJob(v));
      }
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    } finally{
      this.isLoading = false;
    }
  }

  public selectJob(job: JobViewModel) {
    try{
      if(this.filteredJobs.indexOf(job) === -1 || this.selectedJob === job) {
        return;
      }

      this.selectedJob = job;
      this.changed.emit(job);

      if(this.jobSelectorState){
        this.jobSelectorState.selectedJob = job;
      }
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }
}

<ul>
  <li>
    <a target="_blank" href="https://support.canopysimulations.com/hc/en-gb/articles/5650629140637-Config-Encryption">
      Config encryption and whitelists
    </a>
  </li>
  <li>
    <a target="_blank" href="https://support.canopysimulations.com/hc/en-gb/articles/8841725365661-Config-Encryption-when-running-in-a-DiL-or-locally">
      Config permissions and key permissions
    </a>
  </li>
</ul>

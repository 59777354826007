import { Utilities } from '../../../utilities';
import { ExplorationSubSweepMetadata, ExplorationSubSweepMetadataWithArrayValues } from './exploration-inputs-metadata';
import { ProcessedExplorationMapValues } from './exploration-map-values';

/**
 * Expands array sweeps into individual numeric sub-sweeps of the array indexes.
 */
export class ExpandExplorationSubSweeps {

  /**
   * Expands array sweeps into individual numeric sub-sweeps of the array indexes. Array sweeps are convenient for
   * the user when they want to sweep an array of values, however they are fundamentally no different to sweeping
   * each array index separately as a parallel sub-sweep. By expanding array sweeps into parallel sub-sweeps
   * early on in the data processing pipeline we don't need to have any special handling for them anywhere else.
   * @param input The exploration sub-sweep metadata, potentially with array values.
   * @returns The exploration sub-sweep metadata, with array values expanded into parallel sub-sweeps.
   */
  public execute(input: ReadonlyArray<ExplorationSubSweepMetadataWithArrayValues>): ReadonlyArray<ExplorationSubSweepMetadata> {
    const output: ExplorationSubSweepMetadata[] = [];

    // For each sub-sweep...
    for (let item of input) {

      const name = item.name;

      // Helper function for if we want to push the current item as is.
      const pushExact = () => {
        output.push(new ExplorationSubSweepMetadata(name, item.values as ProcessedExplorationMapValues));
      };

      // If there are no values, push the current item as is.
      if (item.values.length === 0) {
        pushExact();
        continue;
      }

      // If it isn't an array sweep, push the current item as is.
      const first = item.values[0];
      if (first.constructor !== Array) {
        pushExact();
        continue;
      }

      // We know it's an array sweep, and we know it at least one value, so get the first value.
      const firstArray = first as number[];
      const values = item.values as ReadonlyArray<number[]>;

      // The arrays in the sweep are zero length, so just make a new sub-sweep with zero values.
      // For the purposes of visualization of this strange corner case, this is fine.
      if (firstArray.length === 0) {
        output.push(new ExplorationSubSweepMetadata(name, item.values.map(_ => 0)));
        continue;
      }

      let fallbackOutput: ExplorationSubSweepMetadata[] = [];
      let filteredOutput: ExplorationSubSweepMetadata[] = [];

      // For each index in the array being swept...
      for (let vectorIndex = 0; vectorIndex < firstArray.length; ++vectorIndex) {

        // Create an appropriate name for the sub-sweep.
        let vectorValueName = `${name.slice(0, name.length - 1)}[${vectorIndex}]${name.slice(name.length - 1, name.length)}`;

        // Pull out the values for this index.
        const subValues = values.map(v => v[vectorIndex]);

        // Create a new sub-sweep metadata object.
        const explorationSubSweepMetadata = new ExplorationSubSweepMetadata(vectorValueName, subValues, name);

        // Sometimes, when sweeping an array, for some indexes the values don't change.
        // In this case we don't need to include them in the expanded sub-sweeps (it is just unnecessary clutter)
        // in the visualization.
        // Unless none of the array indexes had any variation, in which case we will add the non-varying sub-sweeps.
        // Because of this we store the non-varying ones separately for now.
        if (Utilities.unique(subValues).length === 1) {
          // This index has no variation.
          fallbackOutput.push(explorationSubSweepMetadata);
        } else {
          filteredOutput.push(explorationSubSweepMetadata);
        }
      }

      if (filteredOutput.length > 0) {
        // If there are any sub-sweep indexes which did have variation, we can just add those expanded sub-sweeps
        // and discard the non-varying indexes.
        output.push(...filteredOutput);
      } else {
        // If none of the sub-sweep indexes had variation, we can just add all the (non-varying) expanded sub-sweeps.
        output.push(...fallbackOutput);
      }
    }

    return output;
  }
}

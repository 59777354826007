import {ListVersionsDialogData, ListVersionsResult} from './list-versions-dialog.service';
import {Injectable} from '@angular/core';
import {CanopyDocument, ConfigStub, VersionedDocumentMetadata} from '../../../../generated/api-stubs';
import {ConfigType, ConfigTypeLookup} from '../../configs/config-types';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {
  CompareConfigDialog,
  NO_SAVE_OUTPUT_CONFIG_HANDLER
} from '../../configs/comparing/compare-config-dialog/compare-config-dialog.service';
import {GetSimVersion} from '../../../common/get-sim-version.service';
import {DisplayableError} from '../../../common/errors/errors';
import {sortBy} from '../../../common/sort-by';
import {StripNonVersionedDataFromDocument} from '../strip-non-versioned-data-from-document.service';
import {LoadingDialog} from '../../../common/dialogs/loading-dialog.service';
import {VersionedDocumentData} from '../versioned-document-data';
import {IDialogActions, IDialogSession, IDialogSessionFactory} from '../../../common/dialogs/dialog-component-base';
import {StudyInput} from '../../../worksheets/study-input';
import {CustomPropertyUtilities} from '../../custom-properties/custom-property-utilities';
import {ConfigAdditionalData, ConfigOrConfigLoader} from '../../configs/comparing/config-or-config-loader';

export const COMPARE_VERSIONS_TITLE: string = 'Comparing Versions';
export const SELECTED_VERSION_DESCRIPTION: string = 'selected version';

export const COMPARE_TARGET_TARGET_NAME: string = 'compare target';
export const EDITOR_TARGET_NAME: string = 'editor';

@Injectable()
export class ListVersionsDialogSessionFactory implements IDialogSessionFactory<ListVersionsDialogData, ListVersionsResult> {

  constructor(
    private readonly configStub: ConfigStub,
    private readonly compareConfigDialog: CompareConfigDialog,
    private readonly getSimVersion: GetSimVersion,
    private readonly stripNonVersionedDataFromDocument: StripNonVersionedDataFromDocument,
    private readonly loadingDialog: LoadingDialog,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  public create(dialogActions: IDialogActions<ListVersionsResult>, dialog: ListVersionsDialogData): ListVersionsDialogSession {
    return new ListVersionsDialogSession(
      dialogActions,
      dialog,
      this.configStub,
      this.compareConfigDialog,
      this.getSimVersion,
      this.stripNonVersionedDataFromDocument,
      this.loadingDialog,
      this.getFriendlyErrorAndLog);
  }
}

export class ListVersionsDialogSession implements IDialogSession {

  public errorMessage: string;
  public configType: ConfigType;
  public versions: ReadonlyArray<VersionViewModel>;
  public compareTarget: VersionViewModel | undefined;

  public simVersion: string;

  constructor(
    private readonly dialogActions: IDialogActions<ListVersionsResult>,
    private readonly dialog: ListVersionsDialogData,
    private readonly configStub: ConfigStub,
    private readonly compareConfigDialog: CompareConfigDialog,
    private readonly getSimVersion: GetSimVersion,
    private readonly stripNonVersionedDataFromDocument: StripNonVersionedDataFromDocument,
    private readonly loadingDialog: LoadingDialog,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  public async load() {
    try {
      this.errorMessage = undefined;
      this.configType = ConfigTypeLookup.get(this.dialog.configType);

      this.simVersion = this.getSimVersion.currentSimVersion;
      let versionsResult = await this.configStub.getConfigVersions(this.dialog.tenantId, this.dialog.configId);
      let orderedVersions = [...versionsResult.versions];
      orderedVersions.sort(sortBy({ name: 'timestamp', reverse: true }));
      this.versions = orderedVersions.map(v =>{
        const tenant = versionsResult.userInformation.tenants.find(t => t.tenantId === v.tenantId);
        let tenantName = tenant ? tenant.shortName : v.tenantId;
        let username = v.userId;
        if(tenant){
          const user = tenant.users.find(u => u.userId === v.userId);
          if(user){
            username = user.username;
          }
        }

        return new VersionViewModel(
          v.tenantId,
          v.userId,
          tenantName,
          username,
          v.timestamp);
      } );
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async compareToCurrent(item: VersionViewModel){
    try {

      if(!item){
        throw new DisplayableError('No item passed to compare.');
      }

      if(!this.compareTarget && !this.dialog.configData) {
        throw new DisplayableError('No compare target.');
      }

      let data = await this.loadSourceAndTarget(item);

      let target = new StudyInput(
        this.configType.singularKey,
        data.targetUserId,
        this.dialog.configId,
        data.target.name,
        data.target.data,
        CustomPropertyUtilities.objectToList(data.target.properties),
        data.target.notes,
        this.simVersion,
        true,
        undefined);
      let additionalTargetData = new ConfigAdditionalData(
        data.target.deleteRequested,
        data.target.parentWorksheetId);

      let source = new StudyInput(
        this.configType.singularKey,
        item.userId,
        this.dialog.configId,
        data.source.name,
        data.source.data,
        CustomPropertyUtilities.objectToList(data.source.properties),
        data.source.notes,
        this.simVersion,
        true,
        undefined);
      let additionalSourceData = new ConfigAdditionalData(
        data.source.deleteRequested,
        data.source.parentWorksheetId);

      await this.compareConfigDialog.compare(
        this.configType.singularKey,
        [
          new ConfigOrConfigLoader(data.targetName, target, undefined, additionalTargetData),
          new ConfigOrConfigLoader(SELECTED_VERSION_DESCRIPTION, source, undefined, additionalSourceData),
        ],
        this.compareTarget
          ? NO_SAVE_OUTPUT_CONFIG_HANDLER
          : this.dialog.saveOutputConfigHandler);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public setCompareTarget(item: VersionViewModel | undefined){
    this.compareTarget = item;
  }

  public async loadVersion(item: VersionViewModel){
    try {
      let strippedVersionDocument = await this.getStrippedConfigVersion(item);
      this.dialogActions.accept(new ListVersionsResult(strippedVersionDocument));
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public getStrippedConfigVersion(item: VersionViewModel): Promise<VersionedDocumentData>{
    return this.loadingDialog.show(() => this.getStrippedConfigVersionInner(item));
  }

  public async getStrippedConfigVersionInner(item: VersionViewModel): Promise<VersionedDocumentData>{
    let versionDocument = await this.getConfigVersion(item.timestamp);
    return this.stripNonVersionedDataFromDocument.execute(versionDocument);
  }

  public async getConfigVersion(timestamp: string): Promise<CanopyDocument> {
    let versionResult = await this.configStub.getConfig(
      this.dialog.tenantId,
      this.dialog.configId,
      undefined,
      this.simVersion,
      timestamp);
    return versionResult.config;
  }

  public loadSourceAndTarget(item: VersionViewModel): Promise<SourceAndTarget> {
    return this.loadingDialog.show(() => this.loadSourceAndTargetInner(item));
  }

  public async loadSourceAndTargetInner(item: VersionViewModel): Promise<SourceAndTarget> {
    let source: any;
    let target: any;
    let targetName: string;
    let targetUserId: string;

    source = await this.getConfigVersion(item.timestamp);

    if(this.compareTarget){
      target = await this.getConfigVersion(this.compareTarget.timestamp);
      targetName = COMPARE_TARGET_TARGET_NAME;
      targetUserId = this.compareTarget.userId;
    } else {
      target = this.dialog.configData;
      targetName = EDITOR_TARGET_NAME;
      targetUserId = this.dialog.userId;
    }

    source = this.stripNonVersionedDataFromDocument.execute(source);
    target = this.stripNonVersionedDataFromDocument.execute(target);

    return new SourceAndTarget(source, target, targetName, targetUserId);
  }
}

export class SourceAndTarget {
  constructor(
    public readonly source: VersionedDocumentData,
    public readonly target: VersionedDocumentData,
    public readonly targetName: string,
    public readonly targetUserId: string){
  }
}

export class VersionViewModel implements VersionedDocumentMetadata {
  constructor(
    public readonly tenantId: string,
    public readonly userId: string,
    public readonly tenantName: string,
    public readonly username: string,
    public readonly timestamp: string){
  }
}

import { IViewerChannelData } from '../channel-data-loaders/viewer-channel-data';
import { ViewerChannelDataMap } from '../channel-data-loaders/viewer-channel-data-map';
import { CloneAsSiChannelData } from './clone-as-si-channel-data';


export class GetSiFeatureChannel {

  constructor(
    private readonly cloneAsSiChannelData: CloneAsSiChannelData) {
  }

  public execute(dataMap: ViewerChannelDataMap, featureChannelName: string | undefined): IViewerChannelData | undefined {
    if (!featureChannelName) {
      return undefined;
    }

    let featureChannel = dataMap.get(featureChannelName);
    if (!featureChannel.data) {
      return undefined;
    }

    return this.cloneAsSiChannelData.execute(featureChannel);
  }

}

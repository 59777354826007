import {SimContext} from '../runner/sim-context';
import {ANY_TARGET, Rule} from '../runner/rule';
import {ConfigContext} from '../runner/config-context';
import {SimChannel} from '../runner/sim-channel';
import {Channel} from '../runner/channel';
import {RuleLogger} from '../runner/rule-logger';
import {TRUN_DOMAIN_NAME, SRUN_DOMAIN_NAME} from '../../../visualizations/constants';

export class MonotonicChannels extends Rule {
  public name = 'Monotonic Channels';
  public configTypes = 'Telemetry';
  public simTypes = ANY_TARGET;

  public getSimChannels(context: SimContext): SimChannel[] {
    return [
      new SimChannel('vCar', context.simType),
      new SimChannel('tRun', context.simType),
      new SimChannel('sRun', context.simType),
    ];
  }

  public evaluateSim(context: SimContext, log: RuleLogger) {

    if(context.is('StraightSim') || context.is('ApexSim')) {
      log.assert(
        context.getChannel('vCar', context.simType).isMonotonic(),
        'vCar is not monotonic.');
    }

    if(context.simType.endsWith('Lap')) {
      log.assert(
        context.getChannel('tRun', context.simType).isMonotonic(),
        'tRun is not monotonic.');
      log.assert(
        context.getChannel('sRun', context.simType).isMonotonic(),
        'sRun is not monotonic.');
    }

    if(context.is('Telemetry')){
      let tRun = context.tryGetChannel('tRun', context.simType);
      if(tRun) {
        log.assert(
          tRun.isMonotonic(),
          'tRun is not monotonic.');
      }
      let sRun = context.tryGetChannel('sRun', context.simType);
      if(sRun) {
        log.assert(
          sRun.isMonotonic(),
          'sRun is not monotonic.');
      }
    }
  }

  public evaluateConfig(context: ConfigContext, log: RuleLogger) {
    if(context.is('telemetry')) {
      let monotonicTelemetryChannels = [
        TRUN_DOMAIN_NAME,
        SRUN_DOMAIN_NAME,
        'timeBase'
      ];

      let channels = context.getValue('channels');
      if(channels){
        for(let channel of channels.value){
          if (monotonicTelemetryChannels.indexOf(channel.name) !== -1) {
            if(channel.data && channel.data.length) {
              let c = new Channel(channel.name, 'Telemetry', channel.data);
              log.assert(
                c.isMonotonic(),
                channel.name + ' is not monotonic.');
            }
          }
        }
      }
    }
  }
}

<div class="filter-query-builder-condition">
  <cs-error-message [message]="errorMessage"></cs-error-message>

  <form [formGroup]="form">
    <select (change)="onConditionSourceChanged($event.target.value)"
      class="form-select condition-source-select">
      @for (source of conditionSources; track source) {
        <option
          [attr.selected]="source.id === condition.source.id ? true : null"
        [value]="source.id">{{source.name}}</option>
      }
    </select>

    <span [ngClass]="{ 'hidden-input': conditionSourceItemSet?.isFiniteSet }" class="input-area">
      <input type="text" placeholder="{{condition.source?.id === 'config' ? 'Path' : 'Name'}}" class="form-control condition-name-textbox"
        formControlName="conditionNameControl"
        id="{{nameElementId}}">
      <div class="errors-area">
        @if (conditionNameControl.hasError('required')) {
          <p class="text-danger">
            Value is required.
          </p>
        }
        @if (conditionNameControl.hasError('configPath')) {
          <p class="text-danger">
            Path is invalid.
          </p>
        }
        @if (conditionNameControl.hasError('customPropertyName')) {
          <p class="text-danger">
            Name is invalid.
          </p>
        }
      </div>
    </span>

    @if (conditionSourceItemSet?.isFiniteSet) {
      <select (change)="onConditionNameChanged($event.target.value)"
        class="form-select condition-name-select">
        @for (item of conditionSourceItemSet.items; track item) {
          <option
            [attr.selected]="item.name === condition.name.name ? true : null"
          [value]="item.name">{{item.displayName}}</option>
        }
      </select>
    }

    <select (change)="onConditionOperatorChanged($event.target.value)"
      class="form-select condition-operator-select">
      @for (operator of conditionOperators; track operator) {
        <option
          [attr.selected]="operator.id === condition.operator.id ? true : null"
        [value]="operator.id">{{operator.name}}</option>
      }
    </select>

    @if (condition.name?.isFiniteValues) {
      <span class="input-group-area">
        <span class="input-group">
          <select (change)="onConditionValueChanged($event.target.value)"
            class="form-select condition-value-select">
            @for (value of condition.name.values; track value) {
              <option
                [attr.selected]="value.value === condition.value ? true : null"
              [value]="value.value">{{value.displayValue}}</option>
            }
          </select>
          <div class="input-group-append">
            @if (condition.name.units) {
              <span class="input-group-text">{{condition.name.units}}</span>
            }
          </div>
        </span>
      </span>
    }

    <span [ngClass]="{ 'hidden-input': !condition.operator.requiresValue || condition.name?.isFiniteValues }" class="input-area input-group-area">
      <span class="input-group">
        <span class="relative-area">
          <input type="text" placeholder="Value" class="form-control condition-value-textbox"
            formControlName="conditionValueControl"
            id="{{valueElementId}}">
          <div class="errors-area">
            @if (conditionValueControl.hasError('required')) {
              <p class="text-danger">
                Value is required.
              </p>
            }
            @if (conditionValueControl.hasError('numeric')) {
              <p class="text-danger">
                Value should be numeric.
              </p>
            }
            @if (conditionValueControl.hasError('dateTime')) {
              <p class="text-danger">
                YYYY-MM-DD<strong>T</strong>HH:mm:ss<strong>Z</strong>
              </p>
            }
          </div>
        </span>
        <div class="input-group-append">
          @if (condition.name?.units) {
            <span class="input-group-text">{{condition.name.units}}</span>
          }
        </div>
      </span>

    </span>
  </form>
</div>

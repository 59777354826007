import { InputValidationError } from '../../../common/errors/errors';
import { UnitsMap } from '../../../visualizations/viewers/channel-data-loaders/local-config-source-loader';
import {
  createRegularExpressionFromWildcardString,
  isWildcardString
} from '../../../common/create-regular-expression-from-wildcard-string';
import { JsonEditorInstance } from './json-editor-instance';


export const CUSTOM_EDITOR_VISIBLE = 'custom-editor-visible';
export const CUSTOM_EDITOR_HIDDEN = 'custom-editor-hidden';

export class ValidatedJsonEditorInstance extends JsonEditorInstance {

  constructor(
    public readonly jsonEditor: any,
    simVersion: string,
    defaultUnits: UnitsMap) {
    super(simVersion, defaultUnits);
  }

  public get ready(): boolean{
    return this.jsonEditor.ready;
  }

  public get canValidate(): boolean {
    return true;
  }

  public setValue(value: any) {
    this.jsonEditor.setValue(value);
  }

  public getValue(): any {
    this.validate();
    return this.jsonEditor.getValue();
  }

  public validate() {
    let errors: any[] = this.jsonEditor.validate();
    if (errors.length) {
      let errorMessages = [];
      for (let error of errors) {
        errorMessages.push(`${error.message} (${error.path}). `);
      }
      throw new InputValidationError(errorMessages.join('\n'));
    }
  }

  public getFirstInput(): HTMLElement | undefined {
    return this.getRenderedInput(this.jsonEditor.element.getElementsByTagName('input'))
      || this.getRenderedInput(this.jsonEditor.element.getElementsByTagName('select'));
  }

  public getFirstVisibleInput(): HTMLElement | undefined {
    return this.getVisibleInput(this.jsonEditor.element.getElementsByTagName('input'))
      || this.getVisibleInput(this.jsonEditor.element.getElementsByTagName('select'));
  }

  private getRenderedInput(inputs: ReadonlyArray<HTMLElement>) {
    for (let item of inputs) {
      if (this.isRendered(item)) {
        return item;
      }
    }

    return undefined;
  }

  private getVisibleInput(inputs: ReadonlyArray<HTMLElement>) {
    for (let item of inputs) {
      if (this.isInViewport(item)) {
        return item;
      }
    }

    return undefined;
  }

  private isRendered(element: HTMLElement): boolean {
    const bounding = element.getBoundingClientRect();
    return !!(bounding.width && bounding.height);
  }

  private isInViewport(element: HTMLElement): boolean {
    const bounding = element.getBoundingClientRect();
    return !!(bounding.top > 0 && bounding.left > 0 && bounding.width && bounding.height);
  }

  // NOTE: This algorithm should match the one for setCustomView.
  public getFilteredValue(): any {
    let config = this.getValue();
    let parameters = this.getCustomViewFilter();

    let inner = (source: any, sourceKey: string, isAncestorVisible: boolean): { isVisible: boolean; value: any } => {
      let isDescendantVisible = false;

      let isNodeVisible = isAncestorVisible || parameters.length === 0
        || this.testParameter(sourceKey, parameters)
        || (source
          && typeof source === 'object'
          && source['name']
          && this.testParameter(source['name'], parameters));

      let clone = source;
      if (source) {
        if (typeof source === 'object') {
          let isArray = Array.isArray(source);
          if (isArray) {
            clone = [];
            for (let child of source) {
              let childResult = inner(child, sourceKey, isNodeVisible);
              if (childResult.isVisible) {
                clone.push(childResult.value);
              }
              isDescendantVisible = childResult.isVisible || isDescendantVisible;
            }
          } else {
            clone = {};
            for (let key in source) {
              if (!source.hasOwnProperty(key)) {
                continue;
              }
              let child = source[key];
              let childResult = inner(child, key, isNodeVisible);
              if (childResult.isVisible) {
                clone[key] = childResult.value;
              }
              isDescendantVisible = childResult.isVisible || isDescendantVisible;
            }
          }
        }
      }

      let isVisible = isNodeVisible || isDescendantVisible;

      return {
        isVisible,
        value: clone
      };
    };

    let result = inner(config, undefined, false);
    return result.value;
  }

  // NOTE: This algorithm should match the one for getFilteredValue.
  public setCustomView(parameters: string[]) {
    this.customViewFilter = parameters || [];

    let rootElement = this.jsonEditor.element;
    if (!rootElement) {
      return;
    }

    let getSchemaPath = (element: Element) => {
      if (element && element.getAttribute) {
        return element.getAttribute('data-schemapath');
      }

      return undefined;
    };

    let getParameterNameFromSchemaPath = (schemaPath: string) => {
      let parameterName = schemaPath;
      let periodIndex = schemaPath.lastIndexOf('.');
      if (periodIndex !== -1) {
        parameterName = schemaPath.substring(periodIndex + 1);
      }
      return parameterName;
    };

    let getBranchName: (element: Element) => string = (element: Element) => {
      if (element.children) {
        let childValueElementFound = false;
        for (let child of Array.from(element.children)) {
          if (child) {
            let schemaPath = getSchemaPath(child);
            if (schemaPath) {
              childValueElementFound = true;

              let parameterName = getParameterNameFromSchemaPath(schemaPath);
              if (parameterName === 'name') {
                let inputElements = child.getElementsByTagName('input');
                if (inputElements.length === 1) {
                  let inputElement = inputElements[0];
                  if (inputElement.type === 'hidden' || inputElement.type === 'text') {
                    return inputElement.value;
                  }
                }
              }
            }
          }
        }

        if (!childValueElementFound) {
          for (let child of Array.from(element.children)) {
            if (child) {
              let result = getBranchName(child);
              if (result) {
                return result;
              }
            }
          }
        }
      }

      return undefined;
    };

    let inner = (element: Element, isAncestorVisible: boolean): boolean => {
      let isDescendantVisible = false;

      let buttonElement = <HTMLButtonElement>element;
      if (buttonElement.type === 'button' && buttonElement.title === 'Expand') {
        buttonElement.click();
        return false;
      }

      let schemaPath = getSchemaPath(element);

      let isNodeVisible = isAncestorVisible || parameters.length === 0;
      if (!isNodeVisible) {
        if (schemaPath) {
          let parameterName = getParameterNameFromSchemaPath(schemaPath);
          let branchName = getBranchName(element);

          if (this.testParameter(parameterName, parameters) || (branchName && this.testParameter(branchName, parameters))) {
            if (parameterName === 'name') {
              let childControls = element.getElementsByTagName('input');
              if (childControls.length !== 1 || childControls[0].type !== 'hidden') {
                isNodeVisible = true;
              }
            } else {
              isNodeVisible = true;
            }
          }
        }
      }

      if (element.children) {
        for (let child of Array.from(element.children)) {
          if (child) {
            isDescendantVisible = inner(child, isNodeVisible) || isDescendantVisible;
          }
        }
      }

      let result = isNodeVisible || isDescendantVisible;

      if (schemaPath) {
        if (result) {
          element.classList.add(CUSTOM_EDITOR_VISIBLE);
          element.classList.remove(CUSTOM_EDITOR_HIDDEN);
        } else {
          element.classList.add(CUSTOM_EDITOR_HIDDEN);
          element.classList.remove(CUSTOM_EDITOR_VISIBLE);
        }
      }

      return result;
    };

    inner(rootElement, false);
  }

  public async collapseAll(): Promise<void> {
    const editors = "editors";
    const collapsed = "collapsed";
    const root = this.jsonEditor.editors.root;

    const collapse = async (editor: any): Promise<void> => {
      if (!editor) {
        return;
      }

      if (editor.hasOwnProperty(collapsed)) {
        if (editor.editor_holder) {
          editor.editor_holder.style.display = 'none';
        }

        editor.collapsed = true;
        if(editor.collapse_control){
          editor.setButtonText(editor.collapse_control, '', 'expand', editor.translate('button_expand'));
        }
      }

      if (editor.hasOwnProperty(editors)) {
        for (let prop of Object.getOwnPropertyNames(editor.editors)) {
          await collapse(editor.editors[prop]);
        }
      }
    };

    if (root.hasOwnProperty(editors)) {
      for (let prop of Object.getOwnPropertyNames(root.editors)) {
        await collapse(root.editors[prop]);
      }
    }
  }

  public destroy() {
    this.jsonEditor.options.canopy.onDestroy();
    return this.jsonEditor.destroy();
  }

  private testParameter(inputParameter: string, parameters: ReadonlyArray<string>): boolean {
    for (let value of parameters || []) {
      if (!isWildcardString(value)) {
        if (value === inputParameter) {
          return true;
        }
      } else {
        let r = createRegularExpressionFromWildcardString(value, false);
        if (r.test(inputParameter)) {
          return true;
        }
      }
    }

    return false;
  }
}

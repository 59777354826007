import { Component, OnInit } from '@angular/core';
import {DialogManager} from '../../../common/dialogs/dialog-manager.service';
import {LintingEngineRunnerDialogData, LintingEngineRunnerResult} from './linting-engine-runner-dialog.service';
import {LintingEngineRunnerDialogSessionFactory} from './linting-engine-runner-dialog-session';
import {DialogComponentBase} from '../../../common/dialogs/dialog-component-base';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';

@Component({
  selector: 'cs-linting-engine-runner-dialog',
  templateUrl: './linting-engine-runner-dialog.component.html',
  styleUrls: ['./linting-engine-runner-dialog.component.scss']
})
export class LintingEngineRunnerDialogComponent extends DialogComponentBase<LintingEngineRunnerDialogData, LintingEngineRunnerResult> implements OnInit {
  constructor(
    sessionFactory: LintingEngineRunnerDialogSessionFactory,
    dialogManager: DialogManager,
    getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
    super(sessionFactory, dialogManager, dialogManager.secondaryChanged, getFriendlyErrorAndLog);
  }

  protected getCurrentDialog(): LintingEngineRunnerDialogData {
    let currentDialog = this.dialogManager.getSecondary();
    if (currentDialog && currentDialog instanceof LintingEngineRunnerDialogData) {
      return currentDialog;
    }
    return undefined;
  }
}



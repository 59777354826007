import {
  StudyStub, SimType,
  StudyType,
  DocumentSubType, NewStudyData
} from '../../../generated/api-stubs';
import {CanopyPusher} from '../../common/canopy-pusher.service';
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {JobViewModel, JobViewModelFactory} from '../jobs/job-results/job-view-model';
import {CustomProperty} from '../custom-properties/custom-properties';
import {simVersionToNumber} from '../../visualizations/sim-version-to-number';
import { AuthenticationService, UserData } from '../../identity/state/authentication.service';

export const WAITING_FOR_RESULT_MESSAGE = 'Waiting for result...';
export const VERIFY_CAR_STUDY_NAME = 'Verify Car';

export abstract class CarConfigValidationSessionBase{
  public errorMessage: string;
  public statusMessage: string;
  public savedInStagingArea: boolean = false;

  public userData: UserData;
  public status: CheckStatus = CheckStatus.none;
  public jobViewModel: JobViewModel;

  public isStudySuccessful: boolean = false;

  constructor(
    public readonly editorValue: any,
    public readonly simVersion: string,
    public readonly userId: string,
    public readonly configId: string,
    public readonly name: string,
    public readonly isEdited: boolean,
    public readonly customProperties: ReadonlyArray<CustomProperty>,
    protected readonly authenticationService: AuthenticationService,
    protected readonly studyStub: StudyStub,
    protected readonly canopyPusher: CanopyPusher,
    protected readonly jobViewModelFactory: JobViewModelFactory,
    protected readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog
  ){}

  public abstract dispose(): void;
  public abstract executeStudy(newStudyData: NewStudyData): Promise<void>;

  public async execute(){
    try {
      this.status = CheckStatus.checking;
      this.statusMessage = 'Submitting simulation...';
      this.userData = this.authenticationService.userDataSnapshot;

      let study = {
        simTypes: [SimType.StraightSim],
        simConfig: {
          car: undefined as any,
          track: undefined as any,
          weather: undefined as any
        },
        exploration: undefined as any
      };

      if(simVersionToNumber(this.simVersion) >= 1604){
        study.simConfig.weather = {
          TAir: 20,
          pAirAtmosphericLocal: 101300,
          rHumidityRelative: 0.2
        };
      } else{
        study.simConfig.track = {
          name: 'Car Verification Track',
          hTrackAboveSeaLevel: 0,
          racingLine: {
            sLap: [0, 1],
            cLap: [0, 1]
          }
        };
        study.simConfig.weather = {
          TAir: 20,
          pAirAtmosphericAtSeaLevel: 101300,
          rHumidityRelative: 0.2
        };
      }

      study.simConfig.car = this.editorValue;

      let sources = [{
        configType: DocumentSubType.car,
        userId: this.userId,
        configId: this.configId,
        name: this.name,
        isEdited: this.isEdited
      }];

      let properties: CustomProperty[] = (this.customProperties || []).map(v => ({
          name: `${DocumentSubType.car}.${v.name}`,
          value: v.value
        }));

      let studyData = {
        name: this.userData.sub,
        studyType: StudyType.straightSim,
        simVersion: this.simVersion,
        sources,
        properties,
        study,
        isTransient: true
      };

      await this.executeStudy(studyData);
    } catch(error){
      this.status = CheckStatus.none;
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
      this.statusMessage = '';
    }
  }
}

export enum CheckStatus{
  none,
  checking,
  checked
}

import {UserStateTenant} from './user-state-tenant';

export class UserState {
  constructor(
    public readonly userId: string,
    public readonly username: string,
    public readonly email: string,
    public readonly isEnabled: boolean,
    public readonly isTwoFactorEnabled: boolean,
    public readonly isMicroCanopy: boolean,
    public readonly tenant: UserStateTenant) {
  }
}

import { ElementRef, Input, OnDestroy, ViewChild, Directive } from '@angular/core';
import {Timer} from '../../common/timer.service';
import {
  JobConfigData,
  VisualizationFactory
} from '../visualizations/visualization-factory.service';
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {OutOfZoneNavigationStation} from '../visualizations/visualization-zones';
import {ConfigType} from './config-types';
import {UnitsMap} from '../../visualizations/viewers/channel-data-loaders/local-config-source-loader';
import {DocumentSubType} from '../../../generated/api-stubs';
import {createRandomString} from '../../visualizations/create-random-string';

export class ConfigPreviewHelper {

  public static isTrack(configType: DocumentSubType): boolean {
    return configType === DocumentSubType.track;
  }

  public static isCar(configType: DocumentSubType): boolean {
    return configType === DocumentSubType.car;
  }

  public static isTelemetry(configType: DocumentSubType): boolean {
    return configType === DocumentSubType.telemetry;
  }

  public static hasConfigPreview(configType: DocumentSubType): boolean {
    return ConfigPreviewHelper.isTrack(configType)
      || ConfigPreviewHelper.isCar(configType)
      || ConfigPreviewHelper.isTelemetry(configType);
  }
}

@Directive()
export class ConfigPreviewComponentBase implements OnDestroy {
  public configPreviewErrorMessage: string;
  @Input() public configType: ConfigType;

  @ViewChild('configPreviewContainer') configPreviewElement: ElementRef;
  public navigationStation: OutOfZoneNavigationStation;

  public readonly elementId: string = 'view-config-preview-' + createRandomString(10);

  constructor(
    private visualizationFactory: VisualizationFactory,
    protected timer: Timer,
    protected getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  public ngOnDestroy(): any {
    if(this.navigationStation){
      this.navigationStation.dispose();
      this.navigationStation = null;
    }
  }

  public get isTrack(): boolean {
    return ConfigPreviewHelper.isTrack(this.configType.singularKey);
  }

  public get isCar(): boolean {
    return ConfigPreviewHelper.isCar(this.configType.singularKey);
  }

  public get isTelemetry(): boolean {
    return ConfigPreviewHelper.isTelemetry(this.configType.singularKey);
  }

  public get hasConfigPreview(): boolean {
    return ConfigPreviewHelper.hasConfigPreview(this.configType.singularKey);
  }

  public async loadConfigPreviews(jobData: ReadonlyArray<JobConfigData>, unitsMap: UnitsMap | undefined): Promise<void> {
    try{
      this.configPreviewErrorMessage = undefined;
      if(!this.configPreviewElement || !this.configPreviewElement.nativeElement){
        // The page has been unloaded.
        return;
      }

      if(this.navigationStation){
        this.navigationStation.dispose();
        this.navigationStation = null;
      }

      let element = <HTMLElement>this.configPreviewElement.nativeElement;
      element.innerHTML = `<div id="${this.elementId}"></div>`;

      await this.timer.yield();

      if(this.isTrack){
        this.navigationStation = await this.visualizationFactory.createTrackPreview(this.elementId, jobData, unitsMap);
      } else if(this.isCar){
        this.navigationStation = await this.visualizationFactory.createCarPreview(this.elementId, jobData, unitsMap);
      } else{
        this.navigationStation = await this.visualizationFactory.createTelemetryPreview(this.elementId, jobData);
      }

      await this.navigationStation.build();
    } catch (error) {
      this.configPreviewErrorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async loadConfigPreviewsIfRequired(jobData: ReadonlyArray<JobConfigData>, unitsMap: UnitsMap | undefined){
    if (this.hasConfigPreview){
      await this.loadConfigPreviews(jobData, unitsMap);
    }
  }
}


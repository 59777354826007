<div class="container-fluid">
  @if (isLoading) {
    <cs-loader class="loader-centered"></cs-loader>
  }
  <cs-error-message [message]="errorMessage"></cs-error-message>
  @if (message) {
    <div class="alert alert-info" data-test-id="email-verified-message">
      <p>{{message}}</p>
    </div>
  }
</div>

import {Injectable} from '@angular/core';
import {DialogManager, IDialog, DialogBase} from '../common/dialogs/dialog-manager.service';

@Injectable()
export class SetChannelUnitsDialog {
  constructor(
    private dialogManager: DialogManager
  ){}

  public async show(
    channelName: string,
    currentUnits: string): Promise<SetChannelUnitsDialogResult> {

    let result = await this.dialogManager.showTertiaryDialog(
      new SetChannelUnitsDialogData(
        channelName,
        currentUnits));

    return result;
  }
}

export class SetChannelUnitsDialogData extends DialogBase<SetChannelUnitsDialogResult> implements IDialog {
  constructor(
    private _channelName: string,
    private _currentUnits: string) {
    super();
  }

  public get channelName(): any {
 return this._channelName; 
}
  public get currentUnits(): any {
 return this._currentUnits; 
}
}

export class SetChannelUnitsDialogResult {
  constructor(
    private _units: string){
  }

  public get units() {
 return this._units; 
}
}

import {RuleError} from './rule-error';
import {SimChannel} from './sim-channel';

export class GetChannelsResult {
  constructor(
    public readonly channels: ReadonlyArray<SimChannel>,
    public readonly ruleErrors: ReadonlyArray<RuleError>,
    public readonly skippedRules: ReadonlyArray<string>) {
  }
}

import { ProcessedMultiPlotChannel } from '../data-pipeline/types/processed-multi-plot-channel';
import { ExplorationSubSweep } from './explorations/exploration-sub-sweep';

/**
 * Metadata for an exploration channel.
 */
export class ExplorationChannelMetadata {

  /**
   * Creates an instance of exploration channel metadata.
   * @param explorationSubSweep The exploration sub sweep.
   */
  constructor(
    public readonly explorationSubSweep: ExplorationSubSweep) {
  }

  /**
   * Creates an instance of exploration channel metadata from a processed multi plot channel.
   * @param channel The processed multi plot channel.
   * @returns The exploration channel metadata.
   */
  public static fromProcessedMultiPlotChannel(channel: ProcessedMultiPlotChannel): ExplorationChannelMetadata | undefined {
    for (let source of channel.sources) {
      if (source.loaderMetadata && source.loaderMetadata instanceof ExplorationChannelMetadata) {
        return source.loaderMetadata;
      }
    }

    return undefined;
  }
}

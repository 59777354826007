
<form (ngSubmit)="onSubmit()" [formGroup]="form">
  <div class="row">
    <div class="col-12 col-sm-6">
      <div class="mb-3">
        <label for="name-input" class="title-label form-label">Name</label>
        <input id="name-input" type="text" class="form-control" formControlName="name">
        @if (!name.pristine && name.hasError('required')) {
          <p class="text-danger">
            Name is required
          </p>
        }
        @if (name.hasError('minlength')) {
          <p class="text-danger">
            Name is too short.
          </p>
        }
        @if (name.hasError('maxlength')) {
          <p class="text-danger">
            Name is too long.
          </p>
        }
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="mb-3">
        <label for="notes-input" class="title-label form-label">Notes</label>
        <textarea autosize [minRows]="1" id="notes-input" class="form-control notes-input" formControlName="notes"></textarea>
        @if (notes.hasError('maxlength')) {
          <p class="text-danger">
            Notes are too long.
          </p>
        }
      </div>
    </div>
  </div>

  <div class="row">
    <div class=col-12>
      <label class="title-label form-label">Custom Properties</label>
      <cs-view-edit-custom-properties [tenantId]="tenantId" [target]="target"
        [form]="form"
        [initialProperties]="customProperties"
      [resultProperties]="customProperties"></cs-view-edit-custom-properties>
    </div>
  </div>

  <div class="modal-footer">
    <cs-error-message [message]="errorMessage"></cs-error-message>
    @if (saveSuccessful) {
      <div id="success-message" class="alert alert-success">
        Saved.
      </div>
    }
    <button id="edit-submit" type="submit" class="btn btn-smart btn-secondary" [disabled]="submitButton.isSubmitting">
      <i class="fa fa-cloud"></i>
      {{submitButton.text}}
    </button>
  </div>
</form>

import {
  DocumentSubType,
  GetConfigQueryResult,
  GetStudyQueryResult
} from '../../generated/api-stubs';
import {InputCustomProperty, StudyInput} from './study-input';
import {CustomPropertyUtilities} from '../simulations/custom-properties/custom-property-utilities';
import {TELEMETRY_SCHEMA_SIM_VERSION} from '../simulations/studies/stage-study.service';
import {ConfigTypeLookup} from '../simulations/configs/config-types';
import {getConfigNotesFromStudy, getConfigPropertiesFromStudy} from './study-utilities';

/**
 * Converts a config result (from requesting a config from the API) into a study input structure.
 * @param configResult The config result.
 * @param isConfigSubTree Whether the config is a subtree of a larger config.
 * @returns The study input.
 */
export function configResultToStudyInput(configResult: GetConfigQueryResult, isConfigSubTree: boolean = false): StudyInput {
  const customProperties = CustomPropertyUtilities.objectToList(configResult.config.properties);

  return new StudyInput(
    configResult.config.subType,
    configResult.config.userId,
    configResult.config.documentId,
    configResult.config.name,
    isConfigSubTree ? configResult.config.data.definition : configResult.config.data,
    InputCustomProperty.fromMutables(customProperties),
    configResult.config.notes,
    configResult.convertedSimVersion,
    false,
    undefined);
}

/**
 * Converts a default config to a study input structure.
 * @param configType The config type.
 * @param configName The config name.
 * @param configData The config data.
 * @param simVersion The simulation version.
 * @returns The study input.
 */
export function defaultConfigToStudyInput(configType: DocumentSubType, configName: string, configData: any, simVersion: string): StudyInput {
  return new StudyInput(
    configType,
    undefined,
    getDefaultConfigId(configType, configName),
    configName,
    configData,
    [],
    undefined,
    simVersion,
    false,
    undefined);
}

/**
 * Converts a telemetry config to a study input structure.
 * @param configName The config name.
 * @param configData The config data.
 * @returns The study input.
 */
export function telemetryConfigToStudyInput(configName: string, configData: any): StudyInput {
  return new StudyInput(
    DocumentSubType.telemetry,
    undefined,
    undefined,
    configName,
    configData,
    [],
    undefined,
    TELEMETRY_SCHEMA_SIM_VERSION,
    false,
    undefined);
}

/**
 * Extracts the study input of the given config type from a study result and converts it into a study input structure.
 * @param configType The config type.
 * @param studyResult The study result.
 * @returns The study input.
 */
export function studyResultToStudyInput(configType: DocumentSubType, studyResult: GetStudyQueryResult): StudyInput {

  const studyData = studyResult.study.data.definition;

  let resultData: any = studyData.simConfig[configType] || studyData[configType];
  if(!resultData){
    return undefined;
  }

  let metadata = studyResult.study.data.sources ? studyResult.study.data.sources.find(v => v.configType === configType) : undefined;
  let name = metadata ? metadata.name : studyResult.study.name;
  let isEdited = !!(metadata ? metadata.isEdited : false);

  let properties = getConfigPropertiesFromStudy(configType, studyResult);
  let notes = getConfigNotesFromStudy(configType, studyResult);

  return new StudyInput(
    configType,
    metadata ? metadata.userId : undefined,
    metadata ? metadata.configId : undefined,
    name,
    resultData,
    InputCustomProperty.fromMutables(properties),
    notes,
    studyResult.convertedSimVersion,
    isEdited,
    undefined);
}

/**
 * Gets the default config id for a config type and name.
 * @param configType The config type.
 * @param configName The config name.
 * @returns The default config id.
 */
export function getDefaultConfigId(configType: DocumentSubType, configName: string){
  //if(isDefaultConfigId(configName)){
  //  return configName;
  //}

  let configTypeData = ConfigTypeLookup.get(configType);
  if(configTypeData){
    return `${configTypeData.pluralKey}/${configName}.json`;
  }

  return undefined;
}

/**
 * Gets whether a value is a default config id.
 * @param value The value.
 * @returns Whether the value is a default config id.
 */
export function isDefaultConfigId(value: string): boolean {
  return value.endsWith('.json');
}

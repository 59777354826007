export function defer(): DeferredPromise {
  let deferred: DeferredPromise = {
    promise: undefined,
    resolve: undefined,
    reject: undefined
  };

  deferred.promise = new Promise(function(resolve, reject) {
    deferred.resolve = resolve;
    deferred.reject = reject;
  });

  return deferred;
}

export interface DeferredPromise {
  promise: Promise<any>;
  resolve: (value?: any) => void;
  reject: (reason?: any) => void;
}

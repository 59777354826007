<div class="view-configs-component view-{{configType?.singularKey}}-configs">
  <cs-error-message [message]="errorMessage"></cs-error-message>

  <div class="configs-loader">
    @if (!configs) {
      <cs-loader></cs-loader>
    }
  </div>

  @if (configs) {
    <div>
      <cs-list-filter [initialFilter]="filter"
        [loadGroupResults]="getLoadGroupResultsDelegate"
        [tenantId]="userData.tenant"
        [disableForm]="isReloading"
        [canPersistFilter]="canPersistFilter"
        [filterConfigType]="configType.singularKey + 'Filter'"
      (filterChanged)="onFilterChanged($event)"></cs-list-filter>
      <div class="filtered-list">
        <table id="configs-table" class="table configs-table last-item {{cssSanitize(configType.singularKey)}}-configs-table" (copy)="onCopy($event)">
          <thead>
            <tr>
              <th class="icon-cell"></th>
              <th>
                Name
                <a class="toggle-properties-button" href="javascript:void(0)" (click)="toggleProperties()">{{showProperties ? 'Hide' : 'Show'}} Properties</a>
              </th>
              <th>Created</th>
              <th>Modified</th>
              <th>Owner</th>
              <th class="d-flex">
                <button id="select-all-configs-button" (click)="toggleSelectAll()"
                  class="btn btn-secondary btn-icon btn-icon-only btn-sm">
                  @if (!canSelectAll) {
                    <i class="fa fa-check-square-o"></i>
                  }
                  @if (canSelectAll) {
                    <i class="fa fa-square-o"></i>
                  }
                </button>
                <button id="delete-configs-button" (click)="deleteSelected()"
                  class="btn btn-secondary btn-icon btn-sm">
                  @if (!isDeleting && !shouldUndelete) {
                    <i class="fa fa-trash-o"></i>
                  }
                  @if (!isDeleting && shouldUndelete) {
                    <i class="fa fa-undo"></i>
                  }
                  @if (isDeleting) {
                    <i class="fa fa-spinner fa-spin"></i>
                  }
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            @for (config of configs; track config.documentId; let index = $index) {
              <tr>
                <td class="icon-cell button-menu-hover-target">
                  @if(canStage || canClone || config.userId !== userData.sub || config.tenantId !== userData.tenant){
                    @if (canStage) {
                      <button class="btn btn-secondary btn-icon btn-sm stage-hover-button-{{index}} stage-hover-name-{{cssSanitize(config.name)}} stage-hover-username-{{cssSanitize(config.username)}}" (click)="stage(config)">
                        @if (!config.isStaging) {
                          <i class="fa fa-arrow-circle-up"></i>
                        }
                        @if (config.isStaging) {
                          <i class="fa fa-spinner fa-spin"></i>
                        }
                      </button>
                    }
                    @if (!canStage) {
                      <button class="btn btn-secondary btn-icon btn-sm config-hover-button-{{index}} config-hover-name-{{cssSanitize(config.name)}}">
                        <i class="fa fa-ellipsis-h"></i>
                      </button>
                    }
                    <span class="button-menu-hovering-area">
                      @if (canStage) {
                        <button class="btn btn-smart btn-secondary stage-button-{{index}} stage-name-{{cssSanitize(config.name)}} stage-username-{{cssSanitize(config.username)}}" (click)="stage(config)">
                          @if (!config.isStaging) {
                            <i class="fa fa-arrow-circle-up"></i>
                          }
                          @if (config.isStaging) {
                            <i class="fa fa-spinner fa-spin"></i>
                          }
                          Stage
                        </button>
                      }
                      @if (canStage) {
                        <button class="btn btn-smart btn-secondary compare-to-staged-button-{{index}} compare-to-staged-name-{{cssSanitize(config.name)}} compare-to-staged-username-{{cssSanitize(config.username)}}" (click)="compareToStaged(config)">
                          <i class="fa fa-files-o"></i>Compare to Staged
                        </button>
                      }
                      @if (canStage) {
                        <cs-linting-engine-dialog-launcher
                          [tenantId]="config.tenantId"
                          [userId]="config.userId"
                        [configId]="config.documentId"></cs-linting-engine-dialog-launcher>
                      }
                      @if (canClone) {
                        <cs-clone-config-launcher
                          class="clone-config-launcher-{{cssSanitize(config.name)}}"
                          [configId]="config.documentId"
                          [configTenantId]="config.tenantId"
                          [configType]="configType.singularKey"
                          [suggestedName]="config.name"
                        (copySaved)="reloadFilter()"></cs-clone-config-launcher>
                      }
                      <cs-set-ownership-dialog-launcher
                        class="set-ownership-dialog-launcher-{{cssSanitize(config.name)}}"
                        [documentTenantId]="config.tenantId"
                        [documentUserId]="config.userId"
                        [documentId]="config.documentId"
                        (ownershipChanged)="reloadFilter()"></cs-set-ownership-dialog-launcher>
                    </span>
                  }
                </td>
                <td>
                  @if(navigationUrlTree){
                    <a class="select-config-link name-{{cssSanitize(config.name)}} username-{{cssSanitize(config.username)}}"
                      [routerLink]="navigationUrlTree(config)">
                      {{renderWithCustomLineBreaks(config.name)}}
                    </a>
                  } @else if(configSelected.observed) {
                    <span class="select-config-link name-{{cssSanitize(config.name)}} username-{{cssSanitize(config.username)}}"
                      (click)="onConfigSelected(config)">
                      {{renderWithCustomLineBreaks(config.name)}}
                    </span>
                  } @else {
                    <span class="name-{{cssSanitize(config.name)}} username-{{cssSanitize(config.username)}}">
                      {{renderWithCustomLineBreaks(config.name)}}
                    </span>
                  }
                  @if (config.deleteRequested) {
                    <div class="config-study-additional-information">Delete Requested</div>
                  }
                  @if (config.parentWorksheetId) {
                    <div class="config-study-additional-information"><a [routerLink]="['/worksheets', config.tenantId, config.parentWorksheetId]">Worksheet</a></div>
                  }
                  @if (showProperties) {
                    <div [attr.data-test-id]="'name-' + cssSanitize(config.name) + '-custom-property-names'">
                      @for (property of config.properties; track property; let cpIndex = $index) {
                        <div class="custom-property custom-property-{{cpIndex}}-name">
                          <small>{{property.name}}</small>
                        </div>
                      }
                    </div>
                  }
                </td>
                <td class="custom-property-values-column">
                  <span title="{{config.creationDateRaw}}">{{config.creationDate}}</span>
                  @if (showProperties) {
                    <div [attr.data-test-id]="'name-' + cssSanitize(config.name) + '-custom-property-values'">
                      @for (property of config.properties; track property; let cpIndex = $index) {
                        <div class="custom-property-{{cpIndex}}-value">
                          <small>{{property.value}}</small>
                        </div>
                      }
                    </div>
                  }
                </td>
                <td>
                  <div class="config-modified-date-area">
                    @if (canNavigate) {
                      <a [ngClass]="{ 'sim-version-warning': config.requiresDowngrade }"
                        class="config-sim-version"
                        [routerLink]="[RELEASE_NOTES_URL]"
                        title="Saved config version. Config versions in red require downgrading for your current sim version, which may not produce valid configs.">
                        {{config.simVersion}}
                      </a>
                    }
                    @if (!canNavigate) {
                      <span [ngClass]="{ 'sim-version-warning': config.requiresDowngrade }" class="config-sim-version">{{config.simVersion}}</span>
                    }
                  </div>
                  <span title="{{config.modifiedDateRaw}}">{{config.modifiedDate}}</span>
                </td>
                <td>{{config.username}}</td>
                <td class="icon-cell">
                  @if ((config.isOwner || isTenantAdministrator) && !config.isDeleting) {
                    <input
                      class="select-item-checkbox select-name-{{cssSanitize(config.name)}} select-username-{{cssSanitize(config.username)}}"
                      [ngClass]="{ 'input-warning': !config.isOwner }"
                      id="select-{{config.documentId}}-checkbox" type="checkbox" (change)="config.isSelected = !config.isSelected" [checked]="config.isSelected">
                  }
                  @if (config.isDeleting) {
                    <i class="fa fa-spinner fa-spin"></i>
                  }
                  <!--
                  <button id="delete-{{config.documentId}}-button" (click)="delete(config)"
                    class="btn btn-secondary btn-icon btn-xsm delete-name-{{cssSanitize(config.name)}} delete-username-{{cssSanitize(config.username)}}"
                    *ngIf="config.isOwner">
                    <i class="fa fa-trash-o" *ngIf="!config.isDeleting"></i>
                    <i class="fa fa-spinner fa-spin" *ngIf="config.isDeleting"></i>
                  </button>
                  -->
                  @if (config.supportSession.session) {
                    <cs-support-session-hover-launcher
                      [tenantId]="config.tenantId" [documentId]="config.documentId"
                      [documentType]="'config'" [documentSubType]="configType"
                      [supportSession]="config.supportSession"
                    (supportSessionChanged)="config.supportSession = $event"></cs-support-session-hover-launcher>
                  }
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
      <p class="table-footer-buttons">
        <!--button *ngIf="configType.singularKey === telemetryConfigType.singularKey"
        id="convert-telemetry-button" (click)="convertSelected()"
        class="btn btn-smart btn-primary">
        Convert Telemetry
      </button-->
      @if (lastResult?.queryResults?.hasMoreResults) {
        <button
          class="btn btn-smart btn-secondary"
          [disabled]="isReloading"
          (click)="loadNext()">
          {{isReloading ? 'Loading...' : 'More...'}}
        </button>
      }
    </p>
  </div>
}
</div>

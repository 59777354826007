// https://stackoverflow.com/a/2901298
import {Pipe, PipeTransform} from '@angular/core';

export function numberWithCommas(x: number) {
  let parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

@Pipe({name: 'withCommas'})
export class NumberWithCommasPipe implements PipeTransform {
  transform(value: any): any {
    return numberWithCommas(value);
  }
}

@if (hasBeenActivated) {
  <div>
    <cs-job-selector [jobs]="jobs"
      [jobSelectorState]="jobSelectorState"
    (changed)="selectJob($event)"></cs-job-selector>
    <div class="container-fluid">
      <cs-error-message [message]="errorMessage"></cs-error-message>
      @if (job) {
        <div>
          @if (!job.jobResult.isLoaded) {
            <cs-loader id="job-result-loader"></cs-loader>
          }
          @if (job.jobResult.isLoaded && job.studyResult.isLoaded) {
            <div>
              @if (job.jobResult.value.studyJobInput) {
                <cs-view-study-sources
                  [studyResult]="job.studyResult.value"
                [jobResult]="job.jobResult.value"></cs-view-study-sources>
              }
              <cs-save-configs-with-constraint-results-buttons [job]="job"></cs-save-configs-with-constraint-results-buttons>
              @if (job.studyResult.isLoaded && job.jobResult.isLoaded) {
                <cs-save-racing-line-buttons [showAsCard]="true" [studyResult]="job.studyResult.value" [jobResult]="job.jobResult.value"></cs-save-racing-line-buttons>
              }
            </div>
          }
        </div>
      }
    </div>
  </div>
}

<div class="select-sim-version-component">
  @if (isTenantSimVersion) {
    <div class="alert alert-warning">
      Changing the tenant sim version will affect <strong>all users</strong>.
    </div>
  }

  <cs-error-message [message]="errorMessage"></cs-error-message>

  @if (!releaseNotes) {
    <cs-loader></cs-loader>
  }
  @if (releaseNotes) {
    <div class="row">
      <div class="col-12">
        <div class="sim-version-option" [ngClass]="{ 'active-sim-version': !currentSimVersion }">
          <button class="btn btn-secondary" (click)="setSimVersion(undefined)" [disabled]="isSaving">
            {{selectDefaultMessage}}
          </button>
        </div>
        @for (item of releaseNotes; track item) {
          <div class="sim-version-option" [ngClass]="{ 'active-sim-version': item.simVersion === currentSimVersion }">
            <h2>
              {{item.simVersion}} - {{item.date}}
              <button class="btn btn-secondary" (click)="setSimVersion(item.simVersion)" [disabled]="isSaving">
                {{selectReleaseMessage}}
              </button>
            </h2>
            <cs-markdown [data]="item.content"></cs-markdown>
          </div>
        }
      </div>
    </div>
  }
</div>

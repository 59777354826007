import { BootstrapBase } from '../bootstrap-base';
import { HTMLDivSelection, SVGSelection } from '../../untyped-selection';
import * as d3 from '../../d3-bundle';
import { SourceLabelsRenderer } from '../../viewers/components/source-labels-renderer';
import { SOURCE_LABELS_NAME } from '../test-area-names';
import { Disposable } from '../../disposable';
import { ChartSettings } from '../../viewers/chart-settings';
import { Size } from '../../viewers/size';
import { Margin } from '../../viewers/margin';
import { FeatureChannels } from '../../viewers/data-pipeline/types/feature-channels';

export class BootstrapSourceLabels extends BootstrapBase {

  public get name() {
    return SOURCE_LABELS_NAME;
  }

  public async load(testArea: HTMLDivSelection): Promise<Disposable | undefined> {
    await this.createSourceLabels(testArea, 4);
    await this.createSourceLabels(testArea, 1);
    await this.createSourceLabels(testArea, 0);

    await this.createSourceLabelsAndAdd(testArea, 4);
    await this.createSourceLabelsAndRemove(testArea, 4);
    await this.createSourceLabelsAndChange(testArea, 4);

    return undefined;
  }

  private async createSourceLabels(testArea: HTMLDivSelection, sourceCount: number) {

    await this.createTestItem(testArea, 'Source Labels ' + sourceCount + ' Source' + (sourceCount === 1 ? '' : 's'), 'source-labels-tests', async (svg) => {
      let { settings, value } = this.getRenderData(sourceCount, svg);

      let sourceLabels = new SourceLabelsRenderer(settings)
        .sources(d3.range(sourceCount).map(i => ({
          name: 'Source ' + (i + 1),
          isVisible: true,
          featureChannels: new FeatureChannels(undefined, undefined)
        })));

      sourceLabels.render(svg);

      this.attachToChangedEvent(value, sourceLabels, svg);
    });
  }

  private async createSourceLabelsAndAdd(testArea: HTMLDivSelection, sourceCount: number) {

    await this.createTestItem(testArea, 'Source Labels Add Sources', 'source-labels-tests', async (svg) => {
      let { settings, value } = this.getRenderData(sourceCount, svg);

      let sources = d3.range(sourceCount).map(i => ({
        name: 'Source ' + (i + 1),
        isVisible: true,
        featureChannels: new FeatureChannels(undefined, undefined)
      }));

      let sourceLabels = new SourceLabelsRenderer(settings)
        .sources(sources);

      sourceLabels.render(svg);

      sources.push({
        name: 'Source ' + (sourceCount + 1),
        isVisible: true,
        featureChannels: new FeatureChannels(undefined, undefined)
      });
      settings.chartMargin = new Margin(
        SourceLabelsRenderer.getRequiredVerticalSpace(sourceCount + 1),
        settings.legend.getRequiredHorizontalSpace(sourceCount + 1),
        settings.chartMargin.bottom, settings.chartMargin.left);
      sourceLabels.render(svg);

      this.attachToChangedEvent(value, sourceLabels, svg);
    });
  }

  private async createSourceLabelsAndRemove(testArea: HTMLDivSelection, sourceCount: number) {

    await this.createTestItem(testArea, 'Source Labels Remove Sources', 'source-labels-tests', async (svg) => {
      let { settings, value } = this.getRenderData(sourceCount, svg);

      let sources = d3.range(sourceCount).map(i => ({
        name: 'Source ' + (i + 1),
        isVisible: true,
        featureChannels: new FeatureChannels(undefined, undefined)
      }));

      let sourceLabels = new SourceLabelsRenderer(settings)
        .sources(sources);

      sourceLabels.render(svg);

      sources.splice(1, 1);
      settings.chartMargin = new Margin(
        SourceLabelsRenderer.getRequiredVerticalSpace(sourceCount - 1),
        settings.legend.getRequiredHorizontalSpace(sourceCount - 1),
        settings.chartMargin.bottom, settings.chartMargin.left);
      sourceLabels.render(svg);

      this.attachToChangedEvent(value, sourceLabels, svg);
    });
  }

  private async createSourceLabelsAndChange(testArea: HTMLDivSelection, sourceCount: number) {

    await this.createTestItem(testArea, 'Source Labels Change Sources', 'source-labels-tests', async (svg) => {
      let { settings, value } = this.getRenderData(sourceCount, svg);

      let sources = d3.range(sourceCount).map(i => ({
        name: 'Source ' + (i + 1),
        isVisible: true,
        featureChannels: new FeatureChannels(undefined, undefined)
      }));

      let sourceLabels = new SourceLabelsRenderer(settings)
        .sources(sources);

      sourceLabels.render(svg);

      sources.forEach(v => v.name = 'New ' + v.name);
      sources[1].isVisible = false;
      sources[2].isVisible = false;
      sourceLabels.render(svg);

      this.attachToChangedEvent(value, sourceLabels, svg);
    });
  }

  private attachToChangedEvent(value: any, sourceLabels: SourceLabelsRenderer, svg: SVGSelection) {
    value.text(JSON.stringify(sourceLabels.getSourceVisibility()));

    sourceLabels
      .on('changed', () => {
        value.text(JSON.stringify(sourceLabels.getSourceVisibility()));
        sourceLabels.render(svg);
      });
  }

  private getRenderData(sourceCount: number, svg: SVGSelection) {
    let requiredVerticalSpace = SourceLabelsRenderer.getRequiredVerticalSpace(sourceCount);

    let settings = ChartSettings.build(sourceCount);
    settings.svgSize = new Size(600, 300);
    settings.svgPadding = new Margin(5, 5, 5, 5);
    settings.chartMargin = new Margin(requiredVerticalSpace, settings.legend.getRequiredHorizontalSpace(sourceCount), 10, 10);

    this.RenderChartOutlines(svg, settings.svgSize, settings.svgPadding, settings.chartMargin, settings.chartSize);

    for (let i = 0; i < sourceCount; ++i) {
      svg.append('rect').attr('class', 'legend-rect')
        .attr('width', settings.legend.valueWidth)
        .attr('height', settings.chartSize.height)
        .attr('x', settings.svgPadding.left + settings.chartMargin.left + settings.chartSize.width + i * settings.legend.valueWidth)
        .attr('y', settings.svgPadding.top + settings.chartMargin.top);
    }

    let value = svg.append('text').attr('class', 'labels-value').attr('transform', 'translate(10, 20)');
    return { settings, value };
  }
}

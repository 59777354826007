import { Injectable } from '@angular/core';
import { JsonEditorCustomization } from './json-editor-customization';
import{ JSONEditor } from'@json-editor/json-editor'

@Injectable()
export class SplitIntegers2DEditor extends JsonEditorCustomization {
  public apply(): void {
    JSONEditor.defaults.editors.splitIntegers2D = class extends JSONEditor.defaults.editors.splitNumbers2D {
      getValue() {
        return super.getValue().map(function(line: number[]) {
 return line.map(function(v) {
 return Math.trunc(v);
});
});
      }
    }
  }
}

import {Component, Input} from '@angular/core';
import {ResolvedGroup} from './filter-query-builder-types';
import {GetTenantUsersQueryResult} from '../../../generated/api-stubs';

@Component({
  selector: 'cs-filter-query-renderer-group',
  templateUrl: './filter-query-renderer-group.component.html'
})
export class FilterQueryRendererGroupComponent {
  @Input() public group: ResolvedGroup;
  @Input() public tenantUsers: GetTenantUsersQueryResult;

  public get operator(): string {
    return this.group ? this.group.operator.toUpperCase() : '';
  }
}

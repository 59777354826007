<div class="container-fluid job-selector-container">
  @if (filteredJobs?.length > 1 && !isLoading) {
    <div class="job-selector">
      <h2>Job:</h2>
      @for (job of filteredJobs; track job) {
        <button
          class="btn"
          [ngClass]="{ 'btn-primary': job === selectedJob, 'btn-secondary': job !== selectedJob }"
          (click)="selectJob(job)">
          @if (showStudyName) {
            <span>{{job.studyName.value}} / </span>
            }{{job.jobName.value}}
          </button>
        }
      </div>
    }

    @if (isLoading && !errorMessage) {
      <div class="job-selector-loader">
        <cs-loader id="job-selector-loader"></cs-loader>
      </div>
    }

    @if (errorMessage) {
      <div class="job-selector-error-message">
        <cs-error-message [message]="errorMessage"></cs-error-message>
      </div>
    }
  </div>

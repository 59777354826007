import { Dimension, ParallelCoordinatesData } from './parallel-coordinates-types';
import * as d3 from '../../d3-bundle';
import { SVGSelection } from '../../untyped-selection';
import { AxisRendererUtilities } from '../multi-plot-viewer-base/axis-renderer-utilities';
import { Utilities } from '../../utilities';
import { isDefaultUnits } from '../../is-default-units';
import { SiteHooks } from '../../site-hooks';
import { ParallelCoordinatesViewerSettings } from './parallel-coordinates-viewer-settings';
import { DraggedElementBaseType } from 'd3-drag';
import { MinMax } from '../min-max';
import { arraysAreEqual } from '../../arrays-are-equal';

const MEAN_PIXEL_SPACING_BETWEEN_TICKS = 10;
export const AXIS_REVERSAL_TRANSITION_TIME = 300;
const AXIS_MOVE_TRANSITION_TIME = 300;

// The brush offsets for the input and output dimensions. Input dimension brushes sit to the right of the axis so they don't
// interfere with the dimension slider drag handles.
const inputBrushOffsetX = 1;
const outputBrushOffsetX = -8;
const brushWidth = 16;

/**
 * Renders the axes for the parallel coordinates viewer.
 */
export class ParallelCoordinatesAxisRenderer {

  /**
   * The D3 style events.
   */
  private readonly listeners = d3.dispatch('dragging', 'reordered', 'reversed', 'filtered', 'zoomed');

  /**
   * The data for the parallel coordinates viewer.
   */
  private data?: ParallelCoordinatesData;

  /**
   * True while an axis dragging operation is in progress.
   */
  private isDragging: boolean = false;

  /**
   * True if we have not previously completed a render.
   */
  private isFirstRender: boolean = true;

  /**
   * Create a new instance of the parallel coordinates axis renderer.
   * @param settings The settings for the parallel coordinates viewer.
   * @param siteHooks The site hooks.
   */
  constructor(
    private readonly settings: ParallelCoordinatesViewerSettings,
    private readonly siteHooks: SiteHooks) {
  }

  /**
   * Set the data for the parallel coordinates viewer.
   * @param data The data for the parallel coordinates viewer.
   */
  public setData(data: ParallelCoordinatesData) {
    this.data = data;
  }

  /**
   * Render the axes for the parallel coordinates viewer.
   * @param svg The SVG selection to render the axes to.
   */
  public render(svg: SVGSelection) {
    if (!this.data) {
      return;
    }

    // Create the container for the axis renderer.
    const containerClassName = 'axis-renderer';
    const containerUpdate = svg.selectAll<SVGGElement, unknown>('.' + containerClassName).data([null]);
    const containerEnter = containerUpdate.enter()
      .append<SVGGElement>('g').attr('class', containerClassName)
      .attr('transform', `translate(${this.settings.svgPadding.left},${this.settings.svgPadding.top})`);
    const container = containerEnter.merge(containerUpdate);

    // Create a container for each dimension.
    const dimensionUpdate = container.selectAll<SVGGElement, Dimension>('.dimension').data(this.data.dimensionList as Dimension[], (d: Dimension) => d.id);
    dimensionUpdate.exit().remove();
    const dimensionEnter = dimensionUpdate.enter().append<SVGGElement>('g')
      .classed('dimension', true)
      .classed('output-dimension', d => !d.isInputDimension)
      .classed('input-dimension', d => d.isInputDimension)
      .attr('id', d => `${d.id}-dimension`);
    const dimension = dimensionUpdate.merge(dimensionEnter);

    // Translate each axis to its render position. If the axis is being dragged, or it is the first render, do not transition.
    (this.isDragging || this.isFirstRender ? dimension : dimension.transition().duration(AXIS_MOVE_TRANSITION_TIME))
      .attr('transform', d => `translate(${d.renderPosition})`);

    this.renderAxis(svg, dimensionEnter, dimension);
    this.renderAxisLabel(svg, dimensionEnter, dimension);
    this.renderAxisFlipButton(dimensionEnter, dimension);
    this.renderAxisFilterBrush(dimensionEnter, dimension);
    this.renderAxisZoomButton(dimensionEnter, dimension);

    // Set isFirstRender to false after the first render.
    this.isFirstRender = false;
  }

  /**
   * Render each vertical D3 axis.
   * @param svg The SVG selection.
   * @param dimensionEnter The enter selection for the dimension.
   * @param dimension The update selection for the dimension.
   */
  private renderAxis(svg: SVGSelection, dimensionEnter: d3.Selection<SVGGElement, Dimension, SVGGElement, any>, dimension: d3.Selection<SVGGElement, Dimension, SVGGElement, any>) {
    const self = this;

    // Create the axis.
    dimensionEnter.append('g').attr('class', 'axis');
    dimension.select('.axis').each(function(d: Dimension) {
      const axis = d3.axisLeft(d.scale);
      AxisRendererUtilities.formatAxis(axis, d.plot.column, MEAN_PIXEL_SPACING_BETWEEN_TICKS);

      d3.select(this).transition().duration(AXIS_REVERSAL_TRANSITION_TIME).on('end', () => {
        d.isAxisReversing = false;
        d.isAxisZooming = false;
      }).call(axis as any);
    });

    // Color the axis and its ticks.
    dimension.select('.axis path')
      .attr('stroke', d => this.getColor(d));
    dimension.each(function(d) {
      d3.select(this).selectAll('.axis line')
        .attr('stroke', () => self.getColor(d));
      d3.select(this).selectAll('.axis text')
        .attr('fill', () => self.getColor(d));
    });
  }

  /**
   * Render the axis labels.
   * @param svg The SVG selection.
   * @param dimensionEnter The enter selection for the dimension.
   * @param dimension The update selection for the dimension.
   */
  private renderAxisLabel(svg: SVGSelection, dimensionEnter: d3.Selection<SVGGElement, Dimension, SVGGElement, any>, dimension: d3.Selection<SVGGElement, Dimension, SVGGElement, any>) {
    if (!this.data) {
      return;
    }

    // Function which returns the drag offset from the start position for a given drag event.
    let getDragOffset = (event: d3.D3DragEvent<DraggedElementBaseType, Dimension, DragInformation>) => {
      let subject = event.subject;
      return <number>d3.pointer(event, <d3.ContainerElement>svg.node())[0] - subject.initialMousePosition;
    };

    // Enable dragging the axis label to reorder the dimensions.
    let drag = d3.drag<SVGTextElement, Dimension, DragInformation>()
      .subject((event, d: Dimension): DragInformation => {
        const initialPosition = <number>d3.pointer(event, <d3.ContainerElement>svg.node())[0];
        return new DragInformation(d, initialPosition);
      })
      .on('start', (event: d3.D3DragEvent<DraggedElementBaseType, Dimension, DragInformation>, d: Dimension) => {
        this.isDragging = true;
        d.dragOffset = getDragOffset(event);
        this.listeners.call('dragging');
      })
      .on('drag', (event: d3.D3DragEvent<DraggedElementBaseType, Dimension, DragInformation>, d: Dimension) => {
        d.dragOffset = getDragOffset(event);
        this.listeners.call('dragging');
      })
      .on('end', (_, d: Dimension) => {
        this.isDragging = false;
        let reorderedDimensions = [...d.allDimensions].sort((a, b) => a.renderPosition - b.renderPosition);
        d.dragOffset = 0;
        this.listeners.call('reordered', null,  reorderedDimensions.map(v => v.plot.column));
      });

    // Add the axis labels at an angle, to maximize how much text we can display.
    // Also, add a click event to allow the user to edit the units.
    dimensionEnter.append<SVGTextElement>('text').attr('class', 'axis-label')
      .style('text-anchor', 'left')
      .attr('transform', 'rotate(-8)')
      .attr('y', -2)
      .attr('fill', d => this.getColor(d))
      .on('click', (_, d) => this.siteHooks.editChannelUnits(d.channel.genericName, d.channel.units))
      .call(drag);
    dimension.select('.axis-label')
      .text(d => Utilities.condenseDimension(d.channel.name) + (isDefaultUnits(d.channel.units) ? '' : ' (' + d.channel.units + ')'));
  }

  /**
   * Render the axis flip button.
   * @param dimensionEnter The enter selection for the dimension.
   * @param dimension The update selection for the dimension.
   */
  private renderAxisFlipButton(dimensionEnter: d3.Selection<SVGGElement, Dimension, SVGGElement, any>, dimension: d3.Selection<SVGGElement, Dimension, SVGGElement, any>) {
    // Add the flip button and handle the click event to reverse the axis.
    dimensionEnter.append('g')
      .attr('class', 'flip-switch')
      .attr('id', d => `${d.id}-flipper`)
      .on('click', (e, d) => {
        e.stopPropagation();
        d.channel.unprocessed.reverseAxis = !d.channel.unprocessed.reverseAxis;
        d.isAxisReversing = true;
        this.listeners.call('reversed');
      })
      .append('text')
      .attr('class', 'flip-switch-icon');

    // But the button at the bottom of the axis.
    dimension.select('.flip-switch')
      .attr('transform', `translate(0, ${this.axisButtonY})`);

    // Set the correct icon, depending on the axis' current reversed status.
    dimension.select('.flip-switch-icon')
      .text((d) => d.channel.reverseAxis ? '\uf162' : '\uf163');
  }

  /**
   * Render the axis zoom button.
   * @param dimensionEnter The enter selection for the dimension.
   * @param dimension The update selection for the dimension.
   */
  private renderAxisZoomButton(dimensionEnter: d3.Selection<SVGGElement, Dimension, SVGGElement, any>, dimension: d3.Selection<SVGGElement, Dimension, SVGGElement, any>) {
    // Create the button and handle the click event to zoom to the filter.
    dimensionEnter.append('g')
      .attr('class', 'zoom-button disabled-zoom-button')
      .attr('id', d => `${d.id}-zoom-button`)
      .on('click', (e, d) => {
        e.stopPropagation();
        d.zoomToFilter();
        d.isAxisZooming = true;
        this.listeners.call('zoomed');
      })
      .append('text')
      .attr('class', 'zoom-button-icon');

    // Only show the button if the axis has a filter, or the user has zoomed to a filter.
    // Position the button at the bottom of the axis, to the left of the axis flip button.
    dimension.select('.zoom-button')
      .attr('class', d => !d.zoomDomain && !d.filter ? 'zoom-button disabled-zoom-button' : 'zoom-button')
      .attr('transform', `translate(-15, ${this.axisButtonY})`);

    // Set the correct icon, depending on the axis' current zoom status.
    dimension.select('.zoom-button-icon')
      .text((d) => d.filter && !d.isZoomedToFilter ? '\uf00e' : '\uf010');
  }

  /**
   * Render the axis filter brush.
   * @param dimensionEnter The enter selection for the dimension.
   * @param dimension The update selection for the dimension.
   */
  private renderAxisFilterBrush(dimensionEnter: d3.Selection<SVGGElement, Dimension, SVGGElement, any>, dimension: d3.Selection<SVGGElement, Dimension, SVGGElement, any>) {

    // This handler is called during brushing, and when the brush event ends.
    let brushHandler = (d: Dimension, brushEvent: d3.D3BrushEvent<Dimension>) => {
      if (d.isAxisReversing || d.isAxisZooming) {
        return;
      }

      if (brushEvent.sourceEvent && brushEvent.sourceEvent.stopPropagation) {
        brushEvent.sourceEvent.stopPropagation();
      }

      let selection = brushEvent.selection as [number, number];

      // Update the filter on the dimension to match the current brush selection.
      if (selection) {
        let extent = d3.extentStrict(selection.map(v => d.scale.invert(v)));
        d.filter = new MinMax(extent[0], extent[1]);
      } else {
        d.filter = undefined;
      }

      this.listeners.call('filtered');
    };

    // Create the D3 brush. If the brush is an input dimension, offset it to the right so that it doesn't interfere with the dimension slider drag handles.
    let brushY = d3.brushY<Dimension>()
      .extent(d => [[d.isInputDimension ? inputBrushOffsetX : outputBrushOffsetX, d3.minStrict(d.scale.range())], [brushWidth + (d.isInputDimension ? inputBrushOffsetX : outputBrushOffsetX), d3.maxStrict(d.scale.range())]])
      //.y(self.y[d])
      .on('start', (e, d) => {
        // Set isBrushing to true during a brush operation.
        d.isBrushing = true;
        if (e && e.stopPropagation) {
          e.stopPropagation();
        }
      })
      .on('brush', (e, d) => {
        brushHandler(d, e);
      })
      .on('end', (e, d) => {
        brushHandler(d, e);
        // Set isBrushing to false when the brushing operation finishes.
        d.isBrushing = false;
      });

    // Create a brush group for each dimension.
    dimensionEnter.append<SVGGElement>('g')
      .attr('class', 'brush')
      .attr('id', d => `${d.id}-brush`)
      .on('click mousemove', currentEvent => {
        // This stops "view job" action from occurring when the user just wanted to
        // clear the brush.
        currentEvent.stopPropagation();
      });

    // Apply the brush to each dimension.
    dimension.select<SVGGElement>('.brush')
      .call(brushY)
      .each(function(d) {
        if (d.isBrushing) {
          return;
        }

        let current = d3.select(this);
        let currentSelection = d3.brushSelection(current.node() as SVGGElement) as [number, number] | null;
        let desiredSelection: [number, number] | undefined;
        let filter = d.filter;
        if (filter) {
          // If there is currently a filter on the dimension, use it to get the desired brush selection.
          desiredSelection = d3.extentStrict([d.scale(filter.minimum), d.scale(filter.maximum)]);
        }

        // If the selection has changed...
        if (!arraysAreEqual(currentSelection, desiredSelection, (a, b) => a === b)) {
          if (desiredSelection) {
            // If the desired selection is not null, update the brush to match it.
            d3.select(this).transition().duration(AXIS_REVERSAL_TRANSITION_TIME).call(brushY.move as any, desiredSelection);
          } else {
            // If the desired selection is null, clear the brush.
            d3.select(this).call(brushY.move as any, null);
          }
        }
      });

    // Add the "clear brush" button.
    dimensionEnter.append<SVGGElement>('g')
      .attr('class', 'clear-brush-button disabled-clear-brush-button')
      .attr('id', d => `${d.id}-clear-brush-button`)
      .on('click', (e, d) => {
        e.stopPropagation();
        d3.select<SVGGElement, Dimension>(`#${d.id}-brush`).call(brushY.move, null);
      })
      .append('text')
      .attr('class', 'clear-brush-button-icon');

    // Position the "clear brush" button. If the brush is an input dimension, offset it to the right so that it doesn't interfere with the dimension slider drag handles.
    dimension.select('.clear-brush-button')
      .attr('class', d => !d.filter ? 'clear-brush-button disabled-clear-brush-button' : 'clear-brush-button')
      .each(function(d) {
        (d.isAxisReversing || d.isAxisZooming ? d3.select(this).transition().duration(AXIS_REVERSAL_TRANSITION_TIME) : d3.select(this))
          .attr('transform', () => `translate(${brushWidth + (d.isInputDimension ? inputBrushOffsetX : outputBrushOffsetX) + 2}, ${d.filter ? d3.extentStrict([d.scale(d.filter.minimum), d.scale(d.filter.maximum)])[1] - 2 : 0})`);
      });

    // Set the icon for the "clear brush" button.
    dimension.select('.clear-brush-button-icon')
      .text(() => '\uf00d');
  }

  /**
   * Get the Y position of the axis buttons.
   */
  private get axisButtonY(): number {
    return this.settings.chartSize.height + 17;
  }

  /**
   * Get the color to render the axis. Input axis are black, output axes are colored.
   * @param d The dimension.
   */
  private getColor(d: Dimension): string {
    return d.isInputDimension ? '#000' : this.settings.getChannelColor(d.outputDimensionIndex, 0);
  }

  /**
   * Subscribe to an event.
   * @param typenames The event name.
   * @param callback The callback to invoke when the event is triggered.
   */
  public on(typenames: string, callback: (this: object, ...args: any[]) => void): this {
    this.listeners.on(typenames, callback);
    return this;
  }
}

/**
 * Information about a dragging operation.
 */
class DragInformation {

  /**
   * Create a new instance of the drag information.
   * @param dimension The dimension being dragged.
   * @param initialMousePosition The initial mouse position.
   */
  constructor(
    public readonly dimension: Dimension,
    public readonly initialMousePosition: number) {
  }
}

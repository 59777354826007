import {Injectable} from '@angular/core';

@Injectable()
export class FixQueryStringUrl {
  public execute(url: string) {
    // Having a final & on a query string can trip up ASP.NET
    if (url.slice(-1) === '&') {
      return url.substring(0, url.length - 1);
    }

    return url;
  }
}

@if (showLauncher) {
  <span class="support-session-hover-launcher">
    <span class="support-hover-target">
      @if (supportSession.session) {
        <button class="btn btn-icon btn-xsm" aria-hidden="true"
          [ngClass]="{ 'support-open': supportSession.session && supportSession.session.isOpen, 'support-closed': supportSession.session && !supportSession.session.isOpen }"
          >
          <i class="fa fa-medkit"></i>
        </button>
      }
      <cs-support-session-launcher
        class="support-session-launcher-component"
        [tenantId]="tenantId" [documentId]="documentId"
        [documentType]="documentType" [documentSubType]="documentSubType"
        [supportSession]="supportSession"
      (supportSessionChanged)="emit($event)"></cs-support-session-launcher>
    </span>
  </span>
}

import * as d3octree from 'd3-octree';
import * as d3 from '../../d3-bundle';
import { OrderedUniqueIndices } from '../../ordered-unique-indices';
import { Utilities } from '../../utilities';
import { Vector2, Vector3 } from 'three';
import { TrackCoordinate } from '../3d/track-coordinate';
import { DisplayableError } from '../../displayable-error';
import { TrackData } from './track-data';
import { TrackPath } from './track-path';
import { isNumber } from '../../is-number';
import { isUndefined } from '../../is-defined';

/**
 * An interface for track data that is not yet fully processed.
 */
interface IntermediateTrackData {
  xLeft?: number[];
  yLeft?: number[];
  zLeft?: number[];
  xRight?: number[];
  yRight?: number[];
  zRight?: number[];
  xCentreLine?: number[];
  yCentreLine?: number[];
  zCentreLine?: number[];
  xCar?: number[];
  yCar?: number[];
  zCar?: number[];
  sLap?: number[];
}

/**
 * A delegate which produces a 3D vector with all components set to zero.
 * @returns A new vector with all components set to zero.
 */
export const CreateZeroVector3 = () => new Vector3(0, 0, 0);

const MINIMUM_CROSS_PRODUCT_ANGLE = Math.PI / 8;
const MINIMUM_EDGE_DISTANCE_TO_REFERENCE = 0.1;

// This is just used to force a different TrackSource value for each source index, which has been useful for debugging.
// Normally we just use "auto".
export const ALTERNATE_SOURCE_FOR_DEBUGGING = false; // DO NOT COMMIT WITH THIS SET TO TRUE.

/**
 * What part of the config to use to render the track.
 */
export enum TrackSource {

  /**
   * Automatically decide which part of the track config to use for rendering.
   */
  auto,

  /**
   * Use the track outline.
   */
  outline,

  /**
   * Use the track centre line definition.
   */
  centreLine,

  /**
   * Use the track racing line definition.
   */
  racingLine,
}

/**
 * Which vertex relative to a current position to use when calculating a reference vector.
 */
enum ReferenceLocation {
  OuterNext,
  OuterPrevious,
  InnerNext,
  InnerPrevious,
}

/**
 * A class for extracting the track data.
 */
export class ExtractTrackData {

  /**
   * Extracts the track data from the given track config.
   * @param track The track config.
   * @param source Where to extract the track data from.
   * @returns The extracted track data.
   */
  public execute(track: any, source: TrackSource = TrackSource.auto): TrackData {

    // We start by extracting the outline data, if it exists.
    let outline: IntermediateTrackData | undefined = this.extractFromOutline(track);
    let hasOutline = !isUndefined(outline);

    // Set data to either the outline or an empty object.
    let data: IntermediateTrackData = outline || {};

    // Get a valid set of left and right outline coordinates.
    let outlineLeftCoordinates = this.getValidatedTrackCoordinates('left', data.xLeft, data.yLeft, data.zLeft);
    let outlineRightCoordinates = this.getValidatedTrackCoordinates('right', data.xRight, data.yRight, data.zRight);

    // If we're not only using the outline...
    if (source !== TrackSource.outline) {
      switch (source) {
        case TrackSource.centreLine:
          // Apply the centre line data.
          this.applyFromCentreLine(track, data);
          break;

        case TrackSource.racingLine:
          // Apply the racing line data
          this.applyFromRacingLine(track, data);
          break;

        default:
          // Try and apply the centre line data.
          if (!this.applyFromCentreLine(track, data) && !hasOutline) {
            // We get here if there was no outline and no centre line.
            // Simulation outputs have centreLine definitions, so we won't reach here.
            // Configs should only render their racing line if the outline is missing.
            // https://app.slack.com/team/UBCCYAV8A
            this.applyFromRacingLine(track, data);
          }
          break;
      }
    }

    //// Uncomment if you want to use edge as missing racing line.
    /*
    if(!data.xCar || !data.xCar.length){
      data.xCar = data.xRight;
      data.yCar = data.yRight;
      data.zCar = data.zRight;
    }
    */

    // Get whether the track should be rendered as periodic (i.e. the start and end are connected).
    let isPeriodic = this.isTrackPeriodic(track);

    // Get validated coordinates from the data so far.
    let leftCoordinates = this.getValidatedTrackCoordinates('inner', data.xLeft, data.yLeft, data.zLeft);
    let rightCoordinates = this.getValidatedTrackCoordinates('outer', data.xRight, data.yRight, data.zRight);
    let centreLineCoordinates = this.getValidatedTrackCoordinates('centreLine', data.xCentreLine, data.yCentreLine, data.zCentreLine);
    let carCoordinates = this.getValidatedTrackCoordinates('car', data.xCar, data.yCar, data.zCar);

    // Next we decide which is the inner and which is the outer edge of the track, and whether the track
    // is a figure of eight.
    // I think it isn't hard to think of a track that breaks the figure of eight detection, so we could
    // improve this at some point.
    let isFigureOfEight = false;

    // Assume the right edge is the outer edge.
    let outlineOuterCoordinates = outlineRightCoordinates;
    let outlineInnerCoordinates = outlineLeftCoordinates;
    let outerCoordinates = rightCoordinates;
    let innerCoordinates = leftCoordinates;

    // If the left most point of the left coordinates is less than the left most point of the right coordinates...
    if (d3.minStrict(leftCoordinates.map(e => e.x)) < d3.minStrict(rightCoordinates.map(e => e.x))) {

      // Then the left edge must be the outer edge.
      outlineOuterCoordinates = outlineLeftCoordinates;
      outlineInnerCoordinates = outlineRightCoordinates;
      outerCoordinates = leftCoordinates;
      innerCoordinates = rightCoordinates;

      // If the right most point of the left coordinates is less than the right most point of the right coordinates...
      if (d3.maxStrict(leftCoordinates.map(e => e.x)) < d3.maxStrict(rightCoordinates.map(e => e.x))) {
        // Then we must have a figure of eight track.
        isFigureOfEight = true;
      }

    } else if (d3.maxStrict(leftCoordinates.map(e => e.x)) > d3.maxStrict(rightCoordinates.map(e => e.x))) {
      // If the right most point of the left coordinates is greater than the right most point of the right coordinates
      // while the left most point of the left coordinates is less than the left most point of the right coordinates,
      // then we must have a figure of eight track.
      isFigureOfEight = true;
    }

    // Clean up car coordinates, just in case of NaNs or repeats.
    let carIndicesToRemove = new OrderedUniqueIndices();
    // For each coordinate from the first to the second last...
    for (let i = 0; i < carCoordinates.length - 1; i++) {
      // ... if either the x or y coordinate are NaN, or if the x and y coordinates are the same as the next
      // x and y coordinates...
      if (isNaN(carCoordinates[i].x) || isNaN(carCoordinates[i].y)
        || (carCoordinates[i].x === carCoordinates[i + 1].x && carCoordinates[i].y === carCoordinates[i + 1].y)) {
        // ... add the index to the list of indices to remove.
        carIndicesToRemove.add(i);
      }
    }
    // Remove the coordinates we found to remove.
    Utilities.strip(carIndicesToRemove, carCoordinates);

    // If the track is periodic, wrap the coordinates by appending the first coordinate to the end of the list.
    if (isPeriodic) {
      this.wrapCoordinates(outlineInnerCoordinates);
      this.wrapCoordinates(outlineOuterCoordinates);
      this.wrapCoordinates(innerCoordinates);
      this.wrapCoordinates(outerCoordinates);
      this.wrapCoordinates(centreLineCoordinates);
      this.wrapCoordinates(carCoordinates);
    }

    // If the car coordinates have no elevation data, generate the elevation data from the track outline.
    if (carCoordinates.every(v => v.z === 0)) {
      this.applyTrackElevation(outlineInnerCoordinates, outlineOuterCoordinates, innerCoordinates, outerCoordinates, centreLineCoordinates, carCoordinates);
    }

    // Compute distance along the racing line from car positions.
    let sLapCoordinates = carCoordinates.length ? carCoordinates : outerCoordinates;

    // Compute the distance vector from sLap.
    let sLap = this.computeDistanceVector(sLapCoordinates);

    // If we have sLapCentreLine, then compute the distance vector for that as well.
    let sLapCentreLine: number[] = [];
    if (centreLineCoordinates.length) {
      sLapCentreLine = this.computeDistanceVector(centreLineCoordinates);
    }

    // Calculate the normals to the track at each car position.
    let carNormals = this.findCarNormals(innerCoordinates, outerCoordinates, carCoordinates);

    // Trim the car coordinates and normals so the length matches the racing line distance mapped
    // to the sLap channel.
    let sLapVisibleUntil: number | undefined =
      track.racingLine && track.racingLine.sLap
      ? track.racingLine.sLap[track.racingLine.sLap.length - 1]
      : undefined;
    if (sLapVisibleUntil) {
      let visibleUntilIndex = sLap.findIndex(v => v > sLapVisibleUntil);
      if (visibleUntilIndex !== -1) {
        carCoordinates = carCoordinates.slice(0, visibleUntilIndex);
        carNormals = carNormals.slice(0, visibleUntilIndex);
      }
    }

    // Return the track data.
    return new TrackData(
      new TrackPath(innerCoordinates),
      new TrackPath(outerCoordinates),
      new TrackPath(centreLineCoordinates),
      new TrackPath(carCoordinates),
      new TrackPath(carNormals),
      sLap,
      sLapCentreLine,
      isFigureOfEight,
      this.getStartFinishOffset(track));
  }

  /**
   * Extracts the start/finish offset from the given track config.
   * @param track The track config.
   * @returns The start/finish offset.
   */
  private getStartFinishOffset(track: any): number {
    let result: number = track.startFinishOffset ? +track.startFinishOffset : 0;
    return isNaN(result) ? 0 : result;
  }

  /**
   * Computes the distance vector from the given coordinates.
   * Note that for periodic tracks we have already ensured the final coordinate is the same as the first.
   * @param coordinates The coordinates to compute the distance vector from.
   * @returns The distance vector.
   */
  private computeDistanceVector(coordinates: ReadonlyArray<TrackCoordinate>) {
    let result = [0];
    for (let i = 0; i < coordinates.length - 1; i++) {
      let sLapDiff = Math.sqrt(
        Math.pow(coordinates[i + 1].x - coordinates[i].x, 2) +
        Math.pow(coordinates[i + 1].y - coordinates[i].y, 2) +
        Math.pow(coordinates[i + 1].z - coordinates[i].z, 2));
      result.push(result[i] + sLapDiff);
    }
    return result;
  }

  /**
   * Finds the normal to the track at each car position.
   * @param innerCoordinates The inner track coordinates.
   * @param outerCoordinates The outer track coordinates.
   * @param carCoordinates The car coordinates.
   * @returns The normals to the track at each car position.
   */
  private findCarNormals(innerCoordinates: ReadonlyArray<TrackCoordinate>, outerCoordinates: ReadonlyArray<TrackCoordinate>, carCoordinates: ReadonlyArray<TrackCoordinate>): TrackCoordinate[] {
    if (!carCoordinates.length) {
      return [];
    }

    // Start off with all the normals pointing vertically up.
    let normals = new Array(carCoordinates.length).fill(new TrackCoordinate(0, 0, 1));

    // Create octrees for the inner and outer coordinates, for fast searching.
    let innerOctree = this.createOctree(innerCoordinates);
    let outerOctree = this.createOctree(outerCoordinates);

    // We'll only calculate the normal every Nth car position, and interpolate between them.
    let calculateEvery = 10;

    // For each Nth car position...
    for (let carIndex = 0; carIndex < carCoordinates.length; carIndex += calculateEvery) {
      let carCoordinate = carCoordinates[carIndex];
      // let innerNearest = this.findNearestCoordinate(carCoordinate, innerCoordinates, innerIndex, false);
      // let outerNearest = this.findNearestCoordinate(carCoordinate, outerCoordinates, outerIndex, false);

      // Find the nearest inner and outer track coordinates to the car position.
      let innerNearest = innerOctree.find(carCoordinate.x, carCoordinate.y, carCoordinate.z);
      let outerNearest = outerOctree.find(carCoordinate.x, carCoordinate.y, carCoordinate.z);

      // If there are no nearest coordinates, then just skip this car position.
      if (!innerNearest || !outerNearest) {
        continue;
      }

      // Get the distance in 3D space between the car coordinate and the nearest inner and outer track coordinates.
      let innerDistance = this.getDistance3D(innerNearest.coordinate, carCoordinate);
      let outerDistance = this.getDistance3D(outerNearest.coordinate, carCoordinate);

      // Package up the data.
      let innerNearestResult = new NearestCoordinateResult(innerNearest.coordinate, innerNearest.index, innerDistance);
      let outerNearestResult = new NearestCoordinateResult(outerNearest.coordinate, outerNearest.index, outerDistance);

      // Calculate the vector for the normal.
      //let cross = this.calculateNormalUsingCarCoordinate(carIndex, carCoordinates, innerNearest, outerNearest);
      let cross = this.calculateNormalUsingTrackCoordinates(innerNearestResult, innerCoordinates, outerNearestResult, outerCoordinates);

      if (cross.equals(CreateZeroVector3())) {
        continue;
      }

      let normal = new TrackCoordinate(cross.x, cross.y, cross.z);

      // Set the normal for the next N car positions (as we only calculate the normal for every Nth position).
      for (let subIndex = 0; subIndex < calculateEvery && carIndex + subIndex < carCoordinates.length; ++subIndex) {
        normals[carIndex + subIndex] = normal;
      }

      // If we're not at the start of the car coordinates...
      if (carIndex >= calculateEvery) {
        // Interpolate between the current normal and the previous normal.
        for (let subIndex = 1; subIndex < calculateEvery; ++subIndex) {
          let aWeight = subIndex / calculateEvery;
          let bWeight = 1 - aWeight;
          let a = normals[carIndex];
          let b = normals[carIndex - calculateEvery];
          normals[carIndex - calculateEvery + subIndex] =
            new TrackCoordinate(
              a.x * aWeight + b.x * bWeight,
              a.y * aWeight + b.y * bWeight,
              a.z * aWeight + b.z * bWeight);
        }
      }
    }

    return normals;
  }

  /**
   * Returns the average vector from a slice of a list of vectors.
   * @param list The list of vectors.
   * @param startIndexInclusive The inclusive start index.
   * @param endIndexInclusive The inclusive end index.
   * @returns The average vector.
   */
  private getAverageVector(list: ReadonlyArray<TrackCoordinate>, startIndexInclusive: number, endIndexInclusive: number): Vector3 {

    // Ensure the indices are in the correct order.
    let minIndex = Math.min(startIndexInclusive, endIndexInclusive);
    let maxIndex = Math.max(startIndexInclusive, endIndexInclusive);

    // Sum all the vectors...
    let result = CreateZeroVector3();
    let count = 0;
    for (let i = minIndex; i <= maxIndex; ++i) {
      if (i >= 0 && i < list.length) {
        result.add(list[i].asVector3());
        ++count;
      }
    }

    if (count === 0) {
      return CreateZeroVector3();
    }

    // ...and divide by the number of vectors to get the average.
    result.divideScalar(count);
    return result;
  }

  /**
   * Given the nearest inner and outer coordinates, and the full set of inner and outer coordinates, find
   * the relative coordinate given by the specified reference location.
   * @param innerNearest The nearest inner coordinate.
   * @param innerCoordinates The set of inner coordinates.
   * @param outerNearest The nearest outer coordinate.
   * @param outerCoordinates The set of outer coordinates.
   * @param location The reference location.
   * @returns The reference coordinate.
   */
  private getReferenceCoordinate(
    innerNearest: NearestCoordinateResult,
    innerCoordinates: ReadonlyArray<TrackCoordinate>,
    outerNearest: NearestCoordinateResult,
    outerCoordinates: ReadonlyArray<TrackCoordinate>,
    location: ReferenceLocation): Vector3 {

    // We average over a few coordinates to get a more stable vector.
    const numberOfVectorsToAverage: number = 4;

    switch (location) {
      case ReferenceLocation.OuterNext:
        return this.getAverageVector(
          outerCoordinates,
          outerNearest.index + 1,
          outerNearest.index + numberOfVectorsToAverage);

      case ReferenceLocation.OuterPrevious:
        return this.getAverageVector(
          outerCoordinates,
          outerNearest.index - 1,
          outerNearest.index - numberOfVectorsToAverage);

      case ReferenceLocation.InnerNext:
        return this.getAverageVector(
          innerCoordinates,
          innerNearest.index + 1,
          innerNearest.index + numberOfVectorsToAverage);

      case ReferenceLocation.InnerPrevious:
        return this.getAverageVector(
          innerCoordinates,
          innerNearest.index - 1,
          innerNearest.index - numberOfVectorsToAverage);
    }

    return CreateZeroVector3();
  }

  /**
   * Given the inner and outer coordinates, returns the normal to the track.
   * @param innerNearest The inner nearest coordinate.
   * @param innerCoordinates The set of inner coordinates.
   * @param outerNearest The outer nearest coordinate.
   * @param outerCoordinates The set of outer coordinates.
   * @returns The normal to the track.
   */
  public calculateNormalUsingTrackCoordinates(
    innerNearest: NearestCoordinateResult,
    innerCoordinates: ReadonlyArray<TrackCoordinate>,
    outerNearest: NearestCoordinateResult,
    outerCoordinates: ReadonlyArray<TrackCoordinate>): Vector3 {

    let edge1 = new Vector3(innerNearest.coordinate.x, innerNearest.coordinate.y, innerNearest.coordinate.z);
    let edge2 = new Vector3(outerNearest.coordinate.x, outerNearest.coordinate.y, outerNearest.coordinate.z);

    if (edge1.equals(edge2)) {
      // If the two edges are the same, then we can't calculate a normal.
      return CreateZeroVector3();
    }

    // We will calculate the normal using the cross product of the vectors from the edge to the reference.
    let referenceLocations = [
      ReferenceLocation.OuterNext,
      ReferenceLocation.OuterPrevious,
      ReferenceLocation.InnerNext,
      ReferenceLocation.InnerPrevious,
    ];

    let validNormals: Vector3[] = [];

    // For each reference location...
    for (let location of referenceLocations) {
      let cross = CreateZeroVector3();

      // Calculate the reference coordinate.
      let reference = this.getReferenceCoordinate(innerNearest, innerCoordinates, outerNearest, outerCoordinates, location);

      // Create a vector from the reference coordinate to the first edge position.
      let v1 = new Vector3().subVectors(edge1, reference);

      // Create a vector from the reference coordinate to the second edge position.
      let v2 = new Vector3().subVectors(edge2, reference);

      // Calculate the angle between the two vectors.
      let angle = Math.abs(v1.angleTo(v2));
      if (angle < MINIMUM_CROSS_PRODUCT_ANGLE
        || Math.abs(angle - Math.PI) < MINIMUM_CROSS_PRODUCT_ANGLE
        || edge1.distanceTo(reference) < MINIMUM_EDGE_DISTANCE_TO_REFERENCE
        || edge2.distanceTo(reference) < MINIMUM_EDGE_DISTANCE_TO_REFERENCE) {
        // If the angle is too small, or the distance to the reference is too small, then we can't calculate a normal without
        // risking a noisy result, so skip this reference location.
        continue;
      } else {
        // Calculate the cross product to get the (not normalized) normal.
        cross = new Vector3().crossVectors(v1, v2);
        if (cross.z < 0) {  // In track coordinates we are z +ve downwards, so we want the negative z normal.
          cross = new Vector3().crossVectors(v2, v1);
        }
      }

      // If the normal is not zero, normalize it and add it to the list of valid normals.
      if (!cross.equals(CreateZeroVector3())) {
        cross.normalize();
        validNormals.push(cross);
      }
    }

    // If we have no valid normals, return a zero vector.
    if (validNormals.length === 0) {
      return CreateZeroVector3();
    }

    // Sum all the valid normals.
    let result = CreateZeroVector3();
    for (let normal of validNormals) {
      result.add(normal);
    }

    // Normalize the vector to create the average normal.
    result.normalize();

    return result;
  }

  /**
   * Given the nearest inner and outer coordinates, and the car coordinates, returns the normal to the track.
   * @param carIndex The index of the car coordinate.
   * @param carCoordinates The set of car coordinates.
   * @param innerNearest The inner nearest coordinate.
   * @param outerNearest The outer nearest coordinate.
   * @returns The normal to the track.
   */
  public calculateNormalUsingCarCoordinate(
    carIndex: number,
    carCoordinates: ReadonlyArray<TrackCoordinate>,
    innerNearest: NearestCoordinateResult,
    outerNearest: NearestCoordinateResult): Vector3 {

    let edge1 = new Vector3(innerNearest.coordinate.x, innerNearest.coordinate.y, innerNearest.coordinate.z);
    let edge2 = new Vector3(outerNearest.coordinate.x, outerNearest.coordinate.y, outerNearest.coordinate.z);

    // If the two edges are the same, then we can't calculate a normal.
    if (edge1.equals(edge2)) {
      return CreateZeroVector3();
    }

    let cross = CreateZeroVector3();
    let carReferenceIndex = carIndex;

    // While we haven't manged to calculate a normal...
    while (cross.equals(CreateZeroVector3()) && carReferenceIndex < carCoordinates.length) {
      let carReference = carCoordinates[carReferenceIndex];
      let car = new Vector3(carReference.x, carReference.y, carReference.z);

      // Create a vector from the car position to the first edge position.
      let v1 = new Vector3().subVectors(edge1, car);

      // Create a vector from the car position to the second edge position.
      let v2 = new Vector3().subVectors(edge2, car);

      let angle = Math.abs(v1.angleTo(v2));
      if (angle < MINIMUM_CROSS_PRODUCT_ANGLE
        || Math.abs(angle - Math.PI) < MINIMUM_CROSS_PRODUCT_ANGLE
        || edge1.distanceTo(car) < MINIMUM_EDGE_DISTANCE_TO_REFERENCE
        || edge2.distanceTo(car) < MINIMUM_EDGE_DISTANCE_TO_REFERENCE) {
        // If the angle is too small, or the distance to the reference is too small, then we can't calculate a normal without
        // risking a noisy result, so skip this car position.
        cross = CreateZeroVector3();
      } else {
        // Calculate the cross product to get the (not normalized) normal.
        cross = new Vector3().crossVectors(v1, v2);
        if (cross.z < 0) {  // In track coordinates we are z +ve downwards, so we wan the negative z normal.
          cross = new Vector3().crossVectors(v2, v1);
        }
      }

      // Advance to the next car position.
      ++carReferenceIndex;
    }

    // Normalize the cross product to create the normal.
    cross.normalize();

    return cross;
  }

  /**
   * Applies the elevation data from the source coordinates to the inner, outer, center line and car coordinates.
   * @param sourceInnerCoordinates The source inner coordinates.
   * @param sourceOuterCoordinates The source outer coordinates.
   * @param innerCoordinates The inner coordinates.
   * @param outerCoordinates The outer coordinates.
   * @param centreLineCoordinates The centre line coordinates.
   * @param carCoordinates The car coordinates.
   */
  private applyTrackElevation(
    sourceInnerCoordinates: ReadonlyArray<TrackCoordinate>,
    sourceOuterCoordinates: ReadonlyArray<TrackCoordinate>,
    innerCoordinates: TrackCoordinate[],
    outerCoordinates: TrackCoordinate[],
    centreLineCoordinates: TrackCoordinate[],
    carCoordinates: TrackCoordinate[]): void {

    // If the source coordinates, from which we will take elevation data, are empty or have no z values
    // then just return as we can't do anything.
    if (!sourceInnerCoordinates.length
      || !sourceOuterCoordinates.length
      || (sourceInnerCoordinates.every(v => v.z === 0) && sourceOuterCoordinates.every(v => v.z === 0))) {
      return;
    }

    // Apply elevation to the inner, outer, centre line and car coordinates.
    this.applyTrackElevationToPath(sourceInnerCoordinates, sourceOuterCoordinates, innerCoordinates);
    this.applyTrackElevationToPath(sourceInnerCoordinates, sourceOuterCoordinates, outerCoordinates);
    this.applyTrackElevationToPath(sourceInnerCoordinates, sourceOuterCoordinates, centreLineCoordinates);
    this.applyTrackElevationToPath(sourceInnerCoordinates, sourceOuterCoordinates, carCoordinates);
  }

  /**
   * Apply the elevation data from the source coordinates to the path.
   * @param sourceInnerCoordinates The source inner coordinates.
   * @param sourceOuterCoordinates The source outer coordinates.
   * @param path The path to apply the elevation data to.
   */
  private applyTrackElevationToPath(
    sourceInnerCoordinates: ReadonlyArray<TrackCoordinate>,
    sourceOuterCoordinates: ReadonlyArray<TrackCoordinate>,
    path: TrackCoordinate[]): void {

    // Create quad trees for the inner and outer coordinates, for fast searching.
    let innerQuadTree = this.createQuadTree(sourceInnerCoordinates);
    let outerQuadTree = this.createQuadTree(sourceOuterCoordinates);

    let calculateEvery = 10;
    // For each Nth coordinate in the path...
    for (let pathIndex = 0; pathIndex < path.length; pathIndex += calculateEvery) {
      let pathCoordinate = path[pathIndex];

      // Find the nearest inner and outer track coordinates to the path coordinate.
      let innerNearest = innerQuadTree.find(pathCoordinate.x, pathCoordinate.y);
      let outerNearest = outerQuadTree.find(pathCoordinate.x, pathCoordinate.y);

      if (!innerNearest || !outerNearest) {
        continue;
      }

      // Get the distance in 2D space between the path coordinate and the nearest inner and outer track coordinates.
      let innerDistance = this.getDistance2D(innerNearest.coordinate, pathCoordinate);
      let outerDistance = this.getDistance2D(outerNearest.coordinate, pathCoordinate);


      // Start with the z value of the inner nearest coordinate.
      let z = innerNearest.coordinate.z;

      // Calculate the total distance between the inner and outer nearest coordinates.
      let totalDistance = innerDistance + outerDistance;

      // If the total distance is not zero, then interpolate between the inner and outer z values.
      if (totalDistance) {
        z = innerNearest.coordinate.z * (1 - (innerDistance / totalDistance))
          + outerNearest.coordinate.z * (1 - (outerDistance / totalDistance));
      }

      // Set the z value for the next N path coordinates (as we only calculate the z value for every Nth position).
      for (let subIndex = 0; subIndex < calculateEvery && pathIndex + subIndex < path.length; ++subIndex) {
        if (z !== pathCoordinate.z) {
          let subIndexCoordinate = path[pathIndex + subIndex];
          path[pathIndex + subIndex] = new TrackCoordinate(subIndexCoordinate.x, subIndexCoordinate.y, z);
        }
      }

      // If we're not at the start of the path coordinates...
      if (pathIndex >= calculateEvery) {
        // Interpolate between the current z value and the previous z value.
        for (let subIndex = 1; subIndex < calculateEvery; ++subIndex) {
          let aWeight = subIndex / calculateEvery;
          let bWeight = 1 - aWeight;
          let a = path[pathIndex];
          let b = path[pathIndex - calculateEvery];
          let c = path[pathIndex - calculateEvery + subIndex];
          path[pathIndex - calculateEvery + subIndex] =
            new TrackCoordinate(
              c.x,
              c.y,
              a.z * aWeight + b.z * bWeight);
        }
      }
    }
  }

  /**
   * Creates a quad tree from the given set of coordinates.
   * @param coordinates The coordinates to create the quad tree from.
   * @returns The quad tree.
   */
  private createQuadTree(coordinates: ReadonlyArray<TrackCoordinate>) {
    return d3.quadtree<QuadTreeItem>()
      .x(v => v.coordinate.x)
      .y(v => v.coordinate.y)
      .addAll(coordinates
        .map((v, i) => ({
          coordinate: v,
          index: i
        }))
        .filter(v => isNumber(v.coordinate.x) && isNumber(v.coordinate.y)));
  }

  /**
   * Creates an octree from the given set of coordinates.
   * @param coordinates The coordinates to create the octree from.
   * @returns The octree.
   */
  private createOctree(coordinates: ReadonlyArray<TrackCoordinate>) {
    return d3octree.octree()
      .x((v: QuadTreeItem) => v.coordinate.x)
      .y((v: QuadTreeItem) => v.coordinate.y)
      .z((v: QuadTreeItem) => v.coordinate.z)
      .addAll(coordinates
        .map((v, i) => ({
          coordinate: v,
          index: i
        }))
        .filter(v => isNumber(v.coordinate.x) && isNumber(v.coordinate.y) && isNumber(v.coordinate.z)));
  }

  /**
   * Returns the distance in 2D space (ignoring z) between the two given coordinates.
   * @param a The first coordinate.
   * @param b The second coordinate.
   * @returns The distance in 2D space.
   */
  private getDistance2D(a: TrackCoordinate, b: TrackCoordinate) {
    return Math.pow(a.x - b.x, 2)
      + Math.pow(a.y - b.y, 2);
  }

  /**
   * Returns the distance in 3D space between the two given coordinates.
   * @param a The first coordinate.
   * @param b The second coordinate.
   * @returns The distance in 3D space.
   */
  private getDistance3D(a: TrackCoordinate, b: TrackCoordinate) {
    return Math.pow(a.x - b.x, 2)
      + Math.pow(a.y - b.y, 2)
      + Math.pow(a.z - b.z, 2);
  }

  /**
   * Returns a partially populated IntermediateTrackData object from the given track config using the track outline.
   * @param track The track config.
   * @returns The partially populated IntermediateTrackData object or undefined.
   */
  private extractFromOutline(track: any): IntermediateTrackData | undefined {
    if (track.trackOutline && Object.entries(track.trackOutline).length >= 0) { // longest tracks don't store the centreline because it's too long!
      return {
        xLeft: track.trackOutline.xTrackEdgeLeft,
        yLeft: track.trackOutline.yTrackEdgeLeft,
        zLeft: track.trackOutline.zTrackEdgeLeft,
        xRight: track.trackOutline.xTrackEdgeRight,
        yRight: track.trackOutline.yTrackEdgeRight,
        zRight: track.trackOutline.zTrackEdgeRight,
        xCar: undefined,
        yCar: undefined,
        zCar: undefined,
        sLap: undefined,
      };
    }

    return undefined;
  }

  /**
   * Updates the supplied IntermediateTrackData object with the centre line data from the given track config.
   * @param track The track config.
   * @param data The IntermediateTrackData object to update.
   * @returns True if the centre line data was applied, otherwise false.
   */
  private applyFromCentreLine(track: any, data: IntermediateTrackData): boolean {
    if (!track.centreLine || Object.keys(track.centreLine).length === 0) {
      return false;
    }

    if (!track.centreLine.xHalfWidth) {
      throw new DisplayableError('Centre line xHalfWidth is missing.');
    }
    if (!track.centreLine.xCentreLine) {
      throw new DisplayableError('Centre line xCentreLine is missing.');
    }
    if (!track.centreLine.yCentreLine) {
      throw new DisplayableError('Centre line yCentreLine is missing.');
    }

    let xCentreLine: number[] = track.centreLine.xCentreLine;
    let yCentreLine: number[] = track.centreLine.yCentreLine;

    let aYaw: number[] = [];
    if (track.centreLine.aYawCentreLine && track.centreLine.aYawCentreLine.length) {
      // Use the yaw vector if it is supplied.
      aYaw = track.centreLine.aYawCentreLine;
    } else {
      // Otherwise calculate the yaw.
      for (let i = 0; i < xCentreLine.length - 1; i++) {
        aYaw.push(Math.atan2(yCentreLine[i + 1] - yCentreLine[i], xCentreLine[i + 1] - xCentreLine[i]));
      }
      aYaw.push(aYaw[aYaw.length - 1]);
    }

    // Calculate the track offsets from the half width and yaw.
    let xTrackOffset = (track.centreLine.xHalfWidth as number[]).map((xHalf, i) => -xHalf * Math.sin(aYaw[i]));
    let yTrackOffset = (track.centreLine.xHalfWidth as number[]).map((xHalf, i) => xHalf * Math.cos(aYaw[i]));

    // Calculate the left and right track coordinates from the center line and track x and y offsets.
    let xLeft = xCentreLine.map((e, i) => e + xTrackOffset[i]);
    let yLeft = yCentreLine.map((e, i) => e + yTrackOffset[i]);
    let xRight = xCentreLine.map((e, i) => e - xTrackOffset[i]);
    let yRight = yCentreLine.map((e, i) => e - yTrackOffset[i]);

    let xCar: number[];
    let yCar: number[];
    if (track.centreLine.rTrackCentreOffset) {
      // We want to calculate the car coordinates from the x and y track offsets and the ratio of the car position to the track width.
      // First we calculate the x and y car offsets relative to the center line.
      let xCarOffset = xTrackOffset.map((e, i) => e * track.centreLine.rTrackCentreOffset[i]);
      let yCarOffset = yTrackOffset.map((e, i) => e * track.centreLine.rTrackCentreOffset[i]);

      // Then we add the car offsets to the center line to get the car coordinates.
      xCar = xCentreLine.map((e, i) => e + xCarOffset[i]);
      yCar = yCentreLine.map((e, i) => e + yCarOffset[i]);
    } else {
      xCar = xRight;
      yCar = yRight;
    }

    // The center line doesn't contain any elevation data, so we set the z values to zero.
    let zLeft = new Array(xLeft.length).fill(0);
    let zRight = new Array(xRight.length).fill(0);
    let zCar = new Array(xCar.length).fill(0);
    let zCentreLine = new Array(xCentreLine.length).fill(0);

    data.xLeft = xLeft;
    data.yLeft = yLeft;
    data.zLeft = zLeft;
    data.xRight = xRight;
    data.yRight = yRight;
    data.zRight = zRight;
    data.xCar = xCar;
    data.yCar = yCar;
    data.zCar = zCar;
    data.xCentreLine = xCentreLine;
    data.yCentreLine = yCentreLine;
    data.zCentreLine = zCentreLine;
    return true;
  }

  /**
   * Updates the supplied IntermediateTrackData object with the racing line data from the given track config.
   * @param track The track config.
   * @param data The IntermediateTrackData object to update.
   * @returns True if the racing line data was applied, otherwise false.
   */
  private applyFromRacingLine(track: any, data: IntermediateTrackData): boolean {
    if (!track.racingLine) {
      return false;
    }

    let hasData = (v: ReadonlyArray<number>) => !!(v && v.length);

    // Figure out what kind of racing line data we have.
    let hasRacingLineDefinedByCurvature
      = hasData(track.racingLine.sLap)
      && hasData(track.racingLine.cLap);

    let hasRacingLineDefinedByXY
      = hasData(track.racingLine.xRacingLine)
      && hasData(track.racingLine.yRacingLine);

    let hasRacingLineDefinedBygLat
      = hasData(track.racingLine.gLat)
      && hasData(track.racingLine.vCar)
      && (hasData(track.racingLine.sLap) || hasData(track.racingLine.tLap));

    let hasRacingLine
      = hasRacingLineDefinedByCurvature
      || hasRacingLineDefinedBygLat
      || hasRacingLineDefinedByXY;

    if (!hasRacingLine) {
      return false;
    }

    // Determine the track lap type (open, closed, closed figure of eight, etc.).
    let trackLapType = this.getTrackLapType(track);

    if (trackLapType === TrackLapType.notSpecified) {
      // If the track lap type is not specified, then we assume it is an open track.
      trackLapType = TrackLapType.open;
    }

    // Extract the racing line xCar, yCar and sLap data.
    let extractRacingLineResult: ExtractRacingLineResult | undefined;
    if (hasRacingLineDefinedByXY) {
      extractRacingLineResult = this.extractRacingLineFromXY(
        track.racingLine.xRacingLine,
        track.racingLine.yRacingLine);
    } else if (hasRacingLineDefinedBygLat) {
      extractRacingLineResult = this.extractRacingLineFromgLat(
        track.racingLine.gLat,
        track.racingLine.vCar,
        track.racingLine.tLap,
        track.racingLine.sLap,
        trackLapType);
    } else {
      extractRacingLineResult = this.extractRacingLineFromCurvature(
        track.racingLine.sLap,
        track.racingLine.cLap,
        trackLapType);
    }

    let xCar = extractRacingLineResult.xCar;
    let yCar = extractRacingLineResult.yCar;
    let sLap = extractRacingLineResult.sLap;

    // Set the z car values from the track elevation data. This may not exist.
    let zCar = track.racingLine.zTrack;
    if (!zCar || !zCar.length) {

      // If the track elevation data doesn't exist, then we need to calculate the z car values from the track incline data.
      let incline = track.racingLine.aTrackIncline;
      if (incline && incline.length) {
        zCar = [0];
        for (let i = 1; i < sLap.length; i++) {
          let ds = sLap[i] - sLap[i - 1];
          zCar.push(zCar[i - 1] + ds * Math.tan(incline[i - 1]));
        }
      }
    } else if (sLap.length !== zCar.length) {
      throw new DisplayableError('Racing line sLap and zTrack differ in length.');
    }

    // If we don't have any elevation data, just set the elevation to zero.
    if (!zCar) {
      zCar = new Array(xCar.length).fill(0);
    }

    data.sLap = [...sLap];
    data.xCar = [...xCar];
    data.yCar = [...yCar];
    data.zCar = zCar;

    // If we have racing line camber data...
    let camber = track.racingLine.aTrackCamber;
    if (camber && camber.length) {

      if (sLap.length !== camber.length) {
        throw new DisplayableError('Racing line sLap and aTrackCamber differ in length.');
      }

      // ... and we don't have left or right track edge data...
      if (!data.xLeft || !data.xLeft.length) {
        // We will use the camber to calculate the left and right track coordinates.
        // Start buy just setting the left and right track coordinates to the car coordinates.
        data.xLeft = [...xCar];
        data.yLeft = [...yCar];
        data.zLeft = [...zCar];
        data.xRight = [...xCar];
        data.yRight = [...yCar];
        data.zRight = [...zCar];

        // Assume a track half width of 0.5.
        const trackHalfWidth = 0.5;
        for (let i = 0; i < sLap.length; i++) {
          // Calculate the height change due to camber.
          let heightChange = trackHalfWidth * Math.tan(camber[i]);
          data.zLeft[i] += heightChange;
          data.zRight[i] -= heightChange;

          // This isn't totally accurate, as we don't account for track rotation when calculating X and Y.
          let aOffset = 0;
          let bOffset = -1;
          if (i === 0) {
            aOffset = 1;
            bOffset = 0;
          }
          let diff = new Vector2(data.xCar[i + aOffset] - data.xCar[i + bOffset], data.yCar[i + aOffset] - data.yCar[i + bOffset]);
          diff.normalize();

          // Calculate the left and right track coordinates.
          data.xLeft[i] -= diff.y;
          data.yLeft[i] += diff.x;
          data.xRight[i] += diff.y;
          data.yRight[i] -= diff.x;
        }
      }
    }

    return true;
  }

  /**
   * Extracts the racing line data from a racing line defined by XY coordinates.
   * @param xCar The x car coordinates.
   * @param yCar The y car coordinates.
   * @returns The extracted racing line data.
   */
  private extractRacingLineFromXY(xCar: ReadonlyArray<number>, yCar: ReadonlyArray<number>): ExtractRacingLineResult {
    if (xCar.length !== yCar.length) {
      throw new DisplayableError('Racing line X and Y coordinates must be the same length.');
    }

    let coordinates = xCar.map((v, i) => new TrackCoordinate(v, yCar[i], 0));

    // All we need to do is calculate the sLap from the XY coordinates.
    let sLap = this.computeDistanceVector(coordinates);

    return new ExtractRacingLineResult(xCar, yCar, sLap);
  }

  /**
   * Extracts the racing line data from a racing line defined by gLat data.
   * @param gLat The gLat data.
   * @param vCar The vCar data.
   * @param tLap The tLap data.
   * @param sLap The sLap data.
   * @param trackLapType The track lap type.
   * @returns The extracted racing line data.
   */
  private extractRacingLineFromgLat(
    gLat: ReadonlyArray<number>,
    vCar: ReadonlyArray<number>,
    tLap: ReadonlyArray<number> | undefined,
    sLap: ReadonlyArray<number> | undefined,
    trackLapType: TrackLapType) {

    if (gLat.length !== vCar.length) {
      throw new DisplayableError('Racing line gLat and vCar vectors do not have consistent lengths.');
    }


    if (!sLap || !sLap.length) {
      if (!tLap) {
        throw new DisplayableError('Racing line must have either sLap or tLap.');
      }

      if (tLap.length !== vCar.length) {
        throw new DisplayableError('Racing line tLap and vCar vectors do not have consistent lengths.');
      }

      if (tLap[0] !== 0) {
        throw new DisplayableError('Racing line tLap must start at tLap = 0.');
      }

      // Calculate sLap from tLap and vCar.
      let sLapConverted: number[] = [];
      sLapConverted.push(0);
      for (let i = 0; i < (tLap.length - 1); i++) {
        sLapConverted.push(0.5 * (vCar[i + 1] + vCar[i]) * (tLap[i + 1] - tLap[i]) + sLapConverted[i]);
      }
      sLap = sLapConverted;
    }

    let gLatOffset = 0;

    // If the track is closed (i.e. the start and end points should meet)...
    if (trackLapType !== TrackLapType.open) {
      let dx = 1e-6;

      // Loop through a few gLat adjusts to ensure we go through 2*pi radians.
      // We have to do this because the gLat and vCar data is normally recorded from the car,
      // and if used directly it will not necessarily result in a closed track due to noise or inaccuracies in the data.
      for (let j = 0; j < 3; j++) {
        let cLapBase: number[] = Array(vCar.length).fill(0);
        let cLapPerturbed: number[] = Array(vCar.length).fill(0);

        for (let i = 0; i < vCar.length; i++) {
          cLapBase[i] = ((gLat[i] + gLatOffset) / (vCar[i] * vCar[i]));
          cLapPerturbed[i] = ((gLat[i] + gLatOffset + dx) / (vCar[i] * vCar[i]));
        }

        let aYaw = this.inferTotalaYawFromRacingLine(trackLapType, sLap, cLapBase);
        let aYawPerturbed = this.trapz(sLap, cLapPerturbed);

        gLatOffset -= aYaw.totalMinusExpected / ((aYawPerturbed - aYaw.total) / dx);
      }
    }

    // Generate cLap with the gLatOffset we've calculated.
    let cLap: number[] = Array(vCar.length).fill(0);
    for (let i = 0; i < vCar.length; i++) {
      cLap[i] = ((gLat[i] + gLatOffset) / (vCar[i] * vCar[i]));
    }

    // Calculate the x and y car coordinates from the curvature.
    return this.computeTrackXYFromCurvature(sLap, cLap, trackLapType !== TrackLapType.open);
  }

  /**
   * Extracts the racing line data from a racing line defined by curvature data.
   * @param sLap The sLap data.
   * @param cLap The cLap (curvature) data.
   * @param trackLapType The track lap type.
   * @returns The extracted racing line data.
   */
  private extractRacingLineFromCurvature(
    sLap: ReadonlyArray<number>,
    cLap: ReadonlyArray<number>,
    trackLapType: TrackLapType): ExtractRacingLineResult {

    if (sLap.length !== cLap.length) {
      throw new DisplayableError(`Racing line sLap and cLap differ in length (${sLap.length} vs ${cLap.length}).`);
    }

    // let aYawTrack: number[] = Array(sLap.length-1).fill(0);
    // let pi = 3.141592;
    // aYawTrack[0] = 0;
    // for (let i = 1; i < sLap.length-1; i++) {
    //   aYawTrack[i] = aYawTrack[i-1] + (sLap[i+1] - sLap[i-1]) / 2 * cLap[i];
    // }

    // if(isPeriodic) {
    //   // scale to exactly match finish angle with start angle
    //   let rScaleTheta = 1;
    //   let thetaOffset = 0;
    //   let thetaTotal = aYawTrack[aYawTrack.length-1];
    //   if (Math.abs(thetaTotal) > pi) {
    //     // loop track
    //     rScaleTheta = Math.abs(2 * pi / thetaTotal);
    //     thetaOffset = 0;
    //   } else {
    //     // figure of eight track
    //     rScaleTheta = 1;
    //     thetaOffset = -thetaTotal;
    //   }
    //   for (let i = 0; i < aYawTrack.length; i++) {
    //     aYawTrack[i] *= rScaleTheta;
    //     aYawTrack[i] += thetaOffset * i / aYawTrack.length;
    //     aYawTrack[i] -= pi/2;
    //   }
    // }

    // // convert theta to x-y
    // let xCar: number[] = [];
    // let yCar: number[] = [];
    // xCar.push(0);
    // yCar.push(0);
    // for (let i = 1; i < sLap.length; i++) {
    //   let ds = sLap[i] - sLap[i-1];
    //   xCar.push(xCar[i-1] + ds * Math.sin(aYawTrack[i-1]));
    //   yCar.push(yCar[i-1] - ds * Math.cos(aYawTrack[i-1]));
    // }

    // if(isPeriodic) {
    //   // match up start and finish points (in x-y)
    //   let dxsf = xCar[xCar.length - 1] - xCar[0];
    //   let dysf = yCar[xCar.length - 1] - yCar[0];
    //   for (let i = 0; i < xCar.length; i++) {
    //     xCar[i] -= dxsf * i / (xCar.length - 1);
    //     yCar[i] -= dysf * i / (xCar.length - 1);
    //   }
    // }

    // Calculate the total aYaw from the curvature. How much we expect depends on the track type.
    let aYaw = this.inferTotalaYawFromRacingLine(trackLapType, sLap, cLap);

    // Apply the changes to cLap to get aYaw just right.
    if (!aYaw.isExpectedYaw) {
      let sLapStart = sLap[0];
      let sLapEnd = sLap[sLap.length - 1];
      let sLapTotal = sLapEnd - sLapStart;

      let cLapAdjusted = [...cLap];

      let daYaw_dsLap = aYaw.expectedMinusTotal / sLapTotal;
      for (let i = 0; i < sLap.length; i++) {
        cLapAdjusted[i] += daYaw_dsLap;
      }

      cLap = cLapAdjusted;
    }

    // Calculate the x and y car coordinates from the curvature.
    return this.computeTrackXYFromCurvature(sLap, cLap, trackLapType !== TrackLapType.open);
  }

  /**
   * Extracts the track xy data from a racing line defined by curvature data.
   * @param sLap The sLap data.
   * @param cLap The cLap (curvature) data.
   * @param correctToZeroDrift Whether to correct to zero drift.
   * @returns The extracted track xy data.
   */
  private computeTrackXYFromCurvature(
    sLap: ReadonlyArray<number>,
    cLap: ReadonlyArray<number>,
    correctToZeroDrift: boolean): ExtractRacingLineResult {

    if (sLap.length !== cLap.length) {
      throw new DisplayableError(`Racing line sLap and cLap differ in length (${sLap.length} vs ${cLap.length}).`);
    }

    let aYawTrack: number[] = Array(sLap.length - 1).fill(0);
    aYawTrack[0] = 0;
    for (let i = 1; i < sLap.length - 1; i++) {
      aYawTrack[i] = aYawTrack[i - 1] + (sLap[i + 1] - sLap[i - 1]) / 2 * cLap[i];
    }

    // convert theta to x-y
    let xCar: number[] = [];
    let yCar: number[] = [];
    xCar.push(0);
    yCar.push(0);
    for (let i = 1; i < sLap.length; i++) {
      let ds = sLap[i] - sLap[i - 1];
      xCar.push(xCar[i - 1] + ds * Math.sin(aYawTrack[i - 1]));
      yCar.push(yCar[i - 1] - ds * Math.cos(aYawTrack[i - 1]));
    }

    if (correctToZeroDrift) {
      // match up start and finish points (in x-y)
      let dxsf = xCar[xCar.length - 1] - xCar[0];
      let dysf = yCar[xCar.length - 1] - yCar[0];
      let sLapEnd = sLap[sLap.length - 1];
      for (let i = 0; i < xCar.length; i++) {
        xCar[i] -= dxsf * (sLap[i] / sLapEnd);
        yCar[i] -= dysf * (sLap[i] / sLapEnd);

        // This was the previous code, but using sLap matches what canopy-sims does and seems more correct.
        // xCar[i] -= dxsf * i / (xCar.length - 1);
        // yCar[i] -= dysf * i / (xCar.length - 1);
      }

    }

    return new ExtractRacingLineResult(xCar, yCar, sLap);
  }

  /**
   * For a given set of X, Y and Z coordinates:
   *  - Ensure the X and Y coordinates are defined.
   *  - Create Z coordinates if they are not defined.
   *  - Ensure they are all the same length.
   * @param name The name of the coordinates (for error messages).
   * @param x The X coordinates.
   * @param y The Y coordinates.
   * @param z The Z coordinates.
   * @returns The coordinates as a TrackCoordinate array.
   */
  private getValidatedTrackCoordinates(name: string, x: number[] | undefined, y: number[] | undefined, z: number[] | undefined): TrackCoordinate[] {
    if (!x || !y) {
      return [];
    }

    if (!z || !z.length) {
      z = new Array(x.length).fill(0);
    }

    const zDefined = z;

    if (x.length !== y.length
      || x.length !== z.length) {
      throw new DisplayableError(`Track ${name} vector lengths do not match (${x.length},${y.length},${z.length}).`);
    }

    return x.map((v, i) => new TrackCoordinate(x[i], y[i], zDefined[i]));
  }

  /**
   * Return whether the track is periodic.
   * @param track The track data.
   * @returns True if the track is periodic, false otherwise.
   */
  private isTrackPeriodic(track: any) {
    return !track.trackPeriodicity || track.trackPeriodicity.bTrackPeriodic;
  }

  private getTrackLapType(track: any): TrackLapType {
    if (track.racingLine && track.racingLine.lapType) {
      switch (track.racingLine.lapType) {
        case 'Open, ends not joined': return TrackLapType.open;
        case 'Single conventional lap': return TrackLapType.closedConventionalLap;
        case 'Single figure of 8': return TrackLapType.closedFigureOfEight;
        case 'Automatically infer': return TrackLapType.closedInfer;
      }
    }

    return TrackLapType.notSpecified;
  }

  /**
   * Trapezoidal numerical integration.
   * Taken from canopy-sims/Track.cpp.
   * @param x The x values.
   * @param y The y values.
   * @returns The integral of y with respect to x.
   */
  private trapz(x: ReadonlyArray<number>, y: ReadonlyArray<number>): number {
    let z: number = 0;

    for (let i = 0; i < x.length - 1; i++) {
      let dx = x[i + 1] - x[i];
      z += dx * (y[i + 1] + y[i]) / 2;
    }

    return z;
  }

  /**
   * Returns the sign of the given value.
   * Taken from canopy-sims/Track.cpp.
   * @param value The value.
   * @returns The sign of the value.
   */
  private sign(value: number): number {
    if (value < 0) {
      return -1;
    }

    return 1;
  }

  /**
   * Infers the total yaw from the racing line.
   * Taken from canopy-sims/Track.cpp.
   * @param trackLapType The track lap type.
   * @param sLap The sLap data.
   * @param cLap The cLap data.
   * @returns The inferred yaw data.
   */
  private inferTotalaYawFromRacingLine(trackLapType: TrackLapType, sLap: ReadonlyArray<number>, cLap: ReadonlyArray<number>): InferredYaw {
    const aYawTotal = this.trapz(sLap, cLap);

    let aYawExpected = aYawTotal;

    switch (trackLapType) {
      case TrackLapType.closedInfer:
        // Correct the yaw to the nearest multiple of 2*pi.
        aYawExpected = 2.0 * Math.PI * Math.round(aYawTotal / (2 * Math.PI));
        break;

      case TrackLapType.closedConventionalLap:
        // Correct the yaw to + or - 2 *pi.
        aYawExpected = (2 * Math.PI * this.sign(aYawTotal));
        break;

      case TrackLapType.closedFigureOfEight:
        // Correct the yaw to zero.
        aYawExpected = 0;
        break;
    }

    return new InferredYaw(aYawTotal, aYawExpected);
  }

  /**
   * Adds the first coordinate to the end of the array to make the path closed.
   * @param input The array of coordinates.
   */
  private wrapCoordinates(input: TrackCoordinate[]): void {
    if (input.length) {
      input.push(input[0]);
    }
  }
}

/**
 * The type of lap.
 */
enum TrackLapType {

  /**
   * The lap type is not specified.
   */
  notSpecified,

  /**
   * The track is open.
   */
  open,

  /**
   * The track is a conventional closed loop.
   */
  closedConventionalLap,

  /**
   * The track is a figure of eight.
   */
  closedFigureOfEight,

  /**
   * The track is closed, and the specific type should be inferred.
   */
  closedInfer
}

/**
 * The result of inferring the yaw from the racing line.
 */
class InferredYaw {

  /**
   * Creates a new instance of InferredYaw.
   * @param total The total yaw.
   * @param expected The expected yaw.
   */
  constructor(
    public readonly total: number,
    public readonly expected: number) {
    this.expectedMinusTotal = expected - total;
    this.totalMinusExpected = total - expected;
    this.isExpectedYaw = (expected === total);
  }

  /**
   * The expected yaw minus the total yaw.
   */
  public readonly expectedMinusTotal: number;

  /**
   * The total yaw minus the expected yaw.
   */
  public readonly totalMinusExpected: number;

  /**
   * Whether the total yaw is the expected yaw.
   */
  public readonly isExpectedYaw: boolean;
}

/**
 * The result of finding the nearest coordinate to another coordinate.
 */
class NearestCoordinateResult {

  /**
   * Creates a new instance of NearestCoordinateResult.
   * @param coordinate The nearest coordinate.
   * @param index The index of the nearest coordinate.
   * @param distance The distance to the nearest coordinate.
   */
  constructor(
    public readonly coordinate: TrackCoordinate,
    public readonly index: number,
    public readonly distance: number) { }
}

/**
 * The result of extracting the racing line data.
 */
class ExtractRacingLineResult {

  /**
   * Creates a new instance of ExtractRacingLineResult.
   * @param xCar The x car coordinates.
   * @param yCar The y car coordinates.
   * @param sLap The sLap data.
   */
  constructor(
    public readonly xCar: ReadonlyArray<number>,
    public readonly yCar: ReadonlyArray<number>,
    public readonly sLap: ReadonlyArray<number>) {
  }
}

/**
 * An item in a quad tree.
 */
interface QuadTreeItem {

  /**
   * The coordinate.
   */
  coordinate: TrackCoordinate;

  /**
   * The index of the coordinate.
   */
  index: number;
}

import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ChannelEditorDialogData} from './channel-editor-dialog.service';
import {DialogManager, DialogBase} from '../../common/dialogs/dialog-manager.service';
import {ChannelEditorDialogSession, ChannelEditorDialogSessionFactory} from './channel-editor-dialog-session';
import {Timer} from '../../common/timer.service';

@Component({
  selector: 'cs-channel-editor-dialog',
  templateUrl: './channel-editor-dialog.component.html',
  styleUrls: ['./channel-editor-dialog.component.scss']
})
export class ChannelEditorDialogComponent implements OnInit {
  @ViewChild('filter') filterElement: ElementRef;

  public isVisible: boolean = false;

  public dialog: DialogBase<any>;
  public session: ChannelEditorDialogSession;

  constructor(
    private timer: Timer,
    private sessionFactory: ChannelEditorDialogSessionFactory,
    private dialogManager: DialogManager) {
  }

  public ngOnInit(): any {
    this.dialogManager.secondaryChanged.subscribe(() => this.dialogManagerChanged());
  }

  public accept() {
    if(this.dialog) {
      this.dialog.resolve(this.session.buildResult());
    }
  }

  public dismiss() {
    if(this.dialog){
      this.dialog.dismiss();
    }
  }

  public async dialogManagerChanged() {
    this.dismiss();

    let currentDialog = this.dialogManager.getSecondary();
    if(currentDialog && currentDialog instanceof ChannelEditorDialogData){
      this.isVisible = true;
      this.dialog = <ChannelEditorDialogData>currentDialog;
      this.session = this.sessionFactory.create(<ChannelEditorDialogData>currentDialog);
      this.session.load();

      await this.timer.yield();
      this.session.filterElement = this.filterElement;
    } else {
      this.isVisible = false;
      this.session = undefined;
      this.dialog = undefined;
    }
  }
}


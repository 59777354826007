@if (hasBeenActivated) {
  <div>
    @if (perJob) {
      <cs-job-selector
        [jobs]="jobs"
        [jobSelectorState]="jobSelectorState"
      (changed)="selectJob($event)"></cs-job-selector>
    }
    <div id="view-job-navigation-station-page" class="view-job-navigation-station-component">
      <div class="container-fluid">
        <cs-error-message [message]="errorMessage"></cs-error-message>
      </div>
      <div id="{{dashboardId}}" class="dashboard"></div>
      @for (job of jobs; track job; let i = $index) {
        <div
          [ngStyle]="{ display: isJobActive(job) ? 'block' : 'none' }">
          <div id="{{getJobDashboardId(i)}}" class="dashboard"></div>
        </div>
      }
      <div class="container-fluid">
        @if (!isLoaded) {
          <cs-loader id="chart-loader"></cs-loader>
        }
      </div>
      <div class="container-fluid">
        <cs-chart-help></cs-chart-help>
      </div>
    </div>
  </div>
}

import { isUndefined } from '../../is-defined';
import { IMinMax, MinMax } from '../min-max';
import { Units } from '../../units';
import { ChannelBinaryFormat } from '../../url-file-loader';
import { GetMonotonicStatus } from './get-monotonic-status';

/**
 * Something that has source data.
 */
export interface IHasSourceData {

  /**
   * Gets the source data of the type TSourceData.
   */
  getSourceData<TSourceData>(): TSourceData;
}

/**
 * Viewer channel data. This is the format the viewers expect their channel data to be in.
 */
export interface IViewerChannelData extends IHasSourceData {

  /**
   * The indices where the data changes. This is particularly useful for data
   * such as NLap for detecting where the laps change.
   */
  readonly dataChangeIndices: ReadonlyArray<number>;

  /**
   * The maximum value as calculated from the data.
   */
  readonly calculatedMaximum: number;

  /**
   * The minimum value as calculated from the data.
   */
  readonly calculatedMinimum: number;

  /**
   * The maximum value as defined in the options, falling back to the calculated maximum.
   */
  readonly maximum: number;

  /**
   * The minimum value as defined in the options, falling back to the calculated minimum.
   */
  readonly minimum: number;

  /**
   * The monotonic status of the data.
   */
  readonly monotonicStatus: MonotonicStatus;

  /**
   * Whether the channel data has any values.
   */
  readonly hasData: boolean;

  /**
   * Whether the channel data is monotonic.
   */
  readonly isMonotonic: boolean;

  /**
   * The name of the channel. This may match either the generic name or the full
   * name, depending on the context.
   */
  readonly name: string;

  /**
   * The generic name of the channel (may not be unique across simulations, e.g. `hRideF100`).
   */
  readonly genericName: string;

  /**
   * The full name of the channel (unique across simulations, e.g. `hRideF100:StraightSim`).
   */
  readonly fullName: string;

  /**
   * How the name should be defined in the chart definition.
   */
  readonly requestedName: string;

  /**
   * The description of the channel.
   */
  readonly description: string;

  /**
   * The binary format of the channel data.
   */
  readonly binaryFormat?: ChannelBinaryFormat;

  /**
   * The units of the channel data.
   */
  readonly units: string;

  /**
   * Whether the units were specified by the user.
   */
  readonly isUserSpecifiedUnits: boolean;

  /**
   * The channel data.
   */
  readonly data?: ReadonlyArray<number>;

  /**
   * The optional data labels for the channel data.
   */
  readonly dataLabels?: ReadonlyArray<string>;

  /**
   * The viewer channel data options.
   */
  readonly options?: Readonly<ViewerChannelDataOptions>;

  /**
   * The loader metadata for the channel.
   */
  readonly loaderMetadata?: Readonly<any>;

  /**
   * Clones the channel data with the specified options and new fields.
   * @param newData The new data.
   * @param newUnits The new units.
   * @param isUserSpecifiedUnits Whether the new units are user specified.
   * @param newDataLabels The new data labels.
   * @param options The new options.
   */
  clone(
    newData?: ReadonlyArray<number>,
    newUnits?: string,
    isUserSpecifiedUnits?: boolean,
    newDataLabels?: ReadonlyArray<string>,
    options?: Readonly<ViewerChannelDataOptions>): IViewerChannelData;

  /**
   * Clones the viewer channel data options with new fields.
   * @param newUnits The new units.
   */
  cloneOptions(newUnits?: string): ViewerChannelDataOptions | undefined;
}

/**
 * Viewer channel data.
 */
export class SourceViewerChannelData<TSourceData> implements IViewerChannelData {

  /**
   * @inheritdoc
   */
  public readonly minimum: number = NaN; // This can be overridden in options.

  /**
   * @inheritdoc
   */
  public readonly maximum: number = NaN; // This can be overridden in options.

  /**
   * @inheritdoc
   */
  public readonly calculatedMinimum: number = NaN; // This cannot be overridden.

  /**
   * @inheritdoc
   */
  public readonly calculatedMaximum: number = NaN; // This cannot be overridden.

  /**
   * @inheritdoc
   */
  public readonly monotonicStatus: MonotonicStatus = MonotonicStatus.Unknown;

  /**
   * The indices where the data changes. This is particularly useful for data
   * such as NLap for detecting where the laps change.
   */
  private _dataChangeIndices?: ReadonlyArray<number>;

  /**
   * Creates a new instance of SourceViewerChannelData.
   * @param sourceData The source data for the channel.
   * @param name @inheritdoc
   * @param genericName @inheritdoc
   * @param fullName @inheritdoc
   * @param requestedName @inheritdoc
   * @param description @inheritdoc
   * @param units @inheritdoc
   * @param isUserSpecifiedUnits @inheritdoc
   * @param binaryFormat @inheritdoc
   * @param data @inheritdoc
   * @param dataLabels @inheritdoc
   * @param options @inheritdoc
   * @param loaderMetadata @inheritdoc
   */
  constructor(
    public readonly sourceData: TSourceData,
    public readonly name: string,
    public readonly genericName: string,
    public readonly fullName: string,
    public readonly requestedName: string, // How the name should be defined in the chart definition.
    public readonly description: string,
    public readonly units: string,
    public readonly isUserSpecifiedUnits: boolean,
    public readonly binaryFormat: ChannelBinaryFormat | undefined,
    public readonly data: ReadonlyArray<number> | undefined,
    public readonly dataLabels?: ReadonlyArray<string> | undefined,
    public readonly options?: Readonly<ViewerChannelDataOptions> | undefined,
    public readonly loaderMetadata?: Readonly<any> | undefined) {

    [this.minimum, this.maximum, this.monotonicStatus] = GetMonotonicStatus.execute(this.data).spread();

    this.calculatedMinimum = this.minimum;
    this.calculatedMaximum = this.maximum;

    if (this.options) {
      if (this.options.overrideExtents) {
        this.minimum = this.options.overrideExtents.minimum;
        this.maximum = this.options.overrideExtents.maximum;
      }
    }
  }

  /**
   * @inheritdoc
   */
  public get hasData(): boolean {
    return !!(this.data && this.data.length);
  }

  /**
   * @inheritdoc
   */
  public get isMonotonic(): boolean {
    return this.monotonicStatus === MonotonicStatus.Decreasing || this.monotonicStatus === MonotonicStatus.Increasing;
  }

  /**
   * @inheritdoc
   */
  public get dataChangeIndices(): ReadonlyArray<number> {
    if (!this._dataChangeIndices) {
      let changeIndices = [];
      let data = this.data;
      if (data && data.length > 1) {
        for (let i = 1; i < data.length; ++i) {
          if (data[i] !== data[i - 1]) {
            changeIndices.push(i);
          }
        }
      }

      this._dataChangeIndices = changeIndices;
    }

    return this._dataChangeIndices;
  }

  /**
   * @inheritdoc
   */
  public clone(newData?: ReadonlyArray<number>, newUnits?: string, isUserSpecifiedUnits?: boolean, newDataLabels?: ReadonlyArray<string>, options?: Readonly<ViewerChannelDataOptions>): IViewerChannelData {
    return this.cloneTyped(newData, newUnits, isUserSpecifiedUnits, newDataLabels, options);
  }

  /**
   * Clones the channel data with the specified options and new fields. Keeps the source data type.
   * @param newData The new data.
   * @param newUnits The new units.
   * @param isUserSpecifiedUnits Whether the new units are user specified.
   * @param newDataLabels The new data labels.
   * @param options The new options.
   * @returns The cloned channel data.
   */
  public cloneTyped(newData?: ReadonlyArray<number>, newUnits?: string, isUserSpecifiedUnits?: boolean, newDataLabels?: ReadonlyArray<string>, options?: Readonly<ViewerChannelDataOptions>): SourceViewerChannelData<TSourceData> {
    if (!options) {
      options = this.cloneOptions(newUnits);
    }

    const data = newData || this.data;
    const dataLabels = newDataLabels || this.dataLabels;

    return new SourceViewerChannelData<TSourceData>(
      this.sourceData,
      this.name,
      this.genericName,
      this.fullName,
      this.requestedName,
      this.description,
      newUnits || this.units,
      !isUndefined(isUserSpecifiedUnits) ? isUserSpecifiedUnits : this.isUserSpecifiedUnits,
      this.binaryFormat,
      data ? [...data] : this.data,
      dataLabels ? [...dataLabels] : this.dataLabels,
      options || this.options,
      this.loaderMetadata);
  }

  /**
   * @inheritdoc
   */
  public cloneOptions(newUnits?: string): ViewerChannelDataOptions | undefined {
    if (!this.options) {
      return this.options;
    }

    let options: ViewerChannelDataOptions = {
      ...this.options
    };

    if (newUnits && newUnits !== this.units) {
      if (options.overrideExtents) {
        options.overrideExtents = new MinMax(
          Units.convertValueBetweenUnits(options.overrideExtents.minimum, this.units, newUnits),
          Units.convertValueBetweenUnits(options.overrideExtents.maximum, this.units, newUnits));
      }
    }

    return options;
  }

  /**
   * @inheritdoc
   */
  public getSourceData<T>(): T {
    // This method is so that we can easily get typed source data even if the
    // type information has been lost through cloning.
    return (this.sourceData || {}) as any as T;
  }

  /**
   * Creates a basic viewer channel data with source data of type T.
   * @param name The name of the channel.
   * @param units The units of the channel.
   * @param data The data for the channel.
   * @returns The viewer channel data.
   */
  public static createBasicTyped<T>(sourceData: T, name: string, units: string, data: number[] | undefined) {
    return new SourceViewerChannelData<T>(
      sourceData,
      name,
      name,
      name,
      name,
      '',
      units,
      false,
      undefined,
      data,
      undefined,
      undefined,
      undefined);
  }
}

/**
 * Viewer channel data with undefined source data.
 */
export class ViewerChannelData extends SourceViewerChannelData<void> {

  /**
   * Creates a new instance of ViewerChannelData.
   * @param name @inheritdoc
   * @param genericName @inheritdoc
   * @param fullName @inheritdoc
   * @param requestedName @inheritdoc
   * @param description @inheritdoc
   * @param units @inheritdoc
   * @param isUserSpecifiedUnits @inheritdoc
   * @param binaryFormat @inheritdoc
   * @param data @inheritdoc
   * @param dataLabels @inheritdoc
   * @param options @inheritdoc
   * @param loaderMetadata @inheritdoc
   */
  constructor(
    name: string,
    genericName: string,
    fullName: string,
    requestedName: string, // How the name should be defined in the chart definition.
    description: string,
    units: string,
    isUserSpecifiedUnits: boolean,
    binaryFormat: ChannelBinaryFormat | undefined,
    data: ReadonlyArray<number> | undefined,
    dataLabels?: ReadonlyArray<string>,
    options?: Readonly<ViewerChannelDataOptions>,
    loaderMetadata?: Readonly<any>) {
    super(
      undefined,
      name,
      genericName,
      fullName,
      requestedName,
      description,
      units,
      isUserSpecifiedUnits,
      binaryFormat,
      data,
      dataLabels,
      options,
      loaderMetadata);
  }

  /**
   * Creates a basic viewer channel data.
   * @param name The name of the channel.
   * @param units The units of the channel.
   * @param data The data for the channel.
   * @returns The viewer channel data.
   */
  public static createBasic(name: string, units: string, data: number[] | undefined) {
    return SourceViewerChannelData.createBasicTyped<void>(undefined, name, units, data);
  }
}

/**
 * The monotonic status of the data.
 */
export enum MonotonicStatus {

  /**
   * The monotonic status has not yet been calculated.
   */
  Unknown,

  /**
   * The data is increasing monotonically.
   */
  Increasing,

  /**
   * The data is decreasing monotonically.
   */
  Decreasing,

  /**
   * The data is not monotonic.
   */
  None
}

/**
 * Viewer channel data options.
 * Note: If data with units is added here, don't forget to update clone method
 * to perform unit conversion.
 */
export interface ViewerChannelDataOptions {

  /**
   * The minimum and maximum values to use for the channel
   * data. If not specified, the minimum and maximum values
   * are calculated from the data.
   */
  overrideExtents?: IMinMax;
}


import {Injectable} from '@angular/core';
import {
  ConditionOperator, ConditionSource, DocumentSubType, GroupOperator,
  ListFilterData
} from '../../../../generated/api-stubs';
import {FilterUtilities} from '../../list-filtering/filter-utilities';

@Injectable()
export class CreateConfigSubTreeFilter {
  constructor(
    private filterUtilities: FilterUtilities){
  }

  public execute(configType: DocumentSubType, config: any, safePath: string, navigablePath: string): ListFilterData {

    if(!navigablePath){
      return undefined;
    }

    let pathComponents = navigablePath.split('.');

    if(configType === DocumentSubType.car){
      switch(safePath){
        case 'aero.coefficientOffsetMaps.secondaryCoefficientOffsetMaps':
          return this.executeForSecondaryCoefficientOffsetMaps(config, pathComponents);
      }
    }

    return undefined;
  }

  private executeForSecondaryCoefficientOffsetMaps(config: any, pathComponents: string[]): ListFilterData{
    let primaryMap = this.navigateTo(config, pathComponents, pathComponents.length - 2);
    if(!primaryMap || Array.isArray(primaryMap) || !primaryMap.componentId){
      return undefined;
    }

    let filter = this.filterUtilities.getInitialFilter();
    filter.query = {
      operator: GroupOperator.and,
      conditions: [
        {
          source: ConditionSource.customProperty,
          name: 'primaryCoefficientOffsetMap',
          operator: ConditionOperator.equals,
          value: primaryMap.componentId
        }
      ]
    };

    return filter;
  }

  private navigateTo(config: any, path: string[], pathLength?: number){
    if(pathLength){
      path = path.slice(0, pathLength);
    }

    return path.reduce((o, i) => o[i], config);
  }
}

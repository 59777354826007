import {Injectable} from '@angular/core';
import {DialogManager, IDialog, DialogBase} from '../../common/dialogs/dialog-manager.service';

@Injectable()
export class SelectSimVersionDialog {
  constructor(
    private dialogManager: DialogManager
  ){}

  public async show(
    tenantId: string,
    isTenantSimVersion: boolean,
    currentSimVersion?: string,
    selectDefaultMessage?: string,
    selectReleaseMessage?: string): Promise<SelectSimVersionResult> {

    let result = await this.dialogManager.showSecondaryDialog(
      new SelectSimVersionDialogData(
        tenantId,
        isTenantSimVersion,
        currentSimVersion,
        selectDefaultMessage,
        selectReleaseMessage));

    return result;
  }
}

export class SelectSimVersionDialogData extends DialogBase<SelectSimVersionResult> implements IDialog {
  constructor(
    public readonly tenantId: string,
    public readonly isTenantSimVersion: boolean,
    public readonly currentSimVersion: string,
    public readonly selectDefaultMessage: string,
    public readonly selectReleaseMessage: string) {
    super();
  }
}

export class SelectSimVersionResult {
  constructor(public readonly simVersion: string | undefined){
  }
}

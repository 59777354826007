import { Injectable } from '@angular/core';
import { ExtensionButtonCategories } from '../json-editor-node-extensions/extension-button-categories';
import { EditorNodeExtensionsFactory } from '../json-editor-node-extensions/editor-node-extensions-factory';
import { JsonEditorCustomization } from './json-editor-customization';
import { getCanopyJsonEditorOptions } from './get-canopy-json-editor-options';
import{ JSONEditor } from'@json-editor/json-editor'

@Injectable()
export class TableEditor extends JsonEditorCustomization {

  constructor(
    private readonly editorNodeExtensionsFactory: EditorNodeExtensionsFactory) {
    super();
  }

  public apply(): void {
    const service = this;

    JSONEditor.defaults.editors.table = class extends JSONEditor.defaults.editors.table {
      build() {
        super.build();
        this.theme.addHoverArea(this);
        const canopyOptions = getCanopyJsonEditorOptions(this);

        this.theme.addHelpLink(this.container, this.schema, this);

        // This is a workaround for a schema error where the title was specified twice,
        // once alongside the $ref and once in the $ref, but one was hyphenated.
        let normalize = function(input: string) {
          if (!input) {
            return input;
          }

          return input.replace('-', ' ');
        };

        if (this.parent.oneOf && this.schema.title && normalize(this.parent.schema.title) === normalize(this.schema.title)) {
          // This will remove the title but keep child elements like collapse buttons.
          // Otherwise the title is rendered twice, once for the `oneOf` and once for the table.
          let heading = this.title;
          let children = [];
          for (let i = 0; i < heading.children.length; i++) {
            children.push(heading.children[i]);
          }

          heading.innerHTML = '';
          children.forEach((item) => {
            heading.appendChild(item);
          });
        }

        this.extensions = service.editorNodeExtensionsFactory.create(canopyOptions.simVersion, canopyOptions.configType, this, canopyOptions.configSubTreeRepository);
        this.extensions.addButtonsContainer(this.title, [ExtensionButtonCategories.load, ExtensionButtonCategories.import]);
      }
    }
  }
}

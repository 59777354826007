@if (viewModel.populated) {
  @if (viewModel.populated.resolvedReference?.data) {
    <a class="link" [href]="url" (click)="onClick($event)">
      <ng-container [ngTemplateOutlet]="validity"></ng-container>
      <ng-container [ngTemplateOutlet]="referenceCount"></ng-container>
      <div class="cell-content">
        <span class="cell-name">
          {{viewModel.populated.resolvedReference.data.name | customLineBreaks}}
          @if (viewModel.populated.resolvedReference.data.isSupportSessionOpen) {
            <i class="fa fa-medkit support-session-open-icon" title="Support Session Open"></i>
          }
        </span>
        @if (isStudyReadOnly) {
          <i class="fa fa-user read-only-icon" title="You are not the owner."></i>
        }
      <div>
        <cs-study-jobs-summary
          [jobCount]="study.jobCount"
          [completedJobCount]="study.completedJobCount"
          [succeededJobCount]="study.succeededJobCount"
          [canNavigate]="false">
        </cs-study-jobs-summary>
        <span class="study-type">
          {{viewModel.populated.studyTypeName}}
        </span>
      </div>
      <span class="sim-version">{{viewModel.populated.resolvedReference.data.simVersion}}</span>
      <span class="creation-date" title="{{creationDate}}">{{fromCreationDate}}</span>
    </div>
    <ng-container [ngTemplateOutlet]="dropdownMenu"></ng-container>
  </a>
} @else {
  @if (viewModel.populated.resolvedReference?.error) {
    <ng-container [ngTemplateOutlet]="validity"></ng-container>
    <ng-container [ngTemplateOutlet]="referenceCount"></ng-container>
    <div class="cell-content">
      {{viewModel.populated.resolvedReference.error}}
    </div>
    <ng-container [ngTemplateOutlet]="dropdownMenu"></ng-container>
  } @else {
    <div class="btn-group">
      <button type="button" class="btn btn-transparent"><cs-loader></cs-loader></button>
    </div>
  }
}
} @else {
  @if (viewModel.isSubmitting) {
    <ng-container *ngTemplateOutlet="loading">
    </ng-container>
  }
  @if (!viewModel.isSubmitting) {
    <ng-container [ngTemplateOutlet]="validity"></ng-container>
    <div class="cell-content">
      <span class="study-type">{{viewModel.studyTypeName}}</span>
    </div>
    <ng-container [ngTemplateOutlet]="dropdownMenu"></ng-container>
  }
}



<ng-template #loading>
  <div class="btn-group">
    <button type="button" class="btn btn-transparent"><cs-loader></cs-loader></button>
  </div>
</ng-template>

<ng-template #dropdownMenu>
</ng-template>

<ng-template #validity>
  <i class="fa fa-circle required-input-icon validity-icon"
    [ngClass]="{ 'valid-icon': viewModel.hashValidity === HashValidity.valid, 'invalid-icon': viewModel.hashValidity === HashValidity.invalid}"
  title="Study Status"></i>
</ng-template>

<ng-template #referenceCount>
  <span class="reference-count">{{referenceCountString}}</span>
</ng-template>

<cs-worksheet-item-error-message [errorMessage]="errorMessage"></cs-worksheet-item-error-message>
<cs-worksheet-context-menu-button></cs-worksheet-context-menu-button>

import { ReadonlyNamedChannelData } from './named-channel-data';

/**
 * A readonly list of named channels with data which is the same length for each channel.
 */
export class ChannelDataList {
  public readonly dataLength: number;

  /**
   * Creates a new instance of ChannelDataList.
   * @param channels The channels and their data.
   */
  constructor(
    public readonly channels: ReadonlyArray<ReadonlyNamedChannelData>) {

    // Ensure channels is not null or undefined.
    if (!this.channels) {
      this.channels = [];
    }

    // Get the length of the first channel with data.
    this.dataLength = 0;
    if (this.channels.length) {
      let firstChannelWithData = this.channels.find(v => !!v.values);
      if (firstChannelWithData) {
        this.dataLength = firstChannelWithData.values.length;
      }
    }

    // Ensure all channels have the same length as the first channel with data.
    if (channels.some(v => !!(v.values && v.values.length !== this.dataLength))) {
      throw new Error('All channels containing data must have the same data length.');
    }
  }
}

import { ChannelInterpolator } from './channel-interpolator';
import { InterpolationCoordinates } from './interpolation-coordinates';
import { ChannelInterpolatorResults, emptyChannelInterpolatorResults } from './channel-interpolator-results';
import { ChannelScalarDataAndMappings } from '../channel-scalar-data-and-mappings';
import { StudyExplorationAndScalarData } from '../load-study-exploration-and-scalar-data';
import { ProcessedExplorationMap } from '../explorations/processed-exploration-map';

/**
 * An interpolator for star designs. Note this does not actually interpolate, but instead
 * extracts the points along the sweep for the exploration dimension given in the InterpolationCoordinates.
 */
export class ChannelInterpolatorStar implements ChannelInterpolator {

  /**
   * Initializes a new instance of ChannelInterpolatorStar.
   * @param explorationAndScalarData The exploration and scalar data.
   */
  constructor(private readonly explorationAndScalarData: StudyExplorationAndScalarData) {
  }

  /**
   * @inheritdoc
   */
  public initialize() {
  }

  /**
   * @inheritdoc
   */
  public execute(channelNames: ReadonlyArray<string>, interpolationCoordinates: InterpolationCoordinates): ChannelInterpolatorResults {
    let map = this.explorationAndScalarData.explorationMap;
    let scalarData = this.explorationAndScalarData.scalarData;
    return this.sweepExtractionFunction(map, scalarData, channelNames, interpolationCoordinates);
  }

  /**
   * Function for viewing sweeps where you don't actually want to interpolate, but rather just display the points along a sweep.
   * @param map The exploration map.
   * @param scalarData The scalar data.
   * @param channelNames The names of the channels to interpolate values for.
   * @param interpolationCoordinates The coordinates to interpolate values for.
   * @returns The interpolated values for each channel at the given interpolation coordinates.
   */
  private sweepExtractionFunction(
    map: ProcessedExplorationMap,
    scalarData: ChannelScalarDataAndMappings,
    channelNames: ReadonlyArray<string>,
    interpolationCoordinates: InterpolationCoordinates): ChannelInterpolatorResults {

    // Get the exploration dimension.
    let explorationDimensionIndex = interpolationCoordinates.explorationDimensionIndex;
    let result: ChannelInterpolatorResults = emptyChannelInterpolatorResults(channelNames);

    // For each job...
    for (let pointIndex = 0; pointIndex < map.jobs.length; pointIndex++) {
      // If the job has a value in this dimension...
      if (!isNaN(map.jobs[pointIndex].sweeps[explorationDimensionIndex].subSweepIndex)) {
        // Add the value to the results for each channel.
        for (let channelName of channelNames) {
          let channelData = scalarData.getScalarData(channelName);
          let value = channelData ? channelData.values[pointIndex] : NaN;
          result[channelName].push(value);
        }
      }
    }

    return result;
  }
}

import {Injectable} from '@angular/core';
import {GetStudyJobQueryResult, GetStudyQueryResult} from '../../../generated/api-stubs';
import {getConfigNotesFromStudy, getConfigPropertiesFromStudy, getSourceConfig} from '../../worksheets/study-utilities';
import {DisplayableError} from '../../common/errors/errors';
import {StudyStagingArea} from '../study-staging-area/study-staging-area.service';
import {SourceItem} from '../configs/view-study-sources/source-item';
import { InferInputSources } from './infer-input-sources.service';

@Injectable()
export class StageInputSource {

  constructor(
    private readonly studyStagingArea: StudyStagingArea,
    private readonly inferInputSources: InferInputSources) {
  }

  public stageWholeStudy(studyResult: GetStudyQueryResult, jobResult: GetStudyJobQueryResult){
    this.studyStagingArea.setStudyInformation(studyResult.study.data.studyType, studyResult.study.name);

    const inputSources = this.inferInputSources.execute(studyResult, jobResult);

    for (const inputSource of inputSources) {
      this.execute(inputSource, studyResult, jobResult);
    }
  }

  public execute(
    source: SourceItem,
    studyResult: GetStudyQueryResult,
    jobResult?: GetStudyJobQueryResult) {

    const simVersion = jobResult ? jobResult.convertedSimVersion : studyResult.convertedSimVersion;

    const  sourceConfig = getSourceConfig(source.typeKey, studyResult, jobResult);

    if (sourceConfig) {
      const properties = getConfigPropertiesFromStudy(source.typeKey, studyResult);
      const notes = getConfigNotesFromStudy(source.typeKey, studyResult);
      this.studyStagingArea.stage(
        source.typeKey,
        source.userId,
        source.configId,
        source.name,
        sourceConfig,
        properties,
        notes,
        simVersion,
        source.isEdited);
    } else {
      throw new DisplayableError('Unknown study source type: ' + source.typeKey);
    }
  }
}

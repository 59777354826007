import {Injectable} from '@angular/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

@Injectable()
export class Dayjs {
  public create(dateTime?: string) {
    return dateTime ? dayjs(dateTime) : dayjs();
  }

  public fromNow(dateTime: string): string {
    return this.fromNowLong(dateTime, 1);
  }

  public fromNowLong(dateTimeString: string, parts: number = 1): string {
    let result = '';
    const pieceStrings: any = ['month', 'day', 'hour', 'minute', 'second'];
    let count = 0;
    let dateTime = dayjs(dateTimeString);
    const currentDateTime = dayjs();

    for(let pieceString of pieceStrings){
      let piece = currentDateTime.diff(dateTime, pieceString);
      if(piece >= 1){
        if(count > 0){
          result += ', ';
        }
        result += `${piece} ${pieceString}`;
        if(piece > 1){
          result += 's';
        }
        count++;
        if(count >= parts){
          break;
        }
      }
      dateTime = dateTime.add(piece, pieceString);
    }

    return result + ' ago';
  }

  public toDate(dateTime: string): string {
    return dayjs(dateTime).format('D MMM YYYY');
  }

  public toDateTime(dateTime: string): string {
    return dayjs(dateTime).format('HH:mm, D MMM YYYY');
  }

  public toDateTimeLong(dateTime: string): string {
    return dayjs(dateTime).format('HH:mm:ss, D MMM YYYY');
  }

  public toDateTimeUniversal(dateTime: string): string {
    return dayjs(dateTime).format('YYYY-MM-DD HH:mm:ss');
  }

  public currentIsoTime(): string{
    return dayjs().format();
  }
}

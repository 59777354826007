import {RuleResult} from './rule-result';
import {RuleLogLevel} from './rule-log-level';

export class RuleLogger {
  private _logged: RuleResult[] = [];

  public info(message: string, location?: string, priority: number = 0) {
    this._logged.push(new RuleResult(RuleLogLevel.info, message, location, priority));
  }

  public warn(message: string, location?: string,priority: number = 0) {
    this._logged.push(new RuleResult(RuleLogLevel.warning, message, location, priority));
  }

  public error(message: string, location?: string,priority: number = 0) {
    this._logged.push(new RuleResult(RuleLogLevel.error, message, location, priority));
  }

  public assert(condition: boolean, message: string, location?: string,priority: number = 0) {
    if(!condition){
      this._logged.push(new RuleResult(RuleLogLevel.error, message, location, priority));
    }
  }
  public get logged(): ReadonlyArray<RuleResult> {
    return this._logged;
  }
}

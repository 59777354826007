import { Injectable, EventEmitter, Directive } from '@angular/core';
import {AccountSettingsStub} from '../../generated/api-stubs';
import {UserState} from './user-state';
import {Output} from '@angular/core';
import {TenancyStub} from '../../generated/api-stubs';
import {UserStateTenant} from './user-state-tenant';
import { AuthenticationService, UserData } from '../identity/state/authentication.service';

@Directive()
@Injectable()
export class FetchUserState {

  @Output() changed: EventEmitter<UserState> = new EventEmitter<UserState>();

  private lastPromise: Promise<UserState>;
  private lastUserId: string;

  constructor(
    private authenticationService: AuthenticationService,
    private accountSettingsStub: AccountSettingsStub,
    private tenancyStub: TenancyStub) {
  }

  public async get(): Promise<UserState> {
    const userData = this.authenticationService.userDataSnapshot;
    let result: UserState;

    if (this.lastPromise && userData?.sub === this.lastUserId) {
      result = await this.lastPromise;
    } else {
      result = await this.fetch(userData);
    }

    return result;
  }

  public async update(): Promise<UserState> {
    const userData = this.authenticationService.userDataSnapshot;
    return await this.fetch(userData);
  }

  public wait(): Promise<UserState> {
    if (!this.lastPromise) {
      return Promise.resolve(undefined);
    }

    return this.lastPromise;
  }

  private async fetch(userData: UserData): Promise<UserState> {
    let result: UserState;
    await this.authenticationService.checkAuthentication().toPromise();
    if (this.authenticationService.isAuthenticatedSnapshot) {
      this.lastUserId = userData.sub;
      this.lastPromise = this.fetchInner(userData.tenant, userData.sub);
      result = await this.lastPromise;
    } else {
      this.lastUserId = undefined;
      this.lastPromise = Promise.resolve(undefined);
      result = undefined;
    }

    this.changed.emit(result);
    return result;
  }

  private async fetchInner(tenantId: string, userId: string): Promise<UserState> {
    let accountSettingsTask = this.accountSettingsStub.get(tenantId, userId);
    let tenantTask = this.tenancyStub.getTenant(tenantId);

    let accountSettings = await accountSettingsTask;
    let tenant = await tenantTask;

    return new UserState(
      userId,
      accountSettings.username,
      accountSettings.email,
      accountSettings.isEnabled,
      accountSettings.isTwoFactorEnabled,
      accountSettings.isMicroCanopy,
      new UserStateTenant(
        tenantId,
        tenant.name,
        tenant.shortName,
        tenant.creationDate,
        tenant.isEnabled,
        tenant.databaseId));
  }
}

<div class="card user-roles-component">
  <h1 class="card-header">Roles</h1>

  <div class="card-body">
    <cs-error-message [message]="errorMessage"></cs-error-message>

    @if (!roles) {
      <cs-loader></cs-loader>
    }

    @if (roles) {
      <div>
        @if (!roles.length) {
          <p>
            This user has no roles assigned.
          </p>
        }
        <div class="roles-list">
          @for (role of roles; track role) {
            <div class="role-indicator-box">
              <i class="fa fa-{{getRoleIcon(role)}} m-r-1 role-icon"></i> {{role}}
            </div>
          }
        </div>
        @if (isDeletedUser) {
          <p>
            Users flagged for deletion will be automatically removed within 48 hours.
          </p>
        }
        @if (isUpdating) {
          <cs-loader></cs-loader>
        }
        @if (canChangeRoles && !isUpdating) {
          <div>
            @if (!isDeletedUser && !isTenantAdministrator) {
              <button class="btn btn-secondary" (click)="setAsTenantAdministrator()">Add Tenant Administrator Role</button>
            }
            @if (!isDeletedUser && isTenantAdministrator && !isSelf) {
              <button class="btn btn-secondary" (click)="removeAsTenantAdministrator()">Remove Tenant Administrator Role</button>
            }
            @if (!isDeletedUser && !isSelf) {
              <button class="btn btn-secondary" (click)="setAsDeletedUser()">Flag for Deletion</button>
            }
            @if (isDeletedUser) {
              <button class="btn btn-secondary" (click)="removeAsDeletedUser()">Unflag for Deletion</button>
            }
          </div>
        }
      </div>
    }
  </div>
</div>

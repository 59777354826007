
/**
 * A class representing a single job in a study.
 */
export class StudyJob {
  constructor(
    public studyId: string,
    public jobIndex: number
  ) { }
}

import {ANY_TARGET, Rule} from '../runner/rule';
import {ConfigContext, ConfigNode} from '../runner/config-context';
import {RuleLogger} from '../runner/rule-logger';

export class MinMax extends Rule {
  public name = 'Min/Max';
  public configTypes = ANY_TARGET;
  public simTypes: string[] = null;

  public evaluateConfig(context: ConfigContext, log: RuleLogger) {
    context.recurseNodes((node: ConfigNode) => {
      let value = node.value;
      for (let key of Object.keys(value)) {
        if(key.endsWith('Max')) {
          let minKey = key.substring(0, key.length - 3) + 'Min';
          let max = value[key];
          let min = value[minKey];
          if(typeof max === 'number' && typeof min === 'number') {
            if(max < min){
              log.error(`Value of ${key} was less than ${minKey} (${max} < ${min}).`, node.path);
            }
          }
        }
      }
    });
  }
}

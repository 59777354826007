import { ChannelMetadata, UrlFileLoader } from '../../url-file-loader';
import { SimType } from '../../sim-type';
import { getFullyQualifiedChannelName } from '../../get-fully-qualified-channel-name';
import { GetLegacyChannelMappings } from './get-legacy-channel-mappings';
import { firstDistinct } from '../../first-distinct';
import { ChannelDataTransform, ChannelDataTransforms } from './channel-data-transforms';

export function createScalarMappedToVectorDescription(scalarName: string, xDomain: string, description: string | undefined) {
  return `Scalar ${scalarName} mapped to ${xDomain} domain. ${description || ''}`;
}

/**
 * Loads the vector metadata for a study job and sim type.
 * This includes adding dummy channels for legacy channel names, adding transform target domains, and adding
 * scalar channels to the list of vector channels.
 */
export class LoadVectorMetadataMap {
  constructor(
    private readonly urlFileLoader: UrlFileLoader,
    private readonly getLegacyChannelMappings: GetLegacyChannelMappings,
    private readonly channelDataTransforms: ChannelDataTransforms) {
  }

  /**
   * Creates a new instance of LoadVectorMetadataMap.
   * @param urlFileLoader The URL file loader.
   * @param channelDataTransforms The channel data transforms.
   * @returns The new instance.
   */
  public static create(urlFileLoader: UrlFileLoader, channelDataTransforms: ChannelDataTransforms) {
    return new LoadVectorMetadataMap(urlFileLoader, new GetLegacyChannelMappings(), channelDataTransforms);
  }

  /**
   * Loads the vector metadata for a study job and sim type.
   * This includes adding dummy channels for legacy channel names, adding transform target domains, and adding
   * scalar channels to the list of vector channels.
   * @param studyId The study ID.
   * @param jobIndex The job index.
   * @param simType The simulation type.
   * @param xDomainNames The optional list of X domain names to filter by.
   * @returns The loaded vector metadata.
   */
  public async execute(studyId: string, jobIndex: number, simType: SimType, xDomainNames?: string[]): Promise<LoadedVectorMetadataResult> {
    let channels = new ChannelSet();
    let defaultDomainName: string | undefined;
    let allDomainNames: string[] = [];

    // Load the vector metadata to ge the full list of channels for the sim type.
    let fullChannelList = await this.urlFileLoader.loadVectorMetadata(studyId, jobIndex, simType);
    if (fullChannelList) {

      let transformedFullChannelList: ChannelMetadata[] = [];

      // For each channel...
      for (let channel of fullChannelList) {
        let xDomainName = channel.xDomainName;
        let transformedDomainName = this.channelDataTransforms.getTransformedName(xDomainName);

        // If the X domain has a transformed channel name...
        if (xDomainName !== transformedDomainName) {
          // ...then update the channel with the transformed X domain name.
          channel = {
            ...channel,
            xDomainName: transformedDomainName
          };
        }

        // Push the channel to the transformed list (whether or not we transformed the X domain name).
        transformedFullChannelList.push(channel);
      }

      fullChannelList = transformedFullChannelList;

      // Pull out the default domain name from the first channel in the list (after performing transforms).
      // We assume channels are ordered in the metadata such that the first channels use the default domain for the simulation.
      if (fullChannelList.length) {
        defaultDomainName = fullChannelList[0].xDomainName;
      }

      // Get the list of all x domains for all channels.
      allDomainNames = firstDistinct(fullChannelList.map(v => v.xDomainName), v => v);

      for (let channel of fullChannelList) {
        let xDomainName = channel.xDomainName;

        // Filter by the requested x domain names, if such a filter is provided, to create the final channel list.
        if (!xDomainName || !xDomainNames || xDomainNames.indexOf(xDomainName) !== -1) {
          channels.add(channel);
        }
      }

      // If the study uses legacy channel names, add dummy channels to the list with the current equivalent channel names.
      this.addDummyChannelsIfRequired(channels);

      // Add any transform target domains to the channel list.
      this.addTransformsTargetDomainsIfRequired(channels);

      // Load the scalar channels for the study job and add them to the list of vector channels,
      // so we can overlay scalar results on the charts.
      await this.loadScalarChannels(studyId, jobIndex, simType, xDomainNames || allDomainNames, channels);
    }

    // Return the data.
    return new LoadedVectorMetadataResult(channels.channelList, channels.channelsByName, defaultDomainName, allDomainNames);
  }

  /**
   * Loads the scalar channels for the study job and adds them to the list of vector channels.
   * @param studyId The study ID.
   * @param jobIndex The job index.
   * @param simType The simulation type.
   * @param xDomainNames The list of X domain names.
   * @param channels The list of channels.
   */
  private async loadScalarChannels(
    studyId: string,
    jobIndex: number,
    simType: SimType,
    xDomainNames: ReadonlyArray<string>,
    channels: ChannelSet) {

    // Load the scalar results.
    let scalarResults = await this.urlFileLoader.loadScalarResultsForSim(studyId, jobIndex, simType);
    if (!scalarResults) {
      return;
    }

    // Get all the X domains we need to create scalar channels for.
    let xDomains = xDomainNames
      .map(v => channels.channelsByName[getFullyQualifiedChannelName(v, simType)])
      .filter(v => !!v)
      .filter(v => !!v.binaryFormat);

    for (let scalarResult of scalarResults) {
      for (let xDomain of xDomains) {

        // Add each scalar result to each domain if it doesn't already exist.
        channels.addIfNew({
          name: scalarResult.name,
          simType,
          description: createScalarMappedToVectorDescription(scalarResult.name, xDomain.name, scalarResult.description),
          units: scalarResult.units,
          xDomainName: xDomain.name,
          binaryFormat: xDomain.binaryFormat, // We need to store the number of points to generate the scalar data.
          loaderMetadata: { scalarName: scalarResult.name, isScalarResult: true },
        });
      }
    }
  }

  /**
   * Adds the transform target domains to the list of channels if they are not already present.
   * @param channels The list of channels.
   */
  private addTransformsTargetDomainsIfRequired(
    channels: ChannelSet) {
    for (let transform of this.channelDataTransforms.transforms) {
      this.addTransformTargetDomainsIfRequired(channels, transform);
    }
  }

  /**
   * Adds the transform target domain to the list of channels if it is not already present.
   * @param channels The list of channels.
   * @param transform The transform whose target domain should be added.
   */
  private addTransformTargetDomainsIfRequired(
    channels: ChannelSet,
    transform: ChannelDataTransform) {

    let simTypes = new Set<string>();

    // For each channel...
    for (let channel of channels.channelList) {

      // Continue channel X domain matches the transform target domain.
      if (channel.xDomainName !== transform.targetDomain) {
        continue;
      }

      // Otherwise if we have not already added the transform target domain for this sim type...
      if (!simTypes.has(channel.simType)) {
        simTypes.add(channel.simType);

        // ... and the channel doesn't already exist in the list...
        if (!channels.channelsByName[getFullyQualifiedChannelName(transform.targetDomain, channel.simType)]) {

          // ... add the channel to the list.
          channels.add({
            name: transform.targetDomain,
            simType: channel.simType,
            description: '',
            units: '()',
            xDomainName: transform.targetDomain,
            binaryFormat: channel.binaryFormat,
          });
        }
      }
    }
  }

  /**
   * If the study uses legacy channel names, add dummy channels to the list with the current equivalent channel names.
   * @param channels The list of channels.
   */
  private addDummyChannelsIfRequired(channels: ChannelSet) {
    let legacyMappings = this.getLegacyChannelMappings.execute();
    for (let mapping of legacyMappings) {
      this.addDummyChannelIfRequired(channels, mapping.currentName, mapping.legacyName);
    }
  }

  /**
   * Adds a dummy channel to the list if the source channel is present and the dummy channel is not.
   * @param channels The list of channels to add the dummy channel to.
   * @param dummyChannelName The name of the dummy channel to add.
   * @param sourceChannelName The name of the source channel to check for.
   */
  private addDummyChannelIfRequired(
    channels: ChannelSet,
    dummyChannelName: string,
    sourceChannelName: string) {

    let sourceChannel = channels.channelsByName[sourceChannelName];
    if (!sourceChannel) {
      return;
    }

    if (channels.channelsByName[dummyChannelName]) {
      return;
    }

    let channel: ChannelMetadata = {
      ...sourceChannel,
      name: dummyChannelName,
    };

    channels.add(channel);
  }
}

/**
 * A set of channels.
 */
class ChannelSet {
  public readonly channelList: ChannelMetadata[] = [];
  public readonly channelsByName: ChannelMetadataMap = {};

  /**
   * Adds a channel if it is not already present.
   * @param channel The channel to add.
   */
  public addIfNew(channel: ChannelMetadata) {
    if (this.channelsByName[channel.name]) {
      return;
    }

    this.add(channel);
  }

  /**
   * Adds a channel to the list.
   * @param channel The channel to add.
   */
  public add(channel: ChannelMetadata) {
    this.channelList.push(channel);
    this.channelsByName[channel.name] = channel;
    this.channelsByName[getFullyQualifiedChannelName(channel.name, channel.simType)] = channel;
  }
}

/**
 * The result of loading the vector metadata for a study job and sim type.
 */
export class LoadedVectorMetadataResult {

  /**
   * Creates a new instance of LoadedVectorMetadataResult.
   * @param list The list of channel metadata.
   * @param map The map of channel name to channel metadata.
   * @param defaultDomainName The default domain name.
   * @param allDomainNames All found domain names.
   */
  constructor(
    public readonly list: ReadonlyArray<ChannelMetadata>,
    public readonly map: ChannelMetadataMap,
    public readonly defaultDomainName: string | undefined,
    public readonly allDomainNames: ReadonlyArray<string>) {
  }
}

/**
 * A map of channel metadata by channel name.
 */
export interface ChannelMetadataMap {
  [channelName: string]: ChannelMetadata;
}

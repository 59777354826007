import { ExplorationMap } from '../exploration-map';

/**
 * Gets the metadata about each exploration job from the exploration map.
 */
export class GetExplorationJobsMetadataFromExplorationMap {

  /**
   * Returns the metadata about each exploration job, which includes the job names and the
   * indices of the sweep values (with the string "NaN" replaced by NaN).
   * @param explorationMap The exploration map.
   * @returns The metadata about each exploration job.
   */
  public execute(explorationMap: ExplorationMap): ExplorationJobsMetadata {
    if (explorationMap.jobNames.length !== explorationMap.indexMatrix.length) {
      throw new Error('List of job names was a different length to list of indices in exploration map.');
    }

    return new ExplorationJobsMetadata(
      explorationMap.jobNames.map((jobName, i) => new ExplorationJobMetadata(
        jobName,
        this.removeNaNStrings(explorationMap.indexMatrix[i]))));
  }

  /**
   * Replaces the string "NaN" with NaN in the input indices.
   * @param inputIndices The input indices.
   * @returns The input indices with the string "NaN" replaced by NaN.
   */
  private removeNaNStrings(inputIndices: ReadonlyArray<(number | 'NaN')>): ReadonlyArray<number> {
    const result = Array(inputIndices.length);
    for (let i = 0; i < inputIndices.length; ++i) {
      result[i] = inputIndices[i] === 'NaN' ? NaN : inputIndices[i];
    }
    return result;
  }
}

/**
 * The metadata about each exploration job.
 */
export class ExplorationJobsMetadata {

  /**
   * Creates an instance of ExplorationJobsMetadata.
   * @param jobs The metadata about each exploration job.
   */
  constructor(
    public readonly jobs: ReadonlyArray<ExplorationJobMetadata>) {
  }
}

/**
 * The metadata about a single exploration job.
 */
export class ExplorationJobMetadata {

  /**
   * Creates an instance of ExplorationJobMetadata.
   * @param name The name of the exploration job.
   * @param inputSweepIndices The indices of the sweep values for the exploration job.
   */
  constructor(
    public readonly name: string,
    public readonly inputSweepIndices: ReadonlyArray<number>) {
  }
}


/**
 * Returns the a new list containing the distinct items from the supplied list.
 * When multiple items have the same key, only the first item is included in the result.
 * @param list The list of items.
 * @param getKey Function that returns the key of the item.
 */
export function firstDistinct<T, K>(list: T[], getKey: (item: T) => K) {
  let result: T[] = [];
  let set = new Set<K>();
  for (let item of list) {
    let key = getKey(item);
    if (!set.has(key)) {
      result.push(item);
      set.add(key);
    }
  }
  return result;
}

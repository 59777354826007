import { ChartSettings } from '../chart-settings';
import { Margin } from '../margin';

/**
 * The settings for the 3D track viewer.
 */
export class TrackViewer3dSettings extends ChartSettings {

  /**
   * Creates a new instance of TrackViewer3dSettings.
   * @param sourceCount The number of sources.
   * @returns The settings for the 3D track viewer.
   */
  public static build(sourceCount: number): TrackViewer3dSettings {
    return new TrackViewer3dSettings(sourceCount);
  }

  /**
   * Creates a new instance of TrackViewer3dSettings.
   * @param sourceCount The number of sources.
   */
  constructor(sourceCount: number) {
    super(sourceCount);
    this.svgPadding = new Margin(5, 5, 18, 5);
  }

  /**
   * Gets the space between plots.
   */
  public get spaceBetweenPlots(): number {
    return 6;
  }
}

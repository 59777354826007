import { IEditChannelsType, NavigationStationViewer } from '../../navigation-station/navigation-station-viewer';
import { Subject, Subscription } from 'rxjs';
import { IEditChannelsOptions, PaneChannelLayout, SiteHooks } from '../../site-hooks';
import { SourceData } from '../data-pipeline/types/source-data';
import { IPopulatedMultiPlotSide } from '../data-pipeline/types/i-populated-multi-plot-side';
import { IPopulatedMultiPlotLayout } from '../data-pipeline/types/i-populated-multi-plot-layout';
import { IMultiPlotLayout } from '../data-pipeline/types/i-multi-plot-layout';
import { ChannelNameStyle } from '../channel-data-loaders/channel-name-style';
import { SharedState } from '../shared-state';
import { SourceLoaderSet } from '../channel-data-loaders/source-loader-set';
import {
  EDIT_TYPE_COLUMNS,
} from '../multi-plot-viewer-base/multi-plot-viewer-base';
import { ParallelCoordinatesViewerSettings } from './parallel-coordinates-viewer-settings';
import { Utilities } from '../../utilities';
import * as d3 from '../../d3-bundle';
import { HTMLDivSelection, SVGSelection } from '../../untyped-selection';
import { SourceLoaderUtilities } from '../channel-data-loaders/source-loader-utilities';
import { BLUE, GREEN, INDEX_DOMAIN_NAME } from '../../constants';
import {
  DimensionLookup,
  Dimension,
  ParallelCoordinatesData,
  PointsInDimensions
} from './parallel-coordinates-types';
import { ParallelCoordinatesDataRenderer } from './parallel-coordinates-data-renderer';
import { ChannelType, ExplorationData } from '../channel-data-loaders/study-source-loader';
import { DisplayableError } from '../../displayable-error';
import { ICanvasData } from '../canvas-utilities';
import { GetInterpolatedChannelValueAtDomainValue } from '../channel-data-loaders/get-interpolated-channel-value-at-domain-value';
import { ParallelCoordinatesAxisRenderer } from './parallel-coordinates-axis-renderer';
import { IErrorHandler } from './error-handler';
import { ParallelCoordinatesDataEventHandler } from './parallel-coordinates-data-event-handler';
import { ChannelInterpolator } from '../channel-data-loaders/interpolation/channel-interpolator';
import { ParallelCoordinatesInterpolationLineRenderer } from './parallel-coordinates-interpolation-line-renderer';
import { ColorCommonInstance } from 'd3-color';
import { DataPipeline } from '../data-pipeline/data-pipeline';
import { FilteredStudyExplorationAndScalarDataCache } from '../channel-data-loaders/filtered-study-exploration-and-scalar-data-cache';

export const PARALLEL_COORDINATES_VIEWER_TYPE = 'parallelCoordinatesViewer';

export const SHOW_ALL_INPUT_SUB_SWEEPS = false;

/**
 * The parallel coordinates viewer.
 * A layout for the parallel coordinates plot is a single row (with no channels) and one column per dimension (each with one channel).
 * This allows us to use the standard data pipeline to render the data.
 */
export class ParallelCoordinatesViewer implements NavigationStationViewer, IErrorHandler {

  /**
   * The error event. Raised when an error occurs.
   */
  public errorEvent: Subject<string> = new Subject<string>();

  /**
   * The ID of the element containing the viewer.
   */
  private elementId?: string;

  /**
   * The settings for the parallel coordinates viewer.
   */
  private settings?: ParallelCoordinatesViewerSettings;

  /**
   * The parent element of the viewer, which is created inside the element given by the `elementId` field.
   */
  private parent?: HTMLDivSelection;

  /**
   * The SVG element for the viewer.
   */
  private svg?: SVGSelection;

  /**
   * The set of source loaders.
   */
  private readonly sourceLoaderSet: SourceLoaderSet;

  /**
   * The list of source data (the loaded channels for a data source).
   */
  private sourceData?: ReadonlyArray<SourceData>;

  /**
   * The parallel coordinates data.
   */
  private parallelCoordinatesData?: ParallelCoordinatesData;

  /**
   * The subscriptions for the parallel coordinates viewer, which need to be disposed with the viewer.
   */
  private subscriptions: Subscription = new Subscription();

  /**
   * The data renderer, which renders the data for the parallel coordinates viewer to canvases.
   */
  private dataRenderer?: ParallelCoordinatesDataRenderer;

  /**
   * The data event handler, which handles events such as mouseover and click on the parallel coordinates data.
   */
  private dataEventHandler?: ParallelCoordinatesDataEventHandler;

  /**
   * The axis renderer, which renders the axes for the parallel coordinates viewer.
   */
  private axisRenderer?: ParallelCoordinatesAxisRenderer;

  /**
   * The interpolation line renderer, which renders the interpolation line for the parallel coordinates viewer.
   */
  private interpolationLineRenderer?: ParallelCoordinatesInterpolationLineRenderer;

  /**
   * The last layout which was filtered by the viewer in the `processLayout` method.
   */
  private filteredLayout?: IMultiPlotLayout;

  /**
   * Create a new instance of the parallel coordinates viewer.
   * @param layout The chart layout.
   * @param channelNameStyle The channel name style.
   * @param dataPipeline The data pipeline.
   * @param sharedState The shared state.
   * @param siteHooks The site hooks.
   * @param channelInterpolator The channel interpolator.
   * @param filteredStudyExplorationAndScalarDataCache The filtered study exploration and scalar data cache.
   */
  constructor(
    private layout: IMultiPlotLayout,
    private readonly channelNameStyle: ChannelNameStyle,
    private readonly dataPipeline: DataPipeline,
    private readonly sharedState: SharedState,
    private readonly siteHooks: SiteHooks,
    private readonly channelInterpolator: ChannelInterpolator,
    private readonly filteredStudyExplorationAndScalarDataCache: FilteredStudyExplorationAndScalarDataCache
    ) {
    this.sourceLoaderSet = this.sharedState.sourceLoaderSet;
  }

  /**
   * Create a new instance of the parallel coordinates viewer.
   * @param layout The chart layout.
   * @param channelNameStyle The channel name style.
   * @param sharedState The shared state.
   * @param siteHooks The site hooks.
   * @param channelInterpolator The channel interpolator.
   * @param filteredStudyExplorationAndScalarDataCache The filtered study exploration and scalar data cache.
   * @returns A new instance of the parallel coordinates viewer.
   */
  public static create(
    layout: IMultiPlotLayout,
    channelNameStyle: ChannelNameStyle,
    sharedState: SharedState,
    siteHooks: SiteHooks,
    channelInterpolator: ChannelInterpolator,
    filteredStudyExplorationAndScalarDataCache: FilteredStudyExplorationAndScalarDataCache): ParallelCoordinatesViewer {

    let interpolator = new GetInterpolatedChannelValueAtDomainValue();
    return new ParallelCoordinatesViewer(
      layout,
      channelNameStyle,
      DataPipeline.create(INDEX_DOMAIN_NAME, siteHooks, sharedState.sourceLoaderSet, channelNameStyle, interpolator),
      sharedState,
      siteHooks,
      channelInterpolator,
      filteredStudyExplorationAndScalarDataCache
      );
  }

  /**
   * Gets the populated layout.
   */
  public get populatedLayout(): IPopulatedMultiPlotLayout {
    return this.layout as IPopulatedMultiPlotLayout;
  }

  /**
   * Build the parallel coordinates viewer.
   * @param elementId The ID of the element containing the viewer.
   * @returns A promise that resolves when the viewer is built.
   */
  public async build(elementId: string): Promise<any> {
    this.elementId = elementId;

    // Load the channel data from the layout.
    await this.loadFromLayout(this.layout);

    if (!this.exists || !this.sourceData) {
      // If the element given by elementId doesn't exist, or if there is no source data, don't build the viewer.
      return;
    }

    let sourceCount = this.sourceData.length;

    // Get the settings for the viewer, and set the SGV size.
    this.settings = ParallelCoordinatesViewerSettings.build(sourceCount);
    this.settings.svgSize = Utilities.getRequiredSvgSize(this.elementId, this.settings.svgSize);

    // Process the layout through the data pipeline.
    this.processLayout();

    // Create a div to contain the viewer.
    this.parent = this.select()
      .append<HTMLDivElement>('div')
      .attr('class', `parallel-coordinates-viewer-container canvas-viewer`);

    // Create the SVG element for the viewer.
    this.svg = this.parent
      .append<SVGElement>('svg')
      .attr('width', this.settings.svgSize.width)
      .attr('height', this.settings.svgSize.height)
      .attr('class', `parallel-coordinates-viewer`)
      .attr('id', 'svg');

    // Create the canvas data.
    let canvasData: ICanvasData = {
      parent: this.parent,
      settings: this.settings
    };

    // Create the data renderer.
    this.dataRenderer = new ParallelCoordinatesDataRenderer(
      this.sharedState,
      this.settings,
      canvasData);

    // Handle the user requesting the removal of a line.
    this.dataRenderer.on('dataRemovalRequested', (d: number) => {
      this.filteredStudyExplorationAndScalarDataCache.addToBadIndices(d);
      this.sourceLoaderSet.raiseChangedEvent();
      this.sharedState.dimensions.triggerAllNews();
    });

    // Create the data event handler.
    this.dataEventHandler = new ParallelCoordinatesDataEventHandler(
      this,
      this.svg,
      this.sharedState,
      this.siteHooks,
      this.settings,
      this.dataRenderer);

    // Create the axis renderer.
    this.axisRenderer = new ParallelCoordinatesAxisRenderer(this.settings, this.siteHooks);
    this.axisRenderer
      .on('dragging', () => this.renderAll(false, true))
      .on('reordered', (columns: IPopulatedMultiPlotSide[]) => {
        // If the dimensions are reordered, update the column list and re-render everything.
        this.layout.columns = columns;
        this.renderAll(true, true);
      })
      .on('reversed', () => this.renderAll(true, true))
      .on('filtered', () => this.renderAll(false, false))
      .on('zoomed', () => this.renderAll(true, true));

    // Create the interpolation line renderer.
    this.interpolationLineRenderer = new ParallelCoordinatesInterpolationLineRenderer(
      this.settings,
      this.svg,
      this.dataRenderer,
      this.sharedState,
      this.channelInterpolator);

    // Render the viewer.
    this.renderAll(true, true);

    // Subscribe to the window resize event.
    this.subscriptions.add(this.sharedState.windowResizeNews.subscribe((code: number) => this.resizeChart()));

    // Subscribe to the source loader set changed event.
    this.subscriptions.add(this.sourceLoaderSet.changed.subscribe(() => this.handleSourcesChanged()));
  }

  /**
   * Render the parallel coordinates viewer.
   * @param processLayout Whether we should process the layout.
   * @param redrawAllData Whether we should redraw all the data.
   * @param disableAnimations Whether we should disable animations.
   */
  private renderAll(processLayout: boolean, redrawAllData: boolean, disableAnimations: boolean = false) {
    if (!this.exists || !this.svg) {
      return;
    }

    if (processLayout) {
      // Process the layout, and set the data on all child components.
      this.processLayout();
      this.setAllData();
    }

    this.updatePlotSizes();

    // Call render on the child components.
    if (this.dataRenderer) {
      this.dataRenderer.render(redrawAllData);
    }

    if (this.axisRenderer) {
      this.axisRenderer.render(this.svg);
    }

    if (this.interpolationLineRenderer) {
      this.interpolationLineRenderer.render();
    }
  }

  /**
   * Called when the window size changes and we need to update
   */
  private updatePlotSizes() {
    if (!this.settings || !this.parallelCoordinatesData) {
      return;
    }

    // Update the plot sizes in the layout.
    this.dataPipeline.updatePlotSizes.execute(this.populatedLayout, this.settings);

    // Update the plot sizes in each dimension, based on the layout.
    for (let dimension of this.parallelCoordinatesData.dimensionList) {
      dimension.updatePlotSizes();
    }
  }

  /**
   * Set the data on all child components.
   */
  private setAllData() {
    if (!this.parallelCoordinatesData) {
      return;
    }

    if (this.dataRenderer) {
      this.dataRenderer.setData(this.parallelCoordinatesData);
    }

    if (this.dataEventHandler) {
      this.dataEventHandler.setData(this.parallelCoordinatesData);
    }

    if (this.axisRenderer) {
      this.axisRenderer.setData(this.parallelCoordinatesData);
    }

    if (this.interpolationLineRenderer) {
      this.interpolationLineRenderer.setData(this.parallelCoordinatesData);
    }
  }

  /**
   * Create the parallel coordinates data object.
   */
  private createParallelCoordinatesData() {
    let dimensionCount = this.populatedLayout.processed.plots.length;

    let dimensionList: Dimension[] = [];
    let dimensionsByName: DimensionLookup = {};
    let outputDimensionIndex: number = 0;

    // Create each dimension.
    for (let dimensionIndex = 0; dimensionIndex < dimensionCount; dimensionIndex++) {
      let plot = this.populatedLayout.processed.plots[dimensionIndex];
      let dimension = new Dimension(dimensionList, plot, outputDimensionIndex);
      dimensionList.push(dimension);
      dimensionsByName[dimension.channel.name] = dimension;

      if (dimension.explorationData.channelType === ChannelType.output) {
        outputDimensionIndex += 1;
      }
    }

    // Create each line.
    // We know every dimension has data because we filtered out the ones that didn't.
    let sourcesData = dimensionList.map(v => v.channel.sources[0].data);
    let lineCount = sourcesData[0].length;
    let lines = new Array<PointsInDimensions>(lineCount);
    for (let lineIndex = 0; lineIndex < lineCount; ++lineIndex) {
      let line = new Array<number>(dimensionCount);
      for (let dimensionIndex = 0; dimensionIndex < dimensionCount; dimensionIndex++) {
        line[dimensionIndex] = sourcesData[dimensionIndex][lineIndex];
      }
      lines[lineIndex] = line;
    }

    // Create color scale over first output dimension range.
    let colorDimension
      = dimensionList.find(v => v.channel.sources[0].getSourceData<ExplorationData>().channelType === ChannelType.output)
      || dimensionList[0];
    let colorDimensionIndex = colorDimension.plot.plotIndex;
    let zoomDomain = colorDimension.zoomDomain;
    let colorScaleDomain = zoomDomain ? [...zoomDomain] : [colorDimension.channel.minimum, colorDimension.channel.maximum];
    let colorScale = d3.scaleLinear<string>()
      .domain(colorScaleDomain)
      .range([GREEN, BLUE])
      .interpolate(minMaxInterpolateHcl);

    // Set the parallel coordinates data.
    this.parallelCoordinatesData = new ParallelCoordinatesData(this.layout, dimensionList, dimensionsByName, lines, colorScale, colorDimensionIndex);
  }

  /**
   * Handle the sources changed event by reloading the data and rendering the viewer.
   */
  private async handleSourcesChanged() {
    try {
      if (!this.settings || !this.sourceData) {
        return;
      }

      await this.loadFromLayout();
      this.settings.sourceCount = this.sourceData.length;
      this.renderAll(true, true);
    } catch (error) {
      this.setError(this.siteHooks.getFriendlyErrorAndLog(error));
    }
  }

  /**
   * Process the layout for the parallel coordinates viewer.
   */
  private processLayout() {
    if (this.layout.columns.length === 0) {
      throw new DisplayableError('No columns specified.');
    }

    if (this.layout.rows.length === 0) {
      throw new DisplayableError('No row specified.');
    }

    if (!this.sourceData) {
      return;
    }

    // Process the layout using the standard data pipeline.
    this.dataPipeline.processLayout.execute(this.layout, this.sourceData);

    // If the layout has changed, we need to update the filtered columns
    // and re-process the layout.
    if (this.filteredLayout !== this.layout) {

      this.filteredLayout = this.layout;
      // We're going to hide some columns if the data doesn't have more than one value,
      // so update the isHidden state and then process again.
      for (let column of this.populatedLayout.columns) {
        if (column.channels.length
          && column.processed.channels[0].sources.length
          && column.processed.channels[0].sources[0].data
          && column.processed.channels[0].sources[0].data.length
          && (
            // Only hide input columns with a single unique value. See "Failed First Job" automated test page.
            column.processed.channels[0].sources[0].getSourceData<ExplorationData>().channelType !== ChannelType.input
            || Utilities.containsUnique(column.processed.channels[0].sources[0].data, 2)
          )) {
          column.transient.isHidden = false;
        } else {
          column.transient.isHidden = true;
        }
      }

      // Process the layout again with the filtered columns removed.
      this.dataPipeline.processLayout.execute(this.layout, this.sourceData);
    }

    // Create the parallel coordinates data.
    this.createParallelCoordinatesData();
  }

  /**
   * Resize the chart based on the DOM element size.
   */
  private resizeChart() {
    try {
      if (!this.settings || !this.elementId || !this.svg) {
        return;
      }

      this.settings.svgSize = Utilities.getRequiredSvgSize(this.elementId, this.settings.svgSize);
      this.svg.attr('width', this.settings.svgSize.width);
      this.svg.attr('height', this.settings.svgSize.height);

      // If the layout is in the middle of being updated, we may not be able to render.
      if (this.layout && this.layout.processed) {
        this.renderAll(true, true);
      }
    } catch (error) {
      this.setError(this.siteHooks.getFriendlyErrorAndLog(error));
    }
  }

  /**
   * Dispose of the parallel coordinates viewer.
   */
  public dispose() {
    this.subscriptions.unsubscribe();

    if (this.interpolationLineRenderer) {
      this.interpolationLineRenderer.dispose();
    }
  }

  /**
   * Gets an unprocessed layout that can be persisted as a custom layout.
   */
  public getLayout() {
    let unprocessedLayout = this.dataPipeline.getUnprocessedLayout.execute(this.layout);

    // We have to remove the input columns as these will be added back in when the layout is loaded,
    // and will change depending on the exploration that has been run.
    this.removeInputColumnsFromLayout(unprocessedLayout);

    return unprocessedLayout;
  }

  /**
   * Set a new layout on the viewer.
   * @param layout The new layout.
   */
  public async setLayout(layout: any): Promise<any> {
    await this.loadFromLayout(layout);
    this.renderAll(true, true);
  }

  /**
   * Loads the channel data for the channels in the given layout.
   * @param inputLayout The input layout.
   */
  private async loadFromLayout(inputLayout?: IMultiPlotLayout) {
    if (inputLayout) {
      upgradeParallelCoordinatesViewerLayout(inputLayout);

      // Add back in the input columns to the loaded layout, as these are removed on saving as they will
      // be different depending on the exploration that has been run.
      await this.addInputColumnsToLayout(inputLayout);

      this.layout = await this.dataPipeline.getValidatedLayout.execute(inputLayout);
    }

    this.sourceData = await this.dataPipeline.loadChannelData.execute(this.layout);
  }

  /**
   * Adds the current input columns to the given layout.
   * @param layout The layout to add the input columns to.
   * @returns A promise that resolves when the input columns have been added.
   */
  private async addInputColumnsToLayout(layout: IMultiPlotLayout): Promise<void> {
    if (!this.sourceLoaderSet.sources.length) {
      return;
    }

    let source = this.sourceLoaderSet.sources[0];
    let requestableChannels = await source.getRequestableChannels(this.channelNameStyle, INDEX_DOMAIN_NAME);
    let inputs = requestableChannels
      .filter(v => {
        let explorationData = v.getSourceData<ExplorationData>();
        return explorationData
          && explorationData.channelType === ChannelType.input
          && (SHOW_ALL_INPUT_SUB_SWEEPS || explorationData.inputDimensionParameterIndex === 0);
      });
    inputs.reverse();
    for (let input of inputs) {
      layout.columns.unshift({
        channels: [{ name: input.name }],
        relativeSize: 1,
      });
    }
  }

  /**
   * Removes the input columns from the given layout so it can be saved as a custom layout.
   * @param layout The layout to remove the input columns from.
   */
  private removeInputColumnsFromLayout(layout: IMultiPlotLayout) {
    if (!this.parallelCoordinatesData) {
      return;
    }

    for (let i = layout.columns.length - 1; i >= 0; --i) {
      let channelName = layout.columns[i].channels[0].name;
      let dimension = this.parallelCoordinatesData.dimensionsByName[channelName];
      // Dimension may not exist for output channels as we are looking up by requested name, not display name.
      let isInputDimension = dimension && dimension.isInputDimension;
      if (isInputDimension) {
        layout.columns.splice(i, 1);
      }
    }
  }

  /**
   * Gets the edit channel options for the given edit type.
   * @param editType The edit type.
   * @returns The edit channel options.
   */
  public async getEditChannelsOptions(editType: IEditChannelsType): Promise<IEditChannelsOptions> {
    let layout = this.getLayout();
    let channels = await SourceLoaderUtilities.getDistinctRequestableChannels(this.sourceLoaderSet.sources, this.channelNameStyle, INDEX_DOMAIN_NAME);
    return {
      channels,
      panes: layout.columns,
      oneChannelPerPane: true
    };
  }

  /**
   * Gets the list of valid edit operations for the viewer.
   * @returns The list of valid edit operations.
   */
  public getEditChannelsTypes(): IEditChannelsType[] {
    return [
      {
        name: 'Edit',
        icon: 'navicon fa-rotate-90',
        id: EDIT_TYPE_COLUMNS
      }
    ];
  }

  /**
   * Sets the result of an edit operation on the viewer.
   * @param editType The edit type.
   * @param result The result of the edit operation.
   */
  public async setEditChannelsResult(editType: IEditChannelsType, result: PaneChannelLayout): Promise<void> {
    let layout = this.getLayout();
    let side = result.map(v => ({
      channels: v.channels,
      relativeSize: v.relativeSize || 1,
      processed: undefined
    }));

    layout.columns = side;

    await this.setLayout(layout);
  }

  /**
   * Whether the viewer can export data.
   * @returns false
   */
  public canExportData(): boolean {
    return false;
  }

  /**
   * Whether the viewer can import data.
   * @returns false
   */
  public canImportData(): boolean {
    return false;
  }

  /**
   * Whether the viewer can stack diagonals.
   * @returns false
   */
  public canStackDiagonals(): boolean {
    return false;
  }

  /**
   * An empty implementation of the importCsvData method, as this viewer does not support importing data.
   * @param name The name of the file.
   * @param fileContent The content of the file.
   */
  public importCsvData(name: string, fileContent: string) {
  }

  /**
   * An empty implementation of the exportCsvData method, as this viewer does not support exporting data.
   * @returns A promise that resolves when the data has been exported.
   */
  public exportCsvData(): Promise<void> {
    return Promise.resolve();
  }

  /**
   * Called when an error occurs in the viewer.
   * @param message The error message.
   */
  public setError(message: string) {
    this.errorEvent.next(message);
  }

  /**
   * A D3 select operation which automatically starts by selecting the viewer element.
   * @param selection The selection to make.
   * @returns The selection.
   */
  private select(selection: string = '') {
    return d3.select('#' + this.elementId + ' ' + selection);
  }

  /**
   * Whether the viewer element exists in the DOM.
   */
  private get exists(): boolean {
    return !!(this.elementId && document.getElementById(this.elementId));
  }
}

/**
 * Upgrade the parallel coordinates viewer layout from the old format to the current format.
 * @param layout The layout to upgrade.
 * @returns The upgraded layout.
 */
export function upgradeParallelCoordinatesViewerLayout(layout: any) {
  // NOTE: This also happens now as part of a document upgrade in the API,
  // but we're keeping this here for safety and because local chart JSONs use it.
  // Remove it if you don't think it's needed anymore.
  delete layout.name;
  delete layout.xDomain;

  if (layout.columns) {
    return;
  }

  layout.rows = [];
  layout.columns = layout.panes.filter((v: any) => v.channels && v.channels.length);
  layout.columns.forEach((v: any) => {
    if (v.direction === -1) {
      for (let i = 0; i < v.channels.length; ++i) {
        if (typeof v.channels[i] === 'string') {
          v.channels[i] = {
            name: v.channels[i]
          };
        }

        v.channels[i].reverseAxis = true;
      }
    }

    delete v.direction;
  });

  delete layout.panes;
  delete layout.xDomains;
}

/**
 * Returns a delegate that performs an interpolation between two HCL colors.
 * @param a The first color.
 * @param b The second color.
 * @returns The delegate which performs the interpolation.
 */
function minMaxInterpolateHcl(a: string | ColorCommonInstance, b: string | ColorCommonInstance) {
  let inner = d3.interpolateHcl(a, b);
  return (value: number) => {
    if (value < 0) {
      value = 0;
    }

    if (value > 1) {
      value = 1;
    }

    return inner(value);
  };
}

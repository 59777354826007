import { Injectable } from '@angular/core';
import { Router, UrlTree, NavigationExtras } from '@angular/router';
import { GetSimVersion } from '../../common/get-sim-version.service';
import { ConfigViewModel } from '../config-view-model';
import { RowItemViewModel } from '../row-item-view-model';
import { StudyViewModel } from '../study-view-model';
import { WorksheetContext } from './worksheet-command';

/**
 * Service for generating URLs which will navigate to row items.
 */
@Injectable()
export class RowItemUrlService {

  /**
   * Creates an instance of RowItemUrlService.
   * @param getSimVersion The service for getting the current sim version.
   * @param router The router.
   */
  constructor(
    private readonly getSimVersion: GetSimVersion,
    private readonly router: Router
    ) { }

  /**
   * Generates a URL tree for navigating to a row item.
   * @param target The target row item.
   * @param worksheetContext The worksheet context.
   * @returns The URL tree.
   */
  public generate<T extends RowItemViewModel>(target: T, worksheetContext: WorksheetContext): UrlTree{
    const currentSimVersion = this.getSimVersion.currentSimVersion;

    let navigationExtras: NavigationExtras;
    let routePrefix = '/';
    if(!worksheetContext.isDocked){
      // If the worksheet isn't docked, we want a URL which loads the row item over the worksheet.
      navigationExtras = { relativeTo: worksheetContext.route };
      routePrefix = '';
    }

    if(target instanceof StudyViewModel && target.isPopulated){
      return this.router.createUrlTree([
        routePrefix + 'studies',
        target.populated.reference.tenantId,
        target.populated.reference.targetId],
        navigationExtras);
    } else if (target instanceof ConfigViewModel && target.isPopulated) {
      if(target.isTelemetry) {
        if(target.populated.reference.tenant){
          return this.router.createUrlTree([
            routePrefix + 'studies',
            target.populated.reference.tenant.tenantId,
            target.populated.reference.tenant.targetId],
            navigationExtras);
        } else {
          return new UrlTree();
        }
      } else {
        if(target.populated.reference.tenant){
          return this.router.createUrlTree([
            routePrefix + 'configs',
            target.unpopulated.configTypePluralKey,
            target.populated.reference.tenant.tenantId,
            target.populated.reference.tenant.targetId,
            'edit'],
            navigationExtras);
        } else if(target.populated.reference.default){
          return this.router.createUrlTree([
            routePrefix + 'default-configs',
            currentSimVersion,
            target.unpopulated.configTypePluralKey,
            target.populated.reference.default.name,
            'edit'],
            navigationExtras);
        } else {
          return new UrlTree();
        }
      }
    } else {
      return new UrlTree();
    }
  }
}

<div class="linting-engine-runner">
  <cs-error-message [message]="errorMessage"></cs-error-message>

  <!--p *ngIf="loaded">
  <button class="btn btn-smart btn-secondary" *ngIf="status !== ResultStatus.running" [disabled]="status === ResultStatus.running"
  (click)="run()">Run Analysis</button>
</p-->

@for (task of tasks; track task) {
  <div class="task-container card">
    <h1 class="card-header">
      {{task.name}}
      <span class="task-type">{{task.executionType}}</span>
      <span class="task-status">{{task.status === ResultStatus.completed ? '' : task.status}}</span>
    </h1>
    @if (task.status !== ResultStatus.pending) {
      <div class="card-body">
        <cs-error-message [message]="task.errorMessage"></cs-error-message>
        @if (task.status === ResultStatus.running) {
          <cs-loader></cs-loader>
        }
        @if (task.result) {
          <div>
            <p>
              <strong>{{task.result.completedRules.length}}</strong> rule(s) completed,
              <strong>{{task.result.skippedRules.length}}</strong> skipped.
              @if (task.result.ruleErrors.length) {
                <strong>{{task.result.ruleErrors.length}}</strong> rule(s) failed to run.
              }
            </p>
            @if (!task.result.ruleResults.length) {
              <div class="alert alert-success">
                Nothing to report.
              </div>
            }
            @for (result of task.result.getOrderedRuleResults(); track result) {
              <div class="rules-section">
                <div class="alert rule-alert"
                  [ngClass]="{ 'alert-info': result.level === RuleLogLevel.info, 'alert-warning': result.level === RuleLogLevel.warning, 'alert-danger': result.level === RuleLogLevel.error }" >
                  <span class="result-name">{{result.ruleName}}</span>
                  <span class="result-message">{{result.message}}</span>
                  @if (result.location) {
                    <span class="result-location">{{result.location}}</span>
                  }
                </div>
              </div>
            }
            @if (!task.showDetails) {
              <button type="button" class="btn btn-secondary btn-sm" (click)="toggleDetails(task)">Show more...</button>
            }
            @if (task.showDetails) {
              <div>
                @if (task.result.ruleErrors.length) {
                  <div class="rules-section">
                    <h2>Failed to Run</h2>
                    @for (error of task.result.ruleErrors; track error) {
                      <div class="alert alert-danger rule-alert">
                        <span class="result-name">{{error.ruleName}}</span>
                        <span class="result-message">{{error.message}}</span>
                      </div>
                    }
                  </div>
                }
                @if (task.result.completedRules.length) {
                  <div class="rules-section">
                    <h2>Completed</h2>
                    @for (completed of task.result.completedRules; track completed) {
                      <div class="rule-box">
                        {{completed}}
                      </div>
                    }
                  </div>
                }
                @if (task.result.skippedRules.length) {
                  <div class="rules-section">
                    <h2>Skipped</h2>
                    @for (skipped of task.result.skippedRules; track skipped) {
                      <div class="rule-box">
                        {{skipped}}
                      </div>
                    }
                  </div>
                }
              </div>
            }
          </div>
        }
      </div>
    }
  </div>
}

@if (status === ResultStatus.running) {
  <cs-loader></cs-loader>
}

</div>

export function getDomain(hostname?: string): string {
  if(!hostname){
    hostname = window.location.hostname;
  }
  if (hostname === 'localhost' || hostname === '127.0.0.1'){
    return hostname;
  } else {
    const hostnameParts = hostname.split('.');
    //assuming one top level domain
    if (hostnameParts.length <= 2) {
      return hostname;
    } else {
      return hostnameParts.slice(hostnameParts.length - 2).join('.');
    }
  }
}

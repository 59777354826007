import {ConfigViewModel} from './config-view-model';
import {ConfigReference, DocumentSubType, WorksheetConfig} from '../../generated/api-stubs';
import {WorksheetUnderlyingData} from './worksheet-underlying-data';
import {RowViewModel} from './row-view-model';

// Note: We don't concern ourselves with removing config view models from this set,
// as if the config isn't displayed anymore it simply won't be shown, and we may re-use it later.

/**
 * A set of config view models on a row of a worksheet.
 */
export class ConfigSet {

  /**
   * The list of config view models.
   */
  private readonly _list: ConfigViewModel[] = [];

  /**
   * The map of config view models by config type.
   */
  private readonly _map: ConfigMap = {};

  /**
   * Gets the list of config view models.
   */
  public get list(): ReadonlyArray<ConfigViewModel> {
    return this._list;
  }

  /**
   * Gets the config view model for the given config type.
   * @param configType The config type.
   * @returns The config view model, or undefined if it doesn't exist.
   */
  public get(configType: DocumentSubType): ConfigViewModel | undefined {
    return this._map[configType];
  }

  /**
   * Sets a config view model.
   * @param prototype The config prototype.
   * @returns The config view model.
   */
  public set(prototype: IConfigPrototype): ConfigViewModel {
    let existing = this._map[prototype.configType];

    // If the config view model already exists, update it.
    if(existing) {
      existing.setConfig(prototype.reference);
      return existing;
    }

    // Otherwise, create a new config view model and add it.
    let config = prototype.createViewModel();
    this._list.push(config);
    this._map[prototype.configType] = config;
    return config;
  }

  /**
   * Replaces the entire set of config view models with the given config prototypes.
   * @param configPrototypes The config prototypes.
   */
  public replace(configPrototypes: ReadonlyArray<IConfigPrototype>) {
    let updatedConfigTypes = new Set<DocumentSubType>();

    // Add or update the config view models.
    for(let prototype of configPrototypes){
      updatedConfigTypes.add(prototype.configType);
      let existing = this._map[prototype.configType];
      if(existing){
        existing.setConfig(prototype.reference);
      } else{
        existing = prototype.createViewModel();
        this._list.push(existing);
        this._map[prototype.configType] = existing;
      }
    }

    // Remove any configs from view models not in the supplied list.
    for(let config of this._list){
      if(!updatedConfigTypes.has(config.configType)){
        config.setConfig(undefined);
      }
    }
  }
}

/**
 * A map of config view models by config type.
 */
type ConfigMap  = { [configType in DocumentSubType]?: ConfigViewModel | undefined };

/**
 * A config prototype. A prototype represents a config reference and config type,
 * and provides a method to create a view model.
 */
export class ConfigPrototype implements IConfigPrototype {

  /**
   * Creates an instance of ConfigPrototype.
   * @param config The config.
   * @param row The row view model.
   * @param underlyingData The underlying data.
   */
  constructor(
    private readonly config: WorksheetConfig,
    private readonly row: RowViewModel,
    private readonly underlyingData: WorksheetUnderlyingData){
  }

  /**
   * Gets the config type.
   */
  public get configType(): DocumentSubType {
    return this.config.configType;
  }

  /**
   * Gets the config reference.
   */
  public get reference(): ConfigReference {
    return this.config.reference;
  }

  /**
   * Gets the function to create a view model.
   */
  public get createViewModel(): () => ConfigViewModel {
    return () => new ConfigViewModel(this.row, this.configType, this.config, this.underlyingData);
  }
}

/**
 * An abstract config prototype. A prototype represents a config reference and config type,
 * and provides a method to create a view model.
 */
export class AbstractConfigPrototype implements IConfigPrototype {

  /**
   * Creates an instance of AbstractConfigPrototype.
   * @param configType The config type.
   * @param reference The config reference.
   * @param createViewModel The function to create a view model.
   */
  constructor(
    public readonly configType: DocumentSubType,
    public readonly reference: ConfigReference,
    public readonly createViewModel: () => ConfigViewModel){
  }
}

/**
 * A config prototype for an unpopulated config cell.
 */
export class UnpopulatedConfigPrototype extends AbstractConfigPrototype {

  /**
   * Creates an instance of UnpopulatedConfigPrototype.
   * @param configType The config type.
   * @param row The row view model.
   * @param underlyingData The worksheet underlying data.
   */
  constructor(
    configType: DocumentSubType,
    row: RowViewModel,
    underlyingData: WorksheetUnderlyingData){
    super(configType, undefined, () => new ConfigViewModel(row, configType, undefined, underlyingData));
  }
}

/**
 * A config prototype. A prototype represents a config reference and config type,
 * and provides a method to create a view model.
 */
export interface IConfigPrototype {

  /**
   * Gets the config type.
   */
  readonly configType: DocumentSubType;

  /**
   * Gets the config reference.
   */
  readonly reference: ConfigReference;

  /**
   * Gets the function to create a view model.
   */
  readonly createViewModel: () => ConfigViewModel;
}

@if (dock.worksheet) {
  <div
    class="worksheet-dock"
    mwlResizable
    (resizing)="handleResize($event)"
    (resizeEnd)="handleResizeEnd($event)"
    [ngStyle]="{ 'height': '' + dock.height + 'px' }">
    <div
      class="resize-handle-top"
      mwlResizeHandle
      [resizeEdges]="{ top: true }"
    ></div>
    <cs-edit-worksheet
      [tenantId]="dock.worksheet.tenantId"
      [worksheetId]="dock.worksheet.worksheetId"
      [isInstanceInDock]="true">
    </cs-edit-worksheet>
  </div>
}

import { Injectable } from '@angular/core';
import {DialogManager, IDialog, DialogBase} from '../../common/dialogs/dialog-manager.service';
import { ConfigOrConfigLoader } from '../../simulations/configs/comparing/config-or-config-loader';

@Injectable()
export class DownloadReEncryptedConfigDialog {
  constructor(
    private readonly dialogManager: DialogManager) {
  }

  public async show(config: ConfigOrConfigLoader): Promise<DownloadReEncryptedConfigResult> {

    let result = await this.dialogManager.showTertiaryDialog(
      new DownloadReEncryptedConfigDialogData(config));

    return result;
  }
}

export class DownloadReEncryptedConfigDialogData extends DialogBase<DownloadReEncryptedConfigResult> implements IDialog {
  constructor(
    public readonly config: ConfigOrConfigLoader) {
    super();
  }
}

export class DownloadReEncryptedConfigResult {
  constructor(){
  }
}

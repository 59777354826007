import * as d3 from '../../d3-bundle';
import { SUSPENSION_VIEWER_TYPE, SuspensionViewer3d } from '../../viewers/suspension-viewer/suspension-viewer-3d';
import { SourceLoaderViewModel } from '../../viewers/channel-data-loaders/source-loader-set';
import { SharedState } from '../../viewers/shared-state';
import { ChannelNameStyle } from '../../viewers/channel-data-loaders/channel-name-style';
import { NavigationStationConfig, NavigationStationConfigBuilder } from './navigation-station-config-builder';
import { SiteHooks } from '../../site-hooks';
import { UrlFileLoader } from '../../url-file-loader';
import { SimType } from '../../sim-type';
import { StudyJob } from '../../study-job';
import { LocalConfigSourceLoader, LocalConfigSourceLoaderOptions, UnitsMap } from '../../viewers/channel-data-loaders/local-config-source-loader';
import { ConfigData, ConfigPreviewChartType, ConfigPreviewConfigBuilder } from './config-preview-config-builder-base';
import { DomainNewsCache } from '../../viewers/domain-news-cache';
import { getObjectWithout } from '../../get-object-without';

/**
 * A config builder for previewing car configs.
 */
export class CarPreviewConfigBuilder extends ConfigPreviewConfigBuilder implements NavigationStationConfigBuilder {
  public readonly studyJobs: StudyJob[] = [new StudyJob('dummy', 0)];
  public readonly simTypes: SimType[] = [];

  /**
   * Constructs a new car preview config builder.
   * @param fileLoader The file loader.
   * @param siteHooks The site hooks.
   * @param configs The configs for this builder session.
   * @param unitsMap The units map.
   */
  constructor(
    fileLoader: UrlFileLoader,
    siteHooks: SiteHooks,
    configs: ReadonlyArray<ConfigData>,
    unitsMap?: UnitsMap) {
    super('Car', fileLoader, siteHooks, configs, unitsMap);
  }

  /**
   * @inheritdoc
   */
  public async build(): Promise<NavigationStationConfig> {

    // Create an empty navigation station config.
    let config: NavigationStationConfig = {
      channelNameStyle: ChannelNameStyle.Generic,
      sharedStates: [],
      views: []
    };

    let domainNewsCache = new DomainNewsCache();

    // Create the suspension viewer.
    {
      let sharedState = new SharedState();
      config.sharedStates.push(sharedState);
      for (let configData of this.configs) {
        sharedState.sourceLoaderSet.add(new SourceLoaderViewModel(LocalConfigSourceLoader.create(this.siteHooks, configData.data, configData.name)));
      }

      const viewerMetadata = await this.resolveViewerMetadata(SUSPENSION_VIEWER_TYPE, undefined);
      config.views.push({
        title: 'Suspension Viewer',
        viewerType: SUSPENSION_VIEWER_TYPE,
        layout: undefined,
        viewer: SuspensionViewer3d.create(this.siteHooks, sharedState),
        grid: this.getGridSlot({
          width: 12,
          height: 8
        }, viewerMetadata)
      });
    }

    let defaultGridSlot = {
      x: 0,
      y: 0,
      width: 6,
      height: 5
    };

    // Ignore any data the user may have added in their own custom properties.
    const configSourceLoaderOptions: LocalConfigSourceLoaderOptions = {
      filter: {
        test: (v: string) => v.indexOf('customProperties.') === -1
      }
    };

    await this.createConfigAreaChart('chassis.carRunningMass', 'Added Mass And Position Lookup',
      'mAdditionalMassLU',
      config, defaultGridSlot, domainNewsCache,
      configSourceLoaderOptions,
      { forBranchName: 'Added mass and position lookup' });

    /*
    await this.createConfigAreaChart('powertrain.engine.MEngineMapData', 'Map-nEngine-rThrottleBarrel-MEngine',
      INDEX_DOMAIN_NAME,
      config, defaultGridSlot, domainNewsCache,
      {
        tableToChannelsMap: {
          'MEngineMapData': ['nEngine', 'rThrottleBarrel', 'MEngine']
        }
      },
      {
        arrayIsValidValue: true,
        filter: (c: any) => Array.isArray(c),
        map: (c: any) => ({ MEngineMapData: c })
      });
      */

    // TODO: MAP: powertrain.engine.MEngineMapData / when is not array.
    // TODO: MAP: powertrain.engine.dmFuelMapData
    // TODO: MAP: Anything using mapProperties or powerMapProperties in powertrain-definitions.schema.json

    await this.createConfigAreaChart('powertrain.electric.storage.eStoreEfficiency', 'Storage Efficiency Lookup',
      'stateOfChargeBasis',
      config, defaultGridSlot, domainNewsCache,
      configSourceLoaderOptions,
      {
        forBranchName: 'Internal Resistance and Voltage/SoC LU',
        map: (c: any) => getObjectWithout(c, ['internalResistance'])
      });
    await this.createConfigAreaChart('powertrain.electric.storage.eDeployEfficiency', 'Deployment Efficiency Lookup',
      'stateOfChargeBasis',
      config, defaultGridSlot, domainNewsCache,
      configSourceLoaderOptions,
      {
        forBranchName: 'Internal Resistance and Voltage/SoC LU',
        map: (c: any) => getObjectWithout(c, ['internalResistance'])
      });

    await this.createConfigAreaChart('powertrain.electric.electricMotors.deployment', 'Electric Motor Torque And Speed',
      'nMotor',
      config, defaultGridSlot, domainNewsCache,
      configSourceLoaderOptions,
      {
        forBranchName: 'eMotor with Torque Curve',
        filter: (c: any) => !!c.MMotorMapData,
        map: (c: any) => ({
          nMotor: c.MMotorMapData.map((v: ReadonlyArray<ReadonlyArray<number>>) => v[0]),
          MMotor: c.MMotorMapData.map((v: ReadonlyArray<ReadonlyArray<number>>) => v[1]),
        })
      });

    await this.createConfigAreaChart('powertrain.electric.electricMotors.deployment', 'Electric Motor Harvesting Torque And Speed',
      'nMotor',
      config, defaultGridSlot, domainNewsCache,
      configSourceLoaderOptions,
      {
        forBranchName: 'eMotor with Torque Curve',
        filter: (c: any) => !!c.MMotorMapHarvestData,
        map: (c: any) => ({
          nMotor: c.MMotorMapHarvestData.map((v: ReadonlyArray<ReadonlyArray<number>>) => v[0]),
          MMotor: c.MMotorMapHarvestData.map((v: ReadonlyArray<ReadonlyArray<number>>) => v[1]),
        })
      });

    await this.createConfigAreaChart('powertrain.primaryTransmission|secondaryTransmission.gearboxType', 'Gear Ratios',
      'NGear',
      config, defaultGridSlot, domainNewsCache,
      configSourceLoaderOptions,
      {
        forBranchName: 'VShift',
        filter: (c: any) => c.rGearRatios && c.rGearRatios.length > 1,
        map: (c: any) => ({
          rGearRatios: c.rGearRatios,
          NGear: d3.range(1, c.rGearRatios.length + 1)
        }),
        chartType: ConfigPreviewChartType.scatter
      });

    await this.createConfigAreaChart('powertrain.primaryTransmission|secondaryTransmission.gearboxType', 'Gear Upshift Points',
      'NGear',
      config, defaultGridSlot, domainNewsCache,
      configSourceLoaderOptions,
      {
        forBranchName: 'VShift',
        filter: (c: any) => c.upshiftPoints && c.upshiftPoints.length > 1,
        map: (c: any) => ({
          nEngineUpshift: c.upshiftPoints.nEngineUpshift,
          NGear: d3.range(1, c.upshiftPoints.nEngineUpshift.length + 1)
        }),
        chartType: ConfigPreviewChartType.scatter
      });

    await this.createConfigAreaChart('suspension.front|rear.internal.triSpring|triSpringL|spring|springL|antiRollBar|antiRollBarL', 'Spring Lookup',
      'xData',
      config, defaultGridSlot, domainNewsCache,
      configSourceLoaderOptions,
      {
        forBranchName: 'Spring LU'
      });

    await this.createConfigAreaChart('suspension.front|rear.internal.damper|damperL|triDamper|triDamperL|rollDamper|rollDamperL', 'Damper Lookup',
      'vDamperBasis',
      config, defaultGridSlot, domainNewsCache,
      configSourceLoaderOptions,
      {
        forBranchName: 'Damper Lookup Table'
      });

    await this.createConfigAreaChart('suspension.front|rear.internal.triBumpStop|triBumpStopL|bumpStop|bumpStopL', 'Bump Stop Lookup',
      'xData',
      config, defaultGridSlot, domainNewsCache,
      configSourceLoaderOptions,
      {
        forBranchName: 'Bump-stop LU'
      });

    defaultGridSlot.width = 12;

    await this.createConfigAreaChart('tyres.front|rear.thermalParameters.brake|brakeL|wheel|wheelL|tyre|tyreL', 'Tyre Thermal Parameters',
      'vAirBasis',
      config, defaultGridSlot, domainNewsCache,
      configSourceLoaderOptions,
      {
        filter: (c: any) => !!c.kConvectiveCooling,
        map: (c: any) => ({
          vAirBasis: c.vAirBasis,
          kConvectiveCooling: c.kConvectiveCooling,
        })
      });

    await this.createConfigAreaChart('tyres.front|rear.thermalParameters.tyre|tyreL', 'Tyre Thermal Lookup',
      'TTyreAverageBasis',
      config, defaultGridSlot, domainNewsCache,
      configSourceLoaderOptions,
      {
        filter: (c: any) => !!c.TTyreAverageBasis,
        map: (c: any) => ({
          TTyreAverageBasis: c.TTyreAverageBasis,
          rGripFactorData: c.rGripFactorData,
          rStiffnessData: c.rStiffnessData,
        })
      });

    defaultGridSlot.width = 6;

    return config;
  }
}

import {Registry} from '../runner/registry';
import {MonotonicChannels} from './monotonic-channels';
import {MinMax} from './min-max';
import {ChannelNaNs} from './channel-nans';
import {AirTemperature} from './air-temperature';
import {MapVerification} from './map-verification';
import {PreferTablesToRBFs} from './prefer-tables-to-rbfs';
import {TrackStartFinishOffset} from './track-start-finish-offset';
import {TrackRepeatedValues} from './track-repeated-values';
import {TrackSwappedLeftRight} from './track-swapped-left-right';

export class RegistryFactory {
  public create(): Registry {
    const registry = new Registry();

    registry.addRule(AirTemperature);
    registry.addRule(ChannelNaNs);
    registry.addRule(MapVerification);
    registry.addRule(MinMax);
    registry.addRule(MonotonicChannels);
    registry.addRule(PreferTablesToRBFs);
    registry.addRule(TrackStartFinishOffset);
    registry.addRule(TrackRepeatedValues);
    registry.addRule(TrackSwappedLeftRight);

    return registry;
  }
}

import { SourceData } from './types/source-data';
import { IPopulatedMultiPlotLayout } from './types/i-populated-multi-plot-layout';
import { IMultiPlotLayout } from './types/i-multi-plot-layout';
import { ProcessedMultiPlotLayout } from './types/processed-multi-plot-layout';
import { ProcessedMultiPlotChannel } from './types/processed-multi-plot-channel';
import { ChannelIndexMap } from './channel-index-map';
import { CreateProcessedPlots } from './create-processed-plots';
import { GetProcessedRenderChannel } from './get-processed-render-channel';


/**
 * Process the layout root, creating processed chart information that is not specific to a row or column (for example,
 * the set of processed plots to render).
 */
export class ProcessLayoutRoot {
  constructor(
    private readonly secondaryDomainName: string | undefined,
    private readonly createProcessedPlots: CreateProcessedPlots,
    private readonly getProcessedRenderChannel: GetProcessedRenderChannel) {
  }

  /**
   * Create processed chart information that is not specific to a row or column (for example,
   * the set of processed plots to render).
   * @param layout
   * @param sourceData
   * @param channelIndexMap
   * @param processedPrimaryDomain
   */
  public execute(layout: IMultiPlotLayout, sourceData: ReadonlyArray<SourceData>, channelIndexMap: ChannelIndexMap, processedPrimaryDomain: ProcessedMultiPlotChannel) {

    // Create the set of processed plots and the columns and rows we need to render, based on the layout and diagonal
    // stacking options.
    let processedPlotsResult = this.createProcessedPlots.execute(layout as IPopulatedMultiPlotLayout, sourceData);

    // For charts which support coloring based off a single channel, get the color channel.
    let colorChannel = this.getProcessedRenderChannel.execute(layout.colorChannel, sourceData, channelIndexMap);

    // For charts which support sizing based off a single channel, get the size channel.
    let sizeChannel = this.getProcessedRenderChannel.execute(layout.sizeChannel, sourceData, channelIndexMap);

    // A secondary domain is used to raise cursor events on a domain in addition to the primary domain.
    let secondaryDomain: ProcessedMultiPlotChannel | undefined;
    if (this.secondaryDomainName) {
      secondaryDomain = this.getProcessedRenderChannel.execute({ name: this.secondaryDomainName }, sourceData, channelIndexMap);
    }

    // Return the processed layout root.
    layout.processed = new ProcessedMultiPlotLayout(
      processedPrimaryDomain,
      secondaryDomain,
      colorChannel,
      sizeChannel,
      processedPlotsResult.plots,
      processedPlotsResult.columns,
      processedPlotsResult.rows,
      sourceData);
  }
}

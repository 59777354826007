import { IViewerChannelData } from './viewer-channel-data';

/**
 * The viewer channel data for a domain.
 */
export class DomainViewerChannelData {

  /**
   * Creates an instance of a domain viewer channel data.
   * @param channelName The domain channel name.
   * @param channelData The domain channel data.
   */
  constructor(
    public readonly channelName: string,
    public readonly channelData: ReadonlyArray<IViewerChannelData>) {
  }
}

import { SourceData } from './types/source-data';
import { IMultiPlotSide } from './types/i-multi-plot-side';
import { SideType } from './types/side-type';
import { ChannelIndexMap } from './channel-index-map';
import { ProcessLayoutSide } from './process-layout-side';


/**
 * Processes the sides (columns or rows) of a multi-plot layout.
 */
export class ProcessLayoutSides {
  constructor(
    private readonly processLayoutSide: ProcessLayoutSide) {
  }

  /**
   * Processes the sides (columns or rows) of a multi-plot layout.
   * @param sides The set of sides to process.
   * @param sourceData The set of data sources.
   * @param channelIndexMap The channel index map.
   * @param sideType The type of side (column or row).
   * @param columnSides The set of column sides.
   */
  public execute(sides: ReadonlyArray<IMultiPlotSide>, sourceData: ReadonlyArray<SourceData>, channelIndexMap: ChannelIndexMap, sideType: SideType, columnSides: ReadonlyArray<IMultiPlotSide>) {
    for (let side of sides) {
      // Process each side (a single column or row) individually.
      this.processLayoutSide.execute(side, sourceData, channelIndexMap, sideType, columnSides);
    }
  }
}

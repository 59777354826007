<cs-error-message [message]="errorMessage"></cs-error-message>
@if (isLoading) {
  <cs-loader></cs-loader>
}
@if (configChanges && configChanges.length) {
  <div class="card">
    <h1 class="card-header">
      Apply Constraint Scalar Results
    </h1>
    <div class="card-body">
      <table class="table last-item">
        <thead>
          <tr>
            <th>Type</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          @for (item of configChanges; track item) {
            <tr>
              <td class="config-type-name">{{item.name}}</td>
              <td>
                <button class="btn btn-secondary btn-smart"
                  (click)="compareToInput(item)">
                  <i class="fa fa-files-o"></i>Compare to Input
                </button>
              </td>
              <td>
                <button class="btn btn-secondary btn-smart"
                  (click)="stageWithChanges(item)">
                  <i class="fa fa-arrow-circle-up"></i>Stage
                </button>
              </td>
              <td>
                <button class="btn btn-secondary btn-smart" [disabled]="!canSaveToSource(item.configType)"
                  (click)="saveChangesToSourceConfig(item)">
                  <i class="fa fa-cloud"></i>Save to Source Config
                </button>
              </td>
              <td>
                <button class="btn btn-secondary btn-smart"
                  (click)="saveChangesToNewConfig(item)">
                  <i class="fa fa-file-o"></i>Save as New Config
                </button>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  </div>
}

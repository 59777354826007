import {Component, Input, EventEmitter, Output, OnChanges, SimpleChanges} from '@angular/core';
import {
  ConfigStub, ListFilterData, GetConfigsQueryResult,
  GetSupportSessionQueryResult, FilteredDocumentsResultType, DocumentCustomPropertyGroup
} from '../../../../generated/api-stubs';
import {OnInit} from '@angular/core';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {Dayjs} from '../../../common/dayjs.service';
import {cssSanitize} from '../../../common/css-sanitize';
import {ConfirmationDialog} from '../../../common/dialogs/confirmation-dialog.service';
import {StudyStagingArea} from '../../study-staging-area/study-staging-area.service';
import {CustomPropertyUtilities} from '../../custom-properties/custom-property-utilities';
import {CustomProperty} from '../../custom-properties/custom-properties';
import {ConfigType, TelemetryConfigType, WorksheetConfigType} from '../config-types';
import {CreateUserInformationMaps, UserInformationMap} from '../../create-user-information-maps.service';
import {renderWithCustomLineBreaks} from '../../../common/render-with-custom-line-breaks';
import {LocalUserSettingsManager} from '../../../common/local-user-settings-manager';
import {ViewConfigsComponentBase} from '../view-configs-component-base';
import {GetSimVersion} from '../../../common/get-sim-version.service';
import {CompareConfigToStaged} from '../../../worksheets/compare-config-to-staged';
import {simVersionToNumber} from '../../../visualizations/sim-version-to-number';
import { AuthenticationService, UserData } from '../../../identity/state/authentication.service';
import { ConfigChangedEvents } from '../config-changed-events';

@Component({
  selector: 'cs-view-configs',
  templateUrl: './view-configs.component.html',
  styleUrls: ['./view-configs.component.scss']
})
export class ViewConfigsComponent extends ViewConfigsComponentBase implements OnInit, OnChanges {
  public errorMessage: string;
  @Input() public tenantId: string;
  @Input() public configType: ConfigType;
  @Input() public filter: ListFilterData;
  @Input() public canStage: boolean;
  @Input() public subTreePath: string;
  @Input() public navigationUrlTree: (config: ConfigSummary) => [];
  @Output() public filterChanged: EventEmitter<ListFilterData> = new EventEmitter<ListFilterData>();
  @Output() public configSelected: EventEmitter<ConfigSummary> = new EventEmitter<ConfigSummary>();

  public userData: UserData;

  public configs: ConfigSummary[];
  public map: UserInformationMap;
  public lastResult: GetConfigsQueryResult;
  public isReloading: boolean;

  private loadTask: Promise<any>;

  public showProperties: boolean = true;
  public isDeleting: boolean = false;

  public telemetryConfigType = TelemetryConfigType;
  public worksheetConfigType = WorksheetConfigType;

  constructor(
    private readonly dayjs: Dayjs,
    private readonly authenticationService: AuthenticationService,
    private readonly configStub: ConfigStub,
    private readonly confirmationDialog: ConfirmationDialog,
    private readonly studyStagingArea: StudyStagingArea,
    private readonly createUserInformationMaps: CreateUserInformationMaps,
    private readonly localUserSettingsManager: LocalUserSettingsManager,
    private readonly getSimVersion: GetSimVersion,
    private readonly compareConfigToStaged: CompareConfigToStaged,
    private readonly configChanged: ConfigChangedEvents,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
    super();
  }

  public ngOnInit() {
    this.load();
    this.configChanged.configsChanged.subscribe(() => this.reloadFilter());
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(changes.subTreePath && !changes.subTreePath.firstChange){
      this.reloadFilter();
    }
  }

  public get isTenantAdministrator(): boolean {
    return this.authenticationService.isTenantAdministrator;
  }

  public toggleProperties(){
    this.showProperties
      = this.localUserSettingsManager.state.showCustomPropertiesInLists
      = !this.localUserSettingsManager.state.showCustomPropertiesInLists;
    this.localUserSettingsManager.save();
  }

  public get canPersistFilter() {
    return !(this.configType.singularKey.endsWith('Filter')
      || this.configType.singularKey.endsWith('Viewer')
      || this.configType.singularKey.endsWith('CustomView')
      || !!this.subTreePath);
  }

  public get canClone() {
    return !this.subTreePath;
  }

  public get getLoadGroupResultsDelegate(): () => Promise<DocumentCustomPropertyGroup[]>{
    return () => this.loadGroupResults();
  }

  public async loadGroupResults(): Promise<DocumentCustomPropertyGroup[]>{
    let result = await this.configStub.getConfigs(this.tenantId, this.configType.singularKey, undefined, this.subTreePath,  FilteredDocumentsResultType.groupOnly);
    return result.groupResults;
  }

  public async reloadFilter(){
    try {
      if(this.loadTask){
        try{
          await this.loadTask;
        } catch(e){}
      }

      this.isReloading = true;
      this.errorMessage = undefined;

      let getConfigsTask = this.loadTask = this.configStub.getConfigs(this.tenantId, this.configType.singularKey, this.filter, this.subTreePath);
      let configResults = await getConfigsTask;
      this.configs = [];
      this.addQueryResults(configResults);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
    this.isReloading = false;
  }

  public async load() {
    try {
      this.showProperties
        = this.localUserSettingsManager.state.showCustomPropertiesInLists;

      this.userData = this.authenticationService.userDataSnapshot;
      if(!this.tenantId){
        this.tenantId = this.userData.tenant;
      }

      if(this.canPersistFilter){
        // Wait for the persisted filter to be loaded,
        // rather than loading the unfiltered results.
        this.isReloading = true;
        this.configs = [];
        return;
      }

      let getConfigsTask = this.loadTask = this.configStub.getConfigs(this.tenantId, this.configType.singularKey, this.filter, this.subTreePath);
      let configResults = await getConfigsTask;

      this.configs = [];
      this.addQueryResults(configResults);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async loadNext() {
    try {
      if(!this.lastResult.queryResults.hasMoreResults){
        return;
      }

      if(this.loadTask){
        await this.loadTask;
      }

      this.isReloading = true;
      this.filter.continuationToken = this.lastResult.queryResults.continuationToken;

      let getConfigsTask = this.loadTask = this.configStub.getConfigs(this.tenantId, this.configType.singularKey, this.filter, this.subTreePath);
      let configResults = await getConfigsTask;

      this.addQueryResults(configResults);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
    this.isReloading = false;
  }

  public addQueryResults(configsResult: GetConfigsQueryResult) {
    this.lastResult = configsResult;

    if(!this.map){
      this.map = this.createUserInformationMaps.execute(configsResult.userInformation);
    } else{
      this.createUserInformationMaps.append(configsResult.userInformation, this.map);
    }

    let currentSimVersion = this.getSimVersion.currentSimVersion;
    this.configs.push(...configsResult.queryResults.documents.map(c => {
      let user = this.map.users[c.userId];

      return new ConfigSummary(
        c.documentId,
        c.tenantId,
        c.userId,
        user ? user.username : c.userId,
        c.name,
        this.dayjs.fromNow(c.creationDate),
        this.dayjs.toDateTime(c.creationDate),
        this.dayjs.fromNow(c.modifiedDate),
        this.dayjs.toDateTime(c.modifiedDate),
        c.userId === this.userData.sub,
        c.deleteRequested,
        c.parentWorksheetId,
        CustomPropertyUtilities.objectToList(c.properties),
        {
          session: c.supportSession,
          userInformation: configsResult.userInformation
        },
        c.simVersion,
        currentSimVersion && simVersionToNumber(c.simVersion) > simVersionToNumber(currentSimVersion));
    }));
  }

  public onFilterChanged(newFilter: ListFilterData){
    this.filter = newFilter;
    this.reloadFilter();
    this.filterChanged.emit(newFilter);
  }

  public onConfigSelected(config: ConfigSummary) {
    this.configSelected.emit(config);
  }

  public renderWithCustomLineBreaks(input: string){
    return renderWithCustomLineBreaks(input);
  }

  public cssSanitize(input: string): string {
    return cssSanitize(input);
  }

  public async stage(config: ConfigSummary) {
    try {
      config.isStaging = true;
      let configResult = await this.configStub.getConfig(
        config.tenantId,
        config.documentId,
        this.subTreePath,
        this.getSimVersion.currentSimVersion);
      let customProperties = CustomPropertyUtilities.objectToList(configResult.config.properties);
      this.studyStagingArea.stage(
        this.configType.singularKey,
        config.userId,
        config.documentId,
        configResult.config.name,
        configResult.config.data,
        customProperties,
        configResult.config.notes,
        configResult.convertedSimVersion,
        false);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }

    config.isStaging = false;
  }

  public async compareToStaged(config: ConfigSummary){
    try {
      await this.compareConfigToStaged.execute(
        this.configType.singularKey,
        {
          tenant: {
            tenantId: config.tenantId,
            targetId: config.documentId,
          }
        },
        'selected');
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async delete(config: ConfigSummary) {
    try {
      if(config.isOwner){
        let isConfirmed = await this.confirmationDialog.show(
          `Are you sure you want to delete this ${this.configType.name}${this.subTreePath ? ' sub-component' : ''}? This action cannot be reversed.`,
          `Confirm Delete ${this.configType.titleName}`,
          `Permanently Delete ${this.configType.titleName}`);

        if (isConfirmed) {
          config.isDeleting = true;
          await this.configStub.deleteConfig(this.userData.tenant, config.documentId, this.subTreePath);
          this.configs = this.configs.filter(v => v.documentId !== config.documentId);
        }
      }
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }

    config.isDeleting = false;
  }

  public get shouldUndelete(): boolean {
    const selected = this.configs.filter(v => v.isSelected);
    return !!selected.length && selected.every(v => v.deleteRequested);
  }

  public async deleteSelected() {
    try {

      const shouldUndelete = this.shouldUndelete;
      let configsToDelete = this.configs.filter(v => v.isSelected);

      if(shouldUndelete){
        configsToDelete = configsToDelete.filter(v => v.deleteRequested);
      } else{
        configsToDelete = configsToDelete.filter(v => !v.deleteRequested);
      }

      let count = configsToDelete.length;
      if(count === 0) {
        return;
      }

      let pluralSuffix = count === 1 ? '' : 's';
      let typeDescription = this.configType.titleName + (this.subTreePath ? ' Sub-Component' : '');

      let isConfirmed: boolean;
      if(shouldUndelete){
        isConfirmed = !!(await this.confirmationDialog.show(
          `Are you sure you want to restore ${count} config${pluralSuffix} of type ${typeDescription}?`,
          `Confirm Restore ${count} ${typeDescription} Config${pluralSuffix}`,
          `Restore ${count} ${typeDescription} Config${pluralSuffix}`));
      } else{
        isConfirmed = !!(await this.confirmationDialog.show(
          `Are you sure you want to delete ${count} config${pluralSuffix} of type ${typeDescription}? This action cannot be reversed.`,
          `Confirm Delete ${count} ${typeDescription} Config${pluralSuffix}`,
          `Permanently Delete ${count} ${typeDescription} Config${pluralSuffix}`));
      }

      if (isConfirmed) {
        try{
          this.isDeleting = true;
          for(let config of configsToDelete){
            config.isDeleting = true;
          }

          // We reverse it so the order doesn't change in the UI when default modified date sorting is applied.
          configsToDelete.reverse();
          for(let config of configsToDelete){
            await this.configStub.deleteConfig(this.userData.tenant, config.documentId, this.subTreePath, shouldUndelete);
            config.deleteRequested = !shouldUndelete;
            //this.configs = this.configs.filter(v => v.documentId !== config.documentId);
          }

          await this.reloadFilter();
        } finally{
          this.isDeleting = false;
          for(let config of configsToDelete){
            config.isDeleting = false;
          }
        }
      }
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public get canSelectAll(): boolean {
    return this.userConfigs.some(v => !v.isSelected);
  }

  public toggleSelectAll(){
    try {
      if(this.canSelectAll){
        this.userConfigs.forEach(v => v.isSelected = true);
      } else{
        this.configs.forEach(v => v.isSelected = false);
      }
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public get userConfigs(): ReadonlyArray<ConfigSummary> {
    return this.configs.filter(v => v.isOwner);
  }
}

export class ConfigSummary {
  constructor(
    public documentId: string,
    public tenantId: string,
    public userId: string,
    public username: string,
    public name: string,
    public creationDate: string,
    public creationDateRaw: string,
    public modifiedDate: string,
    public modifiedDateRaw: string,
    public isOwner: boolean,
    public deleteRequested: boolean,
    public parentWorksheetId: string,
    public properties: CustomProperty[],
    public supportSession: GetSupportSessionQueryResult,
    public simVersion: string,
    public requiresDowngrade: boolean) {
  }

  public isStaging: boolean = false;
  public isDeleting: boolean = false;
  public isSelected: boolean = false;
}


/**
 * A class that stores a set of unique indices in a sorted order.
 * The sorting is performed lazily when the indices are requested.
 * The class tracks whether the indices have been modified since the last
 * sorting operation and only sorts the indices if necessary.
 */
export class OrderedUniqueIndices {
  private hashset: { [key: number]: boolean } = {};
  private sortedValues: number[] = [];
  private isDirty: boolean = true;

  /**
   * Creates an instance of OrderedUniqueIndices from an array of indices.
   * @param indices The indices to store.
   * @returns An instance of OrderedUniqueIndices.
   */
  public static from(indices: ReadonlyArray<number>): OrderedUniqueIndices {
    const result = new OrderedUniqueIndices();
    for (let index of indices) {
      result.add(index);
    }
    return result;
  }

  /**
   * Adds an index to the set.
   * @param index The index to add.
   */
  public add(index: number) {
    this.hashset[index] = true;
    this.isDirty = true;
  }

  /**
   * Gets the indices in sorted order.
   * @returns The indices in sorted order.
   */
  public get(): number[] {
    if (this.isDirty) {
      this.buildSortedValues();
      this.isDirty = false;
    }

    return this.sortedValues;
  }

  /**
   * Builds the sorted values array.
   */
  private buildSortedValues() {
    this.sortedValues = [];
    for (let index in this.hashset) {
      if (this.hashset.hasOwnProperty(index)) {
        this.sortedValues.push(+index);
      }
    }
    this.sortedValues.sort((a, b) => a - b);
  }
}

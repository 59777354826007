<div class="container-fluid">
  @if (isLoading) {
    <cs-loader class="loader-centered"></cs-loader>
  }
  <cs-error-message [message]="errorMessage"></cs-error-message>

  @if (requiresEmailConfirmation) {
    <p>
      <button (click)="verifyEmail()" class="btn btn-primary" data-test-id="email-verification-button">Send Verification Email</button>
    </p>
  }
  @if (emailConfirmationSent) {
    <div class="alert alert-info" data-test-id="email-sent-message">
      <p>
        A verification link has been sent to your registered email address.
      </p>
      <p>
        Once you have performed the verification, you may click the button below to try and access the support portal again.
      </p>
      <p>
        <button (click)="retryZendeskAutentication()" class="btn btn-primary">I Have Verified</button>
      </p>
    </div>
  }
  <form id="jwtForm" method="post" action="https://canopysimulations.zendesk.com/access/jwt">
    <input id="jwtInput" type="hidden" name="jwt" />
    <input id="returnToInput" type="hidden" name="return_to" />
  </form>
</div>

import {Injectable} from '@angular/core';
import {CommandContext, CommandResult, ICommandContext, WorksheetCommand} from './worksheet-command';
import {
  ButtonMenuItem,
  MenuItem,
} from '../../context-menu/context-menu-types';
import {RowMetadataViewModel} from '../row-metadata-view-model';
import { AuthenticationService } from '../../identity/state/authentication.service';

/**
 * A command that removes one or more rows from the worksheet.
 */
@Injectable()
export class RemoveRowCommand extends WorksheetCommand {

  /**
   * Creates an instance of RemoveRowCommand.
   * @param authenticationService The authentication service.
   */
  constructor(
    private readonly authenticationService: AuthenticationService) {
    super();
  }

  /**
   * Determines if the command can be executed.
   * @param context The command context.
   * @returns True if the command can be executed, otherwise false.
   */
  public canExecute(context: CommandContext): context is ICommandContext<RowMetadataViewModel> {
    const userData = this.authenticationService.userDataSnapshot;
    return context.isRowMetadata()
      && context.worksheet.canWrite(userData.sub);
  }

  /**
   * Executes the command.
   * @param context The command context.
   * @returns The command result.
   */
  public async execute(context: CommandContext): Promise<CommandResult> {
    if(!this.canExecute(context)) {
      return CommandResult.NoUpdate;
    }

    const rows = context.selectedRows;
    for(let row of rows){
      row.removeRow();
    }

    return CommandResult.UpdateAndGenerateColumns;
  }

  /**
   * Creates the menu items for the command.
   * @param context The command context.
   * @param result The menu items.
   */
  public createMenuItems(context: CommandContext, result: MenuItem<CommandResult>[]): void {
    if(!this.canExecute(context)) {
      return;
    }

    result.push(new ButtonMenuItem<CommandResult>(
      `Remove Row`,
      `remove-button`,
      () => this.execute(context),
      'times'));
  }
}

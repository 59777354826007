@if (isVisible) {
  <div class="modal dialog-container dialog-inner-scroll" id="study-loader-dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title">Load Study</h1>
          <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()">
          </button>
        </div>
        <div class="modal-body">
          <cs-error-message [message]="session?.errorMessage"></cs-error-message>
          @if (session?.isLoading) {
            <cs-loader></cs-loader>
          }
          @if (!session?.isLoading) {
            <div>
              <div class="scroll-area">
                <h1>
                  User Studies
                </h1>
                <cs-view-studies class="view-studies-component"
                  [canNavigate]="false"
                  [initialQuery]="session.initialQuery"
                  (studySelected)="session.onStudySelected($event)">
                </cs-view-studies>
              </div>
            </div>
          }
        </div>
        <div class="modal-footer">
          <cs-error-message [message]="session?.errorMessage"></cs-error-message>
          <button id="dialog-dismiss-button" type="button" class="btn btn-secondary" (click)="dismiss()">Cancel</button>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div>
  }<!-- /.modal -->



/**
 * The results of a value interpolator.
 * A mapping of channel name to the value at a point in the hypercube.
 */
export interface ValueInterpolatorResults {
  [channelName: string]: number;
}

/**
 * The results of a value interpolator that should not be modified.
 * A mapping of channel name to the value at a point in the hypercube.
 */
export interface ReadonlyValueInterpolatorResults {
  readonly [channelName: string]: number;
}

/**
 * Create interpolated results for a set of channels with all values set to NaN.
 * @param channelNames The names of the channels to create empty results for.
 * @returns The mapping of channel name to NaN for the requested channels.
 */
export function emptyValueInterpolatorResults(channelNames: ReadonlyArray<string>): ValueInterpolatorResults {
  return channelNames.reduce<ValueInterpolatorResults>((p, c) => {
    p[c] = NaN;
    return p;
  },
  {});
}

import { ConfigBuilderBase, ResolvedViewerLayout } from './config-builder-base';
import { SimType } from '../../sim-type';
import { StudyJob } from '../../study-job';
import { SiteHooks } from '../../site-hooks';
import { UrlFileLoader } from '../../url-file-loader';
import {
  StudyExplorationAndScalarData
} from '../../viewers/channel-data-loaders/load-study-exploration-and-scalar-data';
import { StudyExplorationAndScalarDataCache } from '../../viewers/channel-data-loaders/study-exploration-and-scalar-data-cache';
import { USE_FIRST_SUCCESSFUL_JOB_INDEX } from '../navigation-station';
import {
  DimensionLinePlotViewer,
  LINE_MULTI_PLOT_VIEWER_TYPE
} from '../../viewers/line-plot-viewer/dimension-line-plot-viewer';
import { SingleDimensionStudySourceLoader } from '../../viewers/channel-data-loaders/single-dimension-study-source-loader';
import { DimensionsSharedState, SharedState } from '../../viewers/shared-state';
import { Utilities } from '../../utilities';
import { NavigationStationConfig } from './navigation-station-config-builder';
import { upgradeMultiPlotViewerLayout } from '../../viewers/multi-plot-viewer-base/multi-plot-viewer-base';
import { INDEX_DOMAIN_NAME } from '../../constants';
import { SourceLoaderViewModel } from '../../viewers/channel-data-loaders/source-loader-set';
import { ChannelInterpolator } from '../../viewers/channel-data-loaders/interpolation/channel-interpolator';
import { ISize } from '../../viewers/size';
import { FilteredStudyExplorationAndScalarDataCache } from '../../viewers/channel-data-loaders/filtered-study-exploration-and-scalar-data-cache';

/**
 * A base class for building the views for explorations.
 */
export abstract class ExplorationConfigBuilderBase extends ConfigBuilderBase {

  protected explorationAndScalarDataCache?: FilteredStudyExplorationAndScalarDataCache;
  protected explorationAndScalarData?: StudyExplorationAndScalarData;

  /**
   * The number of input dimensions in the exploration.
   */
  protected numberOfDimensions: number = 0;

  /**
   * This naming convention comes from sim-dev, but this is the normalized coordinates
   * of the interpolation line for each input dimension.
   * For example if an input dimension ranges from 100 to 200 and the line intersects at 125,
   * then the rCoordinate would be 0.25.
   */
  protected rCoordinates: number[] = [];

  /**
   * The normalized rendered domain extents for each input dimension.
   */
  protected rDomainExtents: [number, number][] = [];

  /**
   * Constructs a new exploration config builder.
   * @param urlFileLoader The file loader.
   * @param siteHooks The site hooks.
   * @param studyJobs The study jobs for this builder session.
   * @param simTypes The sim types for this builder session.
   */
  constructor(
    urlFileLoader: UrlFileLoader,
    siteHooks: SiteHooks,
    studyJobs: StudyJob[],
    simTypes: SimType[]
  ) {
    super(urlFileLoader, siteHooks, studyJobs, simTypes);
  }

  /**
   * Loads the exploration data and resets the interpolation line.
   */
  protected async load(): Promise<void> {
    let firstStudyId = this.studyJobs[0].studyId;
    this.explorationAndScalarDataCache = new FilteredStudyExplorationAndScalarDataCache(
      StudyExplorationAndScalarDataCache.create(firstStudyId, this.simTypes, this.fileLoader));

    await this.explorationAndScalarDataCache.initialize();
    this.explorationAndScalarData = this.explorationAndScalarDataCache.get();

    if (this.studyJobs.length === 1 && this.studyJobs[0].jobIndex === USE_FIRST_SUCCESSFUL_JOB_INDEX) {
      this.studyJobs[0].jobIndex = this.explorationAndScalarData.scalarData.lineIndexToJobIndexMap.length
        ? this.explorationAndScalarData.scalarData.lineIndexToJobIndexMap[0]
        : 0;
    }

    this.numberOfDimensions = this.explorationAndScalarData.explorationMap.dimensionCount;
    this.rCoordinates = [];
    this.rDomainExtents = [];
    for (let iDim = 0; iDim < this.numberOfDimensions; iDim++) {
      this.rCoordinates.push(0.5);
      this.rDomainExtents.push([0, 1]);
    }
  }

  /**
   * Creates a viewer for a single exploration dimension.
   * @param dimensionsSharedState The shared state for the dimensions.
   * @param dimensionIndex The index of the dimension.
   * @param channelInterpolator The channel interpolator.
   * @param config The navigation station config.
   * @param resolvedLineMultiPlotViewerLayout The resolved layout for the line multi plot viewer.
   * @param showDimensionSlider Whether to show the dimension slider.
   * @param gridSlot The grid slot.
   */
  public async createDimensionViewer(
    dimensionsSharedState: DimensionsSharedState,
    dimensionIndex: number,
    channelInterpolator: ChannelInterpolator,
    config: NavigationStationConfig,
    resolvedLineMultiPlotViewerLayout: ResolvedViewerLayout,
    showDimensionSlider: boolean,
    gridSlot: ISize) {

    if (!this.explorationAndScalarDataCache || !this.explorationAndScalarData) {
      return;
    }

    const explorationAndScalarDataCache = this.explorationAndScalarDataCache;

    let sharedState = new SharedState(undefined, dimensionsSharedState);
    let sourceLoaders = this.studyJobs.map(v =>
      SingleDimensionStudySourceLoader.create(
        dimensionIndex, sharedState, this.siteHooks, this.fileLoader, v.studyId, explorationAndScalarDataCache, channelInterpolator));
    sharedState.sourceLoaderSet.add(...sourceLoaders.map(v => new SourceLoaderViewModel(v)));
    config.sharedStates.push(sharedState);

    let layout = resolvedLineMultiPlotViewerLayout.resolvedLayout.getConfigCopy();
    upgradeMultiPlotViewerLayout(layout);

    let xDomainNames: ReadonlyArray<string> = [];
    if (sourceLoaders.length) {
      xDomainNames = await sourceLoaders[0].getInputDomainNames();
    }

    config.views.push({
      title: Utilities.condenseDimension(this.explorationAndScalarData.explorationMap.inputs.sweeps[dimensionIndex].subSweeps[0].name),
      viewerType: LINE_MULTI_PLOT_VIEWER_TYPE,
      layout: resolvedLineMultiPlotViewerLayout,
      viewer: DimensionLinePlotViewer.createDimensionLinePlotViewer(
        dimensionIndex,
        xDomainNames,
        showDimensionSlider,
        INDEX_DOMAIN_NAME,
        layout,
        config.channelNameStyle,
        sharedState,
        this.siteHooks),
      grid: this.getGridSlot(gridSlot, resolvedLineMultiPlotViewerLayout.viewerMetadata)
    });
  }
}

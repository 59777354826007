import * as d3 from '../../d3-bundle';
import { IMargin } from '../margin';
import { ILegendSettings } from '../legend-settings';
import { ISize } from '../size';
import { SVGSelection } from '../../untyped-selection';
import { SharedState } from '../shared-state';
import { FeatureChannels } from '../data-pipeline/types/feature-channels';
import { SourceSplitInspector } from '../channel-data-inspectors/source-split-inspector';
import { SourceLoaderViewModel } from '../channel-data-loaders/source-loader-set';
import { GetChannelColorDelegate } from '../chart-settings';

/**
 * The interface for the chart settings we're interested in.
 */
interface IChartSettings {

  /**
   * The padding between the edge of the SVG and where we should draw.
   */
  readonly svgPadding: IMargin;

  /**
   * The margin around the data area of the chart, to allow for source labels, legend, etc.
   */
  readonly chartMargin: IMargin;

  /**
   * The size of the chart, calculated using the SVG size, SVG padding, and chart margin.
   */
  readonly chartSize: ISize;

  /**
   * The legend settings.
   */
  readonly legend: ILegendSettings;

  /**
   * Get the color for a channel.
   */
  readonly getChannelColor: GetChannelColorDelegate;
}

/**
 * The interface for a source of source labels.
 */
export interface ISourceLabelsSource {
  readonly name: string;
  isVisible: boolean;
  featureChannels: FeatureChannels;
}

export const SOURCE_LABELS_CONTAINER_CLASS = 'source-labels';

/**
 * The D3 style renderer for source labels.
 * The source labels are the labels that appear above the chart area, one for each data source,
 * with a line leading down to the legend column for that source.
 */
export class SourceLabelsRenderer {
  private inner: SourceLabelsRendererInner;

  /**
   * Create a new source labels renderer.
   * @param chartSettings The chart settings.
   */
  constructor(chartSettings: IChartSettings) {
    this.inner = new SourceLabelsRendererInner(chartSettings, new SourceSplitInspector());
  }

  /**
   * Set the shared state.
   * @param value The shared state.
   * @returns This instance.
   */
  public sharedState(value: SharedState): this {
    this.inner.sharedState = value;
    return this;
  }

  /**
   * Render the source labels.
   * @param selection The selection to render to.
   * @returns This instance.
   */
  public render(selection: SVGSelection): this {
    this.inner.render(selection);
    return this;
  }

  /**
   * Set the sources.
   * @param value The sources.
   * @returns This instance.
   */
  public sources(value: ReadonlyArray<ISourceLabelsSource>): this {
    this.inner.sources = value;
    return this;
  }

  /**
   * Get the visibility of the sources.
   * This is here for compatibility with MultiPlotViewer, but eventually
   * should be removed and then ISourceLabelsSource.name can be readonly.
   * @returns The visibility of the sources.
   */
  public getSourceVisibility(): boolean[] {
    return this.inner.sources.map(v => v.isVisible);
  }

  /**
   * Sets whether to render the legend lines.
   * @param value True to render the legend lines.
   * @returns This instance.
   */
  public renderLegendLines(value: boolean): this {
    this.inner.renderLegendLines = value;
    return this;
  }

  /**
   * Listen for changes to the renderer.
   * @param typenames The event type names.
   * @param callback The callback.
   * @returns This object.
   */
  public on(typenames: string, callback: (this: object, ...args: any[]) => void): this {
    this.inner.listeners.on(typenames, callback);
    return this;
  }

  /**
   * Get the required vertical space for the given number of sources.
   * @param sourceCount The number of sources.
   * @returns The required vertical space.
   */
  public static getRequiredVerticalSpace(sourceCount: number): number {
    // TODO: Extract getRequiredVerticalSpace so we don't need this hack.
    return new SourceLabelsRendererInner(undefined as any, undefined).getRequiredVerticalSpace(sourceCount);
  }
}

/**
 * The inner class for the source labels renderer.
 * The source labels are the labels that appear above the chart area, one for each data source,
 * with a line leading down to the legend column for that source.
 */
export class SourceLabelsRendererInner {

  /**
   * The gap between the bottom of the source labels and the top of the chart area.
   */
  private gapToChart: number = 10;

  /**
   * The Y position of the end of the line which is drawn between the source label text and the
   * top of the legend column it refers to. This should be negative, as 0 is the top of the chart area.
   */
  private labelTargetYPosition: number = 8 - this.gapToChart;

  /**
   * The color of the line between the source label and the legend column.
   */
  private lineColor: string = 'gray';

  /**
   * The D3 style events.
   */
  public listeners = d3.dispatch('changed');

  /**
   * The sources.
   */
  public sources: ReadonlyArray<ISourceLabelsSource> = [];

  /**
   * The shared state.
   */
  public sharedState?: SharedState;

  /**
   * Whether to render the legend lines.
   */
  public renderLegendLines: boolean = true;

  /**
   * Create a new instance of SourceLabelsRendererInner.
   * @param settings The chart settings.
   * @param sourceSplitInspector The source split inspector.
   */
  constructor(
    public readonly settings: IChartSettings,
    private readonly sourceSplitInspector: SourceSplitInspector | undefined) {
  }

  /**
   * Render the source labels.
   * @param selection The selection to render to.
   */
  public render(selection: SVGSelection) {
    // Ensure we have at least one source and a source split inspector.
    if (this.sources.length < 1 || !this.sourceSplitInspector) {
      return;
    }

    const sourceSplitInspector = this.sourceSplitInspector;

    // Create the container for all the source labels.
    let containerUpdate = selection.selectAll<SVGGElement, any>('.' + SOURCE_LABELS_CONTAINER_CLASS).data([null]);
    let containerEnter = containerUpdate.enter().append('g').attr('class', SOURCE_LABELS_CONTAINER_CLASS);
    let container = containerEnter.merge(containerUpdate);

    // Create a container for each source.
    let gUpdate = container.selectAll<SVGGElement, ISourceLabelsSource>('.source-legend').data(() => this.sources as ISourceLabelsSource[]);
    gUpdate.exit().remove();
    let gEnter = gUpdate.enter().append('g').attr('class', 'source-legend');
    let g = gEnter.merge(gUpdate);

    // Position the source container at the top right of the chart area, plus any horizontal space for
    // render channel icons (the icons you click on to use a channel for rendering, for example, track ribbon color or height).
    // Essentially, this is positioned at the top left of the left most legend source column.
    // Because this is positioned below the source labels, the Y position of any source label elements will be negative.
    g.attr('transform', (d, i) =>
      'translate('
      + (this.settings.svgPadding.left + this.settings.chartMargin.left + this.settings.chartSize.width + this.settings.legend.renderChannelWidth)
      + ','
      + (this.settings.svgPadding.top + this.settings.chartMargin.top)
      + ')');

    if (this.renderLegendLines) {
      // Draw the vertical line from the source label to the legend column.
      gEnter.append('line')
        .attr('class', 'vertical-line')
        .attr('stroke-width', 1)
        .attr('stroke', this.lineColor);

      g.select('.vertical-line')
        .attr('x1', (d, i) => this.getLabelTargetXPosition(i))
        .attr('y1', this.labelTargetYPosition)
        .attr('x2', (d, i) => this.getLabelTargetXPosition(i))
        .attr('y2', (d, i) => this.getLabelLineYPosition(i));

      // Draw the horizontal line from the source label to the legend column.
      gEnter.append('line')
        .attr('class', 'horizontal-line')
        .attr('stroke-width', 1)
        .attr('stroke', this.lineColor);

      g.select('.horizontal-line')
        .attr('x1', 8)
        .attr('y1', (d, i) => this.getLabelLineYPosition(i))
        .attr('x2', (d, i) => this.getLabelTargetXPosition(i))
        .attr('y2', (d, i) => this.getLabelLineYPosition(i));
    }

    // Draw the square you can click on to toggle the visibility of a source.
    gEnter.append('rect')
      .attr('class', 'legend-square')
      .attr('width', this.settings.legend.blobSize)
      .attr('height', this.settings.legend.blobSize)
      .style('stroke', this.lineColor)
      .style('cursor', 'pointer')
      .on('click', (_, d) => {
        d.isVisible = !d.isVisible;
        this.listeners.call('changed');
        if (this.sharedState) {
          this.sharedState.sourceLoaderSet.raiseChangedEvent();
        }
      });
    g.select('.legend-square')
      .attr('y', (d, i) => this.getLabelYPosition(i) - this.settings.legend.blobSize + 1.5)
      .attr('x', -this.settings.legend.blobSize / 2)
      .style('fill', d => d.isVisible ? this.lineColor : 'transparent');

    let blobSize = this.settings.legend.blobSize;
    let arrowDownPath = `0,1.5 ${blobSize / 2},${blobSize * 0.75} ${blobSize},1.5`;
    let arrowUpPath = `0,${blobSize - 1.5} ${blobSize / 2},${blobSize * 0.25} ${blobSize},${blobSize - 1.5}`;

    if (this.sharedState) {
      const sharedState = this.sharedState;

      // Draw the down arrow to move the source down the list.
      gEnter.append('g')
        .attr('class', 'legend-down')
        .append('polyline')
        .attr('points', arrowDownPath)
        .attr('fill', this.lineColor)
        .style('stroke', this.lineColor);

      let polylinesDown = g.select('.legend-down');
      polylinesDown.on('click', e => {
          const n = polylinesDown.nodes();
          const i = n.indexOf(e.currentTarget);
          sharedState.sourceLoaderSet.move(i, i + 1);
        });
      g.select('.legend-down')
        .attr('transform', (d, i) =>
          'translate('
          + (-2 * this.settings.legend.blobSize)
          + ','
          + (this.getLabelYPosition(i) - this.settings.legend.blobSize + 1.5)
          + ')')
        .select('polyline')
        .style('opacity', (d, i) => i === this.sources.length - 1 ? 0.2 : 1)
        .style('cursor', (d, i) => i === this.sources.length - 1 ? 'inherit' : 'pointer');

      // Draw the up arrow to move the source up the list.
      gEnter.append('g')
        .attr('class', 'legend-up')
        .append('polyline')
        .attr('points', arrowUpPath)
        .attr('fill', this.lineColor)
        .style('stroke', this.lineColor);

      let polylinesUp = g.select('.legend-up');
      polylinesUp.on('click', e => {
          const n = polylinesUp.nodes();
          const i = n.indexOf(e.currentTarget);
          sharedState.sourceLoaderSet.move(i, i - 1);
        });
      g.select('.legend-up')
        .attr('transform', (d, i) =>
          'translate('
          + (-3 * this.settings.legend.blobSize)
          + ','
          + (this.getLabelYPosition(i) - this.settings.legend.blobSize + 1.5)
          + ')')
        .select('polyline')
        .style('opacity', (d, i) => i === 0 ? 0.2 : 1)
        .style('cursor', (d, i) => i === 0 ? 'inherit' : 'pointer');

      // Draw the split icon, which can be used for splitting or merging sources.
      // (e.g. splitting a multi-lap sim into individual laps, or re-combining them).
      gEnter.append('g')
        .attr('class', 'split-source')
        .attr('title', 'Split')
        .style('fill', this.lineColor)
        .append('text')
        .text('\uf0e8');

      let splits = g.select('.split-source');

      // Handle the click event for the split icon, either splitting or merging the source.
      splits.on('click', (e, d) => {
          const n = splits.nodes();
          const i = n.indexOf(e.currentTarget);
          let source = sharedState.sourceLoaderSet.sources[i];
          if (sourceSplitInspector.hasSplitIndices(d.featureChannels.splitChannel)) {
            let newSources = sourceSplitInspector.splitSource(source, d.featureChannels.splitChannel);
            sharedState.sourceLoaderSet.replace(i, newSources.map(v => new SourceLoaderViewModel(v, d.isVisible)));
          } else if (sourceSplitInspector.canMergeSource(source)) {
            let mergeInformation = sourceSplitInspector.getMergeSourceInformation(
              source,
              sharedState.sourceLoaderSet.sources);

            if (mergeInformation) {
              sharedState.sourceLoaderSet.replaceAll(
                mergeInformation.sourceIndices,
                new SourceLoaderViewModel(mergeInformation.originSource, true));
            }
          }
        });

      // Only display the split icon if the source can be split or merged.
      g.select('.split-source')
        .attr('transform', (d, i) =>
          'translate('
          + (-4.25 * this.settings.legend.blobSize)
          + ','
          + (this.getLabelYPosition(i))
          + ')')
        .style('display', (d: ISourceLabelsSource, i) => {
          if (sourceSplitInspector.hasSplitIndices(d.featureChannels.splitChannel)) {
            return 'inherit';
          } else if (sourceSplitInspector.canMergeSource(sharedState.sourceLoaderSet.sources[i])) {
            return 'inherit';
          }
          return 'none';
        })
        .style('cursor', 'pointer')
        .select('text')
        .attr('transform', (d, i) => {
          if (sourceSplitInspector.canMergeSource(sharedState.sourceLoaderSet.sources[i])) {
            let offset = this.settings.legend.blobSize;
            return `rotate(180) translate(-${offset}, ${offset / 1.75})`;
          }
          return null;
        });
    }

    // Render the source label text, ensuring we adjust the X position depending on whether we're
    // showing the split icon.
    gEnter.append('text')
      .attr('class', 'source-label-text')
      .attr('text-anchor', 'end');
    g.select('.source-label-text')
      .attr('y', (d, i) => this.getLabelYPosition(i))
      .attr('x', (d, i) => {
        let baseOffset = -1;
        let upDownArrowsOffset = this.sharedState ? -2.5 : 0;
        let splitOffset = this.sharedState &&
          (sourceSplitInspector.hasSplitIndices(d.featureChannels.splitChannel)
            || sourceSplitInspector.canMergeSource(this.sharedState.sourceLoaderSet.sources[i]))
          ? -1 : 0;
        return (baseOffset + upDownArrowsOffset + splitOffset) * this.settings.legend.blobSize;
      })
      .attr('fill', (d, i) =>
        this.sources.length <= 1 ? 'black' : this.settings.getChannelColor(0, i))
      .text(d => d.name);
  }

  /**
   * Get the X position we should draw a line to, so that it is above the correct source legend column.
   * @param index The index of the source.
   * @returns The X position.
   */
  private getLabelTargetXPosition(index: number): number {
    return this.settings.legend.valueWidth * (index + 1) - 2.5;
  }

  /**
   * Get the Y position of the source label.
   * @param index The index of the source.
   * @returns The Y position.
   */
  private getLabelYPosition(index: number): number {
    return this.getLabelYPositionForSources(index, this.sources.length);
  }

  /**
   * Get the Y position of the source label for the given number of sources.
   * @param index The index of the source.
   * @param sourceCount The number of sources.
   * @returns The Y position.
   */
  private getLabelYPositionForSources(index: number, sourceCount: number): number {
    let position = sourceCount - 1 - index;
    return -(15 * position) - this.gapToChart;
  }

  /**
   * Get the Y position of the line that connects the source label to the legend column.
   * @param index The index of the source.
   * @returns The Y position.
   */
  private getLabelLineYPosition(index: number): number {
    return this.getLabelYPosition(index) - 3 - 0.5;
  }

  /**
   * Get the required vertical space for the given number of sources, so we have space to render all
   * the source labels.
   * @param sourceCount The number of sources.
   * @returns The required vertical space.
   */
  public getRequiredVerticalSpace(sourceCount: number): number {
    if (sourceCount < 1) {
      return 0;
    }

    return 5 - this.getLabelYPositionForSources(-1, sourceCount) - this.gapToChart;
  }
}

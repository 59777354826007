<div class="container-fluid">
  <div class="card">
    <h1 class="card-header">Reset Password</h1>

    <div class="card-body">
      @if (!submitted && !tokenInvalid) {
        <div class="row" id="form-panel">
          <div class="col-12 col-sm-6">
            <form (ngSubmit)="onSubmit()" [formGroup]="form">
              <div class="mb-3">
                <label class="form-label" for="password-input">New Password</label>
                <input id="password-input" type="password" class="form-control" formControlName="password">
                @if (!password.pristine && password.hasError('required')) {
                  <p class="text-danger">
                    Password is required
                  </p>
                }
                @if (password.hasError('minlength')) {
                  <p class="text-danger">
                    Password is too short.
                  </p>
                }
                @if (password.hasError('maxlength')) {
                  <p class="text-danger">
                    Password is too long.
                  </p>
                }
              </div>
              <cs-error-message [message]="errorMessage"></cs-error-message>
              <button id="reset-password-submit" type="submit" class="btn btn-smart btn-primary" [disabled]="submitButton.isSubmitting">{{submitButton.text}}</button>
            </form>
          </div>
        </div>
      }
      @if (submitted) {
        <div id="success-message">
          <h4 class="text-success">Password has been reset</h4>
          <p>
            Please sign in with your new password. <strong><a href="javascript:void(0)" (click)="authenticationService.login().toPromise()" id="continue-link">Continue to sign in page &raquo;</a></strong>
          </p>
        </div>
      }

      @if (tokenInvalid) {
        <div id="expired-message">
          <h4 class="text-warning">This link has expired</h4>
          <p>
            If you still need to reset your password, please go to the <strong><a [routerLink]="['/sign-in/forgot']">forgot your details</a></strong> page.
          </p>
        </div>
      }
    </div>
  </div>
</div>

import {CanopyError} from './errors/errors';
import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot} from '@angular/router';
import { AuthenticationService, UserData } from '../identity/state/authentication.service';

@Injectable()
export class InferableRouteParams {
  private keyMap: Map<string, string> = new Map<string, string>([
    [ 'sub', 'userId' ],
    [ 'tenant', 'tenantId' ],
  ]);

  constructor(
    private authenticationService: AuthenticationService
  ){
  }

  public getTenantId(route: ActivatedRoute | ActivatedRouteSnapshot): string {
    let routeSnapshot = this.getRouteSnapshot(route);
    return this.tryGet(routeSnapshot, 'tenant');
  }

  public getUserId(route: ActivatedRoute | ActivatedRouteSnapshot): string {
    let routeSnapshot = this.getRouteSnapshot(route);
    return this.tryGet(routeSnapshot, 'sub');
  }

  private getRouteSnapshot(route: ActivatedRoute | ActivatedRouteSnapshot) {
    return route instanceof ActivatedRouteSnapshot
      ? route
      : route.snapshot;
  }

  private tryGet(routeSnapshot: ActivatedRouteSnapshot, key: keyof UserData, throwIfFalsy: boolean = true) {
    let result = routeSnapshot.params[this.keyMap.get(key)] || this.authenticationService.userDataSnapshot[key];

    if(throwIfFalsy && !result){
      throw new CanopyError('Failed to get ' + key);
    }

    return result;
  }
}

import {SLAPCENTRELINE_DOMAIN_NAME, SRUN_DOMAIN_NAME} from '../../constants';

/**
 * Maps a primary domain to a secondary domain.
 * A secondary domain is used to raise cursor events on a domain in addition to the primary domain.
 */
export class SecondaryDomainChannelMapping {
  constructor(
    public readonly primaryDomainName: string,
    public readonly secondaryDomainName: string){
  }
}

/**
 * The secondary domain channel mappings.
 */
const SECONDARY_DOMAIN_CHANNEL_MAPPINGS: ReadonlyArray<SecondaryDomainChannelMapping> = [
  new SecondaryDomainChannelMapping(SRUN_DOMAIN_NAME, SLAPCENTRELINE_DOMAIN_NAME),
];

/**
 * Gets the secondary domain channel mappings.
 * A secondary domain is used to raise cursor events on a domain in addition to the primary domain.
 */
export class GetSecondaryDomainChannelMapping{

  /**
   * Gets the secondary domain channel mappings.
   * @returns The secondary domain channel mappings.
   */
  public execute(): ReadonlyArray<SecondaryDomainChannelMapping> {
    return SECONDARY_DOMAIN_CHANNEL_MAPPINGS;
  }

  /**
   * Gets the secondary domain name for a given primary domain name.
   * @param primaryDomainName The primary domain name.
   * @returns The secondary domain name.
   */
  public executeForChannel(primaryDomainName: string): string | undefined {
    for(let mapping of SECONDARY_DOMAIN_CHANNEL_MAPPINGS) {
      if(mapping.primaryDomainName === primaryDomainName){
        return mapping.secondaryDomainName;
      }
    }

    return undefined;
  }
}

import { ExplorationMap } from '../viewers/channel-data-loaders/exploration-map';
import { SimType } from '../sim-type';
import { ChannelBinaryFormat, ChannelMetadata, SingleScalarResult, StudyMetadata, StudyScalarResults, UrlFileLoader } from '../url-file-loader';
import { UrlFileLoaderBase } from '../url-file-loader-base';

export class MockFileLoader extends UrlFileLoaderBase implements UrlFileLoader {
  constructor(private readonly fileLoader: UrlFileLoader) {
    super();
  }

  public overrideLoadVectorMetadata: (studyId: string, jobIndex: number, simType: SimType) => Promise<ChannelMetadata[]>;
  public overrideLoadScalarResultsForSim: (studyId: string, jobIndex: number, simType: SimType) => Promise<SingleScalarResult[]>;
  public overrideLoadScalarResultsForStudy: (studyId: string) => Promise<StudyScalarResults>;
  public overrideLoadChannelData: (studyId: string, jobIndex: number, simType: SimType, channelName: string, binaryFormat: ChannelBinaryFormat | undefined) => Promise<ReadonlyArray<number>>;
  public overrideLoadExplorationMap: (studyId: string) => Promise<ExplorationMap>;
  public overrideLoadChartLayout: (layoutId: string) => Promise<any>;
  public overrideLoadTrackForStudy: (studyId: string) => Promise<any>;
  public overrideLoadTrackForStudyJob: (studyId: string, jobIndex: number) => Promise<any>;
  public overrideLoadCsv: (fileName: string) => Promise<any>;
  public overrideLoadCarForStudy: (studyId: string) => Promise<any>;
  public overrideLoadCarForStudyJob: (studyId: string, jobIndex: number) => Promise<any>;
  public overrideLoadStudyMetadata: (studyId: string) => Promise<StudyMetadata>;

  public loadVectorMetadata(studyId: string, jobIndex: number, simType: string): Promise<any> {
    return this.overrideLoadVectorMetadata ? this.overrideLoadVectorMetadata(studyId, jobIndex, simType) : this.fileLoader.loadVectorMetadata(studyId, jobIndex, simType);
  }

  public loadScalarResultsForSim(studyId: string, jobIndex: number, simType: string): Promise<any> {
    return this.overrideLoadScalarResultsForSim ? this.overrideLoadScalarResultsForSim(studyId, jobIndex, simType) : this.fileLoader.loadScalarResultsForSim(studyId, jobIndex, simType);
  }

  public loadScalarResultsForStudy(studyId: string): Promise<any> {
    return this.overrideLoadScalarResultsForStudy ? this.overrideLoadScalarResultsForStudy(studyId) : this.fileLoader.loadScalarResultsForStudy(studyId);
  }

  public loadChannelData(studyId: string, jobIndex: number, simType: string, channelName: string, binaryFormat: any): Promise<any> {
    return this.overrideLoadChannelData ? this.overrideLoadChannelData(studyId, jobIndex, simType, channelName, binaryFormat) : this.fileLoader.loadChannelData(studyId, jobIndex, simType, channelName, binaryFormat);
  }

  public loadExplorationMap(studyId: string): Promise<any> {
    return this.overrideLoadExplorationMap ? this.overrideLoadExplorationMap(studyId) : this.fileLoader.loadExplorationMap(studyId);
  }

  public loadChartLayout(layoutId: string): Promise<any> {
    return this.overrideLoadChartLayout ? this.overrideLoadChartLayout(layoutId) : this.fileLoader.loadChartLayout(layoutId);
  }

  public loadTrackForStudy(studyId: string): Promise<any> {
    return this.overrideLoadTrackForStudy ? this.overrideLoadTrackForStudy(studyId) : this.fileLoader.loadTrackForStudy(studyId);
  }

  public loadTrackForStudyJob(studyId: string, jobIndex: number): Promise<any> {
    return this.overrideLoadTrackForStudyJob ? this.overrideLoadTrackForStudyJob(studyId, jobIndex) : this.fileLoader.loadTrackForStudyJob(studyId, jobIndex);
  }

  public loadCsv(fileName: string): Promise<any> {
    return this.overrideLoadCsv ? this.overrideLoadCsv(fileName) : this.fileLoader.loadCsv(fileName);
  }

  public loadCarForStudy(studyId: string): Promise<any> {
    return this.overrideLoadCarForStudy ? this.overrideLoadCarForStudy(studyId) : this.fileLoader.loadCarForStudy(studyId);
  }

  public loadCarForStudyJob(studyId: string, jobIndex: number): Promise<any> {
    return this.overrideLoadCarForStudyJob ? this.overrideLoadCarForStudyJob(studyId, jobIndex) : this.fileLoader.loadCarForStudyJob(studyId, jobIndex);
  }

  public loadStudyMetadata(studyId: string): Promise<any> {
    return this.overrideLoadStudyMetadata ? this.overrideLoadStudyMetadata(studyId) : this.fileLoader.loadStudyMetadata(studyId);
  }
}

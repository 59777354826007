@if (tenantSimVersion) {
  <div class="container-fluid" id="sim-version-status"
    >
    <cs-error-message [message]="errorMessage"></cs-error-message>
    <div class="right-container">
      <div class="version-item" [ngClass]="{'active-version': !!userSimVersion}"
        (click)="selectUserSimVersion()">
        <div class="version-item-left">
          User Sim Version
        </div><div class="version-item-right">
        {{userSimVersion ? userSimVersion : 'From Tenant'}}
      </div>
    </div>
    <div class="version-item" [ngClass]="{'active-version': !userSimVersion}"
      (click)="selectTenantSimVersion()">
      <div class="version-item-left">
        Tenant Sim Version
      </div><div class="version-item-right">
      @if (isUpdatingTenantSimVersion) {
        <span class="updating-sim-version-message"
          >
          <cs-loader></cs-loader>
        </span>
      }
      <span [ngStyle]="{ 'visibility': isUpdatingTenantSimVersion ? 'hidden' : 'visible' }">
        {{tenantSimVersion}}
      </span>
    </div>
  </div>
</div>
</div>
}

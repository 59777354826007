import { IMultiPlotChannel } from './types/i-multi-plot-channel';


/**
 * Validates a channel in a multi-plot layout.
 */
export class GetValidatedChannel {

  /**
   * Validates a channel in a multi-plot layout.
   *
   * @param channel - The channel to validate.
   * @returns Either the original channel (perhaps modified) or a new validated channel.
   */
  public execute(channel: IMultiPlotChannel | string): IMultiPlotChannel {

    // If the channel has been specified as a string, turn it into an object.
    if (typeof channel === 'string') {
      channel = {
        name: channel
      };
    }

    // Set a default visibility.
    if (typeof channel.isVisible === 'undefined') {
      channel.isVisible = true;
    }

    // Set a default delta status.
    if (typeof channel.isDelta === 'undefined') {
      channel.isDelta = false;
    }

    // Set a default reverse axis status.
    if (typeof channel.reverseAxis === 'undefined') {
      channel.reverseAxis = false;
    }

    return channel;
  }
}

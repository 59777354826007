import {Injectable} from '@angular/core';
import {DialogManager, IDialog, DialogBase} from '../../../../common/dialogs/dialog-manager.service';
import {DocumentSubType} from '../../../../../generated/api-stubs';
import {ConfigType} from '../../config-types';
import {StudyInput} from '../../../../worksheets/study-input';
import {ConfigOrConfigLoader} from '../config-or-config-loader';

export const NO_SAVE_OUTPUT_CONFIG_HANDLER: ISaveOutputConfigHandler = undefined;

@Injectable()
export class CompareConfigDialog {
  constructor(
    private dialogManager: DialogManager
  ){}

  public async compare(
    configType: DocumentSubType | ConfigType | undefined,
    configs: ReadonlyArray<ConfigOrConfigLoader>,
    saveOutputConfigHandler: ISaveOutputConfigHandler,
    saveOutputButtonText?: string): Promise<CompareConfigDialogResult> {

    if(configType instanceof ConfigType){
      configType = configType.singularKey;
    }

    let dialog = new CompareConfigDialogData(
      configType,
      configs,
      saveOutputConfigHandler,
      saveOutputButtonText || 'Save Output');

    dialog.dismissData = new CompareConfigDialogResult();

    return await this.dialogManager.showSecondaryDialog(dialog);
  }

  public async compareDeprecated(
    configType: DocumentSubType | ConfigType | undefined,
    first: any,
    second: any,
    title: string,
    firstTitle: string = 'first',
    secondTitle: string = 'second'): Promise<void> {

    if(configType instanceof ConfigType){
      configType = configType.singularKey;
    }

    let dialog = new CompareConfigDialogDataDeprecated(
      configType,
      first,
      second,
      title,
      firstTitle,
      secondTitle);
    return await this.dialogManager.showSecondaryDialog(dialog);
  }
}

export class CompareConfigDialogData extends DialogBase<CompareConfigDialogResult> implements IDialog {
  constructor(
    public readonly configType: DocumentSubType | undefined,
    public readonly configs: ReadonlyArray<ConfigOrConfigLoader>,
    public readonly saveOutputConfigHandler: ISaveOutputConfigHandler | undefined,
    public readonly saveOutputButtonText: string) {
    super();
  }
}

export class CompareConfigDialogDataDeprecated extends DialogBase<CompareConfigDialogResult> implements IDialog {
  constructor(
    public readonly configType: DocumentSubType | undefined,
    public readonly first: any,
    public readonly second: any,
    public readonly title: string,
    public readonly firstTitle: string,
    public readonly secondTitle: string) {
    super();
  }
}

export class CompareConfigDialogResult {
  constructor() {
  }
}

export interface ISaveOutputConfigHandler {
  canExecute(studyInput: StudyInput): boolean;
  execute(studyInput: StudyInput): Promise<void>;
}

import { CanopyJsonEditorOptions } from '../json-editor.service';

export function getCanopyJsonEditorOptions(self: any): CanopyJsonEditorOptions {

  if (!self) {
    throw new Error('Could not find JSON Editor node.');
  }

  const jsonEditor = self.jsoneditor;

  if (!jsonEditor) {
    throw new Error('Could not find JSON Editor.');
  }

  const result = jsonEditor.options.canopy;

  if (!result) {
    throw new Error('Could not find Canopy JSON Editor options.');
  }

  return result;
}

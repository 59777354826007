<div class="card">
  <h1 class="card-header">
    {{jobResult ? 'Job' : 'Study'}} Inputs
    <cs-stage-all-inputs class="card-header-float-right" [tenantId]="studyResult.study.tenantId" [studyId]="studyResult.study.documentId" [jobId]="jobResult?.studyJob?.documentId"></cs-stage-all-inputs>
  </h1>

  <div class="card-body">
    <table class="table last-item">
      <thead>
        <tr>
          <th>Type</th>
          <th>Name</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        @for (item of sources; track item) {
          <tr>
            <td>{{item.type}}</td>
            <td>
              {{item.name}}
              @if (item.isEdited) {
                <i id="study-edited-{{item.typeKey}}-icon" class="fa fa-pencil"></i>
              }
            </td>
            <td class="d-flex justify-content-between flex-wrap w-100" style="gap:0.5rem">
              <button id="stage-{{item.typeKey}}-button" class="btn btn-secondary" (click)="stageSource(item)">
                <i class="fa fa-arrow-circle-up"></i>Stage
              </button>
              <span class="button-menu-hover-target">
                <button id="compare-{{item.typeKey}}-hover-button" class="btn btn-smart btn-secondary">
                  <i class="fa fa-files-o"></i>Compare to ...
                </button>
                <span class="button-menu-hovering-area">
                  <cs-compare-to-something-button
                    [configType]="item.typeKey"
                    [getConfigOrConfigLoader]="getConfigOrConfigLoaderCallback(item.typeKey)">
                  </cs-compare-to-something-button>
                  <button id="compare-{{item.typeKey}}-button" class="btn btn-secondary" (click)="compareToStaged(item)">
                    <i class="fa fa-files-o"></i>Compare to Staged
                  </button>
                </span>
              </span>
              <cs-export-config-buttons
                [isAdministrator]="isAdministrator"
                [getConfigOrConfigLoader]="getConfigOrConfigLoaderCallback(item.typeKey)">
              </cs-export-config-buttons>
            </td>
          </tr>
        }
      </tbody>
    </table>
  </div>
</div>

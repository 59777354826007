<div class="view-telemetry-component">
  <div class="list-card">
    <h1 class="list-card-header">{{configType.titleName}}</h1>
    <div class="list-card-body">
      <div class="list-card-padded">
        <p>
          <a id="back-button" class="btn btn-smart btn-secondary" [routerLink]="['/']">
            <i class="fa fa-caret-left"></i>Back
          </a>
          <cs-import-telemetry-dialog-launcher></cs-import-telemetry-dialog-launcher>
          <a id="new-config-button" class="btn btn-smart btn-secondary" [routerLink]="['/configs', configType.pluralKey, 'create']">
            <i class="fa fa-file-o"></i>New {{configType.titleName}}
          </a>
        </p>
      </div>
      <cs-view-studies
        [initialQuery]="initialQuery"
        (studySelected)="studySelected($event)"></cs-view-studies>
    </div>
  </div>
</div>

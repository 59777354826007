import { IMinMax } from '../../min-max';
import { TransientMultiPlotBase } from './transient-multi-plot-base';

/**
 * Transient data for a multi-plot channel.
 */

export class TransientMultiPlotChannel extends TransientMultiPlotBase {

  /**
   * An optional filter on the channel (used in the parallel coordinates plot).
   */
  public filter?: IMinMax;
}

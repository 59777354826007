export function getParameterNameFromPath(path: string){
  let channelName = path;
  if(channelName){
    let lastPeriodIndex = channelName.lastIndexOf('.');
    if(lastPeriodIndex !== -1){
      channelName = channelName.substring(lastPeriodIndex + 1);
    }

    let bracketIndex = channelName.indexOf('[');
    if(bracketIndex !== -1){
      channelName = channelName.substring(0, bracketIndex);
    }
  }

  return channelName;
}

import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken } from '@ngxs/store';
import { ErrorMessageService } from '../../../common/errors/services/error-message/error-message.service';
import { UserChangedMessage } from '../../../identity/state/identity.actions';
import { EncryptionConfigPermission, EncryptionStub } from '../../../../generated/api-stubs';
import { ClearConfigPermission, DeleteConfigPermission, FetchConfigPermissions, PutConfigPermission, SetConfigPermission } from './config-permissions.actions';

export interface ConfigPermissionsStateModel {
  permissions: EncryptionConfigPermission[];
  selectedPermission: EncryptionConfigPermission;
}

const STATE_TOKEN = new StateToken<ConfigPermissionsStateModel>('configPermissions');

@State({
  name: STATE_TOKEN
})
@Injectable()
export class ConfigPermissionsState {
  constructor(
    private _errorMessageService: ErrorMessageService,
    private _encryptionStub: EncryptionStub) {}


  @Action(UserChangedMessage)
  public userChanged(ctx: StateContext<ConfigPermissionsStateModel>): void {
    ctx.setState({
      permissions: [],
      selectedPermission: undefined,
    });
  }

  @Action(FetchConfigPermissions)
  public async fetchConfigPermission(ctx: StateContext<ConfigPermissionsStateModel>, action: FetchConfigPermissions): Promise<void> {
    this._errorMessageService.clearErrorMessage();
    try {
      const { tenantId } = action;
      const configPermissionsResult = await this._encryptionStub.getConfigPermissions(tenantId);
      ctx.patchState({
        permissions: configPermissionsResult.permissions,
        selectedPermission: undefined,
      });
    } catch (err) {
      this._errorMessageService.setErrorMessage(err);
    }
  }

  @Action(SetConfigPermission)
  public setConfigPermission(ctx: StateContext<ConfigPermissionsStateModel>, action: SetConfigPermission): void {
    const { encryptingTenantShortName, decryptingTenantShortName, minimumSimVersion, description } = action;
    ctx.patchState({
      selectedPermission: {
        encryptingTenantShortName,
        decryptingTenantShortName,
        minimumSimVersion,
        description
      }
    });
  }

  @Action(ClearConfigPermission)
  public clearConfigPermission(ctx: StateContext<ConfigPermissionsStateModel>): void {
    ctx.patchState({
      selectedPermission: undefined
    });
  }

  @Action(PutConfigPermission)
  public async putConfigPermission(ctx: StateContext<ConfigPermissionsStateModel>, action: PutConfigPermission): Promise<void> {
    this._errorMessageService.clearErrorMessage();
    try {
      const { tenantId, encryptingTenantShortName, decryptingTenantShortName, minimumSimVersion, description } = action;
      await this._encryptionStub.putConfigPermission(
        tenantId,
        encryptingTenantShortName,
        decryptingTenantShortName,
        {
          minimumSimVersion,
          description
        });
    } catch (err) {
      this._errorMessageService.setErrorMessage(err);
      throw err;
    }
  }

  @Action(DeleteConfigPermission)
  public async deleteConfigPermission(ctx: StateContext<ConfigPermissionsStateModel>, action: DeleteConfigPermission): Promise<void> {
    this._errorMessageService.clearErrorMessage();
    try {
      const { tenantId, encryptingTenantShortName, decryptingTenantShortName } = action;
      await this._encryptionStub.deleteConfigPermission(tenantId, encryptingTenantShortName, decryptingTenantShortName);
    } catch (err) {
      this._errorMessageService.setErrorMessage(err);
    }
  }
}

import { SVGSelection } from '../../untyped-selection';
import { Dimension, ParallelCoordinatesData } from './parallel-coordinates-types';
import { Utilities } from '../../utilities';
import { InterpolationCoordinatesFactory } from '../channel-data-loaders/interpolation/interpolation-coordinates';
import { ChannelInterpolator } from '../channel-data-loaders/interpolation/channel-interpolator';
import { Units } from '../../units';
import { SharedState } from '../shared-state';
import { ParallelCoordinatesDataRenderer } from './parallel-coordinates-data-renderer';
import { ParallelCoordinatesViewerSettings } from './parallel-coordinates-viewer-settings';
import { Subscription } from 'rxjs';
import { ISlider, sliderLeft } from '../components/slider';
import * as d3 from '../../d3-bundle';
import { AXIS_REVERSAL_TRANSITION_TIME } from './parallel-coordinates-axis-renderer';
import { ExplorationChannelMetadata } from '../channel-data-loaders/exploration-channel-metadata';
import { ProcessedMultiPlotChannel } from '../data-pipeline/types/processed-multi-plot-channel';
import { isNullOrUndefined } from '../../is-null-or-undefined';

const SLIDER_PROPERTY = '__cs_slider';

/**
 * Class to render the interpolation line in the parallel coordinates viewer.
 * The interpolation line is the line which allows the user to explore the RBF interpolation
 * over the input and output dimensions.
 */
export class ParallelCoordinatesInterpolationLineRenderer {

  /**
   * The data for the parallel coordinates viewer.
   */
  private data?: ParallelCoordinatesData;

  /**
   * The subscriptions for the renderer.
   */
  private subscriptions: Subscription = new Subscription();

  /**
   * Create a new instance of the parallel coordinates interpolation line renderer.
   * @param settings The parallel coordinates viewer settings.
   * @param svg The SVG selection.
   * @param dataRenderer The parallel coordinates data renderer.
   * @param sharedState The shared state.
   * @param channelInterpolator The channel interpolator.
   */
  constructor(
    private readonly settings: ParallelCoordinatesViewerSettings,
    private readonly svg: SVGSelection,
    private readonly dataRenderer: ParallelCoordinatesDataRenderer,
    private readonly sharedState: SharedState,
    private readonly channelInterpolator: ChannelInterpolator) {

    // Subscribe to events when the interpolation line points change. This can happen externally when the
    // user drags the sliders under the single dimension plots.
    this.subscriptions.add(this.sharedState.definedDimensions.rCoordinatesNews.subscribe(() => this.onrCoordinatesNews()));
  }

  /**
   * Dispose of the parallel coordinates interpolation line renderer.
   */
  public dispose() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Called when the r-coordinates change. The r-coordinates are the normalized values on the input dimensions where
   * we are currently interpolating.
   */
  private onrCoordinatesNews() {
    this.render();
  }

  /**
   * Set the parallel coordinates data.
   * @param data The parallel coordinates data.
   */
  public setData(data: ParallelCoordinatesData) {
    this.data = data;
  }

  /**
   * Render the interpolation line in the parallel coordinates viewer.
   */
  public render() {
    let isSourceHidden = !this.sharedState.isFirstSourceVisible;

    this.renderInterpolationLine(isSourceHidden);
    this.renderSliders(isSourceHidden);
  }

  /**
   * Render the sliders for the input dimensions that appear next to the vertical input dimension axes.
   * @param isSourceHidden Whether the data source is hidden.
   */
  private renderSliders(isSourceHidden: boolean) {
    if (!this.data) {
      return;
    }

    // Create the container for the sliders.
    let containerUpdate = this.svg.selectAll<SVGGElement, any>('.interpolation-sliders-container')
      .data([null]);
    let containerEnter = containerUpdate.enter().append('g')
      .attr('class', 'interpolation-sliders-container')
      .attr('transform', 'translate(' + this.settings.svgPadding.left + ',' + this.settings.svgPadding.top + ')');
    let container = containerUpdate.merge(containerEnter);

    // Create a container for each slider.
    let sliderContainerUpdate = container.selectAll<SVGGElement, Dimension>('.slider-container')
      .data(isSourceHidden ? [] : this.data.dimensionList.filter(v => v.isInputDimension));
    sliderContainerUpdate.exit().remove();
    let sliderContainerEnter = sliderContainerUpdate.enter().append<SVGGElement>('g')
      .attr('class', 'slider-container');
    let sliderContainer = sliderContainerUpdate.merge(sliderContainerEnter);
    sliderContainer
      .attr('transform', d => `translate(${d.renderPosition})`);

    let self = this;

    // Create a D3 slider for each input dimension.
    sliderContainerEnter.each(function(d: Dimension) {
      (this as any)[SLIDER_PROPERTY] = sliderLeft();
    });
    sliderContainer.each(function(d: Dimension) {
      let trackLength = self.getSliderTrackLength(d);

      // Get the slider position from the current r-coordinate value.
      let position = self.getSliderPosition(d);

      let slider = ((this as any)[SLIDER_PROPERTY] as ISlider);
      slider
        .trackLength(trackLength)
        .on('moved', (event) => {
          // Note that we overwrite the moved delegate
          // as the bound dimension instance may have changed.
          let position = event.position;
          let value = d.scale.invert(position);
          const dataDomain = d.dataDomain;

          let rCoordinate = rCoordinateFromData(value, dataDomain, d.channel);
          rCoordinate = mapFromDataToIndexNormalizedValue(rCoordinate, d.channel);

          // Raise an event that the rCoordinate for this dimension has changed.
          self.sharedState.definedDimensions.rCoordinatesSet(
            d.explorationData.inputDimensionIndex,
            rCoordinate);
        });
      if (d.isAxisReversing) {
        // If the axis is reversing, reposition the slider with a transition.
        d3.select<SVGElement, Dimension>(this).call(slider.reposition, position, AXIS_REVERSAL_TRANSITION_TIME);
      } else {
        // Otherwise just set the slider position.
        slider.position(position);
        d3.select<SVGGElement, Dimension>(this).call(slider);
      }
    });
  }

  /**
   * Get the length of the slider track in pixels for a dimension.
   * @param d The dimension.
   * @returns The length of the slider track in pixels.
   */
  private getSliderTrackLength(d: Dimension): number {
    return d3.maxStrict(d.scale.range()) - d3.minStrict(d.scale.range());
  }

  /**
   * Get the position of the slider in pixel space for a dimension based on the current r-coordinate position..
   * @param d The dimension.
   * @returns The position of the slider in pixel space.
   */
  private getSliderPosition(d: Dimension): number {
    const dataDomain = d.dataDomain;

    let rCoordinate = this.sharedState.definedDimensions.rCoordinates[d.explorationData.inputDimensionIndex];
    rCoordinate = mapFromIndexToDataNormalizedValue(rCoordinate, d.channel);
    const value = rCoordinateToData(rCoordinate, dataDomain, d.channel);

    return d.scale(value);
  }

  /**
   * Render the interpolation line in the parallel coordinates viewer.
   * @param isSourceHidden
   */
  private renderInterpolationLine(isSourceHidden: boolean) {
    let rCoordinates = this.sharedState.definedDimensions.rCoordinates.slice();
    let interpolationData = this.getInterpolationData(rCoordinates);

    // Create a container for the interpolation line.
    let containerUpdate = this.svg.selectAll<SVGGElement, number[]>('.interpolation-line-container')
      .data(isSourceHidden ? [] : [interpolationData]);
    containerUpdate.exit().remove();
    let containerEnter = containerUpdate.enter().append('g')
      .attr('class', 'interpolation-line-container')
      .attr('transform', 'translate(' + this.settings.svgPadding.left + ',' + this.settings.svgPadding.top + ')');
    let container = containerUpdate.merge(containerEnter);

    // Create an SVG path for the interpolation line.
    containerEnter.append('path')
      .attr('class', 'interpolation-line')
      .on('click mousemove', currentEvent => {
        // Stops other chart actions from occurring when the user is over the interpolation line.
        currentEvent.stopPropagation();
      });
    // Set the data for the SVG path.
    container.select('.interpolation-line')
      .attr('d', d => this.dataRenderer.createLine(d)(d));
  }

  /**
   * Returns the data values for the interpolation line.
   * @param rCoordinates The r-coordinates.
   * @returns The data values for the interpolation line.
   */
  private getInterpolationData(rCoordinates: ReadonlyArray<number>): number[] {
    let outputValues = this.getInterpolatedPoint(rCoordinates);
    let result: number[] = [];
    if (this.data) {
      for (let dimension of this.data.dimensionList) {
        if (dimension.isInputDimension) {
          const dataDomain = dimension.dataDomain;

          let rCoordinate = rCoordinates[dimension.explorationData.inputDimensionIndex];
          rCoordinate = mapFromIndexToDataNormalizedValue(rCoordinate, dimension.channel);
          const value = rCoordinateToData(rCoordinate, dataDomain, dimension.channel);

          result.push(value);
        } else {
          result.push(outputValues[dimension.channel.name]);
        }
      }
    }
    return result;
  }

  /**
   * Get the interpolated output dimension values for the given r-coordinates.
   * @param rCoordinates The r-coordinates.
   * @returns The interpolated output dimension values.
   */
  public getInterpolatedPoint(rCoordinates: ReadonlyArray<number>): InterpolatedPoint {
    if (!this.data) {
      return {};
    }

    let outputDimensions = this.data.dimensionList.filter(v => !v.isInputDimension);
    let outputChannelNames = outputDimensions.map(v => v.channel.name);
    let interpolationResult = this.interpolationFunction(outputChannelNames, rCoordinates.map(e => [e]));
    let result: InterpolatedPoint = {};
    for (let dimension of outputDimensions) {
      let channelUnits = dimension.channel.units;
      if (isNullOrUndefined(channelUnits)) {
        continue;
      }

      let channelName = dimension.channel.name;
      result[channelName] = Units.convertValueFromSi(interpolationResult[channelName][0], channelUnits);
    }

    return result;
  }

  /**
   * The function which will return the map of channel names to interpolated values for the given channel names
   * and input coordinates.
   * @param channelNames The channel names to evaluate.
   * @param setOfPoints The set of points to evaluate.
   * @returns The map of channel names to interpolated values.
   */
  protected interpolationFunction(channelNames: string[], setOfPoints: number[][]): { [channelName: string]: number[] } {
    let interpolationCoordinates = InterpolationCoordinatesFactory.fromArray(setOfPoints);
    return this.channelInterpolator.execute(channelNames, interpolationCoordinates);
  }
}

/**
 * Maps a value from a normalized data value to a normalized index value.
 * @param rCoordinate The normalized data value.
 * @param channel The channel.
 * @returns The normalized index value.
 */
function mapFromDataToIndexNormalizedValue(rCoordinate: number, channel: ProcessedMultiPlotChannel): number {
  let loaderMetadata = ExplorationChannelMetadata.fromProcessedMultiPlotChannel(channel);
  if (loaderMetadata) {
    return loaderMetadata.explorationSubSweep.mapFromDataToIndexNormalizedValue(rCoordinate);
  }

  return rCoordinate;
}

/**
 * Maps a value from a normalized index value to a normalized data value.
 * @param rCoordinate The normalized index value.
 * @param channel The channel.
 * @returns The normalized data value.
 */
function mapFromIndexToDataNormalizedValue(rCoordinate: number, channel: ProcessedMultiPlotChannel): number {
  let loaderMetadata = ExplorationChannelMetadata.fromProcessedMultiPlotChannel(channel);
  if (loaderMetadata) {
    return loaderMetadata.explorationSubSweep.mapFromIndexToDataNormalizedValue(rCoordinate);
  }

  return rCoordinate;
}

/**
 * Maps a value from an r-coordinate to a data value.
 * @param rCoordinate The r-coordinate.
 * @param dataDomain The data domain.
 * @param channel The channel.
 * @returns The data value.
 */
function rCoordinateToData(rCoordinate: number, dataDomain: Readonly<[number, number]>, channel: ProcessedMultiPlotChannel) {
  return rCoordinate
    * Utilities.diff(dataDomain)
    + dataDomain[0];
}

/**
 * Maps a value from a data value to an r-coordinate.
 * @param value The data value.
 * @param dataDomain The data domain.
 * @param channel The channel.
 * @returns The r-coordinate.
 */
function rCoordinateFromData(value: number, dataDomain: Readonly<[number, number]>, channel: ProcessedMultiPlotChannel) {
  let rCoordinate = (value - dataDomain[0]) / Utilities.diff(dataDomain);
  return rCoordinate;
}

/**
 * A mapping from channel name to RBF interpolated value.
 */
interface InterpolatedPoint {
  [channelName: string]: number;
}

import {Component, OnInit} from '@angular/core';
import { RedirectService } from './navigation/redirect.service';

@Component({
  template: `
    <div class="container-fluid">
      <div class="card card-body">
        <p>Redirecting</p>
      </div>
    </div>`,
})
export class SignInRedirectPage implements OnInit {
  constructor(private readonly redirectService: RedirectService) {
  }

  ngOnInit(){
    this.redirectService.handleRedirectWithDefault();
  }
}

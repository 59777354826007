import * as d3 from '../../../d3-bundle';
import { ExplorationInputs } from './exploration-inputs';
import { ExplorationJob } from './exploration-job';
import { DesignName } from '../exploration-map';
import { ExplorationJobsMetadata } from './get-exploration-jobs-metadata-from-exploration-map';
import { GetExplorationJobs } from './get-exploration-jobs';
import { ArrayMappings } from './exploration-sorting/array-mappings';
import { CompareJobFactorialPositions } from './compare-job-factorial-positions';


/**
 * The `ProcessedExplorationMap` is an easier to work with version of the `ExplorationMap` structure. The
 * `ExplorationMap` is output during the creation of a study, so we convert it to a `ProcessedExplorationMap`
 * as soon as possible after reading it in.
 *
 *  - Instead of a `struct-of-arrays` layout we switch it to an `array-of-structs` layout. This makes type
 *    safety far easier to deal with throughout the code.
 *
 *  - We extract the exploration inputs from the exploration map using the `indexMatrix` and `coordinates` arrays.
 *
 *  - We expand array sweeps up front into parallel sub-sweeps for each element, so that nothing else in the code has
 *    to deal with array sweeps.
 *
 *  - We extract numeric values for string and bool sweeps up front, so we don't have to generate them elsewhere.
 *
 *  - We replace `coordinates` and `rCoordinates` with `values`, `normalizedValues`, `index`, `normalizedIndex`, etc.
 *    - `rCoordinates` in explorations are in the range 0..1 for sweeps, but integer indexes for enumerations. This
 *      makes them hard to deal with, so we split this into `index` and `normalizedIndex`.
 *    - `normalizedValues` allows us to properly support interpolating between non-linear sub sweeps,
 *       including when sibling sub-sweeps are non-linear in different ways. Previously this was handled incorrectly.
 *
 *  - For each job, we then discard everything except the job name and the indexes into each sweep, and re-build the jobs
 *    using the indexes and the inputs.
 *
 *  - We sort each sub-sweep by the first numeric sub-sweep in the sweep, and update the indexes in each job to match.
 *    - This allows us to map both factorials and monte-carlo sub-sweep values on the sliders back to a single `rCoordinate` in index-space.
 *    - It allows randomly ordered points (e.g. monte-carlo, or badly done factorials) to be handled as if they were ordered, and for the
 *      monotonic direction to be easily established.
 *    - We can then map back from index-space to any other sub-sweep, taking into account its individual non-linearity.
 *      - This allows the sibling sub-sweep sliders to correctly track each other in the viewers.
 *    - This does add a complication to the `interpolateFactorialPoint` function, as it assumes the factorial points are in order
 *      (`[0,0,0], [1,0,0], [2,0,0], [0,1,0], [1,1,0], etc`), so we store a mapping to jobs in factorial order in the root of the
 *      `ProcessedExplorationMap` structure so it can pick the jobs out in the expected order.
 *
 * After all this was done, supporting non-linear sweeps was quite easy.
 *
 * A lot of code dealing with array sweeps, and string and bool sweeps, was also simplified as the complexity is now
 * dealt with on import.
 */
export class ProcessedExplorationMap {

  /**
   * The normalized sub-sweep values for each job.
   */
  public readonly normalizedSubSweepValues: ReadonlyArray<ReadonlyArray<ReadonlyArray<number>>>;

  /**
   * The normalized sweep indices for each job.
   */
  public readonly normalizedSweepIndices: ReadonlyArray<ReadonlyArray<number>>;

  /**
   * A mapping from the sorted job order to the original job order.
   */
  public readonly jobOrderMapping: ArrayMappings;

  /**
   * Creates a new `ProcessedExplorationMap`.
   * @param designName The type of exploration design (e.g. factorial, monte-carlo, etc.)
   * @param inputs The exploration inputs.
   * @param jobs The exploration jobs.
   */
  constructor(
    public readonly designName: DesignName,
    public readonly inputs: ExplorationInputs,
    public readonly jobs: ReadonlyArray<ExplorationJob>) {

    this.normalizedSubSweepValues = jobs.map(job => job.sweeps.map(sweep => sweep.normalizedSubSweepValues));
    this.normalizedSweepIndices = jobs.map(job => job.sweeps.map(sweep => sweep.normalizedSubSweepIndex));

    // As mentioned in the above comments, because we change the order of the sub-sweeps in the jobs as part of the
    // processing to make rendering easier, we also must store a mapping which allows us to read the job in factorial order
    // with the new sweep order (`[0,0,0], [1,0,0], [2,0,0], [0,1,0], [1,1,0], etc`).
    // This is primarily used in `interpolateFactorialPoint`.
    const jobOrderMapping: number[] = d3.range(jobs.length);
    if (designName === DesignName.factorial) {
      jobOrderMapping.sort((a, b) => CompareJobFactorialPositions.execute(jobs[a], jobs[b]));
    }

    this.jobOrderMapping = new ArrayMappings(jobOrderMapping);
  }

  /**
   * Creates a `ProcessedExplorationMap` for testing purposes.
   * @param designName The type of exploration design (e.g. factorial, monte-carlo, etc.)
   * @param inputs The exploration inputs.
   * @param jobs The exploration jobs.
   * @returns A new `ProcessedExplorationMap` for testing purposes.
   */
  public static createForTest(designName: DesignName, inputs: ExplorationInputs, jobs: ExplorationJobsMetadata) {
    return new ProcessedExplorationMap(
      designName,
      inputs,
      GetExplorationJobs.create().execute(inputs, jobs));
  }

  /**
   * Gets the number of dimensions in the exploration.
   */
  public get dimensionCount(): number {
    return this.inputs.sweeps.length;
  }
}

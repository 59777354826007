<div id="tenant-information-page">

  <div class="container-fluid">
    <div class="card">
      @if (tenant) {
        <h1 class="card-header" id="tenant-name">
          {{tenant.name}}
        </h1>
      }
      <div class="card-body">
        <cs-error-message [message]="errorMessage"></cs-error-message>
        @if (!tenant) {
          <cs-loader></cs-loader>
        }
        @if (tenant) {
          <div>
            @if (!tenant.isEnabled) {
              <div class="alert alert-danger">
                <p>Your account <strong>{{tenant.shortName}}</strong> has been disabled.</p>
              </div>
            }
            <p>Your company sign-in name is <strong id="tenant-short-name">{{tenant.shortName}}</strong>.</p>
            @if (!configTypes) {
              <cs-loader></cs-loader>
            }
            @if (studyTypes) {
              <div class="feature-area">
                <h5>Study Types:</h5>
                @if (!studyTypes.length) {
                  <span><strong>none</strong>.</span>
                }
                @if (studyTypes.length) {
                  <span>
                    @for (item of studyTypes; track item; let i = $index) {
                      <span>
                        <span class="feature" [ngClass]="{ 'disabled-feature': !isStudyTypeEnabled(item) }">{{item.name}}</span></span>
                      }
                    </span>
                  }
                </div>
              }
              @if (configTypes) {
                <div class="feature-area">
                  <h5>Config Types:</h5>
                  @if (!configTypes.length) {
                    <span><strong>none</strong>.</span>
                  }
                  @if (configTypes.length) {
                    <span>
                      @for (item of configTypes; track item; let i = $index) {
                        <span>
                          <span class="feature" [ngClass]="{ 'disabled-feature': !isConfigTypeEnabled(item) }">{{item.titleName}}</span></span>
                        }
                      </span>
                    }
                  </div>
                }
                @if (isTenantAdministrator) {
                  <p class="last-item">
                    <a id="edit-tenant-link" class="btn btn-smart btn-secondary" [routerLink]="['/tenant-account-settings', tenantId]">Edit</a>
                  </p>
                }
              </div>
            }
          </div>
        </div>

        <div class="card">

          <h1 class="card-header">Members</h1>
          <div class="card-body">
            @if (isTenantAdministrator) {
              <p>
                <a id="new-user-link" class="btn btn-smart btn-secondary" [routerLink]="['/register', tenantId]">New user</a>
              </p>
            }
            @if (!tenantUsers) {
              <cs-loader></cs-loader>
            }
            @if (tenantUsers) {
              <div>
                <p>
                  <strong>{{tenant.name}}</strong> has
                  <strong id="tenant-count">{{tenantUsers.length}}</strong> user{{tenantUsers.length === 1 ? '' : 's'}}.
                </p>
                <table class="table users-table last-item">
                  <thead>
                    <tr>
                      <th>Username</th>
                      <th>Email</th>
                      <th>Enabled</th>
                      <th>Roles</th>
                    </tr>
                  </thead>
                  <tbody>
                    @for (user of tenantUsers; track user) {
                      <tr [ngClass]="{ 'disabled-user': !user.isEnabled }">
                        <td class="strikethrough-if-disabled">
                          @if (isTenantAdministrator) {
                            <a id="username-link-{{user.username}}" [routerLink]="['/account-settings', tenantId, user.userId]">{{user.username}}</a>
                          }
                          @if (!isTenantAdministrator) {
                            <span id="username-{{user.username}}">{{user.username}}</span>
                          }
                        </td>
                        <td class="strikethrough-if-disabled">{{user.email}}</td>
                        <td>@if (user.isEnabled) {
                          <i class="fa fa-check"></i>
                          }@if (!user.isEnabled) {
                          <i class="fa fa-times"></i>
                        }</td>
                        <td>
                          @for (role of user.roles; track role) {
                            <i class="fa fa-{{getRoleIcon(role)}} m-r-1 role-icon"></i>
                          }
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            }
          </div>
        </div>

        <h3>General Settings</h3>
        <p>
          <button class="btn btn-smart btn-tab" (click)="setDisplayMode(displayModeType.tenantSettingsSimVersion)"
            [ngClass]="{ 'btn-primary': displayMode === displayModeType.tenantSettingsSimVersion, 'btn-secondary': displayMode !== displayModeType.tenantSettingsSimVersion }">
            Sim Version
          </button>
          <button class="btn btn-smart btn-tab" (click)="setDisplayMode(displayModeType.defaultCustomPropertyNames)"
            [ngClass]="{ 'btn-primary': displayMode === displayModeType.defaultCustomPropertyNames, 'btn-secondary': displayMode !== displayModeType.defaultCustomPropertyNames }">
            Custom Properties
          </button>
          @if (isAdministrator) {
            <button class="btn btn-smart btn-tab" (click)="setDisplayMode(displayModeType.adminTenantSettings)"
              [ngClass]="{ 'btn-primary': displayMode === displayModeType.adminTenantSettings, 'btn-secondary': displayMode !== displayModeType.adminTenantSettings }">
              Administrator Settings
            </button>
          }
          <button class="btn btn-smart btn-tab" (click)="setDisplayMode(displayModeType.studyStatistics)"
            [ngClass]="{ 'btn-primary': displayMode === displayModeType.studyStatistics, 'btn-secondary': displayMode !== displayModeType.studyStatistics }">
            Study Statistics
          </button>

          <a class="btn btn-smart btn-secondary btn-tab" [routerLink]="['/downloads', tenantId]">
            Downloads
          </a>
        </p>

        <h3>Encryption Settings</h3>
        <cs-encryption-help-links></cs-encryption-help-links>
        <p>
          <a class="btn btn-smart btn-secondary btn-tab" [routerLink]="['/encryption-channel-whitelists', tenantId]">
            Channel Whitelists
          </a>

          <a class="btn btn-smart btn-secondary btn-tab" [routerLink]="['/config-permissions', tenantId]">
            Config Permissions
          </a>

          <a class="btn btn-smart btn-secondary btn-tab" [routerLink]="['/key-permissions', tenantId]">
            Key Permissions
          </a>
        </p>

      </div>

      @if (displayMode === displayModeType.defaultCustomPropertyNames) {
        <cs-tenant-settings-default-custom-property-names [tenantId]="tenantId"></cs-tenant-settings-default-custom-property-names>
      }

      @if (displayMode === displayModeType.tenantSettingsSimVersion) {
        <cs-tenant-settings-sim-version [tenantId]="tenantId"></cs-tenant-settings-sim-version>
      }

      @if (isAdministrator && displayMode === displayModeType.adminTenantSettings) {
        <div>
          <div class="container-fluid">
            <cs-admin-tenant-settings [tenantId]="tenantId"></cs-admin-tenant-settings>
            <cs-pool-status [tenantId]="tenantId"></cs-pool-status>
            <cs-pool-status [tenantId]="tenantId" [poolType]="'secondary'"></cs-pool-status>
            <cs-pool-status [tenantId]="tenantId" [poolType]="'heavy'"></cs-pool-status>
          </div>
        </div>
      }

      @if (displayMode === displayModeType.studyStatistics) {
        <cs-tenant-study-statistics [tenantId]="tenantId"></cs-tenant-study-statistics>
      }
    </div>

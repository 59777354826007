
import { SimType } from '../../sim-type';
import { LoadVectorMetadataMap } from './load-vector-metadata-map';

/**
 * Gets the sim type metadata for the specified sim types in the requested study job.
 */
export class GetJobSimTypeMetadata {

  /**
   * Returns the sim type metadata for the specified sim types in the requested study job.
   * @param studyId The study id.
   * @param jobIndex The job index.
   * @param simTypes The sim types.
   * @param loadVectorMetadataMap The load vector metadata map service.
   * @returns The sim type metadata.
   */
  public async execute(
    studyId: string,
    jobIndex: number,
    simTypes: ReadonlyArray<SimType>,
    loadVectorMetadataMap: LoadVectorMetadataMap): Promise<SimTypeInformation[]> {
    let result: SimTypeInformation[] = [];

    // Load all the vector metadata files with some parallelism.
    let vectorMetadataTasks = simTypes.map(
      v => ({
        simType: v,
        task: loadVectorMetadataMap.execute(studyId, jobIndex, v)
      }));

    for (let item of vectorMetadataTasks) {
      let vectorMetadata = await item.task;
      if (vectorMetadata && vectorMetadata.list.length) {
        let xDomainNames = [];
        let channels: SimTypeChannel[] = [];

        // For each line in the vector metadata file...
        for (let csvLine of vectorMetadata.list) {
          // Extract the channel name and the X domain name.
          channels.push(new SimTypeChannel(csvLine.name, csvLine.xDomainName));
          let xDomainName = csvLine.xDomainName;
          if (xDomainName && xDomainNames.indexOf(xDomainName) === -1) {
            xDomainNames.push(xDomainName);
          }
        }

        // Add the data for this sim type to the results.
        result.push({
          name: item.simType,
          xDomainNames,
          channels
        });
      }
    }

    return result;
  }
}

/**
 * The sim type information.
 */
export interface SimTypeInformation {

  /**
   * The sim type name.
   */
  name: string;

  /**
   * The list of distinct X domain names.
   */
  xDomainNames: string[];

  /**
   * The list of channels for the sim type.
   */
  channels: SimTypeChannel[];
}

/**
 * A sim type channel.
 */
export class SimTypeChannel {

  /**
   * Creates an instance of a sim type channel.
   * @param name The channel name.
   * @param xDomainName The X domain name.
   */
  constructor(public readonly name: string, public readonly xDomainName: string) {
  }
}

import {DomainNewsEvent, SharedState} from '../shared-state';
import {Subscription} from 'rxjs';
import {
  CursorDataPoint, DomainPosition, DomainSnapBehaviour,
  PlotCursorDataPoint
} from './multi-plot-data-renderer-base';
import {INDEX_DOMAIN_NAME, SLAPCENTRELINE_DOMAIN_NAME} from '../../constants';
import {Units} from '../../units';
import {SearchDirection, Utilities} from '../../utilities';
import { SourceData } from '../data-pipeline/types/source-data';
import { IPopulatedMultiPlotLayout } from '../data-pipeline/types/i-populated-multi-plot-layout';
import {ColumnLegendList} from './multi-plot-viewer-base';
import * as d3 from '../../d3-bundle';

export const LEGEND_CHANGED_EVENT = 'legendChanged';
export const DOMAIN_EVENT_PROCESSED_EVENT = 'domainEventProcessed';

/**
 * The handler for domain events in the multi plot viewer.
 */
export class DomainEventHandler{

  /**
   * The subscriptions we need to dispose of.
   */
  private readonly subscriptions: Subscription = new Subscription();

  /**
   * The last domain events for each domain.
   */
  private lastDomainEvents: { [domainName: string]: DomainNewsEvent } = {};

  /**
   * The layout.
   */
  private layout?: IPopulatedMultiPlotLayout;

  /**
   * The list of data sources.
   */
  private sourceData?: ReadonlyArray<SourceData>;

  /**
   * The x legend list.
   */
  private xLegendList?: ColumnLegendList;

  /**
   * The domain snap behaviour.
   */
  private domainSnapBehaviour?: DomainSnapBehaviour;

  /**
   * The events raised by this instance when a domain event occurs.
   */
  public readonly listeners = d3.dispatch(LEGEND_CHANGED_EVENT, DOMAIN_EVENT_PROCESSED_EVENT);

  /**
   * Creates a new instance of DomainEventHandler.
   * @param sharedState The shared state.
   * @param primaryDomainName The primary domain name.
   */
  constructor(
    private readonly sharedState: SharedState,
    private readonly primaryDomainName?: string){
  }

  /**
   * Disposes of the subscriptions.
   */
  public dispose(){
    this.subscriptions.unsubscribe();
  }

  /**
   * Adds a subscription to a domain event for the specified domain name.
   * @param domainName The domain name.
   */
  private addSubscription(domainName: string) {
    this.subscriptions.add(this.sharedState.getDomainNews(domainName)
      .observable.subscribe(event => this.handleDomainEvent(event, domainName)));
  }

  /**
   * Builds the domain event handler.
   */
  public build(){
    if(this.sharedState){
      if(this.primaryDomainName){
        this.addSubscription(this.primaryDomainName);
      }
    }
  }

  /**
   * Sets the layout.
   * @param layout The layout.
   */
  public setLayout(layout: IPopulatedMultiPlotLayout): this {
    this.layout = layout;
    return this;
  }

  /**
   * Sets the source data.
   * @param sourceData The source data.
   */
  public setSourceData(sourceData: ReadonlyArray<SourceData>): this {
    this.sourceData = sourceData;
    return this;
  }

  /**
   * Sets the x legend list.
   * @param xLegendList The x legend list.
   */
  public setXLegendList(xLegendList: ColumnLegendList): this {
    this.xLegendList = xLegendList;
    return this;
  }

  /**
   * Sets the domain snap behaviour.
   * @param domainSnapBehaviour The domain snap behaviour.
   */
  public setDomainSnapBehaviour(domainSnapBehaviour: DomainSnapBehaviour): this{
    this.domainSnapBehaviour = domainSnapBehaviour;
    return this;
  }

  /**
   * Performs the actions to be taken on render.
   */
  public render(){
    if(this.primaryDomainName && this.lastDomainEvents[this.primaryDomainName]){
      this.handleDomainEvent(this.lastDomainEvents[this.primaryDomainName], this.primaryDomainName);
    }
  }

  /**
   * Adds a listener for the specified event.
   * @param typenames The event name.
   * @param callback The callback.
   */
  public on(typenames: string, callback: (this: object, ...args: any[]) => void): this {
    this.listeners.on(typenames, callback);
    return this;
  }

  /**
   * Called on a domain event.
   * @param event The domain event.
   * @param domainName The domain name.
   */
  protected handleDomainEvent(event: DomainNewsEvent, domainName: string){

    // Store the last event for each domain.
    this.lastDomainEvents[domainName] = event;

    // Ensure you have everything you need.
    if(!this.xLegendList || !this.sourceData) {
      return;
    }

    //for(let plot of this.cursorData.plots) {
    //  plot.resetLegendValues();
    //}

    // Reset the X legend list.
    this.xLegendList.clearChannels();

    let plotCursorDataPoints: PlotCursorDataPoint[] = [];

    // For each data source...
    for(let sourceIndex=0; sourceIndex < this.sourceData.length; ++sourceIndex){

      let domainPosition: DomainPosition | undefined;

      // Fetch the domain value for this source for this domain news event.
      let eventSourceValue = event.getSourceValue(sourceIndex);

      // If the primary domain has no value, switch to the secondary domain.
      // We do this below now (see comment below).
      // if(isNaN(eventSourceValue) && event.secondary){
      //   domainName = event.secondary.domainName;
      //   eventSourceValue = event.secondary.event.getSourceValue(sourceIndex);
      // }

      if(domainName === INDEX_DOMAIN_NAME){
        // If we're an index domain, find out our position between the two surrounding indexes.
        let nextIndex = Math.ceil(eventSourceValue);

        let nearestIndex = nextIndex;
        let previousIndex = nextIndex;
        let ratio = 1;

        if(nextIndex > 0){
          previousIndex = nextIndex - 1;

          let difference = nextIndex - eventSourceValue;
          let previousDifference = eventSourceValue - (nextIndex - 1);
          if(previousDifference < difference){
            nearestIndex = nextIndex - 1;
          }

          ratio = difference / (difference + previousDifference);
        }

        domainPosition = new DomainPosition(nearestIndex, previousIndex, ratio);
      } else {
        let domainValue = this.sourceData[sourceIndex].channels.get(domainName);

        // We do this slightly uglier solution rather than the commented out code above, because
        // we must always send sRun from the track to support QSL which doesn't have an sRunCentreLine
        // channel. Previously we would send a NaN sRun if the track had a centre line, but this broke QSL.
        if(event.secondary && event.secondary.domainName === SLAPCENTRELINE_DOMAIN_NAME){
          let secondaryDomainValue = this.sourceData[sourceIndex].channels.get(event.secondary.domainName);
          if(secondaryDomainValue.hasData) {
            domainValue = secondaryDomainValue;
            domainName = event.secondary.domainName;
            eventSourceValue = event.secondary.event.getSourceValue(sourceIndex);
          }
        }

        // Find our position between the two surrounding data points.
        if(domainValue && domainValue.data && domainValue.data.length) {
          eventSourceValue = Units.convertValueFromSi(eventSourceValue, domainValue.units);

          let nextIndex = Utilities.findIndexInMonotonicallyIncreasingData(domainValue.data, eventSourceValue, SearchDirection.Forwards);
          if(nextIndex === -1){
            nextIndex = domainValue.data[domainValue.data.length - 1];
          }

          let nearestIndex = nextIndex;
          let previousIndex = nextIndex;
          let ratio = 1;

          if(nextIndex > 0){
            previousIndex = nextIndex - 1;

            let difference = domainValue.data[nextIndex] - eventSourceValue;
            let previousDifference = eventSourceValue - domainValue.data[previousIndex];
            if(previousDifference < difference){
              nearestIndex = nextIndex - 1;
            }

            ratio = difference / (difference + previousDifference);
          }

          domainPosition = new DomainPosition(nearestIndex, previousIndex, ratio);
        }
      }

      if(!domainPosition) {
        continue;
      }

      // Now we have our domain position between the surrounding data points, we can calculate the cursor data points.
      this.handleDomainEventForSource(sourceIndex, domainPosition, plotCursorDataPoints);
    }

    // Raise an event to say the legend has changed.
    this.listeners.call(LEGEND_CHANGED_EVENT);

    // Raise an event with the plot cursor data points.
    this.listeners.call(DOMAIN_EVENT_PROCESSED_EVENT, undefined, new ProcessedDomainEvent(event, plotCursorDataPoints));
  }

  /**
   * Handles the domain event for the specified source.
   * @param sourceIndex The source index.
   * @param domainPosition The event position relative to the surrounding two data points.
   * @param plotCursorDataPoints The cursor data points in each plot, which will be populated by this method.
   */
  protected handleDomainEventForSource(sourceIndex: number, domainPosition: DomainPosition, plotCursorDataPoints: PlotCursorDataPoint[]) {
    // Make sure we have everything we need.
    if(!this.xLegendList || !this.layout || this.sharedState.domainOverridden){
      return;
    }

    let id = 0;

    // For each plot...
    for (let plot of this.layout.processed.plots) {

      // For each channel in the column...
      for (let columnChannel of plot.column.processed.channels) {

        // If the channel isn't already in the X legend list, add it.
        if (!this.xLegendList.channels.find(v => v.name === columnChannel.name)) {
          this.xLegendList.addChannel(columnChannel);
        }

        // Get the channel data source for this source index.
        let columnChannelSource = columnChannel.sources[sourceIndex];

        if (!columnChannelSource || !columnChannelSource.data) {
          // If there isn't any data, the skip it.
          continue;
        }

        // Create the delegate which will return a value at a given position.
        // This is where we can implement different snap behaviors.
        let getValueDelegate: (data: ReadonlyArray<number>, position: DomainPosition) => number;
        if(this.domainSnapBehaviour === DomainSnapBehaviour.linearInterpolation){
          getValueDelegate = (data: ReadonlyArray<number>, position: DomainPosition) => (data[position.previousIndex] * position.ratio) + (data[position.previousIndex + 1] * (1 - position.ratio));
        } else{
          getValueDelegate = (data: ReadonlyArray<number>, position: DomainPosition) => data[position.nearestIndex];
        }

        // Get the X value for the current column channel.
        let xValue = getValueDelegate(columnChannelSource.data, domainPosition);

        columnChannel.legendValues[sourceIndex] = xValue;

        // For each channel in the row...
        for (let rowChannel of plot.row.processed.channels) {

        // Get the channel data source for this source index.
        let rowChannelSource = rowChannel.sources[sourceIndex];

          if (!rowChannelSource || !rowChannelSource.data) {
            // If there isn't any data, the skip it.
            continue;
          }

          // Get the Y value for the current row channel.
          let yValue = getValueDelegate(rowChannelSource.data, domainPosition);

          rowChannel.legendValues[sourceIndex] = yValue;

          if(!plot.hasChannelPair(columnChannel.name, rowChannel.name)){
            // If the plot isn't plotting this column channel against this row channel, the continue.
            continue;
          }

          // Create the cursor data point.
          let cursorDataPoint = new CursorDataPoint(plot.column.processed.scale(xValue), plot.row.processed.scale(yValue), rowChannel, sourceIndex);

          // If the cursor data point is valid and both channels are visible, add it to the plot cursor data points list.
          if (!isNaN(cursorDataPoint.x) && !isNaN(cursorDataPoint.y)) {
            if(columnChannel.isVisible && rowChannel.isVisible){
              plotCursorDataPoints.push(new PlotCursorDataPoint('point-' + sourceIndex + '-' + (id++), plot, cursorDataPoint));
            }
          }
        }
      }
    }
  }
}

/**
 * The processed domain event.
 */
export class ProcessedDomainEvent{

  /**
   * Creates a new instance of ProcessedDomainEvent.
   * @param event The domain event.
   * @param plotCursorDataPoints The cursor data points in each plot.
   */
  constructor(
    public readonly event: DomainNewsEvent,
    public readonly plotCursorDataPoints: ReadonlyArray<PlotCursorDataPoint>){
  }
}

import { IPopulatedMultiPlotLayout } from './types/i-populated-multi-plot-layout';
import { Rectangle } from '../rectangle';
import { Position } from '../position';
import { UpdateScalesChartSettings } from './update-scales-chart-settings';


/**
 * Iterates through each plot updating the plot's render area.
 */
export class UpdateProcessedPlotSizes {

  /**
   * Iterates through each plot updating the plot's render area.
   *
   * @param layout The layout.
   * @param settings The chart settings.
   */
  public execute(layout: IPopulatedMultiPlotLayout, settings: UpdateScalesChartSettings) {
    let svgPadding = settings.svgPadding;
    let chartMargin = settings.chartMargin;

    let initialPosition = new Position(
      svgPadding.left + chartMargin.left,
      svgPadding.top + chartMargin.top);
    for (let plot of layout.processed.plots) {
      let plotX = plot.column.processed.offset + settings.spaceBetweenPlots;
      let plotY = plot.row.processed.offset;

      let plotWidth = plot.column.processed.plotSize;
      let plotHeight = plot.row.processed.plotSize;

      let absoluteRenderArea = new Rectangle(initialPosition.x + plotX, initialPosition.y + plotY, plotWidth, plotHeight);
      plot.updateRenderArea(absoluteRenderArea);
    }
  }
}

import { ISourceLoader } from '../channel-data-loaders/source-loader';
import { SubsetSourceLoader } from '../channel-data-loaders/subset-source-loader';
import { IViewerChannelData } from '../channel-data-loaders/viewer-channel-data';
import { SourceLoaderViewModel } from '../channel-data-loaders/source-loader-set';

/**
 * A class containing helper methods for dealing with sources that can or have been split.
 */
export class SourceSplitInspector {
  /**
   * Returns true if the split channel has split indices.
   * For example, if NLap was the split channel, and the simulation only had one lap,
   * then the split channel would not have split indices. If there were multiple laps,
   * then the split channel would have split indices.
   * @param splitChannel The split channel to check. A split channel is a channel you're using to determine where to split the data.
   * @returns True if the split channel has split indices.
   */
  public hasSplitIndices(splitChannel: IViewerChannelData | undefined): splitChannel is IViewerChannelDataWithData {
    if (!splitChannel || !splitChannel.data || splitChannel.data.length < 2) {
      return false;
    }

    return !!splitChannel.dataChangeIndices.length;
  }

  /**
   * Returns the split indices for the split channel.
   * @param splitChannel The split channel to get the split indices for.
   * @returns The split indices for the split channel.
   */
  public getSplitIndices(splitChannel: IViewerChannelData | undefined): ReadonlyArray<number> {

    if (!this.hasSplitIndices(splitChannel)) {
      return [];
    }

    let splitIndices = [...splitChannel.dataChangeIndices];

    // If the final index is the final point then the split channel incremented on the final point.
    // In that case just keep the array as-is to effectively discard the final point.
    // Otherwise, add a final index of one beyond the final point to capture the final section of data.
    if (splitIndices.length && splitIndices[splitIndices.length - 1] !== splitChannel.data.length - 1) {
      splitIndices.push(splitChannel.data.length);
    }

    return splitIndices;
  }

  /**
   * Splits the source into multiple sources based on the split channel.
   * @param source The source to split.
   * @param splitChannel The split channel to use to split the source.
   * @returns The set of sources, one for each section of data.
   */
  public splitSource(source: ISourceLoader, splitChannel: IViewerChannelData | undefined): ReadonlyArray<ISourceLoader> {
    let splitIndices = this.getSplitIndices(splitChannel);

    // If there are not split indices then just return the source as-is.
    if (!splitIndices || !splitIndices.length) {
      return [source];
    }

    // Otherwise create a set of subset sources, which wrap the original source and provide a view
    // into a subset of the data.
    let sources: ISourceLoader[] = [];
    let startIndex = 0;
    let part = 1;
    for (let splitIndex of splitIndices) {
      sources.push(new SubsetSourceLoader(source, `${part}`, startIndex, splitIndex));
      startIndex = splitIndex;
      ++part;
    }

    return sources;
  }

  /**
   * Returns true if the source can be merged (un-split).
   * This is basically a test to see if the source is a SubsetSourceLoader.
   * @param source The source to check.
   * @returns True if the source can be merged.
   */
  public canMergeSource(source: SourceLoaderViewModel): boolean {
    let subsetSource = source.inner as SubsetSourceLoader;
    return !!subsetSource.subsetSourceOrigin;
  }

  /**
   * Returns the information necessary to replace all SubsetSourceLoader sources that have the same origin source
   * as the source passed in with the original source.
   * @param source The source the user has clicked to merge.
   * @param allSources All sources in the viewer. Not all of these will necessarily be sources split from the original source.
   * @returns The information necessary to replace all SubsetSourceLoader sources with the original source.
   */
  public getMergeSourceInformation(source: SourceLoaderViewModel, allSources: ReadonlyArray<SourceLoaderViewModel>): MergeSourceInformation | undefined {
    let subsetSource = source.inner as SubsetSourceLoader;

    if (!subsetSource.subsetSourceOrigin) {
      return undefined;
    }

    // Get the indices of all SubsetSourceLoader sources which have the same origin source as the source passed in.
    let indices: number[] = [];
    for (let i = 0; i < allSources.length; ++i) {
      let source = allSources[i].inner as SubsetSourceLoader;
      if (source.subsetSourceOrigin === subsetSource.subsetSourceOrigin) {
        indices.push(i);
      }
    }

    return new MergeSourceInformation(subsetSource.subsetSourceOrigin, indices);
  }
}

/**
 * The information necessary to replace all SubsetSourceLoader sources that have the same origin source
 * as the source passed in with the original source.
 */
export class MergeSourceInformation {
  constructor(
    public readonly originSource: ISourceLoader,
    public readonly sourceIndices: ReadonlyArray<number>) {
  }
}

/**
 * An IViewerChannelData with a data array.
 */
interface IViewerChannelDataWithData extends IViewerChannelData {
  readonly data: ReadonlyArray<number>;
}

import * as d3 from '../../../d3-bundle';
import { Utilities } from '../../../utilities';
import { ChannelDataList } from './channel-data-list';
import { emptyValueInterpolatorResults, ValueInterpolatorResults } from './value-interpolator-results';
import { NamedChannelData } from './named-channel-data';
import { modulo } from '../../../modulo';
import { ProcessedExplorationMap } from '../explorations/processed-exploration-map';
import { ArrayMappings } from '../explorations/exploration-sorting/array-mappings';

/**
 * Return a linearly interpolated value from the given factorial set of points in n-dimensional space.
 * @param explorationMap The exploration map to use for interpolation.
 * @param channelList The channel data to interpolate.
 * @param rPoint A set of relative coordinates for a point in the hypercube, with each dimension scaled between 0 and 1.
 * @returns The interpolated values for each channel at the given point.
 */
export function interpolateFactorialPoint(
  explorationMap: ProcessedExplorationMap,
  channelList: ChannelDataList,
  rPoint: ReadonlyArray<number>): ValueInterpolatorResults {
  return interpolateFactorialPointInner(
    explorationMap.normalizedSweepIndices,
    explorationMap.jobOrderMapping,
    channelList,
    rPoint);
}

/**
 * Return a linearly interpolated value from the given factorial set of points in n-dimensional space.
 * This algorithm interpolates in the first dimension, then calls itself recursively to interpolate in the remaining dimensions.
 * @param rCoordinates The normalized sweep indices from an exploration map. The outer array is the job, the inner array is the sweep.
 * @param rCoordinatesMap The mapping of sorted job indices to original job indices for the exploration.
 * This is important because this algorithm assumes that the data is in factorial order, where as we change the order
 * when constructing the ProcessedExplorationMap to make rendering easier.
 * @param channelList The channel data to interpolate.
 * @param rPoint A set of relative coordinates for a point in the hypercube, with each dimension scaled between 0 and 1.
 * @returns The interpolated values for each channel at the given point.
 */
function interpolateFactorialPointInner(
  rCoordinates: ReadonlyArray<ReadonlyArray<number>>,
  rCoordinatesMap: ArrayMappings,
  channelList: ChannelDataList,
  rPoint: ReadonlyArray<number>): ValueInterpolatorResults {

  if (!channelList.dataLength) {
    return emptyValueInterpolatorResults(channelList.channels.map(v => v.name));
  }

  let dimensionCount = rPoint.length;

  // Get the number of unique values in the first sweep of each job.
  let pointCountInFirstDimension = Utilities.unique(rCoordinates.map(v => v[0])).length;
  let interpolationPointIndex = rPoint[0] * (pointCountInFirstDimension - 1);
  let ceilingInterpolationPointIndex = Math.ceil(interpolationPointIndex);
  let interpolationRatio = interpolationPointIndex === ceilingInterpolationPointIndex
    ? 1
    : modulo(interpolationPointIndex, 1);

  /**
   * Get the channel indices to interpolate between for the given index.
   * We have to map back to factorial order if the data is not in that order.
   * @param index The index to get the channel indices for.
   * @returns The channel indices to interpolate between.
   */
  const getChannelDataIndices = (index: number) => {
    const secondChannelDataIndex = index;
    const firstChannelDataIndex = index === 0 ? 0 : index - 1;

    return [
      rCoordinatesMap.newIndexToOldIndex[firstChannelDataIndex],
      rCoordinatesMap.newIndexToOldIndex[secondChannelDataIndex],
    ];
  };

  if (dimensionCount === 1) {
    // Perform linear interpolation between these (equally spaced) values.
    const [firstChannelDataIndex, secondChannelDataIndex] = getChannelDataIndices(ceilingInterpolationPointIndex);
    return interpolateLinear(channelList, firstChannelDataIndex, secondChannelDataIndex, interpolationRatio);
  }

  // Linearly interpolate the values in one dimension, construct the new arguments, and recurse.
  let newrCoordinates: number[][] = [];
  let newChannelData: NamedChannelData[] = channelList.channels.map(v => new NamedChannelData(v.name, []));
  let newChannelDataCount = channelList.dataLength / pointCountInFirstDimension;
  for (let i = 0; i < newChannelDataCount; i++) {
    newrCoordinates.push(
      rCoordinates[rCoordinatesMap.newIndexToOldIndex[i * pointCountInFirstDimension]]
        .slice(1, dimensionCount));

    let newCeilingInterpolationPointIndex = ceilingInterpolationPointIndex + i * pointCountInFirstDimension;

    const [firstChannelDataIndex, secondChannelDataIndex] = getChannelDataIndices(newCeilingInterpolationPointIndex);
    let newValues = interpolateLinear(channelList, firstChannelDataIndex, secondChannelDataIndex, interpolationRatio);

    for (let channel of newChannelData) {
      channel.values.push(newValues[channel.name]);
    }
  }

  return interpolateFactorialPointInner(
    newrCoordinates,
    new ArrayMappings(d3.range(newrCoordinates.length)), // Everything is now in order for the new rCoordinates.
    new ChannelDataList(newChannelData),
    rPoint.slice(1, dimensionCount));
}

/**
 * Linearly interpolate between two data indices for a set of channels.
 * @param channelList The list of channels to interpolate.
 * @param firstChannelDataIndex The first channel data index to interpolate.
 * @param secondChannelDataIndex The second channel data index to interpolate.
 * @param interpolationRatio The ratio to interpolate between the two channels.
 * @returns The interpolated values for each channel at the given point.
 */
function interpolateLinear(
  channelList: ChannelDataList,
  firstChannelDataIndex: number,
  secondChannelDataIndex: number,
  interpolationRatio: number): ValueInterpolatorResults {

  let result = emptyValueInterpolatorResults(channelList.channels.map(v => v.name));
  for (let channel of channelList.channels) {
    result[channel.name] = interpolateChannelLinear(
      channel.values,
      firstChannelDataIndex,
      secondChannelDataIndex,
      interpolationRatio);
  }
  return result;
}

/**
 * Linearly interpolate between two data indices for a single channel.
 * @param channelData The channel data to interpolate.
 * @param firstChannelDataIndex The first channel data index to interpolate.
 * @param secondChannelDataIndex The second channel data index to interpolate.
 * @param interpolationRatio The ratio to interpolate between the two channels.
 * @returns The interpolated value for the given channel.
 */
function interpolateChannelLinear(
  channelData: ReadonlyArray<number> | undefined,
  firstChannelDataIndex: number,
  secondChannelDataIndex: number,
  interpolationRatio: number): number {

  if (!channelData) {
    return NaN;
  }

  return channelData[firstChannelDataIndex]
    + interpolationRatio * (channelData[secondChannelDataIndex] - channelData[firstChannelDataIndex]);
}

import {Component} from '@angular/core';
import {FetchUserState} from '../user-state/fetch-user-state.service';
import {OnInit} from '@angular/core';
import {GetFriendlyErrorAndLog} from '../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {
  TenancyStub, StudyTypeDefinition, StudyTypeState, ConfigTypeState
} from '../../generated/api-stubs';
import {InferableRouteParams} from '../common/inferable-route-params.service';
import {sortBy} from '../common/sort-by';
import {StudyTypeLookupFactory} from '../simulations/studies/study-type-lookup.service';
import {ConfigType} from '../simulations/configs/config-types';
import {ActivatedRoute} from '@angular/router';
import {getRoleIcon} from '../identity/get-role-icon';
import { AuthenticationService } from '../identity/state/authentication.service';

@Component({
  templateUrl: './tenant-information.page.html',
  styleUrls: ['./tenant-information.page.scss']
})
export class TenantInformationPage implements OnInit {

  public errorMessage: string;
  public tenant: Tenant;
  public tenantUsers: TenantUser[];
  public studyTypes: StudyTypeDefinition[];
  public configTypes: ConfigType[];

  public tenantId: string;
  public isAdministrator: boolean;
  public isTenantAdministrator: boolean;

  public displayModeType = DisplayMode;
  public displayMode: DisplayMode = DisplayMode.none;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly fetchUserState: FetchUserState,
    private readonly route: ActivatedRoute,
    private readonly inferableRouteParams: InferableRouteParams,
    private readonly tenancyStub: TenancyStub,
    private readonly studyTypeLookupFactory: StudyTypeLookupFactory,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog
  ) {
  }

  public ngOnInit() {
    this.load();
  }

  public async load() {
    try {
      this.isAdministrator = this.authenticationService.isAdministrator;
      this.isTenantAdministrator = this.authenticationService.isTenantAdministrator;
      this.tenantId = this.inferableRouteParams.getTenantId(this.route);
      let userState = await this.fetchUserState.get();

      if(userState.tenant.tenantId === this.tenantId){
        this.tenant = userState.tenant;
      } else {
        this.tenant = await this.tenancyStub.getTenant(this.tenantId);
      }

      let studyTypeLookup = this.studyTypeLookupFactory.create(this.tenantId);
      this.studyTypes = await studyTypeLookup.getStudyTypeList();
      this.configTypes = await studyTypeLookup.getConfigTypeList();

      let usersResult = await this.tenancyStub.getTenantUsers(this.tenantId);

      this.tenantUsers = usersResult.users;
      this.tenantUsers.sort(sortBy('username'));
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public isStudyTypeEnabled(studyType: StudyTypeDefinition): boolean {
    return studyType.state === StudyTypeState.enabled;
  }

  public isConfigTypeEnabled(configType: ConfigType): boolean {
    return configType.state === ConfigTypeState.enabled;
  }

  public getRoleIcon(role: string): string {
    return getRoleIcon(role);
  }

  public setDisplayMode(displayMode: DisplayMode){
    this.displayMode = displayMode;
  }
}
class Tenant {
  constructor(
    public name: string,
    public shortName: string,
    public creationDate: string,
    public isEnabled: boolean){
  }
}
class TenantUser {
  constructor(
    public userId: string,
    public username: string,
    public email: string,
    public roles: string[]) {
  }
}

enum DisplayMode{
  none,
  defaultCustomPropertyNames,
  adminTenantSettings,
  studyStatistics,
  tenantSettingsSimVersion,
  tenantDownloads
}

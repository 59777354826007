<div id="edit-custom-properties-component">
  <cs-error-message [message]="errorMessage"></cs-error-message>
  @for (item of customProperties; track item; let i = $index) {
    <div class="d-flex gap-2 mb-2">
      <input id="{{item.nameId}}-input" placeholder="Name" type="text" class="form-control"
        (focus)="onNameFocus(item)"
        [formControl]="item.name">
      <input id="{{item.valueId}}-input" placeholder="Value" type="text" class="form-control"
        (focus)="onValueFocus(item)"
        [formControl]="item.value">
      <button type="button" class="btn btn-smart btn-secondary remove-custom-property-button" id="remove-custom-property-button-{{i}}" (click)="remove(i)">Remove</button>
      @if (item.name.hasError('customPropertyName')) {
        <p class="text-danger">
          Only letters, numbers, hyphens and periods are allowed.
        </p>
      }
      @if (item.name.hasError('minlength')) {
        <p class="text-danger">
          Name is too short.
        </p>
      }
      @if (item.name.hasError('maxlength')) {
        <p class="text-danger">
          Name is too long.
        </p>
      }
      @if (item.value.hasError('maxlength')) {
        <p class="text-danger">
          Value is too long.
        </p>
      }
    </div>
  }
  <p>
    @if (!customProperties) {
      <span class="btn btn-smart btn-secondary btn-hidden">
        <cs-loader></cs-loader>
      </span>
    }

    @if (customProperties) {
      <button type="button" class="btn btn-smart btn-secondary add-button" id="add-custom-property-button" (click)="add()">Add</button>
    }
  </p>
</div>

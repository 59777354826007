
import {CanopyError} from '../../common/errors/errors';
import {
  ConditionOperator, ConditionSource, getConditionOperatorValues, GroupOperator, ListFilterCondition,
  ListFilterData,
  ListFilterGroup
} from '../../../generated/api-stubs';
import {EventEmitter} from '@angular/core';
import {ValidatorFn, Validators} from '@angular/forms';

export const DefaultConditionNameValidators = [Validators.required];

export enum ConditionSourceItemType {
  string,
  number,
  dateTime
}

export class ConditionOperatorViewModel {
  constructor(
    public readonly id: ConditionOperator,
    public readonly name: string,
    public readonly requiresValue: boolean
  ){
  }
}

export const ConditionOperators: ReadonlyArray<ConditionOperatorViewModel> = [
  new ConditionOperatorViewModel(ConditionOperator.equals, '=', true),
  new ConditionOperatorViewModel(ConditionOperator.notEquals, '!=', true),
  new ConditionOperatorViewModel(ConditionOperator.lessThan, '<', true),
  new ConditionOperatorViewModel(ConditionOperator.greaterThan, '>', true),
  new ConditionOperatorViewModel(ConditionOperator.lessThanOrEquals, '<=', true),
  new ConditionOperatorViewModel(ConditionOperator.greaterThanOrEquals, '>=', true),
  new ConditionOperatorViewModel(ConditionOperator.exists, 'exists', false),
  new ConditionOperatorViewModel(ConditionOperator.notExists, '!exists', false),
];

export interface ConditionOperatorToViewModelMap {
  [id: string]: ConditionOperatorViewModel;
}

export const ConditionOperatorsMap = ConditionOperators.reduce<ConditionOperatorToViewModelMap>((p, c) => {
  p[c.id] = c;
  return p;
}, {});

export interface IFilterQueryBuilderDataSource {
  getConditionSources(): ReadonlyArray<ConditionSourceViewModel>;
  getConditionSourceItems(conditionSourceId: string): ConditionSourceItemSetViewModel;
  getConditionSourceViewModel(source: ConditionSource): ConditionSourceViewModel;
  getConditionSourceItemViewModel(source: string, name: string): ConditionSourceItemViewModel;
  readonly hasConditionSourceItemsChanged: boolean;
}

export interface IFilterQueryBuilderDataAndFilterSource extends IFilterQueryBuilderDataSource {
  readonly filterChanged: EventEmitter<ListFilterData>;
  readonly currentQuery: ListFilterGroup;
}

export interface IFilterQueryBuilderManager extends IFilterQueryBuilderDataSource {
  notifyChanged(): void;
}

export class ConditionSourceViewModel {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly conditionNameValidators?: ValidatorFn[]
  ){
    if(!this.conditionNameValidators){
      this.conditionNameValidators = DefaultConditionNameValidators;
    }
  }
}

export class ConditionSourceItemSetViewModel {
  constructor(
    public readonly isFiniteSet: boolean,
    public readonly items: ReadonlyArray<ConditionSourceItemViewModel>,
    public readonly autocompleteOptions?: any
  ){
  }

  public get(name: string){
    return this.items.find(v => v.name === name);
  }
}

export class ConditionSourceItemViewModel {
  constructor(
    public readonly name: string,
    public readonly displayName: string,
    public readonly units: string | null,
    public readonly type: ConditionSourceItemType,
    public readonly operators?: ReadonlyArray<ConditionOperator>,
    public readonly values: ReadonlyArray<ConditionValueViewModel> = [],
    public readonly isFiniteValues: boolean = false){

    if(!operators || operators.length === 0){
      this.operators = getConditionOperatorValues();
    }

    if(!values){
      this.values = [];
    }
  }
}

export class ConditionValueViewModel {
  constructor(
    public readonly value: string,
    public readonly displayValue?: string){
    if(!this.displayValue){
      this.displayValue = value;
    }
  }
}

export class GroupViewModel {
  constructor(
    public operator: GroupOperator = GroupOperator.and,
    public readonly conditions: FilterConditionViewModel[] = [],
    public readonly groups: GroupViewModel[] = []){

    if(!this.conditions){
      this.conditions = [];
    }

    if(!this.groups){
      this.groups = [];
    }
  }

  public populate(): boolean {
    let result = true;
    for(let condition of this.conditions){
      result = result && condition.populate();
    }
    for(let group of this.groups){
      result = result && group.populate();
    }
    return result;
  }
}

export class FilterConditionViewModel {
  private _dataSource: IFilterConditionDataSource;

  constructor(
    public source: ConditionSourceViewModel,
    public name?: ConditionSourceItemViewModel,
    public operator?: ConditionOperatorViewModel,
    public value: string = ''){
  }

  public set dataSource(value: IFilterConditionDataSource){
    this._dataSource = value;
  }

  public populate(): boolean {
    if(!this._dataSource) {
      throw new CanopyError('Filter condition data source not found.');
    }

    return this._dataSource.populate();
  }
}

export class ResolvedGroup implements ListFilterGroup {
  constructor(
    public readonly operator: GroupOperator,
    public readonly conditions?: ResolvedFilterCondition[],
    public readonly groups?: ResolvedGroup[]){

    if(!this.conditions){
      this.conditions = [];
    }

    if(!this.groups){
      this.groups = [];
    }
  }
}

export class ResolvedFilterCondition implements ListFilterCondition {
  constructor(
    public readonly source: string,
    public readonly name: string,
    public readonly operator: ConditionOperator,
    public readonly value?: string){
  }
}

export interface IFilterConditionDataSource {
  populate(): boolean;
}

@if (group) {
  <span>
    @for (condition of group.conditions; track condition; let i = $index) {
      <span>
        @if (i > 0) {
          <span class="query-operator">
            {{operator}}
          </span>
        }
        <cs-filter-query-renderer-condition
          [condition]="condition"
          [tenantUsers]="tenantUsers">
        </cs-filter-query-renderer-condition>
      </span>
    }
    @for (child of group.groups; track child; let i = $index) {
      <span>
        @if (i > 0 || group.conditions.length) {
          <span class="query-operator">
            {{operator}}
          </span>
        }
        (
        <cs-filter-query-renderer-group
          [group]="child"
          [tenantUsers]="tenantUsers">
        </cs-filter-query-renderer-group>
        )
      </span>
    }
  </span>
}

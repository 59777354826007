import {RuleError} from './rule-error';
import {RuleInstanceResult} from './rule-instance-result';

export class ExecutionResult {
  constructor(
    public readonly completedRules: ReadonlyArray<string>,
    public readonly ruleResults: ReadonlyArray<RuleInstanceResult>,
    public readonly ruleErrors: ReadonlyArray<RuleError>,
    public readonly skippedRules: ReadonlyArray<string>) {
  }

  public getOrderedRuleResults(): ReadonlyArray<RuleInstanceResult> {
    return [...this.ruleResults].sort(ExecutionResult.sort);
  }

  private static sort(a: RuleInstanceResult, b: RuleInstanceResult) {
    if (a.level > b.level) {
      return 1;
    }

    if (a.level < b.level) {
      return -1;
    }

    if (a.priority > b.priority) {
      return 1;
    }

    if (a.priority < b.priority) {
      return -1;
    }

    return 0;
  }
}

import {EventEmitter, Injectable, NgZone} from '@angular/core';
import {removeJsonComments} from '../../../common/remove-json-comments';
import {DocumentSubType,} from '../../../../generated/api-stubs';
import {CanopyJson} from '../../../common/canopy-json.service';
import {setFormPristine} from '../../../common/forms/set-form-pristine-hack';
import {SimVersionDocumentCache} from '../../sim-version-document-cache.service';
import {ConfigSubTreeRepository, ConfigSubTreeRepositoryFactory} from '../config-sub-tree-repository.service';
import {ServiceOnInit} from '../../../common/service-on-init';
import {
  AutoCompleteMap,
  GetAutocompleteMaps,
} from './get-autocomplete-maps.service';
import {UntypedFormGroup} from '@angular/forms';
import {UnitsManager} from '../../../units/units-manager.service';
import {SavedConfigNamesCache, SavedConfigNamesCacheFactory} from './saved-config-names-cache';
import {convertLocalSchemaReferencesToExternal} from './convert-local-schema-references-to-external';
import { ApplyCustomizations } from './json-editor-customizations/apply-customizations';
import { JsonEditorInstance } from './json-editor-instance';
import { UnvalidatedJsonEditorInstance } from './unvalidated-json-editor-instance';
import { ValidatedJsonEditorInstance } from './validated-json-editor-instance';
import { backwardsCompatibilityAndBugFixes } from './backwards-compatibility';
import{ JSONEditor } from'@json-editor/json-editor'

@Injectable()
export class JsonEditor implements ServiceOnInit {

  constructor(
    private readonly zone: NgZone,
    private readonly simVersionDocumentCache: SimVersionDocumentCache,
    private readonly getAutocompleteMaps: GetAutocompleteMaps,
    private readonly configSubTreeRepositoryFactory: ConfigSubTreeRepositoryFactory,
    private readonly unitsManager: UnitsManager,
    private readonly json: CanopyJson,
    private readonly savedConfigNamesCacheFactory: SavedConfigNamesCacheFactory,
    private readonly applyCustomizations: ApplyCustomizations){
  }

  public async create(
    form: UntypedFormGroup,
    containerElementId: string,
    schemaPrefix: string,
    initialContent: any,
    simVersion: string,
    configType: DocumentSubType,
    waitReady: boolean = true): Promise<JsonEditorInstance>{

    const internalUnitsChanged = new EventEmitter<any>();
    await this.unitsManager.refresh();

    const autoCompleteMaps = await this.getAutocompleteMaps.execute(simVersion, configType, internalUnitsChanged);
    const savedConfigNamesCache = this.savedConfigNamesCacheFactory.create(simVersion);

    let documentsResult = await this.simVersionDocumentCache.get(simVersion);
    const configSubTreeRepository = this.configSubTreeRepositoryFactory.create(configType, simVersion);

    // A set of defaults to support sim versions before we added the metadata JSON file.
    let documentationPageNameToId = {
      Aerodynamics: '5662764605597',
      Powertrain: '5650947600797',
      Brakes: '5650968263965',
      Chassis: '5650904438173',
      Overview: '5650865989789',
      Constraints: '5650526936605',
      Control: '5650886956573',
      Encryption: '5650629140637',
      Suspension: '5660662691613',
      Tyres: '5658017584413',
      TyreThermal: '5650991556253'
    };

    let schema: any = {};
    let refs: any = {};

    for (let document of documentsResult.documents) {
      if (document.name === 'documentation-metadata.json') {
        documentationPageNameToId = this.json.parse(document.content).pageNameToId;
      } else if (document.name.endsWith('.schema.json')) {
        let content = this.json.parse(
          removeJsonComments(
            convertLocalSchemaReferencesToExternal(document.content, document.name)));
        // Draft-06 compatibility
        backwardsCompatibilityAndBugFixes(content);

        refs[document.name] = JSON.stringify(content);

        if (document.name === `${schemaPrefix}.schema.json`) {
          schema = content;
        }
      }
    }

    let element = document.getElementById(containerElementId);

    if (!element){
      // Page has unloaded.
      return undefined;
    }

    let hasSchema = true;
    if (!schema){
      hasSchema = false;
    } else if (schema.properties){
      hasSchema = Object.keys(schema.properties).length > 0;
    } else if (schema.items || schema.oneOf || schema.anyOf){
      hasSchema = true;
    } else {
      hasSchema = false;
    }

    if (!hasSchema){
      // The target object has no schema.
      return new UnvalidatedJsonEditorInstance(initialContent, documentsResult.simVersion, documentsResult.units);
    }

    const containerButtonMap = new Map<HTMLElement, HTMLElement[]>();

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if(entry.isIntersecting){
          observer.unobserve(entry.target);
          let buttons = containerButtonMap.get(entry.target as HTMLElement);
          for(let button of buttons){
            if(!entry.target.contains(button)){
              entry.target.appendChild(button);
            }
          }
        }
      })
    });

    const onDestroy = () => {
      observer.disconnect();
      containerButtonMap.clear();
    };

    let canopyJsonEditorOptions = new CanopyJsonEditorOptions(
      simVersion,
      configType,
      configSubTreeRepository,
      autoCompleteMaps,
      internalUnitsChanged,
      savedConfigNamesCache,
      documentationPageNameToId,
      observer,
      containerButtonMap,
      onDestroy
    );

    JSONEditor.defaults.options.object_background = "";
    let editor = this.zone.runOutsideAngular(() => new JSONEditor(element, {
        theme: 'canopy',
        iconlib: 'fontawesome4',
        schema,
        collapsed: true,
        disable_edit_json: true,
        disable_properties: false,
        disable_array_reorder: false,
        display_required_only: true,
        keep_oneof_values: true, // With this turned on, hidden enum fields in oneOf branches are overwritten. Workaround in JSONEditor.defaults.editors.hiddenEnum.
        startval: initialContent,
        canopy: canopyJsonEditorOptions,
        urn_resolver: (urn: string) => refs[urn.substring(4)],
        prompt_before_delete: false
      }));


    let result = new ValidatedJsonEditorInstance(editor, documentsResult.simVersion, documentsResult.units);

    let isFirstChangedEvent = true;
    editor.on('ready', function() {
      editor.on('change', function() {
        if (isFirstChangedEvent) {
          isFirstChangedEvent = false;
          return;
        }
        setFormPristine(form, false);
        result.changed.emit(undefined);
      });
    });

    if(!editor.ready && waitReady){
      await new Promise(resolve => editor.on('ready', () => resolve(undefined)));
    }


    return result;
  }

  public serviceOnInit() {
    this.applyCustomizations.execute();
  }
}

export class CanopyJsonEditorOptions {
  constructor(
    public readonly simVersion: string,
    public readonly configType: DocumentSubType,
    public readonly configSubTreeRepository: ConfigSubTreeRepository,
    public readonly autoCompleteMaps: ReadonlyArray<AutoCompleteMap>,
    public readonly internalUnitsChanged: EventEmitter<any>,
    public readonly savedConfigNamesCache: SavedConfigNamesCache,
    public readonly documentationPageNameToId: { [name: string]: string },
    public observer: IntersectionObserver,
    public containerButtonMap: Map<HTMLElement, HTMLElement[]>,
    public readonly onDestroy: () => void = () => {}) {}
}

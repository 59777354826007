import {Component, Input} from '@angular/core';
import {JobViewModel} from '../job-view-model';
import {GetFriendlyErrorAndLog} from '../../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {ViewJobResultsSectionBase} from '../view-job-results-section-base';
import {JobSelectorState} from '../job-selector-state';
import {LazyLoaded} from '../lazy-loaded';

@Component({
  selector: 'cs-view-job-log-file',
  templateUrl: './view-job-log-file.component.html',
  styleUrls: ['./view-job-log-file.component.scss']
})
export class ViewJobLogFileComponent extends ViewJobResultsSectionBase {
  @Input() public jobSelectorState: JobSelectorState;
  @Input() public logFileType: JobLogFileType = JobLogFileType.warnings;
  @Input() public collapsible: boolean = false;

  public job: JobViewModel = null;

  constructor(
    getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
    super(getFriendlyErrorAndLog);
  }

  public get alertType(): string {
    switch(this.logFileType){
      case JobLogFileType.info:
        return 'info';

      default:
        return 'warning';
    }
  }

  public get lazy(): LazyLoaded<string[]> {
    switch(this.logFileType){
      case JobLogFileType.info:
        return this.job.info;

      default:
        return this.job.warnings;
    }
  }

  protected async load(){
    if(this.job){
      await this.lazy.tryLoad();
      await this.job.jobMetadataResult.tryLoad();
    }
  }

  public async selectJob(job: JobViewModel) {
    try{
      this.job = job;
      if(this.isLoaded) {
        await this.load();
      }
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }
}

export enum JobLogFileType {
  warnings = 'warnings',
  info = 'info',
}

import { ChannelNameMap } from './channel-name-map';
import { ScalarInputsMetadataItem } from '../../url-file-loader';

/**
 * Represents scalar data for a single channel, where the values are indexed by job.
 */
export interface ChannelScalarData {
  fullName: string;
  genericName: string;
  simType: string;
  values: number[];
  units: string;
  description: string;
}

/**
 * Represents scalar data for multiple channels, keyed by the fully qualified channel name.
 */
export interface ChannelScalarDataMap {
  [fullName: string]: ChannelScalarData;
}

/**
 * A lookup of study input name to metadata about the input.
 */
export interface InputsMetadataMap {
  [inputName: string]: ScalarInputsMetadataItem;
}

/**
 * Encapsulates everything about scalar results in a study.
 */
export class ChannelScalarDataAndMappings {

  /**
   * Creates a new instance of ChannelScalarDataAndMappings.
   * @param data The mapping off fully qualified channel names to scalar data.
   * @param map The mapping of generic channel names to fully qualified channel names.
   * @param inputsMetadataMap The mapping of study input names to metadata about the input.
   * @param lineIndexToJobIndexMap A map of line index to job index. This is used to keep track of the job indexes as jobs are filtered out.
   */
  constructor(
    public readonly data: ChannelScalarDataMap,
    public readonly map: ChannelNameMap,
    public readonly inputsMetadataMap: InputsMetadataMap,
    public readonly lineIndexToJobIndexMap: number[]) {
  }

  /**
   * Gets the scalar data for a channel.
   * @param channelName Either the fully qualified or generic channel name.
   * @returns The scalar data for the channel.
   */
  public getScalarData(channelName: string): ChannelScalarData | undefined {
    let result = this.data[channelName];
    if (result) {
      return result;
    }

    // If the name did not match treating it as fully qualified, try mapping it
    // to a fully qualified name.
    let alternative = this.map[channelName];
    if (alternative) {
      return this.data[alternative];
    }

    return undefined;
  }

  /**
   * Gets the list of scalar channel names.
   * @returns The list of channel names.
   */
  public getChannelNames(): string[] {
    return this.getKeys(this.data);
  }

  /**
   * Gets all the scalar channel data.
   * @returns A list of scalar channels.
   */
  public getAllChannels(): ChannelScalarData[] {
    return this.getKeys(this.data).map(v => this.data[v]);
  }

  /**
   * Gets the channel names for the given simulation type.
   * @param simType The simulation type.
   * @returns The list of channel names.
   */
  public getChannelNamesForSimType(simType: string): string[] {
    let result = [];
    for (let channelName of this.getChannelNames()) {
      let channel = this.data[channelName];
      if (channel.simType === simType) {
        result.push(channelName);
      }
    }

    return result;
  }

  /**
   * Gets the list of simulation types.
   * @returns The list of simulation types.
   */
  public getSimTypes(): string[] {
    let simTypes: { [simType: string]: boolean } = {};
    for (let channelName of this.getChannelNames()) {
      let channel = this.data[channelName];
      simTypes[channel.simType] = true;
    }
    return this.getKeys(simTypes);
  }

  /**
   * Gets the keys for a given object.
   * @param input The object.
   * @returns The keys.
   */
  private getKeys(input: any) {
    let result: string[] = [];
    for (let key in input) {
      if (!input.hasOwnProperty(key)) {
        continue;
      }
      result.push(key);
    }
    return result;
  }
}

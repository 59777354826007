import { MultiPlotViewerSettings } from '../multi-plot-viewer-base/multi-plot-viewer-settings';

/**
 * Settings for the line plot viewer.
 */
export class LinePlotViewerSettings extends MultiPlotViewerSettings {

  /**
   * Create a new instance of the line plot viewer settings.
   * @param sourceCount The number of data sources.
   * @returns A new instance of the line plot viewer settings.
   */
  public static build(sourceCount: number) {
    return new LinePlotViewerSettings(sourceCount);
  }
}

import {Injectable} from '@angular/core';
import {TenancyStub} from '../../generated/api-stubs';

@Injectable()
export class GetTenantNameMap {

  constructor(
    private tenancyStub: TenancyStub) {
  }

  public async execute(): Promise<TenantNameMap>{
    let usersResult = await this.tenancyStub.getTenants();

    return usersResult.tenants.reduce((map, value) => {
      map[value.tenantId] = value.shortName;
      return map;
    }, <TenantNameMap>{});
  }
}

export interface TenantNameMap {
  [tenantId: string]: string;
}

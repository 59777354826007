<div class="card" id="admin-tenant-settings-component">
  <h1 class="card-header">Simulation Settings</h1>
  <div class="card-body">
    <cs-error-message [message]="errorMessage"></cs-error-message>

    @if (!form) {
      <cs-loader></cs-loader>
    }
    @if (form) {
      <div class="row">
        <div class="col-12">
          <form (ngSubmit)="onSubmit()" [formGroup]="form">
            <div class="mb-3">
              <label class="form-label" for="study-types-input">Study Types</label>
              <input type="text" id="study-types-input" class="form-control" formControlName="studyTypes">
            </div>
            <div class="mb-3">
              <label class="form-label" for="sim-version-input">Sim Version</label>
              <input type="text" id="sim-version-input" class="form-control" formControlName="simVersion">
            </div>
            <div class="mb-3">
              <label class="form-label" for="tags-input">Tenant Tags</label>
              <input type="text" id="tags-input" class="form-control" formControlName="tags">
            </div>
            <div class="mb-3">
              <label class="form-label" for="internal-tags-input">Internal Tenant Tags</label>
              <input type="text" id="internal-tags-input" class="form-control" formControlName="internalTags">
            </div>
            <h2>Compute Pool</h2>
            <div class="mb-3">
              <label class="form-label" for="pool-id-input">Compute Pool ID</label>
              <input type="text" id="pool-id-input" class="form-control" formControlName="poolId">
              @if (!poolId.pristine && poolId.hasError('required')) {
                <p class="text-danger">
                  Pool ID is required
                </p>
              }
            </div>
            <div class="mb-3">
              <label class="form-label" for="auto-scale-formula-input">Formula</label>
              <textarea id="auto-scale-formula-input" class="form-control monospace-text-area" formControlName="autoScaleFormula"></textarea>
              <small>
                This formula will be applied to the specified pool both when saved, and when studies are created.
                Keep this in mind if the pool is shared.
              </small>
              @if (!autoScaleFormula.pristine && autoScaleFormula.hasError('required')) {
                <p class="text-danger">
                  Formula is required
                </p>
              }
            </div>
            <h2>Secondary Compute Pool</h2>
            <div class="mb-3">
              <label class="form-label" for="secondary-pool-id-input">Secondary Compute Pool ID</label>
              <input type="text" id="secondary-pool-id-input" class="form-control" formControlName="secondaryPoolId">
              @if (!secondaryPoolId.pristine && secondaryPoolId.hasError('required')) {
                <p class="text-danger">
                  Secondary Pool ID is required
                </p>
              }
            </div>
            <div class="mb-3">
              <label class="form-label" for="secondary-auto-scale-formula-input">Secondary Formula</label>
              <textarea id="secondary-auto-scale-formula-input" class="form-control monospace-text-area" formControlName="secondaryAutoScaleFormula"></textarea>
              <small>
                This formula will be applied to the specified pool both when saved, and when studies are created.
                Keep this in mind if the pool is shared.
              </small>
              @if (!secondaryAutoScaleFormula.pristine && secondaryAutoScaleFormula.hasError('required')) {
                <p class="text-danger">
                  Secondary formula is required
                </p>
              }
            </div>
            <h2>Heavy Compute Pool</h2>
            <div class="mb-3">
              <label class="form-label" for="heavy-pool-id-input">Heavy Compute Pool ID</label>
              <input type="text" id="heavy-pool-id-input" class="form-control" formControlName="heavyPoolId">
              @if (!heavyPoolId.pristine && heavyPoolId.hasError('required')) {
                <p class="text-danger">
                  Heavy Pool ID is required
                </p>
              }
            </div>
            <div class="mb-3">
              <label class="form-label" for="heavy-auto-scale-formula-input">Heavy Formula</label>
              <textarea id="heavy-auto-scale-formula-input" class="form-control monospace-text-area" formControlName="heavyAutoScaleFormula"></textarea>
              <small>
                This formula will be applied to the specified pool both when saved, and when studies are created.
                Keep this in mind if the pool is shared.
              </small>
              @if (!heavyAutoScaleFormula.pristine && heavyAutoScaleFormula.hasError('required')) {
                <p class="text-danger">
                  Heavy formula is required
                </p>
              }
            </div>
            @if (saveSuccessful) {
              <div id="success-message" class="alert alert-success">
                Saved.
              </div>
            }
            <cs-error-message [message]="errorMessage"></cs-error-message>
            <p>
              <button id="pool-settings-submit" type="submit" class="btn btn-smart btn-primary" [disabled]="submitButton.isSubmitting">{{submitButton.text}}</button>
              <button id="pool-settings-test" type="button" class="btn btn-smart btn-primary" [disabled]="submitButton.isSubmitting"
              (click)="testFormula()">Test</button>
            </p>
            @if (isTestingFormula) {
              <cs-loader></cs-loader>
            }
            @if (testResult) {
              <div>
                <h2>Test Result</h2>
                <textarea readonly class="raw-json">{{testResult}}</textarea>
              </div>
            }
          </form>
        </div>
      </div>
    }
  </div>
</div>

import {ChartId} from './canopy-site-hooks.service';
import {UserSettingsCache} from '../../user-state/user-settings-cache.service';
import {Injectable} from '@angular/core';
import {ChartSettings, DocumentSubType} from '../../../generated/api-stubs';
import {RequestedLayoutIds} from '../../visualizations/site-hooks';

@Injectable()
export class GetPreferredChart {
  constructor(
    private userSettingsCache: UserSettingsCache){
  }

  public async execute(chartType: DocumentSubType, layoutIds: RequestedLayoutIds): Promise<ChartId>{
    if(!layoutIds){
      return undefined;
    }

    let allLayoutIds = [layoutIds.primary, ...layoutIds.fallback];
    let userSettings = await this.userSettingsCache.get();

    let chartSettings: ChartSettings;
    for(let layoutId of allLayoutIds){
      chartSettings = userSettings.settings.charts.find(v => v.chartType === chartType && v.chartId === layoutId);
      if(chartSettings){
        break;
      }
    }

    if(!chartSettings){
      return undefined;
    }

    return {
      userId: chartSettings.preferredChartUserId,
      configId: chartSettings.preferredChartId
    };
  }
}

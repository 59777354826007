import {Component, HostBinding, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ConfigStub, DocumentSubType, GetConfigQueryResult} from '../../../../generated/api-stubs';
import {simVersionToNumber} from '../../../visualizations/sim-version-to-number';
import {
  CompareConfigDialog,
  ISaveOutputConfigHandler
} from '../comparing/compare-config-dialog/compare-config-dialog.service';
import {LoadingDialog} from '../../../common/dialogs/loading-dialog.service';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {StudyInput} from '../../../worksheets/study-input';
import {COMPARE_SAVE_OUTPUT_BUTTON_NAME} from '../edit-config/edit-config.component';
import {ConfigOrConfigLoader} from '../comparing/config-or-config-loader';

@Component({
  selector: 'cs-compare-config-to-original',
  templateUrl: './compare-config-to-original.component.html',
  styleUrls: ['./compare-config-to-original.component.scss'],
})
export class CompareConfigToOriginalComponent implements OnChanges {

  @Input() public databaseResult: GetConfigQueryResult;
  @Input() public stagedResult: StudyInput;
  @Input() public originalStagedResult: StudyInput;
  @Input() public editorContent: StudyInput;
  @Input() public saveOutputConfigHandler: ISaveOutputConfigHandler;

  public errorMessage: string;

  @HostBinding('class.downgraded-config') public isDowngradedConfig: boolean;
  public isUpgradedConfig: boolean;
  public originalConfigVersion: number;
  public currentConfigVersion: number;
  public originalConfigVersionString: string;
  public currentConfigVersionString: string;
  public configType: DocumentSubType;

  constructor(
    private readonly configStub: ConfigStub,
    private readonly compareConfigDialog: CompareConfigDialog,
    private readonly loadingDialog: LoadingDialog,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  public get status(): string {
    if(this.isDowngradedConfig){
      return 'downgraded for your current sim version. This may produce an unexpected config';
    } else if(this.isUpgradedConfig){
      return 'upgraded';
    }

    return 'neither upgraded or downgraded';
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isDowngradedConfig = false;
    this.isUpgradedConfig = false;

    if(this.databaseResult || (this.stagedResult && this.originalStagedResult)){
      if(this.databaseResult){
        this.originalConfigVersionString = this.databaseResult.config.simVersion;
        this.currentConfigVersionString = this.databaseResult.convertedSimVersion;
        this.configType = this.databaseResult.config.subType;
      } else if(this.stagedResult){
        this.originalConfigVersionString = this.originalStagedResult.simVersion;
        this.currentConfigVersionString = this.stagedResult.simVersion;
        this.configType = this.stagedResult.configType;
      }

      this.originalConfigVersion = simVersionToNumber(this.originalConfigVersionString);
      this.currentConfigVersion = simVersionToNumber(this.currentConfigVersionString);
      this.isDowngradedConfig = this.currentConfigVersion < this.originalConfigVersion;
      this.isUpgradedConfig = this.currentConfigVersion > this.originalConfigVersion;
    }
  }

  public async compareToOriginal(event: MouseEvent){
    try {
      await this.compareToOriginalInner(event);
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async compareToOriginalInner(event: MouseEvent){
    let originalStudyInput: StudyInput;
    if(this.databaseResult){
      let originalConfigResult = await this.loadingDialog.showUntilFinished(
        this.configStub.getConfig(
          this.databaseResult.config.tenantId,
          this.databaseResult.config.documentId,
          undefined,
          this.databaseResult.config.simVersion),
        'Loading original...');

      originalStudyInput = this.studyInputFromApiResult(originalConfigResult);
    } else{
      originalStudyInput = this.originalStagedResult;
    }

    if(!this.editorContent || event.ctrlKey){
      await this.compareConvertedToOriginal(originalStudyInput);
    } else if(event.altKey){
      await this.compareEditorToConverted();
    } else{
      await this.compareEditorToOriginal(originalStudyInput);
    }
  }

  public async compareConvertedToOriginal(originalStudyInput: StudyInput){
    let convertedStudyInput = this.stagedResult || this.getDatabaseResultAsStudyInput();

    await this.showCompareDialog(
        new ConfigOrConfigLoader(this.currentConfigVersionString + ' (before editor)', convertedStudyInput, undefined),
        new ConfigOrConfigLoader(this.originalConfigVersionString, originalStudyInput, undefined));
  }

  public async compareEditorToConverted(){
    let convertedStudyInput = this.stagedResult || this.getDatabaseResultAsStudyInput();
    let editorStudyInput = this.editorContent;

    await this.showCompareDialog(
      new ConfigOrConfigLoader(this.currentConfigVersionString, editorStudyInput, undefined),
      new ConfigOrConfigLoader(this.currentConfigVersionString + ' (before editor)', convertedStudyInput, undefined));
  }

  public async compareEditorToOriginal(originalStudyInput: StudyInput){
    let editorStudyInput = this.editorContent;

    await this.showCompareDialog(
        new ConfigOrConfigLoader(this.currentConfigVersionString, editorStudyInput, undefined),
        new ConfigOrConfigLoader(this.originalConfigVersionString, originalStudyInput, undefined));
  }

  public getDatabaseResultAsStudyInput(){
    return this.studyInputFromApiResult(this.databaseResult);
  }

  public studyInputFromApiResult(value: GetConfigQueryResult){
    return StudyInput.fromApiResult(value);
  }
  public async showCompareDialog(a: ConfigOrConfigLoader, b: ConfigOrConfigLoader){
    await this.compareConfigDialog.compare(
      this.configType,
      [
        a,
        b,
      ],
      this.saveOutputConfigHandler,
      COMPARE_SAVE_OUTPUT_BUTTON_NAME);
  }
}

import { ChannelData } from './channel-data';
import { cssSanitize } from '../../../css-sanitize';
import { ChannelBinaryFormat } from '../../../url-file-loader';

export class XData implements ChannelData {
  constructor(
    public name: string,
    public genericName: string,
    public units: string,
    public description: string,
    public iLap: number,
    public specificUnits: boolean,
    public binaryFormat: ChannelBinaryFormat | undefined,
    public binaryUnits: string
  ) {
    this.values = [];
    this.sanitizedName = cssSanitize(this.name);
  }

  public sanitizedName: string;
  public values: ReadonlyArray<number>;
  public fullDomain?: [number, number];
  public cursorValue?: number;
  public direction: number = 1; // Used in PC viewer when flipping axis. 1 or -1.
  public label?: string;
  public valueLabels?: ReadonlyArray<string>;
}

<div  class="row">
  <div class="col-12">
    <cs-error-message [message]="errorMessage"></cs-error-message>
    @for (message of errorMessages; track message) {
      <cs-error-message [message]="message"></cs-error-message>
    }
    @if (!errorMessage && !errorMessages.length) {
      <cs-loader></cs-loader>
    }
  </div>
</div>



import { ProcessedPlot } from './processed-plot';
import { IPopulatedMultiPlotSide } from './i-populated-multi-plot-side';

/**
 * The result of generating the set of processed plots.
 */

export class ProcessedPlotResult {
  /**
   * Creates a new ProcessedPlotResult.
   * @param plots The set of processed plots.
   * @param columns The set of columns.
   * @param rows The set of rows.
   */
  constructor(
    public readonly plots: ProcessedPlot[],
    public readonly columns: IPopulatedMultiPlotSide[],
    public readonly rows: IPopulatedMultiPlotSide[]) {
  }
}

import * as d3 from '../../../../d3-bundle';
import { ExplorationSweepMetadata } from '../exploration-inputs-metadata';
import { GetExplorationSubSweepDataType } from '../get-exploration-sub-sweep-data-type';
import { ExplorationSubSweepDataType } from '../exploration-sub-sweep';
import { isUndefined } from '../../../../is-defined';
import { ArrayMappings } from './array-mappings';

/**
 * Gets the sweep remapping so the sweep is ordered by the first numeric sub-sweep.
 */
export class GetSweepRemapping {

  /**
   * Gets the sweep remapping so the sweep is ordered by the first numeric sub-sweep.
   * This has a number of advantages for rendering. See the comments in
   * `ProcessedExplorationMap` for more information.
   * @param sweep The sweep to remap.
   * @returns The remapping.
   */
  public execute(sweep: ExplorationSweepMetadata): ArrayMappings {
    if (sweep.subSweeps.length === 0) {
      throw new Error('Sweep contained no sub-sweeps for sorting.');
    }

    // The mapping initially consists of the indices in order.
    const mapping = d3.range(sweep.subSweeps[0].values.length);

    // Find the first sub-sweep of numeric values, so we can sort on it.
    const firstNumericSubSweep = sweep.subSweeps.find(
      subSweep => GetExplorationSubSweepDataType.execute(subSweep.values) === ExplorationSubSweepDataType.Numeric);

    // If we have a numeric sub-sweep, we can sort the mappings array based on the values in that sub-sweep.
    if (!isUndefined(firstNumericSubSweep)) {
      const values = firstNumericSubSweep.values as ReadonlyArray<number>;

      mapping.sort((a: number, b: number) => values[a] - values[b]);
    }

    // And return the new mapping.
    return new ArrayMappings(mapping);
  }
}

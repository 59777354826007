import { SourceData } from './types/source-data';
import { NormalizeUnitsForChannelSources } from '../channel-data-loaders/normalize-units-for-channel-sources';


/**
 * For each channel, ensure each source's data is in the same units.
 */
export class NormalizeSourcesChannelData {
  constructor(
    private readonly normalizeUnitsForChannelSources: NormalizeUnitsForChannelSources) {
  }

  /**
   * For each channel, ensure each source's data is in the same units.
   * @param channelNames - The names of the channels to normalize.
   * @param sourcesData - The sources to normalize.
   */
  public execute(channelNames: ReadonlyArray<string>, sourcesData: ReadonlyArray<SourceData>) {
    for (let channelName of channelNames) {
      let rawData = sourcesData.map(v => v.channels.get(channelName));

      let modifiedData = this.normalizeUnitsForChannelSources.execute(rawData);

      if (modifiedData) {
        sourcesData.forEach((s, i) => {
          s.channels.set(channelName, modifiedData[i]);
        });
      }
    }
  }
}

import {Injectable} from '@angular/core';
import {ConvertPacejkaTirToJson} from './convert-pacejka-tir-to-json.service';
import {ProcessPacejkaJson, SIDE_DEFINITION, SIM_VERSION_BEFORE_TYRE_SIDE} from './process-pacejka-json.service';
import {ConfirmationDialog} from '../../common/dialogs/confirmation-dialog.service';
import {simVersionToNumber} from '../../visualizations/sim-version-to-number';
import {ConformTirDataToCanopyStructureService} from './conform-tir-data-to-canopy-structure.service';

@Injectable()
export class PerformTirImportFactory {

  constructor(
    private readonly convertPacejkaTirToJson: ConvertPacejkaTirToJson,
    private readonly processPacejkaJson: ProcessPacejkaJson,
    private readonly conformTirDataToCanopyStructureService: ConformTirDataToCanopyStructureService,
    private readonly confirmationDialog: ConfirmationDialog) {
  }

  public create(simVersion: string){
    return new PerformTirImport(
      simVersion,
      this.convertPacejkaTirToJson,
      this.processPacejkaJson,
      this.conformTirDataToCanopyStructureService,
      this.confirmationDialog);
  }
}

export class PerformTirImport {

  constructor(
    private readonly simVersion: string,
    private readonly convertPacejkaTirToJson: ConvertPacejkaTirToJson,
    private readonly processPacejkaJson: ProcessPacejkaJson,
    private readonly conformTirDataToCanopyStructureService: ConformTirDataToCanopyStructureService,
    private readonly confirmationDialog: ConfirmationDialog){
  }

  public async execute(tirContent: string, existingTyre: any): Promise<any> {
    let tirData = this.convertPacejkaTirToJson.execute(tirContent);
    let processResult = this.processPacejkaJson.execute(tirData, this.simVersion);

    let simVersionAsNumber = simVersionToNumber(this.simVersion);
    if (simVersionAsNumber >= SIM_VERSION_BEFORE_TYRE_SIDE) {
      if (!tirData[SIDE_DEFINITION]) {
        let side = await this.confirmationDialog.show(
          'Do these Pacejka coefficients define the behaviour of a left or right tyre?',
          'TIR file side',
          ['Left', 'Right'],
          'Cancel');

        if (!side) {
          return undefined;
        }

        tirData[SIDE_DEFINITION] = side;
      }
    }

    if(processResult.warnings && processResult.warnings.length){
      let confirmationDialogResult = await this.confirmationDialog.show(
        'The following warnings occurred during the import. Would you like to continue?',
        'TIR Import',
        'Continue',
        'Cancel',
        processResult.warnings);

      if(!confirmationDialogResult){
        return undefined;
      }
    }

    let existingWithoutPacejka = this.getParametersWithoutPacejka(existingTyre);

    let result = {
      ...existingWithoutPacejka,
      ...tirData
    };

    this.conformTirDataToCanopyStructureService.execute(result, this.simVersion, processResult.pacejkaVersion);

    return result;
  }

  private getParametersWithoutPacejka(input: any): any {
    let result = {
      ...input,
    };

    for (let key in result) {
      if (!result.hasOwnProperty(key)) {
        continue;
      }

      if (key.toUpperCase() !== key){
        // If the parameter isn't uppercase then it isn't pacejka.
        continue;
      }

      delete result[key];
    }

    return result;
  }
}

@if (hasBeenActivated) {
  <cs-job-selector [jobs]="jobs"
    [showPostProcessor]="true"
    [jobSelectorState]="jobSelectorState"
  (changed)="selectJob($event)"></cs-job-selector>
  <div class="container-fluid">
    <cs-error-message [message]="errorMessage"></cs-error-message>
    @if (job) {
      @if (collapsible && lazy.value?.length) {
        <p>
          <button
            class="btn btn-secondary" type="button" data-bs-toggle="collapse" data-bs-target="#log-file-content" aria-expanded="false" aria-controls="log-file-content">
            Toggle Warnings
          </button>
        </p>
      }
      <div class="show" id="log-file-content">
        @if (!lazy.isLoaded) {
          <cs-loader id="job-{{logFileType}}-loader"></cs-loader>
        }
        @if (lazy.isLoaded) {
          @if (lazy.value?.length) {
            <div class="alert alert-{{alertType}}">
              @for (message of lazy.value; track message) {
                <p>{{message}}</p>
              }
            </div>
          }
          @if (!collapsible && !lazy.value?.length) {
            <div class="alert alert-info">
              <p>No messages found.</p>
            </div>
          }
        }
      </div>
    }
  </div>
}

import {
  ConfigReference,
  DocumentSubType,
} from '../../generated/api-stubs';
import {Injectable} from '@angular/core';
import {LoadConfigFromReference} from './load-config-from-reference';
import {
  CompareConfigDialog,
} from '../simulations/configs/comparing/compare-config-dialog/compare-config-dialog.service';
import {LoadingDialog} from '../common/dialogs/loading-dialog.service';
import {StudyStagingArea} from '../simulations/study-staging-area/study-staging-area.service';
import {GetSimVersion} from '../common/get-sim-version.service';
import {SaveToConfigReferenceHandlerFactory} from '../simulations/configs/comparing/save-output-config-handlers/save-to-config-reference-handler';
import {ConfigOrConfigLoader} from '../simulations/configs/comparing/config-or-config-loader';

/**
 * A service for comparing a worksheet config to the staged config.
 */
@Injectable()
export class CompareConfigToStaged {

  /**
   * Creates an instance of CompareConfigToStaged.
   * @param compareConfigDialog The dialog for comparing configs.
   * @param loadingDialog The dialog for showing a loading spinner.
   * @param loadConfigFromReference The service for loading a config from a reference.
   * @param getSimVersion The service for getting the current sim version.
   * @param studyStagingArea The service for the study staging area.
   * @param saveToConfigReferenceHandlerFactory The factory for creating a handler for saving to a config reference.
   */
  constructor(
    private readonly compareConfigDialog: CompareConfigDialog,
    private readonly loadingDialog: LoadingDialog,
    private readonly loadConfigFromReference: LoadConfigFromReference,
    private readonly getSimVersion: GetSimVersion,
    private readonly studyStagingArea: StudyStagingArea,
    private readonly saveToConfigReferenceHandlerFactory: SaveToConfigReferenceHandlerFactory){
  }

  /**
   * Compares a worksheet config to the staged config.
   * @param configType The config type.
   * @param configReference The config reference.
   * @param referenceShortName The short name for the reference.
   */
  public async execute(configType: DocumentSubType, configReference: ConfigReference, referenceShortName?: string){
    const config = await this.loadingDialog.showUntilFinished(
      this.loadConfigFromReference.execute(configType, configReference),
      'Loading config...');
    let staged = await this.studyStagingArea.getInput(configType, this.getSimVersion.currentSimVersion);
    await this.compareConfigDialog.compare(
      configType,
      [
        new ConfigOrConfigLoader(referenceShortName || 'worksheet', config, undefined),
        new ConfigOrConfigLoader('staged', staged, undefined),
      ],
      this.saveToConfigReferenceHandlerFactory.create(configReference));
  }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeWhile } from 'rxjs/operators';

import { DEFAULT_ACCEPT_MESSAGE } from '../../../common/dialogs/confirmation-dialog.component';
import { ConfirmationDialog } from '../../../common/dialogs/confirmation-dialog.service';
import { InferableRouteParams } from '../../../common/inferable-route-params.service';
import { ConfigPermissionsService } from '../services/config-permissions.service';
import { AuthenticationService } from '../../../identity/state/authentication.service';

export class ConfigPermissionSummary {
  constructor(
    public encryptingTenantShortName: string,
    public decryptingTenantShortName: string,
    public minimumSimVersion: string,
    public description: string
  ) {}
}

@Component({
  selector: 'cs-view-config-permission',
  templateUrl: './view-config-permission.component.html',
})
export class ViewConfigPermissionComponent implements OnInit, OnDestroy {

  @Input() public readonly tenantId: string;

  public isLoading: boolean = true;
  public configPermissions: ConfigPermissionSummary[] = [];

  private _isSubscribed: boolean = false;

  public get showToolbar(): boolean {
    return this._authenticationService.isAdministrator || this._authenticationService.isTenantAdministrator;
  }

  constructor(
    inferableRouteParams: InferableRouteParams,
    private _configPermissionService: ConfigPermissionsService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _confirmationDialog: ConfirmationDialog,
    private _authenticationService: AuthenticationService) {
      this.tenantId = inferableRouteParams.getTenantId(this._route);
    }

  /**
   * Handles the OnInit lifecycle event
   */
  public async ngOnInit(): Promise<void> {
    this._isSubscribed = true;
    this.configPermissions = this._configPermissionService.permissionsSnapshot;

    if (this.configPermissions.length === 0) {
      await this._configPermissionService.fetchConfigPermissions(this.tenantId).toPromise();
    } else {
      this.isLoading = false;
    }

    this._configPermissionService.permissions
      .pipe(
        takeWhile(_ => this._isSubscribed)
      )
      .subscribe(permissions => {
        this.configPermissions = permissions;
        this.isLoading = false;
      });
  }

  /**
   * Handles the OnDestroy lifecycle event
   */
  public ngOnDestroy(): void {
    this._isSubscribed = false;
  }

  /**
   * Handles the click event of edit config permission
   * @param event
   * @param configPermission
   */
  public async onEdit(configPermission: ConfigPermissionSummary): Promise<void> {
    await this._configPermissionService.setConfigPermission(
      configPermission.encryptingTenantShortName,
      configPermission.decryptingTenantShortName,
      configPermission.minimumSimVersion,
      configPermission.description
    ).toPromise();

    this._router.navigate([
        'edit',
        configPermission.encryptingTenantShortName,
        configPermission.decryptingTenantShortName
      ],
      { relativeTo: this._route }
    );
  }

  /**
   * Handles the click event of delete config permission
   * @param configPermission
   * @returns
   */
  public async onDelete(configPermission: ConfigPermissionSummary): Promise<void> {
    const confirmationResult = await this._confirmationDialog.show(
      'Are you sure you want to delete this item?',
      'Delete Config Permission'
    );

    if (confirmationResult !== DEFAULT_ACCEPT_MESSAGE) {
      return;
    }

    await this._configPermissionService.deleteConfigPermission(
      this.tenantId,
      configPermission.encryptingTenantShortName,
      configPermission.decryptingTenantShortName
    ).toPromise();

    await this._configPermissionService.fetchConfigPermissions(this.tenantId).toPromise();
  }

  public trackById(index: number, item: ConfigPermissionSummary) {
    return index;
  }
}

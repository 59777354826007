import { Vector3 } from 'three';
import { CoordinateTriplet } from './coordinate-triplet';

/**
 * Represents a coordinate in the track's coordinate system.
 */
export class TrackCoordinate {

  /**
   * Creates a new instance of TrackCoordinate.
   * @param x The x coordinate.
   * @param y The y coordinate.
   * @param z The z coordinate.
   */
  constructor(
    public readonly x: number,
    public readonly y: number,
    public readonly z: number) {
  }

  /**
   * Gets the x coordinate in the world coordinate system.
   */
  public get worldX(): number {
    return this.x;
  }

  /**
   * Gets the y coordinate in the world coordinate system.
   */
  public get worldY(): number {
    return this.z;
  }

  /**
   * Gets the z coordinate in the world coordinate system.
   */
  public get worldZ(): number {
    return -this.y;
  }

  /**
   * Gets the coordinates in the world coordinate system.
   */
  public get worldVector(): Vector3 {
    return new Vector3(this.worldX, this.worldY, this.worldZ);
  }

  /**
   * Gets the coordinates as a Vector3.
   * @returns The coordinates as a Vector3.
   */
  public asVector3(): Vector3 {
    return new Vector3(this.x, this.y, this.z);
  }
}

/**
 * A factory for creating TrackCoordinate instances.
 */
class TrackCoordinateFactoryImplementation {

  /**
   * Creates a new instance of TrackCoordinate from an array.
   * @param coordinate The coordinate.
   * @returns The new instance.
   */
  public fromArray(coordinate: CoordinateTriplet) {
    return new TrackCoordinate(coordinate[0], coordinate[1], coordinate[2]);
  }

  /**
   * Creates a new instance of TrackCoordinate from a vector in the world coordinate system.
   * @param coordinate The coordinate.
   * @returns The new instance.
   */
  public fromWorldVector(coordinate: Vector3) {
    return new TrackCoordinate(coordinate.x, -coordinate.z, coordinate.y);
  }
}

/**
 * The factory for creating TrackCoordinate instances.
 */
export const TrackCoordinateFactory = new TrackCoordinateFactoryImplementation();

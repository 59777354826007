import { SourceLoaderBase, SourceMetadata } from './source-loader';
import { IViewerChannelData } from './viewer-channel-data';
import { ChannelNameStyle } from './channel-name-style';
import { getFullyQualifiedChannelName } from '../../get-fully-qualified-channel-name';
import { LoadedChannelData, ViewerChannelDataFactory } from './viewer-channel-data-factory';
import { ParseChannelDataCsv } from './parse-channel-data-csv';
import {
  GENERIC_CHANNEL_DESCRIPTION, IMPORT_CHANNEL_DEFAULT_DESCRIPTION, IMPORT_SIM_TYPE,
  NO_CHANNEL_DESCRIPTION
} from '../../constants';
import { EditChannelsChannelMetadata, IEditChannelsChannelMetadata, SiteHooks } from '../../site-hooks';
import { CsvSourceLoaderCache } from './csv-source-loader-cache';
import { GetLegacyChannelMappings } from './get-legacy-channel-mappings';

/**
 * A channel data source loader for CSV files.
 */
export class CsvSourceLoader extends SourceLoaderBase {

  /**
   * Creates a new instance of CsvSourceLoader.
   * @param name The name of the source.
   * @param cache The parsed CSV data cache.
   * @param channelDataFactory The channel data factory.
   */
  constructor(
    private name: string,
    private cache: CsvSourceLoaderCache,
    private channelDataFactory: ViewerChannelDataFactory) {
    super();
  }

  /**
   * Creates a new instance of CsvSourceLoader.
   * @param name The name of the source.
   * @param fileContent The content of the CSV file.
   * @param siteHooks The site hooks.
   * @returns A new instance of CsvSourceLoader.
   */
  public static create(
    name: string,
    fileContent: string,
    siteHooks: SiteHooks): CsvSourceLoader {

    return new CsvSourceLoader(
      name,
      new CsvSourceLoaderCache(
        fileContent,
        new ParseChannelDataCsv(
          IMPORT_SIM_TYPE,
          new GetLegacyChannelMappings())),
      new ViewerChannelDataFactory(siteHooks));
  }

  /**
   * @inheritdoc
   */
  public async getSourceMetadata(): Promise<SourceMetadata> {
    return new SourceMetadata(this.name);
  }

  /**
   * @inheritdoc
   */
  public async getChannelData(requestedName: string, resultChannelNameStyle: ChannelNameStyle, xDomainName: string): Promise<IViewerChannelData> {
    let parsedChannels = await this.cache.get();

    let channel: LoadedChannelData | undefined;
    let parsedChannel = parsedChannels.map[requestedName];
    if (parsedChannel) {
      channel = new LoadedChannelData(
        parsedChannel.name,
        getFullyQualifiedChannelName(parsedChannel.name, parsedChannel.simType),
        '',
        parsedChannel.units,
        undefined,
        parsedChannel.values);
    }

    return this.channelDataFactory.createChannelData(requestedName, channel, resultChannelNameStyle);
  }

  /**
   * @inheritdoc
   */
  public async getRequestableChannels(resultChannelNameStyle: ChannelNameStyle, xDomainName: string): Promise<IEditChannelsChannelMetadata[]> {
    let parsedChannels = await this.cache.get();

    switch (resultChannelNameStyle) {
      case ChannelNameStyle.FullyQualified:
        return [
          ...parsedChannels.list.map(
            v => new EditChannelsChannelMetadata(v.name, GENERIC_CHANNEL_DESCRIPTION)),
          ...parsedChannels.list.map(
            v => new EditChannelsChannelMetadata(getFullyQualifiedChannelName(v.name, v.simType), IMPORT_CHANNEL_DEFAULT_DESCRIPTION))
        ];

      case ChannelNameStyle.Generic:
        return parsedChannels.list.map(
          v => new EditChannelsChannelMetadata(v.name, NO_CHANNEL_DESCRIPTION));
    }

    return [];
  }
}


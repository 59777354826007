import { ExtractSuspensionDataFromCar } from './extract-suspension-data-from-car';
import {
  Color,
  BufferGeometry,
  Group,
  Line,
  LineBasicMaterial,
  LineDashedMaterial,
  Material,
  Mesh,
  MeshPhongMaterial,
  Raycaster,
  SphereGeometry, Object3D, BufferAttribute
} from 'three';
import { System } from '../3d/system';
import { SharedState } from '../shared-state';
import { SelectedComponentsRenderer } from './selected-components-renderer';
import { firstDistinct } from '../../first-distinct';
import { ConfigRendererBase, RenderedConfig } from '../3d/config-renderer-base';
import { SuspensionAreaData, SuspensionAreas, SuspensionMember, SuspensionMemberType } from './car-types';

/**
 * A rendered suspension member.
 */
export class RenderedSuspensionMember {

  /**
   * Creates a RenderedSuspensionMember instance.
   * @param suspensionArea The suspension area data.
   * @param suspensionMember The suspension member.
   * @param colorString The color the member was rendered in.
   */
  constructor(
    public readonly suspensionArea: SuspensionAreaData,
    public readonly suspensionMember: SuspensionMember,
    public readonly colorString: string) {
  }
}

/**
 * The suspension renderer.
 */
export class SuspensionRenderer extends ConfigRendererBase implements System {

  /**
   * The selected components renderer. This renders the components currently being hovered over.
   */
  private selectedComponentsRenderer?: SelectedComponentsRenderer;

  /**
   * Create a new instance of the suspension renderer.
   * @param sharedState The shared state.
   * @param extractSuspensionDataFromCar The service to extract suspension data from car.
   */
  constructor(
    sharedState: SharedState,
    private readonly extractSuspensionDataFromCar: ExtractSuspensionDataFromCar) {
    super(sharedState);
  }

  /**
   * The list of highlighted groups, which the user is hovering over with the mouse.
   */
  private highlightedGroups: ReadonlyArray<Object3D> | undefined;

  /**
   * Builds the the suspension renderer.
   */
  public performBuild() {
    this.selectedComponentsRenderer = new SelectedComponentsRenderer(this.settings);
  }

  /**
   * Updates the suspension renderer.
   * @param mouseRaycaster The raycaster for the mouse.
   */
  public performUpdate(mouseRaycaster: Raycaster | undefined) {
    // If we haven't been built, don't do anything.
    if (!this.selectedComponentsRenderer) {
      return;
    }

    if (mouseRaycaster) {
      // Get the groups the user is hovering over.
      let intersectionGroups = this.getIntersectionGroups(mouseRaycaster);

      // If the currently highlighted groups are not the same as the new groups, reset the colors of the currently highlighted groups.
      if (this.highlightedGroups && !this.areIntersectionGroupsEqual(this.highlightedGroups, intersectionGroups)) {
        for (let highlightedGroup of this.highlightedGroups) {
          let member: RenderedSuspensionMember = highlightedGroup.userData.model;
          if (member) {
            this.setMemberColor(highlightedGroup, undefined);
          }
        }
        this.highlightedGroups = undefined;
      }

      // If there are new groups to highlight, highlight them.
      if (intersectionGroups && !this.areIntersectionGroupsEqual(intersectionGroups, this.highlightedGroups)) {
        this.highlightedGroups = intersectionGroups;
        for (let intersectionGroup of intersectionGroups) {
          let member: RenderedSuspensionMember = intersectionGroup.userData.model;
          if (member) {
            // We'll make the color slightly darker for highlighted members.
            this.setMemberColor(intersectionGroup, (c: Color) => c.multiplyScalar(0.75));
          }
        }

        // Update the selected components renderer with the new set of components.
        this.selectedComponentsRenderer.selectedComponents(this.highlightedGroups).render(this.svg);
      }
    }
  }

  /**
   * Tests if two lists of objects contain the same set of objects (in any order).
   * @param a The first list of objects.
   * @param b The second list of objects.
   * @returns True if the lists contain the same set of objects; otherwise false.
   */
  private areIntersectionGroupsEqual(a: ReadonlyArray<Object3D> | undefined, b: ReadonlyArray<Object3D> | undefined): boolean {
    if (!a && !b) {
      return true;
    }

    if (!a || !b) {
      return false;
    }

    if (a.length !== b.length) {
      return false;
    }

    return a.every(v => b.indexOf(v) !== -1) && b.every(v => a.indexOf(v) !== -1);
  }

  /**
   * Gets the visible, non-ignored groups that are intersected by the mouse.
   * @param mouseRaycaster The raycaster for the mouse.
   * @returns The groups that are intersected by the mouse.
   */
  private getIntersectionGroups(mouseRaycaster: Raycaster | undefined): ReadonlyArray<Object3D> {
    if (mouseRaycaster) {
      // Get all the objects intersecting with the mouse.
      let intersects = mouseRaycaster.intersectObjects(this.group.children, true);

      // Filter the list...
      intersects = intersects.filter(v => {
        // Ignore objects that are marked as ignored.
        if (v.object.userData.ignoreIntersection) {
          return false;
        }

        // Return true if the object and all its ancestors are visible.
        let visible = true;
        v.object.traverseAncestors(a => visible = visible && a.visible);
        return visible;
      });

      // If there are any intersections, return the distinct parents of the intersected objects.
      if (intersects && intersects.length) {
        return firstDistinct(intersects.map(v => v.object.parent), v => v);
      }
    }

    // No intersections, so return an empty array.
    return [];
  }

  /**
   * Sets the color of the members in the specified group.
   * @param group The group to set the color of.
   * @param desiredColor The delegate which returns the desired color, or undefined to reset the color.
   */
  private setMemberColor(group: Object3D, desiredColor: ((c: Color) => Color) | undefined) {
    // Traverse the group and all its descendants and update their color.
    group.traverse(v => {
      if (v.userData.originalColor) {
        let material: Material = (v as any).material;
        if (material) {
          let color: Color = (material as any).color;
          if (color) {

            if (desiredColor) {
              // Set the color to the desired color.
              color.set(desiredColor(new Color(v.userData.originalColor)));
            } else {
              // Reset the color.
              color.set(v.userData.originalColor);
            }
          }
        }
      }
    });
  }

  /**
   * Renders the suspension data using the specified source index. The source index determines the color of the rendered suspension data.
   * @param sourceIndex The source index.
   * @param data The suspension data.
   * @returns The rendered configuration.
   */
  protected renderConfig(sourceIndex: number, data: any): RenderedConfig {
    let suspensionData = this.extractSuspensionDataFromCar.execute(data);

    let group = new Group();
    for (let areaName of SuspensionAreas) {
      let area = suspensionData.getArea(areaName);
      this.addSuspensionAreaToScene(group, sourceIndex, area);
    }

    return new RenderedConfig(sourceIndex, group);
  }

  /**
   * Adds a suspension area to the scene.
   * @param areaGroup The group to add the suspension area to.
   * @param sourceIndex The source index, which determines the color of the rendered suspension data.
   * @param suspensionArea The suspension area data.
   */
  private addSuspensionAreaToScene(areaGroup: Group, sourceIndex: number, suspensionArea: SuspensionAreaData) {
    let colorIndex = 0;
    for (let member of suspensionArea.list) {

      // Get the color using the member index and source index.
      let colorString = this.settings.getChannelColor(colorIndex, sourceIndex);
      ++colorIndex;

      if (!member.coordinates || !member.coordinates.length) {
        continue;
      }

      // Create a new group for the member.
      let group = new Group();

      // Add it to the area group.
      areaGroup.add(group);

      let color = new Color(colorString);

      // Store the suspension member data in the group.
      group.userData.model = new RenderedSuspensionMember(suspensionArea, member, colorString);

      // Add the member data to the group.
      switch (member.type) {
        case SuspensionMemberType.line:
          this.addSuspensionMemberAsLine(color, member, group);
          break;

        case SuspensionMemberType.circle:
          this.addSuspensionMemberAsCircle(color, member, group);
          break;
      }
    }
  }

  /**
   * Adds a suspension member to the scene as a 2D circle.
   * @param color The color of the circle.
   * @param member The suspension member.
   * @param group The group to add the circle to.
   * @returns
   */
  private addSuspensionMemberAsCircle(color: Color, member: SuspensionMember, group: Group) {
    if (!member.coordinates || member.coordinates.length !== 2) {
      return;
    }

    // We use the first two coordinates as the center and edge positions.
    const center = member.coordinates[0].worldVector;
    const edge = member.coordinates[1].worldVector;

    const radius = center.distanceTo(edge);
    const segmentCount = 32;
    const geometry = new BufferGeometry();
    const material = new LineBasicMaterial({ color, linewidth: 1 });

    // Create a set of lines that form a circle.
    let array = new Float32Array((segmentCount + 1) * 3);
    for (let i = 0; i <= segmentCount; i++) {
      const theta = (i / segmentCount) * Math.PI * 2;
      array[i * 3] = center.x + Math.cos(theta) * radius;
      array[i * 3 + 1] = center.y + Math.sin(theta) * radius;
      array[i * 3 + 2] = center.z;
    }
    geometry.setAttribute('position', new BufferAttribute(array, 3));
    geometry.getAttribute('position').needsUpdate = true;

    // Add the lines to the group.
    group.add(new Line(geometry, material));
  }

  /**
   * Adds a suspension member to the scene as a 3D line.
   * @param color The color of the line.
   * @param member The suspension member.
   * @param group The group to add the line to.
   */
  private addSuspensionMemberAsLine(color: Color, member: SuspensionMember, group: Group) {
    if (!member.coordinates) {
      return;
    }

    let referenceLineColor = 0xdddddd;

    // A solid sphere material.
    let startMaterial = new MeshPhongMaterial({ color, flatShading: false, specular: 0x444444 });

    // A wireframe sphere material.
    let endMaterial = new MeshPhongMaterial({
      color,
      flatShading: false,
      specular: 0x444444,
      wireframe: true,
      wireframeLinewidth: 0.1
    });
    let lineMaterial = new LineBasicMaterial({ color, linewidth: 1 });
    let materialDashed = new LineDashedMaterial({ color: referenceLineColor, dashSize: 0.02, gapSize: 0.02 });

    // Render each coordinate as a sphere connected to the previous and next coordinates by a line.
    for (let i = 0; i < member.coordinates.length; ++i) {
      let current = member.coordinates[i];

      if (i === 0) {
        // We use a solid sphere as the first point on the line.
        let startSphereGeometry = new SphereGeometry(0.012, 16, 16);
        let startSphere = new Mesh(startSphereGeometry, startMaterial);
        startSphere.position.copy(current.worldVector);
        startSphere.userData.originalColor = color;
        group.add(startSphere);

        // Create a dashed line down to the ground plane.
        let startReferenceLineGeometry = new BufferGeometry();
        startReferenceLineGeometry.setAttribute('position', new BufferAttribute(
          new Float32Array([
            current.worldVector.x, current.worldVector.y, current.worldVector.z,
            current.worldVector.x, 0, current.worldVector.z
          ]), 3));
        startReferenceLineGeometry.getAttribute('position').needsUpdate = true;
        let startReferenceLine = new Line(startReferenceLineGeometry, materialDashed);
        startReferenceLine.computeLineDistances();
        startReferenceLine.userData.originalColor = referenceLineColor;
        group.add(startReferenceLine);
      } else {
        let previous = member.coordinates[i - 1];

        // Create a line between the current point and the previous point.
        let lineGeometry = new BufferGeometry();
        lineGeometry.setAttribute('position', new BufferAttribute(
          new Float32Array([
            previous.worldVector.x, previous.worldVector.y, previous.worldVector.z,
            current.worldVector.x, current.worldVector.y, current.worldVector.z
          ]), 3));
        lineGeometry.getAttribute('position').needsUpdate = true;
        let line = new Line(lineGeometry, lineMaterial);
        line.userData.originalColor = color;
        group.add(line);

        // Create a dashed line down to the ground plane.
        let endReferenceLineGeometry = new BufferGeometry();
        endReferenceLineGeometry.setAttribute('position', new BufferAttribute(
          new Float32Array([
            current.worldVector.x, current.worldVector.y, current.worldVector.z,
            current.worldVector.x, 0, current.worldVector.z
          ]), 3));
        endReferenceLineGeometry.getAttribute('position').needsUpdate = true;
        let endReferenceLine = new Line(endReferenceLineGeometry, materialDashed);
        endReferenceLine.computeLineDistances();
        endReferenceLine.userData.originalColor = referenceLineColor;
        group.add(endReferenceLine);

        // All other points on the line use a wireframe sphere.
        let endSphereGeometry = new SphereGeometry(0.012, 10, 10);
        let endSphere = new Mesh(endSphereGeometry, endMaterial);
        endSphere.position.copy(current.worldVector);
        endSphere.userData.originalColor = color;
        group.add(endSphere);
      }
    }
  }
}

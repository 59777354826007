import {Rule} from '../runner/rule';
import {ConfigContext} from '../runner/config-context';
import {RuleLogger} from '../runner/rule-logger';

export class AirTemperature extends Rule {
  public name = 'Air Temperature';
  public configTypes = 'weather';
  public simTypes: string[] = null;

  public evaluateConfig(context: ConfigContext, log: RuleLogger) {
    let TAir = context.getValue('TAir');
    if(TAir){
      if(TAir.value > 60) {
        log.warn(`Air temperature is unusually hot: ${TAir.value} ℃`, TAir.path);
      } else if(TAir.value < -60) {
        log.warn(`Air temperature is unusually cold: ${TAir.value} ℃`, TAir.path);
      }
    }
  }
}

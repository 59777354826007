export class FormSubmissionButton {
  constructor(private defaultText: string, private submittingText: string) {
  }

  public isSubmitting: boolean;

  public get text(): string {
    return this.isSubmitting ? this.submittingText : this.defaultText;
  }
}

import {Injectable} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {Event} from '@angular/router';

@Injectable()
export class RouterDataCache {
  private data: any;
  private navigationCounter: number;

  constructor(
    private readonly router: Router) {
    this.router.events.subscribe((event: Event) => this.handleRouteChange(event as NavigationStart));
  }

  public handleRouteChange(event: NavigationStart) {
    if(event instanceof NavigationStart){
      ++this.navigationCounter;
      if (this.navigationCounter >= 2){
        this.data = undefined;
      }
    }
  }

  public push(data: any) {
    this.data = data;
    this.navigationCounter = 0;
  }

  public pop<T>(): T {
    let result = this.data as T;
    this.data = undefined;
    return result;
  }
}

import {
  ConfigReference,
  ConfigResolvedReference,
  DocumentSubType, ResolvedLabel, SimType,
  StudyResolvedReference,
  UserInformation,
  WorksheetConfig,
} from '../../generated/api-stubs';
import {WorksheetUnderlyingData} from './worksheet-underlying-data';
import {UNKNOWN_USER} from './worksheet-constants';
import {RowViewModel} from './row-view-model';
import {PopulatedStudyViewModel, StudyInputNecessity} from './study-view-model';
import {HashValidity} from './hash-validity';
import {RowItemViewModel} from './row-item-view-model';
import {referenceAsStudy} from './worksheet-types';
import {IReadonlyWorksheetReferenceMetadata} from './worksheet-references-metadata';

/**
 * The view model for a config.
 */
export class ConfigViewModel extends RowItemViewModel {

  /**
   * The populated config view model.
   */
  public populated: PopulatedConfigViewModel | undefined;

  /**
   * The unpopulated config view model.
   */
  public readonly unpopulated: UnpopulatedConfigViewModel;

  /**
   * Whether the config is selected.
   */
  public isSelected: boolean;

  /**
   * Whether the config matches the selected config.
   */
  public matchesSelectedConfig: boolean = false;

  /**
   * Creates an instance of ConfigViewModel.
   * @param parent The parent row view model.
   * @param configType The config type.
   * @param config The config metadata as returned from the API.
   * @param underlyingData The worksheet underlying data.
   */
  constructor(
    parent: RowViewModel,
    configType: DocumentSubType,
    config: WorksheetConfig,
    private underlyingData: WorksheetUnderlyingData) {
    super(parent);
    if (config && config.reference) {
      this.populated = new PopulatedConfigViewModel(this, configType, config);
    }

    this.unpopulated = new UnpopulatedConfigViewModel(configType, underlyingData);

    this.update(underlyingData);
  }

  /**
   * Gets the metadata for the config reference, or undefined if the cell is not populated.
   */
  public get referenceMetadata(): IReadonlyWorksheetReferenceMetadata {
    return this.populated
      ? this.underlyingData.referencesMetadata.get(this.populated.reference)
      : undefined;
  }

  /**
   * Gets the config type.
   */
  public get configType(): DocumentSubType {
    return this.unpopulated.configType;
  }

  /**
   * Gets whether the config view model is populated with a config.
   */
  public get isPopulated(): boolean {
    return !!this.populated;
  }

  /**
   * Gets whether the study view model on the same row as the config is populated with a study.
   */
  public get isStudyPopulated(): boolean {
    return !!this.row.study.populated;
  }

  /**
   * Gets whether the config is a telemetry config.
   */
  public get isTelemetry(): boolean {
    return this.configType === DocumentSubType.telemetry;
  }

  /**
   * Gets whether the config is read only for the given user ID.
   * @param userId The user ID to check.
   */
  public isReadOnly(userId: string): boolean {
    return !!(this.populated
      && this.populated.resolvedReference
      && this.populated.resolvedReference.data
      && this.populated.resolvedReference.data.userId !== userId);
  }

  /**
   * Gets whether the config is writeable for the given user ID.
   * @param userId The user ID to check.
   */
  public canWrite(userId: string): boolean{
    return !this.isReadOnly(userId);
  }

  /**
   * Gets the hash validity state of the config. A valid hash means that the config matches
   * one of the input hashes of the study, and therefore is identical to the config used to
   * produce the study. An invalid hash indicates the config is not the same as the one used
   * to produce the study.
   */
  public get hashValidity(): HashValidity {
    if(this.row.study.isResolved && this.row.study.populated.resolvedReference.data.inputHashes.length){

      if (this.studyInputNecessity === StudyInputNecessity.invalid){
        return HashValidity.none;
      }

      const studyHashes = this.row.study.populated.resolvedReference.data.inputHashes.find(
        v => v.configType === this.configType);
      if (studyHashes){
        if(this.isResolved){
          if(this.populated.configResolvedReference.data.hashes.some(
            c => studyHashes.hashes.some(s => c.hash === s.hash))){
            return HashValidity.valid;
          } else{
            return HashValidity.invalid;
          }
        } else{
          return HashValidity.invalid;
        }
      } else {
        if (this.isResolved) {
          return HashValidity.invalid;
        }
      }
    }

    return HashValidity.none;
  }

  /**
   * Updates the config view model with the given underlying data.
   * @param underlyingData The underlying data.
   */
  public update(underlyingData: WorksheetUnderlyingData) {
    this.underlyingData = underlyingData;
    if(this.populated){
      this.populated.update(this.underlyingData);
    }

    this.unpopulated.update(this.underlyingData);
  }

  /**
   * Replaces all references in the the worksheet which match the current referenced config
   * with the new target reference.
   * @param targetReference The target reference to replace with.
   */
  public replaceAllConfigs(targetReference: ConfigReference) {
    if(!this.populated){
      return;
    }

    this.row.worksheet.setAllConfigs(this.populated.reference, targetReference);
  }

  /**
   * Replaces all references in the the worksheet which match the source reference
   * with the target reference.
   * @param sourceReference The source reference to replace.
   * @param targetReference The target reference to replace with.
   */
  public replaceSpecificConfigs(sourceReference: ConfigReference, targetReference: ConfigReference) {
    this.row.worksheet.setAllConfigs(sourceReference, targetReference);
  }

  /**
   * Sets the config reference for this view model.
   * @param reference The config reference.
   */
  public setConfig(reference: ConfigReference) {
    if(this.populated){
      // Destroy the old populated config view model.
      this.populated.destroy(this.underlyingData);
    }

    if(reference){
      // Create a new populated config view model.
      this.populated = new PopulatedConfigViewModel(
        this,
        this.configType,
        {
          configType: this.unpopulated.configType,
          reference,
          inheritReference: false,
        });
      this.populated.update(this.underlyingData);
    } else {
      // Clear the populated config view model.
      this.populated = undefined;
      this.matchesSelectedConfig = false;
    }
  }

  /**
   * Gets the config reference for this view model.
   */
  public get reference(): ConfigReference | undefined {
    if(this.populated) {
      return this.populated.reference;
    }

    return undefined;
  }

  /**
   * Gets the resolved reference for this view model.
   */
  public get resolvedReference(): ConfigResolvedReference | StudyResolvedReference | undefined {
    if(this.populated) {
      return this.populated.resolvedReference;
    }

    return undefined;
  }

  /**
   * Gets the outline for this view model, which can be used for posting the worksheet
   * back to the API.
   * @returns The outline.
   */
  public getOutline(): WorksheetConfig {
    if(!this.populated) {
      throw new Error('Cannot create outline of unpopulated config.');
    }

    return {
      configType: this.unpopulated.configType,
      reference: this.populated.reference,
      inheritReference: this.populated.inheritReference,
    };
  }

  /**
   * Gets whether the config is resolved.
   */
  public get isResolved(): boolean {
    return !!(this.populated && this.populated.resolvedReference && this.populated.resolvedReference.data);
  }

  /**
   * Gets whether the config resolving has errored.
   */
  public get isErrored(): boolean {
    return !!(this.populated && this.populated.resolvedReference && this.populated.resolvedReference.error);
  }

  /**
   * Gets whether the config is a required input of the currently selected study on the row.
   */
  public get studyInputNecessity(): StudyInputNecessity {
    return this.row.study.getStudyInputNecessity(this.unpopulated.configType);
  }

  /**
   * Tells the worksheet to highlight all configs that match the config referenced in this view model.
   */
  public setItemsMatching() {
    this.row.worksheet.setItemsMatching(this.reference);
  }

  /**
   * Tells the worksheet to clear all highlighted items.
   */
  public clearItemsMatching() {
    this.row.worksheet.clearItemsMatching();
  }
}

/**
 * The view model for a populated config.
 */
export class PopulatedConfigViewModel {

  /**
   * The resolved reference for this config. If the config type is telemetry, this will
   * be the resolved study reference that the telemetry config references.
   * If the config type is not telemetry, the resolved reference will be the same as `configResolvedReference`.
   */
  public resolvedReference: ConfigResolvedReference | StudyResolvedReference | undefined;

  /**
   * The config resolved reference for this config. If the config type is telemetry this will
   * be the resolved config (rather than the resolved study the telemetry config references).
   * If the config type is not telemetry, this will be the same as `resolvedReference`.
   */
  public configResolvedReference: ConfigResolvedReference | undefined;

  /**
   * The resolved labels for this config.
   */
  public resolvedLabels: ReadonlyArray<ResolvedLabel> = [];

  /**
   * The owner of the config.
   */
  public owner: UserInformation | undefined;

  /**
   * The name of the owner of the config.
   */
  public ownerName: string;

  /**
   * The populated study view model for the study that this config references,
   * if it is a telemetry config.
   */
  public populatedStudy: PopulatedStudyViewModel;

  /**
   * Creates an instance of PopulatedConfigViewModel.
   * @param parent The parent config view model.
   * @param configType The config type.
   * @param config The config metadata as returned from the API.
   */
  constructor(
    private readonly parent: ConfigViewModel,
    private readonly configType: DocumentSubType,
    private readonly config: WorksheetConfig) {
  }

  /**
   * Destroys the populated config view model.
   * @param underlyingData The underlying data.
   */
  public destroy(underlyingData: WorksheetUnderlyingData){
    underlyingData.referencesMetadata.decrementConfig(this.parent);
  }

  /**
   * Updates the populated config view model using the given underlying data.
   * @param underlyingData The underlying data.
   */
  public update(underlyingData: WorksheetUnderlyingData) {
    underlyingData.referencesMetadata.incrementConfig(this.parent);

    this.resolvedLabels = [];
    switch(this.configType){
      case DocumentSubType.telemetry:
        // Populate the telemetry config data.
        this.resolvedReference = underlyingData.studyResolvedReferences.get(this.config.reference);
        this.configResolvedReference = underlyingData.configResolvedReferences.get(this.config.reference);
        const studyResolvedLabels = underlyingData.studyResolvedLabels.get(this.config.reference);
        if(studyResolvedLabels){
          const simulationResolvedLabels = studyResolvedLabels.simulationLabels.find(v => v.simType === SimType.Telemetry);
          if(simulationResolvedLabels){
            this.resolvedLabels = simulationResolvedLabels.resolvedLabels;
          }
        }
        this.populatedStudy = new PopulatedStudyViewModel(undefined, referenceAsStudy(this.config.reference));
        this.populatedStudy.update(underlyingData);
        break;

      default:
        // Populate the non-telemetry config data.
        this.resolvedReference =
          this.configResolvedReference = underlyingData.configResolvedReferences.get(this.config.reference);
        const configResolvedLabels = underlyingData.configResolvedLabels.get(this.config.reference);
        if(configResolvedLabels){
          this.resolvedLabels = configResolvedLabels.resolvedLabels;
        }
        break;
    }

    // Populate the owner information.
    this.ownerName = UNKNOWN_USER;
    if (this.resolvedReference && this.resolvedReference.data) {
      this.owner = underlyingData.users[this.resolvedReference.data.userId];
      if (this.owner) {
        this.ownerName = this.owner.username;
      }
    }
  }

  /**
   * Gets the config reference for this view model.
   */
  public get reference(): ConfigReference {
    return this.config.reference;
  }

  /**
   * Gets whether the config inherits the reference from next populated row up in the worksheet.
   * Note this feature is supported on the back-end, but we do not expose any front-end functionality
   * to allow users to use this feature.
   */
  public get inheritReference(): boolean {
    return this.config.inheritReference;
  }
}

/**
 * The view model for an unpopulated config.
 */
export class UnpopulatedConfigViewModel {

  /**
   * The config type name (e.g. `Car` for a car config).
   */
  public configTypeName: string;

  /**
   * The plural key for the config type (e.g. `cars` for a car config).
   */
  public configTypePluralKey: string;

  /**
   * Creates an instance of UnpopulatedConfigViewModel.
   * @param configType The config type.
   * @param underlyingData The worksheet underlying data.
   */
  constructor(
    public readonly configType: DocumentSubType,
    private underlyingData: WorksheetUnderlyingData){

    this.configTypeName = this.underlyingData.getConfigTypeName(this.configType);
    this.configTypePluralKey = this.underlyingData.getConfigTypePluralKey(this.configType);
  }

  /**
   * Updates the unpopulated config view model using the given underlying data.
   * @param underlyingData The underlying data.
   */
  public update(underlyingData: WorksheetUnderlyingData) {
    this.underlyingData = underlyingData;
  }
}

import { ChannelInterpolator } from './channel-interpolator';
import { ChannelInterpolatorResults } from './channel-interpolator-results';
import { InterpolationCoordinates } from './interpolation-coordinates';
import { interpolateRange } from './interpolate-range';
import { interpolateFactorialPoint } from './interpolate-factorial-point';
import { ChannelInterpolatorExplorationBase } from './channel-interpolator-exploration-base';
import { StudyExplorationAndScalarData } from '../load-study-exploration-and-scalar-data';

/**
 * An interpolator for factorial designs.
 */
export class ChannelInterpolatorFactorial extends ChannelInterpolatorExplorationBase implements ChannelInterpolator {

  /**
   * Initializes a new instance of ChannelInterpolatorFactorial.
   * @param explorationAndScalarData The exploration and scalar data.
   */
  constructor(private readonly explorationAndScalarData: StudyExplorationAndScalarData) {
    super();
  }

  /**
   * @inheritdoc
   */
  public initialize() {
  }

  /**
   * @inheritdoc
   */
  public execute(
    channelNames: ReadonlyArray<string>,
    interpolationCoordinates: InterpolationCoordinates): ChannelInterpolatorResults {

    let map = this.explorationAndScalarData.explorationMap;
    let scalarData = this.explorationAndScalarData.scalarData;

    let channelDataList = this.createScalarDataList(scalarData, channelNames);

    // For each set of coordinates from interpolationCoordinates, call interpolateFactorialPoint.
    return interpolateRange(
      channelNames,
      (rPoint) => interpolateFactorialPoint(map, channelDataList, rPoint),
      interpolationCoordinates);
  }
}

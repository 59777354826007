import { Component, OnInit } from '@angular/core';
import {DialogBase, DialogManager} from '../../common/dialogs/dialog-manager.service';
import {
  EditDocumentMetadataDialogSession,
  EditDocumentMetadataDialogSessionFactory
} from './edit-document-metadata-session';
import {
  EditDocumentMetadataDialogData,
  EditDocumentMetadataResult
} from './edit-document-metadata-dialog.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'cs-edit-document-metadata-dialog',
  templateUrl: './edit-document-metadata-dialog.component.html',
  styleUrls: ['./edit-document-metadata-dialog.component.scss']
})
export class EditDocumentMetadataDialogComponent implements OnInit {
  public isVisible: boolean = false;

  public dialog: DialogBase<EditDocumentMetadataResult>;
  public session: EditDocumentMetadataDialogSession;
  public sessionSubscription: Subscription;

  constructor(
    private sessionFactory: EditDocumentMetadataDialogSessionFactory,
    private dialogManager: DialogManager) {
  }

  public ngOnInit(): any {
    this.dialogManager.secondaryChanged.subscribe(() => this.dialogManagerChanged());
  }

  public accept(result: EditDocumentMetadataResult) {
    if(this.dialog) {
      this.dialog.resolve(result);
    }
  }

  public dismiss() {
    if(this.dialog){
      this.dialog.dismiss();
    }
  }

  public dialogManagerChanged() {
    this.dismiss();

    let currentDialog = this.dialogManager.getSecondary();

    if(this.sessionSubscription){
      this.sessionSubscription.unsubscribe();
      this.sessionSubscription = undefined;
    }

    if(currentDialog && currentDialog instanceof EditDocumentMetadataDialogData){
      this.isVisible = true;
      this.dialog = <EditDocumentMetadataDialogData>currentDialog;
      this.session = this.sessionFactory.create(<EditDocumentMetadataDialogData>currentDialog);
      this.sessionSubscription = this.session.accepted.subscribe((result: EditDocumentMetadataResult) => this.accept(result));
    } else {
      this.isVisible = false;
      this.session = undefined;
      this.dialog = undefined;
    }
  }
}

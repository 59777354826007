import * as d3 from '../../../d3-bundle';
import { IPosition } from '../../position';
import { isNumber } from '../../../is-number';
import { ProcessedMultiPlotChannel } from './processed-multi-plot-channel';
import { QuadTreeCursorDataPoint } from './quad-tree-cursor-data-point';
import { QuadTreeItem } from './quad-tree-item';

/**
 * A channel and its X domain from a data source in a plot.
 */

export class ProcessedPlotSourceChannel {

  /**
   * A quad tree for the channel data.
   */
  private quadTree: d3.Quadtree<QuadTreeItem> | undefined;

  /**
   * The X coordinates of the channel data (after scaling).
   */
  public xCoordinates: number[] = [];

  /**
   * The Y coordinates of the channel data (after scaling).
   */
  public yCoordinates: number[] = [];

  /**
   * Creates a new ProcessedPlotSourceChannel.
   * @param plotIndex The index of the plot.
   * @param sourceIndex The index of the data source.
   * @param xChannel The X channel.
   * @param yChannel The Y channel.
   */
  constructor(
    public readonly plotIndex: number,
    public readonly sourceIndex: number,
    public readonly xChannel: ProcessedMultiPlotChannel,
    public readonly yChannel: ProcessedMultiPlotChannel) {
  }

  /**
   * Called when the plot is rescaled. Re-calculates X and Y coordinates and resets the quad tree.
   * @param xScale The new X scale.
   * @param yScale The new Y scale.
   */
  public onRescaled(xScale: d3.ScaleLinear<number, number>, yScale: d3.ScaleLinear<number, number>) {
    const xSource = this.xChannel.sources[this.sourceIndex];
    if (xSource && xSource.data) {
      this.xCoordinates = xSource.data.map(v => xScale(v));
    } else {
      this.xCoordinates = [];
    }

    const ySource = this.yChannel.sources[this.sourceIndex];
    if (ySource && ySource.data) {
      this.yCoordinates = ySource.data.map(v => yScale(v));
    } else {
      this.yCoordinates = [];
    }

    this.quadTree = undefined;
  }

  /**
   * Gets the closest point to a given position in the channel data.
   * Lazily creates the quad tree if it wasn't already created.
   * @param internalPosition The internal position to find the closest point to.
   * @returns The closest point in the channel data, or undefined if no point was found.
   */
  public getClosestPoint(internalPosition: IPosition): QuadTreeCursorDataPoint | undefined {
    if (!this.quadTree) {

      this.quadTree = d3.quadtree<QuadTreeItem>()
        .x(v => v.x)
        .y(v => v.y)
        .addAll(d3.zip(this.xCoordinates, this.yCoordinates)
          .map((v, i) => ({
            x: v[0],
            y: v[1],
            index: i
          }))
          .filter(v => isNumber(v.x) && isNumber(v.y))); // Filter must be after map to retain data index.
    }

    let found = this.quadTree.find(internalPosition.x, internalPosition.y);
    if (found) {
      return new QuadTreeCursorDataPoint(found.x, found.y, found.index, this.yChannel, this.plotIndex, this.sourceIndex);
    }

    return undefined;
  }
}

import {Injectable} from '@angular/core';
import {ConfirmationDialog} from '../dialogs/confirmation-dialog.service';
import {UntypedFormGroup} from '@angular/forms';

export const CONFIRM_DIALOG_MESSAGE = 'Form has changes. Are you sure you want to navigate away?';

@Injectable()
export class FormCancellationHandler {
  constructor(private confirmationDialog: ConfirmationDialog) {
  }

  public async execute(form: UntypedFormGroup): Promise<boolean> {
    if (!form.dirty) {
      return true;
    }

    return !!(await this.confirmationDialog.show(CONFIRM_DIALOG_MESSAGE));
  }
}


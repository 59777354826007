import { TransientMultiPlotBase } from './transient-multi-plot-base';

/**
 * Transient data for a multi-plot side.
 */

export class TransientMultiPlotSide extends TransientMultiPlotBase {

  /**
   * Whether the side is hidden.
   */
  public isHidden: boolean = false;
}

<div class="view-studies-component">
  <cs-error-message [message]="errorMessage"></cs-error-message>
  @if (!studies) {
    <p>
      <cs-loader></cs-loader>
    </p>
  }
  @if (studies) {
    <div>
      <cs-list-filter [initialFilter]="filter"
        [loadGroupResults]="getLoadGroupResultsDelegate"
        [tenantId]="userData.tenant"
        [disableForm]="isReloading"
        [canPersistFilter]="canPersistFilter"
        [filterConfigType]="'studyFilter'"
      (filterChanged)="filterChanged($event)"></cs-list-filter>
      <div class="filtered-list">
        <table id="studies-table" class="table studies-table last-item" (copy)="onCopy($event)">
          <thead>
            <tr>
              @if (canNavigate) {
                <th class="icon-cell"></th>
              }
              <th>
                Name
                <a class="toggle-properties-button" href="javascript:void(0)" (click)="toggleProperties()">{{showProperties ? 'Hide' : 'Show'}} Properties</a>
              </th>
              <th class="created-column">Created</th>
              <th>Jobs</th>
              <th>Time</th>
              <th>Type</th>
              <th class="hidden-md-down">Owner</th>
              <th>
                <button id="select-all-studies-button" (click)="toggleSelectAll()"
                  class="btn btn-secondary btn-icon btn-icon-only btn-xsm d-inline-block">
                  @if (!canSelectAll) {
                    <i class="fa fa-check-square-o"></i>
                  }
                  @if (canSelectAll) {
                    <i class="fa fa-square-o"></i>
                  }
                </button>
                <button id="delete-studies-button" (click)="deleteSelected()"
                  class="btn btn-secondary btn-icon btn-xsm">
                  @if (!isDeleting && !shouldUndelete) {
                    <i class="fa fa-trash-o"></i>
                  }
                  @if (!isDeleting && shouldUndelete) {
                    <i class="fa fa-undo"></i>
                  }
                  @if (isDeleting) {
                    <i class="fa fa-spinner fa-spin"></i>
                  }
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            @for (study of studies; track trackById(index, study); let index = $index) {
              <tr>
                @if (canNavigate && !study.isCompleted) {
                  <td class="icon-cell" ></td>
                }
                @if (canNavigate && study.isCompleted) {
                  <td class="icon-cell button-menu-hover-target button-menu-hover-target-{{index}} button-menu-hover-target-name-{{cssSanitize(study.name)}}">
                    <button class="btn btn-smart btn-secondary btn-icon btn-xsm study-hover-button-{{index}} study-hover-name-{{cssSanitize(study.name)}}">
                      <i class="fa fa-ellipsis-h"></i>
                    </button>
                    <span class="button-menu-hovering-area">
                      <button type="button" class="btn btn-smart btn-secondary"
                        (click)="stageToResultsStagingArea(study)">
                        <i class="fa fa-area-chart"></i>Compare
                      </button>
                      <cs-stage-all-inputs [tenantId]="study.tenantId" [studyId]="study.studyId"></cs-stage-all-inputs>
                      @if (significantSimVersions.isAfterTelemetrySimulation) {
                        <button type="button" class="btn btn-smart btn-secondary"
                          (click)="stageToStudyStagingArea(study)">
                          <i class="fa fa-arrow-circle-up"></i><cs-stage-as-telemetry-text [studyType]="study.studyType"></cs-stage-as-telemetry-text>
                        </button>
                      }
                      <button type="button" class="btn btn-smart btn-secondary"
                        (click)="goToJob(study)">
                        <i class="fa fa-cube"></i>Go To Job
                      </button>
                      <cs-linting-engine-dialog-launcher
                        [tenantId]="study.tenantId"
                        [userId]="study.userId"
                      [studyId]="study.studyId"></cs-linting-engine-dialog-launcher>
                      <cs-set-ownership-dialog-launcher
                        [documentTenantId]="study.tenantId"
                        [documentUserId]="study.userId"
                        [documentId]="study.studyId"
                      (ownershipChanged)="reloadFilter()"></cs-set-ownership-dialog-launcher>
                      <cs-download-buttons
                        [tenantId]="study.tenantId"
                        [studyId]="study.studyId"
                        [studyName]="study.name"
                        [jobCount]="study.jobCount"
                      [isCompleted]="study.isCompleted"></cs-download-buttons>
                    </span>
                  </td>
                }
                <td>
                  @if (canNavigate) {
                    <a
                      class="select-study-link name-{{cssSanitize(study.name)}} username-{{cssSanitize(study.username)}} overflow-ellipsis"
                      [routerLink]="['/studies', study.tenantId, study.studyId]"
                      title="{{study.name}}">
                      {{renderWithCustomLineBreaks(study.name)}}
                    </a>
                  }
                  @if (!canNavigate) {
                    <a
                      class="select-study-link name-{{cssSanitize(study.name)}} username-{{cssSanitize(study.username)}} overflow-ellipsis"
                      (click)="onStudySelected(study)"
                      href="javascript:void(0)"
                      title="{{study.name}}">
                      {{renderWithCustomLineBreaks(study.name)}}
                    </a>
                  }
                  @if (study.deleteRequested) {
                    <div class="config-study-additional-information">Delete Requested</div>
                  }
                  @if (study.parentWorksheetId) {
                    <div class="config-study-additional-information"><a [routerLink]="['/worksheets', study.tenantId, study.parentWorksheetId]">Worksheet</a></div>
                  }
                  @if (showProperties) {
                    <div class="custom-property-names">
                      @for (property of study.properties; track property) {
                        <div>
                          <small title="{{property.name}}" class="overflow-ellipsis">{{property.name}}</small>
                        </div>
                      }
                    </div>
                  }
                </td>
                <td class="created-column custom-property-values-column">
                  @if (study.isBuilding) {
                    <div>
                      @if (study.dispatchedJobCount) {
                        <span>
                          <span class="progress-icon"><i class="fa fa-cloud-upload"></i></span>
                          <div class="progress">
                            <div class="progress-bar" [ngStyle]="{ 'width': (100 * study.dispatchedJobCount / study.jobCount) + '%' }">
                            </div>
                          </div>
                        </span>
                      }
                      @if (!study.dispatchedJobCount) {
                        <span>
                          @if (study.jobCount) {
                            <span>
                              Building...
                            </span>
                          }
                          @if (!study.jobCount) {
                            <span>
                              Waiting...
                            </span>
                          }
                        </span>
                      }
                    </div>
                  }
                  @if (!study.isBuilding) {
                    <span title="{{study.creationDateRaw}}">
                      {{study.creationDate}}
                    </span>
                  }
                  @if (showProperties) {
                    <div class="custom-property-values">
                      @for (property of study.properties; track property) {
                        <div>
                          <small title="{{property.value}}">{{property.value}}</small>
                        </div>
                      }
                    </div>
                  }
                </td>
                <td class="job-count-column">
                  <div class="job-count-area">
                    <cs-view-credits class="view-credits-component" [computeCredits]="study.succeededComputeCredits" [storageCredits]="study.succeededStorageCredits"></cs-view-credits>
                    <cs-study-jobs-summary
                      [tenantId]="study.tenantId"
                      [studyId]="study.studyId"
                      [jobCount]="study.jobCount"
                      [completedJobCount]="study.completedJobCount"
                      [succeededJobCount]="study.succeededJobCount"
                    [canNavigate]="canNavigate"></cs-study-jobs-summary>
                  </div>
                </td>
                <td>
                  @if (study.isCompleted || study.hasErrors) {
                    <span id="time-name-{{cssSanitize(study.name)}}">{{study.executionTime }}</span>
                  }
                  @if (!study.isCompleted && !study.hasErrors) {
                    <div>
                      @if (!study.isCompleted) {
                        <div class="progress">
                          <div class="progress-bar" [ngStyle]="{ width: (100 * study.completedJobCount / study.jobCount) + '%' }"
                            [ngClass]="{ 'bg-danger': study.hasErrors || study.completedJobCount !== study.succeededJobCount }">
                          </div>
                        </div>
                      }
                    </div>
                  }
                </td>
                <td>
                  @if (canNavigate) {
                    <div class="study-type-area">
                      {{study.studyTypeName}}<a class="study-sim-version" [routerLink]="[RELEASE_NOTES_URL]">{{study.simVersion}}</a>
                    </div>
                  }
                  @if (!canNavigate) {
                    <div class="study-type-area">
                      {{study.studyTypeName}}<span class="study-sim-version">{{study.simVersion}}</span>
                    </div>
                  }
                </td>
                <td class="hidden-md-down">
                  {{study.username}}
                </td>
                <td class="icon-cell">
                  @if ((study.isOwner || isTenantAdministrator) && !study.isDeleting) {
                    <input
                      class="select-item-checkbox select-name-{{cssSanitize(study.name)}} select-username-{{cssSanitize(study.username)}}"
                      [ngClass]="{ 'input-warning': !study.isOwner }"
                      id="select-{{study.studyId}}-checkbox" type="checkbox" (change)="study.isSelected = !study.isSelected" [checked]="study.isSelected">
                  }
                  @if (study.isDeleting) {
                    <i class="fa fa-spinner fa-spin"></i>
                  }
                  <!--
                  <button id="delete-{{study.studyId}}-button" (click)="delete(study)"
                    class="btn btn-secondary btn-icon btn-xsm delete-name-{{cssSanitize(study.name)}} delete-username-{{cssSanitize(study.username)}}"
                    *ngIf="study.isOwner">
                    <i class="fa fa-trash-o" *ngIf="!study.isDeleting"></i>
                    <i class="fa fa-spinner fa-spin" *ngIf="study.isDeleting"></i>
                  </button>
                  -->
                  @if (study.supportSession.session) {
                    <cs-support-session-hover-launcher
                      [tenantId]="study.tenantId" [documentId]="study.studyId"
                      [documentType]="'study'" [documentSubType]="'definition'"
                      [supportSession]="study.supportSession"
                    (supportSessionChanged)="study.supportSession = $event"></cs-support-session-hover-launcher>
                  }
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
      <p class="table-footer-buttons">
        @if (lastResult?.queryResults?.hasMoreResults) {
          <button
            class="btn btn-smart btn-secondary"
            [disabled]="isReloading"
            (click)="loadNext()">
            {{isReloading ? 'Loading...' : 'More...'}}
          </button>
        }
      </p>
    </div>
  }
</div>

/*
 * Usage:
 *   value | toFixed:dp
 * Example:
 *   {{ 2.1234 |  toFixed:2}}
 *   formats to: 2.12
 */
import {Pipe, PipeTransform} from '@angular/core';
import {Units} from '../visualizations/units';
import {ToFixedPipe} from './to-fixed.pipe';

const toFixed = new ToFixedPipe();

@Pipe({name: 'units'})
export class UnitsPipe implements PipeTransform {
  transform(value: any, targetUnits: string, sourceUnits: string, decimalPlaces: number, minimumDecimalPlaces: number = 0): any {
    if(typeof value !== 'number'){
      return value;
    }

    let converted = Units.convertValueBetweenUnits(value, sourceUnits, targetUnits);
    return toFixed.transform(converted, decimalPlaces, minimumDecimalPlaces) + (!targetUnits || targetUnits === '()' ? '' : ' ' + targetUnits);
  }
}

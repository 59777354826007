import { FeatureChannelNames } from './types/feature-channel-names';
import { FeatureChannels } from './types/feature-channels';
import { ViewerChannelDataMap } from '../channel-data-loaders/viewer-channel-data-map';
import { GetSiFeatureChannel } from './get-si-feature-channel';


export class GetSourceFeatureChannels {
  constructor(
    private readonly getSiFeatureChannel: GetSiFeatureChannel) {
  }

  public execute(dataMap: ViewerChannelDataMap, featureChannelNames: FeatureChannelNames): FeatureChannels {
    let integrationChannel = this.getSiFeatureChannel.execute(dataMap, featureChannelNames.integrationChannel);
    let splitChannel = this.getSiFeatureChannel.execute(dataMap, featureChannelNames.splitChannel);

    if (integrationChannel && !integrationChannel.isMonotonic) {
      console.warn('Integration channel ' + integrationChannel.fullName + ' is not monotonic.');
    }

    return new FeatureChannels(integrationChannel, splitChannel);
  }
}


/**
 * Represents a job with indices.
 */
interface AbstractJobWithIndices {
  readonly sweeps: ReadonlyArray<{
    readonly subSweepIndex: number;
  }>;
}

/**
 * Compares two jobs based on their factorial positions.
 */
export class CompareJobFactorialPositions {

  /**
   * Compares two jobs based on their factorial positions.
   * @param jobA The first job to compare.
   * @param jobB The second job to compare.
   * @returns -1 if jobA is before jobB, 1 if jobA is after jobB, and 0 if they are equal.
   */
  public static execute(jobA: AbstractJobWithIndices, jobB: AbstractJobWithIndices) {
    for (let i = jobA.sweeps.length - 1; i >= 0; --i) {
      const result = jobA.sweeps[i].subSweepIndex - jobB.sweeps[i].subSweepIndex;

      if (result !== 0) {
        return result < 0 ? -1 : 1;
      }
    }

    return 0;
  }
}

import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {EditDocumentMetadataDialogData, EditDocumentMetadataResult,} from './edit-document-metadata-dialog.service';
import {EventEmitter, Injectable} from '@angular/core';
import {DocumentMetadata} from '../edit-document-metadata/edit-document-metadata.component';

@Injectable()
export class EditDocumentMetadataDialogSessionFactory {
  constructor(
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog){}

  public create(data: EditDocumentMetadataDialogData): EditDocumentMetadataDialogSession{
    return new EditDocumentMetadataDialogSession(
      data,
      this.getFriendlyErrorAndLog);
  }
}

export class EditDocumentMetadataDialogSession {
  public errorMessage: string;

  public accepted: EventEmitter<EditDocumentMetadataResult> = new EventEmitter<EditDocumentMetadataResult>();

  constructor(
    public readonly dialog: EditDocumentMetadataDialogData,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  public async onMetadataChanged(documentMetadata: DocumentMetadata){
    try {
      this.accepted.emit(new EditDocumentMetadataResult(documentMetadata));
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }
}

import {Injectable} from '@angular/core';
import {Dayjs} from '../common/dayjs.service';
import {UsernameMap} from '../simulations/get-username-map.service';
import {distinct} from '../common/distinct';
import {ManipulateType} from 'dayjs';

export const RawStatisticsPeriodItemKeys: (keyof RawStatisticsPeriodItem)[] = [
  'studyCount',
  'failedStudyCount',
  'completedSimulations',
  'succeededSimulations',
  'succeededComputeCredits',
  'succeededStorageCredits',
  'failedSimulations',
  'executionTimeSeconds'];

@Injectable()
export class TenantStudyStatisticsUtilities {

  constructor(
    private dayjs: Dayjs) {
  }

  public mergeRawStatistics(a: RawStatistics, b: RawStatistics): RawStatistics {
    const createDefault = () => ({ tenant: undefined, user: {}, studyType: {}} as RawStatistics);
    a = a || createDefault();
    b = b || createDefault();

    let result: RawStatistics = {
      tenant: this.mergeRawStatisticsAreaKey(a.tenant, b.tenant),
      user: this.mergeRawStatisticsArea(a.user, b.user),
      studyType: this.mergeRawStatisticsArea(a.studyType, b.studyType),
    };

    return result;
  }

  public mergeRawStatisticsArea(a: {[key: string]: RawStatisticsPeriod}, b: {[key: string]: RawStatisticsPeriod}): {[key: string]: RawStatisticsPeriod} {
    a = a || {};
    b = b || {};

    let result: {[key: string]: RawStatisticsPeriod} = {};
    let keys: string[] = distinct([
      ...Object.keys(a),
      ...Object.keys(b)
    ]);

    for(let key of keys){
      result[key] = this.mergeRawStatisticsAreaKey(a[key], b[key]);
    }

    return result;
  }

  public mergeRawStatisticsAreaKey(a: RawStatisticsPeriod, b: RawStatisticsPeriod): RawStatisticsPeriod {
    let period: RawStatisticsPeriod = {
      monthly: {
        ...(a ? a.monthly : {}),
        ...(b ? b.monthly : {})
      },
      daily: {
        ...(a ? a.daily : {}),
        ...(b ? b.daily : {})
      }
    };

    return period;
  }

  public getProcessedStatistics(statistics: RawStatistics, dateStrings: string[], periodKey: keyof RawStatisticsPeriod, usernameMap: UsernameMap): ProcessedStatisticsForPeriodType{
    let result: ProcessedStatisticsForPeriodType = {
      dates: dateStrings,
      tenant: this.getProcessedPeriodItems(dateStrings, statistics.tenant ? statistics.tenant[periodKey] : {}),
      user: {},
      studyType: {}
    };

    for(let user of this.getKeys(statistics.user)){
      result.user[usernameMap[user] || user] = this.getProcessedPeriodItems(dateStrings, statistics.user[user][periodKey]);
    }

    for(let studyType of this.getKeys(statistics.studyType)){
      result.studyType[studyType] = this.getProcessedPeriodItems(dateStrings, statistics.studyType[studyType][periodKey]);
    }

    return result;
  }

  public getProcessedPeriodItems(dateStrings: string[], periodSet: RawStatisticsPeriodSet){
    return dateStrings.map(date => {
      let periodItem = periodSet[date];
      if(!periodItem){
        periodItem = this.createEmptyPeriodItem();
      } else {
        periodItem.failedSimulations = periodItem.completedSimulations - periodItem.succeededSimulations;
      }

      return periodItem;
    });
  }

  public createEmptyPeriodItem(): RawStatisticsPeriodItem {
    return <RawStatisticsPeriodItem>RawStatisticsPeriodItemKeys.reduce<any>((p, c) => {
 p[c] = 0; return p;
}, {});
  }

  public getDatesForRange(min: string, max: string, format: string, intervalType: ManipulateType): string[] {
    let start = this.dayjs.create(min);
    let end = this.dayjs.create(max);
    let result = [];

    let current = start;
    while(current <= end){
      result.push(current.format(format));
      current = current.add(1, intervalType);
    }

    return result;
  }

  public getDateRanges(statistics: RawStatistics, fromNow: boolean = true): DateRanges {
    let months: string[] = [];
    let days: string[] = [];

    if(statistics.tenant){
      months.push(...this.getStringKeys(statistics.tenant.monthly));
      days.push(...this.getStringKeys(statistics.tenant.daily));

      for(let user of this.getStringKeys(statistics.user)){
        months.push(...this.getStringKeys(statistics.user[user].monthly));
        days.push(...this.getStringKeys(statistics.user[user].daily));
      }
      for(let studyType of this.getKeys(statistics.studyType)){
        months.push(...this.getStringKeys(statistics.studyType[studyType].monthly));
        days.push(...this.getStringKeys(statistics.studyType[studyType].daily));
      }
    }

    if(fromNow){
      let now = this.dayjs.create();
      // Always add the current day/month as we want graphs to be from this point.
      months.push(now.format('YYYY-MM'));
      days.push(now.format('YYYY-MM-DD'));

      // Add the previous day/month so we always have a range.
      now.add(-1, 'd');
      months.push(now.format('YYYY-MM'));
      days.push(now.format('YYYY-MM-DD'));
    }

    months.sort();
    days.sort();

    return {
      minMonth: months[0],
      maxMonth: months[months.length - 1],
      minDay: days[0],
      maxDay: days[days.length - 1],
    };
  }

  public getKeys<T>(input: T): (keyof T)[] {
    let keys = [];
    for(let key in input){
      if(!input.hasOwnProperty(key)){
        continue;
      }
      keys.push(key);
    }
    keys.sort();
    return keys;
  }

  public getStringKeys(input: any): string[] {
    return this.getKeys(input) as string[];
  }
}

export interface DateRanges {
  minMonth: string;
  maxMonth: string;
  minDay: string;
  maxDay: string;
}

export interface ProcessedStatisticsForPeriodType {
  dates: string[];
  tenant: RawStatisticsPeriodItem[];
  user: {
    [userId: string]: RawStatisticsPeriodItem[];
  };
  studyType: {
    [studyType: string]: RawStatisticsPeriodItem[];
  };
}

export interface AllTenantsProcessedStatisticsForPeriodType {
  dates: string[];
  all: RawStatisticsPeriodItem[];
  tenant: {
    [tenantId: string]: RawStatisticsPeriodItem[];
  };
  user: {
    [userId: string]: RawStatisticsPeriodItem[];
  };
  studyType: {
    [studyType: string]: RawStatisticsPeriodItem[];
  };
}

export interface ProcessedStatistics {
  monthly: ProcessedStatisticsForPeriodType;
  daily: ProcessedStatisticsForPeriodType;
}

export interface AllTenantsProcessedStatistics {
  monthly: AllTenantsProcessedStatisticsForPeriodType;
  daily: AllTenantsProcessedStatisticsForPeriodType;
}

export interface RawStatisticsPeriodItem {
  studyCount: number;
  failedStudyCount: number;
  completedSimulations: number;
  succeededSimulations: number;
  failedSimulations: number;
  succeededComputeCredits: number;
  succeededStorageCredits: number;
  executionTimeSeconds: number;
}

export interface RawStatisticsPeriodSet {
  [date: string]: RawStatisticsPeriodItem;
}

export interface RawStatisticsPeriod {
  monthly: RawStatisticsPeriodSet;
  daily: RawStatisticsPeriodSet;
}

export interface RawStatistics {
  tenant: RawStatisticsPeriod;
  user: {
    [userId: string]: RawStatisticsPeriod;
  };
  studyType: {
    [studyType: string]: RawStatisticsPeriod;
  };
}

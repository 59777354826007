
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {StudyLoaderDialogData, StudyLoaderResult} from './study-loader-dialog.service';
import {Injectable, EventEmitter} from '@angular/core';
import {GetStudiesQueryResult, ListFilterGroup} from '../../../generated/api-stubs';
import {Timer} from '../../common/timer.service';
import {FilterUtilities} from '../../simulations/list-filtering/filter-utilities';
import {StudySummary} from '../../simulations/studies/view-studies/view-studies.component';

/**
 * Factory for creating the study loader dialog session.
 */
@Injectable()
export class StudyLoaderDialogSessionFactory {

  /**
   * Creates an instance of StudyLoaderDialogSessionFactory.
   * @param filterUtilities The filter utilities service.
   * @param timer The timer service.
   * @param getFriendlyErrorAndLog The service to get a friendly error message and log the error.
   */
  constructor(
    private filterUtilities: FilterUtilities,
    private timer: Timer,
    private getFriendlyErrorAndLog: GetFriendlyErrorAndLog
  ){}

  /**
   * Creates the study loader dialog session.
   * @param data The data for the dialog.
   */
  public create(data: StudyLoaderDialogData): StudyLoaderDialogSession{
    return new StudyLoaderDialogSession(
      data,
      this.filterUtilities,
      this.timer,
      this.getFriendlyErrorAndLog);
  }
}

/**
 * The study loader dialog session.
 */
export class StudyLoaderDialogSession {

  /**
   * The error message to display if an error occurs.
   */
  public errorMessage: string;

  /**
   * The initial filter for the list of studies.
   */
  public initialQuery: ListFilterGroup;

  /**
   * The last result of the query for the list of studies.
   */
  public lastResult: GetStudiesQueryResult;

  /**
   * The event emitter for when a study is selected.
   */
  public studySelected: EventEmitter<StudyLoaderResult> = new EventEmitter<StudyLoaderResult>();

  /**
   * Whether the study loader is loading.
   */
  public isLoading: boolean;

  /**
   * Creates an instance of StudyLoaderDialogSession.
   * @param dialog The data for the dialog.
   * @param filterUtilities The filter utilities service.
   * @param timer The timer service.
   * @param getFriendlyErrorAndLog The service to get a friendly error message and log the error.
   */
  constructor(
    private dialog: StudyLoaderDialogData,
    private filterUtilities: FilterUtilities,
    private timer: Timer,
    private getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  /**
   * Loads the study loader dialog data.
   */
  public async load() {
    try {
      this.errorMessage = undefined;
      if(this.dialog.initialQuery){
        this.initialQuery = this.dialog.initialQuery;
      } else {
        this.initialQuery = this.filterUtilities.getInitialFilter().query;
      }
      await this.timer.yield();
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  /**
   * Handles the selection of a study.
   * @param study The study that was selected.
   */
  public async onStudySelected(study: StudySummary) {
    try {
      this.isLoading = true;
      let result: StudyLoaderResult = {
        userId: study.userId,
        studyId: study.studyId,
        name: study.name,
      };

      this.studySelected.emit(result);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
    this.isLoading = false;
  }
}

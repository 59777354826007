@if (isLoading) {
  <div class="route-resolving-indicator">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <cs-loader>Loading...</cs-loader>
        </div>
      </div>
    </div>
  </div>
}

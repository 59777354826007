import { UntypedFormControl } from '@angular/forms';
import { FormBuilderModel } from '../../../common/components/form-builder/form-builder.model';

export class EditConfigPermissionModel extends FormBuilderModel {

  public get encryptingTenantShortName(): UntypedFormControl {
    return this.getFormControl('encryptingTenantShortName');
  }

  public get decryptingTenantShortName(): UntypedFormControl {
    return this.getFormControl('decryptingTenantShortName');
  }

  public get minimumSimVersion(): UntypedFormControl {
    return this.getFormControl('minimumSimVersion');
  }

  public get description(): UntypedFormControl {
    return this.getFormControl('description');
  }

  /**
   * Patches the value of the model's form group
   * @param value
   */
  public patchValue(value: { [key: string]: any }): void {
    this._form.patchValue(value);

    if (!!(this.encryptingTenantShortName.value && this.decryptingTenantShortName.value)) {
      this.encryptingTenantShortName.disable();
      this.decryptingTenantShortName.disable();
    }
  }

}

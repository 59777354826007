import { GetMonotonicStatus } from '../get-monotonic-status';
import { MonotonicStatus } from '../viewer-channel-data';
import { ProcessedExplorationMapValues } from './exploration-map-values';
import { GetExplorationSubSweepNormalizedValues } from './get-exploration-sub-sweep-normalized-values';
import { GetExplorationSubSweepDataType } from './get-exploration-sub-sweep-data-type';
import { GetExplorationSubSweepNumericValues } from './get-exploration-sub-sweep-numeric-values';

/**
 * The type of data in a sub-sweep.
 */
export enum ExplorationSubSweepDataType {
  Numeric = 'numeric',
  Boolean = 'boolean',
  Other = 'other',
}

/**
 * Represents a single sub-sweep of an exploration.
 */
export class ExplorationSubSweep {

  /**
   * The normalized values of the sub-sweep.
   */
  public readonly normalizedValues: ReadonlyArray<number>;

  /**
   * The normalized indices of the sub-sweep.
   */
  public readonly normalizedIndices: ReadonlyArray<number>;

  /**
   * The minimum value of the sub-sweep.
   */
  public readonly minimum: number = NaN;

  /**
   * The maximum value of the sub-sweep.
   */
  public readonly maximum: number = NaN;

  /**
   * The monotonic status of the sub-sweep.
   */
  public readonly monotonicStatus: MonotonicStatus = MonotonicStatus.Unknown;

  /**
   * The numeric values of the sub-sweep.
   */
  public readonly numericValues: ReadonlyArray<number>;

  /**
   * The type of data in the sub-sweep.
   */
  public readonly dataType: ExplorationSubSweepDataType;

  /**
   * The unexpanded name of the sub-sweep. For array sweeps which have been expanded into parallel numeric sub-sweeps, this
   * is the original name of the sweep before expansion.
   */
  public readonly unexpandedName: string;

  /**
   * Creates a new instance of ExplorationSubSweep.
   * @param name The name of the sub-sweep.
   * @param values The values of the sub-sweep.
   * @param unexpandedName The unexpanded name of the sub-sweep (for array sweeps expanded into parallel numeric sub-sweeps).
   */
  constructor(
    public readonly name: string,
    public readonly values: ProcessedExplorationMapValues,
    unexpandedName?: string) {

    this.unexpandedName = unexpandedName || name;

    this.dataType = GetExplorationSubSweepDataType.execute(values);
    this.numericValues = GetExplorationSubSweepNumericValues.execute(this.dataType, values);
    this.normalizedValues = GetExplorationSubSweepNormalizedValues.execute(this.numericValues);
    this.normalizedIndices = GetExplorationSubSweepNormalizedValues.execute(Array.from({ length: this.numericValues.length }, (_, key) => key));
    [this.minimum, this.maximum, this.monotonicStatus] = GetMonotonicStatus.execute(this.numericValues).spread();
  }

  /**
   * Maps from a normalized data value to a normalized index value.
   * @param normalizedValue The normalized data value.
   * @returns The normalized index value.
   */
  public mapFromDataToIndexNormalizedValue(normalizedValue: number): number {
    return this.mapFromDataToIndexOrIndexToDataNormalizedValue(normalizedValue, false);
  }

  /**
   * Maps from a normalized index value to a normalized data value.
   * @param normalizedValue The normalized index value.
   * @returns The normalized data value.
   */
  public mapFromIndexToDataNormalizedValue(normalizedValue: number): number {
    return this.mapFromDataToIndexOrIndexToDataNormalizedValue(normalizedValue, true);
  }

  /**
   * Maps between normalized data and index values. These differ if the data is not linearly spaced.
   * @param normalizedValue The normalized value.
   * @param indexToData True if the value is a normalized index value, false if it is a normalized data value.
   * @returns The mapped value.
   */
  private mapFromDataToIndexOrIndexToDataNormalizedValue(normalizedValue: number, indexToData: boolean): number {

    // If the data is not monotonic then there is no mapping we can do.
    if (this.monotonicStatus === MonotonicStatus.None) {
      return normalizedValue;
    }

    let sourceNormalizedValues = this.normalizedValues;
    const sourceNormalizedIndices = this.normalizedIndices;

    if (this.monotonicStatus === MonotonicStatus.Decreasing) {
      // If the data is monotonically decreasing, reverse it.
      sourceNormalizedValues = [...sourceNormalizedValues].reverse();

      if (indexToData) {
        // If the data is monotonically decreasing, and we're mapping from index to data, we also
        // need to mirror the normalized index.
        normalizedValue = 1 - normalizedValue;
      }
    }

    let fromValues = indexToData ? sourceNormalizedIndices : sourceNormalizedValues;
    const toValues = indexToData ? sourceNormalizedValues : sourceNormalizedIndices;

    // Find the first index after the requested value in the from data.
    const firstIndexAfter = fromValues.findIndex(v => v > normalizedValue);

    let result: number;

    if (firstIndexAfter === -1) {
      // If the value is beyond the end of the data, return 1 (the normalized end of data).
      result = 1;
    } else if (firstIndexAfter === 0) {
      // If the value is before the start of the data, return 0 (the normalized start of data).
      result = 0;
    } else {
      // Otherwise, linearly interpolate between the two closest values.
      const fromValuesValueBefore = fromValues[firstIndexAfter - 1];
      const fromValuesRange = fromValues[firstIndexAfter] - fromValuesValueBefore;

      const toValuesBefore = toValues[firstIndexAfter - 1];
      const toValuesRange = toValues[firstIndexAfter] - toValuesBefore;

      const fromValuesDistance = normalizedValue - fromValuesValueBefore;
      result = toValuesBefore + toValuesRange * (fromValuesDistance / fromValuesRange);
    }

    // If the data is monotonically decreasing, and we're mapping from index to data,
    // reverse the result (because we reversed the data at the start).
    if (this.monotonicStatus === MonotonicStatus.Decreasing && !indexToData) {
      result = 1 - result;
    }

    return result;
  }
}

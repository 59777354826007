import { IMultiPlotLayout } from './types/i-multi-plot-layout';
import { ChannelIndexMap } from './channel-index-map';
import { GetChannelIndexMapForSides } from './get-channel-index-map-for-sides';


/**
 * Creates a map of channel name to channel index for the given layout.
 */
export class GetChannelIndexMap {
  constructor(
    private readonly getChannelIndexMapForSides: GetChannelIndexMapForSides) {
  }

  /**
   * Creates a map of channel name to channel index for the given layout.
   * This is primarily used for assigning colors to channels.
   * Multiple channel names can have the same index if they are created from a
   * single channel name (e.g. delta channels have one channel per X domain).
   * @param layout
   * @returns
   */
  public execute(layout: IMultiPlotLayout): ChannelIndexMap {
    // Create an empty map.
    let map: ChannelIndexMap = {};

    // Calculate the map of channel name to index for the given rows and columns.
    let nextChannelIndex = this.getChannelIndexMapForSides.execute(map, layout.rows, layout.columns);

    if (nextChannelIndex === 0) {
      // If there are no channels, then try again but only for columns (which is the case for the parallel coordinates chart).
      this.getChannelIndexMapForSides.execute(map, layout.columns, undefined);
    }

    return map;
  }
}


import { DataService } from '../../viewers/contour-viewer/data/data-service';
import { NavigationStationConfig } from './navigation-station-config-builder';
import { SimType } from '../../sim-type';
import { StudyJob } from '../../study-job';
import { ConfigBuilderBase } from './config-builder-base';
import { RequestedLayoutIds, SiteHooks } from '../../site-hooks';
import { ChannelNameStyle } from '../../viewers/channel-data-loaders/channel-name-style';
import { SharedState } from '../../viewers/shared-state';
import { GetJobSimTypeMetadata } from '../../viewers/channel-data-loaders/get-job-sim-type-metadata';
import { UrlFileLoader } from '../../url-file-loader';
import { SourceLoaderViewModel } from '../../viewers/channel-data-loaders/source-loader-set';
import { StudyJobSourceLoader } from '../../viewers/channel-data-loaders/study-job-source-loader';
import { LinePlotViewer, POINT_MULTI_PLOT_VIEWER_TYPE } from '../../viewers/line-plot-viewer/line-plot-viewer';
import {
  LINE_CONTOUR_OVERLAY_VIEWER_TYPE,
  LineContourOverlayViewer
} from '../../viewers/contour-viewer/line-contour-overlay-viewer';
import { SUSPENSION_VIEWER_TYPE, SuspensionViewer3d } from '../../viewers/suspension-viewer/suspension-viewer-3d';
import { simVersionToNumber } from '../../sim-version-to-number';
import { ChannelDataTransform, ChannelDataTransforms } from '../../viewers/channel-data-loaders/channel-data-transforms';
import { LoadVectorMetadataMap } from '../../viewers/channel-data-loaders/load-vector-metadata-map';
import { SIM_VERSION_BEFORE_MOTOR_SPEED_CHANNEL_RENAME, getEngineAndMotorMetadata } from './get-engine-and-motor-metadata';

/**
 * Areas of the car we might want to view individually.
 */
export enum Filter {
  suspension = 1,
  tyreSlip = 2,
  suspensionKinematics= 4,
  powertrain = 8,
  all = 15
}

const SIM_VERSION_BEFORE_ASYMMETRIC_SUSPENSION_KINEMATICS = 3374;

/**
 * A config builder for car configs.
 */
export class CarConfigBuilder extends ConfigBuilderBase {

  protected getJobSimTypeMetadata: GetJobSimTypeMetadata;
  public readonly loadVectorMetadataMap: LoadVectorMetadataMap;

  /**
   * Constructs a new car config builder.
   * @param urlFileLoader The file loader.
   * @param siteHooks The site hooks.
   * @param studyJobs The study jobs for this builder session.
   * @param simTypes The sim types for this builder session.
   * @param filter The filter to apply to the views.
   * @param showContourPlot Whether to show a contour plot.
   */
  constructor(
    urlFileLoader: UrlFileLoader,
    siteHooks: SiteHooks,
    studyJobs: StudyJob[],
    simTypes: SimType[],
    protected filter: Filter = Filter.all,
    protected showContourPlot: boolean = false) {
    super(urlFileLoader, siteHooks, studyJobs, simTypes);

    this.getJobSimTypeMetadata = new GetJobSimTypeMetadata();
    this.loadVectorMetadataMap = LoadVectorMetadataMap.create(this.fileLoader, ChannelDataTransforms.none);
  }

  /**
   * @inheritdoc
   */
  public async build(): Promise<NavigationStationConfig> {

    // Create an empty navigation station config to build off.
    let config: NavigationStationConfig = {
      channelNameStyle: ChannelNameStyle.Generic,
      sharedStates: [],
      views: []
    };

    let firstStudyId = this.studyJobs[0].studyId;
    let firstJobIndex = this.studyJobs[0].jobIndex;

    let firstStudyMetadata = await this.fileLoader.loadStudyMetadata(firstStudyId);

    // Get the sim version from the first study metadata.
    let simVersion = simVersionToNumber(firstStudyMetadata.simVersion);

    // Get the sim types for the first study job.
    let simTypes = await this.getJobSimTypeMetadata.execute(firstStudyId, firstJobIndex, this.simTypes, this.loadVectorMetadataMap);
    let componentSweeps = simTypes.find(v => v.name === 'ComponentSweeps');

    let sharedState: SharedState = new SharedState();
    config.sharedStates.push(sharedState);

    let channelDataTransforms = ChannelDataTransforms.none;
    if (simVersion > SIM_VERSION_BEFORE_ASYMMETRIC_SUSPENSION_KINEMATICS) {
      channelDataTransforms = new ChannelDataTransforms(
        [
          new ChannelDataTransform(
            [
              'xRackFL',
              'xRackFR',
            ],
            'xRackF'
          ),
          new ChannelDataTransform(
            [
              'aRockerFL',
              'aRockerFR',
              'aRockerRL',
              'aRockerRR',
              'aRockerF',
              'aRockerR',
            ],
            'aRocker'
          ),
        ]);
    }

    if (componentSweeps) {
      // If we have component sweeps, add loaders for each job.
      let loaders = this.studyJobs.map(v =>
        new SourceLoaderViewModel(
          StudyJobSourceLoader.create(this.siteHooks, this.fileLoader, v, 'ComponentSweeps', channelDataTransforms),
          true));
      sharedState.sourceLoaderSet.add(...loaders);
    }

    // Determine the default grid slot width based on the number of study jobs.
    // If there are only a few, then we can fit two viewers per row.
    // If there are many, then we can only fit one viewer per row due to the legend width.
    let defaultGridSlotWidth = this.studyJobs.length > 5 ? 12 : 6;

    // Create the suspension viewer if requested.
    if (this.filter & Filter.suspension) {
      const viewerMetadata = await this.resolveViewerMetadata(SUSPENSION_VIEWER_TYPE, undefined);
      config.views.push({
        title: 'Suspension Viewer',
        viewerType: SUSPENSION_VIEWER_TYPE,
        layout: undefined,
        viewer: SuspensionViewer3d.create(this.siteHooks, sharedState),
        grid: this.getGridSlot({
          width: 12,
          height: 8
        }, viewerMetadata)
      });
    }

    let getChannelNameForAsymmetric = (channelName: string, side: string): string => (channelName.replace(/([FR])_/, `$1${side}_`));

    let getChannelNamesWithAsymmetric = (channelNames: ReadonlyArray<string>): ReadonlyArray<string> =>
      [
        ...channelNames,
        ...channelNames.map(n => getChannelNameForAsymmetric(n, 'L')),
        ...channelNames.map(n => getChannelNameForAsymmetric(n, 'R')),
      ];

    // Create component sweeps viewers if requested.
    if (componentSweeps && (this.filter & Filter.tyreSlip)) {
      let defaultGridSlot = {
        x: 0,
        y: 0,
        width: defaultGridSlotWidth,
        height: 6
      };

      {
        let defaultConfig = {
          columns: [
            {
              channels: ['aSlipTyre'],
              relativeSize: 1
            }
          ],
          rows: [
            {
              channels: getChannelNamesWithAsymmetric([
                'rMuyTyreF_Fz2kN',
                'rMuyTyreF_Fz4kN',
                'rMuyTyreF_Fz6kN',
                'rMuyTyreF_Fz8kN',
                'rMuyTyreR_Fz2kN',
                'rMuyTyreR_Fz4kN',
                'rMuyTyreR_Fz6kN',
                'rMuyTyreR_Fz8kN',
              ]),
              relativeSize: 1
            }
          ]
        };

        let layout = await this.resolveViewerLayout(
          POINT_MULTI_PLOT_VIEWER_TYPE,
          new RequestedLayoutIds('Default-TyreLateralSlip'),
          defaultConfig);

        config.views.push(
          {
            title: 'Tyre Lateral Slip Behaviour',
            viewerType: POINT_MULTI_PLOT_VIEWER_TYPE,
            layout,
            viewer: LinePlotViewer.createLinePlotViewer('aSlipTyre', layout.resolvedLayout.getConfigCopy(), config.channelNameStyle, sharedState, this.siteHooks),
            grid: this.getGridSlot({ width: defaultGridSlot.width, height: 6 }, layout.viewerMetadata)
          });
      }

      {
        let defaultConfig = {
          columns: [
            {
              channels: ['rSlipTyre'],
              relativeSize: 1
            }
          ],
          rows: [
            {
              channels: getChannelNamesWithAsymmetric([
                'rMuxTyreF_Fz2kN',
                'rMuxTyreF_Fz4kN',
                'rMuxTyreF_Fz6kN',
                'rMuxTyreF_Fz8kN',
                'rMuxTyreR_Fz2kN',
                'rMuxTyreR_Fz4kN',
                'rMuxTyreR_Fz6kN',
                'rMuxTyreR_Fz8kN',
              ]),
              relativeSize: 1
            }
          ]
        };

        let layout = await this.resolveViewerLayout(
          POINT_MULTI_PLOT_VIEWER_TYPE,
          new RequestedLayoutIds('Default-TyreLongitudinalSlip'),
          defaultConfig);

        config.views.push(
          {
            title: 'Tyre Longitudinal Slip Behaviour',
            viewerType: POINT_MULTI_PLOT_VIEWER_TYPE,
            layout,
            viewer: LinePlotViewer.createLinePlotViewer('rSlipTyre', layout.resolvedLayout.getConfigCopy(), config.channelNameStyle, sharedState, this.siteHooks),
            grid: this.getGridSlot(defaultGridSlot, layout.viewerMetadata)
          });
      }

      {
        let columnChannels = [
          'rMuxOnEllipseAtaThetaF_Fz2kN',
          'rMuxOnEllipseAtaThetaF_Fz4kN',
          'rMuxOnEllipseAtaThetaF_Fz6kN',
          'rMuxOnEllipseAtaThetaF_Fz8kN',
        ];

        let rowChannels = [
          'rMuyOnEllipseAtaThetaF_Fz2kN',
          'rMuyOnEllipseAtaThetaF_Fz4kN',
          'rMuyOnEllipseAtaThetaF_Fz6kN',
          'rMuyOnEllipseAtaThetaF_Fz8kN',
        ];

        let defaultConfig = {
          columns: getChannelNamesWithAsymmetric(columnChannels).map(v => (
            {
              channels: [v],
              relativeSize: 1
            }
          )),
          rows: getChannelNamesWithAsymmetric(rowChannels).map(v => (
            {
              channels: [v],
              relativeSize: 1
            }
          )),
          stackDiagonalsHorizontally: true,
          stackDiagonalsVertically: true,
        };

        let layout = await this.resolveViewerLayout(
          POINT_MULTI_PLOT_VIEWER_TYPE,
          new RequestedLayoutIds('Default-TyreEllipse'),
          defaultConfig);

        config.views.push(
          {
            title: 'Tyre Ellipse',
            viewerType: POINT_MULTI_PLOT_VIEWER_TYPE,
            layout,
            viewer: LinePlotViewer.createLinePlotViewer('aSlipPlane', layout.resolvedLayout.getConfigCopy(), config.channelNameStyle, sharedState, this.siteHooks),
            grid: this.getGridSlot({ width: 12, height: 10 }, layout.viewerMetadata)
          });
      }
    }

    // Create suspension kinematics viewers if requested.
    if (componentSweeps && (this.filter & Filter.suspensionKinematics)) {
      let defaultGridSlot = {
        x: 0,
        y: 0,
        width: defaultGridSlotWidth,
        height: 8
      };

      if (simVersion <= SIM_VERSION_BEFORE_ASYMMETRIC_SUSPENSION_KINEMATICS) {
        {
          let defaultConfig = {
            columns: [
              {
                channels: ['aRockerF', 'xBumpF_aRockerF'],
                relativeSize: 1
              }
            ],
            rows: [
              {
                channels: [
                  'aCamberWheelF_aRockerF',
                  'aCasterWheelF_aRockerF',
                  'aToeWheelF_aRockerF',
                  'aKingPinInclinationF_aRockerF'
                ],
                relativeSize: 1
              },
              {
                channels: ['xHubF_aRockerF'],
                relativeSize: 1
              },
              {
                channels: ['yHubF_aRockerF'],
                relativeSize: 1
              },
              {
                channels: ['xBumpF_aRockerF'],
                relativeSize: 1
              },
              {
                channels: ['xKinematicTrailF_aRockerF'],
                relativeSize: 1
              },
              {
                channels: ['xScrubRadiusF_aRockerF'],
                relativeSize: 1
              },
              {
                channels: ['hRollCentreF_aRockerF'],
                relativeSize: 1
              },
              {
                channels: ['rAntiRise_aRockerF'],
                relativeSize: 1
              }
            ]
          };

          let layout = await this.resolveViewerLayout(
            POINT_MULTI_PLOT_VIEWER_TYPE,
            new RequestedLayoutIds('Default-FrontExternalSuspensionKinematics-aRocker'),
            defaultConfig);

          config.views.push(
            {
              title: 'Front External Suspension Kinematics with aRocker',
              viewerType: POINT_MULTI_PLOT_VIEWER_TYPE,
              layout,
              viewer: LinePlotViewer.createLinePlotViewer('aRockerF', layout.resolvedLayout.getConfigCopy(), config.channelNameStyle, sharedState, this.siteHooks),
              grid: this.getGridSlot(defaultGridSlot, layout.viewerMetadata)
            });
        }

        {
          let defaultConfig = {
            columns: [
              {
                channels: ['xRackF'],
                relativeSize: 1
              }
            ],
            rows: [
              {
                channels: [
                  'aCamberWheelF_xRackF',
                  'aCasterWheelF_xRackF',
                  'aToeWheelF_xRackF',
                  'aKingPinInclinationF_xRackF'
                ],
                relativeSize: 1
              },
              {
                channels: ['xHubF_xRackF'],
                relativeSize: 1
              },
              {
                channels: ['yHubF_xRackF'],
                relativeSize: 1
              },
              {
                channels: ['xBumpF_xRackF'],
                relativeSize: 1
              },
              {
                channels: ['xKinematicTrailF_xRackF'],
                relativeSize: 1
              },
              {
                channels: ['xScrubRadiusF_xRackF'],
                relativeSize: 1
              },
              {
                channels: ['hRollCentreF_xRackF'],
                relativeSize: 1
              }
            ]
          };

          let layout = await this.resolveViewerLayout(
            POINT_MULTI_PLOT_VIEWER_TYPE,
            new RequestedLayoutIds('Default-FrontExternalSuspensionKinematics-xRack'),
            defaultConfig);

          config.views.push(
            {
              title: 'Front External Suspension Kinematics with xRack',
              viewerType: POINT_MULTI_PLOT_VIEWER_TYPE,
              layout,
              viewer: LinePlotViewer.createLinePlotViewer('xRackF', layout.resolvedLayout.getConfigCopy(), config.channelNameStyle, sharedState, this.siteHooks),
              grid: this.getGridSlot(defaultGridSlot, layout.viewerMetadata)
            });
        }

        {
          let defaultConfig = {
            columns: [
              {
                channels: ['aRockerR', 'xBumpR_aRockerR'],
                relativeSize: 1
              }
            ],
            rows: [
              {
                channels: [
                  'aCamberWheelR_aRockerR',
                  'aCasterWheelR_aRockerR',
                  'aToeWheelR_aRockerR',
                  'aKingPinInclinationR_aRockerR'
                ],
                relativeSize: 1
              },
              {
                channels: ['xHubR_aRockerR'],
                relativeSize: 1
              },
              {
                channels: ['yHubR_aRockerR'],
                relativeSize: 1
              },
              {
                channels: ['xBumpR_aRockerR'],
                relativeSize: 1
              },
              {
                channels: ['xKinematicTrailR_aRockerR'],
                relativeSize: 1
              },
              {
                channels: ['xScrubRadiusR_aRockerR'],
                relativeSize: 1
              },
              {
                channels: ['hRollCentreR_aRockerR'],
                relativeSize: 1
              },
              {
                channels: ['rAntiSquat_aRockerR'],
                relativeSize: 1
              }
            ]
          };

          let layout = await this.resolveViewerLayout(
            POINT_MULTI_PLOT_VIEWER_TYPE,
            new RequestedLayoutIds('Default-RearExternalSuspensionKinematics-aRocker'),
            defaultConfig);

          config.views.push(
            {
              title: 'Rear External Suspension Kinematics with aRocker',
              viewerType: POINT_MULTI_PLOT_VIEWER_TYPE,
              layout,
              viewer: LinePlotViewer.createLinePlotViewer('aRockerR', layout.resolvedLayout.getConfigCopy(), config.channelNameStyle, sharedState, this.siteHooks),
              grid: this.getGridSlot(defaultGridSlot, layout.viewerMetadata)
            });
        }

        {
          let defaultConfig = {
            columns: [
              {
                channels: ['aRockerF', 'xBumpF_aRockerF'],
                relativeSize: 1
              }
            ],
            rows: [
              {
                channels: ['xSpringFR', 'xDamperFR', 'xInerterFR', 'xBumpStopFR'],
                relativeSize: 1
              },
              {
                channels: ['aTorsionBarTwistFR'],
                relativeSize: 1
              },
              {
                channels: [
                  'xTriSpringF', 'xTriDamperF', 'xTriInerterF', 'xTriBumpStopF',
                  'xTriSpringFR', 'xTriDamperFR', 'xTriInerterFR', 'xTriBumpStopFR', // Legacy, can be removed after October 2020.
                ],
                relativeSize: 1
              }
            ]
          };

          let layout = await this.resolveViewerLayout(
            POINT_MULTI_PLOT_VIEWER_TYPE,
            new RequestedLayoutIds('Default-FrontInternalSuspensionKinematics-aRocker'),
            defaultConfig);

          config.views.push(
            {
              title: 'Front Internal Suspension Kinematics with aRocker',
              viewerType: POINT_MULTI_PLOT_VIEWER_TYPE,
              layout,
              viewer: LinePlotViewer.createLinePlotViewer('aRockerF', layout.resolvedLayout.getConfigCopy(), config.channelNameStyle, sharedState, this.siteHooks),
              grid: this.getGridSlot(defaultGridSlot, layout.viewerMetadata)
            });
        }

        {
          let defaultConfig = {
            columns: [
              {
                channels: ['aRockerR', 'xBumpR_aRockerR'],
                relativeSize: 1
              }
            ],
            rows: [
              {
                channels: ['xSpringRR', 'xDamperRR', 'xInerterRR', 'xBumpStopRR'],
                relativeSize: 1
              },
              {
                channels: ['aTorsionBarTwistRR'],
                relativeSize: 1
              },
              {
                channels: [
                  'xTriSpringR', 'xTriDamperR', 'xTriInerterR', 'xTriBumpStopR',
                  'xTriSpringRR', 'xTriDamperRR', 'xTriInerterRR', 'xTriBumpStopRR', // Legacy, can be removed after October 2020.
                ],
                relativeSize: 1
              }
            ]
          };

          let layout = await this.resolveViewerLayout(
            POINT_MULTI_PLOT_VIEWER_TYPE,
            new RequestedLayoutIds('Default-RearInternalSuspensionKinematics-aRocker'),
            defaultConfig);

          config.views.push(
            {
              title: 'Rear Internal Suspension Kinematics with aRocker',
              viewerType: POINT_MULTI_PLOT_VIEWER_TYPE,
              layout,
              viewer: LinePlotViewer.createLinePlotViewer('aRockerR', layout.resolvedLayout.getConfigCopy(), config.channelNameStyle, sharedState, this.siteHooks),
              grid: this.getGridSlot(defaultGridSlot, layout.viewerMetadata)
            });
        }
      } else {
        class DomainAndSuffix {
          constructor(
            public readonly domains: ReadonlyArray<string>,
            public readonly suffix: string,
            public readonly primaryDomain: string) {
          }
        }

        let side = ['L', 'R'];
        {
          let externalCharts: ReadonlyArray<DomainAndSuffix> = [
            new DomainAndSuffix(['xRackF'], 'F', 'xRackF'),
            new DomainAndSuffix(['aRockerF', 'xBumpF{side}_aRockerF'], 'F', 'aRocker'),
            new DomainAndSuffix(['aRockerR', 'xBumpR{side}_aRockerR'], 'R', 'aRocker'),
          ];

          for (let chart of externalCharts) {
            let defaultConfig = {
              columns: [
                ...side.map(s => ({
                  channels: chart.domains.map(d => (d + s).replace('{side}', s)),
                  relativeSize: 1
                }))
              ],
              rows: [
                ...side.map(s => ({
                  channels: [
                    `aCamberWheel${chart.suffix}${s}_${chart.domains[0]}${s}`,
                    `aCasterWheel${chart.suffix}${s}_${chart.domains[0]}${s}`,
                    `aToeWheel${chart.suffix}${s}_${chart.domains[0]}${s}`,
                    `aKingPinInclination${chart.suffix}${s}_${chart.domains[0]}${s}`
                  ],
                  relativeSize: 1
                })),
                ...side.map(s => ({
                  channels: [`xHub${chart.suffix}${s}_${chart.domains[0]}${s}`],
                  relativeSize: 1
                })),
                ...side.map(s => ({
                  channels: [`yHub${chart.suffix}${s}_${chart.domains[0]}${s}`],
                  relativeSize: 1
                })),
                ...side.map(s => ({
                  channels: [`xBump${chart.suffix}${s}_${chart.domains[0]}${s}`],
                  relativeSize: 1
                })),
                ...side.map(s => ({
                  channels: [`xKinematicTrail${chart.suffix}${s}_${chart.domains[0]}${s}`],
                  relativeSize: 1
                })),
                ...side.map(s => ({
                  channels: [`xScrubRadius${chart.suffix}${s}_${chart.domains[0]}${s}`],
                  relativeSize: 1
                })),
                ...side.map(s => ({
                  channels: [`hRollCentre${chart.suffix}${s}_${chart.domains[0]}${s}`],
                  relativeSize: 1
                })),
                ...side.map(s => (chart.domains[0].startsWith('aRockerF')
                  ? {
                    channels: [`rAntiRise_${chart.domains[0]}${s}`],
                    relativeSize: 1
                  }
                  : chart.domains[0].startsWith('aRockerR')
                    ? {
                      channels: [`rAntiSquat_${chart.domains[0]}${s}`],
                      relativeSize: 1
                    }
                    : undefined))
              ],
              stackDiagonalsHorizontally: true,
            };

            let layout = await this.resolveViewerLayout(
              POINT_MULTI_PLOT_VIEWER_TYPE,
              new RequestedLayoutIds(`Default-ExternalSuspensionKinematics-${chart.domains[0]}`),
              defaultConfig);

            config.views.push(
              {
                title: `External Suspension Kinematics with ${chart.domains[0]}`,
                viewerType: POINT_MULTI_PLOT_VIEWER_TYPE,
                layout,
                viewer: LinePlotViewer.createLinePlotViewer(chart.primaryDomain, layout.resolvedLayout.getConfigCopy(), config.channelNameStyle, sharedState, this.siteHooks),
                grid: this.getGridSlot({ width: 12, height: 12 }, layout.viewerMetadata)
              });
          }
        }

        {
          let internalCharts: ReadonlyArray<DomainAndSuffix> = [
            new DomainAndSuffix(['aRockerF', 'xBumpF{side}_aRockerF'], 'F', 'aRocker'),
            new DomainAndSuffix(['aRockerR', 'xBumpR{side}_aRockerR'], 'R', 'aRocker'),
          ];

          for (let chart of internalCharts) {
            let defaultConfig = {
              columns: [
                ...side.map(s => ({
                  channels: chart.domains.map(d => (d + s).replace('{side}', s)),
                  relativeSize: 1
                }))
              ],
              rows: [
                ...side.map(s => ({
                  channels: [`xSpring${chart.suffix}${s}`, `xDamper${chart.suffix}${s}`, `xInerter${chart.suffix}${s}`, `xBumpStop${chart.suffix}${s}`],
                  relativeSize: 1
                })),
                ...side.map(s => ({
                  channels: [`aTorsionBarTwist${chart.suffix}${s}`],
                  relativeSize: 1
                })),
                ...side.map(s => ({
                  channels: [
                    `xTriSpring${chart.suffix}`, `xTriDamper${chart.suffix}`, `xTriInerter${chart.suffix}`, `xTriBumpStop${chart.suffix}`,
                  ],
                  relativeSize: 1
                })),
              ],
              stackDiagonalsHorizontally: true,
            };

            let layout = await this.resolveViewerLayout(
              POINT_MULTI_PLOT_VIEWER_TYPE,
              new RequestedLayoutIds(`Default-InternalSuspensionKinematics-${chart.domains[0]}`),
              defaultConfig);

            config.views.push(
              {
                title: `Internal Suspension Kinematics with ${chart.domains[0]}`,
                viewerType: POINT_MULTI_PLOT_VIEWER_TYPE,
                layout,
                viewer: LinePlotViewer.createLinePlotViewer(chart.primaryDomain, layout.resolvedLayout.getConfigCopy(), config.channelNameStyle, sharedState, this.siteHooks),
                grid: this.getGridSlot(defaultGridSlot, layout.viewerMetadata)
              });
          }
        }
        // {
        //   let internalCharts: ReadonlyArray<DomainAndSuffix> = [
        //     new DomainAndSuffix(['aRockerF'], 'F', 'aRocker'),
        //     new DomainAndSuffix(['aRockerR'], 'R', 'aRocker'),
        //   ];
        //
        //   for (let chart of internalCharts) {
        //     let defaultConfig = {
        //       columns: [
        //         {
        //           channels: chart.domains,
        //           relativeSize: 1
        //         }
        //       ],
        //       rows: [
        //         {
        //           channels: [
        //             `xTriSpring${chart.suffix}`, `xTriDamper${chart.suffix}`, `xTriInerter${chart.suffix}`, `xTriBumpStop${chart.suffix}`,
        //           ],
        //           relativeSize: 1
        //         }
        //       ]
        //     };
        //
        //     let layout = await this.resolveViewerLayout(
        //       POINT_MULTI_PLOT_VIEWER_TYPE,
        //       new RequestedLayoutIds(`Default-InternalSuspensionKinematics-${chart.domains[0]}`),
        //       defaultConfig);
        //     gridSlot = layout.getGridSlot(gridSlot);
        //
        //     config.views.push(
        //       {
        //         title: `Internal Suspension Kinematics with ${chart.domains[0]}`,
        //         viewerType: POINT_MULTI_PLOT_VIEWER_TYPE,
        //         layout: layout,
        //         viewer: LinePlotViewer.createLinePlotViewer(chart.primaryDomain, layout.resolvedLayout.getConfigCopy(), config.channelNameStyle, sharedState, this.siteHooks),
        //         grid: gridSlot
        //       });
        //
        //     gridSlot = this.getNextGridSlot(gridSlot);
        //   }
        // }
      }
    }

    // Create powertrain viewers if requested.
    if (componentSweeps && (this.filter & Filter.powertrain)) {

      let defaultGridSlot = {
        x: 0,
        y: 0,
        width: defaultGridSlotWidth,
        height: 8
      };

      let { engineChannelNames, motorChannelNames, motorNames } = getEngineAndMotorMetadata(componentSweeps.channels, simVersion);

      if (engineChannelNames.length) {
        let defaultConfig = {
          columns: [
            {
              channels: ['nEngine'],
              relativeSize: 1
            }
          ],
          rows: [
            {
              channels: engineChannelNames,
              relativeSize: 1
            }
          ]
        };

        let layout = await this.resolveViewerLayout(
          POINT_MULTI_PLOT_VIEWER_TYPE,
          new RequestedLayoutIds('Default-EngineTorqueOverFullEngineSpeedRange'),
          defaultConfig,
          true);

        config.views.push(
          {
            title: 'Engine Torque Over Full Engine Speed Range',
            viewerType: POINT_MULTI_PLOT_VIEWER_TYPE,
            layout,
            viewer: LinePlotViewer.createLinePlotViewer('nEngine', layout.resolvedLayout.getConfigCopy(), config.channelNameStyle, sharedState, this.siteHooks),
            grid: this.getGridSlot(defaultGridSlot, layout.viewerMetadata)
          });
      }

      if (motorChannelNames.length) {
        if (simVersion > SIM_VERSION_BEFORE_MOTOR_SPEED_CHANNEL_RENAME) {
          // Each M{motorName} channel now has it's own n{motorName} domain,
          // so we plot separate charts for each.
          for (let motorName of motorNames) {
            let defaultConfig = {
              columns: [
                {
                  channels: ['n' + motorName],
                  relativeSize: 1
                }
              ],
              rows: [
                {
                  channels: ['M' + motorName],
                  relativeSize: 1
                }
              ]
            };

            let layout = await this.resolveViewerLayout(
              POINT_MULTI_PLOT_VIEWER_TYPE,
              new RequestedLayoutIds('Default-MotorTorqueOverFullMotorSpeedRange'),
              defaultConfig,
              true);

            config.views.push(
              {
                title: motorName + 'Motor Torque Over Full Motor Speed Range',
                viewerType: POINT_MULTI_PLOT_VIEWER_TYPE,
                layout,
                viewer: LinePlotViewer.createLinePlotViewer('n' + motorName, layout.resolvedLayout.getConfigCopy(), config.channelNameStyle, sharedState, this.siteHooks),
                grid: this.getGridSlot(defaultGridSlot, layout.viewerMetadata)
              });
          }
        } else {
          let defaultConfig = {
            columns: [
              {
                channels: ['nMotor'],
                relativeSize: 1
              }
            ],
            rows: [
              {
                channels: motorChannelNames,
                relativeSize: 1
              }
            ]
          };

          let layout = await this.resolveViewerLayout(
            POINT_MULTI_PLOT_VIEWER_TYPE,
            new RequestedLayoutIds('Default-MotorTorqueOverFullMotorSpeedRange'),
            defaultConfig,
            true);

          config.views.push(
            {
              title: 'Motor Torque Over Full Motor Speed Range',
              viewerType: POINT_MULTI_PLOT_VIEWER_TYPE,
              layout,
              viewer: LinePlotViewer.createLinePlotViewer('nMotor', layout.resolvedLayout.getConfigCopy(), config.channelNameStyle, sharedState, this.siteHooks),
              grid: this.getGridSlot(defaultGridSlot, layout.viewerMetadata)
            });
        }
      }

      if (this.showContourPlot && motorNames.length) {

        let minimalDataService = new DataService(
          simTypes.map(v => v.name),
          this.fileLoader,
          this.siteHooks);

        let plotTorqueMap = async (motorName: string, backgroundYDomain: string, backgroundChannel: string, layoutIdSuffix: string, titleSuffix: string, reverseColorScale: boolean): Promise<boolean> => {
          if (componentSweeps && !componentSweeps.channels.some(v => v.name === backgroundChannel)) {
            return false;
          }

          let defaultConfig = {
            // Motor nMotor channels have now been renamed to be n{motorName}.
            xDomains: [simVersion > SIM_VERSION_BEFORE_MOTOR_SPEED_CHANNEL_RENAME ? 'n' + motorName : 'nMotor'],
            panes: [
              {
                channels: ['M' + motorName],
                relativeSize: 1,
                background: {
                  yDomain: backgroundYDomain,
                  channel: backgroundChannel
                }
              }
            ]
          };

          let layout = await this.resolveViewerLayout(
            LINE_CONTOUR_OVERLAY_VIEWER_TYPE,
            new RequestedLayoutIds('Default-MotorTorqueAnd' + layoutIdSuffix),
            defaultConfig,
            true);

          config.views.push(
            {
              title: motorName + ' Torque and ' + titleSuffix,
              viewerType: LINE_CONTOUR_OVERLAY_VIEWER_TYPE,
              layout,
              viewer: new LineContourOverlayViewer(this.studyJobs, layout.resolvedLayout.getConfigCopy(), minimalDataService, sharedState, this.siteHooks, reverseColorScale),
              grid: this.getGridSlot(defaultGridSlot, layout.viewerMetadata)
            });

          return true;
        };

        for (let motorName of motorNames) {
          await plotTorqueMap(motorName, 'M' + motorName + 'LossMapBasis', 'M' + motorName + 'MechanicalLoss', 'MechanicalLoss', 'Mechanical Loss', true);
          await plotTorqueMap(motorName, 'M' + motorName + 'EfficiencyBasis', 'e' + motorName + 'Deployment', 'Efficiency', 'Efficiency', false);
        }
      }
    }

    return config;
  }
}

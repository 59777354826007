import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ErrorHandlingResolve } from '../../common/errors/error-handling-resolve';
import { ErrorMessageService } from '../../common/errors/services/error-message/error-message.service';
import { InferableRouteParams } from '../../common/inferable-route-params.service';
import { KeyPermissionsService } from './services/key-permissions.service';


@Injectable()
export class KeyPermissionResolve extends ErrorHandlingResolve<void> {
  constructor(
    private _router: Router,
    private _errorMessageService: ErrorMessageService,
    private _inferableRouteParams: InferableRouteParams,
    private _keyPermissionService: KeyPermissionsService,
  ) {
    super(_router, _errorMessageService);
  }

  async resolveInner(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<void> {
    const tenantId = this._inferableRouteParams.getTenantId(route);
    try {
      await this._keyPermissionService.fetchKeyPermissions(tenantId).toPromise();

      let permissions = this._keyPermissionService.permissionsSnapshot;

      let selectedPermission = permissions.find((value) => value.encryptingTenantShortName === route.params.encryptingTenantShortName);

      if (!selectedPermission) {
        throw new Error('The requested Key Permission could not be found.');
      }

      this._keyPermissionService.setKeyPermission(
        selectedPermission.encryptingTenantShortName,
        selectedPermission.minimumSimVersion,
        selectedPermission.description
      );
    } catch (error) {
      this._errorMessageService.setErrorMessage(error);
      this._router.navigate(['/key-permissions', tenantId]);
    }
  }
}

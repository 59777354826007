import {Injectable} from '@angular/core';
import {DialogManager, IDialog, DialogBase} from '../../../common/dialogs/dialog-manager.service';

@Injectable()
export class ImportTelemetryDialog {
  constructor(
    private dialogManager: DialogManager
  ){}

  public async show(showDifferences: boolean): Promise<ImportTelemetryResult> {

    let result = await this.dialogManager.showPrimaryDialog(
      new ImportTelemetryDialogData(showDifferences));

    return result;
  }
}

export class ImportTelemetryDialogData extends DialogBase<ImportTelemetryResult> implements IDialog {
  constructor(
    public readonly showDifferences: boolean) {
    super();
  }
}

export class ImportTelemetryResult {
  constructor(
    public readonly telemetrySubmitted: boolean,
    public readonly studyId: string){
  }
}

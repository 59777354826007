
/**
 * Error class that is meant to be displayed to the user.
 */
export class DisplayableError extends Error {
  public readonly stack: any;
  public readonly isDisplayable: boolean = true;

  constructor(public message: string) {
    super(message);
    this.stack = (<any>new Error()).stack;
  }
}

import { SVGSelection } from '../../untyped-selection';
import { Object3D } from 'three';
import { IMargin } from '../margin';
import { ISize } from '../size';
import { ILegendSettings } from '../legend-settings';
import { RenderedSuspensionMember } from './suspension-renderer';

export const CONTAINER_CLASS = 'selected-components';

/**
 * The required chart settings for the selected components renderer.
 */
interface IChartSettings {
  readonly svgSize: ISize;
  readonly svgPadding: IMargin;
  readonly chartMargin: IMargin;
  readonly chartSize: ISize;
  readonly legend: ILegendSettings;
}

/**
 * The D3 style interface to the selected components renderer. This renders the names of the components that the user is hovering over.
 */
export class SelectedComponentsRenderer {

  /**
   * The inner selected components renderer.
   */
  private readonly inner: SelectedComponentsRendererInner;

  /**
   * Create a new instance of the selected components renderer.
   * @param settings The settings for the selected components renderer.
   */
  constructor(settings: IChartSettings) {
    this.inner = new SelectedComponentsRendererInner(settings);
  }

  /**
   * Render the selected components.
   * @param selection The selection to render the selected components to.
   * @returns This selected components renderer.
   */
  public render(selection: SVGSelection): this {
    this.inner.render(selection);
    return this;
  }

  /**
   * Set the selected components to render.
   * @param value The selected components to render.
   * @returns This selected components renderer.
   */
  public selectedComponents(value: ReadonlyArray<Object3D>): this {
    this.inner.selectedComponents = value;
    return this;
  }
}

/**
 * The inner selected components renderer.
 */
export class SelectedComponentsRendererInner {

  /**
   * The list of selected components to render.
   */
  public selectedComponents: ReadonlyArray<Object3D> = [];

  /**
   * Create a new instance of the inner selected components renderer.
   * @param settings The settings for the selected components renderer.
   */
  constructor(public readonly settings: IChartSettings) {
  }

  /**
   * Render the selected components.
   * @param selection The selection to render the selected components to.
   */
  public render(selection: SVGSelection) {

    // Create the container for the selected components.
    let containerUpdate = selection.selectAll<SVGGElement, any>('.' + CONTAINER_CLASS).data([null]);
    let containerEnter = containerUpdate.enter().append('g').attr('class', CONTAINER_CLASS);
    let container = containerEnter.merge(containerUpdate);

    // Position the container in the bottom right of the viewer.
    container.attr('transform', `translate(${this.settings.svgSize.width - this.settings.svgPadding.right - this.settings.chartMargin.right}, ${this.settings.svgPadding.top + this.settings.chartMargin.top + this.settings.chartSize.height})`);

    // Create a group for each suspension member.
    let gUpdate = container.selectAll<SVGGElement, RenderedSuspensionMember>('.selected-component').data(() => (this.selectedComponents || []).map(v => v.userData.model as RenderedSuspensionMember));
    gUpdate.exit().remove();
    let gEnter = gUpdate.enter().append('g').attr('class', 'selected-component');
    let g = gEnter.merge(gUpdate);

    // Move each group so they are stacked vertically.
    g.attr('transform', (v, i) => `translate(0, ${i * -15})`);

    // Add text to each group which is the name of the suspension area and member.
    gEnter.append('text').attr('font-size', '12px');
    g.select('text')
      .attr('fill', v => v.colorString)
      .text((v) => v.suspensionArea.name + ' - ' + v.suspensionMember.name);
  }
}

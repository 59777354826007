import { Component } from '@angular/core';

@Component({
  selector: 'cs-generic-error',
  templateUrl: './generic-error.page.html',
  styleUrls: ['./generic-error.page.scss']
})
export class GenericErrorPage {

  constructor() { }

}

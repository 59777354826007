import {Injectable} from '@angular/core';
import {CommandContext, CommandResult, WorksheetCommand} from './worksheet-command';
import {
  ButtonMenuItem, KeyboardAction,
  MenuItem,
} from '../../context-menu/context-menu-types';
import {WorksheetClipboard} from '../worksheet-clipboard.service';

/**
 * A command that copies the selected cells to the clipboard.
 */
@Injectable()
export class CopyCommand extends WorksheetCommand {

  /**
   * Creates an instance of CopyCommand.
   * @param clipboard The service for copying to the clipboard.
   */
  constructor(
    private readonly clipboard: WorksheetClipboard) {
    super();
  }

  /**
   * Determines if the command can be executed.
   * @param context The command context.
   * @returns True if the command can be executed, otherwise false.
   */
  public canExecute(context: CommandContext): boolean {
    let canCopy = true;
    if(context.isConfigOrStudy()){
      canCopy = context.target.isPopulated;
    }

    return canCopy;
  }

  /**
   * Executes the command.
   * @param context The command context.
   * @returns The command result.
   */
  public async execute(context: CommandContext): Promise<CommandResult> {
    if(!this.canExecute(context)) {
      return CommandResult.NoUpdate;
    }

    this.clipboard.copy(
      context.worksheet.tenantId,
      context.worksheet.worksheetId,
      context.worksheet.getSelectedOutline());

    return CommandResult.NoUpdate;
  }

  /**
   * Creates the menu items for the command.
   * @param context The command context.
   * @param result The menu items.
   */
  public createMenuItems(context: CommandContext, result: MenuItem<CommandResult>[]): void {
    if(!this.canExecute(context)) {
      return;
    }

    result.push(new ButtonMenuItem<CommandResult>(
      'Copy' + (context.isRowMetadata() ? ' Row' : ''),
      `copy-button`,
      () => this.execute(context),
      'file-o',
      KeyboardAction.copy));
  }
}

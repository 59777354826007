import { ExplorationJobMetadata, ExplorationJobsMetadata } from '../get-exploration-jobs-metadata-from-exploration-map';
import { ArrayMappings } from './array-mappings';


/**
 * Applies the sweep remappings to the jobs.
 */
export class ApplySweepRemappingsToJobs {

  /**
   * Apply the sweep remappings to the jobs.
   * @param mappings The mappings to apply.
   * @param jobs The jobs to apply the mappings to.
   * @returns The jobs with the mappings applied.
   */
  public execute(mappings: ReadonlyArray<ArrayMappings>, jobs: ExplorationJobsMetadata): ExplorationJobsMetadata {
    return new ExplorationJobsMetadata(
      jobs.jobs.map(job => new ExplorationJobMetadata(
        job.name,
        job.inputSweepIndices.map((v, sweepIndex) => mappings[sweepIndex].oldIndexToNewIndex[v]))));
  }
}

import {afterRender, Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {ContextMenu} from '../context-menu.service';
import {Size} from '../../visualizations/viewers/size';
import {
  ContextMenuRendererComponent,
  HorizontalExpandDirection,
  VerticalExpandDirection
} from '../context-menu-renderer/context-menu-renderer.component';
import { Position } from '../../visualizations/viewers/position';

@Component({
  selector: 'cs-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent implements OnInit {

  private screenSize: Size;

  @ViewChild(ContextMenuRendererComponent,  {read: ElementRef}) renderer!: ElementRef;
  @ViewChild('container') container!: ElementRef;
  private lastPosition: Position;

  constructor(public readonly menu: ContextMenu) {
    // If menu is too tall it can overflow the viewport. This pins it to the top or bottom edge.
    // Need to wait for render to measure height
    afterRender({
      write: ()=>{
        if(this.shouldDisplay && this.renderer){
          let height = this.renderer.nativeElement.offsetHeight;
          let el = this.container.nativeElement;
          el.style.top = this.isInBottomPortion ? null : '' + Math.min(this.menu.content.position.y, this.screenSize.height - height) + 'px';
          el.style.left = this.isInRightPortion ? null : '' + this.menu.content.position.x + 'px';
          el.style.bottom = this.isInBottomPortion ? '' + Math.min(this.screenSize.height - this.menu.content.position.y, this.screenSize.height - height) + 'px': null;
          el.style.right = this.isInRightPortion ? '' + (this.screenSize.width - this.menu.content.position.x) + 'px' : null;
        }
      }
    });
  }

  @HostListener('window:resize', [])
  onResize() {
    this.screenSize = new Size(
      window.innerWidth,
      window.innerHeight);
  }

  ngOnInit() {
    this.onResize();
  }

  public get shouldDisplay(): boolean {
    if(!(this.menu.content && this.menu.content.items && this.menu.content.items.length)){
      return false;
    }
    // If the position object has changed the menu may have a new height.
    // Need to push the menu off-screen so we can measure its new height without flickering on screen
    if(this.lastPosition !== this.menu.content.position && this.renderer){
      this.renderer.nativeElement.style.top = '10000px';
    }
    this.lastPosition = this.menu.content.position;
    return true;
  }

  public close(){
    this.menu.content = undefined;
  }

  public onCloseRequested(){
    this.close();
  }

  public get verticalExpandDirection(): VerticalExpandDirection {
    return this.isInBottomPortion ? VerticalExpandDirection.up : VerticalExpandDirection.down;
  }

  public get horizontalExpandDirection(): HorizontalExpandDirection {
    return this.isInRightPortion ? HorizontalExpandDirection.left : HorizontalExpandDirection.right;
  }

  private get isInBottomPortion(): boolean {
    return this.menu.content.position.y > this.screenSize.height / 2;
  }

  private get isInRightPortion(): boolean {
    return this.menu.content.position.x > this.screenSize.width / 2;
  }
}

import { CoordinateTriplet } from '../3d/coordinate-triplet';

/**
 * Wraps the car config and exposes functions for getting key bits of the car.
 */
export class RawCarData {

  /**
   * Create a new instance of the raw car data.
   * @param car The car config.
   */
  constructor(private readonly car: any) {
  }

  /**
   * Gets the front suspension data.
   */
  public get frontSuspension(): RawCarSuspension {
    return new RawCarSuspension(this.car && this.car.suspension && this.car.suspension.front);
  }

  /**
   * Gets the rear suspension data.
   */
  public get rearSuspension(): RawCarSuspension {
    return new RawCarSuspension(this.car && this.car.suspension && this.car.suspension.rear);
  }

  /**
   * Gets the chassis suspension data.
   */
  public get chassis(): RawSuspensionCoordinatesMap {
    return (this.car && this.car.chassis) || {};
  }
}

/**
 * Wraps the suspension data and exposes functions for getting key bits of the suspension.
 */
export class RawCarSuspension {

  /**
   * Create a new instance of the raw car suspension data.
   * @param suspension The suspension config.
   */
  constructor(private readonly suspension: any) {
    if (!this.suspension) {
      this.suspension = {};
    }
  }

  /**
   * Gets the suspension offset.
   */
  public get suspensionOffset(): CoordinateTriplet {
    return this.suspension.rSuspensionDatumOffset || [0, 0, 0];
  }

  /**
   * Gets the external pick-up points.
   */
  public get externalPickUpPoints(): RawSuspensionCoordinatesMap {
    return (this.suspension.external && this.suspension.external.pickUpPts) || {};
  }

  /**
   * Gets the left hand external pick-up points.
   */
  public get externalPickUpPointsL(): RawSuspensionCoordinatesMap {
    return (this.suspension.external && this.suspension.external.pickUpPtsL) || {};
  }

  /**
   * Gets the internal pick-up points.
   */
  public get internalPickUpPoints(): RawSuspensionCoordinatesMap {
    return (this.suspension.internal && this.suspension.internal.pickUpPts) || {};
  }

  /**
   * Gets the left hand internal pick-up points.
   */
  public get internalPickUpPointsL(): RawSuspensionCoordinatesMap {
    return (this.suspension.internal && this.suspension.internal.pickUpPtsL) || {};
  }

  /**
   * Gets the wheel data.
   */
  public get wheel(): RawWheelData {
    return new RawWheelData(this.suspension.rWheelDesign, this.suspension.rWheelSetup);
  }
}

/**
 * Represents a map of names to suspension coordinates triplets.
 */
export interface RawSuspensionCoordinatesMap {
  readonly [name: string]: CoordinateTriplet;
}

/**
 * Raw wheel data from a car config.
 */
export class RawWheelData {

  /**
   * Create a new instance of the raw wheel data.
   * @param rWheelDesign The wheel design.
   * @param rWheelSetup The wheel setup.
   */
  constructor(
    public readonly rWheelDesign: number,
    public readonly rWheelSetup: number) {
  }
}

import { ViewerChannelDataFactory } from './viewer-channel-data-factory';
import { GetInterpolatedChannelValueAtDomainValue } from './get-interpolated-channel-value-at-domain-value';
import { IViewerChannelData, MonotonicStatus } from './viewer-channel-data';
import { ChannelWithModifiersResult, ChannelNameWithModifiersResult } from './apply-channel-modifiers';
import { DomainViewerChannelData } from './domain-viewer-channel-data';

/**
 * A class to apply delta channel modifiers to a channel.
 */
export class ApplyDeltaChannelModifier {
  constructor(
    private viewerChannelDataFactory: ViewerChannelDataFactory,
    private getInterpolatedChannelValueAtDomainValue: GetInterpolatedChannelValueAtDomainValue) {
  }

  /**
   * Creates a set of delta channel names from the source channel name for each supplied X domain name.
   * @param channelName The source channel name.
   * @param xDomainNames The list of domains to create a delta channel for.
   * @returns The list of delta channels created from the source channel.
   */
  public executeForChannelName(channelName: string, xDomainNames: ReadonlyArray<string>): ChannelNameWithModifiersResult[] {
    return xDomainNames.map(v => this.executeForChannelNameForDomain(channelName, v));
  }

  /**
   * Creates a delta channel for each supplied X domain.
   * @param channelName The source channel name.
   * @param xDomainNames The list of domains to create a delta channel for.
   * @returns The list of delta channels created from the source channel.
   */
  public execute(channelName: string, channelData: ReadonlyArray<IViewerChannelData>, xDomains: ReadonlyArray<DomainViewerChannelData>): ChannelWithModifiersResult[] {
    return xDomains.map(v => this.executeForDomain(channelName, channelData, v));
  }

  /**
   * Creates a delta channel name from the source channel name for the supplied X domain name.
   * @param channelName The source channel name.
   * @param xDomainName The domain to create a delta channel for.
   * @returns The delta channel created from the source channel.
   */
  private executeForChannelNameForDomain(channelName: string, xDomainName: string): ChannelNameWithModifiersResult {
    return new ChannelNameWithModifiersResult(`Δ${channelName}_${xDomainName}`, xDomainName);
  }

  /**
   * Creates a delta channel for the supplied X domain.
   * @param channelName The source channel name.
   * @param channelData The source channel data.
   * @param xDomain The domain to create a delta channel for.
   * @returns The delta channel created from the source channel.
   */
  private executeForDomain(channelName: string, channelData: ReadonlyArray<IViewerChannelData>, xDomain: DomainViewerChannelData): ChannelWithModifiersResult {

    let modifiedChannel = this.executeForChannelNameForDomain(channelName, xDomain.channelName);

    let xDomainData = xDomain.channelData;

    if (channelData.length === 0 || xDomainData.length === 0) {
      return new ChannelWithModifiersResult(modifiedChannel.channelName, modifiedChannel.renderOnlyForDomain, []);
    }

    let referenceValues = channelData[0].data;
    let referenceXData = xDomainData[0];
    let referenceXValues = referenceXData.data;

    let modifiedChannelData: IViewerChannelData[] = [];
    for (let sourceIndex = 0; sourceIndex < channelData.length; ++sourceIndex) {
      let sourceData = channelData[sourceIndex];
      let sourceXData = xDomainData[sourceIndex];
      let sourceXValues = sourceXData ? sourceXData.data : undefined;

      let newValues: number[] | undefined;
      if (sourceData && sourceData.data) {
        if (sourceIndex === 0
          || !sourceXValues
          || !referenceXData
          || !referenceValues
          || !referenceXValues
          || !referenceXData.isMonotonic
          || (sourceData.dataLabels && sourceData.dataLabels.length) /* Can't delta enumerated values. */) {
          newValues = sourceData.data.map(() => NaN);
        } else {
          let sourceValues = sourceData.data;
          newValues = this.calculateDeltaValues(
            sourceValues, sourceXValues, referenceValues, referenceXValues, referenceXData.monotonicStatus);
        }
      }

      let newChannelData = this.viewerChannelDataFactory.createChannelDataWithModifiers(
        sourceData,
        (name: string) => this.executeForChannelNameForDomain(name, xDomain.channelName).channelName,
        newValues);

      modifiedChannelData.push(newChannelData);
    }

    return new ChannelWithModifiersResult(modifiedChannel.channelName, modifiedChannel.renderOnlyForDomain, modifiedChannelData);
  }

  /**
   * Calculates the delta values between the source and reference values.
   * @param sourceValues The source values.
   * @param sourceXValues The source X values.
   * @param referenceValues The reference values.
   * @param referenceXValues The reference X values.
   * @param referenceMonotonicStatus The reference X values monotonic status.
   * @returns The delta values between the source and reference values.
   */
  public calculateDeltaValues(
    sourceValues: ReadonlyArray<number>,
    sourceXValues: ReadonlyArray<number>,
    referenceValues: ReadonlyArray<number>,
    referenceXValues: ReadonlyArray<number>,
    referenceMonotonicStatus: MonotonicStatus) {

    return sourceValues.map((v: number, i: number) => {
      let xValue = sourceXValues[i];
      let referenceValue = this.getInterpolatedChannelValueAtDomainValue.execute(
        referenceValues, xValue, referenceXValues, referenceMonotonicStatus);
      return v - referenceValue;
    });
  }
}

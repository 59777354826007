
import {Injectable} from '@angular/core';

@Injectable()
export class CreateUserInformationMaps {

  public execute(userInformation: UserInformation): UserInformationMap {
    let map = {
      tenants: {},
      users: {}
    };

    this.append(userInformation, map);

    return map;
  }

  public append(userInformation: UserInformation, map: UserInformationMap) {
    if(userInformation){
      let tenantMap = map.tenants;
      let userMap = map.users;

      for(let tenant of userInformation.tenants){
        tenantMap[tenant.tenantId] = {
          tenantId: tenant.tenantId,
          name: tenant.name,
          shortName: tenant.shortName
        };
        for(let user of tenant.users){
          userMap[user.userId] = {
            userId: user.userId,
            username: user.username,
            email: user.email,
            tenantId: tenant.tenantId,
            tenantName: tenant.name,
            tenantShortName: tenant.shortName
          };
        }
      }
    }
  }
}

export interface UserInformation {
  tenants: {
    tenantId: string;
    name: string;
    shortName: string;
    users: {
      userId: string;
      username: string;
      email: string;
    }[];
  }[];
}

export interface UserInformationMap {
  tenants: {
    [tenantId: string]: {
      tenantId: string;
      name: string;
      shortName: string;
    };
  };
  users: {
    [userId: string]: {
      userId: string;
      username: string;
      email: string;
      tenantId: string;
      tenantName: string;
      tenantShortName: string;
    };
  };
}

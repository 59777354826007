import {RowViewModel} from '../row-view-model';
import {ReplaceResult} from '../worksheet-item-component-base';
import {Injectable} from '@angular/core';
import {ConfirmationDialog} from '../../common/dialogs/confirmation-dialog.service';
import {RowItemViewModel} from '../row-item-view-model';
import {ConfigViewModel} from '../config-view-model';

export const replaceThisReferenceMessage = 'Replace this reference';
export const getReplaceAllReferencesMessage = (count: number) => 'Replace ' + count + ' references';

/**
 * A service that confirms if all references should be replaced or just the selected one.
 */
@Injectable()
export class ConfirmReplaceAllReferences {

  /**
   * Creates an instance of ConfirmReplaceAllReferences.
   * @param confirmationDialog The confirmation dialog service.
   */
  constructor(
    private readonly confirmationDialog: ConfirmationDialog) {
  }

  /**
   * When a config has multiple cells referencing it, and only one is selected,
   * this method confirms if all references should be replaced or just the selected one.
   * @param target The target row item.
   * @param rows The selected rows.
   * @returns The result of the confirmation.
   */
  public async execute(target: RowItemViewModel, rows: ReadonlyArray<RowViewModel>): Promise<ReplaceResult> {
    if(!this.isConfig(target)) {
      // For studies we always only replace the selected reference.
      return ReplaceResult.replaceThisReference;
    }

    if(rows.length !== 1){
      // If they've selected multiple cells, we just replace the selected reference.
      return ReplaceResult.replaceThisReference;
    }

    let referenceMetadata = target.referenceMetadata;
    if(!referenceMetadata || referenceMetadata.configCount < 2){
      // If there are no other configs sharing this reference, we just replace the selected.
      return ReplaceResult.replaceThisReference;
    }

    // Otherwise ask the user.
    const replaceAllReferences = getReplaceAllReferencesMessage(referenceMetadata.configCount);
    let response = await this.confirmationDialog.show(
      'Would you like to replace all ' + referenceMetadata.configCount + ' references?',
      'Replace Reference',
      [replaceAllReferences, replaceThisReferenceMessage],
      'Cancel');

    if(!response){
      return ReplaceResult.cancel;
    }

    return response === replaceAllReferences
      ? ReplaceResult.replaceAllReferences
      : ReplaceResult.replaceThisReference;
  }

  /**
   * Determines if the target is a config.
   * @param target The target row item.
   * @returns True if the target is a config, otherwise false.
   */
  public isConfig(target: RowItemViewModel): target is ConfigViewModel {
    return target instanceof ConfigViewModel;
  }
}

import {Rule} from '../runner/rule';
import {ConfigContext} from '../runner/config-context';
import {RuleLogger} from '../runner/rule-logger';

export class MapVerification extends Rule {
  public name = 'Map Verification';
  public configTypes = 'car';
  public simTypes: string[] = null;

  public evaluateConfig(context: ConfigContext, log: RuleLogger) {
    for(let item of context.getValues('powertrain.electric.electricMotors.harvestIndependence')) {
      //if(item.value.name === 'Use Harvest Map' || item.value.name === 'Use Harvest Torque Map') {
      const harvestOutputs = item.value.MHarvestOutputs || item.value.PHarvestOutputs;
      if(harvestOutputs && Array.isArray(harvestOutputs)){
        for(let i=1; i < harvestOutputs.length - 1; ++i){
          if(harvestOutputs[i] > 2*harvestOutputs[i - 1]
            && harvestOutputs[i] > 2*harvestOutputs[i + 1]) {
            log.error('Harvest map outputs are lumpy.', item.path);
          }
        }
      }
      //}
    }

    for(let item of context.getValues('powertrain.electric.electricMotors.deployment')) {
      const motorMap = item.value.MMotorMapData;
      if(motorMap && Array.isArray(motorMap) && motorMap.length){
        let lastRow = motorMap[motorMap.length - 1];
        if(lastRow && Array.isArray(lastRow) && lastRow.length) {
          if(lastRow[lastRow.length - 1] === 0){
            log.error('Deployment motor map ends with zero MMotor.', item.path);
          }
        }
      }
    }
  }
}

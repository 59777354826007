import {OnInit, OnDestroy, Input, Output, EventEmitter} from '@angular/core';
import {Component} from '@angular/core';
import {
  StudyStub, GetStudiesQueryResult, ListFilterData,
  GetSupportSessionQueryResult, OrderByProperty, DocumentCustomPropertyGroup, FilteredDocumentsResultType,
  ListFilterGroup, StudyType
} from '../../../../generated/api-stubs';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {Dayjs} from '../../../common/dayjs.service';
import {cssSanitize} from '../../../common/css-sanitize';
import {CanopyPusher, StudyBuildProgress} from '../../../common/canopy-pusher.service';
import {StudiesProgress} from '../../../common/canopy-pusher.service';
import {ConfirmationDialog} from '../../../common/dialogs/confirmation-dialog.service';
import {StudyTypeLookup, StudyTypeMap} from '../study-type-lookup.service';
import {FilterUtilities} from '../../list-filtering/filter-utilities';
import {CustomProperty} from '../../custom-properties/custom-properties';
import {CustomPropertyUtilities} from '../../custom-properties/custom-property-utilities';
import {getExecutionTimeString} from '../../../common/get-execution-time-string';
import {UserInformationMap, CreateUserInformationMaps} from '../../create-user-information-maps.service';
import {DomSanitizer} from '@angular/platform-browser';
import {renderWithCustomLineBreaks} from '../../../common/render-with-custom-line-breaks';
import {StageStudy} from '../stage-study.service';
import {GoToJob} from '../../jobs/go-to-job.service';
import {LocalUserSettingsManager} from '../../../common/local-user-settings-manager';
import {ViewConfigsComponentBase} from '../../configs/view-configs-component-base';
import {SignificantSimVersions} from '../../../common/significant-sim-versions.service';
import {StudySubmittedEvents} from '../study-submitted-events.service';
import {Subscription} from 'rxjs';
import { AuthenticationService, UserData } from '../../../identity/state/authentication.service';

export class StudySummary {
  constructor(
    public tenantId: string,
    public userId: string,
    public studyId: string,
    public name: string,
    public nameTruncated: string,
    public username: string,
    public creationDate: string,
    public creationDateRaw: string,
    public modifiedDate: string,
    public isCompleted: boolean,
    public isBuilding: boolean,
    public hasErrors: boolean,
    public executionTime: string,
    public jobCount: number,
    public dispatchedJobCount: number,
    public completedJobCount: number,
    public succeededJobCount: number,
    public studyType: StudyType,
    public studyTypeName: string,
    public isOwner: boolean,
    public deleteRequested: boolean,
    public parentWorksheetId: string,
    public properties: CustomProperty[],
    public simVersion: string,
    public supportSession: GetSupportSessionQueryResult,
    public succeededComputeCredits: number,
    public succeededStorageCredits: number,
  ){}

  public isDeleting: boolean = false;
  public isSelected: boolean = false;
}

@Component({
  selector: 'cs-view-studies',
  templateUrl: './view-studies.component.html',
  styleUrls: ['./view-studies.component.scss']
})
export class ViewStudiesComponent extends ViewConfigsComponentBase implements OnInit, OnDestroy {

  @Input() public initialQuery: ListFilterGroup;
  @Output() public studySelected: EventEmitter<StudySummary> = new EventEmitter<StudySummary>();

  public errorMessage: string;

  public userData: UserData;
  public filter: ListFilterData;

  public studies: StudySummary[];
  public map: UserInformationMap;
  public studyTypeMap: StudyTypeMap;
  public lastResult: GetStudiesQueryResult;
  public isReloading: boolean;

  public studiesProgressSubscription: Subscription;
  public studyBuildProgressSubscription: Subscription;
  public studySubmittedSubscription: Subscription;
  public loadTask: Promise<any>;

  public showProperties: boolean = true;
  public isDeleting: boolean = false;

  constructor(
    private readonly filterUtilities: FilterUtilities,
    public readonly authenticationService: AuthenticationService,
    private readonly dayjs: Dayjs,
    private readonly canopyPusher: CanopyPusher,
    private readonly studyStub: StudyStub,
    private readonly confirmationDialog: ConfirmationDialog,
    private readonly studyTypeLookup: StudyTypeLookup,
    private readonly createUserInformationMaps: CreateUserInformationMaps,
    private readonly sanitizer: DomSanitizer,
    private readonly stageStudy: StageStudy,
    private readonly goToJobService: GoToJob,
    private readonly localUserSettingsManager: LocalUserSettingsManager,
    public readonly significantSimVersions: SignificantSimVersions,
    private readonly studySubmittedEvents: StudySubmittedEvents,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
    super();
    this.userData = this.authenticationService.userDataSnapshot;
  }

  public ngOnInit() {
    this.filter = this.filterUtilities.getInitialFilter(OrderByProperty.creationDate, true, this.initialQuery);
    this.studiesProgressSubscription = this.canopyPusher.studiesProgress.subscribe((data: StudiesProgress) => this.onStudiesProgress(data));
    this.studyBuildProgressSubscription = this.canopyPusher.studyBuildProgress.subscribe((data: StudyBuildProgress) => this.onStudyBuildProgress(data));
    this.studySubmittedSubscription = this.studySubmittedEvents.studySubmitted.subscribe(() => this.reloadFilter());
    this.loadTask = this.load();
  }

  public get isTenantAdministrator(): boolean {
    return this.authenticationService.isTenantAdministrator;
  }

  public ngOnDestroy() {
    if (this.studiesProgressSubscription) {
      this.studiesProgressSubscription.unsubscribe();
    }

    if (this.studyBuildProgressSubscription) {
      this.studyBuildProgressSubscription.unsubscribe();
    }

    if (this.studySubmittedSubscription) {
      this.studySubmittedSubscription.unsubscribe();
    }
  }

  public toggleProperties(){
    this.showProperties
      = this.localUserSettingsManager.state.showCustomPropertiesInLists
      = !this.localUserSettingsManager.state.showCustomPropertiesInLists;
    this.localUserSettingsManager.save();
  }

  public get canPersistFilter(): boolean {
    // We don't persist if an initial query is specified, i.e. when
    // showing a list of Telemetry studies.
    return !this.initialQuery;
  }

  public get getLoadGroupResultsDelegate(): () => Promise<DocumentCustomPropertyGroup[]>{
    return () => this.loadGroupResults();
  }

  public async loadGroupResults(): Promise<DocumentCustomPropertyGroup[]>{
    let result = await this.studyStub.getStudies(this.userData.tenant, undefined, false, FilteredDocumentsResultType.groupOnly);
    return result.groupResults;
  }

  public async reloadFilter(){
    try {
      this.isReloading = true;
      this.errorMessage = undefined;

      if(this.loadTask){
        try{
          await this.loadTask;
        } catch(e){}
      }

      let studiesTask = this.loadTask = this.studyStub.getStudies(this.userData.tenant, this.filter);
      let studiesResult = await studiesTask;
      this.studies = [];
      this.addQueryResults(studiesResult);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
    this.isReloading = false;
  }

  public async load() {
    try {
      this.showProperties
        = this.localUserSettingsManager.state.showCustomPropertiesInLists;

      this.studyTypeMap = await this.studyTypeLookup.getStudyTypeMap();

      if(this.canPersistFilter){
        // Wait for the persisted filter to be loaded,
        // rather than loading the unfiltered results.
        this.studies = [];
        this.isReloading = true;
        return;
      }

      this.reloadFilter();
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async loadNext() {
    try {
      if(!this.lastResult.queryResults.hasMoreResults){
        return;
      }

      if(this.loadTask){
        await this.loadTask;
      }

      this.isReloading = true;
      this.filter.continuationToken = this.lastResult.queryResults.continuationToken;
      let studiesTask = this.loadTask = this.studyStub.getStudies(this.userData.tenant, this.filter);
      let studiesResult = await studiesTask;
      this.addQueryResults(studiesResult);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
    this.isReloading = false;
  }

  public addQueryResults(studiesResult: GetStudiesQueryResult) {
    this.lastResult = studiesResult;

    if(!this.map){
      this.map = this.createUserInformationMaps.execute(studiesResult.userInformation);
    } else{
      this.createUserInformationMaps.append(studiesResult.userInformation, this.map);
    }

    this.studies.push(...studiesResult.queryResults.documents.map(s => {
      let jobCount = s.data.jobCount || s.data.dispatchedJobCount;
      let isCompleted = jobCount && jobCount === s.data.completedJobCount;
      let hasErrors = !!(s.data.errorMessages && s.data.errorMessages.length);
      let isBuilding = !isCompleted && !hasErrors && (!jobCount || jobCount !== s.data.dispatchedJobCount);
      let user = this.map.users[s.userId];
      let studyType = this.studyTypeMap[s.data.studyType];
      return new StudySummary(
        s.tenantId,
        s.userId,
        s.documentId,
        s.name,
        this.truncate(s.name),
        user ? user.username : s.userId,
        this.dayjs.fromNow(s.creationDate),
        this.dayjs.toDateTime(s.creationDate),
        this.dayjs.fromNow(s.modifiedDate),
        isCompleted,
        isBuilding,
        hasErrors,
        getExecutionTimeString(s.data.executionTimeSeconds),
        jobCount,
        s.data.dispatchedJobCount,
        s.data.completedJobCount,
        s.data.succeededJobCount,
        s.data.studyType,
        studyType ? studyType.name : s.data.studyType,
        s.userId === this.userData.sub,
        s.deleteRequested,
        s.parentWorksheetId,
        CustomPropertyUtilities.objectToList(s.properties),
        s.simVersion,
        {
          session: s.supportSession,
          userInformation: studiesResult.userInformation
        },
        s.data.succeededComputeCredits,
        s.data.succeededStorageCredits
      );
    }));
  }

  public filterChanged(newFilter: ListFilterData){
    this.filter = newFilter;
    //this.router.navigate(['/studies', { filter: this.rison.encodeObject(newFilter) }]);
    this.reloadFilter();
  }

  public onStudySelected(study: StudySummary) {
    this.studySelected.emit(study);
  }

  public async onStudiesProgress(data: StudiesProgress){
    try {
      if(this.loadTask){
        await this.loadTask;
      }

      if(!this.studies){
        return;
      }

      for(let item of data.items) {
        for(let study of this.studies){
          if(study.studyId === item.studyId){
            study.isCompleted = study.jobCount && study.jobCount === item.completedJobCount;
            study.executionTime = getExecutionTimeString(item.executionTimeMs / 1000);
            study.completedJobCount = item.completedJobCount;
            study.succeededJobCount = item.succeededJobCount;
            study.succeededComputeCredits = item.succeededComputeCredits;
            study.succeededStorageCredits = item.succeededStorageCredits;
          }
        }
      }
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async onStudyBuildProgress(data: StudyBuildProgress){
    try {
      if(this.loadTask){
        await this.loadTask;
      }

      if(!this.studies){
        return;
      }

      for(let study of this.studies){
        if(study.studyId === data.studyId && study.dispatchedJobCount <= data.dispatchedJobCount){
          if(data.errorMessages){
            study.hasErrors = !!data.errorMessages.length;
            study.isBuilding = false;
            return;
          }

          study.jobCount = data.jobCount;
          study.dispatchedJobCount = data.dispatchedJobCount;
          study.isBuilding = data.dispatchedJobCount !== data.jobCount;
          break;
        }
      }
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public renderWithCustomLineBreaks(input: string){
    return renderWithCustomLineBreaks(input);
  }

  public cssSanitize(input: string): string {
    return cssSanitize(input);
  }

  public styleSanitize(input: string){
    return this.sanitizer.bypassSecurityTrustStyle(input);
  }

  public async delete(study: StudySummary) {
    try {
      if(study.isOwner){
        let isConfirmed = await this.confirmationDialog.show(
          `Are you sure you want to delete this study? This action cannot be reversed.`,
          'Confirm Delete Study',
          'Permanently Delete Study');

        if (isConfirmed) {
          study.isDeleting = true;
          await this.studyStub.deleteStudy(this.userData.tenant, study.studyId);
          this.studies = this.studies.filter(v => v.studyId !== study.studyId);
        }
      }
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }

    study.isDeleting = false;
  }

  public get shouldUndelete(): boolean {
    const selected = this.studies.filter(v => v.isSelected);
    return !!selected.length && selected.every(v => v.deleteRequested);
  }

  public async deleteSelected() {
    try {
      const shouldUndelete = this.shouldUndelete;
      let studiesToDelete = this.studies.filter(v => v.isSelected);

      if(shouldUndelete){
        studiesToDelete = studiesToDelete.filter(v => v.deleteRequested);
      } else{
        studiesToDelete = studiesToDelete.filter(v => !v.deleteRequested);
      }

      let count = studiesToDelete.length;
      if(count === 0) {
        return;
      }

      let typeDescription = count === 1 ? 'Study' : 'Studies';

      let isConfirmed: boolean;
      if(shouldUndelete){
        isConfirmed = !!(await this.confirmationDialog.show(
          `Are you sure you want to restore ${count} ${typeDescription}?`,
          `Confirm Restore ${count} ${typeDescription}`,
          `Restore ${count} ${typeDescription}`));
      } else{
        isConfirmed = !!(await this.confirmationDialog.show(
          `Are you sure you want to delete ${count} ${typeDescription}? This action cannot be reversed.`,
          `Confirm Delete ${count} ${typeDescription}`,
          `Permanently Delete ${count} ${typeDescription}`));
      }

      if (isConfirmed) {
        try{
          this.isDeleting = true;
          for(let study of studiesToDelete){
            study.isDeleting = true;
          }

          // We reverse it so the order doesn't change in the UI when default modified date sorting is applied.
          studiesToDelete.reverse();
          for(let study of studiesToDelete){
            await this.studyStub.deleteStudy(this.userData.tenant, study.studyId, shouldUndelete);
            study.deleteRequested = !shouldUndelete;
            //this.studies = this.studies.filter(v => v.studyId !== study.studyId);
          }

          await this.reloadFilter();
        } finally{
          this.isDeleting = false;
          for(let study of studiesToDelete){
            study.isDeleting = false;
          }
        }
      }
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public get canSelectAll(): boolean {
    return this.userStudies.some(v => !v.isSelected);
  }

  public toggleSelectAll(){
    try {
      if(this.canSelectAll){
        this.userStudies.forEach(v => v.isSelected = true);
      } else{
        this.studies.forEach(v => v.isSelected = false);
      }
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public get userStudies(): ReadonlyArray<StudySummary> {
    return this.studies.filter(v => v.isOwner);
  }

  public stageToResultsStagingArea(study: StudySummary) {
    try {
      this.stageStudy.toResultsStagingArea(
        study.tenantId, study.studyId, study.name, study.jobCount);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async stageToStudyStagingArea(study: StudySummary) {
    try {
      await this.stageStudy.toStudyStagingArea(
        study.tenantId, study.studyId, study.name, study.jobCount);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async goToJob(study: StudySummary){
    try {
      await this.goToJobService.execute(study.tenantId, study.studyId, study.jobCount);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public trackById(index: number, item: StudySummary) {
    return item.studyId;
  }

  private truncate(input: string): string {
    if(input.length > 20){
      return input.substr(0, 20) + '…';
    }

    return input;
  }
}

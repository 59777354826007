import {CONNECTION_ERROR_TEXT} from '../../../constants';
import {UNEXPECTED_ERROR_TEXT} from '../../../constants';
import {Injectable} from '@angular/core';
import {LogError} from '../../../log-error.service';

@Injectable()
export class GetFriendlyErrorAndLog {
  constructor(private logError: LogError) {
  }

  public execute(error: any): string {
    if (error.isCancellationError) {
      return undefined;
    }

    this.logError.execute(error);

    if (error.isDisplayable) {
      // These error messages are fine to display.
      return error.message || UNEXPECTED_ERROR_TEXT;
    }

    if (error.isConnectionError) {
      return CONNECTION_ERROR_TEXT;
    }

    // An unknown error.  Try to log it and return a generic message.
    return UNEXPECTED_ERROR_TEXT;
  }
}

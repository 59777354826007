import { IChannelMetadata } from '../channel-data-loaders/source-loader';


export class GetFirstChannelName {
  public execute(allChannels: ReadonlyArray<IChannelMetadata>, possibleChannelNames: ReadonlyArray<string>): string | undefined {
    for (let possibleChannelName of possibleChannelNames) {
      let foundChannel = allChannels.find(v => v.name === possibleChannelName);
      if (foundChannel) {
        return foundChannel.name;
      }
    }

    return undefined;
  }
}

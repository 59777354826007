<div class="container-fluid" id="view-support-sessions-page">
  <div class="card">
    <h1 class="card-header">Support sessions</h1>
    <div class="card-body">
      <div>

        <p>
          @if (documents) {
            <button class="btn btn-secondary" (click)="reload()">Refresh</button>
          }
        </p>

        <cs-error-message [message]="errorMessage"></cs-error-message>

        <div class="loader-padding">
          @if (!documents) {
            <cs-loader></cs-loader>
          }
        </div>

        @if (documents) {
          <div>
            <table id="documents-table" class="table documents-table last-item">
              <thead>
                <tr>
                  <th>User</th>
                  <th>Name</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                @for (document of documents; track document; let index = $index) {
                  <tr>
                    <td>
                      <div class="multi-line-container">
                        {{document.username}}
                        <div class="second-line">{{document.tenantName}}</div>
                      </div>
                    </td>
                    <td>
                      <div class="multi-line-container">
                        @if (document.isStudy) {
                          <a class="select-document-link"
                            [routerLink]="['/studies', document.tenantId, document.documentId]">
                            {{document.name}}
                          </a>
                        }
                        @if (document.isConfig) {
                          <a class="select-document-link"
                            [routerLink]="['/configs', document.configType.pluralKey, document.tenantId, document.documentId, 'edit']">
                            {{document.name}}
                          </a>
                        }
                        @if (document.isWorksheet) {
                          <a class="select-document-link"
                            [routerLink]="['/worksheets', document.tenantId, document.documentId]">
                            {{document.name}}
                          </a>
                        }
                        <div class="second-line">{{document.isStudy ? document.type : document.subType}}</div>
                      </div>
                    </td>
                    <td class="session-information">
                      @if (document.supportSession.session) {
                        <cs-support-session-launcher
                          class="support-session-launcher-component"
                          [tenantId]="document.tenantId" [documentId]="document.documentId"
                          [documentType]="document.type" [documentSubType]="document.subType"
                          [supportSession]="document.supportSession"
                        (supportSessionChanged)="document.supportSession = $event"></cs-support-session-launcher>
                      }
                    </td>
                    <td>
                      @if (document.isStudy) {
                        <cs-linting-engine-dialog-launcher
                          [compactButton]="true"
                          [tenantId]="document.tenantId"
                          [userId]="document.userId"
                        [studyId]="document.documentId"></cs-linting-engine-dialog-launcher>
                      }
                      @if (document.isConfig) {
                        <cs-linting-engine-dialog-launcher
                          [compactButton]="true"
                          [tenantId]="document.tenantId"
                          [userId]="document.userId"
                        [configId]="document.documentId"></cs-linting-engine-dialog-launcher>
                      }
                      @if (document.isStudy) {
                        <button class="btn btn-secondary btn-icon" (click)="createWorksheet(document, $event)"><i class="fa fa-th"></i></button>
                      }
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        }
      </div>
    </div>
  </div>
</div>

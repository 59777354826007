import { ChannelNameStyle } from '../../viewers/channel-data-loaders/channel-name-style';
import { NavigationStationConfig, NavigationStationConfigBuilder } from './navigation-station-config-builder';
import { SiteHooks } from '../../site-hooks';
import { UrlFileLoader } from '../../url-file-loader';
import { DomainNewsCache } from '../../viewers/domain-news-cache';
import { ConfigData, ConfigPreviewConfigBuilder } from './config-preview-config-builder-base';
import { Units } from '../../units';

/**
 * A config builder for previewing telemetry data.
 */
export class TelemetryPreviewConfigBuilder extends ConfigPreviewConfigBuilder implements NavigationStationConfigBuilder {

  /**
   * Constructs a new Telemetry preview config builder.
   * @param fileLoader The file loader.
   * @param siteHooks The site hooks.
   * @param configs The configs for this builder session.
   * @param initialHeight The initial height of the chart.
   */
  constructor(
    fileLoader: UrlFileLoader,
    siteHooks: SiteHooks,
    configs: ReadonlyArray<ConfigData>,
    private readonly initialHeight: number = 0) {
    super('Telemetry', fileLoader, siteHooks, configs, undefined);
  }

  /**
   * @inheritdoc
   */
  public async build(): Promise<NavigationStationConfig> {

    // Create the empty navigation station config object.
    let config: NavigationStationConfig = {
      channelNameStyle: ChannelNameStyle.Generic,
      sharedStates: [],
      views: []
    };

    // Create a map of channel names to SI units. The data will be mapped to those
    // units before being plotted.
    let unitsMap: MutableUnitsMap = {};
    for (let configData of this.configs) {
      if (configData.data && configData.data.channels && configData.data.channels.length) {
        unitsMap = this.mapChannelsArrayToUnits(configData.data.channels, unitsMap);
      }
    }

    let defaultGridSlot = {
      x: 0,
      y: 0,
      width: 12,
      height: this.initialHeight || Math.max(6, Object.keys(unitsMap).length),
    };

    let domainNewsCache = new DomainNewsCache();

    // Create the chart based on the data in the telemetry config.
    // We supply a map function to map the data to SI units.
    await this.createConfigAreaChart(
      'channels',
      'Telemetry',
      'tRun',
      config,
      defaultGridSlot,
      domainNewsCache,
      {},
      {
        arrayIsValidValue: true,
        map: channels => this.mapChannelsArrayToObject(channels),
        unitsMap,
        layoutName: 'Default-Telemetry-tRun',
      });

    return config;
  }

  /**
   * Maps the channels array to a map of channel names to SI units.
   * @param channels The channels array.
   * @param initialValue The initial value for the map.
   * @returns The map of channel names to SI units.
   */
  private mapChannelsArrayToUnits(channels: ReadonlyArray<Channel>, initialValue: MutableUnitsMap): MutableUnitsMap {
    return channels
      .reduce((p, c) => {
        p[this.mapName(c.name)] = Units.getSiUnit(c.units);
        return p;
      }, initialValue);
  }

  /**
   * Maps the channels array to a map of channel names to data arrays, with the
   * data converted to SI units.
   * @param channels The channels array.
   * @returns The map of channel names to data arrays.
   */
  private mapChannelsArrayToObject(channels: ReadonlyArray<Channel>): MutableChannelDataMap {
    return channels.reduce((p, c) => {
      p[this.mapName(c.name)] = Units.convertValuesToSi(c.data, c.units, true);
      return p;
    }, {} as MutableChannelDataMap);
  }

  /**
   * Maps the channel name to a standard name, if necessary.
   * @param channelName The channel name.
   * @returns The mapped name.
   */
  private mapName(channelName: string): string {
    if (channelName === 'timeBase') {
      return 'tRun';
    }

    return channelName;
  }
}

/**
 * The channel data.
 */
interface Channel {

  /**
   * The name of the channel.
   */
  name: string;

  /**
   * The units of the channel.
   */
  units: string;

  /**
   * The data for the channel.
   */
  description: string;

  /**
   * The data for the channel.
   */
  data: number[];
}

/**
 * The units map.
 */
interface MutableUnitsMap {

  /**
   * The map of names to units.
   */
  [key: string]: string;
}

/**
 * The channel data map.
 */
interface MutableChannelDataMap {

  /**
   * The map of names to data arrays.
   */
  [name: string]: ReadonlyArray<number>;
}

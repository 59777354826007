<div class="card">
  <h1 class="card-header">
    Downloads
  </h1>
  <div class="card-body">
    <cs-error-message [message]="errorMessage"></cs-error-message>
    @if (!downloads && !errorMessage) {
      <cs-loader></cs-loader>
    }

    @if (downloads) {
      @if (downloads.length === 0) {
        <p class="last-item">
          No downloads found for this sim version.
        </p>
      }
      <ul class="last-item fa-ul">
        @for (download of downloads; track download) {
          <li>
            <i class="fa-li fa fa-download"></i>
            <a href="{{download.url}}">{{download.name}}</a>
          </li>
        }
      </ul>
    }
  </div>
</div>

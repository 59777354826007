
/**
 * A mapping between old and new indices of an array.
 */
export class ArrayMappings {

  /**
   * The mapping from old to new indices.
   */
  public readonly oldIndexToNewIndex: ReadonlyArray<number>;

  /**
   * Creates an instance of ArrayMappings.
   * @param newIndexToOldIndex The mapping from new to old indices.
   */
  constructor(
    public readonly newIndexToOldIndex: ReadonlyArray<number>) {
    const oldIndexToNewIndex = Array(newIndexToOldIndex.length);

    for (let i = 0; i < oldIndexToNewIndex.length; ++i) {
      oldIndexToNewIndex[newIndexToOldIndex[i]] = i;
    }

    this.oldIndexToNewIndex = oldIndexToNewIndex;
  }
}

import {Component, Input} from '@angular/core';
import {
  ConditionOperatorViewModel,
  ConditionOperatorToViewModelMap, ConditionOperatorsMap,
  ResolvedFilterCondition
} from './filter-query-builder-types';
import {GetTenantUsersQueryResult} from '../../../generated/api-stubs';

@Component({
  selector: 'cs-filter-query-renderer-condition',
  templateUrl: './filter-query-renderer-condition.component.html'
})
export class FilterQueryRendererConditionComponent {
  @Input() public condition: ResolvedFilterCondition;
  @Input() public tenantUsers: GetTenantUsersQueryResult;

  public readonly conditionOperatorsMap: ConditionOperatorToViewModelMap = ConditionOperatorsMap;

  public get operator(): ConditionOperatorViewModel{
    return this.condition ? this.conditionOperatorsMap[this.condition.operator] : undefined;
  }

  public get displayValue(): string {
    if(this.condition){
      if(this.condition.name === 'userId' && this.tenantUsers){
        let user = this.tenantUsers.users.find(v => v.userId === this.condition.value);
        if(user){
          return user.username;
        }
      }

      return this.condition.value;
    }

    return undefined;
  }
}

/*
 * Usage:
 *   value | fromNow
 * Example:
 *   {{ '2016-12-02T13:44:28.8378529Z' | fromNow }}
 *   formats to: 3 weeks ago
 */
import {Pipe, PipeTransform} from '@angular/core';
import {Dayjs} from './dayjs.service';

@Pipe({name: 'fromNow'})
export class FromNowPipe implements PipeTransform {

  constructor(
    private dayjs: Dayjs){
  }

  transform(value: string, parts: number = 1): any {
    if(!value){
      return '';
    }

    return this.dayjs.fromNowLong(value, parts);
  }
}

import { Injectable } from '@angular/core';
import { RowMetadataViewModel } from '../../row-metadata-view-model';
import { ICommandContext } from '../worksheet-command';
import { ReplaceResult } from '../../worksheet-item-component-base';
import { SetConfigOrAllReferences } from '../set-config-or-all-references.service';
import { StudyState } from '../../../simulations/study-staging-area/study-staging-area.service';
import { ConfigReference, DocumentSubType } from '../../../../generated/api-stubs';
import { ImportTelemetryConfigFromStagingArea } from './import-telemetry-config-from-staging-area';
import { ImportStandardConfigFromStagingArea } from './import-standard-config-from-staging-area';
import { ConfigViewModel } from '../../config-view-model';

/**
 * A service for importing a specific config type from the staging area.
 */
@Injectable()
export class ImportConfigFromStagingArea {

  /**
   * Creates an instance of ImportConfigFromStagingArea.
   * @param setConfigOrAllReferences The service for setting the config or all references.
   * @param importStandardConfigFromStagingArea The service for importing a standard config from the staging area.
   * @param importTelemetryConfigFromStagingArea The service for importing a telemetry config from the staging area.
   */
  constructor(
    private readonly setConfigOrAllReferences: SetConfigOrAllReferences,
    private readonly importStandardConfigFromStagingArea: ImportStandardConfigFromStagingArea,
    private readonly importTelemetryConfigFromStagingArea: ImportTelemetryConfigFromStagingArea) {
  }

  /**
   * Imports the specified config type from the staging area.
   * @param state The study state in the staging area.
   * @param configToImport The config type to import.
   * @param context The command context.
   * @param replaceResult The result of asking the user if they would like to replace all references for the selected config.
   */
  public async execute(
    state: StudyState,
    configToImport: DocumentSubType,
    context: ICommandContext<RowMetadataViewModel | ConfigViewModel>,
    replaceResult: ReplaceResult) {

    // Get the required study input from the staging area.
    const studyInput = state.getInput(configToImport);

    if (!studyInput) {
      // If it doesn't exist, just return.
      return;
    }

    // Get the config reference, saving a copy first if necessary.
    let configReference: ConfigReference | undefined;
    if (configToImport === DocumentSubType.telemetry) {
      configReference = this.importTelemetryConfigFromStagingArea.execute(studyInput);
    } else {
      configReference = await this.importStandardConfigFromStagingArea.execute(studyInput);
    }

    if (!configReference) {
      return;
    }

    // Add the reference to the worksheet.
    await this.setConfigOrAllReferences.execute(
      context.target.row.getOrCreateConfig(configToImport),
      context.selectedRows,
      configReference,
      replaceResult);
  }
}

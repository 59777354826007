import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {
  ConditionOperatorViewModel, ConditionOperatorToViewModelMap, ConditionOperators, ConditionOperatorsMap,
  ConditionSourceViewModel, ConditionSourceItemViewModel, ConditionSourceItemSetViewModel, ConditionSourceItemType,
  FilterConditionViewModel,
  IFilterConditionDataSource,
  IFilterQueryBuilderManager, DefaultConditionNameValidators
} from './filter-query-builder-types';
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {DisplayableError} from '../../common/errors/errors';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CanopyValidators} from '../../common/forms/canopy-validators.service';
import {Subscription} from 'rxjs';
import {GetTenantUsersQueryResult} from '../../../generated/api-stubs';
import {CanopyAutocomplete} from '../../common/canopy-autocomplete.service';
import {createRandomString} from '../../visualizations/create-random-string';
import {Timer} from '../../common/timer.service';

const StringValueValidators = [Validators.required];
const NumericValueValidators = [Validators.required, CanopyValidators.numeric];
const DateTimeValueValidators = [Validators.required, CanopyValidators.dateTime];

interface ConditionSourcesMap {
  [id: string]: ConditionSourceViewModel;
}

@Component({
  selector: 'cs-filter-query-builder-condition',
  templateUrl: './filter-query-builder-condition.component.html',
  styleUrls: ['./filter-query-builder-condition.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FilterQueryBuilderConditionComponent implements OnInit, OnChanges, OnDestroy, IFilterConditionDataSource{
  @Input() public manager: IFilterQueryBuilderManager;
  @Input() public condition: FilterConditionViewModel;
  @Input() public tenantUsers: GetTenantUsersQueryResult;

  public errorMessage: string;

  public conditionSources: ReadonlyArray<ConditionSourceViewModel> = [];
  public conditionSourceItemSet: ConditionSourceItemSetViewModel;

  public conditionOperators: ReadonlyArray<ConditionOperatorViewModel> = ConditionOperators;
  public readonly conditionOperatorsMap: ConditionOperatorToViewModelMap = ConditionOperatorsMap;

  public conditionSourcesMap: ConditionSourcesMap = {};

  public form: UntypedFormGroup;
  public conditionNameControl: UntypedFormControl = new UntypedFormControl('', DefaultConditionNameValidators);
  public conditionValueControl: UntypedFormControl = new UntypedFormControl('', StringValueValidators);
  public formChangedSubscription: Subscription;

  public conditionId: string;
  public nameElementId: string;
  public nameAutocomplete: any;
  public valueElementId: string;
  public valueAutocomplete: any;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly timer: Timer,
    private readonly canopyAutocomplete: CanopyAutocomplete,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  public ngOnInit(): void {
    this.load();
  }

  public ngOnDestroy(): void {
    this.unsubscribeFromTransientEvents();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.condition.dataSource = this;
    this.updateFormValuesFromViewModel();
  }

  public async load(){
    try{
      this.conditionId = createRandomString(10);
      this.nameElementId = `${this.conditionId}-name-textbox`;
      this.valueElementId = `${this.conditionId}-value-textbox`;

      let formData = {
        conditionValueControl: this.conditionValueControl,
        conditionNameControl: this.conditionNameControl,
      };

      this.form = this.formBuilder.group(formData);

      this.condition.dataSource = this;

      this.conditionSources = this.manager.getConditionSources() || [];

      if(!this.conditionSources.length){
        throw new DisplayableError('No condition sources found.');
      }

      if(!this.conditionOperators.length){
        throw new DisplayableError('No condition operators found.');
      }

      this.conditionSourcesMap = this.conditionSources.reduce<ConditionSourcesMap>((p, c) => {
        p[c.id] = c;
        return p;
      }, {});

      if(!this.condition.operator){
        this.condition.operator = this.conditionOperators[0];
      }

      await this.timer.yield();

      this.nameAutocomplete = this.canopyAutocomplete.create(
        this.nameElementId,
        this.conditionNameControl,
        () => {
          if(this.manager.hasConditionSourceItemsChanged){
            this.updateConditionSourceItemSet();
          }

          return this.getNameAutocompleteListFromSourceItemSet();
        });

      this.valueAutocomplete = this.canopyAutocomplete.create(
        this.valueElementId,
        this.conditionValueControl, () => {
          let items: string[] = [];
          if(this.condition.name){
            items.push(...this.condition.name.values.map(v => v.value));
          }

          return items;
        });

      this.onConditionSourceChanged(this.condition.source ? this.condition.source.id : this.conditionSources[0].id);

      this.ngOnChanges(null);

      this.subscribeToTransientEvents();
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public onConditionSourceChanged(conditionSourceId: string){
    try{
      this.condition.source = this.conditionSourcesMap[conditionSourceId];

      if(!this.condition.source && this.conditionSources.length){
        this.condition.source = this.conditionSources[0];
      }

      this.updateConditionSourceItemSet();

      if(!this.conditionSourceItemSet.isFiniteSet){
        let autocompleteOptions = this.conditionSourceItemSet.autocompleteOptions;
        if(autocompleteOptions){
          this.nameAutocomplete.sort = autocompleteOptions.sort;
          this.nameAutocomplete.filter = autocompleteOptions.filter;
        }

        this.nameAutocomplete.list = this.getNameAutocompleteListFromSourceItemSet();
      }
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  private getNameAutocompleteListFromSourceItemSet(){
    if(!this.conditionSourceItemSet){
      return [];
    }

    let autocompleteOptions = this.conditionSourceItemSet.autocompleteOptions;
    if(autocompleteOptions){
      return autocompleteOptions.list;
    } else{
      return this.conditionSourceItemSet.items.map(v => v.name);
    }
  }

  private updateConditionSourceItemSet(){
    try{
      this.conditionSourceItemSet = this.manager.getConditionSourceItems(this.condition.source.id);

      if(this.condition.name){
        let resolvedConditionName = this.conditionSourceItemSet.get(this.condition.name.name);
        if(resolvedConditionName){
          this.condition.name = resolvedConditionName;
        } else if(this.conditionSourceItemSet.isFiniteSet && this.conditionSourceItemSet.items.length) {
          this.condition.name = this.conditionSourceItemSet.items[0];
        } else{
          // We need to refresh the condition name object as it may contain
          // operators and values from another condition source.
          this.condition.name = this.manager.getConditionSourceItemViewModel(
            this.condition.source.id,
            this.condition.name.name);
        }
      } else{
        this.condition.name = new ConditionSourceItemViewModel('', '', null, ConditionSourceItemType.string);
      }

      this.conditionNameControl.setValidators(this.condition.source.conditionNameValidators);
      if(!this.conditionSourceItemSet.isFiniteSet) {
        this.conditionNameControl.updateValueAndValidity();
      }

      this.updateConditionOperators();
      this.updateConditionValue();

      this.notifyChanged();
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  private updateConditionOperators() {
    this.conditionOperators = this.getNameConditionOperators();
    if (this.conditionOperators.length && !this.conditionOperators.some(v => v.id === this.condition.operator.id)) {
      this.condition.operator = this.conditionOperators[0];
    }
  }

  private updateConditionValue() {
    if(this.condition.name){
      switch(this.condition.name.type){
        case ConditionSourceItemType.number:
          this.conditionValueControl.setValidators(NumericValueValidators);
          break;

        case ConditionSourceItemType.dateTime:
          this.conditionValueControl.setValidators(DateTimeValueValidators);
          break;

        default:
          this.conditionValueControl.setValidators(StringValueValidators);
          break;
      }

      if(!this.condition.name.isFiniteValues){
        this.conditionValueControl.updateValueAndValidity();
      }

      if(this.condition.name.isFiniteValues
        && this.condition.name.values.length
        && !this.condition.name.values.some(v => v.value === this.condition.value)){
        this.condition.value = this.condition.name.values[0].value;
      }
    }
  }

  public getNameConditionOperators(): ReadonlyArray<ConditionOperatorViewModel>{
    if(this.condition.name && this.condition.name.operators && this.condition.name.operators.length){
      return this.condition.name.operators.map(v => this.conditionOperatorsMap[v]);
    }

    return ConditionOperators;
  }

  public onConditionNameChanged(conditionName: string) {
    try{
      this.condition.name = this.getConditionNameViewModel(conditionName);
      this.updateConditionOperators();
      this.updateConditionValue();
      this.notifyChanged();
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public onConditionOperatorChanged(conditionOperatorName: string) {
    try{
      this.condition.operator = this.conditionOperatorsMap[conditionOperatorName];
      this.notifyChanged();
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public onConditionValueChanged(conditionValue: string) {
    try{
      this.condition.value = conditionValue;
      this.notifyChanged();
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public subscribeToTransientEvents(){
    this.formChangedSubscription = this.conditionNameControl.valueChanges.subscribe(() => {
      this.onConditionNameChanged(this.conditionNameControl.value);
    });

    this.formChangedSubscription.add(this.conditionValueControl.valueChanges.subscribe(() => {
      this.onConditionValueChanged(this.conditionValueControl.value);
    }));
  }

  public unsubscribeFromTransientEvents(){
    if(this.formChangedSubscription){
      this.formChangedSubscription.unsubscribe();
      this.formChangedSubscription = undefined;
    }
  }

  public notifyChanged(){
    this.updateFormValuesFromViewModel();
    this.manager.notifyChanged();
  }

  public populate(){

    if(!this.condition.operator.requiresValue && this.condition.value){
      this.condition.value = '';
      this.updateFormValuesFromViewModel();
    }

    if((!this.conditionSourceItemSet || !this.conditionSourceItemSet.isFiniteSet)
      && !this.conditionNameControl.valid){
      return false;
    }

    if(this.condition.operator.requiresValue
      && !this.conditionValueControl.valid){
      return false;
    }

    return true;
  }

  private getConditionNameViewModel(conditionName: string): ConditionSourceItemViewModel{
    let result: ConditionSourceItemViewModel;

    if(this.conditionSourceItemSet){
      result = this.conditionSourceItemSet.get(conditionName);
    }

    if(!result){
      result = new ConditionSourceItemViewModel(conditionName, conditionName, null, ConditionSourceItemType.string);
    }

    return result;
  }

  private updateFormValuesFromViewModel(){
    let conditionName = (this.condition.name ? this.condition.name.name : '');

    if(this.conditionNameControl.value !== conditionName){
      this.conditionNameControl.setValue(conditionName);
    }

    if(this.conditionValueControl.value !== this.condition.value){
      this.conditionValueControl.setValue(this.condition.value);
    }
  }
}

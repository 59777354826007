import { RequestedLayoutIds, LoadViewerLayoutResult, SiteHooks, ResolvedLayoutId, ViewerMetadata } from '../../site-hooks';
import { UrlFileLoader } from '../../url-file-loader';
import { StudyJob } from '../../study-job';
import { SimType } from '../../sim-type';
import { POINT_MULTI_PLOT_VIEWER_TYPE } from '../../viewers/line-plot-viewer/line-plot-viewer';
import { POINT_SCATTER_PLOT_VIEWER_TYPE } from '../../viewers/scatter-plot-viewer/scatter-plot-viewer';
import { upgradeMultiPlotViewerLayout } from '../../viewers/multi-plot-viewer-base/multi-plot-viewer-base';
import { IRectangle } from '../../viewers/rectangle';
import { NavigationStationConfig, NavigationStationConfigBuilder } from './navigation-station-config-builder';
import { ISize } from '../../viewers/size';

export const DEFAULT_LAYOUT_ID = 'Default';

/**
 * A base class for building the navigation station configs.
 */
export abstract class ConfigBuilderBase implements NavigationStationConfigBuilder {

  /**
   * Constructs a new config builder.
   * @param fileLoader The file loader.
   * @param siteHooks The site hooks.
   * @param studyJobs The study jobs for this builder session.
   * @param simTypes The sim types for this builder session.
   */
  constructor(
    public readonly fileLoader: UrlFileLoader,
    public readonly siteHooks: SiteHooks,
    public readonly studyJobs: ReadonlyArray<StudyJob>,
    public readonly simTypes: ReadonlyArray<SimType>) {
  }

  /**
   * @inheritdoc
   */
  public abstract build(): Promise<NavigationStationConfig>;

  /**
   * Fetches the viewer metadata based on the requested layout IDs.
   * @param viewerType The viewer type.
   * @param layoutIds The requested layout IDs.
   * @returns The viewer metadata.
   */
  protected async resolveViewerMetadata(
    viewerType: string,
    layoutIds: RequestedLayoutIds | undefined): Promise<ViewerMetadata | undefined> {

    let layoutId = layoutIds ? layoutIds.primary : '';
    let viewerMetadata = await this.siteHooks.loadViewerMetadata(viewerType, layoutId);
    return viewerMetadata;
  }

  /**
   * Fetches the viewer layout.
   * @param viewerType The viewer type.
   * @param layoutIds The requested layout IDs.
   * @param defaultLayoutConfig The default layout config.
   * @param usePassedLayoutConfig Whether to use the passed layout config rather than trying to load one.
   * @returns The resolved viewer layout.
   */
  protected async resolveViewerLayout(
    viewerType: string,
    layoutIds: RequestedLayoutIds,
    defaultLayoutConfig?: any,
    usePassedLayoutConfig?: boolean): Promise<ResolvedViewerLayout> {

    let layoutId = layoutIds.primary;
    let viewerMetadata = await this.siteHooks.loadViewerMetadata(viewerType, layoutId);
    let defaultLayoutId = await this.siteHooks.getViewerDefaultLayoutId(viewerType, layoutIds);
    let layoutResult: LoadViewerLayoutResult | undefined;

    if (usePassedLayoutConfig) {
      // Just use the layout that has been passed in.
      layoutResult = new LoadViewerLayoutResult(layoutId, layoutId, defaultLayoutConfig);
    } else {
      try {
        if (defaultLayoutId) {
          // If the user has set a default layout, load that.
          layoutResult = await this.siteHooks.loadViewerLayout(viewerType, defaultLayoutId);
        } else {
          // Otherwise load the built-in layout.
          layoutResult = await this.tryGetViewerLayoutFromLayoutIds(layoutIds, viewerType);
        }
      } catch (error) {
        if (defaultLayoutConfig) {
          // We failed to load, so just use the passed layout.
          layoutResult = new LoadViewerLayoutResult(layoutId, layoutId, defaultLayoutConfig);
        } else {
          // Revert to original default chart if problem loading user chart (e.g. deleted).
          layoutResult = await this.tryGetViewerLayoutFromLayoutIds(layoutIds, viewerType);
        }
      }
    }

    // If we still don't have a layout with a config, use the default layout config.
    if (!layoutResult || !layoutResult.hasConfig) {
      if (defaultLayoutConfig) {
        layoutResult = new LoadViewerLayoutResult(layoutId, layoutId, defaultLayoutConfig);
      } else {
        // Finally try the most generic default layout for this chart type.
        layoutResult = await this.siteHooks.loadViewerLayout(viewerType, DEFAULT_LAYOUT_ID);
        if (layoutResult) {
          // Treat as if it was the requested default.
          layoutResult = new LoadViewerLayoutResult(layoutId, layoutResult.name, layoutResult.getConfigCopy());
        } else {
          // We don't have a layout config at this point.
          layoutResult = new LoadViewerLayoutResult(layoutId, layoutId, undefined);
        }
      }
    }

    // Perform any necessary upgrades to the layout.
    if (layoutResult.hasConfig && (viewerType === POINT_MULTI_PLOT_VIEWER_TYPE || viewerType === POINT_SCATTER_PLOT_VIEWER_TYPE)) {
      let layoutConfig = layoutResult.getConfigCopy();
      upgradeMultiPlotViewerLayout(layoutConfig);
      layoutResult = new LoadViewerLayoutResult(layoutResult.layoutId, layoutResult.name, layoutConfig);
    }

    return new ResolvedViewerLayout(
      layoutIds,
      defaultLayoutId || layoutId,
      layoutResult,
      viewerMetadata);
  }

  /**
   * Gets the grid slot for the viewer.
   * @param defaultGridSlot The default grid slot.
   * @param viewerMetadata The viewer metadata.
   * @returns The grid slot.
   */
  protected getGridSlot(defaultGridSlot: ISize, viewerMetadata: ViewerMetadata | undefined): IRectangle {
    if (viewerMetadata) {
      // X and Y are now ignored.
      return {
        x: 0,
        y: 0,
        width: viewerMetadata.size.width,
        height: viewerMetadata.size.height
      };
    }

    return {
      x: 0,
      y: 0,
      width: defaultGridSlot.width,
      height: defaultGridSlot.height
    };
  }

  /**
   * Given a set of requested layout IDs, tries to load the viewer layout from the primary ID and then the fallback IDs.
   * @param layoutIds The requested layout IDs.
   * @param viewerType The viewer type.
   * @returns The loaded viewer layout.
   */
  private async tryGetViewerLayoutFromLayoutIds(layoutIds: RequestedLayoutIds, viewerType: string): Promise<LoadViewerLayoutResult | undefined> {
    let layoutIdsToTry = [layoutIds.primary, ...layoutIds.fallback];
    let layout: LoadViewerLayoutResult;
    for (let layoutId of layoutIdsToTry) {
      layout = await this.siteHooks.loadViewerLayout(viewerType, layoutId);
      if (layout) {
        // We don't want to use the fallback layout ID if that was the one which was loaded.
        // We mask it with the primary ID.
        return new LoadViewerLayoutResult(
          layoutIds.primary,
          layoutIds.primary,
          layout.getConfigCopy());
      }
    }

    return undefined;
  }
}

/**
 * A resolved viewer layout.
 */
export class ResolvedViewerLayout {

  /**
   * Constructs a new resolved viewer layout.
   * @param requestedLayoutId The requested layout ID.
   * @param defaultViewerId The default viewer ID.
   * @param resolvedLayout The resolved layout.
   * @param viewerMetadata The viewer metadata.
   */
  constructor(
    public readonly requestedLayoutId: RequestedLayoutIds,
    public readonly defaultViewerId: ResolvedLayoutId,
    public readonly resolvedLayout: LoadViewerLayoutResult,
    public readonly viewerMetadata: ViewerMetadata | undefined) {
  }

  /**
   * Gets whether the layout has a config.
   */
  public get hasConfig(): boolean {
    return this.resolvedLayout && this.resolvedLayout.hasConfig;
  }
}

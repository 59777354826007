import {Rule} from '../runner/rule';
import {ConfigContext, ConfigNode} from '../runner/config-context';
import {RuleLogger} from '../runner/rule-logger';
import {isNumber} from '../../../common/is-number';

export const INITIAL_LENGTH_TO_COMPARE = 25;

export class TrackRepeatedValues extends Rule {
  public name = 'Track Repeated Values';
  public configTypes = 'track';
  public simTypes: string[] = null;

  public evaluateConfig(context: ConfigContext, log: RuleLogger) {
    context.recurseNodes((node: ConfigNode) => {
      let value = node.value;
      if (!Array.isArray(value) || !value.every(v => isNumber(v))) {
        return;
      }

      let vector = value as number[];
      let firstValue = vector[0];
      if (vector.every(v => v === firstValue)) {
        return;
      }

      let initialValues: number[];
      let sampleLength = INITIAL_LENGTH_TO_COMPARE / 2;
      do {
        sampleLength *= 2;
        initialValues = vector.slice(0, Math.min(sampleLength, vector.length));
      }
      while(initialValues.every(v => v === initialValues[0]));

      for(let compareStartIndex=sampleLength; compareStartIndex <= vector.length - sampleLength; ++compareStartIndex) {
        let matches = true;
        for(let offset=0; offset < sampleLength; ++offset){
          if(initialValues[offset] !== vector[compareStartIndex + offset]){
            matches = false;
            break;
          }
        }

        if(matches){
          log.warn(`Array may be repeating at index ${compareStartIndex} (total length ${vector.length}.`, node.path);
          break;
        }
      }
    });
  }
}

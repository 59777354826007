<div id="pools-component">
  <div class="container-fluid">
    <div class="card">

      <h1 class="card-header">Pools</h1>
      <div class="card-body">
        <cs-error-message [message]="errorMessage"></cs-error-message>
        @if (!poolsResult) {
          <cs-loader></cs-loader>
        }
        @if (poolsResult) {
          <div>
            <table class="table last-item">
              <thead>
                <tr>
                  <th class="small">ID</th>
                  <th class="small">Dedicated Nodes</th>
                  <th class="small">Low Priority Nodes</th>
                  @for (item of poolsResult.pools[0]?.intervals; track item; let i = $index) {
                    <th class="small">
                      {{i === 0 ? 'Hours ' : ''}}d-{{i}}
                    </th>
                  }
                </tr>
              </thead>
              <tbody>
                @for (pool of poolsResult.pools; track pool) {
                  <tr>
                    <td class="small">{{pool.poolId}}</td>
                    <td class="small" [ngClass]="{ faded: !pool.currentDedicated && !pool.targetDedicated }">{{pool.currentDedicated}}@if (pool.currentDedicated !== pool.targetDedicated) {
                      <span> → {{pool.targetDedicated}}</span>
                    }</td>
                    <td class="small" [ngClass]="{ faded: !pool.currentLowPriority && !pool.targetLowPriority }">{{pool.currentLowPriority}}@if (pool.currentLowPriority !== pool.targetLowPriority) {
                      <span> → {{pool.targetLowPriority}}</span>
                    }</td>
                    @for (item of pool.intervals; track item; let i = $index) {
                      <td class="small" [ngClass]="{ faded: !item.totalCoreHours}">
                        {{item.totalCoreHours | toFixed:1}}
                      </td>
                    }
                  </tr>
                }
              </tbody>
            </table>
          </div>
        }
      </div>
    </div>
  </div>
</div>

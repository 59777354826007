import { EventEmitter, Output, Directive } from '@angular/core';
import { LocalStorage } from '../common/local-storage.service';
import { AuthenticationService, UserData } from '../identity/state/authentication.service';

interface IState {
  userId: string;
}

@Directive()
export abstract class LocalStateService<TState extends IState, TMutableState> {
  @Output() changed: EventEmitter<TState> = new EventEmitter<TState>();

  protected userData: UserData;
  private _state: TState;

  constructor(
    private authenticationService: AuthenticationService,
    private localStorage: LocalStorage){
  }

  protected initialize() {
    this.userData = this.authenticationService.userDataSnapshot;

    const mutableState = this.localStorage.getItem(this.getLocalStorageKey());

    if (mutableState?.userId !== undefined && mutableState?.userId === this.userData.sub) {
      this._state = this.fromMutable(mutableState);
    } else {
      this._state = this.uninitialized(this.userData.sub);
    }
  }


  protected abstract fromMutable(value: TMutableState): TState;
  protected abstract toMutable(value: TState): TMutableState;
  protected abstract uninitialized(userId: string): TState;
  protected abstract getLocalStorageKey(): string;

  public get state(): TState {
    return this._state;
  }

  public get(): TState {
    return this._state;
  }

  public clear() {
    this.update(this.toMutable(this.uninitialized(this.userData.sub)));
  }

  protected update(mutableState: TMutableState){
    this.localStorage.setItem(this.getLocalStorageKey(), mutableState);
    this._state = this.fromMutable(mutableState);
    this.changed.emit(this._state);
  }
}

export interface IStateManager<TState, TMutableState> {
  localStorageKey: string;
  fromMutable(value: TMutableState): TState;
  toMutable(value: TState): TMutableState;
  uninitialized(userId: string): TState;
}

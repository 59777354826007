import * as d3 from '../../d3-bundle';
import {
  MultiPlotDataRendererBase,
  MultiPlotDataRendererInnerBase
} from '../multi-plot-viewer-base/multi-plot-data-renderer-base';
import { ProcessedPlot } from '../data-pipeline/types/processed-plot';
import { ProcessedPlotSourceChannel } from '../data-pipeline/types/processed-plot-source-channel';
import { GetInterpolatedChannelValueAtDomainValue } from '../channel-data-loaders/get-interpolated-channel-value-at-domain-value';

/**
 * D3 style interface to the data renderer for the scatter plot viewer.
 * Draws points to a canvas.
 */
export class DataRenderer extends MultiPlotDataRendererBase {

  /**
   * Create a new instance of the data renderer for the scatter plot viewer.
   * @returns A new instance of the data renderer.
   */
  public static create() {
    return new DataRenderer(new DataRendererInner('scatter-plot', new GetInterpolatedChannelValueAtDomainValue()));
  }

  /**
   * Create a new instance of the data renderer for the scatter plot viewer.
   * @param inner The inner data renderer for the scatter plot viewer.
   */
  private constructor(inner: DataRendererInner) {
    super(inner);
  }
}

/**
 * The inner data renderer for the scatter plot viewer.
 * Draws points to a canvas.
 */
export class DataRendererInner extends MultiPlotDataRendererInnerBase {

  /**
   * Draw the data for the given channel to the canvas.
   * @param context The canvas rendering context.
   * @param plot The plot to draw.
   * @param channel The channel to draw.
   */
  protected drawChannelData(context: CanvasRenderingContext2D, plot: ProcessedPlot, channel: ProcessedPlotSourceChannel) {
    let sourceIndex = channel.sourceIndex;
    let yChannel = channel.yChannel;
    let xCoordinates = channel.xCoordinates;
    let yCoordinates = channel.yCoordinates;

    context.fillStyle = this.definedSettings.getChannelColor ? this.definedSettings.getChannelColor(yChannel.channelIndex, sourceIndex) : 'green';

    let pointSize = DataRendererInner.getScatterPlotPointSize(xCoordinates.length);

    for (let dataIndex = 0; dataIndex < xCoordinates.length; ++dataIndex) {
      // Draw a circle for each data point.
      context.beginPath();
      context.arc(xCoordinates[dataIndex], yCoordinates[dataIndex], pointSize, 0, 2 * Math.PI);
      context.fill();
    }
  }

  /**
   * Draw any features for the plot, such as split lines.
   * @param context The canvas rendering context.
   * @param plot The plot to draw.
   */
  protected drawPlotFeatures(context: CanvasRenderingContext2D, plot: ProcessedPlot) {
    // There are no features to draw for the scatter plot viewer.
  }

  /**
   * Get the point size for the scatter plot viewer.
   * @param dataLength The length of the data.
   * @returns The point size for the scatter plot viewer.
   */
  static getScatterPlotPointSize(dataLength: number) {
    const maxPointSize = 1;
    const minPointSize = 1;

    const minPointSizeAtDataSize = 200;
    const maxPointSizeAtDataSize = 50;

    // Scale the point size with the number of points (note currently this is disabled as minPointSize and maxPointSize are the same).
    return d3.maxStrict([minPointSize, d3.minStrict([maxPointSize,
      maxPointSize - ((maxPointSize - minPointSize) * (dataLength - maxPointSizeAtDataSize) / (minPointSizeAtDataSize - maxPointSizeAtDataSize))])]);
  }
}

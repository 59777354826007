import {Component, Input} from '@angular/core';
import {FormSubmissionButton} from '../common/forms/form-submission-button';
import {FormSubmissionHandler} from '../common/forms/form-submission-handler.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import { AuthenticationService } from './state/authentication.service';
import { IdentityApi } from '../common/identity-api.service';
import { SignOut } from './authentication/sign-out.service';

@Component({
  selector: 'cs-sign-out-everywhere',
  templateUrl: './sign-out-everywhere.component.html'
})
export class SignOutEverywhereComponent {

  @Input() public tenantId: string;
  @Input() public userId: string;

  public errorMessage: string;
  public submitButton = new FormSubmissionButton('Sign Out Everywhere', 'Signing Out Everywhere...');
  public form: UntypedFormGroup;
  public isSuccessful = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    private identityApi: IdentityApi,
    private formSubmissionHandler: FormSubmissionHandler,
    private signOut: SignOut){

    this.form = this.formBuilder.group({});
  }

  public async onSubmit() {
    this.isSuccessful = false;
    this.isSuccessful = await this.formSubmissionHandler.execute(this.submit, this.form, this.submitButton, this);
  }

  public async submit(){
    await this.identityApi.deleteSessions(this.tenantId, this.userId);

    const userData = this.authenticationService.userDataSnapshot;
    if(userData.sub === this.userId){
      this.signOut.execute();
    }
  }
}

import {Injectable} from '@angular/core';
import {ConfirmationDialog} from '../common/dialogs/confirmation-dialog.service';
import {ConfigStub} from '../../generated/api-stubs';
import {LoadingDialog} from '../common/dialogs/loading-dialog.service';
import {GetFriendlyErrorAndLog} from '../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';

export const SET_OWNERSHIP_ERROR_TITLE = 'Set Ownership Error';
export const PROCEED_RESULT = 'It is safe to take ownership';

@Injectable()
export class PromptAndSetOwnership {
  constructor(
    private readonly prompt: ConfirmationDialog,
    private readonly configStub: ConfigStub,
    private readonly loadingDialog: LoadingDialog,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  public async execute(tenantId: string, documentId: string, targetUserId: string): Promise<boolean> {
    try{
      const result = await this.prompt.show(
        'Taking ownership could cause the existing owner to lose work if they are currently working on the document. Please ensure the existing owner is not working on the document before proceeding.',
        'WARNING',
        PROCEED_RESULT,
        'Cancel');

      if(result === PROCEED_RESULT){
        await this.loadingDialog.showUntilFinished(
          this.configStub.putConfigOwner(tenantId, documentId, {
            userId: targetUserId,
          }),
          'Setting owner...');
        return true;
      }
    } catch(error){
      let message = this.getFriendlyErrorAndLog.execute(error);
      await this.prompt.show(message, SET_OWNERSHIP_ERROR_TITLE);
    }

    return false;
  }
}

import {LintingEngineRunnerDialogData, LintingEngineRunnerResult} from './linting-engine-runner-dialog.service';
import {Injectable} from '@angular/core';
import {getJobIndexFromJobId} from '../../../common/get-job-index-from-job-id';
import {IDialogActions, IDialogSession, IDialogSessionFactory} from '../../../common/dialogs/dialog-component-base';

@Injectable()
export class LintingEngineRunnerDialogSessionFactory implements IDialogSessionFactory<LintingEngineRunnerDialogData, LintingEngineRunnerResult> {

  constructor(){
  }

  public create(dialogActions: IDialogActions<LintingEngineRunnerResult>, dialog: LintingEngineRunnerDialogData): LintingEngineRunnerDialogSession {
    return new LintingEngineRunnerDialogSession(dialog);
  }
}

export class LintingEngineRunnerDialogSession implements IDialogSession {
  public readonly jobIndex: number = 0;

  constructor(
    public readonly dialog: LintingEngineRunnerDialogData){
    if(this.dialog.jobId){
      this.jobIndex = getJobIndexFromJobId(this.dialog.jobId);
    }
  }

  public load(): Promise<void> {
    return Promise.resolve();
  }
}

import {Injectable} from '@angular/core';
import {IWorksheetContextMenuBuilder} from '../worksheet-context-menu-builder';
import {ViewConfigOrStudyCommand} from './view-config-or-study-command';
import {RowItemViewModel} from '../row-item-view-model';
import {
  MenuItem,
  SeparatorMenuItem,
} from '../../context-menu/context-menu-types';
import {CommandContext, CommandResult, WorksheetContext} from './worksheet-command';
import {TakeOwnershipCommand} from './take-ownership-command';
import {SetStudyTypeCommand} from './set-study-type-command';
import {CutCommand} from './cut-command';
import {CopyCommand} from './copy-command';
import {PasteCommand} from './paste-command';
import {LoadStudyCommand} from './load-study-command';
import {RunStudyCommand} from './run-study-command';
import {ClearSelectedCommand} from './clear-selected-command';
import {ExtractStudyInputsCommand} from './extract-study-inputs-command';
import {CompareResultsCommand} from './compare-results-command';
import {DownloadResultsCommand} from './download-results-command';

/**
 * A context menu builder for study row items.
 */
@Injectable()
export class StudyContextMenuBuilder implements IWorksheetContextMenuBuilder {

  /**
   * Creates an instance of StudyContextMenuBuilder.
   * @param setStudyTypeCommand The set study type command.
   * @param viewConfigOrStudyCommand The view config or study command.
   * @param takeOwnershipCommand The take ownership command.
   * @param cutCommand The cut command.
   * @param copyCommand The copy command.
   * @param pasteCommand The paste command.
   * @param loadStudyCommand The load study command.
   * @param runStudyCommand The run study command.
   * @param clearSelectedCommand The clear selected command.
   * @param extractStudyInputsCommand The extract study inputs command.
   * @param compareResultsCommand The compare results command.
   * @param downloadResultsCommand The download results command.
   */
  constructor(
    private readonly setStudyTypeCommand: SetStudyTypeCommand,
    private readonly viewConfigOrStudyCommand: ViewConfigOrStudyCommand,
    private readonly takeOwnershipCommand: TakeOwnershipCommand,
    private readonly cutCommand: CutCommand,
    private readonly copyCommand: CopyCommand,
    private readonly pasteCommand: PasteCommand,
    private readonly loadStudyCommand: LoadStudyCommand,
    private readonly runStudyCommand: RunStudyCommand,
    private readonly clearSelectedCommand: ClearSelectedCommand,
    private readonly extractStudyInputsCommand: ExtractStudyInputsCommand,
    private readonly compareResultsCommand: CompareResultsCommand,
    private readonly downloadResultsCommand: DownloadResultsCommand) {
  }

  /**
   * Generates the context menu items for the target row item.
   * @param target The target row item.
   * @param worksheetContext The worksheet context.
   * @returns The context menu items.
   */
  generateContextMenu(target: RowItemViewModel, worksheetContext: WorksheetContext): ReadonlyArray<MenuItem<CommandResult>> {
    let result: MenuItem<CommandResult>[] = [];

    const context = new CommandContext(target, worksheetContext);
    if (!context.isStudy()) {
      return result;
    }

    this.setStudyTypeCommand.createMenuItems(context, result);
    result.push(new SeparatorMenuItem());
    this.viewConfigOrStudyCommand.createMenuItems(context, result);
    this.takeOwnershipCommand.createMenuItems(context, result);
    result.push(new SeparatorMenuItem());
    this.loadStudyCommand.createMenuItems(context, result);
    this.runStudyCommand.createMenuItems(context, result);
    this.clearSelectedCommand.createMenuItems(context, result);
    result.push(new SeparatorMenuItem());
    this.extractStudyInputsCommand.createMenuItems(context, result);
    this.compareResultsCommand.createMenuItems(context, result);
    this.downloadResultsCommand.createMenuItems(context, result);
    result.push(new SeparatorMenuItem());
    this.cutCommand.createMenuItems(context, result);
    this.copyCommand.createMenuItems(context, result);
    this.pasteCommand.createMenuItems(context, result);
    result.push(new SeparatorMenuItem());

    return result;
  }
}

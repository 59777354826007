import {ServiceOnInit} from './service-on-init';
import {LocalStateService} from '../simulations/local-state-service';
import { Injectable, Directive } from '@angular/core';
import {LocalStorage} from './local-storage.service';
import { DocumentSubType } from '../../generated/api-stubs';
import { ChartId } from '../simulations/visualizations/canopy-site-hooks.service';
import { AuthenticationService } from '../identity/state/authentication.service';

export const LOCAL_STORAGE_KEY = 'localUserSettings';

@Directive()
@Injectable()
export class LocalUserSettingsManager extends LocalStateService<LocalUserSettings, LocalUserSettings> implements ServiceOnInit {

  constructor(
    authenticationService: AuthenticationService,
    localStorage: LocalStorage){
    super(authenticationService, localStorage);
  }

  protected fromMutable(state: LocalUserSettings): LocalUserSettings {
    return state;
  }

  protected toMutable(state: LocalUserSettings): LocalUserSettings {
    return state;
  }

  protected uninitialized(userId: string): LocalUserSettings {
    return new LocalUserSettings(userId);
  }

  protected getLocalStorageKey(): string {
    return LOCAL_STORAGE_KEY;
  }

  public serviceOnInit() {
    this.initialize();
  }

  public save(){
    this.update(this.state);
  }
}

export class LocalUserSettings {
  constructor(
    public readonly userId: string,
    public showCustomPropertiesInLists: boolean = true,
    public userSimVersion?: string,
    public charts?: LocalUserChartSettings[]){
  }
}

export class LocalUserChartSettings {
  constructor(
    public readonly chartType: DocumentSubType,
    public readonly chartId: ChartId | undefined,
    public readonly size: ChartSize) {
  }
}

export class ChartSize {
  constructor(
    public width: number,
    public height: number) {
  }
}

import {ClipboardContent} from './worksheet-clipboard.service';
import {CanopyJson} from '../common/canopy-json.service';
import {ConfigReference, DefaultConfigId, DocumentSubType, WorksheetConfig, WorksheetStub} from '../../generated/api-stubs';
import {Injectable} from '@angular/core';
import {GetSimVersion} from '../common/get-sim-version.service';


/**
 * Duplicates the configs in the content if they are not already in the target worksheet, or if
 * forceDuplication is set to true.
 */
@Injectable()
export class DuplicateClipboardContentIfRequired {

  /**
   * Creates an instance of DuplicateClipboardContentIfRequired.
   * @param getSimVersion The service for getting the current sim version.
   * @param json The json utils service.
   * @param worksheetStub The worksheet stub.
   */
  constructor(
    private readonly getSimVersion: GetSimVersion,
    private readonly json: CanopyJson,
    private readonly worksheetStub: WorksheetStub) {
  }

  /**
   * Duplicates the configs in the content if they are not already in the target worksheet, or if
   * forceDuplication is set to true.
   * @param targetTenantId The tenant ID of the target worksheet.
   * @param targetWorksheetId The ID of the target worksheet.
   * @param content The clipboard content.
   * @param forceDuplication If true, the configs will always be duplicated.
   * @returns The updated clipboard content.
   */
  public async execute(targetTenantId: string, targetWorksheetId: string, content: ClipboardContent, forceDuplication: boolean): Promise<ClipboardContent> {

    // If there is no content, or no rows, just return what we were given.
    if(!content || !content.rows || !content.rows.length){
      return content;
    }

    if(forceDuplication === false){
      // If the source and target are the same (copying within a worksheet),
      // and we're not forcing duplication, just return the content.
      // If we're copying across worksheets, we always duplicate the user configs
      // because a config can only be in one worksheet at a time.
      if(content.sourceWorksheetId === targetWorksheetId && content.sourceTenantId === targetTenantId) {
        return content;
      }
    }

    // Create a set of all the config IDs.
    const sourceConfigIdSet = new Set<string>();
    this.forEachTenantConfig(content, config => {
      sourceConfigIdSet.add(config.reference.tenant.targetId);
    });

    // Create a set of all the default config IDs.
    // We don't actually allow default configs to be in worksheets on the front-end
    // (although it is supported on the back-end, we always copy them as tenant configs
    // before adding them to the worksheet on the front-end). However if there were default
    // configs in the worksheet, we wouldn't need to duplicate them unless forceDuplication
    // was true.
    const sourceDefaultConfigIdSet = new Map<string, DefaultConfigId>();
    if(forceDuplication){
      this.forEachDefaultConfig(content, config => {
        const defaultConfigId: DefaultConfigId = {
          configType: config.configType,
          name: config.reference.default.name,
        };
        sourceDefaultConfigIdSet.set(
          this.defaultConfigIdToString(defaultConfigId),
          defaultConfigId);
      });
    }

    if(sourceConfigIdSet.size === 0 && sourceDefaultConfigIdSet.size === 0) {
      // None were found (the clipboard could still contain studies), so return
      // what we were given.
      return content;
    }

    const sourceConfigIds: string[] = Array.from(sourceConfigIdSet);
    const sourceDefaultConfigIds: DefaultConfigId[] = Array.from(sourceDefaultConfigIdSet.values());

    // Use the API to duplicate all the configs in a single API call.
    const duplicationResult = await this.worksheetStub.postDuplicateConfigs(
      targetTenantId,
      targetWorksheetId,
      {
        sourceTenantId: content.sourceTenantId,
        sourceWorksheetId: content.sourceWorksheetId,
        sourceConfigIds,
        sourceDefaultConfigIds,
      },
      this.getSimVersion.currentSimVersion);

    if(duplicationResult.targetConfigIds.length !== sourceConfigIds.length){
      throw new Error('Unexpected number of target config IDs returned from duplication request.');
    }

    // Map the source user config IDs to the IDs of the duplicated configs.
    const map: {[input: string]: string} = {};
    for(let i=0; i < sourceConfigIds.length; ++i){
      map[sourceConfigIds[i]] = duplicationResult.targetConfigIds[i];
    }

    // Update the references in the content to point to the duplicated configs.
    content = this.json.clone(content);
    this.forEachTenantConfig(content, config => {
      config.reference.tenant.tenantId = targetTenantId;
      config.reference.tenant.targetId = map[config.reference.tenant.targetId];
    });

    // If we're forcing duplication...
    if(forceDuplication) {

      // Map the default config IDs to the IDs of the duplicated default configs (which are now tenant configs).
      const defaultMap: {[input: string]: string} = {};
      for(let i=0; i < sourceDefaultConfigIds.length; ++i){
        defaultMap[this.defaultConfigIdToString(sourceDefaultConfigIds[i])] = duplicationResult.targetDefaultConfigIds[i];
      }

      // Update the references in the content to point to the duplicated default configs.
      this.forEachDefaultConfig(content, config => {
        const defaultConfigId: DefaultConfigId = {
          configType: config.configType,
          name: config.reference.default.name,
        };
        delete config.reference.default;
        config.reference.tenant = {
          tenantId: targetTenantId,
          targetId: defaultMap[this.defaultConfigIdToString(defaultConfigId)],
        };
      });
    }

    return content;
  }

  /**
   * Converts a DefaultConfigId to a string, used for the key in a map.
   * @param id The DefaultConfigId.
   * @returns The string representation.
   */
  private defaultConfigIdToString(id: DefaultConfigId): string {
    return id.configType + '/' + id.name;
  }

  /**
   * Calls the specified action for each tenant config in the content.
   * @param content The content.
   * @param action The action to call.
   */
  private forEachTenantConfig(content: ClipboardContent, action: (config: WorksheetConfig) => void){
    this.forEachConfig(content, r => !!r.tenant, action);
  }

  /**
   * Calls the specified action for each default config in the content.
   * @param content The content.
   * @param action The action to call.
   */
  private forEachDefaultConfig(content: ClipboardContent, action: (config: WorksheetConfig) => void){
    this.forEachConfig(content, r => !!r.default, action);
  }

  /**
   * Calls the specified action for each config in the content that matches the check.
   * @param content The content.
   * @param check The check to match.
   * @param action The action to call.
   */
  private forEachConfig(content: ClipboardContent, check: (reference: ConfigReference) => boolean, action: (config: WorksheetConfig) => void){
    for(let row of content.rows){
      if(!row.configs){
        continue;
      }

      for(let config of row.configs){
        if(config.reference && config.configType !== DocumentSubType.telemetry && check(config.reference)){
          action(config);
        }
      }
    }
  }
}

import { IPopulatedMultiPlotSide } from './types/i-populated-multi-plot-side';
import { UpdateScalesChartSettings } from './update-scales-chart-settings';


/**
 * Updates the sizes of a set of sides (rows or columns).
 */
export class UpdateSideSizes {

  /**
   * Updates the sizes of a set of sides (rows or columns).
   *
   * @param sides The sides to update.
   * @param settings The chart settings.
   * @param sizes The new sizes of the sides.
   */
  public execute(sides: ReadonlyArray<IPopulatedMultiPlotSide>, settings: UpdateScalesChartSettings, sizes: ReadonlyArray<number>) {
    let offset = 0;

    // For each side (either rows or columns)...
    for (let i = 0; i < sides.length; ++i) {
      // Get the new size...
      let size = sizes[i];

      // And update the side.
      sides[i].processed.updateSize(offset, size, settings.spaceBetweenPlots);

      // Update the offset for the next side.
      offset += size;
    }
  }
}

<ul class="textdiff">
  @for (line of this.parsed; track line) {
    <li>
      <div class="textdiff-location">
        <span class="textdiff-line-number">
          {{line.location.line}}
        </span>
        <span class="textdiff-char">
          {{line.location.chr}}
        </span>
      </div>
      <div class="textdiff-line">
        @for (piece of line.pieces; track piece) {
          <span class="textdiff-{{piece.type}}">
            {{unescape(piece.text)}}
          </span>
        }
      </div>
    </li>
  }
</ul>

<div class="container-fluid" id="home-page">
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="card">
        <h1 class="card-header">Simulation Inputs</h1>

        <div class="card-body">
          @if (!inputConfigTypes) {
            <cs-loader></cs-loader>
          }
          @if (inputConfigTypes) {
            <ul class="inputs-list button-list last-item">
              @for (config of inputConfigTypes; track config) {
                <li>
                  @if (config.state === ConfigTypeState.enabled) {
                    <a id="{{config.singularKey}}-configs-button"
                      class="btn btn-smart btn-secondary"
                      [routerLink]="['/configs', config.pluralKey]"><i class="fa {{config.icon}}"></i>{{config.titleName}}</a>
                    }
                  </li>
                }
              </ul>
            }
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="card">
          <h1 class="card-header">Studies</h1>

          <div class="card-body">
            <ul class="study-links-list button-list last-item">
              <li>
                <a id="studies-button" class="btn btn-smart btn-secondary" [routerLink]="['/studies']">
                  <i class="fa fa-cubes"></i>View Studies
                </a>
              </li>
            </ul>
          </div>
        </div>
        @if (showWorksheets) {
          <div class="card">
            <h1 class="card-header">Worksheets</h1>
            <div class="card-body">
              <ul class="worksheet-links-list button-list last-item">
                <li>
                  <a id="worksheets-button" class="btn btn-smart btn-secondary" [routerLink]="['/worksheets']">
                    <i class="fa fa-th"></i>View Worksheets
                  </a>
                </li>
              </ul>
            </div>
          </div>
        }
        <div class="card">
          <h1 class="card-header">Compute Pool</h1>

          <div class="card-body">
            <ul class="button-list last-item">
              <li>
                <a id="pool-button" class="btn btn-smart btn-secondary" [routerLink]="['/pool']">
                  <i class="fa fa-cloud"></i>View Pool Status
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <cs-tenant-simulation-quota-view></cs-tenant-simulation-quota-view>

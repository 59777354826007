import { TextFileReader } from '../../../text-file-reader.service';
import { Timer } from '../../../../common/timer.service';
import { getSafePath } from '../get-safe-path';
import { PerformTirImportFactory } from '../../../pacejka-canopy-conversion/perform-tir-import';
import { LoadingDialog } from '../../../../common/dialogs/loading-dialog.service';
import { simVersionToNumber } from '../../../../visualizations/sim-version-to-number';
import { ExtensionButtons } from './extension-buttons';
import { AddButtonsHandler } from './add-buttons-handler';
import { EditorNodeUtilities } from './editor-node-utilities';
import { UpdateDelegate } from './update-delegate';
import { ExtensionButtonCategories } from './extension-button-categories';
import { Injectable } from '@angular/core';


@Injectable()
export class AddPacejkaImportButtons implements AddButtonsHandler {
  constructor(
    private readonly timer: Timer,
    private readonly textFileReader: TextFileReader,
    private readonly performTirImportFactory: PerformTirImportFactory,
    private readonly loadingDialog: LoadingDialog) {
  }

  public get category(): ExtensionButtonCategories {
    return ExtensionButtonCategories.importTir;
  }

  public execute(utils: EditorNodeUtilities, subTreeControls: any, globalUpdateDelegate: UpdateDelegate): ExtensionButtons {
    let fullPath = utils.self.path;
    let safePath = getSafePath(fullPath);

    let numericSimVersion = simVersionToNumber(utils.simVersion);

    if (!(numericSimVersion >= 3102 && (safePath === 'tyres.front' || safePath === 'tyres.rear' || safePath === 'tyres.frontL' || safePath === 'tyres.rearL'))) {
      return undefined;
    }

    let importTirButton = utils.self.getButton('TIR', 'importtir', 'Import Pacejka TIR file');
    importTirButton.className += ' btn-icon import-tir-button';

    let setImportTirButtonEnablement = () => {
      let parentName = utils.self.parent.oneOf
        ? utils.self.parent.parent.value.name
        : utils.self.parent.value.name;
      if (parentName.indexOf('Pacejka') === -1) {
        importTirButton.classList.add('sub-tree-button-hidden');
      }
    };

    let importFileInput = document.createElement('input');
    importFileInput.setAttribute('type', 'file');
    importFileInput.setAttribute('accept', '.tir, .ctir');
    importFileInput.addEventListener('change', async (event) => {
      try {
        let files = importFileInput.files;
        if (!files || files.length === 0) {
          return;
        }

        let file = files[0];
        importFileInput.value = '';

        let text = await this.loadingDialog.showUntilFinished(
          this.textFileReader.read(file),
          'Loading...');

        let existingTyre = utils.self.getValue();

        let performTirImport = this.performTirImportFactory.create(utils.simVersion);
        let result = await performTirImport.execute(text, existingTyre);

        await this.timer.yield();

        if (result) {
          utils.subTreeData = undefined;
          utils.setValueOrParentValue(result);
        }
      } catch (error) {
        utils.handleError(error);
      }

      globalUpdateDelegate();
    });

    setImportTirButtonEnablement();

    utils.addButton(subTreeControls, importTirButton, async () => {
      importFileInput.click();
    }, () => { });

    return new ExtensionButtons(
      () => setImportTirButtonEnablement(),
      {
        importTir: importTirButton,
      });
  }
}

import { ChannelInterpolator } from './channel-interpolator';
import { FilteredStudyExplorationAndScalarDataCache } from '../filtered-study-exploration-and-scalar-data-cache';
import { DesignName } from '../exploration-map';
import { ChannelInterpolatorFactorial } from './channel-interpolator-factorial';
import { ChannelInterpolatorMonteCarlo } from './channel-interpolator-monte-carlo';
import { ChannelInterpolatorStar } from './channel-interpolator-star';
import { DisplayableError } from '../../../displayable-error';

/**
 * A factory for creating channel interpolators.
 */
export class ChannelInterpolatorFactory {

  /**
   * Creates a new ChannelInterpolator appropriate for the supplied data.
   * @param explorationAndScalarDataCache The data to create an interpolator for.
   * @returns An appropriate ChannelInterpolator for the supplied data.
   */
  public create(explorationAndScalarDataCache: FilteredStudyExplorationAndScalarDataCache): ChannelInterpolator {
    if (!explorationAndScalarDataCache) {
      throw new DisplayableError('No exploration or scalar data found.');
    }

    let explorationAndScalarData = explorationAndScalarDataCache.get();
    let map = explorationAndScalarData.explorationMap;
    let result: ChannelInterpolator;

    switch (map.designName) {
      case DesignName.factorial:
        // can only use linear factorial interpolation if there are no failures at all in the simulations
        result = new ChannelInterpolatorFactorial(explorationAndScalarData);
        break;

      case DesignName.monteCarlo:
      case DesignName.factorialMonteCarlo:
        result = new ChannelInterpolatorMonteCarlo(explorationAndScalarDataCache);
        break;

      case DesignName.star:
        result = new ChannelInterpolatorStar(explorationAndScalarData);
        break;

      default:
        throw new Error('Unsupported design type: ' + map.designName);
    }

    result.initialize();

    return result;
  }
}

import { Component } from '@angular/core';
import {WorksheetDock} from './worksheet-dock.service';
import {ResizeEvent} from 'angular-resizable-element';

/**
 * Component for the worksheet dock area.
 */
@Component({
  selector: 'cs-worksheet-dock',
  templateUrl: './worksheet-dock.component.html',
  styleUrls: ['./worksheet-dock.component.scss']
})
export class WorksheetDockComponent {

  /**
   * Creates an instance of WorksheetDockComponent.
   * @param dock The worksheet dock service.
   */
  constructor(
    public readonly dock: WorksheetDock) {
  }

  /**
   * Handles the resize event, as the user drags the dock to make it larger or smaller.
   * @param event The resize event.
   */
  handleResize(event: ResizeEvent): void {
    this.dock.height = event.rectangle.height;
  }

  /**
   * Handles the resize end event, when the user stops dragging the dock.
   * @param event The resize event.
   */
  handleResizeEnd(event: ResizeEvent): void {
    this.dock.height = event.rectangle.height;
  }
}

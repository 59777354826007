import {WorksheetViewModel} from './worksheet-view-model';
import {Injectable} from '@angular/core';


/**
 * A service for managing the list of active worksheets.
 * You can have more than one worksheet active if one is docked and the other is occupying the main area.
 */
@Injectable()
export class ActiveWorksheets {

  /**
   * The list of active worksheets.
   */
  private viewModels: WorksheetViewModel[] = [];

  /**
   * Gets the list of active worksheets.
   */
  public get items(): ReadonlyArray<WorksheetViewModel> {
    return this.viewModels;
  }

  /**
   * Adds a worksheet to the list of active worksheets.
   * @param viewModel The worksheet view model.
   */
  public add(viewModel: WorksheetViewModel){
    this.remove(viewModel);
    this.viewModels.push(viewModel);
  }

  /**
   * Removes a worksheet from the list of active worksheets.
   * @param viewModel The worksheet view model.
   */
  public remove(viewModel: WorksheetViewModel){
    for(let i = this.viewModels.length - 1; i >= 0; --i){
      if(this.viewModels[i].worksheetId === viewModel.worksheetId){
        this.viewModels.splice(i, 1);
      }
    }
  }
}


<div class="filter-query-builder-group">
  <cs-error-message [message]="errorMessage"></cs-error-message>

  <div>
    <select (change)="onGroupOperatorChanged($event.target.value)" class="form-select group-operator-select">
      @for (operator of groupOperators; track operator) {
        <option
          [selected]="operator === group.operator ? true : null"
        [value]="operator">{{operator}}</option>
      }
    </select>
    <button class="btn btn-secondary btn-smart add-condition-button" (click)="addCondition()">Add Condition</button>
    <button class="btn btn-secondary btn-smart add-group-button" (click)="addGroup()">Add Group</button>
    <button class="btn btn-secondary btn-smart wrap-group-button" (click)="wrapGroup()">Wrap Group</button>
  </div>

  <div class="group-conditions">
    @for (condition of group.conditions; track condition) {
      <div class="group-condition">
        <cs-filter-query-builder-condition
          [manager]="manager"
          [condition]="condition"
          [tenantUsers]="tenantUsers">
        </cs-filter-query-builder-condition>
        <button class="btn btn-secondary remove-condition-button" (click)="removeCondition(condition)" title="Delete Condition"><i class="fa fa-trash-o"></i></button>
      </div>
    }
  </div>

  <div class="group-children">
    @for (child of group.groups; track child) {
      <div class="group-child">
        <cs-filter-query-builder-group
          [manager]="manager"
          [group]="child"
          [tenantUsers]="tenantUsers">
        </cs-filter-query-builder-group>
        <button class="btn btn-secondary remove-group-button" (click)="removeGroup(child)" title="Delete Group"><i class="fa fa-trash-o"></i></button>
        <button class="btn btn-secondary absorb-group-button" (click)="absorbGroup(child)" title="Unwrap Group"><i class="fa fa-long-arrow-left"></i></button>
      </div>
    }
  </div>
</div>

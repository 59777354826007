import {ADMINISTRATOR_ROLE, DELETED_USER_ROLE, TENANT_ADMINISTRATOR_ROLE, TEST_USER_ROLE} from './user-identity';

export function getRoleIcon(role: string): string {
  switch(role){
    case ADMINISTRATOR_ROLE:
      return 'user-secret';

    case TENANT_ADMINISTRATOR_ROLE:
      return 'user-plus';

    case TEST_USER_ROLE:
      return 'gavel';

    case DELETED_USER_ROLE:
      return 'trash';

    default:
      return 'question';
  }
}

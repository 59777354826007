import {Injectable, EventEmitter} from '@angular/core';
import {GetUserSettingsQueryResult, UserSettingsStub} from '../../generated/api-stubs';
import {DisplayableError} from '../common/errors/errors';

@Injectable()
export class UserSettings {

  public changed: EventEmitter<UpdatedUserSettings> = new EventEmitter<UpdatedUserSettings>();
  private isUpdating: boolean;

  constructor(
    private userSettingsStub: UserSettingsStub){
  }

  public async get(tenantId: string, userId: string): Promise<GetUserSettingsQueryResult>{
    let settings = await this.userSettingsStub.getUserSettings(tenantId, userId);
    this.changed.emit(new UpdatedUserSettings(tenantId, userId, settings));
    return settings;
  }

  public async update(tenantId: string, userId: string, updateDelegate: (settings: GetUserSettingsQueryResult) => void){
    if(this.isUpdating){
      throw new DisplayableError('User settings are currently being updated.');
    }

    this.isUpdating = true;
    try {
      let retries = 3;
      while(retries > 0){
        try {
          await this.updateInner(tenantId, userId, updateDelegate);
          return;
        } catch(error){
          if(!error.isPreconditionFailedError) {
            throw error;
          }
        }

        --retries;
      }
    } finally {
      this.isUpdating = false;
    }
  }

  private async updateInner(tenantId: string, userId: string, updateDelegate: (settings: GetUserSettingsQueryResult) => void){
    let settings = await this.userSettingsStub.getUserSettings(tenantId, userId);
    updateDelegate(settings);
    let newETag = await this.userSettingsStub.putUserSettings(tenantId, userId, {
      settings: settings.settings,
      eTag: settings.eTag
    });

    let newSettings = {
      settings: settings.settings,
      eTag: newETag
    };

    this.changed.emit(new UpdatedUserSettings(tenantId, userId, newSettings));
  }
}

export class UpdatedUserSettings {
  constructor(
    public tenantId: string,
    public userId: string,
    public result: GetUserSettingsQueryResult) {
  }
}

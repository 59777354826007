@if (isVisible) {
  <div class="modal dialog-container dialog-inner-scroll dialog-full-width dialog-no-footer" id="view-job-dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title">View Job</h1>
          <button type="button" class="btn-close" aria-label="Close" id="dialog-dismiss-button" (click)="dismiss()">
          </button>
        </div>
        <div class="modal-body">
          <div class="scroll-area">
            @if (session) {
              <cs-view-job
                [tenantId]="session.dialog.tenantId"
                [studyId]="session.dialog.studyId"
                [jobId]="session.dialog.jobId"
                [hideBackButton]="true"
              [lazyLoadCharts]="true"></cs-view-job>
            }
          </div>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div>
  }<!-- /.modal -->

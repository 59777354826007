import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {GetFriendlyErrorAndLog} from '../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {NavigationStation} from '../visualizations/navigation-station/navigation-station';
import {NavigationStationConfig} from '../visualizations/navigation-station/config-builders/navigation-station-config-builder';
import {ConfigBuilderBase} from '../visualizations/navigation-station/config-builders/config-builder-base';
import {ChannelNameStyle} from '../visualizations/viewers/channel-data-loaders/channel-name-style';
import {SharedState} from '../visualizations/viewers/shared-state';
import {LinePlotViewer, POINT_MULTI_PLOT_VIEWER_TYPE} from '../visualizations/viewers/line-plot-viewer/line-plot-viewer';
import {SourceMetadata, SourceLoaderBase} from '../visualizations/viewers/channel-data-loaders/source-loader';
import {ViewerChannelData} from '../visualizations/viewers/channel-data-loaders/viewer-channel-data';
import {SimType} from '../visualizations/sim-type';
import {UrlFileLoader} from '../visualizations/url-file-loader';
import {SiteHooks, IEditChannelsChannelMetadata, EditChannelsChannelMetadata} from '../visualizations/site-hooks';
import {StudyJob} from '../visualizations/study-job';
import {SourceLoaderViewModel} from '../visualizations/viewers/channel-data-loaders/source-loader-set';
import {StudyStub, TenantStatistics} from '../../generated/api-stubs';
import {GetUsernameMap, UsernameMap} from '../simulations/get-username-map.service';
import {
  TenantStudyStatisticsUtilities,
  RawStatistics, RawStatisticsPeriodItem,
  AllTenantsProcessedStatistics, AllTenantsProcessedStatisticsForPeriodType, ProcessedStatisticsForPeriodType,
  RawStatisticsPeriodItemKeys
} from '../tenancy/tenant-study-statistics-utilities.service';
import {GetTenantNameMap} from '../simulations/get-tenant-name-map.service';
import {CanopyFileLoader, CanopyFileLoaderFactory} from '../simulations/visualizations/canopy-file-loader.service';
import {CanopySiteHooks, CanopySiteHooksFactory} from '../simulations/visualizations/canopy-site-hooks.service';
import {RequestedLayoutIds} from '../visualizations/site-hooks';
import {Dayjs as DayjsService} from '../common/dayjs.service';
import {Dayjs} from 'dayjs';
import {Timer} from '../common/timer.service';
import {
  OutOfZoneFileLoader,
  OutOfZoneNavigationStation,
  OutOfZoneSiteHooks
} from '../simulations/visualizations/visualization-zones';

@Component({
  selector: 'cs-all-tenants-study-statistics',
  templateUrl: './all-tenants-study-statistics.component.html',
  styleUrls: ['./all-tenants-study-statistics.component.scss']
})
export class AllTenantsStudyStatisticsComponent implements OnInit, OnDestroy {
  public errorMessage: string;
  public isAdministrator: boolean;

  public statistics: AllTenantsProcessedStatistics;

  public navigationStation: OutOfZoneNavigationStation;

  private fileLoader: CanopyFileLoader;
  private siteHooks: CanopySiteHooks;
  private tenantMap: AllTenantsUsernameMap;

  private startOfStatistics: Dayjs;
  private endOfStatistics: Dayjs;
  private existingStatistics: { [tenantId: string]: any } = {};

  public canLoadMore: boolean = false;

  constructor(
    private readonly studyStub: StudyStub,
    private readonly getUsernameMap: GetUsernameMap,
    private readonly getTenantNameMap: GetTenantNameMap,
    private readonly utilities: TenantStudyStatisticsUtilities,
    private readonly dayjs: DayjsService,
    private readonly canopyFileLoaderFactory: CanopyFileLoaderFactory,
    private readonly canopySiteHooksFactory: CanopySiteHooksFactory,
    private readonly timer: Timer,
    private readonly zone: NgZone,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  public ngOnInit() {
    this.load();
  }

  public ngOnDestroy() {
    if(this.navigationStation){
      this.navigationStation.dispose();
    }
  }

  public async load() {
    try {
      let tenantNameMap = await this.getTenantNameMap.execute();

      this.tenantMap = {};
      for(let tenantId in tenantNameMap){
        if(!tenantNameMap.hasOwnProperty(tenantId)){
          continue;
        }

        let usernameMap = await this.getUsernameMap.executeWithEmail(tenantId);
        this.tenantMap[tenantId] = {
          name: tenantNameMap[tenantId],
          usernameMap
        };
      }

      this.fileLoader = this.canopyFileLoaderFactory.create();
      this.siteHooks = this.canopySiteHooksFactory.create(this.fileLoader);

      await this.loadNext();
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async loadNext() {
    try {
      this.canLoadMore = false;
      this.statistics = undefined;
      if(!this.startOfStatistics){
        let startOfMonth = this.dayjs.create().utc().startOf('month');
        this.startOfStatistics = startOfMonth.subtract(3, 'month');
      } else{
        this.endOfStatistics = this.startOfStatistics.clone();
        this.startOfStatistics = this.startOfStatistics.subtract(3, 'month');
      }
      if(this.navigationStation){
        this.navigationStation.dispose();
      }

      let statisticsResult = await this.studyStub.getAllTenantsStudyStatistics(
        this.startOfStatistics.format(),
        this.endOfStatistics ? this.endOfStatistics.format() : undefined);

      let mergedTenantStatistics: TenantStatistics[] = [];
      for(let tenantStatistics of statisticsResult.tenants){
        let tenantId = tenantStatistics.tenantId;
        this.existingStatistics[tenantId] =
          this.utilities.mergeRawStatistics(this.existingStatistics[tenantId], tenantStatistics.statistics);
        mergedTenantStatistics.push({
          tenantId,
          statistics: this.existingStatistics[tenantId]});
      }

      this.statistics = this.processStatistics(mergedTenantStatistics, this.tenantMap);

      let tenantNames = this.utilities.getStringKeys(this.statistics.monthly.tenant);
      let usernames = this.utilities.getStringKeys(this.statistics.monthly.user);
      let studyTypes = this.utilities.getStringKeys(this.statistics.monthly.studyType);

      await this.timer.yield();

      this.navigationStation = new OutOfZoneNavigationStation(this.zone, new NavigationStation(
        'navigation-station',
        new TenantStudyStatisticsConfigBuilder(
          this.statistics,
          tenantNames,
          usernames,
          studyTypes,
          new OutOfZoneFileLoader(this.zone, this.fileLoader),
          new OutOfZoneSiteHooks(this.zone, this.siteHooks),
          [{ studyId: 'dummy-study', jobIndex: 0 }],
          ['dummy-sim-type'])));

      await this.navigationStation.build();

      await this.timer.delay(5000);
      this.canLoadMore = true;
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public processStatistics(statistics: AllTenantsRawStatistics, tenantMap: AllTenantsUsernameMap): AllTenantsProcessedStatistics {
    let dateStrings = this.getDateStrings(statistics);
    let allStatistics = [];
    for(let tenant of statistics){
      let tenantMapItem = tenantMap[tenant.tenantId];
      let usernameMap = tenantMapItem.usernameMap;
      let nextMonthly = this.utilities.getProcessedStatistics(tenant.statistics, dateStrings.months, 'monthly', usernameMap);
      let nextDaily = this.utilities.getProcessedStatistics(tenant.statistics, dateStrings.days, 'daily', usernameMap);
      allStatistics.push({
        tenantName: tenantMapItem.name,
        monthly: nextMonthly,
        daily: nextDaily
      });
    }

    let emptyResult = {
      monthly: this.createEmptyResult(dateStrings.months),
      daily: this.createEmptyResult(dateStrings.days)
    };

    return allStatistics.reduce((current, value) => ({
        monthly: this.aggregateProcessedStatisticsForPeriodType(value.tenantName, current.monthly, value.monthly),
        daily: this.aggregateProcessedStatisticsForPeriodType(value.tenantName, current.daily, value.daily),
      }), emptyResult);
  }

  public createEmptyResult(dates: string[]): AllTenantsProcessedStatisticsForPeriodType{
    return {
      dates,
      all: dates.map(v => this.utilities.createEmptyPeriodItem()),
      tenant: {},
      user: {},
      studyType: {}
    };
  }

  public aggregateProcessedStatisticsForPeriodType(
    tenantName: string,
    current: AllTenantsProcessedStatisticsForPeriodType,
    value: ProcessedStatisticsForPeriodType){

    current.all = current.all.map((c, i) => <RawStatisticsPeriodItem>RawStatisticsPeriodItemKeys
        .reduce<any>((p, k: keyof RawStatisticsPeriodItem) => {
 p[k] = c[k] + value.tenant[i][k]; return p;
}, {}));
    current.tenant[tenantName] = value.tenant;
    this.merge(current.user, value.user, '/' + tenantName);
    this.mergeValues(current.studyType, value.studyType);
    return current;
  }

  public merge(target: any, source: any, prefix: string){
    for(let key of this.utilities.getStringKeys(source)){
      target[key + prefix] = source[key];
    }
  }

  public mergeValues(target: { [key: string]: RawStatisticsPeriodItem[] }, source: { [key: string]: RawStatisticsPeriodItem[] }){
    for(let key of this.utilities.getKeys(source)){
      let sourceArray = source[key];
      let targetArray = target[key];
      if(!targetArray){
        target[key] = source[key].map(v =>
          <RawStatisticsPeriodItem>RawStatisticsPeriodItemKeys
            .reduce<any>((p, k: keyof RawStatisticsPeriodItem) => {
 p[k] = v[k]; return p;
}, {}));
      } else{
        for(let i=0; i < sourceArray.length; ++i){
          for(let periodItemKey of RawStatisticsPeriodItemKeys){
            targetArray[i][periodItemKey] += sourceArray[i][periodItemKey];
          }
        }
      }
    }
  }

  public getDateStrings(statistics: AllTenantsRawStatistics): { months: string[]; days: string[] } {
    let ranges = this.utilities.getDateRanges(statistics[0].statistics);
    let keys = this.utilities.getKeys(ranges);
    for(let i=1; i<statistics.length; ++i){
      let nextRanges = this.utilities.getDateRanges(statistics[i].statistics);
      for(let key of keys){
        ranges[key] = ranges[key] < nextRanges[key] ? ranges[key] : nextRanges[key];
      }
    }

    return {
      months: this.utilities.getDatesForRange(ranges.minMonth, ranges.maxMonth, 'YYYY-MM', 'M'),
      days: this.utilities.getDatesForRange(ranges.minDay, ranges.maxDay, 'YYYY-MM-DD', 'd'),
    };
  }
}

interface AllTenantsUsernameMap {
  [tenantId: string]: {
    name: string;
    usernameMap: UsernameMap;
  };
}

type AllTenantsRawStatistics = {
  tenantId: string;
  statistics: RawStatistics;
}[];

class TenantStudyStatisticsConfigBuilder extends ConfigBuilderBase {
  constructor(
    private statistics: AllTenantsProcessedStatistics,
    private tenantNames: string[],
    private usernames: string[],
    private studyTypes: string[],
    urlFileLoader: UrlFileLoader,
    siteHooks: SiteHooks,
    studyJobs: StudyJob[],
    simTypes: SimType[]){
    super(urlFileLoader, siteHooks, studyJobs, simTypes);
  }

  public async build(): Promise<NavigationStationConfig> {
    let config: NavigationStationConfig = {
      channelNameStyle: ChannelNameStyle.Generic,
      sharedStates: [],
      views: []
    };

    await this.createView(config, 'Monthly Failed Simulations', 'monthly', 'failedSimulations');
    await this.createView(config, 'Monthly Failed Studies', 'monthly', 'failedStudyCount');
    await this.createView(config, 'Monthly Successful Compute Credits', 'monthly', 'succeededComputeCredits');
    await this.createView(config, 'Monthly Successful Storage Credits', 'monthly', 'succeededStorageCredits');
    await this.createView(config, 'Monthly Successful Simulations', 'monthly', 'succeededSimulations');
    await this.createView(config, 'Monthly Simulations', 'monthly', 'completedSimulations');
    await this.createView(config, 'Monthly Studies', 'monthly', 'studyCount');
    await this.createView(config, 'Daily Failed Simulations', 'daily', 'failedSimulations');
    await this.createView(config, 'Daily Failed Studies', 'daily', 'failedStudyCount');
    await this.createView(config, 'Daily Successful Storage Credits', 'daily', 'succeededStorageCredits');
    await this.createView(config, 'Daily Successful Compute Credits', 'daily', 'succeededComputeCredits');
    await this.createView(config, 'Daily Successful Simulations', 'daily', 'succeededSimulations');
    await this.createView(config, 'Daily Simulations', 'daily', 'completedSimulations');
    await this.createView(config, 'Daily Studies', 'daily', 'studyCount');

    return Promise.resolve(config);
  }

  private async createView(config: NavigationStationConfig, title: string, period: keyof AllTenantsProcessedStatistics, property: keyof RawStatisticsPeriodItem): Promise<void>{
    let sharedState = new SharedState();
    sharedState.sourceLoaderSet.add(
      new SourceLoaderViewModel(
        new StatisticsSourceLoader(period, property, this.statistics, this.tenantNames, this.usernames, this.studyTypes)));
    config.sharedStates.push(sharedState);

    let xDomainName = period === 'daily' ? 'Day' : 'Month';

    let layoutId = `Default-AllTenantStatistics-${property}-${xDomainName}`;

    let layout = await this.resolveViewerLayout(
      POINT_MULTI_PLOT_VIEWER_TYPE,
      new RequestedLayoutIds(layoutId),
      {
        overrideLineCurve: 'curveStepBefore',
        columns: [
          {
            channels: [xDomainName],
          }
        ],
        rows: [
          {
            channels: ['Total'],
            relativeSize: 1
          },
          {
            channels: this.tenantNames,
            relativeSize: 3
          },
          {
            channels: this.usernames,
            relativeSize: 6
          },
          {
            channels: this.studyTypes,
            relativeSize: 2
          },
        ]
      },
      true);


    config.views.push({
      title,
      viewerType: POINT_MULTI_PLOT_VIEWER_TYPE,
      layout,
      viewer: LinePlotViewer.createLinePlotViewer(
        xDomainName,
        layout.resolvedLayout.getConfigCopy(),
        config.channelNameStyle,
        sharedState,
        this.siteHooks),
      grid: {
        x: 0,
        y: 0,
        width: 12,
        height: 16
      }
    });
  }
}

class StatisticsSourceLoader extends SourceLoaderBase {
  constructor(
    private period: keyof AllTenantsProcessedStatistics,
    private property: keyof RawStatisticsPeriodItem,
    private statistics: AllTenantsProcessedStatistics,
    private tenantNames: string[],
    private usernames: string[],
    private studyTypes: string[]){
    super();
  }

  getSourceMetadata(): Promise<SourceMetadata> {
    return Promise.resolve(new SourceMetadata('Tenant Statistics'));
  }

  getChannelData(requestedName: string, resultChannelNameStyle: ChannelNameStyle, xDomainName: string): Promise<ViewerChannelData> {
    let simTypeStatistics = this.statistics[this.period];
    let data: number[];
    if(requestedName === 'Day' || requestedName === 'Month') {
      let lastIndex = simTypeStatistics.dates.length - 1;
      data = simTypeStatistics.dates.map((v, i) => i - lastIndex);
    } else if(requestedName === 'Total'){
      data = simTypeStatistics.all.map(v => v[this.property]);
    } else if(this.tenantNames.indexOf(requestedName) !== -1){
      data = simTypeStatistics.tenant[requestedName].map(v => v[this.property]);
    } else if(this.usernames.indexOf(requestedName) !== -1){
      data = simTypeStatistics.user[requestedName].map(v => v[this.property]);
    } else{
      data = simTypeStatistics.studyType[requestedName].map(v => v[this.property]);
    }

    let result = new ViewerChannelData(
      requestedName,
      requestedName,
      requestedName,
      requestedName,
      '',
      '',
      false,
      undefined,
      data);

    return Promise.resolve(result);
  }

  getRequestableChannels(resultChannelNameStyle: ChannelNameStyle, xDomainName: string): Promise<IEditChannelsChannelMetadata[]> {
    return Promise.resolve([
      new EditChannelsChannelMetadata('Day', ''),
      new EditChannelsChannelMetadata('Month', ''),
      new EditChannelsChannelMetadata('Total', ''),
      ...this.tenantNames.map(v => new EditChannelsChannelMetadata(v, '')),
      ...this.usernames.map(v => new EditChannelsChannelMetadata(v, '')),
      ...this.studyTypes.map(v => new EditChannelsChannelMetadata(v, '')),
    ]);
  }
}

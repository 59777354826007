import { Selector } from '@ngxs/store';
import { ErrorMessageState, ErrorMessageStateModel } from './error-message.state';

export class ErrorMessageSelectors {

  @Selector([ErrorMessageState])
  public static message(state: ErrorMessageStateModel): string {
    return state.errorMessage || '';
  }

}

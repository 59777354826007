import { Injectable } from '@angular/core';
import { SimVersionStub, GetSimVersionDocumentsQueryResult } from '../../generated/api-stubs';
import { GetSimVersion } from '../common/get-sim-version.service';

@Injectable()
export class SimVersionDocumentCache {
  private cache: { [simVersion: string]: Promise<GetSimVersionDocumentsQueryResult> };

  private _lastRequestedVersion: string;

  constructor(
    private readonly simVersionStub: SimVersionStub,
    private readonly getSimVersion: GetSimVersion) {
    this.cache = {};
  }

  public async get(simVersion: string): Promise<GetSimVersionDocumentsQueryResult> {
    if (!simVersion) {
      simVersion = await this.getSimVersion.execute();
    }

    this._lastRequestedVersion = simVersion;

    let cached = this.cache[simVersion];

    if (!cached) {
      cached = this.simVersionStub.getDocuments(simVersion);
      this.cache[simVersion] = cached;
    }

    try {
      return await cached;
    } catch (error) {
      this.cache[simVersion] = undefined;
      throw error;
    }
  }

  public async getFolder(folder: string, simVersion: string): Promise<GetSimVersionDocumentsQueryResult> {
    let documentsResult = await this.get(simVersion);

    let filteredResult: GetSimVersionDocumentsQueryResult = {
      simVersion: documentsResult.simVersion,
      documents: [],
      units: documentsResult.units
    };

    for (let document of documentsResult.documents) {
      if (document.name.indexOf(folder + '/') === 0) {
        filteredResult.documents.push(document);
      }
    }

    return filteredResult;
  }

  public clear() {
    this._lastRequestedVersion = undefined;
    this.cache = {};
  }

  public get lastRequestedVersion() {
    return this._lastRequestedVersion;
  }
}

export interface UnitsMap { [key: string]: string }

import { FeatureChannelNames } from './types/feature-channel-names';
import { SourceLoaderSet } from '../channel-data-loaders/source-loader-set';
import { GetSourceFeatureChannelNames } from './get-source-feature-channel-names';


export class GetFeatureChannelNames {
  constructor(
    private readonly sourceLoaderSet: SourceLoaderSet,
    private readonly getSourceFeatureChannelNames: GetSourceFeatureChannelNames) {
  }

  public async execute(): Promise<ReadonlyArray<FeatureChannelNames>> {
    // Load metadata in parallel.
    let getFeatureChannelNamesTasks = this.sourceLoaderSet.sources.map(v => this.getSourceFeatureChannelNames.execute(v));

    let result: FeatureChannelNames[] = [];
    for (let task of getFeatureChannelNamesTasks) {
      let integrationChannelName = await task;
      result.push(integrationChannelName);
    }

    return result;
  }
}

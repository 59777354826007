import {Injectable} from '@angular/core';
import {DialogManager, IDialog, DialogBase} from '../../common/dialogs/dialog-manager.service';
import {LabelDefinitions} from '../../../generated/api-stubs';

/**
 * Service for the worksheet labels editor dialog.
 */
@Injectable()
export class WorksheetLabelsEditorDialog {

  /**
   * Creates an instance of WorksheetLabelsEditorDialog.
   * @param dialogManager The dialog manager.
   */
  constructor(
    private dialogManager: DialogManager
  ){}

  /**
   * Shows the dialog.
   * @param canEditWorksheetLabels Whether the user can edit the worksheet labels.
   * @param worksheetLabels The worksheet labels.
   * @returns A promise that resolves to the result of the dialog.
   */
  public show(
    canEditWorksheetLabels: boolean,
    worksheetLabels?: LabelDefinitions): Promise<WorksheetLabelsEditorResult> {

    return this.dialogManager.showSecondaryDialog(
      new WorksheetLabelsEditorDialogData(
        canEditWorksheetLabels,
        worksheetLabels));
  }
}

/**
 * Data for the worksheet labels editor dialog.
 */
export class WorksheetLabelsEditorDialogData extends DialogBase<WorksheetLabelsEditorResult> implements IDialog {

  /**
   * Creates an instance of WorksheetLabelsEditorDialogData.
   * @param canEditWorksheetLabels Whether the user can edit the worksheet labels.
   * @param worksheetLabels The worksheet labels.
   */
  constructor(
    public readonly canEditWorksheetLabels: boolean,
    public readonly worksheetLabels: LabelDefinitions | undefined) {
    super();

    this.dismissData = new WorksheetLabelsEditorResult(false, undefined);
  }
}

/**
 * Result of the worksheet labels editor dialog.
 */
export class WorksheetLabelsEditorResult {

  /**
   * Creates an instance of WorksheetLabelsEditorResult.
   * @param refreshRequired Whether the worksheet labels need to be refreshed.
   * @param newWorksheetLabels The new worksheet labels.
   */
  constructor(
    public readonly refreshRequired: boolean,
    public readonly newWorksheetLabels: LabelDefinitions | undefined){
  }
}

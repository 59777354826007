import { Injectable } from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import { SetAutocompleteSanitizer } from './set-autocomplete-sanitizer';
import awesomplete from 'awesomplete' ;

type LoadValuesDelegate = () => string[];

@Injectable()
export class CanopyAutocomplete{

  constructor(
    private readonly setAutocompleteSanitizer: SetAutocompleteSanitizer) {
  }

  public create(
    elementId: string,
    formControl: UntypedFormControl,
    values: string[] | LoadValuesDelegate | any, // "any" is for an options object.
    sort?: (a: string, b: string) => number,
    ngxChipsBehavior: boolean = false){
    let element = <Element>document.getElementById(elementId);

    if(!element){
      let elements = document.getElementsByClassName(elementId);
      if(!elements || elements.length === 0){
        // Most likely the page has changed, so just return.
        return undefined;
      }

      element = elements[0];
    }

    let loadValues: LoadValuesDelegate;
    let options: any;
    if(values.list){
      options = values;
    } else{
      if(!Array.isArray(values)){
        loadValues = values;
        values = loadValues();
      }

      options = {
        list: values,
        minChars: 0,
        maxItems: 100,
        autoFirst: true
      };

      if(sort){
        options.sort = sort;
      }
    }

    this.setAutocompleteSanitizer.execute(options);

    let result = new awesomplete(element, options);

    let valueOnOpen: any;
    if(ngxChipsBehavior){
      // This is used when the ngx-chips control receives an enter it
      // first populates the form with the partially complete value before awesomplete
      // can add the auto-completed value. This allows us to overwrite the partial
      // value with what awesomplete generates.
      element.addEventListener('awesomplete-open', (e: any) => {
        valueOnOpen = formControl.value;
      });
    }

    element.addEventListener('awesomplete-selectcomplete', (e: any) => {
      let currentValue = ngxChipsBehavior ? valueOnOpen : formControl.value;
      if (Array.isArray(currentValue)) {
        let newValue = e.text.value;
        if(currentValue.indexOf(newValue) === -1){
          currentValue.push(newValue);
          formControl.setValue(currentValue); // Ensure all events trigger.
        }

        (<HTMLInputElement>element).value = '';

        if(loadValues){
          result.list = this.loadAndFilterValues(loadValues, formControl);
        }
        result.open();
        result.evaluate();

        if(ngxChipsBehavior && element){
          if (element instanceof HTMLElement){
            element.blur();
            element.focus();
          }
        }

      } else {
        let selectedValue = e.text.value;
        formControl.setValue(selectedValue);

        if(result._list.some((v: string | { value: string }) => (typeof v === 'object' ? v.value : v).startsWith(e.text.value + '.'))){
          result.open();
          result.evaluate();
        }
      }
      formControl.markAsDirty();
    });

    element.addEventListener('focus', (e) => {
      if(loadValues){
        result.list = this.loadAndFilterValues(loadValues, formControl);
      }
      result.open();
      result.evaluate();
    });

    return result;
  }

  private loadAndFilterValues(loadValues: LoadValuesDelegate, formControl: UntypedFormControl){
    let result = loadValues();
    let currentValue = formControl.value;
    if(Array.isArray(currentValue)){
      for(let c of currentValue){
        let currentIndex = result.indexOf(c);
        if(currentIndex !== -1){
          result.splice(currentIndex, 1);
        }
      }
    }
    return result;
  }
}

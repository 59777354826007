import { Injectable } from '@angular/core';
import { JsonEditorCustomization } from './json-editor-customization';
import{ JSONEditor } from'@json-editor/json-editor'

@Injectable()
export class IntegerEditor extends JsonEditorCustomization {
  public apply(): void {
    JSONEditor.defaults.editors.integer = class extends JSONEditor.defaults.editors.number {
      sanitize(value: string) {
        value = value + '';
        return value.replace(/[^0-9\-]/g, '');
      }

      getNumColumns() {
        return 2;
      }
    }
  }
}

import {Component, Input} from '@angular/core';
import {RowViewModel} from '../row-view-model';
import {ColumnType} from '../column-type';
import {ResolvedColumn} from '../resolved-column';

/**
 * Component for displaying a worksheet row.
 */
/* eslint-disable @angular-eslint/component-selector */
@Component({
  selector: '[cs-worksheet-row]',
  templateUrl: './worksheet-row.component.html',
  styleUrls: ['./worksheet-row.component.scss']
})
export class WorksheetRowComponent {

  /**
   * The row view model to display.
   */
  @Input() public readonly row: RowViewModel;

  /**
   * The index of the row in the worksheet.
   */
  @Input() public readonly rowIndex: number;

  /**
   * Whether the worksheet is in the dock.
   */
  @Input() public readonly isWorksheetInDock: boolean;

  /**
   * The column type enum.
   */
  public ColumnType = ColumnType;

  /**
   * Gets the track by function for the columns.
   * @param index The index of the column.
   * @param item The column.
   */
  public columnTrackById(index: number, item: ResolvedColumn) {
    return item.definition.trackingId;
  }
}

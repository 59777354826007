import { IViewerChannelData } from './viewer-channel-data';
import { Units } from '../../units';

/**
 * Ensures that all sources for a channel have the data in the same units.
 */
export class NormalizeUnitsForChannelSources {

  /**
   * Ensures that all sources for a channel have the data in the same units.
   *
   * @param sources - The list of viewer channel data for the same channel, one for each source.
   * @returns The list of viewer channel data for the same channel, with the data in the same units.
   */
  public execute(sources: IViewerChannelData[]): IViewerChannelData[] {

    if (!sources.length) {
      return sources;
    }

    // Find the first channel source with user specified source.
    let reference = sources.find(v => v.isUserSpecifiedUnits);

    // Failing that, find the first one with data.
    if (!reference) {
      reference = sources.find(v => !!v.data);
    }

    // And failing that, just take the first one.
    if (!reference) {
      reference = sources[0];
    }

    let units = reference.units;
    let isUserSpecifiedUnits = reference.isUserSpecifiedUnits;

    let result: IViewerChannelData[] = [];
    for (let source of sources) {
      if (source === reference) {
        // Just push the reference channel source.
        result.push(source);
        continue;
      }

      // If the source has no data, clone it but with the new units.
      if (!source.data || !source.data.length) {
        result.push(source.clone(source.data, units, isUserSpecifiedUnits));
        continue;
      }

      // If the source has data, convert the data into the same units as the
      // reference source.
      let convertedSourceData = Units.convertValuesBetweenUnits(source.data, source.units, units, true);
      result.push(source.clone(convertedSourceData, units, isUserSpecifiedUnits));
    }

    return result;
  }
}

import { isDevMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { SentryService } from './app/sentry.service';
import {AppModule} from './app/app.module';

if (!isDevMode()) {
  SentryService.initialize();
}

// Also update preserveWhitespaces in tsconfig.app.json for AOT compilation.
platformBrowserDynamic().bootstrapModule(AppModule, { preserveWhitespaces: true })
  .catch(err => console.log(err));

@if (isVisible) {
  <div class="modal dialog-container" id="linting-engine-runner-dialog">
    <div class="modal-dialog modal-dialog-wide" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title">
            {{session && session.dialog.studyId ? 'Job ' : 'Config '}}Analysis
            @if (session.dialog.studyId) {
              <small>
                Job Index {{session.jobIndex}}
              </small>
            }
          </h1>
          <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()">
          </button>
        </div>
        <div class="modal-body">
          <cs-error-message [message]="errorMessage"></cs-error-message>
          @if (!session) {
            <cs-loader></cs-loader>
          }
          @if (session) {
            <div class="row">
              <div class="col-12">
                <cs-linting-engine-runner
                  [tenantId]="session.dialog.tenantId"
                  [configId]="session.dialog.documentId"
                  [configData]="session.dialog.configData"
                  [configType]="session.dialog.configType"
                  [studyId]="session.dialog.studyId"
                [jobId]="session.dialog.jobId"></cs-linting-engine-runner>
              </div>
            </div>
          }
        </div>
        <div class="modal-footer">
          <button id="dialog-dismiss-button" type="button" class="btn btn-secondary" (click)="dismiss()">Close</button>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div>
  }<!-- /.modal -->


/**
 * Create a random string of a given length.
 * @param length The length of the string to create.
 * @returns A random string of the given length.
 */
export function createRandomString(length: number): string {
  let text = '';
  let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
}

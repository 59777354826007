<div class="quick-config-manager button-menu-hover-target" (mouseenter)="loadIfRequired()">
  <button class="btn btn-smart btn-secondary btn-icon open-button">
    <i class="fa fa-cloud"></i>
    @if (isEdited) {
      <i class="fa fa-pencil"></i>
    }
  </button>
  <div class="content button-menu-hovering-area">

    <div class="hover-area-tab">
      <div class="icon-area">
        <i class="fa fa-cloud"></i>
        @if (isEdited) {
          <i class="fa fa-pencil"></i>
        }
      </div>
    </div>

    <cs-error-message [message]="errorMessage"></cs-error-message>

    <div>
      @if (showName) {
        <div class="row">
          <div class="col-12">
            <h3 class="current-config-name">{{currentConfig?.name || 'Unsaved'}}</h3>
          </div>
        </div>
      }

      <div class="row">
        <div class="col-12">
          <button type="button" class="btn btn-secondary save-button" (click)="save()" [disabled]="!canSave">{{isSaving ? 'Saving...' : 'Save'}}</button>
          <button type="button" class="btn btn-secondary save-as-button" (click)="saveAs()" [disabled]="!canSaveAs">Save As</button>
          <button type="button" class="btn btn-secondary view-all-button" (click)="viewAll()">View All</button>
        </div>
      </div>

      <hr/>
      <div class="row">
        <div class="col-12">
          <h3>Quick Access:</h3>
          @if (isLoading && !errorMessage) {
            <cs-loader class="quick-access-loader"></cs-loader>
          }

          @if (userConfigs) {
            <div>
              @for (item of userConfigs; track item) {
                <div class="previously-saved-item">
                  <a href="javascript:void(0)" (click)="loadUserConfig(item)">{{item.name}}</a>
                </div>
              }
              @if (!userConfigs.length) {
                <p class="no-user-configs-message last-item">None</p>
              }
            </div>
          }
        </div>
      </div>
    </div>

  </div>
</div>

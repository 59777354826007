import {RowItemViewModel} from './row-item-view-model';
import {Injectable} from '@angular/core';

/**
 * A service for looking up the view model for a cell element in the DOM.
 */
@Injectable()
export class CellElementToViewModelLookup {

  /**
   * The map of elements to view models.
   */
  private readonly map: Map<Element, RowItemViewModel> = new Map<Element, RowItemViewModel>();

  /**
   * Registers the view model for the specified element.
   * @param element The element.
   * @param viewModel The view model.
   */
  public register(element: Element, viewModel: RowItemViewModel) {
    this.map.set(element, viewModel);
  }

  /**
   * Unregisters the view model for the specified element.
   * @param element The element.
   */
  public unregister(element: Element){
    this.map.delete(element);
  }

  /**
   * Gets the view model for the specified element.
   * @param element The element.
   * @returns The view model, or undefined if not found.
   */
  public get(element: Element): RowItemViewModel | undefined {
    return this.map.get(element);
  }
}

import { Injectable, NgZone } from '@angular/core';
import { SetChannelUnitsDialog } from '../../../../units/set-channel-units-dialog.service';
import { JsonEditorCustomization } from './json-editor-customization';
import { getCanopyJsonEditorOptions } from './get-canopy-json-editor-options';
import{ JSONEditor } from'@json-editor/json-editor'

@Injectable()
export class CanopyTheme extends JsonEditorCustomization {

  constructor(
    private readonly zone: NgZone,
    private readonly setChannelUnitsDialog: SetChannelUnitsDialog) {
    super();
  }

  public apply(): void {
    const service = this;

    JSONEditor.defaults.themes.canopy = class extends JSONEditor.defaults.themes.bootstrap5 {
      getHelpLink(wikiPage: string, wikiAnchor: string, editorNode: any) {
        const canopyOptions = getCanopyJsonEditorOptions(editorNode);

        let helpBox = document.createElement('a');
        helpBox.className += ' help-link-container';
        helpBox.setAttribute('tabindex', '-1');
        helpBox.setAttribute('target', '_blank');
        // if (service.userIdentity.isAdministrator) {
        helpBox.href = ('https://support.canopysimulations.com/hc/en-gb/articles/' + (canopyOptions.documentationPageNameToId[wikiPage] || wikiPage) + (wikiAnchor ? '#' + wikiAnchor.toLowerCase() : '').replace(/ /g, '-'));
        // }
        // else {
        //   helpBox.href = ('/wiki/' + wikiPage + (wikiAnchor ? ';location=' + wikiAnchor.toLowerCase() : '')).replace(/ /g, '-');
        // }
        let icon = document.createElement('i');
        icon.className += ' fa fa-book';
        let tooltip = document.createElement('span');
        tooltip.className += ' help-tooltip';
        tooltip.textContent = (wikiPage + (wikiAnchor ? '/' + wikiAnchor : '')).replace(/\//g, ' / ');
        helpBox.appendChild(tooltip);
        helpBox.appendChild(icon);
        return helpBox;
      }

      addHelpLink(content: any, schema: any, editorNode: any) {
        let wikiPage = schema ? schema.wikiPage : undefined;
        let wikiAnchor = schema ? schema.wikiAnchor : undefined;
        if (wikiPage) {
          let helpBox = this.getHelpLink(wikiPage, wikiAnchor, editorNode);
          helpBox.className += ' help-non-addon';
          content.appendChild(helpBox);
        }
      }

      getFormControl(label: HTMLLabelElement, input: HTMLInputElement, description: Element, infoText: Element, formName: string, schema: any, editorNode: any) {
        if (input.type === 'hidden') {
          let group = document.createElement('div');
          group.appendChild(input);
          return group;
        } else {
          let formGroup = super.getFormControl(label, input, description);
          if(!(input.type === 'checkbox' || input.type === 'radio')){
            let units = editorNode ? editorNode.getUnits() : undefined;
            let wikiPage = schema ? schema.wikiPage : undefined;
            let wikiAnchor = schema ? schema.wikiAnchor : undefined;

            let isInputGroup = (input.type === 'text' || input.type === 'textarea');

            // not sure what below is doing
            // if (!isInputGroup && units) {
            //   console.log(units);
            //   let unitsSpan = document.createElement('span');
            //   unitsSpan.textContent = ' (' + units + ')';
            //   formGroup.appendChild(unitsSpan);
            // }

            let inputGroup = formGroup;
            if (isInputGroup) {
              inputGroup = document.createElement('div');
              inputGroup.classList.add('input-group', 'input-group-sm');
              inputGroup.appendChild(input);
              formGroup.insertBefore(inputGroup, description);

              if(units){
                let unitsText = document.createElement('span');
                unitsText.classList.add('input-group-text', 'units-addon');

                let setUnits = () => {
                  let currentUnits = editorNode.getUnits();
                  if (currentUnits === '()' || currentUnits === '1') {
                    currentUnits = '-';
                  }
                  unitsText.textContent = currentUnits;
                };
                setUnits();

                const canopyOptions = getCanopyJsonEditorOptions(editorNode);

                canopyOptions.internalUnitsChanged.subscribe(() => {
                  service.zone.runOutsideAngular(() => {
                    setUnits();
                  });
                });

                unitsText.addEventListener('click', async () => {
                  let unitsData = editorNode.getUnitsAndChannelName();
                  let result = await service.setChannelUnitsDialog.show(unitsData.channelName, unitsData.units);
                  if (result) {
                    canopyOptions.internalUnitsChanged.emit(undefined);
                  }
                });
                inputGroup.prepend(unitsText);
              }
            }

            if (wikiPage) {
              let helpBox = this.getHelpLink(wikiPage, wikiAnchor, editorNode);
              if (isInputGroup) {
                helpBox.classList.add('input-group-text', 'help-addon');
                inputGroup.appendChild(helpBox);
              } else {
                helpBox.classList.add('help-non-addon');
                formGroup.appendChild(helpBox);
              }
            }
          }

          return formGroup;
        }
      }

      getButton (text: string, icon: string, title: string) {
        const el = super.getButton(text, icon, title)
        if(icon && !text) {
          el.classList.add('btn-icon');
        }
        return el
      }

      getButtonHolder () {
        const el = document.createElement('span');
        return el;
      }

      addHoverArea(editor: any){
        let hoverArea = document.createElement('div');
        hoverArea.classList.add('hover-button-group-container');
        hoverArea.appendChild(editor.title);
        editor.container.prepend(hoverArea);
      }
    }
  }
}

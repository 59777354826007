import { Component, Input } from '@angular/core';
import { UntypedFormControl, ValidationErrors } from '@angular/forms';
import { FormComponent } from '../../services/form-builder.service';

@Component({
  selector: 'cs-input-text',
  templateUrl: './input-text.component.html',
})
export class InputTextComponent implements FormComponent {

  @Input() public name: string;
  @Input() public label: string;
  @Input() public formControl: UntypedFormControl;

  public get id(): string {
    return this.name.replace(/([A-Z])/g, (s: string) => `-${s.toLowerCase()}`); // todo: pipe?
  }

  public get inputId(): string {
    return `${this.id}-input`;
  }

  public get errors(): ValidationErrors {
    if (!this.formControl || this.formControl.pristine) {
      return null;
    }

    return this.formControl.errors;
  }

}

import { DomainNews } from './shared-state';

/**
 * A cache for domain news.
 */
export class DomainNewsCache {

  /**
   * The cache, mapping domain names to domain news.
   */
  private _cache: { [name: string]: DomainNews } = {};

  /**
   * Gets the domain news for the given domain name.
   * @param name The domain name.
   * @returns The domain news.
   */
  public getDomainNews(name: string): DomainNews {
    let result = this._cache[name];
    if (!result) {
      this._cache[name] = result = new DomainNews();
    }

    return result;
  }
}

import { ISourceLabelsSource } from '../../components/source-labels-renderer';
import { SourceLoaderViewModel } from '../../channel-data-loaders/source-loader-set';
import { ViewerChannelDataMap } from '../../channel-data-loaders/viewer-channel-data-map';
import { FeatureChannels } from './feature-channels';

/**
 * Loaded channels for a single data source.
 */

export class SourceData implements ISourceLabelsSource {

  /**
   * Creates a SourceData instance.
   * @param name The name of the data source.
   * @param channels The channels loaded from the data source.
   * @param featureChannels The feature channels loaded from the data source.
   * @param _loader The the data source.
   */
  constructor(
    public readonly name: string,
    public readonly channels: ViewerChannelDataMap,
    public readonly featureChannels: FeatureChannels,
    private readonly _loader: SourceLoaderViewModel) { }

  /**
   * Returns true if the data source is visible, false otherwise.
   */
  public get isVisible(): boolean {
    return this._loader.isVisible;
  }

  /**
   * Sets whether the data source is visible.
   */
  public set isVisible(value: boolean) {
    this._loader.isVisible = value;
  }
}

export class GetJsonValues {

  /**
   * Gets the values at the given path.
   * This will enumerate any arrays it finds along the path, potentially returning multiple results.
   * The path can contain multiple paths separated by '|'. Each path will be enumerated.
   * @param root The root object.
   * @param path The path to the values.
   * @param arrayIsValid True if arrays are valid leaf values, otherwise they will be enumerated.
   * @returns The values at the path.
   */
  public static forPath(root: any, path: string, arrayIsValid: boolean): JsonValueResult[] {
    let result: JsonValueResult[] = [];
    let pathElements = path.split('.');
    GetJsonValues.inner(root, '', pathElements, result, arrayIsValid);
    return result;
  }

  /**
   * The recursive implementation.
   * @param root The root object.
   * @param path The current path to the values, including array indexes.
   * @param pathElements The remaining path elements.
   * @param arrayIsValid True if arrays are valid leaf values, otherwise they will be enumerated.
   * @returns The values at the path.
   */
  private static inner(root: any, path: string, pathElements: ReadonlyArray<string>, result: JsonValueResult[], arrayIsValid: boolean) {

    if (!root) {
      return;
    }

    // Add the path item to the current path.
    const addPath = (path: string, pathItem: string) => path + (path.length ? '.' : '') + pathItem;

    if (Array.isArray(root)) {
      if (pathElements.length === 0 && arrayIsValid) {
        // If there are no more path elements, and arrays are valid result values, add the array to the list of results.
        result.push(new JsonValueResult(path, root));
      } else {
        // Otherwise enumerate the array.
        let index = 0;
        for (let child of root) {
          this.inner(child, addPath(path, '' + index), pathElements, result, arrayIsValid);
          ++index;
        }
      }
    } else if (typeof root === 'object') {
      if (pathElements.length === 0) {
        // If there are no more path elements, add the object to the list of results.
        result.push(new JsonValueResult(path, root));
      } else {
        // Take the next path element, and split by | to allow multiple paths.
        let pathItems = pathElements[0].split('|');

        // For each path...
        for (let pathItem of pathItems) {
          // Remove the path item from the list of path elements.
          let nextPathElements = pathElements.slice(1);

          // Get the next token.
          let nextToken = root[pathItem];

          // Recurse.
          this.inner(nextToken, addPath(path, pathItem), nextPathElements, result, arrayIsValid);
        }
      }
    } else if (pathElements.length === 0) {
      // If there are no more path elements, add the value to the list of results.
      // If there are more path elements, and this isn't an array or object, we can't continue down the path.
      result.push(new JsonValueResult(path, root));
    }
  }
}

/**
 * Represents a value at a path.
 */
export class JsonValueResult {

  /**
   * Initializes a new instance of the JsonValueResult class.
   * @param path The path to the value.
   * @param value The value.
   */
  constructor(
    public readonly path: string,
    public readonly value: any) {
  }
}

import {LazyLoaded} from './lazy-loaded';
import {SimType, GetStudyJobQueryResult, GetStudyQueryResult} from '../../../../generated/api-stubs';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {Injectable} from '@angular/core';

@Injectable()
export class LazyLoadedSimTypesFactory {
  constructor(
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  public create(
    jobResult: LazyLoaded<GetStudyJobQueryResult>,
    studyResult: LazyLoaded<GetStudyQueryResult>): LazyLoaded<SimType[]>{
    return new LazyLoaded<SimType[]>(
      async () => {
        if (jobResult.value) {
          return jobResult.value.simTypes;
        }

        await studyResult.tryLoad();
        if (studyResult.value) {
          return studyResult.value.simTypes;
        }

        return [];
      },
      this.getFriendlyErrorAndLog);
  }
}

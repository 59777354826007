import {Injectable} from '@angular/core';
import {DialogManager, DialogBase, IDialog} from './dialog-manager.service';

@Injectable()
export class PromptDialog {
  constructor(private dialogManager: DialogManager) {
  }

  public async show<T>(
    message: string,
    title?: string,
    initialValue?: string,
    acceptMessage?: string,
    dismissMessage?: string,
    submitAction?: (v: string) => Promise<T>,
    autocomplete?: string[] | any): Promise<T> {
    return <T>await this.dialogManager.showTertiaryDialog(
      new PromptDialogData(
        message,
        title,
        initialValue,
        acceptMessage,
        dismissMessage,
        submitAction,
        autocomplete));
  }

  public promptForNumber(message: string, title: string, initialValue: number): Promise<number> {
    return this.show<number>(
      message,
      title,
      '' + initialValue,
      undefined,
      undefined,
      v => {
        let result = Math.round(parseFloat(v)) || 0;
        if(isNaN(result) || result < 0){
          result = 0;
        }

        return Promise.resolve(result);
      });
  }
}

export class PromptDialogData extends DialogBase<string> implements IDialog {
  constructor(
    public readonly message: string,
    public readonly title: string,
    public readonly initialValue: string,
    public readonly acceptMessage: string,
    public readonly dismissMessage: string,
    public readonly submitAction: (v: string) => Promise<any>,
    public readonly autocomplete: string[] | any) {
    super();
  }
}


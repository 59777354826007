import * as d3 from '../d3-bundle';
import { cssSanitize } from '../css-sanitize';
import { HTMLDivSelection, SVGSelection } from '../untyped-selection';
import { PIXEL_DATA_ATTRIBUTE } from './constants';
import { Disposable } from '../disposable';
import { ISize } from '../viewers/size';
import { IMargin } from '../viewers/margin';
import { Timer } from '../timer';
import { DisplayableError } from '../displayable-error';
import { UrlFileLoader } from '../url-file-loader';
import { SiteHooks } from '../site-hooks';
import { UserData } from '../../identity/state/authentication.service';

export abstract class BootstrapBase {

  abstract get name(): string;

  abstract load(testArea: HTMLDivSelection, userData: UserData, fileLoader: UrlFileLoader, siteHooks: SiteHooks): Promise<Disposable | undefined>;

  public get loadImmediately() {
    return false;
  }

  protected async createTestItem(
    testArea: HTMLDivSelection,
    name: string,
    cssClass: string,
    handler: (svg: SVGSelection, svgParent: HTMLDivSelection, root: HTMLDivSelection) => Promise<any>) {

    let testItem = testArea.append<HTMLDivElement>('div').attr('class', 'test-item ' + (cssClass ? cssClass : '')).attr('id', cssSanitize(name));
    testItem.append('h2').text(name);

    let svgParent = testItem.append<HTMLDivElement>('div').attr('class', 'svg-parent clearfix');
    let svg = svgParent.append<SVGElement>('svg').attr('width', '300px').attr('height', '300px');

    return await handler(svg, svgParent, testItem);
  }

  protected async createChartTestItem(
    testArea: HTMLDivSelection,
    name: string,
    cssClass: string,
    handler: (parent: HTMLDivSelection, root: HTMLDivSelection) => Promise<any>) {

    let testItem = testArea.append<HTMLDivElement>('div').attr('class', 'test-item ' + (cssClass ? cssClass : '')).attr('id', cssSanitize(name));
    testItem.append('h2').text(name);

    let svgParent = testItem.append<HTMLDivElement>('div').attr('class', 'svg-parent clearfix');

    await Timer.delay(0);

    await handler(svgParent, testItem);
  }

  protected writeCanvas(canvas: HTMLCanvasElement) {
    let context = canvas.getContext('2d');
    if (!context) {
      throw new DisplayableError('Unable to write to canvas.');
    }

    let imageData = context.getImageData(0, 0, canvas.width, canvas.height);

    let pixelData: number[][][] = [];
    for (let y = 0; y < imageData.height; ++y) {
      let line: number[][] = [];
      pixelData.push(line);
      for (let x = 0; x < imageData.width; ++x) {
        let pixel: number[] = [];
        line.push(pixel);
        let index = (x + y * imageData.width) * 4;
        let pixelR = imageData.data[index];
        let pixelG = imageData.data[index + 1];
        let pixelB = imageData.data[index + 2];

        pixel.push(pixelR, pixelG, pixelB);
      }
    }

    d3.select(canvas).attr(PIXEL_DATA_ATTRIBUTE, JSON.stringify(pixelData));
  }

  protected RenderCanvasChartOutlines(svg: SVGSelection, svgSize: ISize, svgPadding: IMargin, chartMargin: IMargin, chartSize: ISize) {
    svg.select(
      function() {
        return this.parentNode as d3.BaseType;
      })
      .attr('style', 'width: ' + svgSize.width + 'px; height: ' + svgSize.height + 'px;')
      .classed('canvas-viewer', true);

    this.RenderChartOutlines(svg, svgSize, svgPadding, chartMargin, chartSize);
  }
  protected RenderChartOutlines(svg: SVGSelection, svgSize: ISize, svgPadding: IMargin, chartMargin: IMargin, chartSize: ISize) {
    svg.attr('width', svgSize.width);
    svg.attr('height', svgSize.height);

    svg.append('rect').attr('class', 'padding-rect')
      .attr('x', svgPadding.left)
      .attr('y', svgPadding.top)
      .attr('width', svgSize.width - svgPadding.left - svgPadding.right)
      .attr('height', svgSize.height - svgPadding.top - svgPadding.bottom);

    svg.append('rect').attr('class', 'chart-rect')
      .attr('x', svgPadding.left + chartMargin.left)
      .attr('y', svgPadding.top + chartMargin.top)
      .attr('width', chartSize.width)
      .attr('height', chartSize.height);
  }
}

export class DisposableList implements Disposable {
  constructor(
    private list: Disposable[]
  ) { }

  public dispose() {
    this.list.forEach(v => v.dispose());
  }
}

import {Component, ElementRef, EventEmitter, Input, Output, ViewChild, OnDestroy} from '@angular/core';
import {RenamedChannel, ValidatedTelemetryFile} from '../telemetry-config';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {DisplayableError} from '../../../common/errors/errors';
import {GetSimVersionDocumentsQueryResult} from '../../../../generated/api-stubs';
import {GetSimVersion} from '../../../common/get-sim-version.service';
import {MutableTelemetryChannel, TelemetryChannel} from '../telemetry-channel';
import {ImportTelemetryStage} from '../import-telemetry-dialog/import-telemetry-stage';
import {Timer} from '../../../common/timer.service';
import {
  SLAP_DOMAIN_NAME,
  SRUN_DOMAIN_NAME,
  DISTANCE_DOMAIN_NAME,
  TLAP_DOMAIN_NAME,
  TRUN_DOMAIN_NAME,
  TIME_DOMAIN_NAME,
  T_DOMAIN_NAME,
  NLAP_DOMAIN_NAME,
} from '../../../visualizations/constants';
import {OrderedUniqueIndices} from '../../../visualizations/ordered-unique-indices';
import {Utilities} from '../../../visualizations/utilities';
import {Units} from '../../../visualizations/units';
import {CanopyJson} from '../../../common/canopy-json.service';
import {SimVersionDocumentCache} from '../../sim-version-document-cache.service';
import {sortBy} from '../../../common/sort-by';
import {
  JobConfigData,
  VisualizationFactory
} from '../../visualizations/visualization-factory.service';
import {OutOfZoneNavigationStation} from '../../visualizations/visualization-zones';
import { ConfigOrConfigLoader } from '../../configs/comparing/config-or-config-loader';
import { defaultConfigToStudyInput } from '../../../worksheets/study-input-utilities';

const TELEMETRY_DISTANCE_DOMAINS: ReadonlyArray<string> = [
  SLAP_DOMAIN_NAME,
  SRUN_DOMAIN_NAME,
  DISTANCE_DOMAIN_NAME,
];

const TELEMETRY_TIME_DOMAINS: ReadonlyArray<string> = [
  TLAP_DOMAIN_NAME,
  TRUN_DOMAIN_NAME,
  TIME_DOMAIN_NAME,
  T_DOMAIN_NAME,
  'TimeIntoExport',
  'timeBase',
];

export const GENERATED_SRUN_DESCRIPTION = 'Monotonic distance.';
export const GENERATED_NLAP_DESCRIPTION = 'Lap number.';

@Component({
  selector: 'cs-refine-telemetry-json',
  templateUrl: './refine-telemetry-json-stage.component.html',
  styleUrls: ['./refine-telemetry-json-stage.component.scss']
})
export class RefineTelemetryJsonStageComponent extends ImportTelemetryStage implements OnDestroy {
  @Input() public showDifferences: boolean;
  @Input() public telemetryConfig: ValidatedTelemetryFile;
  @Output() public telemetryConfigProcessed: EventEmitter<ValidatedTelemetryFile> = new EventEmitter<ValidatedTelemetryFile>();

  @ViewChild('configPreviewContainer') configPreviewElement: ElementRef;
  public navigationStation: OutOfZoneNavigationStation;

  public inputTelemetry: any;
  public outputTelemetry: any;

  public compareInputTelemetry: ConfigOrConfigLoader;
  public compareOutputTelemetry: ConfigOrConfigLoader;

  public infoMessages: string[] = [];
  public warnMessages: string[] = [];
  public errorMessages: ReadonlyArray<string> = [];
  public configPreviewErrorMessage: string;

  public name: string;
  public channel: TelemetryChannel[] = [];

  public readonly unitConversions: UnitConversion[] = [];
  public readonly removedChannels: ChannelNameAndReason[] = [];
  public readonly addedChannels: ChannelNameAndReason[] = [];
  public readonly renamedChannels: RenamedChannel[] = [];
  public inputDataLength: number;
  public outputDataLength: number;
  public timeChannel: MutableTelemetryChannel;
  public timeChannelOriginalName: string;
  public distanceChannel: MutableTelemetryChannel;
  public distanceChannelOriginalName: string;

  public documentsResult: GetSimVersionDocumentsQueryResult;

  constructor(
    private readonly getSimVersion: GetSimVersion,
    private readonly json: CanopyJson,
    private readonly documentCache: SimVersionDocumentCache,
    private readonly visualizationFactory: VisualizationFactory,
    timer: Timer,
    getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
    super(timer, getFriendlyErrorAndLog);
  }

  ngOnDestroy(): void {
    if(this.navigationStation){
      this.navigationStation.dispose();
    }
  }

  public async run(): Promise<void> {

    // NOTE: To support multiple laps we need to:
    //  - Find the sLap channel.
    //  - Create an NLap channel and increment whenever sLap drops.
    //  - Create an sRun channel if necessary and approximate from sLap

    this.documentsResult = await this.documentCache.get(this.getSimVersion.currentSimVersion);

    if(this.showDifferences){
      this.inputTelemetry = this.getConfigSummary(this.telemetryConfig.config);
    }

    this.renamedChannels.push(...this.telemetryConfig.channelNameMappings);

    this.processDomainChannels();
    this.processChannelUnits();

    this.renamedChannels.sort(sortBy({ name: 'from', primer: (v: string) => v.toLowerCase() }));

    if(this.showDifferences) {
      this.outputTelemetry = this.getConfigSummary(this.telemetryConfig.config);
      this.compareInputTelemetry = new ConfigOrConfigLoader('input', defaultConfigToStudyInput(undefined, 'input', this.inputTelemetry, undefined), undefined);
      this.compareOutputTelemetry = new ConfigOrConfigLoader('output', defaultConfigToStudyInput(undefined, 'output', this.outputTelemetry, undefined), undefined);
    }

    await this.loadTelemetryPreview();
  }

  public async loadTelemetryPreview() {
    try{
      this.configPreviewErrorMessage = undefined;

      await this.timer.yield();
      if(!this.configPreviewElement || !this.configPreviewElement.nativeElement){
        // The page has been unloaded, or we are not displaying the preview.
        return;
      }

      let element = <HTMLElement>this.configPreviewElement.nativeElement;
      element.innerHTML = '<div id="view-config-preview"></div>';

      await this.timer.yield();

      this.navigationStation = await this.visualizationFactory.createTelemetryPreview(
        'view-config-preview', [new JobConfigData('Telemetry', this.telemetryConfig.config)]);

      await this.navigationStation.build();
    } catch (error) {
      this.configPreviewErrorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public processDomainChannels() {
    // Locate time and distance channels.
    this.timeChannel = this.telemetryConfig.config.channels.find(v => TELEMETRY_TIME_DOMAINS.indexOf(v.name) !== -1);
    if (!this.timeChannel) {
      throw new DisplayableError(`Time domain not found (${TELEMETRY_TIME_DOMAINS.join(', ')}).`);
    }
    this.timeChannelOriginalName = this.timeChannel.name;
    if (this.timeChannel.name !== TRUN_DOMAIN_NAME) {
      this.timeChannel.name = TRUN_DOMAIN_NAME;
      this.renamedChannels.push(new RenamedChannel(this.timeChannelOriginalName, this.timeChannel.name));
    }

    let distanceChannels = this.telemetryConfig.config.channels.filter(v => TELEMETRY_DISTANCE_DOMAINS.indexOf(v.name) !== -1);
    if(distanceChannels.length === 0){
      this.warnMessages.push(`Distance domain not found (${TELEMETRY_DISTANCE_DOMAINS.join(', ')}).`);
    } else if(distanceChannels.length === 1){
      this.distanceChannel = distanceChannels[0];
    } else{
      this.distanceChannel = distanceChannels.find(v => v.name === SRUN_DOMAIN_NAME);
      if(!this.distanceChannel){
        this.distanceChannel = distanceChannels[0];
      }
    }

    // Ensure monotonic and remove duplicate time/distance rows.
    this.inputDataLength = this.timeChannel.data.length;
    let dataToRemove = new OrderedUniqueIndices();
    this.addDuplicateValueIndices(this.timeChannel.data, dataToRemove);
    this.ensureMonotonic(this.timeChannel.data, 'Time');

    if (this.distanceChannel) {
      this.addDuplicateValueIndices(this.distanceChannel.data, dataToRemove);
    }

    this.removePointsFromChannels(dataToRemove, 'repeating domain values');

    if (this.distanceChannel) {
      this.distanceChannelOriginalName = this.distanceChannel.name;
      if(this.isMonotonic(this.distanceChannel.data)) {
        if (this.distanceChannel.name !== SRUN_DOMAIN_NAME) {
          this.distanceChannel.name = SRUN_DOMAIN_NAME;
          this.renamedChannels.push(new RenamedChannel(this.distanceChannelOriginalName, this.distanceChannel.name));
        }
      } else {
        this.generateMonotonicDistanceDomain();
      }
    }

    this.generateLapNumberChannelIfRequired();
    this.removeSuspiciouslyShortInitialLap();
  }

  private generateLapNumberChannelIfRequired() {
    let sLapChannel = this.telemetryConfig.config.channels.find(v => v.name === SLAP_DOMAIN_NAME);
    let NLapChannel = this.telemetryConfig.config.channels.find(v => v.name === NLAP_DOMAIN_NAME);
    if (sLapChannel && !NLapChannel && !this.isMonotonic(sLapChannel.data)) {
      NLapChannel = new MutableTelemetryChannel(NLAP_DOMAIN_NAME, '()', GENERATED_NLAP_DESCRIPTION, []);
      this.addedChannels.push(new ChannelNameAndReason(NLapChannel.name, 'Created lap number channel.'));
      this.telemetryConfig.config.channels.push(NLapChannel);

      let currentNLap = 1;
      NLapChannel.data.push(currentNLap);
      for (let i = 1; i < sLapChannel.data.length; ++i) {
        let current = sLapChannel.data[i];
        let previous = sLapChannel.data[i - 1];
        if (current < previous) {
          ++currentNLap;
          NLapChannel.data.push(currentNLap);
        } else {
          NLapChannel.data.push(currentNLap);
        }
      }
    }
  }

  private generateMonotonicDistanceDomain() {
    if (this.distanceChannel.name !== SLAP_DOMAIN_NAME) {
      this.distanceChannel.name = SLAP_DOMAIN_NAME;
      this.renamedChannels.push(new RenamedChannel(this.distanceChannelOriginalName, this.distanceChannel.name));
    }

    let sRunChannel = new MutableTelemetryChannel(SRUN_DOMAIN_NAME, this.distanceChannel.units, GENERATED_SRUN_DESCRIPTION, []);
    this.addedChannels.push(new ChannelNameAndReason(sRunChannel.name, 'Created monotonic distance channel.'));
    this.telemetryConfig.config.channels.push(sRunChannel);

    sRunChannel.data.push(0);
    for (let i = 1; i < this.distanceChannel.data.length; ++i) {
      let current = this.distanceChannel.data[i];
      let previous = this.distanceChannel.data[i - 1];
      let sRunPrevious = sRunChannel.data[i - 1];
      if (current < previous) {

        let sRunIncrement = current;
        if (i > 1) {
          // Just duplicate the previous gap as an approximation.
          sRunIncrement = sRunPrevious - sRunChannel.data[i - 2];
        }

        if (sRunIncrement <= 0) {
          sRunIncrement = 1;
        }

        sRunChannel.data.push(sRunPrevious + sRunIncrement);
      } else {
        sRunChannel.data.push(sRunPrevious + (current - previous));
      }
    }
  }

  private removeSuspiciouslyShortInitialLap() {
    let NLapChannel = this.telemetryConfig.config.channels.find(v => v.name === NLAP_DOMAIN_NAME);
    if(!NLapChannel){
      return;
    }

    const MINIMUM_INITIAL_LAP_POINTS = 5;
    let trimIndices = new OrderedUniqueIndices();
    for (let i = 1; i < Math.min(MINIMUM_INITIAL_LAP_POINTS, NLapChannel.data.length); ++i) {
      let current = NLapChannel.data[i];
      let previous = NLapChannel.data[i - 1];
      if (current !== previous) {
        // Suspiciously short initial lap. Remove.
        for (let trimIndex = 0; trimIndex < i; ++trimIndex) {
          trimIndices.add(trimIndex);
        }

        NLapChannel.data = NLapChannel.data.map(v => v - 1);
        break;
      }
    }

    this.removePointsFromChannels(trimIndices, 'suspiciously short initial lap');
  }

  private removePointsFromChannels(indices: OrderedUniqueIndices, reason: string) {
    let dataToRemoveCount = indices.get().length;
    if (dataToRemoveCount) {
      this.warnMessages.push(`Removing ${dataToRemoveCount} data point${dataToRemoveCount === 1 ? '' : 's'} from each channel due to ${reason}.`);
      Utilities.strip(indices, ...this.telemetryConfig.config.channels.map(v => v.data));
      this.outputDataLength = this.timeChannel.data.length;
    }
  }

  public addDuplicateValueIndices(data: ReadonlyArray<number>, indices: OrderedUniqueIndices) {
    for (let i = data.length - 1; i > 0; --i) {
      if (data[i] === data[i - 1]) {
        indices.add(i);
      }
    }
  }

  public ensureMonotonic(data: ReadonlyArray<number>, domainName: string): void {
    let nonMonotonicIndex = this.getNonMonotonicIndex(data);
    if(nonMonotonicIndex > 0){
      throw new DisplayableError(`${domainName} domain should be monotonically increasing at index ${nonMonotonicIndex}.`);
    }
  }

  public isMonotonic(data: ReadonlyArray<number>): boolean{
    return this.getNonMonotonicIndex(data) === 0;
  }

  public getNonMonotonicIndex(data: ReadonlyArray<number>): number {
    for (let i = data.length - 1; i > 0; --i) {
      if (data[i] < data[i - 1]) {
        return i;
      }
    }

    return 0;
  }

  public processChannelUnits() {
    // Map unit names to standard names (replace 'sec' with 's').
    // Convert units to SI.
    let knownUnits = Units.getKnownUnits().reduce<{[key: string]: string}>((p, c) => {
      p[c] = c;
      return p;
    }, {});
    let supportedSiUnits = this.getSupportedSiUnits().reduce<{[key: string]: string}>((p, c) => {
      p[c] = c;
      return p;
    }, {});
    let channelsToRemove = new OrderedUniqueIndices();
    let channelIndex = 0;

    for (let channel of this.telemetryConfig.config.channels) {
      let units = this.sanitizeUnits(channel.units);

      if (!knownUnits[units]) {
        this.removedChannels.push(new ChannelNameAndReason(channel.name, 'Unrecognised units: ' + channel.units));
        channelsToRemove.add(channelIndex);
      } else {
        let siUnits = Units.getSiUnit(units);
        if (!supportedSiUnits[siUnits]) {
          this.removedChannels.push(new ChannelNameAndReason(channel.name, 'Unrecognised SI units: ' + channel.units));
          channelsToRemove.add(channelIndex);
        } else if (siUnits !== units) {
          Units.replaceValuesWithSi(channel.data, units);

          let existingConvertedUnit = this.unitConversions.find(v => v.from === units);
          if (!existingConvertedUnit) {
            existingConvertedUnit = new UnitConversion(units, siUnits);
            this.unitConversions.push(existingConvertedUnit);
          }
          existingConvertedUnit.channels.push(channel.name);

          channel.units = siUnits;
        }
      }

      ++channelIndex;
    }

    Utilities.strip(channelsToRemove, this.telemetryConfig.config.channels);
  }

  public submit(){
    if(this.errorMessage || this.errorMessages.length){
      return;
    }

    this.telemetryConfigProcessed.emit(this.telemetryConfig);
  }

  public get channelsUnitConverted(): number {
    return this.unitConversions.reduce((p, c) => p + c.channels.length, 0);
  }

  public getSupportedSiUnits(): string[] {
    let siUnitsDocument = this.documentsResult.documents.find(v => v.name === 'si-units.schema.json');
    if(siUnitsDocument) {
      let siUnitsSchema = this.json.parse(siUnitsDocument.content);
      return [...siUnitsSchema.enum];
    } else {
      let telemetryDocument = this.documentsResult.documents.find(v => v.name === 'telemetry.schema.json');
      if(!telemetryDocument){
        throw new DisplayableError('Telemetry importing is not supported for this sim version. Please use a later sim version.');
      }

      let telemetrySchema = this.json.parse(telemetryDocument.content);
      return [...telemetrySchema.properties.channels.items.properties.units.enum];
    }

  }

  private getConfigSummary(config: any): any{
    return this.json.clone(this.telemetryConfig.config, (key: string, value: any) => {
      if(key === 'channels'){
        return (value as MutableTelemetryChannel[]).reduce<{ [name: string]: {units: string; exampleData: any[]}}>((p, c) => {
            p[c.name] = {
              units: c.units,
              exampleData: c.data,
            };
            return p;
          },
          {});
      }
      if(key === 'exampleData' && Array.isArray(value) && value.length > 2){
        return [
          value[0],
          value[1],
        ];
      }

      return value;
    });
  }

  private sanitizeUnits(input: string): string {
    return input
      .replace('seconds', 's')
      .replace('second', 's')
      .replace('secs', 's')
      .replace('sec', 's')
      .replace('�', '°');
  }
}

class UnitConversion {
  public readonly channels: string[] = [];
  constructor(
    public readonly from: string,
    public readonly to: string){
  }
}

class ChannelNameAndReason {
  constructor(
    public readonly name: string,
    public readonly reason: string) {
  }
}


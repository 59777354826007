
export function $each(obj: any, callback: (key: string | number, value: any) => boolean | void ) {
  if(!obj || typeof obj !== 'object'){
    return;
  }

  let i;
  if(Array.isArray(obj) || (typeof obj.length === 'number' && obj.length > 0 && (obj.length - 1) in obj)) {
    for(i=0; i<obj.length; i++) {
      if(callback(i,obj[i])===false) {
        return;
      }
    }
  } else {
    if (Object.keys) {
      let keys = Object.keys(obj);
      for(i=0; i<keys.length; i++) {
        if(callback(keys[i],obj[keys[i]])===false) {
          return;
        }
      }
    } else {
      for(i in obj) {
        if(!obj.hasOwnProperty(i)) {
          continue;
        }
        if(callback(i,obj[i])===false) {
          return;
        }
      }
    }
  }
}



/**
 * A named channel with values that cannot be modified.
 */
export class ReadonlyNamedChannelData {

  /**
   * Creates a new instance of ReadonlyNamedChannelData.
   * @param name The name of the channel.
   * @param values The values of the channel.
   */
  constructor(
    public readonly name: string,
    public readonly values: ReadonlyArray<number> | undefined) {
  }
}

/**
 * A named channel with values that can be modified.
 */
export class NamedChannelData {

  /**
   * Creates a new instance of NamedChannelData.
   * @param name The name of the channel.
   * @param values The values of the channel.
   */
  constructor(
    public readonly name: string,
    public readonly values: number[] | undefined) {
  }
}

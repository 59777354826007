import { SourceData } from './types/source-data';
import { ProcessedMultiPlotChannel } from './types/processed-multi-plot-channel';
import { AutoScaleChannelGroup } from '../channel-data-loaders/auto-scale-channel-group';
import { ChannelIndexMap } from './channel-index-map';
import { GetProcessedChannel } from './get-processed-channel';
import { GetValidatedChannel } from './get-validated-channel';

/**
 * Returns the primary domain in processed form (validated, with data, and auto-scaled if necessary).
 */
export class GetProcessedPrimaryDomain {
  constructor(
    private readonly primaryDomainName: string,
    private readonly getProcessedChannel: GetProcessedChannel,
    private readonly getValidatedChannel: GetValidatedChannel,
    private readonly autoScaleChannelGroup: AutoScaleChannelGroup) {
  }

  /**
   * Returns the primary domain in processed form (validated, with data, and auto-scaled if necessary).
   * @param sourceData The set of data sources.
   * @param channelIndexMap The channel index map.
   * @returns The processed primary domain.
   */
  public execute(sourceData: ReadonlyArray<SourceData>, channelIndexMap: ChannelIndexMap): ProcessedMultiPlotChannel {
    // Get the channel definition in a standard form.
    let validatedChannel = this.getValidatedChannel.execute(this.primaryDomainName);

    // Get the processed channel. A processed channel is a validated channel with data.
    let result = this.getProcessedChannel.execute(validatedChannel, sourceData, channelIndexMap);
    result.isVisible = false;

    // Auto scale the channel if necessary to improve readability.
    this.autoScaleChannelGroup.executeForChannel(result);

    return result;
  }
}

import { Injectable } from '@angular/core';
import { TextFileReader } from '../text-file-reader.service';
import { ConfigStub, DocumentSubType, NewConfigData, SimVersion } from '../../../generated/api-stubs';
import { CustomProperty } from '../custom-properties/custom-properties';
import { CustomPropertyUtilities } from '../custom-properties/custom-property-utilities';
import { CanopyJson } from '../../common/canopy-json.service';
import { ToastrService } from 'ngx-toastr';
import { GetFriendlyErrorAndLog } from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import { LoadingDialog } from '../../common/dialogs/loading-dialog.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigImport {

  constructor(
    private readonly textFileReader: TextFileReader,
    private readonly json: CanopyJson,
    private readonly configStub: ConfigStub,
    private readonly loadingDialog: LoadingDialog,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog,
    private readonly toastrService: ToastrService) { }

  public async extractConfigFromEvent(tenantId: string, event: Event, configType: DocumentSubType, convertToSimVersion?: SimVersion): Promise<NewConfigData> {
    try{
      return await this.doImport(tenantId, event, configType, convertToSimVersion);
    }catch(error){
      this.toastrService.error(this.getFriendlyErrorAndLog.execute(error));
      return null;
    }
  }

  private async doImport(tenantId: string, event: Event, configType: DocumentSubType, convertToSimVersion?: SimVersion): Promise<NewConfigData>{
    let textFile = await this.textFileReader.readSingleFileAndReset(event);
    if(!textFile){
      return null;
    }

    let data = this.json.parse(textFile.content);

    let config = data;
    let notes: string | undefined;
    let customProperties: CustomProperty[] | undefined;
    if(data.simVersion){
      let simVersion = data.simVersion;
      config = data.config;
      notes = data.notes || '';
      customProperties = CustomPropertyUtilities.objectToList(data.customProperties || {});

      if(convertToSimVersion && simVersion !== convertToSimVersion){
        let upgradeResult = await this.loadingDialog.showUntilFinished(this.configStub.upgradeConfig(
          tenantId,
          convertToSimVersion,
          {
            configType,
            config,
            simVersion
          }), 'Upgrading sim version');
        config = upgradeResult.config;
      }
    }
    return {
      name: textFile.name.replace(/\.[^/.]+$/, ''),
      configType,
      config,
      properties: customProperties,
      notes,
      simVersion: data.simVersion
    };

  }

  public async saveConfig(tenantId: string, configData: NewConfigData): Promise<string>{
    try{
      return await this.loadingDialog.showUntilFinished(this.configStub.postConfig(tenantId, configData), 'Saving');
    }catch (error){
      this.toastrService.error(this.getFriendlyErrorAndLog.execute(error));
      return null;
    }
  }
}

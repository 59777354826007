import {Injectable} from '@angular/core';
import {DialogManager, IDialog, DialogBase} from '../../common/dialogs/dialog-manager.service';
import {DocumentMetadata} from '../edit-document-metadata/edit-document-metadata.component';
import {DocumentSubType} from '../../../generated/api-stubs';

@Injectable()
export class EditDocumentMetadataDialog {
  constructor(
    private dialogManager: DialogManager
  ){}

  public show(
    tenantId: string,
    target: DocumentSubType,
    initialDocumentMetadata: DocumentMetadata | undefined,
    saveOnSubmit: boolean): Promise<EditDocumentMetadataResult> {

    return this.dialogManager.showSecondaryDialog(
      new EditDocumentMetadataDialogData(
        tenantId,
        target,
        initialDocumentMetadata,
        saveOnSubmit));
  }
}

export class EditDocumentMetadataDialogData extends DialogBase<EditDocumentMetadataResult> implements IDialog {
  constructor(
    public readonly tenantId: string,
    public readonly target: DocumentSubType,
    public readonly initialDocumentMetadata: DocumentMetadata | undefined,
    public readonly saveOnSubmit: boolean) {
    super();

    this.dismissData = new EditDocumentMetadataResult(undefined);
  }
}

export class EditDocumentMetadataResult {
  constructor(
    public readonly documentMetadata: DocumentMetadata | undefined){
  }
}

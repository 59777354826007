import {Injectable} from '@angular/core';
import {Interceptors} from './http-interceptors/interceptors.service';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';

@Injectable()
export class CanopyHttp {
  constructor(private http: HttpClient, private interceptors: Interceptors) {
  }
/*
  public request(method: string, url: string, options?: BasicRequestOptions): Promise<HttpResponse<any>> {
    return this.performAction(
      options,
      o => this.http.request(method, url, {...o, observe: 'response'}).toPromise());
  }
*/
  public get(url: string, options?: BasicRequestOptions): Promise<HttpResponse<any>> {
    return this.performAction(
      options,
      o => this.http.get(url, {...o, observe: 'response'}).toPromise());
  }

  public post(url: string, body: string, options?: BasicRequestOptions): Promise<HttpResponse<any>> {
    return this.performAction(
      options,
      o => this.http.post(url, body, {...o, observe: 'response'}).toPromise());
  }

  public put(url: string, body: string, options?: BasicRequestOptions): Promise<HttpResponse<any>> {
    return this.performAction(
      options,
      o => this.http.put(url, body, {...o, observe: 'response'}).toPromise());
  }

  public delete(url: string, options?: BasicRequestOptions): Promise<HttpResponse<any>> {
    return this.performAction(
      options,
      o => this.http.delete(url, {...o, observe: 'response'}).toPromise());
  }

  public patch(url: string, body: string, options?: BasicRequestOptions): Promise<HttpResponse<any>> {
    return this.performAction(
      options,
      o => this.http.patch(url, body, {...o, observe: 'response'}).toPromise());
  }

  public head(url: string, options?: BasicRequestOptions): Promise<HttpResponse<any>> {
    return this.performAction(
      options,
      o => this.http.head(url, {...o, observe: 'response'}).toPromise());
  }

  private async performAction(
    options: BasicRequestOptions,
    action: (options: BasicRequestOptions) => Promise<HttpResponse<any>>) {

    let errorResponse: HttpResponse<any>;
    let shouldRetry = true;
    let retryCount = 0;
    while(shouldRetry){
      try {
        return await this.performActionInner(options, action);
      } catch (response) {
        errorResponse = response;
      }

      retryCount += 1;
      shouldRetry = await this.interceptors.responseError(errorResponse, retryCount);
    }

    throw errorResponse;
  }

  private async performActionInner(
    options: BasicRequestOptions,
    action: (options: BasicRequestOptions) => Promise<HttpResponse<any>>) {

    options = await this.interceptors.request(options);
    let response = await action(options);
    return await this.interceptors.response(response);
  }
}

export interface BasicRequestOptions {
  headers?: HttpHeaders;
  withCredentials?: boolean;
}

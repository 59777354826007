import { Units } from '../../../../visualizations/units';
import { Injectable } from '@angular/core';
import { OverrideChildUnitsMode } from '../get-autocomplete-maps.service';
import { humanizeNumber } from '../humanize-number';
import { JsonEditorCustomization } from './json-editor-customization';
import{ JSONEditor } from'@json-editor/json-editor'

@Injectable()
export class NumberEditor extends JsonEditorCustomization {
  public apply(): void {
    JSONEditor.defaults.editors.number = class extends JSONEditor.defaults.editors.string {
      postBuild() {
        super.postBuild();
        this.initializeUnitChangedSubscription();
      }

      sanitize(value: string) {
        return (value + '').replace(/[^0-9\.\-eE]/g, '');
      }

      getNumColumns() {
        return 2;
      }

      getValue() {
        if (this.initialValue && parseFloat(this.value) === this.initialValue.display) {
          return this.initialValue.raw || 0;
        }

        let defaultUnits = this.getDefaultUnits();
        let overrideChildUnits = this.getOverrideChildUnits();
        let applyFactorOnly: boolean = (overrideChildUnits && overrideChildUnits.mode === OverrideChildUnitsMode.scaleOnly);
        return Units.convertValueBetweenUnits(this.value, this.valueUnits, defaultUnits, applyFactorOnly);
      }

      setValue(value: any, initial: any, from_template: boolean) {
        let defaultUnits = this.getDefaultUnits();
        this.valueUnits = this.getUnits();

        let overrideChildUnits = this.getOverrideChildUnits();
        let applyFactorOnly: boolean = (overrideChildUnits && overrideChildUnits.mode === OverrideChildUnitsMode.scaleOnly);
        let displayValue = Units.convertValueBetweenUnits(value, defaultUnits, this.valueUnits, applyFactorOnly);
        if (value !== displayValue) {
          displayValue = humanizeNumber(displayValue);
        }

        this.initialValue = {
          raw: value,
          display: displayValue
        };

        super.setValue(displayValue, initial, from_template);
      }
    }
  }
}


/**
 * Sums the first N values in the array.
 * @param values The values to sum.
 * @param take The number of values to sum.
 * @returns The sum of the first N values.
 */
export function sumFirstN(values: number[], take: number): number {
  return values.slice(0, take).reduce((p, c) => p + c, 0);
}

/**
 * Sums the last N values in the array.
 * @param values The values to sum.
 * @param take The number of values to sum.
 * @returns The sum of the last N values.
 */
export function sumLastN(values: number[], take: number): number {
  return values.slice(Math.max(0, values.length - take)).reduce((p, c) => p + c, 0);
}

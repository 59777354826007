import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { ErrorMessageService } from './services/error-message/error-message.service';

export abstract class ErrorHandlingResolve<T>  {

  constructor(
    private readonly router: Router,
    private readonly errorMessageService: ErrorMessageService
  ) {
  }

  protected abstract resolveInner(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<T>;

  public async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<T> {
    this.errorMessageService.clearErrorMessage();
    try {
      return await this.resolveInner(route, state);
    } catch (error) {
      this.errorMessageService.setErrorMessage(error);
      this.router.navigate(['/error']);
      return undefined;
    }
  }
}

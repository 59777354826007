export class Channel {
  constructor(
    public readonly name: string,
    public readonly simType: string,
    public readonly values: ReadonlyArray<number>){
  }

  public get fullName(): string {
    return this.name + ':' + this.simType;
  }

  public isMonotonic(): boolean {
    for (let i=1; i < this.values.length; ++i) {
      if(this.values[i - 1] >= this.values[i]){
        return false;
      }
    }

    return true;
  }

  public isDecreasingMonotonic(): boolean {
    for (let i=1; i < this.values.length; ++i) {
      if(this.values[i - 1] <= this.values[i]){
        return false;
      }
    }

    return true;
  }
}

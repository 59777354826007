import { MonotonicStatus } from './viewer-channel-data';
import { SearchDirection, Utilities } from '../../utilities';

/**
 * A class to get the interpolated channel value at a domain value.
 */
export class GetInterpolatedChannelValueAtDomainValue {

  /**
   * Returns the interpolated channel value at the given domain value.
   * @param channelData The channel data to interpolate.
   * @param domainValue The domain value to interpolate at.
   * @param domainData The domain data to interpolate against.
   * @param domainMonotonicStatus The monotonic status of the domain data.
   * @returns The interpolated channel value.
   */
  public execute(channelData: ReadonlyArray<number> | undefined, domainValue: number, domainData: ReadonlyArray<number> | undefined, domainMonotonicStatus: MonotonicStatus): number {

    // If the channel data or domain data is not defined, return NaN.
    if (!channelData || !domainData) {
      return NaN;
    }

    // Find the index after the given value in the domain data.
    let index: number;
    if (domainMonotonicStatus === MonotonicStatus.Decreasing) {
      index = Utilities.findIndexInMonotonicallyDecreasingData(domainData, domainValue, SearchDirection.Forwards);
    } else if (domainMonotonicStatus === MonotonicStatus.Increasing) {
      index = Utilities.findIndexInMonotonicallyIncreasingData(domainData, domainValue, SearchDirection.Forwards);
    } else {
      return NaN;
    }

    // Interpolate the channel value at the index.
    return this.executeAtIndex(channelData, domainValue, domainData, index);
  }

  /**
   * Interpolates the channel value at the given index.
   * @param channelData The channel data to interpolate.
   * @param domainValue The domain value to interpolate at.
   * @param domainData The domain data to interpolate against.
   * @param index The index to interpolate at (found in the calling function).
   * @returns The interpolated channel value.
   */
  protected executeAtIndex(channelData: ReadonlyArray<number>, domainValue: number, domainData: ReadonlyArray<number>, index: number) {
    if (index === -1) {
      // The value -1 is returned if the domain value is never found in the monotonic search, so we are at the end.
      // Return the last value.
      return channelData[channelData.length - 1];
    } else if (index === 0) {
      // The value 0 is returned if the first value is past the domain value.
      // Return the first value.
      return channelData[0];
    } else {
      // Interpolate the value between the two domain values on either side of the requested value.
      let r = (domainValue - domainData[index - 1]) / (domainData[index] - domainData[index - 1]);
      return channelData[index - 1] + r * (channelData[index] - channelData[index - 1]);
    }
  }
}

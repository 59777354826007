import { MonotonicStatus } from '../viewer-channel-data';
import { ExplorationSubSweep } from './exploration-sub-sweep';

/**
 * Represents a single sweep of an exploration.
 */
export class ExplorationSweep {

  /**
   * Gets whether all the sub-sweeps are monotonic.
   */
  public readonly areSubSweepsMonotonic: boolean;

  /**
   * Gets the normalized indices of the sub-sweeps.
   */
  public readonly normalizedIndices: ReadonlyArray<number>;

  /**
   * Creates an instance of ExplorationSweep.
   * @param subSweeps The sub-sweeps of the exploration.
   */
  constructor(public readonly subSweeps: ReadonlyArray<ExplorationSubSweep>) {

    this.areSubSweepsMonotonic = subSweeps.every(v => v.monotonicStatus === MonotonicStatus.Increasing || v.monotonicStatus === MonotonicStatus.Decreasing);

    this.normalizedIndices = subSweeps.length === 0 ? [] : subSweeps[0].normalizedIndices;

    if (subSweeps.some(v => v.normalizedIndices.length !== this.normalizedIndices.length)) {
      throw new Error('SubSweep lengths did not match.');
    }
  }
}

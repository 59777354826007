import { ChartSettings } from '../chart-settings';
import { Margin } from '../margin';

/**
 * Settings for the 3D suspension viewer.
 */
export class SuspensionViewer3dSettings extends ChartSettings {

  /**
   * Create a new instance of the suspension viewer 3D settings.
   * @param sourceCount The number of data sources.
   * @returns A new instance of the suspension viewer 3D settings.
   */
  public static build(sourceCount: number): SuspensionViewer3dSettings {
    return new SuspensionViewer3dSettings(sourceCount);
  }

  /**
   * Create a new instance of the suspension viewer 3D settings.
   * @param sourceCount The number of data sources.
   */
  constructor(sourceCount: number) {
    super(sourceCount);
    this.svgPadding = new Margin(5, 5, 18, 5);
  }
}

import {RuleLogger} from './rule-logger';
import {SimChannel} from './sim-channel';
import {RuleContextBase} from './rule-context-base';
import {ConfigContext} from './config-context';
import {SimContext} from './sim-context';
import {RuleTargets} from './rule-targets';

export const ANY_TARGET = 'ANY_TARGET';

export abstract class Rule {

  public abstract get configTypes(): string[] | string | undefined | null;
  public abstract get simTypes(): string[] | string | undefined | null;
  public abstract get name(): string;

  public getTargets(): RuleTargets {
    return new RuleTargets(
      this.toArray(this.configTypes),
      this.toArray(this.simTypes));
  }

  public evaluateConfig(context: ConfigContext, log: RuleLogger) {
    return;
  }

  public getSimChannels(context: SimContext): SimChannel[] {
    return [];
  }

  public evaluateSim(context: SimContext, log: RuleLogger) {
    return;
  }

  public canExecute(context: RuleContextBase): boolean {
    let targets = this.getTargets();

    if (context instanceof ConfigContext) {
      return targets.configTypes.some(v => context.is(v)) || targets.configTypes.indexOf(ANY_TARGET) !== -1;
    }

    if (context instanceof SimContext) {
      return targets.simTypes.some(v => context.is(v)) || targets.simTypes.indexOf(ANY_TARGET) !== -1;
    }

    return false;
  }

  public execute(context: RuleContextBase, log: RuleLogger) {
    if (context instanceof ConfigContext) {
      this.evaluateConfig(context, log);
    }

    if (context instanceof SimContext) {
      return this.evaluateSim(context, log);
    }
  }

  public getChannels(context: RuleContextBase): SimChannel[] {
    if (context instanceof SimContext) {
      return this.getSimChannels(context);
    }

    return [];
  }

  private toArray(input: string[] | string | undefined): string[] {
    if(!input){
      return [];
    }

    if(!Array.isArray(input)) {
      return [ input ];
    }

    return input;
  }
}

@if (downloadStudyTypes.length >= 1) {
  <span class="inline-button-text">Download:</span>
  <span class="button-menu-hover-target">
    <button
      [title]="downloadStudyTypes[0].description"
      class="btn btn-smart btn-secondary"
      [disabled]="!isCompleted"
      id="download-results-{{downloadStudyTypes[0].name}}-hover-button">
      <i class="fa fa-download"></i>{{downloadStudyTypes[0].displayName}}
    </button>
    <span class="button-menu-hovering-area">
      @for (download of downloadStudyTypes; track download) {
        <button
          [title]="download.description"
          class="btn btn-smart btn-secondary"
          [disabled]="!isCompleted"
          (click)="download.execute()"
          id="download-results-{{download.name}}-button">
          <i class="fa fa-download"></i>{{download.displayName}}
        </button>
      }
    </span>
  </span>
}

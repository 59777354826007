import { TRACK_VIEWER_TYPE, TrackViewer3d } from '../../viewers/track-viewer/track-viewer-3d';
import { SourceLoaderViewModel } from '../../viewers/channel-data-loaders/source-loader-set';
import { SharedState } from '../../viewers/shared-state';
import { ChannelNameStyle } from '../../viewers/channel-data-loaders/channel-name-style';
import { NavigationStationConfig, NavigationStationConfigBuilder } from './navigation-station-config-builder';
import { SiteHooks } from '../../site-hooks';
import { UrlFileLoader } from '../../url-file-loader';
import {
  LocalConfigSourceLoader,
  UnitsMap
} from '../../viewers/channel-data-loaders/local-config-source-loader';
import { INDEX_DOMAIN_NAME } from '../../constants';
import { DomainNewsCache } from '../../viewers/domain-news-cache';
import { ConfigData, ConfigPreviewConfigBuilder } from './config-preview-config-builder-base';

/**
 * A config builder for viewing track previews.
 */
export class TrackPreviewConfigBuilder extends ConfigPreviewConfigBuilder implements NavigationStationConfigBuilder {

  /**
   * Constructs a new Track preview config builder.
   * @param fileLoader The file loader.
   * @param siteHooks The site hooks.
   * @param configs The configs for this builder session.
   * @param unitsMap The units map.
   */
  constructor(
    fileLoader: UrlFileLoader,
    siteHooks: SiteHooks,
    configs: ReadonlyArray<ConfigData>,
    unitsMap?: UnitsMap) {
    super('Track', fileLoader, siteHooks, configs, unitsMap);
  }

  /**
   * Adds the source loaders to the shared state.
   * @param sharedState The shared state.
   */
  protected async populateSources(sharedState: SharedState): Promise<void> {
    // Add a source loader for each supplied config.
    for (let configData of this.configs) {
      sharedState.sourceLoaderSet.add(new SourceLoaderViewModel(LocalConfigSourceLoader.create(this.siteHooks, configData.data, configData.name)));
    }
  }

  public trackPreviewName: string = 'Track Preview';

  /**
   * @inheritdoc
   */
  public async build(): Promise<NavigationStationConfig> {

    let config: NavigationStationConfig = {
      channelNameStyle: ChannelNameStyle.Generic,
      sharedStates: [],
      views: []
    };

    let defaultGridSlot = {
      x: 0,
      y: 0,
      width: 12,
      height: 8
    };

    let domainNewsCache = new DomainNewsCache();

    // Add the track viewer.
    {
      let sharedState = new SharedState(domainNewsCache);
      config.sharedStates.push(sharedState);

      await this.populateSources(sharedState);

      config.views.push({
        title: 'Track Preview',
        viewerType: TRACK_VIEWER_TYPE,
        layout: undefined,
        viewer: TrackViewer3d.create(this.siteHooks, sharedState),
        grid: defaultGridSlot
      });
    }

    defaultGridSlot.height = 7;
    defaultGridSlot.width = 6;

    // Try and add various viewers for different aspects of possible track data.
    await this.createConfigAreaChart('racingLine', 'Racing Line', 'sLap', config, defaultGridSlot, domainNewsCache, undefined, { onlyIfPrimaryDomainExists: true });
    await this.createConfigAreaChart('racingLine', 'Racing Line XY', INDEX_DOMAIN_NAME, config, defaultGridSlot, domainNewsCache, undefined);
    await this.createConfigAreaChart('centreLine', 'Centre Line', 'sLapCentreLine', config, defaultGridSlot, domainNewsCache);
    await this.createConfigAreaChart('trackOutline', 'Track Outline Left', INDEX_DOMAIN_NAME, config, defaultGridSlot, undefined, { filter: /.*Left/ });
    await this.createConfigAreaChart('trackOutline', 'Track Outline Right', INDEX_DOMAIN_NAME, config, defaultGridSlot, undefined, { filter: /.*Right/ });
    await this.createConfigAreaChart('trackConditions.WindLookup', 'Track Conditions - Wind Lookup', 'sLap', config, defaultGridSlot, domainNewsCache);
    await this.createConfigAreaChart('trackConditions.GripLookup', 'Track Conditions - Grip Lookup', 'sLap', config, defaultGridSlot, domainNewsCache);
    await this.createConfigAreaChart('strategyWeights', 'Strategy Weights', 'sLap', config, defaultGridSlot, undefined, { xDomainAlias: 'sLapBasis2', filter: { test: v => v !== 'rLapWeights' } });
    await this.createConfigAreaChart('strategyWeights', 'Strategy Lap Weights', INDEX_DOMAIN_NAME, config, defaultGridSlot, undefined, { filter: /rLapWeights/ });
    await this.createConfigAreaChart('gearLimits.minimumGearLookup', 'Gear Limits - Minimum Gear Lookup', 'sLap', config, defaultGridSlot, domainNewsCache, { xDomainAlias: 'sLapBasis' });
    await this.createConfigAreaChart('gearLimits.maximumGearLookup', 'Gear Limits - Maximum Gear Lookup', 'sLap', config, defaultGridSlot, domainNewsCache, { xDomainAlias: 'sLapBasis' });

    return config;
  }

}

import { Injectable, NgZone } from '@angular/core';
import { OverrideChildUnits } from '../get-autocomplete-maps.service';
import { UnitsManager } from '../../../../units/units-manager.service';
import { JsonEditorCustomization } from './json-editor-customization';
import { getCanopyJsonEditorOptions } from './get-canopy-json-editor-options';
import awesomplete from 'awesomplete';
import {JSONEditor} from '@json-editor/json-editor';

@Injectable()
export class StringEditor extends JsonEditorCustomization {

  constructor(
    private readonly zone: NgZone,
    private readonly unitsManager: UnitsManager) {
    super();
  }

  public apply(): void {
    const service = this;
    JSONEditor.defaults.editors.string = class extends JSONEditor.defaults.editors.string {
      getAutocompleteMap() {
        const canopyOptions = getCanopyJsonEditorOptions(this);

        if (!canopyOptions.autoCompleteMaps) {
          return undefined;
        }

        return canopyOptions.autoCompleteMaps.find(v => this.path.endsWith(v.pathSuffix));
      }

      getOverrideChildUnits(): OverrideChildUnits | undefined {
        let parent = this.parent;
        while (parent) {
          if (parent.overrideChildUnits) {
            return parent.overrideChildUnits;
          }

          parent = parent.parent;
        }
        return undefined;
      }

      preBuild() {
        super.preBuild();
        const canopyOptions = getCanopyJsonEditorOptions(this);

        this.getDefaultUnitsAndChannelName = () => {
          let defaultUnits = this.schema.units;
          let channelName = this.schema.unitsChannelName || this.path.split('.').pop();;

          if (defaultUnits) {
            let overrideChildUnits = this.getOverrideChildUnits();
            if (overrideChildUnits) {
              defaultUnits = overrideChildUnits.units || defaultUnits;
              channelName = overrideChildUnits.channelName || channelName;
            }
          }

          return {
            units: defaultUnits,
            channelName
          };
        };
        this.getUnitsAndChannelName = () => {
          let defaultUnitsAndChannel = this.getDefaultUnitsAndChannelName();
          let defaultUnits = defaultUnitsAndChannel.units;
          let channelName = defaultUnitsAndChannel.channelName;

          let units = service.unitsManager.getUnitsSynchronous(channelName, defaultUnits);

          return {
            units,
            channelName
          };
        };
        this.getDefaultUnits = () => this.getDefaultUnitsAndChannelName().units;
        this.getUnits = () => this.getUnitsAndChannelName().units;
        this.initializeUnitChangedSubscription = () => {
          canopyOptions.internalUnitsChanged.subscribe(() => {
            service.zone.runOutsideAngular(() => {
              let previousUnits = this.valueUnits;
              let newUnits = this.getUnits();

              // We always update if overrideChildUnits is set because we may have changed the OverrideChildUnitsMode.
              let overrideChildUnits = this.getOverrideChildUnits();

              if (previousUnits !== newUnits || overrideChildUnits) {
                let value = this.getValue();
                this.setValue(value, undefined, undefined);
              }
            });
          });
        };
        this.sanitizeArray = (value: any) => {
          if (value && !Array.isArray(value)) {
            if (typeof value === 'object') {
              value = [];
            } else {
              value = [value];
            }
          }
          return value || [];
        };
        this.sanitizeArray2D = (value: any) => {
          if (value) {
            if (!Array.isArray(value)) {
              if (typeof value === 'object') {
                return [[]];
              }
              return [[value]];
            }

            for (let i = 0; i < value.length; ++i) {
              value[i] = this.sanitizeArray(value[i]);
            }

            return value;
          }

          return [[]];
        };
        this.replaceNullWithNaN = (value: any) => {
          if (!Array.isArray(value)) {
            return;
          }

          for (let i = 0; i < value.length; ++i) {
            if (value[i] === null || value[i] === undefined) {
              value[i] = NaN;
            }
          }
        };
        this.replaceNullWithNaN2D = (value: any) => {
          if (!Array.isArray(value)) {
            return;
          }

          for (let row of value) {
            this.replaceNullWithNaN(row);
          }
        };
      }

      build() {
        super.build();

        this.container.removeChild(this.control);
        this.control = this.theme.getFormControl(this.label, this.input, this.description, this.infoButton, this.formname, this.schema, this);
        this.container.prepend(this.control);

        let map = this.getAutocompleteMap();
        if (map) {

          let a = new awesomplete(this.input, map.options);
          if (map.popupOnSelect) {
            this.input.addEventListener('awesomplete-selectcomplete', (e: Event) => {
              this.refreshValue();
              this.onChange(true);
              a.open();
              a.evaluate();
            });
            this.input.addEventListener('focus', function(e: Event) {
              a.open();
              a.evaluate();
            });
          }

          if (map.changedAction) {
            this.input.addEventListener('change', (e: Event) => {
              map.changedAction(this.value, this.parent);
            });

            this.input.addEventListener('awesomplete-selectcomplete', (e: Event) => {
              map.changedAction(this.value, this.parent);
            });
          }

          if (map.userInformation.length) {
            for (let userInformation of map.userInformation) {
              this.description ?
                this.description.parentElement.insertBefore(this.theme.getFormInputDescription(userInformation), this.description) :
                this.control.appendChild(this.theme.getFormInputDescription(userInformation));
            }
          }
        }
      }

      postBuild() {
        super.postBuild();

        // Below is called in the super class within a requestAnimationFrame
        /* Any special formatting that needs to happen after the input is added to the dom */
        if (this.input.parentNode) this.afterInputReady()
          if (this.adjust_height) this.adjust_height(this.input)
          if (this.format === 'range') {
            const output = this.control.querySelector('output')
            output.value = this.input.value
          }
      }

      setValue(value: any, initial: any, from_template: boolean) {
        super.setValue(value, initial, from_template);
        if(this.schema.default){
          if(!this.default){
            this.default = document.createElement('span');
            this.default.classList.add('input-group-text');
            this.default.innerHTML = 'default';
          }
          if(this.schema.default === this.getValue()){
            this.input.after(this.default);
          }
        }

        let map = this.getAutocompleteMap();
        if (map && map.changedAction) {
          map.changedAction(value, this.parent);
        }
      }

      refreshValue() {
        super.refreshValue();
        if(this.schema.default && this.default){
          if(this.schema.default !== this.getValue()){
            this.default.remove();
          } else {
            this.input.after(this.default);
          }
        }
      }
    };
  }
}

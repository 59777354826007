export const SLIDERS_NAME = 'Sliders';
export const SOURCE_LABELS_NAME = 'Source Labels Renderer';
export const LEGEND_RENDERER_NAME = 'Legend Renderer';
export const MULTI_PLOT_VIEWER_LINE_RENDERER_NAME = 'MPV Line Renderer';

export const CAR_NAME = 'Car Charts';
export const CAR_WITH_EMBEDDED_CUSTOM_PROPERTIES_NAME = 'Car With Embedded Custom Properties';
export const SINGLE_JOB_MULTI_SIM_NAME = 'Single-Job Multi-Sim';
export const USER_MATHS_NON_SI_UNITS = 'User Maths Non-SI Units';
export const MULTI_JOB_MULTI_LAP_OVERLAY_NAME = 'Multi-Job Multi-Lap Overlay';
export const SINGLE_JOB_MULTI_LAP_OVERLAY_SPLIT_LAPS_NAME = 'Single-Job Multi-Lap Overlay (Split Laps)';
export const MULTI_JOB_OVERLAY_HIDE_JOBS_NAME = 'Multi-Job Overlay (Hide Jobs)';
export const CHANNEL_INFERENCE_OVERLAY_NAME = 'Channel Inference Overlay';
export const MULTI_JOB_MULTI_SIM_OVERLAY_NAME = 'Multi-Job Multi-Sim Overlay';
export const STAR_CONTAINING_NAN_SCALAR_RESULTS_IN_ALL_LAPS_NAME = 'Star with NaN Scalar Results';
export const STAR_SINGLE_ELEMENT_DIMENSION_NAME = 'Star with single element dimension';
export const STAR_SINGLE_ARRAY_ELEMENT_DIMENSION_NAME = 'Star with single array element dimension';
export const FAILED_FIRST_JOB_NAME = 'Failed First Job';
export const FACTORIAL_COMPONENT_ENUMERATION_NAME = 'Factorial Component Enumeration';
export const MONTE_CARLO_8D_NAME = 'Monte-Carlo 8D';
export const FACTORIAL_MONTE_CARLO_NAME = 'Factorial Monte-Carlo';
export const MONTE_CARLO_NO_APEX_SIM_NAME = 'Monte-Carlo No ApexSim';
export const TWO_LAP_MONTE_CARLO_NAME = 'Two Lap Monte-Carlo';
export const ELEVATION_STUDY = 'Track with Elevation Study';
export const CAR_PREVIEW_NAME = 'Car Preview';
export const TELEMETRY_PREVIEW_NAME = 'Telemetry Preview';
export const CAR_WITHOUT_SUSPENSION_PREVIEW_NAME = 'Car Without Suspension Preview';
export const TRACK_PREVIEW_NAME = 'Track Preview';
export const TRACK_PREVIEW_NON_PERIODIC_NAME = 'Track Preview (Non-Periodic)';
export const TRACK_PREVIEW_DAYTONA_NAME = 'Track Preview (Daytona)';
export const TRACK_PREVIEW_RACING_LINE_DEFINED_BY_XY_NAME = 'Track Preview (Racing Line defined by XY)';
export const TRACK_PREVIEW_RACING_LINE_DEFINED_BY_GLAT_NAME = 'Track Preview (Racing Line defined by gLat)';
export const TRACK_PREVIEW_DIAGONAL_START_FINISH_LINE_AND_OFFSET = 'Track Preview (Diagonal Start-Finsh Line + Offset)';
export const SLAP_ERROR_ACCUMULATION = 'sLap Error Accumulation';
export const TRACK_PREVIEW_WIDTH_ADJUST_AFFECTS_ELEVATION_RENDERING = 'Track Preview (Width adjust affects elevation rendering)';
export const TRACK_PREVIEW_CAMBER_NAME = 'Track Preview (Camber)';
export const RACING_LINE_JOB_NAME = 'Racing Line Result';
export const TRACK_CONVERTER_NAME = 'Track Converter';
export const TRACK_CHALLENGING_JOB_NAME = 'Challenging Track';
export const TRACK_DML_MORE_THAN_MAXIMUM_VERTICES = 'Track > Max Vertices';
export const RACING_LINE_WITH_METADATA_JOB_NAME = 'Racing Line With Metadata Result';
export const OVERTAKING_LAP_JOB_NAME = 'Overtaking Lap Result';
export const TELEMETRY_OVERLAY_NAME = 'Telemetry Overlay (no sRun)';
export const MONTE_CARLO_REVERSED_INPUTS_PARTIAL_COVERAGE = 'Monte-Carlo Reversed Inputs Partial Coverage';
export const FACTORIAL_REVERSED_INPUTS_WITH_ENUMERATION = 'Factorial Reversed Inputs With Enumeration';
export const FACTORIAL_REVERSED_INPUTS_WITH_CONFIG_ENUMERATION = 'Factorial Reversed Inputs With Config Enumeration';
export const FACTORIAL_REVERSED_ARRAY_INPUT = 'Factorial Reversed Array Input';
export const FACTORIAL_ASCENDING_DESCENDING_DIMENSIONS = 'Factorial Ascending and Descending Dimensions';

export const MULTI_PLOT_AXIS_RENDERER_NAME = 'Multi Plot Axis Renderer';
export const SCATTER_PLOT_DATA_RENDERER_NAME = 'Scatter Plot Data Renderer';
export const SCATTER_PLOT_VIEWER_NAME = 'Scatter Plot Viewer';
export const LINE_PLOT_VIEWER_NAME = 'Line Plot Viewer';

@if (showButton) {
  <button
    type="button"
    class="btn btn-smart btn-secondary set-ownership-dialog-launcher"
    [ngClass]="{'btn-icon': compactButton}"
    (click)="execute()">
    <i class="fa fa-user-circle-o" aria-hidden="true"></i>
    @if (!compactButton) {
      <span>Take Ownership</span>
    }
  </button>
}
<cs-error-message [message]="errorMessage"></cs-error-message>

import { getSafePath } from '../get-safe-path';
import { ExtensionButtonCategories } from './extension-button-categories';
import { ExtensionButtonNames } from './extension-button-names';
import { ExtensionButtons } from './extension-buttons';
import { AddButtonsHandler } from './add-buttons-handler';
import { EditorNodeUtilities } from './editor-node-utilities';


export const AllButtonCategories: ReadonlyArray<ExtensionButtonCategories> = [
  ExtensionButtonCategories.load,
  ExtensionButtonCategories.import,
  ExtensionButtonCategories.tirToCanopy,
  ExtensionButtonCategories.importTir,
  ExtensionButtonCategories.encrypt,
];

export type ButtonMap = { [key in ExtensionButtonNames]?: HTMLButtonElement };

export class EditorNodeExtensions {

  private readonly allExtensionButtons: ExtensionButtons[] = [];

  constructor(
    private readonly extensions: ReadonlyArray<AddButtonsHandler>,
    private readonly self: any,
    private readonly editorNodeUtilities: EditorNodeUtilities) {
  }

  public update() {
    for (let extensionButtons of this.allExtensionButtons) {
      extensionButtons.updateDelegate();
    }
  }

  public addButtonsContainer(parent: any, buttonCategories: ReadonlyArray<ExtensionButtonCategories>): ExtensionButtons | undefined {

    let fullPath = this.self.path;
    let safePath = getSafePath(fullPath);

    let subTreeControls = this.self.theme.getHeaderButtonHolder();
    subTreeControls.className += ' hover-button-group';
    parent.appendChild(subTreeControls);

    if (safePath) {
      return this.addButtons(subTreeControls, buttonCategories);
    }

    return undefined;
  }

  public addButtons(subTreeControls: any, buttonCategories: ReadonlyArray<ExtensionButtonCategories>): ExtensionButtons {

    let allResults: ExtensionButtons[] = [];
    let updateDelegate = () => {
      for (let result of allResults) {
        if (result && result.updateDelegate) {
          result.updateDelegate();
        }
      }
    };

    for (let category of buttonCategories) {
      for (let extension of this.extensions) {
        if (extension.category !== category) {
          continue;
        }

        let result = extension.execute(this.editorNodeUtilities, subTreeControls, updateDelegate);
        if (result) {
          allResults.push(result);
        }
      }
    }

    let result = new ExtensionButtons(
      updateDelegate,
      allResults.map(v => v.buttons).reduce(
        (p, c) => ({
            ...p,
            ...c
          }),
        {} as ButtonMap));

    this.allExtensionButtons.push(result);

    return result;
  }
}


<div class="container-fluid">
  <div class="card">
    <h1 class="card-header">Company Settings</h1>
    <div class="card-body">
      <cs-error-message [message]="errorMessage"></cs-error-message>

      @if (!form) {
        <cs-loader></cs-loader>
      }
      @if (form) {
        <div class="row">
          <div class="col-12 col-sm-6">
            <form (ngSubmit)="onSubmit()" [formGroup]="form">
              <div class="mb-3">
                <label class="form-label" for="name-input">Name</label>
                <input id="name-input" type="text" class="form-control" formControlName="name">
                @if (!name.pristine && name.hasError('required')) {
                  <p class="text-danger">
                    Name is required
                  </p>
                }
                @if (name.hasError('minlength')) {
                  <p class="text-danger">
                    Name is too short.
                  </p>
                }
                @if (name.hasError('maxlength')) {
                  <p class="text-danger">
                    Name is too long.
                  </p>
                }
              </div>
              @if (isAdministrator) {
                <div>
                  <div class="mb-3">
                    <label class="form-label" for="short-name-input">Sign-in Name</label>
                    <input id="short-name-input" type="text" class="form-control" formControlName="shortName">
                    @if (!shortName.pristine && shortName.hasError('required')) {
                      <p class="text-danger">
                        Sign-in Name is required
                      </p>
                    }
                    @if (shortName.hasError('minlength')) {
                      <p class="text-danger">
                        Sign-in Name is too short.
                      </p>
                    }
                    @if (shortName.hasError('maxlength')) {
                      <p class="text-danger">
                        Sign-in Name is too long.
                      </p>
                    }
                  </div>
                  <div class="mb-3">
                    <label class="form-label" for="database-id-input">Database ID</label>
                    <input id="database-id-input" type="text" class="form-control" formControlName="databaseId">
                    @if (shortName.hasError('maxlength')) {
                      <p class="text-danger">
                        Database ID is too long.
                      </p>
                    }
                  </div>
                  <div class="mb-3">
                    <input id="is-enabled-input" formControlName="isTenantEnabled" type="checkbox">
                    <label class="form-label" for="is-enabled-input">Enabled</label>
                  </div>
                </div>
              }
              @if (saveSuccessful) {
                <div id="success-message" class="alert alert-success">
                  Saved.
                </div>
              }
              <button id="tenant-settings-submit" type="submit" class="btn btn-smart btn-primary" [disabled]="submitButton.isSubmitting">{{submitButton.text}}</button>
            </form>
          </div>
        </div>
      }
    </div>
  </div>


</div>

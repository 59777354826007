export const ADMINISTRATOR_ROLE: string = 'administrator';
export const TENANT_ADMINISTRATOR_ROLE: string = 'tenant-administrator';
export const TEST_USER_ROLE: string = 'test-user';
export const DELETED_USER_ROLE: string = 'deleted-user';

export class UserIdentity {
  public static readonly unauthenticated = new UserIdentity(false, undefined, undefined, undefined, undefined, [], undefined);

  public static fromMutable(mutable: IMutableUserIdentity) {
    return new UserIdentity(
      mutable.authenticated,
      mutable.accessToken,
      mutable.refreshToken,
      mutable.userId,
      mutable.tenantId,
      mutable.roles,
      mutable.nonImpersonatedUserId);
  }

  public static toMutable(value: UserIdentity): IMutableUserIdentity {
    return {
      authenticated: value.authenticated,
      accessToken: value.accessToken,
      refreshToken: value.refreshToken,
      userId: value.userId,
      tenantId: value.tenantId,
      roles: value.roles ? [...value.roles] : [],
      nonImpersonatedUserId: value.nonImpersonatedUserId};
  }

  constructor(
    public readonly authenticated: boolean,
    public readonly accessToken: string,
    public readonly refreshToken: string,
    public readonly userId: string,
    public readonly tenantId: string,
    public readonly roles: ReadonlyArray<string>,
    public readonly nonImpersonatedUserId?: string) {

    if(!this.roles){
      this.roles = [];
    }
  }

  public isInRole(role: string): boolean {
    return this.roles ?  this.roles.indexOf(role) !== -1 : false;
  }

  public get isTestUser() {
    return this.isInRole(TEST_USER_ROLE);
  }

  public get isAdministrator() {
    return this.isInRole(ADMINISTRATOR_ROLE);
  }

  public get isTenantAdministrator() {
    return this.isAdministrator || this.isInRole(TENANT_ADMINISTRATOR_ROLE);
  }
}

export interface IMutableUserIdentity {
  authenticated: boolean;
  accessToken: string;
  refreshToken: string;
  userId: string;
  tenantId: string;
  roles: string[];
  nonImpersonatedUserId: string;
}

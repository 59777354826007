import { ChartSettings } from '../chart-settings';
import { Margin } from '../margin';

/**
 * Settings for multi-plot viewers.
 */
export abstract class MultiPlotViewerSettings extends ChartSettings {

  /**
   * Creates a new instance of MultiPlotViewerSettings.
   * @param sourceCount The number of sources.
   */
  constructor(sourceCount: number) {
    super(sourceCount);

    this.svgPadding = new Margin(5, 5, 18, 5);
  }

  /**
   * Gets the space between plots.
   */
  public get spaceBetweenPlots(): number {
    return 6;
  }
}

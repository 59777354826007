import {IContextDataLoader} from './runner/context-data-loader';
import {LintingEngine} from './runner/linting-engine';
import {RulesOrchestrator} from './runner/rules-orchestrator';
import {Injectable} from '@angular/core';
import {RegistryFactory} from './rules/_index';

@Injectable()
export class LintingEngineFactory {
  constructor(){
  }

  public create(contextDataLoader: IContextDataLoader): LintingEngine {
    const registryFactory = new RegistryFactory();
    const registry = registryFactory.create();
    return new LintingEngine(contextDataLoader, new RulesOrchestrator(registry));
  }
}

import {Injectable} from '@angular/core';
import {WorksheetRow} from '../../generated/api-stubs';
import {CanopyJson} from '../common/canopy-json.service';

/**
 * A service for managing the worksheet clipboard.
 */
@Injectable()
export class WorksheetClipboard {

  /**
   * The current clipboard content.
   */
  private cache: ClipboardContent | undefined;

  /**
   * Creates an instance of WorksheetClipboard.
   * @param json The JSON service.
   */
  constructor(
    private readonly json: CanopyJson) {
  }

  /**
   * Creates a new ClipboardContent instance.
   * @param sourceTenantId The source tenant ID.
   * @param sourceWorksheetId The source worksheet ID.
   * @param rows The set of rows.
   * @returns The clipboard content.
   */
  public createContent(sourceTenantId: string, sourceWorksheetId: string, rows: ReadonlyArray<WorksheetRow>): ClipboardContent {
    return new ClipboardContent(
      sourceTenantId,
      sourceWorksheetId,
      this.json.clone(rows) || []);
  }

  /**
   * Copies the given rows to the clipboard.
   * @param sourceTenantId The source tenant ID.
   * @param sourceWorksheetId The source worksheet ID.
   * @param rows The set of rows.
   */
  public copy(sourceTenantId: string, sourceWorksheetId: string, rows: ReadonlyArray<WorksheetRow>) {
    this.cache = this.createContent(sourceTenantId, sourceWorksheetId, rows);
  }

  /**
   * Returns a copy of the current clipboard content.
   * @returns The clipboard content.
   */
  public get(): ClipboardContent | undefined {
    if(!this.cache) {
      return undefined;
    }

    return this.cache.withRows(this.json.clone(this.cache.rows));
  }
}

/**
 * The content of the clipboard.
 */
export class ClipboardContent {

  /**
   * Creates an instance of ClipboardContent.
   * @param sourceTenantId The source tenant ID.
   * @param sourceWorksheetId The source worksheet ID.
   * @param rows The set of rows.
   */
  constructor(
    public readonly sourceTenantId: string,
    public readonly sourceWorksheetId: string,
    public readonly rows: ReadonlyArray<WorksheetRow>) {
    if(!rows){
      this.rows = [];
    }
  }

  /**
   * Creates a copy of the ClipboardContent with the given rows.
   * @param rows The set of rows.
   * @returns The new clipboard content.
   */
  public withRows(rows: ReadonlyArray<WorksheetRow>) {
    return new ClipboardContent(this.sourceTenantId, this.sourceWorksheetId, rows);
  }
}

import {Component} from '@angular/core';
import {CanopyValidators} from '../common/forms/canopy-validators.service';
import {FormSubmissionButton} from '../common/forms/form-submission-button';
import {FormSubmissionHandler} from '../common/forms/form-submission-handler.service';
import {AccountSettingsStub} from '../../generated/api-stubs';
import {OnInit} from '@angular/core';
import {FetchUserState} from '../user-state/fetch-user-state.service';
import {GetFriendlyErrorAndLog} from '../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {InferableRouteParams} from '../common/inferable-route-params.service';
import {UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';

@Component({
  templateUrl: './account-settings.page.html'
})
export class AccountSettingsPage implements OnInit {
  public form: UntypedFormGroup;
  public email: UntypedFormControl = new UntypedFormControl('', [Validators.required, ...CanopyValidators.emailValidators]);
  public username: UntypedFormControl = new UntypedFormControl('', [Validators.required, ...CanopyValidators.usernameValidators]);
  public currentPassword: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
  public password: UntypedFormControl = new UntypedFormControl('', [...CanopyValidators.passwordValidators]);
  public isUserEnabled: UntypedFormControl = new UntypedFormControl(false);

  public errorMessage: string;
  public submitButton = new FormSubmissionButton('Save', 'Saving...');
  public saveSuccessful = false;
  public changePassword = false;
  public isSelf: boolean;

  public userId: string;
  public tenantId: string;
  public twoFactorEnabled: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private inferableRouteParams: InferableRouteParams,
    private fetchUserState: FetchUserState,
    private accountSettingsStub: AccountSettingsStub,
    private formSubmissionHandler: FormSubmissionHandler,
    private getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  public ngOnInit() {
    this.load();
  }

  public async load() {
    try {
      this.userId = this.inferableRouteParams.getUserId(this.route);
      this.tenantId = this.inferableRouteParams.getTenantId(this.route);

      let currentUserState = await this.fetchUserState.get();

      this.twoFactorEnabled = currentUserState.isTwoFactorEnabled;

      this.isSelf = currentUserState.userId === this.userId;
      if(this.isSelf){
        this.username.setValue(currentUserState.username, {});
        this.email.setValue(currentUserState.email, {});
        this.isUserEnabled.setValue(currentUserState.isEnabled, {});
      } else {
        let requestedUser = await this.accountSettingsStub.get(this.tenantId, this.userId);
        this.username.setValue(requestedUser.username, {});
        this.email.setValue(requestedUser.email, {});
        this.isUserEnabled.setValue(requestedUser.isEnabled, {});
      }

      this.form = this.formBuilder.group({
        currentPassword: this.isSelf ? this.currentPassword : undefined,
        email: this.email,
        username: this.username,
        password: this.password,
        isUserEnabled : this.isUserEnabled
      });
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public setChangePassword(value: boolean) {
    this.changePassword = value;
  }

  public async onSubmit() {
    this.saveSuccessful = false;
    let success = await this.formSubmissionHandler.execute(this.submit, this.form, this.submitButton, this);

    if (success) {
      this.saveSuccessful = true;
    }
  }

  private async submit() {
    await this.accountSettingsStub.put(
      this.tenantId,
      this.userId,
      {
        currentPassword: this.isSelf ? this.currentPassword.value : undefined,
        newUsername: this.username.value,
        newEmail: this.email.value,
        newPassword: this.changePassword ? this.password.value : undefined,
        isEnabled: this.isUserEnabled.value
      });

    await this.fetchUserState.update();
  }
}

import {Component, OnInit} from '@angular/core';
import {ConfigType} from '../simulations/configs/config-types';
import {GetFriendlyErrorAndLog} from '../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {StudyTypeLookup} from '../simulations/studies/study-type-lookup.service';
import {ConfigTypeState, DocumentSubType} from '../../generated/api-stubs';
import { AuthenticationService } from '../identity/state/authentication.service';

const hiddenConfigTypes = [DocumentSubType.pacejkaTyres];

@Component({
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss']
})
export class HomePage implements OnInit {

  public errorMessage: string;
  public inputConfigTypes: ConfigType[];

  public readonly ConfigTypeState = ConfigTypeState;

  constructor(
    private studyTypeLookup: StudyTypeLookup,
    private readonly authenticationService: AuthenticationService,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  public get showWorksheets(): boolean {
    return this.authenticationService.showWorksheets;
  }

  public ngOnInit(){
    this.load();
  }

  public async load(){
    try {
      let inputConfigTypes = await this.studyTypeLookup.getConfigTypeList();
      this.inputConfigTypes = inputConfigTypes.filter(v => hiddenConfigTypes.indexOf(v.singularKey) === -1);
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }
}

<div class="alert" [ngClass]="{ 'alert-warning': isDowngradedConfig }">
  <p>
    This config has been {{status}}.
    The original version was {{originalConfigVersionString}} and the converted version is {{currentConfigVersionString}}.
  </p>
  <button type="button" class="btn btn-secondary" (click)="compareToOriginal($event)" title="View changes made by both conversion and editor. Ctrl+click: View changes made by conversion only. Alt+click: View changes made by editor only.">
    Compare Original and Converted
  </button>
  <cs-error-message [message]="errorMessage"></cs-error-message>
</div>

import { ISize } from '../size';
import { IPopulatedMultiPlotSide } from './types/i-populated-multi-plot-side';
import { IPopulatedMultiPlotLayout } from './types/i-populated-multi-plot-layout';


/**
 * Returns the lengths of a set of sides (columns or rows).
 */
export class GetSideSizes {
  /**
   * For a given set of sides (where a side is a row or column) and total length, returns the size of each side
   * in the list.
   * @param side The list of sides.
   * @param totalLength The total length to divide between the sides.
   * @returns The size of each side in the list.
   */
  public execute(side: ReadonlyArray<IPopulatedMultiPlotSide>, totalLength: number): number[] {
    // Add up all the relative sizes of the visible panes.
    let relativeSizeSum = side.reduce((p, c) => p + (c.transient.isHidden ? 0 : c.relativeSize), 0);

    // Get the fraction of the total length for each pane.
    let sizeFractions = side.map(v => v.transient.isHidden ? 0 : (v.relativeSize / relativeSizeSum));

    // Return the size of each pane.
    return sizeFractions.map(v => v * totalLength);
  }

  /**
   * Gets the sizes of the rows.
   * @param layout The layout.
   * @param chartSize The chart dimensions.
   * @returns The sizes of the rows.
   */
  public getRowSizes(layout: IPopulatedMultiPlotLayout, chartSize: ISize): number[] {
    return this.execute(layout.rows, chartSize.height);
  }

  /**
   * Gets the sizes of the columns.
   * @param layout The layout.
   * @param chartSize The chart dimensions.
   * @returns The sizes of the columns.
   */
  public getColumnSizes(layout: IPopulatedMultiPlotLayout, chartSize: ISize): number[] {
    return this.execute(layout.columns, chartSize.width);
  }

  /**
   * Gets the sizes of the processed rows.
   * @param layout The layout.
   * @param chartSize The chart dimensions.
   * @returns The sizes of the processed rows.
   */
  public getProcessedRowSizes(layout: IPopulatedMultiPlotLayout, chartSize: ISize): number[] {
    return this.execute(layout.processed.rows, chartSize.height);
  }

  /**
   * Gets the sizes of the processed columns.
   * @param layout The layout.
   * @param chartSize The chart dimensions.
   * @returns The sizes of the processed columns.
   */
  public getProcessedColumnSizes(layout: IPopulatedMultiPlotLayout, chartSize: ISize): number[] {
    return this.execute(layout.processed.columns, chartSize.width);
  }
}

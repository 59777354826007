@if (isChristmas) {
  <div class="hat">
    <div class="ball"></div>
    <div class="circle"></div>
    <div class="body"></div>
    <div class="base"></div>
  </div>
}
<div class="logo">
  <div class="logo-icon"></div>
  <div class="logo-text">Canopy Simulations</div>
</div>

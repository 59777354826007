@if (highPriority || lowPriority) {
  <p>
    Study is running at {{highPriority ? 'high' : 'low'}} priority.
  </p>
}
@if (this.isSettingPriority) {
  <cs-loader data-testid="loader"></cs-loader>
}
@if (!this.isSettingPriority) {
  <button [disabled]="lowPriority" data-testid="set-low-priority-button" title="Gives lower priority to this job so other jobs complete faster at the expense of this job." class="btn btn-smart btn-secondary" (click)="setLowPriority()">
    <i class="fa fa-chevron-down"></i>Set low priority
  </button>
  <button [disabled]="highPriority" data-testid="set-high-priority-button" title="Gives higher priority to this job so it completes faster at the expense of other jobs." class="btn btn-smart btn-secondary" (click)="setHighPriority()">
    <i class="fa fa-chevron-up"></i>Set high priority
  </button>
}

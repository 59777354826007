import { BootstrapBase } from '../bootstrap-base';
import { HTMLDivSelection } from '../../untyped-selection';
import { sliderBottom, sliderLeft } from '../../viewers/components/slider';
import { SLIDERS_NAME } from '../test-area-names';
import { Disposable } from '../../disposable';

export class BootstrapSliders extends BootstrapBase {

  public get name() {
    return SLIDERS_NAME;
  }

  public async load(testArea: HTMLDivSelection): Promise<Disposable | undefined> {
    await this.createSliderBottom(testArea);
    await this.createSliderLeft(testArea);
    return undefined;
  }

  private async createSliderBottom(testArea: HTMLDivSelection) {

    await this.createTestItem(testArea, 'Slider Bottom', 'slider-tests', async (svg) => {
      let margin = { top: 50, left: 50 };
      let width = 200;

      svg.attr('height', margin.top * 2);

      let value = svg.append('text').attr('class', 'slider-value').attr('transform', 'translate(10, 20)').text('0');

      let g = svg.append<SVGGElement>('g').attr('transform', 'translate(' + margin.left + ', ' + margin.top + ')');
      g.append('line').attr('x1', 0).attr('x2', width)
        .attr('class', 'slider-visible-line');

      let slider = sliderBottom().trackLength(200).on('start drag end', currentEvent => {
        value.text(currentEvent.position);
      });

      g.call(slider);
    });

  }

  private async createSliderLeft(testArea: HTMLDivSelection) {

    await this.createTestItem(testArea, 'Slider Left', 'slider-tests', async (svg) => {
      let margin = { top: 50, left: 50 };
      let width = 200;

      svg.attr('width', margin.left * 2);

      let value = svg.append('text').attr('class', 'slider-value').attr('transform', 'translate(10, 20)').text('0');

      let g = svg.append<SVGGElement>('g').attr('transform', 'translate(' + margin.left + ', ' + margin.top + ')');
      g.append('line').attr('y1', 0).attr('y2', width)
        .attr('class', 'slider-visible-line');

      let slider = sliderLeft().trackLength(200).on('start drag end', currentEvent => {
        value.text(currentEvent.position);
      });

      g.call(slider);
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GetFriendlyErrorAndLog } from '../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import { MembershipStub } from '../../generated/api-stubs';
import { FetchUserState } from '../user-state/fetch-user-state.service';
import { DisplayableError } from '../common/errors/errors';

export const MISSING_TOKEN_MESSAGE: string = 'Missing token.';
export const SUCCESS_MESSAGE: string = 'Thank you. Your email address has been verified.';

@Component({
  selector: 'cs-confirm-email',
  templateUrl: './confirm-email.page.html',
  styleUrls: ['./confirm-email.page.scss']
})
export class ConfirmEmailPage implements OnInit {

  public readonly token: string;
  public readonly expectedTenantShortName: string;
  public readonly expectedUsername: string;

  public errorMessage: string;
  public message: string;

  constructor(
    private readonly fetchUserState: FetchUserState,
    private readonly membershipStub: MembershipStub,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog,
    route: ActivatedRoute) {

    let queryParams = route.snapshot.queryParamMap;
    this.token = queryParams.get('email-verification-token');
    this.expectedTenantShortName = queryParams.get('tenant');
    this.expectedUsername = queryParams.get('username');
  }

  ngOnInit() {
    this.confirmToken();
  }

  public get isLoading() {
    return !this.errorMessage && !this.message;
  }

  public async confirmToken() {
    try {
      if (!this.token) {
        this.errorMessage = MISSING_TOKEN_MESSAGE;
        return;
      }

      await this.tryCheckExpectedUser();

      await this.membershipStub.postEmailConfirmation({
        token: this.token
      });

      this.message = SUCCESS_MESSAGE;
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async tryCheckExpectedUser() {
    if (this.expectedTenantShortName || this.expectedUsername) {
      let currentUserState = await this.fetchUserState.get();
      if (currentUserState) {

        if (this.expectedTenantShortName) {
          let currentTenantShortName = currentUserState.tenant.shortName;
          if (currentTenantShortName !== this.expectedTenantShortName) {
            throw new DisplayableError(
              `Email confirmation was requested for "${this.expectedTenantShortName}", but you are logged to "${currentTenantShortName}".
              Please log into the correct account and try again.`);
          }
        }

        if (this.expectedUsername) {
          let currentUsername = currentUserState.username;
          if (currentUsername !== this.expectedUsername) {
            throw new DisplayableError(
              `Email confirmation was requested for "${this.expectedUsername}", but you are logged as "${currentUsername}".
              Please log into the correct account and try again.`);
          }
        }
      }
    }
  }
}

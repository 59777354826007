import {Injectable} from '@angular/core';
import {LogError} from './log-error.service';

@Injectable()
export class ApplicationInsights {
  constructor(private logError: LogError) {
  }

  public trackPageView(pageName: string, pageUrl: string) {
    try {
      (window as any)['appInsights'].trackPageView(pageName, pageUrl);
    } catch(error){
      this.logError.execute(error);
    }
  }

  public setAuthenticatedUserContext(userId: string, tenantId: string){
    try {
      (window as any)['appInsights'].setAuthenticatedUserContext(userId, tenantId);
    } catch(error){
      this.logError.execute(error);
    }
  }
}

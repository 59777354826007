<div class="container-fluid">

  <a id="back-button" class="btn btn-secondary mb-3"
    [routerLink]="['/configs', configType.pluralKey]">
    <i class="fa fa-caret-left"></i>Back
  </a>

  <select class="form-select mb-3" [(ngModel)]="path" data-test-id="path-select">
    <option value="" disabled selected>Select path</option>
    @for(pathOption of paths; track pathOption){
      <option [value]="pathOption">{{pathOption}}</option>
    }
  </select>

  @if(path !== ""){
    <cs-view-configs
      [tenantId]="tenantId"
      [configType]="configType"
      [filter]="filter"
      [subTreePath]="path"
      (configSelected)="onConfigSelected($event)">
    </cs-view-configs>
  }
</div>

<div #modal class="modal" tabindex="-1" data-test-id="view-component-modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <div class="input-group input-group me-3">
          <input class="form-control" [(ngModel)]="name" data-test-id="name-input" />
          <button class="btn btn-primary" (click)="save()" data-test-id="name-save-button">
            @if(isSaving){
              <cs-loader></cs-loader>
            } @else{
              Save Name
            }
          </button>
        </div>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div #config data-test-id="json-formatter"></div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

import { NavigationStation } from '../../navigation-station/navigation-station';
import { JobConfigBuilder } from '../../navigation-station/config-builders/job-config-builder';
import { BootstrapNavigationStationBase } from '../bootstrap-navigation-station-base';
import { SINGLE_JOB_MULTI_LAP_OVERLAY_SPLIT_LAPS_NAME } from '../test-area-names';
import { CanopySiteHooks } from '../../../simulations/visualizations/canopy-site-hooks.service';
import { CanopyFileLoader } from '../../../simulations/visualizations/canopy-file-loader.service';
import { UserData } from '../../../identity/state/authentication.service';

export class BootstrapSingleJobMultiLapOverlaySplitLaps
 extends BootstrapNavigationStationBase {
  public get name() {
    return SINGLE_JOB_MULTI_LAP_OVERLAY_SPLIT_LAPS_NAME;
  }

  public get description() {
    return 'A job viewer overlaying a single job with multiple laps.';
  }

  public createNavigationStation(elementId: string, userData: UserData, fileLoader: CanopyFileLoader, siteHooks: CanopySiteHooks): NavigationStation {

    this.addGeneratedStudy('dynamic-multi-lap', 'DynamicMultiLap', userData, fileLoader);

    return new NavigationStation(
      elementId,
      new JobConfigBuilder(
        fileLoader,
        siteHooks,
        [
          { studyId: 'dynamic-multi-lap', jobIndex: 0 },
        ],
        ['DynamicMultiLap']));
  }
}

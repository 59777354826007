@if (session) {
  <div class="modal dialog-container dialog-content-width" id="support-session-dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title">Canopy Support</h1>
          <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()">
          </button>
        </div>
        <div class="modal-body">
          <cs-error-message [message]="session.errorMessage"></cs-error-message>
          @if (!session.isLoaded) {
            <cs-loader></cs-loader>
          }
          @if (session.isLoaded) {
            <div>
              @if (session.showZendesk) {
                <cs-zendesk-information [session]="session"></cs-zendesk-information>
              }
              @if (!session.showZendesk) {
                @if (!session.data.session) {
                  <div>
                    <div class="alert alert-info">
                      <p>
                        By opening a support session you are granting Canopy Simulations
                        permission to access this study, config or worksheet and any data it contains.
                      </p>
                      <p>
                        You may revoke this permission at any time by closing the support session.
                      </p>
                    </div>
                  </div>
                }
                @if (session.data.session) {
                  <div class="session-status-container">
                    @if (session.data.session.isOpen) {
                      <div class="alert alert-warning">Support Session Open</div>
                    }
                    @if (!session.data.session.isOpen) {
                      <div class="alert alert-success">Support Session Closed</div>
                    }
                  </div>
                }
                <form (ngSubmit)="session.onSubmitAndOpen()" [formGroup]="session.form">
                  <div class="mb-3">
                    <label class="form-label" for="support-message-input">{{session.data.session ? 'Message' : 'Issue Description' }}</label>
                    <textarea id="support-message-input" class="form-control support-message-input" formControlName="message"></textarea>
                    @if (!session.message.pristine && session.message.hasError('required')) {
                      <p class="text-danger">
                        A message is required to help us understand your issue.
                      </p>
                    }
                  </div>
                  @if (session.warnings && session.warnings.length && (!session.data.session || !session.data.session.responses.length)) {
                    <div class="alert alert-warning">
                      <h2>Warnings (from first job)</h2>
                      @for (warning of session.warnings; track warning) {
                        <p>{{warning}}</p>
                      }
                    </div>
                    <div class="mb-3 last-item">
                      <input id="are-warnings-unrelated-input" formControlName="areWarningsUnrelated" type="checkbox">
                      <label class="form-label" for="are-warnings-unrelated-input">I confirm that addressing these warnings does not resolve my issue.</label>
                    </div>
                    @if (session.hasFormSubmissionBeenAttempted && session.areWarningsUnrelated.hasError('required')) {
                      <p class="text-danger">
                        Please confirm these warnings are unrelated to your issue.
                      </p>
                    }
                  }
                  <p>
                    <button id="support-session-submit-button" type="submit" class="btn btn-secondary"><strong>{{session.submitButton.text}}</strong></button>
                    @if (session.data?.session?.isOpen) {
                      <button id="support-session-close-button" type="button" class="btn btn-secondary"
                      (click)="session.onSubmitAndClose()">{{session.closeSupportSessionButton.text}}</button>
                    }
                  </p>
                </form>
                @if (session.data.session) {
                  <div class="session-container">
                    @for (response of session.data.session.responses; track response) {
                      <div>
                        <div class="response-title">
                          <span class="username">
                            <a href="mailto:{{session.map.users[response.userId].email}}">{{session.map.users[response.userId].username}}</a>
                          </span>
                          <span class="tenant-name">
                            {{session.map.tenants[response.tenantId].shortName}}
                          </span>
                          <span class="response-date">
                            {{response.creationDate | fromNow}}
                          </span>
                        </div>
                        <pre class="response-message">{{response.message}}</pre>
                      </div>
                    }
                  </div>
                }
              }
            </div>
          }
        </div>
        <!--div class="modal-footer">
        <button id="dialog-dismiss-button" type="button" class="btn btn-secondary" (click)="dismiss()">Cancel</button>
      </div-->
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div>
}<!-- /.modal -->

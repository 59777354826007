import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

export function minLengthValidator(length: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const errors = Validators.minLength(length)(control);
    return errors ? {
      minLength: {
        value: control.value,
        message: 'is too short'
      }
    } : null;
  };
}

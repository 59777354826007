import { Injectable } from '@angular/core';
import { CommandContext, CommandResult, ICommandContext, WorksheetCommand } from './worksheet-command';
import { ButtonMenuItem, MenuItem, SubMenuItem } from '../../context-menu/context-menu-types';
import { ConfigViewModel } from '../config-view-model';
import { ExportConfigService } from '../../simulations/configs/export-config.service';
import { ConfigReferenceLoaderFactory } from '../../simulations/configs/comparing/config-loaders/config-reference-loader';
import { ConfigOrConfigLoader } from '../../simulations/configs/comparing/config-or-config-loader';

/**
 * A command that downloads the selected config.
 */
@Injectable()
export class DownloadConfigCommandService extends WorksheetCommand {

  /**
   * Creates an instance of DownloadConfigCommandService.
   * @param exportConfig The service for exporting configs.
   * @param loadConfigFromReference The factory for loading a config from a reference.
   */
  constructor(
    private readonly exportConfig: ExportConfigService,
    private readonly loadConfigFromReference: ConfigReferenceLoaderFactory){
    super();
  }

  /**
   * Determines if the command can be executed.
   * @param context The command context.
   * @returns True if the command can be executed, otherwise false.
   */
  public canExecute(context: CommandContext): context is ICommandContext<ConfigViewModel> {
    return context.isConfig()
      && context.isSingleRow
      && context.target.isPopulated;
  }

  /**
   * Creates the menu items for the command.
   * @param context The command context.
   * @param result The menu items.
   */
  public createMenuItems(context: CommandContext, result: MenuItem<CommandResult>[]): void {
    if(!this.canExecute(context)) {
      return;
    }

    // Get the config loader.
    let configLoader = this.loadConfigFromReference.create(context.target.configType, context.target.reference, undefined);

    // Create the various download type menu items (JSON, CSV, Re-encrypted, etc.).
    let downloadTypes: MenuItem<CommandResult>[] = [];
    for (let downloadType of this.exportConfig.downloadConfigTypes(() => new ConfigOrConfigLoader('', undefined, configLoader))){
      downloadTypes.push(new ButtonMenuItem<CommandResult>(
        downloadType.displayName,
        '',
        async () => {
          await downloadType.execute();
          return CommandResult.NoUpdate;
        },
        'download'));
    }

    // Put them in a sub-menu.
    result.push(new SubMenuItem<CommandResult>(
      'Download',
      '',
      downloadTypes,
      true));
  }
}

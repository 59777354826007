@for (column of row.columns; track columnTrackById($index, column)) {
  @switch (column.definition.columnType) {
    @case (ColumnType.rowMetadata) {
      <td tabindex="0" class="row-metadata-cell" cs-worksheet-row-metadata-item
        [viewModel]="column.value"
        [isWorksheetInDock]="isWorksheetInDock"
        [rowIndex]="rowIndex">
      </td>
    }
    @case (ColumnType.config) {
      <td tabindex="0" class="config-cell {{column.definition.id}}-config-cell" cs-worksheet-config-item
        [viewModel]="column.value"
        [isWorksheetInDock]="isWorksheetInDock">
      </td>
    }
    @case (ColumnType.study) {
      <td tabindex="0" class="study-cell" cs-worksheet-study-item
        [viewModel]="column.value"
        [isWorksheetInDock]="isWorksheetInDock">
      </td>
    }
    @case (ColumnType.simulation) {
      <td tabindex="-1" class="simulation-cell {{column.definition.id}}-simulation-cell" cs-worksheet-simulation-item
        [viewModel]="column.value"
        [isWorksheetInDock]="isWorksheetInDock">
      </td>
    }
  }
}

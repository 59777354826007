
/**
 * A simple undo history tracker that keeps track of the history of a given type.
 * @param T The type of the history items
 */
export class UndoHistoryTracker<T> {

  /**
   * The history of items.
   */
  private readonly history: T[] = [];

  /**
   * The current position in the history (for when we are undoing with the potential of re-doing).
   */
  private position: number = 0;

  /**
   * Creates an instance of UndoHistoryTracker.
   * @param historySize The maximum size of the history.
   */
  constructor(public readonly historySize: number) {
  }

  /**
   * Gets whether there are items to undo.
   */
  public get canUndo(): boolean {
    return this.history.length > 0 && this.position > 0;
  }

  /**
   * Gets whether there are items to redo.
   */
  public get canRedo(): boolean {
    return this.history.length > 0 && this.position < this.history.length - 1;
  }

  /**
   * Gets the current size of the undo buffer.
   */
  public get undoBufferSize(): number {
    return this.position;
  }

  /**
   * Gets the current size of the redo buffer.
   */
  public get redoBufferSize(): number {
    return this.history.length - this.position - 1;
  }

  /**
   * Adds an item to the history.
   * @param item The item to add.
   */
  public add(item: T) {

    // If we are in the middle of the history, we need to remove the redo buffer.
    // When you undo a bunch of times you have the opportunity to redo, until you perform
    // an action which creates a new item in the history. At that point, the redo buffer
    // is cleared.
    if(this.canRedo){
      this.history.splice(this.position + 1);
    }

    // Push the new history item.
    this.history.push(item);

    // Move our position to the new end of the list.
    this.position = this.history.length - 1;

    // If there are too many items in the history, remove the oldest item.
    if(this.history.length > this.historySize){
      this.history.splice(0, 1);
      --this.position;
    }
  }

  /**
   * Performs an undo operation by moving the position back one and returning the item at that position.
   * @returns The item at the new position.
   */
  public undo(): T | undefined {
    if(!this.canUndo){
      return undefined;
    }

    return this.history[--this.position];
  }

  /**
   * Performs a redo operation by moving the position forward one and returning the item at that position.
   * @returns The item at the new position.
   */
  public redo(): T | undefined {
    if(!this.canRedo){
      return undefined;
    }

    return this.history[++this.position];
  }
}

import { Inject, Injectable } from '@angular/core';
import { JsonEditorCustomization } from './json-editor-customization';


@Injectable()
export class ApplyCustomizations {
  constructor(
    @Inject(JsonEditorCustomization) private readonly customizations: JsonEditorCustomization[]) {
  }

  public execute() {
    for (let customization of this.customizations) {
      customization.apply();
    }
  }
}

@if (!state?.isInitialized) {
  <cs-error-message [message]="errorMessage"></cs-error-message>
}
<div id="study-staging-area" [ngClass]="{'area-visible': state?.isInitialized}">
  @if (state?.isInitialized && isLoaded) {
    <div class="study-container-top-line">
      <form (ngSubmit)="onSubmit()" [formGroup]="form">
        <div class="container-fluid study-container-top">
          <div class="row header-row">
            <div class="col-12">
              <h1><a [routerLink]="['/studies']">Study</a></h1>
              <select id="study-type-input" name="select" formControlName="studyType" class="form-select study-type-select">
                <!--
                We need to use [ngValue] here not [value] or we get issues when the token expires and the list of
                study types is replaced with an entirely new list with different object identities, which causes
                the select to lose its selected value.
                -->
              @for (t of enabledStudyTypes; track t) {
                <option [ngValue]="t.studyType">{{t.name}}</option>
              }
              </select>
              <div class="study-name-area">
                <input placeholder="Study Name" id="study-name-input" type="text" class="form-control" formControlName="name">
              </div>
              <div class="button-area">
                <button id="commit-study-submit" type="submit" [disabled]="submitButton.isSubmitting" class="btn btn-smart btn-secondary">
                  <i class="fa fa-cloud-upload"></i>{{submitButton.text}}
                </button>
                <button id="clear-study-staging-area-button" type="button" class="btn btn-smart btn-secondary" (click)="clear()">
                  <i class="fa fa-close"></i>Clear
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="study-container-bottom">
          <div class="study-inputs">
            @for (input of studyInputs; track input) {
              <div class="study-input button-menu-hover-target" [ngClass]="{'optional-study-input': !input.isRequired, 'hidden-study-input': !input.isEnabled, 'updated': input.isUpdated}">
                <a [routerLink]="['/configs', input.configType.pluralKey]" class="list-link"
                  id="staged-view-all-{{input.configType.singularKey}}-link">
                  <div class="study-input-left">
                    <i class="fa {{input.configType.icon}} config-type-icon"></i>
                    {{input.configType.titleName}}
                  </div></a><div [ngClass]="{ 'study-input-right': input.isRequired || !input.state, 'study-input-center': !(input.isRequired || !input.state) }" class="study-input-content">
                  @if (input.state) {
                    <a id="staged-{{input.configType.singularKey}}-link"
                      class="staged-input-link"
                      [ngClass]="{ updated: input.isUpdated }"
                    [routerLink]="['/staged', input.configType.singularKey, 'edit']">{{input.state.name}}</a>
                  }
                  @if (input.state?.isEdited) {
                    <i id="edited-{{input.configType.singularKey}}-icon" class="fa fa-pencil edited-icon"></i>
                  }
                  @if (!input.state) {
                    <i>none</i>
                  }
                </div>@if (!(input.isRequired || !input.state)) {
                <div class="study-input-right study-input-close" (click)="removeInput(input)">
                  <i class="fa fa-close"></i>
                </div>
              }
              @if (input.state) {
                <div class="button-menu-hovering-area full-width-buttons">
                  <a [routerLink]="['/staged', input.configType.singularKey, 'edit']"
                    id="staged-{{input.configType.singularKey}}-edit-link"
                  class="btn btn-secondary btn-smart">Edit Snapshot</a>
                  @if (canViewReferencedStudyJob(input)) {
                    <button type="button" class="btn btn-secondary btn-smart"
                      id="staged-{{input.configType.singularKey}}-view-referenced-study-job-link"
                      (click)="viewReferencedStudyJob(input)">
                      View Referenced Study Job
                    </button>
                  }
                  <button type="button" class="btn btn-secondary btn-smart"
                    id="staged-{{input.configType.singularKey}}-edit-source-link"
                    (click)="editSource(input)" [disabled]="!canEditSource(input)">
                    Edit Source Config
                  </button>
                  <button type="button" class="btn btn-secondary btn-smart"
                    id="staged-{{input.configType.singularKey}}-compare-to-source-link"
                    (click)="compareToSource(input)" [disabled]="!canCompareToSource(input)">
                    Compare to Source Config
                  </button>
                  <cs-compare-to-something-button class="add-margin"
                    [configType]="input.configType.singularKey"
                    [getConfigOrConfigLoader]="getConfigOrConfigLoader(input)"
                    [saveOutputConfigHandler]="saveToStagingAreaHandler">
                  </cs-compare-to-something-button>
                </div>
              }
            </div>
          }
        </div>
        <div class="container-fluid study-container-bottom-messages">
          <cs-error-message [message]="errorMessage" class="error-message-component"></cs-error-message>
          @if (showErrors) {
            <div class="errors-area">
              @if (name.hasError('required')) {
                <p class="text-danger">
                  Study name is required.
                </p>
              }
              @if (name.hasError('minlength')) {
                <p class="text-danger">
                  Study name is too short.
                </p>
              }
              @if (name.hasError('maxlength')) {
                <p class="text-danger">
                  Study name is too long.
                </p>
              }
            </div>
          }
          <div class="alert alert-info committed-study" [ngClass]="{ 'show-committed-study': !!committedStudy }">
            @if (committedStudy) {
              <div id="study-committed-area">
                <div class="item">
                  <strong>{{committedStudy?.name}}</strong> committed
                </div>
                <div class="item">
                  <a id="study-committed-view-study" [routerLink]="['/studies', committedStudy?.tenantId, committedStudy?.studyId]">
                    View Study
                  </a>
                </div>
                <div class="item">
                  <a id="study-committed-view-studies" [routerLink]="['/studies']">
                    View All Studies
                  </a>
                </div>
                <div id="study-committed-dismiss" class="item">
                  <a href="javascript:void(0)" (click)="dismissCommittedStudy()">
                    Dismiss
                  </a>
                </div>
                @for (worksheet of worksheets; track worksheet; let i = $index) {
                  <div id="study-committed-add-to-worksheet-{{i}}" class="worksheet-item">
                    <a href="javascript:void(0)" (click)="addToWorksheet(worksheet)">
                      <i class="fa fa-th"></i> Add to {{worksheet.name}}
                    </a>
                  </div>
                }
              </div>
            }
          </div>
        </div>
      </div>
    </form>
  </div>
}
</div>


import {
  ConfigResolvedLabels,
  ConfigResolvedReference,
  DocumentSubType,
  GetWorksheetQueryResult,
  SimType,
  StudyResolvedLabels,
  StudyResolvedReference,
  StudyType, StudyTypeDefinition
} from '../../generated/api-stubs';
import {SimTypeMap, StudyTypeLookup, StudyTypeMap} from '../simulations/studies/study-type-lookup.service';
import {ConfigTypeLookup} from '../simulations/configs/config-types';
import {ResolvedByReference} from './resolved-by-reference';
import {TenantsByTenantId, UsersByUserId} from './worksheet-types';
import {DisplayableError} from '../common/errors/errors';
import {Injectable} from '@angular/core';
import {WorksheetReferencesMetadata} from './worksheet-references-metadata';
import {getFilteredStudyTypes} from '../simulations/study-staging-area/study-staging-area.component';
import {GetSimVersion} from '../common/get-sim-version.service';

/**
 * Factory for creating the worksheet underlying data.
 * Examples of the underlying data for a worksheet are resolved references and labels.
 */
@Injectable()
export class WorksheetUnderlyingDataFactory {

  /**
   * Creates an instance of WorksheetUnderlyingDataFactory.
   * @param studyTypeLookup The study type lookup service.
   * @param getSimVersion The service to get the current simulation version for the user.
   */
  constructor(
    private readonly studyTypeLookup: StudyTypeLookup,
    private readonly getSimVersion: GetSimVersion){
  }

  /**
   * Creates the underlying data service for a worksheet.
   * @param worksheetResult The result of a get worksheet query.
   */
  public async create(worksheetResult: GetWorksheetQueryResult): Promise<WorksheetUnderlyingData>{
    let simVersion = this.getSimVersion.currentSimVersion;
    const studyTypeList = getFilteredStudyTypes(await this.studyTypeLookup.getStudyTypeList(simVersion));
    const studyTypeMap = await this.studyTypeLookup.getStudyTypeMap(simVersion);
    const simTypeMap = await this.studyTypeLookup.getSimTypeMap();

    if(studyTypeList.length === 0){
      throw new DisplayableError('No study types found.');
    }

    return new WorksheetUnderlyingData(simVersion, worksheetResult, studyTypeList, studyTypeMap, simTypeMap);
  }
}

/**
 * A service to get the underlying data for a worksheet, for example resolved references and labels.
 */
export class WorksheetUnderlyingData {

  /**
   * The tenants in the worksheet.
   */
  public readonly tenants: TenantsByTenantId;

  /**
   * The users in the worksheet.
   */
  public readonly users: UsersByUserId;

  /**
   * The cache of resolved references for configs.
   */
  public readonly configResolvedReferences: ResolvedByReference<ConfigResolvedReference>;

  /**
   * The cache of resolved references for studies.
   */
  public readonly studyResolvedReferences: ResolvedByReference<StudyResolvedReference>;

  /**
   * The cache of resolved labels for configs.
   */
  public readonly configResolvedLabels: ResolvedByReference<ConfigResolvedLabels>;

  /**
   * The cache of resolved labels for studies.
   */
  public readonly studyResolvedLabels: ResolvedByReference<StudyResolvedLabels>;

  /**
   * The transient metadata for the references in a worksheet, for example the reference count.
   */
  public readonly referencesMetadata: WorksheetReferencesMetadata = new WorksheetReferencesMetadata();

  /**
   * Creates an instance of WorksheetUnderlyingData.
   * @param simVersion The user's current simulation version.
   * @param worksheetResult The result of the get worksheet query.
   * @param studyTypesList The list of study types.
   * @param studyTypes The map of study types.
   * @param simTypes The map of sim types.
   */
  constructor(
    public readonly simVersion: string,
    public readonly worksheetResult: GetWorksheetQueryResult,
    public readonly studyTypesList: ReadonlyArray<StudyTypeDefinition>,
    public readonly studyTypes: StudyTypeMap,
    public readonly simTypes: SimTypeMap) {

    this.tenants = worksheetResult.userInformation.tenants
      .reduce((p, c) => {
        p[c.tenantId] = c;
        return p;
      }, {} as TenantsByTenantId);

    this.users = worksheetResult.userInformation.tenants
      .map(v => v.users)
      .reduce((p, c) => p.concat(c), [])
      .reduce((p, c) => {
        p[c.userId] = c;
        return p;
      }, {} as UsersByUserId);

    this.configResolvedReferences = worksheetResult.worksheet.resolvedReferences.configs
      .reduce((p, c) => {
        p.add(c);
        return p;
      }, new ResolvedByReference<ConfigResolvedReference>(true));

    this.studyResolvedReferences = worksheetResult.worksheet.resolvedReferences.studies
      .reduce((p, c) => {
        p.add(c);
        return p;
      }, new ResolvedByReference<StudyResolvedReference>());

    this.configResolvedLabels = worksheetResult.worksheet.resolvedLabels.configs
      .reduce((p, c) => {
        p.add(c);
        return p;
      }, new ResolvedByReference<ConfigResolvedLabels>(true));

    this.studyResolvedLabels = worksheetResult.worksheet.resolvedLabels.studies
      .reduce((p, c) => {
        p.add(c);
        return p;
      }, new ResolvedByReference<StudyResolvedLabels>());
  }

  /**
   * Get's the user facing name of a config type.
   * @param configType The config type.
   * @returns The user facing name of the config type.
   */
  public getConfigTypeName(configType: DocumentSubType): string {
    const resolved = ConfigTypeLookup.get(configType);
    if (resolved) {
      return resolved.titleName;
    }
    return configType;
  }

  /**
   * Get's the plural version of a config type.
   * @param configType The config type.
   * @returns The plural version of the config type.
   */
  public getConfigTypePluralKey(configType: DocumentSubType): string {
    const resolved = ConfigTypeLookup.get(configType);
    if (resolved) {
      return resolved.pluralKey;
    }
    return configType;
  }

  /**
   * Get's the user facing name of a study type.
   * @param studyType The study type.
   * @returns The user facing name of the study type.
   */
  public getStudyTypeName(studyType: StudyType): string {
    const resolved = this.studyTypes[studyType];
    if (resolved) {
      return resolved.name;
    }
    return studyType;
  }

  /**
   * Get's the user facing name of a study type.
   * @param studyType The study type.
   * @returns The user facing name of the study type.
   */
  public getSimTypeName(simType: SimType): string {
    const resolved = this.simTypes[simType];
    if (resolved) {
      return resolved.name;
    }
    return simType;
  }
}

import { SourceData } from './types/source-data';
import { IMultiPlotSide } from './types/i-multi-plot-side';
import { SideType } from './types/side-type';
import { ProcessedMultiPlotChannel } from './types/processed-multi-plot-channel';
import { ChannelIndexMap } from './channel-index-map';
import { GetProcessedChannel } from './get-processed-channel';
import { GetProcessedChannels } from './get-processed-channels';
import { GetProcessedSide } from './get-processed-side';
import { GetXDomainNamesFromColumnSides } from './get-x-domain-names-from-column-sides';


/**
 * Processes the side (column or row) of a multi-plot layout.
 */
export class ProcessLayoutSide {
  constructor(
    private readonly getXDomainNamesFromColumnSides: GetXDomainNamesFromColumnSides,
    private readonly getProcessedChannels: GetProcessedChannels,
    private readonly getProcessedChannel: GetProcessedChannel,
    private readonly getProcessedSide: GetProcessedSide) {
  }

  /**
   * Processes the side (column or row) of a multi-plot layout.
   * @param side The side to process.
   * @param sourceData The set of data sources.
   * @param channelIndexMap The channel index map.
   * @param sideType The type of side (column or row).
   * @param columnSides The set of column sides.
   */
  public execute(side: IMultiPlotSide, sourceData: ReadonlyArray<SourceData>, channelIndexMap: ChannelIndexMap, sideType: SideType, columnSides: ReadonlyArray<IMultiPlotSide>) {
    let channels: ProcessedMultiPlotChannel[];

    if (sideType === SideType.row) {
      channels = [];

      // Row channels can have modifiers, and channels with modifiers can be associated with specific X domains.
      // Therefore we need to get the list of X domain names.
      const xDomainNames = this.getXDomainNamesFromColumnSides.execute(columnSides);

      // For each row channel...
      for (let channel of side.channels) {

        // Get the processed channel (validated, with data, and auto-scaled if necessary).
        let processedChannels = this.getProcessedChannels.execute(channel, sourceData, channelIndexMap, xDomainNames);

        // For each processed row channel...
        for (let processedChannel of processedChannels) {
          let isRendered = false;
          if (processedChannel.renderOnlyForDomain) {
            // The row channel is rendered only for specific X domains.
            for (let columnSide of columnSides) {
              if (!columnSide.processed) {
                continue;
              }
              for (let columnChannel of columnSide.processed.channels) {
                if (columnChannel.name === processedChannel.renderOnlyForDomain && columnChannel.isVisible) {
                  // If the X domain is currently visible, set the row channel as rendered.
                  isRendered = true;
                }
              }
            }
          } else {
            // The channel is rendered for all domains.
            isRendered = true;
          }

          if (isRendered) {
            // Add the processed row channel to the list of channels if it is to be rendered.
            channels.push(processedChannel);
          }
        }
      }
    } else {
      // For column channels, we simply get the processed version of each channel.
      channels = side.channels.map(
        channel => this.getProcessedChannel.execute(channel, sourceData, channelIndexMap));
    }

    // Get the processed side (passing in the processed channels).
    side.processed = this.getProcessedSide.execute(channels, sourceData, sideType);
  }
}


/**
 * Represents a transformation of channel data from one domain to another.
 */
export class ChannelDataTransform {

  /**
   * Creates an instance of a channel data transform.
   * @param sourceDomains The source domains which should be transformed.
   * @param targetDomain The target domain to transform to.
   */
  constructor(
    public readonly sourceDomains: ReadonlyArray<string>,
    public readonly targetDomain: string) {
  }
}

/**
 * Represents a set of channel data transforms.
 */
export class ChannelDataTransforms {
  public static readonly none: ChannelDataTransforms = new ChannelDataTransforms([]);

  /**
   * Creates an instance of channel data transforms.
   * @param transforms The transforms to apply.
   */
  constructor(
    public readonly transforms: ReadonlyArray<ChannelDataTransform>) {
  }

  /**
   * Gets the transformed name of a channel.
   * @param channelName The channel name to transform.
   * @returns The transformed channel name.
   */
  public getTransformedName(channelName: string): string {
    for (let transform of this.transforms) {
      if (transform.sourceDomains.indexOf(channelName) !== -1) {
        return transform.targetDomain;
      }
    }

    return channelName;
  }
}

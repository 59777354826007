import {
  StudyStub,
  GetStudyQueryResult, GetStudyJobMetadataQueryResult,
  PostStudyInlineResult, NewStudyData, JobDocument, StudyState, StudyJobState, GetStudyJobQueryResult
} from '../../../generated/api-stubs';
import {CanopyPusher} from '../../common/canopy-pusher.service';
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {JobViewModelFactory} from '../jobs/job-results/job-view-model';
import {CustomProperty} from '../custom-properties/custom-properties';
import {CarConfigValidationSessionBase, CheckStatus} from './car-config-validation-session-base';
import {StudyUtilities} from '../studies/study-utilities.service';
import { AuthenticationService } from '../../identity/state/authentication.service';

export const WAITING_FOR_RESULT_MESSAGE = 'Waiting for result...';
export const VERIFY_CAR_STUDY_NAME = 'Verify Car';

export class CarConfigValidationInlineSession extends CarConfigValidationSessionBase{
  public isStudyCompleted: boolean = false;

  public studyMetadataResult: GetStudyQueryResult;
  public studyJobMetadataResult: GetStudyJobMetadataQueryResult;

  public isStudySuccessful: boolean = false;

  constructor(
    editorValue: any,
    simVersion: string,
    userId: string,
    configId: string,
    name: string,
    isEdited: boolean,
    customProperties: ReadonlyArray<CustomProperty>,
    private readonly studyUtilities: StudyUtilities,
    authenticationService: AuthenticationService,
    studyStub: StudyStub,
    canopyPusher: CanopyPusher,
    jobViewModelFactory: JobViewModelFactory,
    getFriendlyErrorAndLog: GetFriendlyErrorAndLog
  ){
    super(
      editorValue,
      simVersion,
      userId,
      configId,
      name,
      isEdited,
      customProperties,
      authenticationService,
      studyStub,
      canopyPusher,
      jobViewModelFactory,
      getFriendlyErrorAndLog);
  }

  public dispose(){
  }

  public async executeStudy(newStudyData: NewStudyData): Promise<void>{
    this.statusMessage = WAITING_FOR_RESULT_MESSAGE;


    let inlineResult = <PostStudyInlineResult>await this.studyStub.postStudy(
      this.userData.tenant,
      newStudyData,
      true);

    this.statusMessage = 'Loading result...';

    this.isStudyCompleted = true;
    this.isStudySuccessful = !(inlineResult.errors && inlineResult.errors.length);

    let study = inlineResult.study;
    study.data.definition = newStudyData.study;

    let studyMetadataResult: GetStudyQueryResult = {
      study,
      accessInformation: null,
      userInformation: null,
      simTypes: newStudyData.study.simTypes,
      convertedSimVersion: newStudyData.simVersion,
      priority: null
    };

    let jobDocument: JobDocument = {
      index: 0,
      state: study.data.studyState === StudyState.failed ? StudyJobState.failed : StudyJobState.successful,
      computeCredits: study.data.dispatchedComputeCredits,
      storageCredits: study.data.dispatchedStorageCredits,
      errorMessages: inlineResult.errors,
      executionTimeSeconds: study.data.executionTimeSeconds,
      studyId: study.documentId,
      changes: []
    };

    let job = {
      ...study,
      documentId: study.documentId + '-0',
      data: jobDocument
    };

    let studyJobMetadataResult: GetStudyJobMetadataQueryResult = {
      studyJob: job,
      accessInformation: null
    };

    let studyJobResult: GetStudyJobQueryResult = {
      studyJob: job,
      studyJobInput: newStudyData.study,
      simTypes: newStudyData.study.simTypes,
      accessInformation: null
    };

    this.studyMetadataResult = studyMetadataResult;
    this.studyJobMetadataResult = studyJobMetadataResult;

    this.jobViewModel = this.jobViewModelFactory.createFromData(
      this.studyJobMetadataResult,
      this.studyMetadataResult,
      'Current',
      VERIFY_CAR_STUDY_NAME,
      inlineResult);

    this.jobViewModel.scalarResults.value = await this.studyUtilities.getScalarResultSetsFromInline(inlineResult.scalarResults);
    this.jobViewModel.info.value = inlineResult.info;
    this.jobViewModel.warnings.value = inlineResult.warnings;
    this.jobViewModel.diagnosis.value = inlineResult.diagnosis;
    this.jobViewModel.jobResult.value = studyJobResult;

    this.isStudySuccessful = !!this.studyMetadataResult.study.data.succeededJobCount;
    this.status = CheckStatus.checked;
    this.statusMessage = '';
  }
}

<li class="dropdown">
  <button
  class="btn btn-link nav-link py-2 px-0 px-lg-2 dropdown-toggle d-flex align-items-center"
  type="button"
  data-bs-toggle="dropdown">
    <i class="fa {{themeIcon(getStoredTheme())}}"></i>
  </button>
  <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="bd-theme-text">
    <li>
      <button
      type="button"
      class="dropdown-item d-flex align-items-center {{getStoredTheme() === ColourTheme.light ? 'active' : ''}}"
      (click)="setTheme(ColourTheme.light)">
        <i class="fa {{themeIcon(ColourTheme.light)}} me-2"></i>
        Light
      </button>
    </li>
    <li>
      <button
      type="button"
      class="dropdown-item d-flex align-items-center {{getStoredTheme() === ColourTheme.dark ? 'active' : ''}}"
      (click)="setTheme(ColourTheme.dark)">
        <i class="fa {{themeIcon(ColourTheme.dark)}} me-2"></i>
        Dark
      </button>
    </li>
    <li>
      <button
      type="button"
      class="dropdown-item d-flex align-items-center {{getStoredTheme() ? '' : 'active'}}"
      (click)="setTheme(null)">
        <i class="fa {{themeIcon(null)}} me-2"></i>
        Auto
      </button>
    </li>
  </ul>
</li>

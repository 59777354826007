import { FilteredStudyExplorationAndScalarDataCache } from './filtered-study-exploration-and-scalar-data-cache';
import { DisplayableError } from '../../displayable-error';
import { DimensionEvaluationData, GetSingleDimensionEvaluationData } from './get-single-dimension-evaluation-data';

/**
 * A cache for single dimension exploration evaluation data.
 */
export class SingleDimensionEvaluationDataCache {
  private cache: { [dimensionIndex: number]: Promise<DimensionEvaluationData | undefined> } = {};

  /**
   * Creates a new cache for single dimension exploration evaluation data.
   * @param explorationAndScalarDataCache The cache for exploration and scalar data.
   * @param getSingleDimensionEvaluationData The service for getting single dimension evaluation data.
   */
  constructor(
    private readonly explorationAndScalarDataCache: FilteredStudyExplorationAndScalarDataCache,
    private readonly getSingleDimensionEvaluationData: GetSingleDimensionEvaluationData) {

      // If the data in the exploration and scalar data cache changes, clear the cache.
      // This can happen when the user is removing jobs from the exploration manually.
      explorationAndScalarDataCache.changed.subscribe(async () => Object.keys(this.cache).forEach(k => this.cache[Number(k)] = this.loadStudyData(Number(k))));
  }

  /**
   * Gets the single dimension evaluation data for the specified dimension index.
   * @param dimensionIndex The dimension index.
   * @returns The single dimension evaluation data, or undefined if the exploration
   * and scalar data cache is not initialized.
   */
  public get(dimensionIndex: number): Promise<DimensionEvaluationData | undefined> {
    if (!this.cache[dimensionIndex]) {
      this.cache[dimensionIndex] = this.loadStudyData(dimensionIndex);
    }

    return this.cache[dimensionIndex];
  }

  /**
   * Gets the single dimension evaluation data for the specified dimension index.
   * Throws if the data is not found.
   * @param dimensionIndex The dimension index.
   * @returns The single dimension evaluation data.
   */
  public async getDefined(dimensionIndex: number): Promise<DimensionEvaluationData> {
    const result = await this.get(dimensionIndex);
    if (!result) {
      throw new DisplayableError('No single dimension evaluation data found.');
    }
    return result;
  }

  /**
   * Loads the study data for the specified dimension index.
   * @param dimensionIndex The dimension index.
   * @returns The single dimension evaluation data, or undefined if the exploration
   * and scalar data cache is not initialized.
   */
  private async loadStudyData(dimensionIndex: number): Promise<DimensionEvaluationData | undefined> {
    const explorationAndScalarData = await this.explorationAndScalarDataCache.get();
    if (!explorationAndScalarData) {
      return undefined;
    }

    const map = explorationAndScalarData.explorationMap;

    return this.getSingleDimensionEvaluationData.execute(map, dimensionIndex);
  }
}

import {Injectable} from '@angular/core';
import {DialogManager, DialogBase, IDialog} from './dialog-manager.service';

@Injectable()
export class ConfirmationDialog {
  constructor(private dialogManager: DialogManager) {
  }

  public async show(
    message: string,
    title?: string,
    acceptMessage?: string | ReadonlyArray<string>,
    dismissMessage?: string,
    warnings?: ReadonlyArray<string>,
    errors?: ReadonlyArray<string>): Promise<string> {
    return <string>await this.dialogManager.showTertiaryDialog(
      new ConfirmationDialogData(
        message,
        title,
        acceptMessage
          ? (typeof acceptMessage === 'string' ? [acceptMessage] : acceptMessage)
          : undefined,
        dismissMessage,
        warnings,
        errors));
  }
}

export class ConfirmationDialogData extends DialogBase<string> implements IDialog {
  constructor(
    public readonly message: string,
    public readonly title: string,
    public readonly acceptMessages: ReadonlyArray<string>,
    public readonly dismissMessage: string,
    public readonly warnings: ReadonlyArray<string>,
    public readonly errors: ReadonlyArray<string>) {
    super();
  }
}

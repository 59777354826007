// https://stackoverflow.com/a/42643593
import {Directive, ElementRef, Output, EventEmitter, HostListener} from '@angular/core';

@Directive({
  selector: '[csClickOutside]'
})
export class ClickOutsideDirective {
  constructor(private _elementRef: ElementRef) {
  }

  @Output()
  public clickOutside = new EventEmitter<MouseEvent>();

  @HostListener('document:click', ['$event'])
  @HostListener('document:touchstart', ['$event'])
  public onClick(event: MouseEvent): void {
    // Only respond to left-click events (on Firefox on OSX a right click event is raised along with the context menu event).
    if(event.button){
      return;
    }

    let targetElement = event.target as Element || event.srcElement;
    if (!targetElement) {
      return;
    }

    let dataset = (targetElement as any).dataset;
    if(dataset && dataset.ignoreClickOuside){
      return;
    }

    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    if (clickedInside) {
      return;
    }

    this.clickOutside.emit(event);
  }
}

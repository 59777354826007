import { SourceData } from './types/source-data';
import { IPopulatedMultiPlotLayout } from './types/i-populated-multi-plot-layout';
import { ProcessedPlotResult } from './types/processed-plot-result';
import { ProcessedPlot } from './types/processed-plot';
import { ProcessedPlotSource } from './types/processed-plot-source';
import { ProcessedPlotSourceChannel } from './types/processed-plot-source-channel';
import { CreateColumnsFromDiagonals } from './create-columns-from-diagonals';
import { CreateRowsFromDiagonals } from './create-rows-from-diagonals';
import { PlotSetDirection } from './plot-set-direction';

/**
 * Create the set of processed plots which should be rendered for the given set of
 * diagonal plot sets, taking into account options around stacking diagonal plots.
 */
export class CreatePlotsFromDiagonals {

  constructor(
    private readonly createColumnsFromDiagonals: CreateColumnsFromDiagonals,
    private readonly createRowsFromDiagonals: CreateRowsFromDiagonals) {
  }

  /**
   * Create the set of processed plots which should be rendered for the given set of
   * diagonal plot sets, taking into account options around stacking diagonal plots.
   *
   * We can plot diagonals when the number of rows and columns are the same, or when one is
   * a multiple of the other.
   *
   * In the case where the number of rows and columns are the same we have a single
   * diagonal plot set.
   *
   * For example the following 3 column by 3 row chart has 1 diagonal plot sets containing
   * three plots:
   *
   *     │  ▉
   *     │ ▉
   *     │▉
   *     └───
   *
   * If we stack these diagonals horizontally, we get a row of three plots:
   *
   *     │▉▉▉
   *     └───
   *
   * If we stack these diagonals vertically, we get a column of three plots:
   *
   *     │▉
   *     │▉
   *     │▉
   *     └─
   *
   * If we stack both horizontally and vertically, we a single plot such that each plot in
   * the (only) diagonal plot set has been merged into one plot:
   *
   *     │▉
   *     └─
   *
   * In the case where for the number of rows and columns one is a multiple of the other,
   * we repeat the diagonal plots in the direction of the longer side.
   * Each set of plots within a single repetition is a diagonal plot set.
   *
   * For example the following 6 column by 3 row chart has 2 diagonal plot sets, each containing
   * three plots:
   *
   *         ┌─┐ Plot set 2
   *      ┌─┐    Plot set 1
   *     │  ▉  ▉
   *     │ ▉  ▉
   *     │▉  ▉
   *     └──────
   *
   * If we stack these diagonals horizontally, we get a row of six plots:
   *
   *     │▉▉▉▉▉▉
   *     └──────
   *
   * If we stack these diagonals vertically, we get two columns of three plots:
   *
   *     │▉▉
   *     │▉▉
   *     │▉▉
   *     └──
   *
   * If we stack both horizontally and vertically, we get a row of two plots
   * such that the three plots in each diagonal plot set has been merged into one:
   *
   *     │▉▉
   *     └──
   *
   * The following 2 column by four row also has 2 diagonal plot sets, but with each containing
   * 2 plots:
   *
   *     │ ▉   ┐
   *     │▉    ┘ Plot set 1
   *     │ ▉   ┐
   *     │▉    ┘ Plot set 2
   *     └──
   *
   * If we stack these diagonals horizontally, we get two rows of two plots:
   *
   *     │▉▉
   *     │▉▉
   *     └──
   *
   * If we stack these diagonals vertically, we get one column of four plots:
   *
   *     │▉
   *     │▉
   *     │▉
   *     │▉
   *     └──
   *
   * If we stack both horizontally and vertically, we get two rows of one plot
   * such that the two plots in each diagonal plot set has been merged into one:
   *
   *     │▉
   *     │▉
   *     └─
   *
   * @param layout The populated layout. The layout contains the diagonal stacking options
   *               (horizontal, vertical or both).
   * @param sourceData The set of data sources.
   * @param diagonalPlotSets The collection of diagonal plot sets.
   * @returns The set of processed plots for the given diagonal stacking options.
   */
  public execute(
    layout: IPopulatedMultiPlotLayout,
    sourceData: ReadonlyArray<SourceData>,
    diagonalPlotSets: readonly ProcessedPlot[][]): ProcessedPlotResult {

    // If a chart has more columns than rows then the plot set direction is horizontal.
    // If it has more rows than columns, it is vertical.
    let plotSetDirection = layout.columns.length > layout.rows.length ? PlotSetDirection.horizontal : PlotSetDirection.vertical;

    // Get the final set of columns and rows in our chart for the given diagonal stacking options.
    let processedColumns = this.createColumnsFromDiagonals.execute(diagonalPlotSets, plotSetDirection, layout, sourceData);
    let processedRows = this.createRowsFromDiagonals.execute(diagonalPlotSets, plotSetDirection, layout, sourceData);

    let plotsFromDiagonals: ProcessedPlot[] = [];

    if (layout.stackDiagonalsHorizontally && !layout.stackDiagonalsVertically) {
      // We're stacking the diagonals horizontally and not vertically.

      // For each diagonal plot set...
      for (let setIndex = 0; setIndex < diagonalPlotSets.length; ++setIndex) {
        let diagonalPlots = diagonalPlotSets[setIndex];

        // For each plot in the set...
        for (let plotIndexInSet = 0; plotIndexInSet < diagonalPlots.length; ++plotIndexInSet) {

          // Get the next plot index.
          let plotIndex = plotsFromDiagonals.length;

          let column = plotSetDirection === PlotSetDirection.horizontal
            // We will have one column per plot with horizontal stacking and a horizontal plot set direction.
            ? processedColumns[plotIndex]
            // We will have one column per plot within the plot set with horizontal stacking and a vertical plot set direction.
            : processedColumns[plotIndexInSet];

          let row = plotSetDirection === PlotSetDirection.horizontal
            // We will only have one row with horizontal stacking and a horizontal plot set direction.
            ? processedRows[0]
            // We will have one row per plot set with horizontal stacking and a vertical plot set direction.
            : processedRows[setIndex];

          // Create a new processed plot for the row and column position.
          plotsFromDiagonals.push(new ProcessedPlot(
            plotIndex,
            column,
            row,
            diagonalPlots[plotIndexInSet].sources.map(
              source => new ProcessedPlotSource(
                source.channels.map(
                  channel => new ProcessedPlotSourceChannel(plotIndex, channel.sourceIndex, channel.xChannel, channel.yChannel))))));
        }
      }
    } else if (!layout.stackDiagonalsHorizontally && layout.stackDiagonalsVertically) {
       // We're stacking the diagonals vertically and not horizontally.

       // For each diagonal plot set...
      for (let setIndex = 0; setIndex < diagonalPlotSets.length; ++setIndex) {
        let diagonalPlots = diagonalPlotSets[setIndex];

        // For each plot in the set...
        for (let plotIndexInSet = 0; plotIndexInSet < diagonalPlots.length; ++plotIndexInSet) {

          // Get the next plot index.
          let plotIndex = plotsFromDiagonals.length;

          let column = plotSetDirection === PlotSetDirection.horizontal
            // We will have one column per plot set with vertical stacking and a horizontal plot set direction.
            ? processedColumns[setIndex]
            // We will only have one column with vertical stacking and a vertical plot set direction.
            : processedColumns[0];

          let row = plotSetDirection === PlotSetDirection.horizontal
            // We will have one row per plot within the plot set with vertical stacking and a horizontal plot set direction.
            ? processedRows[plotIndexInSet]
            // We will have one row per plot with vertical stacking and a vertical plot set direction.
            : processedRows[plotIndex];

          // Create a new processed plot for the row and column position.
          plotsFromDiagonals.push(new ProcessedPlot(
            plotIndex,
            column,
            row,
            diagonalPlots[plotIndexInSet].sources.map(
              source => new ProcessedPlotSource(
                source.channels.map(
                  channel => new ProcessedPlotSourceChannel(plotIndex, channel.sourceIndex, channel.xChannel, channel.yChannel))))));
        }
      }
    } else if (layout.stackDiagonalsHorizontally && layout.stackDiagonalsVertically) {
      // We're stacking the diagonals both vertically and horizontally.

      // For each diagonal plot set...
      for (let setIndex = 0; setIndex < diagonalPlotSets.length; ++setIndex) {
        let diagonalPlots = diagonalPlotSets[setIndex];

        let plotIndex = plotsFromDiagonals.length;

        let column = plotSetDirection === PlotSetDirection.horizontal
          // If the plot set direction is horizontal, we will have one column per plot set.
          ? processedColumns[setIndex]
          // If the plot set direction is vertical, we will only have one column.
          : processedColumns[0];

        let row = plotSetDirection === PlotSetDirection.horizontal
          // If the plot set direction is horizontal, we only have one row.
          ? processedRows[0]
          // If the plot set direction is vertical, we will have one row per plot set.
          : processedRows[setIndex];

        // With diagonals stacked horizontally and vertically we end up with one plot per plot set, so we
        // need to merge the plots from the current diagonal plot set.
        let mergedSources: ProcessedPlotSource[] = [];

        // For each channel data source...
        for (let sourceIndex = 0; sourceIndex < diagonalPlots[0].sources.length; ++sourceIndex) {
          // Get all the channels for the current source index from all the plots in the current diagonal plot set.
          let mergedSourceChannels = diagonalPlots.reduce<ProcessedPlotSourceChannel[]>((p, c) => {
            p.push(...c.sources[sourceIndex].channels);
            return p;
          }, []);

          // Create a new processed plot source with the merged channels.
          mergedSources.push(new ProcessedPlotSource(mergedSourceChannels.map(
            channel => new ProcessedPlotSourceChannel(plotIndex, channel.sourceIndex, channel.xChannel, channel.yChannel))));
        }

        // Create a new processed plot for the row and column position.
        plotsFromDiagonals.push(new ProcessedPlot(
          plotIndex,
          column,
          row,
          mergedSources));
      }
    }

    return new ProcessedPlotResult(plotsFromDiagonals, processedColumns, processedRows);
  }
}

import * as d3 from '../../../d3-bundle';
import { ChannelData } from './channel-data';
import { DataFormat } from './data-format';
import { cssSanitize } from '../../../css-sanitize';
import { YData } from './y-data';
import { ChannelBinaryFormat } from '../../../url-file-loader';

export class ZData implements ChannelData {
  constructor(
    public name: string,
    public genericName: string,
    public fullName: string,
    public requestedName: string, // How the name was defined in the chart definition.
    public units: string,
    public description: string,
    public iPane: number,
    public iLap: number,
    public specificUnits: boolean,
    public binaryFormat: ChannelBinaryFormat | undefined,
    public binaryUnits: string,
    public deltaChannel: boolean = false,
  ) {
    this.isChannelVisible = true;
    this.isLapVisible = true;
    this.values = [];
    this.sValues = [];
    //this.sValuesSubSampled = [];
    this.sanitizedName = cssSanitize(this.name);
  }

  public sanitizedName: string; // PC VIEWER
  public isChannelVisible: boolean;
  public isLapVisible: boolean;
  public format?: DataFormat;
  public sValues: number[];
  //public sValuesSubSampled: number[];
  public values: number[];
  public szMax?: number;
  public szMin?: number;
  public zMax: number = NaN;
  public zMin: number = NaN;
  public cursorValue?: number;
  public zScale?: d3.ScaleLinear<number, number>;
  public direction: number = 1;
  public yDomain?: YData;

  public get isVisible(): boolean {
    return this.isChannelVisible && this.isLapVisible;
  }
}

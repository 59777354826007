import {Component, ViewChild, OnDestroy, ElementRef} from '@angular/core';
import {ConfigType, ExplorationConfigType, IS_DEFAULT_DATA_KEY} from '../config-types';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {CONFIG_TYPE_DATA_KEY} from '../config-types';
import {CanopyJson} from '../../../common/canopy-json.service';
import {ConfigStub, SimVersionStub, GetSupportSessionQueryResult} from '../../../../generated/api-stubs';
import {StudyStagingArea} from '../../study-staging-area/study-staging-area.service';
import {IS_STAGED_DATA_KEY} from '../config-types';
import {
  CONFIG_ID_URL_PARAMETER_KEY, CONFIG_NAME_URL_PARAMETER_KEY,
  SIM_VERSION_URL_PARAMETER_KEY
} from '../edit-config-page/edit-config.page';
import {OnInit} from '@angular/core';
import {InferableRouteParams} from '../../../common/inferable-route-params.service';

import {JSONFormatter} from '../../../common/json-formatter/json-formatter';
import {Timer} from '../../../common/timer.service';
import {CustomPropertyUtilities} from '../../custom-properties/custom-property-utilities';
import {CustomProperty} from '../../custom-properties/custom-properties';
import {Disposable} from '../../../common/disposable';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {JsonEditor} from '../json-config-editor/json-editor.service';
import {
  CompareConfigDialog,
  NO_SAVE_OUTPUT_CONFIG_HANDLER
} from '../comparing/compare-config-dialog/compare-config-dialog.service';
import {cssSanitize} from '../../../common/css-sanitize';
import {fileNameSanitize} from '../../../common/file-name-sanitize';
import {InputValidationError} from '../../../common/errors/errors';
import {GetSimVersion} from '../../../common/get-sim-version.service';
import {InputCustomProperty, StudyInput} from '../../../worksheets/study-input';
import {getDefaultConfigId} from '../../../worksheets/study-input-utilities';
import {ConfigOrConfigLoader} from '../comparing/config-or-config-loader';
import { AuthenticationService } from '../../../identity/state/authentication.service';

@Component({
  templateUrl: './view-config.page.html',
  styleUrls: ['./view-config.page.scss']
})
export class ViewConfigPage implements OnInit, OnDestroy {
  public errorMessage: string;
  public tenantId: string;
  public userId: string;

  public configId: string;
  public simVersion: string;

  public isCurrentTenant: boolean;
  public configType: ConfigType;
  public configName: string;
  public configNotes: string;
  public configDataFormatted: string;
  public configDataEncoded: string;
  public supportSession: GetSupportSessionQueryResult;
  public isStaged: boolean;
  public isDefault: boolean;
  public isLoaded: boolean = false;
  public isEdited: boolean = false;

  public configData: any;
  public customProperties: CustomProperty[] = [];

  public viewer: Disposable;

  @ViewChild('config') input: ElementRef;

  constructor(
    route: ActivatedRoute,
    inferableRouteParams: InferableRouteParams,
    private readonly authenticationService: AuthenticationService,
    private readonly configStub: ConfigStub,
    private readonly studyStagingArea: StudyStagingArea,
    private readonly json: CanopyJson,
    private readonly timer: Timer,
    private readonly simVersionStub: SimVersionStub,
    private readonly getSimVersion: GetSimVersion,
    private readonly sanitizer: DomSanitizer,
    private readonly jsonEditor: JsonEditor,
    private readonly compareConfigDialog: CompareConfigDialog,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {

    this.tenantId = inferableRouteParams.getTenantId(route);
    const userData = this.authenticationService.userDataSnapshot;
    this.isCurrentTenant = userData.tenant === this.tenantId;

    this.configType = route.snapshot.data[CONFIG_TYPE_DATA_KEY];
    this.isStaged = route.snapshot.data[IS_STAGED_DATA_KEY];
    this.isDefault = route.snapshot.data[IS_DEFAULT_DATA_KEY];
    if(!this.isStaged && !this.isDefault){
      this.configId = route.snapshot.params[CONFIG_ID_URL_PARAMETER_KEY];
    }
    if(this.isDefault){
      this.configName = route.snapshot.params[CONFIG_NAME_URL_PARAMETER_KEY];
      this.simVersion = route.snapshot.params[SIM_VERSION_URL_PARAMETER_KEY];
    }
  }

  //public routerCanReuse():any {
  //  return false;
  //}

  public ngOnInit() {
    if(this.isStaged) {
      this.loadFromStagingArea();
    } else if(this.isDefault){
      this.loadFromSimVersionDocuments();
    } else {
      this.loadFromDatabase();
    }
  }

  public ngOnDestroy(){
    if(this.viewer){
      this.viewer.dispose();
    }
  }

  public async completeLoad(){
    let editorInstance = await this.jsonEditor.create(
      undefined,
      'view-config-json-editor',
      this.configType.singularKey,
      this.configData,
      this.simVersion,
      this.configType.singularKey);

    if(!editorInstance){
      return; // The page load has been canceled.
    }

    this.isLoaded = true;

    await this.timer.yield();

    this.configData = editorInstance.getValue();
    editorInstance.destroy();
    this.configDataFormatted = this.json.stringify({
      simVersion: this.simVersion,
      config: this.configData
    });
    this.configDataEncoded = encodeURIComponent(this.configDataFormatted);

    await this.loadJsonViewer();
  }

  public async loadFromSimVersionDocuments() {
    try {
      let documentResult = await this.simVersionStub.getDocument(
        this.simVersion,
        getDefaultConfigId(this.configType.singularKey, this.configName));

      this.configData = this.json.parse(documentResult.document.content);
      this.customProperties = [];

      await this.completeLoad();
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async loadFromStagingArea() {
    try {
      this.simVersion = await this.getSimVersion.execute();
      let input = await this.studyStagingArea.getInput(this.configType.singularKey, this.simVersion);
      if(!input) {
        throw new InputValidationError('A staged ' + this.configType.name + ' was not found.');
      }

      this.configName = input.name;
      this.configNotes = input.notes;
      this.configData = input.data;
      this.userId = input.userId;
      this.configId = input.configId;
      this.isEdited = input.isEdited;
      this.customProperties = InputCustomProperty.toMutables(input.properties);

      await this.completeLoad();
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async loadFromDatabase() {
    try {
      this.simVersion = await this.getSimVersion.execute();
      let configResult = await this.configStub.getConfig(
        this.tenantId,
        this.configId,
        undefined,
        this.simVersion);

      this.userId = configResult.config.userId;
      this.configName = configResult.config.name;
      this.configNotes = configResult.config.notes;
      this.configData = configResult.config.data;
      this.supportSession = {
        session: configResult.config.supportSession,
        userInformation: configResult.userInformation
      };
      this.customProperties = CustomPropertyUtilities.objectToList(configResult.config.properties);

      await this.completeLoad();
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async loadJsonViewer() {
    if(!this.input.nativeElement){
      // The page has been unloaded.
      return;
    }

    let config = {
      hoverPreviewEnabled: false,
      hoverPreviewArrayCount: 100,
      hoverPreviewFieldCount: 5
    };
    let open = this.configType.singularKey === ExplorationConfigType.singularKey
      ? Infinity : 1;
    let formatter = new JSONFormatter(this.configData, open, config, this.configType.name);
    this.input.nativeElement.appendChild(formatter.render());
  }

  public stage() {
    try {
      this.studyStagingArea.stageInput(
        this.getAsStudyInput());
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async compareToStaged(){
    try {
      let staged = await this.studyStagingArea.getInput(this.configType.singularKey, this.simVersion);
      let current = this.getAsStudyInput();
      await this.compareConfigDialog.compare(
        this.configType,
        [
          new ConfigOrConfigLoader('current', current, undefined),
          new ConfigOrConfigLoader('staged', staged, undefined),
        ],
        NO_SAVE_OUTPUT_CONFIG_HANDLER);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public bypassSecurityTrustUrl(url: string){
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  public cssSanitize(input: string){
    return cssSanitize(input);
  }

  public fileNameSanitize(input: string){
    return fileNameSanitize(input);
  }

  public tryGetEditorValue = () => Promise.resolve(this.configData);

  public onSupportSessionChanged(supportSession: GetSupportSessionQueryResult){
    this.supportSession = supportSession;
  }

  private getAsStudyInput(): StudyInput{
    return new StudyInput(
      this.configType.singularKey,
      this.isDefault ? undefined : this.userId,
      this.isDefault ? undefined : this.configId,
      this.configName,
      this.configData,
      this.customProperties,
      this.configNotes,
      this.simVersion,
      false,
      undefined);
  }
}

import {DocumentCustomPropertyData, WorksheetOutline} from '../../generated/api-stubs';

/**
 * A request to update a worksheet.
 */
export class WorksheetUpdateRequest {

  /**
   * Creates a new WorksheetUpdateRequest.
   * @param generateColumns Whether to generate columns. If true, this finds the superset of columns
   * across all rows, ensures we have column view models for each column, and ensures all the rows
   * have row item view models for each column.
   * @param name The new name of the worksheet.
   * @param outline The new outline of the worksheet.
   * @param properties The new custom properties of the worksheet.
   * @param notes The new notes of the worksheet.
   */
  constructor(
    public readonly generateColumns: boolean,
    public readonly name: string,
    public readonly outline: WorksheetOutline,
    public readonly properties: DocumentCustomPropertyData[] | undefined,
    public readonly notes: string | undefined) {
  }
}


import {ErrorHandler, Injectable} from '@angular/core';

export const LOGGING_FAILED_MESSAGE = 'Failed to log error';

@Injectable()
export class LogError {
  constructor(private errorHandler: ErrorHandler) {
  }

  public execute(error: any) {
    try {
      if (error.isFromApi || error.isInputValidationError || error.isDisplayable) {
        // We don't log API errors to the server (as they have already been logged),
        // also, input validation errors are recoverable and intended for the user.
        // Displayable errors are also intended for the user.
        console.log(error.message);
      } else {
        const type = (error || {}).toString();
        if (type === '[object XMLHttpRequest]') {
          error = `XMLHttpRequest error: ${error.statusText}`;
        }

        console.error(error);
        let isConnectionError = error && error.message && error.message.status === 0;
        if(!isConnectionError){
          this.errorHandler.handleError(error);
        }
      }
    } catch (e) {
      console.log(LOGGING_FAILED_MESSAGE);
    }
  }
}

import { Injectable } from '@angular/core';
import { InterceptorBase } from './interceptor-base.service';
import { BasicRequestOptions } from '../canopy-http.service';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { AuthenticationService } from '../../identity/state/authentication.service';
import { RedirectService } from '../../navigation/redirect.service';

@Injectable()
export class AuthenticationInterceptor extends InterceptorBase {

  constructor(
    private authenticationService: AuthenticationService,
    private redirectService: RedirectService) {
    super();
  }

  public async request(requestOptions: BasicRequestOptions): Promise<BasicRequestOptions> {
    if (!requestOptions.headers) {
      requestOptions.headers = new HttpHeaders();
    }

    const accessToken = this.authenticationService.accessTokenSnapshot;

    requestOptions.headers = requestOptions.headers.set('Authorization', 'Bearer ' + accessToken);
    requestOptions.withCredentials = true;

    return Promise.resolve(requestOptions);
  }

  public async responseError(response: HttpResponse<any>, retryCount: number): Promise<boolean> {
    if (response.status === 401 && retryCount <= 1) {
      await this.authenticationService.checkAuthentication().toPromise();

      if (!this.authenticationService.isAuthenticatedSnapshot) {
        this.redirectService.setRedirect('canopy', this.redirectUrl);
        await this.authenticationService.login().toPromise();
      }

      return true;
    }

    return false;
  }

  private get redirectUrl() {
    return window.location.pathname + window.location.search;
  }
}

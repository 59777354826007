import {Injectable} from '@angular/core';
import {DialogManager, IDialog, DialogBase} from '../../../common/dialogs/dialog-manager.service';
import {DocumentSubType} from '../../../../generated/api-stubs';
import {VersionedDocumentData} from '../versioned-document-data';
import {ISaveOutputConfigHandler} from '../../configs/comparing/compare-config-dialog/compare-config-dialog.service';

@Injectable()
export class ListVersionsDialog {
  constructor(
    private dialogManager: DialogManager
  ){}

  public async show(
    tenantId: string,
    userId: string,
    configId: string,
    configData: any,
    configType: DocumentSubType,
    saveOutputConfigHandler: ISaveOutputConfigHandler): Promise<ListVersionsResult> {

    let result = await this.dialogManager.showPrimaryDialog(
      new ListVersionsDialogData(
        tenantId,
        userId,
        configId,
        configData,
        configType,
        saveOutputConfigHandler));

    return result;
  }
}

export class ListVersionsDialogData extends DialogBase<ListVersionsResult> implements IDialog {
  constructor(
    public readonly tenantId: string,
    public readonly userId: string,
    public readonly configId: string,
    public readonly configData: any,
    public readonly configType: DocumentSubType,
    public readonly saveOutputConfigHandler: ISaveOutputConfigHandler) {
    super();
  }
}

export class ListVersionsResult {
  constructor(
    public readonly loadedDocument: VersionedDocumentData){
  }
}

import {EventEmitter} from '@angular/core';
import {Observable} from 'rxjs';
import {Filter} from '../../../visualizations/navigation-station/config-builders/car-config-builder';
import {SimType} from '../../../../generated/api-stubs';
import {cssSanitize} from '../../../common/css-sanitize';

export class TabViewModel {
  private readonly activatedEmitter: EventEmitter<TabViewModel> = new EventEmitter<TabViewModel>();

  private _isActive: boolean = false;

  constructor(
    public readonly name: string,
    public readonly id: string,
    public readonly type: TabRenderType){
  }

  public get activated(): Observable<TabViewModel> {
    return this.activatedEmitter;
  }

  public get isActive(): boolean {
    return this._isActive;
  }

  public activate(): void {
    this._isActive = true;
    this.activatedEmitter.emit(this);
  }

  public deactivate(): void {
    this._isActive = false;
  }
}

export class VerifyCarTabViewModel extends TabViewModel {
  constructor(
    name: string,
    public readonly filter: Filter,
    public readonly perJob: boolean = false){
    super(name, `data-car-${cssSanitize(name)}`, TabRenderType.navigationStation);
  }
}

export class SimTypesTabViewModel extends TabViewModel {
  constructor(
    name: string,
    public readonly simTypes: SimType[],
    public readonly navigationStationRenderType: NavigationStationRenderType){
    super(name, simTypes.reduce((p, c) => `${p}-${c}`, 'data-sim-types'), TabRenderType.navigationStation);
  }
}

export class CarTabViewModel extends TabViewModel {
  constructor(
    public readonly perJob: boolean = false){
    super('Suspension Viewer', `data-car`, TabRenderType.navigationStation);
  }
}

export class TrackTabViewModel extends TabViewModel {
  constructor(
    public readonly perJob: boolean = false){
    super('Track Config', `data-track`, TabRenderType.navigationStation);
  }
}

export enum NavigationStationRenderType {
  default,
  racingLine
}

export enum TabRenderType {
  inputs,
  scalarResults,
  info,
  warnings,
  errors,
  navigationStation,
}

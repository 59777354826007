import { SourceData } from './types/source-data';
import { IPopulatedMultiPlotChannel } from './types/i-populated-multi-plot-channel';
import { IMultiPlotChannel } from './types/i-multi-plot-channel';
import { ProcessedMultiPlotChannel } from './types/processed-multi-plot-channel';
import { ProcessedDataFactory } from './types/processed-data-factory';
import { ApplyChannelModifiers } from '../channel-data-loaders/apply-channel-modifiers';
import { NO_UNITS } from '../../constants';
import { ChannelIndexMap } from './channel-index-map';


/**
 * Returns a set of processed channels for the given channel. A channel with modifiers may
 * return more than one processed channel.
 */
export class GetProcessedChannels {

  constructor(
    private readonly applyChannelModifiers: ApplyChannelModifiers,
    private readonly processedDataFactory: ProcessedDataFactory) {
  }

  /**
   * Returns a set of processed channels for the given channel. A channel with modifiers may
   * return more than one processed channel.
   * @param channel The channel definition to process.
   * @param sourcesData The set of data sources.
   * @param channelIndexMap The channel index map.
   * @param xDomainNames The set of X domain names.
   * @returns The set of processed channels.
   */
  public execute(channel: IMultiPlotChannel, sourcesData: ReadonlyArray<SourceData>, channelIndexMap: ChannelIndexMap, xDomainNames: ReadonlyArray<string>): ProcessedMultiPlotChannel[] {
    // Apply any channel modifiers to the channel name (e.g. delta).
    // This can result in more than one channel, for example there is one delta channel per X domain.
    let modifiedChannels = this.applyChannelModifiers.executeForChannelName(channel.name, channel, xDomainNames);
    let result: ProcessedMultiPlotChannel[] = [];

    // For each resulting channel...
    for (let modifiedChannel of modifiedChannels) {

      // Get the name, index and data.
      let channelName = modifiedChannel.channelName;
      let index = channelIndexMap[channelName] || 0;
      let data = sourcesData.map(v => v.channels.get(channelName));

      // Get some default values for the channel.
      let displayName: string = channelName;
      let genericName = channelName;
      let units: string = NO_UNITS;
      let isUserSpecifiedUnits: boolean = false;

      if (data.length) {
        let first = data[0];
        if (first) {
          // If we have data from a data source then use the first data source to get the units
          // and other metadata, overriding the defaults specified above.
          displayName = first.name;
          genericName = first.genericName;
          units = first.units;
          isUserSpecifiedUnits = first.isUserSpecifiedUnits;
        }
      }

      result.push(this.processedDataFactory.createChannel(
        channel as IPopulatedMultiPlotChannel,
        displayName,
        genericName,
        index,
        data,
        units,
        isUserSpecifiedUnits,
        modifiedChannel.renderOnlyForDomain));
    }

    return result;
  }
}

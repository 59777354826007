import { ProcessedMultiPlotChannel } from './processed-multi-plot-channel';

/**
 * The result of finding the closest data point to a given point in a multi plot viewer.
 */

export class QuadTreeCursorDataPoint {
  /**
   * Creates a new result of finding the closest data point to a given point in a multi plot viewer.
   * @param x The x coordinate of the data point.
   * @param y The y coordinate of the data point.
   * @param index The index of the data point.
   * @param channel The channel of the data point.
   * @param plotIndex The index of the plot.
   * @param sourceIndex The index of the data source containing the point.
   */
  constructor(
    public readonly x: number,
    public readonly y: number,
    public readonly index: number,
    public readonly channel: ProcessedMultiPlotChannel,
    public readonly plotIndex: number,
    public readonly sourceIndex: number) {
  }
}

import {Rule} from '../runner/rule';
import {ConfigContext, ConfigNode} from '../runner/config-context';
import {RuleLogger} from '../runner/rule-logger';

export class TrackStartFinishOffset extends Rule {
  public name = 'Track Start Finish Offset';
  public configTypes = 'track';
  public simTypes: string[] = null;

  public evaluateConfig(context: ConfigContext, log: RuleLogger) {
    let startFinishOffsetNode = context.getValue('startFinishOffset');
    let sLapCentreLineNode = context.getValue('centreLine.sLapCentreLine');
    let sLapRacingLineNode = context.getValue('racingLine.sLap');
    this.evaluateConfigForPath(sLapCentreLineNode, 'centre line', startFinishOffsetNode, log);
    this.evaluateConfigForPath(sLapRacingLineNode, 'racing line', startFinishOffsetNode, log);
  }

  private evaluateConfigForPath(pathNode: ConfigNode, pathNodeName: string, startFinishOffsetNode: ConfigNode, log: RuleLogger) {
    if (startFinishOffsetNode && pathNode && pathNode.value.length) {
      let startFinishOffset = startFinishOffsetNode.value;
      let sLapMax = pathNode.value[pathNode.value.length - 1];
      if (startFinishOffset > sLapMax) {
        log.warn(`Start/finish offset ${startFinishOffset} is greater than ${pathNodeName} distance ${sLapMax}.`, startFinishOffsetNode.path);
      } else if (startFinishOffset < -sLapMax) {
        log.warn(`Start/finish offset ${startFinishOffset} is less than than negative ${pathNodeName} distance ${sLapMax}.`, startFinishOffsetNode.path);
      }
    }
  }
}

export class UserStateTenant {
  constructor(
    public readonly tenantId: string,
    public readonly name: string,
    public readonly shortName: string,
    public readonly creationDate: string,
    public readonly isEnabled: boolean,
    public readonly databaseId: string) {
  }
}

import {DialogBase, DialogManager, IDialog} from './dialog-manager.service';
import {Directive, EventEmitter, OnInit} from '@angular/core';
import {GetFriendlyErrorAndLog} from '../errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';

@Directive()
export abstract class DialogComponentBase<TData extends DialogBase<TResult>, TResult> implements IDialogActions<TResult>, OnInit {
  public isVisible: boolean = false;

  public dialog: TData;
  public errorMessage: string;

  public session: IDialogSession;

  constructor(
    private readonly sessionFactory: IDialogSessionFactory<TData, TResult>,
    protected readonly dialogManager: DialogManager,
    private readonly dialogLevel: EventEmitter<IDialog>,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  public ngOnInit(): any {
    this.dialogLevel.subscribe(() => this.dialogManagerChanged());
  }

  protected abstract getCurrentDialog(): TData | undefined;

  public dialogManagerChanged() {
    this.dismiss();

    let currentDialog = this.getCurrentDialog();

    if(currentDialog){
      this.isVisible = true;
      this.dialog = currentDialog;
      let dialogActions = new SessionVerifyingDialogActions<TData, TResult>(this);
      this.session = this.sessionFactory.create(
        dialogActions,
        <TData>currentDialog);
      dialogActions.attachToSession();
      this.loadSessionInBackground();
    } else {
      this.isVisible = false;
      this.session = undefined;
      this.dialog = undefined;
    }
  }

  public accept(result: TResult): void {
    if(this.dialog) {
      this.dialog.resolve(result);
    }
  }

  public dismiss(): void {
    if(this.dialog){
      this.dialog.dismiss();
    }
  }

  private async loadSessionInBackground(): Promise<void>{
    try {
      this.session.load();
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }
}

export interface IDialogSessionFactory<TData, TResult> {
  create(dialogActions: IDialogActions<TResult>, dialog: TData): IDialogSession;
}

export interface IDialogSession {
  load(): Promise<void>;
}

  export interface IDialogActions<TResult> {
  accept(result: TResult): void;
  dismiss(): void;
}

  class SessionVerifyingDialogActions<TData extends DialogBase<TResult>, TResult> implements IDialogActions<TResult> {

private expectedSession: IDialogSession;

  constructor(
    private readonly target: DialogComponentBase<TData, TResult>) {
  }

public attachToSession(){
    this.expectedSession = this.target.session;
  }

public accept(result: TResult): void {
    if(this.target.session === this.expectedSession){
    this.target.accept(result);
  }
}

  public dismiss(): void {
    if(this.target.session === this.expectedSession){
      this.target.dismiss();
    }
  }
}

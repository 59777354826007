import {Router, RouterStateSnapshot} from '@angular/router';
import {AuthorizationResult} from './authorize.service';
import {AuthorizePage} from './authorize-page.service';
import {PageSecurity} from './page-security';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../state/authentication.service';
import { RedirectService } from '../../navigation/redirect.service';

@Injectable()
export class AuthGuardService {

  constructor(
    private authorizePage: AuthorizePage,
    private router: Router,
    private authenticationService: AuthenticationService,
    private redirectService: RedirectService) {
  }

  public async canActivate(pageSecurity: PageSecurity, state: RouterStateSnapshot): Promise<boolean> {
    let result = this.authorizePage.execute(pageSecurity);
    switch (result) {
      case AuthorizationResult.authorized:
        return true;

      case AuthorizationResult.loginRequired:
        await this.authenticationService.checkAuthentication().toPromise();

        const isAuthenticatedOidc = this.authenticationService.isAuthenticatedSnapshot;

        if (isAuthenticatedOidc) {
          return true;
        }

        this.redirectService.setRedirect('canopy', window.location.pathname + window.location.search);

        await this.authenticationService.login().toPromise();

        return false;

      default:
        this.router.navigate(['/not-authorized']);
        return false;
    }
  }
}

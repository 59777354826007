import {Injectable} from '@angular/core';
import {CommandContext, CommandResult, ICommandContext, WorksheetCommand} from './worksheet-command';
import {
  ButtonMenuItem,
  MenuItem,
} from '../../context-menu/context-menu-types';
import {CompareConfigToStudyInput} from '../compare-config-to-study-input';
import {ConfigViewModel} from '../config-view-model';

/**
 * A command that compares the selected config to the study input of the same type on the same row.
 */
@Injectable()
export class CompareToStudyInputCommand extends WorksheetCommand {

  /**
   * Creates an instance of CompareToStudyInputCommand.
   * @param compareConfigToStudyInput The service for comparing a config to the study input.
   */
  constructor(
    private readonly compareConfigToStudyInput: CompareConfigToStudyInput) {
    super();
  }

  /**
   * Determines if the command can be executed.
   * @param context The command context.
   * @returns True if the command can be executed, otherwise false.
   */
  public canExecute(context: CommandContext): context is ICommandContext<ConfigViewModel> {
    return context.isConfig()
      && context.isSingleRow
      && context.target.isPopulated
      && context.target.isStudyPopulated;
  }

  /**
   * Executes the command.
   * @param context The command context.
   * @returns The command result.
   */
  public async execute(context: CommandContext): Promise<CommandResult> {
    if(!this.canExecute(context)) {
      return CommandResult.NoUpdate;
    }

    await this.compareConfigToStudyInput.execute(
      context.target.configType,
      context.target.reference,
      context.target.row.study.reference);

    return CommandResult.NoUpdate;
  }

  /**
   * Creates the menu items for the command.
   * @param context The command context.
   * @param result The menu items.
   */
  public createMenuItems(context: CommandContext, result: MenuItem<CommandResult>[]): void {
    if(!this.canExecute(context)) {
      return;
    }

    result.push(new ButtonMenuItem<CommandResult>(
      `Compare to Study Input`,
      `compare-to-study-input-button`,
      () => this.execute(context)));
  }
}

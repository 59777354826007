
/**
 * Get the generic name of an input channel. This converts a name like "car.chassis.hRideF=" to "hRideF".
 *
 * @param channelName The name of the channel.
 * @returns The generic name of the channel.
 */
export function getInputChannelGenericName(channelName: string) {

  // NOTE: This regex matches the one used in Canopy.Api.Simulations.Studies.CreateStudy.GetScalarInputsMetadata
  let regex = /^(.*\.)?([\w-]+)(\[\d+\])*(\W?)$/;
  let match = regex.exec(channelName);
  if (match) {
    channelName = match[2];
  }

  return channelName;
}

import {Injectable} from '@angular/core';
import {CommandContext, CommandResult, ICommandContext, WorksheetCommand} from './worksheet-command';
import {StudyViewModel} from '../study-view-model';
import {
  ButtonMenuItem, KeyboardAction,
  MenuItem,
} from '../../context-menu/context-menu-types';
import {DisplayableError} from '../../common/errors/errors';
import {UNKNOWN_STUDY_TYPE_ERROR_MESSAGE} from '../../simulations/study-staging-area/study-staging-area.component';
import {SubmitStudy} from '../submit-study.service';
import {PromptDialog} from '../../common/dialogs/prompt-dialog.service';
import {LoadContextConfig} from './load-context-config.service';
import { AuthenticationService } from '../../identity/state/authentication.service';

export const NAME_PROMPT_MESSAGE = 'Enter a name for the study:';
export const NAME_PROMPT_TITLE = 'Study Name';

/**
 * A command that runs a study.
 */
@Injectable()
export class RunStudyCommand extends WorksheetCommand {

  /**
   * Creates an instance of RunStudyCommand.
   * @param submitStudy The service for submitting a study.
   * @param loadContextConfig The service for loading a context config.
   * @param promptDialog The prompt dialog service.
   * @param authenticationService The authentication service.
   */
  constructor(
    private readonly submitStudy: SubmitStudy,
    private readonly loadContextConfig: LoadContextConfig,
    private readonly promptDialog: PromptDialog,
    private readonly authenticationService: AuthenticationService) {
    super();
  }

  /**
   * Determines if the command can be executed.
   * @param context The command context.
   * @returns True if the command can be executed, otherwise false.
   */
  public canExecute(context: CommandContext): context is ICommandContext<StudyViewModel> {
    const userData = this.authenticationService.userDataSnapshot;
    return context.isStudy()
      && context.isSingleRow
      && context.worksheet.canWrite(userData.sub);
  }

  /**
   * Executes the command.
   * @param context The command context.
   * @returns The command result.
   */
  public async execute(context: CommandContext): Promise<CommandResult> {
    if(!this.canExecute(context)) {
      return CommandResult.NoUpdate;
    }

    const target = context.target;

    await context.worksheet.waitForUpdate();

    // Get all the inputs for the study.
    for(let input of target.inputs) {
      if(input.isRequired){
        const inputConfig = target.row.getConfig(input.configType);
        if(!inputConfig || !inputConfig.isPopulated){
          throw new DisplayableError(`Input ${target.underlyingData.getConfigTypeName(input.configType)} not provided.`);
        } else if(!inputConfig.isResolved) {
          throw new DisplayableError(`Input ${target.underlyingData.getConfigTypeName(input.configType)} not found.`);
        }
      }
    }

    // Look up the study type.
    const studyTypeInformation = target.underlyingData.studyTypes[target.studyType];
    if(!studyTypeInformation) {
      throw new DisplayableError(UNKNOWN_STUDY_TYPE_ERROR_MESSAGE, 'Unknown study type: ' + target.studyType);
    }

    let defaultName = target.row.rowMetadata.name || '';
    if(target.isResolved){
      defaultName = target.populated.resolvedReference.data.name;
    }

    // Get the study name from the user.
    const studyName = await this.promptDialog.show<string>(NAME_PROMPT_MESSAGE, NAME_PROMPT_TITLE, defaultName);
    if(!studyName) {
      return CommandResult.NoUpdate;
    }

    // Clear the study if it is already populated.
    if(target.isPopulated){
      target.setStudy(undefined);
    }

    target.isSubmitting = true;
    try {
      // Submit the study.
      const committedStudy = await this.submitStudy.execute(
        studyName,
        studyTypeInformation,
        (configType, targetSimVersion) => this.loadContextConfig.execute(context, configType, targetSimVersion));

      // Set the study reference in the worksheet.
      target.setStudy({
        tenantId: committedStudy.tenantId,
        targetId: committedStudy.studyId
      });
    } finally {
      target.isSubmitting = false;
    }

    return CommandResult.UpdateAndGenerateColumns;
  }

  /**
   * Creates the menu items for the command.
   * @param context The command context.
   * @param result The menu items.
   */
  public createMenuItems(context: CommandContext, result: MenuItem<CommandResult>[]): void {
    if(!this.canExecute(context)) {
      return;
    }

    result.push(new ButtonMenuItem<CommandResult>(
      `${context.target.populated ? 'Re-' : ''}Run Study`,
      `run-button`,
      () => this.execute(context),
      context.target.populated ? 'rotate-left' : 'arrow-right',
      KeyboardAction.enter));
  }
}

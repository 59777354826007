import { ButtonMap } from './editor-node-extensions';
import { UpdateDelegate } from './update-delegate';


export class ExtensionButtons {
  constructor(
    public readonly updateDelegate: UpdateDelegate,
    public readonly buttons: ButtonMap) {
  }
}

import {OptionalColumnItemViewModel} from './worksheet-types';
import {ColumnViewModel} from './column-view-model';


/**
 * Represents a column in a row, with a resolved (populated or unpopulated) value.
 */
export class ResolvedColumn {

  /**
   * Creates an instance of ResolvedColumn.
   * @param definition The column definition.
   * @param value The resolved value.
   */
  constructor(
    public readonly definition: ColumnViewModel,
    public readonly value: OptionalColumnItemViewModel) {
  }
}

import {
  DocumentSubType,
  SimulationInput,
  StudyReference,
  StudyResolvedLabels,
  StudyResolvedReference,
  StudyType,
  UserInformation,
  WorksheetStudy
} from '../../generated/api-stubs';
import {WorksheetUnderlyingData} from './worksheet-underlying-data';
import {UNKNOWN_STUDY_TYPE, UNKNOWN_USER} from './worksheet-constants';
import {RowViewModel} from './row-view-model';
import {HashValidity} from './hash-validity';
import {RowItemViewModel} from './row-item-view-model';
import {isStudyComplete} from '../common/is-study-complete';
import {IReadonlyWorksheetReferenceMetadata} from './worksheet-references-metadata';

/**
 * The view model for a Study item on a row.
 */
export class StudyViewModel extends RowItemViewModel {

  /**
   * The view model for a populated study row item.
   */
  public populated: PopulatedStudyViewModel | undefined;

  /**
   * The view model for an unpopulated study row item.
   */
  public readonly unpopulated: UnpopulatedStudyViewModel;

  /**
   * The cached study inputs (populated on first request).
   */
  private cachedStudyInputs: CachedStudyInputs | undefined;

  /**
   * The study never matches the selected config.
   */
  public matchesSelectedConfig: boolean = false;

  /**
   * Whether the study is selected.
   */
  public isSelected: boolean;

  /**
   * Whether the study is being submitted.
   */
  public isSubmitting: boolean;

  /**
   * Creates a new StudyViewModel.
   * @param parent The parent row view model.
   * @param study The study outline, as returned from the API.
   * @param unpopulatedStudyType The study type the user currently has selected for the unpopulated study.
   * @param underlyingData The underlying data for the worksheet.
   */
  protected constructor(
    parent: RowViewModel,
    study: WorksheetStudy,
    unpopulatedStudyType: StudyType,
    public underlyingData: WorksheetUnderlyingData) {
    super(parent);

    if (study && study.reference) {
      this.populated = new PopulatedStudyViewModel(this, study.reference);
    }

    this.unpopulated = new UnpopulatedStudyViewModel();

    this.update(underlyingData);
    if(this.populated){
      unpopulatedStudyType = this.populated.studyType;
    }
    this.unpopulated.initialize(unpopulatedStudyType);
  }

  /**
   * Gets whether the study is populated.
   */
  public get isPopulated(): boolean {
    return !!this.populated;
  }

  /**
   * Gets whether the study is read only.
   */
  public isReadOnly(userId: string): boolean {
    return !!(this.populated
      && this.populated.resolvedReference
      && this.populated.resolvedReference.data
      && this.populated.resolvedReference.data.userId !== userId);
  }

  /**
   * Gets whether the study is writable for the given user ID.
   * @param userId The user ID.
   */
  public canWrite(userId: string): boolean{
    return !this.isReadOnly(userId);
  }

  /**
   * Gets the overall hash validity of the study. The overall validity is valid if all
   * the configs have valid hashes. A valid hash on a config indicates the data in the config
   * matches the input data used to run the study.
   */
  public get hashValidity(): HashValidity {
    if(this.isResolved){
      if(this.row.configs.some(v => v.hashValidity === HashValidity.invalid)){
        return HashValidity.invalid;
      }

      return HashValidity.valid;
    }

    return HashValidity.none;
  }

  /**
   * Updates the view model based on the given worksheet underlying data.
   * @param underlyingData The underlying data for the worksheet.
   */
  public update(underlyingData: WorksheetUnderlyingData) {
    this.underlyingData = underlyingData;
    if(this.populated){
      this.populated.update(this.underlyingData);
    }

    this.unpopulated.update(this.underlyingData);
  }

  /**
   * Gets the outline of the study, which can be used for posting the worksheet back to the API.
   */
  public getOutline(): WorksheetStudy {
    return {
      reference: this.reference,
    };
  }

  /**
   * Updates the view model based on the given study reference.
   * @param reference The study reference.
   */
  public setStudy(reference: StudyReference) {
    if(this.populated) {
      this.populated.destroy(this.underlyingData);
    }

    if(reference){
      this.populated = new PopulatedStudyViewModel(
        this,
        reference);
      this.populated.update(this.underlyingData);
    } else {
      this.populated = undefined;
      this.matchesSelectedConfig = false;
    }

    this.row.onStudyChanged();
  }

  /**
   * Gets the metadata about the current study reference (reference counts, etc.).
   */
  public get referenceMetadata(): IReadonlyWorksheetReferenceMetadata {
    return this.populated
      ? this.underlyingData.referencesMetadata.get(this.populated.reference)
      : undefined;
  }

  /**
   * Gets the study type.
   */
  public get studyType(): StudyType {
    if(this.isResolved){
      return this.populated.studyType;
    }

    return this.unpopulated.studyType;
  }

  /**
   * Gets the study type name.
   */
  public get studyTypeName(): string {
    if(this.isResolved){
      return this.populated.studyTypeName;
    }

    return this.unpopulated.studyTypeName;
  }

  /**
   * Gets the current study reference.
   */
  public get reference(): StudyReference | undefined {
    if(this.populated) {
      return this.populated.reference;
    }

    return undefined;
  }

  /**
   * Gets the resolved study reference.
   */
  public get resolvedReference(): StudyResolvedReference | undefined {
    if(this.populated) {
      return this.populated.resolvedReference;
    }

    return undefined;
  }

  /**
   * Gets the study input config types.
   */
  public get inputConfigTypes(): ReadonlyArray<DocumentSubType> {
    this.ensureCachedStudyInputs();
    return this.cachedStudyInputs.inputConfigTypes;
  }

  /**
   * Gets the study inputs.
   */
  public get inputs(): ReadonlyArray<SimulationInput> {
    this.ensureCachedStudyInputs();
    return this.cachedStudyInputs.inputs;
  }

  /**
   * Gets the necessity of the study input (required or optional) for the given config type.
   * @param configType The config type.
   */
  public getStudyInputNecessity(configType: DocumentSubType): StudyInputNecessity {
    this.ensureCachedStudyInputs();
    const input = this.cachedStudyInputs.inputMap[configType];
    if(!input){
      return StudyInputNecessity.invalid;
    }

    return input.isRequired ? StudyInputNecessity.required : StudyInputNecessity.optional;
  }

  /**
   * Extracts the study inputs and puts them on the row as configs.
   */
  public async extractStudyInputs(){
    await this.row.extractStudyInputs();
  }

  /**
   * Ensures the cached study inputs are up to date.
   */
  private ensureCachedStudyInputs() {
    let studyType = this.studyType;
    let simVersion = this.underlyingData.simVersion;
    if (!this.cachedStudyInputs || this.cachedStudyInputs.studyType !== studyType || this.cachedStudyInputs.simVersion !== simVersion) {
      const definition = this.underlyingData.studyTypes[studyType];
      let inputs = definition
        ? definition.inputs
        : [];

      this.cachedStudyInputs = new CachedStudyInputs(studyType, simVersion, inputs);
    }
  }

  /**
   * Creates a new StudyViewModel from the given study outline.
   * @param row The parent row view model.
   * @param study The study outline.
   * @param underlyingData The underlying data for the worksheet.
   * @returns The new study view model.
   */
  public static fromStudy(
    row: RowViewModel,
    study: WorksheetStudy,
    underlyingData: WorksheetUnderlyingData): StudyViewModel {

    return new StudyViewModel(row, study, undefined, underlyingData);
  }

  /**
   * Creates a new unpopulated StudyViewModel.
   * @param row The parent row view model.
   * @param unpopulatedStudyType The study type for the unpopulated study.
   * @param underlyingData The underlying data for the worksheet.
   * @returns The new study view model.
   */
  public static fromEmpty(
    row: RowViewModel,
    unpopulatedStudyType: StudyType,
    underlyingData: WorksheetUnderlyingData): StudyViewModel {

    return new StudyViewModel(row, undefined, unpopulatedStudyType, underlyingData);
  }

  /**
   * Gets whether the study is resolved.
   */
  public get isResolved(): boolean {
    return !!(this.populated && this.populated.resolvedReference && this.populated.resolvedReference.data);
  }

  /**
   * Gets whether the study resolving has errored.
   */
  public get isErrored(): boolean {
    return !!(this.populated && this.populated.resolvedReference && this.populated.resolvedReference.error);
  }

  /**
   * Gets whether the study is complete.
   */
  public get isComplete(): boolean {
    return this.isPopulated && this.populated.isComplete;
  }

  /**
   * Tells the worksheet to highlight all studies that match the study referenced in this view model.
   */
  public setItemsMatching() {
    this.row.worksheet.setItemsMatching(this.reference);
  }

  /**
   * Tells the worksheet to clear all highlighted items.
   */
  public clearItemsMatching() {
    this.row.worksheet.clearItemsMatching();
  }
}

/**
 * The view model for a populated study item on a row.
 */
export class PopulatedStudyViewModel {

  /**
   * The resolved reference for the study.
   */
  public resolvedReference: StudyResolvedReference | undefined;

  /**
   * The resolved labels for the study.
   */
  public resolvedLabels: StudyResolvedLabels | undefined;

  /**
   * The owner data for the study.
   */
  public owner: UserInformation | undefined;

  /**
   * The name of the owner of the study.
   */
  public ownerName: string;

  /**
   * The study type.
   */
  public studyType: StudyType | undefined;

  /**
   * The name of the study type.
   */
  public studyTypeName: string;

  /**
   * Creates a new PopulatedStudyViewModel.
   * @param parent The parent study view model.
   * @param reference The study reference.
   */
  constructor(
    private readonly parent: StudyViewModel,
    public readonly reference: StudyReference) {
  }

  /**
   * Destroys the view model, cleaning up any resources and decrementing reference counts.
   * @param underlyingData The underlying data for the worksheet.
   */
  public destroy(underlyingData: WorksheetUnderlyingData) {
    if(this.parent) {
      underlyingData.referencesMetadata.decrementStudy(this.parent);
    }
  }

  /**
   * Updates the view model with new worksheet underlying data.
   * @param underlyingData The underlying data for the worksheet.
   */
  public update(underlyingData: WorksheetUnderlyingData) {
    if(this.parent){
      underlyingData.referencesMetadata.incrementStudy(this.parent);
    }

    this.resolvedReference = underlyingData.studyResolvedReferences.get(this.reference);
    this.resolvedLabels = underlyingData.studyResolvedLabels.get(this.reference);

    this.ownerName = UNKNOWN_USER;
    this.studyTypeName = UNKNOWN_STUDY_TYPE;
    if (this.resolvedReference && this.resolvedReference.data) {
      this.owner = underlyingData.users[this.resolvedReference.data.userId];
      if (this.owner) {
        this.ownerName = this.owner.username;
      }

      this.studyType = this.resolvedReference.data.studyDocument.studyType;
      this.studyTypeName = underlyingData.getStudyTypeName(this.studyType);
    }
  }

  /**
   * Gets whether the study is complete.
   */
  public get isComplete(): boolean {
    return this.resolvedReference
      && this.resolvedReference.data
      && isStudyComplete(this.resolvedReference.data.studyDocument);
  }

  /**
   * Gets whether the study has failed jobs.
   */
  public get hasFailedJobs(): boolean {
    return this.resolvedReference
      && this.resolvedReference.data
      && this.resolvedReference.data.studyDocument.succeededJobCount !== this.resolvedReference.data.studyDocument.completedJobCount;
  }
}

/**
 * The view model for an unpopulated study item on a row.
 */
export class UnpopulatedStudyViewModel {

  /**
   * The study type.
   */
  private _studyType: StudyType;

  /**
   * The name of the study type.
   */
  private _studyTypeName: string;

  /**
   * The underlying data for the worksheet.
   */
  private underlyingData: WorksheetUnderlyingData;

  /**
   * Initializes the view model with the given study type.
   * @param initialStudyType The initial study type.
   */
  public initialize(initialStudyType: StudyType) {
    this.studyType = initialStudyType;
  }

  /**
   * Updates the view model with new worksheet underlying data.
   * @param underlyingData The underlying data for the worksheet.
   */
  public update(underlyingData: WorksheetUnderlyingData) {
    this.underlyingData = underlyingData;
  }

  /**
   * Gets the study type.
   */
  public get studyType(): StudyType {
    return this._studyType;
  }

  /**
   * Sets the study type.
   */
  public set studyType(value: StudyType) {
    this._studyType = value;
    this._studyTypeName = this.underlyingData.getStudyTypeName(value);
  }

  /**
   * Gets the study type name.
   */
  public get studyTypeName(): string {
    return this._studyTypeName;
  }
}

/**
 * The cached study inputs for a study view model. This only contains the metadata
 * about the study inputs, and not the actual input data.
 */
class CachedStudyInputs {

  /**
   * A map of the study inputs by config type.
   */
  public readonly inputMap: StudyInputMap;

  /**
   * A list of the study input config types.
   */
  public readonly inputConfigTypes: ReadonlyArray<DocumentSubType>;

  /**
   * Creates a new CachedStudyInputs.
   * @param studyType The study type.
   * @param simVersion The simulation version.
   * @param inputs The study inputs list.
   */
  constructor(
    public readonly studyType: StudyType,
    public readonly simVersion: string,
    public readonly inputs: ReadonlyArray<SimulationInput>) {
    this.inputConfigTypes = this.inputs.map(v => v.configType);
    this.inputMap = this.inputs.reduce((p, c) => {
      p[c.configType] = c; return p;
    }, {} as StudyInputMap);
  }
}

/**
 * A map of study inputs by config type.
 */
type StudyInputMap  = { [configType in DocumentSubType]?: SimulationInput | undefined };

/**
 * The necessity of a study input.
 */
export enum StudyInputNecessity {

  /**
   * The input is invalid for the study type (neither optional nor required).
   */
  invalid,

  /**
   * The input is optional.
   */
  optional,

  /**
   * The input is required.
   */
  required,
}

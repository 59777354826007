import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {
  DocumentSubType,
  GetWorksheetQueryResult,
  WorksheetOutline,
  WorksheetRow,
  WorksheetStub
} from '../../../generated/api-stubs';
import {IWorksheetParent, WorksheetViewModel, WorksheetViewModelFactory} from '../worksheet-view-model';
import {DisplayableError} from '../../common/errors/errors';
import {WorksheetUnderlyingDataFactory} from '../worksheet-underlying-data';
import {WorksheetUpdateRequest} from '../worksheet-update-request';
import {DockedWorksheet, WorksheetDock} from '../worksheet-dock/worksheet-dock.service';
import {Router} from '@angular/router';
import {LoadingDialog} from '../../common/dialogs/loading-dialog.service';
import {Subscription} from 'rxjs';
import {cssSanitize} from '../../common/css-sanitize';
import {DocumentUpdatedEventService, UpdatedDocument} from '../document-updated-event.service';
import {
  WorksheetLabelsEditorDialog, WorksheetLabelsEditorResult,
} from '../worksheet-labels-editor-dialog/worksheet-labels-editor-dialog.service';
import {CanopyJson} from '../../common/canopy-json.service';
import {ActiveWorksheets} from '../active-worksheets.service';
import {EditDocumentMetadataDialog} from '../../simulations/edit-document-metadata-dialog/edit-document-metadata-dialog.service';
import {DocumentMetadata} from '../../simulations/edit-document-metadata/edit-document-metadata.component';
import {GetSimVersion} from '../../common/get-sim-version.service';
import {UnitsManager} from '../../units/units-manager.service';
import { AuthenticationService, UserData } from '../../identity/state/authentication.service';


/**
 * Renders the worksheet.
 */
@Component({
  selector: 'cs-edit-worksheet',
  templateUrl: './edit-worksheet.component.html',
  styleUrls: ['./edit-worksheet.component.scss']
})
export class EditWorksheetComponent implements OnInit, OnDestroy, IWorksheetParent {

  /**
   * The tenant ID of the worksheet.
   */
  @Input() public readonly tenantId: string;

  /**
   * The worksheet ID.
   */
  @Input() public readonly worksheetId: string;

  /**
   * Indicates if the worksheet is docked to the bottom of the window.
   */
  @Input() public readonly isInstanceInDock: boolean = false;

  /**
   * The error message to display.
   */
  public errorMessage: string;

  /**
   * The view model for the worksheet.
   */
  public worksheetViewModel: WorksheetViewModel;

  /**
   * If the worksheet is currently updating via the API, the promise
   * for the update task will be stored here.
   */
  private updateTask: Promise<any>;

  /**
   * The next update request to be processed.
   */
  private pendingUpdate: WorksheetUpdateRequest;

  /**
   * The subscriptions for this component, which should be unsubscribed when the component is destroyed.
   */
  private subscriptions: Subscription = new Subscription();

  /**
   * The authentication user data.
   */
  private readonly userData: UserData;

  /**
   * Creates an instance of EditWorksheetComponent.
   * @param worksheetStub The API endpoint for worksheets.
   * @param worksheetUnderlyingDataFactory The service for getting underlying worksheet data (e.g. resolved references).
   * @param worksheetViewModelFactory The factory for creating worksheet view models.
   * @param dock The service for docking worksheets.
   * @param router The router.
   * @param loadingDialog The loading dialog service.
   * @param configUpdatedEventService The service for handling updated document events.
   * @param authenticationService The authentication service.
   * @param worksheetLabelsEditorDialog The dialog for editing worksheet labels.
   * @param json The JSON utilities service.
   * @param activeWorksheets The service for tracking active worksheets.
   * @param editDocumentMetadataDialog The dialog for editing document metadata.
   * @param getSimVersion The service for getting the current simulation version.
   * @param unitsManager The units manager service.
   * @param getFriendlyErrorAndLog The service for logging errors and getting user displayable error messages.
   */
  constructor(
    private readonly worksheetStub: WorksheetStub,
    private readonly worksheetUnderlyingDataFactory: WorksheetUnderlyingDataFactory,
    private readonly worksheetViewModelFactory: WorksheetViewModelFactory,
    private readonly dock: WorksheetDock,
    private readonly router: Router,
    private readonly loadingDialog: LoadingDialog,
    private readonly configUpdatedEventService: DocumentUpdatedEventService,
    private readonly authenticationService: AuthenticationService,
    private readonly worksheetLabelsEditorDialog: WorksheetLabelsEditorDialog,
    private readonly json: CanopyJson,
    private readonly activeWorksheets: ActiveWorksheets,
    private readonly editDocumentMetadataDialog: EditDocumentMetadataDialog,
    private readonly getSimVersion: GetSimVersion,
    private readonly unitsManager: UnitsManager,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {

    this.userData = this.authenticationService.userDataSnapshot;
  }

  /**
   * The ID of the container element for the worksheet.
   */
  public get containerElementId(): string {
    if(this.worksheetViewModel){
      return cssSanitize(this.worksheetViewModel.name) + '-worksheet';
    }

    return cssSanitize(this.worksheetId) + '-worksheet';
  }

  /**
   * Initializes the component.
   */
  ngOnInit() {
    this.load();
  }

  /**
   * Cleans up the component.
   */
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();

    // This could be undefined if the worksheet was not loaded due to it being already open in the dock.
    if(this.worksheetViewModel){
      this.activeWorksheets.remove(this.worksheetViewModel);
    }
  }

  /**
   * Indicates if the worksheet is loading.
   */
  public get isLoading(): boolean {
    return (!this.worksheetViewModel && !this.errorMessage) || !!this.updateTask;
  }

  /**
   * Indicates if the user is the owner of the worksheet.
   */
  public get isOwner(): boolean {
    return !!this.worksheetViewModel && this.userData.sub === this.worksheetViewModel.user.userId;
  }

  /**
   * Indicates if the worksheet is docked at the bottom of the window.
   */
  public get isWorksheetInDock(): boolean {
    return this.dock.worksheet
      && this.dock.worksheet.tenantId === this.tenantId
      && this.dock.worksheet.worksheetId === this.worksheetId;
  }

  /**
   * Loads the worksheet.
   */
  public async load() {
    try {
      this.errorMessage = undefined;

      // We're going to display a lot of units, so ensure they are loaded
      // in advance for efficiency.
      await this.unitsManager.ensureInitialized();

      if(!this.worksheetId){
        throw new DisplayableError('Worksheet ID not provided.');
      }

      // If the worksheet is already open in the dock, navigate to a page which
      // prompts the user to open the worksheet.
      if(!this.isInstanceInDock && this.isWorksheetInDock){
        this.router.navigate(['/worksheets', 'docked']);
        return;
      }

      // If we're moving the worksheet from the dock to the main view, we can
      // transfer the view model over to keep the state.
      const transferringViewModel = this.dock.popTransferringViewModel();
      if(transferringViewModel
        && transferringViewModel.tenant.tenantId === this.tenantId
        && transferringViewModel.worksheetId === this.worksheetId){
        this.worksheetViewModel = transferringViewModel;
        this.worksheetViewModel.updateParent(this);
      } else{
        // Otherwise load the worksheet from the server.
        await this.loadFromServer();
      }

      // Subscribe to relevant events.
      this.subscriptions.add(this.configUpdatedEventService.documentUpdated.subscribe((e) => this.handleConfigChangedEvent(e)));
      this.subscriptions.add(this.getSimVersion.changed.subscribe(() => this.handleSimVersionChangedEvent()));

      // If we're docked, subscribe to the user requesting to undock the worksheet.
      if (this.isInstanceInDock) {
        this.subscriptions.add(this.dock.undockRequested.subscribe(() => this.undockWorksheet(true)));
      }

      // Add the worksheet to the list of active worksheets.
      this.activeWorksheets.add(this.worksheetViewModel);
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  /**
   * Opens the edit labels dialog for the worksheet and handles the result.
   */
  public async editLabels() {
    try {
      this.errorMessage = undefined;
      const outline = this.worksheetViewModel.getOutline();
      const labelDefinitions = this.json.clone(outline.labelDefinitions);
      const result = await this.worksheetLabelsEditorDialog.show(this.isOwner, labelDefinitions);
      await this.processEditLabelsResult(result);
    } catch(error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  /**
   * Opens the edit metadata dialog for the worksheet and handles the result.
   */
  public async editMetadata() {
    try {
      this.errorMessage = undefined;
      const result = await this.editDocumentMetadataDialog.show(
        this.tenantId,
        DocumentSubType.worksheet,
        new DocumentMetadata(this.worksheetViewModel.name, this.worksheetViewModel.properties, this.worksheetViewModel.notes),
        false);

      if(result && result.documentMetadata){
        this.worksheetViewModel.name = result.documentMetadata.name;
        this.worksheetViewModel.properties = [...result.documentMetadata.customProperties];
        this.worksheetViewModel.notes = result.documentMetadata.notes;
        await this.update(false, false);
      }
    } catch(error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  /**
   * Indicates if the worksheet belongs to the current tenant.
   */
  public get isCurrentTenant(): boolean {
    return this.userData.tenant === this.tenantId;
  }

  /**
   * Indicates if the worksheet has custom properties.
   */
  public get hasProperties(): boolean {
    return !!(this.worksheetViewModel.properties && this.worksheetViewModel.properties.length);
  }

  /**
   * Indicates if the worksheet has notes.
   */
  public get hasNotes(): boolean {
    return !!this.worksheetViewModel.notes;
  }

  /**
   * Handle the result of the user editing worksheet labels.
   * @param result The result of the user editing worksheet labels.
   */
  private async processEditLabelsResult(result: WorksheetLabelsEditorResult){
    if(result && result.refreshRequired){
      if(result.newWorksheetLabels){
        this.worksheetViewModel.worksheetLabelDefinitions = result.newWorksheetLabels;
      }
      if(this.isOwner){
        await this.update(true, false);
      } else{
        this.reload();
      }
    }
  }

  /**
   * Handles the user's current simulation version changing.
   */
  private async handleSimVersionChangedEvent(){
    if(this.isOwner){
      await this.update(true, false);
    } else{
      this.reload();
    }
  }

  /**
   * Loads the worksheet from the server.
   */
  public async loadFromServer() {
    const worksheetResult = await this.worksheetStub.getWorksheet(this.tenantId, this.worksheetId);
    await this.loadFrom(worksheetResult, true);
  }

  /**
   * Waits for the current update, if any, to complete.
   */
  public waitForUpdate() {
    if(this.updateTask){
      return this.updateTask;
    }

    return Promise.resolve();
  }

  /**
   * Reloads the worksheet from the server. Should only be used if the current user isn't the owner, otherwise
   * it does nothing.
   */
  public async reload() {
    if(this.isOwner) {
      // If we own the worksheet we should use update() to reload the worksheet.
      return;
    }

    try {
      this.errorMessage = undefined;
      const task = this.worksheetStub.getWorksheet(this.tenantId, this.worksheetId);
      this.updateTask = task;
      const worksheetResult = await task;
      this.worksheetViewModel = undefined;
      await this.loadFrom(worksheetResult, true);
    } catch(error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    } finally {
      this.updateTask = undefined;
    }
  }

  /**
   * Updates the worksheet by sending the latest config to the API and rendering the result.
   * @param generateColumns Indicates if columns should be regenerated.
   * @param updateHistory Indicates if the history (undo stack) should have the current outline added to it.
   */
  public async update(generateColumns: boolean, updateHistory: boolean) {
    try {
      await this.updateInner(generateColumns, updateHistory);
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  /**
   * Undoes the last action.
   */
  public async undo() {
    try {
      const newRows = this.worksheetViewModel.history.undo();
      await this.setFromHistory(newRows);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  /**
   * Redoes the last undone action.
   */
  public async redo() {
    try {
      const newRows = this.worksheetViewModel.history.redo();
      await this.setFromHistory(newRows);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  /**
   * Gets the string representation of the number of items in the undo buffer.
   */
  public get undoBufferSizeString(): string {
    return this.getBufferSizeString(this.worksheetViewModel.history.undoBufferSize);
  }

  /**
   * Gets the string representation of the number of items in the redo buffer.
   */
  public get redoBufferSizeString(): string {
    return this.getBufferSizeString(this.worksheetViewModel.history.redoBufferSize);
  }

  /**
   * Gets the string representation of the number of items in a buffer.
   * @param size The size of the buffer.
   * @returns The string representation of the buffer size.
   */
  private getBufferSizeString(size: number): string {
    return size ? '(' + size + ')' : '';
  }

  /**
   * Overwrites the worksheet's rows with the provided rows from the undo/redo buffer.
   * @param newRows The new rows to apply.
   */
  private async setFromHistory(newRows: ReadonlyArray<WorksheetRow>) {
    if(!newRows){
      return;
    }

    const newOutline: WorksheetOutline = {
      rows: [...newRows],
      labelDefinitions: this.worksheetViewModel.worksheetLabelDefinitions
    };

    this.worksheetViewModel.applyRows(newRows);

    // Generate columns is true as we could restore a study which adds simulation columns.
    this.worksheetViewModel.generateColumns();
    await this.updateInner(true, false, newOutline);
  }

  /**
   * Updates the worksheet by sending the latest config to the API and rendering the result.
   * This private version of the `update` method also supports passing in a new outline.
   * @param generateColumns Indicates if columns should be regenerated.
   * @param updateHistory Indicates if the history (undo stack) should have the current outline added to it.
   * @param newOutline The new outline to use for the update.
   */
  private async updateInner(generateColumns: boolean, updateHistory: boolean, newOutline?: WorksheetOutline) {
    this.errorMessage = undefined;
    if(!this.isOwner) {
      await this.reload();
      return;
      // throw new DisplayableError('You are not the owner of this worksheet.');
    }

    if(this.pendingUpdate && this.pendingUpdate.generateColumns){
      generateColumns = true;
    }

    if(newOutline){
      // If we're supplying a new outline, it is likely because we're undoing/redoing.
      updateHistory = false;
    } else {
      newOutline = this.worksheetViewModel.getOutline();
    }

    if(updateHistory){
      this.worksheetViewModel.history.add(newOutline.rows);
    }

    this.pendingUpdate = new WorksheetUpdateRequest(
      generateColumns,
      this.worksheetViewModel.name,
      newOutline,
      this.worksheetViewModel.properties,
      this.worksheetViewModel.notes);

    if(!this.updateTask){
      try {
        this.updateTask = this.performUpdateLoop();
        await this.updateTask;
      } finally{
        this.updateTask = undefined;
      }
    }
  }

  /**
   * Performs updates one after another until there is no update pending.
   */
  private async performUpdateLoop(){
    while(this.pendingUpdate){
      try {
        const nextUpdate = this.pendingUpdate;
        this.pendingUpdate = undefined;
        await this.updateFromRequest(nextUpdate);
      } catch(error){
        this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
      }
    }
  }

  /**
   * Updates the worksheet by posting the new data to the API and applying the result.
   * @param request The update request.
   */
  public async updateFromRequest(request: WorksheetUpdateRequest) {
    const worksheetResult = await this.worksheetStub.putWorksheet(
      this.tenantId,
      this.worksheetId,
      {
        name: request.name,
        properties: request.properties,
        notes: request.notes,
        outline: request.outline
      });
    await this.loadFrom(worksheetResult, request.generateColumns);
  }

  /**
   * Docks the worksheet to the bottom of the page.
   */
  public async dockWorksheet() {
    try {
      await this.waitForUpdateWithSpinner();
    } catch(error){
      return;
    }

    this.dock.setTransferringViewModel(this.worksheetViewModel);
    await this.dock.setWorksheet(new DockedWorksheet(this.tenantId, this.worksheetId));
    this.router.navigate(['/worksheets']);
  }

  /**
   * Undocks the worksheet from the bottom of the page.
   * @param navigateToWorksheet Indicates if the user should be navigated to the worksheet.
   */
  public async undockWorksheet(navigateToWorksheet: boolean) {
    try {
      await this.waitForUpdateWithSpinner();
    } catch(error){
      return;
    }

    this.dock.setTransferringViewModel(this.worksheetViewModel);
    await this.dock.setWorksheet(undefined);

    if(navigateToWorksheet) {
      this.router.navigate(['/worksheets', this.tenantId, this.worksheetId]);
    }
  }

  /**
   * Handles an even indicating a config has changed, by updating the worksheet
   * if the config exists in the worksheet.
   * @param event The event indicating a config has changed.
   */
  private async handleConfigChangedEvent(event: UpdatedDocument) {
    try {
      if(!this.worksheetViewModel){
        return;
      }

      if(this.worksheetViewModel.rows.some(
        row =>
          (
            row.study.populated
            && row.study.populated.reference
            && row.study.populated.reference.tenantId === event.tenantId
            && row.study.populated.reference.targetId === event.documentId
          )
          ||
          row.configs.some(
            config => config.populated
            && config.populated.reference
            && config.populated.reference.tenant
            && config.populated.reference.tenant.tenantId === event.tenantId
            && config.populated.reference.tenant.targetId === event.documentId))){
        await this.update(false, false);
      }
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  /**
   * Waits for the worksheet to update, showing a spinner
   * while waiting.
   * @returns A promise that resolves when the worksheet has updated.
   */
  private waitForUpdateWithSpinner(): Promise<void> {
    return this.loadingDialog.show((v) => {
      v.setStatus('Waiting for worksheet sync...');
      return this.waitForUpdate();
    });
  }

  /**
   * Loads the worksheet from the result of the API call and updates the view model.
   * @param worksheetResult The result of the get worksheet query.
   * @param generateColumns Indicates if columns should be generated.
   */
  private async loadFrom(worksheetResult: GetWorksheetQueryResult, generateColumns: boolean){
    const underlyingData = await this.worksheetUnderlyingDataFactory.create(worksheetResult);
    const defaultStudyType = underlyingData.studyTypesList[0];

    if(this.worksheetViewModel){
      this.worksheetViewModel.update(underlyingData);
    } else{
      this.worksheetViewModel = this.worksheetViewModelFactory.create(this, underlyingData, defaultStudyType.studyType);
      this.worksheetViewModel.history.add(underlyingData.worksheetResult.worksheet.outline.rows);
    }

    if (generateColumns) {
      this.worksheetViewModel.generateColumns();
    }
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {GetFriendlyErrorAndLog} from '../../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {VisualizationFactory} from '../../../visualizations/visualization-factory.service';
import {Timer} from '../../../../common/timer.service';
import {ConfigPreviewComponentBase} from '../../config-preview-component-base';
import {ConfigOrConfigLoader} from '../config-or-config-loader';

@Component({
  selector: 'cs-compare-config-visualizations',
  templateUrl: './compare-config-visualizations.component.html',
  styleUrls: ['./compare-config-visualizations.component.scss']
})
export class CompareConfigVisualizationsComponent extends ConfigPreviewComponentBase implements OnInit {

  @Input() configs: ConfigOrConfigLoader[];
  @Input() simVersion: string;
  @Input() units: { [key: string]: string };

  public errorMessage: string;

  constructor(
    visualizationFactory: VisualizationFactory,
    timer: Timer,
    getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
    super(visualizationFactory, timer, getFriendlyErrorAndLog);
  }

  ngOnInit() {
    this.load();
  }

  public async load(){
    try {
      this.errorMessage = undefined;
      this.configPreviewErrorMessage = undefined;

      await this.timer.yield();
      await this.loadConfigPreviewsIfRequired(this.configs.map(v => v.materialized), this.units);
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }
}

import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { EncryptionKeyPermission } from '../../../../generated/api-stubs';
import { ClearKeyPermission, DeleteKeyPermission, FetchKeyPermissions, PutKeyPermission, SetKeyPermission } from '../state/key-permissions.actions';
import { KeyPermissionsSelectors } from '../state/key-permissions.selectors';

@Injectable()
export class KeyPermissionsService {

  public get permissions(): Observable<EncryptionKeyPermission[]> {
    return this._store.select(KeyPermissionsSelectors.permissions);
  }

  public get permissionsSnapshot(): EncryptionKeyPermission[] {
    return this._store.selectSnapshot(KeyPermissionsSelectors.permissions);
  }

  public get selectedPermission(): Observable<EncryptionKeyPermission> {
    return this._store.select(KeyPermissionsSelectors.selectedPermission);
  }

  public get selectedPermissionSnapshot(): EncryptionKeyPermission {
    return this._store.selectSnapshot(KeyPermissionsSelectors.selectedPermission);
  }

  constructor(private _store: Store) {
  }

  public fetchKeyPermissions(tenantId: string): Observable<any> {
    return this._store.dispatch(new FetchKeyPermissions(tenantId));
  }

  public setKeyPermission(encryptingTenantShortName: string, minimumSimVersion: string, description: string): Observable<any> {
    return this._store.dispatch(new SetKeyPermission(
      encryptingTenantShortName,
      minimumSimVersion,
      description
    ));
  }

  public putKeyPermission(tenantId: string, encryptingTenantShortName: string, minimumSimVersion: string, description: string): Observable<any> {
    return this._store.dispatch(new PutKeyPermission(
      tenantId,
      encryptingTenantShortName,
      minimumSimVersion,
      description
    ));
  }

  public deleteKeyPermission(tenantId: string, encryptingTenantShortName: string): Observable<any> {
    return this._store.dispatch(new DeleteKeyPermission(tenantId, encryptingTenantShortName));
  }

  public clearKeyPermission(): Observable<any> {
    return this._store.dispatch(new ClearKeyPermission());
  }

}

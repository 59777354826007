import {Injectable} from '@angular/core';

let rison = require('../../rison.js');

@Injectable()
export class Rison {
  constructor(){
    rison.uri_ok = {  // ok in url paths and in form query args
      '~': true,  '!': true,  '*': true,  '(': false,  ')': false,
      '-': true,  _: true,  '.': true,  ',': true,
      ':': false,  '@': true,  $: true,
      '\'': true,  '/': true
    };
  }

  public encode(value: any): string {
    return this.customEncode(rison.encode(value));
  }
  public encodeObject(value: any): string {
    return this.customEncode(rison.encode_object(value));
  }
  public encodeArray(value: any): string {
    return this.customEncode(rison.encode_array(value));
  }
  public decode(value: string): any {
    return rison.decode(this.customDecode(value));
  }
  public decodeObject(value: string): any {
    return rison.decode_object(this.customDecode(value));
  }
  public decodeArray(value: string): any {
    return rison.decode_array(this.customDecode(value));
  }

  private customDecode(input: string){
    return input.replace(/%25/g, '%');
    //return input.replace(/%3A/g, ':').replace(/%28/g, '(').replace(/%29/g, ')');
    //return decodeURIComponent(input);
  }
  private customEncode(input: string){
    return input.replace(/%/g, '%25');
    //return input.replace(/:/g, '%3A').replace(/\(/g, '%28').replace(/\)/g, '%29');
    //return encodeURIComponent(input).replace(/\(/g, '%28').replace(/\)/g, '%29');
  }
}

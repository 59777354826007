@if (viewModel.populated) {
  <i class="fa fa-check simulation-exists-icon"></i>
  <div class="cell-content">
    <cs-worksheet-labels [labels]="viewModel.populated.resolvedLabels"></cs-worksheet-labels>
  </div>
} @else {
}


<cs-worksheet-item-error-message [errorMessage]="errorMessage"></cs-worksheet-item-error-message>

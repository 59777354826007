/**
 * Represents an immutable minimum and maximum value.
 */
export interface IMinMax {
  readonly minimum: number;
  readonly maximum: number;
}

/**
 * An immutable minimum and maximum value.
 */
export class MinMax {
  constructor(
    public readonly minimum: number,
    public readonly maximum: number) {

    // Swap them if they are in the wrong order.
    if (minimum > maximum) {
      this.minimum = maximum;
      this.maximum = minimum;
    }
  }
}

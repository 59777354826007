import { ExplorationSweep } from './exploration-sweep';

/**
 * Represents the inputs to an exploration.
 */
export class ExplorationInputs {
  /**
   * Gets whether all the sweeps are monotonic.
   */
  public readonly areSweepsMonotonic: boolean;

  /**
   * Creates an instance of ExplorationInputs.
   * @param sweeps The sweeps of the exploration.
   */
  constructor(
    public readonly sweeps: ReadonlyArray<ExplorationSweep>) {
    this.areSweepsMonotonic = sweeps.every(v => v.areSubSweepsMonotonic);
  }
}



import {Injectable} from '@angular/core';
import { AuthenticationService } from '../state/authentication.service';

export enum RoleCheckType {
  atLeastOne,
  all,
  none,
}

export enum AuthorizationResult {
  notAuthorized,
  loginRequired,
  authorized,
}

@Injectable()
export class Authorize {
  constructor(private authenticationService: AuthenticationService) {
  }

  public execute(
    loginRequired: boolean,
    requiredRoles: ReadonlyArray<string> | undefined,
    roleCheckType?: RoleCheckType): AuthorizationResult {

    let result = AuthorizationResult.authorized;
    roleCheckType = roleCheckType || RoleCheckType.atLeastOne;

    if (loginRequired && !this.authenticationService.isAuthenticatedSnapshot) {
      result = AuthorizationResult.loginRequired;
    } else if ((loginRequired && this.authenticationService.isAuthenticatedSnapshot) && (requiredRoles === undefined || requiredRoles.length === 0)) {
      // Login is required but no specific roles are specified.
      result = AuthorizationResult.authorized;
    } else if (requiredRoles) {
      const userData = this.authenticationService.userDataSnapshot;
      const loweredRoles: string[] = userData.roles ? userData.roles.map(v => v.toLowerCase()) : [];
      let hasRole = true;
      for (let role of requiredRoles.map(v => v.toLowerCase())) {

        if (roleCheckType === RoleCheckType.all) {
          hasRole = hasRole && loweredRoles.indexOf(role) > -1;
          // if all of the roles are required and hasRole is false there is no point carrying on
          if (hasRole === false) {
            break;
          }
        } else if (roleCheckType === RoleCheckType.atLeastOne || roleCheckType === RoleCheckType.none) {
          hasRole = loweredRoles.indexOf(role) > -1;
          // if we only need one of the roles, or we should have any, and we have it there is no point carrying on
          if (hasRole) {
            break;
          }
        }
      }

      if (roleCheckType === RoleCheckType.none) {
        result = hasRole ?
          AuthorizationResult.notAuthorized :
          AuthorizationResult.authorized;
      } else {
        result = hasRole ?
          AuthorizationResult.authorized :
          AuthorizationResult.notAuthorized;
      }
    }

    return result;
  }
}

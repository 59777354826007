
<div class="chart-help-area">
  <div class="clearfix">
    <button class="btn btn-smart btn-secondary toggle-button pull-sm-right" (click)="toggleHelp()">
      <i class="fa fa-question-circle"></i>Chart Help
    </button>
  </div>
  <div class="help-content" [ngClass]="{'show-help': showHelp}">
    <div class="card card-body">
      <ul class="last-item">
        <li>Press 'x', 'y' or 'z' to change between X, Y or XY zooming.</li>
        <li>Hold 'x', 'y' or 'z' and scroll with mouse/trackpad to zoom in and out.</li>
        <li>Click-and-drag to pan after zooming.</li>
        <li>Double-click to reset zoom.</li>
        <li>Click on x-axis label to toggle x-domains.</li>
        <li>Right-click to start an X, Y or XY area selection.</li>
        <li>While a selection exists, legend values can optionally display mean, min or max value calculations.</li>
        <li>Press 'm' to toggle y-domain calculation mode between None, Mean, Min and Max</li>
        <li>While a calculation is enabled, the x-domain values show the difference over the highlighted range.</li>
        <li>Toggling calculation mode with no selection will briefly display calculation for all data.</li>
        <li>Right-click or press escape to cancel area selection.
        <li>Left click to zoom to selection.</li>
        <li>Click on any legend colour square to toggle channel visibility.</li>
        <li>Click on any legend units square to edit channel units.</li>
        <li>Click and drag between sub-plots or sub-plot axis to resize.</li>
        <li>See <a [href]="CHART_SHORTCUT_KEYS_URL" target="_blank">the help page</a> for more details.</li>
      </ul>
    </div>
  </div>
</div>

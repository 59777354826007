
@if (!showAsCard) {
  @if (isSupportedStudyType) {
    <span class="button-menu-hover-target">
      <button id="save-racing-line-hover-button" class="btn btn-smart btn-secondary">
        <i class="fa fa-flag-checkered"></i>Save Racing Line
      </button>
      <span class="button-menu-hovering-area">
        <button id="save-racing-line-button" class="btn btn-smart btn-secondary"
          [disabled]="!canSaveToSource"
          (click)="saveRacingLineToSourceTrack()">
          <i class="fa fa-flag-checkered"></i>Save Racing Line to Source Track
        </button>
        <button id="save-racing-line-as-button" class="btn btn-smart btn-secondary"
          [disabled]="!canSave"
          (click)="saveRacingLineToNewTrack()">
          <i class="fa fa-flag-o"></i>Save Racing Line as New Track
        </button>
        <button id="stage-racing-line-button" class="btn btn-smart btn-secondary"
          [disabled]="!canSave"
          (click)="stageRacingLine()">
          <i class="fa fa-arrow-circle-up"></i>Stage
        </button>
        <button id="download-deprecated-button" class="btn btn-smart btn-secondary"
          [disabled]="!canSave"
          (click)="downloadDeprecated()">
          <i class="fa fa-download"></i>(Deprecated) Download with Centre Line
        </button>
      </span>
    </span>
  }
}

@if (showAsCard) {
  @if (isSupportedStudyType) {
    <div class="card">
      <h1 class="card-header">
        Apply Racing Line
      </h1>
      <div class="card-body">
        <table class="table last-item">
          <thead>
            <tr>
              <th>Type</th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="config-type-name">Track</td>
              <td>
                <button id="stage-racing-line-button" class="btn btn-smart btn-secondary"
                  [disabled]="!canSave"
                  (click)="stageRacingLine()">
                  <i class="fa fa-arrow-circle-up"></i>Stage
                </button>
              </td>
              <td>
                <button id="save-racing-line-button" class="btn btn-smart btn-secondary"
                  [disabled]="!canSaveToSource"
                  (click)="saveRacingLineToSourceTrack()">
                  <i class="fa fa-flag-checkered"></i>Save to Source Config
                </button>
              </td>
              <td>
                <button id="save-racing-line-as-button" class="btn btn-smart btn-secondary"
                  [disabled]="!canSave"
                  (click)="saveRacingLineToNewTrack()">
                  <i class="fa fa-flag-o"></i>Save as New Config
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  }
}

<cs-error-message [message]="errorMessage"></cs-error-message>

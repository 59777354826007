import { Injectable } from '@angular/core';
import { CanopyHttp } from './canopy-http.service';
import { FixQueryStringUrl } from '../stubs/fix-query-string-url.service';
import { GetHttpError } from './errors/services/get-http-error/get-http-error.service';
import { IDENTITY_BASE_URL } from '../../generated/api-base-url';

@Injectable({
  providedIn: 'root'
})
export class IdentityApi {

  constructor(
    private http: CanopyHttp,
    private getHttpError: GetHttpError,
    private fixUrl: FixQueryStringUrl) { }

  public async deleteSessions(tenantId: string, userId: string): Promise<void> {
    try {
      await this.http.delete(this.fixUrl.execute(IDENTITY_BASE_URL + 'sessions/' + encodeURIComponent(tenantId) + '/' + encodeURIComponent(userId)));
    } catch (response) {
      throw this.getHttpError.execute(response);
    }
  }

}

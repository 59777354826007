import { NgZone } from '@angular/core';
import { NavigationStation } from '../../visualizations/navigation-station/navigation-station';
import {
  IEditChannelsOptions, LoadViewerLayoutResult,
  PaneChannelLayout,
  RequestedLayoutIds,
  ResolvedLayoutId,
  SiteHooks,
  ViewerMetadata
} from '../../visualizations/site-hooks';
import {
  ChannelBinaryFormat,
  ChannelMetadata,
  SingleScalarResult,
  StudyScalarResults,
  UrlFileLoader,
  StudyMetadata
} from '../../visualizations/url-file-loader';
import { Subject } from 'rxjs';
import { ChannelNameStyle } from '../../visualizations/viewers/channel-data-loaders/channel-name-style';
import { SimType } from '../../../generated/api-stubs';
import { ExplorationMap } from '../../visualizations/viewers/channel-data-loaders/exploration-map';

export class OutOfZoneNavigationStation {
  constructor(
    private readonly zone: NgZone,
    private readonly inner: NavigationStation) {
  }

  public build(): Promise<void> {
    return this.zone.runOutsideAngular(() => this.inner.build());
  }

  public redraw(): Promise<void> {
    return this.zone.runOutsideAngular(() => this.inner.redraw());
  }

  public dispose() {
    this.inner.dispose();
  }
}

export class OutOfZoneSiteHooks implements SiteHooks {

  constructor(
    private readonly zone: NgZone,
    private readonly inner: SiteHooks) {
  }

  public get unitsChangedEvent(): Subject<any> {
    return this.inner.unitsChangedEvent;
  }

  canSaveViewerLayout(viewerType: string, layoutId: ResolvedLayoutId): Promise<boolean> {
    return this.zone.run(() => this.inner.canSaveViewerLayout(viewerType, layoutId));
  }

  dispose(): void {
    this.inner.dispose();
  }

  editChannelUnits(channelName: string, currentUnits: string): Promise<void> {
    return this.zone.run(() => this.inner.editChannelUnits(channelName, currentUnits));
  }

  editViewerChannels(options: IEditChannelsOptions): Promise<PaneChannelLayout | undefined> {
    return this.zone.run(() => this.inner.editViewerChannels(options));
  }

  editViewerDeprecated(viewerType: string, layoutConfig: any, studyId: string, jobIndex: number, simTypes: ReadonlyArray<string>, channelNameStyle: ChannelNameStyle): Promise<any> {
    return this.zone.run(() => this.inner.editViewerDeprecated(viewerType, layoutConfig, studyId, jobIndex, simTypes, channelNameStyle));
  }

  ensureUnitsLoaded(): Promise<void> {
    return this.zone.run(() => this.inner.ensureUnitsLoaded());
  }

  getDefaultChannelUnits(channelName: string): Promise<string> {
    return this.zone.run(() => this.inner.getDefaultChannelUnits(channelName));
  }

  getFriendlyErrorAndLog(error: any): string {
    return this.inner.getFriendlyErrorAndLog(error);
  }

  getStudyJobName(studyId: string, jobIndex: number): Promise<string> {
    return this.zone.run(() => this.inner.getStudyJobName(studyId, jobIndex));
  }

  getStudyName(studyId: string): Promise<string> {
    return this.zone.run(() => this.inner.getStudyName(studyId));
  }

  getUserChannelUnits(channelName: string): Promise<string | undefined> {
    return this.zone.run(() => this.inner.getUserChannelUnits(channelName));
  }

  getUserChannelUnitsSynchronous(channelName: string): string | undefined {
    return this.zone.run(() => this.inner.getUserChannelUnitsSynchronous(channelName));
  }

  getViewerDefaultLayoutId(viewerType: string, layoutIds: RequestedLayoutIds): Promise<any> {
    return this.zone.run(() => this.inner.getViewerDefaultLayoutId(viewerType, layoutIds));
  }

  loadViewerLayout(viewerType: string, layoutId?: ResolvedLayoutId): Promise<LoadViewerLayoutResult> {
    return this.zone.run(() => this.inner.loadViewerLayout(viewerType, layoutId));
  }

  loadViewerMetadata(viewerType: string, layoutId?: ResolvedLayoutId): Promise<ViewerMetadata | undefined> {
    return this.zone.run(() => this.inner.loadViewerMetadata(viewerType, layoutId));
  }

  saveViewerLayout(viewerType: string, layoutId: ResolvedLayoutId, name: string, config: any): Promise<any> {
    return this.zone.run(() => this.inner.saveViewerLayout(viewerType, layoutId, name, config));
  }

  saveViewerMetadata(viewerType: string, layoutId: ResolvedLayoutId, viewerMetadata: ViewerMetadata): Promise<any> {
    return this.zone.run(() => this.inner.saveViewerMetadata(viewerType, layoutId, viewerMetadata));
  }

  saveViewerLayoutAs(viewerType: string, config: any): Promise<{ viewerId: any; name: string }> {
    return this.zone.run(() => this.inner.saveViewerLayoutAs(viewerType, config));
  }

  setViewerDefaultLayoutId(viewerType: string, layoutIds: RequestedLayoutIds, defaultLayoutId: ResolvedLayoutId): Promise<any> {
    return this.zone.run(() => this.inner.setViewerDefaultLayoutId(viewerType, layoutIds, defaultLayoutId));
  }

  showStudyJob(studyId: string, jobIndex: number, currentEvent: any): Promise<void> {
    return this.zone.run(() => this.inner.showStudyJob(studyId, jobIndex, currentEvent));
  }
}

export class OutOfZoneFileLoader implements UrlFileLoader {

  constructor(
    private readonly zone: NgZone,
    private readonly inner: UrlFileLoader) {
  }

  loadCarForStudy(studyId: string): Promise<any> {
    return this.zone.run(() => this.inner.loadCarForStudy(studyId));
  }

  loadCarForStudyJob(studyId: string, jobIndex: number): Promise<any> {
    return this.zone.run(() => this.inner.loadCarForStudyJob(studyId, jobIndex));
  }

  loadChannelData(studyId: string, jobIndex: number, simType: SimType, channelName: string, binaryFormat: ChannelBinaryFormat | undefined): Promise<ReadonlyArray<number>> {
    return this.zone.run(() => this.inner.loadChannelData(studyId, jobIndex, simType, channelName, binaryFormat));
  }

  loadChartLayout(layoutId: string): Promise<any> {
    return this.zone.run(() => this.inner.loadChartLayout(layoutId));
  }

  loadCsv(fileName: string): Promise<any> {
    return this.zone.run(() => this.inner.loadCsv(fileName));
  }

  loadExplorationMap(studyId: string): Promise<ExplorationMap> {
    return this.zone.run(() => this.inner.loadExplorationMap(studyId));
  }

  loadScalarResultsForSim(studyId: string, jobIndex: number, simType: SimType): Promise<SingleScalarResult[]> {
    return this.zone.run(() => this.inner.loadScalarResultsForSim(studyId, jobIndex, simType));
  }

  loadScalarResultsForStudy(studyId: string): Promise<StudyScalarResults> {
    return this.zone.run(() => this.inner.loadScalarResultsForStudy(studyId));
  }

  loadTrackForStudy(studyId: string): Promise<any> {
    return this.zone.run(() => this.inner.loadTrackForStudy(studyId));
  }

  loadTrackForStudyJob(studyId: string, jobIndex: number): Promise<any> {
    return this.zone.run(() => this.inner.loadTrackForStudyJob(studyId, jobIndex));
  }

  loadVectorMetadata(studyId: string, jobIndex: number, simType: SimType): Promise<ChannelMetadata[]> {
    return this.zone.run(() => this.inner.loadVectorMetadata(studyId, jobIndex, simType));
  }

  loadStudyMetadata(studyId: string): Promise<StudyMetadata> {
    return this.zone.run(() => this.inner.loadStudyMetadata(studyId));
  }
}


import {ColumnType} from './column-type';
import {DocumentSubType, SimType} from '../../generated/api-stubs';

/**
 * The view model for a column in the worksheet.
 */
export class ColumnViewModel {

  /**
   * The tracking id for the column.
   */
  public readonly trackingId: string;

  /**
   * Creates an instance of ColumnViewModel.
   * @param columnType The type of column.
   * @param id The id of the column.
   * @param name The name of the column.
   */
  constructor(
    public readonly columnType: ColumnType,
    public readonly id: DocumentSubType | SimType | undefined,
    public readonly name: string) {

    this.trackingId = `${id}-${name}`;
  }
}

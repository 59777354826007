import { ExplorationInputs } from './exploration-inputs';
import { ExplorationJob } from './exploration-job';
import { ExplorationJobSweep } from './exploration-job-sweep';
import { ProcessedExplorationMapValue } from './exploration-map-values';
import { ExplorationJobMetadata } from './get-exploration-jobs-metadata-from-exploration-map';


/**
 * Gets the exploration job from the exploration inputs and job metadata.
 */
export class GetExplorationJob {

  /**
   * Creates the exploration job structure from the job metadata and exploration inputs.
   * @param inputs The exploration inputs.
   * @param jobMetadata The job metadata.
   * @returns The exploration job.
   */
  public execute(inputs: ExplorationInputs, jobMetadata: ExplorationJobMetadata): ExplorationJob {

    const sweepCount = inputs.sweeps.length;

    if (jobMetadata.inputSweepIndices.length !== sweepCount) {
      throw new Error('List of sweep input indices was a different length to the list of sweeps.');
    }

    // Create the array to store the sweep values for this job.
    const jobSweeps: ExplorationJobSweep[] = Array(sweepCount);

    /// For each sweep...
    for (let sweepIndex = 0; sweepIndex < sweepCount; ++sweepIndex) {
      const sweep = inputs.sweeps[sweepIndex];

      // Get the index into the sweep values for the current job and sweep.
      const sweepInputIndex = jobMetadata.inputSweepIndices[sweepIndex];

      // Get the normalized index.
      const normalizedSweepInputIndex: number = isNaN(sweepInputIndex) ? NaN : sweep.normalizedIndices[sweepInputIndex];

      // Create the arrays to store the sub-sweep values for this sweep and job.
      const subValues: ProcessedExplorationMapValue[] = [];
      const numericSubValues: number[] = [];
      const normalizedSubValues: number[] = [];

      // For each sub-sweep...
      for (let subSweep of sweep.subSweeps) {

        if (isNaN(sweepInputIndex)) {
          // If the sweep index is NaN (which can happen, for example, if you're looking at a job in a star exploration
          // for a dimension which is not swept over in that job), then the sub-sweep value is also NaN.
          subValues.push(NaN);
          numericSubValues.push(NaN);
          normalizedSubValues.push(NaN);
        } else {
          // Otherwise append the sub-sweep value, numeric value and normalized value for the current job and sub-sweep.
          subValues.push(subSweep.values[sweepInputIndex]);
          numericSubValues.push(subSweep.numericValues[sweepInputIndex]);
          normalizedSubValues.push(subSweep.normalizedValues[sweepInputIndex]);
        }
      }

      // Create the exploration job sweep, which now contains the values, numeric values, normalized values,
      // indexes and normalized indexes for each sweep for that job.
      jobSweeps[sweepIndex] = new ExplorationJobSweep(
        subValues,
        numericSubValues,
        normalizedSubValues,
        sweepInputIndex,
        normalizedSweepInputIndex);
    }

    // Return the exploration job.
    return new ExplorationJob(
      jobMetadata.name,
      jobSweeps);
  }
}

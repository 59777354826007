import { SharedState } from '../shared-state';
import { Group, Mesh, MeshPhongMaterial, Raycaster, SphereGeometry } from 'three';
import * as d3 from '../../d3-bundle';
import { BaseType } from 'd3-selection';
import { LoadedConfig } from './load-configs-for-sources';
import { ChartSettings } from '../chart-settings';
import { Subscription } from 'rxjs';
import { SVGSelection } from '../../untyped-selection';

export class RenderedConfig {
  constructor(
    public readonly sourceIndex: number,
    public readonly group: Group) { }
}

/**
 * The base class for rendering configs, such as track or car, in 3D.
 */
export abstract class ConfigRendererBase {
  /**
   * The map of IDs to rendered configs.
   */
  protected readonly renderedConfigs: { [id: string]: RenderedConfig } = {};

  /**
   * The settings for the chart.
   */
  protected settings!: ChartSettings;

  /**
   * The group to add the rendered configs to.
   */
  protected group!: Group;

  /**
   * The SVG selection for the SVG overlay.
   */
  protected svg!: SVGSelection;

  /**
   * The configs to render.
   */
  protected configs!: ReadonlyArray<LoadedConfig>;

  /**
   * True if all configs should be updated in the next `update` call, even if an update
   * is not obviously required.
   */
  protected updateAll: boolean = false;

  /**
   * The list of subscriptions for us to dispose.
   */
  protected readonly subscriptions: Subscription = new Subscription();

  /**
   * Creates a new instance of ConfigRendererBase.
   * @param sharedState The shared state.
   */
  constructor(
    protected readonly sharedState: SharedState) {
  }

  /**
   * Disposes of the renderer.
   */
  public dispose() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Parent class' build method.
   */
  protected abstract performBuild(): void;

  /**
   * Parent class' update method.
   * @param mouseRaycaster The raycaster for the mouse.
   */
  protected abstract performUpdate(mouseRaycaster: Raycaster | undefined): void;

  /**
   * Renders a config.
   * @param sourceIndex The source index.
   * @param configData The config data.
   */
  protected abstract renderConfig(sourceIndex: number, configData: any): RenderedConfig;

  /**
   * Builds the renderer.
   * @param settings The settings for the chart.
   * @param svg The SVG selection for the SVG overlay.
   * @param configs The configs to render.
   * @returns The group containing the rendered configs.
   */
  public build(
    settings: ChartSettings,
    svg: d3.Selection<SVGElement, any, BaseType, any>,
    configs: ReadonlyArray<LoadedConfig>): Group {

    this.settings = settings;
    this.svg = svg;
    this.configs = configs;
    this.group = new Group();

    // This avoids crashing if group ends up empty.
    this.createDummyObject(this.group);

    this.performBuild();

    this.update(undefined);
    return this.group;
  }

  /**
   * Updates the scene if necessary.
   * @param mouseRaycaster The raycaster for the mouse.
   * @returns True if the scene was modified.
   */
  public update(mouseRaycaster: Raycaster | undefined): boolean {
    let sceneModified = false;

    // Track the IDs of the configs we have in the scene.
    let usedIds: { [id: string]: boolean } = {};

    // For each config...
    for (let sourceIndex = 0; sourceIndex < this.configs.length; ++sourceIndex) {
      let config = this.configs[sourceIndex];

      // If the config exists...
      if (config) {

        // Set the ID as used.
        usedIds[config.id] = true;
        let renderedConfig: RenderedConfig | undefined = this.renderedConfigs[config.id];

        // If config has been rendered before but either the source index doesn't match
        // the current index, or updateAll is true, remove the rendered config.
        if (renderedConfig && (renderedConfig.sourceIndex !== sourceIndex || this.updateAll)) {
          sceneModified = true;
          this.group.remove(renderedConfig.group);
          delete this.renderedConfigs[config.id];
          renderedConfig = undefined;
        }

        // If the config hasn't been rendered before, render it.
        if (!renderedConfig && config.data) {
          sceneModified = true;
          renderedConfig = this.renderedConfigs[config.id] = this.renderConfig(sourceIndex, config.data);
          if (renderedConfig.group.children.length) {
            this.group.add(renderedConfig.group);
          }
        }

        // If the config has been rendered, update its visibility.
        if (renderedConfig) {
          renderedConfig.group.visible = this.sharedState.sourceLoaderSet.sources[sourceIndex].isVisible;
        }
      }
    }

    // Remove any rendered configs that are no longer in the scene.
    for (let renderedConfigId in this.renderedConfigs) {
      if (!usedIds[renderedConfigId]) {
        sceneModified = true;
        let renderedConfig = this.renderedConfigs[renderedConfigId];
        delete this.renderedConfigs[renderedConfigId];
        this.group.remove(renderedConfig.group);
      }
    }

    if (sceneModified) {
      // Update render orders to ensure first configs are rendered on top.
      for (let sourceIndex = 0; sourceIndex < this.configs.length; ++sourceIndex) {
        let config = this.configs[sourceIndex];
        if (config) {
          let renderedConfig: RenderedConfig = this.renderedConfigs[config.id];
          if (renderedConfig) {
            renderedConfig.group.traverse(v => v.renderOrder -= (sourceIndex * 1000));
          }
        }
      }
    }

    // Call the super-class.
    this.performUpdate(mouseRaycaster);

    // Reset updateAll.
    this.updateAll = false;

    return sceneModified;
  }

  /**
   * Creates a dummy object to ensure the group is never empty.
   * @param group The group to add the dummy object to.
   * @returns The dummy object.
   */
  private createDummyObject(group: Group): Mesh {
    const radius = 1;
    let geometry = new SphereGeometry(radius, 10, 10);
    let material = new MeshPhongMaterial({ color: 0x888888, transparent: true, opacity: 0, flatShading: false, specular: 0x444444 });
    let marker = new Mesh(geometry, material);
    marker.userData.ignoreIntersection = true;
    group.add(marker);
    return marker;
  }

  /**
   * Returns a new group with only objects that are not ignored for intersection.
   * @param group The group to filter.
   * @returns The new group.
   */
  public groupWithoutIgnoredObjects(group: Group) {
    let result = new Group();
    result.add(...group.children.filter(v => !v.userData.ignoreIntersection).map(v => v.clone(true)));
    return result;
  }
}

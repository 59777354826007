@if (isVisible) {
  <div class="modal dialog-container" id="set-channel-units-dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title">Select {{dialog.channelName}} Units</h1>
          <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()">
          </button>
        </div>
        <div class="modal-body">
          <cs-error-message [message]="errorMessage"></cs-error-message>
          <cs-error-message [message]="session.errorMessage"></cs-error-message>
          @if (session.isSaving) {
            <cs-loader></cs-loader>
          }
          @if (!session.isSaving) {
            <div>
              <div class="units-options">
                @for (item of session.allUnits; track item; let i = $index) {
                  <button type="button" class="btn btn-secondary units-button-index-{{i}} units-button-{{cssSanitize(item)}}" (click)="accept(item)">
                    {{item === '()' ? '-' : item}}
                  </button>
                }
                <button type="button" class="btn btn-secondary units-button-default" (click)="accept(undefined)">
                  Use Default Units
                </button>
              </div>
            </div>
          }
        </div>
        @if (!session.isSaving) {
          <div class="modal-footer">
            <button id="dialog-dismiss-button" type="button" class="btn btn-secondary" (click)="dismiss()">Cancel</button>
          </div>
        }
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div>
  }<!-- /.modal -->

import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { EncryptionConfigPermission } from '../../../../generated/api-stubs';
import { ClearConfigPermission, DeleteConfigPermission, FetchConfigPermissions, PutConfigPermission, SetConfigPermission } from '../state/config-permissions.actions';
import { ConfigPermissionsSelectors } from '../state/config-permissions.selectors';

@Injectable()
export class ConfigPermissionsService {

  public get permissions(): Observable<EncryptionConfigPermission[]> {
    return this._store.select(ConfigPermissionsSelectors.permissions);
  }

  public get permissionsSnapshot(): EncryptionConfigPermission[] {
    return this._store.selectSnapshot(ConfigPermissionsSelectors.permissions);
  }

  public get selectedPermission(): Observable<EncryptionConfigPermission> {
    return this._store.select(ConfigPermissionsSelectors.selectedPermission);
  }

  public get selectedPermissionSnapshot(): EncryptionConfigPermission {
    return this._store.selectSnapshot(ConfigPermissionsSelectors.selectedPermission);
  }

  constructor(private _store: Store) {
  }

  public fetchConfigPermissions(tenantId: string): Observable<any> {
    return this._store.dispatch(new FetchConfigPermissions(tenantId));
  }

  public setConfigPermission(encryptingTenantShortName: string, decryptingTenantShortName: string, minimumSimVersion: string, description: string): Observable<any> {
    return this._store.dispatch(new SetConfigPermission(
      encryptingTenantShortName,
      decryptingTenantShortName,
      minimumSimVersion,
      description
    ));
  }

  public putConfigPermission(tenantId: string, encryptingTenantShortName: string, decryptingTenantShortName: string, minimumSimVersion: string, description: string): Observable<any> {
    return this._store.dispatch(new PutConfigPermission(
      tenantId,
      encryptingTenantShortName,
      decryptingTenantShortName,
      minimumSimVersion,
      description
    ));
  }

  public deleteConfigPermission(tenantId: string, encryptingTenantShortName: string, decryptingTenantShortName: string): Observable<any> {
    return this._store.dispatch(new DeleteConfigPermission(tenantId, encryptingTenantShortName, decryptingTenantShortName));
  }

  public clearConfigPermission(): Observable<any> {
    return this._store.dispatch(new ClearConfigPermission());
  }

}

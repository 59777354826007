import {Injectable} from '@angular/core';
import {TenancyStub} from '../../generated/api-stubs';

@Injectable()
export class GetUsernameMap {

  constructor(
    private tenancyStub: TenancyStub) {
  }

  public async execute(tenantId: string): Promise<UsernameMap>{
    let usersResult = await this.tenancyStub.getTenantUsers(tenantId);

    return usersResult.users.reduce((map, value) => {
      map[value.userId] = value.username;
      return map;
    }, <UsernameMap>{});
  }

  public async executeWithEmail(tenantId: string): Promise<UsernameMap>{
    let usersResult = await this.tenancyStub.getTenantUsers(tenantId);

    return usersResult.users.reduce((map, value) => {
      map[value.userId] = value.email + '/' + value.username;
      return map;
    }, <UsernameMap>{});
  }
}

export interface UsernameMap {
  [userId: string]: string;
}


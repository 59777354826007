import { ISourceLoader } from './source-loader';
import { IEditChannelsChannelMetadata } from '../../site-hooks';
import { ChannelNameStyle } from './channel-name-style';

/**
 * Utilities for source loaders.
 */
export class SourceLoaderUtilities {

  /**
   * Gets the distinct requestable channels from a set of loaders.
   * @param loaders The loaders.
   * @param channelNameStyle The style of the channel name to return.
   * @param xDomainName The name of the x domain.
   * @returns The distinct requestable channels.
   */
  public static async getDistinctRequestableChannels(loaders: ISourceLoader[], channelNameStyle: ChannelNameStyle, xDomainName: string): Promise<IEditChannelsChannelMetadata[]> {
    let result: IEditChannelsChannelMetadata[] = [];
    let existingNames = new Set<string>();
    for (let loader of loaders) {
      let channels = await loader.getRequestableChannels(channelNameStyle, xDomainName);
      for (let channel of channels) {
        if (!existingNames.has(channel.name)) {
          existingNames.add(channel.name);
          result.push(channel);
        }
      }
    }

    return result;
  }
}

import {
  ConfigReference,
  DocumentSubType,
} from '../../generated/api-stubs';
import {Injectable} from '@angular/core';
import {LoadConfigFromReference} from './load-config-from-reference';
import {
  CompareConfigDialog,
} from '../simulations/configs/comparing/compare-config-dialog/compare-config-dialog.service';
import {LoadingDialog} from '../common/dialogs/loading-dialog.service';
import {GetSimVersion} from '../common/get-sim-version.service';
import {SaveToConfigReferenceHandlerFactory} from '../simulations/configs/comparing/save-output-config-handlers/save-to-config-reference-handler';
import {ConfigLoaderDialog} from '../simulations/configs/config-loader-dialog/config-loader-dialog.service';
import {ConfigOrConfigLoader} from '../simulations/configs/comparing/config-or-config-loader';

/**
 * A service for comparing a worksheet config to something (the user is prompted to select
 * what the config should be compared to). The user can also compare the config to
 * nothing if they just want to view the config in the compare dialog.
 */
@Injectable()
export class CompareConfigToSomething {

  /**
   * Creates an instance of CompareConfigToSomething.
   * @param configLoaderDialog The dialog for loading a config.
   * @param loadingDialog The dialog for showing a loading spinner.
   * @param loadConfigFromReference The service for loading a config from a reference.
   * @param compareConfigDialog The dialog for comparing configs.
   * @param getSimVersion The service for getting the current sim version.
   * @param saveToConfigReferenceHandlerFactory The factory for creating a handler for saving to a config reference.
   */
  constructor(
    private readonly configLoaderDialog: ConfigLoaderDialog,
    private readonly loadingDialog: LoadingDialog,
    private readonly loadConfigFromReference: LoadConfigFromReference,
    private readonly compareConfigDialog: CompareConfigDialog,
    private readonly getSimVersion: GetSimVersion,
    private readonly saveToConfigReferenceHandlerFactory: SaveToConfigReferenceHandlerFactory) {
  }

  /**
   * Compares a worksheet config to something (the user is prompted to select
   * what the config should be compared to). The user can also compare the config to
   * nothing if they just want to view the config in the compare dialog.
   * @param configType The config type.
   * @param configReference The config reference.
   * @param referenceShortName The short name for the reference.
   */
  public async execute(configType: DocumentSubType, configReference: ConfigReference, referenceShortName?: string) {
    let simVersion = this.getSimVersion.currentSimVersion;

    // Prompt the user to load a config. Note that the user can select nothing here,
    // and that is different from cancelling.
    let selectedConfig = await this.configLoaderDialog.loadConfig(
      configType,
      simVersion,
      undefined,
      undefined,
      false,
      true);

    // If the user cancelled, return.
    if(!selectedConfig){
      return;
    }

    // Load the worksheet config.
    const config = await this.loadingDialog.showUntilFinished(
      this.loadConfigFromReference.execute(configType, configReference),
      'Loading config...');

    // Add the worksheet config to the compare list.
    let configs: ConfigOrConfigLoader[] = [
      new ConfigOrConfigLoader(referenceShortName || 'worksheet', config, undefined),
    ];

    // If the user selected a config (rather than explicitly selecting nothing), add it to the compare list.
    if(selectedConfig.configId){
      configs.push(new ConfigOrConfigLoader('selected', selectedConfig.config, undefined));
    }

    // Compare the config(s).
    await this.compareConfigDialog.compare(
      configType,
      configs,
      this.saveToConfigReferenceHandlerFactory.create(configReference));
  }
}

import * as d3 from './d3-bundle';
import { OrderedUniqueIndices } from './ordered-unique-indices';
import { ISize, Size } from './viewers/size';


class UtilitiesImplementation {

  /**
   * Create an identical object but with no reference to the original.
   * @param input The object to clone.
   * @param replacer The replacer function to use when stringifying the object.
   * @returns A deep clone of the input object.
   */
  public deepClone<T>(input: T, replacer?: (key: string, value: any) => any): T {
    if (input === null || input === undefined) {
      return input;
    }

    return JSON.parse(JSON.stringify(input, replacer));
  }

  /**
   * Calculate the standard deviation of an array of numbers.
   * @param values The array of numbers to calculate the standard deviation of.
   * @returns The standard deviation of the input array.
   */
  public standardDeviation(values: ReadonlyArray<number>): number {
    let avg = d3.mean(values) || 0;

    let squareDiffs = values.map(value => {
      let diff = value - avg;
      return diff * diff;
    });

    let avgSquareDiff = d3.mean(squareDiffs) || 0;

    return Math.sqrt(avgSquareDiff);
  }

  /**
   * Euclidean distance between two points.
   * @param x The first point.
   * @param y The second point.
   * @returns The Euclidean distance between the two points.
   */
  public point2pointDistance(x: number[], y: number[]): number {
    let c = 0;
    let d = x.length;
    for (let i = 0; i < d; i++) {
      c += (x[i] - y[i]) * (x[i] - y[i]);
    }
    return Math.sqrt(c);
  }

  /**
   * Multiply a matrix by a vector.
   * @param A The matrix to multiply.
   * @param x The vector to multiply.
   * @returns The result of the multiplication.
   */
  public matrixTimesVector(A: number[][], x: number[]): number[] {
    let b = new Array(x.length);
    for (let i = 0; i < A.length; i++) {
      b[i] = 0;
      for (let j = 0; j < x.length; j++) {
        b[i] += A[i][j] * x[j];
      }
    }
    return b;
  }

  /**
   * Calculate the difference between two numbers.
   * @param input The two numbers to calculate the difference of.
   * @returns The difference between the two numbers.
   */
  public diff(input: Readonly<[number, number]> | ReadonlyArray<number>): number {
    return input[1] - input[0];
  }

  /**
   * Nest a flat array by one level according to the given key.
   * @param data The data to nest.
   * @param key The key to nest by.
   * @returns The nested data.
   */
  public nest(data: any[], key: string) {
    return Array.from(d3.group(data, (e: any) => e[key]), ([_, values]) => values);
  }

  /**
   * Flatten a nested array.
   * @param data The data to flatten.
   * @returns The flattened data.
   */
  public flatten(data: Array<any> | any): any[] {
    if (!Array.isArray(data)) {
      // Nothing to flatten
      return data;
    }
    // flatten each element
    let result: any[] = [];
    for (let i = 0; i < data.length; i++) {
      result = result.concat(this.flatten(data[i]));
    }
    return result;
  }

  /**
   * Linearly space values between two end-points.
   * @param bounds The end-points of the range.
   * @param nPts The number of points to generate.
   * @returns The linearly spaced values.
   */
  public linspace(bounds: Readonly<[number, number]>, nPts: number): number[] {
    let res: number[] = [];
    let range = this.diff(bounds);
    nPts = Math.ceil(nPts);
    for (let i = 0; i < nPts; i++) {
      res.push(bounds[0] + range * i / (nPts - 1));
    }
    return res;
  }

  /**
   * Reduce an array to just the unique elements of that array, unsorted
   * @param input The array to reduce.
   * @param selector The selector function to use to determine uniqueness.
   * @returns The array with only unique elements.
   */
  public uniqueValue<TInput, T extends { toString(): string }>(input: ReadonlyArray<TInput>, selector: (item: TInput) => T): TInput[] {
    let valueSeen: { [key: string]: boolean } = {};
    let result = [];
    for (let value of input) {
      let valueKey = selector(value).toString();
      if (!valueSeen[valueKey]) {
        valueSeen[valueKey] = true;
        result.push(value);
      }
    }
    return result;
  }

  /**
   * Reduce an array to just the unique elements of that array, unsorted
   * @param input The array to reduce.
   * @returns The array with only unique elements.
   */
  public unique<T extends { toString(): string }>(input: ReadonlyArray<T>): T[] {
    return this.uniqueValue(input, v => v);
  }

  /**
   * Ensure an array contains at least N unique values.
   * @param input The array to check.
   * @param minimumUnique The minimum number of unique values to check for.
   * @returns True if the array contains at least N unique values, false otherwise.
   */
  public containsUnique<T extends { toString(): string }>(input: ReadonlyArray<T>, minimumUnique: number): boolean {
    let valueSeen: { [key: string]: boolean } = {};
    let result = [];
    for (let value of input) {
      let valueKey = value.toString();
      if (!valueSeen[valueKey]) {
        valueSeen[valueKey] = true;
        result.push(value);
        if (result.length >= minimumUnique) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Find the indices of the elements in the array that match the given condition.
   * @param items The array to search.
   * @param match The condition to match.
   * @returns The indices of the elements that match the condition.
   */
  public findIndices<T>(items: T[], match: (item: T) => boolean): OrderedUniqueIndices {
    return items.reduce(
      (p, c, i) => {
        if (match(c)) {
          p.add(i);
        }
        return p;
      },
      new OrderedUniqueIndices());
  }

  /**
   * Remove the elements at the supplied indices from the set of arrays.
   * @param indicesBuilder The indices of the elements to remove.
   * @param targetArrays The arrays to remove the elements from.
   */
  public strip(indicesBuilder: OrderedUniqueIndices, ...targetArrays: any[][]) {
    let indices = indicesBuilder.get();
    for (let i = indices.length - 1; i >= 0; i--) {
      let index = indices[i];
      for (let a of targetArrays) {
        a.splice(index, 1);
      }
    }
  }

  /**
   * Remove the elements at the supplied indices from the array.
   * @param arr The array to remove the elements from.
   * @param indices The indices of the elements to remove.
   * @returns The array with the elements removed.
   */
  public stripOriginal(arr: any[], indices: OrderedUniqueIndices) {
    let ind = indices.get();
    for (let i = ind.length - 1; i >= 0; i--) {
      arr.splice(ind[i], 1);
    }
    return arr;
  }

  /**
   * Strip non-essential parts from dimension labels.
   * @param dimName The dimension label to strip.
   * @returns The stripped dimension label.
   */
  public condenseDimension(dimName: string) {
    let indices = [];
    for (let i = 0; i < dimName.length; i++) {
      if (dimName[i] === '.') {
        indices.push(i);
      }
    }
    return dimName.slice(indices[1] + 1);
  }

  /**
   * Get the dimensions of the viewport in a cross-browser way.
   * @returns The dimensions of the viewport.
   */
  public getViewport(): [number, number] {

    let viewPortWidth;
    let viewPortHeight;

    if (typeof window.innerWidth !== 'undefined') {
      // the more standards compliant browsers (mozilla/netscape/opera/IE7) use window.innerWidth and window.innerHeight
      viewPortWidth = window.innerWidth;
      viewPortHeight = window.innerHeight;
    } else if (typeof document.documentElement !== 'undefined'
      && typeof document.documentElement.clientWidth !==
      'undefined' && document.documentElement.clientWidth !== 0) {
      // IE6 in standards compliant mode (i.e. with a valid doctype as the first line in the document)
      viewPortWidth = document.documentElement.clientWidth;
      viewPortHeight = document.documentElement.clientHeight;
    } else {
      // older versions of IE
      viewPortWidth = document.getElementsByTagName('body')[0].clientWidth;
      viewPortHeight = document.getElementsByTagName('body')[0].clientHeight;
    }
    return [viewPortWidth, viewPortHeight];
  }

  /**
   * Get's the view dimension size based on the current and previous sizes.
   * @param size The current size.
   * @param previousSize The previous size.
   * @returns The view dimension size.
   */
  private getClientViewSize(size: number, previousSize: number): number {
    const defaultSize = 800;
    const minimumSize = 300;

    if (!size) {
      size = previousSize;
    }

    if (!size) {
      return defaultSize;
    }

    if (size < minimumSize) {
      return minimumSize;
    }

    return size;
  }

  /**
   * Get the width of the viewable region of this chart's container.
   * @param elementId The element id.
   * @param previousWidth The previous width.
   * @returns The width of the viewable region.
   */
  public getClientViewWidth(elementId: string, previousWidth: number): number {
    let element = document.getElementById(elementId);
    let width = element ? element.clientWidth : 0;
    return this.getClientViewSize(width, previousWidth);
  }

  /**
   * Get the height of the viewable region of this chart's container.
   * @param elementId The element id.
   * @param previousHeight The previous height.
   * @returns The height of the viewable region.
   */
  public getClientViewHeight(elementId: string, previousHeight: number): number {
    let element = document.getElementById(elementId);
    let height = element ? element.clientHeight : 0;
    return this.getClientViewSize(height, previousHeight);
  }

  /**
   * Get the height available for drawing graphs within a tile.
   * @param elementId The element id.
   * @param previousHeight The previous height.
   * @returns The height available for drawing graphs.
   */
  public getAvailableTileHeight(elementId: string, previousHeight: number) {
    const cardId = elementId.slice(0, elementId.lastIndexOf('-'));
    const card = document.getElementById(cardId);
    if (card) {
      const cardHeight = card.clientHeight || 0;
      const cardOffset = card.getBoundingClientRect();
      const cardOffsetTop = cardOffset ? cardOffset.top : 0;
      const elementOffset = document.getElementById(elementId).getBoundingClientRect();
      const elementOffsetTop = elementOffset ? elementOffset.top : 0;
      const height = cardHeight + cardOffsetTop - elementOffsetTop;
      return this.getClientViewSize(height, previousHeight);
    } else {
      return this.getClientViewHeight(elementId, previousHeight);
    }
  }

  /**
   * Get the required SVG size based on the current and previous sizes.
   * @param elementId The element id.
   * @param previousSize The previous size.
   * @returns The required SVG size.
   */
  public getRequiredSvgSize(elementId: string, previousSize: ISize): Size {
    return new Size(
      this.getClientViewWidth(elementId, previousSize ? previousSize.width : 0),
      this.getAvailableTileHeight(elementId, previousSize ? previousSize.height : 0));
  }

  /**
   * Remove NaNs from a numeric array.
   * @param arr The array to remove NaNs from.
   * @returns The array with NaNs removed.
   */
  public deNaN(arr: number[]): number[] {
    for (let i = arr.length - 1; i >= 0; i--) {
      if (isNaN(arr[i])) {
        arr.splice(i, 1);
      }
    }
    return arr;
  }

  /**
   * Transpose a matrix.
   * @param m The matrix to transpose.
   * @returns The transposed matrix.
   */
  public transpose<T>(m: ReadonlyArray<ReadonlyArray<T>>): T[][] {
    if (!m.length) {
      return [];
    }

    return m[0].map((x, i) => m.map(x => x[i]));
  }

  /**
   * Programmatically download a file.
   * @param filename The name of the downloaded file.
   * @param text The text to download.
   * @param contentType The content type of the file.
   */
  public download(filename: string, text: string, contentType?: string) {

    let blob = new Blob([text], { type: contentType || 'text/plain' });
    let url = URL.createObjectURL(blob);
    Object.assign(document.createElement('a'),
    {
      href: url,
      download: filename,
    }).click();
  }

  /**
   * Direction dependent >
   * @param direction The direction to check.
   * @param a The first number.
   * @param b The second number.
   * @returns True if a > b in the given direction, false otherwise.
   */
  public after(direction: number, a: number, b: number) {
    if (direction === 1) {
      return a > b;
    } else {
      return a < b;
    }
  }

  /**
   * Direction dependent <
   * @param direction The direction to check.
   * @param a The first number.
   * @param b The second number.
   * @returns True if a < b in the given direction, false otherwise.
   */
  public before(direction: number, a: number, b: number) {
    if (direction === 1) {
      return a < b;
    } else {
      return a > b;
    }
  }

  /**
   * Find the index of the target value in monotonically increasing data.
   * @param data The data to search.
   * @param targetValue The target value to find.
   * @param direction The direction to search in.
   * @returns The index of the target value.
   */
  public findIndexInMonotonicallyIncreasingData(data: ReadonlyArray<number>, targetValue: number, direction: SearchDirection) {
    let test = direction === SearchDirection.Forwards
      ? (sourceValue: number, targetValue: number) => sourceValue > targetValue
      : (sourceValue: number, targetValue: number) => sourceValue < targetValue;

    // TODO: Use binary search.
    return (data || []).findIndex(sourceValue => test(sourceValue, targetValue));
  }

  /**
   * Find the index of the target value in monotonically decreasing data.
   * @param data The data to search.
   * @param targetValue The target value to find.
   * @param direction The direction to search in.
   * @returns The index of the target value.
   */
  public findIndexInMonotonicallyDecreasingData(data: ReadonlyArray<number>, targetValue: number, direction: SearchDirection) {
    let test = direction === SearchDirection.Forwards
      ? (sourceValue: number, targetValue: number) => sourceValue < targetValue
      : (sourceValue: number, targetValue: number) => sourceValue > targetValue;

    // TODO: Use binary search.
    return (data || []).findIndex(sourceValue => test(sourceValue, targetValue));
  }

  /**
   * Remove null or undefined items from an array.
   * @param items The array to remove null or undefined items from.
   */
  public removeNullOrUndefinedItems<T>(items: (T | undefined)[]) {
    for (let i = items.length - 1; i >= 0; --i) {
      if (items[i] === null || items[i] === undefined) {
        items.splice(i, 1);
      }
    }
  }
}

/**
 * The search direction.
 */
export enum SearchDirection {
  Forwards,
  Backwards
}

/**
 * The utilities instance.
 */
export const Utilities = new UtilitiesImplementation();

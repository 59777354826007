import {DisplayableError} from '../../common/errors/errors';
import {Injectable} from '@angular/core';

@Injectable()
export class ApplyChangesToConfig {

  public execute(config: any, changes: ReadonlyArray<ConfigChange>) {
    for(let change of changes){
      this.applySingleChange(config, change.path, change.value);
    }
  }

  private applySingleChange(config: any, path: ReadonlyArray<string>, value: number) {
    if(config === undefined){
      throw new DisplayableError('Path not found when applying constraint result.');
    }

    if(path.length === 0) {
      return;
    }

    let pathItem = path[0];

    if(path.length === 1) {
      config[pathItem] = value;
      return;
    }

    this.applySingleChange(config[pathItem], path.slice(1), value);
  }
}

export class ConfigChange {
  constructor(
    public readonly path: ReadonlyArray<string>,
    public readonly value: any){
  }
}

<div id="view-job-page">
  @if (!isLoaded) {
    <div class="container-fluid">
      <div>
        <div class="card">
          <h1 class="card-header">
            View Job
          </h1>
          <div class="card-body">
            @if (!hideBackButton) {
              <p>
                <a id="back-button" class="btn btn-smart btn-secondary" [routerLink]="['../']">
                  <i class="fa fa-caret-left"></i>Back
                </a>
              </p>
            }
            <cs-error-message [message]="errorMessage"></cs-error-message>
            @if (!errorMessage) {
              <cs-loader id="job-loader"></cs-loader>
            }
          </div>
        </div>
      </div>
    </div>
  }

  <pre id="job-dashboard-id" class="raw-json debug-information">{{dashboardId}}</pre>

  @if (isLoaded) {
    <div>
      <div class="container-fluid">
        <div class="card">
          <h1 class="card-header">
            {{job.name}}
            <small class="text-body-secondary job-index">Index {{job.jobIndex}}, {{job.studyResult.study.name}}</small>
            <div class="card-header-float-right">
              @if (jobViewModel) {
                <cs-linting-engine-dialog-launcher
                  [tenantId]="tenantId"
                  [userId]="userId"
                  [studyId]="studyId"
                [jobId]="jobId"></cs-linting-engine-dialog-launcher>
              }
            </div>
          </h1>
          <div class="card-body">
            <p>
              @if (!hideBackButton) {
                <a id="back-button" class="btn btn-smart btn-secondary" [routerLink]="['../']">
                  <i class="fa fa-caret-left"></i>Back
                </a>
              }
              @if (job) {
                <cs-edit-and-run-telemetry-button
                  [studyResult]="job.studyResult"
                [jobResult]="job.jobResult"></cs-edit-and-run-telemetry-button>
              }
              @if (job.isSuccessful && !job.isPostProcessor) {
                <button id="stage-job-to-results-staging-area-button" class="btn btn-smart btn-secondary" (click)="stageToResultsStagingArea()">
                  <i class="fa fa-area-chart"></i>Compare
                </button>
              }
              <cs-stage-all-inputs [tenantId]="tenantId" [studyId]="studyId" [jobId]="jobId"></cs-stage-all-inputs>
              @if (job.isSuccessful && !job.isPostProcessor && significantSimVersions.isAfterTelemetrySimulation) {
                <button id="stage-job-to-study-staging-area-button" class="btn btn-smart btn-secondary" (click)="stageToStudyStagingArea()">
                  <i class="fa fa-arrow-circle-up"></i><cs-stage-as-telemetry-text [studyType]="job.studyResult.study.data.studyType"></cs-stage-as-telemetry-text>
                </button>
              }
              <cs-save-racing-line-buttons [studyResult]="job.studyResult" [jobResult]="job.jobResult"></cs-save-racing-line-buttons>
              <span class="inline-button-text">Download:</span>
              <span class="button-menu-hover-target">
                <button id="download-job-results-csv-hover-button" class="btn btn-smart btn-secondary" [disabled]="!job.isSuccessful || job.isPostProcessor" (click)="downloadJob(true)">
                  <i class="fa fa-download"></i>Job (CSV)
                </button>
                <span class="button-menu-hovering-area">
                  <button id="download-job-results-csv-button" class="btn btn-smart btn-secondary" [disabled]="!job.isSuccessful || job.isPostProcessor" (click)="downloadJob(true)">
                    <i class="fa fa-download"></i>Job (CSV)
                  </button>
                  <button id="download-job-results-button" class="btn btn-smart btn-secondary" [disabled]="job.isPostProcessor" (click)="downloadJob(false)">
                    <i class="fa fa-download"></i>Job (Binary)
                  </button>
                  <a id="download-token-button" class="btn btn-smart btn-menu btn-secondary" [class.disabled]="job.isPostProcessor" download="{{studyId}}-{{job.jobIndex}}.canopy-download-token" [attr.href]="downloadToken">
                    <i class="fa fa-download"></i>Job (Token)
                  </a>
                  @for (simType of job.jobResult.simTypes; track simType) {
                    <button id="download-job-results-csv-{{simType}}button" class="btn btn-smart btn-secondary" [disabled]="!job.isSuccessful || job.isPostProcessor" (click)="downloadJob(true, simType)">
                      <i class="fa fa-download"></i>Channels ({{simType}})
                    </button>
                    <button id="download-job-scalar-results-{{simType}}button" class="btn btn-smart btn-secondary" [disabled]="!job.isSuccessful || job.isPostProcessor" (click)="downloadJobScalarResults(simType)">
                      <i class="fa fa-download"></i>Scalar Results ({{simType}})
                    </button>
                  }
                </span>
              </span>
            </p>
            <cs-error-message [message]="errorMessage"></cs-error-message>
            <pre id="job-json" class="raw-json debug-information">{{jobJson}}</pre>
            @if (!job.isFailed && !job.isSuccessful) {
              <div class="alert alert-info">
                Job is still running...
              </div>
            }
            <p>
              This job is <strong>{{job.jobResult.studyJob.data.computeCredits}} {{job.jobResult.studyJob.data.computeCredits === 1 ? 'compute credit' : 'compute credits'}}</strong>
              and <strong>{{job.jobResult.studyJob.data.storageCredits}} {{job.jobResult.studyJob.data.storageCredits === 1 ? 'storage credit' : 'storage credits'}}</strong>.
            </p>
            @if (!job.isSingleJob && !job.isPostProcessor) {
              <div>
                <h1 id="changes-from-baseline-header">Changes from Baseline</h1>
                <table id="diff-table" class="table diff-table last-item">
                  <thead>
                    <tr>
                      <th>Path</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    @for (item of job.flattenedDefinition; track item) {
                      <tr>
                        <td>{{item.key}}</td>
                        <td>{{item.value}}</td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            }
          </div>
        </div>
      </div>
      @if (jobViewModel) {
        <cs-view-job-results [jobs]="[jobViewModel]" [navigationStationPrefix]="dashboardId"></cs-view-job-results>
      }
    </div>
  }
</div>

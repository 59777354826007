import {MutableTelemetryChannel} from './telemetry-channel';

export class TelemetryFile {
  constructor(
    public readonly name: string,
    public readonly config: TelemetryConfig | VersionedTelemetryConfig) {
  }
}

export class ValidatedTelemetryFile {
  public readonly channelNameMappings: RenamedChannel[] = [];

  constructor(
    public readonly name: string,
    public readonly config: TelemetryConfig) {
  }
}

export interface TelemetryConfig {
  channels: MutableTelemetryChannel[];
}

export interface VersionedTelemetryConfig {
  simVersion: string;
  config: TelemetryConfig;
}

export class RenamedChannel {
  constructor(
    public readonly from: string,
    public readonly to: string) {
  }
}

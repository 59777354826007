import { SourceData } from './types/source-data';
import { TransientMultiPlotSide } from './types/transient-multi-plot-side';
import { IPopulatedMultiPlotChannel } from './types/i-populated-multi-plot-channel';
import { IPopulatedMultiPlotSide } from './types/i-populated-multi-plot-side';
import { SideType } from './types/side-type';
import { ProcessedMultiPlotChannel } from './types/processed-multi-plot-channel';
import { GetProcessedSide } from './get-processed-side';


/**
 * Merges a set of sides (columns or rows) into a single side.
 */
export class MergeSides {

  constructor(
    private readonly getProcessedSide: GetProcessedSide) {
  }

  /**
   * Merges a set of sides (columns or rows) into a single side.
   * @param sides The set of sides to merge.
   * @param sourceData The set of data sources.
   * @param sideType The type of side (column or row).
   * @returns The processed merged side.
   */
  public execute(sides: ReadonlyArray<IPopulatedMultiPlotSide>, sourceData: ReadonlyArray<SourceData>, sideType: SideType): IPopulatedMultiPlotSide {

    // Get the complete list of channels in all the sides.
    let populatedSideChannels = sides.reduce<IPopulatedMultiPlotChannel[]>(
      (p, c) => {
        p.push(...c.channels);
        return p;
      },
      []);

    // Get the complete list of processed channels in all the sides.
    let processedSideChannels = sides.reduce<ProcessedMultiPlotChannel[]>(
      (p, c) => {
        p.push(...c.processed.channels);
        return p;
      },
      []);

    // Create the single merged side.
    let result: IPopulatedMultiPlotSide = {
      relativeSize: 1,
      channels: populatedSideChannels,
      processed: this.getProcessedSide.execute(processedSideChannels, sourceData, sideType),
      transient: new TransientMultiPlotSide(),
    };

    return result;
  }
}

import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';

export class LazyLoaded<T> {
  private _value: T;
  private _isLoaded: boolean = false;
  private _errorMessage: string;
  private loadTask: Promise<T>;

  constructor(
    private readonly loadDelegate: () => Promise<T>,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  private async performLoad(rethrow: boolean): Promise<void> {
    try {
      this._errorMessage = undefined;
      this._isLoaded = false;
      this.loadTask = this.loadDelegate();
      this._value = await this.loadTask;
    } catch(error){
      this._errorMessage = this.getFriendlyErrorAndLog.execute(error);
      if(rethrow){
        throw error;
      }
    } finally {
      this.loadTask = undefined;
      this._isLoaded = true;
    }
  }

  public get value(): T {
    return this._value;
  }

  public set value(input: T){
    this._value = input;
    this._isLoaded = true;
  }

  public get isLoaded(): boolean{
    return this._isLoaded;
  }

  public get errorMessage(): string {
    return this._errorMessage;
  }

  public load(): Promise<void>{
    return this.loadInner(true);
  }

  public tryLoad(): Promise<void>{
    return this.loadInner(false);
  }

  private async loadInner(rethrow: boolean): Promise<void> {
    if(this._isLoaded){
      return;
    }

    if(this.loadTask) {
      await this.loadTask;
    } else{
      await this.performLoad(rethrow);
    }
  }

  public get isLoading(): boolean {
    return !!this.loadTask;
  }

  public reset(): void {
    // Currently if a load is in progress the value will be set again once the load is finished.
    // Clone does not have this behaviour.
    this._errorMessage = undefined;
    this._value = undefined;
    this._isLoaded = false;
  }

  public clone(): LazyLoaded<T> {
    let result = new LazyLoaded<T>(this.loadDelegate, this.getFriendlyErrorAndLog);
    if(this.isLoaded){
      result.value = this.value;
    }
    return result;
  }
}

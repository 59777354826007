import * as d3 from '../d3-bundle';

/**
 * Base interface for legend settings.
 */
export interface ILegendSettingsBase {
  /**
   * Pixel size of coloured squares in legend.
   */
  readonly blobSize: number;

  /**
   * Pixel gap between coloured squares in legend.
   */
  readonly blobSpacing: number;

  /**
   * Pixel width of legend value boxes.
   */
  readonly valueWidth: number;

  /**
   * Pixel width of legend value units display.
   */
  readonly unitsWidth: number;

  /**
   * Pixel width of channel name in legend.
   */
  readonly maximumLabelWidth: number;

  /**
   * Number of "render channels" (i.e. size channel, color channel, etc).
   */
  renderChannelCount: number;

  /**
   * Pixel width of channel name in legend.
   */
  readonly renderChannelWidth: number;
}

/**
 * Interface for legend settings.
 */
export interface ILegendSettings extends ILegendSettingsBase {

  /**
   * Get the required horizontal space for the legend.
   * @param sourceCount The number of sources.
   * @param labels The set of channel labels.
   * @param renderChannelCount The number of render channels.
   */
  getRequiredHorizontalSpace(sourceCount: number, labels?: string[], renderChannelCount?: number): number;

  /**
   * Get the required vertical space for the legend.
   * @param channelCount The number of channels.
   */
  getRequiredVerticalSpace(channelCount: number): number;

  /**
   * Trim a label to fit the maximum label width.
   * @param label The label to trim.
   */
  trimLabel(label: string): string;
}

/**
 * Default average pixels per character. We use this in place of a more complex text measurement.
 */
export const AVERAGE_PIXELS_PER_CHARACTER = 5.8;

/**
 * Legend settings implementation.
 */
export class LegendSettings implements ILegendSettings {

  /**
   * @inheritdoc
   */
  public readonly blobSize: number;

  /**
   * @inheritdoc
   */
  public readonly blobSpacing: number;

  /**
   * @inheritdoc
   */
  public readonly valueWidth: number;

  /**
   * @inheritdoc
   */
  public readonly unitsWidth: number;

  /**
   * @inheritdoc
   */
  public readonly maximumLabelWidth: number;

  /**
   * @inheritdoc
   */
  public renderChannelCount: number;

  /**
   * Create a new legend settings object.
   * @param value The legend settings to use.
   */
  constructor(value: ILegendSettingsBase) {
    this.blobSize = value.blobSize;
    this.blobSpacing = value.blobSpacing;
    this.valueWidth = value.valueWidth;
    this.unitsWidth = value.unitsWidth;
    this.maximumLabelWidth = value.maximumLabelWidth;
    this.renderChannelCount = value.renderChannelCount;
  }

  /**
   * @inheritdoc
   */
  public get renderChannelWidth(): number {
    return this.renderChannelCount * (this.blobSize + this.blobSpacing);
  }

  /**
   * @inheritdoc
   */
  public getRequiredHorizontalSpace(sourceCount: number, labels?: string[]): number {
    let requiredLabelWidth = 50; // Dome default value.

    if (labels && labels.length) {
      requiredLabelWidth = d3.maxStrict([
        40,
        d3.maxStrict(labels.map(v => v.length)) * AVERAGE_PIXELS_PER_CHARACTER]);
    }

    requiredLabelWidth = d3.minStrict([requiredLabelWidth, this.maximumLabelWidth]);

    return sourceCount * this.valueWidth
      + this.unitsWidth
      + this.blobSize
      + requiredLabelWidth
      + this.renderChannelWidth;
  }

  /**
   * @inheritdoc
   */
  public trimLabel(label: string): string {
    if (!label) {
      return label;
    }

    let maximumCharacters = Math.ceil(this.maximumLabelWidth / AVERAGE_PIXELS_PER_CHARACTER);
    if (label.length <= maximumCharacters) {
      return label;
    }

    return '…' + label.substr(label.length - maximumCharacters, maximumCharacters);
  }

  /**
   * @inheritdoc
   */
  public getRequiredVerticalSpace(channelCount: number): number {
    return channelCount * this.blobSize;
  }
}

import {Injectable} from '@angular/core';
import {StudyInput} from '../worksheets/study-input';

export function clone<T>(data: T, replacer?: (key: string, value: any) => any): T {
  if(data === null || data === undefined){
    return data;
  }

  return JSON.parse(JSON.stringify(data, replacer));
}

@Injectable()
export class CanopyJson {
  public stringify(data: any, replacer?: (key: string, value: any) => any): string {
    return JSON.stringify(data, replacer, 1);
  }

  public parse(data: string): any {
    return JSON.parse(data);
  }

  public format(data: string): string {
    let parsed = this.parse(data);
    return this.stringify(parsed);
  }

  public flatten(data: any): FlattenedJsonItem[] {
    // http://stackoverflow.com/questions/19098797/fastest-way-to-flatten-un-flatten-nested-json-objects
    let result: FlattenedJsonItem[] = [];
    function recurse(cur: any, prop: string) {
      if (Object(cur) !== cur) {
        result.push(new FlattenedJsonItem(prop, cur));
      } else if (Array.isArray(cur)) {
        let l = cur.length;
        for(let i=0; i<l; i++) {
          recurse(cur[i], prop + '[' + i + ']');
        }
        if (l === 0) {
          result.push(new FlattenedJsonItem(prop, '[]'));
        }
      } else {
        let isEmpty = true;
        for (let p in cur) {
          if(!cur.hasOwnProperty(p)) {
            continue;
          }
          isEmpty = false;
          recurse(cur[p], prop ? prop+'.'+p : p);
        }
        if (isEmpty && prop) {
          result.push(new FlattenedJsonItem(prop, '{}'));
        }
      }
    }
    recurse(data, '');

    return result;
  }

  public clone<T>(data: T, replacer?: (key: string, value: any) => any): T {
    return clone(data, replacer);
  }

  public equals(a: any, b: any){
    return JSON.stringify(a) === JSON.stringify(b);
  }

  public studyInputDataEquals(a: StudyInput, b: StudyInput){
    return a.name === b.name
      && a.notes === b.notes
      && this.equals(a.properties, b.properties)
      && this.equals(a.data, b.data);
  }
}

export class FlattenedJsonItem {
  constructor(
    public key: string,
    public value: any
  ){}
}

import {RowViewModel} from './row-view-model';
import {RowItemViewModel} from './row-item-view-model';

/**
 * The view model for a RowMetadata item on a row. The RowMetadata item is the first column in a row.
 */
export class RowMetadataViewModel extends RowItemViewModel {

  /**
   * Creates an instance of RowMetadataViewModel.
   * @param parent The row view model containing this row metadata item.
   */
  constructor(
    parent: RowViewModel){
    super(parent);
  }

  /**
   * Gets the name of the row. This simply gets the name from the underlying row.
   */
  public get name(): string {
    return this.row.name;
  }

  /**
   * Sets the name of the row. This simply sets the name on the underlying row.
   */
  public set name(value: string) {
    this.row.name = value;
  }

  /**
   * Gets a value indicating whether the item is selected.
   * A row metadata item is considered to be selected if all configs and the study on the row are selected.
   */
  public get isSelected() {
    return this.row.configs.every(v => v.isSelected)
      && this.row.study.isSelected;
  }

  /**
   * @inheritdoc
   * This does nothing for a row metadata item.
   */
  public setItemsMatching() {
    // Do nothing.
  }

  /**
   * @inheritdoc
   * This does nothing for a row metadata item.
   */
  public clearItemsMatching() {
    // Do nothing.
  }
}

import { Injectable, EventEmitter, Directive } from '@angular/core';
import {LogError} from './log-error.service';
import {Output} from '@angular/core';
import {ServiceOnInit} from './service-on-init';
import { AuthenticationService } from '../identity/state/authentication.service';

import Pusher from 'pusher-js/with-encryption';

@Directive()
@Injectable()
export class CanopyPusher implements ServiceOnInit {
  @Output() studiesProgress: EventEmitter<StudiesProgress> = new EventEmitter<StudiesProgress>();
  @Output() studyBuildProgress: EventEmitter<StudyBuildProgress> = new EventEmitter<StudyBuildProgress>();

  private pusher: any;
  private tenantId: string;

  constructor(
    private logError: LogError,
    private authenticationService: AuthenticationService){
  }

  public serviceOnInit() {
    this.pusher = new Pusher('5dc5bbb037d2ffb96fe1');

    this.updateCurrentUser();
  }


  public updateCurrentUser() {
    try {
      const userData = this.authenticationService.userDataSnapshot;
      if(userData.tenant !== this.tenantId){
        if (this.tenantId){
          this.pusher.unsubscribe(this.tenantId);
        }

        this.tenantId = userData.tenant;

        if(this.tenantId){
          let channel = this.pusher.subscribe(this.tenantId);
          channel.bind('studies-progress', (data: StudiesProgress) => {
            this.studiesProgress.emit(data);
          });
          channel.bind('study-build-progress', (data: StudyBuildProgress) => {
            this.studyBuildProgress.emit(data);
          });
        }
      }
    } catch (error) {
      this.logError.execute(error);
    }
  }
}

export interface StudiesProgress {
  items: StudyProgress[];
}

export interface StudyProgress {
  studyId: string;
  executionTimeMs: number;
  jobCount: number;
  completedJobCount: number;
  succeededJobCount: number;
  succeededComputeCredits: number;
  succeededStorageCredits: number;
  successfulJobs: JobProgress[];
  failedJobs: JobProgress[];
  resultsStudyIds: string[];
}

export interface JobProgress {
  i: number;
  t: number;
}


export interface StudyBuildProgress {
  studyId: string;
  jobCount: number;
  dispatchedJobCount: number;
  errorMessages: string[];
}

import {Component} from '@angular/core';
import {FormSubmissionHandler} from '../common/forms/form-submission-handler.service';
import {TenancyStub} from '../../generated/api-stubs';
import {FormSubmissionButton} from '../common/forms/form-submission-button';
import {CanopyValidators} from '../common/forms/canopy-validators.service';
import {Validators, UntypedFormControl, UntypedFormGroup, UntypedFormBuilder} from '@angular/forms';
import {Router} from '@angular/router';

@Component({
  templateUrl: './create-tenant.page.html'
})
export class CreateTenantPage {
  public form: UntypedFormGroup;
  public name: UntypedFormControl = new UntypedFormControl('', [Validators.required, ...CanopyValidators.tenantNameValidators]);
  public shortName: UntypedFormControl = new UntypedFormControl('', [Validators.required, ...CanopyValidators.tenantShortNameValidators]);
  public databaseId: UntypedFormControl = new UntypedFormControl('', [...CanopyValidators.databaseIdValidators]);
  public regionId: UntypedFormControl = new UntypedFormControl('', [...CanopyValidators.regionIdValidators]);

  public errorMessage: string;
  public submitButton = new FormSubmissionButton('Create', 'Creating...');

  constructor(
    formBuilder: UntypedFormBuilder,
    private tenancyStub: TenancyStub,
    private formSubmissionHandler: FormSubmissionHandler,
    private router: Router) {
    this.form = formBuilder.group({
      name: this.name,
      shortName: this.shortName,
      databaseId: this.databaseId,
      regionId: this.regionId,
    });
  }

  public async onSubmit() {
    let success = await this.formSubmissionHandler.execute(this.submit, this.form, this.submitButton, this);

    if (success) {
      this.router.navigate(['/admin']);
    }
  }

  private async submit() {
    await this.tenancyStub.postTenant({
      name: this.name.value,
      shortName: this.shortName.value,
      databaseId: this.databaseId.value,
      region: this.regionId.value
    });
  }
}

import {Injectable} from '@angular/core';
import {InterceptorBase} from './interceptor-base.service';
import {DEVELOPER_NAME_HEADER} from '../constants';
import {DeveloperNameReader} from './developer-name-reader.service';
import {BasicRequestOptions} from '../canopy-http.service';
import {HttpHeaders} from '@angular/common/http';

@Injectable()
export class DeveloperNameInterceptor extends InterceptorBase {
  private developerName: string;

  constructor(developerNameReader: DeveloperNameReader) {
    super();
    this.developerName = developerNameReader.execute();
  }

  public request(requestOptions: BasicRequestOptions): Promise<BasicRequestOptions> {
    if (this.developerName) {
      if (!requestOptions.headers) {
        requestOptions.headers = new HttpHeaders();
      }

      requestOptions.headers = requestOptions.headers.set(DEVELOPER_NAME_HEADER, this.developerName);
    }

    //requestOptions.headers = requestOptions.headers.set('X-Azure-DebugInfo', '1');

    return Promise.resolve(requestOptions);
  }
}

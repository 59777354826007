@if (isVisible) {
  <div class="modal dialog-container" id="chart-editor-dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title">Edit Chart</h1>
          <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()">
          </button>
        </div>
        <div class="modal-body">
          <cs-error-message [message]="session.errorMessage"></cs-error-message>
          @if (!session?.data) {
            <cs-loader></cs-loader>
          }
          @if (session?.data) {
            <div>
              <div class="scroll-area">
                @if (!session.flattenView) {
                  <div>
                    @for (pane of session.data.panes; track pane; let paneIndex = $index) {
                      <div class="pane-container">
                        <div class="card">
                          <div class="card-header">
                            <div class="clearfix">
                              <div class="button-container-left">
                                <button class="btn btn-secondary btn-icon" id="pane-{{paneIndex}}-up"
                                  [disabled]="paneIndex === 0"
                                  [ngClass]="{ 'no-button': paneIndex === 0 }"
                                  (click)="session.movePaneUp(paneIndex)"><i class="fa fa-arrow-up"></i></button>
                                  <button class="btn btn-secondary btn-icon" id="pane-{{paneIndex}}-down"
                                    [disabled]="paneIndex === session.data.panes.length - 1"
                                    [ngClass]="{ 'no-button': paneIndex === session.data.panes.length - 1 }"
                                    (click)="session.movePaneDown(paneIndex)"><i class="fa fa-arrow-down"></i></button>
                                  </div>
                                  <div class="button-container-right">
                                    <button class="btn btn-secondary btn-icon" id="pane-{{paneIndex}}-remove"
                                      (click)="session.removePane(paneIndex)"><i class="fa fa-times"></i></button>
                                    </div>
                                    <h1 class="pane-title">Pane {{paneIndex + 1}}</h1>
                                  </div>
                                </div>
                                <div class="card-body">
                                  @for (channel of pane.channels; track channel; let channelIndex = $index) {
                                    <div class="channel-container clearfix">
                                      <div class="button-container-left">
                                        <button class="btn btn-secondary btn-icon" id="pane-{{paneIndex}}-channel-{{channelIndex}}-up"
                                          [disabled]="channelIndex === 0"
                                          [ngClass]="{ 'no-button': channelIndex === 0 }"
                                          (click)="session.moveChannelUp(pane, channelIndex)"><i class="fa fa-arrow-up"></i></button>
                                          <button class="btn btn-secondary btn-icon" id="pane-{{paneIndex}}-channel-{{channelIndex}}-down"
                                            [disabled]="channelIndex === pane.channels.length - 1"
                                            [ngClass]="{ 'no-button': channelIndex === pane.channels.length - 1 }"
                                            (click)="session.moveChannelDown(pane, channelIndex)"><i class="fa fa-arrow-down"></i></button>
                                          </div>
                                          <div class="button-container-right">
                                            <button class="btn btn-secondary btn-icon" id="pane-{{paneIndex}}-channel-{{channelIndex}}-remove"
                                              (click)="session.removeChannel(pane, channelIndex)"><i class="fa fa-times"></i></button>
                                            </div>
                                            <div class="channel-content">
                                              <h1 class="channel-title">{{channel.name}}</h1>
                                              <div class="channel-description">{{channel.description}}</div>
                                            </div>
                                          </div>
                                        }
                                        <button class="btn btn-secondary btn-icon" id="pane-{{paneIndex}}-channel-add"
                                          (click)="session.addChannel(paneIndex)"><i class="fa fa-plus"></i></button>
                                        </div>
                                      </div>
                                      <div class="pane-add-button">
                                        <button class="btn btn-secondary btn-icon" id="pane-{{paneIndex}}-add"
                                          (click)="session.addPane(paneIndex)"><i class="fa fa-plus"></i></button>
                                        </div>
                                      </div>
                                    }
                                  </div>
                                }
                                @if (session.flattenView) {
                                  <div>
                                    <div class="pane-container">
                                      <div class="card">
                                        <div class="card-header">
                                          <h1 class="pane-title">Outputs</h1>
                                        </div>
                                        <div class="card-body">
                                          @for (pane of session.data.panes; track pane; let paneIndex = $index) {
                                            <div class="flattened-pane-container">
                                              @for (channel of pane.channels; track channel; let channelIndex = $index) {
                                                <div class="channel-container clearfix">
                                                  <div class="button-container-left">
                                                    <button class="btn btn-secondary btn-icon" id="channel-{{paneIndex}}-up"
                                                      [disabled]="paneIndex === 0"
                                                      [ngClass]="{ 'no-button': paneIndex === 0 }"
                                                      (click)="session.movePaneUp(paneIndex)"><i class="fa fa-arrow-up"></i></button>
                                                      <button class="btn btn-secondary btn-icon" id="channel-{{paneIndex}}-down"
                                                        [disabled]="paneIndex === session.data.panes.length - 1"
                                                        [ngClass]="{ 'no-button': paneIndex === session.data.panes.length - 1 }"
                                                        (click)="session.movePaneDown(paneIndex)"><i class="fa fa-arrow-down"></i></button>
                                                      </div>
                                                      <div class="button-container-right">
                                                        <button class="btn btn-secondary btn-icon" id="channel-{{paneIndex}}-remove"
                                                          (click)="session.removePane(paneIndex)"><i class="fa fa-times"></i></button>
                                                        </div>
                                                        <div class="channel-content">
                                                          <h1 class="channel-title">{{channel.name}}</h1>
                                                          <div class="channel-description">{{channel.description}}</div>
                                                        </div>
                                                      </div>
                                                    }
                                                    <button class="btn btn-secondary btn-icon add-channel-button" id="channel-{{paneIndex}}-add"
                                                      (click)="session.addChannel(paneIndex)"><i class="fa fa-plus"></i></button>
                                                    </div>
                                                  }
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        }
                                      </div>
                                      <div [hidden]="!session.showChannelSelection" class="scroll-area">
                                        <p>
                                          <input type="text" #filter placeholder="Filter" class="form-control">
                                        </p>
                                        @for (channel of session.channelMetadata; track channel; let channelIndex = $index) {
                                          <div>
                                            @if (!filter.value || channel.name.toLowerCase().indexOf(filter.value.toLowerCase()) !== -1) {
                                              <div class="channel-container clearfix">
                                                <div class="button-container-left">
                                                  <button class="btn btn-secondary btn-icon" id="channel-{{channelIndex}}-select"
                                                    (click)="session.selectChannel(channelIndex)"><i class="fa fa-plus"></i></button>
                                                  </div>
                                                  <div class="channel-content">
                                                    <h1 class="channel-title">{{channel.name}}</h1>
                                                    <div class="channel-description">{{channel.description}}</div>
                                                  </div>
                                                </div>
                                              }
                                            </div>
                                          }
                                        </div>
                                      </div>
                                    }
                                  </div>
                                  @if (session.showChannelSelection) {
                                    <div class="modal-footer">
                                      <button id="cancel-add-channel-button" type="button" class="btn btn-secondary" (click)="session.cancelAddChannel()">Cancel</button>
                                    </div>
                                  }
                                  @if (!session.showChannelSelection) {
                                    <div class="modal-footer">
                                      <button id="dialog-dismiss-button" type="button" class="btn btn-secondary" (click)="dismiss()">Cancel</button>
                                      <button id="dialog-accept-button" type="button" class="btn btn-primary" (click)="accept()">Apply</button>
                                    </div>
                                  }
                                </div><!-- /.modal-content -->
                              </div><!-- /.modal-dialog -->
                            </div>
                            }<!-- /.modal -->

import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'cs-compare-config-structures-unidiff-renderer',
  templateUrl: './compare-config-structures-unidiff-renderer.component.html',
  styleUrls: ['./compare-config-structures-unidiff-renderer.component.scss']
})
export class CompareConfigStructuresUnidiffRendererComponent implements OnInit {

  @Input() public text: string;

  public parsed: ReadonlyArray<LineOutput>;

  constructor() { }

  ngOnInit() {
    this.parsed = this.parseTextDiff(this.text);
  }

  public parseTextDiff(value: string): LineOutput[] {
    let output: LineOutput[] = [];
    if(!value){
      return output;
    }

    let lines = value.split('\n@@ ');
    for (let i = 0, l = lines.length; i < l; i++) {
      let line = lines[i];
      let location = /^(?:@@ )?[-+]?(\d+),(\d+)/.exec(line).slice(1);
      let lineOutput: LineOutput = {
        pieces: [],
        location: {
          line: location[0],
          chr: location[1]
        },
      };

      let pieces = line.split('\n').slice(1);
      for (let pieceIndex = 0, piecesLength = pieces.length; pieceIndex < piecesLength; pieceIndex++) {
        let piece = pieces[pieceIndex];
        if (!piece.length) {
          continue;
        }
        let pieceOutput: PieceOutput = {
          type: 'context',
          text: piece.slice(1),
        };
        if (piece.substr(0, 1) === '+') {
          pieceOutput.type = 'added';
        } else if (piece.substr(0, 1) === '-') {
          pieceOutput.type = 'deleted';
        }
        lineOutput.pieces.push(pieceOutput);
      }
      output.push(lineOutput);
    }
    return output;
  }

  public unescape(text: string){
    return unescape(text);
  }

  public htmlEscape(text: string) {
    let html = text;
    let replacements: [RegExp, string][] = [
      [/&/g, '&amp;'],
      [/</g, '&lt;'],
      [/>/g, '&gt;'],
      [/'/g, '&apos;'],
      [/"/g, '&quot;']
    ];
    for (let i = 0; i < replacements.length; i++) {
      html = html.replace(replacements[i][0], replacements[i][1]);
    }
    return html;
  }

}

interface LineOutput {
  pieces: PieceOutput[];
  location: {
    line: string;
    chr: string;
  };
}

interface PieceOutput {
  type: 'context' | 'added' | 'deleted';
  text: string;
}

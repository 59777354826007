export class TelemetryChannel {
  constructor(
    public readonly name: string,
    public readonly units: string,
    public readonly description: string,
    public readonly data: ReadonlyArray<number>){}
}

export class MutableTelemetryChannel {
  constructor(
    public name: string,
    public units: string,
    public description: string,
    public data: number[]){}
}

const formParametersKey = 'form_parameters';

export class ZendeskRedirectWorkaround {

  public static createZendeskUrl(url: string): string {
    if (!url) {
      return url;
    }

    let [start, queryString] = ZendeskRedirectWorkaround.splitAtQueryString(url);
    if (!queryString) {
      return url;
    }

    return start + '?' + queryString + '&' + formParametersKey + '=' + encodeURIComponent(queryString);
  }

  public static processRedirectUrl(url: string): string {
    if (!url) {
      return url;
    }

    let [start, queryString] = ZendeskRedirectWorkaround.splitAtQueryString(url);
    if (!queryString) {
      return url;
    }

    let queryParameters = ZendeskRedirectWorkaround.getQueryParameters(queryString);
    if (!queryParameters[formParametersKey]) {
      return url;
    }

    let formParameters = queryParameters[formParametersKey];
    let decodedParameters = decodeURIComponent(formParameters);

    return start + '?' + decodedParameters;
  }

  private static splitAtQueryString(url: string): [string, string] {
    let queryStart = url.indexOf('?');
    if (queryStart === -1) {
      return [url, ''];
    }

    return [url.slice(0, queryStart), url.slice(queryStart + 1)];
  }

  private static getQueryParameters(queryString: string): { [key: string]: string } {
    if(!queryString) {
      return {};
    }

    let result: { [key: string]: string } = {};

    let parts = queryString.split('&');
    for (let part of parts) {
      let [key, value] = part.split('=');
      result[key] = value;
    }

    return result;
  }
}

import {Injectable} from '@angular/core';
import {DialogManager, IDialog, DialogBase} from '../../common/dialogs/dialog-manager.service';
import {IEditChannelsOptions} from '../../visualizations/site-hooks';

@Injectable()
export class ChannelEditorDialog {
  constructor(
    private dialogManager: DialogManager
  ){}

  public async editChart(
    options: IEditChannelsOptions): Promise<any> {

    let result = await this.dialogManager.showSecondaryDialog(
      new ChannelEditorDialogData(options));

    return result;
  }
}

export class ChannelEditorDialogData extends DialogBase<any> implements IDialog {
  constructor(
    private _options: IEditChannelsOptions) {
    super();
  }

  public get options(): IEditChannelsOptions {
 return this._options; 
}
}

import {Injectable} from '@angular/core';
import {Observable, timer} from 'rxjs';

@Injectable()
export class Timer {
  public yield(){
    return new Promise<any>((resolve, reject) => {
      setTimeout(resolve);
    });
  }

  public delay(milliseconds: number){
    return new Promise<any>((resolve, reject) => {
      setTimeout(resolve, milliseconds);
    });
  }

  public repeat(milliseconds: number): Observable<number>{
    return timer(milliseconds, milliseconds);
  }

  public backoff(attemptNumber: number, baseDelay: number) {
    return this.delay(getExponentialBackoff(attemptNumber, baseDelay));
  }

}

export function getExponentialBackoff(attemptNumber: number, baseDelay: number): number {
  return baseDelay * Math.pow(2, attemptNumber - 1) - baseDelay;
}

export function checkIfAroundDate(monthIndex: number, date: number, afterDays: number, beforeDays: number = 0){
  if(afterDays < 0 || afterDays > 180 || beforeDays < 0 || beforeDays > 180){
    throw new Error('Invalid afterDays or beforeDays');
  }
  const today = new Date();
  const start = new Date(today.getFullYear(), today.getMonth(), today.getDate() - afterDays);
  const end = new Date(today.getFullYear(), today.getMonth(), today.getDate() + beforeDays);
  const possibleDate1 = new Date(start.getFullYear(), monthIndex, date).getTime();
  const possibleDate2 = new Date(end.getFullYear(), monthIndex, date).getTime();

  return (start.getTime() < possibleDate1 && end.getTime() >= possibleDate1) || (start.getTime() < possibleDate2 && end.getTime() >= possibleDate2);
}

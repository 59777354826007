import {Component, Input, ViewEncapsulation} from '@angular/core';
import {ResolvedFilterQuery} from './filter-query-builder.component';
import {GetTenantUsersQueryResult} from '../../../generated/api-stubs';

@Component({
  selector: 'cs-filter-query-renderer',
  templateUrl: './filter-query-renderer.component.html',
  styleUrls: ['./filter-query-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FilterQueryRendererComponent {
  @Input() public data: ResolvedFilterQuery;
  @Input() public isApplied: boolean = true;
  @Input() public tenantUsers: GetTenantUsersQueryResult;
}

import {ChartId} from './canopy-site-hooks.service';
import {UserSettings} from '../../user-state/user-settings.service';
import {Injectable} from '@angular/core';
import {DocumentSubType} from '../../../generated/api-stubs';
import {RequestedLayoutIds} from '../../visualizations/site-hooks';

@Injectable()
export class SetPreferredChart {
  constructor(
    private userSettings: UserSettings){
  }

  public async execute(tenantId: string, userId: string, chartType: DocumentSubType, layoutIds: RequestedLayoutIds, preferredChartIdObject: ChartId): Promise<any>{
    if(!layoutIds){
      return;
    }

    let layoutId = layoutIds.primary;

    let preferredChartUserId: string;
    let preferredChartId: string;
    if(preferredChartIdObject){
      if(typeof preferredChartIdObject === 'string'){
        preferredChartId = preferredChartIdObject;
      } else{
        preferredChartUserId = preferredChartIdObject.userId;
        preferredChartId = preferredChartIdObject.configId;
      }
    }

    let removeSetting = !preferredChartIdObject || preferredChartId === layoutId;

    await this.userSettings.update(tenantId, userId, currentSettings => {
      let chartSettings = currentSettings.settings.charts.find(v => v.chartType === chartType && v.chartId === layoutId);

      if(!removeSetting){
        if(chartSettings){
          chartSettings.preferredChartId = preferredChartId;
          chartSettings.preferredChartUserId = preferredChartUserId;
        } else{
          currentSettings.settings.charts.push({
            chartType,
            chartId: layoutId,
            preferredChartId,
            preferredChartUserId
          });
        }
      } else{
        if(chartSettings){
          let index = currentSettings.settings.charts.indexOf(chartSettings);
          currentSettings.settings.charts.splice(index, 1);
        }
      }

      // Search for fallback chart IDs and remove settings.
      for(let fallbackId of layoutIds.fallback){
        let index = currentSettings.settings.charts.findIndex(v => v.chartType === chartType && v.chartId === fallbackId);
        if(index !== -1){
          currentSettings.settings.charts.splice(index, 1);
        }
      }
    });

    return;
  }
}

const GUID_PLUS_HYPHEN_LENGTH = 33;

export function getJobIndexFromJobId(jobId: string){
  if(!jobId){
    return undefined;
  }

  let indexStartIndex = GUID_PLUS_HYPHEN_LENGTH;
  if(jobId.length < GUID_PLUS_HYPHEN_LENGTH){
    // This is useful for tests, so we can create shorter IDs.
    indexStartIndex = jobId.indexOf('-') + 1;
  }

  return parseInt(jobId.substr(indexStartIndex), 10);
}

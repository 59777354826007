import { ExplorationJobsMetadata } from '../get-exploration-jobs-metadata-from-exploration-map';
import { ExplorationInputsMetadata } from '../exploration-inputs-metadata';
import { GetSweepRemapping } from './get-sweep-remapping';
import { ApplySweepRemappingToInputSweep } from './apply-sweep-remapping-to-input-sweep';
import { ApplySweepRemappingsToJobs } from './apply-sweep-remappings-to-jobs';
import { ArrayMappings } from './array-mappings';


/**
 * Sorts the exploration input sweeps and job metadata based on the sweep values.
 */
export class SortExplorationMetadata {

  public static create(): SortExplorationMetadata {
    return new SortExplorationMetadata(
      new GetSweepRemapping(),
      new ApplySweepRemappingToInputSweep(),
      new ApplySweepRemappingsToJobs());
  }

  /**
   * Creates a new instance of SortExplorationMetadata.
   * @param getSweepRemapping Gets the remapping for a sweep.
   * @param applySweepRemappingToInputSweep Applies the remapping to an input sweep.
   * @param applySweepRemappingsToJobs Applies the remappings to the jobs.
   */
  constructor(
    private readonly getSweepRemapping: GetSweepRemapping,
    private readonly applySweepRemappingToInputSweep: ApplySweepRemappingToInputSweep,
    private readonly applySweepRemappingsToJobs: ApplySweepRemappingsToJobs) {
  }

  /**
   * Sorts the exploration input sweeps and job metadata based on the sweep values.
   * @param inputs The exploration inputs.
   * @param jobs The exploration jobs.
   * @returns The sorted exploration inputs and jobs.
   */
  public execute(inputs: ExplorationInputsMetadata, jobs: ExplorationJobsMetadata): [ExplorationInputsMetadata, ExplorationJobsMetadata] {

    // Get the remapping for each sweep.
    const sweepRemappings: ReadonlyArray<ArrayMappings> = inputs.sweeps.map(v => this.getSweepRemapping.execute(v));

    // Apply the remapping to each input sweep.
    const newInputs = new ExplorationInputsMetadata(
      inputs.sweeps.map((v, i) => this.applySweepRemappingToInputSweep.execute(sweepRemappings[i], v)));

    // Apply the remapping to the jobs.
    const newJobs = this.applySweepRemappingsToJobs.execute(sweepRemappings, jobs);

    // Return the new inputs and jobs.
    return [newInputs, newJobs];
  }
}

import { ChannelScalarDataAndMappings } from './channel-scalar-data-and-mappings';
import { SimType } from '../../sim-type';
import { UrlFileLoader } from '../../url-file-loader';
import { ProcessedExplorationMap } from './explorations/processed-exploration-map';
import { GetScalarDataFromConsolidatedFiles } from './get-scalar-data-from-consolidated-files';
import { GetScalarDataFromIndividualJobs } from './get-scalar-data-from-individual-jobs';
import { GetProcessedExplorationMap } from './explorations/get-processed-exploration-map';

/**
 * Loads the study exploration and scalar data.
 */
export class LoadStudyExplorationAndScalarData {

  constructor(
    private readonly urlFileLoader: UrlFileLoader,
    private readonly getScalarDataFromConsolidatedFiles: GetScalarDataFromConsolidatedFiles,
    private readonly getScalarDataFromIndividualJobs: GetScalarDataFromIndividualJobs,
    private readonly getProcessedExplorationMap: GetProcessedExplorationMap) {
  }

  /**
   * Creates a new instance of LoadStudyExplorationAndScalarData.
   * @param urlFileLoader The URL file loader.
   * @returns The new instance.
   */
  public static create(
    urlFileLoader: UrlFileLoader) {

    return new LoadStudyExplorationAndScalarData(
      urlFileLoader,
      new GetScalarDataFromConsolidatedFiles(urlFileLoader),
      new GetScalarDataFromIndividualJobs(urlFileLoader),
      GetProcessedExplorationMap.create());
  }

  /**
   * Loads the study exploration and scalar data.
   * @param studyId The study ID.
   * @param simTypes The simulation types.
   * @returns The study exploration and scalar data.
   */
  public async execute(studyId: string, simTypes: ReadonlyArray<SimType>): Promise<StudyExplorationAndScalarData | undefined> {

    // Load the exploration data.
    let map = await this.urlFileLoader.loadExplorationMap(studyId);
    if (!map) {
      return undefined;
    }

    let scalarData: ChannelScalarDataAndMappings;
    try {
      // Try and load the scalar data from the consolidated files created by the post-processor job.
      scalarData = await this.getScalarDataFromConsolidatedFiles.execute(studyId, simTypes, map);
    } catch (err) {
      console.log('Something went wrong with getting data from consolidated files.');

      // If that fails (probably because the post-processor failed), then load the scalar data from the individual jobs.
      // This is slow, but better than nothing. Hopefully we very rarely do this.
      scalarData = await this.getScalarDataFromIndividualJobs.execute(studyId, simTypes, map);
    }

    // Process the exploration map to make it easier to work with.
    const processedMap = this.getProcessedExplorationMap.execute(map, scalarData);

    // Return the data.
    return new StudyExplorationAndScalarData(scalarData, processedMap);
  }
}

/**
 * The study exploration and scalar data.
 */
export class StudyExplorationAndScalarData {

  /**
   * Creates a new instance of StudyExplorationAndScalarData.
   * @param scalarData The scalar data.
   * @param explorationMap The exploration map.
   */
  constructor(public readonly scalarData: ChannelScalarDataAndMappings, public readonly explorationMap: ProcessedExplorationMap) {
  }
}

import { ParseChannelDataCsv, ParsedChannelDataCsvResult } from './parse-channel-data-csv';

/**
 * A cache for CSV data.
 */
export class CsvSourceLoaderCache {

  /**
   * The parsed channels.
   */
  private parsedChannels?: ParsedChannelDataCsvResult;

  /**
   * Creates a new instance of CsvSourceLoaderCache.
   * @param fileContent The file content to parse.
   * @param parseChannelDataCsv The parser for the channel data.
   */
  constructor(
    private fileContent: string,
    private parseChannelDataCsv: ParseChannelDataCsv) {
  }

  /**
   * Gets the parsed channel data.
   * @returns The parsed channel data.
   */
  public get(): Promise<ParsedChannelDataCsvResult> {
    if (!this.parsedChannels) {
      this.parsedChannels = this.parseChannelDataCsv.execute(this.fileContent);
    }

    return Promise.resolve(this.parsedChannels);
  }
}

import { SVGSelection } from '../../untyped-selection';
import { ICanvasData } from '../canvas-utilities';
import { ParallelCoordinatesViewerSettings } from './parallel-coordinates-viewer-settings';
import {
  CanvasDataRendererBase,
  RenderInformation
} from '../components/canvas-data-renderer-base';
import * as d3 from '../../d3-bundle';
import {
  Dimension,
  LineMouseEvent,
  ParallelCoordinatesData,
  PointsInDimensions
} from './parallel-coordinates-types';
import { Position } from '../position';
import { Rectangle } from '../rectangle';
import { SharedState } from '../shared-state';

const BACKGROUND_CSS_CLASS = 'parallel-coordinates-canvas-background';
const FOREGROUND_CSS_CLASS = 'parallel-coordinates-canvas-foreground';

/**
 * Extends the RenderInformation class to include information specific to the parallel coordinates viewer.
 */
class ParallelCoordinatesRenderInformation extends RenderInformation {
  constructor(
    public readonly redrawAllData: boolean) {
    super();
  }

  /**
   * Get the target canvas contexts.
   * @param isFirstCall Whether this is the first call.
   * @returns All contexts on the first call or when redrawing all data, the foreground context on other calls.
   */
  public getTargetCanvasContexts(isFirstCall: boolean): ReadonlyArray<CanvasRenderingContext2D> {
    if (this.redrawAllData || isFirstCall) {
      return this.canvases.all.map(v => v.canvasContext);
    }

    return [this.canvases.getByCssClass(FOREGROUND_CSS_CLASS).canvasContext];
  }
}

/**
 * The data renderer for the parallel coordinates viewer. Renders the data to two canvases, a coloured foreground canvas and a grayscale background canvas.
 */
export class ParallelCoordinatesDataRenderer extends CanvasDataRendererBase<ParallelCoordinatesViewerSettings, ParallelCoordinatesRenderInformation>  {

  /**
   * The D3 style event listeners.
   */
  private readonly listeners = d3.dispatch('mouseover', 'mouseout', 'click', 'dataRemovalRequested');

  /**
   * The parallel coordinates data.
   */
  private data?: ParallelCoordinatesData;

  /**
   * Create a new instance of the data renderer for the parallel coordinates viewer.
   * @param sharedState The shared state.
   * @param settings The parallel coordinates viewer settings.
   * @param canvasData The canvas data.
   */
  constructor(
    private readonly sharedState: SharedState,
    settings: ParallelCoordinatesViewerSettings,
    canvasData: ICanvasData) {
    super(
      'parallel-coordinates-data-renderer',
      [BACKGROUND_CSS_CLASS, FOREGROUND_CSS_CLASS],
      settings,
      canvasData);
  }

  /**
   * Set the data for the parallel coordinates data renderer.
   * @param data The data to set.
   */
  public setData(data: ParallelCoordinatesData) {
    this.data = data;
  }

  /**
   * Render the data.
   * @param redrawAllData Whether to redraw all data.
   */
  public render(redrawAllData: boolean) {
    // Call the base class, passing in the render information.
    super.performRender(new ParallelCoordinatesRenderInformation(redrawAllData));
  }

  /**
   * Render the parallel coordinates data to the canvas contexts. This is called by the base class.
   * @param targetContexts The target contexts.
   * @param renderInformation The render information.
   */
  protected renderContext(targetContexts: ReadonlyArray<CanvasRenderingContext2D>, renderInformation: ParallelCoordinatesRenderInformation) {
    if (!this.container || !this.svg || !this.data) {
      return;
    }

    const settings = this.definedSettings;
    this.container.attr('transform', 'translate(' + settings.svgPadding.left + ',' + settings.svgPadding.top + ')');

    // The matched path is the path that is currently being hovered over.
    // Here we clear the matched path by passing in -1 as the matched index.
    this.updateMatchedPath(this.svg, -1);

    // The parallel coordinates viewer only supports one data source, so if this isn't visible we have nothing to do.
    if (!this.sharedState.isFirstSourceVisible) {
      return;
    }

    // Get the foreground and background contexts.
    let backgroundContext = renderInformation.canvases.getByCssClass(BACKGROUND_CSS_CLASS).canvasContext;
    let foregroundContext = renderInformation.canvases.getByCssClass(FOREGROUND_CSS_CLASS).canvasContext;

    // Fetch the dimensions in the order they are rendered, so we can start drawing the lines.
    let orderedDimensions = this.getOrderedDimensions();
    if (orderedDimensions.length) {
      let firstDimension = orderedDimensions[0];
      let lastDimension = orderedDimensions[orderedDimensions.length - 1];

      // The range of the scale (or any of the scales) will give us the height in pixels of the data area.
      let yRange = d3.extentStrict(firstDimension.scale.range());

      // Create a clip rectangle to clip the data to the data area.
      let clip = new Rectangle(
        settings.svgPadding.left + firstDimension.renderPosition,
        settings.svgPadding.top,
        lastDimension.renderPosition - firstDimension.renderPosition,
        yRange[1] - yRange[0]);

      // Set the clipping region on the canvas contexts.
      targetContexts.forEach(c => c.beginPath());
      targetContexts.forEach(c => c.rect(clip.x, clip.y, clip.width, clip.height));
      targetContexts.forEach(c => c.clip());

      // Position us at the top left of the data area on each canvas.
      targetContexts.forEach(c => c.translate(settings.svgPadding.left, settings.svgPadding.top));

      // Set the line width on each canvas.
      targetContexts.forEach(c => c.lineWidth = 0.5);

      // For the background context, we will draw in a semi-transparent light gray.
      backgroundContext.strokeStyle = '#ccc';
      backgroundContext.globalAlpha = 0.4;

      // For each line we need to draw...
      for (let d of this.data.lines) {

        // Create the line object.
        let line = this.createLine(d);

        // Set the color of the line on the foreground context based on the data value for the dimension
        // we're using to color the data.
        foregroundContext.strokeStyle = this.data.colorScale(d[this.data.colorDimensionIndex]);

        // For each context...
        targetContexts.forEach(c => {
          // We always render background context data, and only render foreground context data if it isn't filtered.
          let shouldRender = c !== foregroundContext || this.shouldShowData(d);

          // If we should render...
          if (shouldRender) {
            // Draw the line.
            line.context(c);
            c.beginPath();
            line(d as number[]);
            c.stroke();
          }
        });
      }
    }
  }

  /**
   * Get the index of the data that is closest to the mouse position.
   * @param orderedDimensions The ordered list of dimensions.
   * @param mousePosition The current mouse position.
   * @returns The index of the data that is closest to the mouse position, or -1 if no data is sufficiently close.
   */
  private getMatchedDataIndex(orderedDimensions: ReadonlyArray<Dimension>, mousePosition: Position) {
    const lines = this.data ? this.data.lines : [];
    const settings = this.definedSettings;

    // We're going to separately track the closest filtered and unfiltered lines, so that we can
    // prioritize rendering unfiltered lines.
    let matchedIndex = -1;
    let matchedDistance = 1000;
    let matchedFilteredIndex = -1;
    let matchedFilteredDistance = 1000;
    // For each line...
    for (let dataIndex = 0; dataIndex < lines.length; ++dataIndex) {
      // Get the line.
      let d = lines[dataIndex];

      // Get the points for the line in pixel space.
      let dp = d.map((v, i) => {
        let dimension = orderedDimensions[i];
        return new Position(
          settings.svgPadding.left + dimension.renderPosition,
          settings.svgPadding.top + dimension.scale(d[dimension.plot.plotIndex]));
      });

      // For each point in the line from the first to the penultimate...
      for (let i = 0; i < d.length - 1; ++i) {
        // Calculate the distance from the mouse position to the line segment.
        let distance = distanceToSegment(mousePosition, dp[i], dp[i + 1]);

        // If we're within 4 pixels of the line...
        if (distance < 4) {
          if (this.shouldShowData(d)) {
            // If the line is visible, compare it with the closest unfiltered line so far.
            if (distance < matchedDistance) {
              // If this line is closer, update the matched index and distance.
              matchedDistance = distance;
              matchedIndex = dataIndex;
            }
          } else {
            // If the line is not visible, compare it with the closest filtered line so far.
            if (distance < matchedFilteredDistance) {
              // If this line is closer, update the matched filtered index and distance.
              matchedFilteredDistance = distance;
              matchedFilteredIndex = dataIndex;
            }
          }
        }
      }
    }

    // If we are close to an unfiltered line, return the index of that line.
    // Otherwise, return the index of the closest filtered line.
    // If no lines are close enough, return -1.
    return matchedIndex === -1 ? matchedFilteredIndex : matchedIndex;
  }

  /**
   * If there is a matched path (a data line the user is hovering over), draw the line in SVG.
   * Otherwise, clear the matched path line.
   * @param svg
   * @param matchedIndex
   */
  private updateMatchedPath(svg: SVGSelection, matchedIndex: number) {
    let transitionDuration = 250;

    let matchedData = matchedIndex === -1 || !this.sharedState.isFirstSourceVisible ? [] : [matchedIndex];

    // Update the line and the outline.
    this.updateMatchedPathInner(transitionDuration, matchedData, 'canvas-selected-line');
    this.updateMatchedPathInner(transitionDuration, matchedData, 'canvas-selected-line-outline', 'rgba(0,0,0,0.5)');
  }

  /**
   * If there is a matched path (a data line the user is hovering over), draw the line in SVG.
   * Otherwise, clear the matched path line.
   * @param transitionDuration The duration of the transition.
   * @param matchedData The matched data.
   * @param className The class name to use.
   * @param colorOverride The color to use. If not provided the color will be selected based on the data.
   */
  private updateMatchedPathInner(transitionDuration: number, matchedData: number[], className: string, colorOverride?: string) {
    if (!this.svg || !this.data) {
      return;
    }

    const data = this.data;

    // Create a group for each line index.
    let lineUpdate = this.svg.select('g').selectAll<SVGPathElement, number>('.' + className).data(matchedData);
    let lineEnter = lineUpdate.enter()
      .append('path')
      .attr('class', className)
      .lower();

    // Set the line to be invisible initially.
    lineEnter.style('opacity', 0);

    // Transition the line to match the data.
    lineUpdate.merge(lineEnter)
      .transition().duration(50).ease(d3.easeLinear)
      .style('stroke', (d) => colorOverride
        ? colorOverride
        : this.shouldShowData(data.lines[d]) ? data.colorScale(data.lines[d][data.colorDimensionIndex]) : '#ccc')
      .style('opacity', 1)
      .attr('d', (d) => this.createLine(data.lines[d])(data.lines[d] as number[]));

    // Fade the line out when it is no longer matched.
    lineUpdate.exit().transition().duration(transitionDuration).style('opacity', 0).remove();
  }

  /**
   * Attaches mouse events to the canvas, and hooks them up to re-broadcast them with
   * the appropriate data index.
   * @param svg The SVG element to attach the mouse events to.
   */
  protected attachCanvasMouseMoveHandler(svg: SVGSelection) {
    let lastMouseDataIndex = -1;

    svg.on('mousemove', currentEvent => {
      let mouseEvent = <MouseEvent>currentEvent;
      let position = new Position(mouseEvent.offsetX, mouseEvent.offsetY);
      let orderedDimensions = this.getOrderedDimensions();
      let matchedIndex = this.getMatchedDataIndex(orderedDimensions, position);

      // If there is an existing matched index, broadcast a mouseout event before we broadcast a new mouseover event.
      if (lastMouseDataIndex !== -1) {
        this.dispatchEvent('mouseout', lastMouseDataIndex, currentEvent);
      }

      if (lastMouseDataIndex !== matchedIndex) {
        // If we're over a new data line, update the matched path SVG line.
        this.updateMatchedPath(svg, matchedIndex);
      }

      if (matchedIndex !== -1) {
        // We're over data, so make the SVG clickable.
        svg.attr('clickable', 'true');

        // If the mouse is over data, rebroadcast the event but with the data index.
        this.dispatchEvent('mouseover', matchedIndex, currentEvent);
      } else {
        // We're not over data, so make the SVG not clickable.
        svg.attr('clickable', null);
      }

      // Update the last matched index.
      lastMouseDataIndex = matchedIndex;
    })
      .on('mouseout', currentEvent => {        //undo everything on the mouseout
        let mouseEvent = <MouseEvent>currentEvent;
        if (lastMouseDataIndex !== -1) {
          // If we were previously over data, broadcast a mouseout event and clear the matched path SVG line.
          this.dispatchEvent(mouseEvent.type, lastMouseDataIndex, currentEvent);
          this.updateMatchedPath(svg, -1);
          lastMouseDataIndex = -1;
        }
      })
      .on('click', currentEvent => {
        let mouseEvent = <MouseEvent>currentEvent;
        let position = new Position(mouseEvent.offsetX, mouseEvent.offsetY);
        let orderedDimensions = this.getOrderedDimensions();
        let matchedIndex = this.getMatchedDataIndex(orderedDimensions, position);
        if (matchedIndex !== -1) {
          // The user has clicked on a data line, so handle the click.
          if(currentEvent.altKey){
            this.listeners.call('dataRemovalRequested', this, matchedIndex);
            return;
          }
          this.dispatchEvent('click', matchedIndex, currentEvent);
        }
      });
  }

  /**
   * Creates a d3.Line object for the given data.
   * @param d The data to create the line for.
   * @returns The d3.Line object.
   */
  public createLine(d: PointsInDimensions): d3.Line<number> {
    // Draw a line where the X point coordinates are the dimension X positions and the
    // Y coordinates are were the data point falls on the scale for that dimension.
    let orderedDimensions = this.getOrderedDimensions();
    return d3.line<number>()
      .curve(d3.curveLinear)
      .defined((d) => !isNaN(d))
      .x((ds, i) => orderedDimensions[i].renderPosition)
      .y((ds, i) => orderedDimensions[i].scale(d[orderedDimensions[i].plot.plotIndex]));
  }

  /**
   * Get the list of dimensions in the order they are rendered.
   * @returns The list of dimensions in the order they are rendered.
   */
  private getOrderedDimensions(): Dimension[] {
    if (!this.data) {
      return [];
    }

    let result = [...this.data.dimensionList];
    result.sort((a, b) => a.renderPosition - b.renderPosition);
    return result;
  }

  /**
   * Whether a given data line should be shown based on the current filters.
   * @param d The data line to check.
   * @returns Whether the data line should be shown.
   */
  private shouldShowData(d: PointsInDimensions): boolean {
    if (!this.data) {
      return false;
    }

    // For each dimension...
    for (let dimension of this.data.dimensionList) {
      // If there is a filter on the dimension...
      let filter = dimension.channel.unprocessed.transient.filter;
      if (!filter) {
        continue;
      }

      // Determine whether the line is within the filter range.
      let show = filter.minimum <= d[dimension.plot.plotIndex] && d[dimension.plot.plotIndex] <= filter.maximum;
      if (show) {
        continue;
      }

      // If it isn't, the entire line is hidden.
      return false;
    }

    return true;
  }

  /**
   * Add an event listener.
   * @param typenames The event type name.
   * @param callback The callback to add.
   * @returns This object.
   */
  public on(typenames: string, callback: (this: object, ...args: any[]) => void): this {
    this.listeners.on(typenames, callback);
    return this;
  }

  /**
   * Dispatch an event.
   * @param type The event type.
   * @param lineIndex The line index.
   * @param currentEvent The current event.
   */
  private dispatchEvent(type: string, lineIndex: number, currentEvent: any) {
    // Example: https://github.com/d3/d3-drag/blob/master/src/drag.js
    let event = new LineMouseEvent(type, lineIndex, currentEvent);
    this.listeners.call(type, this, event);
  }
}

/**
 * Calculate the square of a number.
 * @param x The number to square.
 * @returns The square of the number.
 */
function sqr(x: number) {
  return x * x;
}

/**
 * Calculate the square of the distance between two points.
 * @param v The first point.
 * @param w The second point.
 * @returns The square of the distance between the two points.
 */
function dist2(v: Position, w: Position) {
  return sqr(v.x - w.x) + sqr(v.y - w.y);
}

/**
 * Calculate the square of the distance between a point and a line segment.
 * @param p The point.
 * @param v The first point of the line segment.
 * @param w The second point of the line segment.
 * @returns The square of the distance between the point and the line segment.
 */
function distToSegmentSquared(p: Position, v: Position, w: Position) {
  let l2 = dist2(v, w);
  if (l2 === 0) {
    return dist2(p, v);
  }
  let t = ((p.x - v.x) * (w.x - v.x) + (p.y - v.y) * (w.y - v.y)) / l2;
  t = Math.max(0, Math.min(1, t));
  return dist2(p, new Position(v.x + t * (w.x - v.x), v.y + t * (w.y - v.y)));
}

/**
 * Calculate the distance between a point and a line segment.
 * http://stackoverflow.com/a/1501725/37725
 * @param p The point.
 * @param v The first point of the line segment.
 * @param w The second point of the line segment.
 * @returns The distance between the point and the line segment.
 */
function distanceToSegment(p: Position, v: Position, w: Position) {
  return Math.sqrt(distToSegmentSquared(p, v, w));
}

import { SimulationViewModel } from './simulation-view-model';
import { StudyViewModel } from './study-view-model';
import { ConfigViewModel } from './config-view-model';
import { StudyReference, TenantInformation, UserInformation } from '../../generated/api-stubs';
import { RowMetadataViewModel } from './row-metadata-view-model';

/**
 * The types which can be items in a column. These all derive from RowItemViewModel.
 */
export type ColumnItemViewModel = RowMetadataViewModel | ConfigViewModel | StudyViewModel | SimulationViewModel;

/**
 * A column item can also be undefined.
 */
export type OptionalColumnItemViewModel = ColumnItemViewModel | undefined;

/**
 * Map of tenant information by tenant ID.
 */
export interface TenantsByTenantId {
  [tenantId: string]: TenantInformation;
}

/**
 * Map of user information by user ID.
 */
export interface UsersByUserId {
  [userId: string]: UserInformation;
}

/**
 * A reference to either a config, study or job in a tenant, with an optional job index when referencing a study job.
 */
export interface ITenantReference {
  readonly tenantId: string;
  readonly targetId: string;
  readonly jobIndex?: number;
}

/**
 * A reference to a default config.
 */
export interface IDefaultReference {
  readonly name: string;
}

/**
 * A reference to either a tenant or a default config.
 */
export interface ITenantOrDefaultReference {
  readonly tenant?: ITenantReference;
  readonly default?: IDefaultReference;
}

/**
 * A reference which is either a ITenantReference, or an ITenantOrDefaultReference.
 */
export type IReference = ITenantReference | ITenantOrDefaultReference;

/**
 * An object which contains a reference.
 */
export interface IHasReference {
  readonly reference: IReference;
}

/**
 * Converts a reference to either a default reference, if it is one, or undefined.
 * @param reference The reference to convert.
 * @returns The default reference, or undefined if the reference is not a default reference.
 */
export function referenceAsDefault(reference: IReference): IDefaultReference | undefined {
  return (reference as ITenantOrDefaultReference).default;
}

/**
 * Converts a reference to either a tenant reference, if it is one, or undefined.
 * @param reference The reference to convert.
 * @returns The tenant reference, or undefined if the reference is not a tenant reference.
 */
export function referenceAsTenant(reference: IReference): ITenantReference | undefined {
  if (referenceAsDefault(reference)) {
    return undefined;
  }

  return (reference as ITenantOrDefaultReference).tenant || (reference as ITenantReference);
}

/**
 * Converts a reference to a study reference, if it is one, or undefined. A study reference
 * does not contain a jobIndex field, so if the reference is a job reference the jobIndex
 * is removed.
 * @param reference The reference to convert.
 * @returns The study reference, or undefined if the reference is not a study reference.
 */
export function referenceAsStudy(reference: IReference): StudyReference | undefined {
  if (referenceAsDefault(reference)) {
    return undefined;
  }

  const tenantReference = referenceAsTenant(reference);
  return {
    tenantId: tenantReference.tenantId,
    targetId: tenantReference.targetId,
  };
}

/**
 * Compares two references for equality.
 * @param a The first reference.
 * @param b The second reference.
 * @returns True if the references are equal, false otherwise.
 */
export function referenceEquals(a: IReference, b: IReference): boolean {
  if (a === null || a === undefined) {
    return b === null || b === undefined;
  }

  if (b === null || b === undefined) {
    return false;
  }

  const aDefault = (a as ITenantOrDefaultReference).default;
  if (aDefault) {
    const bDefault = (b as ITenantOrDefaultReference).default;
    return !!(bDefault && aDefault.name === bDefault.name);
  }

  const aTenant: ITenantReference = (a as ITenantOrDefaultReference).tenant || a as ITenantReference;
  const bTenant: ITenantReference = (b as ITenantOrDefaultReference).tenant || b as ITenantReference;

  // If either reference doesn't specify a job index we assume we're comparing a study to a config and we ignore
  // it. Otherwise if both exist we assume we're comparing two telemetry configs and include it.
  return !!(bTenant
    && aTenant.tenantId === bTenant.tenantId
    && aTenant.targetId === bTenant.targetId
    && (aTenant.jobIndex === undefined || bTenant.jobIndex === undefined || aTenant.jobIndex === bTenant.jobIndex));
}

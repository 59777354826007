import { SVGSelection } from '../../untyped-selection';
import { CanvasUtilities, ICanvasData } from '../canvas-utilities';
import * as d3 from '../../d3-bundle';
import { BaseType } from 'd3-selection';
import { DisplayableError } from '../../displayable-error';

/**
 * Base class for rendering data to one or more canvases.
 * Multiple canvases are used for the parallel coordinates plot, one with the filtered
 * out lines rended in gray at the back, and one with the non-filtered lines rended
 * in color at the front.
 */
export abstract class CanvasDataRendererBase<TChartSettings, TRenderInformation extends RenderInformation> {

  /**
   * Utility class for working with canvas elements.
   */
  protected canvasUtilities = new CanvasUtilities();

  /**
   * The SVG element which sits in front of the canvas elements.
   */
  protected svg?: SVGSelection;

  /**
   * The container within the SVG element that may contain elements related to the canvas.
   * For example when hovering over a parallel coordinates plot, this container may contain
   * the line that is being hovered over.
   */
  protected container?: d3.Selection<SVGGElement, null, SVGElement, unknown>;

  /**
   * Creates a new instance of CanvasDataRendererBase.
   * @param containerCssClass The CSS class for the container.
   * @param canvasCssClasses The CSS classes for the canvases.
   * @param settings The settings for the chart.
   * @param canvasData The data for the canvases.
   */
  protected constructor(
    private readonly containerCssClass: string,
    private readonly canvasCssClasses: ReadonlyArray<string>,
    protected settings?: TChartSettings,
    protected canvasData?: ICanvasData) {
  }

  /**
   * Gets the settings for the chart. If they are not defined, an error is thrown.
   */
  protected get definedSettings(): TChartSettings {
    if (!this.settings) {
      throw new Error('Settings are not defined.');
    }

    return this.settings;
  }

  /**
   * Performs a render of the canvasses. This ensures the canvasses are created and configured
   * and delegates the actual data rendering to the super class.
   * @param renderInformation The render information.
   */
  public performRender(renderInformation: TRenderInformation) {
    // If we don't have any of the required data, we can't render.
    if (!this.settings || !this.canvasData || !this.canvasCssClasses) {
      return;
    }

    // Get the SVG element.
    this.svg = this.canvasData.parent.select<SVGElement>('svg');

    // Create the g container in the SVG element that will contain any canvas related elements
    // such as the hover line in the parallel coordinates plot.
    let containerClassName = this.canvasCssClasses.join('-') + '-data-renderer';
    let containerUpdate = this.svg.selectAll<SVGGElement, unknown>('.' + containerClassName).data([null]);
    let containerEnter = containerUpdate.enter()
      .append<SVGGElement>('g').attr('class', containerClassName + ' ' + this.containerCssClass);
    this.container = containerUpdate.merge(containerEnter);

    // For each canvas class...
    for (let canvasCssClass of this.canvasCssClasses) {

      // Ensure the canvas exists and get the selectors.
      let [canvasUpdate, canvasEnter, canvasAll] = this.canvasUtilities.getCanvas(this.canvasData, canvasCssClass);

      let canvas = canvasAll.node();
      if (!canvas) {
        throw new DisplayableError('Unable to get canvas.');
      }

      let context = canvas.getContext('2d');
      if (!context) {
        throw new DisplayableError('Unable to write to canvas.');
      }

      // Add the canvas information to the render information.
      renderInformation.canvases.add(new CanvasInformation(
        canvasCssClass,
        canvasUpdate,
        canvasEnter,
        canvasAll,
        context));
    }

    // If this is the first call, attach the mouse move handler.
    let isFirstCall = !containerEnter.empty();
    if (isFirstCall) {
      this.attachCanvasMouseMoveHandler(this.svg);
    }

    // Update the SVG size.
    this.canvasUtilities.updateSvgSize(this.canvasData.settings);

    // Get the canvas contexts for each canvas.
    let targetContexts = renderInformation.getTargetCanvasContexts(isFirstCall);

    // Render each canvas. We save and restore the drawing state to ensure it is the same
    // going out as it was going in.
    targetContexts.forEach(c => c.save());
    try {
      let pixelRatio = this.canvasData.settings.pixelRatio;
      targetContexts.forEach(c => c.scale(pixelRatio, pixelRatio));

      let svgSize = this.canvasData.settings.svgSize;
      targetContexts.forEach(c => c.clearRect(0, 0, svgSize.width, svgSize.height));

      this.renderContext(targetContexts, renderInformation);
    } finally {
      targetContexts.forEach(c => c.restore());
    }
  }

  /**
   * Attaches a mouse move handler to the SVG element.
   * @param svg The SVG element.
   */
  protected abstract attachCanvasMouseMoveHandler(svg: SVGSelection): void;

  /**
   * Renders the data for the canvases.
   * @param targetContexts The target contexts.
   * @param renderInformation The render information.
   */
  protected abstract renderContext(targetContexts: ReadonlyArray<CanvasRenderingContext2D>, renderInformation: TRenderInformation): void;
}

/**
 * Information about the canvases we're rending to.
 */
export abstract class RenderInformation {

  /**
   * The canvases we're rendering to.
   */
  public readonly canvases: Canvases = new Canvases();

  /**
   * Gets the target canvas contexts.
   * @param isFirstCall True if this is the first call.
   */
  public abstract getTargetCanvasContexts(isFirstCall: boolean): ReadonlyArray<CanvasRenderingContext2D>;
}

/**
 * Default render information.
 */
export class DefaultRenderInformation extends RenderInformation {

  /**
   * @inheritdoc
   */
  public getTargetCanvasContexts(isFirstCall: boolean): ReadonlyArray<CanvasRenderingContext2D> {
    return this.canvases.all.map(v => v.canvasContext);
  }
}

/**
 * A collection of canvas information.
 */
export class Canvases {

  /**
   * The list of canvas information.
   */
  private readonly list: CanvasInformation[] = [];

  /**
   * A map of canvas information by CSS class.
   */
  private readonly map: { [cssPrefix: string]: CanvasInformation } = {};

  /**
   * Add a canvas information to the collection.
   * @param item The canvas information to add.
   */
  public add(item: CanvasInformation) {
    this.list.push(item);
    this.map[item.cssClass] = item;
  }

  /**
   * Gets the number of canvases.
   */
  public get count(): number {
    return this.list.length;
  }

  /**
   * Gets all the canvas information.
   */
  public get all(): ReadonlyArray<CanvasInformation> {
    return [...this.list];
  }

  /**
   * Gets the canvas information at the specified index.
   * @param index The index.
   * @returns The canvas information.
   */
  public getByIndex(index: number): CanvasInformation {
    return this.list[index];
  }

  /**
   * Gets the canvas information by CSS class.
   * @param cssPrefix The CSS class.
   * @returns The canvas information.
   */
  public getByCssClass(cssPrefix: string): CanvasInformation {
    return this.map[cssPrefix];
  }

  /**
   * Gets the first canvas information.
   */
  public get first(): CanvasInformation {
    return this.list[0];
  }
}

/**
 * Information about a canvas.
 */
export class CanvasInformation {

  /**
   * Creates a new instance of CanvasInformation.
   * @param cssClass The CSS class for the canvas.
   * @param canvasUpdate The canvas update selection.
   * @param canvasEnter The canvas enter selection.
   * @param canvas The canvas selection.
   * @param canvasContext The canvas context.
   */
  constructor(
    public readonly cssClass: string,
    public readonly canvasUpdate: d3.Selection<HTMLCanvasElement, any, BaseType, any>,
    public readonly canvasEnter: d3.Selection<HTMLCanvasElement, any, BaseType, any>,
    public readonly canvas: d3.Selection<HTMLCanvasElement, any, BaseType, any>,
    public readonly canvasContext: CanvasRenderingContext2D) {
  }
}

import {
  ConfigStub, DocumentSubType,
  DocumentNamesResultType,
  getDocumentSubTypeValues
} from '../../../../generated/api-stubs';
import {Injectable} from '@angular/core';
import { AuthenticationService } from '../../../identity/state/authentication.service';

@Injectable()
export class SavedConfigNamesCacheFactory{
  constructor(
    private configStub: ConfigStub,
    private authenticationService: AuthenticationService){
  }

  public create(simVersion: string): SavedConfigNamesCache{
    const userData = this.authenticationService.userDataSnapshot;
    return new SavedConfigNamesCache(this.configStub, userData.tenant, simVersion);
  }
}

export class SavedConfigNamesCache {
  private cache: {[path: string]: Promise<string[]>} = {};

  constructor(
    private configStub: ConfigStub,
    private tenantId: string,
    private simVersion: string){
  }

  public get(path: string): Promise<string[]>{

    if(!path){
      return Promise.resolve([]);
    }

    let result = this.cache[path];
    if(result){
      return result;
    }

    result = this.getConfigNames(path);
    this.handleErrors(path, result);
    this.cache[path] = result;
    return result;
  }

  private handleErrors(path: string, result: Promise<string[]>){
    result.catch(() => {
      this.cache[path] = undefined;
    });
  }

  private async getConfigNames(path: string): Promise<string[]>{
    let permittedConfigTypes = getDocumentSubTypeValues();
    let firstSeparatorIndex = path.indexOf('.');
    let configType: DocumentSubType;
    let subTreePath: string;

    if(firstSeparatorIndex === -1) {
      configType = <DocumentSubType>path;
      subTreePath = undefined;
    } else {
      configType = <DocumentSubType>path.substr(0, firstSeparatorIndex);
      subTreePath = path.substr(firstSeparatorIndex + 1);
    }

    if(permittedConfigTypes.indexOf(configType) === -1){
      return [];
    }

    let result = await this.configStub.getConfigNames(this.tenantId, <DocumentSubType>configType, DocumentNamesResultType.name, subTreePath, this.simVersion);
    return result.names.map(v => v.name);
  }
}

import {Injectable} from '@angular/core';
import {ConfigReference, ConfigStub, DocumentSubType} from '../../generated/api-stubs';
import {StudyStagingArea} from '../simulations/study-staging-area/study-staging-area.service';
import {TELEMETRY_SCHEMA_SIM_VERSION} from '../simulations/studies/stage-study.service';
import {CustomPropertyUtilities} from '../simulations/custom-properties/custom-property-utilities';
import {LoadConfigFromReference} from './load-config-from-reference';
import {GetSimVersion} from '../common/get-sim-version.service';
import {LoadingDialog} from '../common/dialogs/loading-dialog.service';

/**
 * A service to stage the config identified by the given reference.
 */
@Injectable()
export class StageConfigReference {

  /**
   * Creates an instance of StageConfigReference.
   * @param configStub The config stub service.
   * @param loadingDialog The loading dialog service.
   * @param loadConfigFromReference The service to load a config from a reference.
   * @param getSimVersion The service to get the current simulation version for the user.
   * @param studyStagingArea The study staging area service.
   */
  constructor(
    private readonly configStub: ConfigStub,
    private readonly loadingDialog: LoadingDialog,
    private readonly loadConfigFromReference: LoadConfigFromReference,
    private readonly getSimVersion: GetSimVersion,
    private readonly studyStagingArea: StudyStagingArea) {
  }

  /**
   * Stages the config identified by the given reference.
   * @param configType The config type.
   * @param configReference The reference to the config.
   * @param configName The name of the config.
   */
  public async execute(configType: DocumentSubType, configReference: ConfigReference, configName: string){

    // Handle a telemetry config.
    if(configType === DocumentSubType.telemetry){
      const config = await this.loadingDialog.showUntilFinished(
        this.loadConfigFromReference.execute(configType, configReference),
        'Loading config...');

      this.studyStagingArea.stage(
        configType,
        undefined,
        undefined,
        (configName || config.name || 'Config') + (configReference.tenant.jobIndex ? ` - Job Index ${configReference.tenant.jobIndex}` : ''),
        config.data,
        [],
        undefined,
        TELEMETRY_SCHEMA_SIM_VERSION,
        false);

      return;
    }

    // Handle a default config.
    if(configReference.default){
      const config = await this.loadingDialog.showUntilFinished(
        this.loadConfigFromReference.execute(configType, configReference),
        'Loading config...');

      this.studyStagingArea.stage(
        configType,
        undefined,
        configReference.default.name,
        configName,
        config.data,
        [],
        undefined,
        this.getSimVersion.currentSimVersion,
        false);

      return;
    }

    // Handle a tenant config.
    let configResult = await this.loadingDialog.showUntilFinished(
      this.configStub.getConfig(
        configReference.tenant.tenantId,
        configReference.tenant.targetId,
        undefined,
        this.getSimVersion.currentSimVersion),
        'Loading config...');

    let customProperties = CustomPropertyUtilities.objectToList(configResult.config.properties);
    this.studyStagingArea.stage(
      configType,
      configResult.config.userId,
      configResult.config.documentId,
      configResult.config.name,
      configResult.config.data,
      customProperties,
      configResult.config.notes,
      configResult.convertedSimVersion,
      false);
  }
}

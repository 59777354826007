import {RowViewModel} from './row-view-model';
import {MenuItemsDefinition} from '../context-menu/context-menu-types';
import {ClipboardContent} from './worksheet-clipboard.service';
import {IWorksheetWrappedContextMenuBuilder} from './worksheet-wrapped-context-menu-builder';
import {CommandResult, WorksheetContext} from './worksheet-commands/worksheet-command';

/**
 * The view model for an item in a row. This is the base class used
 * by the ConfigViewModel, StudyViewModel, SimulationViewModel and RowMetadataViewModel classes.
 */
export abstract class RowItemViewModel {

  /**
   * The context menu builder for the item.
   */
  private contextMenuBuilder: IWorksheetWrappedContextMenuBuilder;

  /**
   * Creates an instance of RowItemViewModel.
   * @param row The row view model containing this row item.
   */
  protected constructor(
    public readonly row: RowViewModel) {
  }

  /**
   * Called when the user wants to paste the clipboard rows into the worksheet using this row item
   * as the primary target. This bubbles up to the row view model.
   * @param content The clipboard content.
   * @param forceDuplication If true, the configs on the rows will always be duplicated.
   * @param removeExisting If true, the existing rows will be removed before pasting
   * (rather than having individual items replaced and non-conflicting items left as-is).
   */
  public async pasteRowsAsync(content: ClipboardContent, forceDuplication: boolean, removeExisting: boolean) {
    await this.row.pasteRowsAsync(content, forceDuplication, removeExisting);
  }

  /**
   * Gets a value indicating whether the item is selected.
   */
  public abstract get isSelected(): boolean;

  /**
   * Requests that any items in the worksheet which match the current item are highlighted.
   */
  public abstract setItemsMatching(): void;

  /**
   * Requests that any items in the worksheet which match the current item are not highlighted.
   */
  public abstract clearItemsMatching(): void;

  /**
   * Overwrites the context menu builder for the item, if one is provided.
   * @param builder The context menu builder.
   */
  public setContextMenuBuilder(builder: IWorksheetWrappedContextMenuBuilder) {
    if(builder){
      this.contextMenuBuilder = builder;
    }
  }

  /**
   * Removes the specified context menu builder for the item, if it is set.
   * @param builder The context menu builder.
   */
  public removeContextMenuBuilder(builder: IWorksheetWrappedContextMenuBuilder) {
    if(this.contextMenuBuilder === builder){
      this.contextMenuBuilder = undefined;
    }
  }

  /**
   * Generates the context menu for the item.
   * @param worksheetContext The worksheet context.
   * @returns The context menu items.
   */
  public generateContextMenu(worksheetContext: WorksheetContext): MenuItemsDefinition<CommandResult> {
    if(this.contextMenuBuilder) {
      return this.contextMenuBuilder.generateWrappedContextMenu(worksheetContext);
    }

    return new MenuItemsDefinition([], undefined);
  }
}

import {ConnectionError, CustomError, NotFoundError, PreconditionFailedError} from '../../errors';
import {UnauthenticatedError} from '../../errors';
import {UnauthorizedError} from '../../errors';
import {ApiError} from '../../errors';
import {UNEXPECTED_ERROR_TEXT, PRECONDITION_FAILED_ERROR_TEXT} from '../../../constants';
import { Injectable } from '@angular/core';

@Injectable()
export class GetHttpError {

  public execute(response: any): CustomError {
    if (response.status === 0) {
      return new ConnectionError(response, response);
    } else if (response.status === 401) {
      return new UnauthenticatedError('Not authenticated.', response);
    } else if (response.status === 403) {
      return new UnauthorizedError('Not authorized.', response);
    } else if(response.status === 404) {
      return new NotFoundError('Not found.', response);
    } else {
      let message;
      let data = response.error || response.body;
      if (data) {
        message = data.message || data.Message || data.error_description;
      }

      if(response.status === 412){
        return new PreconditionFailedError(message || PRECONDITION_FAILED_ERROR_TEXT);
      }

      return new ApiError(message || UNEXPECTED_ERROR_TEXT, response);
    }
  }
}

import {GetStudyQueryResult, PostStudyResult, SimType, StudyReference} from '../../generated/api-stubs';
import {Injectable} from '@angular/core';
import {CanopyFileLoaderFactory} from '../simulations/visualizations/canopy-file-loader.service';
import {LoadingDialog} from '../common/dialogs/loading-dialog.service';
import {ConvertTelemetryConfigToStudy} from '../simulations/configs/convert-telemetry-config-to-study.service';
import { AuthenticationService } from '../identity/state/authentication.service';

/**
 * The input telemetry files are stored as if they were vector result .bin files
 * with a sim type of 'Input'.
 */
const InputSimType = 'Input' as any as SimType;

/**
 * Creates a telemetry study from the telemetry input data of another study.
 */
@Injectable()
export class RecreateTelemetryStudyFromInputData {

  /**
   * Creates an instance of RecreateTelemetryStudyFromInputData.
   * @param authenticationService The authentication service.
   * @param canopyFileLoaderFactory The canopy file loader factory.
   * @param convertTelemetryConfigToStudy The service for converting telemetry configs to telemetry studies.
   * @param loadingDialog The loading dialog.
   */
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly canopyFileLoaderFactory: CanopyFileLoaderFactory,
    private readonly convertTelemetryConfigToStudy: ConvertTelemetryConfigToStudy,
    private readonly loadingDialog: LoadingDialog){
  }

  public async execute(
    studyName: string,
    studyResult: GetStudyQueryResult,
    telemetryJobIndex: number): Promise<StudyReference> {

    // Create a file loader (as used by the visualizations) so we can load
    // files from the study results.
    const fileLoader = this.canopyFileLoaderFactory.create();

    // Add the study to the file loader so it can load files from it.
    // This creates a cache for the results, which is less important to us
    // here but is important for visualizations so that's just how it works.
    fileLoader.addStudy(
      studyResult.study.tenantId,
      studyResult.study.documentId,
      studyResult.accessInformation,
      studyResult.convertedSimVersion);

    // Load the vector metadata for "Input" sim type for the study, which will give us
    // all the telemetry input channel metadata.
    const vectorMetadata = await this.loadingDialog.showUntilFinished(
      fileLoader.loadVectorMetadata(
        studyResult.study.documentId,
        telemetryJobIndex,
        InputSimType),
      'Loading input metadata...');

    // Load the data for each input channel.
    let channels: { name: string; units: string; description: string; data: number[] }[] = [];
    for(let item of vectorMetadata){
      const data = await this.loadingDialog.showUntilFinished(
        fileLoader.loadChannelData(
          studyResult.study.documentId,
          telemetryJobIndex,
          InputSimType,
          item.name,
          item.binaryFormat),
        'Loading ' + item.name + '...');

      channels.push({
        name: item.name,
        units: item.units,
        description: item.description,
        data: Array.from(data)
      });
    }

    // Convert the telemetry input data to a study.
    const telemetryStudyResult = <PostStudyResult>await this.loadingDialog.showUntilFinished(
      this.convertTelemetryConfigToStudy.execute(
        {
          channels,
        },
        studyResult.convertedSimVersion,
        studyName,
        false,
        undefined,
        undefined),
    'Creating telemetry study...');

    const userData = this.authenticationService.userDataSnapshot;

    // Return the reference to the new telemetry study.
    return {
      tenantId: userData.tenant,
      targetId: telemetryStudyResult.studyId,
    };
  }
}

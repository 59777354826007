import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {Timer} from '../../common/timer.service';
import {Injectable} from '@angular/core';
import {CarConfigValidationSession} from './car-config-validation-session';
import {CanopyPusher} from '../../common/canopy-pusher.service';
import {JobViewModelFactory} from '../jobs/job-results/job-view-model';
import {CustomProperty} from '../custom-properties/custom-properties';
import {StudyStub} from '../../../generated/api-stubs';
import {CarConfigValidationInlineSession} from './car-config-validation-inline-session';
import {StudyUtilities} from '../studies/study-utilities.service';
import { AuthenticationService } from '../../identity/state/authentication.service';

@Injectable()
export class CarConfigValidationSessionFactory{
  constructor(
    private readonly timer: Timer,
    private readonly studyUtilities: StudyUtilities,
    private readonly authenticationService: AuthenticationService,
    private readonly studyStub: StudyStub,
    private readonly canopyPusher: CanopyPusher,
    private readonly jobViewModelFactory: JobViewModelFactory,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog
  ){}

  public createNormalStudySession(
    editorValue: any,
    simVersion: string,
    userId: string,
    configId: string,
    name: string,
    isEdited: boolean,
    customProperties: ReadonlyArray<CustomProperty>){
    return new CarConfigValidationSession(
      editorValue,
      simVersion,
      userId,
      configId,
      name,
      isEdited,
      customProperties,
      this.timer,
      this.authenticationService,
      this.studyStub,
      this.canopyPusher,
      this.jobViewModelFactory,
      this.getFriendlyErrorAndLog);
  }

  public createInlineStudySession(
    editorValue: any,
    simVersion: string,
    userId: string,
    configId: string,
    name: string,
    isEdited: boolean,
    customProperties: ReadonlyArray<CustomProperty>){
    return new CarConfigValidationInlineSession(
      editorValue,
      simVersion,
      userId,
      configId,
      name,
      isEdited,
      customProperties,
      this.studyUtilities,
      this.authenticationService,
      this.studyStub,
      this.canopyPusher,
      this.jobViewModelFactory,
      this.getFriendlyErrorAndLog);
  }
}

import { SimType } from '../../sim-type';
import { UrlFileLoader } from '../../url-file-loader';
import { DisplayableError } from '../../displayable-error';
import { StudyExplorationAndScalarData, LoadStudyExplorationAndScalarData } from './load-study-exploration-and-scalar-data';


/**
 * A cache for study exploration and scalar data.
 */
export class StudyExplorationAndScalarDataCache {
  private data?: StudyExplorationAndScalarData | undefined;

  /**
   * Creates a new cache for study exploration and scalar data.
   * @param studyId The study ID.
   * @param simTypes The simulation types.
   * @param loadStudyExplorationAndScalarData The service for loading study exploration and scalar data.
   */
  constructor(
    private studyId: string,
    private simTypes: ReadonlyArray<SimType>,
    private loadStudyExplorationAndScalarData: LoadStudyExplorationAndScalarData) {
  }

  /**
   * Creates a new cache for study exploration and scalar data.
   * @param studyId The study ID.
   * @param simTypes The simulation types.
   * @param urlFileLoader The URL file loader.
   */
  public static create(
    studyId: string,
    simTypes: ReadonlyArray<SimType>,
    urlFileLoader: UrlFileLoader) {

    return new StudyExplorationAndScalarDataCache(
      studyId,
      simTypes,
      LoadStudyExplorationAndScalarData.create(urlFileLoader));
  }

  /**
   * Initializes the cache.
   */
  public async initialize(): Promise<void>{
    this.data = await this.loadStudyExplorationAndScalarData.execute(this.studyId, this.simTypes);
    if (!this.data) {
      throw new DisplayableError('No exploration or scalar data found.');
    }
  }

  /**
   * Gets the study exploration and scalar data. This does not initialize the cache.
   * @returns The study exploration and scalar data, or undefined if the cache is not initialized.
   */
  public get(): StudyExplorationAndScalarData | undefined {
    return this.data;
  }
}

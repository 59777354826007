import {Injectable} from '@angular/core';
import {InterceptorBase} from './interceptor-base.service';
import {HttpResponse} from '@angular/common/http';
import {Timer} from '../timer.service';

@Injectable()
export class RetryOnNetworkErrorInterceptor extends InterceptorBase {
  constructor(
    private readonly timer: Timer  ) {
    super();
  }

  public async responseError(response: HttpResponse<any>, retryCount: number): Promise<boolean> {
    if (response.status === 0 && retryCount <= 10) {
      await this.timer.delay(5000);
      return true;
    }

    return false;
  }
}

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FileDownloadMetadata, SimVersionStub} from '../../../generated/api-stubs';
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {GetSimVersion} from '../../common/get-sim-version.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'cs-tenant-downloads',
  templateUrl: './tenant-downloads.component.html',
  styleUrls: ['./tenant-downloads.component.scss']
})
export class TenantDownloadsComponent implements OnInit, OnDestroy {

  @Input() public tenantId: string;

  public errorMessage: string;

  public downloads: ReadonlyArray<FileDownloadMetadata>;

  private subscription: Subscription;

  constructor(
    private readonly getSimVersion: GetSimVersion,
    private readonly simVersionStub: SimVersionStub,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) { }

  ngOnInit() {
    this.load();
    this.subscription = this.getSimVersion.changed.subscribe(() => this.load());
  }

  ngOnDestroy(){
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }

  async load(){
    try{
      this.downloads = undefined;
      let downloadsResult = await this.simVersionStub.getDownloads(this.getSimVersion.currentSimVersion || 'current', this.tenantId);
      this.downloads = downloadsResult.downloads;
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }
}

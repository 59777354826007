import {ResolvedLabel, SimType, SimulationResolvedLabels} from '../../generated/api-stubs';
import {WorksheetUnderlyingData} from './worksheet-underlying-data';
import {RowViewModel} from './row-view-model';
import {RowItemViewModel} from './row-item-view-model';

/**
 * The view model for a Simulation item on a row.
 */
export class SimulationViewModel extends RowItemViewModel {

  /**
   * The view model for a populated simulation row item.
   */
  public populated: PopulatedSimulationViewModel | undefined;

  /**
   * The view model for an unpopulated simulation row item.
   */
  public readonly unpopulated: UnpopulatedSimulationViewModel;

  /**
   * Creates a new SimulationViewModel.
   * @param parent The parent row view model.
   * @param simType The simulation type.
   * @param labels The resolved labels for the simulation.
   * @param underlyingData The underlying data for the worksheet.
   */
  constructor(
    parent: RowViewModel,
    simType: SimType,
    labels: SimulationResolvedLabels,
    private underlyingData: WorksheetUnderlyingData) {
    super(parent);

    if(labels){
      this.populated = new PopulatedSimulationViewModel();
    }

    this.unpopulated = new UnpopulatedSimulationViewModel(simType);

    this.update(labels, underlyingData);
  }

  /**
   * Updates the view model with new data.
   * @param simulationResolvedLabels The resolved labels for the simulation.
   * @param underlyingData The underlying data for the worksheet.
   */
  public update(simulationResolvedLabels: SimulationResolvedLabels, underlyingData: WorksheetUnderlyingData) {
    this.underlyingData = underlyingData;
    if(this.populated){
      this.populated.update(simulationResolvedLabels, this.underlyingData);
    }

    this.unpopulated.update(this.underlyingData);
  }

  /**
   * Gets whether the simulation is selected. A simulation is selected if it's study is selected.
   */
  public get isSelected() {
    return this.row.study.isSelected;
  }

  /**
   * @inheritdoc
   */
  public setItemsMatching() {
    this.row.worksheet.setItemsMatching(this.row.study.reference);
  }

  /**
   * @inheritdoc
   */
  public clearItemsMatching() {
    this.row.worksheet.clearItemsMatching();
  }

  /**
   * Gets whether the simulation has been resolved (resolving is the process of mapping a reference to
   * the underlying data).
   */
  public get isResolved(): boolean {
    return !!(this.populated);
  }
}

/**
 * The view model for a populated simulation.
 */
export class PopulatedSimulationViewModel {

  /**
   * The resolved labels for the simulation.
   */
  public resolvedLabels: ReadonlyArray<ResolvedLabel>;

  /**
   * Updates the view model with new data.
   * @param simulationResolvedLabels The resolved labels for the simulation.
   * @param underlyingData The underlying data for the worksheet.
   */
  public update(simulationResolvedLabels: SimulationResolvedLabels, underlyingData: WorksheetUnderlyingData) {
    if(simulationResolvedLabels){
      this.resolvedLabels = simulationResolvedLabels.resolvedLabels;
    }
  }
}

/**
 * The view model for an unpopulated simulation.
 */
export class UnpopulatedSimulationViewModel {

  /**
   * Creates a new UnpopulatedSimulationViewModel.
   * @param simType The simulation type.
   */
  constructor(
    public readonly simType: SimType) {
  }

  /**
   * Updates the view model with new data.
   * @param underlyingData The underlying data for the worksheet.
   */
  public update(underlyingData: WorksheetUnderlyingData) {
  }
}

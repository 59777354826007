import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import { Input, OnInit, Directive } from '@angular/core';
import {TabViewModel} from './tab-view-model';
import {JobViewModel} from './job-view-model';

@Directive()
export abstract class ViewJobResultsSectionBase implements OnInit {
  @Input() public tab: TabViewModel;
  @Input() public jobs: JobViewModel[];

  public errorMessage: string;
  public hasBeenActivated: boolean = false;
  public isLoaded: boolean = false;

  constructor(
    protected getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  public ngOnInit(){
    if(this.tab){
      this.tab.activated.subscribe(() => this.onActivated());
      if(this.tab.isActive){
        this.onActivated();
      }
    } else {
      this.onActivated();
    }
  }

  private async onActivated() {
    try{
      if(this.hasBeenActivated){
        return;
      }

      this.hasBeenActivated = true;
      await this.load();
      this.isLoaded = true;
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  protected abstract load(): Promise<void>;
}

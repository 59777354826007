import { SimType } from './sim-type';
import { UrlFileLoaderBase } from './url-file-loader-base';
import {
  UrlFileLoader,
  StudyScalarResults,
  SingleScalarResult,
  ChannelMetadata,
  ChannelBinaryFormat, StudyMetadata
} from './url-file-loader';
import { ExplorationMap } from './viewers/channel-data-loaders/exploration-map';
import { PopulateTrackRacingLineFromSimulation } from './populate-track-racing-line-from-simulation';

/**
 * The folder for manual tests data.
 */
export const MANUAL_TESTS_FOLDER = 'manual-tests';

/**
 * The folder for automated tests data.
 */
export const AUTOMATED_TESTS_FOLDER = 'automated-tests';

/**
 * The folder for JSON data when running automated tests.
 */
export const CANOPY_SIMS_JSON_FOLDER = 'canopy-sims-json';

/**
 * Get the URL targeting the manual tests folder.
 * @param url The URL.
 * @returns The URL targeting the manual tests folder.
 */
export function manualTests(url: string): string {
  return '/' + MANUAL_TESTS_FOLDER + encodeURI(url);
}

/**
 * Get the URL targeting the automated tests folder.
 * @param url The URL.
 * @returns The URL targeting the automated tests folder.
 */
export function automatedTests(url: string): string {
  return '/' + AUTOMATED_TESTS_FOLDER + encodeURI(url);
}

/**
 * Performs the action for the automated tests folder, and then the manual tests folder if the former
 * returns a falsy result.
 * @param url The URL.
 * @param d The function to perform.
 * @returns The result of the first function that returns a truthy result.
 */
export async function forUrlLocations<T>(url: string, d: (url: string) => Promise<T>) {
  return await d(automatedTests(url))
    || await d(manualTests(url));
}

/**
 * An implementation of the UrlFileLoader that loads files from the local URL for automated tests.
 */
export class LocalUrlFileLoader extends UrlFileLoaderBase implements UrlFileLoader {

  /** @inheritDoc */
  public async loadVectorMetadata(studyId: string, jobIndex: number, simType: SimType): Promise<ChannelMetadata[]> {
    let rawMetadata = await this.loadCsv('/studies/' + studyId + '/' + this.createJobId(studyId, jobIndex) + '/' + simType + '_VectorMetadata.csv');
    return (rawMetadata || []).map(v => ({
      name: v.name,
      simType,
      description: v.description,
      units: v.units,
      xDomainName: v.xDomainName,
      binaryFormat: new ChannelBinaryFormat(+v.NPtsInChannel),
    }));
  }

  /** @inheritDoc */
  public async loadScalarResultsForSim(studyId: string, jobIndex: number, simType: SimType): Promise<SingleScalarResult[]> {
    let scalarResults = await this.loadCsv('/studies/' + studyId + '/' + this.createJobId(studyId, jobIndex) + '/' + simType + '_ScalarResults.csv');
    return this.processScalarResultsForSim(scalarResults);
  }

  /** @inheritDoc */
  public async loadScalarResultsForStudy(studyId: string): Promise<StudyScalarResults> {
    let resultsTask = this.loadCsvRows('/studies/' + studyId + '/scalar-results.csv');
    let metadataTask = await this.loadCsv('/studies/' + studyId + '/scalar-metadata.csv');
    let inputsMetadata = await this.loadCsv('/studies/' + studyId + '/scalar-inputs-metadata.csv');
    let results = await resultsTask;
    let metadata = await metadataTask;

    let parsedResults = this.createScalarResultsUsingScalarMetadata(results, metadata);
    return {
      data: parsedResults,
      metadata,
      inputsMetadata: inputsMetadata || []
    };
  }

  /** @inheritDoc */
  public async loadGPParamsForStudy(studyId: string): Promise<any[]> {
    let Alpha = await this.loadCsv('/studies/' + studyId + '/GP-Alpha.csv');
    let X = await this.loadCsvRows('/studies/' + studyId + '/GP-X.csv');
    let YMeans = await this.loadCsv('/studies/' + studyId + '/GP-YMeans.csv');
    let YStdDevs = await this.loadCsv('/studies/' + studyId + '/GP-YStdDevs.csv');
    let CovHyperParams = await this.loadJson('/studies/' + studyId + '/GP-CovHyperParams.json');
    return [Alpha, X, YMeans[0], YStdDevs[0], CovHyperParams];
  }

  /** @inheritDoc */
  public loadChannelData(studyId: string, jobIndex: number, simType: SimType, channelName: string, binaryFormat: ChannelBinaryFormat | undefined): Promise<any> {
    return this.loadChannelDataByFileName(studyId, jobIndex, simType + '_' + channelName + '.bin', binaryFormat);
  }

  /** @inheritDoc */
  public loadExplorationMap(studyId: string): Promise<ExplorationMap> {
    return this.loadJson('/studies/' + studyId + '/exploration-map.json');
  }

  /** @inheritDoc */
  public loadChartLayout(layoutId: string): Promise<any> {
    return this.loadJson('/' + layoutId + '.json', true);
  }

  /** @inheritDoc */
  public loadJsonFile(path: string): Promise<any> {
    return this.loadJson(path);
  }

  /** @inheritDoc */
  public async loadStudyMetadata(studyId: string) {
    let jobDocument = await this.loadStudyJobDocument(studyId, 0);
    return new StudyMetadata(
      studyId,
      jobDocument ? jobDocument.simVersion : '1.3375');
  }

  /** @inheritDoc */
  public async loadTrackForStudy(studyId: string): Promise<any> {
    let study = await this.loadStudyBaseline(studyId);
    return study.simConfig.track;
    //return await this.loadJson('/tracks/' + job.simConfig.track.name + '.json');
  }

  /** @inheritDoc */
  public async loadTrackForStudyJob(studyId: string, jobIndex: number): Promise<any> {
    let job = (await this.loadStudyJobInputJson(studyId, jobIndex)) || (await this.loadStudyBaseline(studyId));
    let simType: string = job.simTypes[0];
    let jobDocument = await this.loadStudyJobDocument(studyId, jobIndex);
    let studyType = simType[0].toLowerCase() + simType.substr(1);

    let vectorMetadata = await this.loadVectorMetadata(studyId, jobIndex, simType);
    let populateRacingLine = new PopulateTrackRacingLineFromSimulation(
      studyType,
      jobDocument ? jobDocument.simVersion : '1.999999',
      vectorMetadata,
      (fileName, binaryFormat) => this.loadChannelDataByFileName(studyId, jobIndex, fileName, binaryFormat),
      (fileName) => this.loadJsonDataByFileName(studyId, jobIndex, fileName));

    return await populateRacingLine.execute(job.simConfig.track);
  }

  /** @inheritDoc */
  public async loadCarForStudy(studyId: string): Promise<any> {
    let job = await this.loadStudyBaseline(studyId);
    return job.simConfig.car;
  }

  /** @inheritDoc */
  public loadCarForStudyJob(studyId: string, jobIndex: number): Promise<any> {
    return this.loadCarForStudy(studyId);
  }

  /**
   * Load the channel data for the given study, job, and file name.
   * @param studyId The study ID.
   * @param jobIndex The job index.
   * @param fileName The file name.
   * @param binaryFormat The binary format for the channel.
   * @returns A promise that resolves to the channel data.
   */
  private loadChannelDataByFileName(studyId: string, jobIndex: number, fileName: string, binaryFormat: ChannelBinaryFormat | undefined): Promise<ReadonlyArray<number>> {
    return this.loadNumericArray('/studies/' + studyId + '/' + this.createJobId(studyId, jobIndex) + '/' + fileName, binaryFormat);
  }

  /**
   * Load the JSON data for the given study, job, and file name.
   * @param studyId The study ID.
   * @param jobIndex The job index.
   * @param fileName The file name.
   * @returns A promise that resolves to the JSON data.
   */
  private loadJsonDataByFileName(studyId: string, jobIndex: number, fileName: string): Promise<any> {
    return this.loadJson('/studies/' + studyId + '/' + this.createJobId(studyId, jobIndex) + '/' + fileName);
  }

  /**
   * Load the study baseline for the given study.
   * @param studyId The study ID.
   * @returns A promise that resolves to the study baseline.
   */
  public loadStudyBaseline(studyId: string): Promise<any> {
    return this.loadJson('/studies/' + studyId + '/study-baseline.json');
  }

  /**
   * Load the study job input JSON for the given study and job.
   * @param studyId The study ID.
   * @param jobIndex The job index.
   * @returns A promise that resolves to the study job input JSON.
   */
  public loadStudyJobInputJson(studyId: string, jobIndex: number): Promise<any> {
    return this.loadJson('/studies/' + studyId + '/' + jobIndex + '/job.json');
  }

  /**
   * Load the study job document for the given study and job.
   * @param studyId The study ID.
   * @param jobIndex The job index.
   * @returns A promise that resolves to the study job document.
   */
  public loadStudyJobDocument(studyId: string, jobIndex: number): Promise<any> {
    return this.loadJson('/studies/' + studyId + '/' + jobIndex + '/job-document.json');
  }

  /**
   * Gets the job ID from the study ID and job index.
   * @param studyId The study ID.
   * @param jobIndex The job index.
   * @returns The job ID.
   */
  private createJobId(studyId: string, jobIndex: number) {
    return jobIndex.toString();
  }

  /**
   * A cache of the previously loaded JSON documents.
   */
  private jsonCache: { [url: string]: Promise<any> } = {};

  /** @inheritDoc */
  protected loadJson(url: string, exactUrl: boolean = false): Promise<any> {
    let cached = this.jsonCache[url];
    if (!cached) {
      if (exactUrl) {
        cached = super.loadJson(url);
      } else {
        cached = forUrlLocations<any>(url, (v) => super.loadJson(v));
      }
      this.jsonCache[url] = cached;
    }

    return cached;
  }

  /** @inheritDoc */
  public loadCsv(url: string): Promise<any[]> {
    return forUrlLocations<any[]>(url, (v) => super.loadCsv(v));
  }

  /** @inheritDoc */
  public loadCsvRows(url: string): Promise<any[]> {
    return forUrlLocations<any[]>(url, (v) => super.loadCsvRows(v));
  }

  /** @inheritDoc */
  public loadText(url: string): Promise<string> {
    return forUrlLocations<string>(url, (v) => super.loadText(v));
  }

  /** @inheritDoc */
  protected loadNumericArray(url: string, binaryFormat: ChannelBinaryFormat | undefined): Promise<ReadonlyArray<number>> {
    return forUrlLocations<ReadonlyArray<number>>(url, (v) => super.loadNumericArray(v, binaryFormat));
  }
}

import { Injectable } from '@angular/core';
import {StudyStub} from '../../generated/api-stubs';
import {StudyProgress} from '../common/canopy-pusher.service';
import { AuthenticationService } from '../identity/state/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class StudyMetadataCache {

  private readonly cache: { [studyId: string]: Promise<StudyMetadata | undefined> } = {};

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly studyStub: StudyStub) {
  }

  public get(studyId: string): Promise<StudyMetadata | undefined> {
    let result = this.cache[studyId];
    if(!result) {
      result = this.cache[studyId] = this.loadStudyMetadata(studyId);
    }

    return result;
  }

  public remove(studyId: string) {
    delete this.cache[studyId];
  }

  public set(tenantId: string, studyId: string, name: string) {
    this.cache[studyId] = Promise.resolve(new StudyMetadata(tenantId, studyId, name));
  }

  private async loadStudyMetadata(studyId: string): Promise<StudyMetadata | undefined> {
    try {
      const userData = this.authenticationService.userDataSnapshot;
      const studyResult = await this.studyStub.getStudyMetadata(userData.tenant, studyId);
      return new StudyMetadata(studyResult.study.tenantId, studyId, studyResult.study.name);
    } catch(error){
      this.cache[studyId] = undefined;
      console.error(error);
      return undefined;
    }
  }
}

export class StudyMetadata {
  constructor(
    public readonly tenantId: string,
    public readonly studyId: string,
    public readonly name: string) {
  }

  public progress: StudyProgress;
}

import * as d3 from '../d3-bundle';
import { ISize, Size } from './size';
import { IPosition } from './position';
import { BaseType } from 'd3-selection';

/**
 * Interface for canvas settings.
 */
export interface ICanvasSettings {

  /**
   * The size of the SVG element.
   */
  readonly svgSize: ISize;

  /**
   * The size of the canvas in retina pixels.
   */
  readonly canvasRetinaSize: ISize;

  /**
   * The pixel ratio of the device.
   */
  readonly pixelRatio: number;
}

/**
 * Interface for canvas data.
 */
export interface ICanvasData {

  /**
   * The parent element of the canvas.
   */
  readonly parent: d3.Selection<HTMLDivElement, any, HTMLElement, any>;

  /**
   * The settings for the canvas.
   */
  readonly settings: ICanvasSettings;
}

/**
 * Utility class for working with canvas elements.
 */
export class CanvasUtilities {

  /**
   * The last recorded size of the SVG element.
   */
  private previousSvgSize: Size | undefined = undefined;

  /**
   * Get the canvas element for the given class name, using D3 data binding.
   * @param canvasData The canvas data.
   * @param className The class name of the canvas element.
   * @returns The canvas update, enter, and merged selection.
   */
  public getCanvas(canvasData: ICanvasData, className: string): [
    d3.Selection<HTMLCanvasElement, any, BaseType, any>,
    d3.Selection<HTMLCanvasElement, any, BaseType, any>,
    d3.Selection<HTMLCanvasElement, any, BaseType, any>
  ] {
    let canvasUpdate = canvasData.parent.selectAll<HTMLCanvasElement, any>(`.${className}`).data([null]);
    let canvasEnter = canvasUpdate.enter()
      .insert<HTMLCanvasElement>('canvas', 'svg')
      .attr('class', className);
    let canvas = canvasEnter.merge(canvasUpdate);

    let retinaSize = canvasData.settings.canvasRetinaSize;
    let svgSize = canvasData.settings.svgSize;

    if (!this.previousSvgSize || this.previousSvgSize.width !== svgSize.width || this.previousSvgSize.height !== svgSize.height) {
      // Setting the size clears the canvas, so only do it if the size has changed.
      canvas
        .attr('width', retinaSize.width)
        .attr('height', retinaSize.height)
        .style('width', svgSize.width + 'px')
        .style('height', svgSize.height + 'px');
    }

    return [
      canvasUpdate,
      canvasEnter,
      canvas
    ];
  }

  /**
   * Update the size of the SVG element based on the supplied canvas settings.
   * @param canvasSettings The canvas settings.
   */
  public updateSvgSize(canvasSettings: ICanvasSettings) {
    this.previousSvgSize = new Size(canvasSettings.svgSize.width, canvasSettings.svgSize.height);
  }

  /**
   * Reset the transform of the canvas context.
   * @param context The canvas rendering context.
   * @param pixelRatio The pixel ratio of the device.
   * @param initialPosition The initial position of the canvas.
   */
  public resetTransform(context: CanvasRenderingContext2D, pixelRatio: number, initialPosition?: IPosition) {
    context.setTransform(1, 0, 0, 1, 0, 0);
    context.scale(pixelRatio, pixelRatio);

    if (initialPosition) {
      context.translate(initialPosition.x, initialPosition.y);
    }
  }
}


import { ValueInterpolator } from './value-interpolator';
import { InterpolationCoordinates } from './interpolation-coordinates';
import { emptyChannelInterpolatorResults, ChannelInterpolatorResults } from './channel-interpolator-results';

/**
 * For the given set of channel names, interpolator and interpolation coordinates,
 * interpolate the values for each channel at each point interpolation coordinates.
 * @param channelNames The names of the channels to interpolate values for.
 * @param interpolator The interpolator to use.
 * @param interpolationCoordinates The coordinates to interpolate values for.
 * @returns The interpolated values for each channel at each point in the hypercube.
 */
export function interpolateRange(
  channelNames: ReadonlyArray<string>,
  interpolator: ValueInterpolator,
  interpolationCoordinates: InterpolationCoordinates): ChannelInterpolatorResults {

  let result: ChannelInterpolatorResults = emptyChannelInterpolatorResults(channelNames);
  for (let coordinates of interpolationCoordinates.getCoordinatesSets()) {
    let valueResults = interpolator(coordinates);
    for (let channelName of channelNames) {
      result[channelName].push(valueResults[channelName]);
    }
  }

  return result;
}

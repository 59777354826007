import { Injectable } from '@angular/core';
import { ActionContext, Actions } from '@ngxs/store';
import { addBreadcrumb } from '@sentry/angular';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SentryBreadcrumbHandler {
  constructor(private actions: Actions) {
    this.actions
      .pipe(
        filter((x: ActionContext<any>) => x?.action)
      )
      .subscribe(({ action, error, status }) => {
        let breadcrumb = {
          category: 'state.action',
          message: `${action.constructor.type}: ${status}`,
          data: error ?? action
        };
        addBreadcrumb(breadcrumb);
      });
  }
}

import { SourceData } from './types/source-data';
import { IMultiPlotLayout } from './types/i-multi-plot-layout';
import { SiteHooks } from '../../site-hooks';
import { SourceLoaderSet } from '../channel-data-loaders/source-loader-set';
import { GetDistinctChannelNames } from './get-distinct-channel-names';
import { ApplyModifiersToSourcesChannelData } from './apply-modifiers-to-sources-channel-data';
import { GetFeatureChannelNames } from './get-feature-channel-names';
import { GetSourceFeatureChannels } from './get-source-feature-channels';
import { LoadSourceChannelDataMap } from './load-source-channel-data-map';
import { NormalizeSourcesChannelData } from './normalize-sources-channel-data';


/**
 * For each source loader, returns a `SourceData` object containing the loaded channel data
 * as defined by the given layout.
 */
export class LoadChannelData {
  constructor(
    private readonly sourceLoaderSet: SourceLoaderSet,
    private readonly siteHooks: SiteHooks,
    private readonly getFeatureChannelNames: GetFeatureChannelNames,
    private readonly getDistinctChannelNames: GetDistinctChannelNames,
    private readonly loadSourceChannelDataMap: LoadSourceChannelDataMap,
    private readonly getSourceFeatureChannels: GetSourceFeatureChannels,
    private readonly normalizeSourcesChannelData: NormalizeSourcesChannelData,
    private readonly applyModifiersToSourcesChannelData: ApplyModifiersToSourcesChannelData
  ) {
  }

  /**
   * For each source loader, returns a `SourceData` object containing the loaded channel data
   * as defined by the given layout.
   *
   * @param layout - The layout which defines the channel data to load.
   * @returns An array of `SourceData` instances, one for each source loader.
   */
  public async execute(layout: IMultiPlotLayout): Promise<ReadonlyArray<SourceData>> {

    await this.siteHooks.ensureUnitsLoaded();

    // Gather all the channels we need to load.
    let featureChannelNames = await this.getFeatureChannelNames.execute();
    let channelNames = this.getDistinctChannelNames.execute(layout, featureChannelNames);
    let result: SourceData[] = [];

    // Note: We could wrap source loaders in FunctionSourceLoaderWrapper as a way of implementing
    // functions in the UI.
    let sourceIndex = 0;
    for (let source of this.sourceLoaderSet.sources) {
      // Load the channels for the source loader.
      let map = await this.loadSourceChannelDataMap.execute(source, channelNames);
      let metadata = await source.getSourceMetadata();

      // Pull out the feature channels into their own object.
      let featureChannels = this.getSourceFeatureChannels.execute(map, featureChannelNames[sourceIndex]);

      result.push(new SourceData(metadata.name, map, featureChannels, source));
      ++sourceIndex;
    }

    // Normalize the channel data. Currently this involves ensuring all data for a given channel
    // is in the same units across all sources.
    this.normalizeSourcesChannelData.execute(channelNames, result);

    // Apply any modifiers to the channel data. For example, if a channel is a delta channel
    // then replace the channel data with the delta.
    this.applyModifiersToSourcesChannelData.execute(layout, result);

    return result;
  }

}

import {Rule} from '../runner/rule';
import {ConfigContext} from '../runner/config-context';
import {RuleLogger} from '../runner/rule-logger';

export const LENGTH_TO_COMPARE = 100;

export class TrackSwappedLeftRight extends Rule {
  public name = 'Track Swapped Left/Right';
  public configTypes = 'track';
  public simTypes: string[] = null;

  public evaluateConfig(context: ConfigContext, log: RuleLogger) {
    let xTrackEdgeLeftNode = context.getValue('trackOutline.xTrackEdgeLeft');
    let yTrackEdgeLeftNode = context.getValue('trackOutline.yTrackEdgeLeft');
    let xTrackEdgeRightNode = context.getValue('trackOutline.xTrackEdgeRight');
    let yTrackEdgeRightNode = context.getValue('trackOutline.yTrackEdgeRight');

    if(!xTrackEdgeLeftNode || !yTrackEdgeLeftNode
      || !xTrackEdgeRightNode || !yTrackEdgeRightNode) {
      return;
    }

    let xTrackEdgeLeft: number[] = xTrackEdgeLeftNode.value;
    let yTrackEdgeLeft: number[] = yTrackEdgeLeftNode.value;
    let xTrackEdgeRight: number[] = xTrackEdgeRightNode.value;
    let yTrackEdgeRight: number[] = yTrackEdgeRightNode.value;

    if(xTrackEdgeLeft.length < 2 || yTrackEdgeLeft.length < 2
      || xTrackEdgeRight.length < 2 || yTrackEdgeRight.length < 2) {
      return;
    }

    let xLeft1 = xTrackEdgeLeft[0];
    let yLeft1 = yTrackEdgeLeft[0];

    let xRight1 = xTrackEdgeRight[0];
    let yRight1 = yTrackEdgeRight[0];
    let xRight2 = xTrackEdgeRight[1];
    let yRight2 = yTrackEdgeRight[1];

    let d = (xLeft1 - xRight1) * (yRight2 - yRight1) - (yLeft1 - yRight1) * (xRight2 - xRight1);

    if(d > 0){
      log.warn('Track outline left and right edges may need to be swapped.');
    }
  }
}

import { SourceData } from './types/source-data';
import { IMultiPlotChannel } from './types/i-multi-plot-channel';
import { ProcessedMultiPlotChannel } from './types/processed-multi-plot-channel';
import { AutoScaleChannelGroup } from '../channel-data-loaders/auto-scale-channel-group';
import { GetProcessedChannel } from './get-processed-channel';
import { ChannelIndexMap } from './channel-index-map';


/**
 * Gets the processed version of a single channel, and performs any required auto-scaling.
 */
export class GetProcessedRenderChannel {
  constructor(
    private readonly getProcessedChannel: GetProcessedChannel,
    private readonly autoScaleChannelGroup: AutoScaleChannelGroup) {
  }

  /**
   * Returns the processed version of a single channel, and performs any required auto-scaling.
   *
   * @param channel The channel to process.
   * @param sourceData The set of data sources.
   * @param channelIndexMap The channel index map.
   * @returns The processed channel.
   */
  public execute(channel: IMultiPlotChannel | undefined, sourceData: ReadonlyArray<SourceData>, channelIndexMap: ChannelIndexMap): ProcessedMultiPlotChannel | undefined {
    if (!channel) {
      return undefined;
    }

    // Get the processed channel.
    let result = this.getProcessedChannel.execute(channel, sourceData, channelIndexMap);

    // And perform any required auto-scaling.
    this.autoScaleChannelGroup.executeForChannel(result);

    return result;
  }
}

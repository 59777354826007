import {
  ConfigStub,
  DocumentSubType,
  GetStudyQueryResult,
  NewStudyDataSource,
  PostStudyResult,
  StudyReference,
  StudyStub,
  WorksheetConfig,
  WorksheetRow
} from '../../generated/api-stubs';
import {ClipboardContent} from './worksheet-clipboard.service';
import {GetSimVersion} from '../common/get-sim-version.service';
import {Injectable} from '@angular/core';
import {LoadingDialog} from '../common/dialogs/loading-dialog.service';
import {RecreateTelemetryStudyFromInputData} from './recreate-telemetry-study-from-input-data';
import {LoadStudy} from './load-study';
import { AuthenticationService, UserData } from '../identity/state/authentication.service';

/**
 * Creates a worksheet row from a study.
 * The inputs of the study will be extracted into config columns.
 * The study will be re-run if it doesn't belong to this tenant, otherwise it will be placed
 * on the same row as its config inputs.
 * Referenced telemetry studies will be re-created from the study inputs and placed on the same row.
 */
@Injectable()
export class CreateWorksheetRowFromStudy {

  /**
   * Creates a new instance of CreateWorksheetRowFromStudy.
   * @param loadingDialog The loading dialog service.
   * @param authenticationService The authentication service.
   * @param studyStub The study stub.
   * @param configStub The config stub.
   * @param getSimVersion The service for getting the current sim version.
   * @param loadStudyService The service for loading studies.
   * @param recreateTelemetryStudyFromInputData The service for recreating telemetry studies from the study input data.
   */
  constructor(
    private readonly loadingDialog: LoadingDialog,
    private readonly authenticationService: AuthenticationService,
    private readonly studyStub: StudyStub,
    private readonly configStub: ConfigStub,
    private readonly getSimVersion: GetSimVersion,
    private readonly loadStudyService: LoadStudy,
    private readonly recreateTelemetryStudyFromInputData: RecreateTelemetryStudyFromInputData){
  }

  /**
   * Creates a worksheet row from a study.
   * The inputs of the study will be extracted into config columns.
   * The study will be re-run if it doesn't belong to this tenant, otherwise it will be placed
   * on the same row as its config inputs.
   * Referenced telemetry studies will be re-created from the study inputs and placed on the same row.
   * @param tenantId The tenant ID.
   * @param studyId The study ID.
   * @param targetWorksheetId The target worksheet ID.
   * @param options The options for creating the worksheet.
   * @returns
   */
  public async execute(
    tenantId: string,
    studyId: string,
    targetWorksheetId: string,
    options: CreateWorksheetRowFromStudyOptions): Promise<WorksheetContent> {

    if(!options){
      options = CreateWorksheetRowFromStudyOptions.default();
    }

    const userData = this.authenticationService.userDataSnapshot;
    const simVersion = this.getSimVersion.currentSimVersion;

    const studyIndex = 0;

    // Load the study.
    const studyResult = await this.loadStudy(tenantId, studyId, simVersion, studyIndex);

    // Create the list to contain the resulting rows and append the rows based on the study.
    const rows: WorksheetRow[] = [];
    await this.appendRowsFromStudy(studyResult, targetWorksheetId, options, simVersion, userData, rows, studyIndex);

    return new WorksheetContent(
      new ClipboardContent(
        userData.tenant,
        targetWorksheetId,
        rows
      ));
  }

  /**
   * Loads a study, returning undefined if access is forbidden.
   * The loading dialog is displayed while the study is loading.
   * @param tenantId The tenant ID.
   * @param studyId The study ID.
   * @param simVersion The sim version.
   * @param studyIndex The study index, for displaying in the loading dialog.
   * @returns The loaded study, or undefined if access is forbidden.
   */
  private async loadStudyIfNotForbidden(
    tenantId: string,
    studyId: string,
    simVersion: string,
    studyIndex: number): Promise<GetStudyQueryResult> {
    return await this.loadingDialog.showUntilFinished(
      this.loadStudyService.tryLoadIfNotForbidden(tenantId, studyId, simVersion),
      `Loading ${this.getProgressStudyName(studyIndex)}...`);
  }

  /**
   * Loads a study.
   * The loading dialog is displayed while the study is loading.
   * @param tenantId The tenant ID.
   * @param studyId The study ID.
   * @param simVersion The sim version.
   * @param studyIndex The study index, for displaying in the loading dialog.
   * @returns The loaded study.
   */
  private async loadStudy(
    tenantId: string,
    studyId: string,
    simVersion: string,
    studyIndex: number): Promise<GetStudyQueryResult> {
    return await this.loadingDialog.showUntilFinished(
      this.loadStudyService.load(tenantId, studyId, simVersion),
      `Loading ${this.getProgressStudyName(studyIndex)}...`);
  }

  /**
   * Creates rows from a study and adds them to the list of rows.
   * @param studyResult The study from which to create rows.
   * @param targetWorksheetId The target worksheet ID.
   * @param options The options for creating the rows.
   * @param simVersion The sim version.
   * @param userData The user data.
   * @param rows The list of rows to which to add the created rows.
   * @param studyIndex The study index, for displaying in the loading dialogs.
   * @returns The reference to the study in the worksheet row (which may or may not be the input study reference).
   */
  private async appendRowsFromStudy(
    studyResult: GetStudyQueryResult,
    targetWorksheetId: string,
    options: CreateWorksheetRowFromStudyOptions,
    simVersion: string,
    userData: UserData,
    rows: WorksheetRow[],
    studyIndex: number): Promise<StudyReference> {

    const study = studyResult.study;
    const tenantId = study.tenantId;
    const studyId = study.documentId;

    // Pull out the input configs from the study.
    const simConfig = { ... study.data.definition.simConfig };
    const exploration = study.data.definition.exploration;
    if (exploration) {
      // We'll stick this here so it's extracted automatically in the loop below.
      simConfig[DocumentSubType.exploration] = exploration;
    }

    let studyData = {
      simTypes: studyResult.simTypes,
      simConfig: <any>{},
      exploration: undefined as any
    };
    let studyDataSources: NewStudyDataSource[] = [];

    const worksheetConfigs: WorksheetConfig[] = [];

    // For each input config...
    for (let key of Object.keys(simConfig)) {
      const configType = key as DocumentSubType;
      let configName = study.name;
      let configData = simConfig[key];
      const configSource = study.data.sources.find(v => v.configType === configType);
      if (configSource && configSource.name) {
        configName = configSource.name;
      }

      // Process the config. This could involve saving a copy, or running a telemetry
      // study on a new row, or just adding the data to the new study.
      const configResult = await this.processConfig(
        studyResult,
        options,
        userData,
        configName,
        configType,
        configData,
        simVersion,
        tenantId,
        targetWorksheetId,
        rows,
        studyIndex);

      const configId = configResult.configId;

      // If the processed config is something we should add to the row, do so.
      if(configResult.worksheetConfig){
        worksheetConfigs.push(configResult.worksheetConfig);
      }

      // Add the config to the data for the new study (in case we need to run one).
      if (configType === DocumentSubType.exploration) {
        studyData.exploration = configResult.studyConfig;
      } else {
        studyData.simConfig[key] = configResult.studyConfig;
      }

      studyDataSources.push({
        configType,
        userId: configId ? userData.sub : undefined,
        configId,
        name: configName,
        isEdited: false,
      });
    }

    let studyReference: StudyReference = {
      tenantId,
      targetId: studyId,
    };

    // If the study is from a different tenant, or we're always creating new studies, create a new study.
    if (options.alwaysCreateNewStudies || tenantId !== userData.tenant) {
      const newStudyId = <PostStudyResult>await this.loadingDialog.showUntilFinished(
        this.studyStub.postStudy(
          userData.tenant,
          {
            name: study.name,
            studyType: study.data.studyType,
            simVersion,
            sources: studyDataSources,
            study: studyData,
            isTransient: false,
          }),
        `Creating ${this.getProgressStudyName(studyIndex)}...`);

      // Create a reference to the running study for the worksheet row.
      studyReference = {
        tenantId: userData.tenant,
        targetId: newStudyId.studyId,
      };
    }

    // Create the worksheet row.
    const row: WorksheetRow = {
      name: undefined,
      configs: worksheetConfigs,
      study: {
        reference: studyReference
      }
    };

    // Add the row to the list of rows and return the study reference.
    rows.push(row);
    return studyReference;
  }

  /**
   * Process the config. This could involve saving a copy, or running a telemetry
   * study on a new row, or just adding the data to the new study.
   * @param studyResult The study from which to create rows.
   * @param options The options for creating the rows.
   * @param userData The user data.
   * @param configName The name of the config.
   * @param configType The type of the config.
   * @param configData The config data.
   * @param simVersion The sim version.
   * @param tenantId The tenant ID.
   * @param targetWorksheetId The target worksheet ID.
   * @param rows The list of rows to which to add any created rows.
   * @param studyIndex The study index, for displaying in the loading dialogs.
   * @returns The processed config and worksheet config.
   */
  private processConfig(
    studyResult: GetStudyQueryResult,
    options: CreateWorksheetRowFromStudyOptions,
    userData: UserData,
    configName: string,
    configType: DocumentSubType,
    configData: any,
    simVersion: string,
    tenantId: string,
    targetWorksheetId: string,
    rows: WorksheetRow[],
    studyIndex: number): Promise<StudyConfigAndWorksheetConfig> {

    if (configType === DocumentSubType.telemetry) {
      return this.processTelemetryConfig(
        studyResult,
        options,
        userData,
        configName,
        configType,
        configData,
        simVersion,
        tenantId,
        targetWorksheetId,
        rows,
        studyIndex);
    }

    return this.processNonTelemetryConfig(
      userData,
      configName,
      configType,
      configData,
      simVersion,
      targetWorksheetId,
      studyIndex);
  }

  /**
   * Process a telemetry config.
   * @param studyResult The study from which to create rows.
   * @param options The options for creating the rows.
   * @param userData The user data.
   * @param configName The name of the config.
   * @param configType The type of the config.
   * @param configData The config data.
   * @param simVersion The sim version.
   * @param tenantId The tenant ID.
   * @param targetWorksheetId The target worksheet ID.
   * @param rows The list of rows to which to add any created rows.
   * @param studyIndex The study index, for displaying in the loading dialogs.
   * @returns The processed config and worksheet config.
   */
  private async processTelemetryConfig(
    studyResult: GetStudyQueryResult,
    options: CreateWorksheetRowFromStudyOptions,
    userData: UserData,
    configName: string,
    configType: DocumentSubType,
    configData: any,
    simVersion: string,
    tenantId: string,
    targetWorksheetId: string,
    rows: WorksheetRow[],
    studyIndex: number): Promise<StudyConfigAndWorksheetConfig> {

    if(!configData.source){
      // If we have telemetry configs with data then we need to add it as a study input
      // but not save the config or put a config in the worksheet as you can't save telemetry data
      // on the platform.
      return new StudyConfigAndWorksheetConfig(undefined, configData, undefined);
    }

    // If we have telemetry configs with a source then we need to append the source study.
    const originalTelemetryTenantId = configData.source.tenantId || tenantId;
    const originalTelemetryStudyId = configData.source.studyId;
    const originalTelemetryJobIndex = configData.source.jobIndex || 0;

    let telemetryTenantId = originalTelemetryTenantId;
    let telemetryStudyId = originalTelemetryStudyId;
    let telemetryJobIndex = originalTelemetryJobIndex;

    // If the telemetry was in a different tenant, or we're always creating new studies, create a new study.
    let shouldCreateNewStudy =
      originalTelemetryTenantId !== userData.tenant
      || options.alwaysCreateNewStudies;

    // We should recreate the telemetry from the study data if the options say so...
    let shouldRecreateFromData = shouldCreateNewStudy && options.alwaysRecreateTelemetryStudiesFromData;

    let telemetryStudyResult: GetStudyQueryResult | undefined;
    if(shouldCreateNewStudy && !shouldRecreateFromData){

      // ... Or if we can't access the referenced telemetry study.
      telemetryStudyResult = await this.loadStudyIfNotForbidden(
        originalTelemetryTenantId, originalTelemetryStudyId, simVersion, studyIndex + 1);

      if(!telemetryStudyResult){
        shouldRecreateFromData = true;
      }
    }

    let telemetryStudyReference: StudyReference;
    if(shouldRecreateFromData){

      // Run a new telemetry study, using the telemetry input data from the original study.
      telemetryStudyReference = await this.recreateTelemetryStudyFromInputData.execute(
        configName,
        studyResult,
        telemetryJobIndex);

      telemetryTenantId = telemetryStudyReference.tenantId;
      telemetryStudyId = telemetryStudyReference.targetId;
      telemetryJobIndex = 0;

      // Wait for the telemetry study to complete.
      await this.loadingDialog.showUntilFinished(
        this.loadStudyService.loadCompleted(
          telemetryStudyReference.tenantId,
          telemetryStudyReference.targetId,
          simVersion),
        'Waiting for telemetry study to complete...');
    } else if (shouldCreateNewStudy) {
      // If we're creating a new study, but not re-creating the telemetry from the study input data, then
      // we need to recurse to add the telemetry study to the worksheet as well on it's own row.
      // Even if the telemetry study was telemetry (rather than another simulation) the
      // user will need to be able to see when it completes and re-run the dependent study.
      telemetryStudyReference = await this.appendRowsFromStudy(
        telemetryStudyResult,
        targetWorksheetId,
        options,
        simVersion,
        userData,
        rows,
        studyIndex + 1);

      telemetryTenantId = telemetryStudyReference.tenantId;
      telemetryStudyId = telemetryStudyReference.targetId;
    }

    configData = {
      source: {
        tenantId: telemetryTenantId,
        studyId: telemetryStudyId,
        jobIndex: telemetryJobIndex,
      }
    };

    // Create a reference to the processed telemetry study for the worksheet row.
    const worksheetConfig = {
      configType,
      inheritReference: false,
      reference: {
        tenant: {
          tenantId: telemetryTenantId,
          targetId: telemetryStudyId,
          jobIndex: telemetryJobIndex,
        }
      }
    };

    return new StudyConfigAndWorksheetConfig(undefined, configData, worksheetConfig);
  }

  /**
   * Process a non-telemetry config.
   * @param userData The user data.
   * @param configName The name of the config.
   * @param configType The type of the config.
   * @param configData The config data.
   * @param simVersion The sim version.
   * @param targetWorksheetId The target worksheet ID.
   * @param studyIndex The study index, for displaying in the loading dialogs.
   * @returns The processed config and worksheet config.
   */
  private async processNonTelemetryConfig(
    userData: UserData,
    configName: string,
    configType: DocumentSubType,
    configData: any,
    simVersion: string,
    targetWorksheetId: string,
    studyIndex: number): Promise<StudyConfigAndWorksheetConfig> {

    // Save a copy of the input config.
    const configId = await this.loadingDialog.showUntilFinished(
      this.configStub.postConfig(
        userData.tenant,
        {
          name: configName,
          configType,
          config: configData,
          simVersion,
          parentWorksheetId: targetWorksheetId,
        }),
      `Creating ${configType} from ${this.getProgressStudyName(studyIndex)}...`);

    // Return a reference to the new config for the worksheet row.
    const worksheetConfig = {
      configType,
      inheritReference: false,
      reference: {
        tenant: {
          tenantId: userData.tenant,
          targetId: configId,
        }
      }
    };

    return new StudyConfigAndWorksheetConfig(configId, configData, worksheetConfig);
  }

  /**
   * Gets the study name for the loading dialog.
   * @param studyIndex The study index.
   * @returns The resulting study name.
   */
  private getProgressStudyName(studyIndex: number){
    return 'study' + (studyIndex ? ' ' + (studyIndex + 1) : '');
  }
}

/**
 * Contains a config id, the config data and the worksheet config object which represents
 * the config in the worksheet.
 */
class StudyConfigAndWorksheetConfig {

  /**
   * Creates an instance of StudyConfigAndWorksheetConfig.
   * @param configId The config ID.
   * @param studyConfig The study config.
   * @param worksheetConfig The worksheet config.
   */
  constructor(
    public readonly configId: string,
    public readonly studyConfig: any,
    public readonly worksheetConfig: WorksheetConfig){
  }
}


/**
 * Some content to add to the worksheet.
 */
export class WorksheetContent {

  /**
   * Creates an instance of WorksheetContent.
   * @param clipboardContent The clipboard content.
   */
  constructor(
    public readonly clipboardContent: ClipboardContent){
  }
}

/**
 * Options for creating a worksheet row from a study.
 */
export class CreateWorksheetRowFromStudyOptions {

  /**
   * Creates an instance of CreateWorksheetRowFromStudyOptions.
   * @param alwaysCreateNewStudies True if new studies should always be created, otherwise false.
   * @param alwaysRecreateTelemetryStudiesFromData True if telemetry studies should always be re-created from the study data, otherwise false.
   */
  constructor(
    public readonly alwaysCreateNewStudies: boolean,
    public readonly alwaysRecreateTelemetryStudiesFromData: boolean){
  }

  /**
   * Gets the default options.
   * @returns The default options.
   */
  public static default(): CreateWorksheetRowFromStudyOptions {
    return new CreateWorksheetRowFromStudyOptions(
      false,
      true);
  }

  /**
   * Creates options from a mouse event.
   * @param event The mouse event.
   * @returns The options.
   */
  public static fromEvent(event: MouseEvent): CreateWorksheetRowFromStudyOptions {
    const defaultValues = CreateWorksheetRowFromStudyOptions.default();
    return new CreateWorksheetRowFromStudyOptions(
      CreateWorksheetRowFromStudyOptions.invertIf(defaultValues.alwaysCreateNewStudies, !!(event.ctrlKey || event.metaKey)),
      CreateWorksheetRowFromStudyOptions.invertIf(defaultValues.alwaysRecreateTelemetryStudiesFromData, !!(event.altKey)));
  }

  /**
   * Inverts a default value if a condition is met.
   * @param defaultValue The default value.
   * @param shouldInvert True if the default value should be inverted, otherwise false.
   * @returns The resulting value.
   */
  private static invertIf(defaultValue: boolean, shouldInvert: boolean){
    return shouldInvert ? !defaultValue : defaultValue;
  }
}

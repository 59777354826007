<span class="view-credits">
  @if (computeCredits) {
    <span class="view-compute-credits" title="Compute">
      <i class="fa fa-cog"></i>{{computeCredits | toFixed:3}}
    </span>
  }
  @if (storageCredits) {
    <span class="view-storage-credits" title="Storage">
      <i class="fa fa-cloud"></i>{{storageCredits | toFixed:3}}
    </span>
  }
</span>

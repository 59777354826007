import {ISaveOutputConfigHandler} from '../compare-config-dialog/compare-config-dialog.service';
import {ConfigReference, ConfigStub} from '../../../../../generated/api-stubs';
import {StudyInput} from '../../../../worksheets/study-input';
import {Injectable} from '@angular/core';
import {LoadingDialog} from '../../../../common/dialogs/loading-dialog.service';
import {DocumentUpdatedEventService} from '../../../../worksheets/document-updated-event.service';
import {fromReadonly} from '../../../../common/from-readonly';
import { AuthenticationService, UserData } from '../../../../identity/state/authentication.service';

@Injectable()
export class SaveToConfigReferenceHandlerFactory {

  constructor(
    private readonly configStub: ConfigStub,
    private readonly authenticationService: AuthenticationService,
    private readonly loadingDialog: LoadingDialog,
    private readonly documentUpdatedEventService: DocumentUpdatedEventService) {
  }

  public create(configReference: ConfigReference): SaveToConfigReferenceHandler {
    return new SaveToConfigReferenceHandler(
      configReference,
      this.configStub,
      this.authenticationService,
      this.loadingDialog,
      this.documentUpdatedEventService);
  }
}

export class SaveToConfigReferenceHandler implements ISaveOutputConfigHandler {

  private readonly userData: UserData;

  constructor(
    private readonly configReference: ConfigReference,
    private readonly configStub: ConfigStub,
    private readonly authenticationService: AuthenticationService,
    private readonly loadingDialog: LoadingDialog,
    private readonly documentUpdatedEventService: DocumentUpdatedEventService) {
    this.userData = this.authenticationService.userDataSnapshot;
  }

  public canExecute(studyInput: StudyInput): boolean {
    return !!(this.configReference.tenant && studyInput && studyInput.userId === this.userData.sub);
  }

  public async execute(studyInput: StudyInput): Promise<void> {
    await this.loadingDialog.showUntilFinished(
      this.configStub.putConfig(
        this.userData.tenant,
        studyInput.configId,
        {
          name: studyInput.name,
          configType: studyInput.configType,
          properties: fromReadonly(studyInput.properties),
          config: studyInput.data,
          notes: studyInput.notes,
          simVersion: studyInput.simVersion
        },
        undefined),
      'Saving...');

    this.documentUpdatedEventService.emit(this.userData.tenant, studyInput.configId);
  }
}

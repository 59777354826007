import {SLAP_DOMAIN_NAME, SRUN_DOMAIN_NAME, T_DOMAIN_NAME, TLAP_DOMAIN_NAME, TRUN_DOMAIN_NAME} from '../../constants';

export class LegacyChannelMapping {
  constructor(
    public readonly currentName: string,
    public readonly legacyName: string){
  }
}

const LEGACY_MAPPINGS: ReadonlyArray<LegacyChannelMapping> = [
  new LegacyChannelMapping(TRUN_DOMAIN_NAME, TLAP_DOMAIN_NAME),
  new LegacyChannelMapping(TRUN_DOMAIN_NAME, T_DOMAIN_NAME),
  new LegacyChannelMapping(SRUN_DOMAIN_NAME, SLAP_DOMAIN_NAME),
];

/**
 * Gets the legacy channel mappings.
 * For example we used to use sLap and tLap as the domain, rather than sRun and tRun.
 */
export class GetLegacyChannelMappings{
  public execute(): ReadonlyArray<LegacyChannelMapping> {
    return LEGACY_MAPPINGS;
  }
}

import { AbstractControlOptions, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { FormConfig } from '../../interfaces/form-config/form-config.interface';

export class FormBuilderModel {

  protected _form: UntypedFormGroup;

  public get form(): UntypedFormGroup {
    return this._form;
  }

  public get isDirty(): boolean {
    return this._form.dirty;
  }

  public get isValid(): boolean {
    return this._form.valid;
  }

  constructor(formConfig: FormConfig, formBuilder: UntypedFormBuilder) {
    this._form = formBuilder.group({});

    if (!formConfig || !formConfig.fields) {
      return;
    }

    for (let field of formConfig.fields) {
      this._initFormControl(field.name, field.defaultValue, field.validators);
    }
  }

  /**
   * Gets the form control from the form for the specified path
   * @param path
   * @returns
   */
  public getFormControl(path: string): UntypedFormControl {
    return this._form.get(path) as UntypedFormControl;
  }

  /**
   * Initialises a form control and adds it to the form group
   * @returns
   */
  private _initFormControl(name: string, defaultValue: any = null, validators: ValidatorFn | ValidatorFn[] | AbstractControlOptions): UntypedFormControl {
    const formControl = new UntypedFormControl(defaultValue, validators);
    this._form.addControl(name, formControl);
    return formControl;
  }
}

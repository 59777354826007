import {Component, Input} from '@angular/core';
import {SimVersionSelectedEvent} from '../select-sim-version.component';
import {LocalUserSettingsManager} from '../../../common/local-user-settings-manager';
import {GetUserSimVersion} from '../../../common/get-user-sim-version.service';

@Component({
  selector: 'cs-user-settings-sim-version',
  templateUrl: './user-settings-sim-version.component.html',
  styleUrls: ['./user-settings-sim-version.component.scss']
})
export class UserSettingsSimVersionComponent {

  @Input() public tenantId: string;

  constructor(
    private readonly getUserSimVersion: GetUserSimVersion,
    private readonly localUserSettingsManager: LocalUserSettingsManager) {
  }

  public get currentSimVersion(): string {
    return this.getUserSimVersion.currentSimVersion;
  }

  public onSimVersionSelected(event: SimVersionSelectedEvent){
    event.promise = this.setSimVersion(event.simVersion);
  }

  public async setSimVersion(simVersion: string) {
    this.localUserSettingsManager.state.userSimVersion = simVersion;
    this.localUserSettingsManager.save();
    await this.getUserSimVersion.execute();
  }
}

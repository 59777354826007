<div class="list-filter">
  <cs-error-message [message]="errorMessage"></cs-error-message>
  <div class="row">
    <div class="col-12 col-sm-7">
      <form (ngSubmit)="filterName()" [formGroup]="form">
        <div class="d-flex gap-1">
          <button class="btn btn-smart btn-secondary"
            type="button"
            id="filter-refresh-button"
            [disabled]="disableForm"
            (click)="refresh()">
            <i class="fa fa-refresh"></i>
          </button>

          @if (canPersistFilter) {
            <cs-quick-config-manager
              [configType]="filterConfigType"
              [persistLocally]="true"
              [showName]="true"
              [updateSource]="configSource"
            (configChanged)="onConfigUpdatedExternally($event)"></cs-quick-config-manager>
          }

          <button class="btn btn-smart btn-secondary"
            id="filter-clear-button"
            type="button"
            [disabled]="disableForm"
            (click)="clearFilter()">
            Clear Filters
          </button>
          @if (!isStudyJobs) {
            <input id="filter-by-name-input" placeholder="Name" maxlength="200" type="text" class="form-control" formControlName="name" [csDynamicDisable]="disableForm">
          }
        <!--button id="search-submit" type="submit" class="btn btn-smart btn-secondary" [disable]="disableForm">Filter</button-->
        @if (disableForm) {
          <cs-loader class="filter-progress" id="filter-progress"></cs-loader>
        }
      </div>
    </form>
  </div>
  <div class="col-12 col-sm-5">
    <div class="right-side">
      @if (canBeInWorksheet) {
        <button class="btn btn-smart btn-secondary btn-icon toggle-button"
          id="include-if-has-parent-worksheet-button"
          type="button"
          [disabled]="disableForm"
          [ngClass]="{ 'active-toggle-button': includeIfHasParentWorksheet }"
          (click)="toggleIncludeIfHasParentWorksheet()"
          title="Include In Worksheets">
          <i class="fa fa-th fa-fw"></i>
        </button>
      }
      @if (canBeSoftDeleted) {
        <button class="btn btn-smart btn-secondary btn-icon toggle-button"
          id="include-if-delete-requested-button"
          type="button"
          [disabled]="disableForm"
          [ngClass]="{ 'active-toggle-button': includeIfDeleteRequested }"
          (click)="toggleIncludeIfDeleteRequested()"
          title="Include Delete Requested">
          <i class="fa fa-trash fa-fw"></i>
        </button>
      }

      <!--div class="include-additional-results-form">
      <div class="form-check">
        <input id="include-if-delete-requested-input"
          class="form-check-input"
          formControlName="includeIfDeleteRequested"
          type="checkbox"
          [disabled]="disableForm">
        <label class="form-check-label" for="include-if-delete-requested-input">Deleted</label>
      </div>
      <div class="form-check">
        <input id="include-if-has-parent-worksheet-input"
          class="form-check-input"
          formControlName="includeIfHasParentWorksheet"
          type="checkbox"
          [disabled]="disableForm">
        <label class="form-check-label" for="include-if-has-parent-worksheet-input">Worksheet</label>
      </div>
    </div-->
    @if (!isStudyJobs) {
      <button class="btn btn-smart btn-secondary"
        id="filter-sort-button"
        type="button"
        [disabled]="disableForm"
        [ngClass]="{ 'active-filter-property': showSorting }"
        (click)="toggleShowSorting()">
        Sort
        @if (initialFilter.orderByProperty || initialFilter.orderByCustomProperty) {
          <div class="current-sort-mode">
            {{initialFilter.orderByProperty || initialFilter.orderByCustomProperty}}
            <i [ngClass]="{ 'fa-caret-down': initialFilter.orderByDescending, 'fa-caret-up': !initialFilter.orderByDescending }"
            class="fa"></i>
          </div>
        }
      </button>
    }
    <button class="btn btn-smart btn-secondary"
      id="filter-filter-button"
      type="button"
      [disabled]="disableForm"
      [ngClass]="{ 'active-filter-property': showFilters }"
      (click)="toggleShowFilters()">
      Filter
    </button>
  </div>
</div>
</div>
<div class="row">
  <div class="col-12">
    @if (!tenantUsers) {
      <cs-loader class="query-renderer-load-progress"></cs-loader>
    }
    @if (tenantUsers) {
      <div>
        <cs-filter-query-renderer
          [data]="currentFilterQuery"
          [isApplied]="isCurrentFilterQueryApplied"
          [tenantUsers]="tenantUsers">
        </cs-filter-query-renderer>
      </div>
    }
  </div>
</div>
@if (showFilters) {
  <div class="row query-builder-row">
    <div class="col-12">
      @if (!canShowFiltersAndSorting && !errorMessage) {
        <cs-loader class="query-builder-load-progress"></cs-loader>
      }
      @if (canShowFiltersAndSorting) {
        <div>
          <cs-filter-query-builder
            [dataSource]="filterQueryBuilderDataSource"
            (queryChanged)="onQueryChanged($event)"
            [tenantUsers]="tenantUsers">
          </cs-filter-query-builder>
          <p class="query-builder-buttons">
            <button class="btn btn-smart btn-secondary apply-query-builder"
              [disabled]="!currentFilterQuery?.isValid"
            (click)="filterByQuery(currentFilterQuery.root)">Apply</button>
            <button class="btn btn-smart btn-secondary cancel-query-builder"
            (click)="discardCurrentFilterQuery()">Cancel</button>
            <a [href]="LIST_FILTERING_DOCUMENTATION_URL" target="_blank">Filtering Help</a>
          </p>
        </div>
      }
    </div>
  </div>
}
@if (showSorting) {
  <div class="row">
    <div class="col-12">
      @if (!canShowFiltersAndSorting && !errorMessage) {
        <cs-loader class="query-builder-load-progress"></cs-loader>
      }
      @if (canShowFiltersAndSorting) {
        <div class="sort-buttons">
          @for (property of propertySortOptions; track property) {
            <button class="btn btn-smart btn-secondary"
              [disabled]="disableForm"
              (click)="togglePropertyOrdering(property)">
              {{property}}
              @if (initialFilter.orderByProperty === property) {
                <i
                  [ngClass]="{ 'fa-caret-down': initialFilter.orderByDescending, 'fa-caret-up': !initialFilter.orderByDescending }"
                class="fa"></i>
              }
            </button>
          }
          @for (property of groupResults; track property) {
            <button class="btn btn-smart btn-secondary sort-custom-property-{{cssSanitize(property.name)}}"
              [disabled]="disableForm"
              (click)="toggleCustomPropertyOrdering(property.name)">
              {{property.name}}
              @if (initialFilter.orderByCustomProperty === property.name) {
                <i
                  [ngClass]="{ 'fa-caret-down': initialFilter.orderByDescending, 'fa-caret-up': !initialFilter.orderByDescending }"
                class="fa"></i>
              }
            </button>
          }
        </div>
      }
    </div>
  </div>
}
</div>

import {
  DocumentSubType, NewStudyData,
  PostStudyInlineResult,
  PostStudyResult,
  SimType,
  StudyStub,
  StudyType
} from '../../../generated/api-stubs';
import {CustomProperty} from '../custom-properties/custom-properties';
import {DisplayableError} from '../../common/errors/errors';
import {Injectable} from '@angular/core';
import {StudySubmittedEvents} from '../studies/study-submitted-events.service';
import { AuthenticationService } from '../../identity/state/authentication.service';

@Injectable()
export class ConvertTelemetryConfigToStudy {

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly studySubmittedEvents: StudySubmittedEvents,
    private readonly studyStub: StudyStub){
  }

  public async execute(
    editorValue: any,
    simVersion: string,
    name: string,
    isEdited: boolean,
    customProperties: ReadonlyArray<CustomProperty>,
    notes: string): Promise<PostStudyResult | PostStudyInlineResult> {

    if(!editorValue || !editorValue.channels || !editorValue.channels.length){
      throw new DisplayableError('Telemetry must contain channels to be converted to a telemetry study.');
    }

    const userData = this.authenticationService.userDataSnapshot;

    let study = {
      simTypes: [SimType.Telemetry],
      simConfig: {
        telemetry: editorValue
      },
      exploration: undefined as any
    };

    let sources = [{
      configType: DocumentSubType.telemetry,
      name,
      isEdited
    }];

    let properties: CustomProperty[] = (customProperties || []).map(v => ({
      name: `${DocumentSubType.telemetry}.${v.name}`,
      value: v.value
    }));

    let studyData: NewStudyData = {
      name,
      studyType: StudyType.telemetry,
      simVersion,
      sources,
      properties,
      notes,
      study,
      isTransient: false
    };

    let studyResult = await this.studyStub.postStudy(
      userData.tenant,
      studyData);

    this.studySubmittedEvents.studySubmitted.emit(studyResult);

    return studyResult;
  }
}

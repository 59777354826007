import {sanitizeClipboardText} from '../../common/sanitize-clipboard-text';
import { Input, Directive } from '@angular/core';

@Directive()
export class ViewConfigsComponentBase {
  @Input() public canNavigate: boolean = true;

  public onCopy(event: ClipboardEvent){
    sanitizeClipboardText(event);
  }
}

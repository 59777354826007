import {SelectSimVersionDialogData, SelectSimVersionResult} from './select-sim-version-dialog.service';
import {EventEmitter, Injectable} from '@angular/core';
import {SimVersionSelectedEvent} from './select-sim-version.component';

@Injectable()
export class SelectSimVersionDialogSessionFactory{

  constructor(){
  }

  public create(dialog: SelectSimVersionDialogData) {
    return new SelectSimVersionDialogSession(dialog);
  }
}

export class SelectSimVersionDialogSession{
  public simVersionSelected: EventEmitter<SelectSimVersionResult> = new EventEmitter<SelectSimVersionResult>();

  constructor(
    public readonly dialog: SelectSimVersionDialogData){
  }

  public onSimVersionSelected(event: SimVersionSelectedEvent){
    event.promise = this.setSimVersion(event.simVersion);
  }

  private async setSimVersion(simVersion: string) {
    this.simVersionSelected.emit(new SelectSimVersionResult(simVersion));
  }
}

import {Injectable} from '@angular/core';
import {CommandContext, CommandResult, ICommandContext, WorksheetCommand} from './worksheet-command';
import {
  ButtonMenuItem, KeyboardAction,
  MenuItem,
} from '../../context-menu/context-menu-types';
import {DocumentSubType} from '../../../generated/api-stubs';
import {ConfigViewModel} from '../config-view-model';
import {LOAD_ICON} from '../worksheet-item-component-base';
import {SetStandardConfig} from './set-standard-config.service';
import {SetTelemetryConfig} from './set-telemetry-config.service';
import { AuthenticationService } from '../../identity/state/authentication.service';

/**
 * A command that loads an existing config or study into the worksheet.
 */
@Injectable()
export class LoadConfigCommand extends WorksheetCommand {

  /**
   * Creates an instance of LoadConfigCommand.
   * @param setStandardConfig The service for setting a standard config.
   * @param setTelemetryConfig The service for setting a telemetry config.
   * @param authenticationService The authentication service.
   */
  constructor(
    private readonly setStandardConfig: SetStandardConfig,
    private readonly setTelemetryConfig: SetTelemetryConfig,
    private readonly authenticationService: AuthenticationService) {
    super();
  }

  /**
   * Determines if the command can be executed.
   * @param context The command context.
   * @returns True if the command can be executed, otherwise false.
   */
  public canExecute(context: CommandContext): context is ICommandContext<ConfigViewModel> {
    const userData = this.authenticationService.userDataSnapshot;
    return context.isConfig()
      && context.worksheet.canWrite(userData.sub);
  }

  /**
   * Executes the command.
   * @param context The command context.
   * @returns The command result.
   */
  public async execute(context: CommandContext): Promise<CommandResult> {
    if(!this.canExecute(context)) {
      return CommandResult.NoUpdate;
    }

    switch(context.target.configType){
      case DocumentSubType.telemetry:
        return await this.setTelemetryConfig.execute(context);

      default:
        return await this.setStandardConfig.execute(context);
        break;
    }
  }

  /**
   * Creates the menu items for the command.
   * @param context The command context.
   * @param result The menu items.
   */
  public createMenuItems(context: CommandContext, result: MenuItem<CommandResult>[]): void {
    if(!this.canExecute(context)) {
      return;
    }

    result.push(new ButtonMenuItem<CommandResult>(
      `${context.target.populated ? 'Replace' : 'Load'} ${context.target.unpopulated.configTypeName}`,
      `set-button`,
      () => this.execute(context),
      LOAD_ICON,
      context.target.isTelemetry ? KeyboardAction.none : KeyboardAction.enter));
  }
}

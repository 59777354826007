import {ActivatedRoute} from '@angular/router';
import {Injectable} from '@angular/core';

@Injectable()
export class IsWorksheetPage {
  public execute(route: ActivatedRoute): boolean {
    if(!route){
      throw new Error('No route provided.');
    }

    let current = route.snapshot;
    while(current){
      if(current.url.some(v => v.path === 'worksheets')){
        return true;
      }

      current = current.parent;
    }

    return false;
  }
}

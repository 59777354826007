import {CommandContext, CommandResult, ICommandContext, WorksheetCommand} from './worksheet-command';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ButtonMenuItem, KeyboardAction, MenuItem} from '../../context-menu/context-menu-types';
import {ConfigViewModel} from '../config-view-model';
import {StudyViewModel} from '../study-view-model';
import { AuthenticationService } from '../../identity/state/authentication.service';
import { RowItemUrlService } from './row-item-url.service';

/**
 * A command that views the selected config or study.
 */
@Injectable()
export class ViewConfigOrStudyCommand extends WorksheetCommand {

  /**
   * Creates an instance of ViewConfigOrStudyCommand.
   * @param authenticationService The authentication service.
   * @param router The router.
   * @param rowItemUrl The service for generating row item URLs.
   */
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router,
    private readonly rowItemUrl: RowItemUrlService) {
    super();
  }

  /**
   * Determines if the command can be executed.
   * @param context The command context.
   * @returns True if the command can be executed, otherwise false.
   */
  public canExecute(context: CommandContext): context is ICommandContext<ConfigViewModel> | ICommandContext<StudyViewModel> {
    return context.isConfigOrStudy()
      && context.isSingleRow
      && context.target.isResolved;
  }

  /**
   * Executes the command.
   * @param context The command context.
   * @returns The command result.
   */
  public async execute(context: CommandContext): Promise<CommandResult> {
    if(!this.canExecute(context)) {
      return CommandResult.NoUpdate;
    }
    await this.router.navigateByUrl(this.rowItemUrl.generate(context.target, context.worksheetContext));
    return CommandResult.NoUpdate;
  }

  /**
   * Creates the menu items for the command.
   * @param context The command context.
   * @param result The menu items.
   */
  public createMenuItems(context: CommandContext, result: MenuItem<CommandResult>[]): void {
    if(!this.canExecute(context)) {
      return;
    }

    const userData = this.authenticationService.userDataSnapshot;
    const cssClass = 'view-button';
    const iconClass = '';
    const action = () => this.execute(context);
    const keyboardAction = KeyboardAction.enter;

    if(context.isStudy()) {
      result.push(new ButtonMenuItem<CommandResult>(
        `View Study`,
        cssClass,
        action,
        iconClass,
        keyboardAction));
    } else {
      const target = context.target;
      if(target.isTelemetry) {
        if(target.populated.reference.tenant){
          result.push(new ButtonMenuItem<CommandResult>(
            `View ${target.unpopulated.configTypeName}`,
            cssClass,
            action,
            iconClass,
            keyboardAction));
        }
      } else {
        if(target.populated.reference.tenant){
          result.push(new ButtonMenuItem<CommandResult>(
            `${target.isReadOnly(userData.sub) ? 'View' : 'Edit'} ${target.unpopulated.configTypeName}`,
            cssClass,
            action,
            iconClass,
            keyboardAction));

        }
        if(target.populated.reference.default){
          result.push(new ButtonMenuItem<CommandResult>(
            `View ${target.unpopulated.configTypeName}`,
            cssClass,
            action,
            iconClass,
            keyboardAction));
        }
      }
    }
  }
}

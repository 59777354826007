<div class="container-fluid" id="edit-config-permissions-page">
  <p>
      <cs-form-back-button [form]="model.form" [routeParams]="routeParams"></cs-form-back-button>
  </p>
  <div class="card">
    <h1 class="card-header">{{ isEdit ? 'Edit' : 'Create' }} Config Permission</h1>
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-6">
          <cs-form-builder
            [formConfig]="formConfig"
            [model]="model"
            [onSubmit]="submit"
            (submitted)="onSubmitted($event)"
          ></cs-form-builder>
        </div>
      </div>
    </div>
  </div>
</div>

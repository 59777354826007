
<div id="edit-config-page">
  <cs-edit-config #editConfigComponent
    [tenantId]="tenantId"
    [configId]="configId"
    [isStaged]="isStaged"
    [isDefault]="isDefault"
    [simVersion]="simVersion"
    [configName]="configName"
    [configType]="configType"
    [isWorksheetPage]="isWorksheetPage"></cs-edit-config>
</div>

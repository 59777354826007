import {Injectable} from '@angular/core';
import {
  ListFilterData, ListFilterStub, OrderByProperty,
  SupportSessionFilter
} from '../../../generated/api-stubs';

export interface LegacyListFilterData {
  itemsPerPage?: number;
  continuationToken?: string;
  orderByCustomProperty?: string;
  orderByProperty?: OrderByProperty;
  orderByDescending?: boolean;
  filterCustomProperties?: { name: string; value: string }[];
  filterName?: string;
  filterUserId?: string;
  filterSupportSessions?: SupportSessionFilter;
}

@Injectable()
export class UpgradeListFilterForComplexQueries {

  constructor(
    private readonly listFilterStub: ListFilterStub) {
  }

  public async execute(filter: ListFilterData): Promise<ListFilterData> {
    if((filter.filterCustomProperties && filter.filterCustomProperties.length)
      || filter.filterUserId
      || filter.filterSupportSessions){
      return await this.listFilterStub.upgradeListFilter(filter);
    }

    return filter;
  }
}

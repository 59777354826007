import {ResultSource, ResultsStagingArea} from '../results-staging-area/results-staging-area.service';
import {Injectable} from '@angular/core';
import {StudyStagingArea} from '../study-staging-area/study-staging-area.service';
import {DocumentSubType} from '../../../generated/api-stubs';
import {PromptDialog} from '../../common/dialogs/prompt-dialog.service';
import {ConfirmationDialog} from '../../common/dialogs/confirmation-dialog.service';

export const TELEMETRY_SCHEMA_SIM_VERSION = '1.1727';

@Injectable()
export class StageStudy {
  constructor(
    private resultsStagingArea: ResultsStagingArea,
    private studyStagingArea: StudyStagingArea,
    private promptDialog: PromptDialog,
    private confirmationDialog: ConfirmationDialog){
  }

  public toResultsStagingArea(
    tenantId: string,
    studyId: string,
    studyName: string,
    jobCount: number) {
    this.resultsStagingArea.addSource(
      new ResultSource(tenantId, studyId, studyName, undefined, `${jobCount} job${jobCount !== 1 ? 's' : ''}`));
  }

  public async toStudyStagingArea(
    tenantId: string,
    studyId: string,
    studyName: string,
    jobCount: number,
    jobIndex?: number) {
    if(jobIndex === undefined) {
      jobIndex = 0;
      let promptResult = '';
      if (jobCount > 1) {
        promptResult = await this.promptDialog.show<string>('Enter a job index between 0 and ' + (jobCount - 1) + ':', 'Go To Job');
        if (!promptResult) {
          return;
        }
        jobIndex = +promptResult;
      }

      if (jobIndex < 0 || jobIndex >= jobCount) {
        await this.confirmationDialog.show('Invalid job index "' + promptResult + '".', 'Invalid Request', 'OK', 'OK');
        return;
      }
    }

    const config: TelemetryReference = {
      source: {
        tenantId,
        studyId,
        jobIndex
      }
    };

    //const currentIdentity = this.identity.get();
    //if(currentIdentity.tenantId !== tenantId){
    //  config.source.tenantId = tenantId;
    //}

    // We always set this now so that we can stage other tenant's telemetry inputs in support sessions.
    config.source.tenantId = tenantId;

    this.studyStagingArea.stage(
      DocumentSubType.telemetry,
      undefined,
      undefined,
      studyName + (jobCount === 1 ? '' : ` - Job Index ${jobIndex}`),
      config,
      [],
      undefined,
      TELEMETRY_SCHEMA_SIM_VERSION,
      false);
  }
}

export interface TelemetryReference {
  source: {
    tenantId: string;
    studyId: string;
    jobIndex: number;
  };
}

/*
 * Usage:
 *   value | toShortPath:dp
 * Example:
 *   {{ car.chassis.mCar |  toShortPath:2}}
 *   formats to: chassis.mCar
 */
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'toShortPath'})
export class ToShortPathPipe implements PipeTransform {
  transform(value: string, pathElements: number = 1): any {
    const values = value.split('.');
    if(values.length <= pathElements) {
      return value;
    }

    values.splice(0, values.length - pathElements);
    return values.join('.');
  }
}

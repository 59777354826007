import { IChannelMetadata, ISourceLoader, SourceLoaderBase, SourceMetadata } from './source-loader';
import { ChannelNameStyle } from './channel-name-style';
import { IViewerChannelData } from './viewer-channel-data';

/**
 * A source loader that exposes a subset of data from another source loader.
 */
export class SubsetSourceLoader extends SourceLoaderBase {

  /**
   * Creates a new subset source loader.
   * @param source The source loader.
   * @param subsetName The name of the subset.
   * @param startIndexInclusive The start index of the subset (inclusive).
   * @param endIndexExclusive The end index of the subset (exclusive).
   */
  constructor(
    private readonly source: ISourceLoader,
    private readonly subsetName: string,
    private readonly startIndexInclusive: number,
    private readonly endIndexExclusive: number) {
    super();
    this._id = this.source.id + '-' + this.subsetName;
  }

  /**
   * @inheritdoc
   */
  public async getSourceMetadata(): Promise<SourceMetadata> {
    let childMetadata = await this.source.getSourceMetadata();
    return new SourceMetadata(childMetadata.name + ' (' + this.subsetName + ')');
  }

  /**
   * @inheritdoc
   */
  public async getChannelData(requestedName: string, resultChannelNameStyle: ChannelNameStyle, xDomainName: string): Promise<IViewerChannelData> {
    let childResult = await this.source.getChannelData(requestedName, resultChannelNameStyle, xDomainName);

    let data = childResult.data ? childResult.data.slice(this.startIndexInclusive, this.endIndexExclusive) : undefined;
    let dataLabels = childResult.dataLabels ? childResult.dataLabels.slice(this.startIndexInclusive, this.endIndexExclusive) : childResult.dataLabels;

    // If the data is monotonic, we shift it so the values to start at the same value as the parent data's first point.
    // This is useful for showing multiple subsets on the same plot, as channels such as sRun will be aligned.
    if (childResult.isMonotonic && data && data.length && childResult.data) {
      let localOffset = data[0];
      let globalOffset = childResult.data[0];
      let offset = globalOffset - localOffset;
      for (let i = 0; i < data.length; ++i) {
        data[i] += offset;
      }
    }

    return childResult.clone(
      data,
      childResult.units,
      childResult.isUserSpecifiedUnits,
      dataLabels);
  }

  /**
   * @inheritdoc
   */
  public getRequestableChannels(resultChannelNameStyle: ChannelNameStyle, xDomainName: string): Promise<IChannelMetadata[]> {
    return this.source.getRequestableChannels(resultChannelNameStyle, xDomainName);
  }

  /**
   * @inheritdoc
   */
  public get subsetSourceOrigin(): ISourceLoader {
    return this.source;
  }

  /**
   * @inheritdoc
   */
  public getConfig(type: string): Promise<any> {
    return this.source.getConfig(type);
  }
}

import {Component, OnDestroy} from '@angular/core';
import {TenancyStub} from '../../generated/api-stubs';
import {OnInit} from '@angular/core';
import {GetFriendlyErrorAndLog} from '../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {sortBy} from '../common/sort-by';
import {Dayjs} from '../common/dayjs.service';
import {GetTenantSimVersion} from '../common/get-tenant-sim-version.service';
import { AuthenticationService, UserData } from '../identity/state/authentication.service';

@Component({
  templateUrl: './admin.page.html',
  styleUrls: ['./admin.page.scss']
})
export class AdminPage implements OnInit, OnDestroy {

  public rowType = RowType;
  public displayModeType = DisplayMode;

  public errorMessage: string;
  public tenants: TenantRow[];

  public displayMode: DisplayMode = DisplayMode.none;

  public userData: UserData;

  public isDisposed: boolean;

  constructor(
    private authenticationService: AuthenticationService,
    private tenancyStub: TenancyStub,
    private getTenantSimVersion: GetTenantSimVersion,
    private dayjs: Dayjs,
    private getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  public ngOnInit() {
    this.load();
  }

  public ngOnDestroy(){
    this.isDisposed = true;
  }

  public async load() {
    try {
      this.userData = this.authenticationService.userDataSnapshot;
      let tenantsResult = await this.tenancyStub.getTenants();
      let tenants = tenantsResult.tenants;
      tenants.sort(sortBy({ name: 'isEnabled', reverse: true }, 'name'));
      tenants.forEach(v => (<Tenant>v).creationDate = this.dayjs.toDate(v.creationDate));

      this.tenants = [];
      for(let tenant of tenants){
        this.tenants.push(new TenantRow(RowType.tenant, tenant));
        this.tenants.push(new TenantRow(RowType.simulationSummary, tenant));
      }

      this.loadTenantDetails();
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async loadTenantDetails() {
    for (let row of this.tenants) {
      try {
        if (this.isDisposed) {
          return;
        }

        if (row.rowType === RowType.tenant) {
          let simVersion = await this.getTenantSimVersion.executeForTenant(row.tenant.tenantId);
          row.tenant.simVersion = simVersion;
        }
      } catch (error) {
        this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
      }
    }
  }

  public expandRow(row: TenantRow){
    row.expanded = true;
  }

  public hideRow(row: TenantRow){
    row.expanded = false;
  }

  public setDisplayMode(displayMode: DisplayMode){
    this.displayMode = displayMode;
  }
}

export class TenantRow {
  public expanded: boolean = false;

  constructor(
    public rowType: RowType,
    public tenant: Tenant,
  ){
    if(rowType === RowType.tenant){
      this.expanded = true;
    }
  }
}
class Tenant {
  public simVersion?: string;

  constructor(
    public tenantId: string,
    public name: string,
    public shortName: string,
    public creationDate: string,
    public databaseId: string,
    public region: string,
    public isEnabled: boolean){
  }
}

export enum RowType {
  tenant,
  simulationSummary
}

enum DisplayMode{
  none,
  tenants,
  pools,
  studyStatistics,
  globalSimVersion,
}

import { ChannelScalarDataAndMappings } from '../channel-scalar-data-and-mappings';
import { ChannelDataList } from './channel-data-list';
import { NamedChannelData } from './named-channel-data';

/**
 * Base class for channel interpolators for explorations.
 */
export abstract class ChannelInterpolatorExplorationBase {


  /**
   * Creates a list of channels and their scalar data values for each job.
   * @param scalarData The scalar data to create the list from.
   * @param channelNames The names of the channels to create the list for.
   * @returns The list of channels and their scalar data values.
   */
  protected createScalarDataList(scalarData: ChannelScalarDataAndMappings, channelNames?: ReadonlyArray<string>): ChannelDataList {

    if (!channelNames) {
      channelNames = scalarData.getChannelNames();
    }

    let list: NamedChannelData[] = [];
    for (let channelName of channelNames) {
      let channel = scalarData.getScalarData(channelName);
      if (channel) {
        list.push(new NamedChannelData(channelName, channel.values));
      } else {
        list.push(new NamedChannelData(channelName, undefined));
      }
    }

    return new ChannelDataList(list);
  }
}

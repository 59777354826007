import { ElementRef, HostListener, Input, OnDestroy, OnInit, Directive, OnChanges } from '@angular/core';
import {RowItemViewModel} from './row-item-view-model';
import {CellElementToViewModelLookup} from './cell-element-to-view-model-lookup';
import {IWorksheetContextMenuBuilder} from './worksheet-context-menu-builder';
import {IMenuItemWrapper, MenuItemsDefinition} from '../context-menu/context-menu-types';
import {GetFriendlyErrorAndLog} from '../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {IWorksheetWrappedContextMenuBuilder} from './worksheet-wrapped-context-menu-builder';
import {CommandResult, UpdateType, WorksheetContext} from './worksheet-commands/worksheet-command';
import { AuthenticationService, UserData } from '../identity/state/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { RowItemUrlService } from './worksheet-commands/row-item-url.service';

export const CLEAR_ICON = 'square-o';
export const DUPLICATE_ICON = 'clone';
export const LOAD_ICON = 'cloud-download';

/**
 * The base class for an Angular component representing an item in a worksheet.
 */
@Directive()
export abstract class WorksheetItemComponentBase implements OnInit, OnDestroy, OnChanges, IMenuItemWrapper<CommandResult>, IWorksheetWrappedContextMenuBuilder {

  /**
   * True if the worksheet is docked at the bottom of the view. False otherwise.
   */
  @Input() public readonly isWorksheetInDock: boolean;

  /**
   * The error message to display.
   */
  public errorMessage: string;

  /**
   * True if the user can write to the worksheet item. False otherwise.
   */
  public canWrite: boolean;

  /**
   * The user data for the current user.
   */
  public userData: UserData;

  /**
   * The URL to navigate to the page which displays the row item.
   */
  public url: string;

  /**
   * Creates an instance of WorksheetItemComponentBase.
   * @param authenticationService The authentication service.
   * @param cellElementToViewModelLookup The cell element to view model lookup.
   * @param elementRef The element reference.
   * @param contextMenuBuilder The context menu builder.
   * @param getFriendlyErrorAndLog The service for getting a friendly error message and logging the error.
   * @param rowItemUrl The service for generating the URL for a row item.
   * @param route The activated route.
   */
  protected constructor(
    protected readonly authenticationService: AuthenticationService,
    private readonly cellElementToViewModelLookup: CellElementToViewModelLookup,
    private readonly elementRef: ElementRef,
    private readonly contextMenuBuilder: IWorksheetContextMenuBuilder,
    protected readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog,
    protected readonly rowItemUrl: RowItemUrlService,
    protected readonly route: ActivatedRoute) {
  }

  /**
   * @inheritdoc
   */
  ngOnInit() {
    this.userData = this.authenticationService.userDataSnapshot;
    this.canWrite = this.viewModel.row.worksheet.canWrite(this.userData.sub);
    this.cellElementToViewModelLookup.register(this.elementRef.nativeElement, this.viewModel);
    this.viewModel.setContextMenuBuilder(this);
  }

  /**
   * @inheritdoc
   */
  ngOnChanges(){
    this.url = this.rowItemUrl.generate(this.viewModel, new WorksheetContext(this.isWorksheetInDock, this.route)).toString();
  }

  /**
   * @inheritdoc
   */
  ngOnDestroy(){
    this.cellElementToViewModelLookup.unregister(this.elementRef.nativeElement);
    this.viewModel.removeContextMenuBuilder(this);
  }

  /**
   * Returns a context menu for the current row item wrapped in a menu items definition.
   */
  public generateWrappedContextMenu(worksheetContext: WorksheetContext): MenuItemsDefinition<CommandResult> {
    return new MenuItemsDefinition<CommandResult>(
      this.contextMenuBuilder.generateContextMenu(this.viewModel, worksheetContext),
      this);
  }

  /**
   * Executes the given context menu action.
   * @param event The mouse event.
   * @param action The action to execute.
   * @returns The result of the action.
   */
  public async executeMenuItemAction(event: MouseEvent, action: (event: MouseEvent) => Promise<CommandResult>): Promise<CommandResult> {
    try {
      this.resetErrorMessage();
      const result = await action(event);

      if(result){
        if(result.generateColumnsImmediately){
          this.viewModel.row.worksheet.generateColumns();
        }

        if(result.refreshWorksheet === UpdateType.update
          || result.refreshWorksheet === UpdateType.updateAndGenerateColumns) {
          this.viewModel.row.worksheet.requestUpdate(
            result.refreshWorksheet === UpdateType.updateAndGenerateColumns);
          this.url = this.rowItemUrl.generate(this.viewModel, new WorksheetContext(this.isWorksheetInDock, this.route)).toString();
        }
      }

      return result;
    } catch(error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
      return undefined;
    }
  }

  /**
   * Gets the string representation of the given reference count.
   * @param referenceCount The reference count.
   * @returns The reference count string.
   */
  public getReferenceCountString(referenceCount: number): string {
    return referenceCount > 1 ? '' + referenceCount : '';
  }

  /**
   * Gets the string representation of the given dual reference counts.
   * Dual reference counts are used when a study in in both the study and telemetry columns.
   * We maintain one reference count for each column.
   * @param firstReferenceCount The first reference count.
   * @param secondReferenceCount The second reference count.
   * @returns The reference count string.
   */
  public getDualReferenceCountString(firstReferenceCount: number, secondReferenceCount: number): string {
    if((firstReferenceCount + secondReferenceCount) <= 1){
      return '';
    }

    if(firstReferenceCount === 0){
      return '' + secondReferenceCount;
    }

    if(secondReferenceCount === 0){
      return '' + firstReferenceCount;
    }

    return '' + firstReferenceCount + '+' + secondReferenceCount;
  }

  /**
   * Gets whether the current user is a test user.
   */
  public get isTestUser(): boolean{
    return this.authenticationService.isTestUser;
  }

  /**
   * Resets the error message.
   */
  public resetErrorMessage() {
    this.errorMessage = undefined;
  }

  /**
   * Handles the mouse entering the current cell.
   */
  @HostListener('mouseenter', [])
  public handleMouseEnter() {
    this.viewModel.setItemsMatching();
  }

  /**
   * Handles the mouse leaving the current cell.
   */
  @HostListener('mouseleave', [])
  public handleMouseLeave() {
    this.viewModel.clearItemsMatching();
  }

  /**
   * Handles the mouse click event.
   * @param e The mouse event.
   * @returns True if the event was handled. False otherwise.
   */
  public onClick(e: MouseEvent): boolean{
    if(e.button !==1){
      e.preventDefault();
      return false;
    }
    return true;
  }

  /**
   * Gets the view model for the row item.
   */
  protected abstract get viewModel(): RowItemViewModel;
}

/**
 * The result of a prompting the user whether they want to replace the item in the cell
 * or replace all items in cells with matching references.
 */
export enum ReplaceResult {

  /**
   * The user cancelled the operation.
   */
  cancel,

  /**
   * The user chose to replace the item in the current cell only.
   */
  replaceThisReference,

  /**
   * The user chose to replace all items in cells with matching references.
   */
  replaceAllReferences,
}


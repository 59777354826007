
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {ConfigLoaderDialogData, ConfigLoaderResult} from './config-loader-dialog.service';
import {Injectable, EventEmitter} from '@angular/core';
import {ListFilterData, GetConfigsQueryResult} from '../../../../generated/api-stubs';
import {FilterUtilities} from '../../list-filtering/filter-utilities';
import {ConfigType, ConfigTypeLookup} from '../config-types';
import {DefaultConfigSummary} from '../view-default-configs/view-default-configs.component';
import {ConfigSummary} from '../view-configs/view-configs.component';
import {Timer} from '../../../common/timer.service';
import {ConfigLoaderUtilities} from '../config-loader-utilities.service';

@Injectable()
export class ConfigLoaderDialogSessionFactory {
  constructor(
    private configLoaderUtilities: ConfigLoaderUtilities,
    private filterUtilities: FilterUtilities,
    private timer: Timer,
    private getFriendlyErrorAndLog: GetFriendlyErrorAndLog
  ){}

  public create(data: ConfigLoaderDialogData): ConfigLoaderDialogSession{
    return new ConfigLoaderDialogSession(
      data,
      this.configLoaderUtilities,
      this.filterUtilities,
      this.timer,
      this.getFriendlyErrorAndLog);
  }
}

@Injectable()
export class ConfigLoaderDialogSession {
  public errorMessage: string;
  public initialFilter: ListFilterData;
  public lastResult: GetConfigsQueryResult;
  public configType: ConfigType;
  public configSelected: EventEmitter<ConfigLoaderResult> = new EventEmitter<ConfigLoaderResult>();
  public isLoading: boolean;

  constructor(
    private dialog: ConfigLoaderDialogData,
    private configLoaderUtilities: ConfigLoaderUtilities,
    private filterUtilities: FilterUtilities,
    private timer: Timer,
    private getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  public async load() {
    try {
      this.errorMessage = undefined;
      if(this.dialog.initialFilter){
        this.initialFilter = this.dialog.initialFilter;
      } else {
        this.initialFilter = this.filterUtilities.getInitialFilter();
      }
      await this.timer.yield();
      this.configType = ConfigTypeLookup.get(this.dialog.configType);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async onConfigSelected(config: ConfigSummary) {
    try {
      this.isLoading = true;
      let result: ConfigLoaderResult;
      if(this.dialog.returnReferenceOnly){
        result = {
          userId: config.userId,
          configId: config.documentId,
          config: undefined,
        };
      } else {
        let studyInput = await this.configLoaderUtilities.loadUserConfig(
          config.tenantId,
          config.userId,
          config.documentId,
          this.dialog.simVersion,
          this.dialog.subTreePath);

        result = {
          userId: studyInput.userId,
          configId: studyInput.configId,
          config: studyInput
        };
      }

      this.configSelected.emit(result);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
    this.isLoading = false;
  }

  public async onDefaultConfigSelected(config: DefaultConfigSummary) {
    try {
      this.isLoading = true;
      let result: ConfigLoaderResult;
      if(this.dialog.returnReferenceOnly){
        result = {
          userId: undefined,
          configId: config.name,
          config: undefined,
        };
      } else {
        let studyInput = await this.configLoaderUtilities.loadSimVersionConfig(
          this.configType,
          config.simVersion,
          config.name);

        result = {
          userId: studyInput.userId,
          configId: studyInput.configId,
          config: studyInput
        };
      }

      this.configSelected.emit(result);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
    this.isLoading = false;
  }
}


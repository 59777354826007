@if (showLauncher) {
  <div class="support-session-launcher">
    <button class="btn btn-secondary" type="button" (click)="open()"
      [ngClass]="{ 'session-exists': supportSession?.session, 'support-open': supportSession?.session && supportSession.session.isOpen, 'support-closed': supportSession?.session && !supportSession.session.isOpen }">
      @if (!supportSession?.session) {
        <span>
          <i class="fa fa-medkit" aria-hidden="true"></i>Support
        </span>
      }
      @if (supportSession?.session) {
        <div>
          <div class="status"><i class="fa fa-medkit" aria-hidden="true"></i>Session {{supportSession.session.isOpen ? 'Open' : 'Closed' }}</div>
          <div class="last-modified-date">Updated by {{map.users[supportSession.session.modifiedUserId].username}} {{supportSession.session.modifiedDate | fromNow}}</div>
        </div>
      }
    </button>
  </div>
}

import {FrontAndRearTyres} from './front-and-rear-tyres';
import {Timer} from '../../common/timer.service';
import {Injectable} from '@angular/core';
import {ConvertPacejkaJsonToCanopy} from './convert-pacejka-json-to-canopy.service';
import {LoadingDialog} from '../../common/dialogs/loading-dialog.service';
import {ConfirmationDialog} from '../../common/dialogs/confirmation-dialog.service';

@Injectable()
export class PerformPacejkaCanopyConversionFactory {

  constructor(
    private readonly timer: Timer,
    private readonly convertPacejkaJsonToCanopy: ConvertPacejkaJsonToCanopy,
    private readonly loadingDialog: LoadingDialog,
    private readonly confirmationDialog: ConfirmationDialog){

  }

  public create(simVersion: string, jsonEditor: any){
    return new PerformPacejkaCanopyConversion(
      simVersion,
      jsonEditor,
      this.timer,
      this.convertPacejkaJsonToCanopy,
      this.loadingDialog,
      this.confirmationDialog);
  }
}

export class PerformPacejkaCanopyConversion {

  constructor(
    private readonly simVersion: string,
    private readonly jsonEditor: any,
    private readonly timer: Timer,
    private readonly convertPacejkaJsonToCanopy: ConvertPacejkaJsonToCanopy,
    private readonly loadingDialog: LoadingDialog,
    private readonly confirmationDialog: ConfirmationDialog){

  }

  public async execute(){
    let tyresEditor = this.jsonEditor.editors['root.tyres'];
    let tyresConfig = tyresEditor.getValue();
    if(!tyresConfig.name.startsWith('Pacejka')){
      return;
    }

    let conversionResult = await this.loadingDialog.showUntilFinished(
      this.convertPacejkaJsonToCanopy.execute(
        this.simVersion,
        tyresConfig.name,
        tyresConfig.front,
        tyresConfig.rear,
        tyresConfig.frontL,
        tyresConfig.rearL,),
      'Converting tyres...');

    if (conversionResult.errorMessages && conversionResult.errorMessages.length > 0){
      await this.confirmationDialog.show(
        'The following errors occurred during the conversion:',
        'TIR Import',
        'OK',
        'OK',
        undefined,
        conversionResult.errorMessages);

      return;
    }

    await this.saveTyres(conversionResult.tyres);
  }

  public async saveTyres(tyres: FrontAndRearTyres): Promise<void> {
    if(!tyres || !tyres.front || !tyres.rear) {
      return Promise.resolve();
    }

    let tyresEditor = this.jsonEditor.editors['root.tyres'];
    let tyresIndex = tyresEditor.display_text.findIndex((v: string) => v.indexOf(tyres.type) !== -1);
    // let otherIndex = tyresIndex === 0 ? 1 : 0;
    //
    // // First we switch to another tyres type to wipe out any non-shared parameters.
    // await this.switchEditorNode(tyresEditor, otherIndex);

    await this.switchEditorNode(tyresEditor, tyresIndex);

    // Bypass validation at this point.
    let oldEditorValue = this.jsonEditor.getValue();

    let newEditorValue = {
      ...oldEditorValue,
      tyres: {
        ...oldEditorValue.tyres
      }
    };

    newEditorValue.tyres.name = tyres.type;

    let sides: ('front' | 'rear' | 'frontL' | 'rearL')[] = ['front', 'rear', 'frontL', 'rearL'];
    for(let side of sides){
      if(!tyres[side]) {
        continue;
      }

      newEditorValue.tyres[side] = {
        ...newEditorValue.tyres[side],
        ...tyres[side]
      };
      // newEditorValue.tyres[side].rUnloaded = oldEditorValue.tyres[side].rUnloaded;
      // newEditorValue.tyres[side].vLowSpeed = oldEditorValue.tyres[side].vLowSpeed;
      // newEditorValue.tyres[side].radiusEquations = oldEditorValue.tyres[side].radiusEquations;
      // newEditorValue.tyres[side].rollingResistance = oldEditorValue.tyres[side].rollingResistance;
    }

    this.jsonEditor.setValue(newEditorValue);

    return Promise.resolve();
  }

  private async switchEditorNode(multipleEditor: any, index: number) {
    if(multipleEditor.switcher.value === multipleEditor.display_text[index]){
      return;
    }

    multipleEditor.switchEditor(index);
    multipleEditor.switcher.value = multipleEditor.display_text[index];
    multipleEditor.refreshValue();
    multipleEditor.onChange(true);

    await this.timer.yield();
  }
}

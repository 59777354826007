import {Injectable} from '@angular/core';
import {StudyTypeLookup} from './study-type-lookup.service';
import {
  SimType, PostStudyInlineResultScalarResult
} from '../../../generated/api-stubs';
import {BlobLoader} from '../../common/blob-loader.service';
import {AccessInformation} from '../visualizations/retrying-file-loader-base';
import {asNumber} from '../../common/try-parse-as-number';

@Injectable()
export class StudyUtilities {

  constructor(
    private blobLoader: BlobLoader,
    private studyTypeLookup: StudyTypeLookup
  ){}

  public async downloadWarnings(
    tenantId: string,
    studyId: string,
    jobId: string,
    jobUrl: string,
    studyAccessInformation: AccessInformation): Promise<string>{
    return await this.blobLoader.loadText(
      jobUrl + 'warning.txt',
      tenantId,
      studyId,
      jobId,
      studyAccessInformation);
  }

  public async downloadText(
    tenantId: string,
    studyId: string,
    jobId: string,
    jobUrl: string,
    studyAccessInformation: AccessInformation,
    fileName: string): Promise<string>{
    return await this.blobLoader.loadText(
      jobUrl + fileName,
      tenantId,
      studyId,
      jobId,
      studyAccessInformation);
  }

  public async downloadInfo(
    tenantId: string,
    studyId: string,
    jobId: string,
    jobUrl: string,
    studyAccessInformation: AccessInformation): Promise<string>{
    return await this.blobLoader.loadText(
      jobUrl + 'info.txt',
      tenantId,
      studyId,
      jobId,
      studyAccessInformation);
  }

  public async downloadDiagnosis(
    tenantId: string,
    studyId: string,
    jobId: string,
    jobUrl: string,
    studyAccessInformation: AccessInformation): Promise<string>{
    return await this.blobLoader.loadText(
      jobUrl + 'diagnosis.txt',
      tenantId,
      studyId,
      jobId,
      studyAccessInformation);
  }

  public async downloadScalarResultSets(
    simTypes: string[],
    tenantId: string,
    studyId: string,
    jobId: string,
    jobUrl: string,
    studyAccessInformation: AccessInformation): Promise<ScalarResultSet[]>{
    let downloadedScalarResults: {[simType: string]: any} = {};
    for(let simType of [...simTypes, "Debug"]){
      let simScalarResults = await this.blobLoader.loadCsv(
        jobUrl + simType + '_ScalarResults.csv',
        tenantId,
        studyId,
        jobId,
        studyAccessInformation);

      if(simScalarResults){
        downloadedScalarResults[simType] = simScalarResults.map(v =>
        ({
          ...v,
          value: asNumber(v.value),
        }));
      }
    }

    return await this.getScalarResultSets(downloadedScalarResults);
  }

  public async getScalarResultSets(scalarResults: { [simType: string]: ReadonlyArray<ScalarResult> }): Promise<ScalarResultSet[]>{
    let results = [];
    let simTypeMap = await this.studyTypeLookup.getSimTypeMap();

    for(let scalarKey in scalarResults) {
      if(!scalarResults.hasOwnProperty(scalarKey)){
 continue;
}

      let studyType = simTypeMap[scalarKey];
      if(studyType){
        let simTypeResults = scalarResults[scalarKey];
        let data = new ScalarResultSet(
          studyType.name,
          studyType.simType,
          simTypeResults,
          this.getStandardScalarResults(simTypeResults),
          this.getConstraintScalarResults(simTypeResults),
          this.getSetupScalarResults(simTypeResults));

          results.push(data);
      }else if(scalarKey == "Debug"){
        let simTypeResults = scalarResults[scalarKey];
        let data = new ScalarResultSet(
          "Debug",
          null,
          simTypeResults,
          this.getStandardScalarResults(simTypeResults),
          this.getConstraintScalarResults(simTypeResults),
          this.getSetupScalarResults(simTypeResults));
        results.push(data);
      }
    }

    return results;
  }

  public async getScalarResultSetsFromInline(scalarResults: { [simType: string]: { [name: string]: PostStudyInlineResultScalarResult}}): Promise<ScalarResultSet[]>{
    let results = [];
    let simTypeMap = await this.studyTypeLookup.getSimTypeMap();

    for(let simTypeKey in scalarResults) {
      if(!scalarResults.hasOwnProperty(simTypeKey)){
 continue;
}

      let simType = simTypeMap[simTypeKey];
      if(simType){
        let simTypeData = scalarResults[simTypeKey];
        let simTypeResults: ScalarResult[] = [];

        for(let scalarResultName in simTypeData) {
          if (!simTypeData.hasOwnProperty(scalarResultName)) {
            continue;
          }

          simTypeResults.push({
            ...simTypeData[scalarResultName],
            name: scalarResultName,
          });
        }

        let data = new ScalarResultSet(
          simType.name,
          simType.simType,
          simTypeResults,
          this.getStandardScalarResults(simTypeResults),
          this.getConstraintScalarResults(simTypeResults),
          this.getSetupScalarResults(simTypeResults),
        );

        results.push(data);
      }
    }

    return results;
  }

  private getStandardScalarResults(allResults: ReadonlyArray<ScalarResult>): ScalarResult[] {
    return allResults.filter(v => !v.constraintParameterPath && !this.isSetupScalarResult(v));
  }

  private getConstraintScalarResults(allResults: ReadonlyArray<ScalarResult>): ScalarResult[] {
    return allResults.filter(v => v.constraintParameterPath && !this.isSetupScalarResult(v));
  }

  private getSetupScalarResults(allResults: ReadonlyArray<ScalarResult>): ScalarResult[] {
    return allResults.filter(v => this.isSetupScalarResult(v));
  }

  private isSetupScalarResult(item: ScalarResult): boolean {
    return item.name.endsWith('_Setup');
  }
}

export class ScalarResultSet {
  constructor(
    public readonly name: string,
    public readonly simType: SimType,
    public readonly results: ReadonlyArray<ScalarResult>,
    public readonly standardResults: ReadonlyArray<ScalarResult>,
    public readonly constraintResults: ReadonlyArray<ScalarResult>,
    public readonly setupResults: ReadonlyArray<ScalarResult>
  ){}
}

export interface ScalarResult {
  readonly name: string;
  readonly value: number;
  readonly description: string;
  readonly units: string;
  readonly constraintParameterPath: string;
}

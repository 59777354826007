import { Injectable } from '@angular/core';
import { stringToBoolean } from './string-to-boolean';
import { JsonEditorCustomization } from './json-editor-customization';
import{ JSONEditor } from'@json-editor/json-editor'

@Injectable()
export class SplitBooleansEditor extends JsonEditorCustomization {
  public apply(): void {
    JSONEditor.defaults.editors.splitBooleans = class extends JSONEditor.defaults.editors.splitStrings {
      getValue() {
        return super.getValue().map(function(str: string) {
 return stringToBoolean(str);
});
      }
    }
  }
}

import {Injectable} from '@angular/core';
import {CommandResult, ICommandContext} from './worksheet-command';
import {ConfigViewModel} from '../config-view-model';
import {ReplaceResult} from '../worksheet-item-component-base';
import {SetConfigOrAllReferences} from './set-config-or-all-references.service';
import {ConfirmReplaceAllReferences} from './confirm-replace-all-references.service';
import {StudyLoaderDialog} from '../study-loader-dialog/study-loader-dialog.service';
import { AuthenticationService } from '../../identity/state/authentication.service';

/**
 * A service which prompts the user for a study to set as the telemetry config on the selected rows.
 */
@Injectable()
export class SetTelemetryConfig {

  /**
   * Creates an instance of SetTelemetryConfig.
   * @param setConfigOrAllReferences The service for setting the config.
   * @param confirmReplaceAllReferences The service for confirming the replacement of all references.
   * @param studyLoaderDialog The study loader dialog service.
   * @param authenticationService The authentication service.
   */
  constructor(
    private readonly setConfigOrAllReferences: SetConfigOrAllReferences,
    private readonly confirmReplaceAllReferences: ConfirmReplaceAllReferences,
    private readonly studyLoaderDialog: StudyLoaderDialog,
    private readonly authenticationService: AuthenticationService) {
  }

  /**
   * Sets the telemetry config for the selected rows.
   * @param context The command context.
   * @returns The command result.
   */
  public async execute(context: ICommandContext<ConfigViewModel>): Promise<CommandResult> {
    const rows = context.selectedRows;
    const replaceResult = await this.confirmReplaceAllReferences.execute(context.target, rows);
    if(replaceResult === ReplaceResult.cancel){
      return CommandResult.NoUpdate;
    }

    const userData = this.authenticationService.userDataSnapshot;
    const studyResult = await this.studyLoaderDialog.loadStudy(
      userData.tenant,
      userData.sub,
      undefined);

    if(!studyResult) {
      return CommandResult.NoUpdate;
    }

    await this.setConfigOrAllReferences.execute(
      context.target,
      rows,
      {
        tenant: {
          tenantId: userData.tenant,
          targetId: studyResult.studyId,
        }
      },
      replaceResult);

    return CommandResult.UpdateOnly;
  }
}

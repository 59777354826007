import { Injectable } from '@angular/core';
import { RowMetadataViewModel } from '../../row-metadata-view-model';
import { ICommandContext } from '../worksheet-command';
import { StudyTypeLookup } from '../../../simulations/studies/study-type-lookup.service';
import { GetSimVersion } from '../../../common/get-sim-version.service';
import { DocumentSubType, StudyType } from '../../../../generated/api-stubs';
import { ConfigViewModel } from '../../config-view-model';


/**
 * A service for getting the configs to import from the staging area based on the selected study type and the user's selection.
 */
@Injectable()
export class GetConfigsToImport {

  /**
   * Creates an instance of GetConfigsToImport.
   * @param studyTypeLookup The service for looking up study types.
   * @param getSimVersion The service for getting the current sim version.
   */
  constructor(
    private readonly studyTypeLookup: StudyTypeLookup,
    private readonly getSimVersion: GetSimVersion) {
  }

  /**
   * Executes the command.
   * @param context The command context.
   * @param stagingAreaStudyType The study type in the staging area.
   * @returns The configs to import.
   */
  public async execute(context: ICommandContext<RowMetadataViewModel | ConfigViewModel>, stagingAreaStudyType: StudyType): Promise<ReadonlyArray<DocumentSubType>> {
    if (context.isConfig()) {
      // If we've got a config selected, then just import that config type.
      return [context.target.configType];
    }

    // Otherwise import all the configs for the selected study type.
    const simVersion = this.getSimVersion.currentSimVersion;
    const studyTypesLookup = await this.studyTypeLookup.getStudyTypeMap(simVersion);
    return studyTypesLookup[stagingAreaStudyType].inputs.map(v => v.configType);
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorage } from '../common/session-storage.service';

export type Source = 'zendesk' | 'canopy';

const SOURCE = 'source';
const REDIRECT_URL = 'redirectUrl';

@Injectable()
export class RedirectService {

  public constructor(
    private readonly router: Router,
    private readonly sessionStorage: SessionStorage) {}

  public setRedirect(source: Source, redirectUrl: string): void {
    if(redirectUrl !== '/sign-in-redirect'){
      this.sessionStorage.setItem(SOURCE, source);
      this.sessionStorage.setItem(REDIRECT_URL, redirectUrl);
    }
  }

  public handleRedirect(): void {
    const source: Source = this.getSource();
    const redirectUrl: string = this.getRedirectUrl();

    this.redirect(source, redirectUrl, false);
  }

  public handleRedirectWithDefault(): void {
    const source: Source = this.getSource();
    const redirectUrl: string = this.getRedirectUrl();

    this.redirect(source, redirectUrl);
  }

  private redirect(source: string, redirectUrl: string, enableDefault: boolean = true): void {
    switch (source) {
      case 'zendesk':
        this.router.navigate(['/zendesk'], { queryParams: { return_to: redirectUrl } });
        break;
      case 'canopy':
        this.router.navigateByUrl(redirectUrl);
        break;
      default:
        if (enableDefault) {
          this.router.navigateByUrl('/');
        }
        break;
    }
  }

  private getSource(): Source {
    const source: Source = this.sessionStorage.getItem(SOURCE);
    this.sessionStorage.removeItem(SOURCE);
    return source;
  }

  private getRedirectUrl(): string {
    const redirectUrl: string = this.sessionStorage.getItem(REDIRECT_URL);
    this.sessionStorage.removeItem(REDIRECT_URL);
    return redirectUrl;
  }

}

import { ExplorationSubSweepDataType } from './exploration-sub-sweep';
import { ProcessedExplorationMapValues } from './exploration-map-values';
import { Utilities } from '../../../utilities';


/**
 * Gets numeric values of the sub-sweep, irrespective of the data type.
 */
export class GetExplorationSubSweepNumericValues {

  /**
   * Gets numeric values of the sub-sweep, irrespective of the data type.
   * @param dataType The data type of the sub-sweep.
   * @param values The values of the sub-sweep.
   * @returns The numeric values of the sub-sweep.
   */
  public static execute(dataType: ExplorationSubSweepDataType, values: ProcessedExplorationMapValues): number[] {

    // Create the array to store the results.
    const numericValues: number[] = Array(values.length);

    if (dataType === ExplorationSubSweepDataType.Numeric) {

      // If the values are numeric, then just copy them (unless an individual value is not numeric, in which case
      // set it to NaN).
      for (let i = 0; i < values.length; ++i) {
        let value = values[i];
        if (typeof value === 'number') {
          numericValues[i] = value;
        } else {
          numericValues[i] = NaN;
        }
      }
    } else if (dataType === ExplorationSubSweepDataType.Boolean) {
      // Map booleans to 0 or 1.
      for (let i = 0; i < values.length; ++i) {
        numericValues[i] = values[i] ? 1 : 0;
      }
    } else {
      // Otherwise assign an index to each unique value and use the index as the numeric value.
      let uniqueValues = Utilities.unique(values);
      for (let i = 0; i < values.length; ++i) {
        let value = values[i];
        numericValues[i] = uniqueValues.indexOf(value);
      }
    }

    return numericValues;
  }
}


/**
 * The type of column in the worksheet.
 */
export enum ColumnType {

  /**
   * The column is a row metadata column (the left most column in a worksheet).
   */
  rowMetadata,

  /**
   * The column is a config column.
   */
  config,

  /**
   * The column is a study column.
   */
  study,

  /**
   * The column is a simulation column.
   */
  simulation,
}

/**
 * Base class for transient data for a multi-plot layout, which only exists for the duration
 * of the current viewer.
 */

export class TransientMultiPlotBase {

  /**
   * A generic set of boolean flags.
   */
  public readonly flags: { [name: string]: boolean } = {};

  /**
   * A generic map of custom data.
   */
  public readonly custom: { [name: string]: any } = {};
}

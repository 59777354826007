import { Injectable } from '@angular/core';
import { ConfigImport } from '../../simulations/configs/config-import.service';
import { CommandContext, CommandResult, ICommandContext, WorksheetCommand } from './worksheet-command';
import { ButtonMenuItem, MenuItem } from '../../context-menu/context-menu-types';
import { ConfigViewModel } from '../config-view-model';
import { GetSimVersion } from '../../common/get-sim-version.service';
import { AuthenticationService } from '../../identity/state/authentication.service';

/**
 * A command that imports a config from a file and adds it to the row.
 */
@Injectable({
  providedIn: 'root'
})
export class ImportConfigFromFileCommand extends WorksheetCommand {

  /**
   * Creates an instance of ImportConfigFromFileCommand.
   * @param authenticationService The authentication service.
   * @param configImport The service for importing a config from a file.
   * @param simVersion The service for getting the current sim version.
   */
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly configImport: ConfigImport,
    private readonly simVersion: GetSimVersion){
      super();
    }

  /**
   * Determines if the command can be executed.
   * @param context The command context.
   * @returns True if the command can be executed, otherwise false.
   */
  public canExecute(context: CommandContext): context is ICommandContext<ConfigViewModel> {
    const userData = this.authenticationService.userDataSnapshot;
    return context.isConfig() && context.worksheet.canWrite(userData.sub);
  }

  /**
   * Creates the menu items for the command.
   * @param context The command context.
   * @param result The menu items.
   */
  public createMenuItems(context: CommandContext, result: MenuItem<CommandResult>[]): void {
    if(!this.canExecute(context)) {
      return;
    }
    result.push(new ButtonMenuItem<CommandResult>(
      'Import from File',
      '',
      async ()=>{
        let resolve: () => void;
        let promise = new Promise<void>(r => resolve = r);
        Object.assign(document.createElement('input'),
        {
          type: 'file',
          accept: '.json',
          onchange: async (event: Event) => {
            await this.doImport(context, event);
            resolve();
          },
          oncancel: async () => {
            resolve();
          }
        }).click();
        await promise;
        return CommandResult.UpdateAndGenerateColumns;
      },
      'upload'));
  }

  /**
   * Adds the local config selected by the user to the row.
   * @param context The command context.
   * @param event The event from the select file dialog.
   */
  private async doImport(context: ICommandContext<ConfigViewModel>, event: Event){
    let newConfig = await this.configImport.extractConfigFromEvent(context.worksheet.tenantId, event, context.target.configType, this.simVersion.currentSimVersion);
    if(!newConfig){
      return;
    }
    let targetId = await this.configImport.saveConfig(context.worksheet.tenantId, newConfig);
    if(targetId){
      context.target.row.getOrCreateConfig(context.target.configType).setConfig({tenant:{tenantId: context.worksheet.tenantId, targetId}});
    }
  }
}

import { ExplorationSubSweepMetadata, ExplorationSweepMetadata } from '../exploration-inputs-metadata';
import { ArrayMappings } from './array-mappings';


/**
 * Applies a re-mapping to an input sweep.
 */
export class ApplySweepRemappingToInputSweep {

  /**
   * Applies a re-mapping to an input sweep.
   * @param mapping The mapping to apply.
   * @param sweep The sweep to apply the mapping to.
   * @returns The sweep with the mapping applied.
   */
  public execute(mapping: ArrayMappings, sweep: ExplorationSweepMetadata): ExplorationSweepMetadata {
    return new ExplorationSweepMetadata(
      sweep.subSweeps.map(v => new ExplorationSubSweepMetadata(
        v.name,
        this.applyMapping(mapping, v.values),
        v.unexpandedName)));
  }

  /**
   * Applies a mapping to an array of values.
   * @param mapping The mapping to apply.
   * @param values The values to apply the mapping to.
   * @returns The values with the mapping applied.
   */
  private applyMapping<T>(mapping: ArrayMappings, values: ReadonlyArray<T>): T[] {
    const result: T[] = Array(values.length);

    for (let i = 0; i < values.length; ++i) {
      result[i] = values[mapping.newIndexToOldIndex[i]];
    }

    return result;
  }
}

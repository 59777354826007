import { IHasSourceData, IViewerChannelData } from './viewer-channel-data';
import { ChannelNameStyle } from './channel-name-style';
import { ViewerChannelDataMap, ViewerChannelDataMapContent } from './viewer-channel-data-map';

/**
 * The metadata about a source.
 */
export class SourceMetadata {

  /**
   * Creates an instance of SourceMetadata.
   * @param name The name of the source.
   */
  constructor(
    public readonly name: string) {
  }
}

/**
 * Represents a source loader.
 */
export interface ISourceLoader {

  /**
   * Gets the source metadata.
   * @returns The source metadata.
   */
  getSourceMetadata(): Promise<SourceMetadata>;

  /**
   * Gets the requested channel data.
   * @param requestedName The requested channel name.
   * @param resultChannelNameStyle The style of the channel name to return.
   * @param xDomainName The name of the x domain.
   * @returns The channel data.
   */
  getChannelData(requestedName: string, resultChannelNameStyle: ChannelNameStyle, xDomainName: string): Promise<IViewerChannelData>;

  /**
   * Gets the requested channel data for multiple channels at the same time.
   * Some data sources may be able to optimize this operation.
   * @param requestedNames The requested channel names.
   * @param resultChannelNameStyle The style of the channel name to return.
   * @param primaryDomainName The name of the primary domain.
   * @returns The channel data.
   */
  getMultipleChannelData(requestedNames: ReadonlyArray<string>, resultChannelNameStyle: ChannelNameStyle, primaryDomainName: string): Promise<ViewerChannelDataMap>;

  /**
   * Gets the list of channels that can be requested for this data source..
   * @param resultChannelNameStyle The style of the channel name to return.
   * @param xDomainName The name of the x domain.
   * @returns The requestable channels.
   */
  getRequestableChannels(resultChannelNameStyle: ChannelNameStyle, xDomainName: string): Promise<IChannelMetadata[]>;

  /**
   * Gets the specified config (e.g. car, track) for the source.
   * Not all sources will support this.
   * @param type The type of config to get.
   * @returns The config.
   */
  getConfig(type: string): Promise<any>;

  /**
   * Gets the unique identifier for this source loader.
   */
  readonly id: string;
}

/**
 * A base class for a source loader.
 * Contains some default implementations for ISourceLoader.
 */
export abstract class SourceLoaderBase implements ISourceLoader {

  /**
   * The unique identifier for this source loader.
   */
  protected _id: string;

  /**
   * Creates an instance of SourceLoaderBase.
   */
  constructor() {

    // Create a unique ID that is statistically random enough.
    this._id = Math.random().toString();
  }

  /**
   * @inheritdoc
   */
  public get id(): string {
    return this._id;
  }

  /**
   * @inheritdoc
   */
  abstract getSourceMetadata(): Promise<SourceMetadata>;

  /**
   * @inheritdoc
   */
  abstract getChannelData(requestedName: string, resultChannelNameStyle: ChannelNameStyle, xDomainName: string): Promise<IViewerChannelData>;

  /**
   * @inheritdoc
   */
  abstract getRequestableChannels(resultChannelNameStyle: ChannelNameStyle, xDomainName: string): Promise<IChannelMetadata[]>;

  /**
   * @inheritdoc
   */
  public async getMultipleChannelData(requestedNames: ReadonlyArray<string>, resultChannelNameStyle: ChannelNameStyle, primaryDomainName: string): Promise<ViewerChannelDataMap> {
    // A naive implementation for getting multiple channels.
    // Override if a more efficient implementation is possible.
    let map: ViewerChannelDataMapContent = {};
    for (let channelName of requestedNames) {
      let channelData = map[channelName];
      if (!channelData) {
        channelData = await this.getChannelData(channelName, resultChannelNameStyle, primaryDomainName);
        map[channelName] = channelData;
      }
    }
    return new ViewerChannelDataMap(map);
  }

  /**
   * @inheritdoc
   */
  public getConfig(type: string): Promise<any> {
    return Promise.resolve(undefined);
  }
}

/**
 * The metadata about a channel.
 */
export interface IChannelMetadata extends IHasSourceData {
  /**
   * The name of the channel.
   */
  readonly name: string;

  /**
   * The description of the channel.
   */
  readonly description: string;
}

/**
 * The metadata about a channel that allows direct access to the source data.
 */
export class SourceChannelMetadata<TSourceData> implements IChannelMetadata {
  constructor(
    public readonly sourceData: TSourceData,
    public readonly name: string,
    public readonly description: string) {
  }

  /**
   * Get's the source data the channel.
   */
  public getSourceData<T>(): T {
    return (this.sourceData || {}) as any as T;
  }
}

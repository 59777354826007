import {Injectable} from '@angular/core';
import {AccessInformation} from '../simulations/visualizations/retrying-file-loader-base';
import {BasicFileLoaderFactory} from './basic-file-loader.service';
import {getJobIndexFromJobId} from './get-job-index-from-job-id';
import {ChannelBinaryFormat} from '../visualizations/url-file-loader';

@Injectable()
export class BlobLoader {

  constructor(
    private basicFileLoaderFactory: BasicFileLoaderFactory){
  }

  public loadText(url: string, tenantId: string, studyId: string, jobId: string, accessInformation: AccessInformation): Promise<any> {
    let fileLoader = this.basicFileLoaderFactory.create(tenantId, studyId, accessInformation);
    let jobIndex = getJobIndexFromJobId(jobId);
    return fileLoader.text(url, jobIndex);
  }

  public loadJson(url: string, tenantId: string, studyId: string, jobId: string, accessInformation: AccessInformation): Promise<any> {
    let fileLoader = this.basicFileLoaderFactory.create(tenantId, studyId, accessInformation);
    let jobIndex = getJobIndexFromJobId(jobId);
    return fileLoader.json(url, jobIndex);
  }

  public loadCsv(url: string, tenantId: string, studyId: string, jobId: string, accessInformation: AccessInformation): Promise<any[]> {
    let fileLoader = this.basicFileLoaderFactory.create(tenantId, studyId, accessInformation);
    let jobIndex = getJobIndexFromJobId(jobId);
    return fileLoader.csv(url, jobIndex);
  }

  public loadNumericArray(url: string, tenantId: string, studyId: string, jobId: string, accessInformation: AccessInformation, binaryFormat: ChannelBinaryFormat | undefined): Promise<ReadonlyArray<number>> {
    let fileLoader = this.basicFileLoaderFactory.create(tenantId, studyId, accessInformation);
    let jobIndex = getJobIndexFromJobId(jobId);
    return fileLoader.loadNumericArray(url, binaryFormat, jobIndex);
  }
}

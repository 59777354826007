import { IRectangle } from '../rectangle';

/**
 * Represents a selection rectangle for zooming.
 */
export class ZoomBrushSelection implements IRectangle {

  /**
   * Gets whether the selection is reversed in the X direction (i.e. the user is dragging right to left).
   * We ensure the width is always positive, so this flag is the only way to determine if the selection is reversed.
   */
  public readonly isSelectionReversedX: boolean = false;

  /**
   * Gets whether the selection is reversed in the Y direction (i.e. the user is dragging bottom to top).
   * We ensure the height is always positive, so this flag is the only way to determine if the selection is reversed.
   */
  public readonly isSelectionReversedY: boolean = false;

  /**
   * Initializes a new instance of the ZoomBrushSelection class.
   * @param x The X coordinate.
   * @param y The Y coordinate.
   * @param width The width.
   * @param height The height.
   */
  constructor(
    public readonly x: number,
    public readonly y: number,
    public readonly width: number,
    public readonly height: number) {

    // Ensure the width is always positive.
    if (width < 0) {
      this.x = x + width;
      this.width = -1 * width;
      this.isSelectionReversedX = true;
    }

    // Ensure the height is always positive.
    if (height < 0) {
      this.y = y + height;
      this.height = -1 * height;
      this.isSelectionReversedY = true;
    }
  }
}

import { Injectable } from '@angular/core';
import { stringToBoolean } from './string-to-boolean';
import { JsonEditorCustomization } from './json-editor-customization';
import{ JSONEditor } from'@json-editor/json-editor'

@Injectable()
export class SplitBooleans2DEditor extends JsonEditorCustomization {
  public apply(): void {
    JSONEditor.defaults.editors.splitBooleans2D = class extends JSONEditor.defaults.editors.splitStrings2D {
      getValue() {
        return super.getValue().map(function(line: string[]) {
 return line.map(function(str) {
 return stringToBoolean(str);
});
});
      }
    }
  }
}

import { BootstrapBase } from '../bootstrap-base';
import { HTMLDivSelection, SVGSelection } from '../../untyped-selection';
import * as d3 from '../../d3-bundle';
import {
  ILegendChannel, ILegendList, LegendRenderer,
  RenderOrientation
} from '../../viewers/components/legend-renderer';
import { LEGEND_RENDERER_NAME } from '../test-area-names';
import { Disposable } from '../../disposable';
import { ChartSettings } from '../../viewers/chart-settings';
import { Size } from '../../viewers/size';
import { Margin } from '../../viewers/margin';
import { Utilities } from '../../utilities';

export class BootstrapLegendRenderer extends BootstrapBase {

  public get name() {
    return LEGEND_RENDERER_NAME;
  }

  private createInitialData() {
    return [
      {
        size: 90,
        channels: [
          {
            name: 'a',
            genericName: 'a',
            units: 'u1',
            legendValues: [1, 2],
            channelIndex: 0,
            isVisible: true
          },
          {
            name: 'b',
            genericName: 'b',
            units: 'u2',
            legendValues: [3, 4],
            channelIndex: 1,
            isVisible: true
          }
        ]
      },
      {
        size: 90,
        channels: [
          {
            name: 'c',
            genericName: 'c',
            units: 'u3',
            legendValues: [5, 6],
            channelIndex: 3,
            isVisible: true
          },
          {
            name: 'd',
            genericName: 'd',
            units: 'u4',
            legendValues: [7, NaN],
            channelIndex: 4,
            isVisible: false
          }
        ]
      },
      {
        size: 40,
        channels: [
          {
            name: 'e',
            genericName: 'e',
            units: 'u5',
            legendValues: [NaN, NaN],
            channelIndex: 5,
            isVisible: true
          }
        ]
      }
    ];
  }

  public async load(testArea: HTMLDivSelection): Promise<Disposable | undefined> {

    await this.createLegendRenderer(testArea, 'Vertical',
      this.createInitialData(), RenderOrientation.vertical);

    let horizontalData = this.createInitialData();
    horizontalData.forEach(v => v.size = 200);
    await this.createLegendRenderer(testArea, 'Horizontal',
      horizontalData, RenderOrientation.horizontal);

    await this.createLegendRendererAndRemoveItems(testArea, 'Vertical Remove Items',
      this.createInitialData(), RenderOrientation.vertical);

    await this.createLegendRendererAndAddItems(testArea, 'Vertical Add Items',
      this.createInitialData(), RenderOrientation.vertical);

    await this.createLegendRendererAndChangeItems(testArea, 'Vertical Change Items',
      this.createInitialData(), RenderOrientation.vertical);

    return undefined;
  }

  private async createLegendRenderer(testArea: HTMLDivSelection, name: string, data: ILegendList[], orientation: RenderOrientation) {

    await this.createTestItem(testArea, 'Legend Renderer ' + name, 'legend-renderer-tests', async (svg, parent) => {

      let { settings, value, siteHooks } = this.getRenderData(data, orientation, svg);

      let legendRenderer = new LegendRenderer()
        .data(data)
        .orientation(orientation)
        .siteHooks(<any>siteHooks)
        .chartSettings(settings);

      this.subscribeToValueChanged(value, data, legendRenderer, svg, parent);

      legendRenderer.render(svg, parent);
    });
  }

  private async createLegendRendererAndRemoveItems(testArea: HTMLDivSelection, name: string, data: ILegendList[], orientation: RenderOrientation) {

    await this.createTestItem(testArea, 'Legend Renderer ' + name, 'legend-renderer-tests', async (svg, parent) => {

      let { settings, value, siteHooks } = this.getRenderData(data, orientation, svg);

      let legendRenderer = new LegendRenderer()
        .data(data)
        .orientation(orientation)
        .siteHooks(<any>siteHooks)
        .chartSettings(settings);

      legendRenderer.render(svg, parent);

      data[0].channels[0].legendValues = [99];
      (data[1].channels as ILegendChannel[]).pop();
      data.pop();

      legendRenderer.render(svg, parent);

      this.subscribeToValueChanged(value, data, legendRenderer, svg, parent);
    });
  }

  private async createLegendRendererAndAddItems(testArea: HTMLDivSelection, name: string, data: ILegendList[], orientation: RenderOrientation) {

    await this.createTestItem(testArea, 'Legend Renderer ' + name, 'legend-renderer-tests', async (svg, parent) => {

      let { settings, value, siteHooks } = this.getRenderData(data, orientation, svg);

      let legendRenderer = new LegendRenderer()
        .data(data)
        .orientation(orientation)
        .siteHooks(<any>siteHooks)
        .chartSettings(settings);

      legendRenderer.render(svg, parent);

      data.push(Utilities.deepClone(data[0]));
      data[0].channels[0].legendValues.push(99);
      (data[1].channels as ILegendChannel[]).push(Utilities.deepClone(data[1].channels[0]));

      legendRenderer.render(svg, parent);

      this.subscribeToValueChanged(value, data, legendRenderer, svg, parent);
    });
  }

  private async createLegendRendererAndChangeItems(testArea: HTMLDivSelection, name: string, data: ILegendList[], orientation: RenderOrientation) {

    await this.createTestItem(testArea, 'Legend Renderer ' + name, 'legend-renderer-tests', async (svg, parent) => {

      let { settings, value, siteHooks } = this.getRenderData(data, orientation, svg);

      let legendRenderer = new LegendRenderer()
        .data(data)
        .orientation(orientation)
        .siteHooks(<any>siteHooks)
        .chartSettings(settings);

      legendRenderer.render(svg, parent);

      data[0].channels[0].legendValues[0] = 99;
      data[0].channels[0].legendValues[1] = 98;
      data[0].channels[0].name = 'a2';
      data[0].channels[0].units = 'u12';
      data[0].channels[0].isVisible = false;

      legendRenderer.render(svg, parent);

      this.subscribeToValueChanged(value, data, legendRenderer, svg, parent);
    });
  }

  private subscribeToValueChanged(value: any, data: ILegendList[], legendRenderer: LegendRenderer, svg: SVGSelection, parent: HTMLDivSelection) {
    value.text(JSON.stringify(data.map(v => v.channels.map(v => v.isVisible))));

    legendRenderer
      .on('changed', () => {
        value.text(JSON.stringify(data.map(v => v.channels.map(v => v.isVisible))));
        legendRenderer.render(svg, parent);
      });
  }

  private getRenderData(data: ILegendList[], orientation: RenderOrientation, svg: SVGSelection) {
    let sourceCount = d3.maxStrict(data.map(v => d3.maxStrict(v.channels.map(c => c.legendValues.length))));

    let settings = ChartSettings.build(sourceCount);
    let requiredHorizontalSpace = settings.legend.getRequiredHorizontalSpace(sourceCount);
    let requiredVerticalSpace = settings.legend.getRequiredVerticalSpace(sourceCount);
    settings.svgSize = new Size(600, 300);
    settings.svgPadding = new Margin(5, 5, 5, 5);
    settings.chartMargin = new Margin(10, orientation === RenderOrientation.vertical ? requiredHorizontalSpace : 0, orientation === RenderOrientation.vertical ? 0 : requiredVerticalSpace, 10);

    this.RenderChartOutlines(svg, settings.svgSize, settings.svgPadding, settings.chartMargin, settings.chartSize);

    let value = svg.append('text').attr('class', 'legend-visibility-values').attr('transform', 'translate(10, 20)');

    svg.append('text').attr('class', 'edit-units-title').attr('transform', 'translate(10, 60)').text('Edit units: ');
    let editUnitsValue = svg.append('text').attr('class', 'edit-units-value').attr('transform', 'translate(100, 60)').text('');

    let siteHooks = { editChannelUnits: (n: string, u: string) => editUnitsValue.text('' + n + '=' + u) };
    return { settings, value, siteHooks };
  }
}

import {UrlTree, DefaultUrlSerializer} from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomUrlSerializer extends DefaultUrlSerializer {
  parse(url: any): UrlTree {

    // Bit of a hack. Handle data encoded with RISON.
    if(url.indexOf(';data=') !== -1){
      url = url.replace(/\(/g, '%28').replace(/\)/g, '%29');
    }

    return super.parse(url);
  }

  serialize(tree: UrlTree): any {
    let result = super.serialize(tree);

    // Bit of a hack. Ignore data encoded with RISON.
    if(result.indexOf(';data=') !== -1){
      result = decodeURI(result);
    }

    return result;
  }
}

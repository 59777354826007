
/**
 * If a chart has more columns than rows, it is horizontal. If it has more rows than columns, it is vertical.
 */
export enum PlotSetDirection {
  /**
   * The chart has more columns than rows.
   */
  horizontal,

  /**
   * The chart has more rows than columns.
   */
  vertical
}

import { ProcessedExplorationMapValue } from './exploration-map-values';


/**
 * Represents a single sweep of an exploration job.
 */
export class ExplorationJobSweep {
  /**
   * Represents a single sweep of an exploration job.
   * @param subSweepValues Each job has a single value for each sub sweep, as it represents one point in the hypercube.
   * @param subSweepNumericValues The sub-sweep values may be enumerations, so this is the numeric representation (e.g. index).
   * @param normalizedSubSweepValues The normalized versions of the sub-sweep values, so the values normalized to the range [0, 1].
   * @param subSweepIndex The index into the sub-sweep of this job.
   * @param normalizedSubSweepIndex The normalized index into the sub-sweep of this job.
   */
  constructor(
    public readonly subSweepValues: ReadonlyArray<ProcessedExplorationMapValue>,
    public readonly subSweepNumericValues: ReadonlyArray<number>,
    public readonly normalizedSubSweepValues: ReadonlyArray<number>,
    public readonly subSweepIndex: number,
    public readonly normalizedSubSweepIndex: number) {
  }
}

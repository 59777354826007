import {Injectable} from '@angular/core';
import {ScalarResultSet, StudyUtilities} from '../../studies/study-utilities.service';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {LazyLoaded} from './lazy-loaded';
import {GetStudyJobQueryResult} from '../../../../generated/api-stubs';

@Injectable()
export class LazyLoadedStudyJobScalarResultSetsFactory {
  constructor(
    private readonly studyUtilities: StudyUtilities,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  public create(jobResult: LazyLoaded<GetStudyJobQueryResult>, tenantId: string, studyId: string, jobId: string): LazyLoaded<ScalarResultSet[]>{
    return new LazyLoaded<ScalarResultSet[]>(
      async () => {
        await jobResult.load();

        return await this.studyUtilities.downloadScalarResultSets(
          jobResult.value.simTypes,
          tenantId,
          studyId,
          jobId,
          jobResult.value.accessInformation.url,
          jobResult.value.accessInformation);
      },
      this.getFriendlyErrorAndLog);
  }
}

import {Injectable} from '@angular/core';
import {DialogManager, IDialog, DialogBase} from './dialog-manager.service';
import {LoadingDialogError} from './loading-dialog-session';

@Injectable()
export class LoadingDialog {
  constructor(
    private dialogManager: DialogManager
  ){}

  public async show<TResult>(
    action: LoadingAction<TResult>): Promise<TResult> {

    const result = await this.dialogManager.showTertiaryDialog(
      new LoadingDialogData<TResult>(action));

    if(result instanceof LoadingDialogError){
      throw result.error;
    }

    return result;
  }

  public showUntilFinished<T>(task: Promise<T>, message: string){
    return this.show<T>(cb => {
      cb.setStatus(message);
      return task;
    });
  }
}

export class LoadingDialogData<TResult> extends DialogBase<TResult> implements IDialog {
  constructor(
    private _action: LoadingAction<TResult>) {
    super();
  }

  public get action(): LoadingAction<TResult> {
 return this._action; 
}

  public dismiss() {
    // Do nothing.
  }
}

export type LoadingAction<T> = (dialogCallbacks: DialogCallbacks) => Promise<T>;

export interface DialogCallbacks {
  setStatus(status: string): void;
}

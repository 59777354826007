import {Injectable} from '@angular/core';
import {JobViewModelSetFactory} from './job-view-model-set';
import {GetStudyQueryResult, StudyStub} from '../../../../generated/api-stubs';
import {ResultSource} from '../../results-staging-area/results-staging-area.service';
import {JobViewModel} from './job-view-model';
import {JobSource} from './job-source';
import {getJobIdFromJobIndex} from '../../../common/get-job-id-from-job-index';

@Injectable()
export class GetJobsFromResultSources {

  constructor(private studyStub: StudyStub,
              private jobViewModelSetFactory: JobViewModelSetFactory) {
  }

  public async execute(sources: ReadonlyArray<ResultSource>): Promise<ReadonlyArray<JobViewModel>> {

    let resolved: JobSource[] = [];
    let loadedStudyMetadata: { [studyId: string]: GetStudyQueryResult } = {};
    for (let source of (sources || [])) {
      if (source.jobId) {
        resolved.push(source);
      } else {
        let studyMetadataResult = loadedStudyMetadata[source.studyId];
        if (!studyMetadataResult) {
          studyMetadataResult = await this.studyStub.getStudyMetadata(source.tenantId, source.studyId);
          loadedStudyMetadata[source.studyId] = studyMetadataResult;
        }

        for (let i = 0; i < studyMetadataResult.study.data.jobCount; ++i) {
          resolved.push(
            new JobSource(
              source.tenantId,
              source.studyId,
              getJobIdFromJobIndex(source.studyId, i),
              source.studyName || studyMetadataResult.study.name));
        }
      }
    }

    // Some of the JobViewModel instances may share a parent study,
    // and therefore share a LazyLoaded<GetStudyQueryResult>.
    // We iterate through each one and populate it with any study metadata have.
    let viewModelSet = this.jobViewModelSetFactory.create(resolved);
    for (let key of Object.keys(loadedStudyMetadata)) {
      let lazyStudyMetadata = viewModelSet.studyMetadataMap[key];
      if (lazyStudyMetadata) {
        lazyStudyMetadata.studyMetadata.value = loadedStudyMetadata[key];
      }
    }

    return viewModelSet.jobs;
  }
}

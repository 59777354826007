@if (isVisible) {
  <div class="modal dialog-container" id="list-versions-dialog">
    <div class="modal-dialog modal-dialog-wide" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title">
            Recent Versions
          </h1>
          <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()">
          </button>
        </div>
        <div class="modal-body">
          <cs-error-message [message]="errorMessage || session.errorMessage"></cs-error-message>
          @if (!session?.versions) {
            <cs-loader></cs-loader>
          }
          @if (session?.versions) {
            <div class="row">
              <div class="col-12">
                @if (!session.versions.length) {
                  <div class="alert alert-info">
                    No versions available.
                  </div>
                }
                @if (session.versions.length) {
                  <div>
                    <div class="alert alert-info compare-target-info">
                      <div>
                        Compare Target:
                        @if (!session.compareTarget) {
                          <strong>Editor</strong>
                        }
                        @if (session.compareTarget) {
                          <strong>{{session.compareTarget.timestamp | toDateTime}}</strong>
                        }
                        <button class="btn btn-secondary reset-compare-target" (click)="session.setCompareTarget(undefined)">
                          Reset
                        </button>
                      </div>
                    </div>
                    <table class="table" >
                      <thead>
                        <tr>
                          <th>
                            Timestamp
                          </th>
                          <th>
                          </th>
                          <th>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        @for (item of session.versions; track item; let i = $index) {
                          <tr [ngClass]="{'selected-timestamp': session.compareTarget === item}">
                            <td title="{{item.username}} / {{item.tenantName}}">
                              {{item.timestamp | fromNow:3}} / {{item.username}}
                              <small class="absolute-timestamp">{{item.timestamp | toDateTime}}</small>
                            </td>
                            <td>
                              @if (session.compareTarget === item) {
                                <div>
                                  <strong>Compare Target</strong>
                                </div>
                              }
                              @if (session.compareTarget !== item) {
                                <div>
                                  <button class="btn btn-secondary" (click)="session.setCompareTarget(item)">
                                    Set as Compare Target
                                  </button>
                                  <button class="btn btn-secondary" (click)="session.compareToCurrent(item)">
                                    <strong>Compare</strong>
                                  </button>
                                </div>
                              }
                            </td>
                            <td>
                              <button class="btn btn-primary load-version-button load-version-button-{{i}}" (click)="session.loadVersion(item)">
                                Load
                              </button>
                            </td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                }
              </div>
            </div>
          }
        </div>
        <div class="modal-footer">
          <button id="dialog-dismiss-button" type="button" class="btn btn-secondary" (click)="dismiss()">Close</button>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div>
  }<!-- /.modal -->


<div class="view-edit-custom-properties-component">
  @if (!isEditing) {
    <div>
      @if (initialProperties.length) {
        <cs-view-custom-properties class="view-custom-properties-container" [customProperties]="initialProperties" [showDivider]="false"></cs-view-custom-properties>
      }
      <p class="button-container">
        <button class="btn btn-secondary" id="edit-custom-properties-button" (click)="edit()">Edit</button>
      </p>
    </div>
  }
  @if (isEditing) {
    <div>
      <cs-edit-custom-properties [tenantId]="tenantId"
        [target]="target"
        [form]="form"
        [initialProperties]="initialProperties"
      [resultProperties]="resultProperties"></cs-edit-custom-properties>
    </div>
  }
</div>


export const PIXEL_DATA_ID = 'pixel-data';
export const PIXEL_DATA_ATTRIBUTE = 'pixel-data';

export const CURRENT_PIXEL_RATIO_ID = 'current-pixel-ratio';
export const PRIMARY_JOB_INDEX_ID = 'primary-job-index';

export const TEST_AREA_TITLE_CLASS = 'test-area-title';
export const TEST_AREA_END_ID = 'test-area-end';

export const CANVAS_SNAPSHOT_BUTTON_ID = 'canvas-snapshot';
export const NAVIGATION_STATION_ID_ID = 'navigation-station-id';

import * as d3octree from 'd3-octree';
import * as d3 from '../../d3-bundle';
import { DomainNewsEvent, SharedState } from '../shared-state';
import {
  Box3,
  BufferAttribute,
  BufferGeometry,
  Color,
  ConeGeometry,
  DoubleSide,
  Float32BufferAttribute,
  Group,
  Line,
  Line3,
  LineBasicMaterial,
  LineBasicMaterialParameters,
  LineDashedMaterial,
  Mesh,
  MeshLambertMaterial,
  MeshLambertMaterialParameters,
  MeshPhongMaterial,
  Object3D,
  Plane,
  Raycaster,
  SphereGeometry,
  Uint16BufferAttribute,
  Vector2,
  Vector3
} from 'three';
import SpriteText from 'three-spritetext';

import { ConfigRendererBase, RenderedConfig } from '../3d/config-renderer-base';
import { System } from '../3d/system';
import { ALTERNATE_SOURCE_FOR_DEBUGGING, ExtractTrackData, TrackSource } from './extract-track-data';
import { TrackCoordinate } from '../3d/track-coordinate';
import { TrackPath } from './track-path';
import { TrackData } from './track-data';
import { SearchDirection, Utilities } from '../../utilities';

import { SourceData } from '../data-pipeline/types/source-data';
import { IPopulatedMultiPlotLayout } from '../data-pipeline/types/i-populated-multi-plot-layout';
import { BLUE, GREEN, SLAPCENTRELINE_DOMAIN_NAME } from '../../constants';
import { GetInterpolatedChannelValueAtDomainValue } from '../channel-data-loaders/get-interpolated-channel-value-at-domain-value';
import { Units } from '../../units';
import { modulo } from '../../modulo';
import { isNumber } from '../../is-number';

const Z_VISIBILITY_ADJUSTMENT = 0.01;

/**
 * A domain event that may or may or may not have been processed.
 */
class ProcessableDomainEvent {

  /**
   * Creates a new instance of ProcessableDomainEvent.
   * @param event The event.
   */
  constructor(
    public readonly event?: DomainNewsEvent) {
  }

  /**
   * Whether the event has been processed.
   */
  public processed: boolean = false;
}

/**
 * The 3D track renderer.
 */
export class TrackRenderer extends ConfigRendererBase implements System {

  /**
   * Gets the interpolated channel value at a specified domain value.
   */
  private getInterpolatedChannelValueAtDomainValue: GetInterpolatedChannelValueAtDomainValue;

  /**
   * The last sLap event.
   */
  private sLapEvent: ProcessableDomainEvent = new ProcessableDomainEvent(undefined);

  /**
   * The track data by source index.
   */
  private trackDataByIndex: { [sourceIndex: number]: TrackRenderData } = {};

  /**
   * The ground plane.
   */
  private groundPlane = new Plane(new Vector3(0, 1, 0), 0);

  /**
   * The set of source data.
   */
  private sourceData?: ReadonlyArray<SourceData>;

  /**
   * The layout.
   */
  private layout?: IPopulatedMultiPlotLayout;

  /**
   * Creates a new instance of TrackRenderer.
   * @param sharedState The shared state.
   * @param extractTrackData The track data extractor.
   */
  constructor(
    sharedState: SharedState,
    private readonly extractTrackData: ExtractTrackData) {
    super(sharedState);

    this.getInterpolatedChannelValueAtDomainValue = new GetInterpolatedChannelValueAtDomainValue();
  }

  /**
   * Builds the track renderer.
   */
  public performBuild() {
    this.subscriptions.add(this.sharedState.sLapCursorNews.subscribe(v => {
      this.sLapEvent = new ProcessableDomainEvent(v);
    }));
  }

  /**
   * Sets the layout.
   * @param layout The layout.
   * @returns This instance of TrackRenderer.
   */
  public setLayout(layout: IPopulatedMultiPlotLayout): this {
    this.layout = layout;
    return this;
  }

  /**
   * Sets the channel data.
   * @param sourceData The source data.
   */
  public setChannelData(sourceData: ReadonlyArray<SourceData>) {
    this.sourceData = sourceData;
    this.updateAll = true;
  }

  /**
   * Gets the set of source values from a given domain news event.
   * @param event The event.
   * @returns The set of source values.
   */
  private getEventValues(event: DomainNewsEvent | undefined) {
    if (event) {
      return [...event.sourceValues];
    } else {
      return new Array(this.configs.length).fill(0);
    }
  }

  /**
   * Updates the track renderer.
   * @param mouseRaycaster The mouse raycaster.
   */
  public performUpdate(mouseRaycaster: Raycaster | undefined) {
    if (mouseRaycaster) {
      // The broadcast flag indicates whether we want to broadcast the cursor sLap values to the shared state.
      let broadcast = false;

      // Get the previous sLap event values.
      let sLapValues: number[] = this.getEventValues(this.sLapEvent.event);
      let sLapCentreLineValues: number[] = this.getEventValues(
        this.sLapEvent.event && this.sLapEvent.event.secondary
          ? this.sLapEvent.event.secondary.event
          : undefined);

      // Intersect the current mouse position with the ground plane.
      let zeroPlaneIntersection = new Vector3();
      mouseRaycaster.ray.intersectPlane(this.groundPlane, zeroPlaneIntersection);

      // For each data source...
      for (let sourceIndex = 0; sourceIndex < this.configs.length; ++sourceIndex) {

        // Get the track config for this data source.
        let config = this.configs[sourceIndex];
        if (!config) {
          continue;
        }

        // Get the track data for this data source.
        let trackData = this.trackDataByIndex[sourceIndex];
        if (!trackData) {
          continue;
        }

        // Get the rendered track config (the tree.js group) for this data source.
        let renderedConfig = this.renderedConfigs[config.id];
        if (!renderedConfig) {
          // If we haven't rendered the track, continue.
          continue;
        }

        // Get the intersection of the mouse position with the track.
        let point: Vector3;
        let intersects = mouseRaycaster.intersectObjects(trackData.intersectGroup.children, true);
        if (intersects.length) {
          // If the mouse is over the track, use the first track intersection point.
          let intersection = intersects[0];
          point = intersection.point;
        } else {
          // Otherwise use the intersection with the ground plane.
          point = zeroPlaneIntersection;
        }

        if (!point) {
          // No intersections, so just continue.
          continue;
        }

        // Find the nearest point on the track to the intersection point.
        let minimumIndex = trackData.findNearest(point);
        if (minimumIndex !== -1) {
          // We always want to send sLap to support QSL which doesn't have an sRunCentreLine channel.
          sLapValues[sourceIndex] = trackData.trackData.sLap[minimumIndex];
          if (trackData.trackData.sLapCentreLine.length) {
            sLapCentreLineValues[sourceIndex] = trackData.trackData.sLapCentreLine[minimumIndex];
          } else {
            sLapCentreLineValues[sourceIndex] = NaN;
          }

          // We have a new sLap value, so we want to broadcast.
          broadcast = true;
        }
      }

      // If we have a new sLap value, broadcast it to the shared state.
      if (broadcast) {
        this.sharedState.sLapCursorSetAll(sLapValues, sLapCentreLineValues);
      }
    }

    if (this.configs) {
      // If the last sLap event hasn't been processed...
      if (!this.sLapEvent.processed) {
        // We will process it now.
        this.sLapEvent.processed = true;

        // For each data source...
        for (let sourceIndex = 0; sourceIndex < this.configs.length; ++sourceIndex) {

          // Get the track config.
          let config = this.configs[sourceIndex];
          if (config && config.data) {

            // Get the track render data.
            let trackRenderData = this.trackDataByIndex[sourceIndex];
            if (trackRenderData) {

              // Get the sLap data for this data source's track config.
              let sLapData = trackRenderData.trackData.sLap;
              let event = this.sLapEvent.event;

              // Get the event's sLap value for this data source.
              let sLapValue = event ? event.getSourceValue(sourceIndex) : 0;

              // If the track has sLapCenterLine data, and the event contains sLapCenterLine data for this data source,
              // then use it instead of sLap.
              if (trackRenderData.trackData.sLapCentreLine.length
                && event && event.secondary && event.secondary.event
                && event.secondary.domainName === SLAPCENTRELINE_DOMAIN_NAME) {

                let newValue = event.secondary.event.getSourceValue(sourceIndex);
                if (!isNaN(newValue)) {
                  sLapValue = newValue;
                  sLapData = trackRenderData.trackData.sLapCentreLine;
                  event = event.secondary.event;
                }
              }

              let sLapMax = sLapData[sLapData.length - 1];

              // Wrap around.
              sLapValue = modulo(sLapValue, sLapMax);

              // Get the index of the sLap value in the sLap data.
              let dataIndex = Utilities.findIndexInMonotonicallyIncreasingData(sLapData, sLapValue, SearchDirection.Forwards);
              if (dataIndex < 0 || dataIndex >= sLapData.length) {
                dataIndex = 0;
              }

              // Get the coordinate and normal for the sLap value.
              let coordinate = trackRenderData.trackData.carPath.getWorldCoordinate(dataIndex);
              let normal = trackRenderData.trackData.carNormals.getWorldCoordinate(dataIndex);
              let cursor = trackRenderData.cursor;
              let axis = new Vector3(0, 1, 0);

              // Update the car cursor position and orientation.
              cursor.position.copy(coordinate);
              cursor.quaternion.setFromUnitVectors(axis, normal);
            }
          }
        }
      }
    }
  }

  // private getClosestIndex(point: Vector3, coordinates: ReadonlyArray<TrackCoordinate>) {
  //   let minimumDistance = -1;
  //   let minimumIndex = -1;
  //   let reference = new Vector3(); // We don't want to create a new vector for each coordinate when doing this.
  //   let index = 0;
  //   for(let coordinate of coordinates){
  //     reference.set(coordinate.worldX, coordinate.worldY, coordinate.worldZ);
  //     let distance = reference.distanceTo(point);
  //     if(minimumIndex === -1 || distance < minimumDistance){
  //       minimumIndex = index;
  //       minimumDistance = distance;
  //     }
  //
  //     ++index;
  //   }
  //
  //   return minimumIndex;
  // }

  /**
   * Returns the coordinate for a given distance around the track.
   * @param referenceDistance The reference distance to get the coordinate for.
   * @param distanceVector The distance vector.
   * @param coordinates The track coordinates.
   * @returns The coordinate for the given distance.
   */
  private getCoordinateForDistance(referenceDistance: number, distanceVector: ReadonlyArray<number>, coordinates: TrackPath): Vector3 {

    if (!coordinates.trackCoordinates.length) {
      return new Vector3(0, 0, 0);
    }

    if (!distanceVector.length) {
      return coordinates.getWorldCoordinate(0);
    }

    // Wrap around.
    referenceDistance = modulo(referenceDistance, distanceVector[distanceVector.length - 1]);

    // Loop through the distance vector...
    for (let i = 0; i < distanceVector.length; ++i) {
      const distance = distanceVector[i];
      if (distance === referenceDistance) {
        return coordinates.getWorldCoordinate(i);
      }

      // If we've gone past the reference distance...
      if (distance > referenceDistance) {
        if (i === 0) {
          return coordinates.getWorldCoordinate(0);
        }

        // Interpolate between the current and previous coordinate.
        const beforeDistance = distanceVector[i - 1];
        const beforeCoordinate = coordinates.getWorldCoordinate(i - 1);
        const afterDistance = distanceVector[i];
        const afterCoordinate = coordinates.getWorldCoordinate(i);

        const ratio = (referenceDistance - beforeDistance) / (afterDistance - beforeDistance);

        const worldLine = new Line3(beforeCoordinate, afterCoordinate);
        const result = new Vector3();
        worldLine.at(ratio, result);
        return result;
      }
    }

    return coordinates.getWorldCoordinate(0);
  }

  /**
   * Renders the track config.
   * @param sourceIndex The data source index.
   * @param data The track config data.
   * @returns The rendered config.
   */
  protected renderConfig(sourceIndex: number, data: any): RenderedConfig {

    // Extract the track data we need for rendering.
    let trackData = ALTERNATE_SOURCE_FOR_DEBUGGING
      ? this.extractTrackData.execute(data, <TrackSource>(modulo(sourceIndex, 4)))
      : this.extractTrackData.execute(data);

    // Create a group for the track, and an inner group for only the track data important for intersection calculations.
    let group = new Group();
    let intersectGroup = new Group();
    group.add(intersectGroup);

    // Get the color for this data source.
    let colorString = this.settings.getChannelColor(0, sourceIndex);
    let color = new Color(colorString);

    // Draw the track.
    let roadColor = new Color(0xaaaaaa);
    let roadEdgeColor = new Color(0x777777);
    this.drawTrack(intersectGroup, trackData.innerEdge, trackData.outerEdge, roadColor, roadEdgeColor);
    this.drawRacingLine(sourceIndex, group, trackData.carPath, trackData.carNormals, color, trackData.sLap);
    this.drawZerothTrackOutlineVertexLine(group, trackData);
    let radius = this.drawStartFinishLine(group, trackData, roadEdgeColor.clone().offsetHSL(0, 0, -0.1));
    this.drawStartFinishOffsets(group, trackData, sourceIndex, color, radius);

    // Draw the cursor (the car) but it is not yet positioned.
    let cursorGroup = this.drawCursor(group, color.clone().offsetHSL(0, 0, -0.1), radius);

    // Update our track data by source index.
    this.trackDataByIndex[sourceIndex] = new TrackRenderData(trackData, cursorGroup, intersectGroup);
    return new RenderedConfig(sourceIndex, group);
  }

  /**
   * Draws the cursor (car) for the track, but does not position it.
   * @param group The group to add the cursor to.
   * @param color The color of the cursor.
   * @param radius The radius of the cursor.
   * @returns The group containing the cursor.
   */
  private drawCursor(group: Group, color: Color, radius: number): Group {
    let cursorGroup = new Group();
    group.add(cursorGroup);
    let geometry = new SphereGeometry(radius, 16, 16, 0, Math.PI, 0, Math.PI);
    let material = new MeshPhongMaterial({ color, side: DoubleSide, flatShading: false, specular: 0x444444 });
    let marker = new Mesh(geometry, material);
    marker.rotateX(-Math.PI / 2);
    cursorGroup.add(marker);
    return cursorGroup;
  }

  /**
   * Draws a red dashed line between the first vertex of the inner and outer track outlines.
   * @param group The group to add the line to.
   * @param trackData The track data.
   */
  private drawZerothTrackOutlineVertexLine(group: Group, trackData: TrackData) {
    if (!trackData.innerEdge.trackCoordinates.length || !trackData.outerEdge.trackCoordinates.length) {
      return;
    }

    let innerPosition = trackData.innerEdge.getWorldCoordinate(0);
    let outerPosition = trackData.outerEdge.getWorldCoordinate(0);
    let dashSize = innerPosition.distanceTo(outerPosition) / 9;

    let materialDashed = new LineDashedMaterial({ color: 0xFF0000, dashSize, gapSize: dashSize });

    let startReferenceLineGeometry = new BufferGeometry();
    startReferenceLineGeometry.setAttribute('position', new BufferAttribute(
      new Float32Array([
        innerPosition.x, innerPosition.y, innerPosition.z,
        outerPosition.x, outerPosition.y, outerPosition.z
      ]), 3));
    startReferenceLineGeometry.getAttribute('position').needsUpdate = true;
    let startReferenceLine = new Line(startReferenceLineGeometry, materialDashed);
    startReferenceLine.computeLineDistances();
    group.add(startReferenceLine);
  }

  /**
   * If the track has start finish offsets, draw labels for them on the track.
   * @param group The group to add the labels to.
   * @param trackData The track data.
   * @param sourceIndex The source index.
   * @param color The color of the labels.
   * @param carRadius The radius of the car.
   */
  private drawStartFinishOffsets(group: Group, trackData: TrackData, sourceIndex: number, color: Color, carRadius: number) {
    const startFinishOffset = trackData.startFinishOffset;
    if (startFinishOffset === 0) {
      return;
    }

    // If has centre line, draw centre line s/f offset and label.
    if (trackData.centreLine.trackCoordinates.length) {
      const coordinate = this.getCoordinateForDistance(startFinishOffset, trackData.sLapCentreLine, trackData.centreLine);
      this.drawLabel(group, coordinate, 'Start/Finish Offset (Centre Line)', sourceIndex, 0, color, carRadius);
    }

    // If has racing line, draw racing line s/f offset and label.
    if (trackData.carPath.trackCoordinates.length) {
      const coordinate = this.getCoordinateForDistance(startFinishOffset, trackData.sLap, trackData.carPath);
      this.drawLabel(group, coordinate, 'Start/Finish Offset (Racing Line)', sourceIndex, 1, color, carRadius);
    }
  }

  /**
   * Draws a label and a line from the label to a coordinate.
   * @param group The group to add the label and line to.
   * @param coordinate The coordinate to draw the line to.
   * @param label The label to draw.
   * @param sourceIndex The source index.
   * @param labelIndex The label index.
   * @param color The color of the label and line.
   * @param carRadius The radius of the car.
   */
  private drawLabel(group: Group, coordinate: Vector3, label: string, sourceIndex: number, labelIndex: number, color: Color, carRadius: number) {

    // let materialDashed = new LineDashedMaterial({color: color, dashSize: carRadius, gapSize: carRadius });
    let material = new LineBasicMaterial({ color });

    let a = coordinate.clone();
    let b = new Vector3(
      carRadius * 5,
      (carRadius * 2 * (sourceIndex + 1)) + (labelIndex * carRadius), // Different labels should be offset.
      sourceIndex * carRadius * -3).add(a);
    let c = new Vector3(
      carRadius * 5,
      0,
      0).add(b);
    let startReferenceLineGeometry = new BufferGeometry();
    startReferenceLineGeometry.setAttribute('position', new BufferAttribute(
      new Float32Array([
        a.x, a.y, a.z,
        c.x, c.y, c.z
      ]), 3));
    startReferenceLineGeometry.getAttribute('position').needsUpdate = true;
    let startReferenceLine = new Line(startReferenceLineGeometry, material);
    startReferenceLine.computeLineDistances();
    group.add(startReferenceLine);

    let sprite =  new SpriteText(label, carRadius * 0.75, '0x000000');
    sprite.fontFace = 'Arial, Helvetica, sans-serif';
    sprite.center.copy(new Vector2(0, 0.5));
    sprite.position.copy(c);
    group.add(sprite);
  }

  /**
   * Draws a cone at the start/finish line.
   * @param group The group to add the cone to.
   * @param trackData The track data.
   * @param color The color of the cone.
   * @returns The radius of the cone.
   */
  private drawStartFinishLine(group: Group, trackData: TrackData, color: Color): number {

    let carPath = trackData.carPath;
    if (!carPath.trackCoordinates.length) {
      carPath = trackData.innerEdge;
    }

    if (!carPath.trackCoordinates.length) {
      return 1;
    }

    // Get the coordinates which will be used to determine the direction of the cone.
    let firstCoordinate = carPath.getWorldCoordinate(0);
    let nextCoordinate = carPath.getWorldCoordinate(d3.minStrict([carPath.trackCoordinates.length - 1, 10]));

    let radius: number;
    let position: Vector3;
    if (trackData.innerEdge.trackCoordinates.length && trackData.outerEdge.trackCoordinates.length) {
      // If we have inner and outer edges for the track, use those to calculate the position and radius of the cone.
      let innerPosition = trackData.innerEdge.getWorldCoordinate(0);
      let outerPosition = trackData.outerEdge.getWorldCoordinate(0);
      position = new Vector3(
        (innerPosition.x + outerPosition.x) / 2,
        (innerPosition.y + outerPosition.y) / 2,
        (innerPosition.z + outerPosition.z) / 2);
      radius = innerPosition.distanceTo(outerPosition) / 2;
    } else {
      // Otherwise, just use the car path.
      position = firstCoordinate.clone();
      radius = 5;
    }

    // Ensure the radius is at least 5.
    radius = Math.max(radius, 5);

    // The height of the cone is 3 times the radius.
    let height = radius * 3;

    // Create the cone.
    let geometry = new ConeGeometry(radius, height, 16);
    let material = new MeshPhongMaterial({ color, flatShading: false, transparent: true, opacity: 0.6, specular: 0x444444 });
    let marker = new Mesh(geometry, material);

    // Get the offset required to correctly position the cone on the track so the base is on the start/finish line.
    let requiredOffset = new Box3().setFromObject(marker).min.y * -1;

    marker.position.copy(position);

    if (nextCoordinate.equals(firstCoordinate)) {
      // If the first and next coordinates are the same, update the next coordinate as the first coordinate moved slightly in the X direction.
      nextCoordinate = firstCoordinate.clone().setX(firstCoordinate.x - 1);
    }

    // Turn the cone to point int he correct direction.
    let direction = nextCoordinate.sub(firstCoordinate).normalize();
    let axis = new Vector3(0, 1, 0);
    marker.quaternion.setFromUnitVectors(axis, direction);

    // Offset the cone so the base is on the start/finish line.
    marker.position.add(direction.clone().multiplyScalar(requiredOffset));

    group.add(marker);

    return radius;
  }

  /**
   * Draw the racing line around the track as a ribbon.
   * @param sourceIndex The data source index.
   * @param group The group to add the racing line to.
   * @param path The path of the racing line.
   * @param normals The normals of the racing line.
   * @param color The color of the racing line.
   * @param sLap The sLap values.
   */
  private drawRacingLine(sourceIndex: number, group: Group, path: TrackPath, normals: TrackPath, color: Color, sLap: ReadonlyArray<number>) {
    if (!path.trackCoordinates.length) {
      return;
    }

    let racingLineGroup = new Group();
    group.add(racingLineGroup);

    // Lift the racing line off the track slightly, to help with z-fighting.
    racingLineGroup.position.setY(Z_VISIBILITY_ADJUSTMENT);

    const ribbonSize = 3;

    // If the ribbon colors and heights are to be set based on channel values, set up the functions to do so.
    let selectColor: ((index: number) => Readonly<[number, number, number]>) | undefined;
    let selectHeight: (index: number) => number = () => ribbonSize;
    if (this.sourceData && this.layout) {
      let sourceDataItem = this.sourceData[sourceIndex];
      if (sourceDataItem) {
        let sLapData = sourceDataItem.channels.get(this.layout.processed.primaryDomain.name);
        if (sLapData) {
          let colorChannel = this.layout.processed.colorChannel;
          if (colorChannel) {
            let colorData = colorChannel.sources[sourceIndex];
            if (colorData) {
              let colorScale = d3.scaleLinear<string>()
                .domain([colorChannel.minimum, colorChannel.maximum])
                .range([BLUE, GREEN])
                .interpolate(d3.interpolateHcl);

              selectColor = index => {
                let sLapValue = sLap[index];
                sLapValue = Units.convertValueFromSi(sLapValue, sLapData.units);
                let value = this.getInterpolatedChannelValueAtDomainValue.execute(colorData.data, sLapValue, sLapData.data, sLapData.monotonicStatus);
                let c = new Color(colorScale(value));
                return [c.r, c.g, c.b];
              };
            }
          }

          let heightChannel = this.layout.processed.sizeChannel;
          if (heightChannel) {
            let heightData = heightChannel.sources[sourceIndex];
            if (heightData) {
              let minimumHeight = heightChannel.minimum;
              let maximumHeight = heightChannel.maximum;
              selectHeight = index => {
                let sLapValue = sLap[index];
                sLapValue = Units.convertValueFromSi(sLapValue, sLapData.units);
                let value = this.getInterpolatedChannelValueAtDomainValue.execute(heightData.data, sLapValue, sLapData.data, sLapData.monotonicStatus);
                return ribbonSize + 6 * ribbonSize * ((value - minimumHeight) / (maximumHeight - minimumHeight));
              };
            }
          }
        }
      }
    }

    // Create the top path based on the path and normals, and the delegate to get the track height
    // from channel data, so the ribbon leans with the track banking.
    let topPath = new TrackPath(path.trackCoordinates.map(
      (v, i) => {
        let height = selectHeight(i);
        return new TrackCoordinate(
          v.x + height * normals.trackCoordinates[i].x,
          v.y + height * normals.trackCoordinates[i].y,
          v.z + height * normals.trackCoordinates[i].z / path.elevationScale);
      }));

    // Scale the top path appropriately.
    topPath.adjustWorldElevationCoordinates(path.elevationScale, path.elevationOffset);

    // Draw the ribbon.
    this.drawStrip(racingLineGroup, path, topPath, color, new Color(color).offsetHSL(0, 0, -0.1), false, selectColor, true);
  }

  /**
   * Draws the track as two strips (one for the track surface, one for the distance to the ground).
   * @param group The group to add the strips to.
   * @param inner The inner track path.
   * @param outer The outer track path.
   * @param roadColor The color of the road.
   * @param edgeColor The color of the edge.
   */
  private drawTrack(group: Group, inner: TrackPath, outer: TrackPath, roadColor: Color, edgeColor: Color) {
    this.drawStrip(group, inner, outer, roadColor, edgeColor);

    if (inner.trackCoordinates.length === outer.trackCoordinates.length) {
      let centerLine = new TrackPath(inner.trackCoordinates.map((v, i) => new TrackCoordinate(
        (v.x + outer.trackCoordinates[i].x) / 2,
        (v.y + outer.trackCoordinates[i].y) / 2,
        ((v.z + outer.trackCoordinates[i].z) / 2) - 10 * Z_VISIBILITY_ADJUSTMENT)));
      centerLine.adjustWorldElevationCoordinates(inner.elevationScale, inner.elevationOffset);

      let centerLineAtZero = new TrackPath(centerLine.trackCoordinates.map(v => new TrackCoordinate(v.x, v.y, 0 - 10 * Z_VISIBILITY_ADJUSTMENT)));

      this.drawStrip(group, centerLine, centerLineAtZero, new Color(roadColor).offsetHSL(0, 0, 0.15), roadColor, true);
    }
  }

  /**
   * Draws a line along the given path.
   * @param group The group to add the line to.
   * @param path The path to draw the line along.
   * @param color The color of the perimeter line.
   */
  private drawPerimeterLine(group: Group, path: TrackPath, color: Color) {
    if (!path.trackCoordinates.length) {
      return;
    }

    let geometry = new BufferGeometry();
    let vertices = path.vertices;
    geometry.setAttribute('position', new BufferAttribute(vertices, 3));
    let materialParameters: LineBasicMaterialParameters = {
      color,
    };
    let material = new LineBasicMaterial(materialParameters);
    let line = new Line(geometry, material);
    group.add(line);
  }

  /**
   * Draws a strip (a ribbon) along the given inner and outer paths.
   * @param group The group to add the strip to.
   * @param inner The inner track path.
   * @param outer The outer track path.
   * @param stripColor The color of the strip.
   * @param edgeColor The color of the edge.
   * @param noInnerLine Whether to skip drawing a line along the inner path.
   * @param getColor A function to get the color of the strip at a given index.
   * @param isTransparent Whether the strip has transparency.
   */
  private drawStrip(
    group: Group,
    inner: TrackPath,
    outer: TrackPath,
    stripColor: Color,
    edgeColor: Color,
    noInnerLine: boolean = false,
    getColor: ((index: number) => Readonly<[number, number, number]>) | undefined = undefined,
    isTransparent: boolean = false) {

    // Only render the ribbon if the inner and outer coordinates are the same length and there are coordinates to render.
    if (inner.trackCoordinates.length === outer.trackCoordinates.length
      && !!inner.trackCoordinates.length) {

      let sideVertexCount = inner.trackCoordinates.length;
      let sideCoordinateCount = sideVertexCount * 3;
      let totalVertexCount = sideVertexCount * 2;
      let totalCoordinateCount = totalVertexCount * 3;

      // Three.js has a maximum number of vertices we can render in one go.
      // If we're going to render more than that, we need to split the strip into multiple strips
      // and render them separately.
      const maxVertices = 65536;
      const maxVerticesPerSide = maxVertices / 2;
      if (sideVertexCount > maxVerticesPerSide) {
        let remainingSideVertices = inner.trackCoordinates.length;
        let startIndex = 0;
        while (remainingSideVertices > 0) {
          let endIndexExclusive = startIndex + maxVerticesPerSide;
          if (endIndexExclusive > inner.trackCoordinates.length) {
            endIndexExclusive = inner.trackCoordinates.length;
          }
          let subInner = new TrackPath(inner.trackCoordinates.slice(startIndex, startIndex + maxVerticesPerSide));
          let subOuter = new TrackPath(outer.trackCoordinates.slice(startIndex, startIndex + maxVerticesPerSide));

          this.drawStrip(group, subInner, subOuter, stripColor, edgeColor, noInnerLine, getColor, isTransparent);
          remainingSideVertices -= maxVerticesPerSide;
          startIndex = endIndexExclusive;
        }

        // Exit, as we've now rendered the strip in multiple parts.
        return;
      }

      // If we've got here we can render the strip in one go.
      // Put all the vertices in one big list of floats, where each float is the x, y and z coordinates
      // of each vertex sequentially.
      let allVertices = new Float32Array(totalCoordinateCount);
      allVertices.set(inner.vertices, 0);
      allVertices.set(outer.vertices, sideCoordinateCount);

      // If the strip color varies along the length, we need an array containing the colors for each vertex.
      let vertexColors: Float32Array | undefined;
      if (getColor) {
        // Each vertex is three floats (x, y, z), and each color is three floats (r, g, b), so the arrays should
        // be the same length.
        vertexColors = new Float32Array(totalCoordinateCount);
        const defaultColor = [stripColor.r, stripColor.g, stripColor.b] as Readonly<[number, number, number]>;
        for (let colorIndex = 0, innerIndex = 0, outerIndex = sideCoordinateCount; colorIndex < sideVertexCount; ++colorIndex, innerIndex += 3, outerIndex += 3) {
          // Set each vertex color based on the supplied delegate.
          let color = getColor(colorIndex) || defaultColor;
          vertexColors[innerIndex] = color[0];
          vertexColors[innerIndex + 1] = color[1];
          vertexColors[innerIndex + 2] = color[2];
          vertexColors[outerIndex] = color[0];
          vertexColors[outerIndex + 1] = color[1];
          vertexColors[outerIndex + 2] = color[2];
        }
      }

      // Next we will create the list of indexes into the vertex list for each face.
      // The face count is always two less than the vertex count. Three vertices make up a face.
      // Four vertices make up two faces, etc.
      let faceCount = totalVertexCount - 2;

      // Each face contains three vertices, so each face requires three indexes sequentially
      // laid out in the list.
      let indexCount = faceCount * 3;
      let indexes = new Uint16Array(indexCount);

      // For each vertex in a side...
      for (let sideIndex = 0, targetIndex = 0; sideIndex < sideVertexCount; ++sideIndex) {
        // If we're more than one vertex into the list...
        if (sideIndex !== 0) {
          // Add three indexes to make a face. We'll use two vertices from the first side of the strip,
          // and one vertex from the second side.
          // .__.  .  .  .
          // | /
          // |/
          // .  .  .  .  .
          indexes[targetIndex++] = sideIndex;
          indexes[targetIndex++] = sideIndex + sideVertexCount;
          indexes[targetIndex++] = sideIndex - 1;
        }

        // If we're not at the end of the list...
        if (sideIndex !== sideVertexCount - 1) {
          // Add three indexes to make the opposing face. We'll use one vertex from the first side of the strip,
          // and two vertices from the second side.
          // .  .  .  .  .
          //   /|
          //  / |
          // .__.  .  .  .
          indexes[targetIndex++] = sideIndex;
          indexes[targetIndex++] = sideIndex + sideVertexCount + 1;
          indexes[targetIndex++] = sideIndex + sideVertexCount;
        }
      }

      // Create the geometry for the strip.
      let geometry = new BufferGeometry();
      geometry.setAttribute('position', new Float32BufferAttribute(allVertices, 3));
      geometry.setIndex(new Uint16BufferAttribute(indexes, 1));

      if (vertexColors) {
        geometry.setAttribute('color', new Float32BufferAttribute(vertexColors, 3));
      }

      geometry.computeVertexNormals();

      // Set up the material.
      let materialParameters: MeshLambertMaterialParameters = {
        side: DoubleSide,
        wireframe: false,
      };

      if (isTransparent) {
        materialParameters.transparent = true;
        materialParameters.opacity = 0.6;
      }

      if (getColor) {
        materialParameters.vertexColors = true;
      } else {
        materialParameters.color = stripColor;
      }

      let material = new MeshLambertMaterial(materialParameters);

      // Create the mesh.
      let mesh = new Mesh(geometry, material);

      // Add the mesh to the group.
      group.add(mesh);
    }

    // If we have edge color, draw the perimeter line.
    if (edgeColor) {
      let perimeterLineGroup = new Group();
      group.add(perimeterLineGroup);

      // Lift the line off the track slightly to help with z-fighting.
      perimeterLineGroup.position.setY(Z_VISIBILITY_ADJUSTMENT);

      if (!noInnerLine) {
        // Draw the inner line.
        this.drawPerimeterLine(perimeterLineGroup, inner, edgeColor);
      }

      // Draw the outer line.
      this.drawPerimeterLine(perimeterLineGroup, outer, edgeColor);
    }
  }
}

/**
 * Represents the data for rendering a track.
 */
class TrackRenderData {

  private _worldTrackCoordinatesOctree: any;

  /**
   * Initializes a new instance of the TrackRenderData class.
   * @param trackData The track data.
   * @param cursor The cursor object.
   * @param intersectGroup The intersect group for intersecting with the mouse raycast.
   */
  constructor(
    public readonly trackData: TrackData,
    public readonly cursor: Object3D,
    public readonly intersectGroup: Group) {

    // Create an octree for fast searching of the nearest track coordinate.
    this._worldTrackCoordinatesOctree = d3octree.octree()
      .x((v: QuadTreeItem) => v.coordinate.worldX)
      .y((v: QuadTreeItem) => v.coordinate.worldY)
      .z((v: QuadTreeItem) => v.coordinate.worldZ)
      .addAll(trackData.carPath.trackCoordinates
        .map((v, i) => ({
          coordinate: v,
          index: i
        }))
        .filter(v => isNumber(v.coordinate.x) && isNumber(v.coordinate.y) && isNumber(v.coordinate.z)));
  }

  /**
   * Finds the nearest track coordinate to a given point.
   * @param point The point.
   * @returns The index of the nearest track coordinate.
   */
  public findNearest(point: Vector3) {
    let result = this._worldTrackCoordinatesOctree.find(point.x, point.y, point.z);
    if (result) {
      return result.index;
    }

    return -1;
  }
}

/**
 * A quad tree item.
 */
interface QuadTreeItem {

  /**
   * The coordinate.
   */
  coordinate: TrackCoordinate;

  /**
   * The index into the track data.
   */
  index: number;
}

import { IViewerChannelData, SourceViewerChannelData, ViewerChannelData } from './viewer-channel-data';
import { ChannelNameStyle } from './channel-name-style';
import { CHANNEL_NOT_PRESENT_DESCRIPTION } from '../../constants';
import { SiteHooks } from '../../site-hooks';
import { Units } from '../../units';
import { ChannelBinaryFormat } from '../../url-file-loader';

/**
 * The factory for creating viewer channel data.
 * This is the standard data format a viewer expects to receive its data in.
 */
export class ViewerChannelDataFactory {

  /**
   * Creates a new viewer channel data factory.
   * @param siteHooks The site hooks.
   */
  constructor(
    private siteHooks: SiteHooks) {
  }

  /**
   * Creates a new viewer channel data based on an existing channel using data which has been adjusted by a modifier (e.g. a delta channel).
   * @param originalData The original channel data.
   * @param nameModifier A function which modifies the name of the channel.
   * @param newValues The new values for the channel.
   * @returns The new channel data.
   */
  public createChannelDataWithModifiers(originalData: IViewerChannelData, nameModifier: (name: string) => string, newValues: number[] | undefined): IViewerChannelData {
    let loadedData = new LoadedChannelMetadata(
      nameModifier(originalData.genericName),
      nameModifier(originalData.fullName),
      originalData.description,
      originalData.units,
      originalData.binaryFormat);

    let channelNameStyle = originalData.name === originalData.fullName ? ChannelNameStyle.FullyQualified : ChannelNameStyle.Generic;
    let requestedName = nameModifier(originalData.requestedName);

    let metadata = this.createChannelMetadata(requestedName, loadedData, channelNameStyle);
    return this.createChannelDataFromMetadata(metadata, newValues, loadedData.units);
  }

  /**
   * Creates a new viewer channel data object, which has unspecified source data.
   * @param requestedName The name of the channel requested by the user.
   * @param loadedData The loaded data for the channel.
   * @param resultChannelNameStyle The style of the channel name to use.
   * @returns The new channel data.
   */
  public createChannelData(requestedName: string, loadedData: ILoadedChannelData | undefined, resultChannelNameStyle: ChannelNameStyle): ViewerChannelData {
    return this.createSourceChannelData<void>(undefined, requestedName, loadedData, resultChannelNameStyle);
  }

  /**
   * Creates a new viewer channel data object, which has specified source data of type `TSourceData`.
   * @param sourceData The source data for the channel.
   * @param requestedName The name of the channel requested by the user.
   * @param loadedData The loaded data for the channel.
   * @param resultChannelNameStyle The style of the channel name to use.
   * @returns The new channel data.
   */
  public createSourceChannelData<TSourceData>(sourceData: TSourceData, requestedName: string, loadedData: ILoadedChannelData | undefined, resultChannelNameStyle: ChannelNameStyle): SourceViewerChannelData<TSourceData> {
    let metadata = this.createSourceChannelMetadata(sourceData, requestedName, loadedData, resultChannelNameStyle);
    if (!loadedData) {
      return metadata;
    }

    return this.createSourceChannelDataFromMetadata(metadata, loadedData.values, loadedData.units, loadedData.valueLabels);
  }

  /**
   * Creates a new viewer channel metadata with unspecified source data.
   * @param requestedName The name of the channel requested by the user.
   * @param loadedData The loaded data for the channel.
   * @param resultChannelNameStyle The style of the channel name to use.
   * @returns The new channel metadata.
   */
  public createChannelMetadata(requestedName: string, loadedData: ILoadedChannelMetadata | undefined, resultChannelNameStyle: ChannelNameStyle): ViewerChannelData {
    return this.createSourceChannelMetadata<void>(undefined, requestedName, loadedData, resultChannelNameStyle);
  }

  /**
   * Creates a new viewer channel metadata with specified source data of type `TSourceData`.
   * @param sourceData The source data for the channel.
   * @param requestedName The name of the channel requested by the user.
   * @param loadedData The loaded data for the channel.
   * @param resultChannelNameStyle The style of the channel name to use.
   * @returns The new channel metadata.
   */
  public createSourceChannelMetadata<TSourceData>(
    sourceData: TSourceData, requestedName: string, loadedData: ILoadedChannelMetadata | undefined, resultChannelNameStyle: ChannelNameStyle): SourceViewerChannelData<TSourceData> {
    let genericName = requestedName;
    let fullName = requestedName;
    let channelDescription = CHANNEL_NOT_PRESENT_DESCRIPTION;
    let binaryFormat;
    let defaultChannelUnits = '()';
    let userChannelUnits;
    let loaderMetadata: Readonly<any> | undefined;

    if (loadedData) {
      genericName = loadedData.genericName;
      fullName = loadedData.fullName;
      channelDescription = loadedData.description || '';
      binaryFormat = loadedData.binaryFormat;
      defaultChannelUnits = loadedData.units || defaultChannelUnits;
      userChannelUnits = this.siteHooks.getUserChannelUnitsSynchronous(genericName);
      loaderMetadata = loadedData.loaderMetadata;
    }

    let channelUnits = userChannelUnits || defaultChannelUnits;
    let isUserSpecifiedUnits = !!userChannelUnits;
    let channelName = resultChannelNameStyle === ChannelNameStyle.Generic ? genericName : fullName;

    return new SourceViewerChannelData<TSourceData>(
      sourceData,
      channelName,
      genericName,
      fullName,
      requestedName,
      channelDescription,
      channelUnits,
      isUserSpecifiedUnits,
      binaryFormat,
      undefined,
      undefined,
      undefined,
      loaderMetadata);
  }

  /**
   * Creates a viewer channel data object from existing channel metadata and values.
   * @param channelMetadata The channel metadata.
   * @param values The values for the channel.
   * @param dataUnits The units of the data.
   * @param valueLabels The optional labels for the values.
   * @returns The new channel data.
   */
  public createChannelDataFromMetadata(
    channelMetadata: IViewerChannelData, values: ReadonlyArray<number> | undefined, dataUnits?: string, valueLabels?: ReadonlyArray<string>): IViewerChannelData {
    if (!values) {
      return channelMetadata;
    }

    values = this.getValuesForSpecifiedUnits(values, dataUnits, channelMetadata);
    return channelMetadata.clone(values, channelMetadata.units, channelMetadata.isUserSpecifiedUnits, valueLabels);
  }

  /**
   * Creates a source viewer channel data object from existing channel metadata and values.
   * @param channelMetadata The channel metadata.
   * @param values The values for the channel.
   * @param dataUnits The units of the data.
   * @param valueLabels The optional labels for the values.
   * @returns The new channel data.
   */
  public createSourceChannelDataFromMetadata<TSourceData>(channelMetadata: SourceViewerChannelData<TSourceData>, values: ReadonlyArray<number> | undefined, dataUnits?: string, valueLabels?: ReadonlyArray<string>): SourceViewerChannelData<TSourceData> {
    if (!values) {
      return channelMetadata;
    }

    values = this.getValuesForSpecifiedUnits(values, dataUnits, channelMetadata);
    return channelMetadata.cloneTyped(values, channelMetadata.units, channelMetadata.isUserSpecifiedUnits, valueLabels);
  }

  /**
   * Gets the values for the units specified in the channel metadata.
   * @param values The values.
   * @param dataUnits The units of the data.
   * @param channelMetadata The channel metadata containing the desired units.
   * @returns The values converted to the specified units.
   */
  private getValuesForSpecifiedUnits(values: ReadonlyArray<number>, dataUnits: string | undefined, channelMetadata: IViewerChannelData) {
    values = dataUnits
      ? Units.convertValuesBetweenUnits(values, dataUnits, channelMetadata.units, true)
      : Units.convertValuesFromSi(values, channelMetadata.units, true);
    return values;
  }
}

/**
 * The metadata for a loaded channel.
 */
export interface ILoadedChannelMetadata {

  /**
   * The generic name of the channel (may not be unique across simulations).
   */
  readonly genericName: string;

  /**
   * The full name of the channel (unique across simulations).
   */
  readonly fullName: string;

  /**
   * The description of the channel.
   */
  readonly description: string;

  /**
   * The units of the channel.
   */
  readonly units: string;

  /**
   * The binary format for the channel.
   */
  readonly binaryFormat?: ChannelBinaryFormat;

  /**
   * The loader metadata for the channel.
   */
  readonly loaderMetadata?: Readonly<any>;
}

/**
 * The data for a loaded channel. Extends ILoadedChannelMetadata.
 */
export interface ILoadedChannelData extends ILoadedChannelMetadata {

  /**
   * The values for the channel.
   */
  readonly values: ReadonlyArray<number>;

  /**
   * The optional labels for the values.
   */
  readonly valueLabels?: ReadonlyArray<string> | undefined;
}

/**
 * The metadata for a loaded channel.
 */
export class LoadedChannelMetadata implements ILoadedChannelMetadata {

  /**
   * Creates a new instance of LoadedChannelMetadata.
   * @param genericName The generic name of the channel (may not be unique across simulations).
   * @param fullName The full name of the channel (unique across simulations).
   * @param description The description of the channel.
   * @param units The units of the channel.
   * @param binaryFormat The binary format for the channel.
   * @param loaderMetadata The loader metadata for the channel.
   */
  constructor(
    public readonly genericName: string,
    public readonly fullName: string,
    public readonly description: string,
    public readonly units: string,
    public readonly binaryFormat: ChannelBinaryFormat | undefined,
    public readonly loaderMetadata?: Readonly<any>) {
  }
}

/**
 * The data for a loaded channel. Extends LoadedChannelMetadata.
 */
export class LoadedChannelData extends LoadedChannelMetadata implements ILoadedChannelData {

  /**
   * Creates a new instance of LoadedChannelData.
   * @param genericName The generic name of the channel (may not be unique across simulations).
   * @param fullName The full name of the channel (unique across simulations).
   * @param description The description of the channel.
   * @param units The units of the channel.
   * @param binaryFormat The binary format for the channel.
   * @param values The values for the channel.
   * @param valueLabels The optional labels for the values.
   * @param loaderMetadata The loader metadata for the values.
   */
  constructor(
    genericName: string,
    fullName: string,
    description: string,
    units: string,
    binaryFormat: ChannelBinaryFormat | undefined,
    public readonly values: ReadonlyArray<number>,
    public readonly valueLabels?: ReadonlyArray<string>,
    loaderMetadata?: Readonly<any>) {
    super(genericName, fullName, description, units, binaryFormat, loaderMetadata);
  }
}

import {Component} from '@angular/core';
import {DialogManager} from './dialog-manager.service';
import {OnInit} from '@angular/core';
import {PromptDialogData} from './prompt-dialog.service';
import {FormSubmissionHandler} from '../forms/form-submission-handler.service';
import {FormSubmissionButton} from '../forms/form-submission-button';
import {UntypedFormControl, UntypedFormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import {CanopyAutocomplete} from '../canopy-autocomplete.service';
import {Timer} from '../timer.service';
import {GetFriendlyErrorAndLog} from '../errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';

export const DEFAULT_MESSAGE = 'Value';
export const DEFAULT_VALUE = '';
export const DEFAULT_TITLE = 'Canopy Simulations';
export const DEFAULT_ACCEPT_MESSAGE = 'OK';
export const DEFAULT_DISMISS_MESSAGE = 'Cancel';

@Component({
  selector: 'cs-prompt-dialog',
  templateUrl: './prompt-dialog.component.html'
})
export class PromptDialogComponent implements OnInit {

  public promptDialog: PromptDialogData;
  public isVisible: boolean = false;
  public title: string;
  public message: string;
  public acceptMessage: string;
  public dismissMessage: string;

  public form: UntypedFormGroup;
  public value: UntypedFormControl;
  public submitButton: FormSubmissionButton;
  public errorMessage: string;

  constructor(
    private dialogManager: DialogManager,
    private formSubmissionHandler: FormSubmissionHandler,
    private canopyAutocomplete: CanopyAutocomplete,
    private formBuilder: UntypedFormBuilder,
    private timer: Timer,
    private getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  public ngOnInit(): any {
    this.dialogManager.tertiaryChanged.subscribe(() => this.dialogManagerChanged());
  }

  public async onSubmit() {
    await this.formSubmissionHandler.execute(this.submit, this.form, this.submitButton, this);
  }

  public async submit() {
    if(this.promptDialog) {
      let result = this.value.value;
      if(this.promptDialog.submitAction){
        result = await this.promptDialog.submitAction(result);
      }

      this.promptDialog.resolve(result);
    }
  }

  public dismiss() {
    if(this.promptDialog){
      this.promptDialog.dismiss();
    }
  }

  public dialogManagerChanged() {
    this.dismiss();

    let currentDialog = this.dialogManager.getTertiary();
    if(currentDialog && currentDialog instanceof PromptDialogData){
      this.isVisible = true;
      this.errorMessage = undefined;
      this.promptDialog = <PromptDialogData>currentDialog;
      this.message = this.promptDialog.message || DEFAULT_MESSAGE;
      this.title = this.promptDialog.title || DEFAULT_TITLE;
      this.acceptMessage = this.promptDialog.acceptMessage || DEFAULT_ACCEPT_MESSAGE;
      this.dismissMessage = this.promptDialog.dismissMessage || DEFAULT_DISMISS_MESSAGE;

      this.value = new UntypedFormControl(
        this.promptDialog.initialValue || DEFAULT_VALUE,
        [Validators.required]);
      this.form = this.formBuilder.group({
        value: this.value
      });
      this.submitButton = new FormSubmissionButton(this.acceptMessage, 'Wait...');

      if(this.promptDialog.autocomplete){
        this.applyAutocomplete();
      }
    } else {
      this.isVisible = false;
      this.promptDialog = undefined;
      this.value = undefined;
      this.form = undefined;
    }
  }

  private async applyAutocomplete() {
    try {
      await this.timer.yield();
      if (this.value && this.promptDialog) {
        this.canopyAutocomplete.create('prompt-dialog-value-input', this.value, this.promptDialog.autocomplete);
      }
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }
}

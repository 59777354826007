<div class="container-fluid">
  <div class="card" id="tenant-settings-default-custom-property-names-component">
    <h1 class="card-header">Default Custom Property Names</h1>
    <div class="card-body">
      <cs-error-message [message]="errorMessage"></cs-error-message>

      @if (!form) {
        <cs-loader></cs-loader>
      }
      @if (form) {
        <div class="row">
          <div class="col-12">
            <form (ngSubmit)="onSubmit()" [formGroup]="form">
              @for (item of defaultCustomPropertyNames; track item; let itemIndex = $index) {
                <div>
                  <h2>{{item.title}}</h2>
                  @for (name of item.names; track name; let nameIndex = $index) {
                    <div class="d-flex gap-1 mb-2">
                      <input id="{{itemIndex}}-{{nameIndex}}-input" type="text" class="form-control" formControlName="{{name.id}}">
                      <button type="button" class="btn btn-smart btn-secondary remove-button" (click)="removeName(item, nameIndex)">Remove</button>
                      @if (controls[name.id].hasError('customPropertyName')) {
                        <p class="text-danger">
                          Only lowercase characters, numbers, hyphens and periods are allowed.
                        </p>
                      }
                      @if (controls[name.id].hasError('minlength')) {
                        <p class="text-danger">
                          Name is too short.
                        </p>
                      }
                      @if (controls[name.id].hasError('maxlength')) {
                        <p class="text-danger">
                          Name is too long.
                        </p>
                      }
                    </div>
                  }
                  <p>
                    <button type="button" class="btn btn-smart btn-secondary add-button" (click)="addEmptyName(item)">Add</button>
                  </p>
                </div>
              }
              @if (saveSuccessful) {
                <div id="success-message" class="alert alert-success">
                  Saved.
                </div>
              }
              <p class="last-item">
                <button id="tenant-settings-default-custom-property-names-component-submit" type="submit" class="btn btn-smart btn-primary" [disabled]="submitButton.isSubmitting">{{submitButton.text}}</button>
              </p>
            </form>
          </div>
        </div>
      }
    </div>
  </div>
</div>

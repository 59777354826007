import {Component} from '@angular/core';

@Component({
  selector: 'cs-not-authorized',
  template: `
    <div class="container-fluid">
      <div class="card card-body">
        <p>Not authorized.</p>
      </div>
    </div>`,
})
export class NotAuthorizedPage {
}

import {FAILED_FIRST_JOB_NAME} from '../test-area-names';
import {NavigationStation, USE_FIRST_SUCCESSFUL_JOB_INDEX} from '../../navigation-station/navigation-station';
import {BootstrapNavigationStationBase} from '../bootstrap-navigation-station-base';
import {MonteCarloConfigBuilder} from '../../navigation-station/config-builders/monte-carlo-config-builder';
import {HTMLDivSelection} from '../../untyped-selection';
import {Disposable} from '../../disposable';
import {PRIMARY_JOB_INDEX_ID} from '../constants';
import { UserData } from '../../../identity/state/authentication.service';
import { CanopyFileLoader } from '../../../simulations/visualizations/canopy-file-loader.service';
import { CanopySiteHooks } from '../../../simulations/visualizations/canopy-site-hooks.service';
import { MockFileLoader } from '../mock-url-file-loader';
import * as d3 from '../../d3-bundle';

export class BootstrapFailedFirstJob extends BootstrapNavigationStationBase {

  private jobIndexArea?: d3.Selection<HTMLSpanElement, any, any, any>;
  private button: d3.Selection<HTMLButtonElement, any, any, any>;

  public load(testArea: HTMLDivSelection, userData: UserData, fileLoader: CanopyFileLoader, siteHooks: CanopySiteHooks): Promise<Disposable> {
    let p = testArea.append('p');
    p.append('span').text('Primary Job Index: ');
    this.jobIndexArea = p.append<HTMLSpanElement>('span').attr('id', PRIMARY_JOB_INDEX_ID).text('?');
    this.button = testArea.append('button');
    this.button.text('Load first job index').attr('class', 'btn btn-primary').attr('id', 'load-first-job-index-button');
    return super.load(testArea, userData, fileLoader, siteHooks);
  }

  public get name(){
    return FAILED_FIRST_JOB_NAME;
  }

  public get description(){
    return 'A study where the first job has failed, meaning the first successful job must be found to get available channels. If successful, job index 1 should be found. The job index is displayed above after clicking the "Edit" button.';
  }

  public createNavigationStation(elementId: string, userData: UserData, fileLoader: CanopyFileLoader, siteHooks: CanopySiteHooks): NavigationStation{

    let mockFileLoader = new MockFileLoader(fileLoader);

    this.configureMockExploration(
      mockFileLoader,
      '{"designName":"Factorial","basis":[["car.chassis.carRunningMass.mCar="],["car.chassis.hRideFSetup+"]],"coordinates":[[[-1.0],[0.0]],[[743.0],[0.0]],[[744.0],[0.0]],[[-1.0],[0.001]],[[743.0],[0.001]],[[744.0],[0.001]]],"rCoordinates":[[0.0,0.0],[1.0,0.0],[2.0,0.0],[0.0,1.0],[1.0,1.0],[2.0,1.0]],"jobNames":["Factorial 1-1","Factorial 2-1","Factorial 3-1","Factorial 1-2","Factorial 2-2","Factorial 3-2"],"indexMatrix":[[0.0,0.0],[1.0,0.0],[2.0,0.0],[0.0,1.0],[1.0,1.0],[2.0,1.0]]}',
      `jobIndex,vCarMax:StraightSim,hRideF300:StraightSim,rAeroBal300:StraightSim,rAeroBal200:StraightSim
0,NaN,NaN,NaN,NaN
1,79.314717,-0.005878,0.495867,0.477392
2,79.314586,-0.005878,0.495868,0.477393
3,NaN,NaN,NaN,NaN
4,79.366417,-0.004626,0.490309,0.474096
5,79.366268,-0.004626,0.49031,0.474097
`,
`"name","units","description","simType"
"vCarMax","m/s","","StraightSim"
"hRideF300","m","","StraightSim"
"rAeroBal100","()","","StraightSim"
"rAeroBal200","()","","StraightSim"
`);

    let studyJobs = [
      { studyId: 'failed-first-job', jobIndex: USE_FIRST_SUCCESSFUL_JOB_INDEX }
    ];

    this.button.on('click', () => {
      this.jobIndexArea.text(studyJobs[0].jobIndex);
    });

    return new NavigationStation(
      elementId,
      new MonteCarloConfigBuilder(
        mockFileLoader,
        siteHooks,
        studyJobs,
        ['QuasiStaticLap', 'ApexSim', 'StraightSim']));
  }
}

import {Injectable} from '@angular/core';
import {DialogManager, IDialog, DialogBase} from '../../../common/dialogs/dialog-manager.service';

@Injectable()
export class ViewJobDialog {
  constructor(
    private dialogManager: DialogManager
  ){}

  public async show(
    tenantId: string,
    studyId: string,
    jobId: string): Promise<ViewJobDialogResult> {

    let result = await this.dialogManager.showPrimaryDialog(
      new ViewJobDialogData(
        tenantId,
        studyId,
        jobId));

    return result;
  }
}

export class ViewJobDialogData extends DialogBase<ViewJobDialogResult> implements IDialog {
  constructor(
    public readonly tenantId: string,
    public readonly studyId: string,
    public readonly jobId: string) {
    super();
  }
}

export class ViewJobDialogResult {
  constructor(){
  }
}

import {ImportTelemetryDialogData, ImportTelemetryResult} from './import-telemetry-dialog.service';
import {Injectable} from '@angular/core';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {GetSimVersion} from '../../../common/get-sim-version.service';
import {DialogCallbacks, LoadingDialog} from '../../../common/dialogs/loading-dialog.service';
import {IDialogActions, IDialogSession, IDialogSessionFactory} from '../../../common/dialogs/dialog-component-base';
import {TextFile, TextFileReader} from '../../text-file-reader.service';
import {ConvertTelemetryConfigToStudy} from '../../configs/convert-telemetry-config-to-study.service';
import {CanopyJson} from '../../../common/canopy-json.service';
import {TelemetryFile} from '../telemetry-config';
import {PostStudyResult} from '../../../../generated/api-stubs';

@Injectable()
export class ImportTelemetryDialogSessionFactory implements IDialogSessionFactory<ImportTelemetryDialogData, ImportTelemetryResult>{

  constructor(
    private readonly getSimVersion: GetSimVersion,
    private readonly loadingDialog: LoadingDialog,
    private readonly textFileReader: TextFileReader,
    private readonly convertTelemetryConfigToStudy: ConvertTelemetryConfigToStudy,
    private readonly json: CanopyJson,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  public create(dialogActions: IDialogActions<ImportTelemetryResult>, dialog: ImportTelemetryDialogData) {
    return new ImportTelemetryDialogSession(
      dialogActions,
      dialog,
      this.getSimVersion,
      this.loadingDialog,
      this.textFileReader,
      this.convertTelemetryConfigToStudy,
      this.json,
      this.getFriendlyErrorAndLog);
  }
}

export class ImportTelemetryDialogSession implements IDialogSession {

  public errorMessage: string;
  public csvFile: TextFile;
  public telemetryConfig: TelemetryFile;
  public stage: ImportTelemetryStage = ImportTelemetryStage.selectFile;

  public ImportTelemetryStage = ImportTelemetryStage;

  constructor(
    private readonly dialogActions: IDialogActions<ImportTelemetryResult>,
    public readonly dialog: ImportTelemetryDialogData,
    private readonly getSimVersion: GetSimVersion,
    private readonly loadingDialog: LoadingDialog,
    private readonly textFileReader: TextFileReader,
    private readonly convertTelemetryConfigToStudy: ConvertTelemetryConfigToStudy,
    private readonly json: CanopyJson,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  public async load() {
    try {
      this.errorMessage = undefined;
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async processTelemetryFileEvent(event: any) {
    try {
      await this.loadingDialog.show(async (cb: DialogCallbacks) => {
        cb.setStatus('Reading file...');
        let telemetryFile = await this.textFileReader.readSingleFileAndReset(event);

        if(telemetryFile.content.startsWith('{')){
          await this.processJson(telemetryFile);
        } else{
          await this.processCsv(telemetryFile);
        }
      });
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public onTelemetryConfigCreated(telemetryConfig: TelemetryFile){
    this.telemetryConfig = telemetryConfig;
    this.stage = ImportTelemetryStage.validateTelemetry;
  }

  public onTelemetryConfigValidated(telemetryConfig: TelemetryFile){
    this.telemetryConfig = telemetryConfig;
    this.stage = ImportTelemetryStage.remapTelemetry;
  }

  public onTelemetryConfigRemapped(telemetryConfig: TelemetryFile){
    this.telemetryConfig = telemetryConfig;
    this.stage = ImportTelemetryStage.refineTelemetry;
  }

  public onTelemetryConfigProcessed(telemetryConfig: TelemetryFile){
    this.telemetryConfig = telemetryConfig;
    this.stage = ImportTelemetryStage.submit;
    this.submit();
  }

  private processCsv(file: TextFile) {
    this.csvFile = file;
    this.stage = ImportTelemetryStage.convertCsvToJson;
  }

  private async processJson(file: TextFile) {
    let json = this.json.parse(file.content);
    this.onTelemetryConfigCreated(new TelemetryFile(file.name, json));
  }

  private async submit(){
    try{
      const result = <PostStudyResult>await this.convertTelemetryConfigToStudy.execute(
        this.telemetryConfig.config,
        this.getSimVersion.currentSimVersion,
        this.telemetryConfig.name,
        false,
        [],
        undefined);

      this.dialogActions.accept(new ImportTelemetryResult(true, result.studyId));
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }


}

export enum ImportTelemetryStage {
  selectFile,
  convertCsvToJson,
  validateTelemetry,
  remapTelemetry,
  refineTelemetry,
  submit
}

import {Injectable} from '@angular/core';
import {EditorAutocompleteInformation} from './editor-autocomplete-information';

@Injectable()
export class GetUserMathsFormulaAutocompleteOptions {

  public execute(studyAutocompleteOptions: EditorAutocompleteInformation, channelNameAutocompleteInformation: EditorAutocompleteInformation): EditorAutocompleteInformation {

    let allItems = [
      ...studyAutocompleteOptions.autocompleteList,
      ...channelNameAutocompleteInformation.autocompleteList,
    ];

    let allUserInformation = [
      ...studyAutocompleteOptions.userInformation,
      ...channelNameAutocompleteInformation.userInformation,
    ];

    return new EditorAutocompleteInformation(
      allItems,
      allUserInformation,
      {
        list: allItems,
        minChars: 0,
        maxItems: 100,
        autoFirst: true,
        filter: (text: string, input: string) => {
          input = input.match(/[\w\.\[\]]*$/)[0].trim();

          return studyAutocompleteOptions.options.filter(text, input);
          //return text.indexOf(input) === 0 && (text as any).label !== input;
        },
        sort(a: string, b: string) {
          return studyAutocompleteOptions.options.sort(a, b);
        },
        replace(text: string | { label: string; value: string }) {
          let before = this.input.value.match(/^(.*?)[\w\.\[\]]*$/)[1];
          this.input.value = before + ((text as any).value || text);
        }
      });
  }
}

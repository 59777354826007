import {cssSanitize} from '../common/css-sanitize';
import {Component, OnInit} from '@angular/core';
import {
  SetChannelUnitsDialogData,
  SetChannelUnitsDialogResult
} from './set-channel-units-dialog.service';
import {DialogManager} from '../common/dialogs/dialog-manager.service';
import {GetFriendlyErrorAndLog} from '../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {SetChannelUnitsDialogSession, SetChannelUnitsDialogSessionFactory} from './set-channel-units-dialog-session';

@Component({
  selector: 'cs-set-channel-units-dialog',
  templateUrl: './set-channel-units-dialog.component.html',
  styleUrls: ['./set-channel-units-dialog.component.scss']
})
export class SetChannelUnitsDialogComponent implements OnInit {
  public isVisible: boolean = false;

  public dialog: SetChannelUnitsDialogData;
  public session: SetChannelUnitsDialogSession;
  public errorMessage: string;

  constructor(
    private sessionFactory: SetChannelUnitsDialogSessionFactory,
    private dialogManager: DialogManager,
    private getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  public ngOnInit(): any {
    this.dialogManager.tertiaryChanged.subscribe(() => this.dialogManagerChanged());
  }

  public async accept(selectedUnits: string) {
    let dialog = this.dialog;
    if(dialog) {
      try {
        await this.session.saveUnits(selectedUnits);
        dialog.resolve(new SetChannelUnitsDialogResult(selectedUnits));
      } catch (error) {
        this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
      }
    }
  }

  public dismiss() {
    if(this.dialog){
      this.dialog.dismiss();
    }
  }

  public async load(){
    try {
      await this.session.load();
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public cssSanitize(input: string): string {
    return cssSanitize(input);
  }

  public dialogManagerChanged() {
    this.dismiss();

    let currentDialog = this.dialogManager.getTertiary();
    if(currentDialog && currentDialog instanceof SetChannelUnitsDialogData){
      this.errorMessage = undefined;
      this.isVisible = true;
      this.dialog = <SetChannelUnitsDialogData>currentDialog;
      this.session = this.sessionFactory.create(this.dialog);
      this.load();
    } else {
      this.isVisible = false;
      this.dialog = undefined;
    }
  }
}


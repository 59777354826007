/**
 * Feature channel names for a viewer. Feature channels are channels which are
 * used for viewer specific features, such as integration or splitting the data
 * into laps.
 */

export class FeatureChannelNames {

  /**
   * Creates a new FeatureChannelNames instance.
   * @param integrationChannel The channel name over which integration calculations should be done.
   * @param splitChannel The channel name over which data should be split into laps.
   */
  constructor(
    public readonly integrationChannel: string | undefined,
    public readonly splitChannel: string | undefined) {
  }

  /**
   * Returns all feature channel names.
   */
  public get allChannelNames(): ReadonlyArray<string> {
    return [
      this.integrationChannel,
      this.splitChannel
    ].filter(v => !!v) as string[];
  }
}

import { ExplorationMapValues, ProcessedExplorationMapValues } from './exploration-map-values';

/**
 * Metadata about the inputs of an exploration.
 */
export class ExplorationInputsMetadata {

  /**
   * Creates a new instance of ExplorationInputsMetadata.
   * @param sweeps The metadata of the sweeps of the exploration.
   */
  constructor(
    public readonly sweeps: ReadonlyArray<ExplorationSweepMetadata>) {
  }
}

/**
 * Metadata about the sweeps of an exploration.
 */
export class ExplorationSweepMetadata {

  /**
   * Creates a new instance of ExplorationSweepMetadata.
   * @param subSweeps The metadata of the sub-sweeps of the sweep.
   */
  constructor(
    public readonly subSweeps: ReadonlyArray<ExplorationSubSweepMetadata>) {
  }
}

/**
 * Metadata about the sub-sweeps of an exploration. This is used during processing
 * of the original exploration map.
 * This may still contain array sweeps.
 */
export class ExplorationSubSweepMetadataWithArrayValues {

  /**
   * Creates a new instance of ExplorationSubSweepMetadataWithArrayValues.
   * @param name The name of the sub-sweep.
   * @param values The values of the sub-sweep (array sweeps have not been expanded yet).
   * @param unexpandedName The unexpanded name of the sub-sweep.
   */
  constructor(
    public readonly name: string,
    public readonly values: ExplorationMapValues,
    public readonly unexpandedName?: string) {
  }
}

/**
 * Metadata about the sub-sweeps of an exploration.
 * This has had any array sweeps expanded into individual numeric sub-sweeps of the array elements.
 */
export class ExplorationSubSweepMetadata {

  /**
   * Creates a new instance of ExplorationSubSweepMetadata.
   * @param name The name of the sub-sweep.
   * @param values The values of the sub-sweep (array sweeps have been expanded into parallel sub-sweeps).
   * @param unexpandedName The unexpanded name of the sub-sweep.
   */
  constructor(
    public readonly name: string,
    public readonly values: ProcessedExplorationMapValues,
    public readonly unexpandedName?: string) {
  }
}

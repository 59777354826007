import {Component} from '@angular/core';
import {CanopyValidators} from '../common/forms/canopy-validators.service';
import {FormSubmissionButton} from '../common/forms/form-submission-button';
import {FormSubmissionHandler} from '../common/forms/form-submission-handler.service';
import {MembershipStub} from '../../generated/api-stubs';
import {UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder} from '@angular/forms';

export const PROVIDE_INPUT_ERROR = 'Please provide either your username or your email address.';

@Component({
  templateUrl: './forgot-password.page.html'
})
export class ForgotPasswordPage {
  public form: UntypedFormGroup;
  public tenant: UntypedFormControl = new UntypedFormControl('', Validators.compose([Validators.required, ...CanopyValidators.tenantShortNameValidators]));
  public email: UntypedFormControl = new UntypedFormControl('', Validators.compose([...CanopyValidators.emailValidators]));
  public username: UntypedFormControl = new UntypedFormControl('', Validators.compose([...CanopyValidators.usernameValidators]));

  public errorMessage: string;
  public submitButton = new FormSubmissionButton('Reset Password', 'Resetting...');
  public submitted = false;

  constructor(
    formBuilder: UntypedFormBuilder,
    private formSubmissionHandler: FormSubmissionHandler,
    private membershipStub: MembershipStub) {
    this.form = formBuilder.group({
      tenant: this.tenant,
      email: this.email,
      username: this.username
    });
  }

  public async onSubmit() {
    if (!this.email.value && !this.username.value) {
      this.errorMessage = PROVIDE_INPUT_ERROR;
      return;
    }

    let success = await this.formSubmissionHandler.execute(this.submit, this.form, this.submitButton, this);

    if (success) {
      this.submitted = true;
    }
  }

  private async submit() {
    await this.membershipStub.postPasswordResetRequest({
      tenant: this.tenant.value,
      email: this.email.value,
      username: this.username.value
    });
  }
}

import { StudyInput } from '../../../worksheets/study-input';

export class ConfigOrConfigLoader {
  constructor(
    public readonly shortName: string,
    public materialized: StudyInput | undefined,
    public readonly loader: IMaterializedConfigLoader | undefined,
    public readonly additionalData?: ConfigAdditionalData) {
  }

  public async getMaterialized(): Promise<StudyInput> {
    if (!this.materialized) {
      this.materialized = await this.loader.execute();

      if (!this.materialized) {
        throw new Error('Failed to materialize config.');
      }
    }

    return this.materialized;
  }
}

export class ConfigAdditionalData {
  constructor(
    public readonly deleteRequested: boolean,
    public readonly parentWorksheetId: string) {
  }
}

export interface IMaterializedConfigLoader {
  execute(): Promise<StudyInput>;
}

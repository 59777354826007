import { SourceData } from './types/source-data';
import { IMultiPlotChannel } from './types/i-multi-plot-channel';
import { ProcessedMultiPlotChannel } from './types/processed-multi-plot-channel';
import { ChannelIndexMap } from './channel-index-map';
import { GetProcessedChannels } from './get-processed-channels';


/**
 * Returns a single processed channel for the given channel.
 */
export class GetProcessedChannel {

  constructor(
    private readonly primaryDomainName: string,
    private readonly getProcessedChannels: GetProcessedChannels) {
  }

  /**
   * Returns a single processed channel for the given channel.
   * @param channel The channel definition to process.
   * @param sourcesData The set of data sources.
   * @param channelIndexMap The channel index map.
   * @returns The processed channel.
   */
  public execute(channel: IMultiPlotChannel, sourcesData: ReadonlyArray<SourceData>, channelIndexMap: ChannelIndexMap): ProcessedMultiPlotChannel {
    let result = this.getProcessedChannels.execute(channel, sourcesData, channelIndexMap, [this.primaryDomainName]);
    return result[0];
  }
}

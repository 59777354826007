import {Position} from '../visualizations/viewers/position';

export class MenuItemsDefinition<TResult> {
  constructor(
    public readonly items: ReadonlyArray<MenuItem<TResult>>,
    public readonly wrapper: IMenuItemWrapper<TResult>) {
  }
}

export class MenuDefinition<TResult> extends MenuItemsDefinition<TResult> {
  constructor(
    public position: Position,
    items: ReadonlyArray<MenuItem<TResult>>,
    wrapper: IMenuItemWrapper<TResult>) {
    super(items, wrapper);
  }
}

export abstract class MenuItem<TResult> {
  protected constructor(
    public readonly type: MenuItemType) {
  }
}

export class ButtonMenuItem<TResult> extends MenuItem<TResult> {
  constructor(
    public readonly name: string,
    public readonly cssClass: string,
    public readonly action: (event: MouseEvent) => Promise<TResult>,
    public readonly iconClass: string = '',
    public readonly keyboardAction: KeyboardAction = KeyboardAction.none) {
    super(MenuItemType.button);
  }
}

export class SeparatorMenuItem extends MenuItem<any> {
  constructor() {
    super(MenuItemType.separator);
  }
}

export class SubMenuItem<TResult> extends MenuItem<TResult> {
  constructor(
    public readonly name: string,
    public readonly cssClass: string,
    public readonly items: ReadonlyArray<MenuItem<TResult>>,
    public readonly enableScroll: boolean = false) {
    super(MenuItemType.subMenu);
  }
}

export interface IMenuItemWrapper<TResult> {
  executeMenuItemAction(event: MouseEvent, action: (event: MouseEvent) => Promise<TResult>): Promise<TResult>;
}

export enum MenuItemType {
  button,
  separator,
  subMenu,
}

export enum KeyboardAction {
  none,
  enter,
  cut,
  copy,
  paste,
  pasteDuplicate,
  delete,
}

import { TextFileReader } from '../../../text-file-reader.service';
import { CanopyJson } from '../../../../common/canopy-json.service';
import { getSafePath } from '../get-safe-path';
import { ExtensionButtons } from './extension-buttons';
import { AddButtonsHandler } from './add-buttons-handler';
import { EditorNodeUtilities } from './editor-node-utilities';
import { UpdateDelegate } from './update-delegate';
import { ExtensionButtonCategories } from './extension-button-categories';
import { Injectable } from '@angular/core';


@Injectable()
export class AddImportButtons implements AddButtonsHandler {
  constructor(
    private readonly json: CanopyJson,
    private readonly textFileReader: TextFileReader) {
  }

  public get category(): ExtensionButtonCategories {
    return ExtensionButtonCategories.import;
  }

  public execute(utils: EditorNodeUtilities, subTreeControls: any, globalUpdateDelegate: UpdateDelegate): ExtensionButtons {
    let fullPath = utils.self.path;
    let safePath = getSafePath(fullPath);

    if (!safePath) {
      return undefined;
    }

    let importButton = utils.self.getButton('', 'import', 'Import');
    importButton.className += ' btn-icon sub-tree-import-button';
    let exportButton = utils.self.getButton('', 'export', 'Export');
    exportButton.className += ' btn-icon sub-tree-export-button';

    let importFileInput = document.createElement('input');
    importFileInput.setAttribute('type', 'file');
    importFileInput.setAttribute('accept', '.json');
    importFileInput.addEventListener('change', async (event) => {
      try {
        let files = importFileInput.files;
        if (!files || files.length === 0) {
          return;
        }

        let file = files[0];
        importFileInput.value = '';

        let text = await this.textFileReader.read(file);
        let data = this.json.parse(<string>text);

        let loadedConfig = data;
        let result = await utils.configSubTreeRepository.import(safePath, loadedConfig);

        if (result) {
          utils.subTreeData = undefined;
          utils.setValueOrParentValue(result);
        }
      } catch (error) {
        utils.handleError(error);
      }

      globalUpdateDelegate();
    });

    utils.addButton(subTreeControls, importButton, async () => {
      importFileInput.click();
    }, () => { });

    utils.addButton(subTreeControls, exportButton, async () => {
      let config = utils.self.getValue();
      await utils.configSubTreeRepository.export(utils.configType, safePath, utils.subTreeData ? utils.subTreeData.config.name : undefined, config);
    }, () => globalUpdateDelegate());


    return new ExtensionButtons(
      () => { },
      {
        import: importButton,
        export: exportButton,
      });
  }
}

<div class="canopy-footer">
  <div class="container-fluid">
    <hr/>
    <p>
      @if (lastRequestedUserSimVersion) {
        <span class="footer-item">
          User Sim Version <span id="last-user-sim-version">{{lastRequestedUserSimVersion}}</span>
        </span>
      }
      @if (lastRequestedTenantSimVersion) {
        <span class="footer-item">
          Tenant Sim Version <span id="last-tenant-sim-version">{{lastRequestedTenantSimVersion}}</span>
        </span>
      }
      <span class="footer-item">Connected to <span id="api-base-url">{{apiBaseUrl}}</span></span>
      @if (isTestUser) {
        <span class="footer-item"><a id="view-sim-version-documents-footer-link" [routerLink]="['/admin/documents']">Sim Version Documents</a></span>
      }
      <cs-notification-enabler class="footer-item"></cs-notification-enabler>
    </p>
  </div>
</div>

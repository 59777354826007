import { SimTypeInformation } from './get-job-sim-type-metadata';
import {
  DISTANCE_DOMAIN_NAME, SLAP_DOMAIN_NAME,
  SRUN_DOMAIN_NAME,
  T_DOMAIN_NAME,
  TIME_DOMAIN_NAME,
  TLAP_DOMAIN_NAME,
  TRUN_DOMAIN_NAME
} from '../../constants';
import { SimType } from '../../sim-type';
import { StudyJobSimTypeMetadata } from './get-jobs-sim-type-metadata';

const timeOrDistanceDomains = [
  SRUN_DOMAIN_NAME,
  TRUN_DOMAIN_NAME,
  SLAP_DOMAIN_NAME,
  TLAP_DOMAIN_NAME,
  DISTANCE_DOMAIN_NAME,
  TIME_DOMAIN_NAME,
  T_DOMAIN_NAME,
];

/**
 * Determines if the sim type is a time or distance sim type.
 * @param simTypeInformation The sim type information.
 * @returns True if the sim type is a time or distance sim type.
 */
export function isTimeOrDistanceSimType(simTypeInformation: SimTypeInformation) {
  return simTypeInformation.xDomainNames.length > 0 && simTypeInformation.xDomainNames.some(x => timeOrDistanceDomains.indexOf(x) !== -1);
}

/**
 * Determines if the sim type or any of the job sim types are a time or distance sim type.
 * @param simType The sim type.
 * @param studyJobSimTypes The study job sim types.
 * @returns True if the sim type or any of the job sim types are a time or distance sim type.
 */
export function isTimeOrDistanceSimTypeForJobs(simType: SimType, studyJobSimTypes: ReadonlyArray<StudyJobSimTypeMetadata>) {
  let result = false;
  for (let job of studyJobSimTypes) {
    let jobSimType = job.simTypes.find(v => v.name === simType);
    if (!jobSimType) {
      continue;
    }

    result = result || isTimeOrDistanceSimType(jobSimType);
  }

  return result;
}

<div class="container-fluid" id="view-jobs-page">
  <div class="list-card">
    <h1 class="list-card-header">
      @if (!studyName) {
        <span>Jobs</span>
      }
      @if (studyName) {
        <span>
          {{studyName}}
          <small class="text-body-secondary">
            Jobs
          </small>
        </span>
      }
    </h1>

    <div class="list-card-body">
      <cs-error-message [message]="errorMessage"></cs-error-message>

      <div class="list-card-padded">
        <p>
          <a id="back-button" class="btn btn-smart btn-secondary" [routerLink]="['../']">
            <i class="fa fa-caret-left"></i>Back
          </a>
          <a id="go-to-job-button" class="btn btn-smart btn-secondary" (click)="goToJob()">
            <i class="fa fa-cube"></i>Go To Job
          </a>
        </p>

        @if (!jobs) {
          <cs-loader></cs-loader>
        }
      </div>
      @if (jobs) {
        <div>
          @if (incompleteJobCount) {
            <div class="alert alert-info">
              {{incompleteJobCount}} {{incompleteJobCount === 1 ? 'job is' : 'jobs are'}} still running.
            </div>
          }
          <cs-list-filter [initialFilter]="filter"
            [loadGroupResults]="getLoadGroupResultsDelegate"
            [tenantId]="tenantId"
            [disableForm]="isReloading"
            [canPersistFilter]="true"
            [filterConfigType]="'jobFilter'"
          (filterChanged)="filterChanged($event)"></cs-list-filter>
          <div class="filtered-list">
            <table id="jobs-table" class="table jobs-table last-item">
              <thead>
                <tr>
                  <th class="icon-cell"></th>
                  <th>Name</th>
                  <th>State</th>
                  <th>Time</th>
                </tr>
              </thead>
              <tbody>
                @for (job of jobs; track trackById(i, job); let i = $index) {
                  <tr>
                    <td class="icon-cell button-menu-hover-target">
                      <button id="job-hover-button-{{job.jobId}}" class="btn btn-smart btn-secondary btn-icon btn-xsm job-hover-button-{{i}}">
                        <i class="fa fa-ellipsis-h"></i>
                      </button>
                      <span class="button-menu-hovering-area">
                        @if (!job.isPostProcessor) {
                          <button title="Compare" id="stage-to-results-staging-area-button-{{job.jobId}}" class="btn btn-smart btn-secondary stage-to-results-staging-area-button-{{i}}" (click)="stageToResultsStagingArea(job)">
                            <i class="fa fa-area-chart"></i>Compare Results
                          </button>
                        }
                        <cs-stage-all-inputs [tenantId]="job.tenantId" [studyId]="job.studyId" [jobId]="job.jobId"></cs-stage-all-inputs>
                        <button (click)="showJob(job)" title="Quick View" class="btn btn-smart btn-secondary quick-view-job-button-{{i}}">
                          <i class="fa fa-eye"></i>Quick View Job
                        </button>
                        @if (!job.isPostProcessor && significantSimVersions.isAfterTelemetrySimulation) {
                          <button title="Stage" id="stage-to-study-staging-area-button-{{job.jobId}}" class="btn btn-smart btn-secondary stage-to-study-staging-area-button-{{i}}" (click)="stageToStudyStagingArea(job)">
                            <i class="fa fa-arrow-circle-up"></i><cs-stage-as-telemetry-text [studyType]="studyType"></cs-stage-as-telemetry-text>
                          </button>
                        }
                        <cs-linting-engine-dialog-launcher
                          [tenantId]="job.tenantId"
                          [userId]="job.userId"
                          [studyId]="job.studyId"
                        [jobId]="job.jobId"></cs-linting-engine-dialog-launcher>
                      </span>
                    </td>
                    <td>
                      <a class="job-link name-{{cssSanitize(job.name)}} job-id-{{job.jobId}} job-link-{{i}}"
                        [routerLink]="['./', job.index]">
                        {{job.name}}
                        </a><small class="job-index">Index {{job.index}}</small>
                        @for (diff of job.flattenedDefinition; track diff) {
                          <div class="job-diff-key">
                            {{diff.key}}
                          </div>
                        }
                      </td>
                      <td>
                        <div title="{{job.errorMessages && job.errorMessages.length ? job.errorMessages[0] : ''}}">
                          @if (job.isSuccessful) {
                            <i class="fa fa-check"></i>
                          }
                          @if (job.isFailed) {
                            <i class="fa fa-times"></i>
                          }
                          @if (!job.isFailed && !job.isSuccessful) {
                            <i class="fa fa-spinner fa-spin"></i>
                          }
                          <cs-view-credits [computeCredits]="job.computeCredits" [storageCredits]="job.storageCredits"></cs-view-credits>
                        </div>
                        @for (diff of job.flattenedDefinition; track diff) {
                          <div class="job-diff-value">
                            {{diff.value}}
                          </div>
                        }
                      </td>
                      <td>{{job.executionTime}}</td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
            @if (lastResult?.queryResults?.hasMoreResults) {
              <button
                class="btn btn-smart btn-secondary"
                [disabled]="isReloading"
                (click)="loadNext()">
                {{isReloading ? 'Loading...' : 'More...'}}
              </button>
            }
          </div>
        }
      </div>
    </div>
  </div>

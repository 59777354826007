
export function getJobIdFromJobIndex(studyId: string, jobIndex: number){
  return studyId + '-' + jobIndex;
}

export function getJobIdFromJobIndexOrJobId(studyId: string, jobIndexOrId: number | string) {
  if(typeof(jobIndexOrId) === 'number'){
    return getJobIdFromJobIndex(studyId, jobIndexOrId);
  }

  return jobIndexOrId;
}

import * as d3 from '../../d3-bundle';
import { LoadedChannelData } from './viewer-channel-data-factory';
import { SiteHooks } from '../../site-hooks';
import { INPUT_DIMENSION_CHANNEL_DESCRIPTION, NO_UNITS } from '../../constants';
import { FilteredStudyExplorationAndScalarDataCache } from './filtered-study-exploration-and-scalar-data-cache';
import { getInputChannelGenericName } from '../../get-input-channel-generic-name';
import {
  SingleDimensionEvaluationDataCache,
} from './single-dimension-evaluation-data-cache';
import { isNullOrUndefined } from '../../is-null-or-undefined';
import { ExplorationChannelMetadata } from './exploration-channel-metadata';
import { ExplorationJobSweep } from './explorations/exploration-job-sweep';
import { ExplorationSubSweep, ExplorationSubSweepDataType } from './explorations/exploration-sub-sweep';
import { InputsMetadataMap } from './channel-scalar-data-and-mappings';

export type NumericEvaluationValues = ReadonlyArray<ReadonlyArray<number>>;

/**
 * Loads channel data from an exploration map's sub-sweeps, returning it in a standard format.
 */
export class LoadChannelDataFromExplorationMap {
  constructor(
    private readonly siteHooks: SiteHooks,
    private readonly studyExplorationAndScalarDataCache: FilteredStudyExplorationAndScalarDataCache,
    private readonly singleDimensionEvaluationDataCache: SingleDimensionEvaluationDataCache | undefined) {
  }

  /**
   * Loads channel data from an exploration map, returning it in a standard format.
   * Each input sub-sweep is a channel.
   * Each job in the exploration provides a data value for each channel.
   * @param options Options for loading the channel data.
   * @returns The loaded channel data.
   */
  public async execute(options?: LoadChannelDataFromExplorationMapOptions): Promise<LoadedExplorationMapChannelData[]> {
    options = options || {};

    let explorationAndScalarData = this.studyExplorationAndScalarDataCache.get();
    let map = explorationAndScalarData.explorationMap;
    let inputsMetadataMap = explorationAndScalarData.scalarData.inputsMetadataMap;

    // A dimension is a sweep (e.g. think of a Star exploration, each sweep is an axis to explore).
    let dimensionIndices: number[];
    let singleDimension: boolean;
    if (!isNullOrUndefined(options.dimensionIndex)) {
      singleDimension = true;
      dimensionIndices = [options.dimensionIndex];
    } else {
      // Use all dimensions if none specified in the input options.
      singleDimension = false;
      dimensionIndices = d3.range(map.dimensionCount);
    }

    let maxParameterCount = options.firstParametersOnly ? 1 : Infinity;

    let result: LoadedExplorationMapChannelData[] = [];

    // For each dimension (may only be one dimension depending on the input options)...
    for (let dimensionIndex of dimensionIndices) {

      // Get all the sweep values for the dimension.
      const originalJobsWithSweep = map.jobs.map(job => job.sweeps[dimensionIndex]);

      // Get the sub-sweep definitions for the dimension.
      const dimensionSweep = map.inputs.sweeps[dimensionIndex];

      // Get the data types for each sub-sweep in the sweep.
      const originalDataTypes = dimensionSweep.subSweeps.map(subSweep => subSweep.dataType);

      let jobsWithSweep: ReadonlyArray<ExplorationJobSweep> = originalJobsWithSweep;
      let dataTypes: ReadonlyArray<ExplorationSubSweepDataType> = originalDataTypes;
      if (singleDimension) {
        if (!this.singleDimensionEvaluationDataCache) {
          throw new Error('Single dimension evaluation data cache not specified.');
        }

        // If we're only getting a single dimension, which happens when we're generating the single
        // dimension charts under a parallel coordinates viewer, we use different way of getting the data.
        // For example, for a monte-carlo exploration we interpolate the data over a regular grid to use
        // it as the x-axis in the slice through the multi-dimensional space.
        let dimensionEvaluationData = await this.singleDimensionEvaluationDataCache.getDefined(dimensionIndex);
        jobsWithSweep = dimensionEvaluationData.jobsWithSweep;
        dataTypes = dimensionEvaluationData.dataTypes;
      }

      // Add parameter values to x-domains.
      for (let subSweepIndex = 0; subSweepIndex < d3.minStrict([maxParameterCount, dimensionSweep.subSweeps.length]); ++subSweepIndex) {
        const explorationSubSweep = dimensionSweep.subSweeps[subSweepIndex];
        const data = await this.createChannelDataForSubSweep(
          explorationSubSweep,
          // originalJobsWithSweep,
          jobsWithSweep,
          dataTypes,
          dimensionIndex,
          subSweepIndex,
          inputsMetadataMap);

        result.push(data);
      }
    }

    return result;
  }

  /**
   * Turns the exploration map sub-sweep data into a LoadedChannelData derived object.
   * @param explorationSubSweep The exploration sub-sweep definition.
   * @param jobsWithSweep The job sweep values for the sweep containing the sub-sweep.
   * @param dataTypes The data types of the sub-sweeps.
   * @param dimensionIndex The dimension (sweep) index.
   * @param subSweepIndex The sub-sweep index within the sweep.
   * @param inputsMetadataMap The map of input metadata.
   * @returns
   */
  private async createChannelDataForSubSweep(
    explorationSubSweep: ExplorationSubSweep,
    // originalJobsWithSweep: ReadonlyArray<ExplorationJobSweep>,
    jobsWithSweep: ReadonlyArray<ExplorationJobSweep>,
    dataTypes: ReadonlyArray<ExplorationSubSweepDataType>,
    dimensionIndex: number,
    subSweepIndex: number,
    inputsMetadataMap: InputsMetadataMap) {

    const name = new NameAndUnexpandedName(explorationSubSweep.name, explorationSubSweep.unexpandedName);

    let units = NO_UNITS;
    let genericName = name.unexpanded;

    // Get the metadata for this sub-sweep.
    let inputMetadata = inputsMetadataMap[name.unexpanded];
    if (inputMetadata && inputMetadata.units) {
      units = inputMetadata.units;
      genericName = inputMetadata.shortName;
    } else {
      genericName = getInputChannelGenericName(genericName);
      units = await this.siteHooks.getDefaultChannelUnits(genericName) || NO_UNITS;
    }

    let valueLabels: ReadonlyArray<string> | undefined;

    // If the sub-sweep data type isn't numeric (e.g. an enumeration of config names), save the string values.
    if (dataTypes[subSweepIndex] !== ExplorationSubSweepDataType.Numeric) {
      valueLabels = explorationSubSweep.values.map(v => v.toString());
    }

    // Get the sub-sweep numeric values.
    let subSweepNumericValues = jobsWithSweep.map(sweep => sweep.subSweepNumericValues[subSweepIndex]).map(v => isNaN(v) ? 0 : v);
    // let originalSubSweepNumericValues = originalJobsWithSweep.map(sweep => sweep.subSweepNumericValues[subSweepIndex]).map(v => isNaN(v) ? 0 : v);
    // let originalNormalizedSubSweepValues = originalJobsWithSweep.map(sweep => sweep.normalizedSubSweepValues[subSweepIndex]);

    let explorationChannelMetadata = new ExplorationChannelMetadata(
      // originalSubSweepNumericValues,
      // originalNormalizedSubSweepValues,
      explorationSubSweep);

    // Return the channel data.
    return new LoadedExplorationMapChannelData(
      genericName,
      name.expanded,
      dimensionIndex,
      subSweepIndex,
      // undefined, // If we want to re-enable tracking the vector index we should store this when we expand the arrays and pass it through here.
      explorationChannelMetadata,
      INPUT_DIMENSION_CHANNEL_DESCRIPTION,
      units,
      subSweepNumericValues,
      valueLabels);
  }
}

/**
 * Options for loading channel data from an exploration map.
 */
export interface LoadChannelDataFromExplorationMapOptions {

  /**
   * The index of the dimension to load. If not specified, all dimensions are loaded.
   */
  dimensionIndex?: number;

  /**
   * If true, only the first parameter (sub-sweep) of each dimension is loaded.
   */
  firstParametersOnly?: boolean;
}

/**
 * Represents the loaded channel data from an exploration map.
 * Derives from the standard LoadedChannelData class.
 */
export class LoadedExplorationMapChannelData extends LoadedChannelData {
  constructor(
    genericName: string,
    fullName: string,
    public readonly inputDimensionIndex: number,
    public readonly inputDimensionParameterIndex: number,
    // public readonly inputDimensionParameterVectorIndex: number | undefined,
    explorationChannelMetadata: ExplorationChannelMetadata,
    description: string,
    units: string,
    values: ReadonlyArray<number>,
    valueLabels?: ReadonlyArray<string>) {
    super(genericName, fullName, description, units, undefined, values, valueLabels, explorationChannelMetadata);
  }
}

/**
 * Represents a name and its unexpanded form.
 */
class NameAndUnexpandedName {

  /**
   * Creates a new instance of NameAndUnexpandedName.
   * @param expanded The expanded name.
   * @param unexpanded The unexpanded name.
   */
  constructor(
    public readonly expanded: string,
    public readonly unexpanded: string) {
  }
}

import { isDefined } from './is-defined';

/**
 * Returns an array containing only defined values from the input array.
 * @param array The input array.
 * @returns An array of defined values.
 */
export function definedValues<T>(array: (T | undefined)[]): T[] {
  return array.filter(v => isDefined(v)) as T[];
}


/**
 * Timer class that provides a way to delay execution of code.
 */
class TimerImplementation {

  /**
   * Yields control back to the event loop.
   * @returns A promise that resolves after the event loop has been given control.
   */
  public yield() {
    return new Promise<any>((resolve, reject) => {
      setTimeout(resolve);
    });
  }

  /**
   * Delays execution of code by a specified number of milliseconds.
   * @param milliseconds The number of milliseconds to delay.
   * @returns A promise that resolves after the specified number of milliseconds.
   */
  public delay(milliseconds: number) {
    return new Promise<any>((resolve, reject) => {
      setTimeout(resolve, milliseconds);
    });
  }
}

/**
 * The timer instance.
 */
export const Timer = new TimerImplementation();

import { Injectable } from '@angular/core';
import { ExtensionButtonCategories } from '../json-editor-node-extensions/extension-button-categories';
import { EditorNodeExtensionsFactory } from '../json-editor-node-extensions/editor-node-extensions-factory';
import { JsonEditorCustomization } from './json-editor-customization';
import { getCanopyJsonEditorOptions } from './get-canopy-json-editor-options';
import{ JSONEditor } from'@json-editor/json-editor'

@Injectable()
export class ArrayEditor extends JsonEditorCustomization {

  constructor(
    private readonly editorNodeExtensionsFactory: EditorNodeExtensionsFactory) {
    super();
  }

  public apply(): void {
    const service = this;

    JSONEditor.defaults.editors.array = class extends JSONEditor.defaults.editors.array {
      build() {
        super.build();
        this.theme.addHoverArea(this);
        const canopyOptions = getCanopyJsonEditorOptions(this);

        this.theme.addHelpLink(this.container, this.schema, this);

        this.extensions = service.editorNodeExtensionsFactory.create(canopyOptions.simVersion, canopyOptions.configType, this, canopyOptions.configSubTreeRepository);
        this.extensions.addButtonsContainer(this.title, [ExtensionButtonCategories.load, ExtensionButtonCategories.import]);
      }

      setValue(...args: any[]) {
        super.setValue(...args);
        if (this.extensions) {
          this.extensions.update();
        }
      }
    }
  }
}

import {Component, OnInit, } from '@angular/core';
import {ViewJobDialogData} from './view-job-dialog.service';
import {ViewJobDialogSession, ViewJobDialogSessionFactory} from './view-job-dialog-session';
import {DialogManager} from '../../../common/dialogs/dialog-manager.service';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {cssSanitize} from '../../../common/css-sanitize';

@Component({
  selector: 'cs-view-job-dialog',
  templateUrl: './view-job-dialog.component.html',
  styleUrls: ['./view-job-dialog.component.scss']
})
export class ViewJobDialogComponent implements OnInit {
  public isVisible: boolean = false;

  public dialog: ViewJobDialogData;
  public session: ViewJobDialogSession;
  public errorMessage: string;

  constructor(
    private sessionFactory: ViewJobDialogSessionFactory,
    private dialogManager: DialogManager,
    private getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  public ngOnInit(): any {
    this.dialogManager.primaryChanged.subscribe(() => this.dialogManagerChanged());
  }

  public dismiss() {
    if(this.dialog){
      this.dialog.dismiss();
    }
  }

  public async load(){
    try {
      await this.session.load();
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public cssSanitize(input: string): string {
    return cssSanitize(input);
  }

  public dialogManagerChanged() {
    this.dismiss();

    let currentDialog = this.dialogManager.getPrimary();
    if(currentDialog && currentDialog instanceof ViewJobDialogData){
      this.errorMessage = undefined;
      this.isVisible = true;
      this.dialog = <ViewJobDialogData>currentDialog;
      this.session = this.sessionFactory.create(this.dialog);
      this.load();
    } else {
      this.isVisible = false;
      this.dialog = undefined;
    }
  }
}


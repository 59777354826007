import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TelemetryConfig, TelemetryFile, ValidatedTelemetryFile, VersionedTelemetryConfig} from '../telemetry-config';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {DisplayableError} from '../../../common/errors/errors';
import {ConfigStub, DocumentSubType} from '../../../../generated/api-stubs';
import {GetSimVersion} from '../../../common/get-sim-version.service';
import {Timer} from '../../../common/timer.service';
import {ImportTelemetryStage} from '../import-telemetry-dialog/import-telemetry-stage';
import {TelemetryChannel} from '../telemetry-channel';
import { AuthenticationService } from '../../../identity/state/authentication.service';

export const DEFAULT_TELEMETRY_FILE_NAME = 'New Telemetry';

@Component({
  selector: 'cs-validate-telemetry-json',
  templateUrl: './validate-telemetry-json-stage.component.html',
  styleUrls: ['./validate-telemetry-json-stage.component.scss']
})
export class ValidateTelemetryJsonStageComponent extends ImportTelemetryStage {
  @Input() public telemetryConfig: TelemetryFile;
  @Output() public telemetryConfigValidated: EventEmitter<ValidatedTelemetryFile> = new EventEmitter<ValidatedTelemetryFile>();

  public readonly errorMessages: string[] = [];

  constructor(
    private readonly configStub: ConfigStub,
    private readonly getSimVersion: GetSimVersion,
    private readonly authenticationService: AuthenticationService,
    timer: Timer,
    getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
    super(timer, getFriendlyErrorAndLog);
  }

  public async run(){
    try {
      let result = await this.getValidatedConfig(this.telemetryConfig);
      if(!this.errorMessage && (!this.errorMessages || !this.errorMessages.length)){
        this.telemetryConfigValidated.emit(result);
      }
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public get hasErrors(): boolean {
    return !!this.errorMessage || !!this.errorMessages.length;
  }

  public async getValidatedConfig(input: TelemetryFile): Promise<ValidatedTelemetryFile> {
    if(!input){
      throw new DisplayableError('No telemetry file provided.');
    }

    let name: string;

    if(input.name) {
      name = input.name;
    } else{
      name = DEFAULT_TELEMETRY_FILE_NAME;
    }

    if(!input.config){
      throw new DisplayableError('No telemetry config provided.');
    }

    if(typeof input.config !== 'object') {
      throw new DisplayableError('Telemetry root not found.');
    }

    let simVersion: string | undefined;
    let config = input.config as TelemetryConfig;
    let versionedConfig = input.config as VersionedTelemetryConfig;
    if(versionedConfig.simVersion){
      simVersion = versionedConfig.simVersion;
      config = versionedConfig.config;
    }

    const userData = this.authenticationService.userDataSnapshot;

    if(simVersion){
      let upgradeResult = await this.configStub.upgradeConfig(
        userData.tenant,
        this.getSimVersion.currentSimVersion,
        {
          configType: DocumentSubType.telemetry,
          config,
          simVersion
        });

      config = upgradeResult.config;
    }

    this.validateChannels(config.channels);

    return new ValidatedTelemetryFile(name, config);
  }

  private validateChannels(channels: ReadonlyArray<TelemetryChannel>){
    if(!channels || !channels.length){
      throw new DisplayableError('No channels provided.');
    }

    let expectedChannelLength = 0;
    for(let i=0; i < channels.length; ++i){
      let channel = channels[i];
      if(!channel){
        this.errorMessages.push('No channel at index ' + i);
        continue;
      }

      if(!channel.name){
        this.errorMessages.push('No channel name at index ' + i);
      } else if(typeof channel.name !== 'string') {
        this.errorMessages.push(`Channel name at index ${i} was not a string.`);
      }

      if(channel.description && typeof channel.description !== 'string') {
        this.errorMessages.push(`Channel description for ${channel.name} was not a string.`);
      }

      if(channel.units && typeof channel.units !== 'string') {
        this.errorMessages.push(`Channel unit for ${channel.name} was not a string.`);
      }

      if(!channel.data || !channel.data.length){
        this.errorMessages.push(`No channel data for ${channel.name}.`);
      } else {
        if(i === 0){
          expectedChannelLength = channel.data.length;
        } else {
          if(channel.data.length !== expectedChannelLength){
            this.errorMessages.push(`Channel data for ${channel.name} was of length ${channel.data.length}, which does not match first channel length of ${expectedChannelLength}.`);
          }
        }

        for(let pointIndex = 0; pointIndex < channel.data.length; ++pointIndex) {
          if(typeof channel.data[pointIndex] !== 'number') {
            this.errorMessages.push(`Channel at for ${channel.name} has non-numeric data at index ${pointIndex} (${channel.data[pointIndex]}).`);
            break;
          }
        }
      }
    }
  }
}

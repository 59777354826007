
import { SimType } from '../../sim-type';
import { ChannelNameStyle } from './channel-name-style';

/**
 * A map of generic channel names to fully qualified channel names.
 */
export interface ChannelNameMap {
  [channelName: string]: string;
}

/**
 * Maps generic channel names to fully qualified channel names.
 * In the event of conflicts between sim types, higher priority sim types take precedence.
 */
export class ChannelNameMapBuilder {

  /**
   * The map of generic channel names to fully qualified channel names and their sim type.
   */
  private items: { [genericName: string]: { fullName: string; simType: SimType } } = {};

  /**
   * Creates a new ChannelNameMapBuilder.
   * @param channelNameStyle The channel name style.
   * @param simTypes The sim types in this study.
   */
  constructor(
    private channelNameStyle: ChannelNameStyle,
    private simTypes: ReadonlyArray<SimType>) {
  }

  /**
   * Adds a generic channel name to the map.
   * @param genericName The generic channel name.
   * @param fullName The fully qualified channel name.
   * @param simType The sim type.
   */
  public add(genericName: string, fullName: string, simType: SimType) {

    // If the channel name style is not fully qualified, then the full name won't actually be a fully qualified name.
    if (this.channelNameStyle === ChannelNameStyle.FullyQualified) {
      let existing = this.items[genericName];

      // Higher priority sim types take preference when there are name conflicts.
      if (!existing || this.simTypes.indexOf(simType) < this.simTypes.indexOf(existing.simType)) {
        this.items[genericName] = {
          fullName,
          simType
        };
      }
    }
  }

  /**
   * Gets the map of generic channel names to fully qualified channel names.
   * @returns The map of generic channel names to fully qualified channel names.
   */
  public getMap(): ChannelNameMap {
    let result: ChannelNameMap = {};
    for (let key in this.items) {
      if (!this.items.hasOwnProperty(key)) {
        continue;
      }
      result[key] = this.items[key].fullName;
    }
    return result;
  }
}

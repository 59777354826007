<div class="container-fluid">
  <cs-error-message [message]="errorMessage"></cs-error-message>
</div>

@if (worksheet) {
  <table class="table worksheet-table wrapping-worksheet-table"
    (mousedown)="onTableClicked($event)"
    (contextmenu)="onTableContextMenu($event)"
    (keydown.control.c)="onClipboardKey($event)"
    (keydown.meta.c)="onClipboardKey($event)"
    (keydown.control.x)="onClipboardKey($event)"
    (keydown.meta.x)="onClipboardKey($event)"
    (keydown.control.v)="onClipboardKey($event)"
    (keydown.meta.v)="onClipboardKey($event)"
    (keydown.control.alt.v)="onClipboardKey($event)"
    (keydown.meta.alt.v)="onClipboardKey($event)"
    (keydown.control.z)="onUndoOrRedo(true)"
    (keydown.meta.z)="onUndoOrRedo(true)"
    (keydown.control.y)="onUndoOrRedo(false)"
    (keydown.meta.y)="onUndoOrRedo(false)"
    (keydown.enter)="onTableKey(KeyboardAction.enter)"
    (keydown.delete)="onTableKey(KeyboardAction.delete)"
    (keydown.arrowUp)="onTableNavigateKey($event, NavigateAction.up)"
    (keydown.arrowDown)="onTableNavigateKey($event, NavigateAction.down)"
    (keydown.arrowLeft)="onTableNavigateKey($event, NavigateAction.left)"
    (keydown.arrowRight)="onTableNavigateKey($event, NavigateAction.right)"
    (dblclick)="onTableDoubleClick($event)"
    (focusin)="onTableFocus($event)"
    (keyup.shift)="onRangeSelectionEnd()"
    ><!--(mousemove)="onTableMouseMove($event)"-->
    <thead>
      <tr>
        @for (column of worksheet.columns; track columnTrackById($index, column)) {
          <th
            class="worksheet-column-heading">
            {{column.name}}
          </th>
        }
      </tr>
    </thead>
    <tbody>
      @for (row of worksheet.rows; track row; let i = $index) {
        <tr
          class="worksheet-row worksheet-row-{{i}}"
          cs-worksheet-row
          [row]="row"
          [rowIndex]="i"
          [isWorksheetInDock]="isWorksheetInDock">
        </tr>
      }
    </tbody>
  </table>
}

import { getSafePath } from '../get-safe-path';
import { PerformPacejkaCanopyConversionFactory } from '../../../pacejka-canopy-conversion/perform-pacejka-canopy-conversion';
import { simVersionToNumber } from '../../../../visualizations/sim-version-to-number';
import { ExtensionButtons } from './extension-buttons';
import { AddButtonsHandler } from './add-buttons-handler';
import { EditorNodeUtilities } from './editor-node-utilities';
import { UpdateDelegate } from './update-delegate';
import { ExtensionButtonCategories } from './extension-button-categories';
import { Injectable } from '@angular/core';


@Injectable()
export class AddPacejkaCanopyConverterButtons implements AddButtonsHandler {
  constructor(
    private readonly performPacejkaCanopyConversionFactory: PerformPacejkaCanopyConversionFactory) {
  }

  public get category(): ExtensionButtonCategories {
    return ExtensionButtonCategories.tirToCanopy;
  }

  public execute(utils: EditorNodeUtilities, subTreeControls: any, globalUpdateDelegate: UpdateDelegate): ExtensionButtons {
    let fullPath = utils.self.path;
    let safePath = getSafePath(fullPath);

    let numericSimVersion = simVersionToNumber(utils.simVersion);
    if (!(numericSimVersion >= 3102 && safePath === 'tyres')) {
      return undefined;
    }

    let tirToCanopyButton = utils.self.getButton('Canopy', 'tirtocanopy', 'Convert from Pacejka to Canopy');
    tirToCanopyButton.className += ' btn-icon tir-to-canopy-button';

    let setTirToCanopyButtonEnablement = () => {
      if (!utils.self.value.name.startsWith('Pacejka')) {
        tirToCanopyButton.classList.add('sub-tree-button-hidden');
      }
    };

    setTirToCanopyButtonEnablement();

    utils.addButton(subTreeControls, tirToCanopyButton, async () => {
      let performPacejkaCanopyConversion = this.performPacejkaCanopyConversionFactory.create(
        utils.simVersion, utils.self.jsoneditor);
      await performPacejkaCanopyConversion.execute();
    }, globalUpdateDelegate);

    return new ExtensionButtons(
      () => setTirToCanopyButtonEnablement(),
      {
        tirToCanopy: tirToCanopyButton
      });
  }
}

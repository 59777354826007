import { IViewerChannelData, ViewerChannelData } from './viewer-channel-data';

/**
 * A map of viewer channel data that guarantees that every channel name has an associated viewer channel data object.
 */
export class ViewerChannelDataMap {

  constructor(private readonly content: ViewerChannelDataMapContent) {
  }

  /**
   * Gets the viewer channel data for the given channel name.
   * If the channel name is not found, a default ViewerChannelData is returned with no data values.
   * @param channelName The channel name.
   * @returns The viewer channel data.
   */
  public get(channelName: string): IViewerChannelData {
    const result = this.content[channelName];
    if (!result) {
      // The data sources should return an object for every channel that was requested.
      // The object can contain undefined data, but the object itself cannot be undefined.
      // If we are throwing this error it either means the channel name was not in the list
      // of channels originally requested, or the data source did not return a valid set of channels.
      // throw new Error('Viewer channel data map did not contain channel: ' + channelName);

      // UPDATE: We are returning an empty data object as this is occasionally occurring.
      // TODO: Track down how we end up in here.
      return this.getDefault(channelName);
    }

    return result;
  }

  /**
   * Sets the viewer channel data for the given channel name.
   * @param channelName The channel name.
   * @param viewerChannelData The viewer channel data.
   */
  public set(channelName: string, viewerChannelData: IViewerChannelData): void {
    if (!viewerChannelData) {
      throw new Error('Cannot set viewer channel data to undefined: ' + channelName);
    }

    this.content[channelName] = viewerChannelData;
  }

  /**
   * Gets all the channel data.
   * @param channelName The channel name.
   * @returns The viewer channel data.
   */
  public getContent(): ViewerChannelDataMapContent {
    return {
      ...this.content
    };
  }

  /**
   * Gets a default viewer channel data object.
   * @param channelName The channel name.
   * @returns The default viewer channel data object
   */
  public getDefault(channelName: string): IViewerChannelData {
    return new ViewerChannelData(channelName, channelName, channelName, channelName, '', '()', false, undefined, undefined);
  }
}

/**
 * The content of a viewer channel data map.
 */
export interface ViewerChannelDataMapContent {

  /**
   * The viewer channel data objects.
   */
  [channelName: string]: IViewerChannelData;
}

@if (configDownloadTypes.length >= 1) {
  <span class="button-menu-hover-target">
    <button type="button" id="export-config-{{configDownloadTypes[0].name}}-hover-button" class="btn btn-smart btn-secondary">
      <i class="fa fa-download"></i>{{configDownloadTypes[0].displayName}}
    </button>
    <span class="button-menu-hovering-area">
      @for (download of configDownloadTypes; track download) {
        <button
          type="button"
          [title]="download.description"
          class="btn btn-smart btn-secondary"
          id="export-config-{{download.name}}-button"
          (click)="download.execute()">
          <i class="fa fa-download"></i>{{download.displayName}}
        </button>
      }
    </span>
  </span>
}

import { CanopyJson } from '../../../../common/canopy-json.service';
import { ConfirmationDialog } from '../../../../common/dialogs/confirmation-dialog.service';
import { simVersionToNumber } from '../../../../visualizations/sim-version-to-number';
import { PromptDialog } from '../../../../common/dialogs/prompt-dialog.service';
import { ExtensionButtons } from './extension-buttons';
import { AddButtonsHandler } from './add-buttons-handler';
import { EditorNodeUtilities } from './editor-node-utilities';
import { UpdateDelegate } from './update-delegate';
import { ExtensionButtonCategories } from './extension-button-categories';
import { Injectable } from '@angular/core';

// Also change this in the canopy-api.
export const FirstSimVersionSupportingEncryptionWithChannelWhitelist = 4804;

@Injectable()
export class AddEncryptionButtons implements AddButtonsHandler {

  constructor(
    private readonly json: CanopyJson,
    private readonly promptDialog: PromptDialog,
    private readonly confirmationDialog: ConfirmationDialog) {
  }

  public get category(): ExtensionButtonCategories {
    return ExtensionButtonCategories.encrypt;
  }

  public execute(utils: EditorNodeUtilities, subTreeControls: any, globalUpdateDelegate: UpdateDelegate): ExtensionButtons {
    if (!utils.hasEncryptedOneOfBranch()) {
      return undefined;
    }

    let encryptButton = utils.self.getButton('', 'encrypt', 'Encrypt');
    encryptButton.className += ' btn-icon sub-tree-encrypt-button';
    let encryptWithMetadataButton = utils.self.getButton('', 'encryptwithmetadata', 'Encrypt with Excluded Channels');
    encryptWithMetadataButton.className += ' btn-icon sub-tree-encrypt-button';
    let decryptButton = utils.self.getButton('', 'decrypt', 'Decrypt');
    decryptButton.className += ' btn-icon sub-tree-decrypt-button';

    let setEncryptButtonEnablement = () => {
      let setEnablement = (button: any, enabled: boolean) => {
        if (enabled) {
          button.classList.remove('sub-tree-button-hidden');
        } else {
          button.classList.add('sub-tree-button-hidden');
        }
      };

      setEnablement(encryptButton, utils.canEncrypt());
      setEnablement(encryptWithMetadataButton, utils.canEncrypt());
      setEnablement(decryptButton, utils.canDecrypt());
    };
    //
    // let encryptButtonFinallyDelegate = () => {
    //   setEncryptButtonEnablement();
    //   globalUpdateDelegate();
    // };
    // editorNodeWrapper.updateMethods.push(encryptButtonFinallyDelegate);
    setEncryptButtonEnablement();

    let performEncryptionChecks = async (): Promise<boolean> => {
      if (utils.self.jsoneditor.validate().length) {
        await utils.flashButton(encryptButton);
        await this.confirmationDialog.show('Please ensure the entire config is valid before encrypting.', 'Error', 'OK', 'OK');
        return false;
      }

      return true;
    };

    let performEncryption = async (channelWhitelist: ReadonlyArray<string> | undefined) => {
      let config = utils.self.getValue();
      let result = await utils.configSubTreeRepository.encrypt(
        config,
        utils.subTreeData ? utils.subTreeData.config.name : undefined,
        channelWhitelist);

      if (result) {
        utils.subTreeData = undefined;
        utils.setValueOrParentValue(result);
      }
    };

    utils.addButton(subTreeControls, encryptButton, async () => {
      if (!await performEncryptionChecks()) {
        return;
      }

      await performEncryption(undefined);
    }, globalUpdateDelegate);


    if (simVersionToNumber(utils.simVersion) >= FirstSimVersionSupportingEncryptionWithChannelWhitelist) {
      utils.addButton(subTreeControls, encryptWithMetadataButton, async () => {
        if (!await performEncryptionChecks()) {
          return;
        }

        let channelWhitelistString = await this.promptDialog.show<string>(
          'Enter a comma list of channels which should not be hidden by this encrypted component.',
          'Channel Whitelist',
          undefined, undefined, undefined, undefined, undefined);

        if (channelWhitelistString === null || channelWhitelistString === undefined) {
          return;
        }

        let channelWhitelist: ReadonlyArray<string> | undefined;
        try {
          channelWhitelist = channelWhitelistString.split(',').map(v => v.trim());
        } catch {
          await this.confirmationDialog.show('Failed to parse channel list.', 'Error', 'OK', 'OK');
        }

        await performEncryption(channelWhitelist);
      }, globalUpdateDelegate);
    }

    utils.addButton(subTreeControls, decryptButton, async () => {
      let config = utils.self.getValue();
      let result;
      let decryptFailed = false;
      try {
        result = await utils.configSubTreeRepository.decrypt(config);
      } catch (error) {
        decryptFailed = true;
        utils.handleError(error);
      }

      if (result !== null && result !== undefined) {
        if (this.json.equals(result, config)) {
          // Can't decrypt.
          decryptFailed = true;
        } else {
          utils.subTreeData = undefined;
          utils.setValueOrParentValue(result);
        }
      }

      if (decryptFailed) {
        await utils.flashButton(decryptButton);
      }
    }, globalUpdateDelegate);

    return new ExtensionButtons(
      () => setEncryptButtonEnablement(),
      {
        encrypt: encryptButton,
        decrypt: decryptButton,
      }
    );
  }
}

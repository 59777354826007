import {Component} from '@angular/core';
import { TELEMETRY_DOCUMENTATION_URL } from '../../../common/constants';
import {SignificantSimVersions} from '../../../common/significant-sim-versions.service';

@Component({
  selector: 'cs-telemetry-help',
  templateUrl: './telemetry-help.component.html',
  styleUrls: ['./telemetry-help.component.scss']
})
export class TelemetryHelpComponent {
  public TELEMETRY_DOCUMENTATION_URL: string = TELEMETRY_DOCUMENTATION_URL;
  constructor(
    public readonly significantSimVersions: SignificantSimVersions){}
}

import {Component} from '@angular/core';
import {ConfigType, TelemetryConfigType} from '../config-types';
import {ListFilterData} from '../../../../generated/api-stubs';
import {CONFIG_TYPE_DATA_KEY} from '../config-types';
import {DefaultConfigSummary} from '../view-default-configs/view-default-configs.component';
import {FilterUtilities} from '../../list-filtering/filter-utilities';
import {ConfigSummary} from '../view-configs/view-configs.component';
import {ActivatedRoute} from '@angular/router';
import {InferableRouteParams} from '../../../common/inferable-route-params.service';
import {SignificantSimVersions} from '../../../common/significant-sim-versions.service';
import { AuthenticationService, UserData } from '../../../identity/state/authentication.service';

@Component({
  templateUrl: './view-configs.page.html'
})
export class ViewConfigsPage {
  public configType: ConfigType;
  public tenantId: string;
  public userId: string;

  public userData: UserData;
  public initialFilter: ListFilterData;
  public errorMessage: string;

  public telemetryConfigType = TelemetryConfigType;
  public showTelemetryConfigs: boolean = false;

  constructor(
    filterUtilities: FilterUtilities,
    inferableRouteParams: InferableRouteParams,
    route: ActivatedRoute,
    private readonly authenticationService: AuthenticationService,
    private readonly significantSimVersions: SignificantSimVersions) {

    this.configType = route.snapshot.data[CONFIG_TYPE_DATA_KEY];
    this.tenantId = inferableRouteParams.getTenantId(route);
    this.userId = inferableRouteParams.getUserId(route);

    this.initialFilter = filterUtilities.getInitialFilter();

    this.userData = this.authenticationService.userDataSnapshot;
  }

  public filterChanged(newFilter: ListFilterData){
  }

  public navigationUrlTree(config: ConfigSummary) {
      return ['/configs', this.configType.pluralKey, config.tenantId, config.documentId, 'edit'];
  }

  public defaultNavigationUrlTree(config: DefaultConfigSummary) {
    return ['/default-configs', config.simVersion, this.configType.pluralKey, config.name, 'edit'];
  }

  public get showTelemetrySimulations() {
    return this.configType.singularKey === this.telemetryConfigType.singularKey
      && this.significantSimVersions.isAfterTelemetrySimulation;
  }

  public toggleShowTelemetryConfigs(): void {
    this.showTelemetryConfigs = !this.showTelemetryConfigs;
  }

  public get showConfigs(): boolean {
    return this.configType.singularKey !== this.telemetryConfigType.singularKey || this.showTelemetryConfigs;
  }
}

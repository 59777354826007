import { LoadedVectorMetadataResult, LoadVectorMetadataMap } from './load-vector-metadata-map';
import { SimType } from '../../sim-type';
import { StudyJob } from '../../study-job';

/**
 * A cache for the loaded vector metadata.
 */
export class StudyJobSourceLoaderCache {
  private cache?: Promise<LoadedVectorMetadataResult>;

  /**
   * Creates a new cache for the loaded vector metadata.
   * @param studyJob The study job.
   * @param simType The simulation type.
   * @param loadVectorMetadataMap The service for loading vector metadata.
   */
  constructor(
    private studyJob: StudyJob,
    private simType: SimType,
    private loadVectorMetadataMap: LoadVectorMetadataMap) {
  }

  /**
   * Gets the loaded vector metadata. This will populate the cache if it is not already populated.
   * @returns The loaded vector metadata.
   */
  public get(): Promise<LoadedVectorMetadataResult> {
    if (!this.cache) {
      this.cache = this.loadVectorMetadataMap.execute(this.studyJob.studyId, this.studyJob.jobIndex, this.simType);
    }

    // Note: While documenting I noticed this looks like the Promise.resolve is unnecessary,
    // as the cache is already a promise.
    return Promise.resolve(this.cache);
  }
}

<div class="container-fluid">
  @if (!isError) {
    <div class="alert alert-info">
      <h3>Message from Support Portal:</h3>
      <p>{{zendeskMessage}}</p>
    </div>
  }
  @if (isError) {
    <div class="alert alert-danger">
      Support portal reported an error. This has been logged.
    </div>
  }
</div>

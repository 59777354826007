import {ViewConfigOrStudyCommand} from './view-config-or-study-command';
import {TakeOwnershipCommand} from './take-ownership-command';
import {SetStudyTypeCommand} from './set-study-type-command';
import {LoadConfigCommand} from './load-config-command';
import {ImportTelemetryCommand} from './import-telemetry-command';
import {SetTelemetryJobIndexCommand} from './set-telemetry-job-index-command';
import {ClearSelectedCommand} from './clear-selected-command';
import {DuplicateInPlaceCommand} from './duplicate-in-place-command';
import {CutCommand} from './cut-command';
import {CopyCommand} from './copy-command';
import {PasteCommand} from './paste-command';
import {PasteDuplicateCommand} from './paste-duplicate-command';
import {CompareToStudyInputCommand} from './compare-to-study-input-command';
import {StageSnapshotCommand} from './stage-snapshot-command';
import {CompareToStagedCommand} from './compare-to-staged-command';
import {LoadStudyCommand} from './load-study-command';
import {RunStudyCommand} from './run-study-command';
import {ExtractStudyInputsCommand} from './extract-study-inputs-command';
import {CompareResultsCommand} from './compare-results-command';
import {RenameRowCommand} from './rename-row-command';
import {CopyRowDownCommand} from './copy-row-down-command';
import {InsertRowCommand} from './insert-row-command';
import {AppendRowCommand} from './append-row-command';
import {RemoveRowCommand} from './remove-row-command';
import {CompareSelectedCommand} from './compare-selected-command';
import {CompareToSomethingCommand} from './compare-to-something-command';
import { ImportStagedCommand } from './import-staged/import-staged-command';
import { DownloadResultsCommand } from './download-results-command';
import { DownloadConfigCommandService as DownloadConfigCommand } from './download-config-command';

/**
 * The set of all worksheet commands.
 */
export const ALL_WORKSHEET_COMMAND_FACTORIES = [
  ViewConfigOrStudyCommand,
  TakeOwnershipCommand,
  SetStudyTypeCommand,
  LoadConfigCommand,
  ImportTelemetryCommand,
  SetTelemetryJobIndexCommand,
  ClearSelectedCommand,
  DuplicateInPlaceCommand,
  CutCommand,
  CopyCommand,
  PasteCommand,
  PasteDuplicateCommand,
  CompareToStudyInputCommand,
  StageSnapshotCommand,
  CompareToStagedCommand,
  LoadStudyCommand,
  RunStudyCommand,
  ExtractStudyInputsCommand,
  CompareResultsCommand,
  DownloadResultsCommand,
  DownloadConfigCommand,
  RenameRowCommand,
  CopyRowDownCommand,
  InsertRowCommand,
  AppendRowCommand,
  RemoveRowCommand,
  CompareSelectedCommand,
  CompareToSomethingCommand,
  ImportStagedCommand,
];

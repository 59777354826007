import { LinePlotViewer } from './line-plot-viewer';
import { SiteHooks } from '../../site-hooks';
import { ChannelNameStyle } from '../channel-data-loaders/channel-name-style';
import { LineViewerGetInterpolatedChannelValueAtDomainValue } from './data-renderer';
import { IPopulatedMultiPlotLayout } from '../data-pipeline/types/i-populated-multi-plot-layout';
import { IMultiPlotLayout } from '../data-pipeline/types/i-multi-plot-layout';
import { SharedState } from '../shared-state';
import { Subscription } from 'rxjs';

import { DimensionCoordinatesSlider } from './dimension-coordinates-slider';
import {
  EDIT_TYPE_ROWS,
  upgradeMultiPlotViewerLayout
} from '../multi-plot-viewer-base/multi-plot-viewer-base';
import { IEditChannelsType } from '../../navigation-station/navigation-station-viewer';
import { DataPipeline } from '../data-pipeline/data-pipeline';

export const LINE_MULTI_PLOT_VIEWER_TYPE = 'lineMultiPlotViewer';

/**
 * A line plot viewer which contains a dimension slider, as used in the dimension
 * plots under the parallel coordinates plot viewer.
 */
export class DimensionLinePlotViewer extends LinePlotViewer {

  /**
   * The dimension slider for the line plot viewer.
   */
  private slider?: DimensionCoordinatesSlider;

  /**
   * The subscriptions for the dimension slider.
   */
  private dimensionSubscriptions?: Subscription;

  /**
   * Create a new instance of the line plot viewer with a dimension slider.
   * @param dimensionIndex The dimension index.
   * @param xDomainNames The x domain names.
   * @param showDimensionSlider Whether to show the dimension slider.
   * @param primaryDomainName The primary domain name.
   * @param layout The chart layout.
   * @param channelNameStyle The channel name style.
   * @param dataPipeline The data pipeline.
   * @param sharedState The shared state.
   * @param siteHooks The site hooks.
   * @param interpolator The interpolator to use for the data renderer.
   */
  constructor(
    private readonly dimensionIndex: number,
    private readonly xDomainNames: ReadonlyArray<string>,
    private readonly showDimensionSlider: boolean,
    primaryDomainName: string,
    layout: IMultiPlotLayout,
    channelNameStyle: ChannelNameStyle,
    dataPipeline: DataPipeline,
    sharedState: SharedState,
    siteHooks: SiteHooks,
    interpolator: LineViewerGetInterpolatedChannelValueAtDomainValue) {
    super(
      primaryDomainName,
      layout,
      channelNameStyle,
      dataPipeline,
      sharedState,
      siteHooks,
      interpolator);
  }

  /**
   * Create a new instance of the dimension line plot viewer.
   * @param dimensionIndex The dimension index.
   * @param xDomainNames The x domain names.
   * @param showDimensionSlider Whether to show the dimension slider.
   * @param primaryDomainName The primary domain name.
   * @param layout The chart layout.
   * @param channelNameStyle The channel name style.
   * @param sharedState The shared state.
   * @param siteHooks The site hooks.
   * @returns
   */
  public static createDimensionLinePlotViewer(
    dimensionIndex: number,
    xDomainNames: ReadonlyArray<string>,
    showDimensionSlider: boolean,
    primaryDomainName: string,
    layout: IMultiPlotLayout,
    channelNameStyle: ChannelNameStyle,
    sharedState: SharedState,
    siteHooks: SiteHooks): DimensionLinePlotViewer {

    let interpolator = new LineViewerGetInterpolatedChannelValueAtDomainValue();
    return new DimensionLinePlotViewer(
      dimensionIndex,
      xDomainNames,
      showDimensionSlider,
      primaryDomainName,
      layout,
      channelNameStyle,
      DataPipeline.create(primaryDomainName, siteHooks, sharedState.sourceLoaderSet, channelNameStyle, interpolator),
      sharedState,
      siteHooks,
      interpolator);
  }

  /**
   * Load the viewer from the layout.
   * @param inputLayout The input layout.
   * @returns The result of the super class implementation.
   */
  protected async loadFromLayout(inputLayout?: IMultiPlotLayout) {
    if (inputLayout) {
      // We only allow the dimension columns, so always overwrite.
      upgradeMultiPlotViewerLayout(inputLayout);
      inputLayout.columns = [
        {
          channels: this.xDomainNames.map(v => ({ name: v })),
          relativeSize: 1
        }
      ];
    }

    // Call the super class implementation.
    return super.loadFromLayout(inputLayout);
  }

  /**
   * Called when the viewer is built.
   */
  protected onBuilt() {
    // Call the super class implementation.
    super.onBuilt();

    if (!this.svg) {
      return;
    }

    // Build the dimension slider.
    if (this.showDimensionSlider) {
      this.slider = new DimensionCoordinatesSlider(this.sharedState, this.dimensionIndex, this.xDomainNames, this.svg);
      this.dimensionSubscriptions = this.slider.sourcesChanged.subscribe(() => this.handleSourcesChanged());
      this.slider.build();
    }
  }

  /**
   * Dispose of the viewer.
   */
  public dispose() {
    // Call the super class implementation.
    super.dispose();

    // Dispose the dimension slider.
    if (this.slider) {
      this.slider.dispose();
    }

    // Dispose the subscriptions.
    if (this.dimensionSubscriptions) {
      this.dimensionSubscriptions.unsubscribe();
    }
  }

  /**
   * Called when the data has been set.
   */
  protected onDataSet() {
    // Call the super class implementation.
    super.onDataSet();

    if (!this.settings) {
      return;
    }

    // Set the data for the dimension slider.
    if (this.slider) {
      this.slider.setData(this.layout as IPopulatedMultiPlotLayout, this.settings);
    }
  }

  /**
   * Called when the viewer has been rendered.
   */
  protected onRendered() {
    // Call the super class implementation.
    super.onRendered();

    // Render the dimension slider.
    if (this.slider) {
      this.slider.render();
    }
  }

  /**
   * Get the valid edit operations for this viewer.
   * As the X axis is fixed, we only allow editing the rows.
   * @returns The valid edit operations.
   */
  public getEditChannelsTypes(): IEditChannelsType[] {
    return [
      {
        name: 'Edit Rows',
        icon: 'navicon',
        id: EDIT_TYPE_ROWS
      }
    ];
  }
}

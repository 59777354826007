import {RuleError} from './rule-error';
import {ExecutionResult} from './execution-result';
import {GetChannelsResult} from './get-channels-result';
import {RuleInstanceResult} from './rule-instance-result';
import {SimChannel} from './sim-channel';
import {RuleContextBase} from './rule-context-base';
import {RuleLogger} from './rule-logger';
import {Registry} from './registry';

export class RulesOrchestrator {
  constructor(
    private readonly registry: Registry) {
  }

  public execute(context: RuleContextBase): ExecutionResult {
    let results: RuleInstanceResult[] = [];
    let errors: RuleError[] = [];
    let completed: string[] = [];
    let skipped: string[] = [];
    for (let ruleClass of this.registry.rules) {
      let ruleName = ruleClass.name;
      try {
        let rule = new ruleClass();
        ruleName = rule.name;
        if (rule.canExecute(context)) {
          let result = new RuleLogger();
          rule.execute(context, result);
          results.push(...result.logged.map(v => new RuleInstanceResult(ruleName, v.level, v.message, v.location, v.priority)));
          completed.push(ruleName);
        } else {
          skipped.push(ruleName);
        }
      } catch (error) {
        errors.push(new RuleError(ruleName, error));
      }
    }
    return new ExecutionResult(completed, results, errors, skipped);
  }

  public getChannels(context: RuleContextBase): GetChannelsResult {
    let channels = new Set<SimChannel>();
    let errors: RuleError[] = [];
    let skipped: string[] = [];
    for (let ruleClass of this.registry.rules) {
      let ruleName = ruleClass.name;
      try {
        let rule = new ruleClass();
        ruleName = rule.name;
        if (rule.canExecute(context)) {
          let ruleChannels = rule.getChannels(context);
          for (let channel of ruleChannels || []) {
            channels.add(channel);
          }
        } else {
          skipped.push(ruleName);
        }
      } catch (error) {
        errors.push(new RuleError(ruleName, error));
      }
    }

    return new GetChannelsResult(Array.from(channels), errors, skipped);
  }
}

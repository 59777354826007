<div id="all-tenants-study-statistics">
  <div class="container-fluid">
    <p>
      <button class="btn btn-smart btn-secondary" (click)="loadNext()" [disabled]="!statistics || !canLoadMore">{{canLoadMore ? 'More' : 'Please wait...'}}</button>
    </p>
    <cs-error-message [message]="errorMessage"></cs-error-message>
    @if (!statistics) {
      <cs-loader class="loader-centered"></cs-loader>
    }
  </div>
  @if (statistics) {
    <div id="navigation-station"></div>
  }
</div>

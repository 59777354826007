export function getExecutionTimeString(time: number): string {
  if(!time){
    return '';
  }
  
  let minutes = Math.floor(time / 60);
  let seconds = time - (minutes * 60);
  let secondsString = seconds.toFixed(2);
  if(seconds < 10){
    secondsString = '0' + secondsString;
  }
  return minutes + ':' + secondsString;
}

import {EventEmitter, Injectable} from '@angular/core';
import {Observable} from 'rxjs';

/**
 * A service that emits an event when a document is updated.
 */
@Injectable()
export class DocumentUpdatedEventService {

  /**
   * The event emitter.
   */
  private eventEmitter: EventEmitter<UpdatedDocument> = new EventEmitter<UpdatedDocument>();

  /**
   * Gets an observable that emits when a document is updated.
   */
  public get documentUpdated(): Observable<UpdatedDocument> {
    return this.eventEmitter;
  }

  /**
   * Causes the service to emit an event.
   * @param tenantId The tenant ID.
   * @param documentId The document ID.
   */
  public emit(tenantId: string, documentId: string) {
    this.eventEmitter.emit(new UpdatedDocument(tenantId, documentId));
  }
}

/**
 * Represents a document that has been updated.
 */
export class UpdatedDocument {

  /**
   * Creates an instance of UpdatedDocument.
   * @param tenantId The tenant ID.
   * @param documentId The document ID.
   */
  constructor(
    public readonly tenantId: string,
    public readonly documentId: string){
  }
}

import {API_BASE_URL} from '../generated/api-base-url';

class ApiUrlImpl {
  public hardCodedApiUrl: string;
  public getHostname: () => string;

  private _value: string | undefined;

  public get value(): string {
    return this._value || this.populateValue();
  }

  public reset() {
    this._value = undefined;
    this.hardCodedApiUrl = API_BASE_URL;
    this.getHostname = () => window.location.hostname;
  }

  private populateValue(): string {
    if(this.hardCodedApiUrl){
      this._value = this.hardCodedApiUrl;
    } else{
      let hostname = this.getHostname();
      let apiHostname = hostname
        .replace('portal', 'api')
        .replace('canopy-web', 'canopy-api');
      this._value = 'https://' + apiHostname + '/';
    }

    return this._value;
  }
}

export const ApiUrl = new ApiUrlImpl();

ApiUrl.reset();

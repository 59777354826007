import {GetStudyQueryResult, StudyStub} from '../../generated/api-stubs';
import {Injectable} from '@angular/core';
import {StudyEvent, StudyNotifier} from '../notifications/study-notifier.service';
import {defer} from '../common/promise-defer';
import {isStudyComplete} from '../common/is-study-complete';


/**
 * A service containing a variety of methods for loading studies.
 */
@Injectable()
export class LoadStudy {

  /**
   * Creates an instance of LoadStudy.
   * @param studyStub The study stub.
   * @param studyNotifier The service which notifies on study building and running progress.
   */
  constructor(
    private readonly studyStub: StudyStub,
    private readonly studyNotifier: StudyNotifier){
  }

  /**
   * Tries to load a study, returning undefined if access is forbidden for this user.
   * @param tenantId The tenant ID.
   * @param studyId The study ID.
   * @param simVersion The sim version.
   * @returns The study query result, or undefined if access the study is forbidden.
   */
  public async tryLoadIfNotForbidden(
    tenantId: string,
    studyId: string,
    simVersion: string): Promise<GetStudyQueryResult> {
    try {
      return await this.load(tenantId, studyId, simVersion);
    } catch(error){
      if (error.isUnauthorizedError
        || error.isFromApi && error.response.status === 404) {
        return undefined;
      } else {
        throw error;
      }
    }
  }

  /**
   * Loads a study.
   * @param tenantId The tenant ID.
   * @param studyId The study ID.
   * @param simVersion The sim version.
   * @returns The study query result.
   */
  public async load(
    tenantId: string,
    studyId: string,
    simVersion: string): Promise<GetStudyQueryResult> {
    return await this.studyStub.getStudy(tenantId, studyId, simVersion);
  }

  /**
   * Loads the metadata for a study.
   * @param tenantId The tenant ID.
   * @param studyId The study ID.
   * @returns The study query result.
   */
  public async loadMetadata(
    tenantId: string,
    studyId: string): Promise<GetStudyQueryResult> {
    return await this.studyStub.getStudyMetadata(tenantId, studyId);
  }

  /**
   * Loads a study, waiting for it to complete.
   * @param tenantId The tenant ID.
   * @param studyId The study ID.
   * @param simVersion The sim version.
   * @returns The study query result.
   */
  public async loadCompleted(
    tenantId: string,
    studyId: string,
    simVersion: string): Promise<GetStudyQueryResult>{

    // Subscribe to the study event notifier before requesting the study metadata.
    let deferred = defer();
    let subscription = this.studyNotifier.studyCompleted.subscribe((event: StudyEvent) => {
      if(event.studyId === studyId){
        deferred.resolve();
      }
    });

    try{
      // Load the study metadata.
      let studyResult = await this.loadMetadata(tenantId, studyId);
      const isComplete = isStudyComplete(studyResult.study.data);
      if(!isComplete){
        // If the study isn't complete, wait for the event.
        await deferred.promise;
      }
    } finally{
      // Unsubscribe from the event notifier.
      subscription.unsubscribe();
    }

    // Load the full study.
    const studyResult = await this.load(tenantId, studyId, simVersion);
    if(!isStudyComplete(studyResult.study.data)){
      // If the study isn't complete, log a warning. This shouldn't actually happen.
      console.warn('Study was not complete.');
    }

    return studyResult;
  }
}

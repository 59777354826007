import {RuleContextBase} from './rule-context-base';
import {ConfigContext} from './config-context';

export class SimContext extends RuleContextBase {
  public get simType(): string {
    return this.type;
  }

  public getConfigContext(configType: string): ConfigContext | undefined {
    if (!this.data || !this.data.simConfig) {
      return undefined;
    }

    const data = this.data.simConfig[configType];
    if(!data){
      return undefined;
    }

    return new ConfigContext(configType, data);
  }

  public getConfigTypes(): string[] {
    if (!this.data || !this.data.simConfig) {
      return [];
    }

    return Object.keys(this.data.simConfig);
  }
}

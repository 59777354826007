import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { ErrorMessageService } from '../../services/error-message/error-message.service';

@Component({
  selector: 'cs-form-error-message',
  templateUrl: './form-error-message.component.html'
})
export class FormErrorMessageComponent implements OnInit, OnDestroy {

  public message: string;

  private _isSubscribed = false;

  public get show(): boolean {
    return !!this.message;
  }

  constructor(private _errorMessageService: ErrorMessageService) {
  }

  public ngOnInit(): void {
    this._isSubscribed = true;
    this._errorMessageService.message
      .pipe(
        takeWhile(_ => this._isSubscribed)
      )
      .subscribe(message => this.message = message);
  }

  public ngOnDestroy(): void {
    this._isSubscribed = false;
    this._errorMessageService.clearErrorMessage();
  }

}

import {Injectable} from '@angular/core';
import {LoadConfigFromReference} from '../../../../worksheets/load-config-from-reference';
import {ConfigReference, DocumentSubType} from '../../../../../generated/api-stubs';
import {StudyInput} from '../../../../worksheets/study-input';
import {IMaterializedConfigLoader} from '../config-or-config-loader';

@Injectable()
export class ConfigReferenceLoaderFactory {
  constructor(
    private readonly loadConfigFromReference: LoadConfigFromReference) {
  }

  public create(
    configType: DocumentSubType,
    reference: ConfigReference,
    simVersion: string | undefined) {
    return new ConfigReferenceLoader(configType, reference, simVersion, this.loadConfigFromReference);
  }
}

export class ConfigReferenceLoader implements IMaterializedConfigLoader {
  constructor(
    private readonly configType: DocumentSubType,
    private readonly reference: ConfigReference,
    private readonly simVersion: string | undefined,
    private readonly loadConfigFromReference: LoadConfigFromReference) {
  }

  execute(): Promise<StudyInput> {
    return this.loadConfigFromReference.execute(
      this.configType,
      this.reference,
      this.simVersion);
  }
}

import {Injectable} from '@angular/core';
import {DialogManager, IDialog, DialogBase} from '../../../common/dialogs/dialog-manager.service';
import {DocumentSubType} from '../../../../generated/api-stubs';

@Injectable()
export class LintingEngineRunnerDialog {
  constructor(
    private dialogManager: DialogManager
  ){}

  public async show(
    tenantId: string,
    userId: string,
    configId: string,
    configData: any,
    configType: DocumentSubType,
    studyId: string,
    jobId: string): Promise<LintingEngineRunnerResult> {

    let result = await this.dialogManager.showSecondaryDialog(
      new LintingEngineRunnerDialogData(
        tenantId,
        userId,
        configId,
        configData,
        configType,
        studyId,
        jobId));

    return result;
  }
}

export class LintingEngineRunnerDialogData extends DialogBase<LintingEngineRunnerResult> implements IDialog {
  constructor(
    public readonly tenantId: string,
    public readonly userId: string,
    public readonly configId: string,
    public readonly configData: any,
    public readonly configType: DocumentSubType,
    public readonly studyId: string,
    public readonly jobId: string) {
    super();
  }
}

export class LintingEngineRunnerResult {
  constructor(){
  }
}

@if (errorMessage) {
  <div class="worksheet-item-error-message">
    <div class="error-icon">
      <i class="fa fa-exclamation-circle"></i>
    </div>
    <div class="error-message">
      {{errorMessage}}
    </div>
  </div>
}

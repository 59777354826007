import {EventEmitter, Injectable} from '@angular/core';
import {ServiceOnInit} from './service-on-init';
import {GetTenantSimVersion} from './get-tenant-sim-version.service';
import {GetUserSimVersion} from './get-user-sim-version.service';

@Injectable()
export class GetSimVersion implements ServiceOnInit {
  public changed: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private readonly getTenantSimVersion: GetTenantSimVersion,
    private readonly getUserSimVersion: GetUserSimVersion) {
  }

  public serviceOnInit(){
    this.getUserSimVersion.changed.subscribe(() => this.onSimVersionChanged());
    this.getTenantSimVersion.changed.subscribe(() => this.onSimVersionChanged());
  }

  private onSimVersionChanged(){
    this.changed.emit(this.currentSimVersion);
  }

  public get currentSimVersion(): string {
    // Note: The user sim version is always loaded (it loads synchronously),
    // so using this for API calls is always safe. If the user sim version
    // is undefined and so is the tenant's, then the API will load the tenant's
    // sim version automatically.
    // However, remember that the result could be undefined, so
    // use the API result for the actual sim version if necessary.
    return this.getUserSimVersion.currentSimVersion
      || this.getTenantSimVersion.currentSimVersion;
  }

  public async execute(): Promise<string> {
    return await this.getUserSimVersion.execute()
      || await this.getTenantSimVersion.execute();
  }
}

export function splitStringContainingQuotes(value: string): string[] {
  //The parenthesis in the regex creates a captured group within the quotes
  const myRegexp = /[^\s,"]+|"([^"]*)"/gi;
  const result = [];

  let match = null;
  do {
    //Each call to exec returns the next regex match as an array
    match = myRegexp.exec(value);
    if (match != null) {
      //Index 1 in the array is the captured group if it exists
      //Index 0 is the matched text, which we use if no captured group exists
      result.push(match[1] ? match[1] : match[0]);
    }
  } while (match != null);

  return result;
}

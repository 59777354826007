import * as d3 from '../../d3-bundle';
import { IChannelMetadata, SourceLoaderBase, SourceMetadata } from './source-loader';
import { ChannelNameStyle } from './channel-name-style';
import { IViewerChannelData } from './viewer-channel-data';
import { isDefined } from '../../is-defined';
import { isNumber } from '../../is-number';
import { ViewerChannelDataFactory } from './viewer-channel-data-factory';
import { SiteHooks } from '../../site-hooks';
import { INDEX_DOMAIN_NAME, NO_UNITS } from '../../constants';

export interface LocalConfigSourceLoaderOptions {
  readonly xDomainAlias?: string;
  readonly filter?: RegExp | { test: (v: string) => boolean };
  readonly tableToChannelsMap?: { [propertyName: string]: string[] };
}

/**
 * A source loader that loads data from a local (in memory) config.
 */
export class LocalConfigSourceLoader extends SourceLoaderBase {

  private configChannelsList?: ReadonlyArray<ConfigChannel>;
  private configChannelsMap?: ReadonlyConfigChannelMap;

  /**
   * Creates a new local config source loader.
   * @param siteHooks The site hooks.
   * @param config The config to load data from.
   * @param name The name of the source.
   * @param xDomainName The name of the X domain.
   * @param unitsMap The units map.
   * @param options The options.
   * @returns The local config source loader.
   */
  public static create(
    siteHooks: SiteHooks,
    config: any,
    name?: string,
    xDomainName?: string,
    unitsMap: UnitsMap = {},
    options: LocalConfigSourceLoaderOptions = {}): LocalConfigSourceLoader {

    return new LocalConfigSourceLoader(
      new ViewerChannelDataFactory(siteHooks),
      config,
      name,
      xDomainName,
      unitsMap,
      options);
  }

  constructor(
    private readonly channelDataFactory: ViewerChannelDataFactory,
    private readonly config: any,
    private readonly name?: string,
    private readonly xDomainName?: string,
    private readonly unitsMap: UnitsMap = {},
    private readonly options: LocalConfigSourceLoaderOptions = {}) {
    super();
    this.unitsMap = this.unitsMap || {};
  }

  /**
   * @inheritdoc
   */
  getSourceMetadata(): Promise<SourceMetadata> {
    return Promise.resolve({
      name: this.name || 'Config'
    });
  }

  /**
   * @inheritdoc
   */
  public getChannelData(requestedName: string, resultChannelNameStyle: ChannelNameStyle, xDomainName: string): Promise<IViewerChannelData> {
    this.loadChannels();

    if (!this.configChannelsMap) {
      return Promise.resolve(this.channelDataFactory.createChannelData(requestedName, undefined, resultChannelNameStyle));
    }

    const channel = this.configChannelsMap[requestedName];
    let channelData = channel ? channel.data : undefined;
    let dataUnits = channel ? channel.units : NO_UNITS;
    let metadata = this.channelDataFactory.createChannelMetadata(requestedName, channel, resultChannelNameStyle);
    return Promise.resolve(this.channelDataFactory.createChannelDataFromMetadata(metadata, channelData, dataUnits));
  }

  /**
   * @inheritdoc
   */
  public getRequestableChannels(resultChannelNameStyle: ChannelNameStyle, xDomainName: string): Promise<IChannelMetadata[]> {
    this.loadChannels();

    if (!this.configChannelsList) {
      return Promise.resolve([]);
    }

    return Promise.resolve([...this.configChannelsList]);
  }

  /**
   * @inheritdoc
   */
  public getConfig(type: string): Promise<any> {
    return Promise.resolve(this.config);
  }

  /**
   * Loads channels from the config by recursively traversing it and finding all numeric arrays.
   * @returns The channels.
   */
  private loadChannels() {
    if (this.configChannelsList) {
      return;
    }

    let addToPath = (path: string, key: string) => path ? `${path}.${key}` : key;

    // A function to recursively traverse the config and extract channels.
    let inner = (value: any, valueKey: string, valuePath: string, channels: ConfigChannel[]): void => {

      if (isDefined(value)) {
        if (typeof value === 'object') {
          let isArray = Array.isArray(value);
          if (isArray) {
            if (value.length) {
              if (isNumber(value[0])) {
                if (!this.options.filter || this.options.filter.test(valuePath)) {
                  // Found a numeric array, add it as a channel.
                  channels.push(new ConfigChannel(
                    valuePath,
                    '',
                    this.unitsMap[valueKey] || NO_UNITS,
                    value));
                }
              } else if (Array.isArray(value[0]) && value[0].length && isNumber(value[0][0])) {
                let map = this.options.tableToChannelsMap ? this.options.tableToChannelsMap[valuePath] : undefined;
                if (map) {
                  // Found a 2D array, which we can map to channels.
                  let outerArray = value as Array<number[]>;
                  const mapLength = map.length;
                  if (outerArray.every(v => v.length === mapLength)) {
                    for (let channelIndex = 0; channelIndex < mapLength; ++channelIndex) {
                      let channelName = map[channelIndex];
                      channels.push(new ConfigChannel(
                        addToPath(valuePath, channelName),
                        '',
                        this.unitsMap[channelName] || NO_UNITS,
                        outerArray.map(v => v[channelIndex])));
                    }
                  }
                }
              } else {
                // Non-numeric array. Recurse.
                let index = 0;
                for (let child of value) {
                  inner(child, valueKey, `${valuePath}[${index}]`, channels);
                  ++index;
                }
              }
            }
          } else {
            // Object. Recurse.
            for (let key in value) {
              if (!value.hasOwnProperty(key)) {
                continue;
              }
              let child = value[key];

              if (this.options.xDomainAlias && key === this.options.xDomainAlias && this.xDomainName) {
                key = this.xDomainName;
              }

              inner(child, key, addToPath(valuePath, key), channels);
            }
          }
        }
      }
    };

    let channels: ConfigChannel[] = [];
    inner(this.config, '', '', channels);

    // If the x domain is the index of the data, generate a channel for it.
    if (this.xDomainName === INDEX_DOMAIN_NAME) {
      channels.push(new ConfigChannel(
        INDEX_DOMAIN_NAME,
        '',
        '()',
        channels.length ? d3.range(channels[0].data.length) : []));
    }

    this.configChannelsList = channels;
    this.configChannelsMap = channels.reduce<ConfigChannelMap>(
      (p, c) => {
        p[c.name] = c;
        return p;
      },
      {});
  }
}

/**
 * A map of channel name to units.
 */
export interface UnitsMap {
  readonly [key: string]: string;
}

/**
 * A channel that is loaded from a local config.
 */
export class ConfigChannel implements IChannelMetadata {

  /**
   * Creates a new config channel.
   * @param name The name of the channel.
   * @param description The description of the channel.
   * @param units The units of the channel.
   * @param data The channel data.
   */
  constructor(
    public readonly name: string,
    public readonly description: string,
    public readonly units: string,
    public readonly data: ReadonlyArray<number>) {
  }

  /**
   * The generic name of the channel (the generic name doesn't include the sim type and so may conflict
   * with channels from other simulations).
   */
  public get genericName(): string {
    return this.name;
  }

  /**
   * The full name of the channel (the full name includes the sim type and so is unique).
   */
  public get fullName(): string {
    return this.name;
  }

  /**
   * @inheritdoc
   */
  getSourceData<TSourceData>(): TSourceData {
    return {} as TSourceData;
  }
}

/**
 * A read-only map of channel names to channel data.
 */
interface ReadonlyConfigChannelMap {
  readonly [name: string]: ConfigChannel;
}

/**
 * A map of channel names to channel data.
 */
interface ConfigChannelMap {
  [name: string]: ConfigChannel;
}

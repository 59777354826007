import {WorksheetViewModel} from '../worksheet-view-model';
import {RowItemViewModel} from '../row-item-view-model';
import {MenuItem} from '../../context-menu/context-menu-types';
import {RowMetadataViewModel} from '../row-metadata-view-model';
import {ConfigViewModel} from '../config-view-model';
import {StudyViewModel} from '../study-view-model';
import {SimulationViewModel} from '../simulation-view-model';
import {RowViewModel} from '../row-view-model';
import {ColumnType} from '../column-type';
import {ActivatedRoute} from '@angular/router';

/**
 * An abstract worksheet command.
 */
export abstract class WorksheetCommand{

  /**
   * Determines if the command can be executed.
   * @param context The command context.
   * @returns True if the command can be executed, otherwise false.
   */
  public abstract createMenuItems(context: CommandContext, result: MenuItem<CommandResult>[]): void;
}

/**
 * The context for a worksheet.
 */
export class WorksheetContext {

  /**
   * Creates an instance of WorksheetContext.
   * @param isDocked True if the worksheet is docked, otherwise false.
   * @param route The route.
   */
  constructor(
    public readonly isDocked: boolean,
    public readonly route: ActivatedRoute) {
  }
}

/**
 * The context in which a command is executed. Primarily contains information
 * about the user's current selection in the worksheet.
 * It is possible for the user to make nonsensical or complex selections when performing certain commands.
 * We do a best attempt at handling these sensibly, sometimes simplifying to save code complexity.
 * Often this means paying attention to the target cell, and the selected rows, and assuming
 * the user wanted to select the target cell type in all rows. This is why these are the two things
 * exposed by default. However you can get the exact selection if if makes sense for the command (see copy command).
 */
export interface ICommandContext<T extends RowItemViewModel> {

  /**
   * The worksheet view model.
   */
  readonly worksheet: WorksheetViewModel;

  /**
   * The target of the command (a config, study, row metadata cell, etc.).
   * Even when multiple cells are selected, there is still a single primary target
   * which is the last cell the user clicked on.
   */
  readonly target: T;

  /**
   * The rows forming part of the selection. The row view models can be queried for the exact selection.
   */
  readonly selectedRows: ReadonlyArray<RowViewModel>;

  /**
   * True if multiple rows are selected, otherwise false.
   */
  readonly isMultiRow: boolean;

  /**
   * True if a single row is selected, otherwise false.
   */
  readonly isSingleRow: boolean;

  /**
   * The context for the worksheet.
   */
  readonly worksheetContext: WorksheetContext;

  /**
   * Determines if the target is a row metadata cell.
   */
  isRowMetadata(): this is ICommandContext<RowMetadataViewModel>;

  /**
   * Determines if the target is a config cell.
   */
  isConfig(): this is ICommandContext<ConfigViewModel>;

  /**
   * Determines if the target is a study cell.
   */
  isStudy(): this is ICommandContext<StudyViewModel>;

  /**
   * Determines if the target is a simulation cell.
   */
  isSimulation(): this is ICommandContext<SimulationViewModel>;

  /**
   * Determines if the target is a config or study cell.
   */
  isConfigOrStudy(): this is ICommandContext<ConfigViewModel> | ICommandContext<StudyViewModel>;
}

/**
 * The context in which a command is executed. Primarily contains information
 * about the user's current selection in the worksheet.
 * It is possible for the user to make nonsensical or complex selections when performing certain commands.
 * We do a best attempt at handling these sensibly, sometimes simplifying to save code complexity.
 * Often this means paying attention to the target cell, and the selected rows, and assuming
 * the user wanted to select the target cell type in all rows. This is why these are the two things
 * exposed by default. However you can get the exact selection if if makes sense for the command (see copy command).
 */
export class CommandContext implements ICommandContext<RowItemViewModel> {

  /**
   * @inheritdoc
   */
  public readonly selectedRows: ReadonlyArray<RowViewModel>;

  /**
   * @inheritdoc
   */
  public readonly isMultiRow: boolean;

  /**
   * @inheritdoc
   */
  public readonly isSingleRow: boolean;

  /**
   * Creates an instance of CommandContext.
   * @param target The target of the command.
   * @param worksheetContext The context for the worksheet.
   */
  constructor(
    public readonly target: RowItemViewModel,
    public readonly worksheetContext: WorksheetContext){
    this.selectedRows = this.worksheet.getSelectedRows();
    this.isMultiRow = this.selectedRows.length > 1;
    this.isSingleRow = this.selectedRows.length === 1;
  }

  /**
   * @inheritdoc
   */
  public get worksheet(): WorksheetViewModel {
    return this.target.row.worksheet;
  }

  /**
   * @inheritdoc
   */
  public isRowMetadata(): this is ICommandContext<RowMetadataViewModel> {
    return this.target instanceof RowMetadataViewModel;
  }

  /**
   * @inheritdoc
   */
  public isConfig(): this is ICommandContext<ConfigViewModel> {
    return this.target instanceof ConfigViewModel;
  }

  /**
   * @inheritdoc
   */
  public isStudy(): this is ICommandContext<StudyViewModel> {
    return this.target instanceof StudyViewModel;
  }

  /**
   * @inheritdoc
   */
  public isSimulation(): this is ICommandContext<SimulationViewModel> {
    return this.target instanceof SimulationViewModel;
  }

  /**
   * @inheritdoc
   */
  public isConfigOrStudy(): this is ICommandContext<ConfigViewModel> | ICommandContext<StudyViewModel> {
    return this.target instanceof ConfigViewModel || this.target instanceof StudyViewModel;
  }
}

/**
 * How to update the worksheet after a command has executed.
 */
export enum UpdateType {

  /**
   * Do not update the worksheet.
   */
  none,

  /**
   * Update the worksheet, assuming the columns have not changed.
   */
  update,

  /**
   * Update the worksheet and generate columns for the new rows.
   * Generating the columns involves determining the superset of columns across
   * all rows then creating any missing view models for each row so that each row has the
   * same columns.
   */
  updateAndGenerateColumns,
}

/**
 * The result of a command.
 */
export class CommandResult {

  /**
   * The result when no update is required.
   */
  public static readonly NoUpdate: CommandResult = new CommandResult(false, UpdateType.none);

  /**
   * The result when an update from the API is required, but columns do not need to be regenerated.
   */
  public static readonly UpdateOnly: CommandResult = new CommandResult(false, UpdateType.update);

  /**
   * The result when an update from the API is required, and columns need to be regenerated.
   */
  public static readonly UpdateAndGenerateColumns: CommandResult = new CommandResult(true, UpdateType.updateAndGenerateColumns);

  /**
   * The result when columns need to be regenerated, but no update from the API is required.
   */
  public static readonly GenerateColumnsOnly: CommandResult = new CommandResult(true, UpdateType.none);

  /**
   * Creates an instance of CommandResult.
   * @param generateColumnsImmediately True if columns should be generated immediately, otherwise false.
   * @param refreshWorksheet The type of update required for the worksheet.
   */
  constructor(
    public readonly generateColumnsImmediately: boolean,
    public readonly refreshWorksheet: UpdateType){
  }
}

/**
 * A mock command context for testing.
 */
export class MockCommandContext<T extends RowItemViewModel> implements ICommandContext<T> {

  /**
   * Creates an instance of MockCommandContext.
   * @param target The target of the command.
   * @param worksheet The worksheet view model.
   * @param selectedRows The selected rows.
   * @param isMultiRow True if multiple rows are selected, otherwise false.
   * @param isSingleRow True if a single row is selected, otherwise false.
   */
  constructor(
    public readonly target: any,
    public readonly worksheet: any,
    public readonly selectedRows: ReadonlyArray<any>,
    private readonly columnType: ColumnType,
    worksheetContext?: WorksheetContext){
      this.worksheetContext = worksheetContext || {} as any;
  }

  /**
   * @inheritdoc
   */
  public readonly worksheetContext: WorksheetContext;

  /**
   * @inheritdoc
   */
  public get isMultiRow(): boolean {
    return this.selectedRows.length > 1;
  }

  /**
   * @inheritdoc
   */
  public get isSingleRow(): boolean {
    return this.selectedRows.length === 1;
  }

  /**
   * @inheritdoc
   */
  isConfig(): this is ICommandContext<ConfigViewModel> {
    return this.columnType === ColumnType.config;
  }

  /**
   * @inheritdoc
   */
  isConfigOrStudy(): this is ICommandContext<ConfigViewModel> | ICommandContext<StudyViewModel> {
    return this.columnType === ColumnType.config || this.columnType === ColumnType.study;
  }

  /**
   * @inheritdoc
   */
  isRowMetadata(): this is ICommandContext<RowMetadataViewModel> {
    return this.columnType === ColumnType.rowMetadata;
  }

  /**
   * @inheritdoc
   */
  isSimulation(): this is ICommandContext<SimulationViewModel> {
    return this.columnType === ColumnType.simulation;
  }

  /**
   * @inheritdoc
   */
  isStudy(): this is ICommandContext<StudyViewModel> {
    return this.columnType === ColumnType.study;
  }
}

export class FetchConfigPermissions {
  static readonly type = '[ConfigPermissions] FetchConfigPermissions';
  constructor(public tenantId: string) {}
}

export class SetConfigPermission {
  static readonly type = '[ConfigPermissions] SetConfigPermission';
  constructor(
    public encryptingTenantShortName: string,
    public decryptingTenantShortName: string,
    public minimumSimVersion: string,
    public description: string) {}
}

export class ClearConfigPermission {
  static readonly type = '[ConfigPermissions] ClearConfigPermission';
  constructor() {}
}

export class PutConfigPermission {
  static readonly type = '[ConfigPermission] PutConfigPermission';
  constructor(
    public tenantId: string,
    public encryptingTenantShortName: string,
    public decryptingTenantShortName: string,
    public minimumSimVersion: string,
    public description: string) {}
}

export class DeleteConfigPermission {
  static readonly type = '[ConfigPermissions] DeleteConfigPermission';
  constructor(
    public tenantId: string,
    public encryptingTenantShortName: string,
    public decryptingTenantShortName: string) {}
}

<div id="results-staging-area" [ngClass]="{'area-visible': state?.sources?.length}">
  @if (state?.sources?.length) {
    <div class="results-container-top-line">
      <div class="container-fluid results-container-top">
        <div class="row header-row">
          <div class="col-12">
            <h1>Compare Results</h1>
            <div class="button-area">
              <a id="standard-overlay-button" class="btn btn-smart btn-secondary" [routerLink]="urlCommands">
                <i class="fa fa-area-chart"></i>Overlay
              </a>
              <button id="clear-results-staging-area-button" class="btn btn-smart btn-secondary" (click)="clear()">
                <i class="fa fa-close"></i>Clear
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="results-container-bottom">
        <div class="results-container-bottom-inner">
          <div class="result-sources">
            @for (source of state.sources; track source; let i = $index) {
              <div class="result-source" id="staged-result-{{i}}">
                <div class="result-source-left">
                  <a id="results-staging-area-study-link-{{source.studyId}}"
                    [routerLink]="['/studies', tenantId, source.studyId]">
                    <i class="fa fa-cubes"></i>
                    {{source.studyName}}
                  </a>
                </div><div class="result-source-center">
                @if (!source.jobId) {
                  <a id="results-staging-area-jobs-link-{{source.studyId}}"
                    [routerLink]="['/studies', tenantId, source.studyId, 'jobs']">
                    <i class="fa fa-cubes"></i>
                    {{source.jobName}}
                  </a>
                }
                @if (source.jobId) {
                  <a id="results-staging-area-job-link-{{source.studyId}}"
                    [routerLink]="['/studies', tenantId, source.studyId, 'jobs', source.jobIndex]">
                    <i class="fa fa-cube"></i>
                    {{source.jobName}}
                  </a>
                }
              </div>@if (i !== 0) {
              <div class="result-source-center move-source move-source-left" (click)="moveSourceLeft(source)">
                <i class="fa fa-caret-left"></i>
              </div>
              }@if (i !== state.sources.length - 1) {
              <div class="result-source-center move-source move-source-right" (click)="moveSourceRight(source)">
                <i class="fa fa-caret-right"></i>
              </div>
              }<div class="result-source-right" (click)="removeSource(source)">
              <i class="fa fa-close"></i>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</div>
}
<cs-error-message [message]="errorMessage"></cs-error-message>
</div>


/*
 *	@author zz85 / http://twitter.com/blurspline / http://www.lab4games.net/zz85/blog
 *
 *	A handy general perpose camera, for setting FOV, Lens Focal Length,
 *		and switching between perspective and orthographic views easily.
 *
 */

// https://raw.githubusercontent.com/zz85/three.js/60c103648e8933db79074f31de1a53cdefb53ddf/src/extras/cameras/CombinedCamera.js
import { OrthographicCamera, PerspectiveCamera, Vector3 } from 'three';
import { Subject, Observable } from 'rxjs';

export enum CameraMode {
  perspective,
  orthographic,
}

export class CameraState {
  private _modeChanged: Subject<CameraMode> = new Subject<CameraMode>();

  private _mode!: CameraMode;
  private _target!: Vector3;
  private _resetPostion!: Vector3;
  private _resetTarget!: Vector3;

  private readonly orthographicCamera: OrthographicCamera;
  private readonly perspectiveCamera: PerspectiveCamera;

  constructor(
    width: number,
    height: number,
    private _fov: number,
    near: number,
    far: number,
    orthonear: number,
    orthofar: number) {

    // We could also handle the projectionMatrix internally, but just wanted to test nested camera objects
    this.orthographicCamera = new OrthographicCamera(width / - 2, width / 2, height / 2, height / - 2, orthonear, orthofar);
    this.perspectiveCamera = new PerspectiveCamera(_fov, width / height, near, far);

    this.toPerspective();
  }

  public get modeChanged(): Observable<CameraMode> {
    return this._modeChanged;
  }

  public get fov(): number {
    return this._fov;
  }

  public get mode(): number {
    return this._mode;
  }

  public get camera(): PerspectiveCamera | OrthographicCamera {
    if (this._mode === CameraMode.perspective) {
      return this.perspectiveCamera;
    }

    return this.orthographicCamera;
  }

  public toPerspective() {
    this.perspectiveCamera.fov = this._fov;
    this.perspectiveCamera.updateProjectionMatrix();

    this.perspectiveCamera.position.copy(this.orthographicCamera.position);
    this.perspectiveCamera.quaternion.copy(this.orthographicCamera.quaternion);

    this._mode = CameraMode.perspective;
    this._modeChanged.next(this._mode);
  }

  public toOrthographic() {

    this.orthographicCamera.position.copy(this.perspectiveCamera.position);
    this.orthographicCamera.quaternion.copy(this.perspectiveCamera.quaternion);

    // Orthographic from Perspective
    let fov = this._fov;
    let aspect = this.perspectiveCamera.aspect;

    //let near = this.perspectiveCamera.near;
    //let far = this.perspectiveCamera.far;

    // Just pretend we want the mid plane of the viewing frustum
    //let hyperfocus = ( near + far ) / 2;


    let focalDistance = this.perspectiveCamera.position.distanceTo(this._target);

    let halfHeight = Math.tan(fov * (Math.PI / 180) / 2) * focalDistance;
    let planeHeight = 2 * halfHeight;
    let planeWidth = planeHeight * aspect;
    let halfWidth = planeWidth / 2;

    this.orthographicCamera.zoom = 1;

    // halfHeight /= this.zoom;
    // halfWidth /= this.zoom;

    this.orthographicCamera.left = -halfWidth;
    this.orthographicCamera.right = halfWidth;
    this.orthographicCamera.top = halfHeight;
    this.orthographicCamera.bottom = -halfHeight;

    // this.cameraO.left = -farHalfWidth;
    // this.cameraO.right = farHalfWidth;
    // this.cameraO.top = farHalfHeight;
    // this.cameraO.bottom = -farHalfHeight;

    // this.cameraO.left = this.left / this.zoom;
    // this.cameraO.right = this.right / this.zoom;
    // this.cameraO.top = this.top / this.zoom;
    // this.cameraO.bottom = this.bottom / this.zoom;

    this.orthographicCamera.updateProjectionMatrix();

    this._mode = CameraMode.orthographic;
    this._modeChanged.next(this._mode);
  }

  public setAspect(aspect: number) {
    let frustumSize = this.orthographicCamera.top - this.orthographicCamera.bottom;
    this.orthographicCamera.left = - frustumSize * aspect / 2;
    this.orthographicCamera.right = frustumSize * aspect / 2;
    this.orthographicCamera.top = frustumSize / 2;
    this.orthographicCamera.bottom = - frustumSize / 2;
    this.orthographicCamera.updateProjectionMatrix();

    this.perspectiveCamera.aspect = aspect;
    this.perspectiveCamera.updateProjectionMatrix();
  }

  public setTarget(target: Vector3) {
    this._target = target;
  }

  public setResetPostion(resetPosition: Vector3, resetTarget: Vector3) {
    this.camera.position.copy(resetPosition);
    this._resetPostion = resetPosition;
    this._resetTarget = resetTarget;
  }

  public setFov(fov: number) {
    this._fov = fov;

    if (this._mode === CameraMode.perspective) {
      this.toPerspective();
    } else {
      this.toOrthographic();
    }
  }

  /*
   * Uses Focal Length (in mm) to estimate and set FOV
   * 35mm (fullframe) camera is used if frame size is not specified;
   * Formula based on http://www.bobatkins.com/photography/technical/field_of_view.html
   */
  public setLens(focalLength: number, framesize: number) {

    if (!framesize) {
      framesize = 43.25; // 36x24mm
    }

    let fov = 2 * Math.atan(framesize / (focalLength * 2));
    fov = 180 / Math.PI * fov;
    this.setFov(fov);

    return fov;
  }

  public toFrontView() {
    let distance = this.perspectiveCamera.position.distanceTo(this._target);
    this.camera.position.x = this._target.x;
    this.camera.position.y = ALMOST_ZERO;
    this.camera.position.z = this._target.z + (this.negativeIfReversed * distance);
    this.camera.lookAt(this._target);
  }

  public toBackView() {
    let distance = this.perspectiveCamera.position.distanceTo(this._target);
    this.camera.position.x = this._target.x;
    this.camera.position.y = ALMOST_ZERO;
    this.camera.position.z = this._target.z - (this.negativeIfReversed * distance);
    this.camera.lookAt(this._target);
  }

  public toLeftView() {
    let distance = this.perspectiveCamera.position.distanceTo(this._target);
    this.camera.position.x = this._target.x - (this.negativeIfReversed * distance);
    this.camera.position.y = ALMOST_ZERO;
    this.camera.position.z = this._target.z;
    this.camera.lookAt(this._target);
  }

  public toRightView() {
    let distance = this.perspectiveCamera.position.distanceTo(this._target);
    this.camera.position.x = this._target.x + (this.negativeIfReversed * distance);
    this.camera.position.y = ALMOST_ZERO;
    this.camera.position.z = this._target.z;
    this.camera.lookAt(this._target);
  }

  public toTopView() {
    let distance = this.perspectiveCamera.position.distanceTo(this._target);
    this.camera.position.x = this._target.x;
    this.camera.position.y = distance;
    this.camera.position.z = this._target.z + (this.negativeIfReversed * ALMOST_ZERO);
    this.camera.lookAt(this._target);
  }

  public toBottomView() {
    let distance = this.perspectiveCamera.position.distanceTo(this._target);
    this.camera.position.x = this._target.x;
    this.camera.position.y = -distance;
    this.camera.position.z = this._target.z + (this.negativeIfReversed * ALMOST_ZERO);
    this.camera.lookAt(this._target);
  }

  private get isFrontBackReversed(): boolean {
    return (this._resetPostion.z - this._resetTarget.z) < 0;
  }

  private get negativeIfReversed(): number {
    return this.isFrontBackReversed ? -1 : 1;
  }
}

export const ALMOST_ZERO = 0.00001;

import {Injectable} from '@angular/core';
import {CommandContext, CommandResult, ICommandContext, WorksheetCommand} from './worksheet-command';
import {
  ButtonMenuItem,
  MenuItem,
} from '../../context-menu/context-menu-types';
import {ConfigViewModel} from '../config-view-model';
import {ReplaceResult} from '../worksheet-item-component-base';
import {SetConfigOrAllReferences} from './set-config-or-all-references.service';
import {ConfirmReplaceAllReferences} from './confirm-replace-all-references.service';
import {GoToJob} from '../../simulations/jobs/go-to-job.service';
import { AuthenticationService } from '../../identity/state/authentication.service';

/**
 * A command that sets the job index for the selected telemetry config.
 * A telemetry config refers to a single job within a study, and the job index is the index of that job within the study.
 */
@Injectable()
export class SetTelemetryJobIndexCommand extends WorksheetCommand {

  /**
   * Creates an instance of SetTelemetryJobIndexCommand.
   * @param setConfigOrAllReferences The service for setting the config.
   * @param confirmReplaceAllReferences The service for confirming the replacement of all references.
   * @param goToJob The service for navigating to a job.
   * @param authenticationService The authentication service.
   */
  constructor(
    private readonly setConfigOrAllReferences: SetConfigOrAllReferences,
    private readonly confirmReplaceAllReferences: ConfirmReplaceAllReferences,
    private readonly goToJob: GoToJob,
    private readonly authenticationService: AuthenticationService) {
    super();
  }

  /**
   * Determines if the command can be executed.
   * @param context The command context.
   * @returns True if the command can be executed, otherwise false.
   */
  public canExecute(context: CommandContext): context is ICommandContext<ConfigViewModel> {
    const userData = this.authenticationService.userDataSnapshot;
    return context.isConfig()
      && context.worksheet.canWrite(userData.sub)
      && context.isSingleRow
      && context.target.isResolved
      && context.target.isTelemetry;
  }

  /**
   * Executes the command.
   * @param context The command context.
   * @returns The command result.
   */
  public async execute(context: CommandContext): Promise<CommandResult> {
    if(!this.canExecute(context)) {
      return CommandResult.NoUpdate;
    }

    const rows = context.selectedRows;
    const replaceResult = await this.confirmReplaceAllReferences.execute(context.target, rows);
    if(replaceResult === ReplaceResult.cancel){
      return CommandResult.NoUpdate;
    }

    const jobIndex = await this.goToJob.loadAndPromptForJobIndex(
      context.target.reference.tenant.tenantId,
      context.target.reference.tenant.targetId);

    if(jobIndex === -1) {
      return CommandResult.NoUpdate;
    }

    await this.setConfigOrAllReferences.execute(
      context.target,
      rows,
      {
        tenant: {
          ...context.target.reference.tenant,
          jobIndex,
        }
      },
      replaceResult);

    return CommandResult.UpdateOnly;
  }

  /**
   * Creates the menu items for the command.
   * @param context The command context.
   * @param result The menu items.
   */
  public createMenuItems(context: CommandContext, result: MenuItem<CommandResult>[]): void {
    if(!this.canExecute(context)) {
      return;
    }

    result.push(new ButtonMenuItem<CommandResult>(
      `Set Job Index`,
      `set-telemetry-job-index-button`,
      () => this.execute(context),
      'list-ol'));
  }
}


<div class="job-results-component">

  @if (shouldShowWarningsInline) {
    <cs-view-job-log-file
      [jobs]="jobs"
      [logFileType]="jobLogFileType.warnings"
    [collapsible]="true"></cs-view-job-log-file>
  }

  <div class="container-fluid">
    @if (!tabs && !errorMessage) {
      <div class="job-results-loader">
        <cs-loader id="job-results-loader"></cs-loader>
      </div>
    }

    @if (errorMessage) {
      <div class="job-results-error-message">
        <cs-error-message [message]="errorMessage"></cs-error-message>
      </div>
    }

    @if (tabs) {
      <div class="job-results-tabs">
        <h2>Job Data:</h2>
        @for (tab of tabs; track tab) {
          <button
            (click)="setTab(tab)"
            [ngClass]="{ 'btn-primary': tab.isActive, 'btn-secondary': !tab.isActive }"
            class="btn btn-smart job-results-tab-{{cssSanitize(tab.name)}}">
            {{tab.name}}
          </button>
        }
      </div>
    }
  </div>

  @if (tabs) {
    <div>
      @for (tab of tabs; track tab; let i = $index) {
        <div [ngStyle]="{ display: tab.isActive ? 'block' : 'none' }">
          <div>
            @switch (tab.type) {
              @case (renderType.scalarResults) {
                <div>
                  <cs-view-job-scalar-results
                    [tab]="tab"
                    [jobs]="jobs"
                  [jobSelectorState]="jobSelectorState"></cs-view-job-scalar-results>
                </div>
              }
              @case (renderType.info) {
                <div>
                  <cs-view-job-log-file
                    [tab]="tab"
                    [jobs]="jobs"
                    [logFileType]="jobLogFileType.info"
                  [jobSelectorState]="jobSelectorState"></cs-view-job-log-file>
                </div>
              }
              @case (renderType.warnings) {
                <div>
                  <cs-view-job-log-file
                    [tab]="tab"
                    [jobs]="jobs"
                    [logFileType]="jobLogFileType.warnings"
                  [jobSelectorState]="jobSelectorState"></cs-view-job-log-file>
                </div>
              }
              @case (renderType.errors) {
                <div>
                  <cs-view-job-errors
                    [tab]="tab"
                    [jobs]="jobs"
                  [jobSelectorState]="jobSelectorState"></cs-view-job-errors>
                </div>
              }
              @case (renderType.inputs) {
                <div>
                  <cs-view-job-inputs
                    [tab]="tab"
                    [jobs]="jobs"
                  [jobSelectorState]="jobSelectorState"></cs-view-job-inputs>
                </div>
              }
              @case (renderType.navigationStation) {
                <div>
                  <cs-view-job-navigation-station
                    [tab]="tab"
                    [jobs]="jobs"
                    [jobSelectorState]="jobSelectorState"
                    [navigationStationPrefix]="navigationStationPrefix ? navigationStationPrefix + '-' + i : undefined"
                  [visualizationCaches]="visualizationCaches"></cs-view-job-navigation-station>
                </div>
              }
            }
          </div>
        </div>
      }
    </div>
  }
</div>


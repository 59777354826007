import {Component, OnInit} from '@angular/core';
import {checkIfAroundDate} from './timer.service';

@Component({
  selector: 'cs-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {

  public isChristmas: boolean;

  public ngOnInit() {
    this.isChristmas = checkIfAroundDate(11, 25, 12, 12);
  }
}

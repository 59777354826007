<div class="tenant-billable-simulation-count container-fluid">
  <cs-error-message [message]="errorMessage"></cs-error-message>
  <h1 class="main-title">Stored Simulation Count</h1>

  @if (!data && !isLoading) {
    <p>
      <button type="button" class="btn btn-secondary btn-smart" (click)="load()">Calculate</button>
    </p>
  }
  @if (!data && isLoading && !errorMessage) {
    <cs-loader class="loader-centered"></cs-loader>
  }
  @if (data) {
    <div>
      @if (data.succeededSimulations !== data.succeededStorageCredits) {
        <p>
          <strong>
            {{data.succeededStorageCredits | withCommas}}
          </strong>
          storage credit{{data.succeededStorageCredits === 1 ? '' : 's'}}
          are currently in use by
          <strong>
            {{data.succeededSimulations | withCommas}}
          </strong>
          simulation{{data.succeededSimulations === 1 ? '' : 's'}}.
        </p>
      }
      @if (data.succeededSimulations === data.succeededStorageCredits) {
        <p>
          <strong>
            {{data.succeededSimulations | withCommas}}
          </strong>
          simulation{{data.succeededSimulations === 1 ? '' : 's'}}
          are currently being stored.
        </p>
      }
    </div>
  }
</div>

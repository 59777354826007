
<cs-error-message [message]="errorMessage"></cs-error-message>
@if (!labels) {
  <cs-loader></cs-loader>
}

@if (labels) {
  <div class="alert alert-info labels-key">
    <h3>Key</h3>
    <span class="label-key"><i class="fa fa-th key-enabled-icon" ></i> Saved for worksheet.</span>
    <span class="label-key"><i class="fa fa-user key-enabled-icon"></i> Saved for user.</span>
    <span class="label-key"><i class="fa fa-users key-enabled-icon"></i> Saved for tenant.</span>
  </div>
  <ul class="nav nav-tabs">
    @for (set of labels.list; track set) {
      <li class="nav-item">
        <a class="nav-link"
          id="{{cssSanitize(set.name)}}-tab"
        [ngClass]="{ active: activeSet === set }" href="javascript:void(0)" (click)="setActiveSet(set)">{{set.name}}</a>
      </li>
    }
  </ul>
  @for (set of labels.list; track set) {
    @if (activeSet === set) {
      <div class="set-content">
        @for (item of set.items; track item) {
          <div class="card" [ngClass]="{ 'no-labels': item.labels.length === 0 }">
            <h3 class="card-header">{{item.displayName}}</h3>
            <div class="card-body">
              @if (item.labels.length === 0) {
                <p class="no-labels-message last-item">No labels.</p>
              }
              <table class="table">
                <tbody>
                  @for (definition of item.labels; track definition) {
                    <tr>
                      <td>
                        {{definition.name}}
                      </td>
                      <td class="button-column">
                        <button class="btn btn-icon btn-secondary"
                          id="{{cssSanitize(set.name)}}-{{cssSanitize(item.key)}}-{{cssSanitize(definition.name)}}-worksheet-button"
                          [ngClass]="{ 'enabled-icon': definition.inWorksheetLabels, 'enabled-worksheet-icon': definition.inWorksheetLabels }"
                          [disabled]="!canEditWorksheetLabels"
                          (click)="toggleLocation(definition, 'inWorksheetLabels')">
                          <i class="fa fa-th"></i>
                        </button>
                      </td>
                      <td class="button-column">
                        <button class="btn btn-icon btn-secondary"
                          id="{{cssSanitize(set.name)}}-{{cssSanitize(item.key)}}-{{cssSanitize(definition.name)}}-user-button"
                          [ngClass]="{ 'enabled-icon': definition.inUserLabels, 'enabled-user-icon': definition.inUserLabels }"
                          (click)="toggleLocation(definition, 'inUserLabels')">
                          <i class="fa fa-user"></i>
                        </button>
                      </td>
                      <td class="button-column">
                        <button class="btn btn-icon btn-secondary"
                          id="{{cssSanitize(set.name)}}-{{cssSanitize(item.key)}}-{{cssSanitize(definition.name)}}-tenant-button"
                          [ngClass]="{ 'enabled-icon': definition.inTenantLabels, 'enabled-tenant-icon': definition.inTenantLabels }"
                          (click)="toggleLocation(definition, 'inTenantLabels')">
                          <i class="fa fa-users"></i>
                        </button>
                      </td>
                      <td class="button-column">
                        <button class="btn btn-icon btn-secondary" (click)="deleteLabel(item, definition)">
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
              <p class="last-item">
                <button class="btn btn-smart btn-secondary"
                  id="{{cssSanitize(set.name)}}-{{cssSanitize(item.key)}}-add-button"
                  (click)="addLabel(item)">
                  Add Label
                </button>
              </p>
            </div>
          </div>
        }
      </div>
    }
  }
}

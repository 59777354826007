import {Injectable} from '@angular/core';
import {StudyReference} from '../../generated/api-stubs';
import {DisplayableError} from '../common/errors/errors';
import {CreateWorksheetRowFromStudy, CreateWorksheetRowFromStudyOptions} from './create-worksheet-row-from-study';
import {WorksheetViewModel} from './worksheet-view-model';
import {DeduplicateWorksheetRow} from './deduplicate-worksheet-row.service';

/**
 * Service for adding a study to a worksheet and extracting it's inputs into the config columns.
 * It will optionally deduplicate the extracted inputs by comparing the hashes to the other rows,
 * and replacing the extracted inputs with references to the existing inputs if they match.
 */
@Injectable()
export class AddStudyToWorksheetAndExtractInputs {

  /**
   * Creates an instance of AddStudyToWorksheetAndExtractInputs.
   * @param createWorksheetRowFromStudy The service for creating a worksheet row from a study.
   * @param deduplicateWorksheetRow The service for deduplicating a worksheet row.
   */
  constructor(
    private readonly createWorksheetRowFromStudy: CreateWorksheetRowFromStudy,
    private readonly deduplicateWorksheetRow: DeduplicateWorksheetRow) {
  }

  /**
   * Adds a study to the worksheet and extracts it's inputs into the config columns.
   * @param worksheet The worksheet.
   * @param studyReference The study reference.
   * @param deduplicate True to deduplicate the extracted inputs, otherwise false.
   */
  public async execute(worksheet: WorksheetViewModel, studyReference: StudyReference, deduplicate: boolean): Promise<void>{

    // Find the last non-empty row in the worksheet.
    let rowIndex = worksheet.rows.length - 1;
    while (rowIndex >= 0) {
      const row = worksheet.rows[rowIndex];
      if(!row.isEmpty){
        break;
      }
      --rowIndex;
    }

    // Select the next row, after the last non-empty row.
    rowIndex = rowIndex + 1;

    // Insert a new row (so the worksheet maintains the same number of empty rows at the end).
    const insertResult = worksheet.insertRowsAt(
      rowIndex,
      1,
      () => worksheet.createEmptyRow(worksheet.getCurrentUnderlyingData().studyTypesList[0].studyType));

    if(!insertResult){
      throw new DisplayableError('Maximum rows reached.');
    }

    const row = worksheet.rows[rowIndex];

    // Create the row.
    const rowContent = await this.createWorksheetRowFromStudy.execute(
      studyReference.tenantId,
      studyReference.targetId,
      worksheet.worksheetId,
      CreateWorksheetRowFromStudyOptions.default());

    // Wait for the worksheet to finish updating.
    await worksheet.waitForUpdate();

    // Paste the row content.
    worksheet.pasteRowsSync(row, rowContent.clipboardContent, false);

    if(deduplicate){
      // If requested, de-duplicate the row. This involves comparing the row to the other rows in the worksheet
      // and replacing the extracted inputs with references to the existing inputs if they match.
      this.deduplicateWorksheetRow.execute(worksheet, rowIndex);
    }

    // Update the worksheet.
    worksheet.generateColumns();
    worksheet.requestUpdate(true);
  }
}

import { Injectable } from '@angular/core';
import { JsonEditorCustomization } from './json-editor-customization';
import{ JSONEditor } from'@json-editor/json-editor'

@Injectable()
export class CheckboxEditor extends JsonEditorCustomization {
  public apply(): void {
    JSONEditor.defaults.editors.checkbox = class extends JSONEditor.defaults.editors.checkbox {
      build() {
        super.build();
        this.theme.addHelpLink(this.control, this.schema, this);
      }
    }
  }
}

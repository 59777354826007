@if (simpleRender) {
  {{renderValue}}
} @else {
  <span (mouseenter)="setMouseOver(true)" (mouseleave)="setMouseOver(false)" class="unit-renderer">
    <span [ngClass]="{ hidden: isMouseOver }">
      {{renderValue}}
    </span>
    <button [ngClass]="{ hidden: !isMouseOver }" class="btn btn-secondary btn-sm" (click)="setUnits()">Set</button>
  </span>
}
